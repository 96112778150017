import React, { FC, memo } from 'react'

import cx from 'classnames'

import TransparentButton from 'Scenes/Components/TransparentButton'

import './DataTable.scss'

interface Props {
	text: string | number
	data: Record<string, any>
	className: string
}

const DataTableTextWithButton: FC<Props> = ({
	text,
	data: { onClick, buttonText, ...restData },
	className
}) => {
	return (
		<div
			className={cx('data-table-opacity-field', className, restData.className)}
		>
			<span>{text}</span>
			<TransparentButton className="data-table-opacity-field--button" disabled={false} onClick={onClick}>
				{buttonText}
			</TransparentButton>
		</div>
	)
}

export default memo(DataTableTextWithButton)
