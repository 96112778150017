import {
	DataTableFieldType,
	IDataTableField,
	IDataTableFields
} from './IDataTableField'

/**
 * a service that creates an object for the data table components props
 */

export class DataTableService {
	RenderFieldObject = (props: IDataTableFields): IDataTableField => {
		const {
			type,
			text = '',
			dataOrDeviation = null,
			iconName = undefined,
			onClick = undefined,
			className = '',
			iconClassName = '',
			extraData = undefined,
			iconOnTheRight = undefined,
			title = undefined,
			intro = undefined,
			hoverText = undefined,
			order = undefined,
			truncated = false,
			nestedDataTableFields = undefined,
			isHover = undefined,
			description = undefined,
			descriptionClassName = undefined,
			score = null,
			withAdditiveMind = false,
			buttonText = ''
		} = props

		switch (type) {
			case DataTableFieldType.RadioButtonWithColor:
				return { type, text, data: extraData, order }
			case DataTableFieldType.Header:
			case DataTableFieldType.HeaderWithDescription:
				return {
					text,
					type,
					data: {
						description,
						descriptionClassName,
						className,
						iconName,
						hoverText
					}
				}
			case DataTableFieldType.OpacityText:
			case DataTableFieldType.WallThicknessButton:
			case DataTableFieldType.LongText:
				return {
					text,
					type,
					data: { className, score, withAdditiveMind },
					order,
					truncated
				}
			case DataTableFieldType.HeaderIcon:
				return {
					text,
					type,
					data: {
						iconName,
						iconClassName,
						iconOnTheRight,
						className,
						hoverText
					},
					order
				}
			case DataTableFieldType.IconInfo:
				return {
					text,
					type,
					data: {
						iconName,
						iconClassName,
						iconOnTheRight,
						className,
						hoverText
					},
					order
				}
			case DataTableFieldType.Detailed:
				return {
					text,
					type,
					data: {
						className,
						title,
						intro
					},
					order
				}
			case DataTableFieldType.TextWithStandardDeviation:
				return {
					text,
					type,
					data: {
						dataOrDeviation
					},
					order
				}
			case DataTableFieldType.TextWithXYZDeviation:
				return {
					text,
					type,
					data: {
						dataOrDeviation
					},
					order
				}
			case DataTableFieldType.TextWithIcon:
				return {
					text,
					type,
					data: {
						iconName,
						iconClassName,
						iconOnTheRight,
						className,
						...extraData
					},
					order
				}
			case DataTableFieldType.TextWithIconButton:
				return {
					text,
					type,
					data: {
						iconName,
						iconClassName,
						iconOnTheRight,
						className,
						isHover,
						description
					},
					order
				}
			case DataTableFieldType.TextWithButton:
				return {
					text,
					type,
					data: {
						className,
						buttonText,
						onClick
					},
					order
				}
			case DataTableFieldType.TextWithIconWarn:
			case DataTableFieldType.TextWithIconLink:
				return {
					text,
					type,
					data: {
						iconName,
						iconClassName,
						iconOnTheRight,
						className
					},
					order
				}
			case DataTableFieldType.ButtonWithIcon:
			case DataTableFieldType.TransparentButtonWithLoader:
			case DataTableFieldType.ButtonWithLoader:
			case DataTableFieldType.ButtonWithPopup:
			case DataTableFieldType.ButtonWithIconAndPopup:

			case DataTableFieldType.Button:
				return {
					text,
					type,
					data: {
						onClick,
						buttonClassName: className || '',
						...extraData
					},
					order
				}

			case DataTableFieldType.Buttons:
				return {
					text,
					type,
					nestedDataTableFields,
					data: { className }
				}
			case DataTableFieldType.DataTableTextWithDescription:
				return {
					text,
					type,
					data: {
						description
					},
					order
				}
			default:
				return {
					text,
					type: DataTableFieldType.Text,
					data: { className },
					order
				}
		}
	}
}
