import React, { FC } from 'react'

import { ILeadTimeDetails } from '../LeadTimeTab/LeadTimeInterface'
import { getString } from 'Services/Strings/StringService'
import InfoBox from 'Scenes/Components/InfoBox'
import {
	COST_AND_LEAD_LABELS,
	TIME_DETAILS_HOVER_HEADER
} from 'Services/Strings'
import Devider from 'Scenes/Components/Devider/Devider'

const DeviderTSX: any = Devider

interface LeadCostProps {
	leadTimeDetails: ILeadTimeDetails
	isBig?: boolean
	boxClassName?: string
}

const LeadCostDetailsIcon: FC<LeadCostProps> = ({
	leadTimeDetails,
	boxClassName,
	isBig = false
}) => {
	const costDetailsPopUpWithoutExplanation = () => {
		const {
			deliveryTimeForEachBatch = null,
			firstLeadTime = null,
			lastDay = null,
			partsForEachShipments = null,
			firstShipments = null
		} = leadTimeDetails

		const dividerWidth = isBig ? '300px' : '228px'

		return (
			<>
				<div className="cost-details-row-header">
					<p>{TIME_DETAILS_HOVER_HEADER}</p>
				</div>
				{firstShipments && firstLeadTime ? (
					<div className="cost-details-row">
						<p>
							{firstShipments} {getString('PARTS_WILL_BE_ARRIVE')}
						</p>
						<p>
							{' '}
							{firstLeadTime} {COST_AND_LEAD_LABELS.DAYS}
						</p>
					</div>
				) : (
					<></>
				)}
				{partsForEachShipments && deliveryTimeForEachBatch ? (
					<div className="cost-details-row">
						<p>
							{getString('NEXT_PARTS_WILL_BE_ARRIVE').format(
								partsForEachShipments
							)}
						</p>
						<p>
							~ {deliveryTimeForEachBatch} {COST_AND_LEAD_LABELS.DAYS}
						</p>
					</div>
				) : (
					<></>
				)}
				<DeviderTSX size={dividerWidth} className="cost-details-row-divider" />
				<div className="cost-details-row cost-details-row-total">
					<div>{getString('COMPLETE_LEAD_TIME')}</div>
					<div>
						<span> {lastDay}</span>
						<span>{COST_AND_LEAD_LABELS.DAYS}</span>
					</div>
				</div>
				<DeviderTSX
					size={dividerWidth}
					className="cost-details-row-divider cost-details-row-divider--last"
				/>
			</>
		)
	}

	return (
		<InfoBox
			boxContactElement={costDetailsPopUpWithoutExplanation()}
			iconClassName="cost-details-icon"
			boxClassName={boxClassName}
		/>
	)
}

export default LeadCostDetailsIcon
