import React, { FC } from 'react'
import cx from 'classnames'

import { ReactComponent as Warn } from 'assets/img/svg/warn.svg'
import { ReactComponent as Check } from 'assets/img/svg/Checkmark.svg'

import './index.scss'

interface WarnMessageProps {
  show: boolean
  showSuccess?: boolean
  message?: string | null | React.ReactNode
  warnClassName?: string
}

const WarnMessage: FC<WarnMessageProps> = ({
  show,
  message,
  showSuccess,
  warnClassName
}) => {
  if (!show) return <div />
  return <div className={cx('warn-message', warnClassName)} data-qa='data-qa-uploading-validation'>
    {showSuccess ? <Check /> : <Warn />}
    <span>{message}</span>
  </div>
}

export default WarnMessage
