import { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { connect, RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import {
	Field,
	FormErrors,
	getFormSyncErrors,
	getFormValues,
	InjectedFormProps,
	reduxForm
} from 'redux-form'

import DateFnsUtils from '@date-io/date-fns'
import { FormControlLabel, MenuItem, RadioGroup } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import UploadIcon from '@material-ui/icons/CloudUpload'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { isEmpty } from 'lodash'

import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import HeaderCard from '../../../Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import { renderSelectFieldTouched } from '../AdminFields/AdminSelectField'
import { onUploadFile, setFile } from './AdminLicenseCreatorActions'
import CastorRadio from 'Scenes/Components/CastorRadio'
import Flexbox from 'Scenes/Components/FlexBox'
import {
	isCommaKeyPressed,
	isDisabledKeyPressed,
	isDotKeyPressed,
	isMinusKeyPressed
} from 'Services/getKeyCodesService'
import {
	CREATE_FILE_FOR_LICENSE,
	DROP_DATABASE_FILE,
	REQUIRED
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const NUMBER_TYPE = 'number'
const META_DATA = 'metaData'
const PDF = 'pdf'

const UploadIconTSX: any = UploadIcon
const ReduxFormField: any = Field
const RadioGroupTSX: any = RadioGroup
const FormControlLabelTSX: any = FormControlLabel
const CheckboxTSX: any = Checkbox

enum organizationByEnvScenario {
	BY_USER = '0',
	BY_ENV = '1',
	NO_CHANGE = '2'
}

enum licenseScenario {
	NEW = '0',
	RENEW = '1'
}

enum smtpFields {
	SERVER = 'smtpServer',
	USER = 'smtpUser',
	PASSWORD = 'smtpPassword',
	EMAIL = 'smtpEmail',
	TCP_PORT = 'smtpTcpPort'
}

const featuresOptions = [
	{ value: 0, name: getString('FEATURES_OPTIONS').dontChange },
	{ value: 1, name: getString('FEATURES_OPTIONS').on },
	{ value: 2, name: getString('FEATURES_OPTIONS').off }
]

const communicationToolOptions = {
	MATERIAL_TYPE: { name: 'communicationToolMaterialType', defaultValue: 1 },
	MATERIAL_CATEGORY: {
		name: 'communicationToolMaterialCategory',
		defaultValue: 1
	},
	ORIGINAL_MATERIAL: {
		name: 'communicationToolOriginalMaterial',
		defaultValue: 2
	},
	PRODUCTION_QUANTITY: {
		name: 'communicationToolProductionQuantity',
		defaultValue: 1
	},
	STANDARD_COST: { name: 'communicationToolStandardCost', defaultValue: 1 },
	TOLERANCE: { name: 'communicationToolTolerance', defaultValue: 2 },
	PRINTING_STANDARDS: {
		name: 'communicationToolPrintingStandards',
		defaultValue: 2
	}
}

interface IFormData {
	startDate: Date
	expireDate: Date
	allowedUsers: number | null
	allowedAdministratorUsers: number | null
	allowedLightUsers: number | null
	totalParts: number | null
	organizationOwners: string | null
	administratorEmails: string | null
	organizationName: string
	smtpServer: string
	smtpUser: string
	smtpPassword: string
	smtpEmail: string
	smtpTcpPort: string
	isOrganizationByEnvScenario: string
	isNewLicenseScenario: string
	isUnlimitedLightUsers: boolean | null
	isCommunicationTool: boolean | null
	isSmtp: boolean | null
	pdf: number | null
	metaData: number | null
	communicationToolMaterialType: number | null
	communicationToolMaterialCategory: number | null
	communicationToolOriginalMaterial: number | null
	communicationToolProductionQuantity: number | null
	communicationToolStandardCost: number | null
	communicationToolTolerance: number | null
	communicationToolPrintingStandards: number | null
}

const validate = (values: IFormData, currentForm: any) => {
	const errors: FormErrors<IFormData, any> = {}

	const isNewScenario =
		!values.isNewLicenseScenario ||
		values.isNewLicenseScenario === licenseScenario.NEW

	const validationFormMessage = isNewScenario
		? getString('NUMBER_VALIDATION_FROM_REQUIRED').format(0)
		: getString('NUMBER_VALIDATION_FROM_OPTIONAL').format(0)

	if (
		isNewScenario &&
		(!values.allowedUsers || Number(values.allowedUsers) === 0)
	) {
		errors.allowedUsers = REQUIRED
	}
	if (values.allowedUsers && values.allowedUsers < 0) {
		errors.allowedUsers = validationFormMessage
	}

	//TODO: Verify that we 100% not need it
	// if (
	// 	isNewScenario &&
	// 	!values?.isUnlimitedLightUsers &&
	// 	(!values.allowedLightUsers || Number(values.allowedLightUsers) < 0)
	// ) {
	// 	errors.allowedLightUsers = REQUIRED
	// }

	if (
		values.allowedAdministratorUsers &&
		values.allowedAdministratorUsers < 0
	) {
		errors.allowedAdministratorUsers = getString(
			'NUMBER_VALIDATION_FROM_OPTIONAL'
		).format(0)
	}

	if (
		!!values.allowedAdministratorUsers &&
		!!values.allowedUsers &&
		Number(values.allowedAdministratorUsers) > Number(values.allowedUsers)
	) {
		errors.allowedAdministratorUsers = getString(
			'VALIDATION_ONE_FIELD_SMALLER_THEN_OTHER'
		).format(
			'allowedAdministratorUsers',
			values.allowedAdministratorUsers,
			'allowedUsers',
			values.allowedUsers
		)
	}
	if (
		(!values.isNewLicenseScenario ||
			values.isNewLicenseScenario === licenseScenario.NEW) &&
		values?.isOrganizationByEnvScenario === organizationByEnvScenario.BY_ENV &&
		!values?.organizationName
	) {
		errors.organizationName = REQUIRED
	}

	if (
		(!values.isNewLicenseScenario ||
			values.isNewLicenseScenario === licenseScenario.NEW) &&
		values?.isOrganizationByEnvScenario === organizationByEnvScenario.BY_ENV &&
		!values?.organizationName
	) {
		errors.organizationName = REQUIRED
	}

	if (
		isNewScenario &&
		!values?.isUnlimitedLightUsers &&
		!values?.allowedLightUsers &&
		values?.allowedLightUsers !== 0
	) {
		errors.allowedLightUsers = REQUIRED
	}

	if (
		!values?.isUnlimitedLightUsers &&
		values.allowedLightUsers &&
		values.allowedLightUsers < 0
	) {
		errors.allowedLightUsers = getString(
			'NUMBER_VALIDATION_FROM_OPTIONAL'
		).format(0)
	}

	if (
		isNewScenario &&
		(!values.totalParts || Number(values.totalParts) === 0)
	) {
		errors.totalParts = REQUIRED
	}

	if (values.isSmtp && !values.smtpServer) {
		errors.smtpServer = REQUIRED
	}
	if (values.isSmtp && !values.smtpUser) {
		errors.smtpUser = REQUIRED
	}
	if (values.isSmtp && !values.smtpPassword) {
		errors.smtpPassword = REQUIRED
	}
	if (values.isSmtp && !values.smtpEmail) {
		errors.smtpEmail = REQUIRED
	}
	if (values.isSmtp && !values.smtpTcpPort) {
		errors.smtpTcpPort = REQUIRED
	}

	if (values.totalParts && values.totalParts < 0) {
		errors.totalParts = validationFormMessage
	}
	const startDate = values.startDate || currentForm?.initialValues?.startDate
	const expireDate = values.expireDate || currentForm?.initialValues?.expireDate
	if (startDate > expireDate) {
		errors.expireDate = 'error'
	}
	return errors
}

const renderTimePicker = ({ input, label, minDate }: any) => {
	const value = input.value ? input.value : new Date()
	return (
		<div className="date-range-picker-wrapper">
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<DatePicker
					PopoverProps={{
						className: 'dropdown'
					}}
					minDate={minDate}
					disableToolbar
					variant="inline"
					label={label}
					value={value}
					autoOk={true}
					format="MMMM d, yyyy"
					onChange={(e: any) => input.onChange(e)}
				/>
			</MuiPickersUtilsProvider>
		</div>
	)
}

const renderTextField = ({
	input,
	label,
	InputProps,
	meta: { touched, error },
	...custom
}: any) => (
	<TextField
		label={label}
		hintText={label}
		floatingLabelText={label}
		error={touched && error}
		helperText={error}
		{...input}
		{...custom}
		onKeyDown={(e: ChangeEvent<HTMLInputElement> | KeyboardEvent) => {
			const disableSymbols =
				isMinusKeyPressed(e as KeyboardEvent) ||
				isCommaKeyPressed(e as KeyboardEvent) ||
				isDotKeyPressed(e as KeyboardEvent)

			if (custom.type === NUMBER_TYPE) {
				if (isDisabledKeyPressed(e as KeyboardEvent) || disableSymbols) {
					e.preventDefault()
				}
			}
		}}
		InputProps={InputProps}
		onWheel={(event: ChangeEvent<HTMLInputElement>) => event.target.blur()}
	/>
)

const renderRadioField = ({
	input,
	label,
	InputProps,
	meta: { touched, error },
	...custom
}: any) => (
	<RadioGroupTSX
		label={label}
		hintText={label}
		floatingLabelText={label}
		error={touched && error}
		helperText={error}
		{...input}
		{...custom}
		InputProps={InputProps}
	/>
)

const renderCheckboxField = ({
	input,
	label,
	InputProps,
	meta: { touched, error },
	checked,
	...custom
}: any) => (
	<CheckboxTSX
		color="primary"
		checked={checked}
		label={label}
		hintText={label}
		floatingLabelText={label}
		error={touched && error}
		helperText={error}
		{...input}
		{...custom}
		InputProps={InputProps}
	/>
)

const renderSelectedPdfOption = (
	selectedPdfOption: string,
	change: (name: string, value: number | string | null) => void
) => {
	return (
		<ReduxFormField
			name={PDF}
			component={renderSelectFieldTouched}
			label={getString('2D_OPTION_LABEL')}
			custom={{
				value: selectedPdfOption || 0,
				onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
					change(PDF, e.target.value)
				}
			}}
		>
			{featuresOptions.map(featureOption => {
				return (
					<MenuItem key={featureOption.value} value={featureOption.value}>
						{featureOption.name}
					</MenuItem>
				)
			})}
		</ReduxFormField>
	)
}

const renderSelectedMetaDataOption = (
	selectedMetaDataOption: string,
	change: (name: string, value: number | string | null) => void
) => {
	return (
		<ReduxFormField
			name={META_DATA}
			component={renderSelectFieldTouched}
			label={getString('META_DATA_OPTION_LABEL')}
			custom={{
				value: selectedMetaDataOption || 0,
				onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
					change(META_DATA, e.target.value)
				}
			}}
		>
			{featuresOptions.map(featureOption => {
				return (
					<MenuItem key={featureOption.value} value={featureOption.value}>
						{featureOption.name}
					</MenuItem>
				)
			})}
		</ReduxFormField>
	)
}

const renderSelectedCommunicationToolFieldOption = (
	selectedCommunicationToolFieldOption: string | number,
	change: (name: string, value: number | string | null) => void,
	isCommunicationTool: boolean,
	name: string,
	label: string,
	defaultValue: number,
	isDisabled?: boolean
) => {
	return (
		<ReduxFormField
			name={name}
			component={renderSelectFieldTouched}
			label={label}
			disabled={isDisabled || !isCommunicationTool}
			custom={{
				value:
					!selectedCommunicationToolFieldOption &&
					selectedCommunicationToolFieldOption !== 0
						? defaultValue
						: selectedCommunicationToolFieldOption,
				onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
					change(name, e.target.value)
				}
			}}
		>
			{featuresOptions.map(featureOption => {
				return (
					<MenuItem key={featureOption.value} value={featureOption.value}>
						{featureOption.name}
					</MenuItem>
				)
			})}
		</ReduxFormField>
	)
}

const AdminLicenseCreator: FC<InjectedFormProps<IFormData, any> & any> = ({
	valid,
	...props
}) => {
	const [draggingFiles, setDragging] = useState<boolean>(false)
	const dispatch = useDispatch()
	const { loading, file, error, preparedToSend } = useSelector(
		(state: RootStateOrAny) => state.AdminLicenseCreatorReducer
	)
	const [defaultOwnOrganizationScenario, setDefaultOwnOrganizationScenario] =
		useState<string | organizationByEnvScenario>(
			organizationByEnvScenario.BY_USER
		)

	const [defaultLicenseScenario, setDefaultLicenseScenario] = useState<
		string | licenseScenario
	>(licenseScenario.NEW)

	const [isUnlimitedLightUsers, setLightUsersSelected] =
		useState<boolean>(false)

	const [isCommunicationTool, setCommunicationToolSelected] =
		useState<boolean>(true)

	const [isSmtp, setSmtpSelected] = useState<boolean>(false)

	const formValues = props.formValues

	useEffect(() => {
		props.change(smtpFields.SERVER, '')
		props.change(smtpFields.USER, '')
		props.change(smtpFields.PASSWORD, '')
		props.change(smtpFields.EMAIL, '')
		props.change(smtpFields.TCP_PORT, '')
	}, [isSmtp])

	useEffect(() => {
		if (isUnlimitedLightUsers) {
			props.change('allowedLightUsers', '')
		}
	}, [isUnlimitedLightUsers])

	useEffect(() => {
		if (!isCommunicationTool) {
			setNoChangeCommunicationToolsFields()
		}
		if (isCommunicationTool) {
			setDefaultCommunicationToolsFields()
		}
	}, [isCommunicationTool])

	const handleFileReader = (files: any) => {
		let reader = new FileReader()
		if (files[0]) {
			reader.readAsDataURL(files[0])
			reader.onload = () => {
				dispatch(setFile(files, reader?.result))
			}
		}
	}

	const onChangeRadioButtonOwnOrganizationScenario = (value: string) => {
		setDefaultOwnOrganizationScenario(value)
	}

	const onChangeRadioButtonLicenseScenario = (value: string) => {
		setDefaultLicenseScenario(value)
		if (value === licenseScenario.NEW) {
			setDefaultOwnOrganizationScenario(organizationByEnvScenario.BY_USER)
			setDefaultCommunicationToolsFields()
		}
		if (value === licenseScenario.RENEW) {
			setDefaultOwnOrganizationScenario(organizationByEnvScenario.NO_CHANGE)
			setNoChangeCommunicationToolsFields()
		}
	}

	const onChangeLightUsersCheckbox = (value: boolean) => {
		setLightUsersSelected(value)
	}

	const onChangeCommunicationToolCheckbox = (value: boolean) => {
		setCommunicationToolSelected(value)
	}

	const onChangeSmtpCheckbox = (value: boolean) => {
		setSmtpSelected(value)
	}

	const setDefaultCommunicationToolsFields = () => {
		props.change(
			communicationToolOptions.MATERIAL_TYPE.name,
			communicationToolOptions.MATERIAL_TYPE.defaultValue
		)
		props.change(
			communicationToolOptions.MATERIAL_CATEGORY.name,
			communicationToolOptions.MATERIAL_CATEGORY.defaultValue
		)
		props.change(
			communicationToolOptions.ORIGINAL_MATERIAL.name,
			communicationToolOptions.ORIGINAL_MATERIAL.defaultValue
		)
		props.change(
			communicationToolOptions.PRODUCTION_QUANTITY.name,
			communicationToolOptions.PRODUCTION_QUANTITY.defaultValue
		)
		props.change(
			communicationToolOptions.STANDARD_COST.name,
			communicationToolOptions.STANDARD_COST.defaultValue
		)
		props.change(
			communicationToolOptions.TOLERANCE.name,
			communicationToolOptions.TOLERANCE.defaultValue
		)
		props.change(
			communicationToolOptions.PRINTING_STANDARDS.name,
			communicationToolOptions.PRINTING_STANDARDS.defaultValue
		)
	}

	const setNoChangeCommunicationToolsFields = () => {
		props.change(communicationToolOptions.MATERIAL_TYPE.name, 0)
		props.change(communicationToolOptions.MATERIAL_CATEGORY.name, 0)
		props.change(communicationToolOptions.ORIGINAL_MATERIAL.name, 0)
		props.change(communicationToolOptions.PRODUCTION_QUANTITY.name, 0)
		props.change(communicationToolOptions.STANDARD_COST.name, 0)
		props.change(communicationToolOptions.TOLERANCE.name, 0)
		props.change(communicationToolOptions.PRINTING_STANDARDS.name, 0)
	}

	return (
		<div className="upload-script">
			<NavBarAndMaterial title={getString('ADMIN_PANEL_LICENSE_CREATOR_TITLE')}>
				<div className="upload-script-body">
					<HeaderCard
						cardTitle={getString('ADMIN_PANEL_LICENSE_CREATOR_TITLE')}
						cardSubtitle=""
						headerColor={'green'}
						content={
							<div>
								<br />
								<p>{getString('GENERATE_LICENSE_TITLE')}</p>
								<form className="upload-script-form" autoComplete="off">
									<div>
										<ReduxFormField
											name="isNewLicenseScenario"
											class="radio-button"
											value={defaultLicenseScenario}
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												onChangeRadioButtonLicenseScenario(e.target.value)
											}
											component={renderRadioField}
											type="radio"
										>
											<FormControlLabelTSX
												value={licenseScenario.NEW}
												control={
													<CastorRadio className="step2-radio-button--radio" />
												}
												label={getString('NEW_LICENSE_OPTION')}
												classes={{
													root: `analysis-settings-radio-button--label`
												}}
											/>
											<FormControlLabelTSX
												value={licenseScenario.RENEW}
												control={
													<CastorRadio className="step2-radio-button--radio" />
												}
												label={getString('RENEW_LICENSE_OPTION')}
												classes={{
													root: `analysis-settings-radio-button--label`
												}}
											/>
										</ReduxFormField>
									</div>
									<div>
										<ReduxFormField
											name="startDate"
											component={renderTimePicker}
											label={getString('START_DATE')}
											type="date"
										/>
									</div>
									<div>
										<ReduxFormField
											name="expireDate"
											component={renderTimePicker}
											label={getString('EXPIRE_DATE_TITLE')}
											type="date"
											minDate={formValues?.['startDate']}
										/>
									</div>

									<div>
										<ReduxFormField
											className="upload-script-field with-error-absolute"
											name="allowedUsers"
											component={renderTextField}
											label={getString('ALLOWED_USERS_TITLE')}
											type="number"
										/>
									</div>

									<div>
										<ReduxFormField
											className="upload-script-field with-error-absolute"
											name="allowedAdministratorUsers"
											component={renderTextField}
											label={getString('ALLOWED__ADMINISTRATOR_USERS_TITLE')}
											type="number"
										/>
									</div>
									<div>
										<ReduxFormField
											className="upload-script-field"
											name="administratorEmails"
											component={renderTextField}
											label={getString('ADMINISTRATOR_EMAILS_TITLE')}
											type="text"
										/>
									</div>

									<Flexbox className="radio-and-field" alignItems="center">
										<Flexbox
											className="radio-and-field-checkbox"
											alignItems="center"
										>
											<ReduxFormField
												name="isUnlimitedLightUsers"
												component={renderCheckboxField}
												onChange={(e: ChangeEvent<HTMLInputElement>) =>
													onChangeLightUsersCheckbox(e.target.checked)
												}
												type="checkbox"
											/>
											<div>{getString('UNLIMITED_LIGHT_USERS')}</div>
										</Flexbox>
										<div className="radio-and-field-field">
											<ReduxFormField
												className="upload-script-field with-error-absolute"
												name="allowedLightUsers"
												component={renderTextField}
												label={getString('ALLOWED__LIGHT_USERS_TITLE')}
												type="number"
												disabled={isUnlimitedLightUsers}
											/>
										</div>
									</Flexbox>

									<div>
										<ReduxFormField
											className="upload-script-field with-error-absolute"
											name="totalParts"
											component={renderTextField}
											label={getString('TOTAL_PARTS_TITLE')}
											type="number"
										/>
									</div>

									<div>
										{renderSelectedPdfOption(formValues?.pdf, props.change)}
									</div>

									<div>
										{renderSelectedMetaDataOption(
											formValues?.metaData,
											props.change
										)}
									</div>

									<div>
										<Flexbox
											className="radio-and-field-checkbox"
											alignItems="center"
										>
											<ReduxFormField
												name="isCommunicationTool"
												component={renderCheckboxField}
												onChange={(e: ChangeEvent<HTMLInputElement>) =>
													onChangeCommunicationToolCheckbox(e.target.checked)
												}
												type="checkbox"
											/>
											<div>
												{getString('COMMUNICATION_TOOL_ON_TITLE_CHECKBOX')}
											</div>
										</Flexbox>
									</div>

									{isCommunicationTool ? (
										<>
											<div className="sub-section">
												{renderSelectedCommunicationToolFieldOption(
													formValues?.communicationToolMaterialType,
													props.change,
													isCommunicationTool,
													communicationToolOptions.MATERIAL_TYPE.name,
													getString('COMMUNICATION_TOOL_MATERIAL_TYPE_LABEL'),
													communicationToolOptions.MATERIAL_TYPE.defaultValue,
													true
												)}
											</div>

											<div className="sub-section">
												{renderSelectedCommunicationToolFieldOption(
													formValues?.communicationToolMaterialCategory,
													props.change,
													isCommunicationTool,
													communicationToolOptions.MATERIAL_CATEGORY.name,
													getString(
														'COMMUNICATION_TOOL_MATERIAL_CATEGORY_LABEL'
													),
													communicationToolOptions.MATERIAL_CATEGORY
														.defaultValue,
													true
												)}
											</div>

											<div className="sub-section">
												{renderSelectedCommunicationToolFieldOption(
													formValues?.communicationToolOriginalMaterial,
													props.change,
													isCommunicationTool,
													communicationToolOptions.ORIGINAL_MATERIAL.name,
													getString(
														'COMMUNICATION_TOOL_ORIGINAL_MATERIAL_LABEL'
													),
													communicationToolOptions.ORIGINAL_MATERIAL
														.defaultValue
												)}
											</div>

											<div className="sub-section">
												{renderSelectedCommunicationToolFieldOption(
													formValues?.communicationToolProductionQuantity,
													props.change,
													isCommunicationTool,
													communicationToolOptions.PRODUCTION_QUANTITY.name,
													getString(
														'COMMUNICATION_TOOL_PRODUCTION_QUANTITY_LABEL'
													),
													communicationToolOptions.PRODUCTION_QUANTITY
														.defaultValue
												)}
											</div>

											<div className="sub-section">
												{renderSelectedCommunicationToolFieldOption(
													formValues?.communicationToolStandardCost,
													props.change,
													isCommunicationTool,
													communicationToolOptions.STANDARD_COST.name,
													getString('COMMUNICATION_TOOL_STANDARD_COST_LABEL'),
													communicationToolOptions.STANDARD_COST.defaultValue
												)}
											</div>

											<div className="sub-section">
												{renderSelectedCommunicationToolFieldOption(
													formValues?.communicationToolTolerance,
													props.change,
													isCommunicationTool,
													communicationToolOptions.TOLERANCE.name,
													getString('COMMUNICATION_TOOL_TOLERANCE_LABEL'),
													communicationToolOptions.TOLERANCE.defaultValue
												)}
											</div>

											<div className="sub-section">
												{renderSelectedCommunicationToolFieldOption(
													formValues?.communicationToolPrintingStandards,
													props.change,
													isCommunicationTool,
													communicationToolOptions.PRINTING_STANDARDS.name,
													getString(
														'COMMUNICATION_TOOL_PRINTING_STANDARDS_LABEL'
													),
													communicationToolOptions.PRINTING_STANDARDS
														.defaultValue
												)}
											</div>
										</>
									) : (
										<></>
									)}

									<div>
										<Flexbox
											className="radio-and-field-checkbox"
											alignItems="center"
										>
											<ReduxFormField
												name="isSmtp"
												component={renderCheckboxField}
												onChange={(e: ChangeEvent<HTMLInputElement>) =>
													onChangeSmtpCheckbox(e.target.checked)
												}
												type="checkbox"
											/>
											<div>{getString('SMTP_TITLE_CHECKBOX')}</div>
										</Flexbox>
									</div>

									{isSmtp ? (
										<>
											<div className="sub-section">
												<ReduxFormField
													className="upload-script-field"
													name={smtpFields.SERVER}
													component={renderTextField}
													label={getString('ADMIN_SMTP_SERVER_LABEL')}
													type="text"
												/>
											</div>

											<div className="sub-section">
												<ReduxFormField
													className="upload-script-field"
													name={smtpFields.USER}
													component={renderTextField}
													label={getString('ADMIN_SMTP_USER_LABEL')}
													type="text"
												/>
											</div>
											<div className="sub-section">
												<ReduxFormField
													className="upload-script-field password"
													name={smtpFields.PASSWORD}
													component={renderTextField}
													label={getString('ADMIN_SMTP_PASSWORD_LABEL')}
													type="text"
												/>
											</div>
											<div className="sub-section">
												<ReduxFormField
													className="upload-script-field"
													name={smtpFields.EMAIL}
													component={renderTextField}
													label={getString('ADMIN_SMTP_FROM_EMAIL_LABEL')}
													type="text"
												/>
											</div>
											<div className="sub-section">
												<ReduxFormField
													className="upload-script-field"
													name={smtpFields.TCP_PORT}
													component={renderTextField}
													label={getString('ADMIN_SMTP_TCP_PORT_LABEL')}
													type="number"
												/>
											</div>
										</>
									) : (
										<></>
									)}
									<div>
										<ReduxFormField
											name="isOrganizationByEnvScenario"
											class="radio-button"
											value={defaultOwnOrganizationScenario}
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												onChangeRadioButtonOwnOrganizationScenario(
													e.target.value
												)
											}
											component={renderRadioField}
											type="radio"
										>
											{defaultLicenseScenario === licenseScenario.RENEW ? (
												<FormControlLabelTSX
													value={organizationByEnvScenario.NO_CHANGE}
													control={
														<CastorRadio className="step2-radio-button--radio" />
													}
													label={getString('ORGANIZATION_NO_CHANGE_OPTION')}
													classes={{
														root: `analysis-settings-radio-button--label`
													}}
												/>
											) : (
												<></>
											)}
											<FormControlLabelTSX
												value={organizationByEnvScenario.BY_USER}
												control={
													<CastorRadio className="step2-radio-button--radio" />
												}
												label={getString('ORGANIZATION_BY_USER_OPTION')}
												classes={{
													root: `analysis-settings-radio-button--label`
												}}
											/>
											<FormControlLabelTSX
												value={organizationByEnvScenario.BY_ENV}
												control={
													<CastorRadio className="step2-radio-button--radio" />
												}
												label={getString('ORGANIZATION_BY_ENV_OPTION')}
												classes={{
													root: `analysis-settings-radio-button--label`
												}}
											/>
										</ReduxFormField>
									</div>
									{defaultOwnOrganizationScenario ===
									organizationByEnvScenario.BY_ENV ? (
										<>
											<div className="sub-section">
												<ReduxFormField
													className="upload-script-field"
													name="organizationName"
													component={renderTextField}
													label={getString('ORGANIZATION_NAME_TITLE')}
													type="text"
												/>
											</div>
											<div className="sub-section">
												<ReduxFormField
													className="upload-script-field"
													name="organizationOwners"
													component={renderTextField}
													label={getString('ORGANIZATION_OWNERS_TITLE')}
													type="text"
												/>
											</div>
										</>
									) : (
										<></>
									)}
									<br />
									<br />
									<Dropzone
										accept=".zip"
										id="upload-script"
										onDrop={(newFile: any) => {
											handleFileReader(newFile)
											setDragging(false)
										}}
										multiple={false}
										className={
											draggingFiles
												? 'upload-script-dropzoneDrag'
												: 'upload-script-dropzone'
										}
										onDragEnter={() => setDragging(true)}
										onDragLeave={() => setDragging(false)}
									>
										<Flexbox
											flexDirection="column"
											className="upload-script-dropzone__flex"
										>
											<UploadIconTSX className="upload-script-dropzone__icon" />
											<h3 style={{ margin: 0 }}>
												{isEmpty(file) ? DROP_DATABASE_FILE : `${file?.name}`}
											</h3>
											<br />
										</Flexbox>
									</Dropzone>
									<div className="upload-script-error">{error}</div>
									<ButtonWithLoader
										id="upload-project-upload-button"
										className="upload-script-button"
										loading={loading}
										primary={true}
										disabled={
											isEmpty(preparedToSend) || isEmpty(file) || !valid
										}
										onClick={() =>
											dispatch(onUploadFile(preparedToSend, formValues))
										}
									>
										{CREATE_FILE_FOR_LICENSE}
									</ButtonWithLoader>
								</form>
							</div>
						}
					/>
				</div>
			</NavBarAndMaterial>
		</div>
	)
}

export default memo(
	compose(
		connect((state: any, props: any) => {
			const formValues = getFormValues('AdminLicenseCreatorForm')(state)
			const formErrors = getFormSyncErrors(`AdminLicenseCreatorForm`)(state)
			return {
				form: `AdminLicenseCreatorForm`,
				formValues,
				formErrors
			}
		}),
		reduxForm<IFormData, any>({
			form: 'AdminLicenseCreatorForm',
			validate,
			initialValues: {
				startDate: new Date(),
				expireDate: new Date(),
				allowedUsers: null,
				totalParts: null,
				isUnlimitedLightUsers: false,
				isCommunicationTool: true,
				isSmtp: false,
				metaData: 0,
				pdf: 0,
				communicationToolMaterialType:
					communicationToolOptions.MATERIAL_TYPE.defaultValue,
				communicationToolMaterialCategory:
					communicationToolOptions.MATERIAL_CATEGORY.defaultValue,
				communicationToolOriginalMaterial:
					communicationToolOptions.ORIGINAL_MATERIAL.defaultValue,
				communicationToolProductionQuantity:
					communicationToolOptions.PRODUCTION_QUANTITY.defaultValue,
				communicationToolStandardCost:
					communicationToolOptions.STANDARD_COST.defaultValue,
				communicationToolTolerance:
					communicationToolOptions.TOLERANCE.defaultValue,
				communicationToolPrintingStandards:
					communicationToolOptions.PRINTING_STANDARDS.defaultValue,
				administratorEmails: ''
			}
		})
	)(AdminLicenseCreator)
)
