import { OrganizationFunctionString } from 'Services/models/IFunctionString'
import { IFunctionStringParam } from 'Services/models/IFunctionStringParam'

export enum CostingFunctionSteps {
	SelectFunctionAndTechnology = '1',
	CreateFunction = '2',
	ViewAndEdit = '3'
}

export enum VariableType {
	operator = 'operator',
	variable = 'variable',
	number = 'number',
	customVariable = 'customVariable'
}

export interface IFunctionVariable {
	type: VariableType
	name: string
	userReadableName: string
	value: string | null
}

export type IFunctionVariableExtended = IFunctionVariable & IFunctionStringParam

export interface ICustomFunction extends OrganizationFunctionString {
	userReadableName: string
}

export enum FunctionCategories {
	productionCost = 'COST_PER_PART',
	cncCost = 'CNC_CALCULATE_PRICE',
	moldCost = 'MOLD_CALCULATE_PRICE',
	optionalPostProcessCost = 'OPTIONAL_PP_TOTAL_ADDITIONAL_PRICE_PER_PART',
	cncPostProcessCost = 'CNC_PART_PP_SURFACE_AREA_MACHINING_PRICE_PER_PART',
	optionalPostProcessLeadTime = 'OPTIONAL_PP_TOTAL_ADDITIONAL_LEAD_TIME',
	cncSurfaceAreaPostProcessLeadTime = 'CNC_PART_PP_SURFACE_AREA_MACHINING_ADDITIONAL_LEAD_TIME',
	cncSupportRemovalPostProcessLeadTime = 'CNC_PART_PP_SUPPORT_REMOVAL_ADDITIONAL_LEAD_TIME'
}
export interface IFunctionCategory {
	name: FunctionCategories
	userReadableName: string
}
