import { Action } from 'global actions/ActionModels'
import { SelectProject } from 'Scenes/Home/Customize/CustomizeRecalculateProjects/CustomizeRecalculateProjectsInterface'
import { prepareDataID } from 'Scenes/Home/Customize/CustomizeRecalculateProjects/CustomizeRecalculateProjectsService'
import {
	CHANGE_ALL_PROJECTS_DATA,
	CHANGE_PROJECTS_DATA,
	CHANGE_RADIO_BUTTON_DEEP_RESET,
	CLEAR_ALL_PROJECTS_DATA,
	GET_ALL_PROJECTS_DATA,
	UPDATE_PROJECTS_DATA,
	UPDATE_PROJECTS_DATA_ERROR,
	UPDATE_PROJECTS_DATA_SUCCESS
} from 'Scenes/Home/Customize/CustomizeRecalculateProjects/CustomizeRecalculateProjectsTypes'
import { getString } from 'Services/Strings/StringService'

export interface CustomizeRecalculateInitialState {
	readonly preparedProjects: Array<SelectProject>
	readonly selectedProjects: Array<string>
	readonly error: string
	readonly isLoading: boolean
	readonly deepReset: string
}

const initialState: CustomizeRecalculateInitialState = {
	preparedProjects: [],
	selectedProjects: [],
	error: '',
	isLoading: false,
	deepReset: '0'
}

const CustomizeRecalculateProjectsReducer = (
	state = initialState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case GET_ALL_PROJECTS_DATA:
			return {
				...state,
				preparedProjects: payload.projectData
			}

		case CHANGE_RADIO_BUTTON_DEEP_RESET:
			return {
				...state,
				deepReset: payload.deepReset
			}
		case CHANGE_ALL_PROJECTS_DATA:
		case CHANGE_PROJECTS_DATA:
			const selected = prepareDataID(payload.projectData)

			return {
				...state,
				preparedProjects: payload.projectData,
				selectedProjects: selected
			}
		case CLEAR_ALL_PROJECTS_DATA: {
			return { ...initialState }
		}
		case UPDATE_PROJECTS_DATA_SUCCESS:
			return { ...state, isLoading: false }
		case UPDATE_PROJECTS_DATA:
			return { ...state, isLoading: true }

		case UPDATE_PROJECTS_DATA_ERROR:
			return {
				...state,
				error: getString('SOMETHING_WENT_WRONG'),
				isLoading: false
			}
		default:
			return state
	}
}

export default CustomizeRecalculateProjectsReducer
