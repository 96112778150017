import React, { ChangeEvent, InputHTMLAttributes, KeyboardEvent } from 'react'

import TextField from '@material-ui/core/TextField'

import {
	isBackSpaceKeyPressed,
	isCopyPasteKeyPressed,
	isDotKeyPressed
} from '../../../../Services/getKeyCodesService'
import InfoBox from '../../../Components/InfoBox'

import 'Scenes/Components/MaterialForm/index.scss'

const TextFieldTSX: any = TextField

export const disableEvent = (e: ChangeEvent<HTMLInputElement>) => {
	e.preventDefault()
}

export const replaceNonNumber = (value: string) => value.replace(/[^0-9.]/g, '')

export const detectNonNumberValue = (e: KeyboardEvent) => {
	const key: string = e.key
	//allow only numbers
	const regex = /[0-9]|/
	//allow BackSpace and Dot
	return (
		!regex.test(key) &&
		!isBackSpaceKeyPressed(e) &&
		!isDotKeyPressed(e) &&
		!isCopyPasteKeyPressed(e)
	)
}

interface INumberField {
	input: InputHTMLAttributes<HTMLInputElement>
	label?: string
	meta: {
		error?: boolean
	}
	initialized: boolean
	iIcon?: string
	className?: string
	shrink?: boolean
	disabled?: boolean
	extraData?: Record<string, any>
}

export const renderNumberField = ({
	input,
	label,
	meta: { error },
	initialized,
	iIcon,
	className,
	shrink,
	disabled,
	extraData
}: INumberField) => {
	if (iIcon && extraData) {
		iIcon = iIcon[Number(extraData.iIconShow)]
		if (extraData.firstValue && extraData.secondValue) {
			iIcon = iIcon.format(extraData.firstValue, extraData.secondValue)
		}
	}
	return (
		<div className="flex-block admin-form-with-info">
			<TextFieldTSX
				data-qa={`data-qa-text-filed-${label}`}
				error={initialized && error}
				helperText={error}
				label={label}
				className={className}
				value={input?.value}
				onChange={(e: ChangeEvent<HTMLInputElement>) => {
					const value: string = replaceNonNumber(e.target.value)
					// @ts-ignore
					input?.onChange(value)
				}}
				onWheel={(event: ChangeEvent<HTMLInputElement>) => event.target.blur()}
				InputLabelProps={
					shrink
						? {
								shrink: true
						  }
						: {}
				}
				onKeyDown={(e: KeyboardEvent) =>
					detectNonNumberValue(e) && e.preventDefault()
				}
				disabled={disabled}
			/>
			{iIcon && (
				<InfoBox
					boxContact={iIcon}
					iconClassName="admin-form-field__info_icon"
					boxDirection="right-start"
				/>
			)}
		</div>
	)
}
