import { Feature, FeatureComponentId } from 'Services/models/Features'

export const printLeadTimeBreakDown = (
  configurationName: string,
  tmPrintingLeadTimeBreakDown: {},
  amPrintingLeadTimeBreakDown: {}
) => {
  const consoleQA = Feature.isFeatureOn(FeatureComponentId.CONSOLE_QA)
  if (!consoleQA) {
    return
  }

  const leadTimeCalculationParametersName = [
    'manufacturingTimePerUnit',
    'partVolume',
    'operationDaysPerWeek',
    'operationHoursPerDay',
    'partProductionCost',
    'eoq',
    'moq',
    'partsPerTray',
    'quantity',
    'packingSize',
    'partComplexityScore'
  ]
  console.table(
    {
      [`${configurationName}: TM lead time`]: tmPrintingLeadTimeBreakDown,
      [`${configurationName}: AM lead time`]: amPrintingLeadTimeBreakDown
    },
    leadTimeCalculationParametersName
  )
}
