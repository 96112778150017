import Flexbox from 'Scenes/Components/FlexBox'
import React, { FC, memo } from 'react'
import { NavLink } from 'react-router-dom'

import MaterialBreadcrumbs from '@material-ui/core/Breadcrumbs'
import cx from 'classnames'

import SmallTooltip from 'Scenes/Components/SmallTooltip'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { NAV_TITLE_HOME } from 'Services/Strings'
import { generateKey } from 'Services/Utils/uniqKeyGenerator'

import './Breadcrumbs.scss'

const NavLinkTSX: any = NavLink

const MAX_TEXT_COUNT = 19

interface IProps {
	crumbs: any
}

const renderClusterAnalysis = (
	clusterPath: any,
	index: number,
	path: string,
	name: string,
	disableLink: boolean
) => {
	const linkTo = disableLink ? '#' : clusterPath?.folderPath || path

	return (
		<Flexbox key={path}>
			<NavLinkTSX
				className={cx('castor-breadcrumbs--link', {
					disable: disableLink
				})}
				key={generateKey(index)}
				to={linkTo}
			>
				<div>
					<SmallTooltip
						rootClass="tooltip-title"
						name={clusterPath?.folderName || name}
						maxTextCount={MAX_TEXT_COUNT}
					/>
				</div>
			</NavLinkTSX>
			<span style={{ margin: '0 10px' }}>›</span>
			<div className="castor-breadcrumbs--last" key={generateKey(index)}>
				<SmallTooltip
					rootClass="tooltip-title"
					name={name}
					maxTextCount={MAX_TEXT_COUNT}
				/>
			</div>
		</Flexbox>
	)
}

const renderCrumb = (
	index: number,
	crumbsLength: number,
	name: string,
	component: any,
	path: string,
	views: any
) => {
	const viewPath = views?.find((view: any) => view.path === path)
	const clusterPath = views?.find(
		(view: any) => path.includes(view.path) && view.clusterRoute
	)
	const lastItem = index === crumbsLength - 1
	const disableLink =
		name === NAV_TITLE_HOME &&
		!Feature.isFeatureActive(FeatureComponentId.UPLOAD_PROJECT_PAGE_REDIRECTION)
	const linkTo = disableLink ? '#' : viewPath?.folderPath || path

	if (clusterPath && lastItem) {
		return renderClusterAnalysis(clusterPath, index, path, name, disableLink)
	}

	if (lastItem) {
		return (
			<div className="castor-breadcrumbs--last" key={generateKey(index)}>
				<SmallTooltip
					rootClass="tooltip-title"
					name={name}
					maxTextCount={MAX_TEXT_COUNT}
				/>
			</div>
		)
	}
	if (!component) {
		return <div className="castor-breadcrumbs--empty" key={generateKey(index)}>{name} </div>
	}
	return (
		<NavLinkTSX
			className={cx('castor-breadcrumbs--link', {
				disable: disableLink
			})}
			key={generateKey(index)}
			to={linkTo}
		>
			<div>
				<SmallTooltip
					rootClass="tooltip-title"
					name={viewPath?.folderName || name}
					maxTextCount={MAX_TEXT_COUNT}
				/>
			</div>
		</NavLinkTSX>
	)
}

const Breadcrumbs: FC<IProps> = ({ crumbs }) => {
	if (crumbs.length <= 1) {
		return <div />
	}
	return (
		<MaterialBreadcrumbs
			separator="›"
			aria-label="breadcrumb"
			className="castor-breadcrumbs"
		>
			{crumbs.map(
				(
					{
						name,
						path,
						component,
						views
					}: { name: string; path: string; component: any; views: any },
					key: number
				) => renderCrumb(key, crumbs.length, name, component, path, views)
			)}
		</MaterialBreadcrumbs>
	)
}

export default WithFeatureToggleHOC(
	memo(Breadcrumbs),
	FeatureComponentId.BREADCRUMBS
)
