import React, { useEffect } from 'react'
import NumberField from 'Scenes/Components/NumberField'
import cx from 'classnames'
import { Danger } from 'Scenes/Components/thirdParty/CreativeTim/components'
import './Customize2DDrawingCost.scss'
import {
  onUpdateDrawingData,
  useRenderValues
} from './Customize2DDrawingCostService'

interface IProps {
  styles: any
  drawingKey: string
  errorMessage: string
  valueInput: number
  minValue: number
  maxValue: number
  isEdit: boolean
  isApply: boolean
  isDefault: boolean
  editDrawingData: Function
  updateDrawingData: Function
}

const Customize2DDrawingCostInput: React.FC<IProps> = ({
  styles,
  drawingKey,
  errorMessage,
  valueInput,
  maxValue,
  minValue,
  isEdit,
  isApply,
  isDefault,
  editDrawingData,
  updateDrawingData
}) => {
  const { numberValue, placeholder, isError, setNumberValue, setIsError } =
    useRenderValues(valueInput, isDefault)

  useEffect(() => {
    if (isApply === true) {
      onUpdateDrawingData(numberValue / 100, drawingKey, updateDrawingData)
    }
  }, [isApply])

  const onEditInputUser = (value: number) => {
    if (value < minValue || value > maxValue) {
      setIsError(true)
    } else {
      setIsError(false)
    }
    setNumberValue(value)
    editDrawingData(value / 100, drawingKey)
  }

  const renderAlertMessage = (alert: string) => {
    return <Danger style={{ textAlign: 'left' }}>{alert}</Danger>
  }

  if (!isEdit) {
    return (
      <div style={styles} className={cx('drawing__field')}>
        <span>{placeholder}</span>
      </div>
    )
  }
  return (
    <>
      <NumberField
        inputClass="drawing__input-field"
        value={numberValue}
        onChangeValue={(value: number) => {
          onEditInputUser(value)
        }}
        step={1}
        allowEmpty={true}
        allowZero={false}
        isDotDisabled={false}
        isCommaDisabled={false}
        showArrows={true}
      />
      {isError && renderAlertMessage(errorMessage)}
    </>
  )
}

export default Customize2DDrawingCostInput
