import { getString } from '../../../../../../../../../Services/Strings/StringService'
import { ILeadTimeDetails } from './LeadTimeInterface'

const FIRST_SHIPMENT = 'firstShipments'
const leadTimeInfo: any = {
  firstShipments: 'PARTS_PER_SHIPMENT',
  firstLeadTime: 'PARTS_FIRST_LEAD_TIME',
  deliveryTimeForEachBatch: 'PARTS_DELIVERY_TIME',
  lastDay: 'PARTS_LAST_SHIPMENT_DAY'
}

export const generateLeadTimeInformation = (
  leadTimeDetails: ILeadTimeDetails | any
) => {
  if (!leadTimeDetails) {
    return ''
  }

  const getTimeInfo = Object.keys(leadTimeDetails)
    .map((key: string) => {
      if (!leadTimeInfo[key]) return ''
      const showPartsText = key === FIRST_SHIPMENT

      return `<p>
      <span class='small'>
        ${leadTimeDetails[key]}&nbsp;
        ${getString(showPartsText ? 'PARTS' : 'CONFIGURATION_RESULTS_DAYS')}
      </span>
      <span>${getString(leadTimeInfo[key])}</span>
      </p>`
    })
    .join('')

  return `<div>
    ${getTimeInfo}
  </div>`
}
