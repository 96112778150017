import isArray from 'lodash/isArray'

import { IN_HOUSE_PRINTERS } from './Constants'
import { IFilter } from 'Services/models/IFilter'
import { IUserFilter } from 'Services/models/IUserFilter'

export class UserFilterService {
	constructor() {}

	getFilterCheckboxes = (
		filters: IFilter[],
		userFilters: IUserFilter[],
		defaultFilters: any = {}
	) => {
		const filterToggles: any = {}
		userFilters.map(userFilter => {
			const filter = filters.find(f => f.name === userFilter.name)
			filterToggles[userFilter.name] =
				defaultFilters[userFilter.name] || userFilter?.on || filter?.mandatory
		})
		return filterToggles
	}

	getFilterValues = (filters: IFilter[], userFilters: IUserFilter[]) => {
		const filterValues: any = {}
		userFilters.map(userFilter => {
			const filter = filters.find(f => f.name === userFilter.name)
			switch (filter?.type) {
				case 'boolean':
					filterValues[userFilter.name] =
						userFilter?.on &&
						(userFilter?.value == '1' || userFilter?.value === 'true')
					break
				case 'array':
					const filterValue = userFilter?.value
					// should be selected all
					if (filterValue === null) {
						filterValues[userFilter.name] = filterValue
					} else {
						filterValues[userFilter.name] = filterValue?.includes(',')
							? filterValue?.split(',')
							: isArray(filterValue)
							? filterValue
							: [filterValue]
					}

					break
				default:
					filterValues[userFilter.name] = userFilter?.value
					break
			}
		})
		return filterValues
	}

	initializeFilters = (filters: IFilter[], userInHouseFilter?: IUserFilter) => {
		const userFilters: IUserFilter[] = []
		filters &&
			filters
				.filter(filter => !filter.viewOnly)
				.map(filter => {
					const userFilter: IUserFilter = {
						name: filter.name,
						on: false,
						value: null
					}
					if (filter.showAsDefault) {
						userFilter.on = true
						userFilter.value =
							typeof filter.type === 'boolean'
								? !!filter.defaultValue
								: filter.defaultValue
					}
					if (userInHouseFilter && userFilter.name === IN_HOUSE_PRINTERS) {
						userFilter.on = userInHouseFilter.on
					}
					userFilters.push(userFilter)
				})
		return userFilters
	}

	getUserFilters = (filters: IFilter[], userFilter: IUserFilter[]) => {
		const userFilters =
			userFilter?.length > 0 ? userFilter : this.initializeFilters(filters)

		return {
			userFilters
		}
	}
}
