import {
	COST_AND_LEAD_TBL_DATA_ARRANGED,
	CUSTOMIZE_MATERIAL_COST_ADDED,
	CUSTOMIZE_MATERIAL_COST_BUTTON_LOADER_UPDATED,
	CUSTOMIZE_MATERIAL_COST_DELETED,
	CUSTOMIZE_MATERIAL_NAME_CHANGED,
	CUSTOMIZE_MATERIAL_PRICE_CHANGED,
	CUSTOMIZE_PRINTER_MATERIAL_COMPANY_CHANGED,
	CUSTOMIZE_PRINTER_MATERIAL_PRINTER_SELECTED,
	CUSTOMIZE_PRINTER_PROPS_SETUPED,
	CUSTOMIZE_PRINTER_TECH_CHANGED,
	EDITABLE_MATERIAL_COSTS_CHANGE,
	MATERIAL_COST_ALERT_UPDATED,
	SETUP_MATERIAL_COSTS
} from '../../../../global actions/types'

const INITIAL_STATE = {
	loading: false,
	materialCostLoading: false,
	selectedPrinterTechnology: '',
	selectedPrinterName: '',
	selectedPrinterCompany: '',
	selectedMaterialName: '',
	materialPrice: '',
	selectedPrinter: {},
	selectedMaterial: {},
	materialCosts: [],
	printerCompanies: [],
	printerNames: [],
	materialId: 0,
	showMaterialCostAlert: false,
	editableMaterialCostsList: [],
	deletableMaterialCostsList: [],
	materialPriceError: false
}

const materialCostReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case COST_AND_LEAD_TBL_DATA_ARRANGED: {
			const { materialCosts } = action.payload
			return {
				...state,
				materialCosts
			}
		}
		case CUSTOMIZE_MATERIAL_COST_BUTTON_LOADER_UPDATED:
			return {
				...state,
				materialCostLoading: action.payload
			}
		case CUSTOMIZE_PRINTER_TECH_CHANGED:
			return {
				...state,
				selectedPrinterTechnology: action.payload,
				selectedMaterialName: ''
			}
		case CUSTOMIZE_MATERIAL_NAME_CHANGED: {
			const materialName = action.payload
			const { selectedPrinter } = state
			const selectedMaterial =
				selectedPrinter &&
				selectedPrinter.materials.find(
					material => material.name === materialName
				)

			return {
				...state,
				selectedMaterial,
				selectedMaterialName: action.payload
			}
		}
		case CUSTOMIZE_MATERIAL_PRICE_CHANGED:
			return {
				...state,
				materialPrice: action.payload,
				materialPriceError: !!action.payload.length && action.payload <= 0
			}
		case CUSTOMIZE_MATERIAL_COST_ADDED:
			const { materialCosts } = action.payload
			return {
				...state,
				materialCosts,
				selectedMaterialName: '',
				selectedPrinterTechnology: '',
				selectedPrinterName: '',
				selectedPrinterCompany: '',
				materialPrice: ''
			}
		case CUSTOMIZE_MATERIAL_COST_DELETED: {
			const { materialCosts } = action.payload
			return {
				...state,
				materialCosts
			}
		}
		case CUSTOMIZE_PRINTER_PROPS_SETUPED:
			const { printerCompanies } = action.payload
			return { ...state, printerCompanies }

		case CUSTOMIZE_PRINTER_MATERIAL_PRINTER_SELECTED:
			const { selectedPrinter } = action.payload
			return {
				...state,
				selectedPrinter,
				selectedPrinterCompany: selectedPrinter?.company || '',
				selectedPrinterName: selectedPrinter?.name || '',
				selectedMaterialName: ''
			}

		case CUSTOMIZE_PRINTER_MATERIAL_COMPANY_CHANGED:
			const { printerNames, printerCompany } = action.payload

			return {
				...state,
				printerNames,
				selectedPrinterCompany: printerCompany,
				selectedPrinter: {},
				selectedPrinterName: '',
				selectedMaterialName: '',
			}

		case MATERIAL_COST_ALERT_UPDATED:
			return {
				...state,
				showMaterialCostAlert: action.payload
			}
		case SETUP_MATERIAL_COSTS: {
			const { editableMaterialCostsList, deletableMaterialCostsList } =
				action.payload
			return {
				...state,
				editableMaterialCostsList,
				deletableMaterialCostsList
			}
		}
		case EDITABLE_MATERIAL_COSTS_CHANGE: {
			const { editableMaterialCostsList } = action.payload
			return {
				...state,
				editableMaterialCostsList
			}
		}
		default:
			return state
	}
}

export default materialCostReducer
