// ##############################
// // // Table styles
// #############################

import {
	dangerColor,
	defaultFont,
	grayRedColor,
	grayColor,
	infoColor,
	primaryColor,
	roseColor,
	successColor,
	warningColor
} from '../styles'

const tableStyle = theme => ({
	warningTableHeader: {
		color: warningColor
	},
	primaryTableHeader: {
		color: primaryColor
	},
	dangerTableHeader: {
		color: dangerColor
	},
	successTableHeader: {
		color: successColor
	},
	infoTableHeader: {
		color: infoColor
	},
	roseTableHeader: {
		color: roseColor
	},
	grayRedTableHeader: {
		color: grayRedColor
	},
	grayTableHeader: {
		color: grayColor
	},
	table: {
		marginBottom: '0',
		width: '100%',
		maxWidth: '100%',
		backgroundColor: 'transparent',
		borderSpacing: '0',
		borderCollapse: 'collapse'
	},
	tableHeadCell: {
		color: 'inherit',
		...defaultFont,
		fontSize: '1em'
		// textTransform: 'capitalize'
	},
	tableCell: {
		...defaultFont,
		lineHeight: '1.42857143',
		padding: '12px 8px',
		verticalAlign: 'middle',
		maxWidth: '150px',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
	costAndLeadTableCell: {
		'&:last-child': {
			textAlign: 'right'
		},
		'&:first-child': {
			textAlign: 'left'
		},
		textAlign: 'center'
	},
	drawingCostTableCell: {
		maxWidth: '60px',

		'&:last-child': {
			textAlign: 'right'
		},
		'&:first-child': {
			textAlign: 'left',
			Width: '50px'
		},
		textAlign: 'center'
	},
	materialCostTableCell: {
		'&:first-child': {
			textAlign: 'left'
		},
		textAlign: 'center'
	},
	co2CalculationTable: {
		'&:first-child': {
			textAlign: 'left'
		},
		textAlign: 'center'
	},
	tableResponsive: {
		width: '100%',
		marginTop: theme.spacing(3),
		overflowX: 'auto'
	}
})

export default tableStyle
