export const xAxis = 'x'
export const yAxis = 'y'
export const zAxis = 'z'
export const noneAxis = 'none'

export const defaultSliderValue = 0
export const defaultAxisValue = '0 0 0 0'

export const minSliderValue = -100
export const maxSliderValue = 100
export const stepSliderValue = 1

export const axisArray = [
	{ name: noneAxis, value: '0 0 0 0', color: '1.0,1.0,1.0' },
	{ name: xAxis, value: '0 1 0 0', color: '1.0,0.0,0.0' },
	{ name: yAxis, value: '1 0 0 0', color: '0.0,1.0,0.0' },
	{ name: zAxis, value: '0 0 1 0', color: '0.0,0.0,1.0' }
]
