import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import TextField from '@material-ui/core/TextField'

import * as CustomizeInHousePrintersActions from './CustomizeInHousePrintersActions'
import {
	IN_HOUSE_PRINTERS_COMPANY_SELECTOR_NAME,
	IN_HOUSE_PRINTERS_NAME_SELECTOR_NAME
} from '../../../../Services/Strings'

import './CustomizeInHousePrinters.scss'

class InHousePrintersOtherSelector extends Component {
	componentWillUnmount() {
		this.props.onInHousePrintersUnmount({
			showOtherPrinterCompanySelector: false,
			showOtherPrinterNameSelector: false
		})
	}

	onOtherPrinterCompanySelectorChange = e => {
		const {
			onOtherPrinterCompanySelectorChange,
			selectedPrinterCompany,
			selectedPrinter
		} = this.props
		onOtherPrinterCompanySelectorChange(
			e.target.value,
			selectedPrinter,
			selectedPrinterCompany
		)
	}

	onOtherPrinterNameSelectorChange = e => {
		const {
			onOtherPrinterNameSelectorChange,
			selectedPrinterCompany,
			selectedOtherPrinterCompany
		} = this.props
		onOtherPrinterNameSelectorChange(
			e.target.value,
			selectedPrinterCompany,
			selectedOtherPrinterCompany
		)
	}

	renderPrinterNameField = selectedOtherPrinterName => (
		<TextField
			className="text-field"
			placeholder={IN_HOUSE_PRINTERS_NAME_SELECTOR_NAME}
			onChange={this.onOtherPrinterNameSelectorChange}
			value={selectedOtherPrinterName}
		/>
	)

	render() {
		const {
			selectedOtherPrinterCompany,
			selectedOtherPrinterName,
			showOtherPrinterCompanySelector,
			showOtherPrinterNameSelector
		} = this.props

		if (!showOtherPrinterCompanySelector && !showOtherPrinterNameSelector) {
			return <div />
		}

		if (showOtherPrinterCompanySelector) {
			return (
				<div className="in-house-printers-other-selector">
					<TextField
						className="text-field"
						placeholder={IN_HOUSE_PRINTERS_COMPANY_SELECTOR_NAME}
						onChange={this.onOtherPrinterCompanySelectorChange}
						value={selectedOtherPrinterCompany}
					/>
					{this.renderPrinterNameField(selectedOtherPrinterName)}
				</div>
			)
		}

		if (showOtherPrinterNameSelector) {
			return (
				<div className="in-house-printers-other-selector">
					{this.renderPrinterNameField(selectedOtherPrinterName)}
				</div>
			)
		}
	}
}

const mapStateToProps = ({ CustomizeInHousePrintersReducer }) => {
	const {
		selectedOtherPrinterCompany,
		selectedOtherPrinterName,
		showOtherPrinterCompanySelector,
		showOtherPrinterNameSelector,
		selectedPrinterCompany,
		selectedPrinter
	} = CustomizeInHousePrintersReducer

	return {
		selectedOtherPrinterCompany,
		selectedOtherPrinterName,
		showOtherPrinterCompanySelector,
		showOtherPrinterNameSelector,
		selectedPrinterCompany,
		selectedPrinter
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...CustomizeInHousePrintersActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InHousePrintersOtherSelector)
