import React, { FC, useCallback, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import {
	onChangeSelectedMaterial,
	onRemoveUserMaterialNameMapping,
	onUpdateMaterialNameMapping
} from 'Scenes/Home/Customize/CustomizeActions'
import MaterialNamesMappingList from 'Scenes/Home/Customize/CustomizeMaterialNamesMapping/MaterialNamesMappingList'
import { IUserMaterialNamesMapping } from 'Services/models/IMaterialNamesMapping'
import { getString } from 'Services/Strings/StringService'

const DefaultMaterial: FC = () => {
	const {
		disableMaterialNameMappingSaveAll,
		userMaterialNamesMapping,
		materials,
		materialTypes,
		materialCategories,
		isLoadingMaterial
	} = useSelector((state: RootStateOrAny) => state.user)
	const dispatch = useDispatch()

	const defaultUserMaterialNamesMapping = useMemo(
		() =>
			userMaterialNamesMapping?.filter(
				(materialName: IUserMaterialNamesMapping) =>
					materialName.defaultFormatType
			),
		[userMaterialNamesMapping]
	)

	const updateMaterialNameMapping = useCallback(
		(materialNameMapping: IUserMaterialNamesMapping[]) => {
			dispatch(onUpdateMaterialNameMapping(materialNameMapping))
		},
		[dispatch]
	)

	const removeUserMaterialNameMapping = useCallback(
		(id: string) => {
			dispatch(onRemoveUserMaterialNameMapping(id))
		},
		[dispatch]
	)

	const changeSelectedMaterial = useCallback(
		(materialNameMapping: IUserMaterialNamesMapping[]) => {
			dispatch(onChangeSelectedMaterial(materialNameMapping))
		},
		[dispatch]
	)

	return (
		<MaterialNamesMappingList
			title={getString('CUSTOMIZE_MATERIAL_NAMES_MAPPING_DEFAULT_TITLE')}
			explanation={getString(
				'CUSTOMIZE_MATERIAL_NAMES_MAPPING_DEFAULT_EXPLANATION'
			)}
			disableMaterialNameMappingSaveAll={disableMaterialNameMappingSaveAll}
			userMaterialNamesMapping={defaultUserMaterialNamesMapping}
			isLoadingMaterial={isLoadingMaterial}
			materialCategories={materialCategories}
			materialTypes={materialTypes}
			materials={materials}
			isActive
			allowRemove={false}
			updateMaterialNameMapping={updateMaterialNameMapping}
			removeUserMaterialNameMapping={removeUserMaterialNameMapping}
			changeSelectedMaterial={changeSelectedMaterial}
			devider={false}
		/>
	)
}

export default DefaultMaterial
