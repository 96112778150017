import { useEffect } from 'react'

import { useError } from './ErrorInterceptorProvider'
import axios from 'Services/Network'
import { getString } from 'Services/Strings/StringService'
import { strings } from 'Services/Strings/stringsFallback'

const useAxiosInterceptor = () => {
	const { setError } = useError()

	useEffect(() => {
		const interceptor = axios?.interceptors?.response?.use(
			response => response,
			error => {
				if (error.status === 500) {
					setError(getString('CONNECTION_LOST') || strings.CONNECTION_LOST)
				} else {
					throw error
				}
			}
		)

		return () => {
			axios?.interceptors?.response?.eject(interceptor)
		}
	}, [setError])
}

export default useAxiosInterceptor
