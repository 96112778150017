import { FC, memo } from 'react'
import { useDispatch } from 'react-redux'

import TransparentButton from 'Scenes/Components/TransparentButton'
import { getString } from 'Services/Strings/StringService'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import SolutionConfigureFeaturesPriorities
	from './SolutionConfigureFeatures/SolutionConfigureFeaturesPriorities'
import { ReactComponent as Add } from 'assets/img/svg/icons/circle_close.svg'
import {
	useSolutionReducer,
	useUserReducer
} from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisSelector'
import {
	onPrioritiesModalConfirm,
	onPriorityToggleChange
} from '../SolutionAnalysisActions'

const AddIcon: any = Add

const SolutionConfigurePrioritizeButtons: FC<any> = ({
	isNewDesign,
	onPrioritiesFeatureClick,
	configurationId
}) => {
	const {
		tempSolutionPrioritiesToggles,
		solutionPriorities
	} = useSolutionReducer(configurationId)
	const {
		priorities,
	} = useUserReducer()
	const dispatch = useDispatch()

	if (!isNewDesign) {
		return (
			<TransparentButton
				onClick={() => onPrioritiesFeatureClick()}
				className="solution-analysis-object-content-configure-widget-feature"
			>
				<AddIcon className="add-circle" /> {getString('ADD')} {getString(
				'PROPERTY')}
			</TransparentButton>
		)
	}
	return (
		<DetailsPopup
			disableClick={true}
			data={
				<div>
					<SolutionConfigureFeaturesPriorities
						isNewDesign={true}
						priorities={priorities}
						showSolutionPriorities={tempSolutionPrioritiesToggles}
						configurationId={configurationId}
						onPriorityToggleChange={(configurationId, priority) => {
							dispatch(onPriorityToggleChange(configurationId, priority))
							dispatch(onPrioritiesModalConfirm(
								configurationId,
								priorities,
								tempSolutionPrioritiesToggles,
								solutionPriorities
							))
						}}
					/>
				</div>
			}
			popperDirection="bottom-start"
			popperClassName="add-new-popper-message"
			popperContactClassName="with-dropdown"
		>
			<TransparentButton
				onClick={() => onPrioritiesFeatureClick()}
				className="solution-analysis-object-content-configure-widget-feature with-dropdown"
			>
				<AddIcon className="add-circle" /> {getString('ADD')} {getString(
				'PROPERTY')}
			</TransparentButton>
		</DetailsPopup>
	)
}

export default memo(SolutionConfigurePrioritizeButtons)