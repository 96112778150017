import React, { FC, memo } from 'react'

import SolutionAnalysis from '../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysis'
import Flexbox from 'Scenes/Components/FlexBox'
import { Part } from 'Services/models/IPart'

import '../MainPartAnalysis.scss'

interface IProps {
	solutions: any[]
	configurations: any[]
	part: Part | null
	cluster: any
	newSolutionAdded: boolean
	newPrinterMaterialConfigurationAdded: boolean
	initialBatchSize: number
}

const SolutionAnalysisObject: any = SolutionAnalysis

const renderNewSolution = (
	configuration: any,
	newSolutionAdded: boolean,
	part: Part | null,
	cluster: any,
	initialBatchSize: number
) => {
	return (
		<SolutionAnalysisObject
			key={configuration.id}
			configuration={configuration}
			part={part}
			cluster={cluster}
			newSolution={!newSolutionAdded}
			initialBatchSize={initialBatchSize}
		/>
	)
}

const SolutionsList: FC<IProps> = ({
	configurations,
	part,
	cluster,
	newSolutionAdded,
	newPrinterMaterialConfigurationAdded,
	initialBatchSize
}) => {
	const renderNewPrinterMaterislConfiguration = () => {
		const newConfiguration = configurations.find(
			configuration => configuration.id === -1
		)
		return renderNewSolution(
			newConfiguration,
			newPrinterMaterialConfigurationAdded,
			part,
			cluster,
			initialBatchSize
		)
	}

	const renderNewConfiguration = () => {
		const newConfiguration = configurations.find(
			configuration => configuration.id === 0
		)
		return renderNewSolution(
			newConfiguration,
			newSolutionAdded,
			part,
			cluster,
			initialBatchSize
		)
	}

	const renderUserConfigurations = () => {
		const userConfigurations = configurations.filter(({ id }) => id > 0)
		return (
			<>
				{userConfigurations.map((configuration: any) => (
					<SolutionAnalysisObject
						initialBatchSize={initialBatchSize}
						configuration={configuration}
						part={part}
						cluster={cluster}
						key={configuration.id}
					/>
				))}
			</>
		)
	}

	return (
		<Flexbox flexDirection="column" width="100%">
			{renderNewPrinterMaterislConfiguration()}
			{renderUserConfigurations()}
			{renderNewConfiguration()}
		</Flexbox>
	)
}

export default memo(SolutionsList)
