import React, { useState, useEffect } from 'react'
import { ReactComponent as TimeSaving } from '../../../assets/img/svg/time saving.svg'
import { ReactComponent as TimeSavingSmall } from '../../../assets/img/svg/time icon.svg'

export default ({
  small,
  className = '',
  smallClassName = '',
  expandIconOnHover
}) => {
  const [isBigIcon, setIsBigIcon] = useState(false)
  useEffect(() => {
    setIsBigIcon(!small)
  }, [small])

  if (!isBigIcon) {
    return (
      <TimeSavingSmall
        className={smallClassName}
        data-qa="data-qa-small-benefit-time-saving"
        onMouseOver={() => setIsBigIcon(expandIconOnHover || false)}
      />
    )
  }
  return (
    <TimeSaving
      className={className}
      data-qa="data-qa-configuration-icon-time-saving"
      onMouseLeave={() => setIsBigIcon(!expandIconOnHover || false)}
    />
  )
}
