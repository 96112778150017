import React from 'react'

import Step1 from 'Scenes/Home/Customize/CustomizeCostingFunctionEditor/Step1'
import Step2 from 'Scenes/Home/Customize/CustomizeCostingFunctionEditor/Step2'
import Step3 from 'Scenes/Home/Customize/CustomizeCostingFunctionEditor/Step3'
import { getString } from 'Services/Strings/StringService'

const CostFunctionEditorSteps = () => {
	return (
		<div className="cost-function-editor-frame--body">
			<h5>{getString('ONBOARDING_COST_FUNCTION_EDITOR_BODY_HEADER')}</h5>
			<div className="costing-function-editor--body">
				<Step1 />
				<Step2 />
				<Step3 />
			</div>
		</div>
	)
}

export default CostFunctionEditorSteps
