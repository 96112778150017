import { FC, memo, useState } from 'react'
import Dropzone from 'react-dropzone'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import UploadIcon from '@material-ui/icons/CloudUpload'
import { isEmpty } from 'lodash'

import ButtonWithLoader from '../../Components/ButtonWithLoader'
import { onUploadFile, setFile } from './LicenseActions'
import Flexbox from 'Scenes/Components/FlexBox'
import {
	DROP_DATABASE_LICENSE_FILE,
	RUN_DATABASE_INSERT_LICENSE
} from 'Services/Strings'

import './index.scss'

const UploadIconTSX: any = UploadIcon

const LicenseDropZone: FC = () => {
	const [draggingFiles, setDragging] = useState<boolean>(false)
	const dispatch = useDispatch()
	const { loading, file, error, preparedToSend } = useSelector(
		(state: RootStateOrAny) => state.LicenseReducer
	)

	const handleFileReader = (files: any) => {
		let reader = new FileReader()
		if (files[0]) {
			reader.readAsDataURL(files[0])
			reader.onload = () => {
				dispatch(setFile(files, reader?.result))
			}
		}
	}

	return (
		<div>
			<Dropzone
				accept=".zip"
				id="upload-script"
				onDrop={(newFile: any) => {
					handleFileReader(newFile)
					setDragging(false)
				}}
				multiple={false}
				className={
					draggingFiles
						? 'upload-script-dropzoneDrag'
						: 'upload-script-dropzone'
				}
				onDragEnter={() => setDragging(true)}
				onDragLeave={() => setDragging(false)}
			>
				<Flexbox
					flexDirection="column"
					className="upload-script-dropzone__flex"
				>
					<UploadIconTSX className="upload-script-dropzone__icon" />
					<h3 style={{ margin: 0 }}>
						{isEmpty(file) ? DROP_DATABASE_LICENSE_FILE : `${file?.name}`}
					</h3>
					<br />
				</Flexbox>
			</Dropzone>
			<div className="upload-script-error">{error}</div>
			<ButtonWithLoader
				id="upload-project-upload-button"
				className="upload-script-button"
				loading={loading}
				primary={true}
				disabled={isEmpty(preparedToSend) || isEmpty(file)}
				onClick={() => dispatch(onUploadFile(preparedToSend))}
			>
				{RUN_DATABASE_INSERT_LICENSE}
			</ButtonWithLoader>
		</div>
	)
}

export default memo(LicenseDropZone)
