import React, { FC, memo, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { find } from 'lodash'

import CostAnalysisTable from '../../../SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostAnalysisTable'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import { LineBreak } from 'Scenes/Components/PdfComponents/LineBreak'
import RowComponent from 'Scenes/Components/PdfComponents/RowPdf'
import { pdfOptions } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisHeader/SolutionExportPdf/SolutionPdfInterface'
import { detailedTable } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisHeader/SolutionExportPdf/SolutionPdfService'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	manufacturingMethod: string
	configuration: any
	solution: any
	show: boolean
	part: Part
}

export const CostAnalysisPdf: FC<IProps> = ({
	manufacturingMethod,
	configuration,
	show,
	part,
	solution
}) => {
	const [isError, setIsError] = useState(false)

	const projectPdfOptions = useSelector(
		(state: RootStateOrAny) => state?.MainPartAnalysisReducer?.projectPdfOptions
	)

	const showDetailedTable = find(projectPdfOptions, (option: pdfOptions) =>
		find(option?.data, (nestedOption: pdfOptions) =>
			find(
				nestedOption?.data,
				(child: pdfOptions) => child.name === detailedTable && child.checked
			)
		)
	)

	if (!show || !configuration?.costResults) return <div />

	if (isError) return <></>

	const isNotSpecifiedQuantity =
		!configuration.isSpecifiedQuantity || !part.isSpecifiedQuantity

	if (isNotSpecifiedQuantity) {
		return <></>
	}

	return (
		<RowComponent>
			<div className="inline">
				<div>
					<div className="export-pdf__cost-analysis">
						<h2>{getString('COST_ANALYSIS_TABLE_VIEW')}</h2>
						<br />
						<ErrorBoundary extra="CostAnalysisTable" isError={setIsError}>
							<CostAnalysisTable
								shouldShowDetailed={!!showDetailedTable}
								shouldShowNested={true}
								traditionalMethod={manufacturingMethod}
								solution={solution}
								configuration={configuration}
								chainBenefits={configuration.chainBenefits}
								part={part}
							/>
						</ErrorBoundary>
					</div>
					<LineBreak />
				</div>
			</div>
		</RowComponent>
	)
}

export default memo(CostAnalysisPdf)
