import React, { FC, memo, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { getString } from '../../../../../../Services/Strings/StringService'
import CastorAlert from '../../../../../Components/alerts/CastorAlert'
import SolutionFeaContent from './SolutionFeaContent'

import './SolutionFea.scss'

interface IProps {
	show: boolean
	strengthTitle: string
	userInputTitle: string
	solutionFeaSliderUnits: string
	feaResult: number
	strength: number
	SDStrength: number
	sliderMaxValue: number
	solutionFeaSliderMinValue: number
	sliderStartPointValue: number
	sliderMarks: Record<string, string>
	solutionFeaSliderValue: number
	solutionFeaSliderIsRange: boolean
	solutionFeaAlertLoading: boolean
	onCancel: Function
	onConfirm: (userSelectedValues: number[]) => any
}

const SolutionFea: FC<IProps> = ({
	show,
	onConfirm,
	onCancel,
	strengthTitle,
	strength,
	SDStrength,
	userInputTitle,
	sliderMarks,
	sliderMaxValue,
	sliderStartPointValue,
	solutionFeaSliderValue,
	solutionFeaSliderIsRange,
	solutionFeaSliderUnits,
	solutionFeaSliderMinValue,
	feaResult,
	solutionFeaAlertLoading
}) => {
	const { showPrintingStandardAlert } = useSelector(
		(state: RootStateOrAny) => state.MainPartAnalysisReducer
	)
	const [userSelectedInputs, setUserSelectedInputs] = useState<number[]>([])
	return (
		<CastorAlert
			headerTitle={getString('MECHANICAL_ANALYSIS_ALERT_TITLE')}
			onCancel={onCancel}
			show={!showPrintingStandardAlert && show}
			onConfirm={() => onConfirm(userSelectedInputs)}
			loadingCalculation={solutionFeaAlertLoading}
			alertBodyClass="solution-fea-alert"
		>
			<SolutionFeaContent
				strengthTitle={strengthTitle}
				strength={strength}
				SDStrength={SDStrength}
				userInputTitle={userInputTitle}
				sliderMarks={sliderMarks}
				sliderMaxValue={sliderMaxValue}
				sliderStartPointValue={sliderStartPointValue}
				solutionFeaSliderValue={solutionFeaSliderValue}
				isRange={solutionFeaSliderIsRange}
				sliderUnits={solutionFeaSliderUnits}
				sliderMinValue={solutionFeaSliderMinValue}
				feaResult={feaResult}
				setUserSelectedInputs={setUserSelectedInputs}
			/>
		</CastorAlert>
	)
}

export default memo(SolutionFea)
