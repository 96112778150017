import React, { FC } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { IFileWithMaterialAndQuantity } from 'Services/models/IUploadProject'

type IProps = {
	state: IFileWithMaterialAndQuantity
	onMaterialChange: (value: string) => void
	materialsList: Record<string, string>[]
	disabledMenuItem: string
}

const OriginalMaterial: FC<IProps> = ({
	state,
	onMaterialChange,
	materialsList,
	disabledMenuItem
}) => {
	return (
		<Select
			displayEmpty
			disabled={!state.materialCategory}
			className={'file-row--select-field'}
			value={state.originalMaterial}
			renderValue={selected =>
				(selected as React.ReactNode) || disabledMenuItem
			}
			onChange={(event: any) => onMaterialChange(event.target.value)}
			classes={{
				select: 'file-row--select'
			}}
			inputProps={{
				name: 'simpleSelect',
				id: 'simple-select'
			}}
			MenuProps={{
				PaperProps: {
					style: {
						transform: 'translate3d(0, 0, 0)'
					}
				}
			}}
		>
			<MenuItem disabled>{disabledMenuItem}</MenuItem>
			{materialsList.map((material: Record<string, string>) => {
				return (
					<MenuItem
						key={material.name}
						style={{ textTransform: 'capitalize' }}
						value={material.name}
					>
						{material.name}
					</MenuItem>
				)
			})}
		</Select>
	)
}

export default OriginalMaterial
