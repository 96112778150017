import BuildIcon from '@material-ui/icons/Build'
import Flag from '@material-ui/icons/Flag'
import IconDownloadIcon from '@material-ui/icons/GetApp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import PersonIcon from '@material-ui/icons/Person'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import Update from '@material-ui/icons/Update'

import { ReactComponent as OnboardingIcon } from '../../../assets/img/svg/onboarding.svg'

type Icons =
	| 'flag'
	| 'person'
	| 'build'
	| 'update'
	| 'supervisor'
	| 'iconDown'
	| 'iconDownload'
	| 'onboarding'

const FlagTSX: any = Flag
const PersonIconTSX: any = PersonIcon
const BuildIconTSX: any = BuildIcon
const UpdateTSX: any = Update
const SupervisorAccountIconTSX: any = SupervisorAccountIcon
const KeyboardArrowDownIconTSX: any = KeyboardArrowDownIcon
const IconDownloadTSX: any = IconDownloadIcon

export const getIconFromName = (iconName: Icons) => {
	switch (iconName) {
		case 'flag':
			return <FlagTSX />
		case 'person':
			return <PersonIconTSX />
		case 'build':
			return <BuildIconTSX />
		case 'update':
			return <UpdateTSX />
		case 'supervisor':
			return <SupervisorAccountIconTSX />
		case 'iconDown':
			return <KeyboardArrowDownIconTSX />
		case 'iconDownload':
			return <IconDownloadTSX />
		case 'onboarding':
			return <OnboardingIcon className="custom-icon" />
	}
}
