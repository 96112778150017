import { getTheme } from '../themes/getTheme'

const theme = getTheme()

export const dropzoneBackground = theme.colors.dropzoneBackground
export const pageBackground = theme.colors.pageBackground
export const passedColor = theme.colors.passedColor
export const borderlineColor = theme.colors.borderlineColor
export const borderlineStrokeColor = theme.colors.borderlineStrokeColor
export const failedColor = theme.colors.failedColor
export const successColor = theme.colors.successColor
export const methodColor = theme.colors.chartMethodColor
export const crossColor = theme.colors.chartCrossColor
export const amColor = theme.colors.chartAMColor
export const piePassedGradientStartColor =
  theme.colors.piePassedGradientStartColor
export const piePassedColor = theme.colors.piePassedColor
export const pieBorderlineColor = theme.colors.pieBorderlineColor
export const costSavingColor = theme.colors.costSavingColor
export const pieBorderlineStrokeColor = theme.colors.pieBorderlineStrokeColor
export const pieFailedColor = theme.colors.pieFailedColor
export const pieNotCostEffectiveColor = theme.colors.notCostEffectiveColor
export const pieMissingInformationColor = theme.colors.pieMissingInformationColor
export const pieCostSavingColor = theme.colors.costSavingColor
export const pieNotSuitableForPrint = theme.colors.notSuitableForPrint
export const pieBenefitsManufacturing = theme.colors.pieBenefitsManufacturing
export const pieBenefitsInventory = theme.colors.pieBenefitsInventory
export const pieBenefitsCo2 = theme.colors.pieBenefitsCo2
export const pieBenefitsShipments = theme.colors.pieBenefitsShipments
export const benefitsBackground = theme.colors.benefitsBackground
export const benefitsBackgroundOpac = theme.colors.benefitsBackgroundOpac

export const benefitAnalysisColors = {
  costSaving: theme.colors.costSavingBenefit,
	timeSaving: theme.colors.timeSavingBenefit,
	complexityScore: theme.colors.complexityScoreBenefit,
	buyToFly: theme.colors.buyToFlyBenefit,
	weightReduction: theme.colors.weightReductionBenefit,
	partConsolidation: theme.colors.partConsolidationBenefit,
}