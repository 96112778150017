import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'

import ItemGrid from '../../../Components/thirdParty/CreativeTim/components/Grid/ItemGrid.jsx'
import extendedTablesStyle from '../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import { Table } from '../../../Components/thirdParty/CreativeTim/components/index.js'
import { changeFieldToEdit } from './CostAndLeadActions'
import CostAndLeadEditInput from './CostAndLeadEditInput.js'
import CostAndLeadActionButton from './CostAndLeadActionButton.js'
import TransparentButton from '../../../Components/TransparentButton'
import { getString } from '../../../../Services/Strings/StringService'

import * as styles from '../customizeStyles.css'

class CostAndLeadTbl extends Component {
	onAllClick = () => {
		const { changeFieldToEdit, editInputs } = this.props
		changeFieldToEdit('all', editInputs)
	}

	renderValue = key => {
		const { settingsData, editInputs, costAndLeadNames } = this.props
		const userSettings = settingsData?.userSettings || {}
		let inputStyle = styles.editedValue

		if (!userSettings[key]) {
			inputStyle = styles.defaultValue
		}

		return (
			<CostAndLeadEditInput
				inputKey={key}
				label={costAndLeadNames[key].text}
				edit={editInputs[key].edit}
				styles={inputStyle}
				value={userSettings[key] || ''}
			/>
		)
	}

	renderTblData = () => {
		const { settingsData, editInputs, costAndLeadNames } = this.props
		const userSettings = settingsData?.userSettings || {}
		
		return Object.keys(costAndLeadNames).map(key => {
			const value = this.renderValue(key)
			const name = (
				<span title={costAndLeadNames[key].title}>
					{costAndLeadNames[key].text}
				</span>
			)
			const action = (
				<CostAndLeadActionButton
					inputKey={key}
					styles={styles}
					edit={editInputs[key].edit}
					isDefault={!userSettings[key]}
				/>
			)
			return [name, value, action]
		})
	}

	render() {
		const editAllBtn = (
			<TransparentButton
				onClick={this.onAllClick}
				className="custom-input--action"
			>
				{getString('COST_AND_LEAD_EDIT_ALL')}
			</TransparentButton>
		)

		return (
			<ItemGrid>
				<Table
					editTableStyle={'costAndLeadTableCell'}
					tableHead={[
						getString('COST_AND_LEAD_PROPERTY_NAME'),
						getString('COST_AND_LEAD_VALUE'),
						editAllBtn
					]}
					tableData={this.renderTblData()}
				/>
			</ItemGrid>
		)
	}
}

const mapStateToProps = ({ CostAndLeadReducer }) => {
	const { editInputs, settingsData, costAndLeadNames } = CostAndLeadReducer
	return { editInputs, settingsData, costAndLeadNames }
}

export default connect(mapStateToProps, { changeFieldToEdit })(
	withStyles(extendedTablesStyle)(CostAndLeadTbl)
)
