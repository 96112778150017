import React, { FC } from 'react'

import cx from 'classnames'

import IconFactory from 'Scenes/Components/StarIcon/IconFactory'

type IProps = {
	title: string
	isStepCurrent: boolean
	isStepCompleted: boolean
	stepNumber: number
}

const ProgressBarStep: FC<IProps> = ({
	title,
	isStepCurrent,
	isStepCompleted,
	stepNumber
}) => {
	return (
		<div className="progress-bar--step">
			<div
				className={cx('progress-bar--step--number', {
					completed: isStepCompleted,
					current: isStepCurrent
				})}
			>
				{isStepCompleted ? (
					<IconFactory iconName="checkmarkWithBorder" />
				) : (
					stepNumber
				)}
			</div>
			<div
				className={cx('progress-bar--step--title', {
					disabled: !isStepCompleted && !isStepCurrent
				})}
			>
				{title}
			</div>
		</div>
	)
}

export default ProgressBarStep
