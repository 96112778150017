import {
  PROJECT_PDF_OPTIONS_CHANGE,
  PROJECT_PDF_OPTIONS_FAILED,
  PROJECT_PDF_OPTIONS_SUCCESS
} from '../../../../../../../global actions/types/partAnalysisTypes'
import { updateProjectPDFOptions } from '../../../../../../../Services/Network'
import { IProjectPdfOptions } from './SolutionPdfInterface'

export const updateProjectPdfOptions = (projectPdfOptions: IProjectPdfOptions): any => {
  return async (dispatch: any) => {
    try {
      const response: any = await updateProjectPDFOptions(projectPdfOptions)
      const exportPdfOptions = response.data?.exportPdfOptions
      
      if (exportPdfOptions) {
        dispatch({
          type: PROJECT_PDF_OPTIONS_SUCCESS,
          payload: {
            projectPdfOptions: JSON.parse(exportPdfOptions)
          }
        })
      }
    } catch (err) {
      console.error(err)
      dispatch({
        type: PROJECT_PDF_OPTIONS_FAILED,
      })
    }
  }
}

export const changeProjectPdfOptions = (
  checkedOption: any,
  currentName: string,
  parentName: string | null,
  nestedParentName: string | null,
) => {
  return (dispatch: any) => {
    dispatch({
      type: PROJECT_PDF_OPTIONS_CHANGE,
      payload: {
        checkedOption: checkedOption,
        currentName: currentName,
        parentName: parentName,
        nestedParentName: nestedParentName
      }
    })
  }
}
