import { cloneDeep, some } from 'lodash'

import { ICostAnalysisParam } from 'Services/models/ICostAnalysisParameter'

export const updateCategoryValue = (
	costAnalysis: ICostAnalysisParam[],
	updatedIndex: number,
	category: string,
	on: boolean
) => {
	const costAnalysisData = cloneDeep(costAnalysis)

	if (updatedIndex !== -1) {
		costAnalysisData[updatedIndex].isOn = on

		costAnalysisData.forEach(param => {
			if (param.parentCategory === category) {
				param.isOn = on
			}
		})

		if (!!costAnalysisData[updatedIndex].parentCategory) {
			const isSomeOn = some(
				costAnalysisData,
				param =>
					param.parentCategory ===
						costAnalysisData[updatedIndex].parentCategory && param.isOn
			)
			const updatedParentIndex = costAnalysisData.findIndex(
				param => param.category == costAnalysisData[updatedIndex].parentCategory
			)

			costAnalysisData[updatedParentIndex].isOn = isSomeOn
		}
	}

	return costAnalysisData
}

export const calculateParentSum = (costAnalysis: ICostAnalysisParam[]) => {
	const data = costAnalysis

	const categorySums = data.reduce((acc: any, item) => {
		const parentCategory = item.parentCategory

		if (parentCategory) {
			acc[parentCategory] = (acc[parentCategory] || 0) + item.cost
		}

		return acc
	}, {})

	return data.map(item => {
		if (categorySums[item.category]) {
			item.cost = categorySums[item.category]?.toFixed(2)
		}

		return item
	})
}

export const calculateTotalCost = (costAnalysis: ICostAnalysisParam[]) => {
	return costAnalysis?.reduce((acc: number, item: ICostAnalysisParam) => {
		if (
			item.cost &&
			item.isOn &&
			(!!item.parentCategory ||
				!some(costAnalysis, param => param.parentCategory === item.category))
		) {
			acc = acc + item.cost
		}

		return acc
	}, 0)
}
