import React, { FC } from 'react'

import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	show: boolean
}

const FailedPartsWarning: FC<IProps> = ({ show }) => {
	if (!show) return null
	return (
		<div className="failed-parts-warning--wrapper">
			<IconFactory iconName="warning" />
			{getString('FAILED_PARTS_WARNING')}
		</div>
	)
}

export default FailedPartsWarning
