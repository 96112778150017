import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import AddIcon from '@material-ui/icons/Add'

import * as CustomizeUserMaterialsActions from './CustomizeUserMaterialsActions'
import {
	customizeNewPrinter,
	customizeNewPrinterMaterial,
	customizeNewUserMaterial
} from '../../../../Services/routeFuncs'
import { getString } from '../../../../Services/Strings/StringService'
import { getThemeString } from '../../../../themes/getThemeString'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import { Button } from '../../../Components/thirdParty/CreativeTim/components'
import AddMultipleMaterialsPopup from '../../Customize/CustomizeMaterialNamesMapping/AddMultipleMaterialsPopup'
import CustomizeUserPrinterMaterialsTable from './NewAMMaterial/CustomizeUserPrinterMaterialsTable'
import CustomizeUserPrintersTable from './NewPrinter/CustomizeUserPrintersTable'
import CustomizeUserMaterialsTable from './NewTMMaterial/CustomizeUserMaterialsTable'

import './CustomizeUserMaterials.css'

class CustomizeUserMaterials extends Component {
	componentDidMount() {
		const { setupUserMaterials, match } = this.props
		setupUserMaterials(match)
	}

	renderFormHeader = () => {
		return (
			<CastorFormHeader
				explanationHeader={getThemeString('CUSTOMIZE_EXPLINATION_1_HEADER')}
				explanationArray={getThemeString('CUSTOMIZE_EXPLANATION_ARRAY')}
			/>
		)
	}

	onCancelMultipleMaterials = () => {
		this.props.openMaterialsNamePopup(false)
		this.props.uploadMaterialsNameBom(null)
	}

	onConfirmMultipleMaterials = () => {
		this.props.sendMaterialsNameBom(this.props.BOMMaterialsFile)
	}

	renderMultipleMaterialButton = () => {
		const { userId } = this.props
		return (
			<div
				className="new-material-link"
							>
				<Button
					color="primary"
					className="customize-user-btn"
					onClick={() => this.props.openMaterialsNamePopup(true)}
					data-qa="data-qa-add-multiple-materials-btn"
				>
					<AddIcon />
					{getString('ADD_MULTIPLE_MATERIALS')}
				</Button>
			</div>
		)
	}

	renderNewMaterialButton = () => {
		const { userId } = this.props
		return (
			<Link
				className="new-material-link"
				to={customizeNewUserMaterial(userId)}
				data-qa="data-qa-add-new-user-original-material-btn"
			>
				<Button color="primary" className="customize-user-btn">
					<AddIcon />
					{getString('USER_MATERIAL_ADD_NEW')}
				</Button>
			</Link>
		)
	}

	renderNewMaterialPrinterButton = () => {
		const { userId } = this.props
		return (
			<Link
				className="new-material-link"
				to={customizeNewPrinterMaterial(userId)}
				data-qa="data-qa-add-new-user-additive-material-btn"
			>
				<Button color="primary" className="customize-user-btn">
					<AddIcon />
					{getString('USER_MATERIAL_ADD_NEW')}
				</Button>
			</Link>
		)
	}

	renderNewPrinterButton = () => {
		const { userId } = this.props
		return (
			<Link
				className="new-material-link"
				to={customizeNewPrinter(userId)}
				data-qa="data-qa-add-new-user-printer-btn"
			>
				<Button color="primary" className="customize-user-btn">
					<AddIcon />
					{getString('USER_PRINTER_ADD_NEW')}
				</Button>
			</Link>
		)
	}

	setUserMaterialsContent = () => {
		return (
			<div>
				<CastorFormHeader
					explanationHeader={getString(
						'CUSTOMIZE_TRADITIONAL_MANUFACTURING_MATERIALS_TITLE'
					)}
					explanationArray={getThemeString(
						'CUSTOMIZE_USER_MATERIAL_EXPLANATION_ARRAY'
					)}
					isInCard={true}
				/>
				<div className="new-material-btn">
					{this.renderMultipleMaterialButton()}
					{this.renderNewMaterialButton()}
				</div>

				<CustomizeUserMaterialsTable />

				<CastorFormHeader
					explanationHeader={getString(
						'CUSTOMIZE_ADDITIVE_MANUFACTURING_MATERIALS_TITLE'
					)}
					explanationArray={getThemeString(
						'CUSTOMIZE_ADDITIVE_MANUFACTURING_MATERIALS_EXPLANATION_ARRAY'
					)}
					isInCard={true}
				/>
				{this.renderNewMaterialPrinterButton()}
				<CustomizeUserPrinterMaterialsTable />
				<CastorFormHeader
					explanationHeader={getString('CUSTOMIZE_3D_PRINTERS_TITLE')}
					explanationArray={getThemeString(
						'CUSTOMIZE_USER_3D_PRINTERS_EXPLANATION_ARRAY'
					)}
					isInCard={true}
				/>
				{this.renderNewPrinterButton()}
				<CustomizeUserPrintersTable />
				<AddMultipleMaterialsPopup
					isOpen={this.props.isOpenMaterialsNamePopup}
					onConfirmMultipleMaterials={this.onConfirmMultipleMaterials}
					onCancelMultipleMaterials={this.onCancelMultipleMaterials}
					fileInputKey={this.props.fileInputKey}
					BOMMaterialsFile={this.props.BOMMaterialsFile}
					isMaterialsNameLoading={this.props.isMaterialsNameLoading}
					isMaterialsNames={false}
					isUserMaterialsNames={true}
					uploadBom={this.props.uploadMaterialsNameBom}
				/>
			</div>
		)
	}

	render() {
		return (
			<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
				<CastorForm
					formHeader={this.renderFormHeader()}
					formTitle={getString('CUSTOMIZE_FORM_USER_MATERIAL_HEADER')}
					content={this.setUserMaterialsContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const mapStateToProps = ({ CustomizeUserMaterialsReducer }) => {
	const { userId, isOpenMaterialsNamePopup, BOMMaterialsFile } =
		CustomizeUserMaterialsReducer
	return {
		userId,
		isOpenMaterialsNamePopup,
		BOMMaterialsFile
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...CustomizeUserMaterialsActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomizeUserMaterials)
