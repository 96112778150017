import { getString } from '../Strings/StringService'

export interface IResultsSelectionOption {
  name: string
  type: PRINTING_SAVING_TYPES
}

export enum PRINTING_SAVING_TYPES {
  COST_EFFECTIVE_PARTS_WITH_THRESHOLD = 'COST_EFFECTIVE_PARTS_WITH_THRESHOLD',
  COST_EFFECTIVE_PARTS_WITH_STARRED = 'COST_EFFECTIVE_PARTS_WITH_STARRED',
  COST_EFFECTIVE_PARTS_WITH_STANDARD_COST = 'COST_EFFECTIVE_PARTS_WITH_STANDARD_COST'
}

export const resultsSelectionOptions = [
  {
    name: getString('RESULTS_SELECTION_PRINTABLE'),
    type: PRINTING_SAVING_TYPES.COST_EFFECTIVE_PARTS_WITH_THRESHOLD
  },
  {
    name: getString('RESULTS_SELECTION_STARRED'),
    type: PRINTING_SAVING_TYPES.COST_EFFECTIVE_PARTS_WITH_STARRED
  },
  {
    name: getString('RESULTS_SELECTION_STANDARD_COST'),
    type: PRINTING_SAVING_TYPES.COST_EFFECTIVE_PARTS_WITH_STANDARD_COST
  }
]
