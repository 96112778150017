import React, { FC, memo } from 'react'
import {
	Draggable,
	DraggableProvided,
	DraggableStateSnapshot
} from 'react-beautiful-dnd'

import { Route } from '../SideBarTypes'
import CollapseViewItem from '../ViewItem/CollapseViewItem'
import { useDraggableInPortal } from './DraggableHooks'

type IProps = {
	listIndex: number
	classes: Record<string, any>
	color: string
	caret: string
	rtlActive?: boolean
	collapseItemText: string
	itemIcon: string
	itemText: string
	state: {
		[key: string]: boolean
	}
	propView: Route
	viewKey: number
	list: Route
	disableDrag?: boolean
	activeRoute: (routeName: string, folderId?: string) => boolean
	openCollapse: (collapse: string) => void
}

const DraggableProject: FC<IProps> = ({
	listIndex,
	color,
	classes,
	caret,
	rtlActive,
	collapseItemText,
	itemIcon,
	itemText,
	state,
	propView,
	viewKey,
	disableDrag,
	activeRoute,
	openCollapse
}) => {
	const renderDraggable = useDraggableInPortal()
	return (
		<Draggable
			draggableId={propView.id as string}
			index={viewKey}
			isDragDisabled={!!disableDrag}
		>
			{renderDraggable(
				(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
					return (
						<div
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
						>
							<CollapseViewItem
								prop={propView}
								key={viewKey + listIndex + propView.name}
								keyProp={viewKey + +listIndex + propView.name}
								collapseItemText={collapseItemText}
								itemIcon={itemIcon}
								itemText={itemText}
								caret={caret}
								classes={classes}
								color={color}
								rtlActive={rtlActive}
								activeRoute={(path: string, id?: string) =>
									activeRoute(path, id)
								}
								openCollapse={(collapseState: string) =>
									openCollapse(collapseState)
								}
								state={state}
								isDragging={snapshot.isDragging}
							/>
						</div>
					)
				}
			)}
		</Draggable>
	)
}

export default memo(DraggableProject)
