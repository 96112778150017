import { FC, memo } from 'react'
import { FormControlLabel } from '@material-ui/core'

import { getString } from 'Services/Strings/StringService'
import CastorSwitch from '../CastorSwitch'
import OverrideComponentHOC from 'themes/OverrideComponentHOC'
import CastorCheckbox from '../CastorCheckbox'

const FormControlLabelTSX: any = FormControlLabel

interface IProps {
	simpleInhouseConfiguration: boolean
	disableConfiguration: boolean
	onSimpleInhouseConfigurationChange: Function
	isNewDesign?: boolean
}

const ControlForm: FC<any> = ({ isNewDesign, ...props }) => {
	if (isNewDesign) return <CastorCheckbox {...props} />

	return <CastorSwitch {...props} />
}

const SimpleConfigurationInHouse: FC<IProps & any> = ({
	simpleInhouseConfiguration,
	disableConfiguration,
	onSimpleInhouseConfigurationChange,
	isNewDesign
}: IProps) => {
	return disableConfiguration ? (
		<div />
	) : (
		<FormControlLabelTSX
			control={
				<ControlForm
					isNewDesign={isNewDesign}
					onChange={(e: any) =>
						onSimpleInhouseConfigurationChange(e.target.checked)
					}
					checked={simpleInhouseConfiguration}
				/>
			}
			label={
				<div className="simpleConfiguration--toggle">
					{getString('IN_HOUSE_PRINTERS_ONLY')}
				</div>
			}
		/>
	)
}

export default memo(OverrideComponentHOC(SimpleConfigurationInHouse))
