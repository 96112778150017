import { useEffect, useState } from 'react'
import { toFixedOnlyIfNeeded } from 'Services/global/toFixedOnlyIfNeeded'

const renderValue = (value: number, isDefault: boolean) => {
  const defaultString = isDefault ? ' (Default)' : ''
  const percentageValue = value * 100
  return `${toFixedOnlyIfNeeded(percentageValue)}% ${defaultString}`
}

export const useRenderValues = (value: number, isDefault: boolean) => {
  const [numberValue, setNumberValue] = useState(value * 100)
  const [placeholder, setPlaceholder] = useState(renderValue(value, isDefault))
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    setNumberValue(toFixedOnlyIfNeeded(value * 100))
    setPlaceholder(renderValue(value, isDefault))
  }, [value])

  return { numberValue, placeholder, isError, setNumberValue, setIsError }
}

export const onApplyAllDrawingData = (
  drawingCost2dNames: any,
  userSettings: any,
  updateAllDrawingData: Function
) => {
  updateAllDrawingData(drawingCost2dNames, userSettings)
}

export const onUpdateDrawingData = (
  data: number,
  drawingKey: any,
  updateDrawingData: Function
) => {
  updateDrawingData(data, drawingKey)
}

export const onEditToggleChange = (
  isEditOn: boolean,
  drawingKey: any,
  drawingEditToggle: Function,
  drawingApplyToggle: Function
) => {
  drawingEditToggle(isEditOn, drawingKey)
  if (isEditOn === true) {
    drawingApplyToggle(false, drawingKey)
  }
}

export const onApplyToggle = (
  isEditOn: boolean,
  drawingKey: any,
  drawingApplyToggle: Function,
  drawingEditToggle: Function,
  onEditToggle: Function
) => {
  drawingApplyToggle(true, drawingKey)
  onEditToggle(isEditOn, drawingKey, drawingEditToggle, drawingApplyToggle)
}

export const onEditAllToggle = (
  drawingEditToggle: Function,
  drawingEditAllToggle: Function,
  drawingCost2dNames: any,
  isEditAllDrawingCost: boolean
) => {
  for (const drawingKey in drawingCost2dNames) {
    if (drawingCost2dNames[drawingKey].edit === isEditAllDrawingCost) {
      drawingEditToggle(!drawingCost2dNames[drawingKey].edit, drawingKey)
    }
  }
  drawingEditAllToggle(!isEditAllDrawingCost)
}
