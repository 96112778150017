import { starPartUpdated } from '../../../Services/Network'
import { STAR_PART_SUCCESS } from '../../../global actions/types'

export const starPartClicked = part => {
  const { projectId, id, starred } = part
  return dispatch => {
    starPartUpdated(projectId, id, !starred)
      .then(() => {
        dispatch({
          type: STAR_PART_SUCCESS,
          payload: { ...part, starred: !starred }
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
}
