import React, { Component, FC, memo, useEffect, useState } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { Link, RouteComponentProps } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form'

import * as AdminPrintingTechnologyActions from './AdminPrintingTechnologyActions'
import { getString } from '../../../../Services/Strings/StringService'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import Loader from '../../../Loader/Loader'
import AdminPrinterTechFrom from './AdminPrinterTechFrom'
import { AdminPrintingTechnologyInitialState } from './AdminPrintingTechnologyReducer'
import { astmIsoName, astmIsoNames } from './constants'

interface IProps
	extends AdminPrintingTechnologyInitialState,
		RouteComponentProps<MatchParams> {
	setupAdminPrintingTechnologyPage: Function
	selectPrinterTechnologyToEdit: Function
	updatePrinterTechnologyParameters: Function
	newAstmIsoSelected: Function
	setSearchPhrase: Function
	selectedAstmIsoName: string
	isNewAstmIsoNameDisabled: boolean
}

interface IStoreProps {
	AdminPrintingTechnologyReducer: AdminPrintingTechnologyInitialState
}
interface MatchParams {
	printerTechName: string
}

const EditTechParameters: FC<IProps> = ({
	loading,
	printersTechParametersData,
	selectedEditPrinterTechnology,
	setupAdminPrintingTechnologyPage,
	selectPrinterTechnologyToEdit,
	newAstmIsoSelected,
	updatePrinterTechnologyParameters,
	match: {
		params: { printerTechName }
	},
	printersTechParametersAstmIsoNameList,
	selectedAstmIsoName,
	isNewAstmIsoNameDisabled
}) => {
	useEffect(() => {
		if (!printersTechParametersData || !printersTechParametersData.length) {
			setupAdminPrintingTechnologyPage()
		} else {
			selectPrinterTechnologyToEdit(printerTechName, printersTechParametersData)
		}
	}, [printersTechParametersData])
	if (loading) {
		return <Loader load={loading} message="" />
	}

	const renderFormContent = (
		selectedEditPrinterTechnology: any,
		selectedAstmIsoName: string,
		isNewAstmIsoNameDisabled: boolean
	) => {
		return (
			<AdminPrinterTechFrom
				onSubmitClick={(values: any) => {
					updatePrinterTechnologyParameters(values)
				}}
				initialValues={selectedEditPrinterTechnology}
				printersTechParametersAstmIsoNameList={
					printersTechParametersAstmIsoNameList
				}
				selectedAstmIsoName={selectedAstmIsoName}
				newAstmIsoSelected={newAstmIsoSelected}
				isNewAstmIsoNameDisabled={isNewAstmIsoNameDisabled}
			/>
		)
	}

	return (
		<NavBarAndMaterial title={''}>
			<CastorForm
				formTitle={getString('ADMIN_PRINTER_TECHNOLOGY_EDIT_HEADER')}
				content={renderFormContent(
					selectedEditPrinterTechnology,
					selectedAstmIsoName,
					isNewAstmIsoNameDisabled
				)}
			/>
		</NavBarAndMaterial>
	)
}

const selector = formValueSelector('AdminPrinterTechParameters')

const mapStateToProps = (state: any) => {
	return {
		...state.AdminPrintingTechnologyReducer,
		selectedAstmIsoName:
			selector(state, astmIsoNames) || selector(state, astmIsoName)
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...AdminPrintingTechnologyActions }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(memo(EditTechParameters))
