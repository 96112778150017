export interface IChainBenefits {
  Global: {
    on: boolean
  }
  Ordering: {
    on: boolean
    storageQuantity?: number
    storagePrice?: number
  }
  Obsolescence: {
    on: boolean
    shipmentCost?: number
  }
  Maintenance: {
    on: boolean
  }
}

export enum ChainBenefitsNames {
  Global = 'Global',
  Ordering = 'Ordering',
  Obsolescence = 'Obsolescence',
  Maintenance = 'Maintenance'
}
