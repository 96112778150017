import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'

// material-ui components
import { withStyles } from '@material-ui/core'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

// core components
import GridContainer from '../Grid/GridContainer.jsx'
import ItemGrid from '../Grid/ItemGrid.jsx'
import navPillsStyle from '../../../../../../assets/jss/material-dashboard-pro-react/components/navPillsStyle.jsx'

class NavPills extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: props.initialSelectedTab
    }
  }
  componentDidUpdate(prevProps) {
    let { initialSelectedTab } = this.props
    if (prevProps.initialSelectedTab !== initialSelectedTab) {
      this.setState({ active: initialSelectedTab })
    }
  }
  handleChange = (event, active) => {
    this.setState({ active })
  }
  handleChangeIndex = index => {
    this.setState({ active: index })
  }

  render() {
    const {
      classes,
      tabs,
      direction,
      color,
      horizontal,
      alignCenter,
      tabButtonsClassName,
      tabContentClassName,
      tabButtonClassName,
      tabButtonSelectedClassName,
      tabButtonLabelClassName
    } = this.props
    const activeValue =
      this.state.active > tabs.length - 1 ? tabs.length - 1 : this.state.active
    const flexContainerClasses =
      classes.flexContainer +
      ' ' +
      cx({
        [classes.horizontalDisplay]: horizontal !== undefined
      })

    const tabButtons = (
      <Tabs
        fullWidth
        classes={{
          root: classes.root + ' ' + tabButtonsClassName,
          fixed: classes.fixed,
          flexContainer: flexContainerClasses
        }}
        indicatorClassName={classes.displayNone}
        value={activeValue}
        onChange={this.handleChange}
        centered={alignCenter}
      >
        {tabs.map((prop, key) => {
          var icon = {}
          if (prop.tabIcon !== undefined) {
            icon['icon'] = <prop.tabIcon className={classes.tabIcon} />
          }
          const pillsClasses =
            classes.pills +
            ' ' +
            cx({
              [classes.horizontalPills]: horizontal !== undefined,
              [classes.pillsWithIcons]: prop.tabIcon !== undefined
            })
          return (
            <Tab
              label={prop.tabButton}
              key={key}
              {...icon}
              classes={{
                root: tabButtonClassName + ' ' + pillsClasses,
                labelContainer: classes.labelContainer,
                label: tabButtonLabelClassName + ' ' + classes.label,
                rootInheritSelected:
                  tabButtonSelectedClassName + ' ' + classes[color]
              }}
            />
          )
        })}
      </Tabs>
    )

    const tabContent = (
      <div className={classes.contentWrapper}>
        <SwipeableViews
          axis={direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeValue}
          onChangeIndex={this.handleChangeIndex}
          slideClassName={tabContentClassName}
          containerStyle={{ paddingRight: '1px' }}
          style={{ overflowX: 'clip', overflowY: 'inherit' }}
        >
          {tabs.map((prop, key) => {
            return (
              <div className={classes.tabContent} key={key}>
                {prop.tabContent}
              </div>
            )
          })}
        </SwipeableViews>
      </div>
    )
    return horizontal !== undefined ? (
      <GridContainer>
        <ItemGrid {...horizontal.tabsGrid}>{tabButtons}</ItemGrid>
        <ItemGrid {...horizontal.contentGrid}>{tabContent}</ItemGrid>
      </GridContainer>
    ) : (
      <div>
        {tabButtons}
        {tabContent}
      </div>
    )
  }
}

NavPills.defaultProps = {
  active: 0,
  color: 'primary'
}

NavPills.propTypes = {
  classes: PropTypes.object.isRequired,
  // index of the default active pill
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]),
      tabIcon: PropTypes.func,
      tabContent: PropTypes.node
    })
  ).isRequired,
  color: PropTypes.oneOf([
    'primary',
    'warning',
    'danger',
    'success',
    'info',
    'rose'
  ]),
  direction: PropTypes.string,
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object
  }),
  alignCenter: PropTypes.bool
}

export default withStyles(navPillsStyle)(NavPills)
