export enum SurfaceFinishMethod {
	rz = 'rz',
	ra = 'ra'
}

export interface Material {
	id: number
	name: string
	type: string
	category: string
	wallThickness: number
	yieldStrengthMPa: number
	percentElongationAtBreak: number
	youngsModulus: number
	density: number
	ultimateTensileStrength: number
	maximumServiceTemperature: number
	thermalConductivity: number
	surfaceFinish: number
	surfaceFinishMethod: SurfaceFinishMethod
	userId: number
	cost: number
	conservativeCostPerCubicCM: number
	standardDeviation: any
	co2perKgMaterial: number
	organizationId: any
	relevantForPrinterMaterials?: string[]
	traditionalCost: any
	defaultPriorities: any
	defaultFilters: any
	active: boolean
	createdAt?: string
	updatedAt?: string
}

export interface IMaterialCategory {
	defaultMaterialSelection?: any
	id: number
	name: string
	similarTo: string
	type: string
	wallThickness: number
	machinability_rating: number
	defaultCost: number
}

export interface IMaterial {
	id: string
	name: string
	type: string
}

export type MaterialType = 'plastic' | 'metal'
export enum MaterialTypeEnum {
	plastic = 'plastic',
	metal = 'metal'
}
