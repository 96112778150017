import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import {
	GET_LICENSE_INFORMATION,
	GET_LICENSE_INFORMATION_ERROR,
	GET_LICENSE_INFORMATION_SUCCESS
} from '../../../../global actions/types'
import { getLicenseInformation } from 'Services/Network'
import { getString } from 'Services/Strings/StringService'

export const getLicenseInfo = (organizationId: number) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: GET_LICENSE_INFORMATION
			})

			const response = await getLicenseInformation(organizationId)

			if (response.data) {
				dispatch({
					type: GET_LICENSE_INFORMATION_SUCCESS,
					payload: {
						licenseInfo: response.data.licenseInfo,
						licenseData: response.data.licenseData
					}
				})
			}
		} catch (err: any) {
			dispatch({
				type: GET_LICENSE_INFORMATION_ERROR,
				payload: {
					error: err?.message || getString('SOMETHING_WENT_WRONG')
				}
			})
		}
	}
}
