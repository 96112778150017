import React, { FC } from 'react'
import cx from 'classnames'
import IconFactory from '../../../Scenes/Components/StarIcon/IconFactory'
import { ButtonForSupport } from './ButtonForSupport'
import { bindActionCreators, AnyAction } from 'redux'
import { connect, DispatchProp } from 'react-redux'
import * as ProjectAnalysisActions from '../../Home/ProjectAnalysis/ProjectAnalysisActions'

interface IProps {
  header: string
  subheader: string
  iconName?: string
  imageSrc?: string
  buttonText: string
  hide: boolean
  onClick?: Function
  onSendProjectFailedToAdmins: any
  projectId: any
}

interface IReduxStore {
  ProjectAnalysisReducer: any
  onSendProjectFailedToAdmins: any
}

const StatusViewerPage: FC<IProps> = ({
  header,
  subheader,
  iconName = '',
  imageSrc = '',
  buttonText = '',
  hide,
  onSendProjectFailedToAdmins,
  projectId
}) => {
  return (
    <div className={cx('status-viewer-page', { 'no-image': iconName })}>
      <div className={cx("status-viewer-page--explanation")}>
        <div className={cx({ 'status-viewer-page--explanation--header--wrap': iconName })}>
          {iconName && <IconFactory iconName={iconName} className="status-viewer-page--icon" />}
          <div className="status-viewer-page--explanation--header">{header}</div>
        </div>
        <div className="status-viewer-page--explanation--subheader" data-qa="data-qa-explanation-subheader">
          {subheader}
        </div>
        <ButtonForSupport
          className={'status-viewer-page--button'}
          buttonText={buttonText}
          hide={hide}
          onClick={() => onSendProjectFailedToAdmins(projectId)}
        />
      </div>
      {imageSrc && <img src={imageSrc} className="status-viewer-page--image" alt="" />}
    </div>
  )
}

const mapStateToProps = ({ ProjectAnalysisReducer }: IReduxStore) => {
  return {
    ...ProjectAnalysisReducer
  }
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
  bindActionCreators({ ...ProjectAnalysisActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StatusViewerPage)
