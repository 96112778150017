import React, { FC, memo, useMemo } from 'react'

import cx from 'classnames'

import { QUICK_UPLOAD_COLUMN } from './constants'
import File from './FileRowColumns/File'
import MaterialCategory from './FileRowColumns/MaterialCategory'
import MaterialType from './FileRowColumns/MaterialType'
import OriginalMaterial from './FileRowColumns/OriginalMaterial'
import PrintingStandards from './FileRowColumns/PrintingStandards'
import ProductionQuantity from './FileRowColumns/ProductionQuantity'
import StandardCost from './FileRowColumns/StandardCost'
import Tolerance from './FileRowColumns/Tolerance'
import { useFileMaterialSelectorState } from './hooks'
import { IUploadProjectComponent } from 'Services/models/IUploadProjectComponent'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	uploadedFile: File
	fileChipDeleteClicked: (file: File) => void
	errorFiles: string[]
	onBlur: () => void
	gridTemplateColumns: string
	columns: IUploadProjectComponent[]
}

const FileRow: FC<IProps> = ({
	uploadedFile,
	fileChipDeleteClicked,
	errorFiles,
	onBlur,
	gridTemplateColumns,
	columns
}) => {
	const {
		state,
		printingStandardsList,
		onMaterialTypeChange,
		onMaterialCategoryChange,
		onMaterialChange,
		onQuantityChange,
		onQuantityBlur,
		materialsService,
		onStandardCostChange,
		onToleranceChange,
		onPrintingStandardsChange
	} = useFileMaterialSelectorState(uploadedFile.name)

	const { materialTypesList, categoriesList, materialsList } =
		materialsService.getDataList()

	const disabledMenuItem = getString('CHOOSE')

	const FileRowComponents = useMemo(
		() => ({
			[QUICK_UPLOAD_COLUMN.FILE]: (
				<File
					uploadedFile={uploadedFile}
					fileChipDeleteClicked={fileChipDeleteClicked}
				/>
			),
			[QUICK_UPLOAD_COLUMN.MATERIAL_TYPE]: (
				<MaterialType
					state={state}
					materialTypesList={materialTypesList}
					onMaterialTypeChange={onMaterialTypeChange}
					disabledMenuItem={disabledMenuItem}
				/>
			),
			[QUICK_UPLOAD_COLUMN.MATERIAL_CATEGORY]: (
				<MaterialCategory
					state={state}
					onMaterialCategoryChange={onMaterialCategoryChange}
					categoriesList={categoriesList}
					disabledMenuItem={disabledMenuItem}
				/>
			),
			[QUICK_UPLOAD_COLUMN.ORIGINAL_MATERIAL]: (
				<OriginalMaterial
					state={state}
					onMaterialChange={onMaterialChange}
					materialsList={materialsList}
					disabledMenuItem={disabledMenuItem}
				/>
			),
			[QUICK_UPLOAD_COLUMN.PRODUCTION_QUANTITY]: (
				<ProductionQuantity
					state={state}
					onQuantityBlur={onQuantityBlur}
					onQuantityChange={onQuantityChange}
				/>
			),
			[QUICK_UPLOAD_COLUMN.STANDARD_COST]: (
				<StandardCost
					state={state}
					onStandardCostChange={onStandardCostChange}
				/>
			),
			[QUICK_UPLOAD_COLUMN.TOLERANCE]: (
				<Tolerance
					state={state}
					onToleranceChange={onToleranceChange}
					disabledMenuItem={disabledMenuItem}
				/>
			),
			[QUICK_UPLOAD_COLUMN.PRINTING_STANDARDS]: (
				<PrintingStandards
					state={state}
					printingStandardsList={printingStandardsList}
					disabledMenuItem={disabledMenuItem}
					onPrintingStandardsChange={onPrintingStandardsChange}
				/>
			)
		}),
		[
			categoriesList,
			disabledMenuItem,
			fileChipDeleteClicked,
			materialTypesList,
			materialsList,
			onMaterialCategoryChange,
			onMaterialChange,
			onMaterialTypeChange,
			onQuantityBlur,
			onQuantityChange,
			onStandardCostChange,
			onToleranceChange,
			state,
			uploadedFile
		]
	)

	const fileError = useMemo(
		() => !!errorFiles.find(errorFile => errorFile === uploadedFile.name),
		[errorFiles, uploadedFile.name]
	)
	return (
		<div
			className={cx('file-row', { withError: fileError })}
			onBlur={onBlur}
			style={{ gridTemplateColumns }}
		>
			{columns.map(column => FileRowComponents[column.id])}
		</div>
	)
}

export default memo(FileRow)
