import React, { memo, FC, useState, useEffect } from 'react'
import { bindActionCreators, AnyAction } from 'redux'
import { DispatchProp, connect } from 'react-redux'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'

import { Action } from '../../../global actions/ActionModels'
import * as CastorSubscriptionAlertActions from './CastorSubscriptionAlertActions'
import { CastorSubscriptionAlertInitialState } from './CastorSubscriptionAlertReducer'
import CastorAlert from '../alerts/CastorAlert'
import {
  CONNECT_CASTOR_POPUP_HEADER,
  SEND,
  CONNECT_CASTOR_POPUP_RADIO_HEADER,
  BUY_FULL_VERSION,
  OTHER,
  EXTEND_SUBSCRIPTION
} from 'Services/Strings'
import {
  SUBSCRIPTION_FULL_VERSION_RADIO_VALUE,
  OTHER_RADIO_VALUE,
  EXTEND_SUBSCRIPTION_RADIO_VALUE
} from 'Services/Constants'

const FormControlTS: any = FormControl
const FormLabelTS: any = FormLabel
const RadioGroupTS: any = RadioGroup
const FormControlLabelTS: any = FormControlLabel
const RadioTS: any = Radio

interface IReduxStore {
  CastorSubscriptionAlertReducer: CastorSubscriptionAlertInitialState
  user: any
}

interface IReduxProps {
  userEmail: string
  onSubscriptionConnectAlertCanceled: () => Action<any>
  connectCastorRadioChanged: (value: any) => Action<any>
  sendConnectCastorUserChoice: (
    subscriptionRequestType: any,
    userEmail: string
  ) => Action<any>
}

interface ConnectProps {
  selectedRadioButtonValue: any
  connectCastorRadioChanged: (value: any) => Action<any>
}

const ConnectCastorBody: FC<ConnectProps> = ({
 selectedRadioButtonValue,
 connectCastorRadioChanged
}) => {
  //RadioGroupTS should receive a string as value, not a number
  const [stringValue, setStringValue] = useState('')
  const fullVersionString = SUBSCRIPTION_FULL_VERSION_RADIO_VALUE.toString()
  const extendVersionString = EXTEND_SUBSCRIPTION_RADIO_VALUE.toString()
  const otherVersionString = OTHER_RADIO_VALUE.toString()

  useEffect(() => {
   setStringValue(selectedRadioButtonValue.toString())
  }, [selectedRadioButtonValue])

  const ConnectCastorRadioChanged = (event: any) => {
    connectCastorRadioChanged(event.target.value)
  }
  return (
    <div>
      <FormControlTS component="fieldset">
        <FormLabelTS component="legend">
          {CONNECT_CASTOR_POPUP_RADIO_HEADER}
        </FormLabelTS>
        <RadioGroupTS
          row={true}
          aria-label="select materials"
          name="select materials"
          value={stringValue}
          onChange={(e: any) => ConnectCastorRadioChanged(e)}
          color="rose"
        >
          <FormControlLabelTS
            value={fullVersionString}
            control={<RadioTS color="primary" />}
            label={BUY_FULL_VERSION}
          />
          <FormControlLabelTS
            value={extendVersionString}
            control={<RadioTS color="primary" />}
            label={EXTEND_SUBSCRIPTION}
          />
          <FormControlLabelTS
            value={otherVersionString}
            control={<RadioTS color="primary" />}
            label={OTHER}
          />
        </RadioGroupTS>
      </FormControlTS>
    </div>
  )
}
const CastorSubscriptionAlert: FC<
  IReduxProps & CastorSubscriptionAlertInitialState
> = ({
  onSubscriptionConnectAlertCanceled,
  loadingCalculation,
  sendConnectCastorUserChoice,
  show,
  selectedRadioButtonValue,
  connectCastorRadioChanged,
  userEmail
}) => {
  return (
    <CastorAlert
      headerTitle={CONNECT_CASTOR_POPUP_HEADER}
      onCancel={() => onSubscriptionConnectAlertCanceled()}
      showCancel={false}
      show={show}
      loadingCalculation={loadingCalculation}
      onConfirm={() => {
        sendConnectCastorUserChoice(selectedRadioButtonValue, userEmail)
      }}
      confirmOptionalText={SEND}
    >
      <ConnectCastorBody
        selectedRadioButtonValue={selectedRadioButtonValue}
        connectCastorRadioChanged={connectCastorRadioChanged}
      />
    </CastorAlert>
  )
}

const mapStateToProps = ({
  CastorSubscriptionAlertReducer,
  user: {
    userDetails: { email }
  }
}: IReduxStore) => {
  return {
    ...CastorSubscriptionAlertReducer,
    userEmail: email
  }
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
  bindActionCreators({ ...CastorSubscriptionAlertActions }, dispatch)

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(CastorSubscriptionAlert)
)
