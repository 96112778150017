import { SelectProject } from 'Scenes/Home/Customize/CustomizeRecalculateProjects/CustomizeRecalculateProjectsInterface'
import { Project } from 'Services/models/IProject'

export const prepareDataToSelect = (projects: Array<Project>) =>
	projects?.map((project: Project) => {
		return {
			id: project.id,
			name: project.name,
			selected: false
		}
	})

export const prepareDataID = (projectsData: Array<SelectProject>) =>
	projectsData
		.filter((project: SelectProject) => project.selected)
		.map((project: SelectProject) => project.id)