import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import cx from 'classnames'

import {
	addConfigurationClick,
	addSimpleConfigurationClick
} from '../../MainPartAnalysis/MainPartAnalysisActions'
import { useAllData } from '../PartAnalysisSelector'
import { getDropdownPosition } from '../PartAnalysisService'
import { NewConfigurationButton, NewPrinterMaterialButton } from './Buttons'
import DropDownListMenu from 'Scenes/Components/CastorDDLMenu/DropDownListMenu'
import { Feature, FeatureComponentId } from 'Services/models/Features'

import { ReactComponent as Plus } from 'assets/img/svg/icons/plus-circle.svg'

const PlusIcon: any = Plus

interface ConfigurationNewButtonsProps {
	cluster: any
	showDropDown: boolean
	setShowDropDown: Function
}

export const ConfigurationNewButtons: FC<ConfigurationNewButtonsProps> = ({
	cluster,
	showDropDown,
	setShowDropDown
}) => {
	const disableAddNewConfiguration = Feature.isFeatureOn(
		FeatureComponentId.DISABLE_NEW_CONFIGURATION_BUTTON
	)

	if (!!cluster || disableAddNewConfiguration) return <></>

	return (
		<div
			id="add-new-configuration-tab"
			className="add-new-button"
			onClick={(e: any) => {
				e.preventDefault()
				e.stopPropagation()
				setShowDropDown(true)
			}}
		>
			<PlusIcon
				className={cx({
					active: showDropDown
				})}
			/>
		</div>
	)
}

export const ConfigurationDropDown: FC<any> = ({
	showDropDown,
	setShowDropDown,
	configurationCount
}) => {
	const partAnalysis = useAllData()
	const dispatch = useDispatch()
	const parentId = 'add-new-configuration-tab'
	const containerId = 'part-analysis-tab--header'
	const [parentPosition, setParentPosition] = useState<any>({})

	const { cluster, isWeightReductionPart, disablePart, disableRibbonInfo } =
		partAnalysis

	const onAddNewConfigurationClick = () => {
		dispatch(addConfigurationClick())
	}

	const onAddSimpleConfigurationClick = () => {
		dispatch(addSimpleConfigurationClick())
	}

	const getParentPosition = useCallback(() => {
		const object = document.getElementById(parentId)
		const container = document.getElementById(containerId)

		const { left, top } = getDropdownPosition(object, container)

		setParentPosition({
			left: left,
			top: top
		})
	}, [showDropDown])

	useEffect(() => {
		getParentPosition()
	}, [showDropDown, configurationCount])

	useEffect(() => {
		document.addEventListener('resize', getParentPosition, true)

		return () => {
			document.removeEventListener('resize', getParentPosition, true)
		}
	})

	const getExportMenu = () => {
		const menu = []

		if (
			(!isWeightReductionPart && !disableRibbonInfo && !cluster) ||
			!Feature.isFeatureOn(FeatureComponentId.DISABLE_RIBBON_INFORMATION)
		) {
			menu.push({
				name: '',
				element: (
					<NewConfigurationButton
						onAddNewConfigurationClick={onAddNewConfigurationClick}
						disablePart={disablePart}
					/>
				),
				onClick: () => {}
			})
		}

		menu.push({
			name: '',
			element: (
				<NewPrinterMaterialButton
					onAddSimpleConfigurationClick={onAddSimpleConfigurationClick}
					disablePart={disablePart}
				/>
			),
			onClick: () => {}
		})

		return menu
	}

	return (
		<DropDownListMenu
			dropdownClass="dropdown-tab"
			parentPosition={parentPosition}
			options={getExportMenu()}
			show={showDropDown}
			parentId={parentId}
			onClose={() => setShowDropDown(false)}
		/>
	)
}
