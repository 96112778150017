import { isEmpty, isEqual, some } from 'lodash'

import { printStatusScore } from './Constants'
import { Part } from './models/IPart'
import {
	PartPrintIssue,
	PartThread,
	PartThreadsCollection,
	PrintIssueId
} from './models/PartPrintIssue'
import { getString } from './Strings/StringService'
import { currencyFormatter, getFixedSize } from './Utils/numberService'
import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import { ConfigurationResultTypes } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'

export const getIssue = ({
	printIssuesList,
	printIssueId,
	isPartLevel,
	vector
}: {
	printIssuesList: PartPrintIssue[]
	printIssueId: PrintIssueId
	isPartLevel?: boolean
	vector?: number[]
}) => {
	let issue: PartPrintIssue | undefined
	if (isPartLevel) {
		//part level
		return printIssuesList.find(
			issue => issue.printIssue.id === printIssueId && !issue.configurationId
		)
	}
	if (!vector) {
		//configuration level
		return printIssuesList.find(
			issue =>
				issue.printIssue.id === printIssueId &&
				!!issue.configurationId &&
				(!issue.orientationVector || !issue.orientationVector?.length)
		)
	}
	//orientation level
	issue = printIssuesList.find(
		issue =>
			issue.printIssue.id === printIssueId &&
			isEqual(vector, issue.orientationVector)
	)
	if (!issue) {
		//configuration level
		return printIssuesList.find(
			issue =>
				issue.printIssue.id === printIssueId &&
				!!issue.configurationId &&
				(!issue.orientationVector || !issue.orientationVector?.length)
		)
	}
	return issue
}

export const getPrintIssueById = (
	printIssueId: PrintIssueId,
	printIssuesList: PartPrintIssue[],
	vector?: number[]
) => {
	switch (printIssueId) {
		case PrintIssueId.Size:
		case PrintIssueId.CAD:
			return getIssue({ printIssueId, printIssuesList, isPartLevel: true })
		case PrintIssueId.WallThickness:
		case PrintIssueId.Tolerance:
		case PrintIssueId.Holes:
		case PrintIssueId.Threads:
			return getIssue({ printIssueId, printIssuesList })

		default:
			return getIssue({ printIssueId, printIssuesList, vector })
	}
}

export const getIconNameForScore = (score?: number, isActive?: boolean) => {
	if (score == null) {
		return 'printable'
	}
	if (isActive === false && score !== printStatusScore.passed) {
		return 'printableWithWarning'
	}
	if (score === printStatusScore.passed) {
		return 'printable'
	}
	if (score === printStatusScore.failed) {
		return 'notPrintable'
	}
	return 'borderline'
}

const getPartSizeDimensions = (part: Part) => {
	if (part.boundingBox) {
		return {
			width: part.boundingBox.xmax - part.boundingBox.xmin,
			height: part.boundingBox.ymax - part.boundingBox.ymin,
			depth: part.boundingBox.zmax - part.boundingBox.zmin
		}
	}

	return {
		width: part.width,
		height: part.height,
		depth: part.depth
	}
}

export const getTextForPartSize = (
	quantity: number,
	partSizeIssue?: PartPrintIssue,
	orientationSizeIssue?: PartPrintIssue,
	configurationSizeIssue?: PartPrintIssue,
	part?: Part,
	solution?: any
) => {
	const failReasonsStrings = []
	if (
		orientationSizeIssue?.score === 0 ||
		configurationSizeIssue?.score === 0
	) {
		const message =
			orientationSizeIssue?.message || configurationSizeIssue?.message
		failReasonsStrings.push(message || getString('PART_SIZE_FAILED_REASON'))
	}
	if (partSizeIssue?.score === 0) {
		failReasonsStrings.push(partSizeIssue?.message || '')
	}

	const sizeScore = getSizeScore(
		partSizeIssue,
		orientationSizeIssue,
		configurationSizeIssue
	)

	if (sizeScore === 100) {
		if (part?.boundingBox || part?.isDrawing) {
			const { width, height, depth } = getPartSizeDimensions(part)

			failReasonsStrings.push(
				getString('PART_SIZE_SUCCEED_DIMENSIONS').format(
					getFixedSize(width, 2),
					getFixedSize(height, 2),
					getFixedSize(depth, 2)
				)
			)
			if (solution?.printer?.trayWeightLimitation) {
				failReasonsStrings.push(
					getString('PART_SIZE_SUCCEED_WEIGHT').format(
						Intl.NumberFormat().format(
							(part.volume * solution.printerMaterial.density) / 1000
						)
					)
				)
			}

			if (
				solution?.printerTechnology?.trayDensity &&
				solution?.costDetails?.threeDPrintingCostsBreakDown?.partsPerBuild &&
				solution?.printer
			) {
				failReasonsStrings.push(
					getString('PART_SIZE_SUCCEED_VOLUMETRIC').format(
						currencyFormatter(part.volume, '0,0'),
						getFixedSize(
							(100 *
								part?.volume *
								solution?.costDetails?.threeDPrintingCostsBreakDown
									?.partsPerBuild) /
								(solution?.printer?.trayX *
									solution?.printer?.trayY *
									solution?.printer?.trayZ),
							2
						)
					)
				)
				if (
					solution?.costDetails?.solutionUseTrayDensity &&
					solution?.costDetails?.threeDPrintingCostsBreakDown?.partsPerBuild &&
					quantity >=
						solution?.costDetails?.threeDPrintingCostsBreakDown?.partsPerBuild
				) {
					failReasonsStrings.push(
						getString('PART_SIZE_SUCCEED_VOLUMETRIC_LIMITED_TRAY').format(
							solution?.costDetails?.threeDPrintingCostsBreakDown?.partsPerBuild
						)
					)
				}
			} else {
				failReasonsStrings.push(
					`${
						solution?.costDetails?.threeDPrintingCostsBreakDown?.partsPerBuild
							? getString('PART_SIZE_SUCCEED_FITS_TIME_TRAY').format(
									solution?.costDetails?.threeDPrintingCostsBreakDown
										?.partsPerBuild
							  )
							: ''
					}${
						solution?.printerTechnology?.canStackModelsVertically
							? getString('PART_SIZE_SUCCEED_WITH_STACKING')
							: ''
					}.`
				)
			}
		}
	}
	if (!failReasonsStrings.length) {
		return ''
	}
	return failReasonsStrings.filter(Boolean).join('')
}

export const getTextForPartThreads = (part?: Part) => {
	const failReasonsStrings = []
	const partThreadsDataCollection: PartThreadsCollection =
		(part?.partThreadsDataCollection && part?.partThreadsDataCollection[0]) ||
		{}
	const partThreadsLength = partThreadsDataCollection?.partThreads?.length

	if (partThreadsLength > 0) {
		failReasonsStrings.push(getString('PART_THREADS_GENERIC_MESSAGE'))
	} else {
		failReasonsStrings.push(getString('PART_THREADS_NO_FOUND'))
	}

	return failReasonsStrings.filter(Boolean).join('')
}

export const getMaterialScore = (
	solution: any,
	configuration: any,
	solutionWithError: boolean
) => {
	if (solutionWithError && !configuration.failReason) {
		return 100
	}
	if (!solution) {
		return 0
	}
	if (configuration.resultType === ConfigurationResultTypes.Desktop) {
		return 50
	}
	return 100
}

export const getSmallestThread = (partThreadsDataCollection: any) => {
	let smallestThread = partThreadsDataCollection?.partThreads[0] || {}

	partThreadsDataCollection?.partThreads?.forEach((elem: PartThread) => {
		if (elem.height < smallestThread.height) {
			smallestThread = elem
		}
	})

	return smallestThread
}

export const getResultScore = (partIssue?: PartPrintIssue) =>
	partIssue == null ? 100 : partIssue.score

export const checkResultsIssues = (partsResult: any[]) =>
	some(partsResult, (value: number) => value === 0)

export const getSizeScore = (
	partSizeIssue?: PartPrintIssue,
	orientationSizeIssue?: PartPrintIssue,
	configurationSizeIssue?: PartPrintIssue
) => {
	const partSizeIssueScore = getResultScore(partSizeIssue)
	const orientationSizeIssueScore = getResultScore(orientationSizeIssue)
	const configurationSizeIssueScore = getResultScore(configurationSizeIssue)

	return Math.min(
		partSizeIssueScore,
		orientationSizeIssueScore,
		configurationSizeIssueScore
	)
}

export const getHeatDeformationIssue = (
	heatDeformationOrientationIssue?: PartPrintIssue,
	heatDeformationConfigurationIssue?: PartPrintIssue,
	heatDeformationPartIssue?: PartPrintIssue
) => {
	const sortedPrintIssues = [
		heatDeformationOrientationIssue,
		heatDeformationConfigurationIssue,
		heatDeformationPartIssue
	]
		.filter((printIssue?: PartPrintIssue) => printIssue)
		.sort(
			(printIssueA?: PartPrintIssue, printIssueB?: PartPrintIssue) =>
				(printIssueA?.score || 0) - (printIssueB?.score || 0)
		)

	return sortedPrintIssues[0]
}

export const getToleranceString = (
	tolerancesIssueScore?: number,
	part?: Part,
	solution?: any
) => {
	switch (tolerancesIssueScore) {
		case undefined:
		case 100:
			if (isEmpty(part?.tolerances?.dimensionalMarkups)) {
				if (
					part?.customToleranceValue ===
					ToleranceClass.TOLERANCE_CLASS_IRRELEVANT
				) {
					return getString('NO_TOLERANCE_REQUIREMENT')
				}
				return getString('TOLERANCE_CLASS_PASS').format(
					ToleranceClassName(part?.customToleranceValue || '')
				)
			}

			if (
				isEmpty(part?.tolerances?.dimensionalMarkups) &&
				part?.customToleranceValue === ToleranceClass.TOLERANCE_CLASS_IRRELEVANT
			) {
				return getString('CAD_FILE_MINIMAL_TOLERANCE').format(
					solution?.lowestToleranceValue
				)
			}

			if (
				solution?.lowestToleranceValue &&
				part?.customToleranceValue === ToleranceClass.TOLERANCE_CLASS_IRRELEVANT
			) {
				return getString('PRINT_TOLERANCE_ACCORDING_CAD').format(
					solution?.lowestToleranceValue
				)
			}
			return getString('TOLERANCE_CLASS_PASS').format(
				ToleranceClassName(part?.customToleranceValue || '')
			)

		case 0:
			if (isEmpty(part?.tolerances?.dimensionalMarkups) || false) {
				if (
					part?.customToleranceValue ===
					ToleranceClass.TOLERANCE_CLASS_IRRELEVANT
				) {
					return ''
				}
				return getString('TOLERANCE_CLASS_PASS').format(
					ToleranceClassName(part?.customToleranceValue || '')
				)
			}

			if (
				part?.customToleranceValue === ToleranceClass.TOLERANCE_CLASS_IRRELEVANT
			) {
				return `The part can not be printed due to ${solution?.lowestToleranceValue} mm tolerance requirement found in CAD.`
			}

			if (solution?.lowestToleranceValue) {
				return getString('PRINT_TOLERANCE_ACCORDING_CAD').format(
					solution?.lowestToleranceValue
				)
			}
			return getString('TOLERANCE_CLASS_PASS').format(
				ToleranceClassName(part?.customToleranceValue || '')
			)

		default:
			if (isEmpty(part?.tolerances?.dimensionalMarkups) || false) {
				if (
					part?.customToleranceValue ===
					ToleranceClass.TOLERANCE_CLASS_IRRELEVANT
				) {
					return getString('NO_TOLERANCE_REQUIREMENT')
				}
				return getString('PRINT_TOLERANCE_ACCORDING_CLASS').format(
					ToleranceClassName(part?.customToleranceValue || '')
				)
			}

			if (
				part?.customToleranceValue === ToleranceClass.TOLERANCE_CLASS_IRRELEVANT
			) {
				return `Machining is required in order to achieve the minimal tolerance of ${solution?.lowestToleranceValue} mm, found in the CAD file.`
			}

			if (solution?.lowestToleranceValue) {
				return `To achieve the required minimal ${solution?.lowestToleranceValue} mm tolerance set in CAD, machining is required.`
			}
			return getString('PRINT_TOLERANCE_ACCORDING_CLASS').format(
				ToleranceClassName(part?.customToleranceValue || '')
			)
	}
}

export const getOrientationStabilityString = (
	printStabilityIssue?: PartPrintIssue | undefined
): string => {
	if (printStabilityIssue?.score === 0) {
		return printStabilityIssue?.message || ''
	}
	return getString('ORIENTATION_STABILITY_PASS')
}

export const getCNCOrientedIssueString = (
	orientedCNCIssue?: PartPrintIssue | undefined
): string => {
	// if (!orientedCNCIssue || orientedCNCIssue?.score === 100) {
	//   return getString('CNC_SUPPORT_REMOVAL_SUCCESS_MSG')
	// }
	return orientedCNCIssue?.message || getString('ORIENTED_CNC_PASS_MSG')
}

const ToleranceClassName = (toleranceClassValue: string) => {
	const toleranceClass = Object.entries(ToleranceClass).find(
		obj => obj[1] === toleranceClassValue
	)
	return toleranceClass ? getString(toleranceClass[0]) : toleranceClassValue
}
