import { Part } from './IPart'

export enum FeaStatus {
  awaitingAnalysis = 'awaitingAnalysis',
  step1 = 'step1',
  step2 = 'step2',
  step3 = 'step3',
  completed = 'completed'
}

export enum FeaResult {
  passed = 'passed',
  borderline = 'borderline',
  failed = 'failed'
}

export interface FeaAnalysisResults {
  id: string
  feaProblemHash: string
  feaProjectId: string
  maxDisplacement: number
  maxVonMisses: number
  userInputs: number[] | null
}

export interface ISolutionFea {
  id: string
  configuration: any
  part: Part
  partId: string
  feaAnalysisResults?: FeaAnalysisResults
  status: FeaStatus
  result?: FeaResult
}
