import { isEqual } from 'lodash'

import { checkIsDefaultValue } from '../Customize/CustomizeCO2Cost/CustomizeCO2Service'
import { PRINTER_TECHNOLOGY } from '../NewPartAnalysis/NewPartConfiguration/constants'
import { CUSTOM } from '../NewUploadProject/constants'
import { Feature } from 'Services/models/Features'
import { IDefaultProjectScenario } from 'Services/models/IDefaultProjectScenario'
import {
	IOnboardingWizardStep,
	IStepContent
} from 'Services/models/IOnboardingWizardStep'
import { IUserFilter } from 'Services/models/IUserFilter'

const checkStepFeatures = (
	step: IOnboardingWizardStep,
	features: Feature[]
) => {
	const filteredContent = step.content.filter(component =>
		component.feature ? Feature.isFeatureOn(component.feature, features) : true
	)
	step.content = filteredContent
	if (!filteredContent.length) {
		step.show = false
	}
	return step
}

export const filterStepsAndContentByFeatures = (
	wizardSteps: IOnboardingWizardStep[],
	features: Feature[]
) => {
	return wizardSteps.map(step => checkStepFeatures(step, features))
}

export const isCustomProjectScenarioChanged = (
	baseDefaultProjectScenarios: IDefaultProjectScenario[] = [],
	projectScenarioSettings: IDefaultProjectScenario[] = []
) => {
	const indexOfCustomProjectScenario = baseDefaultProjectScenarios.findIndex(
		scenario => scenario.key === CUSTOM
	)
	return indexOfCustomProjectScenario >= 0
		? Object.keys(
				baseDefaultProjectScenarios[indexOfCustomProjectScenario]
		  ).some(
				key =>
					baseDefaultProjectScenarios[indexOfCustomProjectScenario][
						key as keyof IDefaultProjectScenario
					] != // we should leave non-strict comparison because values for the key could be either string or number
					projectScenarioSettings[indexOfCustomProjectScenario][
						key as keyof IDefaultProjectScenario
					]
		  )
		: false
}

const sortUserFiltersByName = (array: IUserFilter[]) => {
	return array.sort((a, b) => {
		if (a.name < b.name) {
			return -1
		}
		if (a.name > b.name) {
			return 1
		}
		return 0
	})
}

export const isSolutionFilteringChanged = (
	defaultFilters: IUserFilter[],
	userFilters: IUserFilter[]
) => {
	const defaultFiltersSorted = sortUserFiltersByName(defaultFilters)
	const userFiltersSorted = sortUserFiltersByName(userFilters)

	return defaultFiltersSorted.some(
		(filter, index) =>
			filter.on !== userFiltersSorted[index].on ||
			(userFiltersSorted[index].name === PRINTER_TECHNOLOGY &&
				!isEqual(filter.value, userFiltersSorted[index].value))
	)
}

export const isTransportationTableChanged = (
	co2Data: Record<string, any>,
	settingsData: Record<string, any>
) => {
	const defaultCO2Data = []
	for (const co2Key in co2Data) {
		const data = co2Data[co2Key].data
		const defaultData = []

		for (const key in data) {
			const isDefault = checkIsDefaultValue(
				data,
				key,
				settingsData.defaultSettings
			)
			defaultData.push(isDefault)
		}

		defaultCO2Data.push(defaultData.some((data: boolean) => !data))
	}

	return defaultCO2Data.some((data: boolean) => data)
}

export const isContentPresent = (
	stepContent: IStepContent[],
	contentID: number
) => {
	return stepContent.some(content => content.id === contentID)
}
