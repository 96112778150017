import { useEffect, useState } from 'react'

import { toFixedOnlyIfNeeded } from 'Services/global/toFixedOnlyIfNeeded'

const renderValue = (value: number, isDefault: boolean) => {
	const defaultString = isDefault ? ' (Default)' : ''
	const percentageValue = value * 100
	return `${toFixedOnlyIfNeeded(percentageValue)}% ${defaultString}`
}

export const useRenderValues = (value: number, isDefault: boolean) => {
	const [numberValue, setNumberValue] = useState(value * 100)
	const [placeholder, setPlaceholder] = useState(renderValue(value, isDefault))
	const [isError, setIsError] = useState(false)

	useEffect(() => {
		setNumberValue(toFixedOnlyIfNeeded(value * 100))
		setPlaceholder(renderValue(value, isDefault))
	}, [value])

	return { numberValue, placeholder, isError, setNumberValue, setIsError }
}

export const onApplyAllMetadataData = (
	metadataCostNames: any,
	userSettings: any,
	updateAllMetadataData: Function
) => {
	updateAllMetadataData(metadataCostNames, userSettings)
}

export const onUpdateMetadataData = (
	data: number,
	metadataKey: any,
	updateMetadataData: Function
) => {
	updateMetadataData(data, metadataKey)
}

export const onEditToggleChange = (
	isEditOn: boolean,
	metadataKey: any,
	metadataEditToggle: Function,
	metadataApplyToggle: Function
) => {
	metadataEditToggle(isEditOn, metadataKey)
	if (isEditOn === true) {
		metadataApplyToggle(false, metadataKey)
	}
}

export const onApplyToggle = (
	isEditOn: boolean,
	metadataKey: any,
	metadataApplyToggle: Function,
	metadataEditToggle: Function,
	onEditToggle: Function
) => {
	metadataApplyToggle(true, metadataKey)
	onEditToggle(isEditOn, metadataKey, metadataEditToggle, metadataApplyToggle)
}

export const onEditAllToggle = (
	metadataEditToggle: Function,
	metadataEditAllToggle: Function,
	metadataCostNames: any,
	isEditAllMetadataCost: boolean
) => {
	for (const metadataKey in metadataCostNames) {
		if (metadataCostNames[metadataKey].edit === isEditAllMetadataCost) {
			metadataEditToggle(!metadataCostNames[metadataKey].edit, metadataKey)
		}
	}
	metadataEditAllToggle(!isEditAllMetadataCost)
}
