import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx'
import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'

import { withStyles } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Close from '@material-ui/icons/Close'
import cx from 'classnames'
import map from 'lodash/map'

import PoweredBy from '../../../../PoweredBy'
import UserMenu from '../../../../UserMenu/UserMenu.js'
import DraggableList from './DraggableList'
import ProjectsToolBar from './ProjectsToolBar'
import CollapseViewItem from './ViewItem/CollapseViewItem'
import {
	IProps,
	IState,
	Route
} from 'Scenes/Components/thirdParty/CreativeTim/components/Sidebar/SideBarTypes'
import SidebarWrapper from 'Scenes/Components/thirdParty/CreativeTim/components/Sidebar/SidebarWrapper'
import {
	PROJECTS_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getTheme } from 'themes/getTheme'

import './SideBar.scss'

const { showPoweredBy } = getTheme()

const ListItemTSX: any = ListItem
const ListTSX: any = List
const ListItemIconTSX: any = ListItemIcon
const ListItemTextTSX: any = ListItemText
const CollapseTSX: any = Collapse
const HiddenTSX: any = Hidden
const DrawerTSX: any = Drawer

const projectIndex = 3

class Sidebar extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		const projectsRoute = PROJECTS_ROUTE.substring(0, PROJECTS_ROUTE.length - 4)
		super(props)
		this.state = {
			openAvatar: false,
			openComponents: this.activeRoute(projectsRoute),
			openForms: this.activeRoute(projectsRoute),
			openTables: this.activeRoute(projectsRoute),
			openMaps: this.activeRoute(projectsRoute),
			openPages: this.activeRoute(projectsRoute),
			openProjects: true
		}
		this.activeRoute.bind(this)
	}

	componentDidMount() {
		this.updateState(this.props.routes)
	}

	updateStateByName(name: string, value: boolean) {
		this.setState({ [name]: value })
	}

	// verifies if routeName is the one active (in browser input)
	activeRoute(routeName: string, folderId?: string) {
		const replacedRoute = this.props.location.pathname.replace(
			'clusterAnalysis',
			'projects'
		)

		const checkActivePath =
			replacedRoute.indexOf(routeName?.replace(USER_HOME_ROUTE, '')) > -1

		//if we have folder we need to find it by projectId or activePath
		if (folderId) {
			const projectId = replacedRoute.split('/')?.[projectIndex]
			const routes = this.props.routes
			return (
				(!!projectId &&
					!!routes?.find(route => {
						return route?.views?.find(view => {
							return view?.id === projectId && folderId === view?.folderId
						})
					})) ||
				checkActivePath
			)
		}

		return checkActivePath
	}

	openCollapse(collapse: string) {
		let st: Record<string, boolean> = {}
		st[collapse] = !this.state[collapse]
		this.setState(st)
	}

	updateState(routes: Route[]) {
		routes.forEach((prop: Route) => {
			let fatherName = ''
			let fatherStateNames: string[] = []

			if (prop.collapse) {
				const isActive = this.activeRoute(prop.path)
				if (prop.state) {
					fatherStateNames.push(prop.state)
					fatherName = prop.state
				}

				if (isActive && this.state[fatherName] == null) {
					this.updateStateByName(fatherName, true)
				}
			}
			prop?.views?.forEach((prop: Route) => {
				const isPropActive = this.activeRoute(prop.path)

				if (prop.collapse && prop.state) {
					fatherStateNames.push(prop.state)
					fatherName = prop.state
				}

				fatherStateNames.forEach((fatherStateName: string) => {
					if (this.state[fatherStateName] == null) {
						this.updateStateByName(fatherStateName, true)
					}
				})

				if (isPropActive && this.state[fatherName] == null) {
					this.updateStateByName(fatherName, true)
				}
				// add states of nested folders
				if (prop.collapse && prop.views?.length) {
					this.updateState(prop.views)
				}
			})
		})
	}

	render() {
		const {
			classes,
			color,
			logo,
			href,
			image,
			logoText,
			routes,
			bgColor = 'blue',
			rtlActive,
			personalizedLightUser,
			clickableLogo,
			onboardingCompleted,
			showCreateNewFolder
		} = this.props
		const searchProjectsInSidebar = Feature.isFeatureOn(
			FeatureComponentId.SEARCH_PROJECTS_IN_SIDEBAR
		)
		const userFoldersInSidebar = Feature.isFeatureOn(
			FeatureComponentId.USER_FOLDERS_IN_SIDEBAR
		)
		const projectsToolBar = searchProjectsInSidebar || userFoldersInSidebar
		const userPanel = Feature.isFeatureOn(FeatureComponentId.USER_PANEL)
		const quickCADUploadIsOn = Feature.isFeatureOn(
			FeatureComponentId.QUICK_CAD_UPLOAD
		)
		const onboardingWizardIsOn = Feature.isFeatureOn(
			FeatureComponentId.ONBOARDING_WIZARD
		)
		const hideMenuList =
			(quickCADUploadIsOn && !personalizedLightUser) ||
			(onboardingWizardIsOn && !onboardingCompleted)
		const replacedRoute = this.props?.location?.pathname?.replace(
			'clusterAnalysis',
			'projects'
		)
		const itemId = replacedRoute?.split('/')[3]

		let links = (
			<ListTSX
				className={cx(classes.list, {
					hidden: hideMenuList
				})}
			>
				{map(routes, (prop: Route, propKey: number) => {
					if (prop.redirect || !prop.sidebar) {
						return null
					}
					let fatherStateNames = []
					if (prop.collapse) {
						const isActive = this.activeRoute(prop.path)
						if (prop.state) {
							fatherStateNames.push(prop.state)
						}
						const navLinkClasses = cx(classes.itemLink, {
							[classes.collapseActive]: isActive
						})
						const itemText = cx(classes.itemText, {
							[classes.itemTextRTL]: rtlActive
						})
						const collapseItemText = cx(classes.collapseItemText, {
							[classes.collapseItemTextRTL]: rtlActive
						})
						const itemIcon = cx(classes.itemIcon, {
							[classes.itemIconRTL]: rtlActive
						})
						const caret = cx(classes.caret, {
							[classes.caretRTL]: rtlActive
						})

						const Icon: JSX.Element | any = prop.icon
						return (
							<ListItemTSX key={propKey + prop.name} className={classes.item}>
								<NavLink
									to={'#'}
									className={cx(navLinkClasses, 'top-nav-link')}
									onClick={() => this.openCollapse(prop.state)}
								>
									<ListItemIconTSX className={itemIcon}>
										{prop.icon ? <Icon /> : <></>}
									</ListItemIconTSX>
									<ListItemTextTSX
										title={prop.name}
										primary={prop.name}
										secondary={
											<b
												className={cx(caret, {
													[classes.caretActive]: this.state[prop.state]
												})}
											/>
										}
										disableTypography={true}
										className={`${itemText} sidebar--text`}
										id="sidebar-my-projects"
									/>
								</NavLink>
								<CollapseTSX in={this.state[prop.state]} unmountOnExit>
									{prop.projectsToolBar && (
										<ProjectsToolBar
											itemIcon={itemIcon}
											updateState={this.updateState.bind(this)}
										/>
									)}
									{prop.draggableViews ? (
										<DraggableList
											list={prop}
											listIndex={propKey}
											caret={caret}
											state={this.state}
											itemIcon={itemIcon}
											itemText={itemText}
											classes={this.props.classes}
											color={this.props.color}
											rtlActive={this.props.rtlActive}
											collapseItemText={collapseItemText}
											showPoweredBy={showPoweredBy}
											userPanel={userPanel}
											activeRoute={this.activeRoute.bind(this)}
											openCollapse={this.openCollapse.bind(this)}
											currentPathname={this.props.location.pathname}
										/>
									) : (
										<ListTSX
											className={cx(
												classes.list,
												'sidebar-collapse',
												classes.collapseList,
												{
													'without-panel': !userPanel,
													'with-powered-by': showPoweredBy
												}
											)}
											id="collapseScroll"
										>
											{prop?.views?.map((propView: Route, viewKey: number) => {
												if (prop.redirect) {
													return null
												}
												return (
													<CollapseViewItem
														prop={propView}
														key={viewKey + propKey + propView.name}
														keyProp={
															viewKey + +propKey + prop.name + propView.name
														}
														collapseItemText={collapseItemText}
														itemIcon={itemIcon}
														itemText={itemText}
														caret={caret}
														classes={this.props.classes}
														color={this.props.color}
														rtlActive={this.props.rtlActive}
														activeRoute={(path: string, id?: string) =>
															this.activeRoute(path, id)
														}
														openCollapse={(collapseState: string) =>
															this.openCollapse(collapseState)
														}
														state={this.state}
													/>
												)
											})}
										</ListTSX>
									)}
								</CollapseTSX>
							</ListItemTSX>
						)
					}
					const navLinkClasses = cx(classes.itemLink, {
						[classes[color]]: this.activeRoute(prop.path)
					})
					const itemText = cx(classes.itemText, {
						[classes.itemTextRTL]: rtlActive
					})
					const itemIcon = cx(classes.itemIcon, {
						[classes.itemIconRTL]: rtlActive
					})
					const Icon: JSX.Element | any = prop.icon

					return (
						<ListItemTSX key={propKey} className={classes.item}>
							<NavLink to={prop.path} className={navLinkClasses}>
								<ListItemIconTSX className={itemIcon}>
									{prop.icon ? <Icon /> : <></>}
								</ListItemIconTSX>
								<ListItemTextTSX
									title={prop.name}
									primary={prop.name}
									disableTypography={true}
									className={`${itemText} sidebar--text`}
								/>
							</NavLink>
						</ListItemTSX>
					)
				})}
			</ListTSX>
		)

		const logoNormal = cx(classes.logoNormal, {
			[classes.logoNormalRTL]: rtlActive
		})
		const logoMini = cx(classes.logoMini, {
			[classes.logoMiniRTL]: rtlActive
		})
		const logoClasses = cx(classes.logo, {
			[classes.whiteAfter]: bgColor === 'white'
		})
		let brand = (
			<div className={logoClasses} >
				{clickableLogo ? (
					<a href={href} className={logoMini}>
						<img src={logo} alt="logo" className={classes.img} data-qa='dats-qa-main-logo' />
					</a>
				) : (
					<div className={logoMini}>
						<img src={logo} alt="logo" className={classes.img} />
					</div>
				)}
				<a className={logoNormal}>{logoText}</a>
				<div
					className="close-sidebar"
					onClick={() => this.props.handleDrawerToggle()}
				>
					<Close />
				</div>
			</div>
		)
		const drawerPaper = cx(classes.drawerPaper, {
			[classes.drawerPaperRTL]: rtlActive,
			'side-bar-block': true
		})
		const sidebarWrapper = cx(classes.sidebarWrapper, {
			[classes.sidebarWrapperWithPerfectScrollbar]:
				navigator.platform.indexOf('Win') > -1,
			'without-panel': !userPanel,
			'with-powered-by': showPoweredBy,
			'with-create-new-folder': showCreateNewFolder,
			'with-projects-search-bar': projectsToolBar
		})

		return (
			<div ref="mainPanel">
				<HiddenTSX mdUp>
					<DrawerTSX
						variant="persistent"
						letiant="temporary"
						anchor={rtlActive ? 'left' : 'right'}
						open={this.props.open}
						classes={{
							paper: drawerPaper + ' ' + classes[bgColor + 'Background']
						}}
						onClose={this.props.handleDrawerToggle}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						{brand}
						<SidebarWrapper
							className={sidebarWrapper}
							headerLinks={<UserMenu addMargin={true} />}
							links={links}
							itemId={itemId}
						/>
						<PoweredBy columnDirection />
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{ backgroundImage: 'url(' + image + ')' }}
							/>
						) : null}
					</DrawerTSX>
				</HiddenTSX>
				<HiddenTSX smDown>
					<DrawerTSX
						variant="persistent"
						style={{ top: this.props.isTrial ? 64 : 0 }}
						anchor={rtlActive ? 'right' : 'left'}
						letiant="permanent"
						open
						classes={{
							paper: drawerPaper + ' ' + classes[bgColor + 'Background']
						}}
					>
						{brand}
						<br />
						<br />
						<SidebarWrapper
							className={sidebarWrapper}
							links={links}
							itemId={itemId}
						/>
						<PoweredBy
							columnDirection
							className="sidebar-poweredby"
							logoClassName="sidebar-poweredby--logo"
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{ backgroundImage: 'url(' + image + ')' }}
							/>
						) : null}
					</DrawerTSX>
				</HiddenTSX>
			</div>
		)
	}
}

const sidebarStyleTSX: any = sidebarStyle

export default memo(withStyles(sidebarStyleTSX)(Sidebar))
