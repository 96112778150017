import { uniqBy } from 'lodash'

import { Action } from '../../../../global actions/ActionModels'
import {
	ADMIN_3D_TECH_PARAMETERS_UPDATED,
	ADMIN_PRINTER_TECH_NEW_ASTM_ISO_NAME_SELECTED,
	GET_ADMIN_3D_TECH_PARAMETERS_EDIT_SELECTED,
	GET_ADMIN_3D_TECH_PARAMETERS_GOT_ERROR,
	GET_ADMIN_3D_TECH_PARAMETERS_SUCCESS
} from '../../../../global actions/types'
import { astmIsoName, OTHER } from './constants'

export class AdminPrintingTechnologyInitialState {
	readonly printers: Array<any> = []
	readonly showNoParametersAlert: boolean = false
	readonly pageNumber: number = 1
	readonly isLastPage: boolean = false
	readonly showPagination: boolean = false
	readonly showAdminPrintersAlert: boolean = false
	readonly selectedEditPrinterTechnology: any = null
	readonly loading: boolean = false
	readonly printersTechParametersData: any = null
	readonly printersTechParametersAstmIsoNameList: any = null
	readonly isNewAstmIsoNameDisabled: boolean = true
}

const initialState: AdminPrintingTechnologyInitialState =
	new AdminPrintingTechnologyInitialState()

const adminPrintingTechnologyReducer = (
	state = initialState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case GET_ADMIN_3D_TECH_PARAMETERS_SUCCESS:
			const {
				data: { techParametersData }
			} = payload
			const printersTechParametersByAstmIsoName = uniqBy(
				techParametersData,
				astmIsoName
			)
			let printersTechParametersAstmIsoNameList =
				printersTechParametersByAstmIsoName.map((p: any) => p.astmIsoName)
			printersTechParametersAstmIsoNameList.sort((a, b) => (b < a ? 1 : -1))
			printersTechParametersAstmIsoNameList.push(OTHER)
			return {
				...state,
				printersTechParametersData: techParametersData,
				showNoParametersAlert: !techParametersData.length,
				printersTechParametersAstmIsoNameList:
					printersTechParametersAstmIsoNameList
			}
		case GET_ADMIN_3D_TECH_PARAMETERS_GOT_ERROR:
			return { ...state, showAdminPrintersAlert: true, loading: false }

		case GET_ADMIN_3D_TECH_PARAMETERS_EDIT_SELECTED:
			return {
				...state,
				selectedEditPrinterTechnology: payload
			}

		case ADMIN_PRINTER_TECH_NEW_ASTM_ISO_NAME_SELECTED:
			const isNewAstmIsoNameDisabled = payload !== OTHER

			return {
				...state,
				isNewAstmIsoNameDisabled
			}

		case ADMIN_3D_TECH_PARAMETERS_UPDATED:
			const updatedPrinterTechnology = payload
			const printersTechParametersByAstmIsoNameUpdating = uniqBy(
				state.printersTechParametersData,
				astmIsoName
			)
			let printersTechParametersAstmIsoNameListUpdating =
				printersTechParametersByAstmIsoNameUpdating.map(
					(p: any) => p.astmIsoName
				)
			printersTechParametersAstmIsoNameListUpdating.sort((a, b) =>
				b < a ? 1 : -1
			)
			printersTechParametersAstmIsoNameListUpdating.push(OTHER)
			return {
				...state,
				printersTechParametersData: state.printersTechParametersData.map(
					(printersTech: any) =>
						printersTech.id === updatedPrinterTechnology.id
							? updatedPrinterTechnology
							: printersTech
				),
				printersTechParametersAstmIsoNameList:
					printersTechParametersAstmIsoNameListUpdating
			}
		default:
			return state
	}
}

export default adminPrintingTechnologyReducer
