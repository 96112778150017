import { store } from '../../../index'
import { UserRole } from '../UserRole.enum'
import {
	HANDLE_NOTIFICATION,
	USER_SUBSCRIPTION_REQUEST_LOADING,
	USER_SUBSCRIPTION_REQUEST_SUCCEED
} from 'global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { PRINTER_TECHNOLOGY } from 'Scenes/Home/NewPartAnalysis/NewPartConfiguration/constants'
import {
	format2D,
	format3D,
	format3D2D,
	METADATA
} from 'Scenes/Home/NewUploadProject/constants'
import { AdvancedFeatureStatus } from 'Scenes/Home/NewUploadProject/UploadingProject.enum'
import {
	drawingFiles,
	metaDataAllowedFileTypes,
	pdfAllowedFileTypes,
	SUBSCRIPTION_FULL_VERSION_RADIO_VALUE
} from 'Services/Constants'
import { getExtensionFromFileName } from 'Services/getExtensionFromFileName'
import { getJsonItemFromLocalStorage } from 'Services/LocalStorageService'
import { ProjectStatus } from 'Services/models/IProject'
import { sendReNewSubscriptionRequest } from 'Services/Network'
import {
	ACCEPTED_FORMAT_ERROR,
	ERROR_UPDATING_TAGS,
	NOT_PART_FORMAT_ERROR,
	NOT_TWO_D_FORMAT_ERROR,
	OK,
	PARTS_CREDIT_END_POPUP_HEADER,
	SHOW_NOTIFICATION,
	SUBSCRIPTION_REQUEST_SECCEDD,
	SUBSCRIPTION_REQUEST_SECCEDD_HEADER
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export class UploadProjectActionService {
	constructor() {}

	onSubscriptionPopup = (dispatch: any, user: any) => {
		let { isOnPrem } = store.getState().GlobalReducer
		const {
			userOnTrial,
			userDetails: { email },
			roles
		} = user

		let alertPopUpText = userOnTrial
			? getString('PARTS_BUNDLE_END_TRIAL')
			: getString('PARTS_BUNDLE_END_NOT_TRIAL')
		if (isOnPrem) {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: alertPopUpText,
					headerTitle: PARTS_CREDIT_END_POPUP_HEADER,
					showCancel: false,
					alertType: AlertType.WARNING,
					onConfirm: () => {
						dispatch({ type: ALERT_POPUP_CANCELED })
					}
				}
			})
		} else {
			const lightUser = roles.includes(UserRole.LIGHT)
			alertPopUpText = lightUser
				? getString('UPLOAD_PAGE_MSG_LIGHT_USER')
				: alertPopUpText
			const alertConfirmText = lightUser
				? getString('CONNECT_CASTOR')
				: `${getString('UPDATE_NOW')}!`
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: alertPopUpText,
					headerTitle: PARTS_CREDIT_END_POPUP_HEADER,
					showCancel: false,
					alertType: AlertType.WARNING,
					onConfirm: () => {
						this.openConnectCastorPopup(dispatch, email)
					},
					confirmText: alertConfirmText
				}
			})
		}
	}

	openConnectCastorPopup = (dispatch: any, userEmail: string) => {
		dispatch({ type: ALERT_POPUP_CANCELED })
		this.sendConnectCastorUserChoise(dispatch, userEmail)
	}

	sendConnectCastorUserChoise = (dispatch: any, userEmail: string) => {
		dispatch({ type: USER_SUBSCRIPTION_REQUEST_LOADING })

		sendReNewSubscriptionRequest(
			SUBSCRIPTION_FULL_VERSION_RADIO_VALUE,
			userEmail
		)
			?.then((response: any) => {
				this.sendSubscriptionRequestSucceed(dispatch)
			})
			?.catch((error: any) => {
				console.log(error)
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: error.validationMessage || ERROR_UPDATING_TAGS
					}
				})
			})
	}

	sendSubscriptionRequestSucceed = (dispatch: any) => {
		dispatch({ type: USER_SUBSCRIPTION_REQUEST_SUCCEED })
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: SUBSCRIPTION_REQUEST_SECCEDD,
				headerTitle: SUBSCRIPTION_REQUEST_SECCEDD_HEADER,
				alertType: AlertType.SUCCESS,
				showCancel: false,
				onConfirm: () => {
					dispatch({ type: ALERT_POPUP_CANCELED })
				},
				confirmText: OK
			}
		})
	}
}

export const shouldShowUnitTypeSelector = (
	filesToUpload: any,
	notSupportedUnitTypeFormats: any,
	allowNumberExtensionFeatureOn: boolean
) => {
	notSupportedUnitTypeFormats = notSupportedUnitTypeFormats.map((type: any) =>
		type.substr(1)
	)
	for (let file of filesToUpload) {
		const fileName = file.name
		const extension = getExtensionFromFileName(
			fileName,
			allowNumberExtensionFeatureOn
		)
		if (notSupportedUnitTypeFormats.includes(extension.toLowerCase())) {
			return true
		}
	}
	return false
}

export const getDisplayFilterItemsList = (
	filterToggles: any,
	filters: any,
	filterValues?: any
) => {
	const itemList: any = []
	Object.keys(filterToggles)
		.filter(key => {
			if (filterToggles[key]) {
				const filter = filters.find((filter: any) => filter.name === key)
				const printingTechnologyValue =
					filter.name == PRINTER_TECHNOLOGY && filterValues[key]

				// return false if:
				// - it's mandatory,showAsDefault and checked
				// - if printingTechnology checked and value is not custom
				return !(
					!printingTechnologyValue &&
					filter.mandatory &&
					filterToggles[key] &&
					filter.showAsDefault
				)
			}
		})
		.map(filterValueKey => {
			const filter = filters.find(
				(filter: any) => filter.name === filterValueKey
			)
			itemList.push({
				key: filterValueKey,
				value: getString(filter?.actualName) || filterValueKey,
				allowRemove: !filter?.mandatory
			})
		})
	return itemList
}

export const handleUploadFile = (state: any, action: any) => {
	const {
		file,
		success,
		uploadURLs,
		antivirusMessages,
		antivirusMessagingEndpoint,
		filePrecentage
	} = action.payload

	if (filePrecentage == null) {
		//finished uploaded a file
		const filesToUpload = state.filesToUpload.filter((el: any) => {
			return !fileMatch(el, file)
		})
		const filesUploaded = success
			? [...state.filesUploaded, file]
			: state.filesUploaded
		const filesFailed = !success
			? [...state.filesFailed, file]
			: state.filesFailed
		const totalFiles = [...filesFailed, ...filesUploaded, ...filesToUpload]
		const uploading = filesToUpload.length > 0
		const uploadSuccess =
			filesToUpload.length === 0 &&
			filesFailed.length === 0 &&
			state.progress >= 100
		return {
			...state,
			uploading,
			filesFailed,
			filesUploaded,
			filesToUpload,
			totalFiles,
			uploadSuccess,
			uploadURLs,
			antivirusMessages,
			antivirusMessagingEndpoint
		}
	}

	//during an upload
	const allFilesPercentages = {
		...state.allFilesPercentages,
		[file.name]: filePrecentage
	}
	const allPercentagesCount: any = Object.values(allFilesPercentages).reduce(
		(a: any, c: any) => a + c,
		0
	)

	let progress = allPercentagesCount / state.totalFiles.length
	progress = progress >= 100 ? 100 : progress
	return {
		...state,
		allFilesPercentages,
		progress: progress.toFixed()
	}
}

export const fileMatch = (lhs: any, rhs: any) => {
	return JSON.stringify(lhs) === JSON.stringify(rhs)
}

export const getUploadingStatus = (
	status: string,
	advancedFeaturesStatus: null | string,
	projectStatus: string
) => {
	if (!!advancedFeaturesStatus && projectStatus === ProjectStatus.published) {
		return getString('INITIAL_PREPARING_SOLUTION')
	}

	if (!!advancedFeaturesStatus) {
		return getString(`INITIAL_${advancedFeaturesStatus}`)
	}

	if (status) {
		return getString(`INITIAL_${status}`)
	}

	return getString('INITIAL_PREPARING_SOLUTION')
}

export const hasUserDateExpired = (date?: Date, isAdmin?: boolean) => {
	if (!date || isAdmin) return false
	const currentDate = new Date()
	let expirationDate = new Date(date)
	const isLicenseExist = getJsonItemFromLocalStorage('license')
	if (!isLicenseExist) {
		return currentDate.getTime() > expirationDate.getTime()
	}
	return false
}

export const getFilesWithMaterialAndQuantity = (files: File[]) => {
	return files.map(file => ({
		name: file.name,
		materialType: '',
		materialCategory: '',
		originalMaterial: '',
		materialId: 0,
		productionQuantity: null,
		standardCost: null,
		customToleranceValue: null,
		printingStandards: null
	}))
}

export const getAcceptedFileFormats = (
	is2dUpload: boolean,
	isMetaData: boolean,
	radioButtonSelected: string | null = '',
	acceptedFileTypes: Array<string>
) => {
	if (isMetaData) {
		return metaDataAllowedFileTypes
	}
	if (is2dUpload) {
		return pdfAllowedFileTypes
	}
	if (radioButtonSelected === drawingFiles) {
		return acceptedFileTypes
	}
	return acceptedFileTypes.filter(
		(type: any) => !pdfAllowedFileTypes.includes(type)
	)
}

export const getCorrectFormatType = (
	is2dUpload: boolean,
	drawingMaterial: boolean,
	selectedType: string
) => {
	if (is2dUpload) {
		return format2D
	}
	if (!is2dUpload && drawingMaterial) {
		return format3D2D
	}
	if (selectedType === METADATA) {
		return METADATA
	}
	return format3D
}

export const fileFormatError = (isMetaData: boolean, is2dUpload: boolean) => {
	if (isMetaData) {
		return ACCEPTED_FORMAT_ERROR
	}
	if (is2dUpload) {
		return NOT_TWO_D_FORMAT_ERROR
	}
	return NOT_PART_FORMAT_ERROR
}

export const getAdvancedFeatureStatusStep = (advancedFeatureStatus: string) => {
	if (!advancedFeatureStatus) return 0

	switch (advancedFeatureStatus) {
		case AdvancedFeatureStatus.DONE:
			return 0.66
		case AdvancedFeatureStatus.PART_CONSOLIDATION:
		case AdvancedFeatureStatus.WEIGHT_REDUCTION:
		case AdvancedFeatureStatus.WEIGHT_REDUCTION_AND_PART_CONSOLIDATION:
			return 0.33
		default:
			return 0
	}
}
