import { FC, memo } from 'react'

import CastorLockedParts from 'Scenes/Components/CastorLockedParts'
import { UserRole } from 'Scenes/Home/UserRole.enum'


const CastorLockedPartsTSX: any = CastorLockedParts

interface LockPartsCarsProps {
	amountOfLockedParts: number
	roles: number[]
	contactUsEmail: string
}

const LockPartsCars: FC<LockPartsCarsProps> = ({
	amountOfLockedParts,
	roles,
	contactUsEmail
}) => {
	return (
		<CastorLockedPartsTSX
			cardClassName={`part-card`}
			amountOfLockedParts={amountOfLockedParts}
			lightUser={roles.includes(UserRole.LIGHT)}
			contactUsEmail={contactUsEmail}
		/>
	)
}

export default memo(LockPartsCars)