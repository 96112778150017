import React, { FC, useEffect, useState } from 'react'

import NumberField from '../../../../Components/NumberField'

interface IProps {
  value: number | null
  isEdit: boolean
  editable: boolean
  name: string
  min: number | undefined
  max: number | undefined
  updateDataValue: Function,
  labelKey: string
  error: string | null
}

const CustomizeNumberInput:FC<IProps> = ({
 value,
 labelKey,
 isEdit,
 editable,
 error,
 name,
 min,
 max,
 updateDataValue
}) => {
  const [numberValue, setNumberValue] = useState(value);

  useEffect(() => {
    setNumberValue(value)
  }, [value])

  // prevent updates each time when user is typing (debounce on minimums)
  useEffect(() => {
    let delayDebounceFn: any

    if (value !== numberValue) {
      delayDebounceFn = setTimeout(() => {
        updateDataValue(numberValue, name, labelKey)
      }, 300)
    }

    return () => clearTimeout(delayDebounceFn)
  }, [numberValue])

  if (editable && isEdit) {
    return <>
      <NumberField
        showArrows={false}
        inputClass="customize__input-field"
        value={numberValue}
        error={!!error}
        onChangeValue={(value: number) => {
          setNumberValue(value)
        }}
        minValue={min}
        maxValue={max}
        allowEmpty={true}
        allowZero={true}
        disableCheckMaxValue={true}
        disableCheckMinValue={true}
        disableCopyPaste={true}
      />
      <div className="customize__input-error small">{error}</div>
    </>
  }

  return (
    <span className="with-opacity">
      {value}
    </span>
  )
}

export default CustomizeNumberInput
