import React, { memo, FC, ChangeEvent } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'

import TextField from '../../../../../../../../../Components/TextField/TextField'
import {
  SHIPMENT_FORM_LABEL,
  INVENTORY_FORM_PRICE_LABEL,
  INVENTORY_FORM_QUANTITY_LABEL,
  CHAIN_BENEFITS_FORM_POPUP_EXPLATION
} from '../../../../../../../../../../Services/Strings'
import {
  ChainBenefitsNames,
  IChainBenefits
} from '../../../../../../../../../../Services/models/IChainBenefits'
import '.././CostComparisonTab.scss'

interface IProps {
  chainBenefits: IChainBenefits
  onBenefitValueChange: Function
  configuration: any
  chainBenefitEditType: string
}

const ChainBenefitsFormContent: FC<IProps> = ({
  chainBenefits,
  configuration,
  onBenefitValueChange,
  chainBenefitEditType
}) => {
  const formValidation = (
    validationType: string,
    value: number | undefined
  ) => {
    if (!value) {
      return ''
    }
    const rangeCheck = (min: number, max: number, value: number) => {
      if (value && (value < min || value > max))
        return `numbers range is: ${min}-${max}`
      return ''
    }

    switch (validationType) {
      case 'shipmentCost':
        return rangeCheck(1, 10000, value)
      case 'storagePrice':
        return rangeCheck(0.1, 1000, value)
      case 'storageQuantity':
        return rangeCheck(1, 999999, value)
      default:
        return ''
    }
  }
  const onChangeFrom = (
    event: ChangeEvent<HTMLInputElement>,
    valueName: string,
    benefitsName: string
  ) => {
    onBenefitValueChange(
      configuration.id,
      { [valueName]: parseFloat(event.target.value) },
      benefitsName
    )
  }

  const renderFormField = (fromType: string) => {
    return <div></div>
    // switch (fromType) {
    //   case ChainBenefitsNames.SHIPPING:
    //     return (
    //       <TextField
    //         label={SHIPMENT_FORM_LABEL.format('$')}
    //         labelClassName="financial-form--text-field-label"
    //         labelInfoWrapperClassName="financial-form--text-field-wrapper"
    //         wrapperClassName="financial-form--text-field"
    //         input={{
    //           onChange: (e: any) =>
    //             onChangeFrom(e, 'shipmentCost', ChainBenefitsNames.SHIPPING),
    //           value: chainBenefits[ChainBenefitsNames.SHIPPING]?.shipmentCost,
    //           min: 1,
    //           max: 10000
    //         }}
    //         type="number"
    //         error={formValidation(
    //           'shipmentCost',
    //           chainBenefits[ChainBenefitsNames.SHIPPING]?.shipmentCost
    //         )}
    //       />
    //     )
    //   case ChainBenefitsNames.INVENTORY:
    //     return (
    //       <div>
    //         <TextField
    //           label={INVENTORY_FORM_PRICE_LABEL.format('$/m3')}
    //           labelClassName="financial-form--text-field-label"
    //           labelInfoWrapperClassName="financial-form--text-field-wrapper"
    //           wrapperClassName="financial-form--text-field"
    //           input={{
    //             onChange: (e: any) => {
    //               onChangeFrom(e, 'storagePrice', ChainBenefitsNames.INVENTORY)
    //             },
    //             value:
    //               chainBenefits[ChainBenefitsNames.INVENTORY]?.storagePrice,
    //             min: 0.1,
    //             step: 0.1,
    //             max: 1000
    //           }}
    //           type="number"
    //           error={formValidation(
    //             'storagePrice',
    //             chainBenefits[ChainBenefitsNames.INVENTORY]?.storagePrice
    //           )}
    //         />
    //         <TextField
    //           label={INVENTORY_FORM_QUANTITY_LABEL}
    //           labelClassName="financial-form--text-field-label"
    //           labelInfoWrapperClassName="financial-form--text-field-wrapper"
    //           wrapperClassName="financial-form--text-field"
    //           input={{
    //             onChange: (e: any) => {
    //               onChangeFrom(
    //                 e,
    //                 'storageQuantity',
    //                 ChainBenefitsNames.INVENTORY
    //               )
    //             },
    //             value:
    //               chainBenefits[ChainBenefitsNames.INVENTORY]?.storageQuantity,
    //             min: 1,
    //             max: 999999
    //           }}
    //           type="number"
    //           error={formValidation(
    //             'storageQuantity',
    //             chainBenefits[ChainBenefitsNames.INVENTORY]?.storageQuantity
    //           )}
    //         />
    //       </div>
    //     )
    //   default:
    //     break
    // }
  }
  return (
    <Flexbox flexDirection="column" padding="0 5px 0 0">
      {renderFormField(chainBenefitEditType)}
      <div className="financial-form--popup-explanation-text">
        {CHAIN_BENEFITS_FORM_POPUP_EXPLATION}
      </div>
    </Flexbox>
  )
}

export default memo(ChainBenefitsFormContent)
