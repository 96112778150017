import React, { memo, FC } from 'react'

import IconFactory from '../StarIcon/IconFactory'
import DetailsPopup from '../DetailsPopup'
import './DataTable.scss'

interface Props {
  classNames: string
  iconName: string
  iconClassName: string
  hoverText: string
}

const DataTableIcon: FC<Props> = ({
  classNames = '',
  iconName,
  iconClassName,
  hoverText
}) => (
  <div className={classNames}>
    {' '}
    {hoverText ? (
      <DetailsPopup
        isHover={true}
        data={
          <div className="castor-detail-title part-detail-title">
            {hoverText}
          </div>
        }
        popperDirection="auto"
        popperClassName="info-box-wrapper data-table-row-icon--info-box-wrapper"
        popperContactClassName="info-box-data"
        inPortal
      >
        <IconFactory
          iconName={iconName}
          className={`data-table-row-icon ${iconClassName}`}
        />
      </DetailsPopup>
    ) : (
      <IconFactory
        iconName={iconName}
        className={`data-table-row-icon ${iconClassName}`}
      />
    )}
  </div>
)

export default memo(DataTableIcon)
