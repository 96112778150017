export const noSolutionStub = {
  printer: {
    name: '-',
    technologyName: '-',
    technology: '-'
  },
  material: {
    name: '-'
  },
  cost: 0,
  leadTime: {
    days: '-',
    warning: '-'
  },
  solutionError: true
}
