import React, { memo, FC } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'
import IconFactory from '../StarIcon/IconFactory'
import { getString } from '../../../Services/Strings/StringService'

import './ExpandButton.scss'

interface IProps {
  className?: string
  showExpandIcon?: boolean
}

const ExpandButton: FC<IProps> = ({
  className = '',
  showExpandIcon = true
}) => (
  <div className={`expand-button ${className}`}>
    <Flexbox alignItems="center" className="expand-button--content">
      {showExpandIcon && (
        <IconFactory
          iconName="expand"
          className="expand-button--content--icon"
        />
      )}
      <div className="expand-button--content--text">
        {getString('SEE_MORE')}
      </div>
    </Flexbox>
  </div>
)

export default memo(ExpandButton)
