import { ChangeEvent, FC, memo } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { FormControlLabel, RadioGroup } from '@material-ui/core'

import * as UserActions from '../../../../global actions/UserActions'
import * as CustomizeSupplyChainActions from './CustomizeSupplyChainActions'
import { getString } from '../../../../Services/Strings/StringService'
import CastorRadio from '../../../Components/CastorRadio'
import CustomizeFormSection from '../CustomizeFormSection'
import { defaultSettingScenario } from 'Services/Constants'
import { UPLOAD_PROJECT_SCENARIO_METHODS_HEADER } from 'Services/Strings'

import './CustomizeSupplyChain.scss'

const RadioGroupTSX: any = RadioGroup
const FormControlLabelTSX: any = FormControlLabel

interface IReduxStore {
	user: any
	CustomizeSupplyChainReducer: any
}

interface ICustomizeProps {
	[name: string]: any
	projectScenarioSettings: any
	onDefaultSecarioRadioSwitch: Function
	defaultProjectScenario: string
}

const CustomizeSupplyChainRadioButton: FC<ICustomizeProps> = ({
	projectScenarioSettings,
	onDefaultSecarioRadioSwitch,
	defaultProjectScenario
}) => {
	if (
		!defaultProjectScenario ||
		Number(defaultProjectScenario) > projectScenarioSettings.length
	) {
		defaultProjectScenario = defaultSettingScenario.lowVolume
	}

	const onChangeRadioButton = (value: string) => {
		const choosedScenario = projectScenarioSettings[Number(value) - 1].printJobs
		onDefaultSecarioRadioSwitch(choosedScenario, value)
	}

	return (
		<div className="default-project-scenario-wrapper">
			<CustomizeFormSection
				explanationHeader={getString('USER_SETTINGS_DEFAULT_OBJECTIVES_TITLE')}
				explanationArray={getString('USER_SETTINGS_DEFAULT_OBJECTIVES_ARRAY')}
			></CustomizeFormSection>
			<RadioGroupTSX
				name="select senario"
				class="solution-feature-supply-chain-radio-button"
				value={defaultProjectScenario}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					onChangeRadioButton(e.target.value)
				}
			>
				{projectScenarioSettings.map((item: any) => {
					return (
						<FormControlLabelTSX
							value={item.order.toString()}
							control={<CastorRadio className="step2-radio-button--radio" />}
							label={UPLOAD_PROJECT_SCENARIO_METHODS_HEADER[item.key]}
							classes={{
								root: `analysis-settings-radio-button--label`
							}}
						/>
					)
				})}
			</RadioGroupTSX>
		</div>
	)
}

const mapStateToProps = ({
	CustomizeSupplyChainReducer,
	user: { defaultProjectScenario }
}: IReduxStore) => {
	return {
		...CustomizeSupplyChainReducer,
		defaultProjectScenario
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators(
		{
			...CustomizeSupplyChainActions,
			...UserActions
		},
		dispatch
	)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizeSupplyChainRadioButton)
)
