import { FC, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { RootStateOrAny, useSelector } from 'react-redux'

import List from '@material-ui/core/List'
import cx from 'classnames'

import { Route } from '../SideBarTypes'
import { useProjectsDrag } from './DraggableHooks'
import ProjectsListWrapper from './ProjectsListWrapper'
import usePrevious from 'Services/CustomHooks/usePrevious'

export type IProps = {
	list: Route
	listIndex: number
	classes: Record<string, any>
	color: string
	caret: string
	rtlActive?: boolean
	collapseItemText: string
	itemIcon: string
	itemText: string
	state: {
		[key: string]: boolean
	}
	showPoweredBy?: boolean
	userPanel?: boolean
	currentPathname: string
	isDropDisabled?: boolean
	disableDrag?: boolean
	setIsDropDisabled?: (disabled: boolean) => void
	activeRoute: (routeName: string, folderId?: string) => boolean
	openCollapse: (collapse: string) => void
}

const DraggableList: FC<IProps> = ({
	list,
	classes,
	showPoweredBy,
	userPanel,
	listIndex,
	color,
	caret,
	rtlActive,
	collapseItemText,
	itemIcon,
	itemText,
	state,
	currentPathname,
	activeRoute,
	openCollapse
}) => {
	const [isDropDisabled, setIsDropDisabled] = useState(false)
	const { showCreateNewFolder, projectsSearchPhrase, routes } = useSelector(
		(state: RootStateOrAny) => state.userHome
	)
	const { isLoading: recalculateProjectsLoading } = useSelector(
		(state: RootStateOrAny) => state.CustomizeRecalculateProjectsReducer
	)
	const prevListViews = usePrevious(list.views)
	const { onDragEnd } = useProjectsDrag(routes, setIsDropDisabled)

	// force update droppable list
	const droppableKey =
		prevListViews !== list.views ? Math.random() : list.views?.length

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<List
				className={cx(
					classes.list,
					'sidebar-collapse sidebar-collapse-in-dnd',
					classes.collapseList,
					{
						'without-panel': !userPanel,
						'with-powered-by': showPoweredBy,
						'with-create-new-folder': showCreateNewFolder
					}
				)}
				id="collapseScroll"
			>
				<Droppable droppableId={list.name} key={droppableKey}>
					{(provided, snapshot) => {
						return (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								className={cx('droppable-zone', 'main', {
									'dragging-over': snapshot.isDraggingOver
								})}
								data-qa="data-qa-sidebar-project-list"
							>
								<ProjectsListWrapper
									list={list}
									listIndex={listIndex}
									caret={caret}
									state={state}
									itemIcon={itemIcon}
									itemText={itemText}
									classes={classes}
									color={color}
									rtlActive={rtlActive}
									collapseItemText={collapseItemText}
									activeRoute={activeRoute}
									openCollapse={openCollapse}
									currentPathname={currentPathname}
									disableDrag={
										!!projectsSearchPhrase || recalculateProjectsLoading
									}
									isDropDisabled={isDropDisabled}
									setIsDropDisabled={setIsDropDisabled}
									isDraggingOver={snapshot.isDraggingOver}
								/>
								{provided.placeholder}
							</div>
						)
					}}
				</Droppable>
			</List>
		</DragDropContext>
	)
}

export default DraggableList
