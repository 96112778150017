import React, { FC, memo } from 'react'

import cx from 'classnames'

import ButtonWithLoader from '../ButtonWithLoader'
import DetailsPopup from '../DetailsPopup'
import Devider from '../Devider/Devider'
import { Button } from '../thirdParty/CreativeTim/components'
import { getString } from 'Services/Strings/StringService'

import '../../Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysis.scss'
import './Modal.scss'

const DeviderTSX: any = Devider

interface IProps {
	onConfirm?: Function
	onCancel: Function
	onFooterCancel?: Function
	confirmOptionalText?: string | React.ReactNode
	cancelOptionalText?: string
	loadingCalculation?: boolean
	disabled?: boolean
	showConfirm?: boolean
	showCancel?: boolean
	extraButton?: any
	headerWithoutActions?: boolean
	showReset?: boolean
	onConfirmReset?: Function
	onButtonHoverText?: string
	buttonHoverClassName?: string
}

const renderExtraButton = (extraButton: any) => {
	if (!extraButton) {
		return <div></div>
	}
	const { disabled, loading, onConfirm, text } = extraButton
	return (
		<ButtonWithLoader
			disabled={disabled}
			loading={loading}
			color="primary"
			onClick={onConfirm}
			className="modal-footer-submit-button"
			muiClasses={{
				label: 'part-analysis-button-label',
				root: 'part-analysis-button-root'
			}}
		>
			{text}
		</ButtonWithLoader>
	)
}

const ModalFooter: FC<IProps> = ({
	onConfirm,
	onCancel,
	confirmOptionalText,
	loadingCalculation,
	cancelOptionalText,
	disabled,
	extraButton,
	showCancel = !loadingCalculation,
	showConfirm = true,
	onFooterCancel,
	showReset = false,
	onConfirmReset,
	onButtonHoverText,
	buttonHoverClassName
}) => {
	return (
		<div className="modal-footer">
			<DeviderTSX size="100%" />
			<div className="modal-footer-buttons">
				{showReset && (
					<Button
						color="secondary"
						disabled={loadingCalculation}
						onClick={onConfirmReset}
						className="modal-footer-reset-button"
						muiClasses={{
							label: 'modal-footer-reset-button-label',
							root: 'modal-footer-reset-button-root'
						}}
					>
						{getString('RESET_ALL')}
					</Button>
				)}
				{showCancel && (
					<Button
						color="secondary"
						onClick={onFooterCancel || onCancel}
						disabled={loadingCalculation}
						className="modal-footer-cancel-button"
						muiClasses={{
							label: 'modal-footer-cancel-button-label',
							root: 'modal-footer-cancel-button-root'
						}}
					>
						{cancelOptionalText || getString('CANCEL')}
					</Button>
				)}
				{renderExtraButton(extraButton)}
				{showConfirm && (
					<DetailsPopup
						isHover={!!onButtonHoverText}
						data={onButtonHoverText}
						popperDirection="bottom"
						popperClassName={cx('info-box-wrapper', buttonHoverClassName)}
						popperContactClassName="info-box-data"
					>
						<ButtonWithLoader
							disabled={disabled}
							loading={loadingCalculation}
							color="primary"
							onClick={onConfirm}
							className="modal-footer-submit-button"
							muiClasses={{
								label: 'part-analysis-button-label',
								root: 'part-analysis-button-root'
							}}
							qaDataElementName="data-qa-submit-btn"
						>
							{confirmOptionalText || getString('CONFIRM')}
						</ButtonWithLoader>
					</DetailsPopup>
				)}
			</div>
		</div>
	)
}

export default memo(ModalFooter)
