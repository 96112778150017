import React, { FC, memo } from 'react'

import cx from 'classnames'

import AdditiveMindsButton from './AdditiveMindsButton'
import { AdditiveMindsProps } from './AdditiveMindsInterface'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'

import './index.scss'

const AdditiveMinds: FC<AdditiveMindsProps> = ({
	type,
	className,
	hoverText
}) => {
	return (
		<div className={cx('additive-minds', `type-${type}`, className)}>
			<AdditiveMindsButton type={type} hoverText={hoverText} />
		</div>
	)
}

export default WithFeatureToggleHOC(
	memo(AdditiveMinds),
	FeatureComponentId.SHOW_CONTACT_ADDITIVE_MINDS_BUTTON
)
