import { CHECK_SYSTEM_PARAMETERS, HANDLE_LOADER} from './types'

export const handleLoaderCounter = value => {
  return {
    type: HANDLE_LOADER,
    payload: value
  }
}


export const getSystemParameters = () => {
  const isOnPrem = process.env.REACT_APP_ONPREM === 'true'
  const isAllowRegister = process.env.REACT_APP_ALLOW_REGISTER === 'true'

  return {
    type: CHECK_SYSTEM_PARAMETERS,
    payload: {isOnPrem, isAllowRegister}
  }
}
