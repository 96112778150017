import React from 'react'
import PropTypes from 'prop-types'
import { Menu } from '@material-ui/icons'
import { AppBar, Toolbar, IconButton, Hidden, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core'
import cx from 'classnames'
import headerStyle from '../../variables/styles/headerStyle.jsx'
import UserMenu from '../../../../UserMenu/UserMenu'

function Header({ ...props }) {
  function makeBrand() {
    var name
    props.routes.map((prop, key) => {
      if (prop.path === props.location.pathname) {
        name = prop.navbarName
      }
      return null
    })
    return name
  }
  const { classes, color, width } = props
  const appBarClasses = cx({
    [' ' + classes[color]]: color
  })
  return (
    <AppBar className={classes.appBar + appBarClasses} style={{ width: width || '100%' }}>
      <Toolbar className={classes.container}>
        <div className={classes.flex} />
        <Hidden smDown implementation="css">
          <UserMenu />
        </Hidden>
        <Hidden mdUp>
          <IconButton
            className={classes.appResponsive}
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger'])
}

export default withStyles(headerStyle)(Header)
