import {
	AMOUNT_CHANGED,
	AMOUNT_CHANGED_IN_TECH_ANALYSIS,
	HIDE_ALERT,
	IN_HOUSE_SWITCH_CHANGED,
	IN_HOUSE_TOGGLE_CHANGED_IN_TECH_ANALYSIS_FINAL,
	MATERIAL_CHANGED_IN_TECH_ANALYSIS,
	MATERIAL_TYPE_CHANGED_IN_TECH_ANALYSIS,
	METRICS_CHANGED_IN_TECH_ANALYSIS,
	METRICS_CHANGED_IN_TECH_ANALYSIS_FINAL,
	PART_UPDATED_IN_TECH_ANALYSIS,
	PROJECT_QUANTITY_AMOUNT_CHANGED,
	PROJECT_UPDATE_FAILED,
	PROJECT_UPDATE_SENT,
	PROJECT_UPDATE_SUCCESS,
	RESULT_RECALCULATED_FAILED,
	RESULT_RECALCULATED_SUCCESS,
	SAME_MATERIAL_TOGGLE_CHANGED_IN_TECH_ANALYSIS_FINAL,
	SETUP_TECH_ANALYISIS_COMPONENT,
	SHOW_LEAD_TIME_ALERT,
	SHOW_MATERIAL_ALERT,
	SHOW_PRICE_ESTIMATE_ALERT,
	SHOW_PRINTER_ALERT,
	SHOW_PRIORITY_ALERT,
	SHOW_TECHNOLOGY_ALERT,
	SHOW_WEIGHT_RESULT_ALERT
} from '../../../../global actions/types'
import { toFixedOnlyIfNeeded } from '../../../../Services/global/toFixedOnlyIfNeeded'
import {
	COST_ESTIMATE_INFO,
	COST_ESTIMATE_TITLE_INFO,
	GRAM_UNITS,
	KG_UNITS,
	LEAD_TIME_TEXT,
	LEAD_TIME_TITLE_INFO,
	MATERIAL,
	MATERIAL_COMPARISON_ELONGATION_AT_BREAK,
	MATERIAL_COMPARISON_NAME,
	MATERIAL_COMPARISON_ORIGINAL_COLUMN_NAME,
	MATERIAL_COMPARISON_PERCENT_COLUMN_NAME,
	MATERIAL_COMPARISON_PRINTABLE_COLUMN_NAME,
	MATERIAL_COMPARISON_YIELD_STENGTH,
	MATERIAL_COMPARISON_YOUNG_MODULES,
	MATERIALS_COMPARISON,
	MULTIPLE_PARTS_IN_PROJECT_WARNING,
	NO_RESULT_FOR_CONFIGURATION,
	PRINTING_TECH,
	PRIORITY_ALERT_TEXT,
	PRIORITY_ALERT_TITLE,
	WEIGHT_RESULT_INFO,
	WEIGHT_RESULT_TITLE_INFO
} from '../../../../Services/Strings'
import { getTheme } from '../../../../themes/getTheme'
import { noSolutionStub } from '../noSolutionStub'

const { defaultMaterial } = getTheme()

let updatedPartDelegate
let stateBeforeServerCall
let materials
const INITIAL_STATE = {
	loading: false,
	error: null,
	showingSimpleAlertText: null,
	showingTableAlertFromArray: null,
	showingSimpleAlertTitle: null,
	chosenMaterial: defaultMaterial,
	materialType: defaultMaterial.type,
	metrics: null,
	inHouse: false,
	projectQuantity: 1,
	amountWarningText: null,
	partWeightUnits: '',
	partWeight: null,
	sameMaterial: true
}
let chosenMaterial
let solution
let part
let project
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SETUP_TECH_ANALYISIS_COMPONENT: {
			materials = action.payload.materials
			updatedPartDelegate = action.payload.updatePartFunc
			const metrics = action.payload.metrics
			solution = action.payload.solution
				? action.payload.solution
				: noSolutionStub
			const partWeightData = getPartWeightData(solution)

			const inHouse = action.payload.showInHouseSolutions
			const chosenMaterialId = action.payload.part.material
				? action.payload.part.material.id || action.payload.part.material
				: defaultMaterial.id || defaultMaterial
			chosenMaterial = materialFromMaterialId(chosenMaterialId)
			part = action.payload.part
			project = action.payload.project
			if (!project) {
				console.log('no project warning')
			}
			const projectQuantity = project ? project.quantity : 1
			const amountWarningText = generateAmountWarningText(
				part.countInAssembly,
				projectQuantity
			)
			return {
				...state,
				chosenMaterial,
				materialType: chosenMaterial.type,
				metrics,
				inHouse,
				amountWarningText,
				projectQuantity,
				...partWeightData,
				sameMaterial: metrics.sameMaterial,
				error: solution.solutionError ? NO_RESULT_FOR_CONFIGURATION : null
			}
		}
		case PART_UPDATED_IN_TECH_ANALYSIS: {
			if (!action.payload.part.material && !action.payload.metrics) {
				return state
			}
			const toReturn = { ...state }

			let chosenMaterial = null
			if (action.payload.material) {
				const chosenMaterialName = action.payload.material
				chosenMaterial = materialFromMaterialId(chosenMaterialName)
				toReturn.chosenMaterial = chosenMaterial
				toReturn.materialType = chosenMaterial.type
			}
			if (action.payload.solution) {
				solution = action.payload.solution
					? action.payload.solution
					: noSolutionStub

				const partWeightData = getPartWeightData(solution)

				toReturn.partWeightUnits = partWeightData.partWeightUnits
				toReturn.partWeight = partWeightData.partWeight
				toReturn.metrics = action.payload.metrics
				toReturn.sameMaterial = toReturn.metrics.sameMaterial

				toReturn.error = solution.solutionError
					? NO_RESULT_FOR_CONFIGURATION
					: null
			}
			return toReturn
		}
		case PROJECT_QUANTITY_AMOUNT_CHANGED:
			return {
				...state,
				projectQuantity: action.payload
			}
		case PROJECT_UPDATE_SUCCESS: {
			stateBeforeServerCall = state
			project = action.payload.project
			const amountWarningText = generateAmountWarningText(
				part.countInAssembly,
				project.quantity
			)

			return {
				...state,
				loading: false,
				error: null,
				projectQuantity: action.payload.project.quantity,
				amountWarningText
			}
		}
		case SHOW_LEAD_TIME_ALERT:
			const text = solution.leadTime.warning
				? `${LEAD_TIME_TEXT} \t\n\t\n ${solution.leadTime.warning}`
				: LEAD_TIME_TEXT
			return {
				...state,
				showingSimpleAlertText: text,
				showingSimpleAlertTitle: LEAD_TIME_TITLE_INFO
			}
		case SHOW_WEIGHT_RESULT_ALERT:
			return {
				...state,
				showingSimpleAlertText: WEIGHT_RESULT_INFO,
				showingSimpleAlertTitle: WEIGHT_RESULT_TITLE_INFO
			}
		case SHOW_PRICE_ESTIMATE_ALERT:
			return {
				...state,
				showingSimpleAlertText: COST_ESTIMATE_INFO,
				showingSimpleAlertTitle: COST_ESTIMATE_TITLE_INFO
			}
		case SHOW_PRIORITY_ALERT:
			return {
				...state,
				showingSimpleAlertText: PRIORITY_ALERT_TEXT,
				showingSimpleAlertTitle: PRIORITY_ALERT_TITLE
			}
		case SHOW_PRINTER_ALERT:
			return {
				...state,
				showingSimpleAlertText: action.payload.description,
				showingSimpleAlertTitle: action.payload.name
			}
		case SHOW_TECHNOLOGY_ALERT:
			const techInfo = PRINTING_TECH.find(
				obj => action.payload.toLowerCase() === obj.name.toLowerCase()
			)
			if (!techInfo) {
				return {
					...state,
					showingSimpleAlertText: null,
					showingSimpleAlertTitle: null
				}
			}
			const title = `${techInfo.name} - ${techInfo.fullName}`
			const description = techInfo.description
			return {
				...state,
				showingSimpleAlertText: description,
				showingSimpleAlertTitle: title
			}
		case SHOW_MATERIAL_ALERT:
			if (!solution) {
				console.log('warning ')
				return state
			}
			const { partOriginalWeight, partOriginalWeightUnits } = action.payload
			const youngsModulesDeltaPercentStr =
				chosenMaterial.youngsModulus && solution.material.youngsModulus
					? `${Math.floor(
							(100 * solution.material.youngsModulus) /
								chosenMaterial.youngsModulus
					  )}%`
					: ''
			const yieldStrengthMPaDeltaPercStr =
				chosenMaterial.yieldStrengthMPa && solution.material.yieldStrengthMPa
					? `${Math.floor(
							(100 * solution.material.yieldStrengthMPa) /
								chosenMaterial.yieldStrengthMPa
					  )}%`
					: ''

			const percentDeltaStr = (solution, Original) => {
				if (solution && Original) {
					return `${Math.floor((100 * solution) / Original)}%`
				}
				return ''
			}

			const weightPercentDeltaStr = (
				weight,
				originalWeight,
				weightUnits,
				originalWeightUnits
			) => {
				if (weight && originalWeight) {
					if (weightUnits === KG_UNITS) {
						weight *= 1000
					}
					if (originalWeightUnits === KG_UNITS) {
						originalWeight *= 1000
					}
					return `${Math.floor((100 * weight) / originalWeight)}%`
				}
				return ''
			}

			//each array is a row in the table
			const showingTableAlertFromArray = [
				[
					'',
					MATERIAL_COMPARISON_ORIGINAL_COLUMN_NAME,
					MATERIAL_COMPARISON_PRINTABLE_COLUMN_NAME,
					MATERIAL_COMPARISON_PERCENT_COLUMN_NAME
				],
				[MATERIAL, chosenMaterial.name, solution.material.name, '-'],
				[
					MATERIAL_COMPARISON_YOUNG_MODULES,
					toFixedOnlyIfNeeded(chosenMaterial.youngsModulus),
					toFixedOnlyIfNeeded(solution.material.youngsModulus),
					youngsModulesDeltaPercentStr
				],
				[
					MATERIAL_COMPARISON_YIELD_STENGTH,
					toFixedOnlyIfNeeded(chosenMaterial.yieldStrengthMPa),
					toFixedOnlyIfNeeded(solution.material.yieldStrengthMPa),
					yieldStrengthMPaDeltaPercStr
				],
				[
					MATERIAL_COMPARISON_ELONGATION_AT_BREAK,
					`${toFixedOnlyIfNeeded(chosenMaterial.percentElongationAtBreak)}%`,
					`${toFixedOnlyIfNeeded(solution.material.percentElongationAtBreak)}%`,
					percentDeltaStr(
						solution.material.percentElongationAtBreak,
						chosenMaterial.percentElongationAtBreak
					)
				],
				[
					'Weight',
					`${toFixedOnlyIfNeeded(
						partOriginalWeight
					)} ${partOriginalWeightUnits}`,
					`${toFixedOnlyIfNeeded(state.partWeight)} ${state.partWeightUnits}`,
					weightPercentDeltaStr(
						state.partWeight,
						partOriginalWeight,
						state.partWeightUnits,
						partOriginalWeightUnits
					)
				]
			]
			return {
				...state,
				showingSimpleAlertText: null,
				showingSimpleAlertTitle: MATERIALS_COMPARISON,
				showingTableAlertFromArray
			}
		case HIDE_ALERT:
			return {
				...state,
				showingSimpleAlertText: null,
				showingSimpleAlertTitle: null,
				showingTableAlertFromArray: null
			}
		case MATERIAL_TYPE_CHANGED_IN_TECH_ANALYSIS:
			stateBeforeServerCall = state
			return { ...state, materialType: action.payload }
		case AMOUNT_CHANGED_IN_TECH_ANALYSIS:
			stateBeforeServerCall = state
			return { ...state, amount: action.payload }
		case MATERIAL_CHANGED_IN_TECH_ANALYSIS:
			stateBeforeServerCall = state
			return {
				...state,
				chosenMaterial: action.payload,
				loading: true,
				error: null
			}
		case METRICS_CHANGED_IN_TECH_ANALYSIS:
			stateBeforeServerCall = state
			if (state.metrics === action.payload) {
				return state
			}
			return { ...state, metrics: action.payload }
		case SAME_MATERIAL_TOGGLE_CHANGED_IN_TECH_ANALYSIS_FINAL:
			stateBeforeServerCall = state
			return {
				...state,
				sameMaterial: action.payload,
				loading: true,
				error: null
			}
		case IN_HOUSE_TOGGLE_CHANGED_IN_TECH_ANALYSIS_FINAL:
			stateBeforeServerCall = state
			return {
				...state,
				inHouse: action.payload
			}
		case METRICS_CHANGED_IN_TECH_ANALYSIS_FINAL:
			stateBeforeServerCall = state
			return { ...state, metrics: action.payload, loading: true, error: null }
		case RESULT_RECALCULATED_SUCCESS: {
			part = action.payload.part
			solution = action.payload.solution
				? action.payload.solution
				: noSolutionStub
			const metrics = action.payload.metrics
			const amountWarningText = generateAmountWarningText(
				part.countInAssembly,
				project.quantity
			)

			chosenMaterial = materialFromMaterialId(part.material)
			return {
				...state,
				chosenMaterial: chosenMaterial,
				materialType: chosenMaterial.type,
				metrics,
				inHouse: action.payload.showInHouseSolutions,
				loading: false,
				amountWarningText,
				sameMaterial: metrics.sameMaterial,
				error: solution.solutionError ? NO_RESULT_FOR_CONFIGURATION : null
			}
		}
		case PROJECT_UPDATE_FAILED:
		case RESULT_RECALCULATED_FAILED:
			// const lastState = stateBeforeServerCall ? stateBeforeServerCall : state
			return {
				...state,
				error: action.payload,
				loading: false,
				solution: noSolutionStub
			}
		case IN_HOUSE_SWITCH_CHANGED:
			return { ...state, inHouse: action.payload, loading: true, error: null }
		case PROJECT_UPDATE_SENT:
			return { ...state, error: null, loading: true }
		default:
			return state
	}
}

const materialFromMaterialId = id => {
	return materials.find(material => material.id === id)
}

const generateAmountWarningText = (countInAssembly, projectQuantity) => {
	const amountWarningText =
		!countInAssembly || countInAssembly === 1
			? null
			: MULTIPLE_PARTS_IN_PROJECT_WARNING.replace(
					'[1]',
					`${countInAssembly}`
			  ).replace('[2]', `${countInAssembly * projectQuantity}`)
	return amountWarningText
}

const getPartWeightData = solution => {
	let partWeightUnits = KG_UNITS
	let partWeight = null

	if (solution && solution.weight) {
		partWeight = solution.weight
		if (partWeight < 0.5) {
			partWeight *= 1000
			partWeightUnits = GRAM_UNITS
		}
	}
	return {
		partWeightUnits,
		partWeight
	}
}
