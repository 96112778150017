import React, { FC, useEffect, useState } from 'react'

import { IOtherProps } from '../../../Services/models/IOtherProps'

import { ReactComponent as BuyToFlyFit } from '../../../assets/img/svg/buy-fly-full.svg'
import { ReactComponent as BuyToFly } from '../../../assets/img/svg/buy-fly.svg'

interface IProps extends IOtherProps {
	small?: boolean
	className?: string
	smallClassName?: string
	expandIconOnHover?: boolean
}

export const IconBuyToFlyScore: FC<IProps> = ({
	small,
	className = '',
	smallClassName = '',
	expandIconOnHover,
	...props
}) => {
	const scoreBTF = props.score?.toFixed()
	const maxValue = 99
	const scoreIsBigger = scoreBTF > maxValue

	const scoreText = scoreIsBigger ? `> ${maxValue}` : scoreBTF

	const [isBigIcon, setIsBigIcon] = useState(false)
	useEffect(() => {
		setIsBigIcon(!small)
	}, [small])

	if (!isBigIcon) {
		return (
			<BuyToFly
				className={smallClassName}
				data-qa="data-qa-small-benefit-high-buy-to-fly"
				onMouseOver={() => setIsBigIcon(expandIconOnHover || false)}
				{...props}
			/>
		)
	}
	return (
		<div
			className="icon-buy-to-fly"
			data-qa="data-qa-configuration-icon-buy-to-fly"
			onMouseLeave={() => setIsBigIcon(!expandIconOnHover || false)}
		>
			<BuyToFlyFit />
			<span className="text">{scoreText}</span>
		</div>
	)
}
