import { PartPrintIssue, PrintIssueId } from 'Services/models/PartPrintIssue'
import { getString } from 'Services/Strings/StringService'

export const differenceValue = (a: number, b: number) => {
	return Math.abs(a - b) < 1
}

export const isCostObjectValid = (
	costDetails: any,
	isCluster?: boolean
): boolean => {
	// check if difference between values less than 1
	// 1 and 1.01 - is equal because of 0,99 < 1
	// 1 and 1.99 - is equal because of 0,01 < 1
	// 2 and 1.01 - is equal because of 0,99 < 1
	// 2 and 1.99 - is equal because of 0,01 < 1
	const isEqualCost = differenceValue(
		costDetails?.totalCost,
		costDetails?.printingCost
	)

	// check when supplyChain is Off = 0, and also if the value has 0
	const isSupplyZero = costDetails.onlySupplyChainCost === 0

	const propExist =
		typeof costDetails.printingCost === undefined ||
		typeof costDetails.optionalPostProcesssesCost === undefined

	return !(
		isCluster ||
		(isSupplyZero && isEqualCost) ||
		propExist ||
		typeof costDetails.onlySupplyChainCost == undefined
	)
}

export const getProducingLeadTime = (leadTime: any, leadTimeDetails: any) => {
	if (leadTimeDetails && leadTimeDetails.total) {
		return leadTimeDetails.total.value
	}
	return leadTime
}

export const getConfigurationFailReason = (
	configurationIsUnprintable: boolean,
	failedPrintIssuesIds: number[],
	configurationPrintIssues: PartPrintIssue[],
	isSmallPart: boolean
) => {
	if (!configurationIsUnprintable)
		return { configurationFailReason: '', extraFailReasonsCount: 0 }
	const printIssueHints = getString('PRINT_ISSUE_HINTS') || {}
	const configurationFailedOnPartSize = failedPrintIssuesIds.includes(
		PrintIssueId.Size
	)
	const partIsTooSmallOnConfigLevel = configurationPrintIssues.find(
		issue =>
			issue.printIssueId === PrintIssueId.Size &&
			issue.message === getString('PART_MIN_DIMENSIONS_LIMITATION_FAILED')
	)

	const configurationFailReason =
		configurationFailedOnPartSize &&
		(isSmallPart || partIsTooSmallOnConfigLevel)
			? getString('PART_SIZE_TOO_SMALL')
			: printIssueHints[failedPrintIssuesIds[0]]
	const extraFailReasonsCount = failedPrintIssuesIds.length - 1
	return { configurationFailReason, extraFailReasonsCount }
}
