import { AxiosResponse } from 'axios'
import moment from 'moment'
import numeral from 'numeral'

import {
	HANDLE_NOTIFICATION,
	USER_PANEL_ROI_CALCULATION_FINISHED,
	USER_PANEL_ROI_CALCULATION_GOT_ERROR,
	USER_PANEL_ROI_CALCULATION_STARTED
} from '../../../global actions/types'
import {
	benefitsBackground,
	benefitsBackgroundOpac,
	pieBenefitsCo2,
	pieBenefitsInventory,
	pieFailedColor,
	pieNotCostEffectiveColor,
	pieNotSuitableForPrint,
	piePassedColor
} from '../../../Services/colors'
import {
	USER_PANEL_PART_RESULTS_INDEXES,
	USER_PANEL_PRINTING_SAVING_INDEXES
} from '../../../Services/Constants'
import { CADAnalysisResult } from '../../../Services/models/CADAnalysisResult'
import {
	PRINTING_SAVING_TYPES,
	resultsSelectionOptions
} from '../../../Services/models/ResultsSelectionOptions'
import { updateUserPanelROI } from '../../../Services/Network/UserNetwork'
import {
	CONFIGURATION_CALCULATION_ERROR,
	MY_PANEL_DISSAVINGS,
	MY_PANEL_SAVINGS,
	PART,
	PARTS,
	SHOW_NOTIFICATION
} from '../../../Services/Strings'
import { IChartSeriesData } from '../../Components/PieChart'
import {
	ISimpleConfigurationCompany,
	ISimpleConfigurationPrinter,
	SimpleConfigurationSelectorService
} from '../../Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { currencyFormatter } from 'Services/Utils/numberService'

export const createUploadedPartsChartData = (
	printable: number,
	notPrintable: number,
	notCostEffective: number,
	failed: number
): IChartSeriesData[] => {
	const total = failed + notCostEffective + notPrintable + printable

	const notPrintablePercent = calcultePercent(notPrintable, total)
	const failedPercent = calcultePercent(failed, total)
	const notCostEffectivePercent = calcultePercent(notCostEffective, total)
	const printablePercent = calculateRestPercent(
		[notPrintable, failed, notCostEffective],
		total,
		printable
	)

	const notPrintableLabel = countToPieChartLongLabel(
		notPrintable,
		notPrintablePercent
	)
	const notPrintableShortLabel = countToPieChartLabel(notPrintablePercent)

	const printableLabel = countToPieChartLongLabel(printable, printablePercent)
	const printableShortLabel = countToPieChartLabel(printablePercent)

	const notCostEffectiveLabel = countToPieChartLongLabel(
		notCostEffective,
		notCostEffectivePercent
	)
	const notCostEffectiveShortLabel = countToPieChartLabel(
		notCostEffectivePercent
	)

	const failedLabel = countToPieChartLongLabel(failed, failedPercent)
	const failedShortLabel = countToPieChartLabel(failedPercent)

	const series = [
		{
			x: notPrintableShortLabel,
			y: notPrintable,
			label: notPrintableLabel,
			name: 'unprintable',
			stroke: pieFailedColor,
			colorScale: pieFailedColor
		},
		{
			x: printableShortLabel,
			y: printable,
			label: printableLabel,
			name: 'printable',
			stroke: piePassedColor,
			colorScale: piePassedColor
		},
		{
			x: notCostEffectiveShortLabel,
			y: notCostEffective,
			label: notCostEffectiveLabel,
			name: 'notCostEffective',
			stroke: pieNotCostEffectiveColor,
			colorScale: pieNotCostEffectiveColor
		},
		{
			x: failedShortLabel,
			y: failed,
			label: failedLabel,
			name: 'failed',
			stroke: pieFailedColor,
			colorScale: pieNotSuitableForPrint
		}
	]
	return series
}

export const createUploadedPartsPieIndexes = (
	printable: number,
	notPrintable: number,
	notCostEffective: number,
	failed: number
) => {
	return USER_PANEL_PART_RESULTS_INDEXES.map(result => {
		let resultCount = 0
		switch (result.result) {
			case CADAnalysisResult.printable:
				resultCount = printable
				break
			case CADAnalysisResult.notPrintable:
				resultCount = notPrintable
				break
			case CADAnalysisResult.notCostEffective:
				resultCount = notCostEffective
				break
			case CADAnalysisResult.notPrintSuitable:
				resultCount = failed
				break
			default:
				break
		}

		return {
			...result,
			title: result.title.format(resultCount)
		}
	})
}

export const createPrintingSavingChartData = (
	printingSavingUpfront: number = 0,
	printingSavingManufacturing: number = 0,
	printingSavingInventory: number = 0,
	printingSavingCo2: number = 0,
	userCurrencySign: string,
	co2FeatureOn: boolean
): any => {
	let series = []
	let totalSaving = 0

	if (printingSavingUpfront > 0) {
		totalSaving += printingSavingUpfront
	}
	if (printingSavingManufacturing > 0) {
		totalSaving += printingSavingManufacturing
	}
	if (printingSavingInventory > 0) {
		totalSaving += printingSavingInventory
	}
	if (printingSavingCo2 > 0 && co2FeatureOn) {
		totalSaving += printingSavingCo2
	}

	if (printingSavingUpfront > 0) {
		series.push(
			createSavingElement(
				totalSaving,
				printingSavingUpfront,
				benefitsBackground,
				userCurrencySign,
				'printingSavingUpfront'
			)
		)
	}
	if (printingSavingManufacturing > 0) {
		series.push(
			createSavingElement(
				totalSaving,
				printingSavingManufacturing,
				benefitsBackgroundOpac,
				userCurrencySign,
				'printingSavingManufacturing'
			)
		)
	}
	if (printingSavingInventory > 0) {
		series.push(
			createSavingElement(
				totalSaving,
				printingSavingInventory,
				pieBenefitsInventory,
				userCurrencySign,
				'printingSavingInventory'
			)
		)
	}
	if (printingSavingCo2 > 0 && co2FeatureOn) {
		series.push(
			createSavingElement(
				totalSaving,
				printingSavingCo2,
				pieBenefitsCo2,
				userCurrencySign,
				'printingSavingCo2'
			)
		)
	}
	return series
}

const createSavingElement = (
	totalSaving: number,
	printingSaving: number,
	colorPrintingSaving: any,
	userCurrencySign: string,
	savingPrintingName: string
) => {
	let savingPercent = ((100 * printingSaving) / totalSaving).toFixed(1)
	if (Number(savingPercent) > 100) {
		savingPercent = '100'
	}
	if (printingSaving < 0) {
		savingPercent = '0'
	}

	return {
		x: `${savingPercent}%`,
		y: Number(savingPercent),
		label: `${userCurrencySign}${currencyFormatter(printingSaving, '0,0')}`,
		name: savingPrintingName,
		stroke: colorPrintingSaving,
		colorScale: colorPrintingSaving,
		percent: savingPercent
	}
}

export const createPrintingSavingIndexes = (
	printingSavingUpfront: number,
	printingSavingManufacturing: number,
	printingSavingInventory: number,
	printingSavingCo2: number,
	userCurrencySign: string,
	co2FeatureOn: boolean
) => {
	return USER_PANEL_PRINTING_SAVING_INDEXES.filter(
		index => !(!co2FeatureOn && index.name === 'co2Saving')
	).map(index => {
		let indexCount: number = 0
		let savingStatus = MY_PANEL_SAVINGS

		switch (index.name) {
			case 'upfront':
				indexCount = printingSavingUpfront
				break
			case 'manufacturing':
				indexCount = printingSavingManufacturing
				break
			case 'inventory':
				indexCount = printingSavingInventory
				break
			case 'co2Saving':
				indexCount = printingSavingCo2
				break
			default:
				break
		}

		if (indexCount < 0) {
			savingStatus = MY_PANEL_DISSAVINGS
		}

		return {
			...index,
			title: index.title
				.format(
					`${userCurrencySign}${numeral(indexCount).format('0,0') || 'X'}`
				)
				.concat(' ' + savingStatus)
		}
	})
}

const calcultePercent = (count: number, total: number) =>
	parseInt(((100 * count) / total).toFixed())

const calculateRestPercent = (
	counts: number[],
	total: number,
	count: number
) => {
	if (!count) {
		return 0
	}
	let othersPercent = 0
	counts.map(count => {
		othersPercent += parseInt(((100 * count) / total).toFixed())
	})
	const restPercentage = 100 - othersPercent
	return restPercentage < 0 ? 0 : restPercentage
}

const countToPieChartLabel = (percent: number) =>
	percent === 0 ? ' ' : `${percent}%`

const countToPieChartLongLabel = (count: number, percent: number) =>
	count === 0 ? ' ' : `${count} ${count > 1 ? PARTS : PART}\n(${percent}%)`

export const updateUserROI = async (
	type: PRINTING_SAVING_TYPES,
	threshold: number,
	printerId: string,
	simpleConfigurationSelectorService: SimpleConfigurationSelectorService,
	printersFullData: ISimpleConfigurationCompany[],
	dispatch: any
) => {
	try {
		dispatch({
			type: USER_PANEL_ROI_CALCULATION_STARTED
		})
		const response = await updateUserPanelROI(type, threshold, printerId)
		dispatch({
			type: USER_PANEL_ROI_CALCULATION_FINISHED,
			payload: {
				...setROIData(
					response,
					simpleConfigurationSelectorService,
					printersFullData
				)
			}
		})
	} catch (error: any) {
		dispatch({
			type: USER_PANEL_ROI_CALCULATION_GOT_ERROR
		})
		dispatch({
			type: HANDLE_NOTIFICATION,
			payload: {
				notificationType: SHOW_NOTIFICATION.ERROR,
				notificationMessage: CONFIGURATION_CALCULATION_ERROR
			}
		})
	}
}

export const setROIData = (
	userROIResponse: any,
	simpleConfigurationSelectorService: SimpleConfigurationSelectorService,
	printersFullData: ISimpleConfigurationCompany[]
) => {
	const {
		data: {
			roi: { result, type, threshold, printer }
		}
	} = userROIResponse

	let userROISelectedCompanyValue = ''
	let userROISelectedPrinterValue: ISimpleConfigurationPrinter | string = ''
	let userROIPrinters: Array<ISimpleConfigurationPrinter> = []
	let userROIMachineCost = 0
	let hideRoiData = true

	if (printer) {
		const { id, company, machineCost } = printer
		hideRoiData = false
		userROISelectedCompanyValue = company
		const companyPrinters = printersFullData.find(
			data => data.companyName === userROISelectedCompanyValue
		)
		userROISelectedPrinterValue =
			companyPrinters?.printers.find(printer => printer.printerId === id) || ''

		userROIPrinters = simpleConfigurationSelectorService.getPrintersNamesList(
			userROISelectedCompanyValue
		)
		userROIMachineCost = machineCost || 0
	}

	const printersCompaniesList: Array<string> =
		simpleConfigurationSelectorService.getCompaniesNamesList()

	// Select the printer company if there is only one.
	if (printersCompaniesList.length === 1 && !printer) {
		userROISelectedCompanyValue = printersCompaniesList[0]
		userROIPrinters = simpleConfigurationSelectorService.getPrintersNamesList(
			userROISelectedCompanyValue
		)
	}

	return {
		printersCompaniesList,
		userROISelectedCompanyValue,
		userROISelectedPrinterValue,
		userROIPrinters,
		userROIMachineCost,
		userROITraditionalSavingYears: result,
		userROITypeThreshold: threshold,
		userROITypeValue: resultsSelectionOptions.find(
			resultsSelectionOption => resultsSelectionOption.type === type
		),
		hideRoiData
	}
}

export const makeIsoTimeFormat = (dateToBeFormatted: string) => {
	const dateObj = new Date(dateToBeFormatted)
	let formattedDate = ''

	if (!isNaN(dateObj.getTime())) {
		const tzOffset = -dateObj.getTimezoneOffset()
		const tzSign = tzOffset >= 0 ? '+' : '-'
		const tzHours = Math.abs(Math.floor(tzOffset / 60))
			.toString()
			.padStart(2, '0')
		const tzMinutes = (Math.abs(tzOffset) % 60).toString().padStart(2, '0')

		// Construct the ISO 8601 format including the time zone offset
		const isoDateString = moment(dateObj).format(
			`YYYY-MM-DDTHH:mm:ss${tzSign}${tzHours}:${tzMinutes}`
		)
		formattedDate = moment(isoDateString).format('MMM, Do YYYY')
	}

	return formattedDate
}
