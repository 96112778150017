import { FC, memo } from 'react'
import { useDispatch } from 'react-redux'

import Delete from '@material-ui/icons/DeleteForever'

import { onCancelProjectClickAction } from '../ProjectAnalysis/ProjectAnalysisActions'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import Button from 'Scenes/Components/thirdParty/CreativeTim/components/CustomButtons/Button.jsx'
import { useNavigatorOnLine } from 'Services/CustomHooks/useOnLineStatus'
import { ProjectStatus } from 'Services/models/IProject'
import { getString } from 'Services/Strings/StringService'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'

import './ProjectDenied.scss'


const DeleteTSX: any = Delete

interface CancelProjectProps {
	projectId: string
	projectName: string
	projectType?: ProjectStatus
}

const CancelProject: FC<CancelProjectProps> = ({
	projectId,
	projectName,
	projectType = null
}) => {
	const dispatch = useDispatch()
	const isOnline = useNavigatorOnLine()
	
	const onCancelProjectClick = () => {
		dispatch(onCancelProjectClickAction(projectId, projectName, projectType))
	}

	return (
		<div className="cancel-project">
			<DetailsPopup
				managerClassName="order-4"
				isHover={true}
				data={
					isOnline
						? projectType === ProjectStatus.failed
							? getString('CANCEL_FAILED_PROJECT_HOVER')
							: getString('CANCEL_ANALYSIS_PROJECT_HOVER')
						: getString('RELOAD_THE_PAGE')
				}
				popperDirection="bottom-end"
				popperClassName="info-box-wrapper details-popup--data"
				popperContactClassName="info-box-data"
			>
				<Button
					disabled={!isOnline}
					onClick={onCancelProjectClick}
					className={'cancel-project--button warning'}
					color="transparent"
				>
					<DeleteTSX />
					<div className="cancel-project--button--text">
						{getString('CANCEL_PROJECT')}
					</div>
				</Button>
			</DetailsPopup>
		</div>
	)
}

export default WithFeatureToggleHOC(
	memo(CancelProject),
	FeatureComponentId.CANCEL_PROJECT
)