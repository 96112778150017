export const ADMIN_GET_SMTP_MAIL = 'ADMIN_GET_SMTP_MAIL'
export const ADMIN_UPDATE_SMTP_MAIL = 'ADMIN_UPDATE_SMTP_MAIL'
export const ADMIN_VERIFY_SMTP_MAIL = 'ADMIN_VERIFY_SMTP_MAIL'
export const ADMIN_SMTP_MAIL_DATA_CHANGE = 'ADMIN_SMTP_MAIL_DATA_CHANGE'
export const ADMIN_VERIFY_PROGRESS_SMTP_MAIL = 'ADMIN_VERIFY_PROGRESS_SMTP_MAIL'
export const ADMIN_GET_SMTP_MAIL_LOADING = 'ADMIN_GET_SMTP_MAIL_LOADING'
export const ADMIN_GET_SMTP_MAIL_FETCH = 'ADMIN_GET_SMTP_MAIL_FETCH'
export const ADMIN_GET_SMTP_MAIL_ERROR = 'ADMIN_GET_SMTP_MAIL_ERROR'
export const GET_ADMIN_SETTINGS_STOP_STEPS_LOADING = 'GET_ADMIN_SETTINGS_STOP_STEPS_LOADING'
export const GET_ADMIN_SETTINGS_STOP_LOADING = 'GET_ADMIN_SETTINGS_STOP_LOADING'
export const GET_ADMIN_SETTINGS_STOP_SUCCESS = 'GET_ADMIN_SETTINGS_STOP_SUCCESS'
export const GET_ADMIN_SETTINGS_STOP_FAILED = 'GET_ADMIN_SETTINGS_STOP_FAILED'
export const GET_ADMIN_SETTINGS_STOP_ENUMS = 'GET_ADMIN_SETTINGS_STOP_ENUMS'
export const GET_ADMIN_SETTINGS_STOP_ENUMS_ERROR = 'GET_ADMIN_SETTINGS_STOP_ENUMS_ERROR'