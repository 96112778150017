import React, { FC, useEffect, useState } from 'react'

import { IOtherProps } from '../../../Services/models/IOtherProps'

import { ReactComponent as CostSavingSmall } from '../../../assets/img/svg/$ icon.svg'
import { ReactComponent as CostSaving } from '../../../assets/img/svg/cost saving.svg'

interface IProps extends IOtherProps {
	small?: boolean
	className?: string
	smallClassName?: string
	expandIconOnHover?: boolean
}

export const IconCostSaving: FC<IProps> = ({
	small,
	className = '',
	smallClassName = '',
	expandIconOnHover,
	...props
}) => {
	const [isBigIcon, setIsBigIcon] = useState(false)
	useEffect(() => {
		setIsBigIcon(!small)
	}, [small])

	if (!isBigIcon) {
		return (
			<CostSavingSmall
				data-qa="data-qa-small-benefit-cost-saving"
				className={smallClassName}
				onMouseOver={() => setIsBigIcon(expandIconOnHover || false)}
				{...props}
			/>
		)
	}
	return (
		<CostSaving
			data-qa="data-qa-configuration-icon-cost-saving"
			className={className}
			onMouseLeave={() => setIsBigIcon(!expandIconOnHover || false)}
		/>
	)
}
