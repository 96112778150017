import React from 'react'

import TransparentButton from 'Scenes/Components/TransparentButton'
import {
	onApplyToggle,
	onEditToggleChange
} from 'Scenes/Home/Customize/CustomizeMetadataCost/CustomizeMetadataCostService'
import { getString } from 'Services/Strings/StringService'

import '../Customize2DDrawingCost/Customize2DDrawingCost.scss'

interface IProps {
	metadataKey: any
	isEdit: boolean
	metadataApplyToggle: Function
	metadataEditToggle: Function
	onEditToggle: Function
}

const CustomizeMetadataCostButton: React.FC<IProps> = ({
	metadataKey,
	isEdit,
	metadataApplyToggle,
	onEditToggle,
	metadataEditToggle
}) => {
	return (
		<div>
			<TransparentButton
				onClick={() =>
					onEditToggleChange(
						!isEdit,
						metadataKey,
						metadataEditToggle,
						metadataApplyToggle
					)
				}
				className="custom-input--action"
			>
				{isEdit ? getString('CANCEL') : getString('EDIT')}
			</TransparentButton>
			{isEdit ? (
				<TransparentButton
					onClick={() =>
						onApplyToggle(
							!isEdit,
							metadataKey,
							metadataApplyToggle,
							metadataEditToggle,
							onEditToggle
						)
					}
					className="custom-input--action"
				>
					{getString('APPLY')}
				</TransparentButton>
			) : (
				<div></div>
			)}
		</div>
	)
}

export default CustomizeMetadataCostButton
