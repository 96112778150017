import React, { Component } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { createTheme } from '@material-ui/core/styles'

import { pageBackground } from '../../Services/colors'
import { muiTheme } from '../../Services/Constants'

class MyMuiTheme extends Component {
  render() {
    return (
      <MuiThemeProvider theme={styles.muiTheme}>
        <div style={styles.superview}>{this.props.children}</div>
      </MuiThemeProvider>
    )
  }
}
const theme = createTheme(muiTheme)
const styles = {
  muiTheme: theme,
  superview: { backgroundColor: pageBackground }
}

export default MyMuiTheme
