import parse from 'html-react-parser'

import { Action } from '../../../global actions/ActionModels'
import { HANDLE_NOTIFICATION } from '../../../global actions/types'
import {
	CASTOR_BANNER_CANCEL,
	CASTOR_BANNER_USER_REQUEST_SENT
} from '../../../global actions/types/CastorBannerTypes'
import { SUBSCRIPTION_FULL_VERSION_RADIO_VALUE } from '../../../Services/Constants'
import { sendReNewSubscriptionRequest } from '../../../Services/Network'
import {
	ERROR_UPDATING_TAGS,
	SHOW_NOTIFICATION,
	SUBSCRIPTION_REQUEST_SECCEDD
} from '../../../Services/Strings'
import { CastorBannerType } from './CastorBannerType.enum'
import { getString } from 'Services/Strings/StringService'

export const onAlertCancel = (): Action<any> => {
	return {
		type: CASTOR_BANNER_CANCEL
	}
}

export const buttonOnClick = (
	userEmail: string,
	contactUsEmail: string,
	type: CastorBannerType,
	userName: string
): any => {
	return (dispatch: any) => {
		switch (type) {
			case CastorBannerType.UPGRADE_NOW:
				sendReNewSubscriptionRequest(
					SUBSCRIPTION_FULL_VERSION_RADIO_VALUE,
					userEmail
				)
					?.then((response: any) => {
						dispatch({
							type: CASTOR_BANNER_USER_REQUEST_SENT,
							payload: {
								showButton: false,
								disabledButton: true,
								text: SUBSCRIPTION_REQUEST_SECCEDD
							}
						})
						setTimeout(() => {
							dispatch({
								type: CASTOR_BANNER_CANCEL
							})
						}, 7000)
					})
					?.catch((error: any) => {
						console.log(error)
						dispatch({
							type: HANDLE_NOTIFICATION,
							payload: {
								notificationType: SHOW_NOTIFICATION.ERROR,
								notificationMessage:
									error.validationMessage || ERROR_UPDATING_TAGS
							}
						})
					})
				break

			case CastorBannerType.CONTACT_US:
				const emailSubject = getString(
					'LIGHT_USER_CONTACT_US_EMAIL_SUBJECT'
				).format(userName ? `- ${userName}` : '')
				// we check here for user name in order not to pass light.user default email
				// until we don't know the real user's email
				const emailFrom = userName && userEmail ? userEmail : ''
				const emailBody = encodeURIComponent(
					getString('LIGHT_USER_CONTACT_US_EMAIL_BODY').format(userName || '')
				)
				window.location.href = `mailto:${contactUsEmail}?subject=${emailSubject}&cc=${emailFrom}&body=${emailBody}`
				break
		}
	}
}
