import { isEmpty } from 'lodash'

import {
	GENERATE_LICENSE_ADD_FILE,
	GENERATE_LICENSE_ERROR,
	GENERATE_LICENSE_FILE_END,
	GENERATE_LICENSE_FILE_START,
	HANDLE_NOTIFICATION
} from '../../../../global actions/types'
import {
	SHOW_NOTIFICATION,
	UPDATE_DATABASE_ERROR_FILE_FORMAT,
	UPDATE_DATABASE_ERROR_FILE_LENGTH,
	UPDATE_DATABASE_FAILED,
	UPDATE_DATABASE_SUCCESS
} from '../../../../Services/Strings'
import { zipFileTypes } from 'Scenes/Home/NewUploadProject/fileTypes'
import { createZip } from 'Services/Network'

export const onUploadFile = (sendFile: any, formValues: any): any => {
	return async (dispatch: any) => {
		dispatch({ type: GENERATE_LICENSE_FILE_START })
		try {
			if (sendFile) {
				const response = await createZip(sendFile, formValues)
				const signedUrl = response?.data?.signedUrl
				window.open(`${signedUrl}`, '_blank')
				if (response) {
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.SUCCESS,
							notificationMessage: UPDATE_DATABASE_SUCCESS
						}
					})
				} else {
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.ERROR,
							notificationMessage: UPDATE_DATABASE_FAILED
						}
					})
				}
			}
		} catch (e) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: UPDATE_DATABASE_FAILED
				}
			})
		}

		dispatch({ type: GENERATE_LICENSE_FILE_END })
	}
}

export const setFile = (newFile: any, readerResult: any): any => {
	return (dispatch: any) => {
		const file = newFile[0]
		const multipleFiles = newFile.length > 1
		const wrongType = !zipFileTypes.includes(file.type)
		const error = multipleFiles
			? UPDATE_DATABASE_ERROR_FILE_LENGTH
			: wrongType
			? UPDATE_DATABASE_ERROR_FILE_FORMAT
			: ''

		if (!isEmpty(error)) {
			dispatch({ type: GENERATE_LICENSE_ERROR, payload: { error } })
		} else {
			const preparedToSend = {
				data: readerResult?.split(',').pop(),
				fileName: file.name
			}
			dispatch({
				type: GENERATE_LICENSE_ADD_FILE,
				payload: { file, preparedToSend }
			})
		}
	}
}
