import React, { FC } from 'react';

interface AnalysisFooterProps {
  errorMessage: string | null
}

const LockLeadTimeGraph: FC<AnalysisFooterProps> = ({
  errorMessage,
}) => {

  if (!errorMessage) return null

  return (
    <div className="disabled-block">
      <div className="disabled-block__image">
        <div className="disabled-block__text">{errorMessage}</div>
      </div>
    </div>
  )
}

export default LockLeadTimeGraph
