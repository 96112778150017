import React, { memo } from 'react'

import { MenuItem } from '@material-ui/core'

import { ActionWithPayload } from '../../../../../../../global actions/ActionModels'
import {
	MAX_QUANTITY_VALUE,
	MIN_NUMBER_FIELD_VALUE
} from '../../../../../../../Services/Constants'
import WithFeatureToggleHOC from '../../../../../../../Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from '../../../../../../../Services/models/Features'
import { getString } from '../../../../../../../Services/Strings/StringService'
import { IconPostProcess } from '../../../../../../Components/CastorIcons/IconPostProcess'
import SelectMenu from '../../../../../../Components/MaterialSolutionSelector/SelectMenu'
import NumberField from '../../../../../../Components/NumberField'
import { Button } from '../../../../../../Components/thirdParty/CreativeTim/components'
import Flexbox from 'Scenes/Components/FlexBox'

import './MaterialQuantityRibbon.scss'

const MenuItemTsx: any = MenuItem

interface IProps {
	configuration: any
	chosenMaterialType: string
	chosenMaterialCategory: string
	categoriesList: Array<string>
	materialTypesList: Array<string>
	batchSize: number
	showAnalysis: boolean
	onChangeMaterialType: (value: any, id: number) => ActionWithPayload<any>
	onChangeMaterialCategory: (value: any, id: number) => ActionWithPayload<any>
	onCostQuantityChanged: Function
	onPostProcesses: (id: number, renderCheckbox: true) => any
}

const createMenuItemTsxElement = (menuItem: any) => {
	return (
		<MenuItemTsx key={menuItem} className="selector-option" value={menuItem}>
			{menuItem}
		</MenuItemTsx>
	)
}

const MaterialQuantityRibbon: React.FC<IProps> = ({
	configuration,
	chosenMaterialType,
	chosenMaterialCategory,
	categoriesList,
	materialTypesList,
	batchSize,
	showAnalysis,
	onChangeMaterialType,
	onChangeMaterialCategory,
	onCostQuantityChanged,
	onPostProcesses
}: IProps) => {
	if (!chosenMaterialType || !chosenMaterialCategory) {
		return <div />
	}
	return (
		<Flexbox
			alignItems="flex-start"
			justifyContent="space-between"
			className="material-quantity-ribbon"
		>
			<Flexbox alignItems="flex-start" flexDirection="column">
				{getString('NEW_PART_CONFIGURATION_MATERIAL')}
				<SelectMenu
					value={chosenMaterialType}
					disabled={true}
					onChange={(event: any) => {
						onChangeMaterialType(event.target.value, configuration.id)
					}}
					classes={{
						select: 'material-quantity-ribbon--material-select',
						root: 'material-quantity-ribbon--material-select--root'
					}}
				>
					{materialTypesList?.map((menuItem: any) =>
						createMenuItemTsxElement(menuItem)
					)}
				</SelectMenu>
			</Flexbox>

			<Flexbox alignItems="flex-start" flexDirection="column">
				{getString('NEW_PART_CONFIGURATION_MATERIAL_DETAIL')}
				<SelectMenu
					value={chosenMaterialCategory.toLocaleLowerCase()}
					disabled={true}
					onChange={(event: any) => {
						onChangeMaterialCategory(event.target.value, configuration.id)
					}}
					classes={{
						select: 'material-quantity-ribbon--material-select',
						root: 'material-quantity-ribbon--material-select--root'
					}}
				>
					{categoriesList?.map((menuItem: any) =>
						createMenuItemTsxElement(menuItem)
					)}
				</SelectMenu>
			</Flexbox>

			<Flexbox
				alignItems="flex-start"
				flexDirection="column"
				className="quantity"
			>
				{getString('MATERIAL_QUANTITY_RIBBON_QUANTITY')}
				<NumberField
					allowEmpty={true}
					value={batchSize}
					disabled={!showAnalysis}
					onChangeValue={(value: number) => {
						onCostQuantityChanged(configuration.id, value)
					}}
					minValue={MIN_NUMBER_FIELD_VALUE}
					maxValue={MAX_QUANTITY_VALUE}
				/>
			</Flexbox>
			<Button
				color="info"
				className="post-process"
				disabled={!showAnalysis}
				onClick={() => onPostProcesses(configuration.id, true)}
			>
				<IconPostProcess />
				{getString('POST_PROCESS_BTN')}
			</Button>
		</Flexbox>
	)
}

export default WithFeatureToggleHOC(
	memo(MaterialQuantityRibbon),
	FeatureComponentId.MATERIAL_QUANTITY_RIBBON
)
