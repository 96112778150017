import bgImage from '../../assets/img/authBG.jpg'
import logoImage from '../../assets/img/castorLogoBlack.png'
import loaderImage from '../../assets/img/loader.gif'

const tabOrder = {
	summary: 3,
	costAnalysis: 4,
	leadTime: 5,
	feaAnalysis: 6,
	geometryAnalysis: 2,
	material: 1,
	cluster: 7
}

const uploadProjectComponents = {
	projectDetails: 1,
	projectType: 2,
	currentMaterial: 0,
	manufacturingType: 0,
	estimatedPart: 0,
	typeOfAM: 0
}

const base = {
	logo: logoImage,
	bgImage,
	loaderImage,
	showPoweredBy: false,
	showEffectiveIcon: true,
	actions: {},
	tabTitle: 'CASTOR',
	colors: {
		dropzoneBackground: 'rgb(60, 60, 60)',
		pageBackground: '#ededed',
		loaderBackground: 'rgb(256, 256, 256)',
		passedColor: '#64c882',
		passedFontColor: '#ffffff',
		passedColorOnBackground: '#64c882',
		successColorOnBackground: '#20c0e6',
		failedColorOnBackground: '#f9d82d',
		borderlineColor: '#64c882',
		borderlineStrokeColor: '#f9d82d',
		failedColor: '#f9d82d',
		successColor: '#20c0e6',
		simpleColor: '#999999',
		piePassedGradientStartColor: '#c2edd1',
		piePassedColor: '#64c882',
		pieBorderlineColor: '#c2edd1',
		pieBorderlineStrokeColor: '#f9d82d',
		pieFailedColor: '#f9d82d',
		notSuitableForPrint: '#20c0e6',
		notCostEffectiveColor: '#848484',
		threadColor: '#c9a900',
		pieMissingInformationColor: '#e7e9c8',
		costSavingColor: 'hsla(254, 83%, 72%, 1)',
		blueCardHeaderBackground: '#4d6fcc',
		greenCardHeaderBackground: '#64c882',
		darkGreenCardHeaderBackground: '#57AD71',
		blueCardHeaderTextColor: '#ffffff',
		blueCardHeaderSubTextColor: '#ffffff8c',
		sideBarBackground: '#111111',
		sideBarBackgroundOpacity: '.93',
		sideBarDraggingItemBackground: '#222222',
		sideBarBackgroundBoxShadow1: 'hsla(187, 100%, 42%, 0.28)',
		sideBarBackgroundBoxShadow2: 'hsla(187, 100%, 42%, 0.2)',
		sideBarItemText: '#FFFFFF',
		sideBarItemTextFocused: '#FFFFFF',
		benefitsText: '#7653ed',
		benefitsBackground: '#9f86f2',
		benefitsBackgroundOpac: '#c8baf8',
		pieBenefitsInventory: '#dcd2fb',
		pieBenefitsCo2: '#c1e8ca',
		pieBenefitsShipments: '#c8baf8',
		chartLineColor: '#20c0e6',
		chartMethodColor: '#ec7d33',
		chartCrossColor: '#977CF3',
		chartAMColor: '#0ab0ef',
		costSavingBenefit: '#7653ED',
		timeSavingBenefit: '#AE9AF4',
		buyToFlyBenefit: '#D6CCFC',
		complexityScoreBenefit: '#EBE5FF',
		weightReductionBenefit: '#57B8FF',
		partConsolidationBenefit: '#A6D9FF',
		paginationButtonColor: 'rgba(32, 192, 230, 0.2)'
	},
	customComponents: {},
	strings: [],
	buttons: {
		fontSize: '17px',
		borderRadius: '3px',
		secondaryColor: 'transparent',
		infoColor: '#20c0e6',
		infoFontColor: '#ffffff',
		primaryBoxShadow:
			'0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)',
		primaryBoxShadowHover:
			'0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)',
		secondaryBoxShadow: 'none',
		secondaryBoxShadowHover: 'none',
		infoBoxShadow:
			'0 6px 10px -10px rgb(0, 0, 0, 0.3), 0 4px 10px 0px rgb(0, 0, 0, 0.12), 0 3px 4px -5px rgb(0, 0, 0, 0.2)',
		infoBoxShadowHover:
			'0 14px 26px -12px rgba(0, 188, 212, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2)',
		tableButtonColor: 'primary'
	},
	tabOrder: tabOrder,
	tabOrderActive: {
		activeTab: tabOrder.material,
		unprintableTab: tabOrder.geometryAnalysis,
		defaultTab: tabOrder.costAnalysis,
		mechanicalTab: tabOrder.feaAnalysis,
		summaryTab: tabOrder.summary
	},
	lightUserAlert: {
		showHeaderText: true,
		headerWithLogo: false,
		withLogo: true,
		showTerms: true,
		showCancel: true,
		showUpload: true
	},
	defaultMaterial: {
		name: 'Nylon 101',
		type: 'plastic',
		id: 117,
		category: 'plastics'
	},
	costAnalysisTextOffset: '140px',
	chartHeadFontSize: '14px',
	uploadProjectComponents,
	defaultObjectiveValue: 'basic'
}

export default base
