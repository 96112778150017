import parse from 'html-react-parser'
import { FC, memo } from 'react'

import cx from 'classnames'

import { ADLinks } from 'Scenes/Components/AdBlock/AdConstants'
import {
	AdBlockProps,
	ADSingleBlock
} from 'Scenes/Components/AdBlock/AdInterface'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'

import './index.scss'

const AdBlock: FC<AdBlockProps> = ({ fullWidth }) => {
	return (
		<div className={cx('ad-blocks', { full: fullWidth })}>
			{ADLinks.map(
				(
					{
						teaserName,
						teaserHeadline,
						teaserButton,
						link,
						text,
						image
					}: ADSingleBlock,
					index
				) => {
					return (
						<div
							key={teaserName + index}
							className="ad-blocks__wrapper"
							onClick={() => window.open(link, '_blank')}
						>
							<div>
								<div
									className="ad-blocks__image"
									style={{ backgroundImage: `url(${image})` }}
								/>
								<div className="ad-blocks__content">
									<div className="ad-blocks__content-header">{teaserName}</div>
									<div className="ad-blocks__content-subheader">
										{parse(teaserHeadline)}
									</div>
									<div className="ad-blocks__content-text">{parse(text)}</div>
								</div>
							</div>
							<div className="ad-blocks__button">
								<a href={link} target="_blank" rel="noreferrer">
									{teaserButton}
								</a>
							</div>
						</div>
					)
				}
			)}
		</div>
	)
}

export default WithFeatureToggleHOC(
	memo(AdBlock),
	FeatureComponentId.SHOW_AD_BLOCK
)
