import React, { FC, memo } from 'react'

import { Select, withStyles } from '@material-ui/core'
import cx from 'classnames'

const SelectTsx: any = Select

interface IProps {
	value: String
	disabled: boolean
	onChange: Function
	children: any
	classes: any
}

const styles = {
	select: {
		width: '207px',
		height: '37px',
		fontSize: ' 14px',
		borderRadius: '2px',
		border: '1px solid #dedede',
		padding: '0 15px',
		lineHeight: '2.8em',
		margin: '4px 0'
	},
	icon: {
		right: '10px'
	}
}

const SelectMenu: FC<IProps & any> = ({
	value,
	disabled,
	onChange,
	children,
	classes
}: IProps) => {
	return (
		<SelectTsx
			title={value}
			disableUnderline={true}
			disabled={disabled}
			className={cx('material-selector-configure', classes.root)}
			value={value}
			onChange={onChange}
			classes={{
				select: classes.select,
				icon: disabled ? 'hide-icon' : classes.icon
			}}
			variant={'filled'}
			inputProps={{
				name: 'simpleSelect',
				id: 'simple-select'
			}}
			MenuProps={{
				PaperProps: {
					style: {
						transform: 'translate3d(0, 0, 0)'
					}
				}
			}}
		>
			{/* <MenuItemTsx disabled>{disabledMenuItem}</MenuItemTsx> */}

			{children}
		</SelectTsx>
	)
}

export default memo(withStyles(styles)(SelectMenu))
