import fileSaver from 'file-saver'

import {
	ADMIN_STRINGS_IMPORT_TOGGLED,
	HANDLE_NOTIFICATION
} from '../../../../global actions/types'
import {
	exportStrings,
	importStrings
} from '../../../../Services/Network/StringNetwork'
import { SHOW_NOTIFICATION } from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'

export const exportStringsToExcel = () => {
	return async (dispatch: any) => {
		try {
			const blob = await exportStrings()

			fileSaver.saveAs(blob, 'strings.xlsx')
		} catch (error: any) {
			console.error(error.message)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('CONFIGURATION_CALCULATION_ERROR')
				}
			})
		}
	}
}

export const onImportStrings = (fileSelected: File | null | undefined) => {
	return async (dispatch: any): Promise<void> => {
		try {
			if (!fileSelected) {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: 'no file selected'
					}
				})
				return
			}
			dispatch({
				type: ADMIN_STRINGS_IMPORT_TOGGLED
			})
			await importStrings(fileSelected)
			dispatch({
				type: ADMIN_STRINGS_IMPORT_TOGGLED
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: 'file imported successfully'
				}
			})
		} catch (error: any) {
			console.error(error.message)
			dispatch({
				type: ADMIN_STRINGS_IMPORT_TOGGLED
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('CONFIGURATION_CALCULATION_ERROR')
				}
			})
		}
	}
}
