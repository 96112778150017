import React from 'react';
import { scroller } from 'react-scroll';


// We've created this component, so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
interface IProps {
	className: string
	itemId: string
	links: any
	headerLinks?: any
}

class SidebarWrapper extends React.Component<IProps> {
	componentDidMount() {
		if (this.props.itemId) this.scrollToItem(this.props.itemId)
	}

	isInViewport = (element: any) => {
		const rect = (element && element.getBoundingClientRect()) || {}
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <=
				(window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		)
	}

	scrollToItem = (id: any) => {
		const elementId = 'scrollToMenu_' + id
		let scrollToElement = document.getElementById(elementId)
		let scrollContainer = document.getElementById(`collapseScroll`)
		const isOnView = this.isInViewport(scrollContainer)
		if (isOnView && scrollToElement) {
			scroller.scrollTo(elementId, {
				duration: 800,
				delay: 0,
				offset: 0,
				containerId: 'collapseScroll'
			})
		}
	}

	render() {
		const { className, headerLinks, links } = this.props
		return (
			<div className={className} ref="sidebarWrapper" id="sidebarWrapper">
				{headerLinks}
				{links}
			</div>
		)
	}
}

export default SidebarWrapper