import React, { memo } from 'react'
import DataTable from '../../../../../Components/DataTable'
import './SolutionOrientation.scss'

interface Props {
  analysisResultsRows: any[][]
}

const OrientationAnalysisResults: React.FC<Props> = ({
  analysisResultsRows
}) => {
  return (
    <div className="solution-orientation--data-table--results">
      <DataTable
        tableDataRows={analysisResultsRows}
        tableStyle={{
          gridTemplateRows: `fit-content(1em)`
        }}
        tableClassName="solution-orientation--data-table--results--grid"
      />
    </div>
  )
}

export default memo(OrientationAnalysisResults)
