import React, { FC, memo } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { IFileWithMaterialAndQuantity } from 'Services/models/IUploadProject'
import { getString } from 'Services/Strings/StringService'

export enum PrintingStandardsEnum {
	NACE = 'NACE',
	API_20S = 'API_20S',
	API_20T = 'API_20T',
	UNKNOWN = 'UNKNOWN'
}

export interface PrintingStandardsList {
	description: string
	key: string
	title: string
}

type IProps = {
	state: IFileWithMaterialAndQuantity
	disabledMenuItem: string
	printingStandardsList: PrintingStandardsList[]
	onPrintingStandardsChange: (value: PrintingStandardsEnum) => void
}

const PrintingStandards: FC<IProps> = ({
	state,
	disabledMenuItem,
	onPrintingStandardsChange,
	printingStandardsList
}) => {
	return (
		<Select
			displayEmpty
			className={'file-row--select-field'}
			value={state.printingStandards}
			renderValue={selected => {
				if (selected) {
					const stringKey =
						printingStandardsList.find(
							(standard: PrintingStandardsList) => standard.key === selected
						)?.key || ''
					return getString(stringKey)
				} else {
					return disabledMenuItem
				}
			}}
			onChange={(event: any) =>
				onPrintingStandardsChange(event.target.value as PrintingStandardsEnum)
			}
			classes={{
				select: 'file-row--select'
			}}
			inputProps={{
				name: 'simpleSelect',
				id: 'simple-select'
			}}
			MenuProps={{
				PaperProps: {
					style: {
						transform: 'translate3d(0, 0, 0)'
					}
				}
			}}
		>
			<MenuItem>{disabledMenuItem}</MenuItem>
			{printingStandardsList.map((standard: PrintingStandardsList) => {
				return (
					<MenuItem
						key={standard.key}
						style={{ textTransform: 'capitalize' }}
						value={standard.key}
					>
						{getString(standard.key)}
					</MenuItem>
				)
			})}
		</Select>
	)
}

export default memo(PrintingStandards)
