import React, { useEffect } from 'react'
import { connect, DispatchProp, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'
import { History } from 'history'

import { loginProviderUser } from 'global actions'
import { onRefreshJWT } from 'global actions/UserActions'
import * as UserActions from 'global actions/UserActions'

import * as IntegrationProviderActions from './IntegrationProviderActions'
import { IntegrationProviderService } from './IntegrationProviderService'
import { ProviderGuid } from './providerGuid'
import {
	EXTERNAL,
	UPLOAD_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { setStringItemToLocalStorage } from 'Services/LocalStorageService'
import { getClientURL } from 'Services/Network'
import { checkIfValidUUID } from 'Services/Utils/guidTools'

interface IReduxStore {
	user: any
	auth: any
}

interface IReduxProps {
	userEmail: string
	history: History
	language: string
	onBeforeSendPartToProvider: any
}

const ExternalRedirect: React.FC<IReduxProps> = ({
	userEmail,
	history,
	language,
	onBeforeSendPartToProvider
}) => {
	useEffect(() => {
		if (!userEmail) {
			// Go to refresh token if the session has expired.
			dispatch(onRefreshJWT())
		}
	}, [userEmail])

	const integrationProviderService = new IntegrationProviderService()
	const url: URL = new URL(window.location.href)
	let queryParams: any = {}
	let providerGuid = url.pathname.split('/').pop()

	useEffect(() => {
		// For GrabCad
		if (providerGuid === ProviderGuid.GrabCad && !userEmail) {
			const params = new URLSearchParams(url.search)

			const token = params.get('token') || ''
			setStringItemToLocalStorage(
				'redirect',
				`${USER_HOME_ROUTE + UPLOAD_ROUTE}`
			)
			dispatch(onRefreshJWT(token, ProviderGuid.GrabCad))
		}
	}, [providerGuid, userEmail])

	const dispatch = useDispatch()

	// For Ultimaker.
	if (providerGuid && !checkIfValidUUID(providerGuid)) {
		queryParams = integrationProviderService.getQueryParams(url.searchParams)
		providerGuid = ProviderGuid.Ultimaker
	}

	switch (providerGuid) {
		case ProviderGuid.Link3D: {
			const params = new URLSearchParams(url.search)
			const token = params.get('token') || ''

			//Do network call for link3d to get user email.
			// Do it via api gateway in this order:
			// 1. api will call integration service - who will call link3d with the token, to get user email.
			// 2. api gateway will login the user via authorization.
			// 3. return user that is logged in already to client.
			// 4. redirect to upload project page.

			dispatch(loginProviderUser(token, providerGuid, language))

			break
		}

		case ProviderGuid.Ultimaker: {
			if (userEmail) {
				const redirect_uri = `${getClientURL()}${EXTERNAL}`
				onBeforeSendPartToProvider(
					userEmail,
					queryParams,
					redirect_uri,
					history
				)
			}
			break
		}

		case ProviderGuid.TeamCenter: {
			const params = new URLSearchParams(url.search)
			const token = params.get('token') || ''
			const state = params.get('state') || ''
			const showSideBar = params.get('showSideBar') || ''
			const search = params.get('search') || ''
			const scrollToBottom = params.get('scrollToBottom') || ''
			const decryptState = integrationProviderService.decryptState(state)
			let redirect_uri = `${USER_HOME_ROUTE}${decryptState.destinationURL}`
			let queryParams = ''

			if(showSideBar || search || scrollToBottom) {
				queryParams += '?'
			}
			queryParams += showSideBar ? queryParams.length > 1 ? `&showSideBar=${showSideBar}` : `showSideBar=${showSideBar}` : ''
			queryParams += search ? queryParams.length > 1 ? `&search=${search}` : `search=${search}` : ''
			queryParams += scrollToBottom ? queryParams.length > 1 ? `&scrollToBottom=${scrollToBottom}` : `scrollToBottom=${scrollToBottom}` : ''

			setStringItemToLocalStorage(
				'redirect',
				`${redirect_uri}${queryParams}`
			)
			dispatch(
				loginProviderUser(
					token,
					providerGuid,
					language || 'en',
					decryptState.thirdPartyId,
					redirect_uri
				)
			)
			break
		}
	}

	return <div />
}

const mapStateToProps = ({
	user: {
		userDetails: { email }
	},
	auth: { language }
}: IReduxStore) => {
	return {
		userEmail: email,
		language
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators(
		{ ...IntegrationProviderActions, ...UserActions },
		dispatch
	)

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ExternalRedirect)
)
