import React, { FC, memo } from 'react'

import { ReanalyzingStatus } from '../SideBarTypes'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import Loader from 'Scenes/Loader/Loader'
import { getString } from 'Services/Strings/StringService'

interface LinkItemStatusProps {
	reanalyzingStatus: string
	popperDirection?: any
}

const LinkItemStatus: FC<LinkItemStatusProps> = ({
	reanalyzingStatus,
	popperDirection = 'bottom-end'
}) => {
	if (reanalyzingStatus === ReanalyzingStatus.pending) {
		return (
			<DetailsPopup
				shouldPreventOverflow={true}
				isHover={true}
				data={getString('PROJECT_UPDATE_PENDING')}
				popperDirection={popperDirection}
				popperClassName="info-box-wrapper info-box-wrapper-in-sidebar"
				popperContactClassName="info-box-data"
				inPortal
			>
				<IconFactory iconName={'warn'} />
			</DetailsPopup>
		)
	}

	if (reanalyzingStatus === ReanalyzingStatus.inProgress) {
		return (
			<DetailsPopup
				shouldPreventOverflow={true}
				isHover={true}
				data={getString('PROJECT_UPDATE_LOADING')}
				popperDirection={popperDirection}
				popperClassName="info-box-wrapper info-box-wrapper-in-sidebar"
				popperContactClassName="info-box-data"
				inPortal
			>
				<Loader
					wrapperClassName="link-loader"
					showFlex={false}
					size={25}
					load={true}
					message=""
				/>
			</DetailsPopup>
		)
	}

	return <></>
}

export default memo(LinkItemStatus)
