import React, { FC, memo } from 'react'

import { PART_ANALYSIS_REMOVE } from 'Services/Strings'

import { ReactComponent as CircleClose } from 'assets/img/svg/icons/circle_close.svg'

import './ItemsList.scss'

interface Item {
	key: string
	value: string
	allowRemove: boolean
}

interface IProps {
	items: Item[]
	onRemoveItem: Function
	roundIcon: boolean
	dataQa?: string
}

const ItemsList: FC<IProps> = ({ dataQa, items, onRemoveItem, roundIcon }) => {
	return (
		<div className="items-list" data-qa={dataQa || ''}>
			{items.map((item: Item) => (
				<div key={item.value} className="items-list--item">
					<div>{item.value}</div>
					{item.allowRemove && (
						<div
							className="items-list--item--remove"
							onClick={() => onRemoveItem(item)}
							title={PART_ANALYSIS_REMOVE}
						>
							{roundIcon ? <CircleClose className="icon-close" /> : 'x'}
						</div>
					)}
				</div>
			))}
		</div>
	)
}

export default memo(ItemsList)
