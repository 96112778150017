export interface ISolutionConfiguration {
  id?: number
  data: string
}

export enum SolutionConfigurationTypes {
  simple = 'simple',
  regular = 'regular',
  custom = 'custom'
}
