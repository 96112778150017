import React, { ChangeEvent, FC, memo, useState } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { TextField } from '@material-ui/core'
import { isEmpty } from 'lodash'

import * as CustomizeActions from '../../CustomizeActions'
import EmissionsPrinterMaterialSelector from './EmissionsPrinterMaterialSelector'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'
import { isDisabledKeyPressed } from 'Services/getKeyCodesService'
import {
	CO2_PER_KG_DATA_VALIDATION,
	CO2_PER_KG_PLACE_HOLDER
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const TextFieldTSX: any = TextField

interface IProps {
	onCo2PerKgPrinterMaterialChange: Function
	setNewPrinterMaterial: Function
	co2PerKg: any
	printersFullData: any
}

interface IReduxStore {
	CustomizeCO2CalculationReducer: any
}

const DEFAULT_SELECT_VALUE = '0'

const CustomizeCO2EmissionsPrinterMaterial: FC<IProps> = ({
	onCo2PerKgPrinterMaterialChange,
	setNewPrinterMaterial,
	co2PerKg,
	printersFullData
}) => {
	const [selectedPrinterCompany, setSelectedPrinterCompany] =
		useState(DEFAULT_SELECT_VALUE)
	const [selectedPrinterName, setSelectedPrinterName] =
		useState(DEFAULT_SELECT_VALUE)
	const [selectedPrinterId, setSelectedPrinterId] =
		useState(DEFAULT_SELECT_VALUE)
	const [selectedMaterials, setSelectedMaterials] =
		useState(DEFAULT_SELECT_VALUE)
	const [isError, setIsError] = useState(false)

	const onCo2PerKgChange = (value: string) => {
		if (Number(value) <= 0) {
			setIsError(true)
		} else {
			setIsError(false)
			onCo2PerKgPrinterMaterialChange(value)
		}
	}
	const onSetClick = () => {
		setNewPrinterMaterial({
			material: {
				id: selectedPrinterId,
				printers: selectedPrinterName,
				name: selectedMaterials
			},
			co2PerKg: co2PerKg
		})

		setSelectedPrinterCompany(DEFAULT_SELECT_VALUE)
		setSelectedPrinterName(DEFAULT_SELECT_VALUE)
		setSelectedMaterials(DEFAULT_SELECT_VALUE)
		setSelectedPrinterId(DEFAULT_SELECT_VALUE)
	}

	const handleKeyDown = (event: KeyboardEvent) => {
		if (isDisabledKeyPressed(event)) {
			event.preventDefault()
		}
	}

	return (
		<>
			{' '}
			<div className="custom-calculation-co2--explanation">
				{getString('CUSTOMIZE_CO2_EMISSIONS_PRINTER_MATERIAL_TITLE')}
			</div>
			<Flexbox
				flexDirection="row"
				className="custom-calculation-co2--wrapper"
				data-qa="data-qa-printer-material-section"
			>
				<Flexbox flexDirection="row" alignItems="center">
					<EmissionsPrinterMaterialSelector
						printersFullData={printersFullData}
						selectedMaterials={selectedMaterials}
						selectedPrinterName={selectedPrinterName}
						selectedPrinterCompany={selectedPrinterCompany}
						setSelectedPrinterCompany={setSelectedPrinterCompany}
						setSelectedPrinterName={setSelectedPrinterName}
						setSelectedMaterials={setSelectedMaterials}
						setSelectedPrinterId={setSelectedPrinterId}
					></EmissionsPrinterMaterialSelector>
					<div className="material-picker">
						<TextFieldTSX
							className="custom-calculation-co2--printer-picker"
							type="number"
							placeholder={CO2_PER_KG_PLACE_HOLDER}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								onCo2PerKgChange(e.target.value)
							}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							onKeyDown={handleKeyDown}
							error={isError}
							value={co2PerKg}
						/>
					</div>
				</Flexbox>
				<ButtonWithLoader
					className="custom-material-names--AddButton"
					onClick={onSetClick}
					disabled={
						selectedMaterials === DEFAULT_SELECT_VALUE ||
						isEmpty(co2PerKg) ||
						isError
					}
				>
					{getString('SET')}
				</ButtonWithLoader>
			</Flexbox>
			{isError && (
				<div className="custom-calculation-co2--error">
					{CO2_PER_KG_DATA_VALIDATION}
				</div>
			)}
		</>
	)
}

const mapStateToProps = (CustomizeCO2CalculationReducer: IReduxStore) => {
	return {
		...CustomizeCO2CalculationReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeActions }, dispatch)

export default memo(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(CustomizeCO2EmissionsPrinterMaterial)
)
