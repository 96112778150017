import {
	ADMIN_PRINTER_INITIAL_VALUES_HAD_SET,
	ADMIN_PRINTER_NAME_ADDED,
	ADMIN_PRINTER_NAME_DELETED,
	ADMIN_PRINTER_NAME_DELETED_GOT_ERROR,
	ADMIN_PRINTER_NAME_SEARCH_PHRASE_CHANGED,
	ADMIN_PRINTER_NAME_UPDATED,
	ADMIN_PRINTER_NAMES_SELECTED_CLEARED,
	GET_ADMIN_PRINTER_NAMES,
	GET_ADMIN_PRINTER_NAMES_EDIT_PRINTER_NAME_SELECTED,
	GET_ADMIN_PRINTER_NAMES_GOT_ERROR,
	GET_ADMIN_PRINTER_NAMES_SUCCESS
} from '../../../../global actions/types'
import {
	createNewPrinterNameAdmin,
	deletePrinterNameAdmin,
	getPrinterNameAdmin,
	getPrinterNamesAdmin,
	updatePrinterNameAdmin
} from '../../../../Services/Network'
import {
	ADMIN_PRINTER_NAMES_ADDED_SUCCESS,
	ADMIN_PRINTER_NAMES_UPDATE_ERROR,
	ADMIN_PRINTER_NAMES_UPDATE_SUCCESS
} from '../../../../Services/Strings'
import {
	createNewItem,
	deleteItem,
	selectItemToEdit,
	setupAdminPage,
	updateItem
} from '../adminActionsService'
import { getString } from 'Services/Strings/StringService'

export const setupAdminPrinterNamesPage = (
	searchPhrase,
	pageNumber,
	limitPrinterNames
) => {
	return dispatch => {
		setupAdminPage(
			dispatch,
			getPrinterNamesAdmin,
			[searchPhrase, pageNumber, limitPrinterNames],
			GET_ADMIN_PRINTER_NAMES_SUCCESS,
			GET_ADMIN_PRINTER_NAMES_GOT_ERROR,
			pageNumber
		)

		dispatch({ type: GET_ADMIN_PRINTER_NAMES })
	}
}

export const setSearchPhrase = searchPhrase => {
	return {
		type: ADMIN_PRINTER_NAME_SEARCH_PHRASE_CHANGED,
		payload: searchPhrase
	}
}

export const selectPrinterNameToEdit = (printerNameId, printerNames) => {
	return dispatch => {
		selectItemToEdit(
			dispatch,
			printerNameId,
			printerNames,
			getPrinterNameAdmin,
			[printerNameId],
			GET_ADMIN_PRINTER_NAMES_EDIT_PRINTER_NAME_SELECTED,
			GET_ADMIN_PRINTER_NAMES_GOT_ERROR,
			'printerName'
		)
	}
}

export const onAdminPrinterNamesUnmounted = () => {
	return {
		type: ADMIN_PRINTER_NAME_SEARCH_PHRASE_CHANGED,
		payload: ''
	}
}

export const updatePrinterName = data => {
	return dispatch => {
		updateItem(
			dispatch,
			updatePrinterNameAdmin,
			data,
			ADMIN_PRINTER_NAME_UPDATED,
			'printerNameUpdated',
			ADMIN_PRINTER_NAMES_UPDATE_SUCCESS,
			ADMIN_PRINTER_NAMES_UPDATE_ERROR
		)
	}
}

export const deletePrinterName = id => {
	return dispatch => {
		deleteItem(
			dispatch,
			deletePrinterNameAdmin,
			id,
			ADMIN_PRINTER_NAME_DELETED,
			ADMIN_PRINTER_NAME_DELETED_GOT_ERROR,
			getString('ADMIN_PRINTER_NAMES_DELETE_SUCCESS'),
			getString('ADMIN_PRINTER_NAMES_DELETE_ERROR')
		)
	}
}

export const createNewPrinterName = printerName => {
	return dispatch => {
		createNewItem(
			dispatch,
			createNewPrinterNameAdmin,
			printerName,
			ADMIN_PRINTER_NAME_ADDED,
			'printerNameAdded',
			ADMIN_PRINTER_NAMES_ADDED_SUCCESS,
			ADMIN_PRINTER_NAMES_UPDATE_ERROR
		)
	}
}

export const clearSelectedPrinterName = () => {
	return {
		type: ADMIN_PRINTER_NAMES_SELECTED_CLEARED
	}
}

export const setPrinterNameInPrinterReducer = printerName => {
	return {
		type: ADMIN_PRINTER_INITIAL_VALUES_HAD_SET,
		payload: printerName
	}
}
