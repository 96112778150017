import { AnyAction } from 'redux'
import { Dispatch } from 'react'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../global actions/types/CastorAlertTypes'
import {
	SUBSCRUPTION_TRIAL_LOGIN_MSG,
	SUBSCRUPTION_LOGIN_MSG,
	SUBSCRUPTION_TRIAL_LOGIN_MSG_HEADER,
	SUBSCRUPTION_LOGIN_MSG_HEADER,
	SUBSCRIPTION_REQUEST_SECCEDD,
	SUBSCRIPTION_REQUEST_SECCEDD_HEADER,
	OK,
	UPDATE_NOW
} from '../../Services/Strings'
import {
	USER_SUBSCRIPTION_REQUEST_LOADING,
	USER_SUBSCRIPTION_REQUEST_SUCCEED
} from '../../global actions/types'
import { SUBSCRIPTION_FULL_VERSION_RADIO_VALUE } from '../../Services/Constants'
import { sendReNewSubscriptionRequest } from '../../Services/Network'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'

export class AuthActionsService {
	constructor() {}

	onSubscriptionLoginError = (
		dispatch: Dispatch<AnyAction>,
		email: string | null = null,
		trialVersion: boolean = false
	) => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: trialVersion
					? SUBSCRUPTION_TRIAL_LOGIN_MSG
					: SUBSCRUPTION_LOGIN_MSG,
				headerTitle: trialVersion
					? SUBSCRUPTION_TRIAL_LOGIN_MSG_HEADER
					: SUBSCRUPTION_LOGIN_MSG_HEADER,
				showCancel: false,
				onConfirm: () => {
					this.openConnectCastorPopup(dispatch, email)
				},
				confirmText: `${UPDATE_NOW}!`
			}
		})
	}

	openConnectCastorPopup = (
		dispatch: Dispatch<AnyAction>,
		email: string | null
	) => {
		dispatch({ type: ALERT_POPUP_CANCELED })
		this.sendConnectCastorUserChoise(dispatch, email)
	}

	sendConnectCastorUserChoise = (
		dispatch: Dispatch<AnyAction>,
		email: string | null
	) => {
		dispatch({ type: USER_SUBSCRIPTION_REQUEST_LOADING })

		sendReNewSubscriptionRequest(SUBSCRIPTION_FULL_VERSION_RADIO_VALUE, email)
			?.then((response: any) => {
				this.sendSubscriptionRequestSucceed(dispatch)
			})
			//TO DO: need to add error popup for the user
			?.catch((error: any) => {
				console.log(error)
			})
	}

	sendSubscriptionRequestSucceed = (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: USER_SUBSCRIPTION_REQUEST_SUCCEED })
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: SUBSCRIPTION_REQUEST_SECCEDD,
				headerTitle: SUBSCRIPTION_REQUEST_SECCEDD_HEADER,
				alertType: AlertType.SUCCESS,
				showCancel: false,
				onConfirm: () => {
					dispatch({ type: ALERT_POPUP_CANCELED })
				},
				confirmText: OK
			}
		})
	}
}
