export const initialState = {
	minX: '',
	maxX: '',
	minY: '',
	maxY: ''
}

export const reducer = (
	prevState: typeof initialState,
	nextState: typeof initialState
) => ({
	...prevState,
	...nextState
})
