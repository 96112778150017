import { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import SolutionConfigureFeaturesFilters from '../../NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionConfigure/SolutionConfigureFeatures/SolutionConfigureFeaturesFilters'
import { setupAdvancedFilters } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { ADMIN } from 'Scenes/Home/Customize/CustomizeSolutionFilters/CustomizeThreadService'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	onFilterConfirm: Function
	returnToDefault: Function
}

const FilterContent: FC<IProps> = ({ onFilterConfirm, returnToDefault }) => {
	const dispatch = useDispatch()
	const { isError, filters } = useSelector(
		(state: RootStateOrAny) =>
			state.AdvancedSettingsReducer?.advancedStates[ADMIN] ||
			new AdvancedSettingsInitialState()
	)

	useEffect(() => {
		dispatch(setupAdvancedFilters(ADMIN))
	}, [])

	if (!filters.length) return <></>

	return (
		<div className="customize-solution-filter__content">
			<SolutionConfigureFeaturesFilters
				id={ADMIN}
				showTitle={false}
				explanationArray={getString(
					'CUSTOMIZE_SOLUTION_FILTER_EXPLANATION_ARRAY'
				)}
				extraExplanation={getString(
					'CUSTOMIZE_SOLUTION_FILTER_EXTRA_EXPLANATION'
				)}
			/>
			<div className="filters-button">
				<Button
					className="customize-solution-filter-secondary"
					color="secondary"
					onClick={() => {
						returnToDefault()
					}}
				>
					{getString('RETURN_TO_DEFAULT')}
				</Button>
				<Button
					color="primary"
					disabled={isError}
					onClick={() => {
						onFilterConfirm()
					}}
				>
					{getString('SET')}
				</Button>
			</div>
		</div>
	)
}

export default memo(FilterContent)
