import {
  SETUP_PROFILE_DETAILS,
  USER_PROFILE_NAME_CHANGED,
  USER_PROFILE_EMAIL_CHANGED,
  USER_PROFILE_COMPANY_CHANGED,
  USER_PROFILE_LOADER,
  USER_PROFILE_UPDATED,
  USER_PROFILE_ADDRESS_CHANGED,
  USER_PROFILE_ADDRESS_SELECTED
} from '../../../global actions/types'
import { ERROR } from '../../../Services/Strings'

const INITIAL_STATE = {
  userName: '',
  userEmail: '',
  userCompany: '',
  validUserName: '',
  validUserEmail: '',
  validUserCompany: '',
  validUserAddress: '',
  disableUploadButton: true,
  profileLoading: false
}

const disableUploadButton = state => {
  if (
    state.validUserName === ERROR ||
    state.validUserEmail === ERROR ||
    state.validUserCompany === ERROR ||
    state.validUserAddress === ERROR
  ) {
    return true
  }
  return false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETUP_PROFILE_DETAILS: {
      const { name, email, company } = action.payload
      return {
        ...state,
        userName: name,
        userEmail: email,
        userCompany: company
      }
    }
    case USER_PROFILE_NAME_CHANGED: {
      const { userName, validUserName } = action.payload
      const newState = { ...state, userName, validUserName }
      return {
        ...newState,
        disableUploadButton: disableUploadButton(newState)
      }
    }
    case USER_PROFILE_EMAIL_CHANGED: {
      const { userEmail, validUserEmail } = action.payload
      const newState = { ...state, userEmail, validUserEmail }
      return {
        ...newState,
        disableUploadButton: disableUploadButton(newState)
      }
    }
    case USER_PROFILE_COMPANY_CHANGED: {
      const { userCompany, validUserCompany } = action.payload
      const newState = { ...state, userCompany, validUserCompany }
      return {
        ...newState,
        disableUploadButton: disableUploadButton(newState)
      }
    }
    case USER_PROFILE_ADDRESS_CHANGED: {
      const validUserAddress = action.payload
      const newState = { ...state, validUserAddress }
      return {
        ...newState,
        disableUploadButton: disableUploadButton(newState)
      }
    }

    case USER_PROFILE_LOADER: {
      return {
        ...state,
        profileLoading: action.payload
      }
    }
    case USER_PROFILE_UPDATED: {
      const { name, email, company, address } = action.payload
      return {
        ...state,
        userName: name,
        userEmail: email,
        userCompany: company,
        disableUploadButton: true,
        validUserName: '',
        validUserEmail: '',
        validUserCompany: '',
        validUserAddress: ''
      }
    }
    default:
      return state
  }
}
