import { getString } from 'Services/Strings/StringService'

export const MIN_DIFF = 0.0005
export const MIN_TICK_AMOUNT = 2
export const MAX_TICK_AMOUNT = 5
export const DIFF_REDUCE = 8

export const MIN_WIDTH_COMPONENTS = 695
export const MIN_WIDTH_GRAPH = 700

export const MIN_X = 'minX'
export const MIN_Y = 'minY'
export const MAX_X = 'maxX'
export const MAX_Y = 'maxY'

export const HIDDEN = '0'
export const VISIBLE = '1'
export const ALL = 'all'
export const NONE = 'none'

export const tooltipSelector = '.apexcharts-tooltip'

export enum GENERATIVE_DESIGN_KEYS {
	mass = getString('MASS'),
	stress = getString('MAX_VON_MISES'),
	cost = getString('COST'),
	partNumber = getString('PART_NAME'),
	result = getString('ADMIN_PARTS_RESULT'),
	status = getString('ADMIN_PARTS_STATUS')
}

export const status = 'status'
export const result = 'result'