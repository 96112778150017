import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import * as AdminMaterialPrinterPairsActions from './AdminMaterialPrinterPairsActions'
import { adminMaterialPrinterPairsRoute } from '../../../../Services/routeFuncs'
import { ADMIN_MATERIAL_PRINTER_PAIRS_NEW } from '../../../../Services/Strings'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import AdminMaterialPrinterPairForm from './AdminMaterialPrinterPairForm'

class NewAdminMaterialPrinterPair extends Component {
	onSubmit = data => {
		this.props.createNewMaterialPrinterPair(data)
	}

	renderFormContent = () => {
		return (
			<AdminMaterialPrinterPairForm
				onSubmit={this.onSubmit}
				addingNewItem={this.props.addingNewItem}
			/>
		)
	}

	render() {
		const { materialPrinterPairAdded, pageNumber } = this.props
		if (materialPrinterPairAdded) {
			return <Redirect to={adminMaterialPrinterPairsRoute(pageNumber)} />
		}
		return (
			<NavBarAndMaterial title={ADMIN_MATERIAL_PRINTER_PAIRS_NEW}>
				<CastorForm
					formTitle={ADMIN_MATERIAL_PRINTER_PAIRS_NEW}
					content={this.renderFormContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const mapStateToProps = ({ AdminMaterialPrinterPairsReducer }) => {
	const { materialPrinterPairAdded, pageNumber, addingNewItem } =
		AdminMaterialPrinterPairsReducer
	return {
		materialPrinterPairAdded,
		pageNumber,
		addingNewItem
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminMaterialPrinterPairsActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NewAdminMaterialPrinterPair)
