import React, { FC } from 'react'

import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { POPUP_HOVER_TIME_BENEFIT } from 'Services/Strings'

interface TimeBenefitsIconProps {
	showSolutionDetails: boolean,
	timeBenefit: any
}

export const TimeBenefitsIcon: FC<TimeBenefitsIconProps> = ({
	showSolutionDetails,
	timeBenefit
}) => {
	if (!timeBenefit || !showSolutionDetails) {
		return <div />
	}
	return <IconFactory iconName="time" className="time-save-icon" />
}

interface BenefitsDetailsProps {
	benefit: any
}

export const BenefitsDetails: FC<BenefitsDetailsProps> = ({ benefit }) => {
	if (benefit && benefit.description) {
		return (
			<div className="solution-analysis-header-benefits-data">
				<IconFactory
					style={{ width: '20px' }}
					iconName="smallTime"
					className="solution-analysis-header-benefits-data-icon"
				/>
				<span className="solution-analysis-header-benefits-data-title">
						{POPUP_HOVER_TIME_BENEFIT}{' '}
					</span>
				{benefit.description}
			</div>
		)
	}

	return <></>
}