import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { HANDLE_NOTIFICATION } from 'global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { ratePartUpdated } from 'Services/Network'
import { OK, SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export const ratePartClicked = (
	projectId: string,
	partId: number,
	rate: number
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		ratePartUpdated(projectId, partId, rate)
			.then(() => {
				dispatch({
					type: ALERT_POPPED,
					payload: {
						text: getString('THANK_YOU_FOR_RATING_TEXT'),
						headerTitle: getString('THANK_YOU_FOR_RATING_HEADER'),
						showCancel: false,
						alertType: AlertType.SUCCESS,
						onConfirm: () => {
							dispatch({
								type: ALERT_POPUP_CANCELED
							})
						},
						confirmText: OK,
						alertClass: 'thank-you-modal'
					}
				})
			})
			.catch((error: any) => {
				console.log(error)
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: getString('SOMETHING_WENT_WRONG')
					}
				})
			})
	}
}
