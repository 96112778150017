import React, { FC, memo } from 'react'

import cx from 'classnames'

import DetailsPopup from 'Scenes/Components/DetailsPopup'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as GrabCadLogo } from '../../../assets/img/svg/grabcad.svg'
import { ReactComponent as GrabCadSVG } from '../../../assets/img/svg/printWith.svg'

import './index.scss'

const GrabCadPrint: FC<any> = ({
	selectedGrabCad,
	blockClass,
	onToggleGrabCad,
	tooltipPosition,
	viewOnly
}) => {
	return (
		<div
			className={cx('grab-blocks', blockClass, {
				selected: selectedGrabCad,
				'view-only': viewOnly,
				button: !viewOnly
			})}
			onClick={(e: React.MouseEvent) => {
				e.stopPropagation()
				!viewOnly && onToggleGrabCad()
			}}
		>
			<DetailsPopup
				isHover={viewOnly}
				data={getString('EXPORT_TO_GRABCAD')}
				popperDirection={tooltipPosition || 'top-end'}
				popperClassName="info-box-wrapper details-popup--contact-us"
				popperContactClassName="info-box-data"
				managerClassName="order-5"
			>
				<GrabCadLogo />
				{!viewOnly && (
					<>
						<span>{getString('PRINT_WITH_GRABCAD')}</span>
						<GrabCadSVG className="print-with" />
					</>
				)}
			</DetailsPopup>
		</div>
	)
}

export default WithFeatureToggleHOC(
	memo(GrabCadPrint),
	FeatureComponentId.GRAB_CAD_PRINT
)
