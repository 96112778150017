import React, { FC, memo, ReactNode } from 'react'

import cx from 'classnames'

import AdditiveMinds from 'Scenes/Components/AdditiveMinds'
import { AdditiveMindsTypes } from 'Scenes/Components/AdditiveMinds/AdditiveMindsInterface'
import DetailWithTitle from 'Scenes/Components/DetailWithTitle/DetailWithTitle'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import Loader from 'Scenes/Loader/Loader'
import { LOADING } from 'Services/Constants'
import { RESULT } from 'Services/Strings'

import './ResultDetail.scss'

interface IProps {
	result: string
	title: string
	body: string
	showAdditiveButton?: boolean
	detailClass?: string
	extraBodyClassText?: ReactNode | string
	extraBodyClassName?: string
}

const renderIcon = (result: string) => {
	if (result === LOADING) {
		return <Loader load={true} size={58.5} message="" showFlex={false} />
	}
	return <IconFactory iconName={result} height="58.5px" width="58.5px" />
}

const ResultDetail: FC<IProps> = ({
	result,
	body,
	showAdditiveButton,
	detailClass,
	extraBodyClassText,
	extraBodyClassName
}: IProps) => {
	return (
		<DetailWithTitle
			title={RESULT}
			body={
				showAdditiveButton ? (
					<AdditiveMinds type={AdditiveMindsTypes.status} />
				) : (
					body
				)
			}
			icon={renderIcon(result)}
			className={cx('result-detail with-frame not-full', detailClass)}
			bodyClassName={'detail-body'}
			titleClassName="detail-title"
			extraBodyClassText={extraBodyClassText}
			extraBodyClassName={extraBodyClassName}
		/>
	)
}

export default memo(ResultDetail)
