import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { COST_MODEL_IDS, STEP_ID } from '../../constants'
import { useStep } from '../../OnboardingWizardHooks'
import OnboardingStepFrame from '../OnboardingStepFrame'
import CostFunctionEditorLink from './CostFunctionEditorLink'
import CustomizeCostParamsLink from './CustomizeCostParamsLink'
import Devider from 'Scenes/Components/Devider/Devider'
import { getString } from 'Services/Strings/StringService'

import './CostModel.scss'

const CostModelComponents = {
	[COST_MODEL_IDS.COST_PARAMS_LINK]: <CustomizeCostParamsLink />,
	[COST_MODEL_IDS.COST_FUNCTION_LINK]: <CostFunctionEditorLink />
}

const CostModel: FC = () => {
	const { steps } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { step, stepContent } = useStep(steps, STEP_ID.COST_MODEL)

	return (
		<OnboardingStepFrame step={step}>
			<div className="cost-model--wrapper">
				{stepContent.map((component, index) => {
					const addDevider =
						stepContent.length > 1 && index !== stepContent.length - 1
					return (
						<React.Fragment key={component.id}>
							{
								CostModelComponents[
									component.id as keyof typeof CostModelComponents
								]
							}
							{addDevider && (
								<Devider
									className="cost-model--devider"
									text={getString('OR')}
								/>
							)}
						</React.Fragment>
					)
				})}
			</div>
		</OnboardingStepFrame>
	)
}

export default memo(CostModel)
