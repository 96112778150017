import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { isEmpty } from 'lodash'

import * as CustomizeActions from './CustomizeActions'
import { getThemeString } from '../../../themes/getThemeString'
import CastorForm from '../../Components/CastorForm/CastorForm'
import CastorFormHeader from '../../Components/CastorForm/CastorFormHeader'
import Devider from '../../Components/Devider/Devider'
import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import CustomizeFormSection from './CustomizeFormSection'
import Flexbox from 'Scenes/Components/FlexBox'
import {
	CUSTOMIZE_CO2_CALCULATIONS_ROUTE,
	CUSTOMIZE_COST_AND_LEAD_ROUTE,
	CUSTOMIZE_COSTING_FUNCTION_EDITOR_ROUTE,
	CUSTOMIZE_IN_HOUSE_PRINTERS_ROUTE,
	CUSTOMIZE_MATERIAL_NAMES_MAPPING_ROUTE,
	CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE,
	CUSTOMIZE_SOLUTION_FILTER_ROUTE,
	CUSTOMIZE_TAGS_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { UnitType } from 'Services/models/IProject'
import { customizeUserMaterial, uploadProjectRoute } from 'Services/routeFuncs'
import { getString } from 'Services/Strings/StringService'

import { styles } from '../styles/formStyles.css'
import './CustomizeForm.scss'

const renderFormHeader = () => {
	return (
		<CastorFormHeader
			explanationHeader={getThemeString('CUSTOMIZE_EXPLINATION_1_HEADER')}
			explanationArray={getThemeString('CUSTOMIZE_EXPLANATION_ARRAY')}
		/>
	)
}

class CustomizeForm extends Component {
	componentDidMount = () => {
		const {
			fetchCustomizeData,
			costAndLeadNames,
			settingsData,
			printersFullData,
			organization_owner,
			history,
			userDetails
		} = this.props
		if (!isEmpty(userDetails) && !organization_owner) {
			history.push(uploadProjectRoute())
		}
		fetchCustomizeData(
			costAndLeadNames,
			settingsData.userSettings,
			null,
			printersFullData
		)
	}

	renderCustomizeInHousePrinters = () => {
		if (!Feature.isFeatureOn(FeatureComponentId.CUSTOMIZE_IN_HOUSE_PRINTERS)) {
			return <div></div>
		}
		return (
			<>
				<CustomizeFormSection
					componentId={2}
					explanationHeader={getString('CUSTOMIZE_IN_HOUSE_PRINTERS_TITLE')}
					explanationArray={getString(
						'CUSTOMIZE_IN_HOUSE_PRINTERS_EXPLANATION_ARRAY'
					)}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_IN_HOUSE_PRINTERS_ROUTE}
					qaDataElementName="data-qa-customize-in-house-printers"
				/>
				<Devider className="customize-form--divider" componentId={2} />
			</>
		)
	}

	renderSemiProPrinters = (
		desktopPrinter,
		onToggleDesktopPrinter,
		isShowSemiProfessionalPrintersToggle
	) => {
		if (
			!Feature.isFeatureOn(
				FeatureComponentId.CUSTOMIZE_USER_SEMI_PRO_PRINTERS
			) ||
			!isShowSemiProfessionalPrintersToggle
		) {
			return <div></div>
		}
		return (
			<>
				<Devider className="customize-form--divider" componentId={2} />
				<CustomizeFormSection
					componentId={2}
					explanationHeader={getString('CUSTOMIZE_USER_SEMI_MATERIAL_TITLE')}
					explanationArray={getString(
						'CUSTOMIZE_USER_SEMI_MATERIAL_EXPLANATION_ARRAY'
					)}
					switchChecked={desktopPrinter}
					onToggleChange={() => onToggleDesktopPrinter(desktopPrinter)}
					qaDataElementName="data-qa-semi-professional-printers"
				/>
			</>
		)
	}

	renderCustomizeFilters = () => {
		if (!Feature.isFeatureOn(FeatureComponentId.CUSTOMIZE_FILTERS)) {
			return <div />
		}
		return (
			<>
				<CustomizeFormSection
					explanationHeader={getString('CUSTOMIZE_SOLUTION_FILTER_TITLE')}
					explanationArray={getString(
						'CUSTOMIZE_SOLUTION_FILTER_EXPLANATION_ARRAY'
					)}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_SOLUTION_FILTER_ROUTE}
					qaDataElementName="data-qa-customize-solution-filtering"
				/>
				<Devider className="customize-form--divider" />
			</>
		)
	}

	renderMaterialNamesMapping = () => {
		if (
			!Feature.isFeatureOn(FeatureComponentId.MATERIAL_NAMES_MAPPING) ||
			!Feature.isFeatureOn(FeatureComponentId.DRAWING_ANALYSIS)
		) {
			return <div />
		}
		return (
			<>
				<CustomizeFormSection
					explanationHeader={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_TITLE'
					)}
					explanationArray={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_EXPLANATION_ARRAY'
					)}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_MATERIAL_NAMES_MAPPING_ROUTE}
					qaDataElementName="data-qa-customize-material-names"
				/>
				<Devider className="customize-form--divider" />
			</>
		)
	}

	renderSustainabilityLifeCycle = () => {
		if (!Feature.isFeatureOn(FeatureComponentId.SUSTAINABILITY_LIFE_CYCLE)) {
			return <div />
		}
		return (
			<>
				<Devider className="customize-form--divider" />
				<CustomizeFormSection
					explanationHeader={getString('CUSTOMIZE_CO2_CALCULATIONS_TITLE')}
					explanationArray={getString('CUSTOMIZE_CO2_CALCULATIONS_ARRAY')}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_CO2_CALCULATIONS_ROUTE}
					qaDataElementName="data-qa-customize-sustainability"
				/>
			</>
		)
	}

	renderCostingFunctionEditor = () => {
		if (!Feature.isFeatureOn(FeatureComponentId.COST_FUNCTION_EDITOR)) {
			return <div></div>
		}

		return (
			<>
				<CustomizeFormSection
					explanationHeader={getString('COSTING_FUNCTION_EDITOR_TITLE')}
					explanationArray={getString(
						'COSTING_FUNCTION_EDITOR_EXPLANATION_ARRAY'
					)}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_COSTING_FUNCTION_EDITOR_ROUTE}
					qaDataElementName="data-qa-costing-function-editor"
				/>
				<Devider className="customize-form--divider" />
			</>
		)
	}

	renderInputUnitsType = (selectedUnitsType, onSelectedInputUnitsType) => {
		if (!Feature.isFeatureOn(FeatureComponentId.CUSTOMIZE_UNITS_TYPE)) {
			return <div></div>
		}

		// DE support only inches and mm:
		const cadUnitTypesFiltered = getString('CAD_UNIT_TYPES')?.filter(
			t => t.key === UnitType.mm || t.key === UnitType.inch
		)

		return (
			<>
				<Devider className="customize-form--divider" componentId={2} />

				<CustomizeFormSection
					componentId={2}
					explanationHeader={getString('CUSTOMIZE_INPUT_UNITS_TYPE_TITLE')}
					explanationArray={getString('CUSTOMIZE_INPUT_UNITS_TYPE_EXPLANATION')}
					select={true}
					selectTypes={cadUnitTypesFiltered}
					selectPlaceHolder={getString('UNIT_SELECTION_PLACEHOLDER')}
					onSelectedInputUnitsType={e =>
						onSelectedInputUnitsType(e.target.value)
					}
					selectedValue={selectedUnitsType}
					qaDataElementName="data-qa-customize-input-units-type"
				/>
			</>
		)
	}

	renderRecalculateAllParts = () => {
		if (!Feature.isFeatureOn(FeatureComponentId.RECALCULATE_PROJECTS)) {
			return <div></div>
		}

		return (
			<>
				<Devider className="customize-form--divider" componentId={2} />

				<CustomizeFormSection
					componentId={2}
					explanationHeader={getString('CUSTOMIZE_RECALCULATE_PROJECTS_TITLE')}
					explanationArray={getString(
						'CUSTOMIZE_RECALCULATE_PROJECTS_EXPLANATION'
					)}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE}
					qaDataElementName="data-qa-customize-recalculate-projects"
					buttonName={getString('CHOOSE_PROJECTS_PLACEHOLDER')}
				/>
			</>
		)
	}

	renderInitialFormContent = () => {
		const {
			onToggleDesktopPrinter,
			desktopPrinter,
			isShowSemiProfessionalPrintersToggle,
			selectedUnitsType,
			onSelectedInputUnitsType
		} = this.props
		return (
			<Flexbox flexDirection="column" style={styles.formSuperview}>
				{this.renderCustomizeInHousePrinters()}

				<CustomizeFormSection
					explanationHeader={getString('CUSTOMIZE_COST_AND_LEAD_TITLE')}
					explanationArray={getString(
						'CUSTOMIZE_COST_AND_LEAD_EXPLANATION_ARRAY'
					)}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_COST_AND_LEAD_ROUTE}
					qaDataElementName="data-qa-cost-calculation-params"
				/>

				{this.renderSustainabilityLifeCycle()}
				<Devider className="customize-form--divider" />

				{this.renderCustomizeFilters()}
				{this.renderMaterialNamesMapping()}
				{this.renderCostingFunctionEditor()}
				<CustomizeFormSection
					explanationHeader={getString('CUSTOMIZE_OFF_THE_SHELF_TITLE')}
					explanationArray={getThemeString(
						'CUSTOMIZE_OFF_THE_SHELF_EXPLANATION_ARRAY'
					)}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_TAGS_ROUTE}
					qaDataElementName="data-customize-part-filtering"
				/>
				{Feature.isFeatureOn(FeatureComponentId.CUSTOM_MATERIALS) ? (
					<>
						<Devider className="customize-form--divider" />
						<CustomizeFormSection
							explanationHeader={getString('CUSTOMIZE_USER_MATERIAL_TITLE')}
							explanationArray={getThemeString(
								'CUSTOMIZE_SETTINGS_MATERIAL_EXPLANATION_ARRAY'
							)}
							linkTo={customizeUserMaterial()}
							qaDataElementName="data-qa-add-material"
						/>
					</>
				) : (
					<></>
				)}
				{this.renderSemiProPrinters(
					desktopPrinter,
					onToggleDesktopPrinter,
					isShowSemiProfessionalPrintersToggle
				)}
				{this.renderInputUnitsType(selectedUnitsType, onSelectedInputUnitsType)}
				{this.renderRecalculateAllParts()}
			</Flexbox>
		)
	}

	render() {
		return (
			<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
				<CastorForm
					formHeader={renderFormHeader()}
					formTitle={getString('CUSTOMIZE_FORM_HEADER')}
					content={this.renderInitialFormContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const mapStateToProps = ({
	CostAndLeadReducer: { settingsData, costAndLeadNames },
	user: { printersFullData, userDetails },
	CustomizeReducer
}) => {
	return {
		settingsData,
		costAndLeadNames,
		printersFullData,
		userDetails,
		organization_owner: userDetails.organization_owner,
		...CustomizeReducer
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators({ ...CustomizeActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeForm)
