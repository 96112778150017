import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import cx from 'classnames'

import {
	onCompanyChangeSimpleConfiguration,
	onMaterialChangeSimpleConfiguration,
	onPrinterChangeSimpleConfiguration,
	onSimpleInhouseConfigurationChange
} from '../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import { useSolutionReducer, useUserReducer } from '../PartAnalysisSelector'
import FieldWithLabel from 'Scenes/Components/FieldWithLabel'
import SimpleConfigurationSelector from 'Scenes/Components/SimpleConfigurationSelector'
import SimpleConfigurationInHouse from 'Scenes/Components/SimpleConfigurationSelector/SimpleConfigurationInHouse'
import { onInHousePrintersAlertOpen } from 'Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrintersActions'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const ConfigurationAddNew: FC<any> = ({
	configuration,
	setQuantity,
	quantity,
	configurationName,
	setConfigurationName,
	isPart2d,
	isSpecifiedQuantity
}) => {
	const configurationId = configuration.id

	const { printers, printersFullData } = useUserReducer()
	const {
		disableConfiguration,
		simpleConfigurationSelectorCompanyValue,
		simpleConfigurationSelectorPrinterValue,
		simpleConfigurationSelectorMaterialValue,
		printersCompaniesList,
		simpleConfigurationPrinters,
		simpleConfigurationMaterialsList,
		simpleInhouseConfiguration
	} = useSolutionReducer(configurationId)

	const dispatch = useDispatch()

	const handleAlertConfirm = (value: boolean) => {
		if (!printers?.length && value) {
			return dispatch(onInHousePrintersAlertOpen())
		}
		dispatch(
			onSimpleInhouseConfigurationChange(
				configurationId,
				printersFullData,
				configuration,
				value
			)
		)
	}

	return (
		<div className="add-new-configuration--form printer-material">
			<div className="configuration--name">
				<p>{getString('CREATE_NEW_PRINTER_MATERIAL_INFORMATION')}</p>
				<FieldWithLabel
					labelName={getString('CREATE_NEW_CONFIGURATION_NAME')}
					fieldPlaceholder={getString('CREATE_NEW_MY_CONFIGURATION')}
					fieldValue={configurationName}
					fieldOnChange={value => setConfigurationName(value)}
					qaDataElementName="data-qa-configuration-name-input"
				/>
			</div>
			<div
				className={cx('configuration--options', {
					'is-2d-option': isPart2d || !isSpecifiedQuantity
				})}
			>
				<div className="grey-block property">
					<p>{getString('CREATE_NEW_PRINTING_PROPERTIES')}</p>
					<div>
						<SimpleConfigurationInHouse
							isNewDesign
							simpleInhouseConfiguration={simpleInhouseConfiguration}
							disableConfiguration={disableConfiguration}
							onSimpleInhouseConfigurationChange={handleAlertConfirm}
							componentId={2}
						/>
					</div>
					<div>
						<SimpleConfigurationSelector
							isNewDesign
							displayAsRow
							printerCompanyValue={simpleConfigurationSelectorCompanyValue}
							printerValue={simpleConfigurationSelectorPrinterValue}
							materialValue={simpleConfigurationSelectorMaterialValue}
							companiesList={printersCompaniesList}
							printersList={simpleConfigurationPrinters}
							materialsList={simpleConfigurationMaterialsList}
							onCompanyChange={(value: any) => {
								dispatch(
									onCompanyChangeSimpleConfiguration(
										configurationId,
										value,
										change
									)
								)
							}}
							onPrinterChange={(value: any) => {
								dispatch(
									onPrinterChangeSimpleConfiguration(
										configurationId,
										value,
										change
									)
								)
							}}
							onMaterialsChange={(value: any) => {
								dispatch(
									onMaterialChangeSimpleConfiguration(
										configurationId,
										value,
										change
									)
								)
							}}
							selectMenuClassName="printer-material-config--select-menu"
							itemWrapperClassname="select-printer"
						/>
					</div>
				</div>
				{!isPart2d && isSpecifiedQuantity && (
					<div className="grey-block basic configuration--name">
						<p>{getString('GENERAL_PROPERTIES')}</p>
						<div className="quantity">
							<FieldWithLabel
								qaDataElementName="data-qa-quantity-input-filed"
								isNumberField
								labelName={getString('UPLOAD_PROJECT_YEARLY_PROD')}
								fieldValue={quantity}
								fieldClassName="short"
								fieldOnChange={(value: any) => setQuantity(value)}
								minValue={1}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default ConfigurationAddNew
