import React from 'react'
import classNames from 'classnames'
import Button from '@material-ui/core/Button'

import PersonIcon from '@material-ui/icons/Person'

import headerLinksStyle from '../../variables/styles/headerLinksStyle'
import { logoutPressed } from '../../../../../../global actions'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
class HeaderLinks extends React.Component {
  state = {
    open: false
  }
  handleClick = () => {
    this.setState({ open: !this.state.open })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <br />

        <Button
          className={classes.button}
          variant="text"
          size="small"
          style={{ backgroundColor: 'transparent' }}
          onClick={this.props.logoutPressed.bind(this)}
        >
          <PersonIcon
            className={classNames(classes.leftIcon, classes.iconSmall)}
          />
          Sign out
        </Button>
      </div>
    )
  }
}
const mapStateToProps = ({ user }) => {
  const { loggedIn } = user
  return { loggedIn }
}
const styledExport = withStyles(headerLinksStyle)(HeaderLinks)
export default connect(
  mapStateToProps,
  { logoutPressed }
)(styledExport)
