import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'
import { uploadProjectRoute } from '../../../Services/routeFuncs'
import IconFactory from '../StarIcon/IconFactory'
import { Button } from '../thirdParty/CreativeTim/components'
import DataTableOpacityTextField from '../DataTable/DataTableOpacityTextField'

import './StatsUploadedParts.scss'

const LinkTSX: any = Link

interface IProps {}

const NoUploadedParts: FC<IProps> = ({}) => {
  return (
    <div className="stats-uploaded-parts--no-uploaded">
      <div className="stats-uploaded-parts--no-uploaded--data-wrapper">
        <DataTableOpacityTextField text="Want to explore your benefits?" />
        <LinkTSX to={uploadProjectRoute()}>
          <Button
            size="xs"
            color="primary"
            className="user-activity-data--upgrade--button"
            data-qa="data-qa-upload-parts-btn"
          >
            Upload your parts
          </Button>
        </LinkTSX>
      </div>

      <div className="stats-uploaded-parts--no-uploaded--data-wrapper">
        <DataTableOpacityTextField text="Find out which parts are 3D printable and which are not" />
        <IconFactory iconName="pie-cloud" />
      </div>
    </div>
  )
}

export default memo(NoUploadedParts)
