import { FC, memo } from 'react'
import {
	connect,
	DispatchProp,
	RootStateOrAny,
	useDispatch,
	useSelector
} from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import { withStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import cx from 'classnames'

import * as UserActions from '../../../global actions/UserActions'
import { onDownloadHealthStatisticsClick } from '../../../global actions/UserActions'
import {
	ADMIN_ROUTE,
	CUSTOMIZE_ROUTE,
	TWO_FACTOR_AUTH,
	UPLOAD_SCRIPT_ROUTE,
	USER_HOME_ROUTE,
	USER_PROFILE_ROUTE
} from '../../../Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from '../../../Services/models/Features'
import {
	ADMIN_PANEL,
	SIGN_OUT,
	USER_MENU_HEALTH_STATISTICS,
	USER_MENU_ONBOARDING_WIZARD,
	USER_MENU_PROFILE,
	USER_MENU_SETTINGS,
	USER_MENU_TAKE_A_TOUR,
	USER_MENU_UPLOAD_SCRIPT
} from '../../../Services/Strings'
import {
	updateTourAction,
	UpdateTourActionAttributes
} from '../TakeATour/TakeATourActions'
import headerLinksStyle from '../thirdParty/CreativeTim/variables/styles/headerLinksStyle'
import SignOutItem from './SignOutItem'
import UserMenuItem from './UserMenuItem'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { onboardingWizardRoute } from 'Services/routeFuncs'
import { checkingRoles } from 'Services/Utils/roleTools'

import * as styles from './UserMenu.css'

type IProps = {
	classes: Record<string, any>
	onLogoutPressed: () => Function
	onDownloadHealthStatisticsClick: () => Function
	updateTourAction: ({
		email,
		pagesVisited,
		pageName,
		isShowTourClick
	}: UpdateTourActionAttributes) => void
	onClick: () => void
}

const onboardingEnabledMenuItems = [
	USER_MENU_ONBOARDING_WIZARD,
	SIGN_OUT,
	USER_MENU_UPLOAD_SCRIPT
]

const renderTakeATourItem = (
	email: string,
	pagesVisited: string[],
	currentPageName: string,
	onClick: () => void,
	updateTourAction: ({
		email,
		pagesVisited,
		pageName,
		isShowTourClick
	}: UpdateTourActionAttributes) => void,
	classes: Record<string, any>,
	showTakeATour?: boolean,
	disabled?: boolean
) => {
	if (!showTakeATour) {
		return <div />
	}
	return (
		<MenuItem
			onClick={() => {
				onClick()
				updateTourAction({
					email,
					pagesVisited,
					pageName: currentPageName,
					isShowTourClick: true
				})
			}}
			className={classes.dropdownItem}
			disabled={disabled}
		>
			<UserMenuItem iconName="flag" itemName={USER_MENU_TAKE_A_TOUR} isNew />
		</MenuItem>
	)
}

const UserMenuItems: FC<IProps> = ({
	classes,
	onClick,
	onLogoutPressed,
	updateTourAction
}) => {
	const dispatch = useDispatch()
	const { isOnPrem } = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer
	)
	const {
		onboardingCompleted,
		userDetails: { email, organization_owner },
		currentPageName,
		pagesVisited,
		roles
	} = useSelector((state: RootStateOrAny) => state.user)
	const { adminAuthenticated } = useSelector(
		(state: RootStateOrAny) => state.TwoFactorAuthReducer
	)

	const isSuperAdmin = roles.includes(UserRole.SUPER_ADMIN)
	const showUploadScripts = Feature.isFeatureOn(
		FeatureComponentId.UPLOAD_SCRIPT
	)
	const showAdminPanelFeature =
		Feature.isFeatureOn(FeatureComponentId.SHOW_ADMIN_PANEL) || isSuperAdmin
	const showUserProfile = Feature.isFeatureOn(
		FeatureComponentId.SHOW_USER_PROFILE
	)
	const showUserSettings =
		Feature.isFeatureOn(FeatureComponentId.SHOW_USER_SETTINGS) &&
		organization_owner
	const showTakeATour = Feature.isFeatureOn(FeatureComponentId.TAKE_A_TOUR)
	const showOnboardingWizard =
		Feature.isFeatureOn(FeatureComponentId.ONBOARDING_WIZARD) &&
		organization_owner

	const disableMenuItem = (menuItem: string) =>
		showOnboardingWizard &&
		!onboardingCompleted &&
		!onboardingEnabledMenuItems.includes(menuItem)

	const showAdminPanel =
		showAdminPanelFeature &&
		checkingRoles(
			[
				UserRole.SUPER_ADMIN,
				UserRole.ADMIN,
				UserRole.USER_MANAGER,
				UserRole.DB_MANAGER
			],
			roles
		)

	const showUserDownloadHealthStatistics = Feature.isFeatureOn(
		FeatureComponentId.SHOW_USER_DOWNLOAD_HEALTH_STATISTICS
	)

	const signOut = Feature.isFeatureOn(FeatureComponentId.SIGN_OUT)

	const showDivider =
		showUserProfile ||
		showUserSettings ||
		showUploadScripts ||
		showAdminPanel ||
		showTakeATour ||
		showUserDownloadHealthStatistics ||
		showOnboardingWizard

	const showBottomMenu = signOut
	return (
		<MenuList role="menu">
			{showUserProfile && (
				<Link
					to={USER_HOME_ROUTE + USER_PROFILE_ROUTE}
					className={cx({
						'disabled-link': disableMenuItem(USER_MENU_PROFILE)
					})}
				>
					<MenuItem
						onClick={onClick}
						className={classes.dropdownItem}
						disabled={disableMenuItem(USER_MENU_PROFILE)}
					>
						<UserMenuItem
							qaDataElementName="data-qa-menu-profile"
							iconName="person"
							itemName={USER_MENU_PROFILE}
						/>
					</MenuItem>
				</Link>
			)}
			{showUserSettings && (
				<Link
					to={USER_HOME_ROUTE + CUSTOMIZE_ROUTE}
					className={cx({
						'disabled-link': disableMenuItem(USER_MENU_SETTINGS)
					})}
				>
					<MenuItem
						onClick={onClick}
						className={classes.dropdownItem}
						disabled={disableMenuItem(USER_MENU_SETTINGS)}
					>
						<UserMenuItem
							iconName="build"
							qaDataElementName="data-qa-menu-settings"
							itemName={USER_MENU_SETTINGS}
						/>
					</MenuItem>
				</Link>
			)}
			{renderTakeATourItem(
				email,
				pagesVisited,
				currentPageName,
				onClick,
				updateTourAction,
				classes,
				showTakeATour,
				disableMenuItem(USER_MENU_TAKE_A_TOUR)
			)}
			{showAdminPanel && (
				<Link
					to={adminAuthenticated ? ADMIN_ROUTE : TWO_FACTOR_AUTH}
					className={cx({
						'disabled-link': disableMenuItem(ADMIN_PANEL)
					})}
				>
					<MenuItem
						onClick={onClick}
						className={classes.dropdownItem}
						disabled={disableMenuItem(ADMIN_PANEL)}
					>
						<UserMenuItem
							iconName="supervisor"
							qaDataElementName="data-qa-menu-admin-panel"
							itemName={ADMIN_PANEL}
						/>
					</MenuItem>
				</Link>
			)}
			{showUploadScripts && (
				<Link
					to={USER_HOME_ROUTE + UPLOAD_SCRIPT_ROUTE}
					className={cx({
						'disabled-link': disableMenuItem(USER_MENU_UPLOAD_SCRIPT)
					})}
				>
					<MenuItem
						onClick={onClick}
						className={classes.dropdownItem}
						disabled={disableMenuItem(USER_MENU_UPLOAD_SCRIPT)}
					>
						<UserMenuItem
							iconName="update"
							qaDataElementName="data-qa-menu-update"
							itemName={USER_MENU_UPLOAD_SCRIPT}
						/>
					</MenuItem>
				</Link>
			)}

			{showUserDownloadHealthStatistics && isOnPrem && (
				<MenuItem
					onClick={() => dispatch(onDownloadHealthStatisticsClick())}
					className={classes.dropdownItem}
					disabled={disableMenuItem(USER_MENU_HEALTH_STATISTICS)}
				>
					<UserMenuItem
						qaDataElementName="data-qa-menu-health-statistics"
						iconName="iconDownload"
						itemName={USER_MENU_HEALTH_STATISTICS}
					/>
				</MenuItem>
			)}
			{showOnboardingWizard && (
				<Link
					to={onboardingWizardRoute()}
					className={cx({
						'disabled-link': disableMenuItem(USER_MENU_ONBOARDING_WIZARD)
					})}
				>
					<MenuItem
						onClick={onClick}
						className={`${classes.dropdownItem} user-menu-item`}
						disabled={disableMenuItem(USER_MENU_ONBOARDING_WIZARD)}
					>
						<UserMenuItem
							iconName="onboarding"
							qaDataElementName="data-qa-onboarding"
							itemName={USER_MENU_ONBOARDING_WIZARD}
						/>
					</MenuItem>
				</Link>
			)}

			{showBottomMenu && showDivider && <Divider style={styles.divider} />}
			{showBottomMenu && signOut && (
				<MenuItem
					onClick={onLogoutPressed}
					className={classes.dropdownItem}
					disabled={disableMenuItem(SIGN_OUT)}
				>
					<SignOutItem />
				</MenuItem>
			)}
		</MenuList>
	)
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...UserActions, updateTourAction }, dispatch)

export default WithFeatureToggleHOC(
	memo(
		connect(mapDispatchToProps)(
			withStyles(headerLinksStyle as any)(UserMenuItems)
		)
	),
	FeatureComponentId.USER_MENU
)
