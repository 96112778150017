import { FC, memo, useState } from 'react'
import { useDispatch } from 'react-redux'

import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'

import { ratePartClicked } from 'Scenes/Components/RatePart/ratePartAction'
import { Project } from 'Services/models/IProject'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const ratingCount = 5

interface RatePartProps {
	rate?: number
	projectId: string
	partId: number
}

const RatePart: FC<RatePartProps> = ({ rate = 0, projectId, partId }) => {
	const [rating, setRating] = useState(rate)
	const [hoverRate, setHoverRate] = useState(rate)
	const dispatch = useDispatch()

	const setPartRating = (index: number) => {
		setRating(index)
		dispatch(ratePartClicked(projectId, partId, index))
	}

	return (
		<div className="rating-block">
			<div className="rating-block--title">{getString('RATE_3D_PREVIEW')}</div>
			<div className="rating-block--stars" data-qa="data-qa-rating-stars">
				{[...Array(ratingCount)].map((star, index) => {
					const idx = index + 1
					const active = idx <= hoverRate

					return (
						<div
							key={idx}
							onClick={() => setPartRating(idx)}
							onMouseEnter={() => setHoverRate(idx)}
							onMouseLeave={() => setHoverRate(rating)}
						>
							{active ? <StarIcon className="filled" /> : <StarBorderIcon />}
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default memo(RatePart)
