import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { connect, DispatchProp, RootStateOrAny, useSelector } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { TextField } from '@material-ui/core'
import { isEmpty } from 'lodash'

import * as CustomizeActions from '../../CustomizeActions'
import { ParameterTypes } from '../ParameterTypes'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'
import InfoBox from 'Scenes/Components/InfoBox'
import { isDisabledKeyPressed } from 'Services/getKeyCodesService'
import { getString } from 'Services/Strings/StringService'

const DEFAULT_VALUE = ' Kg (Default)'
const TextFieldTSX: any = TextField

interface IProps {
	setUserCustomzationSettings: Function
	onCo2PerKwChange: Function
	co2PerKwValue: string
	co2PerKW: number
}

interface IReduxStore {
	user: any
	CustomizeCO2CalculationReducer: any
}

const CustomizeCO2CarbonIntensity: FC<IProps> = ({
	setUserCustomzationSettings,
	onCo2PerKwChange,
	co2PerKwValue,
	co2PerKW
}) => {
	const [isCarbonError, setIsCarbonError] = useState(false)
	const onSaveCarbonIntensity = (reset?: boolean) => {
		setUserCustomzationSettings(
			reset ? null : co2PerKwValue,
			ParameterTypes.carbon,
			reset
		)
	}
	const { carbonLoading, userSettingsDefaultValues, carbonResetLoading } =
		useSelector((state: RootStateOrAny) => state.user)

	useEffect(() => {
		if (!carbonLoading) {
			onCo2PerKwChange('')
		}
	}, [carbonLoading])

	const carbonIntensityCheck = (value: string) => {
		if (Number(value) < 0.01 || Number(value) > 5) {
			setIsCarbonError(true)
		} else {
			setIsCarbonError(false)
		}
		onCo2PerKwChange(value)
	}

	const handleKeyDown = (event: KeyboardEvent) => {
		if (isDisabledKeyPressed(event)) {
			event.preventDefault()
		}
	}

	return (
		<>
			<Flexbox flexDirection="row" className="custom-calculation-co2--wrapper">
				<Flexbox flexDirection="row" alignItems="center" gap="13px">
					<div className="custom-calculation-co2--explanation-text">
						{getString('CARBON_CO2_CALCULATION')}
					</div>
					<TextFieldTSX
						data-qa="data-qa-production-electricity-co2-input-filed"
						type="number"
						placeholder={
							co2PerKW === userSettingsDefaultValues.co2PerKW
								? co2PerKW + DEFAULT_VALUE
								: `${co2PerKW} ${getString('KG_UNITS')}`
						}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							carbonIntensityCheck(e.target.value)
						}
						onWheel={(event: ChangeEvent<HTMLInputElement>) =>
							event.target.blur()
						}
						onKeyDown={handleKeyDown}
						value={co2PerKwValue}
					/>
					<div className="custom-calculation-co2--explanation-text">
						{getString('CO2_PER_KWH')}
					</div>
					<InfoBox
						boxContact={getString('CARBON_CO2_CALCULATION_DATA')}
						boxClassName="custom-calculation-co2--icon-info-content"
						boxDirection="right-end"
					/>
				</Flexbox>
				<div>
					{co2PerKW !== userSettingsDefaultValues.co2PerKW && (
						<ButtonWithLoader
							className="button-to-default"
							color="transparent"
							loading={carbonResetLoading}
							onClick={() => onSaveCarbonIntensity(true)}
						>
							{getString('RETURN_TO_DEFAULT')}
						</ButtonWithLoader>
					)}
					<ButtonWithLoader
						loading={carbonLoading}
						onClick={() => onSaveCarbonIntensity(false)}
						disabled={isEmpty(co2PerKwValue) || isCarbonError}
					>
						{getString('SET')}
					</ButtonWithLoader>
				</div>
			</Flexbox>
			{isCarbonError && (
				<div className="custom-calculation-co2--error">
					{getString('CARBON_CO2_CALCULATION_VALIDATION')}
				</div>
			)}
		</>
	)
}

const mapStateToProps = (CustomizeCO2CalculationReducer: IReduxStore) => {
	return {
		...CustomizeCO2CalculationReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizeCO2CarbonIntensity)
)
