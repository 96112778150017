import {
	CHECK_SYSTEM_PARAMETERS,
	CLOSE_SSE_CONNECTIONS,
	HANDLE_LOADER,
	RESET_LOADER,
	RESET_SSE_CONNECTIONS,
	SETUP_SSE_CONNECTIONS
} from '../global actions/types'

const INITIAL_STATE = {
	loaderCounter: 0,
	isOnPrem: false,
	isAllowRegister: false,
	sseConnections: []
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case HANDLE_LOADER:
			return {
				...state,
				loaderCounter: (state.loaderCounter += action.payload)
			}
		case RESET_LOADER:
			return {
				...state,
				loaderCounter: INITIAL_STATE.loaderCounter
			}
		case CHECK_SYSTEM_PARAMETERS:
			return {
				...state,
				isOnPrem: action.payload.isOnPrem,
				isAllowRegister: action.payload.isAllowRegister
			}
		case SETUP_SSE_CONNECTIONS: {
			const { eventSource } = action.payload
			return {
				...state,
				sseConnections: [...state.sseConnections, eventSource]
			}
		}
		case CLOSE_SSE_CONNECTIONS: {
			return {
				...state,
				sseConnections: state.sseConnections.filter(
					connection => connection.url !== action.payload.url
				)
			}
		}
		case RESET_SSE_CONNECTIONS: {
			return {
				...state,
				sseConnections: []
			}
		}

		default:
			return state
	}
}
