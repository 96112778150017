import React, { FC } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { COST_PARAMS_IDS, STEP_ID } from '../../constants'
import { useStep } from '../../OnboardingWizardHooks'
import { isContentPresent } from '../../OnboardingWizardService'
import OnboardingStepFrame from '../OnboardingStepFrame'
import IncludeAllCostsCheckbox from './IncludeAllCostsCheckbox'
import CustomizeAnalysisCost from 'Scenes/Home/Customize/CustomizeAnalysisCost'
import { ICostAnalysisParam } from 'Services/models/ICostAnalysisParameter'

import './CustomizeCostParams.scss'

const CustomizeCostParamsComponents = {
	[COST_PARAMS_IDS.COST_PARAMS]: <CustomizeAnalysisCost />,
	[COST_PARAMS_IDS.INCLUDE_ALL_CHECKBOX]: <IncludeAllCostsCheckbox />
}

const CustomizeCostParams: FC = () => {
	const { steps, includeAllCostsCheckbox } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { initialCostAnalysis } = useSelector(
		(state: RootStateOrAny) => state.CustomizeAnalysisCostReducer
	)
	const { step, stepContent, childSteps } = useStep(
		steps,
		STEP_ID.CUSTOMIZE_COST_PARAMS
	)
	const changesInCurrentStep =
		(isContentPresent(stepContent, COST_PARAMS_IDS.COST_PARAMS) &&
			initialCostAnalysis.some((data: ICostAnalysisParam) => !data.isOn)) ||
		(isContentPresent(stepContent, COST_PARAMS_IDS.INCLUDE_ALL_CHECKBOX) &&
			includeAllCostsCheckbox)

	return (
		<OnboardingStepFrame
			step={step}
			bodyClassName="customize-cost-params-frame"
			childSteps={childSteps}
			changesInCurrentStep={changesInCurrentStep}
		>
			<div>
				{stepContent?.map(component => (
					<React.Fragment key={component.id}>
						{
							CustomizeCostParamsComponents[
								component.id as keyof typeof CustomizeCostParamsComponents
							]
						}
					</React.Fragment>
				))}
			</div>
		</OnboardingStepFrame>
	)
}

export default CustomizeCostParams
