import React, { FC, memo } from 'react'

import { CASTOR_LINK } from '../../../Services/Constants'
import { POWERED_BY } from '../../../Services/Strings'
import { getTheme } from '../../../themes/getTheme'
import Flexbox from 'Scenes/Components/FlexBox'

import logoImage from '../../../assets/img/castorLogoBlack.png'

import './PoweredBy.scss'

const { showPoweredBy } = getTheme()

interface IProps {
	className?: string
	logoClassName?: string
	columnDirection?: boolean
}

const PoweredBy: FC<IProps> = ({
	className = '',
	logoClassName = '',
	columnDirection
}) => {
	if (!showPoweredBy) {
		return <div />
	}
	return (
		<div className={className}>
			<a target="_blank" href={CASTOR_LINK}>
				<Flexbox
					flexDirection={columnDirection ? 'column' : 'row'}
					alignItems={
						columnDirection || showPoweredBy ? 'center' : 'flex-start'
					}
					justifyContent={
						showPoweredBy && !columnDirection ? 'center' : 'space-between'
					}
				>
					<div className="poweredBy--text">{POWERED_BY}</div>
					<img
						alt="poweredBy"
						className={`poweredBy--logo ${logoClassName}`}
						src={logoImage}
					/>
				</Flexbox>
			</a>
		</div>
	)
}

export default memo(PoweredBy)
