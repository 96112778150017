import {
  HANDLE_NOTIFICATION,
  NOTIFICATION_CLEARED,
  NOTIFICATION_HAD_SHOWN
} from '../../../global actions/types'

const INITIAL_STATE = {
  notificationType: '',
  notificationMessage: '',
  notificationTime: 3000,
  removeNotification: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HANDLE_NOTIFICATION: {
      return {
        ...state,
        ...action.payload
      }
    }
    case NOTIFICATION_HAD_SHOWN: {
      return {
        ...state,
        ...INITIAL_STATE
      }
    }
    case NOTIFICATION_CLEARED: {
      return {
        ...state,
        removeNotification: true
      }
    }
    default:
      return state
  }
}
