import React, { FC, memo } from 'react'

import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import {
	EMISSION_COMPARISON_TITLE,
	EMISSION_COMPARISON_EXPLANATION_ARRAY
} from 'Services/Strings'
import CustomizeCO2CostTbl from './CustomizeCO2CostTbl'
import { FeatureComponentId } from 'Services/models/Features'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { CustomizeCO2Props } from './CustomizeCO2Interface'

const CustomizeCO2Cost: FC<CustomizeCO2Props> = props => {
	return (
		<div className="customize">
			<CastorFormHeader
				explanationArray={EMISSION_COMPARISON_EXPLANATION_ARRAY}
				isInCard={true}
			/>
			<CustomizeCO2CostTbl {...props} />
		</div>
	)
}

export default memo(
	WithFeatureToggleHOC(CustomizeCO2Cost, FeatureComponentId.CO2_EMISSIONS)
)
