import { FC, memo } from 'react'

import cx from 'classnames'
import { isEmpty, isString } from 'lodash'

import { Danger } from '../thirdParty/CreativeTim/components'
import DetailsPopup from 'Scenes/Components/DetailsPopup'

import './DataTable.scss'

interface IProps {
	text: string | number
	className: string
	data: any
}

const DataTableTextField: FC<IProps> = ({ text, className, data = {} }) => {
	let dataTableText =
		(isString(text) && isEmpty(text)) || text == null ? '' : text

	return (
		<DetailsPopup
			inPortal={true}
			isHover={data.tooltip}
			data={data.tooltipText}
			managerClassName={cx('data-table-text-field with-border', {
				'visible-error': data.showErrorForTextField && data.error
			})}
			popperDirection="left-start"
			popperClassName="data-table-long-text-popper-message"
			popperContactClassName="data-table-long-text-popper-data"
		>
			<div
				data-qa={`data-qa-part-filed-${dataTableText}`}
				className={cx('data-table-text-field', className, data.className, {
					'with-tooltip': data.tooltip
				})}
				title={data.tooltip || text == null ? '' : text.toString()}
			>
				{dataTableText}
			</div>
			{data.showErrorForTextField && data.error && (
				<Danger className={`text_field__error error-in-text-field`}>
					{data.error}
				</Danger>
			)}
		</DetailsPopup>
	)
}

export default memo(DataTableTextField)
