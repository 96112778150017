import { enc } from 'crypto-js'
import AES from 'crypto-js/aes'

import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../global actions/types/CastorAlertTypes'
import { store } from '../../index'
import { EXTERNAL } from '../../Services/Constants/RoutesConstants'
import { IUserProvider } from '../../Services/models/IUserProvider'
import { getClientURL } from '../../Services/Network'
import {
	getFileStatus,
	isLoggedin,
	sendPartToProvider,
	updateToken
} from '../../Services/Network/integration-provider'
import Poller from '../../Services/PollingService/Poller'
import { getString } from '../../Services/Strings/StringService'
import { HANDLE_NOTIFICATION } from 'global actions/types'
import { FINISH_SEND_PART_TO_CONSUMER } from 'global actions/types/partAnalysisTypes'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { SHOW_NOTIFICATION } from 'Services/Strings'

export class IntegrationProviderService {
	poller: Poller

	private stateKey: string

	constructor() {
		this.poller = new Poller()
		this.stateKey = '2b54f8ee-5020-49da-ad9f-b1f29e25f217'
	}

	private providerAuthorize(
		userProvider: IUserProvider,
		partName: string,
		partStlUrl: string
	) {
		const state = {
			location: window.location.href,
			provider_printer: userProvider,
			partStlUrl,
			partName
		}
		const query = new URLSearchParams({
			client_id: userProvider.auth.clientId,
			redirect_uri: `${getClientURL()}${EXTERNAL}`,
			scope: userProvider.auth.scope,
			state: this.encryptState(state),
			response_type: 'code'
		})
		window.location.replace(`${userProvider.auth.authUrl}/authorize?${query}`)
	}

	encryptState(state: any) {
		return AES.encrypt(JSON.stringify(state), this.stateKey).toString()
	}

	decryptState(state: any) {
		return JSON.parse(
			AES.decrypt(state.replaceAll(' ', '+'), this.stateKey).toString(enc.Utf8)
		)
	}

	getQueryParams(urlSearchParams: URLSearchParams) {
		return {
			state: this.decryptState(urlSearchParams.get('state') || ''),
			code: urlSearchParams.get('code')
		}
	}

	async sendPartToProvider(
		partName: string,
		partStlUrl: string,
		partCastorPath: string,
		userProvider: IUserProvider,
		user_email: string,
		configuration: any,
		partActualName?: string
	) {
		try {
			const query = `?user_email=${user_email}&provider_guid=${userProvider.auth.guid}`
			const loggedIn = (await isLoggedin(query))?.data?.success
			if (!loggedIn) {
				this.providerAuthorize(userProvider, partName, partStlUrl)
			} else {
				const configurationData = {
					printerId: configuration?.solution?.printer?.id,
					materialId: configuration?.solution?.printerMaterial?.id,
					quantity: configuration?.quantity
				}
				const result = await sendPartToProvider({
					part_name: partName,
					part_stl_url: partStlUrl,
					part_castor_path: partCastorPath,
					provider_guid: userProvider.auth.guid,
					user_email,
					part_actual_name: partActualName,
					configurationData
				})
				store.dispatch({
					type: ALERT_POPPED,
					payload: {
						alertType: AlertType.LOADING,
						text: getString('SEND_TO_INTEGRATION_POPUP_TEXT').format(
							userProvider.auth.description
						),
						headerTitle: getString('SEND_TO_INTEGRATION_POPUP_TITLE').format(
							userProvider.auth.description
						),
						showConfirm: false
					}
				})
				await this.handlePoller(result?.data?.file_id, query)
				const fileStatus = await getFileStatus(result?.data?.file_id, query)
				if (fileStatus?.data?.file?.status === 'uploaded') {
					this.displayUploadedPopup(
						userProvider.auth.description,
						fileStatus?.data?.file?.file_path,
						fileStatus?.data?.file?.library_project_id
					)
				}
			}
		} catch (error: any) {
			store.dispatch({
				type: FINISH_SEND_PART_TO_CONSUMER,
				payload: {}
			})
			store.dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: `Could not reach the server.
          Please try again later or contact Customer Support.`
				}
			})
			throw error
		}
	}

	displayUploadedPopup(
		providerDescription: string,
		library_projectURI: string,
		library_project_id: string
	) {
		store.dispatch({
			type: ALERT_POPUP_CANCELED
		})
		store.dispatch({
			type: ALERT_POPPED,
			payload: {
				text: getString('SEND_TO_INTEGRATION_FINISH_POPUP_TEXT').format(
					providerDescription
				),
				headerTitle: getString('SEND_TO_INTEGRATION_FINISH_POPUP_TITLE'),
				showConfirm: true,
				showCancel: library_project_id,
				alertType: AlertType.SUCCESS,
				onConfirm: () => {
					store.dispatch({
						type: ALERT_POPUP_CANCELED
					})
					if (library_project_id) {
						window.open(library_projectURI, '_blank')?.focus()
					}
				},
				confirmText: getString(
					library_project_id
						? 'SEND_TO_INTEGRATION_FINISH_POPUP_BUTTON'
						: 'CLOSE'
				).format(providerDescription)
			}
		})
		store.dispatch({
			type: FINISH_SEND_PART_TO_CONSUMER
		})
	}

	async updateToken(body: {}) {
		return await updateToken(body)
	}

	async handlePoller(file_id: string, query: string) {
		let timeOutDateTime = new Date()
		timeOutDateTime.setMinutes(timeOutDateTime.getMinutes() + 5)
		this.poller = new Poller(
			5000,
			timeOutDateTime,
			() => getFileStatus(file_id, query),
			(res: any) => res.data.file.status === 'uploaded'
		)

		return await this.poller.start()
	}

	clearPoller() {
		if (this.poller.pollerInterval) {
			this.poller.stop()
		}
	}
}

export default IntegrationProviderService
