import React, { FC } from 'react'

import { getString } from 'Services/Strings/StringService'

const DescriptionBlock: FC = () => {
	return (
		<div className="done-screen--description">
			<h4>{getString('ONBOARDING_DONE_SCREEN_HEADING')}</h4>
			<p>{getString('ONBOARDING_DONE_SCREEN_DESCRIPTION')}</p>
		</div>
	)
}

export default DescriptionBlock
