import React, { FC, memo } from 'react'

import cx from 'classnames'

import ButtonWithLoader from '../ButtonWithLoader'
import DetailsPopup from '../DetailsPopup'
import IconFactory from '../StarIcon/IconFactory'

import './DataTable.scss'

interface Props {
	text: any
	data: any
	className: string
}

const DataTableButtonWithPopupAndIcon: FC<Props> = ({
	text,
	data: {
		hoverText,
		isHover,
		onClick,
		disabled,
		buttonClassName,
		className: hoverClassName,
		buttonLoading = false,
		iconName,
		iconClassName,
		textClassName
	},
	className
}) => (
	<DetailsPopup
		isHover={isHover}
		data={hoverText}
		popperDirection="bottom"
		popperClassName={cx('info-box-wrapper', hoverClassName)}
		popperContactClassName="info-box-data"
	>
		<div className="data-table-button-with-loader">
			<ButtonWithLoader
				className={`${buttonClassName} ${className}`}
				onClick={onClick}
				loading={buttonLoading}
				disabled={disabled}
			>
				<IconFactory iconName={iconName} className={iconClassName} />
				<div className={textClassName}>{text}</div>
			</ButtonWithLoader>
		</div>
	</DetailsPopup>
)

export default memo(DataTableButtonWithPopupAndIcon)
