import {
  GET_CLUSTER_ANALYSIS_SUCCESS,
  GET_CLUSTER_ANALYSIS,
  GET_CLUSTER_ANALYSIS_FAILED,
  CLUSTER_UPDATED
} from '../../../../global actions/types'

const INITIAL_STATE = {
  loading: false,
  cluster: null,
  error: null,
  clusterId: null,
  project: null,
  solution: null,
  inHouse: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CLUSTER_ANALYSIS:
      return { ...state, loading: true, clusterId: action.payload }

    case GET_CLUSTER_ANALYSIS_SUCCESS:
      const inHouse = action.payload.showInHouseSolutions

      const cluster = action.payload.cluster
      const solution = action.payload.solution
      return { ...state, solution, cluster, loading: false, inHouse }
    case GET_CLUSTER_ANALYSIS_FAILED:
      return { ...state, loading: false, error: action.payload }
    case CLUSTER_UPDATED: {
      const cluster = action.payload.cluster
      return { ...state, cluster, solution: action.payload.solution }
    }
    default:
      return state
  }
}
