import React, { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { ClickAwayListener } from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'
import cx from 'classnames'

import DetailsPopup from '../DetailsPopup'
import IconFactory from '../StarIcon/IconFactory'
import { toggleNotificationView } from './PushNotificationActions'
import { usePushNotificationEvent } from './PushNotificationHooks'
import PushNotificationList from './PushNotificationList'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

const PushNotification: FC = () => {
	const { isOpen, newMessagesAmount, failedConnection } = useSelector(
		(state: RootStateOrAny) => state.PushNotificationReducer
	)
	const dispatch = useDispatch()

	usePushNotificationEvent()

	const setShowMessages = (open: boolean) =>
		dispatch(toggleNotificationView(open))

	return (
		<ClickAwayListener onClickAway={() => setShowMessages(false)}>
			<div className="push-notification">
				<div
					className="push-notification_icon"
					data-qa="data-qa-push-notification-icon"
					onClick={() => setShowMessages(!isOpen)}
				>
					<NotificationsIcon />
					{failedConnection && (
						<div
							className={cx('push-notification_status status text warning')}
							data-qa="data-qa-new-notification-icon"
						>
							<DetailsPopup
								isHover={true}
								data={getString('NOTIFICATION_MESSAGE_FAIL')}
								popperDirection="top-end"
								popperClassName="info-box-wrapper"
								popperContactClassName="info-box-data"
								targetClassName="weight-reduction-info-popup"
								inPortal
							>
								<IconFactory iconName={'warning'} />
							</DetailsPopup>
						</div>
					)}
					{!failedConnection && newMessagesAmount > 0 && (
						<div
							className={cx('push-notification_status status text')}
							data-qa="data-qa-new-notification-icon"
						>
							{newMessagesAmount}
						</div>
					)}
				</div>
				<PushNotificationList />
			</div>
		</ClickAwayListener>
	)
}

export default WithFeatureToggleHOC(
	memo(PushNotification),
	FeatureComponentId.PUSH_NOTIFICATIONS
)
