import { getDefaultSelectedFunctionCategory } from './CostingFunctionEditorService'
import {
	CostingFunctionSteps,
	ICustomFunction,
	IFunctionCategory,
	IFunctionVariable,
	IFunctionVariableExtended
} from './CustomizeCostingFunctionTypes'
import {
	ADD_VARIABLE_TO_FUNCTION,
	CLEAR_CUSTOM_FUNCTION,
	CUSTOM_FUNCTION_FAIL,
	CUSTOM_FUNCTION_SUCCESS,
	DELETE_CUSTOM_FUNCTION,
	DELETE_CUSTOM_VARIABLE,
	DELETE_VARIABLE_FROM_FUNCTION,
	ERROR_ADD_CUSTOM_VARIABLE,
	ERROR_FETCHING_VARIABLES,
	FUNCTION_TYPE_SELECTED,
	ROLL_BACK_TO_FIRST_STEP,
	SETUP_COSTING_FUNCTION_EDITOR,
	START_ADD_CUSTOM_VARIABLE,
	START_FETCHING_VARIABLES,
	START_SAVING_CUSTOM_FUNCTION,
	SUCCESS_ADD_CUSTOM_VARIABLE,
	TECHNOLOGY_VARIABLES_FETCHED,
	TOGGLE_COSTING_FUNCTION_ACCORDION,
	TOGGLE_FUNCTION_PRINTING_TECHNOLOGY,
	TOGGLE_PARAMETER_MODAL
} from 'global actions/types'
import { IFunctionStringParam } from 'Services/models/IFunctionStringParam'

export class FunctionEditorInitialState {
	functionCategoriesList = [] as IFunctionCategory[]
	selectedFunctionCategory = ''
	selectedPrintingTechnologies = []
	variablesList = [] as IFunctionVariableExtended[]
	operatorsList = [] as IFunctionVariable[]
	customFunction = [] as IFunctionVariableExtended[]
	allCustomFunctionsList = [] as ICustomFunction[]
	customFunctionsList = [] as ICustomFunction[]
	addCustomVariableLoading = false
	isLoadingStep1 = false
	isLoadingStep2 = false
	isStep2ToggleAllowed = false
	stepIsOpen = {
		[CostingFunctionSteps.SelectFunctionAndTechnology]: false,
		[CostingFunctionSteps.CreateFunction]: false,
		[CostingFunctionSteps.ViewAndEdit]: false
	}
	parameterModalIsOpen = false
}

const INITIAL_STATE = new FunctionEditorInitialState()

const CostingFunctionEditorReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case TOGGLE_COSTING_FUNCTION_ACCORDION: {
			const { step } = action.payload
			return {
				...state,
				stepIsOpen: {
					...state.stepIsOpen,
					[step]: !state.stepIsOpen[step as CostingFunctionSteps]
				}
			}
		}
		case FUNCTION_TYPE_SELECTED: {
			const { type } = action.payload
			return {
				...state,
				selectedFunctionCategory: type
			}
		}
		case TOGGLE_FUNCTION_PRINTING_TECHNOLOGY: {
			const { selectedPrintingTechnologies } = action.payload
			return {
				...state,
				selectedPrintingTechnologies
			}
		}
		case START_FETCHING_VARIABLES: {
			return {
				...state,
				isLoadingStep1: true
			}
		}
		case ERROR_FETCHING_VARIABLES: {
			return {
				...state,
				isLoadingStep1: false
			}
		}
		case TECHNOLOGY_VARIABLES_FETCHED: {
			const { variablesList } = action.payload
			return {
				...state,
				isLoadingStep1: false,
				isStep2ToggleAllowed: true,
				variablesList
			}
		}
		case ADD_VARIABLE_TO_FUNCTION: {
			const { variable } = action.payload
			return {
				...state,
				customFunction: [...state.customFunction, variable]
			}
		}
		case CLEAR_CUSTOM_FUNCTION: {
			return {
				...state,
				customFunction: []
			}
		}
		case DELETE_VARIABLE_FROM_FUNCTION: {
			const { variableId } = action.payload
			const filteredFunction = state.customFunction.filter(
				variable => variable.id !== variableId
			)
			return {
				...state,
				customFunction: filteredFunction
			}
		}
		case TOGGLE_PARAMETER_MODAL: {
			const { isOpen } = action.payload
			return {
				...state,
				parameterModalIsOpen: isOpen
			}
		}
		case START_ADD_CUSTOM_VARIABLE: {
			return {
				...state,
				addCustomVariableLoading: true
			}
		}
		case ERROR_ADD_CUSTOM_VARIABLE: {
			return {
				...state,
				addCustomVariableLoading: false
			}
		}
		case SUCCESS_ADD_CUSTOM_VARIABLE: {
			const { variableToAdd } = action.payload
			return {
				...state,
				addCustomVariableLoading: false,
				variablesList: [...state.variablesList, variableToAdd]
			}
		}
		case DELETE_CUSTOM_VARIABLE: {
			const { param } = action.payload as { param: IFunctionStringParam }

			const filteredCustomVariables = state.variablesList.filter(
				variable => variable.id !== param.id
			)
			const filteredFunction = state.customFunction.filter(
				variable => variable.name !== param.name
			)
			return {
				...state,
				variablesList: filteredCustomVariables,
				customFunction: filteredFunction
			}
		}
		case START_SAVING_CUSTOM_FUNCTION: {
			return {
				...state,
				isLoadingStep2: true
			}
		}
		case CUSTOM_FUNCTION_FAIL: {
			return {
				...state,
				isLoadingStep2: false
			}
		}
		case CUSTOM_FUNCTION_SUCCESS: {
			const { customFunctionToAdd } = action.payload
			return {
				...state,
				customFunctionsList: [
					...state.customFunctionsList,
					customFunctionToAdd
				],
				allCustomFunctionsList: [
					...state.allCustomFunctionsList,
					customFunctionToAdd
				],
				customFunction: [],
				selectedPrintingTechnologies: [],
				selectedFunctionCategory: getDefaultSelectedFunctionCategory(
					state.functionCategoriesList
				),
				isLoadingStep2: false,
				isStep2ToggleAllowed: false,
				stepIsOpen: {
					...state.stepIsOpen,
					[CostingFunctionSteps.CreateFunction]: false,
					[CostingFunctionSteps.ViewAndEdit]: true
				}
			}
		}
		case SETUP_COSTING_FUNCTION_EDITOR: {
			const {
				customFunctionsList,
				functionCategoriesList,
				setUpCategory,
				originalCustomFunctionsList
			} = action.payload

			const filteredCustomFunctionList = customFunctionsList.filter(
				(customFunction: ICustomFunction) =>
					functionCategoriesList.find(
						(category: IFunctionCategory) =>
							category.name === customFunction.category
					)
			)

			return {
				...state,
				allCustomFunctionsList: originalCustomFunctionsList,
				customFunctionsList: filteredCustomFunctionList,
				functionCategoriesList,
				selectedFunctionCategory: setUpCategory
					? getDefaultSelectedFunctionCategory(functionCategoriesList)
					: state.selectedFunctionCategory,
				stepIsOpen: {
					...state.stepIsOpen,
					[CostingFunctionSteps.SelectFunctionAndTechnology]:
						functionCategoriesList.length > 0,
					[CostingFunctionSteps.ViewAndEdit]:
						filteredCustomFunctionList.length > 0
				}
			}
		}
		case DELETE_CUSTOM_FUNCTION: {
			const { functionId } = action.payload
			const filteredAllFunctions = state.allCustomFunctionsList.filter(
				customFunction => customFunction.id !== functionId
			)
			const filteredFunctions = state.customFunctionsList.filter(
				customFunction => customFunction.id !== functionId
			)
			return {
				...state,
				customFunctionsList: filteredFunctions,
				allCustomFunctionsList: filteredAllFunctions,
				stepIsOpen: {
					...state.stepIsOpen,
					[CostingFunctionSteps.ViewAndEdit]: filteredFunctions.length > 0
				}
			}
		}
		case ROLL_BACK_TO_FIRST_STEP: {
			return {
				...state,
				variablesList: [],
				customFunction: [],
				isStep2ToggleAllowed: false,
				stepIsOpen: {
					...state.stepIsOpen,
					[CostingFunctionSteps.CreateFunction]: false
				}
			}
		}
		default:
			return state
	}
}

export default CostingFunctionEditorReducer
