import {
	ADD_MATERIALS_BOM_FILE,
	ADD_MATERIALS_BOM_LOADING,
	ADD_MATERIALS_BOM_POPUP,
	COST_AND_LEAD_TBL_DATA_ARRANGED,
	USER_DESKTOP_MATERIAL_TOGGLED,
	USER_UNITS_TYPE_SELECTED
} from '../../../global actions/types'

const INITIAL_STATE = {
	desktopPrinter: false,
	isShowSemiProfessionalPrintersToggle: false,
	BOMMaterialsFile: null,
	isMaterialsNameLoading: false,
	isOpenMaterialsNamePopup: false,
	fileInputKey: Date.now(),
	selectedUnitsType: ''
}

const customizeReducer = (state = INITIAL_STATE, { type, payload }) => {
	switch (type) {
		case USER_DESKTOP_MATERIAL_TOGGLED:
			return {
				...state,
				desktopPrinter: !state.desktopPrinter
			}
		case USER_UNITS_TYPE_SELECTED:
			const { unitsType } = payload
			return {
				...state,
				selectedUnitsType: unitsType
			}
		case COST_AND_LEAD_TBL_DATA_ARRANGED:
			const {
				originalUserSettingsValues: { desktopPrinter },
				isShowSemiProfessionalPrintersToggle,
				selectedUnitsType
			} = payload
			return {
				...state,
				desktopPrinter,
				isShowSemiProfessionalPrintersToggle,
				selectedUnitsType
			}
		case ADD_MATERIALS_BOM_FILE: {
			return {
				...state,
				BOMMaterialsFile: payload
			}
		}
		case ADD_MATERIALS_BOM_LOADING: {
			return {
				...state,
				isMaterialsNameLoading: payload,
				...(!payload && { isOpenMaterialsNamePopup: false })
			}
		}
		case ADD_MATERIALS_BOM_POPUP: {
			return {
				...state,
				isOpenMaterialsNamePopup: payload
			}
		}
		default:
			return state
	}
}

export default customizeReducer
