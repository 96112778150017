import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import {
	connect,
	DispatchProp,
	RootStateOrAny,
	useDispatch,
	useSelector
} from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { TextField } from '@material-ui/core'

import * as CustomizeActions from '../../CustomizeActions'
import { onSetUserCustomizationSettings } from '../CustomizeCO2CalculationAction'
import { ParameterTypes } from '../ParameterTypes'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'
import { isDisabledKeyPressed } from 'Services/getKeyCodesService'
import { getString } from 'Services/Strings/StringService'

const TextFieldTSX: any = TextField

interface IProps {
	disposeFactorValue: string
	setUserCustomzationSettings: Function
	disposeFactorChange: Function
}

interface IReduxStore {
	user: any
	CustomizeCO2CalculationReducer: any
}

const CustomizeCO2EndOfLife: FC<IProps> = ({
	disposeFactorValue,
	disposeFactorChange
}) => {
	const [isEndOfLifeError, setEndOfLifeError] = useState(false)
	const { disposeFactorLoading, disposeFactor } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const dispatch = useDispatch()

	useEffect(() => {
		if (!disposeFactorLoading && disposeFactor !== disposeFactorValue) {
			dispatch(disposeFactorChange(''))
		}
	}, [disposeFactor, disposeFactorLoading])

	useEffect(() => {
		if (!disposeFactorLoading) {
			disposeFactorChange('')
		}
	}, [disposeFactorLoading])

	const disposeFactorCheck = (value: string) => {
		if (Number(value) < 0 || Number(value) > 25) {
			setEndOfLifeError(true)
		} else {
			setEndOfLifeError(false)
		}
		disposeFactorChange(value)
	}

	const handleKeyDown = (event: KeyboardEvent) => {
		if (isDisabledKeyPressed(event)) {
			event.preventDefault()
		}
	}

	const onSaveProductUse = (reset: boolean = false) => {
		if (disposeFactorValue !== '' || reset) {
			dispatch(
				onSetUserCustomizationSettings(
					reset ? null : (disposeFactorValue as any),
					ParameterTypes.disposeFactor,
					reset
				)
			)
		}
	}

	const renderEndOfLife = () => {
		return (
			<>
				<div className="custom-calculation-co2--explanation">
					{getString('CO2_CALCULATION_END_OF_LIFE_SUBTITLE')}
				</div>
				<Flexbox
					flexDirection="row"
					className="custom-calculation-co2--wrapper"
				>
					<Flexbox
						flexDirection="row"
						alignItems="center"
						width="100%"
						gap="13px"
					>
						<div className="custom-calculation-co2--explanation-text">
							{getString('CO2_CALCULATION_END_OF_LIFE_INPUT_TEXT')}
						</div>
						<TextFieldTSX
							className="custom-calculation-co2--end-of-life-picker"
							type="number"
							placeholder={disposeFactor}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								disposeFactorCheck(e.target.value)
							}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							onKeyDown={handleKeyDown}
							value={disposeFactorValue}
						/>
					</Flexbox>
					<ButtonWithLoader
						loading={disposeFactorLoading}
						onClick={() => onSaveProductUse(false)}
						disabled={isEndOfLifeError || !disposeFactorValue}
					>
						{getString('SET')}
					</ButtonWithLoader>
				</Flexbox>
				{isEndOfLifeError && (
					<div className="custom-calculation-co2--error">
						{getString('CO2_CALCULATION_END_OF_LIFE_DATA_VALIDATION')}
					</div>
				)}
			</>
		)
	}

	return <>{renderEndOfLife()}</>
}

const mapStateToProps = (CustomizeCO2CalculationReducer: IReduxStore) => {
	return {
		...CustomizeCO2CalculationReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizeCO2EndOfLife)
)
