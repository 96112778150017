import React, { ChangeEvent, FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import TextField from '@material-ui/core/TextField'
import cx from 'classnames'
import { isBoolean, map, some } from 'lodash'

import {
	changeEmailManager,
	getEmailManager,
	updateEmailManager,
	verifyEmailManager
} from 'Scenes/admin/AdminHome/AdminSettings/AdminSettingsActions'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import Flexbox from 'Scenes/Components/FlexBox'
import NumberField from 'Scenes/Components/NumberField'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import Loader from 'Scenes/Loader/Loader'
import HideForRoleHOC from 'Services/HOC/HideForRoleHOC'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { SUBMIT } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const AdminMailManager: FC = () => {
	const dispatch = useDispatch()
	const {
		mailError,
		mailVerifiedLoading,
		mailVerified,
		mailData,
		mailLoading,
		fetchMailLoading
	} = useSelector((state: RootStateOrAny) => {
		return state.AdminSettingsReducer
	})
	const disabledButton = some(mailData, field => field.error)

	useEffect(() => {
		dispatch(getEmailManager())
	}, [])

	return (
		<CastorForm
			formTitle={getString('ADMIN_SMTP_SETTINGS')}
			content={
				<div className="smtp-form">
					{fetchMailLoading ? (
						<Loader load={true} />
					) : (
						<div className="smtp-form--fields">
							{map(mailData, field => {
								return (
									<div key={field.key} className="smtp-form__field">
										{field.isNumber ? (
											<NumberField
												error={field.error}
												disabled={field.disabled}
												helperText={
													!!field.error
														? field.validationMessage ||
														  getString('STRING_VALIDATION')
														: ''
												}
												shrink
												minValue={0}
												label={field.label}
												value={field.value}
												placeholder={field.value || field.placeholder}
												onChangeValue={(value: number) =>
													dispatch(changeEmailManager(field.key, value))
												}
												showArrows={false}
											/>
										) : (
											<TextField
												error={field.error}
												disabled={field.disabled}
												helperText={
													!!field.error
														? field.validationMessage ||
														  getString('STRING_VALIDATION')
														: ''
												}
												InputLabelProps={{
													shrink: true
												}}
												type="text"
												label={field.label}
												value={field.value}
												placeholder={field.value || field.placeholder}
												onChange={(e: ChangeEvent<HTMLInputElement>) =>
													dispatch(
														changeEmailManager(field.key, e.target.value)
													)
												}
											/>
										)}
									</div>
								)
							})}
						</div>
					)}
					{isBoolean(mailVerified) && (
						<Flexbox alignItems="center">
							<span
								className={cx({
									'icon-success': mailVerified,
									'icon-fail': !mailVerified
								})}
							></span>
							<span>
								{mailVerified
									? getString('ADMIN_SMTP_VERIFY_SUCCESS')
									: getString('ADMIN_SMTP_VERIFY_FAIL')}
							</span>
						</Flexbox>
					)}
					{mailError && (
						<p className="error">{getString('ADMIN_SMTP_UPDATING_ERROR')}</p>
					)}
					<Flexbox
						alignItems="center"
						alignSelf="flex-end"
						justifyContent="flex-end"
						className="button-block"
					>
						<ButtonWithLoader
							onClick={() => dispatch(verifyEmailManager())}
							loading={mailVerifiedLoading}
							disabled={disabledButton}
						>
							{getString('ADMIN_SMTP_VERIFY')}
						</ButtonWithLoader>
						<ButtonWithLoader
							onClick={() => dispatch(updateEmailManager())}
							loading={mailLoading}
							disabled={disabledButton}
						>
							{SUBMIT}
						</ButtonWithLoader>
					</Flexbox>
				</div>
			}
			style={{ maxWidth: 'unset' }}
		/>
	)
}

export default memo(
	HideForRoleHOC(
		WithFeatureToggleHOC(AdminMailManager, FeatureComponentId.SMTP_SETTINGS),
		[UserRole.SUPER_ADMIN, UserRole.USER_MANAGER],
		true
	)
)
