import React, { FC, memo } from 'react'
import { useDispatch } from 'react-redux'

import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import {
	NO,
	YES
} from 'Services/Strings'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import {
	onRemoveApprove
} from '../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import { getString } from '../../../../../Services/Strings/StringService'

interface ConfigurationRemoveAlertProps {
	setShowRemoveAlert: Function,
	simpleConfiguration: any,
	configurationName: string,
	configurationId: number,
	showRemoveAlert: boolean,
	removeConfigurationLoading: boolean
}

const ConfigurationRemoveAlert: FC<ConfigurationRemoveAlertProps> = ({
	setShowRemoveAlert,
	simpleConfiguration,
	configurationId,
	configurationName,
	showRemoveAlert,
	removeConfigurationLoading
}) => {
	const dispatch = useDispatch()
	const onRemoveAlert = () => setShowRemoveAlert(false)

	return (
		<CastorAlert
			headerTitle={getString('DELETE_CONFIGURATION')}
			onCancel={() => {
				setShowRemoveAlert(false)
			}}
			show={showRemoveAlert}
			onConfirm={() => {
				dispatch(onRemoveApprove(
					configurationId,
					simpleConfiguration,
					onRemoveAlert
				))
			}}
			cancelOptionalText={NO}
			confirmOptionalText={YES}
			alertType={AlertType.WARNING}
			loadingCalculation={removeConfigurationLoading}
		>
			{getString('ARE_YOU_SURE_DELETE_CONFIGURATION').format(configurationName)}
		</CastorAlert>
	)
}

export default memo(ConfigurationRemoveAlert)
