import React, { FC, memo, useEffect } from 'react'
import { connect, DispatchProp, RootStateOrAny, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { AnyAction, bindActionCreators } from 'redux'

import { toNumber } from 'lodash'

import * as AdminUsersActions from '../AdminUsersActions'
import AdminUserForm from '../AdminUserForm'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import ErrorPage from 'Scenes/Components/ErrorPage'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import PageLoader from 'Scenes/Loader/PageLoader'
import { ADMIN_USER_INFO_SUBSCRIPTION_EDIT_ROUTE } from 'Services/Constants/RoutesConstants'
import {
	ADMIN_USER_INFO_EDIT_HEADER,
	ADMIN_USER_INFO_EDIT_SUB_HEADER
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import '../../adminHome.scss'

const NavBarAndMaterialTSX: any = NavBarAndMaterial

interface IProps extends RouteComponentProps<MatchParams> {
	onUserUpdateSubmitClick: Function
	onRemoveUserClick: Function
	userSelected: any
	userUpdateLoading: boolean
	loading: boolean
	fetchUserInfo: Function
	userIdError?: number
	error?: boolean
}

interface IReduxStore {
	AdminUsersReducer: any
}

interface MatchParams {
	user: string
}

const AdminUserSubscriptionEdit: FC<IProps> = ({
	onUserUpdateSubmitClick,
	onRemoveUserClick,
	userSelected,
	userUpdateLoading,
	match: { params },
	fetchUserInfo,
	loading,
	userIdError,
	error
}) => {
	const { user } = params
	const { loaderCounter } = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer
	)

	useEffect(() => {
		const path = window.location.pathname
		if (path.includes(ADMIN_USER_INFO_SUBSCRIPTION_EDIT_ROUTE)) {
			if (
				(error && !!userIdError && toNumber(userIdError) !== toNumber(user)) ||
				(!error &&
					((!userSelected && !loading) ||
						(!loading && toNumber(userSelected?.id) !== toNumber(user))))
			) {
				fetchUserInfo(user)
			}
		}
	}, [userSelected, loading, user, error, userIdError])

	if (loaderCounter) {
		return <PageLoader withDrawer />
	}

	return (
		<NavBarAndMaterialTSX title={ADMIN_USER_INFO_EDIT_HEADER}>
			<div>
				{!userSelected ? (
					<ErrorPage
						showMessageOnly
						errorMessage={getString('ADMIN_USERS_NO_USERS_FOUND')}
					/>
				) : (
					<CastorForm
						formTitle={ADMIN_USER_INFO_EDIT_SUB_HEADER}
						content={
							<AdminUserForm
								initialValues={userSelected}
								onSubmitClick={(data: any) =>
									onUserUpdateSubmitClick(userSelected, data)
								}
								userUpdateLoading={userUpdateLoading}
								onRemoveUser={(data: any) => onRemoveUserClick(userSelected)}
							/>
						}
						style={{ maxWidth: 'unset' }}
					/>
				)}
			</div>
		</NavBarAndMaterialTSX>
	)
}

const mapStateToProps = ({ AdminUsersReducer }: IReduxStore) => {
	return {
		...AdminUsersReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...AdminUsersActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(AdminUserSubscriptionEdit)
)
