import { useEffect, useState } from 'react'

import { holesColor, overhangingColor, wtColor, cncColor, threadsColor } from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { PartPrintIssue, PrintIssueId } from 'Services/models/PartPrintIssue'
import { materialTypes } from 'Services/Constants'
import { Part } from 'Services/models/IPart'

const aColor = holesColor
const bColor = overhangingColor
const cColor = wtColor
const dColor = cncColor
const eColor = threadsColor

const colorsString = 'abcde'

export interface IFeatureColor {
  isFeatureOn: boolean
  color: number[]
}

export const setColor = (featureColors: IFeatureColor[]): number[] => {
  const colorsArr: number[][] = []

  featureColors.forEach(featureColor => {
    if (featureColor.isFeatureOn) {
      colorsArr.push(featureColor.color)
    }
  })

  return getColorFromColorsArray(colorsArr)
}

export const getColorFromColorsArray = (colorsArr: number[][]): number[] => {
  const newColor: number[] = []
  for (let i = 0; i < colorsString.length; i++) {
    let sum = 0

    colorsArr.map(color => {
      sum += color[i]
    })
    const color = sum / colorsArr.length

    !isNaN(color) && newColor.push(color)
  }

  return newColor
}

/**
 * Gets a string and returns an array of all the possible string combinations
 * @param str string
 * @returns Ex: "abc" -> ['a','b','c','ab','ac','bc','abc']
 */
function combinations(str: string): Array<string> | undefined {
  var fn = function (active: string, rest: string, a: Array<string>) {
    if (!active && !rest) return
    if (!rest) {
      a.push(active)
    } else {
      fn(active + rest[0], rest.slice(1), a)
      fn(active, rest.slice(1), a)
    }
    return a
  }
  return fn('', str, [])
}

const isPrinterMaterialMetal = (solution?: any) => {
  return solution?.printerMaterial?.type === materialTypes.metal
}
const isCNCSupportRemovalFeatureOn = (
  solution: any,
  configurationPrintIssues: PartPrintIssue[],
  isSurfaceMachiningOn: boolean,
  showOnlyPrintIssue?: PrintIssueId
) => {
  if (!isSurfaceMachiningOn || !isPrinterMaterialMetal(solution)) {
    return false
  }

  const featureOn =
    Feature.isFeatureOn(FeatureComponentId.CNC_SUPPORT_REMOVAL) &&
    !hidesPrintablePrintIssues(configurationPrintIssues, PrintIssueId.CNC)

  if (!showOnlyPrintIssue) {
    return featureOn
  }
  return showOnlyPrintIssue === PrintIssueId.CNC && featureOn
}

export function useConfig(
  src: string,
  part: Part,
  configurationPrintIssues: PartPrintIssue[],
  isSurfaceMachiningOn: boolean,
  showOnlyPrintIssue?: PrintIssueId,
  solution?: any
) {
  const [holesFeatureColor, setHolesFeatureColor] = useState<IFeatureColor>({
    isFeatureOn:
      !hidesPrintablePrintIssues(configurationPrintIssues, PrintIssueId.Holes) &&
      (((!showOnlyPrintIssue || showOnlyPrintIssue === PrintIssueId.Holes) &&
        Feature.isFeatureOn(FeatureComponentId.HOLES_ANALYSIS)) ||
        false),
    color: aColor
  })
  const [threadsFeatureColor, setThreadsFeatureColor] = useState<IFeatureColor>({
    isFeatureOn:
      !hidesPrintablePrintIssues(configurationPrintIssues, PrintIssueId.Threads) &&
      (((!showOnlyPrintIssue || showOnlyPrintIssue === PrintIssueId.Threads) &&
        Feature.isFeatureOn(FeatureComponentId.THREAD_DETECTION)) ||
        false),
    color: eColor
  })
  const [overhangingFeatureColor, setOverhangingFeatureColor] = useState<IFeatureColor>({
    isFeatureOn:
      !hidesPrintablePrintIssues(configurationPrintIssues, PrintIssueId.Overhanging) &&
      (((!showOnlyPrintIssue || showOnlyPrintIssue === PrintIssueId.Overhanging) &&
        Feature.isFeatureOn(FeatureComponentId.OVERHANGING) &&
        solution?.printer?.hasSupportIssues) ||
        false),
    color: bColor
  })
  const [WTFeatureColor, setWTFeatureColor] = useState<IFeatureColor>({
    isFeatureOn:
      !hidesPrintablePrintIssues(configurationPrintIssues, PrintIssueId.WallThickness) &&
      (!showOnlyPrintIssue || showOnlyPrintIssue === PrintIssueId.WallThickness),
    color: cColor
  })
  const [cncFeatureColor, setCNCFeatureColor] = useState<IFeatureColor>({
    isFeatureOn:
      isCNCSupportRemovalFeatureOn(solution, configurationPrintIssues, isSurfaceMachiningOn, showOnlyPrintIssue) ||
      false,
    color: dColor
  })

  const [colorsNamesToFeaturesColors, setColorsNamesToFeaturesColors] = useState<Record<string, IFeatureColor>>({
    a: holesFeatureColor,
    b: overhangingFeatureColor,
    c: WTFeatureColor,
    d: cncFeatureColor,
    e: threadsFeatureColor
  })

  const createColorsObject = (colorsCombinationNames: Array<string> | undefined) => {
    let colorsObject: Record<string, any> = {}
    let errors = []
    if (!colorsCombinationNames) {
      return colorsObject
    }

    for (let colorName of colorsCombinationNames) {
      const chars = colorName.split('')
      const featuresColors = chars.map((char: string) => colorsNamesToFeaturesColors[char])
      const color = setColor(chars.map((char: string) => colorsNamesToFeaturesColors[char]))
      const id = chars.map(char => char.toUpperCase()).join('_')
      const visible = featuresColors.some(feature => feature.isFeatureOn)
      colorsObject[colorName] = {
        color,
        id,
        visible
      }
      errors.push(colorsObject[colorName])
    }
    return errors
  }

  const [errors, setErrors] = useState<Record<string, any>>(createColorsObject(combinations(colorsString)))

  const [conf, setConf] = useState({
    errors,
    id: '0',
    showXYZ: false,
    url: src,
    boundingBox: part.boundingBox,
    cog: part.cog
  })

  useEffect(() => {
    setConf(config => ({
      ...config,
      errors,
      url: src
    }))
  }, [src, errors])

  useEffect(() => {
    setHolesFeatureColor(color => ({
      ...color,
      isFeatureOn:
        !hidesPrintablePrintIssues(configurationPrintIssues, PrintIssueId.Holes) &&
        (((!showOnlyPrintIssue || showOnlyPrintIssue === PrintIssueId.Holes) &&
          Feature.isFeatureOn(FeatureComponentId.HOLES_ANALYSIS)) ||
          false)
    }))
    setThreadsFeatureColor(color => ({
      ...color,
      isFeatureOn:
        !hidesPrintablePrintIssues(configurationPrintIssues, PrintIssueId.Threads) &&
        (((!showOnlyPrintIssue || showOnlyPrintIssue === PrintIssueId.Threads) &&
          Feature.isFeatureOn(FeatureComponentId.THREAD_DETECTION)) ||
          false)
    }))
    setOverhangingFeatureColor(color => ({
      ...color,
      isFeatureOn:
        !hidesPrintablePrintIssues(configurationPrintIssues, PrintIssueId.Overhanging) &&
        (((!showOnlyPrintIssue || showOnlyPrintIssue === PrintIssueId.Overhanging) &&
          Feature.isFeatureOn(FeatureComponentId.OVERHANGING) &&
          solution?.printer?.hasSupportIssues) ||
          false)
    }))
    setWTFeatureColor(color => ({
      ...color,
      isFeatureOn:
        !hidesPrintablePrintIssues(configurationPrintIssues, PrintIssueId.WallThickness) &&
        (!showOnlyPrintIssue || showOnlyPrintIssue === PrintIssueId.WallThickness)
    }))
    setCNCFeatureColor(color => ({
      ...color,
      isFeatureOn:
        isCNCSupportRemovalFeatureOn(solution, configurationPrintIssues, isSurfaceMachiningOn, showOnlyPrintIssue) ||
        false
    }))
  }, [showOnlyPrintIssue])

  useEffect(() => {
    setColorsNamesToFeaturesColors({
      a: holesFeatureColor,
      b: overhangingFeatureColor,
      c: WTFeatureColor,
      d: cncFeatureColor,
      e: threadsFeatureColor
    })
  }, [holesFeatureColor, WTFeatureColor, overhangingFeatureColor, cncFeatureColor, threadsFeatureColor])

  useEffect(() => {
    setErrors(createColorsObject(combinations(colorsString)))
  }, [colorsNamesToFeaturesColors])

  return conf
}

const hidesPrintablePrintIssues = (configurationPrintIssues: PartPrintIssue[], printIssueId: PrintIssueId) => {
  const printIssueScore = configurationPrintIssues.find(printIssue => printIssue.printIssue.id === printIssueId)?.score
  return Feature.isFeatureOn(FeatureComponentId.HIDES_PRINTABLE_PRINT_ISSUES) && printIssueScore === 100
}
