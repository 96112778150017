import { materialTypes } from '../../../../../../Services/Constants'
import { PrinterMaterial } from '../../../../../../Services/models/IPrinterMaterial'
import {
  FeaAnalysisResults,
  FeaResult,
  ISolutionFea
} from '../../../../../../Services/models/ISolutionFea'
import { getString } from '../../../../../../Services/Strings/StringService'
import { getTheme } from '../../../../../../themes/getTheme'
const theme = getTheme()

export const getUserSliderInput = (
  isRange: boolean,
  selectedValue: number
): number[] => {
  if (!isRange) {
    return [selectedValue]
  }
  const minValue = Math.min(2, selectedValue)
  const maxValue = Math.max(2, selectedValue)
  return [minValue, maxValue]
}

export const getUserFeaValues = (
  userInputs: number[] | null | undefined,
  strength: number,
  SDStrength: number,
  isPercentage: boolean
): number[] => {
  if (!userInputs) {
    return []
  }
  if (!isPercentage && SDStrength) {
    return [
      Math.round(strength - (SDStrength * userInputs[1]) / 2),
      Math.round(strength - (SDStrength * userInputs[0]) / 2)
    ]
  }
  return [Math.round(strength - strength * (userInputs[0] / 100)), strength]
}

export const editSliderMarks = (
  sliderMarks: Record<string, any>,
  isRange: boolean,
  selectedValue: number,
  sliderUnits: string
): Record<string, any> => {
  const currentMarks = { ...sliderMarks }
  if (!isRange) {
    currentMarks[selectedValue] = {
      style: { color: theme.colors.successColor },
      label: `${selectedValue}${sliderUnits}`
    }
    return currentMarks
  }

  const userInputs = getUserSliderInput(isRange, selectedValue)

  for (const key in currentMarks) {
    const parsedKey = parseInt(key)
    if (parsedKey > userInputs[0] && parsedKey < userInputs[1]) {
      currentMarks[key] = {
        style: { color: theme.colors.successColor },
        label: `${parsedKey}${sliderUnits}`
      }
    }
  }

  currentMarks['2'] = {
    style: { color: theme.colors.successColor },
    label: `2${sliderUnits}`
  }
  currentMarks[selectedValue] = {
    style: { color: theme.colors.successColor },
    label: `${selectedValue}${sliderUnits}`
  }
  return currentMarks
}

export const getFeaCompareResult = (
  userFeaValues: number[],
  feaResult: number
): string => {
  if (feaResult <= userFeaValues[0]) {
    return FeaResult.passed
  }
  if (feaResult >= userFeaValues[1]) {
    return FeaResult.failed
  }
  return FeaResult.borderline
}

export const getFeaData = (
  solution: any,
  solutionFea: ISolutionFea,
  feaAnalysisResults?: FeaAnalysisResults
) => {
  if (solution?.id > 0) {
    const showFeaAnalysisOldAnalysis: boolean = !!solutionFea?.feaAnalysisResults
    const solutionPrinterMaterial: PrinterMaterial = solution.printerMaterial
    if (solutionFea && feaAnalysisResults) {
      solutionFea.feaAnalysisResults = { ...feaAnalysisResults }
    }

    let solutionFeaStrengthTitle = getString(
      'MECHANICAL_ANALYSIS_ALERT_STRENGTH_TITLE_PLASTIC'
    )
    let solutionFeaUserInputTitle = getString(
      'MECHANICAL_ANALYSIS_ALERT_USER_INPUT_PLASTIC'
    )
    let solutionFeaStrength =
      solutionPrinterMaterial.ultimateTensileStrengthExt.X.val
    let solutionFeaSDStrength = 0
    let solutionFeaSliderUnits = '%'
    let solutionFeaSliderMarks: Record<string, string> = {
      0: '0%',
      25: '25%',
      50: '50%',
      75: '75%'
    }
    let solutionFeaSliderMaxValue = 75
    let solutionFeaSliderMinValue = 0
    let solutionFeaSliderValue =
      (solutionFea?.feaAnalysisResults?.userInputs &&
        solutionFea.feaAnalysisResults.userInputs[0]) ||
      20
    let solutionFeaSliderStartValue = 0
    let solutionFeaSliderIsRange = false
    const solutionFeaResult = solutionFea?.feaAnalysisResults?.maxVonMisses || 0
    if (solutionPrinterMaterial.type === materialTypes.metal) {
      solutionFeaStrengthTitle = getString(
        'MECHANICAL_ANALYSIS_ALERT_STRENGTH_TITLE_METAL'
      )
      solutionFeaUserInputTitle = getString(
        'MECHANICAL_ANALYSIS_ALERT_USER_INPUT_METAL'
      )
      solutionFeaStrength = solutionPrinterMaterial.yieldStrengthMPaExt.X.val
      solutionFeaSDStrength =
        solutionPrinterMaterial.yieldStrengthMPaExt.X.stdev
      solutionFeaSliderUnits = 'σ'
      solutionFeaSliderMarks = {
        1: '1σ',
        2: '2σ',
        3: '3σ',
        4: '4σ',
        5: '5σ',
        6: '6σ',
        7: '7σ'
      }
      solutionFeaSliderMinValue = 1
      solutionFeaSliderMaxValue = 7
      solutionFeaSliderValue =
        (solutionFea?.feaAnalysisResults?.userInputs &&
          solutionFea.feaAnalysisResults.userInputs.filter(
            userInput => userInput !== 2
          )[0]) ||
        6
      solutionFeaSliderStartValue = 2
      solutionFeaSliderIsRange = true
    }
    const solutionFeaSelectedValues = getUserFeaValues(
      solutionFea?.feaAnalysisResults?.userInputs,
      solutionFeaStrength,
      solutionFeaSDStrength,
      !solutionFeaSliderIsRange
    )
    return {
      solutionFeaStrengthTitle: solutionFeaStrengthTitle.format(
        solutionPrinterMaterial.name.trimEnd()
      ),
      solutionFeaStrength,
      solutionFeaSDStrength,
      solutionFeaUserInputTitle,
      solutionFeaSliderUnits,
      solutionFeaSliderMarks,
      solutionFeaSliderMaxValue,
      solutionFeaSliderValue,
      solutionFeaSliderStartValue,
      solutionFeaSliderIsRange,
      solutionFeaSliderMinValue,
      solutionFeaResult,
      solutionFeaSelectedValues,
      feaAnalysisResultsId: feaAnalysisResults?.id || '',
      showFeaAnalysisOldAnalysis
    }
  }
  return {}
}
