import React, { FC, useEffect, useState } from 'react'

import TextField from '@material-ui/core/TextField'
import { cloneDeep } from 'lodash'

import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import CastorMaterialSelector from '../../../Components/CastorMaterialSelector/CastorMaterialSelector'
import { getSelectedMaterial } from './CastorMaterialNamesMappingService'
import Flexbox from 'Scenes/Components/FlexBox'
import { Material } from 'Services/models/IMaterial'
import { IUserMaterialNamesMapping } from 'Services/models/IMaterialNamesMapping'
import { getString } from 'Services/Strings/StringService'

import './CustomizeMaterialNamesMapping.scss'

interface IProps {
	userMaterialNameMapping: IUserMaterialNamesMapping
	userMaterialNamesMapping?: IUserMaterialNamesMapping[]
	materialCategories: any
	isLoadingMaterial?: boolean
	materialTypes: string[]
	materials: Material[]
	allowRemove: boolean
	updateSelectedMaterial: Function
	removeUserMaterialNameMapping: Function
	changeSelectedMaterial?: Function
}

const MaterialNamesMappingRow: FC<IProps> = ({
	userMaterialNameMapping,
	userMaterialNamesMapping,
	materialCategories,
	materialTypes,
	materials,
	allowRemove = true,
	updateSelectedMaterial,
	removeUserMaterialNameMapping,
	isLoadingMaterial,
	changeSelectedMaterial
}) => {
	const [editMode, setEditMode] = useState(false)
	const [_userMaterialNameMapping, setUserMaterialNameMapping] = useState(
		userMaterialNameMapping
	)
	const [isRemoveLoading, setIsRemoveLoading] = useState(false)
	const [isEditLoading, setIsEditLoading] = useState(false)

	useEffect(
		() => setUserMaterialNameMapping(cloneDeep(userMaterialNameMapping)),
		[userMaterialNameMapping]
	)

	useEffect(() => {
		if (!isLoadingMaterial) {
			setIsEditLoading(false)
			setIsRemoveLoading(false)
		}
	}, [userMaterialNamesMapping, isLoadingMaterial])

	const onChangeSelector = (property: string, value: any) => {
		const tmpUserMaterialNameMapping = _userMaterialNameMapping
		tmpUserMaterialNameMapping.material = getSelectedMaterial(
			materials,
			materialCategories,
			property,
			value
		)
		if (changeSelectedMaterial) {
			changeSelectedMaterial(_userMaterialNameMapping)
		}
		setUserMaterialNameMapping(cloneDeep(tmpUserMaterialNameMapping))
	}

	const onRowClick = (e: any) => {
		if (!editMode) {
			setEditMode(true)
		}
	}

	const onEditClick = () => {
		if (editMode) {
			setIsEditLoading(true)
			updateSelectedMaterial([
				{
					expression: _userMaterialNameMapping.expression,
					material: _userMaterialNameMapping.material,
					active: true
				}
			])
		}
		setEditMode(!editMode)
	}

	const onRemoveClick = (e: any) => {
		e.stopPropagation()
		setIsRemoveLoading(true)
		removeUserMaterialNameMapping(_userMaterialNameMapping.id)
	}

	return (
		<div onClick={e => onRowClick(e)}>
			<Flexbox alignItems="center">
				<TextField
					type="input"
					disabled={true}
					data-qa={`data-qa-material-expression-${
						_userMaterialNameMapping.expression || ''
					}`}
					className="custom-material-names text"
					value={_userMaterialNameMapping.expression || ''}
					variant="standard"
				/>
				<CastorMaterialSelector
					selectedMaterial={_userMaterialNameMapping.material}
					onChangeMaterial={onChangeSelector}
					materialCategories={materialCategories}
					materials={materials}
					disabled={!editMode}
					materialTypes={materialTypes}
				/>
				<ButtonWithLoader
					size={20}
					style={{
						padding: '5px 20px',
						width: 'unset',
						maxWidth: '70px',
						minWidth: '70px',
						marginRight: '5px'
					}}
					top={20}
					onClick={onEditClick}
					className="add-button"
					disabled={editMode && !_userMaterialNameMapping.material}
					loading={isEditLoading}
				>
					{editMode ? getString('SAVE') : getString('EDIT')}
				</ButtonWithLoader>
				{allowRemove && (
					<ButtonWithLoader
						size={20}
						style={{
							padding: '5px 20px',
							width: 'unset',
							maxWidth: '90px',
							minWidth: '70px'
						}}
						top={20}
						className="add-button"
						onClick={onRemoveClick}
						loading={isRemoveLoading}
					>
						{getString('REMOVE')}
					</ButtonWithLoader>
				)}
			</Flexbox>
		</div>
	)
}

export default MaterialNamesMappingRow
