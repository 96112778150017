export const starIcon = {
  width: '2.5em',
  height: 'auto',
  marginRight: '0.5em'
}

export const starDiv = {
  display: 'flex',
  alignItems: 'center'
}
