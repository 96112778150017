export const styles = {
	submitButtonStyle: {
		margin: 15,
		width: 400
	},
	errorTextStyle: {
		fontSize: 15,
		alignSelf: 'center',
		color: 'red'
	},
	logoImage: {
		margin: 10,
		width: 150,
		alighSelf: 'center'
	},
	infoBut: { width: 40, marginLeft: 3, marginRight: -80, marginTop: 6 },
	infoButHidden: {
		width: 40,
		marginLeft: 3,
		marginRight: -80,
		visibility: 'hidden'
	}
}
