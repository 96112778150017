import { FC, memo } from 'react'

import map from 'lodash/map'

import { PART_ANALYSIS_CONFIGURE_FILTER_FEATURES } from 'Services/Strings'
import { generateKey } from 'Services/Utils/uniqKeyGenerator'

interface AdvancedExplanationProps {
	explanationArray?: Array<string>
	showTitle?: boolean
	title?: string
	extraExplanation?: string
}

export const AdvancedExplanation: FC<AdvancedExplanationProps> = ({
	explanationArray,
	showTitle,
	title,
	extraExplanation
}) => {
	let paragraph =
		explanationArray &&
		explanationArray.length > 0 &&
		map(explanationArray, (text: string, index: number) => (
			<div
				key={generateKey(index)}
				className="solution-feature-modal-explanation-text"
			>
				<p>{text}</p>
			</div>
		))

	return (
		<>
			{showTitle && (
				<div className="solution-feature-modal-title">
					{title || PART_ANALYSIS_CONFIGURE_FILTER_FEATURES}
				</div>
			)}
			{paragraph}
			{extraExplanation && (
				<p className="solution-feature-modal-extra-explanation-text">
					{extraExplanation}
				</p>
			)}
		</>
	)
}

export default memo(AdvancedExplanation)
