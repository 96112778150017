import React, { FC } from 'react'

import cx from 'classnames'

import IconFactory from '../StarIcon/IconFactory'
import { Button } from '../thirdParty/CreativeTim/components'
import { THREE_D_BUTTON_TEXT } from 'Services/Strings'

import './ThreeDButton.scss'

type IProps = {
	configurationId?: number
	buttonText?: string
	show3dModalIframe: () => void
	buttonClassName?: string
	iconClassName?: string
}

const ThreeDButton: FC<IProps> = ({
	configurationId,
	buttonText,
	show3dModalIframe,
	buttonClassName,
	iconClassName
}) => {
	return (
		<Button
			id={`part-analysis-3d-button_${configurationId}`}
			data-qa="data-qa-part-analysis-3d-button"
			color="primary"
			onClick={(e: Event) => {
				e.stopPropagation()
				show3dModalIframe()
			}}
			className={cx('three-d--button', buttonClassName)}
			muiClasses={{
				label: '',
				root: ''
			}}
		>
			<IconFactory
				iconName="threeD"
				className={cx('three-d--icon', iconClassName)}
			/>
			{buttonText || THREE_D_BUTTON_TEXT}
		</Button>
	)
}

export default ThreeDButton
