import React, { FC } from 'react'

import { getString } from 'Services/Strings/StringService'

const AdvancedDescriptionBlock: FC = () => {
	const descriptionTexts =
		getString('ONBOARDING_DESCRIPTION_BLOCK_DESCRIPTIONS_ARRAY') || []

	return (
		<div className="advanced--description-block--wrapper">
			<div className="advanced--description-block--header">
				{getString('ONBOARDING_DESCRIPTION_BLOCK_HEADER')}
			</div>
			<div className="advanced--description-block--description">
				{descriptionTexts?.map((text: string) => (
					<p key={text}>{text}</p>
				))}
			</div>
		</div>
	)
}

export default AdvancedDescriptionBlock
