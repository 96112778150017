export interface IFunctionString {
	id: number
	category: string
	type?: string
	technologies?: string[]
	functionString: string
	editable: boolean
}

export interface OrganizationFunctionString
	extends Omit<IFunctionString, 'editable'> {
	organizationId: number
}

export enum CustomFunctionStringCategory {
	productionCost = 'COST_PER_PART'
}
