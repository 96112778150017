import { some } from 'lodash'

import {
	ADMIN_HOME_DRAW_TOGGLE,
	HANDLE_LOADER,
	SETUP_ADMIN_HOME,
	USER_HOME_CRUMB_RESET
} from '../../../global actions/types'
import AdminRoutes from './routes/app.jsx'
import { Empty } from 'Scenes/Components/Empty'
import {
	TWO_FACTOR_AUTH,
	UPLOAD_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { isAdminAuthenticated } from 'Services/Network'

export const drawToggled = () => {
	return { type: ADMIN_HOME_DRAW_TOGGLE }
}

export const setupAdminRoutes = (userRoles, userFeatures) => {
	const routesNotAllowed = !userRoles || !userRoles.length
	const routes = getUserPermittedRoutes(AdminRoutes, userRoles, userFeatures)
	return { type: SETUP_ADMIN_HOME, payload: { routes, routesNotAllowed } }
}
const getUserPermittedRoutes = (adminRoutes, roles, features) => {
	let allow = false

	return adminRoutes.map(route => {
		let excludeComponent = false

		if (!roles.length) {
			return []
		}

		if (route.permissions && !!roles.length) {
			allow = route.permissions.filter(permission => {
				return roles && roles.some(role => role === permission)
			})
			const sideBarIsOff = !allow || !allow.length

			if (route.childRoute) {
				excludeComponent = sideBarIsOff
			} else {
				route.sidebar = !sideBarIsOff
			}
		}

		if (route.features) {
			features.forEach(feature => {
				const isSomeFtOff = some(route.features, routeFT => {
					if (routeFT === feature.componentId) {
						const isOn = feature.on
						excludeComponent = !isOn
						return !isOn
					}
					return false
				})

				if (isSomeFtOff) {
					route.sidebar = false
				}
			})
		}

		route.component = excludeComponent ? Empty : route.component
		return route
	})
}

export const resetCrumbs = () => {
	return { type: USER_HOME_CRUMB_RESET }
}

export const checkAdminAuthentication = history => {
	return async dispatch => {
		try {
			dispatch({ type: HANDLE_LOADER, payload: 1 })
			const res = await isAdminAuthenticated()
			const adminAuthenticated = res?.data.verify
			dispatch({ type: HANDLE_LOADER, payload: -1 })
			if (adminAuthenticated) {
				return
			} else {
				history.push(TWO_FACTOR_AUTH)
			}
		} catch (err) {
			console.log(err)
			dispatch({ type: HANDLE_LOADER, payload: -1 })
			history.push(USER_HOME_ROUTE + UPLOAD_ROUTE)
		}
	}
}
