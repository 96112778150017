import React, { FC, memo } from 'react'
import Chart from 'react-apexcharts'

import { isEmpty } from 'lodash'

import { getCostInformationPdfData } from '../SolutionPdfService'
import { LineBreak } from 'Scenes/Components/PdfComponents/LineBreak'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import {
	usePDFReduxState,
	useUpdateChart
} from 'Services/CustomHooks/usePdfHooks'
import { getString } from 'Services/Strings/StringService'

const ChartGraph: any = Chart

interface IProps {
	configuration: any
	configurationId: number
	show: boolean
}

export const CostInformationPdf: FC<IProps> = ({
	configurationId,
	show,
	configuration
}) => {
	const { chartData, isCostEffective, effectiveQuantity } =
		usePDFReduxState(configurationId)

	const chart = useUpdateChart(chartData)

	if (!show) return <div />

	const { methodText, costResult, costUpTo } = getCostInformationPdfData(
		configuration,
		isCostEffective,
		effectiveQuantity
	)

	return (
		<div className="inline">
			<div className="export-pdf__lead-time with-standard-cost inline">
				<h2>{getString('FINANCIAL_ANALYSIS_WITH').format(methodText)}</h2>
				<br />
				<div className="financial-form-chart">
					<div>
						{!isEmpty(chart) && (
							<ChartGraph
								type="line"
								options={chart && chart?.componentChartData}
								series={chart && chart?.series}
								height="200px"
								width="1200px" //a4 width
							/>
						)}
						<div className="cost-comparison-tab--information--cost-explenation">
							<IconFactory
								iconName={isCostEffective || effectiveQuantity > 0 ? 'dollar' : 'notCostEffective'}
								className="cost-comparison-tab--cost-explenation--icon"
							/>
							{costResult}
							{(effectiveQuantity > 0 && ` ${costUpTo}`) || ''}
						</div>
					</div>
				</div>
				<LineBreak />
			</div>
		</div>
	)
}

export default memo(CostInformationPdf)