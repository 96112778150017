import React, { memo, FC } from 'react'

import CostSummaryTable from './CostSummaryTable'
import CostSummaryUnprintable from './CostSummaryUnprintable'

import './CostSummaryTab.scss'
import { Feature, FeatureComponentId } from '../../../../../../../../../Services/models/Features'

interface IProps {
  manufacturingMethodName: string
  resultTitle: string
  solution: any
  configuration: any
  configurationResultBody: string
  showAnalysis: boolean
  onConfigureClick: Function
  onRetrieveResultClick: Function
}

const CostSummaryTab: FC<IProps> = ({
  manufacturingMethodName,
  resultTitle,
  solution,
  configuration,
  configurationResultBody,
  showAnalysis,
  onConfigureClick,
  onRetrieveResultClick
}) => {
  const retrieveResultMode = Feature.isFeatureOn(FeatureComponentId.RETRIEVE_RESULTE_UNPRINTABLE);
  if (!solution || (!showAnalysis && retrieveResultMode)) {
    return (
      <CostSummaryUnprintable
        result={configuration.result}
        resultTitle={resultTitle}
        configurationResultBody={configurationResultBody}
        solution={solution}
        showAnalysis={showAnalysis}
        onConfigureClick={onConfigureClick}
        onRetrieveResultClick={onRetrieveResultClick}
      />
    )
  }
  return (
    <CostSummaryTable
      solution={solution}
      configurationResultBody={configurationResultBody}
      manufacturingMethodName={manufacturingMethodName}
      configuration={configuration}
    />
  )
}

export default memo(CostSummaryTab)
