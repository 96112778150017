import { store } from 'index'
import _ from 'lodash'
import { OFF_THE_SHELF_SELECTOR_ITEMS } from '../../../Services/Strings'

export const OFF_THE_SHELF = 0
export const OFF_THE_MANUFACTURE = 1

export const getNewInputs = (inputKey, editInputs, edit) => {
	switch (inputKey) {
		case 'all':
			let newEditInputs = { ...editInputs }
			for (const key in newEditInputs) {
				newEditInputs = { ...newEditInputs, [key]: { edit } }
			}
			return newEditInputs

		default:
			const newInputs = { ...editInputs, [inputKey]: { edit } }
			return newInputs
	}
}

export const makeTagItem = (item, label, itemType) => {
	return {
		text: item,
		label: label,
		itemType: OFF_THE_SHELF_SELECTOR_ITEMS[itemType]
	}
}

export const getPartFilteringArray = data => {
	if (data && Object.keys(data.userSettings).length > 0) {
		const offTheShelfSubstrings = data.userSettings.offTheShelfSubstrings.map(
			item => makeTagItem(item, 'info', OFF_THE_SHELF)
		)
		const manufacturedSubstrings = data.userSettings.manufacturedSubstrings.map(
			item => makeTagItem(item, 'danger', OFF_THE_MANUFACTURE)
		)
		return [...offTheShelfSubstrings, ...manufacturedSubstrings]
	}
	return []
}

export const getGlobalFilteringArray = data => {
	if (data && data.length > 0) {
		const globalOffShelfSubstrings = data
			.filter(string => !string?.endsWith('}$/'))
			.map(string => string?.slice(1, -1))
			.sort()
			.map(item => makeTagItem(item, 'info', OFF_THE_SHELF))
		return [...globalOffShelfSubstrings]
	}
	return []
}

export const checkSemiProfessionalPrintersToggle = printersFullData => {
	let isTrue = false
	if (printersFullData?.length > 0) {
		for (const printerFullData of printersFullData) {
			if (printerFullData.printers?.length > 0) {
				isTrue = printerFullData.printers.some(printer => {
					return printer.isDesktop
				})
				if (isTrue) {
					break
				}
			}
		}
	}
	return isTrue
}

export const getMaterialCosts = materialPriceData => {
	const materialCosts = []
	if (materialPriceData?.priceList) {
		materialPriceData.priceList.forEach(item => {
			materialCosts.push({
				materialPrice: item.price,
				selectedPrinterName: item.printerMaterial.printers.name,
				selectedCompany: item.printerMaterial.printers.company,
				selectedMaterialName: item.printerMaterial.name,
				selectedWeightUnit: 'KG',
				materialIds: [item.printerMaterial.id]
			})
		})
	}
	return materialCosts
}

export const validateUserSettingsResponseData = data => {
	const settingsData = { ...data }
	if (!settingsData.userSettings) {
		settingsData.userSettings = {}
	}
	if (!settingsData.userSettings.offTheShelfSubstrings) {
		settingsData.userSettings.offTheShelfSubstrings = []
	}
	if (!settingsData.userSettings.manufacturedSubstrings) {
		settingsData.userSettings.manufacturedSubstrings = []
	}
	return settingsData
}

export const setupValidationStateKeys = (
	settingsData,
	costAndLeadNames,
	validationStateKeys,
	drawingCost2dNames
) => {
	for (const key in settingsData?.userSettings || {}) {
		if (costAndLeadNames.hasOwnProperty(key)) {
			const validationType = costAndLeadNames[key].validation.type
			validationStateKeys[`${validationType}State_${key}`] = ''
		}
	}
}
