import { FC, memo, useState } from 'react'
import Dropzone from 'react-dropzone'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import Divider from '@material-ui/core/Divider'
import UploadIcon from '@material-ui/icons/CloudUpload'
import { isEmpty } from 'lodash'

import ButtonWithLoader from '../../Components/ButtonWithLoader'
import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import HeaderCard from '../../Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import { UserRole } from '../UserRole.enum'
import { onUploadFile, setFile } from './UploadScriptActions'
import Flexbox from 'Scenes/Components/FlexBox'
import HideForRoleHOC from 'Services/HOC/HideForRoleHOC'
import {
	DROP_DATABASE_FILE,
	RUN_DATABASE_UPDATE,
	UPDATE_DATABASE_INFO,
	USER_MENU_UPLOAD_SCRIPT
} from 'Services/Strings'
import { getTheme } from 'themes/getTheme'

import './index.scss'

const { cardBoxColor } = getTheme()

const UploadIconTSX: any = UploadIcon
const DividerTSX: any = Divider

interface IProps {}

const UploadScript: FC<IProps> = () => {
	const [draggingFiles, setDragging] = useState<boolean>(false)
	const dispatch = useDispatch()
	const { loading, file, error, preparedToSend } = useSelector(
		(state: RootStateOrAny) => state.UploadScriptReducer
	)

	const handleFileReader = (files: any) => {
		let reader = new FileReader()
		if (files[0]) {
			reader.readAsDataURL(files[0])
			reader.onload = () => {
				dispatch(setFile(files, reader?.result))
			}
		}
	}

	//TODO: check if user organization_owner

	return (
		<div className="upload-script">
			<NavBarAndMaterial title={USER_MENU_UPLOAD_SCRIPT}>
				<div className="upload-script-body">
					<HeaderCard
						cardTitle={USER_MENU_UPLOAD_SCRIPT}
						cardSubtitle=""
						headerColor={cardBoxColor || 'green'}
						content={
							<div>
								<br />
								<p>{UPDATE_DATABASE_INFO}</p>
								<br />
								<DividerTSX />
								<br />
								<Dropzone
									accept=".zip"
									id="upload-script"
									onDrop={(newFile: any) => {
										handleFileReader(newFile)
										setDragging(false)
									}}
									multiple={false}
									className={
										draggingFiles
											? 'upload-script-dropzoneDrag'
											: 'upload-script-dropzone'
									}
									onDragEnter={() => setDragging(true)}
									onDragLeave={() => setDragging(false)}
								>
									<Flexbox
										flexDirection="column"
										className="upload-script-dropzone__flex"
									>
										<UploadIconTSX className="upload-script-dropzone__icon" />
										<h3 style={{ margin: 0 }}>
											{isEmpty(file) ? DROP_DATABASE_FILE : `${file?.name}`}
										</h3>
										<br />
									</Flexbox>
								</Dropzone>
								<div className="upload-script-error">{error}</div>
								<ButtonWithLoader
									id="upload-project-upload-button"
									className="upload-script-button"
									loading={loading}
									primary={true}
									disabled={isEmpty(preparedToSend) || isEmpty(file)}
									onClick={() => dispatch(onUploadFile(preparedToSend))}
								>
									{RUN_DATABASE_UPDATE}
								</ButtonWithLoader>
							</div>
						}
					/>
				</div>
			</NavBarAndMaterial>
		</div>
	)
}

export default HideForRoleHOC(memo(UploadScript), [UserRole.LIGHT])
