import React, { ChangeEvent, FC, memo, useState } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import * as CostAndLeadActions from '../CostAndLeadActions'
import { PRINTING_COST_PARAMETERS } from 'Services/Constants'
import { Printer } from 'Services/models/IPrinter'

import './CustomizePrinterProps.scss'

const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem

interface IProps {
	onPrinterCostParameterChange: Function
	selectedPrinter: Printer
	selectedPrinterCompany: string
	selectedPrinterCostParameter: string
}

interface ICostAndLeadReducerState {
	selectedPrinter: any
	selectedPrinterCompany: string
	selectedPrinterCostParameter: string
}

interface IReduxStore {
	CostAndLeadReducer: ICostAndLeadReducerState
}

const CustomizePrinterCostOptions: FC<IProps> = ({
	selectedPrinterCompany,
	selectedPrinterCostParameter,
	onPrinterCostParameterChange
}) => {
	return (
		<div style={{ display: 'flex' }}>
			<SelectTSX
				displayEmpty
				title={selectedPrinterCompany}
				className="customize-printer-props--content--select-printer-cost"
				value={selectedPrinterCostParameter}
				renderValue={() => selectedPrinterCostParameter}
				onChange={(e: ChangeEvent<HTMLSelectElement>) => {
					onPrinterCostParameterChange(e.target.value)
				}}
			>
				{PRINTING_COST_PARAMETERS.map(item => {
					return (
						<MenuItemTSX key={item} value={item}>
							{item}
						</MenuItemTSX>
					)
				})}
			</SelectTSX>
		</div>
	)
}

const mapStateToProps = ({ CostAndLeadReducer }: IReduxStore) => {
	return {
		...CostAndLeadReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CostAndLeadActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizePrinterCostOptions)
)
