import React, { FC, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { STEP_ID, TM_FORM_IDS } from '../../constants'
import { useStep } from '../../OnboardingWizardHooks'
import OnboardingStepFrame from '../OnboardingStepFrame'
import TMMaterialForm from './TMMaterialForm'
import { setupNewUserMaterialForm } from 'Scenes/Home/Customize/CustomizeUserMaterials/CustomizeUserMaterialsActions'

const TMFormComponents = {
	[TM_FORM_IDS.TM_FORM]: <TMMaterialForm />
}

const TMForm: FC = () => {
	const { steps } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { step, stepContent, childSteps } = useStep(steps, STEP_ID.TM_FORM)
	const { materialAdded } = useSelector(
		(state: RootStateOrAny) => state.CustomizeUserMaterialsReducer
	)
	const dispatch = useDispatch()

	useEffect(() => {
		if (materialAdded) {
			dispatch(setupNewUserMaterialForm())
		}
	}, [dispatch, materialAdded])

	return (
		<OnboardingStepFrame
			step={step}
			bodyClassName="cost-function-editor-frame"
			childSteps={childSteps}
		>
			<div>
				{stepContent?.map(component => (
					<React.Fragment key={component.id}>
						{TMFormComponents[component.id as keyof typeof TMFormComponents]}
					</React.Fragment>
				))}
			</div>
		</OnboardingStepFrame>
	)
}

export default TMForm
