import React, { FC } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { ADD_PRINTER_FORM_IDS, STEP_ID } from '../../constants'
import { useStep } from '../../OnboardingWizardHooks'
import OnboardingStepFrame from '../OnboardingStepFrame'
import NewPrinterForm from './NewPrinterForm'

const AddPrinterFormComponents = {
	[ADD_PRINTER_FORM_IDS.PRINTER_FORM]: <NewPrinterForm />
}

const AddPrinterForm: FC = () => {
	const { steps } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { step, stepContent, childSteps } = useStep(
		steps,
		STEP_ID.ADD_PRINTER_FORM
	)

	return (
		<OnboardingStepFrame
			step={step}
			bodyClassName="cost-function-editor-frame"
			childSteps={childSteps}
		>
			<div>
				{stepContent?.map(component => (
					<React.Fragment key={component.id}>
						{
							AddPrinterFormComponents[
								component.id as keyof typeof AddPrinterFormComponents
							]
						}
					</React.Fragment>
				))}
			</div>
		</OnboardingStepFrame>
	)
}

export default AddPrinterForm
