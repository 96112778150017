import Flexbox from 'Scenes/Components/FlexBox'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { isEmpty } from 'lodash'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'

import { MIN_NUMBER_FIELD_VALUE } from '../../../../Services/Constants'
import { sendToPrint } from '../../../../Services/Network'
import { ADDRESS, OK } from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import { AlertType } from '../../../Components/alerts/AlertTypes'
import CastorAlert from '../../../Components/alerts/CastorAlert'
import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import CastorLocationSearchInput from '../../../Components/CastorLocationSearchInput/CastorLocationSearchInput'
import NumberField from '../../../Components/NumberField'
import HeaderCard from '../../../Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import Danger from '../../../Components/thirdParty/CreativeTim/components/Typography/Danger.jsx'
import { ERROR, SUCCESS } from 'Services/Strings'

import './RequestPrintOfferForm.scss'

const FAILED_RESULT = 'failed'

class RequestPrintOfferForm extends Component {
	//Lifecycle functions

	constructor() {
		super()
	}
	componentWillMount() {
		const {
			match,
			formatted_address,
			city,
			state,
			country,
			address,
			zip_code
		} = this.props
		const quantity = match.params.quantity ? match.params.quantity : ''
		const emptyAddress =
			isEmpty(address) && getString('SHIPPING_ADDRESS_IS_MISSING')

		this.setState({
			loading: false,
			error: emptyAddress || null,
			disableButton: !!emptyAddress,
			notes: '',
			file: null,
			addressLine1: formatted_address,
			addressLine2: '',
			city: city,
			state: state,
			country: country,
			zip: zip_code,
			quantity,
			completeSuccessfully: false
		})
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		const { validAddress, address } = this.props
		const { disableButton } = this.state
		if (prevProps.address !== address) {
			if ((isEmpty(address) || validAddress === ERROR) && !disableButton) {
				this.setErrorMessage(getString('SHIPPING_ADDRESS_IS_MISSING'))
			}
			if (!isEmpty(address) && validAddress === SUCCESS && disableButton) {
				this.setErrorMessage(null)
			}
		}
	}

	hasExtension(fileName, exts) {
		return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(
			fileName
		)
	}

	//Actions
	leaveFormAfterSuccess = () => {
		this.props.history.goBack()
	}

	//Render functions
	renderSuccessAlert() {
		return (
			<CastorAlert
				showCancel={false}
				onCancel={this.leaveFormAfterSuccess.bind(this)}
				show={this.state.completeSuccessfully}
				headerTitle={getString('SEND_TO_SERVICE_BUREAU_SUCCESS_POPUP_TITLE')}
				onConfirm={this.leaveFormAfterSuccess.bind(this)}
				confirmOptionalText={OK}
				alertType={AlertType.SUCCESS}
			>
				{getString('SEND_TO_SERVICE_BUREAU_SUCCESS_POPUP_MESSAGE')}
			</CastorAlert>
		)
	}

	offTheShelfCheckboxClicked() {
		this.setState({
			isOffTheShelf: !this.state.isOffTheShelf
		})
	}

	submitForm() {
		const { address, city, state, country, zip_code, long, lat } = this.props
		const { quantity, notes, file } = this.state
		sendToPrint(
			this.props.match.params.projectId,
			this.props.match.params.partId,
			this.props.match.params.configurationId,
			address,
			city,
			country,
			state,
			zip_code,
			lat,
			long,
			quantity,
			notes,
			file
		).then(
			() => {
				this.setState({ completeSuccessfully: true, loading: false })
			},
			error => {
				this.setState({ error: error.message, loading: false })
			}
		)
		this.setState({ loading: true, error: null })
	}

	fileSelected(event) {
		const file = event?.target?.files[0]
		const allowExtension = file && this.hasExtension(file?.name, ['.pdf'])
		let errorMessage = null
		if (file && !allowExtension) {
			errorMessage = getString('ERROR_FILE_FORMAT_SUPPORT_PDF')
		}

		this.setState({ file: event.target.files[0] })
		this.setErrorMessage(errorMessage)
	}

	setErrorMessage(errorMessage) {
		this.setState({ error: errorMessage, disableButton: !!errorMessage })
	}

	renderForm() {
		return (
			<Flexbox flexDirection="column" style={styles.formSuperview}>
				<p>{getString('SEND_TO_SERVICE_BUREAU_EXPLANATION')}</p>
				{this.renderPrintMethod()}
				<TextField
					label={getString('SEND_TO_SERVICE_BUREAU_NOTES')}
					value={this.state.notes}
					onChange={event => this.setState({ notes: event.target.value })}
					style={styles.textField}
					multiline={true}
					helperText={getString('SEND_TO_SERVICE_BUREAU_NOTES_EXTRA')}
				/>
				<br />
				<NumberField
					label={getString('SEND_TO_SERVICE_BUREAU_QUANTITY_LABEL')}
					style={styles.textField}
					allowEmpty={false}
					value={this.state.quantity}
					onChangeValue={value => this.setState({ quantity: value })}
					minValue={MIN_NUMBER_FIELD_VALUE}
				/>
				<br />
				<div style={styles.uploadSuperview}>
					<p>{getString('SEND_TO_SERVICE_BUREAU_UPLOAD_DRAWING')}</p>
					<p>{getString('SEND_TO_SERVICE_BUREAU_UPLOAD_DRAWING_EXPLAINED')}</p>
					<input
						type="file"
						accept="application/pdf"
						onChange={this.fileSelected.bind(this)}
						style={styles.bomUploadInput}
					/>
				</div>
				<br />
				<Divider />
				<br />
				<h4>{getString('SEND_TO_SERVICE_BUREAU_ADDRESS_HEADER')}</h4>
				<CastorLocationSearchInput labelText={ADDRESS} />
				{this.renderErrorFieldIfNeeded()}
				<br />
				<ButtonWithLoader
					disabled={this.state.disableButton}
					style={styles.submitButtonStyle}
					loading={this.state.loading}
					primary={true}
					onClick={this.submitForm.bind(this)}
				>
					{getString('SEND_TO_SERVICE_BUREAU_SUBMIT')}
				</ButtonWithLoader>

				<Danger>{this.state.error}</Danger>
			</Flexbox>
		)
	}

	render() {
		return (
			<div className="get-a-quote-form">
				{this.renderSuccessAlert()}
				<br />
				<br />
				<Flexbox style={styles.cardSuperview}>
					<HeaderCard
						hover
						underChart={<p>testing</p>}
						cardTitle={getString('SEND_TO_SERVICE_BUREAU_TITLE')}
						cardSubtitle={getString('SEND_TO_SERVICE_BUREAU_SUBTITLE')}
						content={this.renderForm()}
						style={styles.card}
					/>
				</Flexbox>
			</div>
		)
	}

	renderErrorFieldIfNeeded() {
		if (this.state.result !== FAILED_RESULT) {
			return <div />
		}
		return (
			<TextField
				label="error message (will be shown in popup when trying to open part)"
				value={this.state.errorMessage}
				onChange={event => this.setState({ errorMessage: event.target.value })}
				style={styles.textField}
			/>
		)
	}

	renderPrintMethod() {
		return (
			<div style={styles.techForPrintSuperview}>
				<h5>{getString('SEND_TO_SERVICE_BUREAU_HOW_TITLE')}</h5>
				<p>{`${getString('SEND_TO_SERVICE_BUREAU_PRINTER')} ${
					this.props.match.params.printerName
				}`}</p>
				<p>{`${getString('SEND_TO_SERVICE_BUREAU_MATERIAL')} ${
					this.props.match.params.materialName
				}`}</p>
			</div>
		)
	}
}
const uploadSuperview = {
	zIndex: 1,
	position: 'relative',
	border: '1px solid lightGray',
	borderRadius: 6,
	padding: 10
}
const styles = {
	textField: {},
	smallTextField: { width: 150, marginLeft: 6, marginRight: 6 },
	chart: { height: '300px', marginRight: 20, marginTop: 20, flexGrow: 5 },
	horizontalFlex: { flex: 1, alignItems: 'center' },
	xAxisLabel: { textAlign: 'center' },
	divider: { margin: 10 },
	thumbnail: { width: 150, maxHeight: 150 },
	card: { marginLeft: 20, marginRight: 20 },
	iconButton: { height: 40, width: 75, alignSelf: 'center' },
	errorTextStyle: {
		fontSize: 15,
		alignSelf: 'center',
		color: 'red'
	},
	submitButtonStyle: {
		margin: 0
	},
	actionSelect: { flex: 3, width: 300 },
	actionSelectFlex: { alignItems: 'center' },
	runActionButton: { marginTop: 15 },
	formSuperview: {
		maxWidth: 1000,
		justifyContent: 'center',
		marginLeft: 30,
		marginRight: 30
	},
	cardSuperview: {
		maxWidth: 720,
		alignSelf: 'center',
		textAlign: 'left',
		marginLeft: '25%',
		marginRight: '25%'
	},
	bomUploadInput: { alignSelf: 'left', textAlign: 'left', margin: 0 },
	uploadSuperview,
	techForPrintSuperview: uploadSuperview
}

const mapStateToProps = ({ user, CastorLocationSearchReducer }) => {
	const { userDetails } = user
	return {
		...userDetails,
		...CastorLocationSearchReducer
	}
}

export default connect(mapStateToProps)(RequestPrintOfferForm)
