import React, { ChangeEvent, FC, memo } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import * as CostAndLeadActions from '../CostAndLeadActions'
import { getString } from '../../../../../Services/Strings/StringService'
import { ISimpleConfigurationPrinter } from '../../../../Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { Printer } from 'Services/models/IPrinter'

import './CustomizePrinterProps.scss'

const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem

interface IProps {
	onPrinterCompanyChange: Function
	selectedPrinter: Printer
	printerNames: ISimpleConfigurationPrinter[]
	selectedPrinterCompany: string
	printerCompanies: any[]
	onPrinterNameChange: Function
	selectedPrinterName: string
}

interface ICostAndLeadReducerState {
	selectedPrinter: any
	printerNames: ISimpleConfigurationPrinter[]
	selectedPrinterCompany: string
	printerCompanies: any[]
	selectedPrinterName: string
}

interface IReduxStore {
	CostAndLeadReducer: ICostAndLeadReducerState
}

const CustomizePrinterSelector: FC<IProps> = ({
	printerNames,
	onPrinterCompanyChange,
	onPrinterNameChange,
	selectedPrinterCompany,
	printerCompanies,
	selectedPrinterName
}) => {
	return (
		<div style={{ display: 'flex' }}>
			<SelectTSX
				displayEmpty
				title={selectedPrinterCompany}
				className="customize-printer-props--content--select--long"
				value={selectedPrinterCompany}
				renderValue={() =>
					selectedPrinterCompany || (
						<div className="custom-placeholder">
							{getString('PRINTER_COMPANY')}
						</div>
					)
				}
				onChange={(e: ChangeEvent<HTMLSelectElement>) => {
					onPrinterCompanyChange(e.target.value)
				}}
			>
				<MenuItemTSX value="" disabled>
					{getString('PRINTER_COMPANY')}
				</MenuItemTSX>
				{printerCompanies.map(item => {
					return (
						<MenuItemTSX key={item} value={item}>
							{item}
						</MenuItemTSX>
					)
				})}
			</SelectTSX>

			<SelectTSX
				displayEmpty
				title={selectedPrinterName}
				className="customize-printer-props--content--select"
				disabled={!printerNames?.length}
				value={selectedPrinterName || 'printer name'}
				renderValue={() =>
					selectedPrinterName || (
						<div className="custom-placeholder">
							{getString('PRINTER_NAME')}
						</div>
					)
				}
				onChange={(e: ChangeEvent<HTMLSelectElement>) => {
					onPrinterNameChange(e.target.value)
				}}
			>
				<MenuItemTSX value="" disabled>
					{getString('PRINTER_NAME')}
				</MenuItemTSX>
				{printerNames.map(printerName => {
					return (
						<MenuItemTSX key={printerName.printerId} value={printerName}>
							{printerName.name}
						</MenuItemTSX>
					)
				})}
			</SelectTSX>
		</div>
	)
}

const mapStateToProps = ({ CostAndLeadReducer }: IReduxStore) => {
	return {
		...CostAndLeadReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CostAndLeadActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizePrinterSelector)
)
