import { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { Divider } from '@material-ui/core'

import { getLicenseInfo } from './AdminLicenseScreenActions'
import LicenseInfo from './AdminLicenseScreenInfo'
import { WarningMessageType } from './AdminLicenseScreenService'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import HeaderCard from 'Scenes/Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import LicenseDropzone from 'Scenes/Home/LicenseManager/LicenseDropZone'
import Loader from 'Scenes/Loader/Loader'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const AdminLicenseScreen: FC = () => {
	const { userCustomizationSettings = {} } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	const { licenseInfo, licenseData, loading, dataLoading } = useSelector(
		(state: RootStateOrAny) => state.AdminLicenseScreenReducer
	)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getLicenseInfo(userCustomizationSettings.organizationId))
	}, [])

	if (loading) return <Loader />

	return (
		<div className="admin-license-screen upload-script">
			<NavBarAndMaterial title={getString('ADMIN_PANEL_LICENSE_SCREEN_TITLE')}>
				<div className="upload-script-body">
					<HeaderCard
						cardTitle={getString('ADMIN_PANEL_LICENSE_SCREEN_TITLE')}
						cardSubtitle=""
						headerColor={'green'}
						style={{ maxWidth: 'unset' }}
						content={
							<div>
								<p className="title">
									{getString('ADMIN_PANEL_LICENSE_SCREEN_INFORMATION')}
								</p>

								<div>
									<LicenseInfo
										info={getString('ADMIN_PANEL_LICENSE_SCREEN_CHARGED')}
										leftValue={licenseInfo?.amountOfChargedParts}
										allValue={licenseData?.total_parts}
										type={WarningMessageType.part}
									/>
									<LicenseInfo
										info={getString('ADMIN_PANEL_LICENSE_SCREEN_LIGHT')}
										leftValue={licenseInfo?.amountLightInLicense}
										allValue={licenseData?.allowed_light_users}
										unlimited={licenseData?.is_unlimited_light_users}
										type={WarningMessageType.user}
									/>
									<LicenseInfo
										info={getString('ADMIN_PANEL_LICENSE_SCREEN_USERS')}
										leftValue={licenseInfo?.amountUsersInLicense}
										allValue={licenseData?.allowed_users}
										type={WarningMessageType.user}
									/>
									<LicenseInfo
										info={getString('ADMIN_PANEL_LICENSE_SCREEN_ADMINS')}
										leftValue={licenseInfo?.amountAdminsInLicense}
										allValue={licenseData?.allowed_administrator}
										type={WarningMessageType.user}
									/>
								</div>

								<Divider />
								<br />
								<LicenseDropzone />
							</div>
						}
					/>
				</div>
			</NavBarAndMaterial>
		</div>
	)
}

export default memo(AdminLicenseScreen)
