import { FC, memo } from 'react'

import Subheader from '@material-ui/core/ListSubheader'

import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'


const SubheaderTSX: any = Subheader

interface PartsSubHeaderProps {
	parts: Part[]
	weightReducedParts: Part[]
}

const PartsSubHeader: FC<PartsSubHeaderProps> = ({
	parts,
	weightReducedParts
}) => {
	if (!parts.length || !weightReducedParts.length) {
		return <></>
	}
	return (
		<SubheaderTSX component="div">
			<h4 className="parts-header--title">
				{getString('MORE_RESULTS')} ({parts.length})
			</h4>
		</SubheaderTSX>
	)
}

export default memo(PartsSubHeader)