import React, { FC, useState, useEffect } from 'react'
import { ReactComponent as WeightReduction } from '../../../assets/img/svg/weight.svg'
import { ReactComponent as WeightReductionSmall } from '../../../assets/img/svg/kg icon.svg'

import './icons.scss'

interface IProps {
  small?: boolean
  className?: string
  smallClassName?: string
  expandIconOnHover?: boolean
}

export const IconWeightReduction: FC<IProps> = ({
  small,
  className = '',
  smallClassName = '',
  expandIconOnHover
}) => {
  const [isBigIcon, setIsBigIcon] = useState(false)
  useEffect(() => {
    setIsBigIcon(!small)
  }, [small])

  if (!isBigIcon) {
    return (
      <WeightReductionSmall
        className={smallClassName}
        onMouseOver={() => setIsBigIcon(expandIconOnHover || false)}
      />
    )
  }
  return (
    <WeightReduction
      className={`${className} weight-icon`}
      width="260px"
      onMouseLeave={() => setIsBigIcon(!expandIconOnHover || false)}
    />
  )
}
