export const customizeStyles = {
  textFieldDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}

export const applyAllButtons = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '0.4rem 2.2rem 0 1.4rem'
}

export const actionButtonsDiv = {
  display: 'flex',
  justifyContent: 'space-evenly'
}

export const editedValue = {
  cursor: 'pointer'
}

export const defaultValue = {
  cursor: 'pointer',
  opacity: 0.7
}
