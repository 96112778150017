import { FC, memo, useEffect, useState } from 'react'

const HeartBeat: FC = () => {
	const [currentTime, setCurrentTime] = useState<string>(
		new Date().toLocaleTimeString()
	)

	useEffect(() => {
		const updateTime = () => {
			setCurrentTime(new Date().toLocaleTimeString())
		}

		updateTime()

		const timeOutInterval = setInterval(updateTime, 0.1 * 60 * 1000)

		return () => {
			clearInterval(timeOutInterval)
		}
	}, [])

	return (
		<div style={{ opacity: 0, display: 'none' }}>
			<div>
				<div>{currentTime}</div>
			</div>
		</div>
	)
}

export default memo(HeartBeat)
