import React, { FC, useEffect, useState } from 'react'

import { IOtherProps } from '../../../Services/models/IOtherProps'

import { ReactComponent as ComplexityFit } from '../../../assets/img/svg/complex-full.svg'
import { ReactComponent as ComplexitySmall } from '../../../assets/img/svg/complex.svg'

interface IProps extends IOtherProps {
	small?: boolean
	className?: string
	smallClassName?: string
	expandIconOnHover?: boolean
}

export const IconComplexityScore: FC<IProps> = ({
	small,
	className = '',
	smallClassName = '',
	expandIconOnHover,
	...props
}) => {
	const [isBigIcon, setIsBigIcon] = useState(false)
	useEffect(() => {
		setIsBigIcon(!small)
	}, [small])

	if (!isBigIcon) {
		return (
			<ComplexitySmall
				className={smallClassName}
				data-qa="data-qa-small-benefit-complex"
				onMouseOver={() => setIsBigIcon(expandIconOnHover || false)}
				{...props}
			/>
		)
	}
	return (
		<ComplexityFit
			className={className}
			data-qa="data-qa-configuration-icon-complex"
			onMouseLeave={() => setIsBigIcon(!expandIconOnHover || false)}
		/>
	)
}
