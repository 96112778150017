import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as CostAndLeadActions from './CostAndLeadActions'
import {
	onFullTrayAssumptionChanged,
	onPostProcessToggleUpdate,
	onSupplyChainCostChanged
} from '../../../../global actions'
import { getThemeString } from '../../../../themes/getThemeString'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import Customize2DDrawingCost from '../../Customize/Customize2DDrawingCost/Customize2DDrawingCost'
import CustomizeMetadataCost from '../../Customize/CustomizeMetadataCost'
import { fetchCustomizeData } from '../CustomizeActions'
import CustomizeAnalysisCost from '../CustomizeAnalysisCost'
import CustomizeFullTrayAssumption from '../CustomizeFullTrayAssumption/CustomizeFullTrayAssumption'
import CustomizeMaterialCost from '../CustomizeMaterialCost/CustomizeMaterialCost'
import CustomizeSupplyChain from '../CustomizeSupplyChain/CustomizeSupplyChain'
import CustomizeSupplyChainRadioButton from '../CustomizeSupplyChain/CustomizeSupplyChainRadioButton'
import CustomizeUserPostProcesses from '../CustomizeUserPostProcesses/CustomizeUserPostProcesses'
import CostCalculationParams from './CostCalculationParams'
import CustomizePrinterProps from './CustomizePrinterProps'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

class CustomizeCostAndLeadSettings extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showUploadAlert: false
		}
	}

	componentDidMount = () => {
		const {
			fetchCustomizeData,
			costAndLeadNames,
			drawingCost2dNames,
			settingsData,
			setupPrinterPropsData,
			printersFullData
		} = this.props
		fetchCustomizeData(
			costAndLeadNames,
			settingsData.userSettings,
			drawingCost2dNames
		)
		setupPrinterPropsData(printersFullData)

		if (window.location.hash) {
			this.scrollToElement(window.location.hash)
		}
	}

	componentDidUpdate = () => {
		if (window.location.hash) {
			this.scrollToElement(window.location.hash)
		}
	}

	scrollToElement = id => {
		const element = document.querySelector(id)
		if (element) {
			element.scrollIntoView({
				behavior: 'smooth'
			})
		}
	}

	onFullTrayAssumptionChanged = fullTrayAssumptionOn => {
		this.props.onFullTrayAssumptionChanged(fullTrayAssumptionOn)
	}

	setSettingsContent = () => {
		const customizeSupplyChain = Feature.isFeatureOn(
			FeatureComponentId.CUSTOMIZE_SUPPLY_CHAIN
		)
		const isDrawingCost = Feature.isFeatureOn(
			FeatureComponentId.DRAWING_COST_COST_AND_LEAD
		)
		const customizeSupplyChainRadioButton = Feature.isFeatureOn(
			FeatureComponentId.DEFAULT_SCENARIO
		)

		const { userCustomizationSettings = {} } = this.props
		const { fullTrayAssumption, includeSupplyChainCosts } =
			userCustomizationSettings

		return (
			<div className="customize-cost-and-lead-settings">
				<CostCalculationParams />
				<CustomizePrinterProps />
				<CustomizeMaterialCost />
				{!customizeSupplyChain && (
					<CustomizeFullTrayAssumption
						fullTrayAssumptionOn={!!fullTrayAssumption}
						onFullTrayAssumptionToggleChange={this.onFullTrayAssumptionChanged}
					/>
				)}
				<CustomizeUserPostProcesses />
				{customizeSupplyChain && (
					<CustomizeSupplyChain
						disabled={false}
						supplyChainOn={includeSupplyChainCosts}
						onSupplyChainToggleChange={this.onSupplyChain}
					/>
				)}
				{customizeSupplyChainRadioButton && <CustomizeSupplyChainRadioButton />}
				<CustomizeAnalysisCost />
				{isDrawingCost && <Customize2DDrawingCost />}
				<CustomizeMetadataCost />
			</div>
		)
	}

	renderFormHeader = () => {
		return (
			<CastorFormHeader
				explanationHeader={getThemeString('CUSTOMIZE_EXPLINATION_1_HEADER')}
				explanationArray={getThemeString('CUSTOMIZE_EXPLANATION_ARRAY')}
			/>
		)
	}

	render() {
		if (!this.props.settingsData.userSettings) {
			return <div />
		}
		return (
			<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
				<CastorForm
					formHeader={this.renderFormHeader()}
					formTitle={getString('CUSTOMIZE_FORM_COST_AND_LEAD_HEADER')}
					content={this.setSettingsContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const mapStateToProps = ({
	CostAndLeadReducer,
	user: { printersFullData, userCustomizationSettings }
}) => {
	return {
		...CostAndLeadReducer,
		printersFullData,
		userCustomizationSettings
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			...CostAndLeadActions,
			fetchCustomizeData,
			onPostProcessToggleUpdate,
			onSupplyChainCostChanged,
			onFullTrayAssumptionChanged
		},
		dispatch
	)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomizeCostAndLeadSettings)
