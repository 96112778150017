export const ALERT_POPPED: string = 'ALERT_POPPED'
export const ALERT_POPUP_CANCELED: string = 'ALERT_POPUP_CANCELED'
export const ALERT_CALCULATION_STARTED: string = 'ALERT_CALCULATION_STARTED'
export const ALERT_CALCULATION_FINISHED: string = 'ALERT_CALCULATION_FINISHED'

//subscription types
export const SUBSCRIPTION_CONNECT_ALERT_POPUP: string =
  'SUBSCRIPTION_CONNECT_ALERT_POPUP'
export const SUBSCRIPTION_ALERT_POPUP = 'SUBSCRIPTION_ALERT_POPUP'
export const SUBSCRIPTION_ALERT_POPUP_CANCELED =
  'SUBSCRIPTION_ALERT_POPUP_CANCELED'
export const SUBSCRIPTION_CONNECT_ALERT_POPUP_CANCELED =
  'SUBSCRIPTION_CONNECT_ALERT_POPUP_CANCELED'
