import { Action } from '../../../../global actions/ActionModels'
import { ADMIN_STRINGS_IMPORT_TOGGLED } from '../../../../global actions/types'

export class AdminStringsInitialState {
  readonly importLoading: boolean = false
}

const initialState: AdminStringsInitialState = new AdminStringsInitialState()

export default (state = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case ADMIN_STRINGS_IMPORT_TOGGLED:
      return { ...state, importLoading: !state.importLoading }
    default:
      return state
  }
}
