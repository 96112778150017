import { Action } from '../../../global actions/ActionModels'
import {
	UPLOAD_SCRIPT_ADD_FILE,
	UPLOAD_SCRIPT_ERROR,
	UPLOAD_SCRIPT_FILE_FINISH,
	UPLOAD_SCRIPT_FILE_START
} from '../../../global actions/types'

export interface CastorAlertInitialState {
	readonly loading: boolean
	readonly file: any
	readonly preparedToSend: any
	readonly error: string
}

const initialState: CastorAlertInitialState = {
	loading: false,
	file: {},
	preparedToSend: {},
	error: ''
}

export default (state = initialState, { type, payload }: Action<any>) => {
	switch (type) {
		case UPLOAD_SCRIPT_FILE_START:
			return {
				...state,
				loading: true
			}

		case UPLOAD_SCRIPT_FILE_FINISH:
			return {
				...state,
				loading: false
			}

		case UPLOAD_SCRIPT_ADD_FILE:
			return {
				...state,
				file: payload.file,
				preparedToSend: payload.preparedToSend,
				error: ''
			}

		case UPLOAD_SCRIPT_ERROR:
			return {
				...state,
				error: payload.error
			}

		default:
			return state
	}
}
