import React from 'react'

import MySimpleAlert from '../alerts/MySimpleAlert'
import ModalHeader from '../Modal/ModalHeader'
import ViewerWrapper from '../ViewerWrapper/ViewerWrapper'
import Flexbox from 'Scenes/Components/FlexBox'

import './CastorModalIframe.scss'

const CastorModalIframe = ({
	showModal,
	onCancel,
	children,
	partImageUrl,
	onViewerModelError,
	solution,
	part
}) => {
	const renderViewer = () => {
		return (
			<ViewerWrapper
				src={partImageUrl}
				solution={solution}
				onViewerModelError={onViewerModelError}
				part={part}
			/>
		)
	}

	return (
		<MySimpleAlert
			title={<ModalHeader title={''} onCancel={onCancel} />}
			show={showModal}
			onCancel={onCancel}
			showConfirm={false}
			customClass={`threed-iframe-alert`}
		>
			<Flexbox flexDirection="column" height="100%">
				<div className={`threed-iframe`}>{renderViewer()}</div>
				{children}
			</Flexbox>
		</MySimpleAlert>
	)
}

export default CastorModalIframe
