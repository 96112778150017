import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { MenuItem, Select } from '@material-ui/core'

import { changeHolesNotify } from './CustomizeThreadActions'
import {
	cadName,
	customizeGeometryAnalysisSelectOptions,
	heatDeformationName,
	holesName,
	internalCavitiesName,
	IOption,
	millingMetalSupportName,
	orientationStabilityName,
	threadName,
	toleranceName,
	wallThicknessName
} from './CustomizeThreadService'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import {
	CUSTOMIZE_GEOMETRY_ANALYSIS_EXPLANATION_ARRAY,
	CUSTOMIZE_GEOMETRY_ANALYSIS_TITLE
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const SelectTsx: any = Select
const MenuItemTsx: any = MenuItem

interface ISelectProps {
	title: string
	value: boolean
	selectOptions: Array<any>
	onUpdate: (value: boolean) => void
}

type IThreadContentProps = {
	showHeader?: boolean
}

const SelectContent: FC<ISelectProps> = ({
	title,
	value,
	selectOptions,
	onUpdate
}) => {
	const [selectValue, setSelectValue] = useState(value)

	useEffect(() => {
		setSelectValue(value)
	}, [value])

	return (
		<div className="customize-solution-filter__thread">
			<div className="customize-solution-filter__thread-block">
				<div>
					<div className="title">{title}:</div>
					<SelectTsx
						disableUnderline={false}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setSelectValue(Boolean(e.target.value))
						}
						value={Number(selectValue)}
					>
						<MenuItemTsx value="-" disabled>
							{title}
						</MenuItemTsx>
						{selectOptions.map((option: IOption) => {
							return (
								<MenuItemTsx key={option.info} value={option.value}>
									{option.label}
								</MenuItemTsx>
							)
						})}
					</SelectTsx>
				</div>

				<Button
					disabled={selectValue === value}
					color="primaryNewStyle"
					onClick={() => onUpdate(selectValue)}
				>
					{getString('SAVE')}
				</Button>
			</div>
		</div>
	)
}

const ThreadContent: FC<IThreadContentProps> = ({ showHeader }) => {
	const dispatch = useDispatch()
	const {
		holesFiltering,
		threadDetection,
		wallThicknessAlert,
		toleranceAlert,
		internalCavitiesAlert,
		cadAlert,
		millingMetalSupportAlert,
		heatDeformationAlert,
		orientationStabilityAlert
	} = useSelector(
		(state: RootStateOrAny) => state?.user?.userCustomizationSettings
	)

	const isThreadDetectionOn = Feature.isFeatureOn(
		FeatureComponentId.THREAD_DETECTION
	)
	return (
		<div className="customize-solution-filter__threads--wrapper">
			{showHeader && (
				<CastorFormHeader
					explanationHeader={CUSTOMIZE_GEOMETRY_ANALYSIS_TITLE}
					explanationArray={CUSTOMIZE_GEOMETRY_ANALYSIS_EXPLANATION_ARRAY}
					isInCard={true}
				/>
			)}
			<div className="customize-solution-filter__threads">
				{isThreadDetectionOn && (
					<SelectContent
						value={threadDetection}
						title={getString('THREAD_BEHAVIOUR')}
						selectOptions={customizeGeometryAnalysisSelectOptions}
						onUpdate={(value: boolean) =>
							dispatch(changeHolesNotify(threadName, value))
						}
					/>
				)}

				<SelectContent
					value={holesFiltering}
					title={getString('HOLES_FILTERING')}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(changeHolesNotify(holesName, value))
					}
				/>

				<SelectContent
					value={wallThicknessAlert}
					title={getString('MINIMAL_WALL_THICKNESS_GEOMETRY_ANALYSIS_TITLE')}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(changeHolesNotify(wallThicknessName, value))
					}
				/>

				<SelectContent
					value={toleranceAlert}
					title={getString('TOLERANCE')}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(changeHolesNotify(toleranceName, value))
					}
				/>

				<SelectContent
					value={internalCavitiesAlert}
					title={getString('INTERNAL_CAVITIES_GEOMETRY_ANALYSIS_TITLE')}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(changeHolesNotify(internalCavitiesName, value))
					}
				/>

				<SelectContent
					value={cadAlert}
					title={getString('PART_RESULTS_CAD')}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(changeHolesNotify(cadName, value))
					}
				/>

				<SelectContent
					value={millingMetalSupportAlert}
					title={getString(
						'MILLING_METAL_SUPPORT_ISSUES_GEOMETRY_ANALYSIS_TITLE'
					)}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(changeHolesNotify(millingMetalSupportName, value))
					}
				/>

				<SelectContent
					value={heatDeformationAlert}
					title={getString('HEAT_DEFORMATION_CONCERNS_GEOMETRY_ANALYSIS_TITLE')}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(changeHolesNotify(heatDeformationName, value))
					}
				/>

				<SelectContent
					value={orientationStabilityAlert}
					title={getString(
						'ORIENTATION_STABILITY_CONCERNS_GEOMETRY_ANALYSIS_TITLE'
					)}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(changeHolesNotify(orientationStabilityName, value))
					}
				/>
			</div>
		</div>
	)
}

export default memo(ThreadContent)
