import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import {
	GET_PROJECT_INFO,
	GET_PROJECT_INFO_FAIL,
	GET_PROJECT_INFO_SUCCESS,
	HANDLE_LOADER
} from 'global actions/types'
import { getProjectsInfo } from 'Services/Network'

export const resetProjectInformation = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: GET_PROJECT_INFO
		})
	}
}

export const getProjectInformation = (projectId: string) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})
		dispatch({
			type: GET_PROJECT_INFO,
			payload: { id: projectId }
		})
		try {
			const response = await getProjectsInfo(projectId)
			dispatch({
				type: GET_PROJECT_INFO_SUCCESS,
				payload: {
					id: projectId,
					isBundle: response?.data?.data?.isBundle
				}
			})
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		} catch (err) {
			console.log(err)
			dispatch({ type: GET_PROJECT_INFO_FAIL, payload: { id: projectId } })
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		}
	}
}
