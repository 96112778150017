import React, { ChangeEvent, FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { Accordion } from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import cx from 'classnames'

import { PART_COST } from './constants'
import { toggleCostingFunctionAccordion } from './CostingFunctionEditorActions'
import { FunctionEditorInitialState } from './CostingFunctionEditorReducer'
import CreateFunctionContent from './CreateFunctionContent'
import { CostingFunctionSteps } from './CustomizeCostingFunctionTypes'
import { getString } from 'Services/Strings/StringService'

const Step2: FC = () => {
	const dispatch = useDispatch()
	const { stepIsOpen, isStep2ToggleAllowed } = useSelector(
		(state: RootStateOrAny) =>
			state.CostingFunctionEditorReducer?.functionEditorStates[PART_COST] ||
			new FunctionEditorInitialState()
	)

	const step2 = CostingFunctionSteps.CreateFunction

	return (
		<>
			<Accordion
				disabled={!isStep2ToggleAllowed}
				className="costing-function-editor--accordion"
				expanded={stepIsOpen[step2]}
				onChange={(e: ChangeEvent<{}>, expanded: boolean) => {
					dispatch(toggleCostingFunctionAccordion(step2))
				}}
			>
				<AccordionSummary
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="costing-function-editor--step-heading--wrapper"
					classes={{ content: 'costing-function-editor--step-heading' }}
				>
					<div className="costing-function-editor--step-heading--text">
						<span className="costing-function-editor--step-heading--text--main">
							{getString('COSTING_FUNCTION_EDITOR_STEP_2_HEADER')}
						</span>
						<span>
							{getString('COSTING_FUNCTION_EDITOR_STEP_2_DESCRIPTION')}
						</span>
					</div>
					<ExpandMoreIcon
						className={cx('expand', {
							open: stepIsOpen[step2]
						})}
					/>
				</AccordionSummary>
				<AccordionDetails className="costing-function-editor--step-body">
					<CreateFunctionContent reducerStateId={PART_COST} />
				</AccordionDetails>
			</Accordion>
		</>
	)
}

export default memo(Step2)
