import React, { FC, memo } from 'react'

import CustomizeMetadataCostTbl from './CustomizeMetadataCostTbl'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

interface IProps {}

// TODO: Customize2DDrawingCost and CustomizeMetadataCost almost the same,
// need to make 1 component and reuse it
const CustomizeMetadataCost: FC<IProps> = ({}) => {
	return (
		<div className="metadata-cost" data-qa="data-qa-metadata-cost">
			<CastorFormHeader
				explanationHeader={getString('CUSTOMIZE_METADATA_COST_TITLE')}
				explanationArray={getString('CUSTOMIZE_METADATA_COST_EXPLANATION')}
				isInCard={true}
			/>
			<CustomizeMetadataCostTbl />
		</div>
	)
}

export default WithFeatureToggleHOC(
	memo(CustomizeMetadataCost),
	FeatureComponentId.META_DATA_PROJECT
)
