import React, { FC, useEffect, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
// @ts-ignore
import SwipeableViews from 'react-swipeable-views'

// material-ui components
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import cx from 'classnames'

import { analysisTabLSKey } from 'Services/Constants'
import usePrevious from 'Services/CustomHooks/usePrevious'
import {
	getStringItemFromLocalStorage,
	removeItemFromLocalStorage
} from 'Services/LocalStorageService'
import { Feature, FeatureComponentId } from 'Services/models/Features'

import './NavScrollablePills.scss'

const TabsTSX: any = Tabs
const TabTSX: any = Tab
const leadTimeActive = 3

interface ITabPanel {
	children: any
	index: number
	value: number
	dir: string
}

const TabPanel: FC<ITabPanel> = props => {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	)
}

interface IProps {
	classes: any
	tabs: any
	direction: string
	alignCenter?: boolean
	tabContentClassName: string
	initialSelectedTab: number
	tabButtonClassName: string
	showSmallButtons?: boolean
	configuration: any
}

const NavScrollablePills: FC<IProps> = ({
	tabs,
	direction,
	alignCenter,
	tabContentClassName,
	initialSelectedTab,
	tabButtonClassName,
	showSmallButtons,
	configuration
}) => {
	const [active, setActive] = useState<number>(initialSelectedTab)
	const [tabsArray, setTabs] = useState<any>(tabs)
	const prevTabs = usePrevious(tabsArray)
	const [animateTabs, setAnimateTabs] = useState(false)
	const tabsArrayLength = tabsArray.length - 1
	const { updateQuantityLoader } = useSelector(
		(state: RootStateOrAny) => state.MainPartAnalysisReducer
	)
	const {
		params: { partId }
	} = useRouteMatch() as { params: Record<string, string> }

	const prevUpdateQuantityLoader = usePrevious(updateQuantityLoader)
	const activeValue = active > tabsArrayLength ? tabsArrayLength : active
	const localStorageTabKey = analysisTabLSKey({
		partId,
		configurationId: configuration.id
	})

	const setActiveTab = () => {
		const tabIndexFromLS = getStringItemFromLocalStorage(localStorageTabKey)
		const indexOfTab = tabsArray.findIndex(
			(tab: Record<string, unknown>) => tab.index === +tabIndexFromLS
		)
		const selectedTab = indexOfTab >= 0 ? indexOfTab : initialSelectedTab
		setActive(selectedTab)
	}

	useEffect(() => {
		setActiveTab()
	}, [initialSelectedTab, localStorageTabKey])

	useEffect(() => {
		if (
			!updateQuantityLoader &&
			prevUpdateQuantityLoader != updateQuantityLoader
		) {
			setActiveTab()
		}
	}, [updateQuantityLoader])

	useEffect(() => {
		setTabs(tabs)
	}, [tabs])

	// add an animation only when all tabs are built,
	// to avoid unnecessary jerking
	useEffect(() => {
		if (tabsArray?.length > 0 && tabsArray?.length === prevTabs?.length) {
			setAnimateTabs(true)
		}
	}, [tabsArray?.length, prevTabs?.length])

	const scrollableProps = (index: number) => {
		return {
			id: `scrollable-auto-tab-${index}`,
			'aria-controls': `scrollable-auto-tabpanel-${index}`
		}
	}

	const handleChange = (event: any, active: number) => {
		setAnimateTabs(true)
		const disableLeadTime =
			!Feature.isFeatureActive(FeatureComponentId.LEAD_TIME_TAB) ||
			!configuration.isSpecifiedQuantity
		if (disableLeadTime && active === leadTimeActive) {
			return
		}
		setActive(active)

		removeItemFromLocalStorage(localStorageTabKey)
	}
	const handleChangeIndex = (index: number) => {
		setAnimateTabs(true)
		setActive(index)
	}

	const tabButtons = () => (
		<TabsTSX
			variant="fullWidth"
			value={activeValue}
			onChange={handleChange}
			centered={alignCenter}
			textColor="primary"
			indicatorColor="primary"
			classes={{
				root: 'tab-root-container',
				fixed: 'tab-overflow-visible',
				flexContainer: 'tab-flex-container',
				scrollable: 'tab-scrollable-container'
			}}
		>
			{tabsArray?.map((prop: any, key: number) => {
				return (
					<TabTSX
						label={prop.tabButton}
						key={key}
						{...scrollableProps(key)}
						classes={{
							root: cx('tab-root-container_button', {
								small: showSmallButtons
							})
						}}
						sx={{
							bgcolor: 'background.paper',
							boxShadow: 1,
							borderRadius: 2,
							p: 2,
							minWidth: 300
						}}
					/>
				)
			})}
		</TabsTSX>
	)

	const tabContent = () => (
		<SwipeableViews
			axis={direction === 'rtl' ? 'x-reverse' : 'x'}
			index={activeValue}
			onChangeIndex={handleChangeIndex}
			slideClassName={tabContentClassName}
			style={{ overflowX: 'clip', overflowY: 'inherit' }}
			animateTransitions={animateTabs}
		>
			{tabsArray?.map((prop: any, key: number) => {
				return (
					<TabPanel key={key} value={active} index={key} dir={'x'}>
						<div key={key}>{prop?.tabContent}</div>
					</TabPanel>
				)
			})}
		</SwipeableViews>
	)

	return (
		<div className="nav-scrollable-container">
			{tabButtons()}
			{tabContent()}
		</div>
	)
}

export default NavScrollablePills
