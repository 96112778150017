export const margin = 15

export const styles = {
	combineCard: { marginLeft: margin, marginRight: margin, width: '100%' },
	firstRowOfGrid: { width: '100%' },
	gridImage: { maxHeight: '100%', maxWidth: '100%', width: '100%' },
	gridSuper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		textAlign: 'left'
	},
	horizontalFlex: {
		flex: 1,
		alignItems: 'center',
		align: 'center',
		justifyContent: 'center',
		marginLeft: margin * 2,
		marginRight: margin * 2,
		minWidth: 700
	},
	notAssemblySuperViewChart: {
		marginLeft: margin,
		marginRight: margin
	},
	partsHeaderFlex: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start'
	},
	filterDiv: {
		display: 'flex',
		alignItems: 'baseline',
		justifyContent: 'space-between',
		width: '100%',
		marginBottom: 5,
		gap: '10px'
	},
	filterDivStart: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		width: '100%',
		marginBottom: 5
	},
	flexDiv: {
		display: 'flex',
		alignItems: 'center'
	},
	projectResultChart: { minHeight: 905 },
	partImageAndDescription: { minHeight: 405, marginRight: margin },
	chartItemGridWhenAssembly: { width: '90%' },
	assemblyImageItemGrid: { width: '90%' },
	assemblyProjectResultChart: { minHeight: 905, marginRight: margin },
	chartWhenAssembly: {
		flex: '1 1 1 100%',
		marginLeft: margin,
		marginRight: margin,
		maxHeight: '610px',
		// overflow: 'auto',
		alignItems: 'inherit'
	},
	gridContainer: { textAlign: 'left' },
	partsGrid: {
		width: '100%',
		minHeight: '400px',
		marginLeft: margin,
		marginRight: margin,
		marginBottom: '2px',
		backgroundColor: '#fff',
		borderRadius: 6,
		boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
		zIndex: 0
	},
	partsGridInner: {
		height: 'auto',
		backgroundColor: 'white',
	},
	partsHeader: {
		height: 'auto',
		backgroundColor: 'white',
		marginBottom: 10,
		minHeight: '145px',
		zIndex: '100'
	},
	partsRows: {
		height: 'auto',
		backgroundColor: 'white',
		marginBottom: 10,
		minHeight: '145px',
		minWidth: '1130px'
	},
	partsPagination: {
		height: 'auto',
		minHeight: '50px',
		backgroundColor: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	partComponent: { height: 344 },
	partTile: { height: '344' },
	assemblyImage: {
		marginLeft: margin,
		minWidth: 400,
		height: 'calc(100% - 50px)',
		maxHeight: '100%',
		maxWidth: '40em',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	filterWithLabel: { float: 'right' },
	horizontalFlex1: { flex: 1, alignItems: 'flex-start' }
}
