import { FC, memo, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { FormControlLabel } from '@material-ui/core'
import Numeral from 'numeral'

import {
	fieldsValidation,
	IFieldsValidation
} from './CostParametersFormConstant'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorSwitch from 'Scenes/Components/CastorSwitch'
import Devider from 'Scenes/Components/Devider/Devider'
import Flexbox from 'Scenes/Components/FlexBox'
import TextField from 'Scenes/Components/TextField/TextField'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { manufacturingMethodTypes } from 'Services/Constants'
import { toFixedOnlyIfNeeded } from 'Services/global/toFixedOnlyIfNeeded'
import {
	ICastFormParameters,
	ICostFormParameters,
	IMetalFormParameters
} from 'Services/models/CostComparisonModels'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IPlasticFormParameters } from 'Services/models/PlasticCostComparisonModels'
import {
	ADVANCED,
	CHAIN_BENEFITS_HEADER,
	CNC_USER_CUSTOM_SETTING_DOMESTIC_SHIPMENT,
	CNC_USER_CUSTOM_SETTING_PROFIT_MARGIN,
	CNC_USER_CUSTOM_SETTING_TAXES,
	COST_AND_LEAD_ACTION_BUTTON_CANCEL_TEXT,
	OUTSOURCED_CNC_MANUFACTURING,
	PART_ANALYSIS_COST_COMPARISON_CAM_EXISTENCE,
	PART_ANALYSIS_COST_COMPARISON_OPERATION_PRICE,
	PART_ANALYSIS_COST_COMPARISON_OPERATION_PRICE_INFO,
	PART_ANALYSIS_COST_COMPARISON_PROGRAMING_PRICE,
	PART_FINANCIAL_ANALYSIS_DFM_COSTS_PLACEHOLDER,
	PART_FINANCIAL_ANALYSIS_MOLD_MAINTENANCE_PRICE_PLACEHOLDER,
	PART_FINANCIAL_ANALYSIS_MOLD_PART_PRICE_PLACEHOLDER,
	PART_FINANCIAL_ANALYSIS_MOLD_PRICE_PLACEHOLDER,
	SUBMIT
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './CostComparisonTab.scss'

const ReduxFormField: any = Field
const LabelFormControl: any = FormControlLabel
const DeviderTSX: any = Devider
const FormControlLabelTSX: any = FormControlLabel

interface IProps {
	formParameters: IPlasticFormParameters &
		IMetalFormParameters &
		ICastFormParameters &
		ICostFormParameters
	showCancelButton: boolean
	costLoading: boolean
	manufacturingTypes: string
	onCancel: Function
	onFormSubmit: any
	isProject2dType: boolean
	outsourcedCNCManufacturingToggle: boolean
	onOutsourceChange: Function
}

export const validateForm = (...validationValue: any) => {
	const name = validationValue[3] // name
	const value = validationValue[0] || validationValue[2].formParameters[name] // value

	const fieldValidation: IFieldsValidation | null =
		fieldsValidation.find(fieldValidation => fieldValidation.name === name) ||
		null

	if (!fieldValidation) {
		return null
	}

	if (
		fieldValidation &&
		(value < fieldValidation?.min ||
			value < fieldValidation?.secondaryMin ||
			value > fieldValidation?.max)
	) {
		return fieldValidation.minMaxErrorMsg.format(
			fieldValidation.min,
			fieldValidation.max
		)
	}

	return null
}

const renderTextField = ({
	input,
	label,
	type,
	placeholder,
	meta: { touched, error },
	inputProps,
	disableMinus,
	disablePlus,
	disableFloat,
	disableZero,
	disabled
}: any) => (
	<TextField
		wrapperClassName="financial-form--text-field"
		labelInfoWrapperClassName="financial-form--text-field-wrapper"
		labelClassName="financial-form--text-field-label"
		inputClassName={'financial-form--text-field-input'}
		label={label}
		error={error}
		type={type}
		input={input}
		touched={touched}
		placeholder={placeholder}
		inputProps={inputProps}
		disableMinus={disableMinus}
		disableZero={disableZero}
		disablePlus={disablePlus}
		disableFloat={disableFloat}
		disabled={disabled}
	/>
)

const renderToggleField = ({ input }: any) => (
	<CastorSwitch checked={input.value} onChange={input.onChange} />
)

const CostParametersForm: FC<InjectedFormProps<any, IProps> & IProps> = ({
	handleSubmit,
	change,
	formParameters,
	showCancelButton,
	costLoading,
	manufacturingTypes,
	onCancel,
	onFormSubmit,
	isProject2dType,
	outsourcedCNCManufacturingToggle,
	onOutsourceChange
}) => {
	const [advanced, setAdvanced] = useState(false)
	const { userCustomizationSettings } = useSelector((state: RootStateOrAny) => {
		return state?.user
	})

	const renderCancelButton = () => {
		if (!showCancelButton) {
			return <div />
		}
		return (
			<TransparentButton
				onClick={() => onCancel()}
				className="financial-form-button"
			>
				{COST_AND_LEAD_ACTION_BUTTON_CANCEL_TEXT}
			</TransparentButton>
		)
	}

	const renderForm = (manufacturingTypes: string) => {
		const showCO2Params =
			Feature.isFeatureOn(FeatureComponentId.CO2_EMISSIONS) && !isProject2dType
		if (
			[
				manufacturingMethodTypes.cast,
				manufacturingMethodTypes.investmentCast
			].includes(manufacturingTypes)
		) {
			if (showCO2Params) {
				return (
					<>
						<DeviderTSX size="95%" className="cost-details-row-divider" />
						<div className="financial-form-user-custom-settings-header">
							{CHAIN_BENEFITS_HEADER}
						</div>
						{renderCO2Parameters()}
					</>
				)
			}
			return <></>
		}
		return (
			<>
				<DeviderTSX size="95%" className="cost-details-row-divider" />
				<div className="financial-form-user-custom-settings-header">
					{CHAIN_BENEFITS_HEADER}
				</div>
				{projectSettingsScenariosOn &&
					manufacturingTypes === manufacturingMethodTypes.cnc && (
						<div className="check-boxs--wrapper">
							<FormControlLabelTSX
								control={
									<CastorSwitch
										checked={outsourcedCNCManufacturingToggle}
										onChange={onOutsourceChange}
									/>
								}
								label={OUTSOURCED_CNC_MANUFACTURING}
							/>
						</div>
					)}
				{renderManufacturingForm(manufacturingTypes)}
				{showCO2Params && renderCO2Parameters()}
			</>
		)
	}
	const renderFooter = () => {
		return (
			<>
				<div className="financial-form-buttons">
					{renderCancelButton()}
					<ButtonWithLoader
						color="primary"
						className="financial-form-button-submit"
						onClick={handleSubmit(onFormSubmit)}
						loading={costLoading}
					>
						{SUBMIT}
					</ButtonWithLoader>
				</div>
			</>
		)
	}

	const projectSettingsScenariosOn = Feature.isFeatureOn(
		FeatureComponentId.PROJECT_SETTINGS_SCENARIOS
	)

	const renderManufacturingForm = (manufacturingTypes: string) => {
		switch (manufacturingTypes) {
			case manufacturingMethodTypes.mold:
				return (
					<>
						{renderMoldsForms()}
						{renderAdvancedSettings(manufacturingTypes)}
					</>
				)

			case manufacturingMethodTypes.cast:
			case manufacturingMethodTypes.investmentCast:
				return <>{renderCastForm()}</>
			default:
				return (
					<>
						{!projectSettingsScenariosOn && renderToggles()}
						{/* {renderCNCUserCustomSettings()} */}
						{renderAdvancedSettings(manufacturingTypes)}
					</>
				)
		}
	}

	const renderToggles = () => {
		return (
			<>
				<LabelFormControl
					control={
						<ReduxFormField name="camExistence" component={renderToggleField} />
					}
					label={PART_ANALYSIS_COST_COMPARISON_CAM_EXISTENCE}
				/>
			</>
		)
	}

	const renderCastForm = () => {
		return (
			<ReduxFormField
				name="castToolingCost"
				component={renderTextField}
				disableMinus
				disableZero
				label={getString(
					'PART_FINANCIAL_ANALYSIS_CAST_PRICE_PLACEHOLDER'
				).format('$')}
				type="number"
				placeholder={Numeral(
					formParameters?.castToolingCost?.toFixed() ||
						formParameters?.investmentCastToolingCost?.toFixed() ||
						''
				).format('0,0')}
				inputProps={{ min: 0 }}
				change={change}
			/>
		)
	}

	const renderMoldsForms = () => {
		const projectSettingsScenariosOn = Feature.isFeatureOn(
			FeatureComponentId.PROJECT_SETTINGS_SCENARIOS
		)

		return (
			<>
				{!projectSettingsScenariosOn && (
					<ReduxFormField
						name="moldCost"
						disablePlus
						disableMinus
						disableZero
						component={renderTextField}
						label={PART_FINANCIAL_ANALYSIS_MOLD_PRICE_PLACEHOLDER.format('$')}
						type="number"
						placeholder={
							formParameters?.moldPrice
								? formParameters?.moldPrice.toFixed()
								: ''
						}
						inputProps={{ min: 0 }}
						change={change}
					/>
				)}
				<ReduxFormField
					name="moldPartCost"
					disablePlus
					disableMinus
					disableZero
					component={renderTextField}
					label={PART_FINANCIAL_ANALYSIS_MOLD_PART_PRICE_PLACEHOLDER.format(
						'$'
					)}
					type="number"
					placeholder={
						formParameters?.costPerPart
							? toFixedOnlyIfNeeded(formParameters.costPerPart)
							: ''
					}
					inputProps={{ min: 0 }}
					change={change}
				/>
			</>
		)
	}

	const renderCO2Parameters = () => {
		return (
			<>
				<DeviderTSX size="86%" className="cost-details-row-divider" />
				<div className="co2-parameters">
					<div className="co2-parameters-description">
						{getString('CO2_PARAMETERS_DESCRIPTION_TEXT')}
					</div>

					<div className="co2-parameters-header">
						{getString('CO2_PARAMETERS_PRODUCT_USE_HEADER_TEXT')}:
					</div>

					<div className="co2-parameters-description">
						{getString('CO2_PARAMETERS_PRODUCT_USE_DESCRIPTION_TEXT')}
					</div>

					<ReduxFormField
						name="puAnnualKgCO2"
						disablePlus
						disableMinus
						component={renderTextField}
						label={getString('CO2_PARAMETERS_AMOUNT_KG_CO2_PER_YEAR')}
						type="number"
						value={formParameters.puAnnualKgCO2}
						placeholder={
							formParameters.puAnnualKgCO2 ||
							userCustomizationSettings.puAnnualKgCO2 ||
							''
						}
						change={change}
						validate={validateForm}
					/>

					<ReduxFormField
						name="puYearsCO2"
						disablePlus
						disableMinus
						component={renderTextField}
						label={getString('CO2_PARAMETERS_PART_LIFE_YEARS')}
						type="number"
						value={formParameters.puYearsCO2}
						placeholder={
							formParameters.puYearsCO2 ||
							userCustomizationSettings.puYearsCO2 ||
							''
						}
						change={change}
						validate={validateForm}
					/>

					<div className="co2-parameters-header">
						{getString('CO2_PARAMETERS_END_OF_LIFE_HEADER_TEXT')}:
					</div>

					<div className="co2-parameters-description">
						{getString('CO2_PARAMETERS_END_OF_LIFE_DESCRIPTION_TEXT')}
					</div>
					<div className="co2-parameters-description">
						{getString('CO2_PARAMETERS_END_OF_LIFE_DESCRIPTION_EXPLANATION')}
					</div>

					<ReduxFormField
						name="disposeFactor"
						disablePlus
						disableMinus
						component={renderTextField}
						label={getString('CO2_PARAMETERS_COST_KG_CO2_OF_KG_OF_PRODUCTS')}
						type="number"
						value={formParameters.disposeFactor}
						placeholder={
							formParameters.disposeFactor ||
							userCustomizationSettings.disposeFactor ||
							''
						}
						change={change}
						validate={validateForm}
					/>
				</div>
			</>
		)
	}

	const renderAdvancedSettings = (manufacturingTypes: string) => {
		if (!advanced) {
			return (
				<TransparentButton
					onClick={() => setAdvanced(true)}
					className="financial-form-advanced-button"
				>
					{ADVANCED}
				</TransparentButton>
			)
		}

		if (manufacturingTypes === manufacturingMethodTypes.mold) {
			return (
				<>
					<ReduxFormField
						name="moldMaintenanceCost"
						disablePlus
						disableMinus
						disableZero
						component={renderTextField}
						label={PART_FINANCIAL_ANALYSIS_MOLD_MAINTENANCE_PRICE_PLACEHOLDER.format(
							'$'
						)}
						type="number"
						placeholder={
							formParameters.moldMaintenanceCost
								? toFixedOnlyIfNeeded(formParameters.moldMaintenanceCost)
								: ''
						}
						inputProps={{ min: 0 }}
						change={change}
					/>

					<ReduxFormField
						name="DFMCosts"
						disablePlus
						disableMinus
						disableZero
						component={renderTextField}
						label={PART_FINANCIAL_ANALYSIS_DFM_COSTS_PLACEHOLDER.format('$')}
						type="number"
						placeholder={Math.round(formParameters.DFMCosts)}
						inputProps={{ min: 0 }}
						change={change}
					/>
				</>
			)
		}

		return (
			<>
				<ReduxFormField
					name="cncProgrammingPricePerHour"
					component={renderTextField}
					label={PART_ANALYSIS_COST_COMPARISON_PROGRAMING_PRICE.format('$')}
					type="number"
					placeholder={formParameters?.cncProgrammingPricePerHour}
					inputProps={{ min: 0 }}
					change={change}
				/>
				<ReduxFormField
					validate={validateForm}
					name="operationCostPerHour"
					component={renderTextField}
					label={PART_ANALYSIS_COST_COMPARISON_OPERATION_PRICE.format('$')}
					type="number"
					placeholder={formParameters?.operationCostPerHour}
					inputProps={{ min: 0, max: 999 }}
					change={change}
					infoData={PART_ANALYSIS_COST_COMPARISON_OPERATION_PRICE_INFO}
				/>
			</>
		)
	}

	const renderCNCUserCustomSettings = () => {
		return (
			<div>
				<ReduxFormField
					name="customsTaxes"
					component={renderTextField}
					label={CNC_USER_CUSTOM_SETTING_TAXES.format('%')}
					type="number"
					placeholder={formParameters?.customsTaxes}
					inputProps={{ min: 0 }}
					change={change}
				/>
				<ReduxFormField
					name="domesticShipment"
					component={renderTextField}
					label={CNC_USER_CUSTOM_SETTING_DOMESTIC_SHIPMENT.format('$')}
					type="number"
					placeholder={formParameters?.domesticShipment}
					inputProps={{ min: 0 }}
					change={change}
				/>
				<ReduxFormField
					name="profitMargin"
					component={renderTextField}
					label={CNC_USER_CUSTOM_SETTING_PROFIT_MARGIN.format('%')}
					type="number"
					placeholder={formParameters?.profitMargin}
					inputProps={{ min: 0 }}
					change={change}
				/>
			</div>
		)
	}

	return (
		<Flexbox flexDirection="column" padding="0 5px 0 0">
			{renderForm(manufacturingTypes)}
			{renderFooter()}
		</Flexbox>
	)
}

export default memo(reduxForm<any, IProps>({})(CostParametersForm))
