import { toLower } from 'lodash'

import {
	ADMIN_MATERIAL_ADDED,
	ADMIN_MATERIAL_DELETED,
	ADMIN_MATERIAL_EDIT_FORM_OPENED,
	ADMIN_MATERIAL_NEW_FORM_OPENED,
	ADMIN_MATERIAL_SEARCH_PHRASE_CHANGED,
	ADMIN_MATERIAL_TYPE_CHANGED,
	ADMIN_MATERIAL_UPDATED,
	GET_ADMIN_MATERIALS,
	GET_ADMIN_MATERIALS_EDIT_MATERIAL_SELECTED,
	GET_ADMIN_MATERIALS_GOT_ERROR,
	GET_ADMIN_MATERIALS_SUCCESS,
	NEW_ITEM_ADD_CALLED,
	NEW_ITEM_ADD_FAILED
} from '../../../../global actions/types'

const INITIAL_STATE = {
	materials: [],
	totalMaterials: 0,
	materialsTableData: [],
	showNoMaterialsAlert: false,
	pageNumber: 1,
	isLastPage: false,
	NumOfMaterialsSeen: 0,
	showPagination: false,
	limitMaterials: 50,
	showAdminMaterialsAlert: false,
	selectedEditMaterial: null,
	materialAdded: false,
	searchPhrase: '',
	searchPhraseChanged: false,
	materialCategoriesToShow: [],
	addingNewItem: false,
	loading: false
}
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_ADMIN_MATERIALS:
			return { ...state, loading: true }
		case GET_ADMIN_MATERIALS_SUCCESS:
			const { data, pageNumber } = action.payload,
				{ materials, totalMaterials } = data,
				NumOfMaterialsSeen =
					(pageNumber - 1) * state.limitMaterials + materials.length,
				isLastPage = NumOfMaterialsSeen >= totalMaterials
			return {
				...state,
				materials,
				totalMaterials,
				materialsTableData: materials.map(material => Object.values(material)),
				showNoMaterialsAlert: !materials.length,
				pageNumber,
				NumOfMaterialsSeen,
				isLastPage,
				showPagination: !(isLastPage && pageNumber === 1),
				showAdminMaterialsAlert: false,
				searchPhraseChanged: false,
				materialAdded: false,
				addingNewItem: false,
				loading: false
			}
		case ADMIN_MATERIAL_UPDATED:
			const updatedMaterial = action.payload
			return {
				...state,
				materials: state.materials.map(material =>
					material.id === updatedMaterial.id ? updatedMaterial : material
				),
				selectedEditMaterial: updatedMaterial
			}
		case GET_ADMIN_MATERIALS_GOT_ERROR:
			return {
				...state,
				showAdminMaterialsAlert: true,
				loading: false
			}
		case GET_ADMIN_MATERIALS_EDIT_MATERIAL_SELECTED:
			return {
				...state,
				selectedEditMaterial: action.payload,
				materialAdded: false
			}
		case ADMIN_MATERIAL_DELETED:
			const id = action.payload.id
			return {
				...state,
				materials: state.materials.filter(material => material.id !== id)
			}
		case ADMIN_MATERIAL_ADDED:
			const newMaterial = action.payload
			return {
				...state,
				materials: [...state.materials, newMaterial],
				materialAdded: true,
				addingNewItem: false
			}
		case ADMIN_MATERIAL_SEARCH_PHRASE_CHANGED:
			const searchPhrase = action.payload
			return {
				...state,
				searchPhrase,
				searchPhraseChanged: true
			}
		case ADMIN_MATERIAL_TYPE_CHANGED:
			const { selectedType, materialCategories } = action.payload
			return {
				...state,
				materialCategoriesToShow: materialCategories.filter(
					materialCategory =>
						toLower(materialCategory.type) === toLower(selectedType)
				)
			}
		case ADMIN_MATERIAL_NEW_FORM_OPENED:
			return {
				...state,
				materialCategoriesToShow: [],
				selectedEditMaterial: null
			}
		case ADMIN_MATERIAL_EDIT_FORM_OPENED: {
			const materialCategories = action.payload
			return {
				...state,
				materialCategoriesToShow: materialCategories.filter(
					materialCategory =>
						toLower(materialCategory.type) ===
						toLower(state.selectedEditMaterial.type)
				)
			}
		}
		case NEW_ITEM_ADD_CALLED:
			return {
				...state,
				addingNewItem: true
			}
		case NEW_ITEM_ADD_FAILED:
			return {
				...state,
				addingNewItem: false
			}
		default:
			return state
	}
}
