import { MenuItem, Select } from '@material-ui/core'
import { FC, memo } from 'react'

import { getString } from 'Services/Strings/StringService'

import './DataTable.scss'

const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem

interface IProps {
  text: string | number
  className: string
  data: any
}

const DataTableDropdown: FC<IProps> = ({ text, className, data = {} }) => (
  <SelectTSX
    className={`data-table-dropdown ${className} ${data.className}`}
    value={text == null ? '' : text}
    onChange={data.onChange}
    disableUnderline={true}
  >
    {data.options.map((option: any) => {
      return (
        <MenuItemTSX key={option.key} value={option.value}>
          {getString(option.key)}
        </MenuItemTSX>
      )
    })}
  </SelectTSX>
)

export default memo(DataTableDropdown)
