import { cloneDeep } from 'lodash'

import {
	COST_AND_LEAD_ACTION_BUTTON_TEXT_CHANGED,
	COST_AND_LEAD_INPUTS_CHANGED,
	COST_AND_LEAD_TBL_DATA_ARRANGED,
	COST_AND_LEAD_TBL_DATA_UPDATED,
	CUSTOMIZE_BUTTON_LOADER_UPDATED,
	CUSTOMIZE_PRINTER_PARAMETERS_CHANGED,
	CUSTOMIZE_PRINTER_PROPS_COMPANY_CHANGED,
	CUSTOMIZE_PRINTER_PROPS_LAYER_THICKNESS_CHANGED,
	CUSTOMIZE_PRINTER_PROPS_PRINTER_PRICE_CHANGED,
	CUSTOMIZE_PRINTER_PROPS_PRINTER_SELECTED,
	CUSTOMIZE_PRINTER_PROPS_SETUPED,
	CUSTOMIZE_PRINTER_SETTINGS_BUTTON_LOADER_TOGGLED,
	CUSTOMIZE_PRINTER_SETTINGS_DELETED,
	CUSTOMIZE_USER_SETTINGS_ADDED,
	DRAWING_COST_APPLY_TOGGLE,
	DRAWING_COST_EDIT_ALL_TOGGLE,
	DRAWING_COST_EDIT_DATA,
	DRAWING_COST_EDIT_TOGGLE,
	DRAWING_COST_UPDATE_ALL_DATA,
	DRAWING_COST_UPDATE_DATA,
	EDIT_USER_SETTING_CANCELED,
	EDITABLE_PRINTER_COSTS_CHANGE,
	METADATA_COST_APPLY_TOGGLE,
	METADATA_COST_EDIT_ALL_TOGGLE,
	METADATA_COST_EDIT_DATA,
	METADATA_COST_EDIT_TOGGLE,
	METADATA_COST_UPDATE_ALL_DATA,
	METADATA_COST_UPDATE_DATA,
	SETUP_PRINTER_COSTS,
	USER_SETTING_CHANGED,
	USER_SETTING_CHANGED_TEMP,
	USER_SETTING_GOT_ERROR,
	VALIDATION_STATE_CHANGED,
	VALUE_CHANGED_TO_EDIT
} from '../../../../global actions/types'
import {
	COST_AND_LEAD_NAMES,
	CUSTOMIZE_MAXIMUM_LAYER_THICKNESS,
	CUSTOMIZE_MAXIMUM_PRINTER_HOURLY_PRICE,
	CUSTOMIZE_MAXIMUM_PRINTER_PRICE,
	CUSTOMIZE_MINIMUM_LAYER_THICKNESS,
	CUSTOMIZE_MINIMUM_PRINTER_HOURLY_PRICE,
	CUSTOMIZE_MINIMUM_PRINTER_PRICE,
	DRAWING_COSTS_NAMES,
	METADATA_COSTS_NAMES,
	PRINTING_COST_PARAMETERS
} from '../../../../Services/Constants'
import { COST_AND_LEAD_BUTTON_ACTIONS } from '../../../../Services/Strings'
import {
	getNewActionButtonTexts,
	setupValueLabels,
	shouldPrinterButtonDisable
} from './CostAndLeadService'

const INITIAL_STATE = {
	editInputs: COST_AND_LEAD_NAMES,
	settingsData: [],
	loading: false,
	userSettingsWithError: {},
	originalUserSettingsValues: {},
	showUploadButton: false,
	showUploadAlert: false,
	rangeState: '',
	costAndLeadNames: COST_AND_LEAD_NAMES,
	isEditAllDrawingCost: false,
	drawingCost2dNames: DRAWING_COSTS_NAMES,
	metadataCostNames: METADATA_COSTS_NAMES,
	isEditAllMetadataCost: false,
	actionButtonTexts: {},
	printerCompanies: [],
	printerNames: [],
	selectedPrinter: {},
	defaultPrinterCost: '',
	defaultLayerThickness: '',
	selectedPrinterCost: '',
	selectedPrinterHourlyCost: '',
	selectedPrinterName: '',
	selectedPrinterCompany: '',
	selectedPrinterCostParameter: PRINTING_COST_PARAMETERS[0],
	selectedPrinterLayerThickness: '',
	disablePrinterPropsButton: true,
	userPrinterSettingsLoading: false,
	userPrinterSettingsData: [],
	incorrectEntryForLayerThickness: false,
	incorrectEntryForPrice: false,
	incorrectEntryForHourlyPrice: false,
	editablePrinterCostsList: [],
	deletablePrinterCostsList: [],
	userSettingsEditMode: false
}

const costAndLeadReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case COST_AND_LEAD_TBL_DATA_ARRANGED: {
			const {
				settingsData,
				originalUserSettingsValues,
				validationStateKeys,
				userPrinterSettingsData
			} = action.payload

			return {
				...state,
				settingsData,
				originalUserSettingsValues,
				userPrinterSettingsData,
				...validationStateKeys,
				...setupValueLabels(settingsData, state.costAndLeadNames)
			}
		}

		case COST_AND_LEAD_TBL_DATA_UPDATED:
			const {
				settingsData,
				newEditInputs,
				originalUserSettingsValues,
				inputKey,
				validationStateKeys,
				showUploadButton
			} = action.payload

			return {
				...state,
				settingsData,
				editInputs: newEditInputs,
				originalUserSettingsValues,
				showUploadButton:
					showUploadButton !== undefined
						? showUploadButton
						: state.showUploadButton,
				actionButtonTexts: getNewActionButtonTexts(
					settingsData,
					state.actionButtonTexts,
					inputKey
				),
				...validationStateKeys,
				...setupValueLabels(settingsData, state.costAndLeadNames),
				userSettingsEditMode: false
			}

		case COST_AND_LEAD_INPUTS_CHANGED: {
			const { newEditInputs, key } = action.payload
			return {
				...state,
				editInputs: newEditInputs,
				actionButtonTexts: {
					...state.actionButtonTexts,
					[key]: [
						COST_AND_LEAD_BUTTON_ACTIONS.CANCEL,
						COST_AND_LEAD_BUTTON_ACTIONS.APPLY
					]
				}
			}
		}
		case VALUE_CHANGED_TO_EDIT:
			return {
				...state,
				settingsData: action.payload.newSettingsData,
				editInputs: action.payload.newEditInputs
			}
		case EDIT_USER_SETTING_CANCELED: {
			const {
				newEditInputs,
				key,
				newSettingsData,
				isOriginalValueIsDefaultValue,
				validationStateKeyName
			} = action.payload
			return {
				...state,
				editInputs: newEditInputs,
				userSettingsWithError: { ...state.userSettingsWithError, [key]: false },
				settingsData: newSettingsData,
				actionButtonTexts: {
					...state.actionButtonTexts,
					[key]: isOriginalValueIsDefaultValue
						? [COST_AND_LEAD_BUTTON_ACTIONS.EDIT]
						: [COST_AND_LEAD_BUTTON_ACTIONS.DEFAULT]
				},
				[validationStateKeyName]: ''
			}
		}
		case CUSTOMIZE_BUTTON_LOADER_UPDATED:
			return {
				...state,
				loading: action.payload
			}

		case USER_SETTING_CHANGED:
			const { newSettingsData } = action.payload
			return {
				...state,
				settingsData: newSettingsData,
				showUploadButton: true,
				userSettingsEditMode: true
			}

		case USER_SETTING_CHANGED_TEMP:
			const { namespace, value } = action.payload
			const userSettings = {
				...state.settingsData.userSettings,
				[namespace]: value ? value : ''
			}
			return {
				...state,
				settingsData: { ...state.settingsData, userSettings },
				showUploadButton: true
			}

		case USER_SETTING_GOT_ERROR: {
			const { key, isError, newActionButtonTexts } = action.payload
			return {
				...state,
				userSettingsWithError: {
					...state.userSettingsWithError,
					[key]: isError
				},
				actionButtonTexts: newActionButtonTexts
			}
		}

		case VALIDATION_STATE_CHANGED: {
			const { validationType, propValue, inputKey } = action.payload
			return { ...state, [`${validationType}State_${inputKey}`]: propValue }
		}

		case COST_AND_LEAD_ACTION_BUTTON_TEXT_CHANGED: {
			const { actionButtonText, key } = action.payload
			const actionButtonTexts = {
				...state.actionButtonTexts,
				[key]: actionButtonText
			}
			return { ...state, actionButtonTexts }
		}

		case CUSTOMIZE_PRINTER_PROPS_SETUPED:
			const { printerCompanies } = action.payload
			return { ...state, printerCompanies }

		case CUSTOMIZE_PRINTER_PROPS_COMPANY_CHANGED:
			const { printerNames, printerCompany } = action.payload
			return {
				...state,
				printerNames,
				selectedPrinterCompany: printerCompany,
				selectedPrinter: {},
				selectedPrinterName: '',
				selectedPrinterCost: '',
				selectedPrinterHourlyCost: '',
				defaultPrinterCost: '',
				defaultLayerThickness: '',
				selectedPrinterLayerThickness: '',
				disablePrinterPropsButton: true,
				iincorrectEntryForLayerThicknessnCorrectEntryForLayerThickness: false,
				incorrectEntryForPrice: false,
				incorrectEntryForHourlyPrice: false
			}
		case CUSTOMIZE_PRINTER_PARAMETERS_CHANGED:
			const { printerCostParameters } = action.payload
			return {
				...state,
				selectedPrinterCostParameter: printerCostParameters
			}

		case CUSTOMIZE_PRINTER_PROPS_PRINTER_SELECTED:
			const { selectedPrinter } = action.payload
			return {
				...state,
				selectedPrinter,
				selectedPrinterCompany: selectedPrinter?.company || '',
				selectedPrinterName: selectedPrinter?.name || '',
				defaultPrinterCost: selectedPrinter?.cost || '',
				defaultLayerThickness: selectedPrinter?.defaultLayerThickness || '',
				disablePrinterPropsButton:
					state.incorrectEntryForPrice ||
					state.incorrectEntryForLayerThickness ||
					!state.selectedPrinterCost ||
					!state.selectedPrinterHourlyCost
			}

		case CUSTOMIZE_PRINTER_PROPS_PRINTER_PRICE_CHANGED: {
			const { selectedPrice, selectedPrinterCostParameter } = action.payload
			const zeroForEntry = selectedPrice === ''

			const incorrectEntryForPrice =
				(selectedPrice < CUSTOMIZE_MINIMUM_PRINTER_PRICE ||
					selectedPrice > CUSTOMIZE_MAXIMUM_PRINTER_PRICE) &&
				!zeroForEntry &&
				selectedPrinterCostParameter === PRINTING_COST_PARAMETERS[1]

			const incorrectEntryForHourlyPrice =
				(selectedPrice < CUSTOMIZE_MINIMUM_PRINTER_HOURLY_PRICE ||
					selectedPrice > CUSTOMIZE_MAXIMUM_PRINTER_HOURLY_PRICE) &&
				!zeroForEntry &&
				selectedPrinterCostParameter === PRINTING_COST_PARAMETERS[0]

			const valuesForSelectedThickness =
				state.selectedPrinterLayerThickness === '' ||
				state.selectedPrinterLayerThickness

			return {
				...state,
				incorrectEntryForPrice: incorrectEntryForPrice,
				incorrectEntryForHourlyPrice: incorrectEntryForHourlyPrice,
				selectedPrinterCost:
					selectedPrinterCostParameter === PRINTING_COST_PARAMETERS[1]
						? selectedPrice
						: '',
				selectedPrinterHourlyCost:
					selectedPrinterCostParameter === PRINTING_COST_PARAMETERS[0]
						? selectedPrice
						: '',
				disablePrinterPropsButton: shouldPrinterButtonDisable(
					state.selectedPrinter.printerId,
					valuesForSelectedThickness,
					incorrectEntryForPrice,
					incorrectEntryForHourlyPrice,
					state.incorrectEntryForLayerThickness,
					zeroForEntry
				)
			}
		}

		case CUSTOMIZE_PRINTER_SETTINGS_DELETED: {
			const { userPrinterSettingsData } = action.payload
			return {
				...state,
				userPrinterSettingsData
			}
		}

		case CUSTOMIZE_PRINTER_PROPS_LAYER_THICKNESS_CHANGED: {
			const { selectedThickness } = action.payload
			const incorrectEntryForLayerThickness =
				(selectedThickness < CUSTOMIZE_MINIMUM_LAYER_THICKNESS ||
					selectedThickness > CUSTOMIZE_MAXIMUM_LAYER_THICKNESS) &&
				selectedThickness !== ''

			return {
				...state,
				selectedPrinterLayerThickness: selectedThickness,
				incorrectEntryForLayerThickness,
				disablePrinterPropsButton: shouldPrinterButtonDisable(
					state.selectedPrinter.printerId,
					state.selectedPrinterCost,
					state.selectedPrinterHourlyCost,
					incorrectEntryForLayerThickness,
					state.incorrectEntryForPrice,
					state.incorrectEntryForHourlyPrice
				)
			}
		}

		case CUSTOMIZE_USER_SETTINGS_ADDED:
			const { userPrinterSettingsData } = action.payload
			return {
				...state,
				userPrinterSettingsData,
				defaultLayerThickness: '',
				selectedPrinterLayerThickness: '',
				defaultPrinterCost: '',
				selectedPrinterCost: '',
				selectedPrinterHourlyCost: '',
				selectedPrinterName: '',
				selectedPrinterCompany: '',
				selectedPrinterCostParameter: PRINTING_COST_PARAMETERS[0],
				disablePrinterPropsButton: true,
				selectedPrinter: {},
				userPrinterSettingsLoading: false
			}

		case CUSTOMIZE_PRINTER_SETTINGS_BUTTON_LOADER_TOGGLED:
			return {
				...state,
				userPrinterSettingsLoading: !state.userPrinterSettingsLoading
			}

		case DRAWING_COST_EDIT_TOGGLE: {
			const { isEditButton, parameterKey } = action.payload
			const changedDrawingCost2dNames = cloneDeep(state.drawingCost2dNames)
			changedDrawingCost2dNames[parameterKey].edit = isEditButton
			let isEditAllChange = true
			for (const key in changedDrawingCost2dNames) {
				if (changedDrawingCost2dNames[key].edit === false) {
					isEditAllChange = false
				}
			}
			return {
				...state,
				drawingCost2dNames: changedDrawingCost2dNames,
				isEditAllDrawingCost: isEditAllChange
			}
		}

		case DRAWING_COST_EDIT_ALL_TOGGLE: {
			const { isEditButton } = action.payload
			return {
				...state,
				isEditAllDrawingCost: isEditButton
			}
		}

		case DRAWING_COST_APPLY_TOGGLE: {
			const { isApplyButton, parameterKey } = action.payload
			const changedDrawingCost2dNames = cloneDeep(state.drawingCost2dNames)
			changedDrawingCost2dNames[parameterKey].apply = isApplyButton
			return {
				...state,
				drawingCost2dNames: changedDrawingCost2dNames
			}
		}

		case DRAWING_COST_UPDATE_DATA: {
			const { parameterKey, updateValue } = action.payload
			const changedUserSettings = cloneDeep(state.settingsData)
			changedUserSettings.userSettings[parameterKey] = updateValue
			return {
				...state,
				settingsData: changedUserSettings
			}
		}

		case DRAWING_COST_UPDATE_ALL_DATA: {
			const { drawingData } = action.payload
			const changedUserSettings = cloneDeep(state.settingsData)
			for (const parameterKey in drawingData) {
				changedUserSettings.userSettings[parameterKey] =
					drawingData[parameterKey]
			}

			const changedDrawingCost2dNames = cloneDeep(state.drawingCost2dNames)
			for (const parameterKey in state.drawingCost2dNames) {
				changedDrawingCost2dNames[parameterKey].edit = false
			}
			return {
				...state,
				settingsData: changedUserSettings,
				drawingCost2dNames: changedDrawingCost2dNames,
				isEditAllDrawingCost: false
			}
		}

		case DRAWING_COST_EDIT_DATA: {
			const { parameterKey, updateValue } = action.payload
			const changedDrawingCost2dNames = cloneDeep(state.drawingCost2dNames)
			changedDrawingCost2dNames[parameterKey].editInput = updateValue
			return {
				...state,
				drawingCost2dNames: changedDrawingCost2dNames
			}
		}

		case METADATA_COST_EDIT_TOGGLE: {
			const { isEditButton, parameterKey } = action.payload
			const changedMetadataNames = cloneDeep(state.metadataCostNames)
			changedMetadataNames[parameterKey].edit = isEditButton
			let isEditAllChange = true
			for (const key in changedMetadataNames) {
				if (changedMetadataNames[key].edit === false) {
					isEditAllChange = false
				}
			}
			return {
				...state,
				metadataCostNames: changedMetadataNames,
				isEditAllMetadataCost: isEditAllChange
			}
		}

		case METADATA_COST_EDIT_ALL_TOGGLE: {
			const { isEditButton } = action.payload
			return {
				...state,
				isEditAllMetadataCost: isEditButton
			}
		}

		case METADATA_COST_APPLY_TOGGLE: {
			const { isApplyButton, parameterKey } = action.payload
			const changedMetadataNames = cloneDeep(state.metadataCostNames)
			changedMetadataNames[parameterKey].apply = isApplyButton
			return {
				...state,
				metadataCostNames: changedMetadataNames
			}
		}

		case METADATA_COST_UPDATE_DATA: {
			const { parameterKey, updateValue } = action.payload
			const changedUserSettings = cloneDeep(state.settingsData)
			changedUserSettings.userSettings[parameterKey] = updateValue
			return {
				...state,
				settingsData: changedUserSettings
			}
		}

		case METADATA_COST_UPDATE_ALL_DATA: {
			const { metadataData } = action.payload
			const changedUserSettings = cloneDeep(state.settingsData)
			for (const parameterKey in metadataData) {
				changedUserSettings.userSettings[parameterKey] =
					metadataData[parameterKey]
			}

			const changedMetadataNames = cloneDeep(state.metadataCostNames)
			for (const parameterKey in state.metadataCostNames) {
				changedMetadataNames[parameterKey].edit = false
			}
			return {
				...state,
				settingsData: changedUserSettings,
				metadataCostNames: changedMetadataNames,
				isEditAllMetadataCost: false
			}
		}

		case METADATA_COST_EDIT_DATA: {
			const { parameterKey, updateValue } = action.payload
			const changedMetadataNames = cloneDeep(state.metadataCostNames)
			changedMetadataNames[parameterKey].editInput = updateValue
			return {
				...state,
				metadataCostNames: changedMetadataNames
			}
		}

		case SETUP_PRINTER_COSTS: {
			const { editablePrinterCostsList, deletablePrinterCostsList } =
				action.payload
			return {
				...state,
				editablePrinterCostsList,
				deletablePrinterCostsList
			}
		}

		case EDITABLE_PRINTER_COSTS_CHANGE: {
			const { editablePrinterCostsList } = action.payload
			return {
				...state,
				editablePrinterCostsList
			}
		}

		default:
			return state
	}
}

export default costAndLeadReducer
