import React, { FC, memo } from 'react'

import { uniqBy } from 'lodash'

import {
	getProducingLeadTime,
	isCostObjectValid
} from '../SolutionAnalysisTopService'
import { BenefitsDetails, TimeBenefitsIcon } from './TopBenefits'
import TopCostBody from './TopCostBody'
import TopCostDetailsIcon from './TopCostDetailsIcon'
import TopMaterialInformation from './TopMaterialInformation'
import TopNoConfigurationResults from './TopNoConfigurationResults'
import TopRetrieveResults from './TopRetrieveResults'
import { TopTimeDetailsIcon } from './TopTimeDetailsIcon'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import DetailWithTitle from 'Scenes/Components/DetailWithTitle/DetailWithTitle'
import PrintersSelector from 'Scenes/Components/PrintersSelector/PrintersSelector'
import { IAlternativePrinters } from 'Services/models/AlternativePrinters'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IBenefit } from 'Services/models/IBenefit'
import { IChainBenefits } from 'Services/models/IChainBenefits'
import { ISolution } from 'Services/models/ISolution'
import { INCLUDES_POST_PROCESS, LEAD_TIME_LABEL } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface TopDetailsProps {
	solution: any
	onConfigureClick: Function
	onRetrieveResultClick: Function
	configurationId: number
	showAnalysis: boolean
	showSolutionDetails: boolean
	userCurrencySign: string
	timeBenefit?: IBenefit
	costDetails: any
	printersList: Array<IAlternativePrinters>
	customConfiguration: boolean
	loading: boolean
	isSpecifiedQuantity: boolean
	onPrinterChange: Function
	chainBenefits: IChainBenefits
	isCluster?: boolean
	includeSupplyChainCosts?: boolean
	firstParts?: number
	showCostInRange?: boolean
	drawingCostPercentage?: number
	disableAlternativeSolution?: boolean
	productLifeCycle?: string
	traditionalCostCalculatedCustom?: boolean
	printingStandards?: string | null
	isWRConfig: boolean
}

export const TopDetails: FC<TopDetailsProps> = ({
	solution,
	onConfigureClick,
	onRetrieveResultClick,
	configurationId,
	showAnalysis,
	showSolutionDetails,
	userCurrencySign,
	timeBenefit,
	costDetails,
	printersList,
	customConfiguration,
	loading,
	onPrinterChange,
	chainBenefits,
	isCluster,
	includeSupplyChainCosts,
	firstParts,
	showCostInRange,
	drawingCostPercentage,
	disableAlternativeSolution,
	productLifeCycle,
	isSpecifiedQuantity,
	traditionalCostCalculatedCustom,
	printingStandards,
	isWRConfig
}) => {
	if (!solution) {
		const showNoConfigurationContent = Feature.isFeatureOn(
			FeatureComponentId.SHOW_NO_CONFIGURATION_CONTENT
		)

		if (!showNoConfigurationContent) return <></>

		return (
			<TopNoConfigurationResults
				onConfigureClick={onConfigureClick}
				isCluster={isCluster}
				configurationId={configurationId}
			/>
		)
	}

	const {
		leadTime,
		leadTimeDetails,
		printerMaterial,
		printerTechnology,
		printer,
		costCalculatedWithCustomFunction,
		costCalculatedWithOverrideFunction
	} = solution as ISolution
	const costObjectValid: boolean = isCostObjectValid(costDetails, isCluster)
	const postProcessesOn = costDetails?.optionalPostProcesssesCost >= 1
	const isLeadTime = !!leadTimeDetails?.firstLeadTime
	const showRetrieveResults = Feature.isFeatureOn(
		FeatureComponentId.RETRIEVE_RESULTE_UNPRINTABLE
	)
	const defaultPrinter = {
		...printer,
		technology: printerTechnology?.userReadableName
	}
	const anyValueIsCustomCalculated = costCalculatedWithCustomFunction
		? Boolean(
				Object.values(costCalculatedWithCustomFunction).some(item => item) ||
					traditionalCostCalculatedCustom
		  )
		: false

	const renderBody = () => {
		if (!isSpecifiedQuantity) {
			return (
				<div className="detail-body--cost lead">
					<div className="detail-body--cost--secondary">
						<span>{getString('LEAD_TIME_NOT_AVAILABLE')}</span>
					</div>
				</div>
			)
		}
		if (isLeadTime) {
			return (
				<div className="detail-body--cost lead">
					<div>
						{leadTimeDetails?.firstLeadTime}{' '}
						{getString('CONFIGURATION_RESULTS_DAYS')}
					</div>
					<div className="detail-body--cost--secondary">
						<span>{getString('FIRST_SHIPMENT_TEXT')}</span>
					</div>
				</div>
			)
		}

		return `${getProducingLeadTime(leadTime, leadTimeDetails)} ${getString(
			'CONFIGURATION_RESULTS_DAYS'
		)}`
	}

	if (!showAnalysis && showRetrieveResults) {
		return (
			<TopRetrieveResults
				configurationId={configurationId}
				onRetrieveResultClick={onRetrieveResultClick}
			/>
		)
	}
	const renderCostExtraBodyText = (postProcessesOn: boolean) => {
		if (!postProcessesOn) {
			return ''
		}
		return (
			<div className="detail-body--cost--extra-body">
				{INCLUDES_POST_PROCESS}
			</div>
		)
	}

	const isShowValuesInRanges = Feature.isFeatureOn(
		FeatureComponentId.SHOW_VALUES_IN_RANGES
	)

	return (
		<>
			<DetailWithTitle
				title={
					<div className="detail-title--wrapper">
						{getString('CUSTOMIZE_FORM_COST_AND_LEAD_HEADER')}
						{anyValueIsCustomCalculated && (
							<DetailsPopup
								data={getString('CUSTOM_COST_ESTIMATION_HOVER_TEXT')}
								popperDirection="right"
								isHover={anyValueIsCustomCalculated}
								popperClassName="info-box-wrapper"
								popperContactClassName="info-box-data"
								targetClassName="detail-title--star"
							>
								<span data-qa="data-qa-cost-customized-star">*</span>
							</DetailsPopup>
						)}
					</div>
				}
				body={
					<TopCostBody
						configurationId={configurationId}
						userCurrencySign={userCurrencySign}
						costDetails={costDetails}
						chainBenefits={chainBenefits}
						includeSupplyChainCosts={includeSupplyChainCosts}
						showCostInRange={showCostInRange}
						drawingCostPercentage={drawingCostPercentage}
						productLifeCycle={productLifeCycle}
						isSpecifiedQuantity={isSpecifiedQuantity}
						costCalculatedWithCustomFunction={costCalculatedWithCustomFunction}
					/>
				}
				extraBodyClassText={
					isShowValuesInRanges ? '' : renderCostExtraBodyText(postProcessesOn)
				}
				bodyClassName="detail-body detail-body--cost--wrapper"
				titleWrapperClassName="title-wrapper"
				className="with-frame"
			>
				<TopCostDetailsIcon
					costDetails={costDetails}
					printingStandards={printingStandards}
					costObjectValid={costObjectValid}
					userCurrencySign={userCurrencySign}
					drawingCostPercentage={drawingCostPercentage}
					showCostInRange={showCostInRange}
					isSpecifiedQuantity={isSpecifiedQuantity}
					costCalculatedWithCustomFunction={costCalculatedWithCustomFunction}
					costCalculatedWithOverrideFunction={
						costCalculatedWithOverrideFunction
					}
					configurationId={configurationId}
					isWRConfig={isWRConfig}
				/>
			</DetailWithTitle>
			<DetailWithTitle
				title={LEAD_TIME_LABEL}
				body={renderBody()}
				bodyClassName="detail-body"
				titleWrapperClassName="title-wrapper"
				className="with-frame"
			>
				{!isLeadTime && (
					<DetailsPopup
						data={<BenefitsDetails benefit={timeBenefit} />}
						popperDirection="left"
						isHover={true}
						popperClassName="detail-popper"
						targetClassName={`${leadTimeDetails ? 'time-benefit-target' : ''}`}
					>
						<TimeBenefitsIcon
							showSolutionDetails={showSolutionDetails}
							timeBenefit={timeBenefit}
						/>
					</DetailsPopup>
				)}

				<TopTimeDetailsIcon
					leadTimeDetails={leadTimeDetails}
					firstParts={firstParts}
					isLeadTime={isLeadTime}
					isSpecifiedQuantity={isSpecifiedQuantity}
				/>
			</DetailWithTitle>
			<PrintersSelector
				onChange={onPrinterChange}
				printersList={uniqBy(printersList, 'id')}
				selectedPrinter={(printersList && printersList[0]) || defaultPrinter}
				disable={customConfiguration || loading || disableAlternativeSolution}
				isCluster={isCluster}
				showCostInRange={showCostInRange}
			/>
			<TopMaterialInformation
				showCostInRange={showCostInRange}
				printerMaterial={printerMaterial}
			/>
		</>
	)
}

export default memo(TopDetails)
