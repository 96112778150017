import React, { FC } from 'react'

import { ReactComponent as Unprintable } from 'assets/img/svg/unprintable.svg'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import {
	ReactComponent as CircleWarning
} from 'assets/img/svg/icons/circle-warn.svg'

interface Props {}

export const IconUnprintable: FC<Props> = (props: any) => {
	let isCircle = Feature.isFeatureOn(
		FeatureComponentId.SHOW_WARNING_IN_CIRCLE)

	if (isCircle) {
		return (
			<CircleWarning {...props} />
		)
	}
	return (
		<Unprintable {...props} />
	)
}
