import * as Strings from './Strings'

export class LocalError {
  constructor(errorCode, errorMessage) {
    this.code = errorCode
    this.message = errorMessage
  }
}
//authentication
export const EMAIL_NOT_ENTERED = new LocalError(300, Strings.EMAIL_NOT_ENTERED)
export const PASSWORD_NOT_ENTERED = new LocalError(
  301,
  Strings.PASSWORD_NOT_ENTERED
)
export const PASSWORD_NOT_MATCHING = new LocalError(
  302,
  Strings.PASSWORD_NOT_MATCHING
)
export const PASSWORD_INVALID = new LocalError(311, Strings.PASSWORD_INVALID)
export const EMAIL_INVALID = new LocalError(303, Strings.EMAIL_INVALID)
export const NAME_NOT_ENTERED = new LocalError(304, Strings.NAME_NOT_ENTERED)
export const MUST_AGREE_TO_TURMS = new LocalError(
  305,
  Strings.MUST_AGREE_TO_TURMS
)
export const COMPANY_NOT_ENTERED = new LocalError(
  309,
  Strings.MISSING_COMPANY_NAME
)
export const ADDRESS_NOT_VALID = new LocalError(
  309,
  Strings.USER_PROFILE_ADDRESS_ALERT
)
export const ORGANIZATION_NOT_ENTERED = new LocalError(
  309,
  'organization missing'
)

export const COUNTRY_NOT_VALID = new LocalError(309, Strings.COUNTRY_NOT_VALID)

export const STATE_NOT_VALID = new LocalError(309, Strings.STATE_NOT_VALID)

//upload project
export const UPLOAD_FAILED_ERROR = new LocalError(306, Strings.UPLOAD_FAILED)
export const MISSING_PROJECT_NAME_ERROR = new LocalError(
  307,
  Strings.MISSING_PROJECT_NAME
)
export const MISSING_PROJECT_QUANTITY_ERROR = new LocalError(
  312,
  Strings.MISSING_PROJECT_QUANTITY
)
export const MISSING_PROJECT_WRONG_QUANTITY_ERROR = new LocalError(
  312,
  Strings.MISSING_PROJECT_WRONG_QUANTITY
)
export const MISSING_PROJECT_UPLOAD_TYPE = new LocalError(
  310,
  Strings.MISSING_PROJECT_UPLOAD_TYPE
)
export const MISSING_PROJECT_FILES_ERROR = new LocalError(
  308,
  Strings.MISSING_PROJECT_FILES
)
export const MISSING_ERP_NAME_ERROR = new LocalError(
  306,
  Strings.MISSING_ERP_NAME
)
export const MISSING_CAD_NAME_ERROR = new LocalError(
  306,
  Strings.MISSING_CAD_NAME
)
export const MISSING_UNIT_TYPE_ERROR = new LocalError(
  308,
  Strings.UPLOAD_UNIT_TYPE_EXPLENATION
)
export const MISSING_BOM_FILE_OR_MATERIAL_ERROR = new LocalError(
  308,
  Strings.MISSING_BOM_OR_MATERIAL
)
export const MISSING_CAD_FILE_ERROR = new LocalError(
  308,
  Strings.MISSING_CAD_FILES
)
export const MISSING_DRAWING_FILE_OR_MATERIAL_ERROR = new LocalError(
  308,
  Strings.MISSING_DRAWING_OR_MATERIAL
)
export const MISSING_DRAWING_MATERIAL_OR_MATERIAL_ERROR = new LocalError(
  308,
  Strings.MISSING_MATERIAL
)
export const PART_FILE_NOT_IN_ENGLISH_ERROR = new LocalError(
  309,
  Strings.PART_FILE_NOT_IN_ENGLISH
)
