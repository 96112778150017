import React, { ChangeEvent, FC, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'

import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import CastorMaterialSelector from '../../../Components/CastorMaterialSelector/CastorMaterialSelector'
import { Button } from '../../../Components/thirdParty/CreativeTim/components'
import { getSelectedMaterial } from './CastorMaterialNamesMappingService'
import Flexbox from 'Scenes/Components/FlexBox'
import {
	openMaterialsNamePopup,
	sendMaterialsNameBom,
	uploadMaterialsNameBom
} from 'Scenes/Home/Customize/CustomizeActions'
import AddMultipleMaterialsPopup from 'Scenes/Home/Customize/CustomizeMaterialNamesMapping/AddMultipleMaterialsPopup'
import { isEnterKeyPressed } from 'Services/getKeyCodesService'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Material } from 'Services/models/IMaterial'
import { getString } from 'Services/Strings/StringService'

import './CustomizeMaterialNamesMapping.scss'

interface IProps {
	defaultMaterial: Material
	materialCategories: any
	materialTypes: string[]
	materials: Material[]
	disabled: boolean
	updateSelectedMaterial: Function
}

const DividerTSX: any = Divider

const AddMaterialNamesMapping: FC<IProps> = ({
	defaultMaterial,
	materialCategories,
	materialTypes,
	materials,
	disabled,
	updateSelectedMaterial
}) => {
	const dispatch = useDispatch()
	const {
		fileInputKey,
		BOMMaterialsFile,
		isMaterialsNameLoading,
		isOpenMaterialsNamePopup
	} = useSelector((state: RootStateOrAny) => state.CustomizeReducer)
	const [expression, setExpression] = useState('')
	const [selectedMaterial, setSelectedMaterial] = useState<
		Material | undefined
	>(defaultMaterial)
	const [disableAddButton, setDisableAddButton] = useState(true)
	const isMultipleNamesOn = Feature.isFeatureOn(
		FeatureComponentId.MULTIPLE_MATERIALS_NAME
	)
	const saveInputFromUser = (e: any) => {
		setExpression(e)
		setDisableAddButton(false)
	}

	const onChangeSelector = (property: string, value: any) => {
		const _selectedMaterial = getSelectedMaterial(
			materials,
			materialCategories,
			property,
			value
		)
		setSelectedMaterial(_selectedMaterial)
	}

	const onAddClick = () => {
		if (!disableAddButton) {
			updateSelectedMaterial(
				{
					expression,
					material: selectedMaterial,
					active: true
				},
				onChangeSelector
			)
		}
		setExpression('')
		setDisableAddButton(true)
	}

	const onConfirmMultipleMaterials = () => {
		dispatch(sendMaterialsNameBom(BOMMaterialsFile))
	}

	const onCancelMultipleMaterials = () => {
		dispatch(openMaterialsNamePopup(false))
		dispatch(uploadMaterialsNameBom(null))
	}

	return (
		<div data-qa="data-qa-add-custom-material">
			<DividerTSX className="custom-material-names--divider" />
			<div className="custom-material-names--title">
				{getString('CUSTOMIZE_MATERIAL_NAMES_MAPPING_ADD_MATERIAL_TITLE')}
			</div>
			<div className="custom-material-names--explanation">
				<div>
					{getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_ADD_BY_LIST_EXPLANATION'
					)}
				</div>
				{!!isMultipleNamesOn && (
					<div>
						<Button
							style={{
								padding: '5px 20px',
								width: 'unset'
							}}
							color="primary"
							className="custom-material-names--AddButton"
							onClick={() => dispatch(openMaterialsNamePopup(true))}
							data-qa="data-qa-add-multiple-materials-btn"
						>
							{getString('ADD_MULTIPLE_MATERIALS')}
						</Button>
					</div>
				)}
			</div>
			<div>
				<Flexbox alignItems="center">
					<TextField
						type="input"
						className="custom-material-names text"
						data-qa="data-qa-custom-material-name-input"
						value={expression}
						variant="standard"
						placeholder={getString('ENTER_EXPRESSION')}
						onChange={(e: ChangeEvent<HTMLInputElement>) => [
							saveInputFromUser(e.target.value)
						]}
						onKeyUp={(e: any) => {
							if (isEnterKeyPressed(e)) {
								onAddClick()
							}
						}}
					/>
					<CastorMaterialSelector
						onChangeMaterial={onChangeSelector}
						materialCategories={materialCategories}
						materials={materials}
						disabled={disabled}
						materialTypes={materialTypes}
						selectedMaterial={selectedMaterial}
					/>
					<ButtonWithLoader
						size={20}
						style={{
							padding: '5px 20px',
							width: 'unset',
							maxWidth: '70px',
							minWidth: '70px'
						}}
						top={20}
						className="custom-material-names--AddButton"
						onClick={onAddClick}
						disabled={disableAddButton}
						qaDataElementName="data-qa-add-custom-material-btn"
					>
						{getString('PART_ANALYSIS_CONFIGURE_FEATURE')}
					</ButtonWithLoader>
				</Flexbox>
			</div>
			<AddMultipleMaterialsPopup
				isOpen={isOpenMaterialsNamePopup}
				onConfirmMultipleMaterials={onConfirmMultipleMaterials}
				onCancelMultipleMaterials={onCancelMultipleMaterials}
				fileInputKey={fileInputKey}
				BOMMaterialsFile={BOMMaterialsFile}
				isMaterialsNameLoading={isMaterialsNameLoading}
				isMaterialsNames={true}
				isUserMaterialsNames={false}
				uploadBom={(file: File) => dispatch(uploadMaterialsNameBom(file))}
			/>
		</div>
	)
}

export default AddMaterialNamesMapping
