import React, { FC, memo } from 'react'

import cx from 'classnames'

import OverrideComponentHOC from 'themes/OverrideComponentHOC'

import './Devider.scss'

interface IProps {
	size: string
	vertical?: boolean
	className?: string
	text?: string
}

const CastorDevider: FC<IProps> = ({ vertical, className, size, text }) => {
	if (text) {
		return (
			<div className={cx('castor-devider--with-text', className)}>
				<div
					className={cx('castor-devider')}
					style={{
						width: vertical ? '1px' : size,
						height: vertical ? size : '1px'
					}}
				/>
				<div>{text}</div>
				<div
					className={cx('castor-devider')}
					style={{
						width: vertical ? '1px' : size,
						height: vertical ? size : '1px'
					}}
				/>
			</div>
		)
	}
	return (
		<div
			className={cx('castor-devider', className)}
			style={{
				width: vertical ? '1px' : size,
				height: vertical ? size : '1px'
			}}
		/>
	)
}

export default memo(OverrideComponentHOC(CastorDevider))
