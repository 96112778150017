import React, { FC, memo } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'

import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import { getString } from 'Services/Strings/StringService'

import '../SolutionAnalysis.scss'
import '../../MainPartAnalysis.scss'
import './SolutionConfigure.scss'

interface Props {
  onConfigureBackClick: Function
  onCalculateClick: Function
  loadingCalculation: boolean
  freezeConfiguration: boolean
  disableConfiguration: boolean
  disableCalculateButton: boolean
  enableSolutionButtons?: boolean
  configurationId: string | number
  disableResetAllButton?: boolean
  onResetClick: Function
}

const SolutionConfigureButtons: FC<Props> = ({
  onConfigureBackClick,
  onCalculateClick,
  loadingCalculation,
  enableSolutionButtons,
  freezeConfiguration,
  disableConfiguration,
  configurationId,
  onResetClick,
  disableCalculateButton,
  disableResetAllButton
}) => {
  const btnCalculateElementId = `part-analysis-calculate-button_${configurationId}`
  return (
    <Flexbox alignItems="center">
      <Button
        disabled={
          freezeConfiguration || disableConfiguration || loadingCalculation || disableResetAllButton
        }
        color="transparent"
        onClick={onResetClick}
        className="part-analysis-button-text-transform solution-analysis-header-text configure-buttons"
      >
        <IconFactory
          iconName="resetAll"
          className="solution-analysis-header-text-icon"
        />
        <div className="solution-analysis-header-text-text">
          {getString('PART_ANALYSIS_CONFIGURE_RESET')}
        </div>
      </Button>
      <Button
        color="transparent"
        onClick={onConfigureBackClick}
        className="part-analysis-button-text-transform solution-analysis-header-text configure-buttons middle-button"
      >
        <IconFactory
          iconName="leftArrow"
          className="solution-analysis-header-text-icon"
        />
        <div className="solution-analysis-header-text-text">
          {getString('PART_ANALYSIS_CONFIGURE_BACK')}
        </div>
      </Button>
      <ButtonWithLoader
        disabled={
          freezeConfiguration || disableConfiguration || disableCalculateButton
        }
        id={btnCalculateElementId}
        loading={loadingCalculation}
        color="primary"
        onClick={(e: any) => onCalculateClick(e, btnCalculateElementId)}
        className="part-analysis-header-button part-analysis-button-text-transform calculate-button"
        muiClasses={{
          label: 'part-analysis-button-label',
          root: 'part-analysis-button-root'
        }}
      >
        {getString('PART_ANALYSIS_CONFIGURE_CALCULATE')}
      </ButtonWithLoader>
    </Flexbox>
  )
}

export default memo(SolutionConfigureButtons)
