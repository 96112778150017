import React, { FC, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'

import {
	Collapse,
	List,
	ListItem,
	ListItemIcon,
	ListItemText
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import cx from 'classnames'

import { Route } from '../SideBarTypes'
import { useMouseBoundary } from './DraggableHooks'
import ProjectsListWrapper from './ProjectsListWrapper'
import { onUserFolderDelete } from 'global actions'
import { FOLDER } from 'Services/Constants'

type IProps = {
	classes: Record<string, any>
	color: string
	caret: string
	rtlActive?: boolean
	collapseItemText: string
	itemIcon: string
	itemText: string
	state: {
		[key: string]: boolean
	}
	view: Route
	viewKey: number
	currentPathname: string
	nestLevel?: number
	isDropDisabled?: boolean
	disableDrag?: boolean
	setIsDropDisabled?: (disabled: boolean) => void
	activeRoute: (routeName: string, folderId?: string) => boolean
	openCollapse: (collapse: string) => void
}

const DraggableFolder: FC<IProps> = ({
	color,
	classes,
	caret,
	rtlActive,
	collapseItemText,
	itemIcon,
	itemText,
	state,
	view,
	viewKey,
	currentPathname,
	nestLevel = 0,
	isDropDisabled,
	disableDrag,
	activeRoute,
	openCollapse,
	setIsDropDisabled
}) => {
	const [disableFolder, setDisableFolder] = useState(true)
	const dispatch = useDispatch()
	const navLinkClasses = cx(classes.collapseItemLink)

	const onFolderDeleteClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()
		dispatch(onUserFolderDelete(view.id))
	}

	const Icon: JSX.Element | any = view.icon

	const onFolderMouseDown = () => {
		if (setIsDropDisabled) {
			setIsDropDisabled(true)
		}
	}

	const onFolderMouseUp = () => {
		if (setIsDropDisabled && isDropDisabled) {
			setIsDropDisabled(false)
		}
		openCollapse(view.state)
	}

	useMouseBoundary({
		id: view.id,
		onEnter: () => {
			setDisableFolder(false)
		},
		onLeave: () => {
			setDisableFolder(true)
		},
		isDropDisabled
	})

	return (
		<Draggable
			draggableId={`${FOLDER}-${view.id}`}
			index={viewKey}
			key={`${FOLDER}-${view.id}`}
			isDragDisabled={!!disableDrag}
		>
			{(providedDraggable, snapshotDraggable) => (
				<div
					className={cx({ 'dragging-folder': snapshotDraggable.isDragging })}
					ref={providedDraggable.innerRef}
					{...providedDraggable.draggableProps}
					id={view.id}
				>
					<Droppable
						droppableId={view.id as string}
						key={view.id}
						isDropDisabled={disableFolder || isDropDisabled}
					>
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={cx('droppable-zone', {
									'dragging-over': snapshot.isDraggingOver
								})}
							>
								<ListItem className={cx('sidebar--user-folder')}>
									<NavLink
										to={'#'}
										className={cx(navLinkClasses, 'sidebar--user-folder--link')}
										onMouseDown={onFolderMouseDown}
										onMouseUp={onFolderMouseUp}
										{...providedDraggable.dragHandleProps}
									>
										<ListItemIcon className={itemIcon}>
											{view.icon ? <Icon /> : <></>}
										</ListItemIcon>
										<ListItemText
											title={view.name}
											primary={
												<div
													className="sidebar--user-folder--folder-name-wrapper"
													data-qa={`data-qa-sidebar-folder-${view.name}`}
												>
													<span>{view.name}</span>
													<ClearIcon
														data-qa={`data-qa-sidebar-remove-folder-${view.name}`}
														onClick={e => onFolderDeleteClick(e)}
														className={cx(
															'clear-input',
															'sidebar--user-folder--delete-icon'
														)}
													/>
												</div>
											}
											secondary={
												<b
													className={cx(caret, {
														'sidebar--user-folder--caret': true,
														[classes.caretActive]: state[view.state]
													})}
												/>
											}
											disableTypography={true}
											className={`${itemText} sidebar--text`}
											id={`sidebar-my-projects-folder-${view.id}`}
										/>
									</NavLink>
									<Collapse
										in={state[view.state]}
										unmountOnExit
										classes={{ root: 'sidebar--user-folder--nested-collapse' }}
									>
										<List
											className={cx(
												classes.list,
												classes.collapseList,
												'collapse-block__list',
												'sidebar--user-folder--list'
											)}
										>
											<ProjectsListWrapper
												list={view}
												listIndex={viewKey}
												caret={caret}
												state={state}
												itemIcon={itemIcon}
												itemText={itemText}
												classes={classes}
												color={color}
												rtlActive={rtlActive}
												collapseItemText={collapseItemText}
												activeRoute={activeRoute}
												openCollapse={openCollapse}
												currentPathname={currentPathname}
												disableDrag={disableDrag}
												nestLevel={nestLevel + 1}
											/>
											{provided.placeholder}
										</List>
									</Collapse>
								</ListItem>
							</div>
						)}
					</Droppable>
				</div>
			)}
		</Draggable>
	)
}

export default DraggableFolder
