import { IPartSolution } from './IPartSolution'

export interface ISolutionBenefit {
	id?: number
	type: benefitType
	name: string
	description: string
	partSolutionId: string
	partSolution: IPartSolution
	score: number
}

export enum benefitType {
	buyToFly = 'buy-to-fly',
	complexityScore = 'complexity-score',
	costSaving = 'cost-saving',
	timeSaving = 'time-saving',
	weightReduction = 'weight-reduction',
	co2Saving = 'co2-saving',
}
