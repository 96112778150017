import {
  getMaterialPrinterPairsAdmin,
  getMaterialPrinterPairAdmin,
  updateMaterialPrinterPairAdmin,
  deleteMaterialPrinterPairAdmin,
  createNewMaterialPrinterPairAdmin
} from '../../../../Services/Network'
import {
  GET_ADMIN_MATERIAL_PRINTER_PAIRS_GOT_ERROR,
  GET_ADMIN_MATERIAL_PRINTER_PAIRS_SUCCESS,
  GET_ADMIN_MATERIAL_PRINTER_PAIRS_EDIT_MATERIAL_PRINTER_PAIR_SELECTED,
  ADMIN_MATERIAL_PRINTER_PAIR_UPDATED,
  ADMIN_MATERIAL_PRINTER_PAIR_DELETED,
  ADMIN_MATERIAL_PRINTER_PAIR_DELETED_GOT_ERROR,
  ADMIN_MATERIAL_PRINTER_PAIR_ADDED,
  ADMIN_MATERIAL_PRINTER_PAIR_SEARCH_PHRASE_CHANGED,
  GET_ADMIN_MATERIALS
} from '../../../../global actions/types'
import {
  ADMIN_MATERIAL_PRINTER_PAIRS_UPDATE_SUCCESS,
  ADMIN_MATERIAL_PRINTER_PAIRS_UPDATE_ERROR,
  ADMIN_MATERIAL_PRINTER_PAIRS_ADDED_SUCCESS
} from '../../../../Services/Strings'
import {
  setupAdminPage,
  selectItemToEdit,
  updateItem,
  deleteItem,
  createNewItem
} from '../adminActionsService'

export const setupAdminMaterialPrinterPairsPage = (
  searchPhrase,
  pageNumber,
  limitMaterialPrinterPairs
) => {
  return dispatch => {
    setupAdminPage(
      dispatch,
      getMaterialPrinterPairsAdmin,
      [searchPhrase, pageNumber, limitMaterialPrinterPairs],
      GET_ADMIN_MATERIAL_PRINTER_PAIRS_SUCCESS,
      GET_ADMIN_MATERIAL_PRINTER_PAIRS_GOT_ERROR,
      pageNumber
    )

    dispatch({ type: GET_ADMIN_MATERIALS })
  }
}

export const setSearchPhrase = searchPhrase => {
  return {
    type: ADMIN_MATERIAL_PRINTER_PAIR_SEARCH_PHRASE_CHANGED,
    payload: searchPhrase
  }
}

export const selectMaterialPrinterPairToEdit = (
  materialPrinterPairId,
  materialPrinterPairs
) => {
  return dispatch => {
    selectItemToEdit(
      dispatch,
      materialPrinterPairId,
      materialPrinterPairs,
      getMaterialPrinterPairAdmin,
      [materialPrinterPairId],
      GET_ADMIN_MATERIAL_PRINTER_PAIRS_EDIT_MATERIAL_PRINTER_PAIR_SELECTED,
      GET_ADMIN_MATERIAL_PRINTER_PAIRS_GOT_ERROR,
      'materialPrinterPair'
    )
  }
}

export const updateMaterialPrinterPair = data => {
  return dispatch => {
    updateItem(
      dispatch,
      updateMaterialPrinterPairAdmin,
      data,
      ADMIN_MATERIAL_PRINTER_PAIR_UPDATED,
      'materialPrinterPairUpdated',
      ADMIN_MATERIAL_PRINTER_PAIRS_UPDATE_SUCCESS,
      ADMIN_MATERIAL_PRINTER_PAIRS_UPDATE_ERROR
    )
  }
}

export const deleteMaterialPrinterPair = id => {
  return dispatch => {
    deleteItem(
      dispatch,
      deleteMaterialPrinterPairAdmin,
      id,
      ADMIN_MATERIAL_PRINTER_PAIR_DELETED,
      ADMIN_MATERIAL_PRINTER_PAIR_DELETED_GOT_ERROR
    )
  }
}

export const createNewMaterialPrinterPair = materialPrinterPair => {
  return dispatch => {
    createNewItem(
      dispatch,
      createNewMaterialPrinterPairAdmin,
      materialPrinterPair,
      ADMIN_MATERIAL_PRINTER_PAIR_ADDED,
      'materialPrinterPairAdded',
      ADMIN_MATERIAL_PRINTER_PAIRS_ADDED_SUCCESS,
      ADMIN_MATERIAL_PRINTER_PAIRS_UPDATE_ERROR
    )
  }
}
