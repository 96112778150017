import {
  MOLDED_PART_PRICE_CHANGED,
  MOLD_PRICE_CHANGED,
  SUBMIT_FORM,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_FAILED,
  SETUP_FINANCIAL_ANALYSIS_COMPONENT,
  PART_COUNT_CHANGED,
  GO_TO_EDIT_MODE,
  MOLD_MAINTENANCE_PRICE_CHANGED,
  MINIMUM_ORDER_CHANGED,
  DFM_COSTS_CHANGED,
  FINANCIAL_ANALYSIS_COMPONENT_PLASTIC_SUBMITED,
  PART_COUNT_SUBMITED,
  EDIT_MODE_CANCELLED
} from '../../../../global actions/types'
import { toFixedOnlyIfNeeded } from '../../../../Services/global/toFixedOnlyIfNeeded'
import _ from 'lodash'

const INITIAL_STATE = {
  moldCost: 0,
  moldPartCost: 0,
  loading: false,
  error: null,
  formMode: true,
  chartData: null,
  costAffectivePoint: null,
  moldMaintenanceCost: 0,
  DFMCosts: 0,
  formParameters: {},
  animateChartData: false,
  showCancelButton: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUBMIT_FORM_SUCCESS:
      // const part = action.payload

      return setupComponent(state, action.payload, state.partCount, true)

    case SETUP_FINANCIAL_ANALYSIS_COMPONENT: {
      const { part, project } = action.payload
      const projectQuantity =
        project && project.quantity ? project.quantity : 100
      const countInAssembly =
        part && part.countInAssembly ? part.countInAssembly : 1
      return setupComponent(state, part, projectQuantity * countInAssembly)
    }
    case FINANCIAL_ANALYSIS_COMPONENT_PLASTIC_SUBMITED: {
      const {
        results,
        actualResult,
        actualQuantity,
        part,
        formParameters
      } = action.payload
      return createPlasticMoldChart(
        state,
        results,
        actualResult,
        actualQuantity,
        part,
        formParameters
      )
    }
    case MOLDED_PART_PRICE_CHANGED:
      return { ...state, moldPartCost: action.payload }

    case MOLD_PRICE_CHANGED:
      return { ...state, moldCost: action.payload }

    case MOLD_MAINTENANCE_PRICE_CHANGED:
      return { ...state, moldMaintenanceCost: action.payload }

    case DFM_COSTS_CHANGED:
      return { ...state, DFMCosts: action.payload }

    case PART_COUNT_SUBMITED:
      if (!state.part) {
        return { ...state }
      }
      return setupComponent(state, state.part, action.payload)

    case PART_COUNT_CHANGED:
      return {
        ...state,
        partCount: action.payload,
        animateChartData: false
      }

    case SUBMIT_FORM:
      return { ...state, loading: true, error: null }

    case SUBMIT_FORM_FAILED:
      return { ...state, loading: false, error: action.payload }
    case GO_TO_EDIT_MODE:
      return { ...state, formMode: true, showCancelButton: true }
    case EDIT_MODE_CANCELLED:
      const part = action.payload
      return {
        ...state,
        formMode: false,
        showCancelButton: false,
        moldCost: part.moldCost || '',
        moldPartCost: part.moldPartCost || '',
        moldMaintenanceCost: part.moldMaintenanceCost || '',
        DFMCosts: part.DFMCosts || ''
      }
    default:
      return state
  }
}

const setupComponent = (state, part, solutionQuantity) => {
  const formMode = part.moldCost == null || part.moldPartCost == null

  part.moldMaintenanceCost = part.moldMaintenanceCost || 0
  part.DFMCosts = part.DFMCosts || 0

  const allPartsCost = moldCostCalculation(part, solutionQuantity)

  const costAffectivePoint = allPartsCost / solutionQuantity
  const chartData = formMode
    ? null
    : createChartData(part, solutionQuantity, costAffectivePoint)
  const roundedCostAffectivePoint = Number(costAffectivePoint.toFixed(2))
  return {
    ...state,
    moldCost: part.moldCost || '',
    moldPartCost: part.moldPartCost || '',
    moldMaintenanceCost: part.moldMaintenanceCost || '',
    DFMCosts: part.DFMCosts || '',
    loading: false,
    error: null,
    formMode,
    part,
    chartData,
    animateChartData: !_.isEqual(chartData, state.chartData),
    costAffectivePoint: roundedCostAffectivePoint,
    formParameters: {}
  }
}

const createPlasticMoldChart = (
  state,
  results,
  actualResult,
  actualQuantity,
  part,
  formParameters
) => {
  const chartData = createCostChartData(results, actualResult, actualQuantity)
  return {
    ...state,
    moldCost: part.moldCost || '',
    moldPartCost: part.moldPartCost || '',
    moldMaintenanceCost: part.moldMaintenanceCost || '',
    DFMCosts: part.DFMCosts || '',
    formMode: false,
    loading: false,
    chartData,
    costAffectivePoint: toFixedOnlyIfNeeded(Number(actualResult)),
    error: null,
    partCount: actualQuantity,
    part,
    animateChartData: !_.isEqual(chartData, state.chartData),
    formParameters
  }
}

const createCostChartData = (results, actualResult, actualQuantity) => {
  let labels = Object.keys(results)
    .map(key => Number(key))
    .sort((a, b) => a - b)

  const molding = labels.map(label => results[label])

  const printCostSeries = labels.map(label =>
    label == actualQuantity ? actualResult : null
  )

  labels = labels.map(label => {
    if (actualQuantity < 20) {
      return toFixedOnlyIfNeeded(label)
    }
    return label.toFixed(0)
  })
  const series = [[], molding, [], [], printCostSeries]
  return { labels, series }
}

const createChartData = (part, count, costAffectivePoint) => {
  let labels = [
    count * 0.5,
    count * 0.6,
    count * 0.7,
    count * 0.8,
    count * 0.9,
    count,
    count * 1.1,
    count * 1.2,
    count * 1.3,
    count * 1.4,
    count * 1.5
  ]
  labels = labels.map(num => Number(num.toFixed(count < 20 ? 2 : 0)))
  const molding = labels.map(c => {
    const maintenacePresentage =
      (part.moldCost * part.moldMaintenanceCost) / 100
    const moldCost = part.moldCost + maintenacePresentage + part.DFMCosts
    return part.moldPartCost + moldCost / c
  })
  const printCostSeries = labels.map(label =>
    label == count ? costAffectivePoint : null
  )
  const series = [[], molding, [], [], printCostSeries]
  return { labels, series }
}

const moldCostCalculation = (part, count) => {
  const maintenacePresentage = (part.moldCost * part.moldMaintenanceCost) / 100
  return (
    part.moldCost +
    maintenacePresentage +
    part.DFMCosts +
    count * part.moldPartCost
  )
}
