import React, { FC, memo } from 'react'

import { Route } from '../SideBarTypes'
import DraggableFolder from './DraggableFolder'
import DraggableProject from './DraggableProject'
import { FOLDER } from 'Services/Constants'

type IProps = {
	list: Route
	listIndex: number
	caret: string
	state: {
		[key: string]: boolean
	}
	itemIcon: string
	itemText: string
	classes: Record<string, any>
	rtlActive?: boolean
	collapseItemText: string
	activeRoute: (routeName: string, folderId?: string) => boolean
	color: string
	openCollapse: (collapse: string) => void
	nestLevel?: number
	isDropDisabled?: boolean
	disableDrag?: boolean
	setIsDropDisabled?: (disabled: boolean) => void
	currentPathname: string
	isDraggingOver?: boolean
}

const ProjectsListWrapper: FC<IProps> = ({
	list,
	listIndex,
	caret,
	state,
	itemIcon,
	itemText,
	classes,
	rtlActive,
	collapseItemText,
	activeRoute,
	color,
	openCollapse,
	nestLevel,
	isDropDisabled,
	setIsDropDisabled,
	disableDrag,
	currentPathname
}) => {
	if (list.redirect) {
		return null
	}
	return (
		<>
			{list.views?.map((propView: Route, viewKey: number) => {
				if (propView?.userFolder) {
					return (
						<DraggableFolder
							caret={caret}
							state={state}
							itemIcon={itemIcon}
							itemText={itemText}
							classes={classes}
							color={color}
							rtlActive={rtlActive}
							collapseItemText={collapseItemText}
							activeRoute={activeRoute}
							openCollapse={openCollapse}
							view={propView}
							viewKey={viewKey}
							key={`${FOLDER}-${propView.id}`}
							currentPathname={currentPathname}
							nestLevel={nestLevel}
							isDropDisabled={isDropDisabled}
							setIsDropDisabled={setIsDropDisabled}
							disableDrag={disableDrag}
						/>
					)
				}
				return (
					<DraggableProject
						list={list}
						listIndex={listIndex}
						caret={caret}
						state={state}
						itemIcon={itemIcon}
						itemText={itemText}
						classes={classes}
						color={color}
						rtlActive={rtlActive}
						collapseItemText={collapseItemText}
						activeRoute={activeRoute}
						openCollapse={openCollapse}
						propView={propView}
						viewKey={viewKey}
						key={propView?.id}
						disableDrag={disableDrag}
					/>
				)
			})}
		</>
	)
}

export default memo(
	ProjectsListWrapper,
	(pevProps, nextProps) =>
		nextProps.isDraggingOver === pevProps.isDraggingOver &&
		nextProps.isDropDisabled === pevProps.isDropDisabled &&
		nextProps.state === pevProps.state &&
		nextProps.currentPathname === pevProps.currentPathname
)
