import { PART_ANALYSIS_TOP_DETAILS_COST_ESTIMATION_POP_UP_ROWS } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const [
	COST_ESTIMATION,
	PRINTING,
	POST_PROCESS,
	POST_PROCESSES,
	TOTAL,
	TOTAL_COST
] = PART_ANALYSIS_TOP_DETAILS_COST_ESTIMATION_POP_UP_ROWS

export enum OptionalPostProcessesIds {
	Dyeing = 1,
	Polishing = 2,
	HeatTreatment = 3,
	SurfaceAreaMachining = 4,
	SupportRemovalMachining = 5
}

export const POPUP_ROWS = {
	COST_ESTIMATION: COST_ESTIMATION,
	PRINTING: PRINTING,
	POST_PROCESS: POST_PROCESS,
	POST_PROCESSES: POST_PROCESSES,
	TOTAL: TOTAL,
	TOTAL_COST: TOTAL_COST
}

export const OPTIONAL_POST_PROCESSES = {
	1: getString('POST_PROCESSES_DYEING_LABEL'),
	2: getString('POST_PROCESSES_POLISHING_LABEL'),
	3: getString('POST_PROCESSES_HEAT_TREATMENT_LABEL'),
	4: getString('POST_PROCESSES_SURFACE_AREA_MACHINING_LABEL'),
	5: getString('POST_PROCESSES_SUPPORT_REMOVAL_LABEL')
}
