import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import CustomizeCO2EndOfLife from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2EndofLife/CustomizeCO2EndOfLife'
import {
	onDisposeFactorChange,
	onSetUserCustomizationSettings
} from 'Scenes/Home/Customize/CustomizeCO2Calculation/CustomizeCO2CalculationAction'
import { getString } from 'Services/Strings/StringService'

const EndOfLife: FC = () => {
	const dispatch = useDispatch()

	const { disposeFactorValue } = useSelector(
		(state: RootStateOrAny) => state.CustomizeCO2CalculationReducer
	)

	return (
		<div className="material-production--wrapper product-use" data-qa="data-qa-end-of-life-section">
			<div className="material-production--header">
				{getString('CO2_PARAMETERS_END_OF_LIFE_HEADER_TEXT')}
			</div>
			<CustomizeCO2EndOfLife
				setUserCustomzationSettings={(value: number, parameter: string) =>
					dispatch(onSetUserCustomizationSettings(value, parameter))
				}
				disposeFactorValue={disposeFactorValue}
				disposeFactorChange={(value: number) =>
					dispatch(onDisposeFactorChange(value))
				}
			></CustomizeCO2EndOfLife>
		</div>
	)
}

export default EndOfLife
