import React, { useEffect } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'

const MySimpleAlert = props => {
	useEffect(() => {
		if (props.show) {
			document.body.style.overflow = 'hidden'
		}
		return () => {
			document.body.style.overflow = 'unset'
		}
	}, [props.show])

	// it's needed for fixing an error while component renders
	// issue in console
	const emptyFunc = () => {}

	const onConfirm = () => {
		props?.onConfirm ? props.onConfirm() : emptyFunc()
	}

	const onCancel = () => {
		if (props?.preventCancel) return
		props?.onCancel ? props.onCancel() : emptyFunc()
	}

	return (
		<SweetAlert
			style={{ display: 'block', marginTop: '-300px' }}
			confirmBtnStyle={styles.successButton}
			cancelBtnStyle={styles.failedButton}
			{...props}
			onCancel={onCancel}
			onConfirm={onConfirm}
		>
			{props.children}
		</SweetAlert>
	)
}

const styles = {
	successButton: {
		fontSize: 16,
		color: '#ffffff',
		width: 120,
		height: 40,
		backgroundColor: '#5ab867',
		'&:hover': {
			backgroundColor: '#5ab867',
			boxShadow:
				'0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)'
		}
	},
	failedButton: {
		fontSize: 16,
		color: '#ffffff',
		width: 120,
		height: 40,
		backgroundColor: '#f4413f',
		'&:hover': {
			backgroundColor: '#f4413f',
			boxShadow:
				'0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)'
		}
	}
}

export default MySimpleAlert
