import React, { memo } from 'react'

import './SolutionPartDetails.scss'

import { Info } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { PART_ANALYSIS_WALL_THICKNESS_ANALYZE } from 'Services/Strings'
import IconLoaderGif from 'Scenes/Components/CastorIcons/IconLoaderGif'
import { OrientationData } from 'Services/models/IOrinetationData'
import { PART_IMAGES_TABS_INDEX } from 'Services/Constants'
import coordinateImage from 'assets/img/axes_watermark.png'
import { doesImageDontHaveCoordinates } from 'Services/Utils/ImagesTools'
import PartImageWithFallback from 'Scenes/Components/PartImageWithFallback/PartImageWithFallback'

const { ORIENTATION_TAB_INDEX } = PART_IMAGES_TABS_INDEX

interface Props {
  chosenOrientation: OrientationData | null
  showImageLoader: boolean
  solutionPartImageIndex: number
  rotatedOrientation: boolean
  formatType?: string
}

const SolutionPartOrientationImage: React.FC<Props> = ({
  chosenOrientation,
  showImageLoader,
  solutionPartImageIndex,
  rotatedOrientation,
  formatType
}: Props) => {
  const imageURL =
    (rotatedOrientation
      ? chosenOrientation?.rightAngleImageURL
      : chosenOrientation?.imageURL) || ''
  const renderImage = () => {
    if (showImageLoader) {
      return (
        <>
          <IconLoaderGif />
          <Info>{PART_ANALYSIS_WALL_THICKNESS_ANALYZE}</Info>
        </>
      )
    }

    return (
      <>
        {doesImageDontHaveCoordinates(imageURL) && (
          <img
            className="part-image-container-coordinate"
            src={coordinateImage}
            alt="..."
          />
        )}
        <PartImageWithFallback
          className="part-image-container-img"
          formatType={formatType}
          src={
            rotatedOrientation
              ? chosenOrientation?.rightAngleImageURL
              : chosenOrientation?.imageURL
          }
          alt="..."
        />
      </>
    )
  }

  return (
    <div
      className={`part-image-container-div part-image-container--orientation ${
        solutionPartImageIndex === ORIENTATION_TAB_INDEX ? 'show' : ''
      }`}
    >
      {renderImage()}
    </div>
  )
}

export default memo(SolutionPartOrientationImage)
