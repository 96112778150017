import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { STEP_ID } from '../../constants'
import { goToNextStep } from '../../OnboardingWizardActions'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { getString } from 'Services/Strings/StringService'

const CustomizeCostParamsLink: FC = () => {
	const { parentSteps, currentStep } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const dispatch = useDispatch()

	const onClick = () => {
		dispatch(
			goToNextStep(parentSteps, currentStep, STEP_ID.CUSTOMIZE_COST_PARAMS)
		)
	}
	return (
		<div className="cost-model--link-wrapper">
			<div className="cost-model--link-text-block">
				<h5>{getString('ONBOARDING_CUSTOMIZE_COST_PARAMS_LINK_HEADER')}</h5>
				<p>{getString('ONBOARDING_CUSTOMIZE_COST_PARAMS_LINK_DESCRIPTION')}</p>
			</div>
			<Button color="primaryNewStyle" onClick={onClick}>
				{getString('ONBOARDING_CONTINUE')}
			</Button>
		</div>
	)
}

export default CustomizeCostParamsLink
