import { extname } from 'path'
import { parse } from 'url'

export const getExtensionFromFileName = (
	fileName: string,
	allowNumberExtensionFeatureOn: boolean = true
): string => {
	const fileNameWithoutNumberInExtension = allowNumberExtensionFeatureOn
		? fileName.replace(/\.\d+$/, '')
		: fileName
	const regex = /(?:\.([^.]+))?$/
	return (regex.exec(fileNameWithoutNumberInExtension) || '')[1] || ''
}

export const getExtensionFromSigndURL = (fileUrl: string): string => {
	const parsed = parse(fileUrl || '')
	const ext = extname(parsed?.pathname || '')
	return ext
}
