import { put, post, del } from '../../Network'

export const updateProject = (projectId: string, project: any) => {
  const params = { project }
  return put(`/projects/admin/${projectId}`, params)
}

export const publishClusters = (projectId: string) =>
  put(`/unification/admin/publish/all/${projectId}`)

export const publishCluster = (clustertId: number) =>
  put(`/unification/admin/publish/${clustertId}`)

export const rejectCluster = (clustertId: number) =>
  put(`/unification/admin/reject/${clustertId}`)

export const removeCluster = (clustertId: number) =>
  del(`/unification/admin/${clustertId}`)

export const removeClusterParts = (
  clustertId: number,
  transformationMatrixHashs: string[]
) => {
  const params = { transformationMatrixHashs }
  return post(`/unification/admin/removeParts/${clustertId}`, params)
}
