import React, { FC, memo } from 'react'

import cx from 'classnames'
import { map } from 'lodash'

import UploadAmValue from './UploadAmValue'
import { uploadBlocksOrdering } from './UploadBlocksService'
import UploadCurrentMaterial from './UploadCurrentMaterial'
import UploadDetails from './UploadDetails'
import UploadEstimatedPart from './UploadEstimatedPart'
import UploadManufacturing from './UploadManufacturing'
import UploadType from './UploadType'
import { uploadBlocks } from 'Scenes/Home/NewUploadProject/UploadBlocks/UploadBlocksConstants'
import { getTheme } from 'themes/getTheme'

const { uploadProjectComponents, uploadBoxClass } = getTheme()

interface UploadBlocksProps {
	isDrawingFeatureOn?: boolean
	selectedType: string | null
	is2dUpload: boolean
	uploadTabIndex: number
	setSelectedType: (value: any) => void
}

const UploadBlocks: FC<UploadBlocksProps> = props => {
	const renderedUploadComponents = uploadBlocksOrdering(uploadProjectComponents)
	const uploadBlockNames = (name: string, props: UploadBlocksProps) => {
		switch (name) {
			case uploadBlocks.projectDetails:
				return <UploadDetails key={name} {...props} />
			case uploadBlocks.projectType:
				return <UploadType key={name} {...props} />
			case uploadBlocks.currentMaterial:
				return <UploadCurrentMaterial key={name} />
			case uploadBlocks.manufacturingType:
				return <UploadManufacturing key={name} />
			case uploadBlocks.estimatedPart:
				return <UploadEstimatedPart key={name} />
			case uploadBlocks.typeOfAM:
				return <UploadAmValue key={name} />
		}
	}
	return (
		<div
			className={cx(
				'multiple-params new-upload-project box-config',
				uploadBoxClass
			)}
		>
			{map(renderedUploadComponents, (data: { order: number; name: string }) =>
				uploadBlockNames(data.name, props)
			)}
		</div>
	)
}
export default memo(UploadBlocks)
