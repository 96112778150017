import { take } from 'lodash'
import moment from 'moment'

import { NotificationType } from './PushNotificationEnum'
import {
	ADMIN_ROUTE,
	ADMIN_USER_INFO_ROUTE,
	PROJECTS_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { getString } from 'Services/Strings/StringService'

export interface PushNotificationContent {
	createdAt: string
	id: number
	isViewed: boolean
	link: string
	messageEvent: string
	messageParams: Record<string, any>
	messageString: string
	messageText: string | null
	messageTitleString: string
	messageType: string
	updatedAt: string
	userId: number | string
}
export interface PushNotificationSilentContent {
	messageType: string
	bundleId: string | null
	projectId: string | null
	partId: number | null
	userId: number | null
	messageParams: {
		bundleId: string | null
		projectId: string | null
		totalParts: number
		projectStatus: string
		analyzingStatus: string | null
		statusPartsType: string
		numberOfPartsAnalyzed: number
		advancedFeaturesStatus: string | null
		reanalyzingStatus: string | null
	}
}

export const getNotificationMessage = (
	notification: PushNotificationContent,
	isCommunicationTool: boolean = false
) => {
	let titleString =
		getString(notification.messageTitleString) ||
		getString('DEFAULT_UPDATE_MESSAGE')
	let messageString = notification.messageText || ''
	let messageLink = notification.link || ''

	switch (notification.messageType) {
		case NotificationType.NOTIFICATION_PROJECT_UPDATED:
		case NotificationType.NOTIFICATION_PROJECT_PUBLISHED: {
			messageString = getString(notification.messageString).format(
				`<b>${notification.messageParams?.projectName}</b>`
			)
			break
		}
		case NotificationType.NOTIFICATION_PROJECT_ADMIN_PUBLISHED: {
			messageString = getString(notification.messageString).format(
				`<b>${notification.messageParams?.userName}</b>`,
				`<b>${notification.messageParams?.projectName}</b>`
			)
			break
		}
		case NotificationType.NOTIFICATION_PART_STATUS_CHANGED: {
			messageString = getString(notification.messageString).format(
				`<b>${notification.messageParams?.partName}</b>`,
				`<b>${notification.messageParams?.projectName}</b>`
			)

			if (isCommunicationTool) {
				const linkParts = notification.link?.split('/')
				const result = `${take(linkParts, 4).join('/')}`

				messageLink = notification.messageParams?.projectId
					? USER_HOME_ROUTE +
					  PROJECTS_ROUTE +
					  `/${notification.messageParams?.projectId}`
					: result
			}
			break
		}
		case NotificationType.NOTIFICATION_PART_CONSOLIDATION_UPDATED: {
			messageString = getString(notification.messageString).format(
				`<b>${notification.messageParams?.projectName}</b>`
			)
			break
		}
		case NotificationType.NOTIFICATION_NEW_USER_REGISTERED: {
			messageString = getString(notification.messageString).format(
				`<b>${notification.messageParams?.userName}</b>`
			)
			messageLink = notification.messageParams?.userId
				? ADMIN_ROUTE +
				  ADMIN_USER_INFO_ROUTE +
				  `/${notification.messageParams?.userId}`
				: ''
			break
		}
		case NotificationType.NOTIFICATION_SETTINGS_UPDATED: {
			messageString = getString(notification.messageString)
			break
		}
		case NotificationType.NOTIFICATION_LICENSE_DATE_EXPIRED: {
			messageString = getString(notification.messageString).format(
				moment(notification.messageParams?.expireDate).format('DD/MM/yyyy')
			)
			break
		}
		case NotificationType.NOTIFICATION_LICENSE_PART_EXPIRED: {
			messageString = getString(notification.messageString).format(
				`<b>${notification.messageParams?.remainingParts}</b>`,
				`<b>${notification.messageParams?.overallParts}</b>`
			)
			break
		}
	}

	return {
		titleString,
		messageString,
		messageLink,
		internalLink:
			messageLink.startsWith(USER_HOME_ROUTE) ||
			messageLink.startsWith(ADMIN_ROUTE)
	}
}
