export interface ITheme {}

export const getTheme = () => {
  return require(`./${process.env.REACT_APP_THEME_NAME || 'base'}/${
    process.env.REACT_APP_THEME_NAME || 'base'
  }.ts`).default
}

export const getComponentFactoryTheme = () => {
  return require(`./${
    process.env.REACT_APP_THEME_NAME || 'base'
  }/CustomComponentsFactory.tsx`).default
}
