import React, { FC, memo } from 'react'

import GroupCheckboxFilter from './GroupCheckboxFilter'
import MultiCheckboxFilter from './MultiCheckboxFilter'
import TextFieldFilter from './TextFieldFilter'
import { IFilterData } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsInterface'
import { FilterType } from 'Services/models/IUserFilter'

interface FilterInputProps {
	id: string | number
	filterData: IFilterData
}

const FilterInput: FC<FilterInputProps> = ({ id, filterData }) => {
	switch (filterData.type.trim()) {
		case FilterType.Boolean:
			return <></>
		case FilterType.Array:
			return (
				<div
					className="filter-fields--widget--dropdown-field"
					data-qa="data-qa-dropdown-field"
				>
					<MultiCheckboxFilter id={id} filter={filterData} />
				</div>
			)
		case FilterType.Group:
			return (
				<div
					className="filter-fields--widget--dropdown-field"
					data-qa="data-qa-dropdown-field"
				>
					<GroupCheckboxFilter id={id} filter={filterData} />
				</div>
			)

		default:
			return <TextFieldFilter id={id} filterData={filterData} />
	}
}
export default memo(FilterInput)
