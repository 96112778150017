import React, { Component } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import CheckedIcon from '@material-ui/icons/CheckBox'
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank'

import { adminUpdateResult } from '../../../../Services/Network'
import {
	CAD_FAIL_REASON,
	CAD_SCORE,
	COMPLEXITY_SCORE,
	EDIT_PART,
	ERROR_WILL_BE_SHOWN,
	MATERIAL_FAIL_REASON,
	MATERIAL_SCORE,
	OFF_THE_SHELF,
	OK,
	PAGE_INVALIDATED,
	PART_RESULT,
	SIZE_FAIL_REASON,
	SIZE_SCORE,
	SUBMIT_CHANGES,
	THICKNESS_FAIL_REASON,
	THICKNESS_SCORE,
	UPDATE_PART_PROPERTIES
} from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import { getTheme } from '../../../../themes/getTheme'
import CastorAlert from '../../../Components/alerts/CastorAlert'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import HeaderCard from '../../../Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import Loader from '../../../Loader/Loader'
import Flexbox from 'Scenes/Components/FlexBox'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'

const { cardBoxColor } = getTheme()

const FAILED_RESULT = 'failed'
class AdminEditPartForm extends Component {
	state = {
		failedLoadingMessage: '',
		errorMessage: '',
		loading: false,
		result: '',
		isOffTheShelf: false,
		partComplexityScore: '',
		sizeScore: '',
		wallThicknessScore: '',
		wallThicknessFailReason: '',
		sizeFailReason: '',
		CADScore: '',
		CADFailReason: '',
		materialScore: '',
		materialFailReason: '',
		part: {},
		error: null
	}

	componentDidMount() {
		const part = this.props.location.part
		this.setPartToState(part)
	}

	setPartToState(part) {
		if (!part) {
			this.setState({
				failedLoadingMessage: PAGE_INVALIDATED
			})
		} else {
			this.setState({
				part,
				error: null,
				isOffTheShelf: part.isOffTheShelf,
				wallThicknessScore: part.wallThicknessScore,
				wallThicknessFailReason: part.wallThicknessFailReason,
				sizeScore: part.sizeScore,
				sizeFailReason: part.sizeFailReason,
				materialScore: part.materialScore,
				materialFailReason: part.materialFailReason,
				partComplexityScore: part.partComplexityScore,
				CADScore: part.CADScore,
				CADFailReason: part.CADFailReason,
				result: part.result,
				errorMessage: part.errorMessage
			})
		}
	}

	//Actions
	resultSelected(event) {
		this.setState({ result: event.target.value })
	}
	//Render functions
	renderAlert() {
		return (
			<CastorAlert
				show={this.state.showingSimpleAlertTitle}
				headerTitle={this.props.showingSimpleAlertTitle}
				onConfirm={() =>
					this.props.publishConfirmed(this.props.projectWaitingForPublishing)
				}
				onCancel={() => this.props.cancelAlert()}
				confirmOptionalText={OK}
			>
				{this.props.showingSimpleAlertText}
			</CastorAlert>
		)
	}

	offTheShelfCheckboxClicked() {
		this.setState({
			isOffTheShelf: !this.state.isOffTheShelf
		})
	}
	submitForm = () => {
		this.setState({ loading: true, error: null })
		const state = this.state
		adminUpdateResult(
			state.part.projectId,
			state.part.id,
			state.isOffTheShelf,
			state.wallThicknessScore,
			state.wallThicknessFailReason,
			state.sizeScore,
			state.sizeFailReason,
			state.materialScore,
			state.materialFailReason,
			state.CADScore,
			state.CADFailReason,
			state.result,
			state.errorMessage,
			state.partComplexityScore
		).then(
			response => {
				this.setPartToState(response.data.part)
				this.setState({ loading: false })
			},
			error => {
				this.setState({ error: error.message, loading: false })
			}
		)
	}

	renderForm() {
		return (
			<Flexbox flexDirection="column" style={styles.formSuperview}>
				<FormControlLabel
					label={OFF_THE_SHELF}
					control={
						<Checkbox
							tabIndex={-1}
							onClick={this.offTheShelfCheckboxClicked.bind(this)}
							checked={this.state.isOffTheShelf}
							checkedIcon={<CheckedIcon />}
							icon={<UncheckedIcon />}
						/>
					}
				/>
				<TextField
					label={THICKNESS_SCORE}
					value={this.state.wallThicknessScore}
					onChange={event =>
						this.setState({ wallThicknessScore: event.target.value })
					}
					style={styles.textField}
					type="number"
					onWheel={event => event.target.blur()}
				/>
				<TextField
					label={THICKNESS_FAIL_REASON}
					value={this.state.wallThicknessFailReason}
					onChange={event =>
						this.setState({ wallThicknessFailReason: event.target.value })
					}
					style={styles.textField}
				/>
				<TextField
					label={SIZE_SCORE}
					value={this.state.sizeScore}
					onChange={event => this.setState({ sizeScore: event.target.value })}
					style={styles.textField}
					type="number"
					onWheel={event => event.target.blur()}
				/>
				<TextField
					label={SIZE_FAIL_REASON}
					value={this.state.sizeFailReason}
					onChange={event =>
						this.setState({ sizeFailReason: event.target.value })
					}
					style={styles.textField}
				/>
				<TextField
					label={CAD_SCORE}
					value={this.state.CADScore}
					onChange={event => this.setState({ CADScore: event.target.value })}
					style={styles.textField}
					onWheel={event => event.target.blur()}
					type="number"
				/>
				<TextField
					label={CAD_FAIL_REASON}
					value={this.state.CADFailReason}
					onChange={event =>
						this.setState({ CADFailReason: event.target.value })
					}
					style={styles.textField}
				/>
				<TextField
					label={MATERIAL_SCORE}
					value={this.state.materialScore}
					onChange={event =>
						this.setState({ materialScore: event.target.value })
					}
					style={styles.textField}
					type="number"
					onWheel={event => event.target.blur()}
				/>
				<TextField
					label={COMPLEXITY_SCORE}
					value={this.state.partComplexityScore}
					onChange={event =>
						this.setState({ partComplexityScore: event.target.value })
					}
					type="number"
					style={styles.textField}
					onWheel={event => event.target.blur()}
				/>
				<TextField
					label={MATERIAL_FAIL_REASON}
					value={this.state.materialFailReason}
					onChange={event =>
						this.setState({ materialFailReason: event.target.value })
					}
					style={styles.textField}
				/>
				{this.renderResultPicker()}
				{this.renderErrorFieldIfNeeded()}
				<br />
				<Button color="primary" onClick={this.submitForm}>
					{SUBMIT_CHANGES}
				</Button>
			</Flexbox>
		)
	}

	renderMainLoader() {
		if (this.state.failedLoadingMessage) {
			return (
				<div>
					<br />
					<br />
					<br />
					<br />
					<p style={styles.errorTextStyle}>{this.state.failedLoadingMessage}</p>
				</div>
			)
		}
		if (this.state.loading) {
			return <Loader load={this.state.loading} />
		}
		return (
			<NavBarAndMaterial title={EDIT_PART}>
				<Flexbox style={styles.cardSuperview}>
					<HeaderCard
						headerColor={cardBoxColor || 'green'}
						hover
						underChart={<p>testing</p>}
						cardTitle={EDIT_PART}
						cardSubtitle={UPDATE_PART_PROPERTIES}
						content={this.renderForm()}
						style={styles.card}
					/>
				</Flexbox>
			</NavBarAndMaterial>
		)
	}

	render() {
		return this.renderMainLoader()
	}

	renderResultPicker() {
		const label = PART_RESULT
		const results = ['printable', 'notPrintable', 'borderline', FAILED_RESULT]
		return (
			<FormControl fullWidth>
				<InputLabel htmlFor="simple-select">{label}</InputLabel>
				<Select
					MenuProps={{}}
					classes={{}}
					value={this.state.result}
					onChange={this.resultSelected.bind(this)}
					inputProps={{
						name: 'simpleSelect',
						id: 'simple-select'
					}}
				>
					<MenuItem disabled>{label}</MenuItem>
					{results.map(item => {
						return (
							<MenuItem key={item} value={item}>
								{item}
							</MenuItem>
						)
					})}
				</Select>
			</FormControl>
		)
	}

	renderErrorFieldIfNeeded() {
		if (this.state.result !== FAILED_RESULT) {
			return <div />
		}
		return (
			<TextField
				label={ERROR_WILL_BE_SHOWN}
				value={this.state.errorMessage}
				onChange={event => this.setState({ errorMessage: event.target.value })}
				style={styles.textField}
			/>
		)
	}
}

const styles = {
	textField: {},
	smallTextField: { width: 150, marginLeft: 6, marginRight: 6 },
	chart: { height: '300px', marginRight: 20, marginTop: 20, flexGrow: 5 },
	horizontalFlex: { flex: 1, alignItems: 'center' },
	xAxisLabel: { textAlign: 'center' },
	divider: { margin: 10 },
	thumbnail: { width: 150, maxHeight: 150 },
	card: { marginLeft: 20, marginRight: 20 },
	iconButton: { height: 40, width: 75, alignSelf: 'center' },
	errorTextStyle: {
		fontSize: 15,
		alignSelf: 'center',
		color: 'red'
	},
	actionSelect: { flex: 3, width: 300 },
	actionSelectFlex: { alignItems: 'center' },
	runActionButton: { marginTop: 15 },
	formSuperview: {
		maxWidth: 1000,
		justifyContent: 'center',
		marginLeft: 30,
		marginRight: 30
	},
	cardSuperview: {
		maxWidth: 720,
		alignSelf: 'center',
		textAlign: 'left',
		marginLeft: '25%',
		marginRight: '25%'
	}
}

export default AdminEditPartForm
