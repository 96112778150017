import {
	ADMIN_MATERIAL_ADDED,
	ADMIN_MATERIAL_DELETED,
	ADMIN_MATERIAL_DELETED_GOT_ERROR,
	ADMIN_MATERIAL_EDIT_FORM_OPENED,
	ADMIN_MATERIAL_NEW_FORM_OPENED,
	ADMIN_MATERIAL_SEARCH_PHRASE_CHANGED,
	ADMIN_MATERIAL_TYPE_CHANGED,
	ADMIN_MATERIAL_UPDATED,
	GET_ADMIN_MATERIALS,
	GET_ADMIN_MATERIALS_EDIT_MATERIAL_SELECTED,
	GET_ADMIN_MATERIALS_GOT_ERROR,
	GET_ADMIN_MATERIALS_SUCCESS
} from '../../../../global actions/types'
import {
	createNewMaterialAdmin,
	deleteMaterialAdmin,
	getMaterialAdmin,
	getMaterialsAdmin,
	updateMaterialAdmin
} from '../../../../Services/Network'
import {
	ADMIN_MATERIALS_ADDED_SUCCESS,
	ADMIN_MATERIALS_UPDATE_ERROR,
	ADMIN_MATERIALS_UPDATE_SUCCESS
} from '../../../../Services/Strings'
import {
	createNewItem,
	deleteItem,
	selectItemToEdit,
	setupAdminPage,
	updateItem
} from '../adminActionsService'

export const setupAdminMaterialsPage = (
	searchPhrase,
	pageNumber,
	limitMaterials
) => {
	return dispatch => {
		setupAdminPage(
			dispatch,
			getMaterialsAdmin,
			[searchPhrase, pageNumber, limitMaterials],
			GET_ADMIN_MATERIALS_SUCCESS,
			GET_ADMIN_MATERIALS_GOT_ERROR,
			pageNumber
		)

		dispatch({ type: GET_ADMIN_MATERIALS })
	}
}

export const setSearchPhrase = searchPhrase => {
	return {
		type: ADMIN_MATERIAL_SEARCH_PHRASE_CHANGED,
		payload: searchPhrase
	}
}

export const onAdminMaterialsUnmounted = () => {
	return {
		type: ADMIN_MATERIAL_SEARCH_PHRASE_CHANGED,
		payload: ''
	}
}

export const selectMaterialToEdit = (materialId, materials) => {
	return dispatch => {
		selectItemToEdit(
			dispatch,
			materialId,
			materials,
			getMaterialAdmin,
			[materialId],
			GET_ADMIN_MATERIALS_EDIT_MATERIAL_SELECTED,
			GET_ADMIN_MATERIALS_GOT_ERROR,
			'material'
		)
	}
}

export const setupEditMaterialForm = materialCategories => {
	return {
		type: ADMIN_MATERIAL_EDIT_FORM_OPENED,
		payload: materialCategories
	}
}

export const setupNewMaterialForm = () => {
	return {
		type: ADMIN_MATERIAL_NEW_FORM_OPENED
	}
}

export const updateMaterial = data => {
	return dispatch => {
		updateItem(
			dispatch,
			updateMaterialAdmin,
			data,
			ADMIN_MATERIAL_UPDATED,
			'materialUpdated',
			ADMIN_MATERIALS_UPDATE_SUCCESS,
			ADMIN_MATERIALS_UPDATE_ERROR
		)
	}
}

export const deleteMaterial = id => {
	return dispatch => {
		deleteItem(
			dispatch,
			deleteMaterialAdmin,
			id,
			ADMIN_MATERIAL_DELETED,
			ADMIN_MATERIAL_DELETED_GOT_ERROR
		)
	}
}

export const createNewMaterial = material => {
	return dispatch => {
		createNewItem(
			dispatch,
			createNewMaterialAdmin,
			material,
			ADMIN_MATERIAL_ADDED,
			'materialAdded',
			ADMIN_MATERIALS_ADDED_SUCCESS,
			ADMIN_MATERIALS_UPDATE_ERROR
		)
	}
}

export const onMaterialTypeChange = (selectedType, materialCategories) => {
	return {
		type: ADMIN_MATERIAL_TYPE_CHANGED,
		payload: { selectedType, materialCategories }
	}
}
