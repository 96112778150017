import { FC, memo, ChangeEvent } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'
import TextField from '@material-ui/core/TextField'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { isEnterKeyPressed } from 'Services/getKeyCodesService'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import { getString } from 'Services/Strings/StringService'
import './AdminFeatureToggle.scss'

interface IProps {
  expression: string
  isLiveEnvironment: boolean
  showNewConfigurationAlert: boolean
  showWarningAlert: boolean
  setShowNewConfigurationAlert: Function
  setShowWarningWindow: Function
  setIsLiveEnvironment: Function
  setExpression: Function
  onCreateConfigurationClick: Function
  onUpdateConfigurationClick: Function
  onUpdateEnvironmentClick: Function
}

const AdminPanelFeaturesAlert: FC<IProps> = ({
  expression,
  isLiveEnvironment,
  showNewConfigurationAlert,
  showWarningAlert,
  setShowNewConfigurationAlert,
  setShowWarningWindow,
  setIsLiveEnvironment,
  setExpression,
  onCreateConfigurationClick,
  onUpdateEnvironmentClick,
  onUpdateConfigurationClick
}) => {
  const onNewConfigurationCancel = () => {
    setShowNewConfigurationAlert(false)
  }
  const onWarningCancel = () => {
    setShowWarningWindow(false)
    setIsLiveEnvironment(false)
  }

  const renderWarningWindow = () => {
    return (
      <CastorAlert
        alertType={AlertType.WARNING}
        headerTitle={
          isLiveEnvironment
            ? getString('ADMIN_PANEL_FEATURE_TOGGLE_UPDATE_ENVIRONMENT')
            : getString('ADMIN_PANEL_FEATURE_TOGGLE_UPDATE_CONFIGURATION')
        }
        onCancel={() => onWarningCancel()}
        show={showWarningAlert}
        confirmOptionalText={getString('Confirm')}
        onConfirm={
          isLiveEnvironment
            ? () => onUpdateEnvironmentClick()
            : () => onUpdateConfigurationClick()
        }
      >
        {isLiveEnvironment
          ? getString('SAVING_FEATURES_WARNING_LIVE_ENVIRONMENT')
          : getString('SAVING_FEATURES_WARNING')}
      </CastorAlert>
    )
  }

  const renderNewConfigurationWindow = () => {
    return (
      <CastorAlert
        headerTitle={getString(
          'ADMIN_PANEL_FEATURE_TOGGLE_CREATE_CONFIGURATION'
        )}
        onCancel={() => onNewConfigurationCancel()}
        show={showNewConfigurationAlert}
        confirmOptionalText={getString('Confirm')}
        onConfirm={() => onCreateConfigurationClick()}
        disabled={expression.length < 1}
      >
        <Flexbox alignItems="center">
          <TextField
            type="input"
            className="admin-feature-toggle-new-configuration-text"
            value={expression}
            variant="standard"
            placeholder={getString('ENTER_NEW_CONFIGURATION_EXPRESSION')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => [
              setExpression(e.target.value)
            ]}
            onKeyUp={(e: any) => {
              if (isEnterKeyPressed(e)) {
                onCreateConfigurationClick()
              }
            }}
          ></TextField>
        </Flexbox>
      </CastorAlert>
    )
  }

  return (
    <>
      {renderNewConfigurationWindow()}
      {renderWarningWindow()}
    </>
  )
}

export default memo(AdminPanelFeaturesAlert)
