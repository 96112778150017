import { PureComponent } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { isEmpty } from 'lodash'

import * as TwoFactorAuthAction from './TwoFactorAuthAction'
import { Action } from '../../../global actions/ActionModels'
import {
	SAVE,
	SEND,
	TROUBLE_TO_LOGIN_TEXT,
	TWO_FACTOR_LOGIN_HEADER,
	TWO_FACTOR_LOGIN_SUB_HEADER,
	TWO_FACTOR_SETUP_HEADER,
	TWO_FACTOR_SETUP_SUB_HEADER
} from '../../../Services/Strings'
import ButtonWithLoader from '../../Components/ButtonWithLoader'
import CastorForm from '../../Components/CastorForm/CastorForm'
import CastorFormHeader from '../../Components/CastorForm/CastorFormHeader'
import NumberField from '../../Components/NumberField'
import { TwoFactorAuthInitialState } from './TwoFactorAuthReducer'
import { History } from 'history'
import { projectPageRoles } from 'Scenes/Authentication/TwoFactorAuth/constants'
import ErrorPage from 'Scenes/Components/ErrorPage'
import Flexbox from 'Scenes/Components/FlexBox'
import { ADMIN_ROLES } from 'Services/Constants'

import './TwoFactorAuth.scss'

interface Props extends TwoFactorAuthInitialState {
	history: History
	roles: number[]
	saveQr: () => Action<any>
	informAdminLoginIssue: () => any
	setup: (history: History, setupEnd: boolean, roles: number[]) => Action<any>
	clear: () => Action<any>
	sendCode: (code: number | null, initialSetup: boolean) => Action<any>
	codeInputOnChange: (value?: number) => Action<any>
}
interface State {}
interface ReduxState {
	user: any
	TwoFactorAuthReducer: TwoFactorAuthInitialState
}
class TwoFactorAuth extends PureComponent<Props, State> {
	componentDidMount = () => {
		const { setup, history, setupEnd, roles }: Props = this.props
		setup(history, setupEnd, roles)
	}
	componentDidUpdate = () => {
		const { adminAuthenticated, history, roles, clear }: Props = this.props
		if (adminAuthenticated) {
			clear()
			if (projectPageRoles.some(role => roles.includes(role))) {
				history.push('/admin/projects')
			} else {
				history.push('/admin/printers')
			}
		}
	}

	saveButton = () => {
		const { saveQr } = this.props
		saveQr()
	}
	sendButton = () => {
		const { sendCode, codeInput, initialSetup } = this.props
		if (codeInput === null) {
			return
		}
		sendCode(codeInput, initialSetup)
	}

	loginTroubleButtonClick = () => {
		const { informAdminLoginIssue } = this.props
		informAdminLoginIssue()
	}
	onCodeInputChanged = (value: number) => {
		const { codeInputOnChange } = this.props
		codeInputOnChange(value)
	}

	renderTowFactorSetup = () => {
		const { qr } = this.props
		return (
			<Flexbox flexDirection="column" alignItems="center">
				<CastorFormHeader
					explanationHeader={TWO_FACTOR_SETUP_HEADER}
					explanationArray={[TWO_FACTOR_SETUP_SUB_HEADER + ' save button']}
					isInCard={true}
				/>

				<div className="qr-img">
					<img src={qr} />
				</div>

				<ButtonWithLoader onClick={this.saveButton}>{SAVE}</ButtonWithLoader>
			</Flexbox>
		)
	}
	renderTwoFactorLogin = () => {
		const { codeInput } = this.props

		return (
			<Flexbox flexDirection="column" className="two-fa-block">
				<CastorFormHeader
					explanationHeader={TWO_FACTOR_LOGIN_HEADER}
					explanationArray={[TWO_FACTOR_LOGIN_SUB_HEADER]}
					isInCard={true}
				/>
				<Flexbox
					alignItems="center"
					justifyContent="space-between"
					marginLeft="12px"
				>
					<NumberField
						value={codeInput}
						showArrows={false}
						allowZero={true}
						onChangeValue={(value: number) => this.onCodeInputChanged(value)}
						inputClass="number-text-field"
						idInput="plastic-form-quantity"
						placeholder="code"
						minValue=""
						variant="outlined"
						onKeyUp={() => this.sendButton()}
					/>
					<ButtonWithLoader
						disabled={isEmpty(codeInput)}
						className="send-button"
						onClick={this.sendButton}
						size={30}
						type={'submit'}
					>
						{SEND}
					</ButtonWithLoader>
				</Flexbox>
				<div className="login-problems-link">
					{TROUBLE_TO_LOGIN_TEXT}{' '}
					<a onClick={this.loginTroubleButtonClick}>here</a>{' '}
				</div>
			</Flexbox>
		)
	}
	renderComponent = () => {
		const { qrSaved, initialSetup } = this.props
		if (initialSetup && !qrSaved) {
			return this.renderTowFactorSetup()
		}
		if (qrSaved) {
			return this.renderTwoFactorLogin()
		}
		return this.renderTwoFactorLogin()
	}

	render() {
		const { setupEnd, roles } = this.props
		const userHasAdminRole = roles.some(role => ADMIN_ROLES.includes(role))
		if (!setupEnd) {
			return <div />
		}
		if (!userHasAdminRole) {
			return <ErrorPage />
		}
		return (
			<div className="tow-factor-login">
				<CastorForm
					formTitle={'Admin login'}
					content={this.renderComponent()}
				/>
			</div>
		)
	}
}

const mapStateToProps = ({
	user: { roles },
	TwoFactorAuthReducer
}: ReduxState) => ({
	roles,
	...TwoFactorAuthReducer
})

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...TwoFactorAuthAction }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuth)
