
export enum NotificationEvent {
  EVENT = 'event',
  SILENT_EVENT = 'silent-event'
}

export enum NotificationType {
  NOTIFICATION_PROJECT_PUBLISHED = 'NOTIFICATION_PROJECT_PUBLISHED',
  NOTIFICATION_PROJECT_ADMIN_PUBLISHED = 'NOTIFICATION_PROJECT_ADMIN_PUBLISHED',
  NOTIFICATION_PART_STATUS_CHANGED = 'NOTIFICATION_PART_STATUS_CHANGED',
  NOTIFICATION_PROJECT_UPDATED = 'NOTIFICATION_PROJECT_UPDATED',
  NOTIFICATION_PART_CONSOLIDATION_UPDATED = 'NOTIFICATION_PART_CONSOLIDATION_UPDATED',
  NOTIFICATION_NEW_USER_REGISTERED = 'NOTIFICATION_NEW_USER_REGISTERED',
  NOTIFICATION_SETTINGS_UPDATED = 'NOTIFICATION_SETTINGS_UPDATED',
  NOTIFICATION_LICENSE_DATE_EXPIRED = 'NOTIFICATION_LICENSE_DATE_EXPIRED',
  NOTIFICATION_LICENSE_PART_EXPIRED = 'NOTIFICATION_LICENSE_PART_EXPIRED',
}

export enum NotificationSilentType {
  NOTIFICATION_PROJECT_UPLOADING = 'NOTIFICATION_PROJECT_UPLOADING'
}

export enum NotificationString {
  PROJECT_PUBLISHED_MESSAGE = 'PROJECT_PUBLISHED_MESSAGE',
  PROJECT_ADMIN_PUBLISHED_MESSAGE = 'PROJECT_ADMIN_PUBLISHED_MESSAGE',
  PART_STATUS_CHANGED_MESSAGE = 'PART_STATUS_CHANGED_MESSAGE',
  PROJECT_UPDATED_MESSAGE = 'PROJECT_UPDATED_MESSAGE',
  PART_CONSOLIDATION_UPDATED_MESSAGE = 'PART_CONSOLIDATION_UPDATED_MESSAGE',
  NEW_USER_REGISTERED_MESSAGE = 'NEW_USER_REGISTERED_MESSAGE',
  SETTINGS_UPDATED_MESSAGE = 'SETTINGS_UPDATED_MESSAGE',
  LICENSE_DATE_EXPIRED_MESSAGE = 'LICENSE_DATE_EXPIRED_MESSAGE',
  LICENSE_PART_EXPIRED_MESSAGE = 'LICENSE_PART_EXPIRED_MESSAGE'
}

export enum NotificationTitleString {
  PROJECT_UPDATE_MESSAGE = 'PROJECT_UPDATE_MESSAGE',
  PART_UPDATE_MESSAGE = 'PART_UPDATE_MESSAGE',
  USER_UPDATE_MESSAGE = 'USER_UPDATE_MESSAGE',
  SETTINGS_UPDATE_MESSAGE = 'SETTINGS_UPDATE_MESSAGE',
  LICENSE_UPDATE_MESSAGE = 'LICENSE_UPDATE_MESSAGE'
}
