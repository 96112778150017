import FlexBox from '../FlexBox'
import { getString } from 'Services/Strings/StringService'

export const Empty = () => (
	<FlexBox
		alignItems="center"
		justifyContent="center"
		width="80vw"
		height="80vh"
        margin="auto"
	>
		{getString('PAGE_IS_NOT_EXIST_OR_ACCESS')}
	</FlexBox>
)
