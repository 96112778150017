import React, { FC, memo } from 'react'

import { FormControlLabel } from '@material-ui/core'

import CastorSwitch from '../../../../../../../../../Components/CastorSwitch'
import { getString } from 'Services/Strings/StringService'

const LabelFormControl: any = FormControlLabel

interface IProps {
	openSwitch: boolean
	show: boolean
	onChange: Function
	costLoading: boolean
	dataQAAttribute?: string
}

const CostAnalysisTableSwitch: FC<IProps> = ({
	openSwitch,
	onChange,
	show,
	costLoading,
	dataQAAttribute
}) => {
	if (!show) {
		return <div></div>
	}
	return (
		<LabelFormControl
			label={
				openSwitch
					? getString('FINANCIAL_BREAKEVEN_TABLE_VIEW')
					: getString('COST_ANALYSIS_TABLE_VIEW')
			}
			classes={{ root: 'cost-comparison-tab--switches--top' }}
			control={
				<CastorSwitch
					disabled={costLoading}
					onChange={onChange}
					checked={openSwitch}
					classes={{ root: 'cost-comparison-tab--toggle--root' }}
					data-qa={dataQAAttribute}
				/>
			}
		/>
	)
}

export default memo(CostAnalysisTableSwitch)
