import React, { FC, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'

import cx from 'classnames'
import { cloneDeep } from 'lodash'

import Loader from 'Scenes/Loader/Loader'
import usePrevious from 'Services/CustomHooks/usePrevious'
import { IChartData } from 'Services/models/CostComparisonModels'

const ChartGraph: any = Chart

type IProps = {
	chartType?: string
	chartData: IChartData | null
	loading: boolean
	forceUpdate?: boolean
	loaderWrapperClassName?: string
	wrapperClassName?: string
	loaderMessage?: string
}

const ChartWithLoader: FC<IProps> = ({
	chartType = 'line',
	chartData,
	loading,
	forceUpdate,
	loaderWrapperClassName,
	wrapperClassName,
	loaderMessage = ''
}) => {
	const prevChartData = usePrevious(chartData)
	const [isChartUpdating, setIsChartUpdating] = useState(false)

	useEffect(() => {
		if (prevChartData !== chartData) {
			setIsChartUpdating(true)
			return
		} else {
			//need to show loader for a while
			setTimeout(() => {
				setIsChartUpdating(false)
			}, 500)
		}
	}, [chartData, prevChartData])

	if (forceUpdate) {
		if ((loading || isChartUpdating) && chartData) {
			// need to create a fake chart data to force chart rerender after loading is finished
			const fakeChartData = cloneDeep(chartData)
			fakeChartData.componentChartData.annotations = {}
			fakeChartData.componentChartData.chart.animations = { enabled: false }

			return (
				<div className={cx(wrapperClassName)}>
					<Loader
						load={loading || isChartUpdating}
						message={loaderMessage}
						wrapperClassName={cx(loaderWrapperClassName)}
					/>
					<ChartGraph
						type={chartType}
						options={fakeChartData.componentChartData}
						series={fakeChartData.series}
						height="100%"
					/>
				</div>
			)
		}
		return (
			<div className={cx(wrapperClassName)}>
				<ChartGraph
					type={chartType}
					options={chartData && chartData.componentChartData}
					series={chartData && chartData.series}
					height="100%"
				/>
			</div>
		)
	}

	return (
		<div className={cx(wrapperClassName)}>
			<Loader
				load={loading}
				message={loaderMessage}
				wrapperClassName={cx(loaderWrapperClassName)}
			/>
			<ChartGraph
				type={chartType}
				options={chartData && chartData.componentChartData}
				series={chartData && chartData.series}
				height="100%"
			/>
		</div>
	)
}

export default ChartWithLoader
