import React, { FC, useState } from 'react'

import {
	IPrinterCost,
	PrinterCostKey,
	ValidationStatus
} from './CustomizePrinterCostsTypes'
import {
	CustomInput,
	Danger
} from 'Scenes/Components/thirdParty/CreativeTim/components'
import {
	isBackSpaceKeyPressed,
	isCommaKeyPressed,
	isDotKeyPressed
} from 'Services/getKeyCodesService'

import * as styles from '../../customizeStyles.css'

type IProps = {
	printerCost: IPrinterCost
	printerCostKey: PrinterCostKey
	relatedPrinterCostKey: PrinterCostKey
	editablePrinterCostsList: IPrinterCost[]
	onPrinterCostChange: (
		printerId: string,
		printerCostKey: PrinterCostKey,
		value: string,
		editablePrinterCostsList: IPrinterCost[],
		isIncorrectNumberValue: boolean,
		relatedPrinterCostKey: PrinterCostKey,
		forceDisableRelatedField: boolean
	) => void
}
const EditableValue: FC<IProps> = ({
	printerCost,
	printerCostKey,
	relatedPrinterCostKey,
	editablePrinterCostsList,
	onPrinterCostChange
}) => {
	const [disableRelatedField, setDisableRelatedField] = useState(false)
	const value = printerCost[printerCostKey]
	const inputStyle = !value ? styles.defaultValue : {}
	const label = printerCost.valueLabel || value
	const isError =
		printerCost.validationStatus?.[printerCostKey] === ValidationStatus.error
	const isSuccess =
		printerCost.validationStatus?.[printerCostKey] === ValidationStatus.success

	const onKeyDown = (e: any) => {
		if (
			(isDotKeyPressed(e) ||
				isCommaKeyPressed(e) ||
				isBackSpaceKeyPressed(e)) &&
			e.target.value.length !== 1
		) {
			setDisableRelatedField(true)
		} else {
			setDisableRelatedField(false)
		}
	}

	if (printerCost.edit) {
		return (
			<div className="custom-input">
				<CustomInput
					id="outlined-dense"
					success={isSuccess}
					error={isError}
					formControlProps={{
						fullWidth: true,
						style: { margin: 'unset' }
					}}
					inputProps={{
						label: label,
						autoFocus: true,
						value: value,
						disabled: printerCost.disabled?.[printerCostKey],
						step: 0.1,
						onKeyDown,
						onChange: (e: any) => {
							onPrinterCostChange(
								printerCost.printer.id as string,
								printerCostKey,
								e.target.value,
								editablePrinterCostsList,
								!e.target.value && !!e.nativeEvent.data,
								relatedPrinterCostKey,
								disableRelatedField
							)
						},
						type: 'number'
					}}
				/>
				{isError && (
					<Danger
						style={{
							textAlign: 'left',
							position: 'absolute',
							bottom: '-7px',
							fontSize: '12px'
						}}
					>
						{printerCost.validationMessage?.[printerCostKey]}
					</Danger>
				)}
			</div>
		)
	}
	return <span style={inputStyle}>{label || '-'}</span>
}

export default EditableValue
