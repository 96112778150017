export default {
  weightSelectorDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '20%'
  },
  weightSelectorSpan: {
    fontSize: 'smaller',
    opacity: '0.7',
    float: 'right',
    maxWidth: '145px',
    marginTop: '-10px',
    textAlign: 'left'
  },
  inputsDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  materialCost: {
    margin: '20px 12px',
    width: 'fit-content'
  }
}
