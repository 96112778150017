import React, { FC, memo, useState, useEffect, ChangeEvent } from 'react'
import { getString } from '../../../../../../Services/Strings/StringService'
import IconFactory from '../../../../../Components/StarIcon/IconFactory'
import CastorSlider from '../../../../../Components/CastorSlider'
import {
  editSliderMarks,
  getUserSliderInput,
  getUserFeaValues
} from './SolutionFeaContentService'
import SolutionFeaResults from './SolutionFeaResults'

import './SolutionFea.scss'

interface IProps {
  strengthTitle: string
  userInputTitle: string
  strength: number
  SDStrength: number
  sliderMaxValue: number
  sliderMinValue: number
  sliderStartPointValue: number
  sliderMarks: Record<string, string>
  solutionFeaSliderValue: number
  isRange: boolean
  sliderUnits: string
  feaResult: number
  setUserSelectedInputs: Function
}

const renderSDStrength = (SDStrength: number) => {
  if (!SDStrength) {
    return <div></div>
  }
  return (
    <>
      <IconFactory iconName="plusMinus" />
      <div className="solution-fea-alert--content--strength--values--number">
        {SDStrength} MPa
      </div>
    </>
  )
}

const renderPrinterMaterialStrengthData = (
  strengthTitle: string,
  strength: number,
  SDStrength: number
) => {
  return (
    <div className="solution-fea-alert--content--strength">
      <div className="solution-fea-alert--content--strength--title">
        {strengthTitle}
      </div>
      <div className="solution-fea-alert--content--strength--values">
        <div className="solution-fea-alert--content--strength--values--number">
          {strength} MPa
        </div>
        {renderSDStrength(SDStrength)}
      </div>
    </div>
  )
}

const SolutionFeaContent: FC<IProps> = ({
  strengthTitle,
  strength,
  SDStrength,
  userInputTitle,
  sliderMarks,
  sliderMaxValue,
  sliderStartPointValue,
  solutionFeaSliderValue,
  isRange,
  sliderUnits,
  sliderMinValue,
  feaResult,
  setUserSelectedInputs
}) => {
  const [sliderValue, setSliderValue] = useState(solutionFeaSliderValue)
  const [selectedValues, setSelectedValues] = useState<number[]>([])
  const [currentMarks, setCurrentMarks] = useState(
    editSliderMarks(sliderMarks, isRange, solutionFeaSliderValue, sliderUnits)
  )

  useEffect(() => {
    const userInputs = getUserSliderInput(isRange, sliderValue)
    const selectedValues = getUserFeaValues(
      userInputs,
      strength,
      SDStrength,
      !isRange
    )
    setSelectedValues(selectedValues)
    setUserSelectedInputs(userInputs)
    setCurrentMarks(
      editSliderMarks(sliderMarks, isRange, sliderValue, sliderUnits)
    )
  }, [sliderValue])

  return (
    <div className="solution-fea-alert--content">
      <div className="solution-fea-alert--content--open-sentence">
        {getString('MECHANICAL_ANALYSIS_ALERT_OPEN_SENTENCE')}
      </div>
      {renderPrinterMaterialStrengthData(strengthTitle, strength, SDStrength)}
      <div className="solution-fea-alert--content--user-input">
        <div className="solution-fea-alert--content--user-input--title">
          {userInputTitle}
        </div>
        <CastorSlider
          className="solution-fea-alert--content--user-input--slider"
          onChange={(value: number) => setSliderValue(value)}
          marks={currentMarks}
          min={sliderMinValue}
          max={sliderMaxValue}
          value={sliderValue}
          startPoint={sliderStartPointValue}
        />
      </div>
      <SolutionFeaResults
        userFeaResults={selectedValues}
        feaResult={feaResult}
      />
    </div>
  )
}

export default memo(SolutionFeaContent)
