import React from 'react'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import headerLinksStyle from '../thirdParty/CreativeTim/variables/styles/headerLinksStyle'
import NewFeatureTag from '../NewFeatureTag'
import { getIconFromName } from './UserMenuIcon'

import * as styles from './UserMenu.css'

const UserMenuItem = props => {
	const { classes, iconName, itemName, isNew, qaDataElementName } = props
	return (
		<div style={styles.btnDiv}>
			{getIconFromName(iconName)}
			<Button
				data-qa={qaDataElementName}
				className={classes.button}
				variant="text"
				size="small"
				style={styles.itemBtn}
			>
				{itemName}
				{isNew && <NewFeatureTag />}
			</Button>
		</div>
	)
}

export default withStyles(headerLinksStyle)(UserMenuItem)
