import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PlacesAutocomplete from 'react-places-autocomplete'

import { CustomInput } from '../thirdParty/CreativeTim/components'
import CastorLocationSearchItems from './CastorLocationSearchItems'
import PopperMenu from '../Popper/PopperMenu'
import * as CastorLocationSearchActions from './CastorLocationSearchActions'
import { SUCCESS, ERROR } from '../../../Services/Strings'
import IconLoaderGif from '../CastorIcons/IconLoaderGif'
import { InputAdornment } from '@material-ui/core'
import WithFeatureToggleHOC from '../../../Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from '../../../Services/models/Features'

import './CastorLocationSearchInput.css'

class CastorLocationSearchInput extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  componentDidCatch() {
    return <div></div>
  }

  handleChange = address => {
    const { autoCompleteDisable } = this.props
    this.props.onAddressChanged(address, autoCompleteDisable)
  }

  handleSelect = fullAddress => {
    this.props.onAddressSelected(fullAddress)
  }

  onHandleCloseClicked = () => {
    // this.setState({ open: !this.state.open })
  }
  onError = (status, clearSuggestions) => {
    console.log('Google Maps API returned error with status: ', status)
    this.props.autoCompeteError()
    clearSuggestions()
  }

  renderEndAdornment = loading => {
    if (!loading) {
      return <div />
    }
    return (
      <InputAdornment position="end">
        <IconLoaderGif className="search-input-loader-gif" />
      </InputAdornment>
    )
  }

  renderInputField = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading
  }) => {
    const {
      address,
      validAddress,
      labelText,
      placeHolder,
      managerStyle,
      inputStyle,
      inputProps,
      autoCompleteDisable,
      enableAutoCompete
    } = this.props
    if (autoCompleteDisable && suggestions.length) {
      enableAutoCompete()
    }
    return (
      <PopperMenu
        open={loading || !!suggestions.length}
        managerStyle={managerStyle}
        hidePopper={loading}
        targetItem={
          <CustomInput
            success={autoCompleteDisable ? undefined : validAddress === SUCCESS}
            error={autoCompleteDisable ? undefined : validAddress === ERROR}
            labelText={labelText}
            formControlProps={{
              fullWidth: true,
              style: inputStyle
            }}
            inputProps={{
              ...getInputProps({
                placeholder: placeHolder,
                className: 'location-search-input'
              }),
              value: address,
              endAdornment: autoCompleteDisable
                ? undefined
                : this.renderEndAdornment(loading),
              ...inputProps
            }}
          />
        }
        menuItems={
          <CastorLocationSearchItems
            loading={loading}
            suggestions={suggestions}
            getSuggestionItemProps={getSuggestionItemProps}
          />
        }
        handleClose={this.onHandleCloseClicked.bind(this, suggestions, loading)}
      />
    )
  }

  render() {
    return (
      <PlacesAutocomplete
        searchOptions={this.props.searchOption || {}}
        value={this.props.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onError={this.onError}
      >
        {this.renderInputField}
      </PlacesAutocomplete>
    )
  }
}

const mapStateToProps = ({ CastorLocationSearchReducer }) => {
  return { ...CastorLocationSearchReducer }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...CastorLocationSearchActions }, dispatch)
}

export default WithFeatureToggleHOC(
  connect(mapStateToProps, mapDispatchToProps)(CastorLocationSearchInput),
  FeatureComponentId.ADDRESS
)
