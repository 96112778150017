import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import AdminFeatureToggleReducer from '../Scenes/admin/AdminHome/AdminFeatureToggle/AdminFeatureToggleReducer'
import AdminHomeReducer from '../Scenes/admin/AdminHome/AdminHomeReducer'
import AdminLicenseCreatorReducer from '../Scenes/admin/AdminHome/AdminLicenseCreator/AdminLicenseCreatorReducer'
import AdminLicenseScreenReducer from '../Scenes/admin/AdminHome/AdminLicenseScreen/AdminLicenseScreenReducer'
import AdminMaterialPrinterPairsReducer from '../Scenes/admin/AdminHome/AdminMaterialPrinterPairs/AdminMaterialPrinterPairsReducer'
import AdminMaterialsReducer from '../Scenes/admin/AdminHome/AdminMaterials/AdminMaterialsReducer'
import AdminPartsReducer from '../Scenes/admin/AdminHome/AdminParts/AdminPartsReducer'
import AdminPrinterMaterialsReducer from '../Scenes/admin/AdminHome/AdminPrinterMaterials/AdminPrinterMaterialsReducer'
import AdminPrinterNamesReducer from '../Scenes/admin/AdminHome/AdminPrinterNames/AdminPrinterNamesReducer'
import AdminPrintersReducer from '../Scenes/admin/AdminHome/AdminPrinters/AdminPrintersReducer'
import AdminPrintingTechnologyReducer from '../Scenes/admin/AdminHome/AdminPrintingTechnology/AdminPrintingTechnologyReducer'
import AdminProjectsReducer from '../Scenes/admin/AdminHome/AdminProjects/AdminProjectsReducer'
import AdminSettingsReducer from '../Scenes/admin/AdminHome/AdminSettings/AdminSettingsReducer'
import AdminStringsReducer from '../Scenes/admin/AdminHome/AdminStrings/AdminStringsReducer'
import AdminUsersReducer from '../Scenes/admin/AdminHome/AdminUsers/AdminUsersReducer'
import AdminVersionPageReducer from '../Scenes/admin/AdminHome/AdminVersionPage/AdminVersionPageReducer'
import ParametersManagerReducer from '../Scenes/admin/AdminHome/ParametersManager/ParametersManagerReducer'
import AuthReducer from '../Scenes/Authentication/AuthReducer'
import TwoFactorAuthReducer from '../Scenes/Authentication/TwoFactorAuth/TwoFactorAuthReducer'
import AdditiveMindReducer from '../Scenes/Components/AdditiveMinds/AdditiveMindsReducer'
import AdvancedSettingsReducer from '../Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import CastorAlertReducer from '../Scenes/Components/alerts/CastorAlertReducer'
import CastorBannerReducer from '../Scenes/Components/CastorBanner/CastorBannerReducer'
import CastorLocationSearchReducer from '../Scenes/Components/CastorLocationSearchInput/CastorLocationSearchReducer'
import CastorLockedPartsReducer from '../Scenes/Components/CastorLockedParts/CastorLockedPartsReducer'
import CastorNotificationReducer from '../Scenes/Components/CastorNotification/CastorNotificationReducer'
import CastorPopperMenuReducer from '../Scenes/Components/CastorPopperMenu/CastorPopperMenuReducer'
import CastorSubscriptionAlertReducer from '../Scenes/Components/CastorSubscriptionAlert/CastorSubscriptionAlertReducer'
import CastorWallThicknessDemandReducer from '../Scenes/Components/CastorWallThicknessDemand/CastorWallThicknessDemandReducer'
import ErrorBoundaryReducer from '../Scenes/Components/ErrorBoundary/ErrorBoundaryReducer'
import MaterialSelectorReducer from '../Scenes/Components/MaterialSelector/MaterialSelectorReducer'
import PushNotificationReducer from '../Scenes/Components/PushNotification/PushNotificationReducer'
import TakeATourReducer from '../Scenes/Components/TakeATour/TakeATourReducer'
import WeightReductionProgressReducer from '../Scenes/Components/WeightReductionProgress/WeightReductionProgressReducer'
import CustomizeAnalysisCostReducer from '../Scenes/Home/Customize/CustomizeAnalysisCost/CustomizeAnalysisCostReducer'
import CustomizeCO2CalculationReducer from '../Scenes/Home/Customize/CustomizeCO2Calculation/CustomizeCO2CalculationReducer'
import CustomizeCO2Reducer from '../Scenes/Home/Customize/CustomizeCO2Cost/CustomizeCO2Reducer'
import CostAndLeadReducer from '../Scenes/Home/Customize/CustomizeCostAndLeadSettings/CostAndLeadReducer'
import CostingFunctionEditorReducer from '../Scenes/Home/Customize/CustomizeCostingFunctionEditor/CostingFunctionEditorReducer'
import CustomizeInHousePrintersReducer from '../Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrintersReducer'
import MaterialCostReducer from '../Scenes/Home/Customize/CustomizeMaterialCost/MaterialCostReducer'
import CustomizePartFilteringReducer from '../Scenes/Home/Customize/CustomizePartFiltering/CustomizePartFilteringReducer'
import CustomizeRecalculateProjectsReducer from '../Scenes/Home/Customize/CustomizeRecalculateProjects/CustomizeRecalculateProjectsReducer'
import CustomizeReducer from '../Scenes/Home/Customize/CustomizeReducer'
import CustomizeSupplyChainReducer from '../Scenes/Home/Customize/CustomizeSupplyChain/CustomizeSupplyChainReducer'
import CustomizeUserMaterialsReducer from '../Scenes/Home/Customize/CustomizeUserMaterials/CustomizeUserMaterialsReducer'
import CustomizePostProcessesReducer from '../Scenes/Home/Customize/CustomizeUserPostProcesses/CustomizePostProcessesReducer'
import LicenseReducer from '../Scenes/Home/LicenseManager/LicenseReducer'
import MainPartAnalysisReducer from '../Scenes/Home/NewPartAnalysis/MainPartAnalysis/MainPartAnalysisReducer'
import SolutionAnalysisReducer from '../Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisReducer'
import NewPartConfigurationReducer from '../Scenes/Home/NewPartAnalysis/NewPartConfiguration/NewPartConfigurationReducer'
import UploadingProjectReducer from '../Scenes/Home/NewUploadProject/UploadingProjectReducer'
import UploadProjectReducer from '../Scenes/Home/NewUploadProject/UploadProjectReducer'
import OnboardingWizardReducer from '../Scenes/Home/OnboardingWizard/OnboardingWizardReducer'
import PartAnalysisReducer from '../Scenes/Home/PartAnalysis/PartAnalysisReducer'
import PartFinancialAnalysis from '../Scenes/Home/PartAnalysis/PartFinancialAnalysis/PartFinancialAnalysisReducer'
import PartRequirementsReducer from '../Scenes/Home/PartAnalysis/PartRequirements/PartRequirementsReducer'
import PrintablePartTechAnalysisReducer from '../Scenes/Home/PartAnalysis/PrintablePartTechAnalysis/PrintablePartTechAnalysisReducer'
import UnprintablePartTechAnalysisReducer from '../Scenes/Home/PartAnalysis/UnprintablePartTechAnalysis/UnprintablePartTechAnalysisReducer'
import ClusterAnalysisReducer from '../Scenes/Home/PrintableClusters/ClusterAnalysis/ClusterAnalysisReducer'
import ClusterTechAnalysisReducer from '../Scenes/Home/PrintableClusters/ClusterAnalysis/ClusterTechAnalysis/ClusterTechAnalysisReducer'
import PrintableClustersOverviewReducer from '../Scenes/Home/PrintableClusters/PrintableClustersOverviewReducer'
import ProfileReducer from '../Scenes/Home/Profile/ProfileReducer'
import RequestMultiplePartsIntoOneCardReducer from '../Scenes/Home/ProjectAnalysis/MultiplePartsIntoOneWidgits/RequestMultiplePartsIntoOneCardReducer'
import ProjectAnalysisReducer from '../Scenes/Home/ProjectAnalysis/ProjectAnalysisReducer'
import ProjectBundleReducer from '../Scenes/Home/ProjectBundle/ProjectBundleReducer'
import ProjectPageReducer from '../Scenes/Home/ProjectPage/ProjectPageReducer'
import UploadScriptReducer from '../Scenes/Home/UploadScriptManager/UploadScriptReducer'
import UserHomeReducer from '../Scenes/Home/UserHomeReducer'
import UserPanelReducer from '../Scenes/Home/UserPanel/UserPanelReducer'
import IntegrationProviderReducer from '../Scenes/IntegrationProvider/IntegrationProviderReducer'
import InstanceStateAdvancedExtractor from '../Services/HOR/InstanceStateAdvancedExtractor'
import InstanceStateExtractor from '../Services/HOR/InstanceStateExtractor'
import InstanceStateFunctionEditorExtractor from '../Services/HOR/InstanceStateFunctionEditorExtractor'
import InstanceStateUploadingExtractor from '../Services/HOR/InstanceStateUploadingExtractor'
import GlobalReducer from './GlobalReducer'
import UserReducer from './UserReducer'

export default combineReducers({
	form: formReducer,
	auth: AuthReducer,
	TwoFactorAuthReducer,
	user: UserReducer,
	AdminHomeReducer,
	AdminProjectsReducer,
	AdminPartsReducer,
	AdminUsersReducer,
	AdminMaterialsReducer,
	AdminPrinterMaterialsReducer,
	AdminPrinterNamesReducer,
	AdminMaterialPrinterPairsReducer,
	AdminPrintingTechnologyReducer,
	AdminVersionPageReducer,
	AdminPrintersReducer,
	AdminFeatureToggleReducer,
	uploadProject: UploadProjectReducer,
	userHome: UserHomeReducer,
	ProjectAnalysisReducer: ProjectAnalysisReducer,
	PartAnalysisReducer: PartAnalysisReducer,
	MainPartAnalysisReducer,
	NewPartConfigurationReducer,
	SolutionAnalysisReducer: InstanceStateExtractor(SolutionAnalysisReducer),
	AdvancedSettingsReducer: InstanceStateAdvancedExtractor(
		AdvancedSettingsReducer
	),
	UploadingProjectReducer: InstanceStateUploadingExtractor(
		UploadingProjectReducer
	),
	PrintablePartTechAnalysisReducer: PrintablePartTechAnalysisReducer,
	UnprintablePartTechAnalysisReducer: UnprintablePartTechAnalysisReducer,
	PartFinancialAnalysis: PartFinancialAnalysis,
	PartRequirementsReducer: PartRequirementsReducer,
	PrintableClustersOverviewReducer: PrintableClustersOverviewReducer,
	ClusterAnalysisReducer: ClusterAnalysisReducer,
	ClusterTechAnalysisReducer: ClusterTechAnalysisReducer,
	RequestMultiplePartsIntoOneCardReducer:
		RequestMultiplePartsIntoOneCardReducer,
	MaterialSelectorReducer,
	GlobalReducer,
	CustomizeReducer,
	CostAndLeadReducer,
	MaterialCostReducer,
	CustomizePartFilteringReducer,
	ProfileReducer,
	CastorNotificationReducer,
	ErrorBoundaryReducer,
	CustomizeInHousePrintersReducer,
	CastorPopperMenuReducer,
	CustomizeUserMaterialsReducer,
	CastorLocationSearchReducer,
	CastorWallThicknessDemandReducer,
	CastorAlertReducer,
	CastorSubscriptionAlertReducer,
	CastorBannerReducer,
	UserPanelReducer,
	CastorLockedPartsReducer,
	AdminSettingsReducer,
	AdminStringsReducer,
	TakeATourReducer,
	UploadScriptReducer,
	ParametersManagerReducer,
	WeightReductionProgressReducer,
	IntegrationProviderReducer,
	CustomizeCO2Reducer,
	CustomizeSupplyChainReducer,
	CustomizeCO2CalculationReducer,
	AdditiveMindReducer,
	CustomizeRecalculateProjectsReducer,
	ProjectPageReducer,
	ProjectBundleReducer,
	CostingFunctionEditorReducer: InstanceStateFunctionEditorExtractor(
		CostingFunctionEditorReducer
	),
	CustomizeAnalysisCostReducer,
	OnboardingWizardReducer,
	AdminLicenseCreatorReducer,
	AdminLicenseScreenReducer,
	LicenseReducer,
	CustomizePostProcessesReducer,
	PushNotificationReducer
})
