import { FC, memo } from 'react'
import ButtonWithLoader from '../ButtonWithLoader'
import OverrideComponentHOC from '../../../themes/OverrideComponentHOC'

interface IProps {
  loading: boolean
  disabled: boolean
  buttonText: string
  color: string
  onClick: Function
  className?: string
}

const LockedPartsButtons: FC<IProps> = ({
  loading,
  disabled,
  onClick,
  buttonText,
  color,
  className = ''
}) => {
  return (
    <ButtonWithLoader
      loading={loading}
      disabled={disabled}
      className={className}
      color={color}
      onClick={onClick}
    >
      {buttonText}
    </ButtonWithLoader>
  )
}

export default memo(OverrideComponentHOC(LockedPartsButtons))
