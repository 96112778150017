import React, { ChangeEvent, FC } from 'react'

import { Select } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'

import { disableMaterialAndManufacturing } from '../../SolutionAnalysisTabsService'
import { getMethodName } from '../LeadTimeTab/LeadTimeService'
import Flexbox from 'Scenes/Components/FlexBox'
import { Muted } from 'Scenes/Components/thirdParty/CreativeTim/components'
import {
	manufacturingMethodTypes,
	materialCategories,
	materialTypes
} from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IConfiguration } from 'Services/models/IConfiguration'
import { Part } from 'Services/models/IPart'
import { PartPrintIssue } from 'Services/models/PartPrintIssue'
import { FINANCIAL_EDIT_MANUFACTURING_METHOD } from 'Services/Strings'

const SelectTsx: any = Select
const MenuItemTsx: any = MenuItem

interface ManufacturingProps {
	configuration: IConfiguration
	part: Part
	manufacturingMethod: string
	onManufacturingMethodChange: Function
	partPrintIssues: PartPrintIssue[]
	isLeadTime?: boolean
}

const TraditionalManufacturingMethod: FC<ManufacturingProps> = ({
	configuration,
	part,
	manufacturingMethod,
	onManufacturingMethodChange,
	partPrintIssues,
	isLeadTime
}) => {
	if (
		configuration?.material?.type === materialTypes.metal &&
		configuration?.material?.category !== materialCategories.stainlessSteels &&
		configuration?.material?.category !== materialCategories.aluminumAlloys &&
		!configuration.standardCost &&
		!part.standardCost
	) {
		return <div></div>
	}

	let disableManufacturingMethod =
		!Feature.isFeatureActive(FeatureComponentId.COST_TAB_INFORMATION) &&
		isLeadTime

	return (
		<Flexbox alignItems="center" className="tm-selector">
			<Muted className="nowrap">{FINANCIAL_EDIT_MANUFACTURING_METHOD}</Muted>
			<div>
				<SelectTsx
					disabled={disableManufacturingMethod}
					title={manufacturingMethod}
					className="financial-form-button"
					value={manufacturingMethod}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						onManufacturingMethodChange(
							configuration.id,
							e.target.value,
							configuration,
							partPrintIssues,
							isLeadTime
						)
					}
				>
					{Object.values(manufacturingMethodTypes).map((method: string) => {
						let methodText = getMethodName(method)
						const disableMethod = disableMaterialAndManufacturing(
							configuration?.material,
							method,
							configuration?.standardCost || part?.standardCost
						)

						if (disableMethod) return <div key={method} />

						return (
							<MenuItemTsx key={method} value={method}>
								{methodText}
							</MenuItemTsx>
						)
					})}
				</SelectTsx>
			</div>
		</Flexbox>
	)
}

export default TraditionalManufacturingMethod
