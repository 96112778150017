import {
	getCurrencyFromLocalStorage,
	getReloadFromLocalStorage,
	setCurrencyInLocalStorage,
	setJsonItemToLocalStorage,
	setLocaleInLocalStorage,
	setReloadInLocalStorage
} from '../LocalStorageService'
import {
	changeUserCurrency,
	changeUserLocale,
	getStrings
} from '../Network/StringNetwork'
import { userStringsFallback } from './stringsFallback'
import { updateSingletons } from 'Services/Network'
import { getStringData, getUserLanguage } from 'Services/Utils/startupTools'

export const getString = (key: string): any => {
	const { userStrings } = getStringData()
	if (!userStrings) {
		return userStringsFallback[key] || ''
	}
	return userStrings[key] || userStringsFallback[key] || ''
}

export const handleUserStrings = async (userEmail?: string): Promise<void> => {
	const { stringsHash } = getStringData()
	const locale = getUserLanguage()
	if (stringsHash) {
		await arrangeNewStrings(stringsHash, locale, true, userEmail)
	} else {
		checkUserLocale(userEmail)
	}
}

export const handleUserLanguageChange = async (
	selectedLocale: string,
	reload: boolean = true
): Promise<void> => {
	const locale = getUserLanguage()
	if (locale !== selectedLocale) {
		setLocaleInLocalStorage(selectedLocale)
		await changeLanguage(selectedLocale, reload)
	}
}

export const handleUserCurrencyChange = async (
	selectedCurrency: string
): Promise<void> => {
	const currency = getCurrencyFromLocalStorage()
	if (currency !== selectedCurrency) {
		setCurrencyInLocalStorage(selectedCurrency)
		await changeUserCurrency(selectedCurrency)
		window.location.reload()
	}
}

export const checkUserLocale = async (userEmail?: string) => {
	const locale = getUserLanguage()
	const reload = getReloadFromLocalStorage()
	if (!locale) {
		// const navigatorLanguage = navigator.language
		let selectedLocale: string = ''

		// if (localesAlowed.includes(navigatorLanguage)) {
		//   selectedLocale = navigatorLanguage
		// }
		await arrangeNewStrings(null, selectedLocale, !!reload, userEmail)
	}
}

const arrangeNewStrings = async (
	stringsHash: string | null,
	selectedLocale: string,
	reload: boolean = true,
	userEmail: string = ''
): Promise<void> => {
	const userStringsResponse = await getStrings(
		stringsHash,
		selectedLocale,
		userEmail
	)
	updateNewStrings(userStringsResponse?.data || {}, reload)
}

export const changeLanguage = async (
	selectedLocale: string,
	reload: boolean = true
): Promise<void> => {
	const userStringsResponse = await changeUserLocale(selectedLocale)
	updateNewStrings(userStringsResponse?.data || {}, reload)
	updateSingletons()
}

const updateNewStrings = (userStringsResponseData: any, reload: boolean) => {
	const { userStrings, locale, stringsHash, currency } = userStringsResponseData
	if (currency) {
		setCurrencyInLocalStorage(currency)
	}
	if (userStrings) {
		if (locale) {
			setLocaleInLocalStorage(locale)
		}

		const reloadNotExistsInLocalStorage = getReloadFromLocalStorage() === ''
		const object = { userStrings, stringsHash }
		setJsonItemToLocalStorage('userStrings', object)
		setReloadInLocalStorage()
		if (!reload) {
			reload = reloadNotExistsInLocalStorage
		}
		reload && window.location.reload()
	}
}
