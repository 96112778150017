import { isEmpty } from 'lodash'
import moment from 'moment'

import {
	HANDLE_NOTIFICATION,
	UPLOAD_LICENSE_ADD_FILE,
	UPLOAD_LICENSE_ERROR,
	UPLOAD_LICENSE_FILE_END,
	UPLOAD_LICENSE_FILE_FINISH,
	UPLOAD_LICENSE_FILE_START,
	UPLOAD_LICENSE_SCRIPT,
	UPLOAD_LICENSE_SCRIPT_SUCCESS
} from '../../../global actions/types'
import { uploadLicenseScript } from '../../../Services/Network'
import {
	LICENSE_SUCCESS_ALERT_TEXT,
	SHOW_NOTIFICATION,
	UPDATE_DATABASE_ERROR_FILE_FORMAT,
	UPDATE_DATABASE_ERROR_FILE_LENGTH,
	UPDATE_DATABASE_FAILED,
	UPDATE_LICENSE_FAILED
} from '../../../Services/Strings'
import { zipFileTypes } from '../NewUploadProject/fileTypes'
import { SKIP_LICENSE_LS_KEY } from 'Services/Constants'
import { getStringItemFromLocalStorage } from 'Services/LocalStorageService'
import { getLicenseData } from 'Services/Network/UserNetwork'
import Poller from 'Services/PollingService/Poller'

let poller = new Poller()

export const licenseUploadedWithError = (message?: string) => {
	return async (dispatch: any) => {
		dispatch({
			type: HANDLE_NOTIFICATION,
			payload: {
				notificationType: SHOW_NOTIFICATION.ERROR,
				notificationMessage: message || UPDATE_DATABASE_FAILED
			}
		})
		dispatch({ type: UPLOAD_LICENSE_FILE_FINISH })
	}
}

export const onUploadFile = (sendFile: any): any => {
	return async (dispatch: any) => {
		dispatch({ type: UPLOAD_LICENSE_FILE_START })

		try {
			if (sendFile) {
				try {
					const licenseUploaded = await uploadLicenseScript(sendFile)

					if (!licenseUploaded) {
						dispatch(licenseUploadedWithError())
						return
					}
				} catch (err) {
					dispatch(licenseUploadedWithError())
					return
				}

				let timeOutDateTime = new Date()
				timeOutDateTime.setHours(timeOutDateTime.getHours() + 1)
				let checkingLicense = 0

				poller = new Poller(
					3000,
					timeOutDateTime,
					() => getLicenseData(),
					(res: any) =>
						(Object.keys(res?.data).length &&
							res.data.expire_date &&
							moment(new Date(res.data.expire_date))
								.endOf('day')
								.isAfter(new Date()) &&
							res.data.start_date &&
							moment(new Date(res.data.start_date))
								.startOf('day')
								.isBefore(new Date())) ||
						checkingLicense > 10,
					(res: any) => {
						checkingLicense++
					}
				)

				poller
					.start()
					.then((res: any) => {
						if (Object.keys(res?.data).length) {
							const isLicenseExpire =
								res.data.expire_date &&
								moment(new Date(res.data.expire_date))
									.endOf('day')
									.isSameOrBefore(new Date())

							const isLicenseStart =
								res.data.start_date &&
								moment(new Date(res.data.start_date))
									.startOf('day')
									.isBefore(new Date())

							if (isLicenseExpire || !isLicenseStart) {
								dispatch(licenseUploadedWithError(UPDATE_LICENSE_FAILED))
							} else {
								dispatch({
									type: HANDLE_NOTIFICATION,
									payload: {
										notificationType: SHOW_NOTIFICATION.SUCCESS,
										notificationMessage: LICENSE_SUCCESS_ALERT_TEXT
									}
								})

								dispatch({
									type: UPLOAD_LICENSE_SCRIPT,
									payload: true
								})

								dispatch({
									type: UPLOAD_LICENSE_SCRIPT_SUCCESS,
									payload: res?.data
								})

								dispatch({ type: UPLOAD_LICENSE_FILE_END })
							}
						} else {
							dispatch(licenseUploadedWithError())
						}
					})
					.catch((error: any) => {
						dispatch(licenseUploadedWithError())
					})
			}
		} catch (e) {
			dispatch(licenseUploadedWithError())
		}
	}
}

export const setFile = (newFile: any, readerResult: any): any => {
	return (dispatch: any) => {
		const file = newFile[0]
		const multipleFiles = newFile.length > 1
		const wrongType = !zipFileTypes.includes(file.type)
		const error = multipleFiles
			? UPDATE_DATABASE_ERROR_FILE_LENGTH
			: wrongType
			? UPDATE_DATABASE_ERROR_FILE_FORMAT
			: ''

		if (!isEmpty(error)) {
			dispatch({ type: UPLOAD_LICENSE_ERROR, payload: { error } })
		} else {
			const preparedToSend = {
				data: readerResult?.split(',').pop(),
				fileName: file.name
			}
			dispatch({
				type: UPLOAD_LICENSE_ADD_FILE,
				payload: { file, preparedToSend }
			})
		}
	}
}

export const updateLicenseValue = (license: boolean) => {
	return (dispatch: any) => {
		const skipLicense = getStringItemFromLocalStorage(SKIP_LICENSE_LS_KEY)
		if (!skipLicense) {
			dispatch({
				type: UPLOAD_LICENSE_SCRIPT,
				payload: license
			})
		}
	}
}

export const getLicenseForUser = () => {
	return async (dispatch: any) => {
		try {
			const isLicenseExist = await getLicenseData()
			dispatch({
				type: UPLOAD_LICENSE_FILE_FINISH
			})
			return isLicenseExist
		} catch (err) {
			console.error(err)
		}
	}
}
