import React, { FC } from 'react'
import { IOtherProps } from '../../../Services/models/IOtherProps'
import { FeaResult } from '../../../Services/models/ISolutionFea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleRight
} from '@fortawesome/free-solid-svg-icons'

interface Props extends IOtherProps {
  type: string
}

export const IconFeaArrows: FC<Props> = ({ type, className }) => {
  switch (type) {
    case FeaResult.passed:
      return (
        <FontAwesomeIcon
          icon={faAngleDoubleRight as any}
          className={className}
        />
      )
    case FeaResult.borderline:
      return (
        <FontAwesomeIcon icon={faAngleRight as any} className={className} />
      )
    case FeaResult.failed:
      return (
        <FontAwesomeIcon
          icon={faAngleDoubleLeft as any}
          className={className}
        />
      )
    default:
      return <div></div>
  }
}
