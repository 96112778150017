import React, { memo, FC } from 'react'
import { map } from "lodash";
import { InjectedFormProps, Field, reduxForm } from 'redux-form'
import Flexbox from 'Scenes/Components/FlexBox'

import { leadTimeFormData } from './constants';
import { renderNumberField } from 'Scenes/admin/AdminHome/AdminFields/AdminNumberField'
import {
  COST_AND_LEAD_ACTION_BUTTON_CANCEL_TEXT,
  SUBMIT,
} from 'Services/Strings'
import TransparentButton from 'Scenes/Components/TransparentButton'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'

import '../LeadTimeTab.scss'
import { validateForm } from '../LeadTimeService';

const ReduxFormField: any = Field

interface IProps {
  showCancelButton: boolean
  leadTimeLoading: boolean
  onCancel: Function
  onLeadFormSubmit: any
  isCnc: boolean
}

interface IData {
  name: string,
  label: string,
  iIcon?: string,
  isCnc?: boolean
}

const LeadTimeForm: FC<InjectedFormProps<any, IProps> & IProps> = ({
  handleSubmit,
  change,
  showCancelButton,
  onCancel,
  leadTimeLoading,
  onLeadFormSubmit,
  isCnc,
  valid
}) => {
  const renderCancelButton = () => {
    if (!showCancelButton) {
      return <div />
    }
    return (
      <TransparentButton
        onClick={() => onCancel()}
        className="financial-form-button"
      >
        {COST_AND_LEAD_ACTION_BUTTON_CANCEL_TEXT}
      </TransparentButton>
    )
  }

  const renderFooter = () => {
    return (
      <div className="financial-form-buttons">
        {renderCancelButton()}
        <ButtonWithLoader
          disabled={!valid}
          color="primary"
          className="financial-form-button-submit"
          onClick={handleSubmit(onLeadFormSubmit)}
          loading={leadTimeLoading}
        >
          {SUBMIT}
        </ButtonWithLoader>
      </div>
    )
  }
  const renderForms = () => {
    return (
      <div className="lead-time-form">
        {
          map(leadTimeFormData, (data: IData) => {
          if (!isCnc && data.isCnc) {
            return <></>
          }

          return (
            <ReduxFormField
              validate={validateForm}
              name={data?.name}
              component={renderNumberField}
              shrink={true}
              className="admin-form-field"
              label={data?.label}
              type="number"
              change={change}
              iIcon={data?.iIcon}
            />
          )
        })
        }
      </div>
    )
  }

  return (
    <Flexbox flexDirection="column" padding="0 5px 0 0">
      {renderForms()}
      {renderFooter()}
    </Flexbox>
  )
}

export default memo(reduxForm<any, IProps>({})(LeadTimeForm))
