import {
  ACCURACY_CHECKBOX_CLICKED,
  OFFTHESHELF_CHECKBOX_CLICKED,
  SURFACE_FINISH_CHECKBOX_CLICKED,
  ACCURACY_CHECKBOX_UPDATED,
  OFFTHESHELF_CHECKBOX_UPDATED,
  SURFACE_FINISH_CHECKBOX_UPDATED,
  SETUP_COMPONENT,
  HIDE_ALERT
} from '../../../../global actions/types'
import {
  SURFACE_FINISH_ALERT_TITLE,
  OFF_THE_SHELF_ALERT_TITLE,
  HIGH_ACCURACY_ALERT_TITLE,
  REQUIREMENTS_ALERT_TEXT,
  OFF_THE_SHELF_ALERT_TEXT,
  SURFACE_FINISH_ALERT_TEXT,
  HIGH_ACCURACY_ALERT_TEXT
} from '../../../../Services/Strings'
const INITIAL_STATE = {
  offTheShelfDisabled: false,
  accuracyDisabled: false,
  surfaceFinishDisabled: false,
  error: null,
  currantPart: null,
  popup: null
}
const createPopup = (payload, title, text) => {
  if (payload.value && !payload.error) {
    return { title: title, text: text ? text : REQUIREMENTS_ALERT_TEXT }
  }
  return null
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETUP_COMPONENT:
      const currantPart = action.payload
      return { ...state, currantPart }
      break
    case ACCURACY_CHECKBOX_CLICKED:
      return { ...state, accuracyDisabled: true, popup: null }
      break
    case OFFTHESHELF_CHECKBOX_CLICKED:
      return { ...state, offTheShelfDisabled: true, popup: null }

      break
    case SURFACE_FINISH_CHECKBOX_CLICKED:
      return { ...state, surfaceFinishDisabled: true, popup: null }
      break
    case ACCURACY_CHECKBOX_UPDATED:
      const payload = action.payload
      const oldPart = state.currantPart
      oldPart.needsHighAccuracy = payload.value
      const popup = createPopup(
        payload,
        HIGH_ACCURACY_ALERT_TITLE,
        HIGH_ACCURACY_ALERT_TEXT
      )
      return {
        ...state,
        accuracyDisabled: false,
        error: payload.error,
        currantPart: oldPart,
        popup
      }

      break
    case OFFTHESHELF_CHECKBOX_UPDATED:
      {
        const payload = action.payload

        state.currantPart.isOffTheShelf = payload.value
        const currantPart = {
          ...state.currantPart,
          isOffTheShelf: payload.value
        }
        const popup = createPopup(
          payload,
          OFF_THE_SHELF_ALERT_TITLE,
          OFF_THE_SHELF_ALERT_TEXT
        )

        return {
          ...state,
          offTheShelfDisabled: false,
          error: payload.error,
          currantPart,
          popup
        }
      }
      break
    case SURFACE_FINISH_CHECKBOX_UPDATED: {
      const payload = action.payload

      const currantPart = {
        ...state.currantPart,
        needsSurfaceFinish: payload.value
      }
      const popup = createPopup(
        payload,
        SURFACE_FINISH_ALERT_TITLE,
        SURFACE_FINISH_ALERT_TEXT
      )

      return {
        ...state,
        surfaceFinishDisabled: false,
        error: payload.error,
        currantPart,
        popup
      }
    }
    case HIDE_ALERT:
      return { ...state, popup: null }
      break
    default:
      return state
  }
}
