import { UploadProjectActionService } from '../../Scenes/Home/NewUploadProject/UploadProjectActionService'
import { UploadProjectService } from '../evonik/customServices/UploadProjectService'

export class UploadProjectServiceBuilder {
  constructor() {
    switch (process.env.REACT_APP_THEME_NAME) {
      case 'evonik':
        return new UploadProjectService()

      default:
        return new UploadProjectActionService()
    }
  }
}
