import React, { FC, useMemo } from 'react'

import { MenuItem, Select } from '@material-ui/core'

import { assemblingCostOptions } from './CostAnalysisTableConstants'
import { useAssemblingCostModal } from './CostAnalysisTableHooks'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import NumberField from 'Scenes/Components/NumberField'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { ZERO_NUMBER_FIELD_VALUE } from 'Services/Constants'
import { AssemblingParams } from 'Services/models/IConfiguration'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	configurationId: number
	showAssemblingCostAlert: boolean
	onAssemblyModalCancel: () => void
	assemblingParams?: AssemblingParams | null
}

const AssemblingCostModal: FC<IProps> = ({
	configurationId,
	onAssemblyModalCancel,
	showAssemblingCostAlert,
	assemblingParams
}) => {
	const {
		type,
		value,
		loading,
		confirmDisabled,
		onModalConfirm,
		onModalClose,
		onTypeChange,
		onValueChange,
		renderTypeTitle
	} = useAssemblingCostModal(
		configurationId,
		onAssemblyModalCancel,
		assemblingParams,
		showAssemblingCostAlert
	)

	const renderTypeValue = (selected?: unknown): any => {
		const option = assemblingCostOptions.find(
			option => option.type === selected
		)
		return (
			<div className="assembling-cost--rendered-value">
				{option?.text}
				<DetailsPopup
					isHover={!!option?.info}
					data={<div className="part-detail-title">{option?.info}</div>}
					popperDirection="auto"
					popperClassName="info-box-wrapper"
					popperContactClassName="info-box-data"
					inPortal
				>
					<IconFactory
						iconName="info"
						className="data-table-row-icon-part assembling-cost--info-icon"
					/>
				</DetailsPopup>
			</div>
		)
	}

	const selectedOption = useMemo(
		() => assemblingCostOptions.find(option => option.type === type),
		[type]
	)

	return (
		<CastorAlert
			headerTitle={getString('ASSEMBLY_COST_MODAL_HEADER')}
			onCancel={onModalClose}
			show={showAssemblingCostAlert}
			disabled={confirmDisabled}
			onConfirm={onModalConfirm}
			confirmOptionalText={getString('APPLY')}
			loadingCalculation={loading}
			alertBodyClass="assembling-cost--alert-body"
			inPortal
		>
			<div className="assembling-cost--wrapper">
				<span>{getString('SET')}</span>
				<div>
					<Select
						className="customize-post-process--select-field"
						value={type}
						renderValue={renderTypeValue}
						onChange={onTypeChange}
						title={renderTypeTitle(type)}
						classes={{
							select: 'customize-post-process--select'
						}}
						inputProps={{
							name: 'simpleSelect',
							id: 'simple-select'
						}}
						MenuProps={{
							PaperProps: {
								style: {
									transform: 'translate3d(0, 0, 0)'
								}
							}
						}}
					>
						{assemblingCostOptions.map(option => {
							return (
								<MenuItem key={option.type} value={option.type}>
									{option.text}
									<DetailsPopup
										isHover={!!option.info}
										data={
											<div className="part-detail-title">{option.info}</div>
										}
										popperDirection="auto"
										popperClassName="info-box-wrapper"
										popperContactClassName="info-box-data"
										inPortal
									>
										<IconFactory
											iconName="info"
											className="data-table-row-icon-part assembling-cost--info-icon"
										/>
									</DetailsPopup>
								</MenuItem>
							)
						})}
					</Select>
				</div>
				{selectedOption?.showInput && (
					<div>
						<NumberField
							variant="outlined"
							value={value}
							onChangeValue={onValueChange}
							inputClass="field-with-label outlined customize-post-process--select-field customize-post-process--number-input"
							minValue={ZERO_NUMBER_FIELD_VALUE}
							allowZero={false}
							changeOnScroll={false}
							isCommaDisabled={false}
							isDotDisabled={false}
							allowEmpty
							showArrows
						/>
					</div>
				)}
			</div>
		</CastorAlert>
	)
}

export default AssemblingCostModal
