export const itemBtn = {
	backgroundColor: 'transparent',
	color: 'unset'
}

export const btnDiv = {
	display: 'flex',
	alignItems: 'center'
}

export const divider = {
	width: '90%',
	margin: '0 auto'
}

export const userMenuTarget = {
	backgroundColor: 'transparent',
	display: 'flex',
	justifyContent: 'space-between',
	color: 'unset',
	lineHeight: '1.5em',
	paddingLeft: '5px',
	borderRadius: '3px',
	fontSize: '1.2em'
}

export const margin = '10px 15px 0'
