import React, { FC } from 'react'

import { IPartsToPrintSummary } from '../../ProjectAnalysisInterfaces'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { PART, PARTS } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	partsToPrintSummary: IPartsToPrintSummary[]
	filteredPartsCount: number
	projectNames: string[]
}

const PartsSummaryPDF: FC<IProps> = ({
	partsToPrintSummary,
	filteredPartsCount,
	projectNames
}) => {
	return (
		<div className="export-pdf--parts-summary">
			<div className="export-pdf--parts-summary--top-block">
				<div className="export-pdf--parts-summary--top-block--names">
					{projectNames.join(', ')}
				</div>
				<div className="export-pdf--parts-summary--top-block--count">
					{getString('FILTERED_PARTS').format(
						`${filteredPartsCount} ${filteredPartsCount > 1 ? PARTS : PART}`
					)}
				</div>
			</div>
			<div className="export-pdf--parts-summary--bottom-block">
				{partsToPrintSummary.map(parts => {
					return (
						<div
							className="export-pdf--parts-summary--bottom-block--status-row"
							key={parts.status}
						>
							<IconFactory iconName={parts.icon} />
							<div>
								{parts.count} {parts.status}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default PartsSummaryPDF
