import React, { FC, memo } from 'react'

import DataTableIcon from './DataTableIcon'

import './DataTable.scss'

interface Props {
	text: string | number
	headerClassName: string
	data: any
}

const DataTableTextHeader: FC<Props> = ({
	text,
	headerClassName,
	data: { className = '', iconName = '', hoverText = '', iconClassName = '' }
}) => {
	return (
		<div className={`data-table-header ${headerClassName} ${className}`}>
			{text}
			{iconName && (
				<DataTableIcon
					hoverText={hoverText}
					iconClassName={iconClassName}
					iconName={iconName}
					classNames={`data-table-text-field`}
				/>
			)}
		</div>
	)
}

export default memo(DataTableTextHeader)
