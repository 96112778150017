import React, { FC, memo } from 'react'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import {
  CUSTOMIZE_DRAWING_COST_TITLE,
  CUSTOMIZE_DRAWING_COST_EXPLANATION
} from 'Services/Strings'
import Customize2DDrawingCostTbl from './Customize2DDrawingCostTbl'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'

import './Customize2DDrawingCost.scss'

interface IProps {}

const Customize2DDrawingCost: FC<IProps> = ({}) => {
  return (
    <div className="drawing-2d-cost" data-qa="data-qa-drawing-2d-cost">
      <CastorFormHeader
        explanationHeader={CUSTOMIZE_DRAWING_COST_TITLE}
        explanationArray={CUSTOMIZE_DRAWING_COST_EXPLANATION}
        isInCard={true}
      />
      <Customize2DDrawingCostTbl />
    </div>
  )
}

export default WithFeatureToggleHOC(
  memo(Customize2DDrawingCost),
  FeatureComponentId.DRAWING_ANALYSIS
)
