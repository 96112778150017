import React, { FC, memo } from 'react'

import cx from 'classnames'

import HeaderCard from 'Scenes/Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import { getTheme } from 'themes/getTheme'

import './index.scss'

const { cardBoxClass, cardBoxColor } = getTheme()

interface CardBoxProps {
	content: React.ReactElement
	title: string | number
	contentClass: string | null
	cardClass?: string
}

const CardHeaderBox: FC<CardBoxProps> = ({
	content,
	title,
	contentClass,
	cardClass
}) => {
	return (
		<HeaderCard
			classes={{
				card: cx(cardBoxClass, cardClass),
				cardHeader: 'card-header'
			}}
			contentRootClassName={contentClass}
			cardTitle={title}
			cardSubtitle=""
			headerColor={cardBoxColor || 'green'}
			content={content}
		/>
	)
}

export default memo(CardHeaderBox)
