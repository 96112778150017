import Flexbox from 'Scenes/Components/FlexBox'
import { FC, memo, useState } from 'react'

import { SelectedScenarioInterface } from '../../../Customize/CustomizeSupplyChain/types'
import ProjectLifeCycleAlert from './ProjectLifeCycleAlert'
import CastorSelectBox from 'Scenes/Components/CastorSelectBox'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { UPLOAD_PROJECT_SCENARIO_METHODS_HEADER } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import inPogressImg from '../../../../../assets/img/uploadPageScenario.png'

interface IProps {
	projectSettingsScenario: any
	loadingLifeCycle: boolean
	selectedProjectScenario: SelectedScenarioInterface
	userScenario: any
	onChangeProjectSettingsScenarios: Function
}

const UploadProjectScenarios: FC<IProps> = ({
	projectSettingsScenario,
	selectedProjectScenario,
	userScenario,
	onChangeProjectSettingsScenarios
}) => {
	const [showLifeCycleAlert, setShowLifeCycleAlert] = useState(false)
	const preparedMenuItem = () => {
		return Object.keys(projectSettingsScenario).map((table: any) => {
			const scenarioName =
				selectedProjectScenario.name ||
				projectSettingsScenario[(Number(userScenario) - 1).toString()]?.key
			const element = projectSettingsScenario[table]
			const selected = scenarioName === projectSettingsScenario[table]?.key

			return (
				<DetailsPopup
					key={element.key}
					isHover={true}
					data={getString('UPLOAD_PROJECT_SCENARIO_HOVER_TEXT')[element.key]}
					popperDirection="bottom-start"
					popperClassName="info-box-wrapper"
					popperContactClassName="info-box-data"
				>
					<CastorSelectBox
						key={element.key}
						qaDataElementName={`data-qa-scenario-${element.key}`}
						setSelectedType={() =>
							onChangeProjectSettingsScenarios(
								element.key,
								projectSettingsScenario[table]
							)
						}
						selected={selected}
					>
						<>{UPLOAD_PROJECT_SCENARIO_METHODS_HEADER[element.key]}</>
					</CastorSelectBox>
				</DetailsPopup>
			)
		})
	}

	return (
		<Flexbox
			className="upload-form__scenario"
			alignItems="center"
			justifyContent="flex-start"
			width="100%"
		>
			<Flexbox
				className="tolerance-check-box"
				alignItems="flex-start"
				flexDirection="column"
				width="100%"
			>
				<Flexbox
					className="label"
					alignItems="center"
					alignContent="flex-start"
					flexDirection="row"
				>
					<Button
						className="tolerance-check-box-icon"
						justIcon
						onClick={() => setShowLifeCycleAlert(true)}
						color="transparent"
					>
						<IconFactory
							iconName="info"
							className="upload-form-scenario-info-icon"
						/>
					</Button>

					{getString('UPLOAD_PROJECT_SCENARIO_TEXT')}
					{showLifeCycleAlert && (
						<ProjectLifeCycleAlert
							changeToleranceAlert={() => setShowLifeCycleAlert(false)}
							showToleranceAlert={showLifeCycleAlert}
							imgSrc={inPogressImg}
						></ProjectLifeCycleAlert>
					)}
				</Flexbox>
				<Flexbox
					className="upload-form__scenario-block"
					alignContent="space-between"
					justifyContent="space-between"
					flexDirection="row"
					width="100%"
					data-qa={`data-qa-scenario-block`}
				>
					{userScenario && preparedMenuItem()}
				</Flexbox>
			</Flexbox>
		</Flexbox>
	)
}

export default WithFeatureToggleHOC(
	memo(UploadProjectScenarios),
	FeatureComponentId.PROJECT_SETTINGS_SCENARIOS
)
