import React, { FC, memo } from 'react'

import CostAnalysisTable from '.'
import {
	Feature,
	FeatureComponentId
} from '../../../../../../../../../../Services/models/Features'
import { Part } from '../../../../../../../../../../Services/models/IPart'
import { TraditionalManufacturingMethod } from '../../common'
import CostAnalysisTableButtons from './CostAnalysisTableButtons/CostAnalysisTableButtons'
import CostAnalysisTableSwitch from './CostAnalysisTableSwitch'
import Devider from 'Scenes/Components/Devider/Devider'
import Loader from 'Scenes/Loader/Loader'
import { AssemblingParams } from 'Services/models/IConfiguration'
import { PartPrintIssue } from 'Services/models/PartPrintIssue'

interface IProps {
	traditionalMethod: string
	show: boolean
	openSwitch: boolean
	showAnalysisTable: boolean
	solution: any
	configuration: any
	onAnalysisTableSwitchChange: Function
	chainBenefits: any
	costLoading: boolean
	part: Part
	showSwitchButton: boolean
	showAssemblingCostAlert: boolean
	assemblingParams: AssemblingParams | null
	showTMSwitch: boolean
	partPrintIssues: PartPrintIssue[]
	toggleAssemblingCostModal: (id: number, open: boolean) => void
	onManufacturingMethodChange: Function
}

const CostAnalysisTableAndSwitch: FC<IProps> = ({
	traditionalMethod,
	solution,
	configuration,
	showAnalysisTable,
	show,
	openSwitch,
	onAnalysisTableSwitchChange,
	chainBenefits,
	costLoading,
	part,
	showSwitchButton,
	showAssemblingCostAlert,
	assemblingParams,
	showTMSwitch,
	partPrintIssues,
	toggleAssemblingCostModal,
	onManufacturingMethodChange
}) => {
	if (!show) {
		return <div></div>
	}
	const costTabOn = Feature.isFeatureOn(FeatureComponentId.COST_TAB_INFORMATION)

	return (
		<div
			className={`cost-comparison-tab--table ${
				showAnalysisTable ? 'show' : ''
			}`}
		>
			<Loader
				load={showTMSwitch && costLoading}
				wrapperClassName="cost-comparison-tab--table--loader"
			/>
			{!costTabOn && (
				<CostAnalysisTableButtons
					openSwitch={showAnalysisTable}
					onChange={onAnalysisTableSwitchChange}
					show={show}
				/>
			)}

			<CostAnalysisTable
				traditionalMethod={traditionalMethod}
				solution={solution}
				configuration={configuration}
				chainBenefits={chainBenefits}
				part={part}
				showAssemblingCostAlert={showAssemblingCostAlert}
				assemblingParams={assemblingParams}
				toggleAssemblingCostModal={toggleAssemblingCostModal}
			/>

			{costTabOn && showSwitchButton && openSwitch && (
				<CostAnalysisTableSwitch
					openSwitch={showAnalysisTable}
					onChange={onAnalysisTableSwitchChange}
					show={show}
					costLoading={costLoading}
				/>
			)}

			{showTMSwitch && (
				<>
					<Devider />
					<TraditionalManufacturingMethod
						isLeadTime={false}
						configuration={configuration}
						part={part}
						manufacturingMethod={traditionalMethod}
						onManufacturingMethodChange={onManufacturingMethodChange}
						partPrintIssues={partPrintIssues}
					/>
				</>
			)}
		</div>
	)
}

export default memo(CostAnalysisTableAndSwitch)
