// ##############################
// // // RegisterPage view styles
// #############################
import { container } from '../../material-dashboard-pro-react.jsx'
import customCheckboxRadioSwitch from '../../material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'

const registerPageStyle = {
	content: {
		paddingTop: '18vh',
		minHeight: 'calc(100vh - 80px)',
		position: 'relative',
		zIndex: '4'
	},
	container: {
		...container,
		position: 'relative',
		zIndex: '3'
	},
	cardHeader: {
		minWidth: '330px',
		width: 'fit-content',
		margin: '-40px auto 0'
	},
	cardClasses: {
		boxShadow:
			'0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
		marginBottom: '100px',
		padding: '20px 0px'
	},
	center: {
		textAlign: 'center'
	},
	right: {
		textAlign: 'right'
	},
	left: {
		textAlign: 'left'
	},
	form: {
		padding: '0 20px',
		position: 'relative'
	},
	socialTitle: {
		fontSize: '18px'
	},
	inputAdornment: {
		marginRight: '18px',
		top: '18px',
		position: 'center'
	},
	inputAdornmentIcon: {
		color: '#555'
	},
	customFormControlClasses: {
		margin: '0 12px'
	},
	checkboxLabelControl: {
		margin: '0'
	},
	checkboxLabel: {
		marginLeft: '6px',
		color: 'rgba(0, 0, 0, 0.26)'
	},
	...customCheckboxRadioSwitch,
	cardTitle: {},
	wrapper: {
		height: 'auto',
		minHeight: '100vh',
		position: 'relative',
		top: '0',
		overflow: 'hidden'
	},
	fullPage: {
		'&:before': {
			backgroundColor: 'rgba(0, 0, 0, 0.65)'
		},
		'&:before,&:after': {
			display: 'block',
			content: '""',
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: '0',
			left: '0',
			zIndex: '2'
		}
	},
	fullPageBackground: {
		position: 'absolute',
		zIndex: '1',
		height: '100%',
		width: '100%',
		display: 'block',
		top: '0',
		left: '0',
		backgroundSize: 'cover',
		backgroundPosition: 'center center'
	}
}

export default registerPageStyle
