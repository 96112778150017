import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'

import { generateKey } from '../../../Services/Utils/uniqKeyGenerator'

import { styles } from './styles.css'

const CastorFormHeader = props => {
	const { explanationArray, explanationHeader, isInCard, extraExplanation } =
		props
	let paragraph =
		explanationArray.length > 0 &&
		(isObject(explanationArray) || isArray(explanationArray)) &&
		explanationArray.map((string, index) => (
			<div key={generateKey(string)}>
				<p style={styles.explanationText}>{string}</p>
			</div>
		))

	paragraph &&
		explanationHeader &&
		paragraph.unshift(
			<h3 key={explanationHeader} style={styles.explanationTextHeader}>
				{explanationHeader}
			</h3>
		)

	return (
		<div
			style={isInCard ? styles.topCardText : styles.topText}
			className="form-header-wrapper"
		>
			{paragraph}
			{extraExplanation && (
				<p style={styles.extraExplanationText}>{extraExplanation}</p>
			)}
		</div>
	)
}

export default CastorFormHeader
