import React, { memo, FC } from 'react'

import './DataTable.scss'

interface Props {
  text: string | number
  className?: string
}

const DataTableOpacityTextField: FC<Props> = ({ text, className = '' }) => (
  <div className={`data-table-opacity-field ${className}`}>{text}</div>
)

export default memo(DataTableOpacityTextField)
