import { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import SolutionConfigureFeaturesFilters from '../SolutionConfigure/SolutionConfigureFeatures/SolutionConfigureFeaturesFilters'
import {
	resetAdvancedFilters,
	saveAdvancedFilters
} from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import { PRINTER_TECHNOLOGY } from 'Scenes/Home/NewPartAnalysis/NewPartConfiguration/constants'
import { IFilter } from 'Services/models/IFilter'
import { PART_ANALYSIS_ADD_FEATURES_FILTERS } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const FilterFeaturesModal: FC<any> = ({ ...props }) => {
	const dispatch = useDispatch()
	const {
		showFilterFeaturesModal,
		onFilterModalCancel,
		onFilterModalConfirm,
		configuration
	} = props

	const { filters, isError } = useSelector(
		(state: RootStateOrAny) =>
			state.AdvancedSettingsReducer?.advancedStates[configuration.id] ||
			new AdvancedSettingsInitialState()
	)

	const handleAlertConfirm = () => {
		dispatch(saveAdvancedFilters(configuration.id))
		onFilterModalConfirm(configuration.id, filters)
	}

	const cancelAlertConfirm = () => {
		dispatch(resetAdvancedFilters(configuration.id))
		onFilterModalCancel(configuration)
	}

	return (
		<CastorAlert
			headerTitle={PART_ANALYSIS_ADD_FEATURES_FILTERS}
			onCancel={cancelAlertConfirm}
			show={showFilterFeaturesModal}
			onConfirm={handleAlertConfirm}
			mediumSize={true}
			disabled={isError}
		>
			<SolutionConfigureFeaturesFilters
				id={configuration.id}
				explanationArray={getString('ADD_FILTER_FIELDS_DESCRIPTION')}
			/>
		</CastorAlert>
	)
}

export default memo(FilterFeaturesModal)
