import React, { FC, memo } from 'react'

import PackageJson from '../../../../package.json'
import WithFeatureToggleHOC from '../../../Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from '../../../Services/models/Features'

import './VersionViewer.scss'

interface IProps {}

const VersionViewer: FC<IProps> = ({}) => {
  return (
    <div className="version-viewer" data-qa="data-qa-version-viewer">
      Ver: <span className="version-viewer--ver">{PackageJson.version}</span>
    </div>
  )
}

export default WithFeatureToggleHOC(
  memo(VersionViewer),
  FeatureComponentId.VERSION_VIEWER
)
