import React, { ChangeEvent, Component } from 'react'

import { ENTER_KEY_CODE } from '../../../Services/Constants'
import IconFactory from '../StarIcon/IconFactory'
import { Button, CustomInput } from '../thirdParty/CreativeTim/components'

const CustomInputTSX: any = CustomInput

interface Props {
	searchPhrase: string
	setSearchPhrase: Function
	placeholder?: string
	triggerUpdateField?: any
}

interface State {
	searchPhrase: string
	triggerUpdateField?: any
}

export default class CastorServerSearchBar extends Component<Props, State> {
	state: State
	props: Props

	constructor(props: Props) {
		super(props)
		this.state = {
			searchPhrase: props.searchPhrase,
			triggerUpdateField: props.triggerUpdateField
		}
		this.props = props
	}

	componentDidUpdate(prevProps: Readonly<Props>) {
		if (prevProps.triggerUpdateField !== this.props.triggerUpdateField) {
			this.setState({ searchPhrase: this.props.searchPhrase })
		}
	}

	onSearchClick = () => {
		this.props.setSearchPhrase(this.state.searchPhrase)
	}

	onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.setState({ searchPhrase: e.target.value })
	}

	onKeyUp = (e: KeyboardEvent) => {
		if (e.keyCode === ENTER_KEY_CODE) {
			this.onSearchClick()
		}
	}

	render() {
		return (
			<div>
				<CustomInputTSX
					formControlProps={{
						style: { margin: 'unset' }
					}}
					inputProps={{
						value: this.state.searchPhrase,
						placeholder: this.props.placeholder || 'Search by name',
						inputProps: {
							'aria-label': 'Search'
						},
						style: { margin: 'unset' },
						onKeyUp: this.onKeyUp,
						onChange: this.onSearchChange
					}}
				/>
				<Button
					onClick={this.onSearchClick}
					color="white"
					aria-label="search-button"
					justIcon
					round
				>
					<IconFactory iconName="search" />
				</Button>
			</div>
		)
	}
}
