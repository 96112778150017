import { cloneDeep } from 'lodash'

import {
	CHANGE_PROJECTS_SEARCH_PHRASE,
	END_ADDING_NEW_FOLDER,
	GET_PROJECTS,
	GET_PROJECTS_FAILED,
	GET_PROJECTS_SUCCESS,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	START_ADDING_NEW_FOLDER,
	TOGGLE_CREATE_PROJECTS_FOLDER,
	USER_HOME_DRAW_TOGGLE,
	USER_HOME_ROUTE_CHANGED,
	USER_HOME_ROUTES_CHANGED
} from '../../global actions/types'
import { store } from '../../index'
import {
	createUserProjectFolder,
	deleteUserProjectFolder,
	getProjects,
	updateProjectPlacement,
	updateUserProjectFolder
} from '../../Services/Network'
import { getShowSideBarValue } from './UserHomeService'
import {
	ALERT_CALCULATION_STARTED,
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { checkReanalysisProjectsPoller } from 'global actions/UserActions'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { SHOW_NOTIFICATION, YES } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { gerUrlParams } from 'Services/Utils/urlTools'

export const setupUserHome = features => {
	return async dispatch => {
		const { maxAllowedUploadProjects, roles } = store.getState().user

		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})
		dispatch({ type: GET_PROJECTS })

		try {
			const { showSideBar = true } = gerUrlParams(['showSideBar'])
			const sideBarIsOn = getShowSideBarValue(showSideBar)

			const response = await getProjects()
			const projects = response?.data?.projects
			const userProjectFolders = response?.data?.userProjectFolders
			if (projects && projects[0]?.owner?.id) {
				dispatch({
					type: GET_PROJECTS_SUCCESS,
					payload: {
						projects,
						features,
						maxAllowedUploadProjects,
						showSideBar: sideBarIsOn,
						roles,
						userProjectFolders
					}
				})
				dispatch(checkReanalysisProjectsPoller(projects[0]?.owner?.id))
			} else {
				dispatch({
					type: GET_PROJECTS_SUCCESS,
					payload: {
						projects,
						features,
						maxAllowedUploadProjects,
						showSideBar: sideBarIsOn,
						roles,
						userProjectFolders
					}
				})
			}
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		} catch (error) {
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
			console.log(error)
			dispatch({ type: GET_PROJECTS_FAILED, payload: error.message })
		}
	}
}

export const drawToggledUser = () => {
	return { type: USER_HOME_DRAW_TOGGLE }
}

export const onRouteChange = crumbs => {
	return { type: USER_HOME_ROUTE_CHANGED, payload: { crumbs } }
}

export const onUserRoutesOrderChange = (
	routes,
	newOrderDate,
	projectId,
	bundleId,
	userProjectFolderId,
	folderId
) => {
	return async dispatch => {
		const { routes: originalRoutes } = store.getState().userHome
		const fallbackCopy = cloneDeep(originalRoutes)
		try {
			dispatch({ type: USER_HOME_ROUTES_CHANGED, payload: { routes } })
			// update in the background
			if (folderId) {
				await updateUserProjectFolder(folderId, newOrderDate)
				return
			}
			await updateProjectPlacement(
				projectId,
				newOrderDate,
				bundleId,
				userProjectFolderId
			)
		} catch (err) {
			console.log(err)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('SOMETHING_WENT_WRONG')
				}
			})
			// roll back to prev routes state in case of error
			dispatch({
				type: USER_HOME_ROUTES_CHANGED,
				payload: { routes: fallbackCopy }
			})
		}
	}
}

export const onProjectsSearchPhraseChange = projectsSearchPhrase => {
	return dispatch => {
		dispatch({
			type: CHANGE_PROJECTS_SEARCH_PHRASE,
			payload: { projectsSearchPhrase }
		})
	}
}

export const onNewUserFolderClick = () => {
	return dispatch => {
		dispatch({
			type: TOGGLE_CREATE_PROJECTS_FOLDER,
			payload: { showCreateNewFolder: true }
		})
	}
}

export const onNewUserFolderCancel = () => {
	return dispatch => {
		dispatch({
			type: TOGGLE_CREATE_PROJECTS_FOLDER,
			payload: { showCreateNewFolder: false }
		})
	}
}

export const onNewUserFolderConfirm = (name, resetNewFolder) => {
	return async dispatch => {
		try {
			dispatch({ type: START_ADDING_NEW_FOLDER })
			const { features, maxAllowedUploadProjects, roles } =
				store.getState().user
			await createUserProjectFolder(name)
			const response = await getProjects()
			const projects = response?.data?.projects
			const userProjectFolders = response?.data?.userProjectFolders
			dispatch({
				type: GET_PROJECTS_SUCCESS,
				payload: {
					projects,
					features,
					maxAllowedUploadProjects,
					roles,
					userProjectFolders
				}
			})
			dispatch({ type: END_ADDING_NEW_FOLDER })
			resetNewFolder()
		} catch (error) {
			console.log(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('SOMETHING_WENT_WRONG')
				}
			})
			dispatch({ type: END_ADDING_NEW_FOLDER })
		}
	}
}

export const deleteUserFolder = folderId => {
	return async dispatch => {
		try {
			const { features, maxAllowedUploadProjects, roles } =
				store.getState().user
			await deleteUserProjectFolder(folderId)
			const response = await getProjects()
			const projects = response?.data?.projects
			const userProjectFolders = response?.data?.userProjectFolders
			dispatch({
				type: GET_PROJECTS_SUCCESS,
				payload: {
					projects,
					features,
					maxAllowedUploadProjects,
					roles,
					userProjectFolders
				}
			})
			dispatch({
				type: ALERT_POPUP_CANCELED
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: ALERT_POPUP_CANCELED
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('SOMETHING_WENT_WRONG')
				}
			})
		}
	}
}

export const onUserFolderDelete = folderId => {
	return dispatch => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: getString('DELETE_USER_FOLDER_WARNING_DESCRIPTION'),
				headerTitle: getString('CONFIGURATION_CHANGES_WARNING'),
				alertType: AlertType.WARNING,
				showCancel: true,
				onConfirm: () => {
					dispatch({
						type: ALERT_CALCULATION_STARTED
					})
					dispatch(deleteUserFolder(folderId))
				},
				confirmText: YES
			}
		})
	}
}

export const searchFoldersAndProjects = (searchPhrase, routes) => {
	return dispatch => {
		const { features } = store.getState().user
		const projectBundleIsOn = Feature.isFeatureOn(
			FeatureComponentId.PROJECT_BUNDLE_PAGE,
			features
		)
		const routesCopy = cloneDeep(routes)
		const newRoutes = routesCopy.map(route => {
			if (route.name !== getString('NAV_TITLE_MY_PROJECTS')) {
				return route
			} else {
				const projectRoutes = route.views

				if (projectRoutes.length) {
					const newProjectRoutes = projectRoutes.reduce((acc, view) => {
						if (view?.views?.length) {
							const innerViews = view?.views.filter(view => {
								const projectName =
									projectBundleIsOn && view.folderName
										? view.folderName
										: view.name
								return projectName
									.toLowerCase()
									.includes(searchPhrase.toLowerCase())
							})
							// if projects in folder match - push them together with folder
							if (innerViews.length) {
								view.views = innerViews
								acc.push(view)
								return acc
							}
						}
						if (view.name?.toLowerCase().includes(searchPhrase.toLowerCase())) {
							acc.push(view)
						}
						return acc
					}, [])
					route.views = newProjectRoutes
				}
				return route
			}
		})
		dispatch({
			type: USER_HOME_ROUTES_CHANGED,
			payload: { routes, filteredRoutes: newRoutes }
		})
	}
}
