import Poller from '../../../Services/PollingService/Poller'
import {
  getWallThicknessStatus,
  updateWallThickness,
  getPartAnalysis
} from '../../../Services/Network'
import { WALL_THICKNESS_UPDATE_ERROR } from '../../../Services/Strings'

export class CastorWallThicknessDemandService {
  constructor(partId) {
    this.poller = new Poller()
    this.partId = partId
  }

  updateWallThickness = async customWallThickness => {
    const wallThicknessUpdated = await updateWallThickness(
      customWallThickness,
      this.partId
    )
    if (wallThicknessUpdated.status !== 200) {
      throw new Error(WALL_THICKNESS_UPDATE_ERROR)
    }
  }

  getPartAnalysis = async () => {
    return await getPartAnalysis(this.partId)
  }

  handlePoller = async () => {
    let timeOutDateTime = new Date()
    timeOutDateTime.setMinutes(timeOutDateTime.getMinutes() + 5)
    this.poller = new Poller(
      5000,
      timeOutDateTime,
      () => getWallThicknessStatus(this.partId),
      res => res.status === 200
    )

    return await this.poller.start()
  }

  clearPoller = () => {
    if (this.poller.pollerInterval) {
      this.poller.stop()
    }
  }
}
