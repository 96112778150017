import { UPLOAD_TAB_TEXTS } from 'Services/Strings'

export const PDF_FORMAT = 'pdf'
export const METADATA_FORMAT = ['xls', 'xlsx', 'csv']

export const KEY_2d = '2d'
export const KEY_3d = '3d'

export const UPLOAD = 'upload'
export const METADATA = 'metadata'
export const PREV_PATH = 'prevPath'
export const CLOSE = 'close'

export const threeD = [
	{
		key: KEY_3d,
		label: UPLOAD_TAB_TEXTS.THREE_D,
		text: 'CAD_3D',
		img: '3d.png'
	}
]

export const twoD = [
	{
		key: KEY_2d,
		label: UPLOAD_TAB_TEXTS.TWO_D,
		text: 'DRAWING_2D',
		img: '2d.png'
	}
]

export const metaData = [
	{
		key: METADATA,
		text: 'METADATA',
		img: 'metadata.png'
	}
]

export const TAB_3d_INDEX = 0
export const TAB_2d_INDEX = 1
export const TAB_METADATA_INDEX = 2

export const LOW_VOLUME = 'LOW_VOLUME'
export const CUSTOM = 'CUSTOM'
export const defaultSelected = LOW_VOLUME

export const format2D = '2D'
export const format3D2D = '3Dby2D'
export const format3D = '3D'
export const formatMetaData = 'metadata'

export const lightUserEmailExistsError = 45321
export const regularUserEmailExistsError = 45322
