import React, { FC, memo } from 'react'

import WithFeatureToggleHOC from '../../../Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from '../../../Services/models/Features'
import { getString } from '../../../Services/Strings/StringService'
import CastorAlert from '../alerts/CastorAlert'
import DataSheet, { GridElement } from '../DataSheet'
import { Danger, Muted } from '../thirdParty/CreativeTim/components'

import './StandardCost.scss'

interface IProps {
	showStandardCostAlert: boolean
	partsStandardCosts: GridElement[][]
	updateStandardCostLoading: boolean
	disableStandardCostSubmit: boolean
	showStandardCostError: boolean
	showStandardCostNumberError: boolean
	showNameDuplicationError: boolean
	onDataUpdated: (data: GridElement[][]) => any
	onCancel: Function
	onConfirm: Function
}

const StandardCostAlert: FC<IProps> = ({
	showStandardCostAlert,
	onCancel,
	partsStandardCosts,
	onDataUpdated,
	updateStandardCostLoading,
	disableStandardCostSubmit,
	onConfirm,
	showStandardCostError,
	showNameDuplicationError,
	showStandardCostNumberError
}) => {
	const renderStandardCostErrorMessage = () => {
		if (!showStandardCostError) {
			return <div />
		}
		return (
			<Danger className="standard-cost--error-message">
				{getString('STANDARD_COST_ERROR_EXPLENATION')}
			</Danger>
		)
	}

	const renderStandardCostNumberErrorMessage = () => {
		if (!showStandardCostNumberError) {
			return <div />
		}
		return (
			<Danger className="standard-cost--error-message">
				{getString('STANDARD_COST_NUMBER_ERROR_EXPLENATION')}
			</Danger>
		)
	}

	const renderNameDuplicationErrorMessage = () => {
		if (!showNameDuplicationError) {
			return <div />
		}
		return (
			<Danger className="standard-cost--error-message">
				{getString('STANDARD_NAME_DUPLICATE_ERROR_EXPLANATION')}
			</Danger>
		)
	}
	return (
		<CastorAlert
			headerTitle={getString('STANDARD_COST')}
			onCancel={onCancel}
			show={showStandardCostAlert}
			onConfirm={onConfirm}
			disabled={
				disableStandardCostSubmit ||
				showStandardCostError ||
				showStandardCostNumberError ||
				showNameDuplicationError
			}
			loadingCalculation={updateStandardCostLoading}
			alertClass="standard-cost--alert"
			alertBodyClass="standard-cost--alert--body"
		>
			<Muted className="standard-cost--alert--body_explanation">
				{getString('STANDARD_COST_EXPLENATION')}
			</Muted>
			<DataSheet
				data={partsStandardCosts}
				headers={[getString('PART_NAME'), `${getString('STANDARD_COST')} [$]`]}
				onDataUpdated={onDataUpdated}
				containerClassName="standard-cost--container"
			/>
			{renderStandardCostErrorMessage()}
			{renderStandardCostNumberErrorMessage()}
			{renderNameDuplicationErrorMessage()}
		</CastorAlert>
	)
}

export default WithFeatureToggleHOC(
	memo(StandardCostAlert),
	FeatureComponentId.STANDARD_COST
)
