import React, { ChangeEvent, FC, memo } from 'react'

import TextField from '@material-ui/core/TextField'
import Numeral from 'numeral'

import {
	CUSTOMIZE_MAXIMUM_PRINTER_HOURLY_PRICE,
	CUSTOMIZE_MAXIMUM_PRINTER_PRICE,
	CUSTOMIZE_MINIMUM_PRINTER_HOURLY_PRICE,
	CUSTOMIZE_MINIMUM_PRINTER_PRICE,
	PRINTING_COST_PARAMETERS
} from '../../../../../Services/Constants'
import { getString } from '../../../../../Services/Strings/StringService'

import './CustomizePrinterProps.scss'

const TextFieldTSX: any = TextField

interface IProps {
	selectedPrinterCost: number
	selectedPrinterHourlyCost: number
	defaultPrinterCost: number
	onPrinterPropsPriceChange: Function
	incorrectEntryForLayerThickness: boolean
	incorrectEntryForPrice: boolean
	incorrectEntryForHourlyPrice: boolean
	selectedPrinterCostParameter: String
}

const CustomizePrinterFields: FC<IProps> = ({
	selectedPrinterCost,
	selectedPrinterHourlyCost,
	onPrinterPropsPriceChange,
	defaultPrinterCost,
	incorrectEntryForLayerThickness,
	incorrectEntryForPrice,
	incorrectEntryForHourlyPrice,
	selectedPrinterCostParameter
}) => {
	return (
		<div className="customize-printer-props--content--inputs--fields">
			<span className="customize-printer-props--content--inputs--fields--field--sign">
				$
			</span>
			<TextFieldTSX
				value={
					selectedPrinterCostParameter === PRINTING_COST_PARAMETERS[1]
						? selectedPrinterCost
						: selectedPrinterHourlyCost
				}
				className="customize-printer-props--content--inputs--fields--field"
				type="number"
				placeholder={getString('PRINTER_SETTINGS_PRICE_IN_USD')}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					onPrinterPropsPriceChange(
						e.target.value,
						selectedPrinterCostParameter,
						incorrectEntryForPrice,
						incorrectEntryForLayerThickness
					)
				}
				onWheel={(event: ChangeEvent<HTMLInputElement>) => event.target.blur()}
				helperText={
					incorrectEntryForPrice &&
					selectedPrinterCostParameter === PRINTING_COST_PARAMETERS[1]
						? getString('VALUE_BETWEEN').format(
								Numeral(CUSTOMIZE_MINIMUM_PRINTER_PRICE).format('0,0'),
								Numeral(CUSTOMIZE_MAXIMUM_PRINTER_PRICE).format('0,0')
						  )
						: incorrectEntryForHourlyPrice &&
						  selectedPrinterCostParameter === PRINTING_COST_PARAMETERS[0]
						? getString('VALUE_BETWEEN').format(
								Numeral(CUSTOMIZE_MINIMUM_PRINTER_HOURLY_PRICE).format('0,0'),
								Numeral(CUSTOMIZE_MAXIMUM_PRINTER_HOURLY_PRICE).format('0,0')
						  )
						: ''
				}
				error={incorrectEntryForPrice || incorrectEntryForHourlyPrice}
				FormHelperTextProps={{
					className:
						'customize-printer-props--content--inputs--fields--field--error-text'
				}}
			/>
		</div>
	)
}

export default memo(CustomizePrinterFields)
