import { faDollarSign, faYenSign } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { memo } from 'react'

import ChevronRight from '@material-ui/icons/ChevronRight'
import IconClear from '@material-ui/icons/Clear'
import IconDone from '@material-ui/icons/Done'
import SaveIcon from '@material-ui/icons/Save'
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import SearchIcon from '@material-ui/icons/Search'

import { benefitTypes } from '../../../Services/Constants'
import { CADAnalysisResult } from '../../../Services/models/CADAnalysisResult'
import { ProjectClusterStatus } from '../../../Services/models/IProject'
import { Icon3dB } from '../CastorIcons/icon3D'
import { IconThreeDPrinter } from '../CastorIcons/Icon3DPrinter'
import { IconAttentionSign } from '../CastorIcons/iconAttentionSign'
import { IconBenefit } from '../CastorIcons/IconBenefit'
import { IconBestMatch } from '../CastorIcons/IconBestMatch'
import { IconBuild } from '../CastorIcons/IconBuild'
import { IconBuyToFlyScore } from '../CastorIcons/IconBuyToFly'
import { BuyToFlySmall } from '../CastorIcons/IconBuyToFlySmall'
import { IconCAD } from '../CastorIcons/IconCAD'
import { IconCanPass } from '../CastorIcons/IconCanPass'
import { IconCheckmark } from '../CastorIcons/IconCheckmark'
import { IconPieWithCloud } from '../CastorIcons/IconCloudPie'
import { IconCO2 } from '../CastorIcons/IconCO2'
import { IconCombine } from '../CastorIcons/IconCombine'
import { IconComplexityScore } from '../CastorIcons/IconComplexityScore'
import { IconComplexityScoreSmall } from '../CastorIcons/IconComplexityScoreSmall'
import { IconConfigure } from '../CastorIcons/IconConfigure'
import { IconContactUs } from '../CastorIcons/IconContactUs'
import { IconCostSaving } from '../CastorIcons/IconCostSaving'
import { IconCostSavingSmall } from '../CastorIcons/IconCostSavingSmall'
import { IconLockCreditCard } from '../CastorIcons/iconCreditCard'
import { IconCrown } from '../CastorIcons/IconCrown'
import { IconCSV } from '../CastorIcons/IconCSV'
import { IconDollar } from '../CastorIcons/IconDollar'
import { IconDollarLight } from '../CastorIcons/IconDollarLight'
import { IconDownload } from '../CastorIcons/iconDownload'
import { IconEligible } from '../CastorIcons/IconEligible'
import { IconExcelExport } from '../CastorIcons/IconExcelExport'
import { IconExpand } from '../CastorIcons/IconExpand'
import { IconExportPDF } from '../CastorIcons/IconExportPDF'
import { IconFastTrackWeightReduction } from '../CastorIcons/IconFastTrackWeightRedction'
import { IconFea } from '../CastorIcons/IconFea'
import { IconFeaArrows } from '../CastorIcons/IconFeaArrows'
import { IconFlagJapan } from '../CastorIcons/IconFlagJapan'
import { IconFlagUSA } from '../CastorIcons/IconFlagUSA'
import { IconGetAQ } from '../CastorIcons/IconGetAQ'
import { IconGraphic } from '../CastorIcons/IconGraphic'
import { IconGrid } from '../CastorIcons/IconGrid'
import { IconInfo } from '../CastorIcons/IconInfo'
import { IconInHouse } from '../CastorIcons/IconInHouse'
import { IconInProgress } from '../CastorIcons/IconInProgress'
import IconLeftArrow from '../CastorIcons/IconLeftArrow'
import IconLoaderGif from '../CastorIcons/IconLoaderGif'
import { IconMinus } from '../CastorIcons/IconMinus'
import { IconMissingInformation } from '../CastorIcons/IconMissingInformation'
import { IconNextArrow } from '../CastorIcons/IconNextArrow'
import { IconNotCostEffective } from '../CastorIcons/IconNotCostEffective'
import { IconOptimizedTrackWeightReduction } from '../CastorIcons/IconOptimizedTrackWeightReduction'
import { IconPDF } from '../CastorIcons/IconPDF'
import { IconPDFDark } from '../CastorIcons/IconPDFDark'
import { IconPlusMinus } from '../CastorIcons/IconPlusMinus'
import { IconPostProcess } from '../CastorIcons/IconPostProcess'
import { IconPrevArrow } from '../CastorIcons/IconPrevArrow'
import { IconPrintableWithWarning } from '../CastorIcons/IconPrintableWithWarning'
import { IconPrintNotSuitable } from '../CastorIcons/IconPrintNotSuitable'
import { IconRemove } from '../CastorIcons/IconRemove'
import { IconRemoveSquare } from '../CastorIcons/IconRemoveSquare'
import IconResetAll from '../CastorIcons/IconResetAll'
import { IconRightArrow } from '../CastorIcons/IconRightArrow'
import { IconSave } from '../CastorIcons/IconSave'
import { IconSaveFill } from '../CastorIcons/IconSaveFill'
import { IconSelectArrow } from '../CastorIcons/IconSelectArrow'
import { IconSmallDollar } from '../CastorIcons/IconSmallDollar'
import { IconSmallTime } from '../CastorIcons/IconSmallTime'
import { IconSortArrows } from '../CastorIcons/IconSortArrows'
import { IconStar } from '../CastorIcons/IconStar'
import { IconStarBorder } from '../CastorIcons/IconStarBorder'
import { IconThumbnail } from '../CastorIcons/IconThumbnail'
import { IconTime } from '../CastorIcons/IconTime'
import { IconTimeDark } from '../CastorIcons/IconTimeDark'
import { IconTimeLight } from '../CastorIcons/IconTimeLight'
import IconTimeSaving from '../CastorIcons/IconTimeSaving'
import { IconUnableToFind } from '../CastorIcons/IconUnableToFind'
import { IconUnprintable } from '../CastorIcons/IconUnprintable'
import { IconUploadCAD } from '../CastorIcons/IconUploadCAD'
import { IconUploadPDF } from '../CastorIcons/IconUploadPDF'
import { IconWallet } from '../CastorIcons/IconWallet'
import { IconWarn } from '../CastorIcons/IconWarn'
import { IconWarning } from '../CastorIcons/IconWarning'
import { IconWeight } from '../CastorIcons/IconWeight'
import { IconWeightReduction } from '../CastorIcons/IconWeightReduction'
import { IconWeightTransparent } from '../CastorIcons/IconWeightTranspernt'
import { IconLightUser } from '../CastorIcons/lightUser'

const IconFactory = ({ iconName, ...props }) => {
	switch (iconName) {
		case 'star':
			return <IconStar {...props} />
		case 'done':
			return <IconDone {...props} />
		case 'starBorder':
			return <IconStarBorder {...props} />
		case 'search':
			return <SearchIcon {...props} />
		case 'costSavingSmall':
			return <IconCostSavingSmall {...props} />
		case 'complexitySmall':
			return <IconComplexityScoreSmall {...props} />
		case 'buyToFlySmall':
			return <BuyToFlySmall {...props} />
		case benefitTypes.costSaving:
			return <IconCostSaving {...props} />
		case benefitTypes.buyToFly:
			return <IconBuyToFlyScore {...props} />
		case benefitTypes.complexityScore:
			return <IconComplexityScore {...props} />
		case benefitTypes.timeSaving:
			return <IconTimeSaving {...props} />
		case benefitTypes.weightReduction:
			return <IconWeightReduction {...props} />
		case 'configure':
			return <IconConfigure {...props} />
		case 'remove':
			return <IconRemove {...props} />
		case 'remove-square':
			return <IconRemoveSquare {...props} />
		case 'save':
			return <IconSave {...props} />
		case 'saved':
			return <IconSaveFill {...props} />
		case 'getAQ':
			return <IconGetAQ {...props} />
		case 'chevronRight':
			return <ChevronRight {...props} />
		case 'checkmark':
		case 'printable':
		case 'success':
			return <IconCheckmark {...props} />
		case 'checkmarkWithBorder':
			return <IconCheckmark withBorder {...props} />
		case 'canPass':
		case 'borderline':
			return <IconCanPass {...props} />
		case 'notCostEffective':
			return <IconNotCostEffective {...props} />
		case 'unprintable':
		case 'notPrintable':
		case 'failed':
		case 'warning':
		case 'dependencyAnalysisFailed':
			return <IconUnprintable {...props} />
		case 'printableWithWarning':
			return <IconPrintableWithWarning {...props} />
		case 'warningBlue':
			return <IconWarning {...props} />
		case 'leftArrow':
			return <IconLeftArrow {...props} />
		case 'resetAll':
			return <IconResetAll {...props} />
		case 'threeD':
			return <Icon3dB {...props} />
		case 'clear':
			return <IconClear {...props} />
		case 'time':
			return <IconTime {...props} />
		case 'time-dark':
			return <IconTimeDark {...props} />
		case 'time-light':
			return <IconTimeLight {...props} />
		case benefitTypes.co2Saving:
		case 'co2':
			return <IconCO2 {...props} />
		case 'smallTime':
			return <IconSmallTime {...props} />
		case 'pie-cloud':
			return <IconPieWithCloud {...props} />
		case CADAnalysisResult.notPrintSuitable:
			return <IconPrintNotSuitable {...props} />
		case 'crown':
			return <IconCrown {...props} />
		case 'dollar':
		case 'cost':
			return <IconDollar {...props} />
		case 'dollar-light':
			return <IconDollarLight {...props} />
		case 'smallDollar':
			return <IconSmallDollar {...props} />
		case 'weight':
			return <IconWeight {...props} />
		case 'contact-us':
			return <IconContactUs {...props} />
		case 'info':
			return <IconInfo {...props} />
		case 'inProgress':
		case ProjectClusterStatus.awaitingAnalysis:
			return <IconInProgress {...props} />
		case 'unableToFind':
			return <IconUnableToFind {...props} />
		case 'creditCard':
			return <IconLockCreditCard {...props} />
		case 'sortArrows':
			return <IconSortArrows {...props} />
		case 'rightArrow':
			return <IconRightArrow {...props} />
		case 'expand':
			return <IconExpand {...props} />
		case 'loading':
			return <IconLoaderGif {...props} />
		case 'exportExcel':
			return <IconExcelExport {...props} />
		case 'selectArrow':
			return <IconSelectArrow {...props} />
		case 'USFlag':
			return <IconFlagUSA {...props} />
		case 'JPFlag':
			return <IconFlagJapan {...props} />
		case 'USDCurrency':
			return <FontAwesomeIcon icon={faDollarSign} {...props} />
		case 'JPYCurrency':
			return <FontAwesomeIcon icon={faYenSign} {...props} />
		case '3DPrinter':
			return <IconThreeDPrinter {...props} />
		case 'plusMinus':
			return <IconPlusMinus {...props} />
		case 'fea':
			return <IconFea {...props} />
		case 'feaArrows':
			return <IconFeaArrows {...props} />
		case 'attentionSign':
			return <IconAttentionSign {...props} />
		case 'download':
			return <IconDownload {...props} />
		case 'fastTrackIcon':
			return <IconFastTrackWeightReduction {...props} />
		case 'optimizedTrackIcon':
			return <IconOptimizedTrackWeightReduction {...props} />
		case 'weightTransparent':
			return <IconWeightTransparent {...props} />
		case 'combine':
			return <IconCombine {...props} />
		case 'build':
			return <IconBuild {...props} />
		case 'wallet':
			return <IconWallet {...props} />
		case 'graphic':
			return <IconGraphic {...props} />
		case 'grid':
			return <IconGrid {...props} />
		case 'postprocess':
			return <IconPostProcess {...props} />
		case 'eligible':
			return <IconEligible {...props} />
		case 'inHouse':
			return <IconInHouse {...props} />
		case 'bestMatch':
			return <IconBestMatch {...props} />
		case 'minus':
			return <IconMinus {...props} />
		case 'pdf-file':
			return <IconPDF {...props} />
		case 'pdf':
			return <IconPDFDark {...props} />
		case 'uploadIconCad':
			return <IconUploadCAD {...props} />
		case 'cad':
			return <IconCAD {...props} />
		case 'uploadIconPdf':
			return <IconUploadPDF {...props} />
		case 'lightUser':
			return <IconLightUser {...props} />
		case 'warn':
			return <IconWarn {...props} />
		case 'benefit':
			return <IconBenefit {...props} />
		case 'thumbnail':
			return <IconThumbnail {...props} />
		case 'exportCSV':
			return <IconCSV {...props} />
		case 'nextArrow':
			return <IconNextArrow {...props} />
		case 'prevArrow':
			return <IconPrevArrow {...props} />
		case 'exportPDF':
			return <IconExportPDF {...props} />
		case 'missingInformation':
			return <IconMissingInformation {...props} />
		case 'saveDisc':
			return <SaveOutlinedIcon {...props} />
		case 'savedDisc':
			return <SaveIcon {...props} />
		default:
			return <div />
	}
}

export default memo(IconFactory)
