import React, { FC } from 'react'

import { ReactComponent as Checkmark } from '../../../assets/img/svg/Checkmark.svg'
import { ReactComponent as CheckmarkWithBorder } from '../../../assets/img/svg/checkmarkWithBorder.svg'

interface Props {}

export const IconCheckmark: FC<Props> = (props: any) =>
	props.withBorder ? (
		<CheckmarkWithBorder {...props} />
	) : (
		<Checkmark {...props} />
	)
