import { FC, memo, useState } from 'react'
import Truncate from 'react-truncate'

import DetailsPopup from '../DetailsPopup'
import AdditiveMinds from 'Scenes/Components/AdditiveMinds'
import { AdditiveMindsTypes } from 'Scenes/Components/AdditiveMinds/AdditiveMindsInterface'
import { printStatusScore } from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { READ_MORE } from 'Services/Strings'

import './DataTable.scss'

const TruncateTSX: any = Truncate

interface IProps {
	text: string | number
	className: string
	data: any
	isTruncated?: boolean
}

const renderEllipsis = (text: string | number) => {
	return (
		<DetailsPopup
			isHover={true}
			data={text}
			popperDirection="left-start"
			popperClassName="data-table-long-text-popper-message"
			popperContactClassName="data-table-long-text-popper-data"
		>
			<span className="data-table-long-text-popper-target">{READ_MORE}</span>
		</DetailsPopup>
	)
}

const DataTableLongTextField: FC<IProps> = ({
	text,
	className,
	data,
	isTruncated = true
}) => {
	const { score, withAdditiveMind } = data
	const isAdditiveOn = Feature.isFeatureOn(
		FeatureComponentId.SHOW_CONTACT_ADDITIVE_MINDS_BUTTON
	)
	const [truncated, setTruncated] = useState(true)

	if (withAdditiveMind && isAdditiveOn && score === printStatusScore.failed) {
		return <AdditiveMinds type={AdditiveMindsTypes.button} hoverText={text} />
	}

	const handleTruncate = (isTruncated: boolean) => {
		if (truncated !== isTruncated) {
			setTruncated(isTruncated)
		}
	}

	if (!isTruncated) {
		return (
			<div
				className={`data-table-long-text-field ${className} ${data.className}`}
			>
				{text || ''}
			</div>
		)
	}

	return (
		<div
			className={`data-table-long-text-field ${className} ${data.className}`}
		>
			<TruncateTSX
				lines={2}
				ellipsis={<span>...{renderEllipsis(text || '')}</span>}
				trimWhitespace
				onTruncate={handleTruncate}
			>
				<div>{text || ''}</div>
			</TruncateTSX>
		</div>
	)
}

export default memo(DataTableLongTextField)
