import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { ADVANCED_IDS, STEP_ID } from '../../constants'
import { goToNextStep } from '../../OnboardingWizardActions'
import { useStep } from '../../OnboardingWizardHooks'
import OnboardingStepFrame from '../OnboardingStepFrame'
import AdvancedDescriptionBlock from './AdvancedDescriptionBlock'

import './Advanced.scss'

const AdvancedComponents = {
	[ADVANCED_IDS.DESCRIPTION_BLOCK]: <AdvancedDescriptionBlock />
}

const Advanced: FC = () => {
	const { steps, parentSteps, currentStep } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { step, stepContent, childSteps } = useStep(steps, STEP_ID.ADVANCED)
	const dispatch = useDispatch()

	const onContinueClick = () => {
		dispatch(goToNextStep(parentSteps, currentStep, childSteps[0].id))
	}

	return (
		<OnboardingStepFrame
			step={step}
			onNextClickAnotherAction={onContinueClick}
			childSteps={childSteps}
		>
			<div className="advanced--wrapper">
				{stepContent.map(component => {
					return (
						<React.Fragment key={component.id}>
							{
								AdvancedComponents[
									component.id as keyof typeof AdvancedComponents
								]
							}
						</React.Fragment>
					)
				})}
			</div>
		</OnboardingStepFrame>
	)
}

export default Advanced
