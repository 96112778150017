import moment from "moment"

import { getLocaleFromLocalStorage, getUserStringsFromLocalStorage } from "Services/LocalStorageService"

export const getUserLanguage = () => {
    return getLocaleFromLocalStorage() || 'en' //(window.navigator.language).split('-')[0]
}

export const getStringData = () => {
    const stringsData = getUserStringsFromLocalStorage()
    return {
        userStrings: stringsData.userStrings || [],
        stringsHash: stringsData.stringsHash || moment().format('YYYY-MM-DD HH:mm:ss')
    }
}