import { useEffect, useState } from 'react'

import Numeral from 'numeral'

import { makeTotalCostForStandard } from '../../../../../SolutionAnalysisHeader/SolutionExportPdf/SolutionPdfService'
import { CostAnalysisEnum } from '../CostParametersEnum'
import { DataTableService } from 'Scenes/Components/DataTable/DataTableService'
import { DataTableFieldType } from 'Scenes/Components/DataTable/IDataTableField'
import {
	generateProductionParameters,
	generateProductionWithoutClusterParameters
} from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostAnalysisTable/CostAnalysisTableHelper'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { NA } from 'Services/Constants'
import {
	calculate2DRange,
	displayInRange
} from 'Services/global/calculateRange'
import { Part } from 'Services/models/IPart'
import { ISolution } from 'Services/models/ISolution'
import { getString } from 'Services/Strings/StringService'

const dataTableService = new DataTableService()

const checkValueForStandardAndCustom = (
	value: any,
	isStandardCost?: boolean,
	isCostCustomized?: boolean
) => {
	if ((isStandardCost && +value === 0) || isCostCustomized) return null

	return value
}

const checkValueForCustom = (
	value: string | number,
	isCostCustomized?: boolean
) => {
	return isCostCustomized ? null : value
}

const getCostAnalysisTableHeaders = (
	traditionalMethod: string,
	configName: string,
	extraConfigName: string,
	extraConfigWithWarning?: boolean,
	extraConfigWarningHoverText?: string
) => {
	const tableHeaders = [
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.Header,
			text: getString('MATERIAL_COMPARISON_HEADER_COMPARISON')
		}),
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.Header,
			text: configName
		}),
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.Header,
			text: traditionalMethod
		})
	]
	if (extraConfigName) {
		tableHeaders.splice(
			2,
			0,
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Header,
				text: extraConfigName,
				iconName: extraConfigWithWarning ? 'warning' : '',
				className: extraConfigWithWarning ? 'header-with-warning' : '',
				hoverText: extraConfigWarningHoverText
			})
		)
	}
	return tableHeaders
}

export function useCostAnalysisTableHeaders(
	traditionalMethod: string,
	configName: string,
	extraConfigName: string,
	extraConfigWithWarning?: boolean,
	extraConfigWarningHoverText?: string
) {
	const [costAnalysisTableHeaders, setCostAnalysisTableHeaders] = useState(
		getCostAnalysisTableHeaders(
			traditionalMethod,
			configName,
			extraConfigName,
			extraConfigWithWarning,
			extraConfigWarningHoverText
		)
	)

	useEffect(() => {
		setCostAnalysisTableHeaders(
			getCostAnalysisTableHeaders(
				traditionalMethod,
				configName,
				extraConfigName,
				extraConfigWithWarning,
				extraConfigWarningHoverText
			)
		)
	}, [
		configName,
		extraConfigName,
		extraConfigWarningHoverText,
		extraConfigWithWarning,
		traditionalMethod
	])

	return costAnalysisTableHeaders
}

const singleRowElement = (
	text: string,
	iconName: string,
	cost: any,
	traditionCost: any,
	showEffectiveIcon: boolean,
	isEffective: boolean,
	isInside?: boolean,
	description?: string,
	extraClassName?: string,
	extraCost?: string | number,
	extraCostIsNA?: boolean
) => {
	const singleRowElement = [
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.TextWithIconButton,
			text: text,
			iconName: iconName,
			className: `cost-summary--table--text--full-detail data-table--first-column ${
				isInside ? 'data-table--first-column--inside' : ''
			}  ${extraClassName || ''}`,
			iconClassName: 'icon-part',
			hoverText: getString('TOTAL_PART_COST_DETAILS'),
			isHover: true,
			iconOnTheRight: true,
			description: description
		}),
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.TextWithIcon,
			text: cost,
			iconName: showEffectiveIcon && isEffective ? 'costSavingSmall' : '',
			className: `cost-comparison-tab--table--text-with-icon cost-summary--table--text--full-detail`,
			iconClassName: 'cost-comparison-tab--table--text-with-icon--icon',
			iconOnTheRight: true,
			extraData: { dataQaAttribute: 'data-qa-AM-total-part-cost' }
		}),
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.Text,
			text: traditionCost,
			className: `cost-summary--table--text--full-detail`
		})
	]
	if (typeof extraCost !== 'undefined' || extraCostIsNA) {
		singleRowElement.splice(
			2,
			0,
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: extraCostIsNA ? NA : extraCost,
				className: `cost-summary--table--text--full-detail`
			})
		)
	}
	return singleRowElement
}

const materialRowElement = (
	materialCost: number | string,
	totalPartMaterial: number | string,
	totalSupportMaterial: number | string,
	unusedMaterialWaste: number | string,
	materialCostIs: number | string,
	partWeightIs: number | string,
	disabledParameters: Record<CostAnalysisEnum, boolean> | null,
	materialCostWithoutCluster: number | string | null,
	totalPartMaterialWithoutCluster: number | string | null,
	totalSupportMaterialWithoutCluster: number | string | null,
	unusedMaterialWasteWithoutCluster: number | string | null,
	materialCostIsWithoutCluster: number | string | null,
	partWeightIsWithoutCluster: number | string | null,
	originalDesignUnusedMaterialWaste?: number | string,
	originalDesignTotalSupportMaterial?: number | string,
	originalDesignTotalPartMaterial?: number | string,
	originalDesignMaterialCost?: number | string,
	originalDesignPartIsUnprintable?: boolean
) => {
	if (
		disabledParameters?.[CostAnalysisEnum.totalPartMaterial] &&
		disabledParameters?.[CostAnalysisEnum.totalSupportMaterial] &&
		disabledParameters?.[CostAnalysisEnum.unusedMaterialWaste]
	)
		return []

	return [
		singleRowElement(
			`${getString('MATERIAL')} [$]`,
			'info',
			materialCost,
			materialCostWithoutCluster || '',
			false,
			false,
			false,
			getString('COST_ANALYSIS_MATERIAL').format(materialCostIs, partWeightIs),
			undefined,
			originalDesignMaterialCost,
			originalDesignPartIsUnprintable
		),
		disabledParameters?.[CostAnalysisEnum.totalPartMaterial]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_TOTAL_PART_MATERIAL')} [$]`,
					'info',
					totalPartMaterial,
					totalPartMaterialWithoutCluster || '',
					false,
					false,
					true,
					undefined,
					undefined,
					originalDesignTotalPartMaterial,
					originalDesignPartIsUnprintable
			  ),
		disabledParameters?.[CostAnalysisEnum.totalSupportMaterial]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_SUPPORT_AND_OTHERS')} [$]`,
					'info',
					totalSupportMaterial,
					totalSupportMaterialWithoutCluster || '',
					false,
					false,
					true,
					undefined,
					undefined,
					originalDesignTotalSupportMaterial,
					originalDesignPartIsUnprintable
			  ),
		disabledParameters?.[CostAnalysisEnum.unusedMaterialWaste]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_MATERIAL_WASTE')} [$]`,
					'info',
					unusedMaterialWaste,
					unusedMaterialWasteWithoutCluster || '',
					false,
					false,
					true,
					undefined,
					undefined,
					originalDesignUnusedMaterialWaste,
					originalDesignPartIsUnprintable
			  )
	]
}

const machineRowElement = (
	machineCost: number | string,
	totalSharedMachineTime: number | string,
	printTime: number | string,
	machineCostPerHour: number | string,
	partPrintTime: number | string,
	disabledParameters: Record<CostAnalysisEnum, boolean> | null,
	machineCostWithoutCluster: number | string | null,
	totalSharedMachineTimeWithoutCluster: number | string | null,
	printTimeWithoutCluster: number | string | null,
	machineCostPerHourWithoutCluster: number | string | null,
	partPrintTimeWithoutCluster: number | string | null,
	originalDesignMachineCost?: number | string,
	originalDesignTotalSharedMachineTime?: number | string,
	originalDesignPrintTime?: number | string,
	originalDesignPartIsUnprintable?: boolean
) => {
	if (
		disabledParameters?.[CostAnalysisEnum.printTime] &&
		disabledParameters?.[CostAnalysisEnum.totalSharedMachineTime]
	)
		return []

	return [
		singleRowElement(
			`${getString('COST_ANALYSIS_COST_TABLE_PROD_MACHINE_ROW_TEXT')} [$]`,
			'info',
			machineCost,
			machineCostWithoutCluster || '',
			false,
			false,
			false,
			getString('COST_ANALYSIS_MACHINE').format(
				machineCostPerHour,
				partPrintTime
			),
			undefined,
			originalDesignMachineCost,
			originalDesignPartIsUnprintable
		),
		disabledParameters?.[CostAnalysisEnum.printTime]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_TOTAL_PRINT_TIME')} [$]`,
					'info',
					printTime,
					printTimeWithoutCluster || '',
					false,
					false,
					true,
					undefined,
					undefined,
					originalDesignPrintTime,
					originalDesignPartIsUnprintable
			  ),
		disabledParameters?.[CostAnalysisEnum.totalSharedMachineTime]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_TOTAL_SHARED_MACHINE_TIME')} [$]`,
					'info',
					totalSharedMachineTime,
					totalSharedMachineTimeWithoutCluster || '',
					false,
					false,
					true,
					undefined,
					undefined,
					originalDesignTotalSharedMachineTime,
					originalDesignPartIsUnprintable
			  ) || []
	]
}

const consumablesRowElement = (
	consumablesCost: number | string,
	operatingCost: number | string,
	perBuildConsumables: number | string,
	disabledParameters: Record<CostAnalysisEnum, boolean> | null,
	consumablesCostWithoutCluster: number | string | null,
	operatingCostWithoutCluster: number | string | null,
	perBuildConsumablesWithoutCluster: number | string | null,
	originalDesignConsumablesCost?: number | string,
	originalDesignOperatingCost?: number | string,
	originalDesignPerBuildConsumables?: number | string,
	originalDesignPartIsUnprintable?: boolean
) => {
	if (
		disabledParameters?.[CostAnalysisEnum.operatingCost] &&
		disabledParameters?.[CostAnalysisEnum.perBuildConsumables]
	)
		return []

	return [
		singleRowElement(
			`${getString('COST_ANALYSIS_COST_TABLE_PROD_CONSUMABLES_ROW_TEXT')} [$]`,
			'info',
			consumablesCost,
			consumablesCostWithoutCluster || '',
			false,
			false,
			false,
			getString('COST_ANALYSIS_CONSUMABLES'),
			undefined,
			originalDesignConsumablesCost,
			originalDesignPartIsUnprintable
		),
		disabledParameters?.[CostAnalysisEnum.perBuildConsumables]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_BUILD_CONSUMABLES')} [$]`,
					'info',
					perBuildConsumables,
					perBuildConsumablesWithoutCluster || '',
					false,
					false,
					true,
					undefined,
					undefined,
					originalDesignPerBuildConsumables,
					originalDesignPartIsUnprintable
			  ),
		disabledParameters?.[CostAnalysisEnum.operatingCost]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_OPERATING_COST')} [$]`,
					'info',
					operatingCost,
					operatingCostWithoutCluster || '',
					false,
					false,
					true,
					undefined,
					undefined,
					originalDesignOperatingCost,
					originalDesignPartIsUnprintable
			  )
	]
}

const laborRowElement = (
	labor: number | string,
	laborHoursBuildExchange: number | string,
	laborHoursDuringPureBuild: number | string,
	laborTechnicianFTE: number | string,
	mandatoryPostProcessCost: number | string,
	optionalPostProcessCost: number | string,
	disabledParameters: Record<CostAnalysisEnum, boolean> | null,
	laborWithoutCluster: number | string | null,
	laborHoursBuildExchangeWithoutCluster: number | string | null,
	laborHoursDuringPureBuildWithoutCluster: number | string | null,
	laborTechnicianFTEWithoutCluster: number | string | null,
	mandatoryPostProcessCostWithoutCluster: number | string | null,
	optionalPostProcessCostWithoutCluster: number | string | null,
	originalDesignLabor?: number | string,
	originalDesignLaborHoursBuildExchange?: number | string,
	originalDesignLaborHoursDuringPureBuild?: number | string,
	originalDesignMandatoryPostProcessCost?: number | string,
	originalDesignOptionalPostProcessCost?: number | string,
	originalDesignPartIsUnprintable?: boolean,
	assemblingCost?: number | string | null,
	assemblingCostWithoutCluster?: number | string | null,
	onClick?: () => void,
	isCluster?: boolean
) => {
	const disableLabor =
		disabledParameters?.[CostAnalysisEnum.laborHoursBuild] &&
		disabledParameters?.[CostAnalysisEnum.laborHoursDuringBuild] &&
		disabledParameters?.[CostAnalysisEnum.postProcessCost] &&
		disabledParameters?.[CostAnalysisEnum.optionalPostProcess]

	if (disableLabor && !isCluster) return []

	return [
		singleRowElement(
			`${getString('COST_ANALYSIS_COST_TABLE_PROD_LABOR_ROW_TEXT')} [$]`,
			'info',
			labor,
			laborWithoutCluster || '',
			false,
			false,
			false,
			getString('COST_ANALYSIS_LABOR').format(laborTechnicianFTE),
			undefined,
			originalDesignLabor,
			originalDesignPartIsUnprintable
		),
		disabledParameters?.[CostAnalysisEnum.laborHoursBuild]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_BUILD_EXCHANGE')} [$]`,
					'info',
					laborHoursBuildExchange,
					laborHoursBuildExchangeWithoutCluster || '',
					false,
					false,
					true,
					undefined,
					undefined,
					originalDesignLaborHoursBuildExchange,
					originalDesignPartIsUnprintable
			  ),
		disabledParameters?.[CostAnalysisEnum.laborHoursDuringBuild]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_LABOR_DURING_BUILD')} [$]`,
					'info',
					laborHoursDuringPureBuild,
					laborHoursDuringPureBuildWithoutCluster || '',
					false,
					false,
					true,
					undefined,
					undefined,
					originalDesignLaborHoursDuringPureBuild,
					originalDesignPartIsUnprintable
			  ),
		disabledParameters?.[CostAnalysisEnum.postProcessCost]
			? []
			: singleRowElement(
					`${getString(
						'COST_ANALYSIS_COST_TABLE_PROD_MANDATORY_PP_ROW_TEXT'
					)} [$]`,
					'info',
					mandatoryPostProcessCost,
					mandatoryPostProcessCostWithoutCluster || '',
					false,
					false,
					true,
					'',
					undefined,
					originalDesignMandatoryPostProcessCost,
					originalDesignPartIsUnprintable
			  ),
		disabledParameters?.[CostAnalysisEnum.optionalPostProcess]
			? []
			: !!optionalPostProcessCost && optionalPostProcessCost != 0
			? singleRowElement(
					`${getString(
						'COST_ANALYSIS_COST_TABLE_PROD_OPTIONAL_PP_ROW_TEXT'
					)} [$]`,
					'info',
					optionalPostProcessCost,
					optionalPostProcessCostWithoutCluster || '',
					false,
					false,
					true,
					getString('COST_ANALYSIS_POST_PROCESS_OPTIONAL'),
					undefined,
					originalDesignOptionalPostProcessCost,
					originalDesignPartIsUnprintable
			  )
			: [],
		!isCluster
			? []
			: assemblyRowElement(
					`${getString('ASSEMBLY')} [$]`,
					assemblingCost,
					assemblingCostWithoutCluster,
					false,
					false,
					true,
					onClick
			  )
	]
}

export const assemblyRowElement = (
	text: string,
	cost: any,
	costWithoutCluster: any,
	showEffectiveIcon: boolean,
	isEffective: boolean,
	isInside: boolean,
	onClick?: () => void
) => {
	return [
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.TextWithButton,
			text: text,
			className: `cost-summary--table--text--full-detail data-table--first-column ${
				isInside ? 'data-table--first-column--inside' : ''
			}`,
			onClick,
			buttonText: onClick ? getString('EDIT') : ''
		}),
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.TextWithIcon,
			text: cost,
			iconName: showEffectiveIcon && isEffective ? 'costSavingSmall' : '',
			className: `cost-comparison-tab--table--text-with-icon cost-summary--table--text--full-detail`,
			iconClassName: 'cost-comparison-tab--table--text-with-icon--icon',
			iconOnTheRight: true,
			extraData: { dataQaAttribute: 'data-qa-cluster-assembly-cost' }
		}),
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.Text,
			text: costWithoutCluster,
			className: `cost-summary--table--text--full-detail`
		})
	]
}

const costOrderFeesElement = (
	totalOrderFeesCost: number | string,
	totalOrderFeesCostWithoutCluster: number | string | null,
	disabledParameters: Record<CostAnalysisEnum, boolean> | null,
	originalDesignTotalOrderFeesCost?: number | string,
	originalDesignPartIsUnprintable?: boolean
) => {
	if (disabledParameters?.[CostAnalysisEnum.totalOrderFees]) return []

	return [
		singleRowElement(
			`${getString('COST_TABLE_ORDER_MANAGEMENT_SERVICES')} [$]`,
			'info',
			totalOrderFeesCost,
			totalOrderFeesCostWithoutCluster || '',
			false,
			false,
			true,
			'',
			'extra-space',
			originalDesignTotalOrderFeesCost,
			originalDesignPartIsUnprintable
		)
	]
}

const buildPrepRowElement = (
	buildPrep: number | string,
	buildPrepWithoutCluster: number | string | null,
	disabledParameters: Record<CostAnalysisEnum, boolean> | null,
	originalDesignBuildPrep?: number | string,
	originalDesignPartIsUnprintable?: boolean
) => {
	if (disabledParameters?.[CostAnalysisEnum.buildPrep]) return []

	return [
		singleRowElement(
			`${getString('COST_TABLE_BUILD_PREP_COST')} [$]`,
			'info',
			buildPrep,
			buildPrepWithoutCluster || '',
			false,
			false,
			true,
			'',
			'extra-space',
			originalDesignBuildPrep,
			originalDesignPartIsUnprintable
		)
	]
}

export const generateMaterialRow = (
	solution: Record<string, any>,
	configuration: Record<string, any>,
	costDetailsSolutionWithoutCluster?: Record<string, any>,
	disregardMaterial?: boolean,
	originalDesignSolution?: ISolution,
	originalDesignPartIsUnprintable?: boolean
) => {
	const materialRows = [
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.Text,
			text: getString('MATERIAL'),
			className:
				'cost-summary--table--text--full-detail data-table--first-column'
		}),
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.Text,
			text: solution.printerMaterial.name,
			className: `cost-summary--table--text--full-detail`
		}),
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.Text,
			text:
				costDetailsSolutionWithoutCluster && disregardMaterial
					? '-'
					: configuration.material.name,
			className: `cost-summary--table--text--full-detail`
		})
	]
	if (originalDesignSolution || originalDesignPartIsUnprintable) {
		materialRows.splice(
			2,
			0,
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: originalDesignPartIsUnprintable
					? NA
					: originalDesignSolution?.printerMaterial.name,
				className: `cost-summary--table--text--full-detail`
			})
		)
	}
	return materialRows
}

export const generateTotalCostRow = (
	showEffectiveIcon: any,
	totalPartEffective: any,
	solution: any,
	configuration: any,
	supplyChainOn: boolean,
	userCustomizationSettings: any,
	part: Part,
	chainBenefits: any,
	isStandardCost?: boolean,
	isShowValuesInRanges?: boolean,
	drawingCostPercentage?: number,
	costDetailsSolutionWithoutCluster?: Record<string, any>,
	originalDesignSolution?: ISolution,
	originalDesignPartIsUnprintable?: boolean
) => {
	let originalDesignSolutionTotalCost
	const isMetadata = part?.formatType === METADATA
	if (!supplyChainOn) {
		return []
	}

	let configurationTotalCost: string | number = Numeral(
		configuration.costResults.actualResult
	).format('0,0[.]00')

	const { maxCostDetails, minCostDetails, totalCost } = solution?.costDetails

	let solutionTotalCost = Numeral(totalCost).format('0,0[.]00')

	// for Standard cost
	if (isStandardCost) {
		configurationTotalCost = makeTotalCostForStandard(
			part,
			configuration,
			chainBenefits
		)
	}

	if (isMetadata) {
		configurationTotalCost = calculate2DRange(
			costDetailsSolutionWithoutCluster?.costDetails?.totalCost ||
				configuration.costResults.actualResult,
			isMetadata ? drawingCostPercentage : undefined
		)
	}

	if (costDetailsSolutionWithoutCluster) {
		configurationTotalCost = Numeral(
			costDetailsSolutionWithoutCluster.costDetails?.totalCost
		).format('0,0[.]00')

		if (
			isShowValuesInRanges &&
			costDetailsSolutionWithoutCluster.minCostDetails &&
			costDetailsSolutionWithoutCluster.maxCostDetails
		) {
			configurationTotalCost = displayInRange(
				costDetailsSolutionWithoutCluster.minCostDetails?.totalCost,
				costDetailsSolutionWithoutCluster.maxCostDetails?.totalCost,
				configurationTotalCost,
				'0,0[.]00',
				part?.isDrawing || isMetadata ? drawingCostPercentage : undefined
			)
		}
	}

	if (isShowValuesInRanges && minCostDetails && maxCostDetails) {
		solutionTotalCost = displayInRange(
			minCostDetails?.totalCost,
			maxCostDetails?.totalCost,
			solutionTotalCost,
			'0,0[.]00',
			part?.isDrawing || isMetadata ? drawingCostPercentage : undefined
		)
	}

	if (originalDesignSolution) {
		const {
			maxCostDetails: originalMaxCostDetails,
			minCostDetails: originalMinCostDetails,
			totalCost: originalTotalCost
		} = originalDesignSolution?.costDetails

		originalDesignSolutionTotalCost =
			Numeral(originalTotalCost).format('0,0[.]00')

		if (
			isShowValuesInRanges &&
			originalMinCostDetails &&
			originalMaxCostDetails
		) {
			originalDesignSolutionTotalCost = displayInRange(
				originalMinCostDetails?.totalCost,
				originalMaxCostDetails?.totalCost,
				originalDesignSolutionTotalCost,
				'0,0[.]00',
				part?.isDrawing || isMetadata ? drawingCostPercentage : undefined
			)
		}
	}

	return singleRowElement(
		getString('TOTAL_PART_COST').format('$'),
		'info',
		solutionTotalCost,
		configurationTotalCost,
		showEffectiveIcon,
		totalPartEffective,
		undefined,
		getString('TOTAL_PART_COST_DETAILS_CHECK_BOX_ON'),
		undefined,
		originalDesignSolutionTotalCost,
		originalDesignPartIsUnprintable
	)
}

export const generateUpfrontRows = (
	supplyChainOn: boolean,
	perPartCosts: any,
	showEffectiveIcon: any,
	upfrontEffective: any,
	perPartCostsTraditional: any,
	costsBreakDown: any,
	toolingEffective: any,
	supplyChainTraditionalDetails: any,
	designEffective: any,
	verificationEffective: any,
	isStandardCost?: boolean,
	isShowValuesInRanges?: boolean,
	minPartCosts?: any,
	maxPartCosts?: any,
	minCostsBreakDown?: any,
	maxCostsBreakDown?: any,
	productionCostCalculatedWithCustomFunction?: boolean,
	traditionalCostCalculatedCustom?: boolean,
	costDetailsSolutionWithoutCluster?: Record<string, any>,
	originalDesignCostsBreakDown?: Record<string, any>,
	originalDesignMinPartCosts?: Record<string, any>,
	originalDesignMaxPartCosts?: Record<string, any>,
	originalDesignMinCostsBreakDown?: Record<string, any>,
	originalDesignMaxCostsBreakDown?: Record<string, any>,
	originalDesignPerPartCosts?: Record<string, any>,
	originalDesignPartIsUnprintable?: boolean
) => {
	if (!supplyChainOn) {
		return []
	}

	const { costsBreakDown: supplyCostsBreakDown = {} } =
		supplyChainTraditionalDetails
	const { upfrontBreakDown = {} } = supplyCostsBreakDown
	const shouldShowEffectiveIcon = productionCostCalculatedWithCustomFunction
		? false
		: showEffectiveIcon
	let costDetailsSolutionWithoutClusterData
	let totalUpfrontCostsPerUnit
	let tooling
	let design
	let verification
	let originalDesignTotalUpfrontCostsPerUnit
	let originalDesignDesign
	let originalDesignTooling
	let originalDesignVerification

	if (!productionCostCalculatedWithCustomFunction) {
		totalUpfrontCostsPerUnit = Numeral(
			perPartCosts.totalUpfrontCostsPerUnit
		).format('0,0[.]00')
		tooling = Numeral(costsBreakDown.upfrontBreakDown.tooling).format(
			'0,0[.]00'
		)
		design = Numeral(costsBreakDown.upfrontBreakDown.design).format('0,0[.]00')
		verification = Numeral(costsBreakDown.upfrontBreakDown.verification).format(
			'0,0[.]00'
		)

		if (costDetailsSolutionWithoutCluster) {
			const supplyChainCostsBreakDown =
				costDetailsSolutionWithoutCluster.costDetails
					?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
			costDetailsSolutionWithoutClusterData = {
				totalUpfrontCostsPerUnit: Numeral(
					supplyChainCostsBreakDown?.perPartCosts?.totalUpfrontCostsPerUnit
				).format('0,0[.]00'),
				tooling: Numeral(
					supplyChainCostsBreakDown?.costsBreakDown?.upfrontBreakDown.tooling
				).format('0,0[.]00'),
				design: Numeral(
					supplyChainCostsBreakDown?.costsBreakDown?.upfrontBreakDown.design
				).format('0,0[.]00'),
				verification: Numeral(
					supplyChainCostsBreakDown?.costsBreakDown?.upfrontBreakDown
						.verification
				).format('0,0[.]00')
			}
		}

		if (isShowValuesInRanges && minPartCosts && maxPartCosts) {
			totalUpfrontCostsPerUnit = displayInRange(
				minPartCosts?.totalUpfrontCostsPerUnit,
				maxPartCosts?.totalUpfrontCostsPerUnit,
				totalUpfrontCostsPerUnit,
				'0,0[.]00'
			)

			tooling = displayInRange(
				minCostsBreakDown?.upfrontBreakDown?.tooling,
				maxCostsBreakDown?.upfrontBreakDown?.tooling,
				tooling,
				'0,0[.]00'
			)

			design = displayInRange(
				minCostsBreakDown?.upfrontBreakDown?.design,
				maxCostsBreakDown?.upfrontBreakDown?.design,
				design,
				'0,0[.]00'
			)

			verification = displayInRange(
				minCostsBreakDown?.upfrontBreakDown?.verification,
				maxCostsBreakDown?.upfrontBreakDown?.verification,
				verification,
				'0,0[.]00'
			)

			if (costDetailsSolutionWithoutCluster) {
				const supplyChainCostsBreakDownMax =
					costDetailsSolutionWithoutCluster.maxCostDetails
						?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
				const supplyChainCostsBreakDownMin =
					costDetailsSolutionWithoutCluster.minCostDetails
						?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown

				costDetailsSolutionWithoutClusterData = {
					totalUpfrontCostsPerUnit: displayInRange(
						supplyChainCostsBreakDownMin?.perPartCosts
							?.totalUpfrontCostsPerUnit,
						supplyChainCostsBreakDownMax?.perPartCosts
							?.totalUpfrontCostsPerUnit,
						costDetailsSolutionWithoutClusterData?.totalUpfrontCostsPerUnit,
						'0,0[.]00'
					),
					tooling: displayInRange(
						supplyChainCostsBreakDownMin?.costsBreakDown?.upfrontBreakDown
							?.tooling,
						supplyChainCostsBreakDownMax?.costsBreakDown?.upfrontBreakDown
							?.tooling,
						costDetailsSolutionWithoutClusterData?.tooling,
						'0,0[.]00'
					),
					design: displayInRange(
						supplyChainCostsBreakDownMin?.costsBreakDown?.upfrontBreakDown
							?.design,
						supplyChainCostsBreakDownMax?.costsBreakDown?.upfrontBreakDown
							?.design,
						costDetailsSolutionWithoutClusterData?.design,
						'0,0[.]00'
					),
					verification: displayInRange(
						supplyChainCostsBreakDownMin?.costsBreakDown?.upfrontBreakDown
							?.verification,
						supplyChainCostsBreakDownMax?.costsBreakDown?.upfrontBreakDown
							?.verification,
						costDetailsSolutionWithoutClusterData?.verification,
						'0,0[.]00'
					)
				}
			}
		}
	}
	if (originalDesignPerPartCosts && originalDesignCostsBreakDown) {
		originalDesignTotalUpfrontCostsPerUnit = Numeral(
			originalDesignPerPartCosts.totalUpfrontCostsPerUnit
		).format('0,0[.]00')
		originalDesignTooling = Numeral(
			originalDesignCostsBreakDown?.upfrontBreakDown.tooling
		).format('0,0[.]00')
		originalDesignDesign = Numeral(
			originalDesignCostsBreakDown?.upfrontBreakDown.design
		).format('0,0[.]00')
		originalDesignVerification = Numeral(
			originalDesignCostsBreakDown?.upfrontBreakDown.verification
		).format('0,0[.]00')

		if (
			isShowValuesInRanges &&
			originalDesignMinPartCosts &&
			originalDesignMaxPartCosts
		) {
			originalDesignTotalUpfrontCostsPerUnit = displayInRange(
				originalDesignMinPartCosts?.totalUpfrontCostsPerUnit,
				originalDesignMaxPartCosts?.totalUpfrontCostsPerUnit,
				originalDesignTotalUpfrontCostsPerUnit,
				'0,0[.]00'
			)

			originalDesignTooling = displayInRange(
				originalDesignMinCostsBreakDown?.upfrontBreakDown?.tooling,
				originalDesignMaxCostsBreakDown?.upfrontBreakDown?.tooling,
				originalDesignTooling,
				'0,0[.]00'
			)

			originalDesignDesign = displayInRange(
				originalDesignMinCostsBreakDown?.upfrontBreakDown?.design,
				originalDesignMaxCostsBreakDown?.upfrontBreakDown?.design,
				originalDesignDesign,
				'0,0[.]00'
			)

			originalDesignVerification = displayInRange(
				originalDesignMinCostsBreakDown?.upfrontBreakDown?.verification,
				originalDesignMaxCostsBreakDown?.upfrontBreakDown?.verification,
				originalDesignVerification,
				'0,0[.]00'
			)
		}
	}

	return [
		singleRowElement(
			`${getString('UPFRONT')} [$]`,
			'info',
			totalUpfrontCostsPerUnit,
			costDetailsSolutionWithoutClusterData
				? costDetailsSolutionWithoutClusterData.totalUpfrontCostsPerUnit
				: checkValueForStandardAndCustom(
						Numeral(perPartCostsTraditional.totalUpfrontCostsPerUnit).format(
							'0,0[.]00'
						),
						isStandardCost,
						traditionalCostCalculatedCustom
				  ),
			shouldShowEffectiveIcon,
			upfrontEffective,
			undefined,
			getString('COST_ANALYSIS_UPFRONT'),
			undefined,
			originalDesignTotalUpfrontCostsPerUnit,
			originalDesignPartIsUnprintable
		),
		singleRowElement(
			`${getString('TOOLING')} [$]`,
			'info',
			tooling,
			costDetailsSolutionWithoutClusterData
				? costDetailsSolutionWithoutClusterData.tooling
				: checkValueForStandardAndCustom(
						Numeral(upfrontBreakDown.tooling).format('0,0[.]00') || 0,
						isStandardCost,
						traditionalCostCalculatedCustom
				  ),
			shouldShowEffectiveIcon,
			toolingEffective,
			true,
			getString('COST_ANALYSIS_TOOLING'),
			undefined,
			originalDesignTooling,
			originalDesignPartIsUnprintable
		),
		singleRowElement(
			`${getString('DESIGN')} [$]`,
			'info',
			design,
			costDetailsSolutionWithoutClusterData
				? costDetailsSolutionWithoutClusterData.design
				: checkValueForStandardAndCustom(
						Numeral(upfrontBreakDown.design).format('0,0[.]00'),
						isStandardCost,
						traditionalCostCalculatedCustom
				  ),
			shouldShowEffectiveIcon,
			designEffective,
			true,
			getString('COST_ANALYSIS_DESIGN'),
			undefined,
			originalDesignDesign,
			originalDesignPartIsUnprintable
		),
		singleRowElement(
			`${getString('VERIFICATION')} [$]`,
			'info',
			verification,
			costDetailsSolutionWithoutClusterData
				? costDetailsSolutionWithoutClusterData.verification
				: checkValueForStandardAndCustom(
						Numeral(upfrontBreakDown.verification).format('0,0[.]00'),
						isStandardCost,
						traditionalCostCalculatedCustom
				  ),
			shouldShowEffectiveIcon,
			verificationEffective,
			true,
			getString('COST_ANALYSIS_VERIFICATION'),
			undefined,
			originalDesignVerification,
			originalDesignPartIsUnprintable
		)
	]
}

export const generateInventoryRows = (
	supplyChainOn: boolean,
	perPartCosts: any,
	showEffectiveIcon: any,
	inventoryEffective: any,
	perPartCostsTraditional: any,
	costsBreakDown: any,
	holdingEffective: any,
	supplyChainTraditionalDetails: any,
	orderingEffective: any,
	obsolescenceEffective: any,
	annualEffective: any,
	otherEffective: any,
	isShowValuesInRanges?: boolean,
	minPartCosts?: any,
	maxPartCosts?: any,
	minCostsBreakDown?: any,
	maxCostsBreakDown?: any,
	productionCostCalculatedWithCustomFunction?: boolean,
	traditionalCostCalculatedCustom?: boolean,
	costDetailsSolutionWithoutCluster?: Record<string, any>,
	originalDesignPerPartCosts?: Record<string, any>,
	originalDesignMinPartCosts?: Record<string, any>,
	originalDesignMaxPartCosts?: Record<string, any>,
	originalDesignCostsBreakDown?: Record<string, any>,
	originalDesignMinCostsBreakDown?: Record<string, any>,
	originalDesignMaxCostsBreakDown?: Record<string, any>,
	originalDesignPartIsUnprintable?: boolean
) => {
	if (!supplyChainOn) {
		return []
	}
	const { costsBreakDown: supplyCostsBreakDown = {} } =
		supplyChainTraditionalDetails
	const { inventoryBreakDown = {} } = supplyCostsBreakDown
	const shouldShowEffectiveIcon = productionCostCalculatedWithCustomFunction
		? false
		: showEffectiveIcon

	let totalInventoryCostsPerUnit
	let holding
	let ordering
	let obsolescence
	let annualMaintenance
	let other
	let costDetailsSolutionWithoutClusterData
	let originalDesignTotalInventoryCostsPerUnit
	let originalDesignHolding
	let originalDesignOrdering
	let originalDesignObsolescence
	let originalDesignAnnualMaintenance
	let originalDesignOther

	if (!productionCostCalculatedWithCustomFunction) {
		totalInventoryCostsPerUnit = Numeral(
			perPartCosts?.totalInventoryCostsPerUnit
		).format('0,0[.]00')

		holding = Numeral(costsBreakDown.inventoryBreakDown.holding).format(
			'0,0[.]00'
		)
		ordering = Numeral(costsBreakDown.inventoryBreakDown.ordering).format(
			'0,0[.]00'
		)
		obsolescence = Numeral(
			costsBreakDown.inventoryBreakDown.obsolescence
		).format('0,0[.]00')
		annualMaintenance = Numeral(
			costsBreakDown.inventoryBreakDown.annualMaintenance
		).format('0,0[.]00')
		other = Numeral(costsBreakDown.inventoryBreakDown.other).format('0,0[.]00')

		if (costDetailsSolutionWithoutCluster) {
			const supplyChainCostsBreakDown =
				costDetailsSolutionWithoutCluster.costDetails
					?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
			costDetailsSolutionWithoutClusterData = {
				totalInventoryCostsPerUnit: Numeral(
					supplyChainCostsBreakDown?.perPartCosts?.totalInventoryCostsPerUnit
				).format('0,0[.]00'),
				holding: Numeral(
					supplyChainCostsBreakDown?.costsBreakDown?.inventoryBreakDown.holding
				).format('0,0[.]00'),
				ordering: Numeral(
					supplyChainCostsBreakDown?.costsBreakDown?.inventoryBreakDown.ordering
				).format('0,0[.]00'),
				obsolescence: Numeral(
					supplyChainCostsBreakDown?.costsBreakDown?.inventoryBreakDown
						.obsolescence
				).format('0,0[.]00'),
				annualMaintenance: Numeral(
					supplyChainCostsBreakDown?.costsBreakDown?.inventoryBreakDown
						.annualMaintenance
				).format('0,0[.]00'),
				other: Numeral(
					supplyChainCostsBreakDown?.costsBreakDown?.inventoryBreakDown.other
				).format('0,0[.]00')
			}
		}

		if (isShowValuesInRanges && minPartCosts && maxPartCosts) {
			totalInventoryCostsPerUnit = displayInRange(
				minPartCosts?.totalInventoryCostsPerUnit,
				maxPartCosts?.totalInventoryCostsPerUnit,
				totalInventoryCostsPerUnit,
				'0,0[.]00'
			)
			holding = displayInRange(
				minCostsBreakDown?.inventoryBreakDown?.holding,
				maxCostsBreakDown?.inventoryBreakDown?.holding,
				holding,
				'0,0[.]00'
			)
			ordering = displayInRange(
				minCostsBreakDown?.inventoryBreakDown?.ordering,
				maxCostsBreakDown?.inventoryBreakDown?.ordering,
				ordering,
				'0,0[.]00'
			)
			obsolescence = displayInRange(
				minCostsBreakDown?.inventoryBreakDown?.obsolescence,
				maxCostsBreakDown?.inventoryBreakDown?.obsolescence,
				obsolescence,
				'0,0[.]00'
			)
			annualMaintenance = displayInRange(
				minCostsBreakDown?.inventoryBreakDown?.annualMaintenance,
				maxCostsBreakDown?.inventoryBreakDown?.annualMaintenance,
				annualMaintenance,
				'0,0[.]00'
			)

			other = displayInRange(
				minCostsBreakDown?.inventoryBreakDown?.other,
				maxCostsBreakDown?.inventoryBreakDown?.other,
				other,
				'0,0[.]00'
			)

			if (costDetailsSolutionWithoutCluster) {
				const supplyChainCostsBreakDownMax =
					costDetailsSolutionWithoutCluster.maxCostDetails
						?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
				const supplyChainCostsBreakDownMin =
					costDetailsSolutionWithoutCluster.minCostDetails
						?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown

				costDetailsSolutionWithoutClusterData = {
					totalInventoryCostsPerUnit: displayInRange(
						supplyChainCostsBreakDownMin?.perPartCosts
							?.totalInventoryCostsPerUnit,
						supplyChainCostsBreakDownMax?.perPartCosts
							?.totalInventoryCostsPerUnit,
						costDetailsSolutionWithoutClusterData?.totalInventoryCostsPerUnit,
						'0,0[.]00'
					),
					holding: displayInRange(
						supplyChainCostsBreakDownMin?.costsBreakDown?.inventoryBreakDown
							?.holding,
						supplyChainCostsBreakDownMax?.costsBreakDown?.inventoryBreakDown
							?.holding,
						costDetailsSolutionWithoutClusterData?.holding,
						'0,0[.]00'
					),
					ordering: displayInRange(
						supplyChainCostsBreakDownMin?.costsBreakDown?.inventoryBreakDown
							?.ordering,
						supplyChainCostsBreakDownMax?.costsBreakDown?.inventoryBreakDown
							?.ordering,
						costDetailsSolutionWithoutClusterData?.ordering,
						'0,0[.]00'
					),
					obsolescence: displayInRange(
						supplyChainCostsBreakDownMin?.costsBreakDown?.inventoryBreakDown
							?.obsolescence,
						supplyChainCostsBreakDownMax?.costsBreakDown?.inventoryBreakDown
							?.obsolescence,
						costDetailsSolutionWithoutClusterData?.obsolescence,
						'0,0[.]00'
					),
					annualMaintenance: displayInRange(
						supplyChainCostsBreakDownMin?.costsBreakDown?.inventoryBreakDown
							?.annualMaintenance,
						supplyChainCostsBreakDownMax?.costsBreakDown?.inventoryBreakDown
							?.annualMaintenance,
						costDetailsSolutionWithoutClusterData?.annualMaintenance,
						'0,0[.]00'
					),
					other: displayInRange(
						supplyChainCostsBreakDownMin?.costsBreakDown?.inventoryBreakDown
							?.other,
						supplyChainCostsBreakDownMax?.costsBreakDown?.inventoryBreakDown
							?.other,
						costDetailsSolutionWithoutClusterData?.other,
						'0,0[.]00'
					)
				}
			}
		}
		if (originalDesignPerPartCosts && originalDesignCostsBreakDown) {
			originalDesignTotalInventoryCostsPerUnit = Numeral(
				originalDesignPerPartCosts?.totalInventoryCostsPerUnit
			).format('0,0[.]00')
			originalDesignHolding = Numeral(
				originalDesignCostsBreakDown.inventoryBreakDown.holding
			).format('0,0[.]00')
			originalDesignOrdering = Numeral(
				originalDesignCostsBreakDown.inventoryBreakDown.ordering
			).format('0,0[.]00')
			originalDesignObsolescence = Numeral(
				originalDesignCostsBreakDown.inventoryBreakDown.obsolescence
			).format('0,0[.]00')
			originalDesignAnnualMaintenance = Numeral(
				originalDesignCostsBreakDown.inventoryBreakDown.annualMaintenance
			).format('0,0[.]00')
			originalDesignOther = Numeral(
				originalDesignCostsBreakDown.inventoryBreakDown.other
			).format('0,0[.]00')

			if (
				isShowValuesInRanges &&
				originalDesignMinPartCosts &&
				originalDesignMaxPartCosts
			) {
				originalDesignTotalInventoryCostsPerUnit = displayInRange(
					originalDesignMinPartCosts?.totalInventoryCostsPerUnit,
					originalDesignMaxPartCosts?.totalInventoryCostsPerUnit,
					originalDesignTotalInventoryCostsPerUnit,
					'0,0[.]00'
				)

				originalDesignHolding = displayInRange(
					originalDesignMinCostsBreakDown?.inventoryBreakDown?.holding,
					originalDesignMaxCostsBreakDown?.inventoryBreakDown?.holding,
					originalDesignHolding,
					'0,0[.]00'
				)

				originalDesignOrdering = displayInRange(
					originalDesignMinCostsBreakDown?.inventoryBreakDown?.ordering,
					originalDesignMaxCostsBreakDown?.inventoryBreakDown?.ordering,
					originalDesignOrdering,
					'0,0[.]00'
				)

				originalDesignObsolescence = displayInRange(
					originalDesignMinCostsBreakDown?.inventoryBreakDown?.obsolescence,
					originalDesignMaxCostsBreakDown?.inventoryBreakDown?.obsolescence,
					originalDesignObsolescence,
					'0,0[.]00'
				)

				originalDesignAnnualMaintenance = displayInRange(
					originalDesignMinCostsBreakDown?.inventoryBreakDown
						?.annualMaintenance,
					originalDesignMaxCostsBreakDown?.inventoryBreakDown
						?.annualMaintenance,
					originalDesignAnnualMaintenance,
					'0,0[.]00'
				)

				originalDesignOther = displayInRange(
					originalDesignMinCostsBreakDown?.inventoryBreakDown?.other,
					originalDesignMaxCostsBreakDown?.inventoryBreakDown?.other,
					originalDesignOther,
					'0,0[.]00'
				)
			}
		}
	}

	return [
		singleRowElement(
			`${getString('INVENTORY')} [$]`,
			'info',
			totalInventoryCostsPerUnit,
			costDetailsSolutionWithoutClusterData
				? costDetailsSolutionWithoutClusterData.totalInventoryCostsPerUnit
				: checkValueForCustom(
						Numeral(perPartCostsTraditional?.totalInventoryCostsPerUnit).format(
							'0,0[.]00'
						),
						traditionalCostCalculatedCustom
				  ),
			shouldShowEffectiveIcon,
			inventoryEffective,
			undefined,
			getString('COST_ANALYSIS_INVENTORY'),
			undefined,
			originalDesignTotalInventoryCostsPerUnit,
			originalDesignPartIsUnprintable
		),
		singleRowElement(
			`${getString('HOLDING')} [$]`,
			'info',
			holding,
			costDetailsSolutionWithoutClusterData
				? costDetailsSolutionWithoutClusterData.holding
				: checkValueForCustom(
						Numeral(inventoryBreakDown.holding).format('0,0[.]00') || 0,
						traditionalCostCalculatedCustom
				  ),
			shouldShowEffectiveIcon,
			holdingEffective,
			true,
			getString('COST_ANALYSIS_HOLDING'),
			undefined,
			originalDesignHolding,
			originalDesignPartIsUnprintable
		),
		singleRowElement(
			`${getString('ORDERING')} [$]`,
			'info',
			ordering,
			costDetailsSolutionWithoutClusterData
				? costDetailsSolutionWithoutClusterData.ordering
				: checkValueForCustom(
						Numeral(inventoryBreakDown.ordering).format('0,0[.]00'),
						traditionalCostCalculatedCustom
				  ),
			shouldShowEffectiveIcon,
			orderingEffective,
			true,
			getString('COST_ANALYSIS_ORDERING'),
			undefined,
			originalDesignOrdering,
			originalDesignPartIsUnprintable
		),
		singleRowElement(
			`${getString('OBSOLESCENCE')} [$]`,
			'info',
			obsolescence,
			costDetailsSolutionWithoutClusterData
				? costDetailsSolutionWithoutClusterData.obsolescence
				: checkValueForCustom(
						Numeral(inventoryBreakDown.obsolescence).format('0,0[.]00'),
						traditionalCostCalculatedCustom
				  ),
			shouldShowEffectiveIcon,
			obsolescenceEffective,
			true,
			getString('COST_ANALYSIS_OBSOLESCE'),
			undefined,
			originalDesignObsolescence,
			originalDesignPartIsUnprintable
		),
		singleRowElement(
			`${getString('ANNUAL_MAINTENANCE')} [$]`,
			'info',
			annualMaintenance,
			costDetailsSolutionWithoutClusterData
				? costDetailsSolutionWithoutClusterData.annualMaintenance
				: checkValueForCustom(
						Numeral(inventoryBreakDown.annualMaintenance).format('0,0[.]00'),
						traditionalCostCalculatedCustom
				  ),
			shouldShowEffectiveIcon,
			annualEffective,
			true,
			getString('COST_ANALYSIS_ANNUAL_MAINTENANCE'),
			undefined,
			originalDesignAnnualMaintenance,
			originalDesignPartIsUnprintable
		),
		singleRowElement(
			`${getString('OTHER')} [$]`,
			'',
			other,
			costDetailsSolutionWithoutClusterData
				? costDetailsSolutionWithoutClusterData.other
				: checkValueForCustom(
						Numeral(inventoryBreakDown.other).format('0,0[.]00'),
						traditionalCostCalculatedCustom
				  ),
			shouldShowEffectiveIcon,
			otherEffective,
			true,
			undefined,
			undefined,
			originalDesignOther,
			originalDesignPartIsUnprintable
		)
	]
}

export const getProductionRows = (
	perPartCosts: any,
	showEffectiveIcon: any,
	productionEffective: any,
	perPartCostsTraditional: any,
	solution: any,
	configuration: any,
	isShowValuesInRanges: boolean = false,
	costDetailsSolutionWithoutCluster?: Record<string, any>,
	minPartCosts?: any,
	maxPartCosts?: any,
	productionCostCalculatedWithCustomFunction?: boolean,
	traditionalCostCalculatedCustom?: boolean,
	fullTrayAssumption?: boolean,
	originalDesignPerPartCosts?: Record<string, any>,
	originalDesignMinPartCosts?: Record<string, any>,
	originalDesignMaxPartCosts?: Record<string, any>,
	originalDesignSolution?: ISolution,
	originalDesignPartIsUnprintable?: boolean,
	isCluster?: boolean,
	onEditAssemblingCostClick?: () => void
) => {
	const disabledParameters =
		solution?.costCalculatedWithOverrideFunction || null

	const {
		materialCost,
		machineCost,
		consumablesCost,
		mandatoryPostProcessCost,
		optionalPostProcessCost,
		labor,
		totalPartMaterial,
		totalSupportMaterial,
		unusedMaterialWaste,
		printTime,
		operatingCost,
		perBuildConsumables,
		totalOrderFeesCost,
		laborHoursBuildExchange,
		laborHoursDuringPureBuild,
		totalProductionCostsPerUnit,
		materialCostIs,
		partWeightIs,
		machineCostPerHour,
		partPrintTime,
		laborTechnicianFTE,
		totalSharedMachineTime,
		buildPrep,
		assemblingCost,
		originalDesignTotalProductionCostsPerUnit,
		originalDesignBuildPrep,
		originalDesignTotalOrderFeesCost,
		originalDesignConsumablesCost,
		originalDesignOperatingCost,
		originalDesignPerBuildConsumables,
		originalDesignUnusedMaterialWaste,
		originalDesignTotalSupportMaterial,
		originalDesignTotalPartMaterial,
		originalDesignMaterialCost,
		originalDesignMachineCost,
		originalDesignTotalSharedMachineTime,
		originalDesignPrintTime,
		originalDesignLabor,
		originalDesignLaborHoursBuildExchange,
		originalDesignLaborHoursDuringPureBuild,
		originalDesignMandatoryPostProcessCost,
		originalDesignOptionalPostProcessCost
	} = generateProductionParameters(
		solution,
		configuration,
		isShowValuesInRanges,
		perPartCosts,
		minPartCosts,
		maxPartCosts,
		fullTrayAssumption,
		disabledParameters,
		originalDesignPerPartCosts,
		originalDesignMinPartCosts,
		originalDesignMaxPartCosts,
		originalDesignSolution,
		isCluster
	)
	const {
		totalOrderFeesCostWithoutCluster,
		totalProductionCostsPerUnitWithoutCluster,
		buildPrepWithoutCluster,
		materialCostWithoutCluster,
		totalPartMaterialWithoutCluster,
		totalSupportMaterialWithoutCluster,
		unusedMaterialWasteWithoutCluster,
		materialCostIsWithoutCluster,
		partWeightIsWithoutCluster,
		mandatoryPostProcessCostWithoutCluster,
		optionalPostProcessCostWithoutCluster,
		laborWithoutCluster,
		laborHoursBuildExchangeWithoutCluster,
		laborHoursDuringPureBuildWithoutCluster,
		laborTechnicianFTEWithoutCluster,
		assemblingCostWithoutCluster,
		machineCostWithoutCluster,
		buildPrepTimeWithoutCluster,
		printTimeWithoutCluster,
		machineCostPerHourWithoutCluster,
		partPrintTimeWithoutCluster,
		totalSharedMachineTimeWithoutCluster,
		consumablesCostWithoutCluster,
		operatingCostWithoutCluster,
		perBuildConsumablesWithoutCluster
	} = generateProductionWithoutClusterParameters(
		solution,
		configuration,
		isShowValuesInRanges,
		fullTrayAssumption,
		disabledParameters,
		costDetailsSolutionWithoutCluster
	)

	if (
		productionCostCalculatedWithCustomFunction ||
		traditionalCostCalculatedCustom
	) {
		return singleRowElement(
			`${getString('PRODUCTION')} [$]`,
			'info',
			productionCostCalculatedWithCustomFunction
				? ''
				: totalProductionCostsPerUnit,
			totalProductionCostsPerUnitWithoutCluster ||
				traditionalCostCalculatedCustom
				? ''
				: Numeral(perPartCostsTraditional.totalProductionCostsPerUnit).format(
						'0,0[.]00'
				  ),
			false,
			false,
			true,
			getString('COST_ANALYSIS_PRODUCTION'),
			undefined,
			originalDesignTotalProductionCostsPerUnit,
			originalDesignPartIsUnprintable
		)
	}

	return [
		singleRowElement(
			`${getString('PRODUCTION')} [$]`,
			'info',
			totalProductionCostsPerUnit,
			totalProductionCostsPerUnitWithoutCluster ||
				Numeral(perPartCostsTraditional.totalProductionCostsPerUnit).format(
					'0,0[.]00'
				),
			showEffectiveIcon,
			productionEffective,
			false,
			getString('COST_ANALYSIS_PRODUCTION'),
			undefined,
			originalDesignTotalProductionCostsPerUnit,
			originalDesignPartIsUnprintable
		),
		materialRowElement(
			materialCost,
			totalPartMaterial,
			totalSupportMaterial,
			unusedMaterialWaste,
			materialCostIs,
			partWeightIs,
			disabledParameters,
			materialCostWithoutCluster,
			totalPartMaterialWithoutCluster,
			totalSupportMaterialWithoutCluster,
			unusedMaterialWasteWithoutCluster,
			materialCostIsWithoutCluster,
			partWeightIsWithoutCluster,
			originalDesignUnusedMaterialWaste,
			originalDesignTotalSupportMaterial,
			originalDesignTotalPartMaterial,
			originalDesignMaterialCost,
			originalDesignPartIsUnprintable
		),
		...buildPrepRowElement(
			buildPrep,
			buildPrepWithoutCluster,
			disabledParameters,
			originalDesignBuildPrep,
			originalDesignPartIsUnprintable
		),
		machineRowElement(
			machineCost,
			totalSharedMachineTime,
			printTime,
			machineCostPerHour,
			partPrintTime,
			disabledParameters,
			machineCostWithoutCluster,
			totalSharedMachineTimeWithoutCluster,
			printTimeWithoutCluster,
			machineCostPerHourWithoutCluster,
			partPrintTimeWithoutCluster,
			originalDesignMachineCost,
			originalDesignTotalSharedMachineTime,
			originalDesignPrintTime,
			originalDesignPartIsUnprintable
		),
		consumablesRowElement(
			consumablesCost,
			operatingCost,
			perBuildConsumables,
			disabledParameters,
			consumablesCostWithoutCluster,
			operatingCostWithoutCluster,
			perBuildConsumablesWithoutCluster,
			originalDesignConsumablesCost,
			originalDesignOperatingCost,
			originalDesignPerBuildConsumables,
			originalDesignPartIsUnprintable
		),
		laborRowElement(
			labor,
			laborHoursBuildExchange,
			laborHoursDuringPureBuild,
			laborTechnicianFTE,
			mandatoryPostProcessCost,
			optionalPostProcessCost,
			disabledParameters,
			laborWithoutCluster,
			laborHoursBuildExchangeWithoutCluster,
			laborHoursDuringPureBuildWithoutCluster,
			laborTechnicianFTEWithoutCluster,
			mandatoryPostProcessCostWithoutCluster,
			optionalPostProcessCostWithoutCluster,
			originalDesignLabor,
			originalDesignLaborHoursBuildExchange,
			originalDesignLaborHoursDuringPureBuild,
			originalDesignMandatoryPostProcessCost,
			originalDesignOptionalPostProcessCost,
			originalDesignPartIsUnprintable,
			assemblingCost,
			assemblingCostWithoutCluster,
			onEditAssemblingCostClick,
			isCluster
		),
		...costOrderFeesElement(
			totalOrderFeesCost,
			totalOrderFeesCostWithoutCluster,
			disabledParameters,
			originalDesignTotalOrderFeesCost,
			originalDesignPartIsUnprintable
		)
	]
}

export const nonPrintedPartsRowElement = (nonPrintedPartsCost: number) => {
	return singleRowElement(
		`${getString('COST_TABLE_NON_PRINTED_PARTS')} [$]`,
		'info',
		0,
		nonPrintedPartsCost,
		false,
		false,
		false,
		getString('COST_TABLE_NON_PRINTED_PARTS_INFO')
	)
}
