import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getLoginState } from '../../global actions/index'
import Login from '../../Scenes/Authentication/Login/Login'
import SelectiveRoute from './SelectiveRoute'
import {
	getStringItemFromLocalStorage,
	removeItemFromLocalStorage
} from 'Services/LocalStorageService'

class PrivateRoute extends Component {
	componentDidUpdate() {
		const redirectUrl = getStringItemFromLocalStorage('redirect')
		if (this.props.loggedIn && redirectUrl) {
			removeItemFromLocalStorage('redirect')
			window.location.replace(redirectUrl)
		}
	}

	isRouteLegal = () => {
		return this.props.loggedIn
	}

	render() {
		return (
			<SelectiveRoute
				alternativeComponent={Login}
				isRouteLegalFunc={this.isRouteLegal.bind(this)}
				parentProps={this.props}
			/>
		)
	}
}

const mapStateToProps = ({ user }) => {
	const { stateUnknown, loggedIn, loginPending } = user

	return { loggedIn, stateUnknown, loginPending }
}
export default withRouter(
	connect(mapStateToProps, { getLoginState })(PrivateRoute)
)
