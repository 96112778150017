import React, { FC, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import TextField from '@material-ui/core/TextField'
import { Folder } from '@material-ui/icons'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import cx from 'classnames'

import { Route } from '../SideBarTypes'
import { onNewUserFolderCancel, onNewUserFolderConfirm } from 'global actions'
import Loader from 'Scenes/Loader/Loader'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	itemIcon: string
	updateState: (routes: Route[]) => void
}

const NewUserFolder: FC<IProps> = ({ itemIcon, updateState }) => {
	const [folderName, setFolderName] = useState('')
	const { newFolderLoading, routes } = useSelector(
		(state: RootStateOrAny) => state.userHome
	)
	const dispatch = useDispatch()

	const onFolderNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFolderName(e.target.value)
	}

	const onFolderCancelClick = () => {
		dispatch(onNewUserFolderCancel())
		setFolderName('')
	}

	const onFolderConfirmClick = () => {
		const resetFolderName = () => {
			setFolderName('')
			updateState(routes)
		}
		dispatch(onNewUserFolderConfirm(folderName, resetFolderName))
	}

	return (
		<div className="sidebar--projects-toolbar--new-folder">
			<div
				className={cx(itemIcon, 'sidebar--projects-toolbar--new-folder-icon')}
			>
				<Folder />
			</div>
			<div className="sidebar--projects-toolbar--new-folder--input">
				<TextField
					InputProps={{
						startAdornment: (
							<CheckIcon
								data-qa="data-qa-side-bar-new-folder-confirm-input-btn"
								onClick={onFolderConfirmClick}
								className={cx('confirm-input', {
									hidden: !folderName || newFolderLoading
								})}
							/>
						),
						endAdornment: newFolderLoading ? (
							<Loader
								wrapperClassName="link-loader"
								showFlex={false}
								size={25}
								load={true}
								message=""
							/>
						) : (
							<ClearIcon
								onClick={onFolderCancelClick}
								className={cx('clear-input')}
							/>
						)
					}}
					id="new-folder-input"
					type="text"
					value={folderName}
					placeholder={getString('NEW_USER_FOLDER_PLACEHOLDER')}
					margin="none"
					onChange={onFolderNameChange}
					disabled={newFolderLoading}
					autoComplete="off"
					autoFocus
				/>
			</div>
		</div>
	)
}

export default NewUserFolder
