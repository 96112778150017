import  { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { isEmpty } from 'lodash'

import * as ProfileActions from './ProfileActions'
import {
	ERROR,
	NAV_TITLE_USER_PROFILE,
	PROFILE_FORM_COMPANY_LABEL,
	PROFILE_FORM_EMAIL_LABEL,
	PROFILE_FORM_FULL_NAME_LABEL,
	PROFILE_FORM_HEADER,
	SUCCESS,
	UPDATE_USER_PROFILE,
	USER_ADDRESS,
	USER_PROFILE_ADDRESS_ALERT,
	USER_PROFILE_ADDRESS_PLACEHOLDER,
	USER_PROFILE_COMPANY_ALERT,
	USER_PROFILE_EMAIL_ALERT,
	USER_PROFILE_NAME_ALERT
} from '../../../Services/Strings'
import ButtonWithLoader from '../../Components/ButtonWithLoader'
import CastorForm from '../../Components/CastorForm/CastorForm'
import CastorLocationDropdown from '../../Components/CastorLocationSearchInput/CastorLocationDropdown/CastorLocationDropdown'
import CastorLocationSearchInput from '../../Components/CastorLocationSearchInput/CastorLocationSearchInput'
import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import {
	CustomInput,
	Danger
} from '../../Components/thirdParty/CreativeTim/components'

import styles from './profile.css'

class Profile extends Component {
	componentDidMount() {
		const { setupProfileDetails, userDetails } = this.props
		setupProfileDetails(userDetails)
	}

	componentDidUpdate = prevProps => {
		const { onValidAddressChange, validAddress, setupProfileDetails } =
			this.props
		const { userDetails } = this.props
		if (
			isEmpty(prevProps.userDetails) &&
			userDetails !== prevProps.userDetails
		) {
			setupProfileDetails(userDetails)
		}
		if (prevProps.validAddress !== validAddress) {
			onValidAddressChange(validAddress)
		}
	}

	onUserNameChange = event => {
		const { onUserNameChange } = this.props
		onUserNameChange(event.target.value)
	}

	onUserEmailChange = event => {
		const { onUserEmailChange } = this.props
		onUserEmailChange(event.target.value)
	}

	onUserCompanyChange = event => {
		const { onUserCompanyChange } = this.props
		onUserCompanyChange(event.target.value)
	}

	renderAlert = (validationModel, alertText) => {
		if (validationModel !== ERROR) {
			return <div />
		}
		return <Danger>{alertText}</Danger>
	}

	updateUserProfile = () => {
		const {
			updateUserProfile,
			userName,
			userEmail,
			userCompany,
			address,
			city,
			state,
			country,
			long,
			lat,
			zip_code
		} = this.props
		updateUserProfile({
			name: userName,
			email: userEmail,
			company: userCompany,
			formatted_address: address,
			city,
			state,
			country,
			long,
			lat,
			zip_code
		})
	}

	renderProfileFormContent = () => {
		const {
			userName,
			userEmail,
			userCompany,
			validUserName,
			validUserEmail,
			validUserCompany,
			disableUploadButton,
			profileLoading,
			validUserAddress,
			isOnPrem
		} = this.props
		return (
			<div style={{ padding: '15px' }}>
				<div>
					<CustomInput
						success={validUserName === SUCCESS}
						error={validUserName === ERROR}
						labelText={PROFILE_FORM_FULL_NAME_LABEL}
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							onChange: event => this.onUserNameChange(event),
							type: 'text',
							value: userName
						}}
					/>
					{this.renderAlert(validUserName, USER_PROFILE_NAME_ALERT)}
				</div>

				<div>
					<CustomInput
						success={validUserEmail === SUCCESS}
						error={validUserEmail === ERROR}
						labelText={PROFILE_FORM_EMAIL_LABEL}
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							onChange: event => this.onUserEmailChange(event),
							type: 'text',
							value: userEmail,
							disabled: true
						}}
					/>
					{this.renderAlert(validUserEmail, USER_PROFILE_EMAIL_ALERT)}
				</div>

				<div>
					<CustomInput
						success={validUserCompany === SUCCESS}
						error={validUserCompany === ERROR}
						labelText={PROFILE_FORM_COMPANY_LABEL}
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							onChange: event => this.onUserCompanyChange(event),
							type: 'text',
							value: userCompany
						}}
					/>
					{this.renderAlert(validUserCompany, USER_PROFILE_COMPANY_ALERT)}
				</div>
				<div>
					{isOnPrem ? (
						<CastorLocationDropdown />
					) : (
						<>
							<CastorLocationSearchInput
								labelText={USER_ADDRESS}
								placeHolder={USER_PROFILE_ADDRESS_PLACEHOLDER}
							/>
							{this.renderAlert(validUserAddress, USER_PROFILE_ADDRESS_ALERT)}
						</>
					)}
				</div>

				<ButtonWithLoader
					style={styles.profileSubmitButtonStyle}
					loading={profileLoading}
					disabled={disableUploadButton}
					primary={true}
					onClick={this.updateUserProfile}
				>
					{UPDATE_USER_PROFILE}
				</ButtonWithLoader>
			</div>
		)
	}

	render() {
		return (
			<NavBarAndMaterial title={NAV_TITLE_USER_PROFILE}>
				<CastorForm
					formTitle={PROFILE_FORM_HEADER}
					content={this.renderProfileFormContent()}
					superViewStyle={{ marginBottom: '155px' }}
				/>
			</NavBarAndMaterial>
		)
	}
}

const mapStateToProps = ({
	user: { userDetails },
	GlobalReducer: { isOnPrem },
	ProfileReducer,
	CastorLocationSearchReducer
}) => {
	return {
		isOnPrem,
		userDetails,
		...ProfileReducer,
		...CastorLocationSearchReducer
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...ProfileActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
