import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'

import * as styles from './UserMenu.css'
import { SIGN_OUT } from '../../../Services/Strings'

class SignOutItem extends Component {
	render() {
		return (
			<div style={styles.btnDiv}>
				<PowerSettingsNewIcon />
				<Button
					data-qa="data-qa-menu-sign-out"
					variant="text"
					size="small"
					style={styles.itemBtn}
				>
					{SIGN_OUT}
				</Button>
			</div>
		)
	}
}

export default SignOutItem
