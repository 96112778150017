import {
	ADDITIVE_PDF_ADD,
	ADDITIVE_PDF_ERROR,
	ADDITIVE_PDF_RESET,
	ADDITIVE_TOGGLE_POPUP
} from './AdditiveMindsTypes'
import { Action } from 'global actions/ActionModels'

export interface AdditiveMindsInitialState {
	readonly additiveFile: Array<any>
	readonly preparedToSend: any
	readonly showPopup: boolean
	readonly error: string
}

const initialState: AdditiveMindsInitialState = {
	showPopup: false,
	additiveFile: [],
	preparedToSend: {},
	error: ''
}

const AdditiveMindsReducer = (
	state = initialState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case ADDITIVE_TOGGLE_POPUP: {
			const {showPopup} = payload

			return {
				...state,
				showPopup
			}
		}

		case ADDITIVE_PDF_ADD:
			const { additiveFile, preparedToSend } = payload
			return {
				...state,
				additiveFile,
				preparedToSend,
				error: ''
			}

		case ADDITIVE_PDF_ERROR:
			const { error } = payload

			return {
				...state,
				error
			}

		case ADDITIVE_PDF_RESET:
			return {
				...state,
				additiveFile: [],
				preparedToSend: {},
				error: ''
			}

		default:
			return state
	}
}

export default AdditiveMindsReducer