import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import TransparentButton from 'Scenes/Components/TransparentButton'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as DownloadMetaData } from 'assets/img/svg/icons/download_metadata.svg'

interface UploadMetaDataProps {
	isOnPrem: boolean
}

const UploadMetaData: FC<UploadMetaDataProps> = ({ isOnPrem }) => {
	const { defaultMetadataFilePath } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const link = isOnPrem
		? `${process.env.PUBLIC_URL}/materials/CASTOR_MetaData_Template.xlsx`
		: defaultMetadataFilePath

	return (
		<TransparentButton
			isAnchor={true}
			href={link}
			data-qa="data-qa-download-metadata-file"
			download
			className="download-metadata"
		>
			<DownloadMetaData />
			<span>{getString('DOWNLOAD_TEMPLATE_FILE')}</span>
		</TransparentButton>
	)
}

export default memo(UploadMetaData)
