import parseHTML from 'html-react-parser'
import { FC, memo, useEffect, useReducer, useState } from 'react'
import Moment from 'react-moment'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import Close from '@material-ui/icons/Close'
import OpenInNew from '@material-ui/icons/OpenInNew'
import cx from 'classnames'

import FlexBox from '../FlexBox'
import { toggleNotificationView } from './PushNotificationActions'
import {
	getNotificationMessage,
	PushNotificationContent
} from './PushNotificationService'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const PushNotificationList: FC = () => {
	const { pushNotifications, isOpen } = useSelector(
		(state: RootStateOrAny) => state.PushNotificationReducer
	)
	const { isCommunicationTool } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const dispatch = useDispatch()
	const history = useHistory()

	if (!isOpen) return <></>

	return (
		<div className="push-notification_wrapper">
			<FlexBox
				alignItems="center"
				justifyContent="space-between"
				className="push-notification_title"
			>
				<p>{getString('NOTIFICATIONS')}</p>
				<Button
					color="transparent"
					className="push-notification_button"
					onClick={() => dispatch(toggleNotificationView(false))}
				>
					<FlexBox className="title" alignItems="center">
						<Close />
					</FlexBox>
				</Button>
			</FlexBox>
			<div className="push-notification_content">
				{pushNotifications?.length === 0 && (
					<div className="push-notification_message no-hover">
						<FlexBox justifyContent="center" alignItems="center">
							{getString('NO_MESSAGES_FOUND')}
						</FlexBox>
					</div>
				)}
				{pushNotifications.map((data: PushNotificationContent, i: number) => {
					const content = data
					const { titleString, messageString, messageLink, internalLink } =
						getNotificationMessage(content, isCommunicationTool)

					return (
						<div
							key={titleString + i}
							className="push-notification_message"
							data-qa="data-qa-notification-message"
							onClick={() => {
								if (messageLink) {
									if (internalLink) {
										history.push(messageLink, { pushNotificationLink: true })
									} else {
										window.open(messageLink, '_blank')
									}
									dispatch(toggleNotificationView(false))
								}
							}}
						>
							<FlexBox className="title" alignItems="center">
								<div
									className={cx('status', {
										new: !content.isViewed,
										old: content.isViewed
									})}
								></div>
								<p>{titleString}</p>
								{messageLink && (
									<OpenInNew data-qa="data-qa-redirect-to-project-icon" />
								)}
							</FlexBox>
							<div className="text" data-qa="data-qa-notification-text">
								{parseHTML(messageString || content.messageText || '')}
							</div>
							<Moment fromNow className="time">
								{content.createdAt}
							</Moment>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default memo(PushNotificationList)
