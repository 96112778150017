export const progressFlex = {
  backgroundColor: 'rgb(256, 256, 256)',
  filter: 'alpha(opacity=80)',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  width: 'calc(100% - 0px)',
  height: 'calc(100% - 0px)',
  zIndex: 100,
  top: 0
}

export const progressFull = {
  ...progressFlex,
  height: '100vh'
}

export const container = {
  position: 'absolute',
  top: 0,
  left: '260px',
  right: 0,
  bottom: 0,
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  display: 'flex'
}

export const submitButtonWithLoaderStyle = {
  alignItems: 'center',
  justifyContent: 'center',
  width: 'auto'
}
