export enum IPrintersTypes {
  BEST_MATCH = 'bestMatch',
  ELIGIBLE = 'eligible',
  IN_HOUSE = 'inHouse',
  CUSTOM = 'custom'
}

export interface ICompaniesPrinters {
  printers: ICompany[]
}

export interface ICompany {
  company: string,
  printers: IPrinter[]
}

export interface IPrinter {
  id: string
  company: string
  cost: number
  defaultLayerThickness: number
  isMainstream: boolean
  isDesktop: boolean
  materials: any[]
  name: string
  printerId: string
}

export interface IBestMatchConfiguration {
  printers: IDefaultConfiguration[]
}

export interface IDefaultConfiguration {
  id: number
  name: string
  priorities: {}
  filters: {}
  isImportant: boolean
  wallThicknessTestInMM: number
  isAffectingPart: boolean
  resultType: number
  showAsDefault: boolean
  desktopPrinter: boolean
  absorbeMaterialPriorities: boolean
  includePostProcess: boolean
}
