import { FC } from 'react'

import Divider from '@material-ui/core/Divider'

import {
	checkIfEmpty,
	filterUserMaterialMapping,
	filterUserMaterialMappingExpression
} from './CastorMaterialNamesMappingService'
import MaterialNamesMappingRow from './MaterialNamesMappingRow'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import { Material } from 'Services/models/IMaterial'
import { IUserMaterialNamesMapping } from 'Services/models/IMaterialNamesMapping'
import { getString } from 'Services/Strings/StringService'

import './CustomizeMaterialNamesMapping.scss'

interface IProps {
	title: string
	explanation: string
	userMaterialNamesMapping: IUserMaterialNamesMapping[]
	materialCategories: any
	materialTypes: string[]
	materials: Material[]
	isActive?: boolean
	allowRemove?: boolean
	disableMaterialNameMappingSaveAll?: boolean
	isLoadingMaterial?: boolean
	updateMaterialNameMapping: Function
	removeUserMaterialNameMapping: Function
	removeAllUserMaterialNameMapping?: (active: boolean) => void
	changeSelectedMaterial?: Function
	devider?: boolean
}
const DividerTSX: any = Divider

const MaterialNamesMappingList: FC<IProps> = ({
	title,
	explanation,
	userMaterialNamesMapping,
	materialCategories,
	materials,
	materialTypes,
	isActive = false,
	allowRemove = true,
	disableMaterialNameMappingSaveAll,
	isLoadingMaterial,
	updateMaterialNameMapping,
	removeUserMaterialNameMapping,
	removeAllUserMaterialNameMapping,
	changeSelectedMaterial,
	devider = true
}) => {
	if (
		!userMaterialNamesMapping ||
		checkIfEmpty(userMaterialNamesMapping, isActive)
	) {
		return <div />
	}

	const onUpdateMaterialNamesMapping = () => {
		updateMaterialNameMapping(
			filterUserMaterialMapping(userMaterialNamesMapping, isActive)
		)
	}

	const filteredUserMaterialNamesMapping = filterUserMaterialMappingExpression(
		userMaterialNamesMapping,
		isActive
	)

	return (
		<div data-qa="data-qa-added-materials-block">
			{devider && <DividerTSX className="custom-material-names--divider" />}
			<div className="custom-material-names--title">{title}</div>
			<div className="custom-material-names--explanation">
				{explanation}

				{!isActive && (
					<ButtonWithLoader
						style={{
							padding: '5px 20px',
							width: 'unset',
							marginRight: '5px'
						}}
						onClick={onUpdateMaterialNamesMapping}
						loading={false}
						disabled={disableMaterialNameMappingSaveAll}
					>
						{getString('SAVE_ALL')}
					</ButtonWithLoader>
				)}
				{removeAllUserMaterialNameMapping && (
					<ButtonWithLoader
						style={{
							padding: '5px 20px',
							width: 'unset',
							marginRight: '5px'
						}}
						onClick={removeAllUserMaterialNameMapping}
						loading={false}
					>
						{getString('REMOVE_ALL')}
					</ButtonWithLoader>
				)}
			</div>
			{filteredUserMaterialNamesMapping?.map(userMaterialNameMapping => (
				<MaterialNamesMappingRow
					isLoadingMaterial={isLoadingMaterial}
					key={userMaterialNameMapping.id}
					userMaterialNameMapping={userMaterialNameMapping}
					userMaterialNamesMapping={userMaterialNamesMapping}
					materialCategories={materialCategories}
					materials={materials}
					allowRemove={allowRemove}
					materialTypes={materialTypes}
					updateSelectedMaterial={updateMaterialNameMapping}
					removeUserMaterialNameMapping={removeUserMaterialNameMapping}
					changeSelectedMaterial={changeSelectedMaterial}
				/>
			))}
		</div>
	)
}

export default MaterialNamesMappingList
