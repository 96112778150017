import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as AdminMaterialPrinterPairsActions from './AdminMaterialPrinterPairsActions'
import {
  ADMIN_MATERIAL_PRINTER_PAIRS_PAGE_HEADER,
  ADMIN_MATERIAL_PRINTER_PAIRS_SUB_TITLE,
  ADMIN_MATERIAL_PRINTER_PAIRS_PARAMS,
  ADMIN_MATERIAL_PRINTER_PAIRS_TABLE_TEXTS
} from '../../../../Services/Strings'
import {
  adminMaterialPrinterPairsRoute,
  adminMaterialPrinterPairsEditFormRoute,
  adminMaterialPrinterPairsNewFormRoute
} from '../../../../Services/routeFuncs'
import AdminDataTable from '../AdminDataTable/AdminDataTable'

import '../adminHome.scss'

const materialPrinterPairParams = ADMIN_MATERIAL_PRINTER_PAIRS_PARAMS

class AdminMaterialPrinterPairs extends Component {
  componentWillMount() {
    this.setupAdminMaterialPrinterPairsPage()
  }

  componentDidUpdate = prevProps => {
    this.setupAdminMaterialPrinterPairsPage(prevProps)
  }

  setupAdminMaterialPrinterPairsPage = prevProps => {
    const {
        match,
        pageNumber,
        setupAdminMaterialPrinterPairsPage,
        materialPrinterPairs,
        limitMaterialPrinterPairs,
        searchPhrase
      } = this.props,
      urlPage = parseInt(match.params['page']) || 1
    if (
      urlPage !== pageNumber ||
      (prevProps && searchPhrase !== prevProps.searchPhrase) ||
      !materialPrinterPairs.length
    ) {
      setupAdminMaterialPrinterPairsPage(
        searchPhrase,
        urlPage,
        limitMaterialPrinterPairs
      )
    }
  }

  renderPrinterField = materialPrinterPair => {
    return (
      <div className="admin-multiple-line-field">
        <div>
          {ADMIN_MATERIAL_PRINTER_PAIRS_TABLE_TEXTS.ID}:{' '}
          {materialPrinterPair.printer.id}
        </div>
        <div>
          {ADMIN_MATERIAL_PRINTER_PAIRS_TABLE_TEXTS.NAME}:{' '}
          {materialPrinterPair.printer.name}
        </div>
      </div>
    )
  }

  renderMaterialField = materialPrinterPair => {
    return (
      <div className="admin-multiple-line-field">
        <div>
          {ADMIN_MATERIAL_PRINTER_PAIRS_TABLE_TEXTS.ID}:{' '}
          {materialPrinterPair.printerMaterial.id}
        </div>
        <div>
          {ADMIN_MATERIAL_PRINTER_PAIRS_TABLE_TEXTS.NAME}:{' '}
          {materialPrinterPair.printerMaterial.name}
        </div>
      </div>
    )
  }

  renderMaterialPrinterPairsTableData = () => {
    const { materialPrinterPairs } = this.props
    return materialPrinterPairs
      .filter(
        materialPrinterPair =>
          materialPrinterPair.printer && materialPrinterPair.printerMaterial
      )
      .map(materialPrinterPair => {
        return [
          this.renderPrinterField(materialPrinterPair),
          this.renderMaterialField(materialPrinterPair),
          materialPrinterPair.buildRate,
          materialPrinterPair.id
        ]
      })
  }

  deleteRow = id => {
    this.props.deleteMaterialPrinterPair(id)
  }

  setSearchPhrase = searchPhrase => {
    this.props.setSearchPhrase(searchPhrase)
  }

  render() {
    const {
      showNoMaterialPrinterPairsAlert,
      showPagination,
      pageNumber,
      isLastPage,
      showAdminMaterialPrinterPairsAlert,
      totalMaterialPrinterPairs,
      limitMaterialPrinterPairs,
      searchPhrase,
      loading
    } = this.props
    return (
      <AdminDataTable
        loading={loading}
        formTitle={ADMIN_MATERIAL_PRINTER_PAIRS_PAGE_HEADER}
        formSubTitle={ADMIN_MATERIAL_PRINTER_PAIRS_SUB_TITLE}
        tableHeadParams={materialPrinterPairParams}
        tableData={this.renderMaterialPrinterPairsTableData()}
        linkToRouteFunction={adminMaterialPrinterPairsRoute}
        showNoDataAlert={showNoMaterialPrinterPairsAlert}
        showPagination={showPagination}
        pageNumber={pageNumber}
        isLastPage={isLastPage}
        showAdminErrorAlert={showAdminMaterialPrinterPairsAlert}
        totalResults={totalMaterialPrinterPairs}
        limitResults={limitMaterialPrinterPairs}
        linkToNew={adminMaterialPrinterPairsNewFormRoute}
        deleteRow={this.deleteRow}
        setSearchPhrase={this.setSearchPhrase}
        removeSearchBar={true}
        searchPhrase={searchPhrase}
      />
    )
  }
}

const mapStateToProps = ({ AdminMaterialPrinterPairsReducer }) => {
  const {
    materialPrinterPairs,
    totalMaterialPrinterPairs,
    materialPrinterPairsTableHead,
    materialPrinterPairsTableData,
    showNoMaterialPrinterPairsAlert,
    pageNumber,
    isLastPage,
    showPagination,
    limitMaterialPrinterPairs,
    showAdminMaterialPrinterPairsAlert,
    searchPhrase,
    loading
  } = AdminMaterialPrinterPairsReducer
  return {
    materialPrinterPairs,
    totalMaterialPrinterPairs,
    materialPrinterPairsTableHead,
    materialPrinterPairsTableData,
    showNoMaterialPrinterPairsAlert,
    pageNumber,
    isLastPage,
    showPagination,
    limitMaterialPrinterPairs,
    showAdminMaterialPrinterPairsAlert,
    searchPhrase,
    loading
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...AdminMaterialPrinterPairsActions }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminMaterialPrinterPairs)
