import React, { FC } from 'react'
import { Tooltip } from '@material-ui/core'

const TooltipTSX: any = Tooltip

interface IProps {
  name?: string
  maxTextCount: number,
  rootClass?: string
}

const SmallTooltip: FC<IProps> = ({ name = '', maxTextCount, rootClass }) => {
  const showTooltipName = name?.length >= maxTextCount

  return (
    <TooltipTSX
      classes={{ tooltip: rootClass }}
      title={showTooltipName ? name : ''}
      placement="bottom"
    >
      <div className="name">{name}</div>
    </TooltipTSX>
  )
}

export default SmallTooltip
