import { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { isNil } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import Numeral from 'numeral'

import { onPostProcessesClick } from '../../SolutionAnalysisActions'
import {
	OPTIONAL_POST_PROCESSES,
	OptionalPostProcessesIds,
	POPUP_ROWS
} from '../SolutionAnalysisTopConstants'
import Devider from 'Scenes/Components/Devider/Devider'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { CostAnalysisEnum } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostParametersEnum'
import {
	postProcesses2DRange,
	postProcessesRange
} from 'Services/global/calculateRange'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IPostProcess } from 'Services/models/IPostProcess'
import { getString } from 'Services/Strings/StringService'

const DeviderTSX: any = Devider

interface TopPostProcessesDetailsProps {
	costDetails: any
	userCurrencySign: string
	drawingCostPercentage?: number
	showCostInRange?: boolean
	costCalculatedWithOverrideFunction: Record<CostAnalysisEnum, boolean> | null
	configurationId: number
	isWRConfig: boolean
}

export const TopPostProcessesDetails: FC<TopPostProcessesDetailsProps> = ({
	costDetails,
	userCurrencySign,
	drawingCostPercentage,
	showCostInRange,
	costCalculatedWithOverrideFunction,
	configurationId,
	isWRConfig
}) => {
	const dispatch = useDispatch()
	const isShowValuesInRanges = Feature.isFeatureOn(
		FeatureComponentId.SHOW_VALUES_IN_RANGES
	)
	const { allOptionalPostProcessesData } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	const optionalPostProcessesOff =
		costCalculatedWithOverrideFunction &&
		costCalculatedWithOverrideFunction[CostAnalysisEnum.optionalPostProcess]
	const mandatoryPostProcessesOff =
		costCalculatedWithOverrideFunction &&
		costCalculatedWithOverrideFunction[CostAnalysisEnum.postProcessCost]

	const {
		minCostDetails: {
			optionalPostProcessBreakdownCost:
				minOptionalPostProcessBreakdownCost = {},
			optionalPostProcessesCost: minOptionalPostProcessesCost = {}
		} = {},
		maxCostDetails: {
			optionalPostProcessBreakdownCost:
				maxOptionalPostProcessBreakdownCost = {},
			optionalPostProcessesCost: maxOptionalPostProcessCost = {}
		} = {}
	} = costDetails || {}

	const {
		optionalPostProcessesCost: standardOptionalPostProcessCost = {},
		labels: standardLabels = {},
		optionalPostProcessBreakdownCost:
			standardOptionalPostProcessBreakdownCost = {}
	} = costDetails || {}

	let optionalPostProcessesCost = standardOptionalPostProcessCost
	let labels = standardLabels
	let optionalPostProcessBreakdownCost =
		standardOptionalPostProcessBreakdownCost

	// check if Ranges is allowed
	if (
		isShowValuesInRanges &&
		minOptionalPostProcessesCost &&
		minOptionalPostProcessBreakdownCost
	) {
		optionalPostProcessesCost = postProcessesRange(
			minOptionalPostProcessesCost,
			maxOptionalPostProcessCost,
			optionalPostProcessesCost,
			null,
			showCostInRange ? drawingCostPercentage : null
		)
		optionalPostProcessBreakdownCost = postProcessesRange(
			minOptionalPostProcessBreakdownCost,
			maxOptionalPostProcessBreakdownCost,
			optionalPostProcessBreakdownCost,
			null,
			showCostInRange ? drawingCostPercentage : null
		)
	}

	if (
		(!isShowValuesInRanges ||
			(!costDetails.maxCostDetails && !costDetails.minCostDetails)) &&
		showCostInRange
	) {
		optionalPostProcessesCost = postProcesses2DRange(
			optionalPostProcessesCost,
			drawingCostPercentage
		)
		optionalPostProcessBreakdownCost = postProcesses2DRange(
			optionalPostProcessBreakdownCost,
			drawingCostPercentage
		)
	}

	const labelsObjectValues = labels && Object.values(labels)

	const breakdownCostValues = Object.values(optionalPostProcessBreakdownCost)

	const breakdownCostsExist =
		breakdownCostValues?.length &&
		(optionalPostProcessBreakdownCost[
			OptionalPostProcessesIds.SurfaceAreaMachining
		]
			? Object.values(
					optionalPostProcessBreakdownCost[
						OptionalPostProcessesIds.SurfaceAreaMachining
					]
			  )?.some(value => value)
			: true)

	const breakdownExist = labelsObjectValues?.some(
		(item: any) => item?.breakdown?.length
	)

	const cncPostProcesses =
		optionalPostProcessBreakdownCost[
			OptionalPostProcessesIds.SurfaceAreaMachining
		]
	const cncPostProcessValue =
		cncPostProcesses?.surfaceAreaRemoval ||
		cncPostProcesses?.[OptionalPostProcessesIds.SurfaceAreaMachining] ||
		cncPostProcesses?.supportRemoval ||
		cncPostProcesses?.[OptionalPostProcessesIds.SupportRemovalMachining] ||
		0

	const renderPostProcessesEditButton = () => {
		if (isWRConfig) {
			return null
		}
		return (
			<TransparentButton
				className="cost-details-row--edit-button"
				disabled={false}
				onClick={() => dispatch(onPostProcessesClick(configurationId))}
			>
				{getString('EDIT')}
			</TransparentButton>
		)
	}

	const createPostProcessCostDetailsBreakdown = () => {
		let breakdownCostDetails

		if (labels) {
			const temp = Object.entries(labels).map((labelEntries: any) =>
				createBreakDownCostsRows(
					labelEntries,
					optionalPostProcessBreakdownCost,
					userCurrencySign
				)
			)
			breakdownCostDetails = temp.flat(1)
		}

		return (
			<>
				<DeviderTSX size="228px" className="cost-details-row-divider" />
				<div className="cost-details-post-process">
					<div className="cost-details-row--with-edit">
						{breakdownCostDetails && breakdownCostDetails?.length > 1
							? POPUP_ROWS.POST_PROCESSES
							: POPUP_ROWS.POST_PROCESS}
						{renderPostProcessesEditButton()}
					</div>
					{breakdownCostDetails}
				</div>
			</>
		)
	}

	const createOnlyGlobalPostProcessCostDetails = () => {
		if (
			!optionalPostProcessBreakdownCost ||
			isEmpty(optionalPostProcessBreakdownCost)
		) {
			//support old parts
			if (
				optionalPostProcessesCost >= 1 ||
				!isEmpty(optionalPostProcessesCost)
			) {
				const cost = showCostInRange
					? optionalPostProcessesCost
					: Numeral(Math.ceil(optionalPostProcessesCost)).format('0,0')

				return (
					<>
						<DeviderTSX size="228px" className="cost-details-row-divider" />
						<div className="cost-details-row">
							<div className="cost-details-row--with-edit">
								{POPUP_ROWS.POST_PROCESSES}
								{renderPostProcessesEditButton()}
							</div>
							<div>{`${userCurrencySign}${cost}`}</div>
						</div>
					</>
				)
			}

			return <div />
		}

		if (optionalPostProcessesOff && optionalPostProcessesCost === 0)
			return <></>
		const postProcessesArray = Object.entries(optionalPostProcessBreakdownCost)
		const postProcessesTitle =
			postProcessesArray.length > 1
				? POPUP_ROWS.POST_PROCESSES
				: POPUP_ROWS.POST_PROCESS

		return (
			<>
				<DeviderTSX size="228px" className="cost-details-row-divider" />
				<div className="cost-details-row">
					<div className="cost-details-row--with-edit">
						{postProcessesTitle + ':'}
						{renderPostProcessesEditButton()}
					</div>
				</div>
				{postProcessesArray.map(([key, value]: [key: string, value: any]) => {
					const postProcess = allOptionalPostProcessesData.find(
						(postProcess: IPostProcess) => postProcess.id == key
					)
					const postProcessName = postProcess?.custom
						? postProcess?.name
						: OPTIONAL_POST_PROCESSES[
								parseInt(key) as keyof typeof OPTIONAL_POST_PROCESSES
						  ]
					return createPostProcessCostRow(
						postProcessName,
						userCurrencySign,
						value
					)
				})}
			</>
		)
	}

	const createPostProcessCostRow = (
		str: string,
		userCurrencySign: any,
		cost: any
	) => {
		if (isNil(cost)) {
			return <div />
		}
		let totalCost = isShowValuesInRanges
			? cost
			: Numeral(Math.ceil(Numeral(cost).value())).format('0,0')

		return (
			<div className="cost-details-row" key={str + totalCost}>
				<div
					className="cost-details-row--name"
					data-qa={`data-qa-post-process-${str}`}
				>
					{str}
				</div>
				{(str === getString('POST_PROCESSES_SURFACE_AREA_MACHINING_LABEL') ||
					str === getString('POST_PROCESSES_SUPPORT_REMOVAL_LABEL')) &&
				cost == -1 ? (
					<div>{'NA*'}</div>
				) : (
					<div
						data-qa={`data-qa-post-process-${str}-toal-cost`}
					>{`${userCurrencySign}${totalCost}`}</div>
				)}
			</div>
		)
	}

	const createBreakDownCostsRows = (
		labelEntries: any,
		breakdownCosts: any,
		userCurrencySign: any
	) => {
		const processId = labelEntries[0]
		const labelObject = labelEntries[1]

		if (labelObject?.breakdown?.length) {
			return labelObject?.breakdown.map((breakdownObject: any) => {
				const string = getString(breakdownObject.label_name)
				const breakdownCost =
					(breakdownCosts && breakdownCosts[processId]) ||
					(breakdownCosts && breakdownCosts[breakdownObject?.sub_process])
				const cost =
					(breakdownCost &&
						(breakdownCost[breakdownObject?.name] ||
							breakdownCost[breakdownObject.process_id])) ||
					''
				return createPostProcessCostRow(string, userCurrencySign, cost)
			})
		} else {
			const string = getString(labelObject?.mainLabel) || labelObject?.mainLabel
			const cost = breakdownCosts[processId]
			return createPostProcessCostRow(string, userCurrencySign, cost)
		}
	}

	if (optionalPostProcessesCost < 1 && cncPostProcessValue === 0) {
		return <div />
	}

	if (!mandatoryPostProcessesOff && breakdownExist && breakdownCostsExist) {
		return createPostProcessCostDetailsBreakdown()
	}

	return createOnlyGlobalPostProcessCostDetails()
}

export default memo(TopPostProcessesDetails)
