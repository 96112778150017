import { FC, memo } from 'react'

import {
	KEY_2d,
	KEY_3d,
	METADATA
} from 'Scenes/Home/NewUploadProject/constants'
import { drawingFiles } from 'Services/Constants'

import { ReactComponent as CADFile } from 'assets/img/svg/cad.svg'
import { ReactComponent as Metadata } from 'assets/img/svg/metadata.svg'
import { ReactComponent as PDF } from 'assets/img/svg/pdf.svg'

interface UploadImageProps {
	is2dUpload: boolean
	radioButtonSelected: string
	selectedType: string
}

const UploadImage: FC<UploadImageProps> = ({
	is2dUpload,
	selectedType,
	radioButtonSelected
}) => {
	if (selectedType === METADATA) return <Metadata />
	if (
		!is2dUpload &&
		radioButtonSelected === drawingFiles &&
		selectedType === KEY_3d
	) {
		return (
			<>
				<CADFile />
				<PDF />
			</>
		)
	}
	if (is2dUpload) return <PDF />
	return <CADFile />
}

export default memo(UploadImage)
