import React, { InputHTMLAttributes } from 'react'
import { isBoolean } from 'lodash'
import Checkbox from '@material-ui/core/Checkbox'

import CastorCheckbox from '../../../Components/CastorCheckbox'

const CheckboxTSX: any = Checkbox

interface ICheckBox {
	input: InputHTMLAttributes<HTMLInputElement>,
	label?: string,
	disabled?: boolean,
	defaultChecked?: boolean
}

export const renderCheckbox = ({ input, label }: ICheckBox) => (
	<CastorCheckbox
		label={label}
		checked={!!input.value}
		onChange={input.onChange}
	/>
)

export const renderCheckboxWithoutLabel = ({
	defaultChecked,
	input,
	disabled
}: ICheckBox) => {
	const checkedValue = isBoolean(input.value) ? input.value : defaultChecked
	return (
		<CheckboxTSX
			disabled={disabled}
			checked={!!checkedValue}
			onChange={input.onChange}
		/>
	)
}
