import React, { ChangeEvent, memo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import cx from 'classnames'

import {
	LocationPartsParams,
	Part
} from '../../../../../../Services/models/IPart'
import {
	PartPrintIssue,
	PrintIssue,
	PrintIssueId
} from '../../../../../../Services/models/PartPrintIssue'
import { getString } from '../../../../../../Services/Strings/StringService'
import DataTable from '../../../../../Components/DataTable'
import TransparentButton from '../../../../../Components/TransparentButton'
import GeometryAnalysisReviewAndFixesContentPartdisplay from './GeometryAnalysisReviewAndFixesContentPartdisplay'
import {
	useSurfaceAreaOn,
	useTableData
} from './GeometryAnalysisReviewAndFixesService'

interface Props {
	partImageUrl: string
	configurationPrintIssues: PartPrintIssue[]
	orientationVector: number[]
	printIssues: PrintIssue[]
	onViewerModelError: Function
	configuration: any
	solution: any
	printIssueLoaders: Record<string, boolean>
	onWallThicknessClick: Function
	onTolerancesClick: Function
	onMeshHealingClick: Function
	onPartPrintIssueChanged: Function
	partHealedStlURLExist?: boolean
	part: Part
}

const GeometryAnalysisReviewAndFixesContent: React.FC<Props> = ({
	partImageUrl,
	configurationPrintIssues,
	orientationVector,
	onViewerModelError,
	configuration,
	printIssues,
	solution,
	onWallThicknessClick,
	onTolerancesClick,
	onMeshHealingClick,
	partHealedStlURLExist,
	printIssueLoaders,
	onPartPrintIssueChanged,
	part
}) => {
	const [showPrintIssue, setShowPrintIssue] = useState<
		PrintIssueId | undefined
	>()
	const [showAllErrors, setShowAllErrors] = useState(false)

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setShowPrintIssue(parseInt(e.target.value))
	}

	const isSurfaceAreaOn = useSurfaceAreaOn(configuration)

	const tableData = useTableData(
		configurationPrintIssues,
		orientationVector,
		handleChange,
		configuration,
		solution,
		onWallThicknessClick,
		onTolerancesClick,
		onMeshHealingClick,
		onPartPrintIssueChanged,
		printIssueLoaders,
		showAllErrors,
		isSurfaceAreaOn,
		partHealedStlURLExist,
		showPrintIssue,
		printIssues
	)

	const location = useLocation<LocationPartsParams>()
	const isWeightReductionConfiguration =
		location?.state?.isWeightReduction || false

	return (
		<div
			className={cx('geometry-analysis--content', {
				'drawing-geometry-analysis': part.isDrawing
			})}
		>
			<GeometryAnalysisReviewAndFixesContentPartdisplay
				partImageUrl={partImageUrl}
				onViewerModelError={onViewerModelError}
				orientationVector={orientationVector}
				showOnlyPrintIssue={showPrintIssue}
				configurationPrintIssues={configurationPrintIssues}
				printIssues={printIssues}
				solution={solution}
				isSurfaceMachiningOn={isSurfaceAreaOn}
				onCheckBoxSelected={(checked: boolean) => {
					setShowPrintIssue(checked ? undefined : PrintIssueId.all)
				}}
				part={part}
			/>
			{!part.isDrawing ? (
				<div className="geometry-analysis--content--print-issues">
					<DataTable
						tableDataRows={tableData}
						tableClassName="geometry-analysis--content--print-issues--table"
						tableStyle={{ gridTemplateColumns: '7% 20% 35% 30%' }}
						showBreakLines
						showBreakLineAtEndOfTable
					/>
					<div className="geometry-analysis--content--print-issues--footer">
						{tableData.length === 1 ? (
							<div className="geometry-analysis--content--print-issues--footer--no-errors">
								{getString('GEOMETRY_ANALYSIS_NO_ERRORS_EXPLANATION')}
							</div>
						) : (
							<div></div>
						)}
						<TransparentButton
							onClick={() => {
								setShowAllErrors(!showAllErrors)
							}}
						>
							{showAllErrors
								? getString('SHOW_ERRORS_AND_WARNINGS_ONLY')
								: getString('SHOW_ALL_RESULTS')}
						</TransparentButton>
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	)
}

export default memo(GeometryAnalysisReviewAndFixesContent)
