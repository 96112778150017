import { FC } from 'react'

import DetailsPopup from 'Scenes/Components/DetailsPopup'
import { Muted } from 'Scenes/Components/thirdParty/CreativeTim/components'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { EDIT } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface AnalysisFooterProps {
	title: any
	onCostEditClicked: Function
	configId: number
}

const AnalysisFooter: FC<AnalysisFooterProps> = ({
	title,
	onCostEditClicked,
	configId
}) => {
	if (!Feature.isFeatureOn(FeatureComponentId.COST_TAB_INFORMATION)) {
		return <></>
	}
	let disableParameters: boolean | undefined
	if (!Feature.isFeatureActive(FeatureComponentId.COST_TAB_INFORMATION)) {
		disableParameters = true
	}
	return (
		<div>
			<div className="financial-form-edit">
				<Muted className="nowrap">{title}</Muted>
				<DetailsPopup
					isHover={disableParameters}
					data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
					popperDirection="bottom"
					popperClassName="info-box-wrapper details-popup--contact-us"
					popperContactClassName="info-box-data"
					targetClassName={disableParameters ? 'light-user' : ''}
				>
					<TransparentButton
						data-qa="data-qa-tab-edit-btn"
						disabled={disableParameters}
						onClick={() => onCostEditClicked(configId)}
						className="financial-form-button"
					>
						{EDIT}
					</TransparentButton>
				</DetailsPopup>
			</div>
		</div>
	)
}

export default AnalysisFooter
