import { ActionWithPayload } from '../../../global actions/ActionModels'
import {
  TOUR_NEXT_STEP_CLICKED,
  TOUR_STARTED,
  TOUR_FINISHED,
  TOUR_STEPS_UPDATED,
  TOUR_PAUSE_TOGGLED
} from '../../../global actions/types/takeATourTypes'
import { TourSteps } from './types'

export class TakeATourInitialState {
  readonly run: boolean = false
  readonly callback: (data: any) => any = () => {}
  readonly steps: TourSteps = []
  readonly stepIndex?: number = undefined
  readonly pauseTour: boolean = false
  readonly disableScrolling: boolean = true
  readonly customSteps?: TourSteps
}
const initialState = new TakeATourInitialState()

export default (
  state = initialState,
  { type, payload }: ActionWithPayload<any>
) => {
  switch (type) {
    case TOUR_STARTED:
      return {
        ...state,
        ...payload,
        run: true,
        customSteps: undefined
      }
    case TOUR_STEPS_UPDATED:
      return { ...state, ...payload }
    case TOUR_FINISHED:
      return { ...state, run: false }
    case TOUR_PAUSE_TOGGLED:
      return { ...state, pauseTour: !state.pauseTour }
    case TOUR_NEXT_STEP_CLICKED:
      const { back } = payload
      return {
        ...state,
        stepIndex: state.stepIndex
          ? !back
            ? state.stepIndex + 1
            : state.stepIndex - 1
          : 1,
        pauseTour: false
      }
    default:
      return state
  }
}
