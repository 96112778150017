import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import TextField from '@material-ui/core/TextField'
import cx from 'classnames'
import { isEmpty, toNumber } from 'lodash'

import {
	resetPdfFile,
	sendAdditiveMind,
	setPDFFile,
	toggleAdditivePopup
} from 'Scenes/Components/AdditiveMinds/AdditiveMindsActions'
import { AdditiveMindsPopupProps } from 'Scenes/Components/AdditiveMinds/AdditiveMindsInterface'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import FieldWithLabel from 'Scenes/Components/FieldWithLabel'
import Flexbox from 'Scenes/Components/FlexBox'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as CircleClose } from 'assets/img/svg/icons/circle_close.svg'
import { ReactComponent as DownloadBom } from 'assets/img/svg/icons/download_bom.svg'
import { ReactComponent as File } from 'assets/img/svg/icons/file.svg'

import './index.scss'

const TextFieldTSX: any = TextField
const DropzoneTSX: any = Dropzone

const contactExpertLink =
	'https://outlook.office365.com/owa/calendar/TalktoanExpertAdditiveMinds@eosinfo.onmicrosoft.com/bookings/s/Ucbay42BcEexHlI_U0YhlA2'

const AdditiveMindsPopup: FC<AdditiveMindsPopupProps> = ({
	configurationId
}) => {
	const { projectId, partId } = useParams() as {
		projectId: string
		partId: string
	}
	const dispatch = useDispatch()
	const configuration = useSelector((state: RootStateOrAny) => {
		return state?.SolutionAnalysisReducer.states[configurationId]
	})
	const { showPopup, additiveFile, error } = useSelector(
		(state: RootStateOrAny) => {
			return state?.AdditiveMindReducer
		}
	)
	const { solution = {}, batchSize } = configuration || {}

	const [notes, setNotes] = useState('')
	const [quantity, setQuantity] = useState<string | number>(0)
	const [draggingFiles, setDragging] = useState(false)

	useEffect(() => {
		setQuantity(batchSize)
	}, [batchSize])

	const printerName = solution?.printer?.name
	const printerMaterial = solution?.printerMaterial?.name

	const disableSubmit = toNumber(quantity) === 0

	const emptyFunc = () => {}
	const closeModal = () => {
		dispatch(resetPdfFile())
		setQuantity(batchSize)
		setNotes('')
		dispatch(toggleAdditivePopup(false))
	}
	const confirmModal = () => {
		dispatch(
			sendAdditiveMind(
				additiveFile,
				notes,
				quantity as number,
				printerName,
				printerMaterial,
				configurationId,
				projectId,
				partId
			)
		)
		window.open(contactExpertLink, '_blank')
		closeModal()
	}
	const handleFileReader = (files: File[]) => {
		let reader = new FileReader()
		if (files[0]) {
			reader.readAsDataURL(files[0])
			reader.onload = () => {
				dispatch(setPDFFile(files, reader?.result as string))
			}
		}
	}
	return (
		<CastorAlert
			alertClass="additive-minds additive-minds-alert with-subtitle"
			onCancel={closeModal}
			show={showPopup}
			onConfirm={confirmModal}
			disabled={disableSubmit}
			cancelOptionalText={getString('CANCEL')}
			confirmOptionalText={getString('SUBMIT')}
			headerTitle={getString('ADDITIVE_MINDS')}
			withSubtitle
		>
			<div className="additive-minds-popup">
				<p className="grey">{getString('CONTACT_ME_TO_DISCUSS')}</p>
				<p>
					<b>{getString('SEND_TO_SERVICE_BUREAU_HOW_TITLE')}</b>
				</p>
				<Flexbox
					className="printer-info"
					flexDirection="row"
					justifyContent="space-between"
					width="100%"
				>
					<FieldWithLabel
						disabled={true}
						labelName={getString('PRINTER')}
						fieldValue={printerName}
						fieldOnChange={emptyFunc}
					/>
					<FieldWithLabel
						disabled={true}
						labelName={getString('MATERIAL')}
						fieldValue={printerMaterial}
						fieldOnChange={emptyFunc}
					/>
				</Flexbox>
				<Flexbox className="notes-info" flexDirection="column" width="100%">
					<p>
						<b>{getString('MY_NOTES')}</b>
					</p>
					<TextFieldTSX
						rows={6}
						value={notes}
						className="filter-fields--widget--text-field-box full"
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setNotes(e.target.value)
						}
						multiline={true}
						placeholder={getString('TELL_US_ADDITION_INFO')}
					/>
				</Flexbox>
				<Flexbox className="quantity-info" flexDirection="column" width="100%">
					<p>
						<b>{getString('NEW_PART_CONFIGURATION_QUANTITY')}</b>
					</p>
					<FieldWithLabel
						isNumberField
						labelName={''}
						fieldValue={quantity}
						fieldOnChange={value => setQuantity(value)}
					/>
					{toNumber(quantity) <= 0 && (
						<p className="error">{getString('CO2_PER_KG_DATA_VALIDATION')}</p>
					)}
				</Flexbox>
			</div>
			{Feature.isFeatureOn(FeatureComponentId.ATTACHMENT_OPTION) ? (
				<Flexbox
					className="upload-info"
					flexDirection="column"
					justifyContent="space-between"
					width="100%"
				>
					<p>
						<b>{getString('UPLOAD_PDF')}</b>
					</p>
					<DropzoneTSX
						disabled={false}
						className={cx('additive-minds-dropzone', {
							'drag-on': draggingFiles,
							'drag-off': !draggingFiles
						})}
						onDrop={(newFile: any) => {
							handleFileReader(newFile)
							setDragging(false)
						}}
						multiple={false}
						onDragEnter={() => setDragging(true)}
						onDragLeave={() => setDragging(false)}
						data-qa="data-qa-dropzone-upload-part"
					>
						{isEmpty(additiveFile) ? (
							<div>
								<DownloadBom className={cx('files-upload-content__icon', {})} />
								<p>{getString('UPLOAD_PDF_WITH_ADDITION_INFO')}</p>
								<p>{getString('DROP_YOUR_FILE')}</p>
								<p>{getString('OR')}</p>
								<Button className="upper" color="transparent">
									{getString('CLICK_TO_UPLOAD')}
								</Button>
							</div>
						) : (
							<div
								className="file-upload"
								onClick={(e: React.MouseEvent) => e.stopPropagation()}
							>
								<File className="icon-file" />
								<div className="text">{additiveFile.name}</div>
								<CircleClose
									onClick={() => {
										dispatch(resetPdfFile())
									}}
									className="icon-close"
								/>
							</div>
						)}
					</DropzoneTSX>
					<p className="error">{error}</p>
				</Flexbox>
			) : (
				<></>
			)}
		</CastorAlert>
	)
}

export default memo(AdditiveMindsPopup)
