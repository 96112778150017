import { FC, memo } from 'react'

import Flexbox from 'Scenes/Components/FlexBox'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { getString } from 'Services/Strings/StringService'

import './ProjectNotUploaded.scss'

const ProjectNotUploaded: FC = () => {
	return (
		<NavBarAndMaterial
			fullWidth={true}
			title={getString('PROJECT_NOT_UPLOADED_TITLE')}
		>
			<Flexbox
				flexDirection="column"
				alignItems="center"
				justifyContent="flex-start"
				height="100%"
			>
				{/* <IconFactory 
         iconName="rightArrow"
         className="project-not-uploaded-arrow-icon"
         /> */}
				<IconFactory
					uploadIconCad
					iconName="uploadIconCad"
					className="project-not-uploaded-icon"
				/>
				<div className="project-not-uploaded-spacer"></div>
				<Flexbox
					flexDirection="row"
					alignItems="center"
					justifyContent="center"
				>
					<div className="project-not-uploaded-text">
						{getString('PROJECT_NOT_UPLOADED_MSG')}
					</div>
				</Flexbox>
			</Flexbox>
		</NavBarAndMaterial>
	)
}

export default memo(ProjectNotUploaded)
