import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form'

import { isEmpty } from 'lodash'

import * as AdminPrinterMaterialsActions from './AdminPrinterMaterialsActions'
import { ADMIN_PRINTER_MATERIALS_EDIT } from '../../../../Services/Strings'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import AdminPrinterMaterialForm from '../../../Components/PrinterMaterialForm/PrinterMaterialForm'
import { adminPrinterMaterialFormName } from './constants'

class EditAdminPrinterMaterial extends Component {
	componentDidMount() {
		const {
			printerMaterials,
			selectPrinterMaterialToEdit,
			match,
			setupAdminPrinterMaterialsPage,
			pageNumber,
			limitPrinterMaterials,
			allPrintersCompanies,
			searchPhrase
		} = this.props
		const { id } = match.params
		const emptyPrinters = isEmpty(allPrintersCompanies)

		if (emptyPrinters) this.setupAdminPrinters()
		if (!printerMaterials.length) {
			setupAdminPrinterMaterialsPage(
				searchPhrase,
				pageNumber,
				limitPrinterMaterials
			)
		} else {
			if (!emptyPrinters) selectPrinterMaterialToEdit(id, printerMaterials)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			printerMaterials,
			selectPrinterMaterialToEdit,
			match,
			allPrintersCompanies,
			selectedEditPrinterMaterial
		} = this.props
		const { id } = match.params
		const emptyPrinters = isEmpty(allPrintersCompanies)

		if (emptyPrinters) {
			this.setupAdminPrinters()
		}

		if (
			!emptyPrinters &&
			printerMaterials.length &&
			(!selectedEditPrinterMaterial || prevProps.match.params.id !== id)
		) {
			selectPrinterMaterialToEdit(id, printerMaterials)
		}
	}

	setupAdminPrinters = () => {
		this.props.setupAdminPrinters()
	}

	onSubmit = data => {
		this.props.updatePrinterMaterial(data)
	}

	renderFormContent = () => {
		const {
			selectedEditPrinterMaterial,
			printingTechnologies,
			selectedType,
			selectedPrinterTechnology,
			allPrintersCompanies,
			selectedPrinterName,
			selectedPrinterCompany,
			allCategories,
			allSubCategories,
			selectedCategory,
			selectedSubCategory,
			optionalPostProcessAvailability,
			selectedId,
			categoriesAverageCO2
		} = this.props
		return (
			<AdminPrinterMaterialForm
				formName={adminPrinterMaterialFormName}
				selectedId={selectedId}
				optionalPostProcessAvailability={optionalPostProcessAvailability}
				allPrintersCompanies={allPrintersCompanies}
				allCategories={allCategories}
				allSubCategories={allSubCategories}
				selectedPrinterName={selectedPrinterName}
				selectedCategory={selectedCategory}
				selectedPrinterCompany={selectedPrinterCompany}
				selectedSubCategory={selectedSubCategory}
				printingTechnologies={printingTechnologies}
				onSubmit={this.onSubmit}
				initialValues={selectedEditPrinterMaterial}
				selectedType={selectedType}
				selectedPrinterTechnology={selectedPrinterTechnology}
				userCurrencySign={'$'}
				categoriesAverageCO2={categoriesAverageCO2}
				isAdminForm
			/>
		)
	}

	render() {
		return (
			<NavBarAndMaterial title={ADMIN_PRINTER_MATERIALS_EDIT}>
				<CastorForm
					formTitle={ADMIN_PRINTER_MATERIALS_EDIT}
					content={this.renderFormContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const selector = formValueSelector(adminPrinterMaterialFormName)

const mapStateToProps = state => {
	const {
		AdminPrinterMaterialsReducer: {
			printerMaterials,
			selectedEditPrinterMaterial,
			pageNumber,
			limitPrinterMaterials,
			searchPhrase,
			allPrintersCompanies,
			allCategories,
			allSubCategories,
			categoriesAverageCO2
		},
		user: { printingTechnologies, optionalPostProcessAvailability }
	} = state
	return {
		printerMaterials,
		selectedEditPrinterMaterial,
		pageNumber,
		limitPrinterMaterials,
		printingTechnologies,
		searchPhrase,
		allPrintersCompanies,
		allCategories,
		allSubCategories,
		optionalPostProcessAvailability,
		selectedType: selector(state, 'type'),
		selectedPrinterTechnology: selector(state, 'printerTechnologyName'),
		selectedPrinterName: selector(state, 'printers'),
		selectedPrinterCompany: selector(state, 'printerCompany'),
		selectedSubCategory: selector(state, 'subCategory'),
		categoriesAverageCO2,
		selectedCategory: selector(state, 'category'),
		selectedId: selector(state, 'id')
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminPrinterMaterialsActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditAdminPrinterMaterial)
