import React, { PureComponent } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { match } from 'react-router'
import { AnyAction, bindActionCreators } from 'redux'

import { isEmpty } from 'lodash'

import * as IntegrationProviderActions from '../../../IntegrationProvider/IntegrationProviderActions'
import * as MainPartAnalysisActions from './MainPartAnalysisActions'
import Loader from '../../../Loader/Loader'
import { MainPartAnalysisInitialState } from './MainPartAnalysisReducer'
import NewConfiguration from './PartAnalysisHeader/NewConfiguration'
import PartAnalysisHeader from './PartAnalysisHeader/PartAnalysisHeader'
import SolutionAnalysis from './SolutionAnalysis/SolutionAnalysis'
import SolutionsList from './SolutionsList/SolutionsList'
import { Action } from 'global actions/ActionModels'
import { History, Location } from 'history'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import Flexbox from 'Scenes/Components/FlexBox'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import {
	onTourStart,
	onTourStop,
	onUpdateTourSteps,
	updateTourAction,
	UpdateTourActionAttributes
} from 'Scenes/Components/TakeATour/TakeATourActions'
import { IPage, TourPages, TourSteps } from 'Scenes/Components/TakeATour/types'
import { Button as ButtonComponents } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { IntegrationProviderInitialState } from 'Scenes/IntegrationProvider/IntegrationProviderReducer'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IUserProvider } from 'Services/models/IUserProvider'
import { customizeUser, partNewConfigurationsRoute } from 'Services/routeFuncs'
import {
	CONFIGURATION_INHOUSE_ALERT_TEXT,
	GO_TO_SETTINGS,
	NO_INHOUSE_PRINTER_WAS_SET
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './MainPartAnalysis.scss'

const SolutionAnalysisObject: any = SolutionAnalysis
const NavBarAndMateriall: any = NavBarAndMaterial

interface Props extends MainPartAnalysisInitialState {
	location: Location
	match: match<any>
	history: History
	materials: any
	crumbs: any
	showTour: boolean
	tourSteps: TourSteps
	pagesVisited: Array<string>
	userCustomizationSettings: any
	tourPages: TourPages
	noStep: boolean
	isWeightReductionPart: boolean
	weightReductionProgressFromProject: boolean
	userEmail: string
	showInhouseAlert: boolean
	isTourConfigurationCalculationLoading: boolean
	loaderCounter: number
	disableRibbonInfo?: boolean
	tourConfigurationId: number | undefined
	providerState: any
	updateTourAction: (
		updateTourActionAttributes: UpdateTourActionAttributes
	) => any
	onTourStart: (payload?: Record<string, any>) => Action<any>
	onUpdateTourSteps: (tourPages: IPage[], pageName: string) => Action<any>
	onTourStop: () => Action<any>
	incrementTourStep: (
		...incrementTourStepParams: Parameters<
			typeof MainPartAnalysisActions.incrementTourStep
		>
	) => any
	tourPagesUpdated: (
		pagesVisited: Array<string>,
		pageName: string,
		customFlag: boolean
	) => any
	updateSteps: (arg0: TourSteps, tourConfigurationId: number) => Action<any>
	hideInhouseAlert: () => Action<any>
	addConfigurationClick: () => Action<any>
	addSimpleConfigurationClick: () => Action<any>
	newSolutionConfigurationAdded: () => Action<any>
	clearComponentData: () => Action<any>
	convertMaterialId: (materials: any, partMaterialId: number) => any
	updateBreadCrumbs: (partEntityId: string, name: string) => any
	fetchSolutions: (
		...fetchSolutionsParams: Parameters<
			typeof MainPartAnalysisActions.fetchSolutions
		>
	) => any
	onSendPartToProvider: (
		partFileName: string,
		stlURL: string,
		partCastorPath: string,
		userProviders: IUserProvider,
		providerState: any,
		userEmail: string,
		configuration: any,
		partActualName?: string
	) => any
}

interface State {
	initTour: boolean
}

interface ReduxState {
	MainPartAnalysisReducer: MainPartAnalysisInitialState
	ProjectAnalysisReducer: any
	user: any
	GlobalReducer: any
	userHome: any
	IntegrationProviderReducer: IntegrationProviderInitialState
}

class MainPartAnalysis extends PureComponent<Props, State> {
	state = {
		initTour: false
	}

	componentDidMount = () => {
		const {
			fetchSolutions,
			match,
			solutionsFetched,
			solutionsFetchCalled,
			materials,
			pageName,
			tourPages,
			weightReductionProgressFromProject,
			userCustomizationSettings,
			userEmail,
			providerState,
			configurations,
			part,
			onSendPartToProvider
		}: Props = this.props
		const { partId, projectId, clusterId, feaId, configurationId } =
			match.params

		fetchSolutions(
			partId,
			projectId,
			solutionsFetched,
			solutionsFetchCalled,
			materials,
			clusterId,
			weightReductionProgressFromProject,
			userCustomizationSettings,
			feaId,
			pageName,
			tourPages
		)

		if (!isEmpty(providerState)) {
			const configuration = undefined
			if (configurationId && configurationId > 0) {
				configurations.find(c => c.id == configurationId)
			}
			onSendPartToProvider(
				providerState.partName,
				providerState.partStlUrl,
				providerState.location,
				providerState.provider_printer,
				providerState,
				userEmail,
				configuration,
				part?.partNumber
			)
		}
	}

	componentDidUpdate = (prevProps: any) => {
		const {
			loaderCounter,
			pagesVisited,
			pageName,
			tourPagesUpdated,
			weightReductionProgressFromProject
		} = this.props

		if (prevProps.loaderCounter && !loaderCounter) {
			tourPagesUpdated(
				pagesVisited,
				pageName,
				weightReductionProgressFromProject
			)
		}
	}

	componentWillUnmount = () => {
		this.props.clearComponentData()
		this.props.onTourStop()
	}

	componentDidCatch = () => {
		//TODO: redirect to an error page
		// alert('component crashed')
	}
	onAddNewConfigurationClick = () => {
		this.props.addConfigurationClick()
	}

	onCustomConfigurationClick = () => {
		const { history, part } = this.props
		const projectId = part?.projectId
		const id = part?.id
		history.push(partNewConfigurationsRoute(projectId, id))
	}

	onAddSimpleConfigurationClick = () => {
		this.props.addSimpleConfigurationClick()
	}

	onInhouseAlertConfirm = () => {
		const { history, hideInhouseAlert } = this.props
		hideInhouseAlert()
		history.push(customizeUser())
	}

	renderInhousePrinterAlert = () => {
		const { showInhouseAlert, hideInhouseAlert } = this.props
		return (
			<CastorAlert
				onCancel={() => hideInhouseAlert()}
				show={showInhouseAlert}
				onConfirm={this.onInhouseAlertConfirm}
				confirmOptionalText={GO_TO_SETTINGS}
				alertType={AlertType.WARNING}
				headerTitle={NO_INHOUSE_PRINTER_WAS_SET}
			>
				{CONFIGURATION_INHOUSE_ALERT_TEXT}
			</CastorAlert>
		)
	}

	renderAddConfigurationButton = () => {
		const {
			isWeightReductionPart,
			disableRibbonInfo,
			disablePart,
			cluster
		}: Props = this.props

		if (
			isWeightReductionPart ||
			!!disableRibbonInfo ||
			cluster ||
			Feature.isFeatureOn(FeatureComponentId.DISABLE_RIBBON_INFORMATION)
		) {
			return <div />
		}

		return (
			<ButtonComponents
				color="primary"
				onClick={this.onAddNewConfigurationClick}
				className="part-analysis-header-button part-analysis-button-text-transform"
				muiClasses={{
					label: 'part-analysis-button-label',
					root: 'part-analysis-button-root'
				}}
				disabled={disablePart}
			>
				+ {getString('PART_ANALYSIS_ADD_CONFIGURATION_BUTTON')}
			</ButtonComponents>
		)
	}

	renderConfigurations = () => {
		const {
			match,
			part,
			cluster,
			solutions,
			newSolutionAdded,
			newPrinterMaterialConfigurationAdded,
			initialBatchSize,
			configurations,
			isWeightReductionPart,
			disablePart
		}: Props = this.props
		const { configurationId } = match.params
		if (configurationId && configurationId > 0) {
			const configuration = configurations.find(c => c.id == configurationId)
			if (configuration) {
				return (
					<div className="single-configuration">
						<SolutionAnalysisObject
							initialBatchSize={initialBatchSize}
							configuration={configuration}
							part={part}
							cluster={cluster}
							key={configuration.id}
						/>
					</div>
				)
			}
			return <div />
		}
		return (
			<>
				<PartAnalysisHeader
					solutionLength={configurations.length}
					onAddSimpleConfigurationClick={this.onAddSimpleConfigurationClick}
					showSimpleConfiguration={!isWeightReductionPart && !cluster}
					disabled={disablePart}
				/>
				<SolutionsList
					initialBatchSize={initialBatchSize}
					solutions={solutions}
					configurations={configurations}
					part={part}
					cluster={cluster}
					newSolutionAdded={newSolutionAdded}
					newPrinterMaterialConfigurationAdded={
						newPrinterMaterialConfigurationAdded
					}
				/>
			</>
		)
	}

	renderWindow = () => {
		const { disablePart, location }: Props = this.props
		const showNewConfigButton = !location.pathname.includes('configurations') // hide button if we are on configuration page

		if (
			disablePart &&
			Feature.isFeatureOn(FeatureComponentId.DISABLE_PART_LOADER)
		) {
			return <Loader load={true} message={'Updating results'} />
		}

		return (
			<>
				{this.renderConfigurations()}
				{this.renderAddConfigurationButton()}
				<br />
				{showNewConfigButton && (
					<NewConfiguration
						onAddNewConfigurationClick={this.onCustomConfigurationClick}
						disabled={disablePart}
					/>
				)}
			</>
		)
	}

	render() {
		const { part, cluster, fileTiltleName }: Props = this.props

		if (!part && !cluster) {
			return <div />
		}

		return (
			<ErrorBoundary extra="MainPartAnalysis">
				<NavBarAndMateriall title={fileTiltleName}>
					{this.renderInhousePrinterAlert()}
					<Flexbox
						flexDirection="column"
						alignSelf="center"
						alignItems="center"
						className="configuration-block"
					>
						{this.renderWindow()}
					</Flexbox>
				</NavBarAndMateriall>
			</ErrorBoundary>
		)
	}
}

const mapStateToProps = ({
	MainPartAnalysisReducer,
	ProjectAnalysisReducer: { weightReductionProgressFromProject },
	user: {
		userDetails: { email, pagesVisited: pagesVisitedUserDetails },
		materials,
		pagesVisited,
		pages,
		userCustomizationSettings
	},
	GlobalReducer: { loaderCounter },
	userHome: { crumbs },
	IntegrationProviderReducer: { providerState }
}: ReduxState) => ({
	...MainPartAnalysisReducer,
	materials,
	pagesVisited,
	pagesVisitedUserDetails,
	tourPages: pages,
	loaderCounter,
	crumbs,
	weightReductionProgressFromProject,
	userCustomizationSettings,
	userEmail: email,
	providerState
})

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators(
		{
			...MainPartAnalysisActions,
			...IntegrationProviderActions,
			updateTourAction,
			onTourStart,
			onUpdateTourSteps,
			onTourStop
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(MainPartAnalysis)
