export const gerUrlParams = (paramNames: string[]): any => {
	const url = new URL(window.location.href)
	const params = new URLSearchParams(url.search)

	const paramsObj: any = {}
	paramNames.forEach(paramName => {
		paramsObj[paramName] = params.get(paramName)
	})

	return paramsObj
}

export const getAllUrlParams = () => {
	const url: URL = new URL(window.location.href)
	const params = {} as Record<string, string>
	for (const param of url.searchParams) {
		const key = param[0]
		params[key] = param[1]
	}
	return params
}
