import React, { FC, memo, ReactNode } from 'react'

import './CastorGridElement.scss'

export enum ElementCardType {
  PRIMARY = 'primary',
  BENEFIT = 'benefit'
}

interface IProps {
  type?: ElementCardType
  className?: string
  contentClassName?: string
  gridColumn?: number
  gridRow?: number
  children?: ReactNode
  title?: string
  subtitle?: string
}

const createElementStyle = (gridRow?: number, gridColumn?: number) => {
  const style: any = {}
  if (gridRow) {
    style.gridRow = `span ${gridRow}`
  }
  if (gridColumn) {
    style.gridColumn = `span ${gridColumn}`
  }
  return style
}

const renderCardTopLine = (type?: ElementCardType) => {
  if (!type) {
    return <div></div>
  }
  return (
    <div
      className={`grid-element--top-line grid-element--top-line--${type}`}
    ></div>
  )
}

const renderTitleAndSubtitle = (title?: string, subTitle?: string) => {
  if (!title) {
    return <div></div>
  }

  let renderSubtitle = <div></div>
  if (subTitle) {
    renderSubtitle = <div className={`grid-element--subtitle`}>{subTitle}</div>
  }
  return (
    <div className={`grid-element--title--wrapper`}>
      <div className={`grid-element--title`}>{title}</div>
      {renderSubtitle}
    </div>
  )
}

const CastorGridElement: FC<IProps> = ({
  type,
  gridColumn,
  gridRow,
  children,
  className = '',
  contentClassName = '',
  title,
  subtitle
}) => {
  return (
    <div
      className={`grid-element ${className}`}
      style={createElementStyle(gridRow, gridColumn)}
    >
      {renderCardTopLine(type)}
      <div className={`grid-element--content ${contentClassName}`}>
        {renderTitleAndSubtitle(title, subtitle)}
        {children || <div />}
      </div>
    </div>
  )
}

export default memo(CastorGridElement)
