import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { Part } from 'Services/models/IPart'
import { benefitType } from 'Services/models/ISolutionBenefit'

export enum benefitsAnalysisType {
	costSaving = 'costSaving',
	timeSaving = 'timeSaving',
	buyToFly = 'buyToFly',
	complexityScore = 'complexityScore',
	weightReduction = 'weightReduction',
	partConsolidation = 'partConsolidation'
}

export enum chartNames {
	printable = 'printable',
	costSaving = 'costSavingPrintable',
	timeSaving = 'timeSavingPrintable',
	buyToFly = 'buyToFlyPrintable',
	complexityScore = 'complexityScorePrintable',
	weightReduction = 'weightReductionPrintable',
	partConsolidation = 'partConsolidationPrintable'
}

export interface IPrintableChartData {
	x: string
	y: number
	label: string
	name: chartNames
}

export type BenefitNameType = keyof typeof benefitsAnalysisType

export interface IBenefitStateType {
	name: BenefitNameType
	numberOfParts: number
	printableChartData: IPrintableChartData[]
}

export enum ProjectAnalysisPartsView {
	thumbnail = 'thumbnail',
	list = 'list',
	graph = 'graph',
}

export type ValueOf<T> = T[keyof T]

export interface IPartsResults {
	totalPartsCount: number
	notCostEffectiveCount: number
	allPartsInapplicable: boolean
	is2dProject: boolean
	isSomePrintable: boolean
	numberOfFailedParts: number
	isSinglePartProject: boolean
	onlyPart: Part | {}
	weightReductionParts: Part[] | []
	partsWithStandardCost: Part[] | []
	printablePartsCount: number
	benefitsData: Record<ValueOf<typeof benefitType>, number>
	printabilityData: Record<keyof CADAnalysisResult, number>
	skipToPartId: string
	skipToPartProjectId: string
	standalonePartsCount: number
	standardCostAllowedNames: string[]
}

export type IPartsToPrintSummary = {
	status: string
	icon: string
	count: number
}

export type PartResultName = {
	title: string
	value: string
}

export type PartFailedIssueWithMessage = {
	id?: number
	order?: number
	message: string
}
