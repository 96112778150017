import { FC, useCallback, useEffect } from 'react'
import { connect, DispatchProp, RootStateOrAny, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form'

import * as CustomizeUserMaterialsActions from '../CustomizeUserMaterialsActions'
import { customizeUserMaterial } from '../../../../../Services/routeFuncs'
import {
	CUSTOMIZE_FORM_USER_NEW_MATERIAL_HEADER,
	NAV_TITLE_CUSTOMIZE_USER
} from '../../../../../Services/Strings'
import CastorForm from '../../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../../Components/NavBarAndMaterial'
import { materialFormName } from 'Scenes/admin/AdminHome/AdminMaterials/constants'
import MaterialForm from 'Scenes/Components/MaterialForm/MaterialForm'
import {
	IMaterialCategory,
	MaterialType,
	SurfaceFinishMethod
} from 'Services/models/IMaterial'

type IProps = {
	selectedType: MaterialType
	selectedCategory: string
	selectedSurfaceFinishMethod: SurfaceFinishMethod
	defaultFilters: Record<string, boolean>
	setupNewUserMaterialForm: () => void
	onUserMaterialAdd: (
		data: Record<string, any>,
		userId: string,
		materialCategories: IMaterialCategory[]
	) => void
	onMaterialTypeChange: () => void
}

const NewUserMaterial: FC<IProps> = ({
	selectedType,
	selectedCategory,
	selectedSurfaceFinishMethod,
	defaultFilters,
	setupNewUserMaterialForm,
	onUserMaterialAdd,
	onMaterialTypeChange
}) => {
	const { materialAdded, userId, materialCategoriesToShow, addingNewItem } =
		useSelector((state: RootStateOrAny) => state.CustomizeUserMaterialsReducer)

	const { materialCategories, userCurrencySign, materials } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	useEffect(() => {
		setupNewUserMaterialForm()
	}, [])

	const onSubmit = useCallback(
		(data: Record<string, any>) => {
			onUserMaterialAdd(data, userId, materialCategories)
		},
		[materialCategories, onUserMaterialAdd, userId]
	)

	const renderFormContent = () => {
		return (
			<MaterialForm
				onSubmit={onSubmit}
				materialCategories={materialCategoriesToShow}
				allMaterialCategories={materialCategories}
				materials={materials}
				onMaterialTypeChange={onMaterialTypeChange}
				addingNewItem={addingNewItem}
				userCurrencySign={userCurrencySign}
				selectedMaterialType={selectedType}
				selectedMaterialCategory={selectedCategory}
				selectedSurfaceFinishMethod={selectedSurfaceFinishMethod}
				defaultFilters={defaultFilters}
				formName={materialFormName}
			/>
		)
	}

	if (materialAdded) {
		return <Redirect to={customizeUserMaterial(userId)} />
	}
	return (
		<NavBarAndMaterial title={NAV_TITLE_CUSTOMIZE_USER}>
			<CastorForm
				formTitle={CUSTOMIZE_FORM_USER_NEW_MATERIAL_HEADER}
				content={renderFormContent()}
			/>
		</NavBarAndMaterial>
	)
}

const selector = formValueSelector(materialFormName)

const mapStateToProps = (state: Record<string, unknown>) => {
	return {
		selectedType: selector(state, 'type'),
		selectedCategory: selector(state, 'category'),
		selectedSurfaceFinishMethod: selector(state, 'surfaceFinishMethod'),
		defaultFilters: selector(state, 'defaultFilters')
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) => {
	return bindActionCreators({ ...CustomizeUserMaterialsActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NewUserMaterial)
