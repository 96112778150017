import React, { memo, FC } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { IOtherProps } from 'Services/models/IOtherProps'

import './Checkbox.scss'

const CheckboxComponent: any = Checkbox

interface IProps extends IOtherProps {
  checked: boolean
  disabled?: boolean
  classes?: Record<string, string>
}

const CastorCheckbox: FC<IProps> = ({
  checked,
  disabled,
  classes = {},
  ...props
}) => (
  <CheckboxComponent
    checked={checked}
    disabled={disabled}
    classes={{
      checked: 'checkbox--checked-icon',
      disabled: 'checkbox--disabled-icon',
      ...classes
    }}
    {...props}
  />
)

export default memo(CastorCheckbox)
