import { getAdvancedFeatureStatusStep } from './UploadProjectActionService'
import { UPLOAD_CHECK_STATUS, UPLOAD_CLEAR_STATUS } from 'global actions/types'
import { ProjectStatusPartsType } from 'Scenes/Home/NewUploadProject/UploadingProject.enum'
import { ProjectStatus } from 'Services/models/IProject'

export class UploadingProjectInitialState {
	readonly numberOfPartsAnalyzed = 0
	readonly numberOfTotalPartsAnalyzed = 0
	readonly inProgressStatus = ''
	readonly progressBar = 0
	readonly statusPartsType = ''
	readonly advancedFeaturesStatus = null
	readonly bundleId = null
	readonly projectStatus = ''
	readonly reanalyzingStatus = ''
}
const initialUploadingState: UploadingProjectInitialState =
	new UploadingProjectInitialState()

const UploadingProjectReducer = (
	state = initialUploadingState,
	action: any
) => {
	switch (action.type) {
		case UPLOAD_CLEAR_STATUS: {
			return {
				...state,
				bundleId: null,
				numberOfPartsAnalyzed: initialUploadingState.numberOfPartsAnalyzed,
				numberOfTotalPartsAnalyzed:
					initialUploadingState.numberOfTotalPartsAnalyzed,
				inProgressStatus: initialUploadingState.inProgressStatus,
				progressBar: initialUploadingState.progressBar,
				statusPartsType: initialUploadingState.statusPartsType,
				reanalyzingStatus: initialUploadingState.reanalyzingStatus
			}
		}

		case UPLOAD_CHECK_STATUS: {
			const {
				totalParts,
				numberOfPartsAnalyzed,
				analyzingStatus,
				statusPartsType,
				advancedFeaturesStatus,
				bundleId,
				projectStatus,
				reanalyzingStatus
			} = action.payload || {}

			const analyzingStatusData =
				typeof analyzingStatus === 'object'
					? analyzingStatus
					: JSON.parse(analyzingStatus)

			let calculateProgressBar = 0
			let finishedAnalyzing = projectStatus === ProjectStatus.published

			if (statusPartsType === ProjectStatusPartsType.SINGLE_PART) {
				calculateProgressBar = 100

				if (!finishedAnalyzing) {
					calculateProgressBar = analyzingStatusData
						? ((analyzingStatusData?.currentAnalysisStep +
								getAdvancedFeatureStatusStep(advancedFeaturesStatus)) /
								analyzingStatusData?.totalAnalysisSteps) *
						  100
						: 0
				}
			}

			if (statusPartsType === ProjectStatusPartsType.MULTIPLE_PART) {
				calculateProgressBar = totalParts
					? (numberOfPartsAnalyzed / totalParts) * 100
					: 0
			}

			return {
				...state,
				projectStatus,
				numberOfPartsAnalyzed: numberOfPartsAnalyzed,
				numberOfTotalPartsAnalyzed: totalParts,
				inProgressStatus: analyzingStatusData?.inProgressStatus,
				progressBar: calculateProgressBar,
				bundleId: bundleId ? bundleId : state.bundleId,
				statusPartsType,
				advancedFeaturesStatus,
				reanalyzingStatus
			}
		}

		default:
			return state
	}
}

export default UploadingProjectReducer
