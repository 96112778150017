import extendedTablesStyle from '../../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import { Component } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { withStyles } from '@material-ui/core'
import Close from '@material-ui/icons/Close'

import * as CustomizeUserMaterialsActions from '../CustomizeUserMaterialsActions.js'
import {
	IN_HOUSE_PRINTERS_ACTION,
	MATERIAL_COST_DELETE_ITEM,
	USER_MATERIAL_NAME,
	USER_PRINTERS_NO_PRINTERS_FOUND
} from '../../../../../Services/Strings.js'
import ItemGrid from '../../../../Components/thirdParty/CreativeTim/components/Grid/ItemGrid.jsx'
import {
	Button,
	Muted,
	Table
} from '../../../../Components/thirdParty/CreativeTim/components/index.js'

const extendedTablesStyleTSX: any = extendedTablesStyle

interface IProps {
	onDeletePrinterClick: Function
	userId: number
	removeUserPrinter: any
	itemToDelete: any
	classes: any
	userPrinters: any[]
}

interface IReduxStore {
	user: any
	CustomizeUserMaterialsReducer: any
}

class CustomizeUserPrintersTable extends Component<IProps> {
	onDeletePrinterClick = (itemToDelete: any) => {
		const { onDeletePrinterClick, userId } = this.props
		onDeletePrinterClick(itemToDelete, userId)
	}

	onDeleteConfirmed = () => {
		const { removeUserPrinter, userId, itemToDelete } = this.props
		removeUserPrinter(itemToDelete, userId)
	}

	renderDeleteButton = (itemToDelete: any) => {
		const { classes } = this.props
		return (
			<Button
				title={MATERIAL_COST_DELETE_ITEM}
				onClick={this.onDeletePrinterClick.bind(this, itemToDelete)}
				color="dangerNoBackground"
				style={{ margin: 'unset', padding: 'unset' }}
				customClass={classes.actionButton}
			>
				<Close className={classes.icon} />
			</Button>
		)
	}

	renderInHousePrinterTblData = () => {
		const { userPrinters } = this.props
		if (!userPrinters.length) {
			return [['', <Muted>{USER_PRINTERS_NO_PRINTERS_FOUND}</Muted>, '']]
		}

		return userPrinters.map(userPrinter => [
			userPrinter.name,
			this.renderDeleteButton(userPrinter)
		])
	}

	render() {
		const { userPrinters } = this.props
		const tableHead = [USER_MATERIAL_NAME, IN_HOUSE_PRINTERS_ACTION]
		if (!userPrinters.length) {
			tableHead.push('')
		}
		return (
			<ItemGrid
				data-qa={'data-qa-3d-printers-table'}
				classes={{ grid: 'customize-user-material--table-wrapper' }}
			>
				<Table
					editTableStyle={'materialCostTableCell'}
					tableHead={tableHead}
					tableData={this.renderInHousePrinterTblData()}
				/>
			</ItemGrid>
		)
	}
}

const mapStateToProps = ({
	CustomizeUserMaterialsReducer,
	user
}: IReduxStore) => {
	const userPrinters = CustomizeUserMaterialsReducer.userId
		? CustomizeUserMaterialsReducer.userPrinters
		: user.userPrinters
	const { userId, itemToDelete, showDeleteAlert } =
		CustomizeUserMaterialsReducer
	return { userId, itemToDelete, showDeleteAlert, userPrinters }
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) => {
	return bindActionCreators({ ...CustomizeUserMaterialsActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(extendedTablesStyleTSX)(CustomizeUserPrintersTable))
