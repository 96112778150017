import {
	CO2_MATERIAL_DELETED,
	CO2_NEW_MATERIAL_ADDED,
	CO2_NEW_PRINTER_MATERIAL_ADDED,
	CO2_NEW_PRINTER_TYPICAL_KWH_ADDED,
	CO2_NEW_TM_TYPICAL_KWH_ADDED,
	CO2_PER_KG_MATERIAL_CHANGED,
	CO2_PER_KG_PRINTER_MATERIAL_CHANGED,
	CO2_PER_KW_CHANGED,
	CO2_CARBON_CREDIT_COST_CHANGED,
	CO2_HOLD_FACTOR_CHANGED,
	CO2_PRINTER_MATERIAL_DELETED,
	CO2_PRINTER_TYPICAL_KWH_DELETED,
	CO2_TM_TYPICAL_KWH_DELETED,
	END_OF_LIFE_DISPOSE_FACTOR_CHANGED,
	GET_CO2_MATERIAL,
	PART_LIFE_TIME_CHANGED,
	PRINTER_TYPICAL_KWH_CHANGED,
	TM_TYPICAL_KWH_CHANGED,
	YEARLY_SAVED_AMOUNT_CHANGED
} from 'global actions/types'

interface IState {
	co2PerKgMaterial: string
	co2PerKgPrinterMaterial: string
	co2PerKwValue: string
	puAnnualKgCO2Value: string
	puYearsCO2Value: string
	disposeFactorValue: string
	holdFactorValue: string
	carbonCreditCostValue: string
	typicalKwhPrinter: string
	typicalKwhTm: string
	materialList: any
	printersList: any
	cycleList: any
	typicalKwhList: any
	printersTypicalkwhList: any
}

const INITIAL_STATE: IState = {
	co2PerKgMaterial: '',
	co2PerKgPrinterMaterial: '',
	co2PerKwValue: '',
	puAnnualKgCO2Value: '',
	puYearsCO2Value: '',
	disposeFactorValue: '',
	holdFactorValue: '',
	carbonCreditCostValue: '',
	typicalKwhPrinter: '',
	typicalKwhTm: '',
	materialList: [],
	printersList: [],
	cycleList: [],
	typicalKwhList: [],
	printersTypicalkwhList: []
}

const CustomizeCO2CalculationReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case GET_CO2_MATERIAL: {
			const {
				materialList,
				cycleList,
				typicalKwhList,
				printersList,
				printersTypicalkwhList
			} = action.payload
			return {
				...state,
				materialList: materialList,
				cycleList: cycleList,
				typicalKwhList: typicalKwhList,
				printersList: printersList,
				printersTypicalkwhList: printersTypicalkwhList
			}
		}

		case CO2_PER_KG_MATERIAL_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				co2PerKgMaterial: value
			}
		}

		case CO2_PER_KG_PRINTER_MATERIAL_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				co2PerKgPrinterMaterial: value
			}
		}

		case PRINTER_TYPICAL_KWH_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				typicalKwhPrinter: value
			}
		}

		case TM_TYPICAL_KWH_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				typicalKwhTm: value
			}
		}

		case YEARLY_SAVED_AMOUNT_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				puAnnualKgCO2Value: value
			}
		}

		case PART_LIFE_TIME_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				puYearsCO2Value: value
			}
		}

		case END_OF_LIFE_DISPOSE_FACTOR_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				disposeFactorValue: value
			}
		}

		case CO2_HOLD_FACTOR_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				holdFactorValue: value
			}
		}

		case CO2_CARBON_CREDIT_COST_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				carbonCreditCostValue: value
			}
		}

		case CO2_PER_KW_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				co2PerKwValue: value
			}
		}

		case CO2_NEW_MATERIAL_ADDED: {
			const { newMaterial } = action.payload
			let changedUserMaterialList = state.materialList

			const isMaterial = changedUserMaterialList.findIndex(
				(materialObject: any) =>
					materialObject.material.id === newMaterial.material.id
			)
			if (isMaterial === -1) {
				changedUserMaterialList.push(newMaterial)
			}
			return {
				...state,
				materialList: changedUserMaterialList,
				co2PerKgMaterial: ''
			}
		}

		case CO2_MATERIAL_DELETED: {
			const { materialToDelete } = action.payload
			const changedUserMaterialList = state.materialList.filter(
				(materials: { material: any; id: any }) =>
					materials.material.id !== materialToDelete
			)
			return {
				...state,
				materialList: changedUserMaterialList
			}
		}
		case CO2_PRINTER_MATERIAL_DELETED: {
			const { materialToDelete } = action.payload
			const changedUserprintersList = state.printersList.filter(
				(materials: { material: any; id: any }) =>
					materials.material.id !== materialToDelete
			)
			return {
				...state,
				printersList: changedUserprintersList
			}
		}
		case CO2_NEW_PRINTER_MATERIAL_ADDED: {
			const { newPrinterMaterial } = action.payload
			let changedUserPrinterMaterialList = state.printersList

			const isMaterial = changedUserPrinterMaterialList.findIndex(
				(printerMaterial: any) =>
					printerMaterial.material.id === newPrinterMaterial.material.id
			)
			if (isMaterial === -1) {
				changedUserPrinterMaterialList.push(newPrinterMaterial)
			}

			return {
				...state,
				printersList: changedUserPrinterMaterialList,
				co2PerKgPrinterMaterial: ''
			}
		}

		case CO2_NEW_PRINTER_TYPICAL_KWH_ADDED: {
			const { printersTypicalkwhList } = action.payload

			return {
				...state,
				printersTypicalkwhList: printersTypicalkwhList,
				typicalKwhPrinter: ''
			}
		}
		case CO2_NEW_TM_TYPICAL_KWH_ADDED: {
			const { cycle } = action.payload
			let changedTypicalKwhList = state.typicalKwhList
			const isCycle = changedTypicalKwhList.findIndex(
				(cycleObject: any) => cycleObject.cycleName === cycle.cycleName
			)
			if (isCycle === -1) {
				changedTypicalKwhList.push(cycle)
			} else {
				changedTypicalKwhList[isCycle] = {
					...changedTypicalKwhList[isCycle],
					...cycle
				}
			}

			return {
				...state,
				typicalKwhList: changedTypicalKwhList,
				typicalKwhTm: ''
			}
		}
		case CO2_PRINTER_TYPICAL_KWH_DELETED: {
			const { printersTypicalkwhList } = action.payload
			return {
				...state,
				printersTypicalkwhList: printersTypicalkwhList
			}
		}
		case CO2_TM_TYPICAL_KWH_DELETED: {
			const { cycleToDelete } = action.payload
			let changedTypicalKwhList = state.typicalKwhList.filter(
				(typicalKwhObject: { id: any }) => typicalKwhObject.id !== cycleToDelete
			)
			return {
				...state,
				typicalKwhList: changedTypicalKwhList
			}
		}

		default:
			return state
	}
}

export default CustomizeCO2CalculationReducer
