import { FC, memo } from 'react'

import LinearProgressBar from './LinearProgressBar'

import './index.scss'

interface ProgressBarProps {
	showProgressAlert?: boolean
	showAnalyze?: boolean
}

const ProgressBar: FC<ProgressBarProps> = ({
	showProgressAlert,
	showAnalyze
}) => {
	const shouldShowAnalyze =
		showProgressAlert || typeof showProgressAlert === 'undefined'
			? showAnalyze
			: undefined

	return <LinearProgressBar showAnalyze={shouldShowAnalyze} />
}

export default memo(ProgressBar)
