import React, { Component } from 'react'

import './takeATour.scss'

interface Props {
  continuous: boolean
  isLastStep: boolean
  backProps: object
  closeProps: object
  skipProps: object
  index: number
  primaryProps: object
  tooltipProps: object
  step: {
    title: string
    content: string
    isWelcome: boolean
    noGradient: boolean
    hideNextButton: boolean
    hideBackButton: boolean
    showSkipButton: boolean
    locale: {
      back: string
      next: string
      close: string
      skip: string
    }
  }
}

const showBackBtn = true

export class Tooltip extends Component<Props> {
  render() {
    const {
      continuous,
      backProps,
      closeProps,
      skipProps,
      index,
      primaryProps,
      step,
      tooltipProps,
      isLastStep
    } = this.props
    const {
      title,
      content,
      locale: { skip, back, next, close },
      isWelcome,
      noGradient,
      hideNextButton,
      hideBackButton,
      showSkipButton
    } = step

    return (
      <div
        className={`tour-tooltip ${
          noGradient ? 'tour-tooltip--no-gradient' : ''
        }`}
        {...tooltipProps}
      >
        {!isLastStep && (
          <div {...skipProps} className="tour-tooltip--close-btn">
            ×
          </div>
        )}
        {title && (
          <h3
            className={`tour-tooltip--title ${
              isWelcome ? 'tour-tooltip--title-welcome' : ''
            }`}
          >
            {title}
          </h3>
        )}
        <div className="tour-tooltip--content">{content}</div>
        <div className="tour-tooltip--footer">
          {index > 0 && !hideBackButton && showBackBtn && (
            <button
              {...backProps}
              className="tour-tooltip--button tour-tooltip--footer--back"
            >
              {back || `< Back`}
            </button>
          )}
          {continuous && !hideNextButton && (
            <button
              {...primaryProps}
              className="tour-tooltip--button tour-tooltip--footer--next"
            >
              {next || `Next >`}
            </button>
          )}
          {showSkipButton && (
            <button
              {...skipProps}
              className="tour-tooltip--button tour-tooltip--footer--next"
            >
              {skip || `Skip`}
            </button>
          )}
          {!continuous ||
            (isLastStep && (
              <button
                {...closeProps}
                className="tour-tooltip--button tour-tooltip--footer--next"
              >
                {close || `Close`}
              </button>
            ))}
        </div>
      </div>
    )
  }
}

export default Tooltip
