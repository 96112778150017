import React, { FC, memo } from 'react';

import {
  Table
} from 'Scenes/Components/thirdParty/CreativeTim/components/index.js'
import {
  EMISSION_COMPARISON_TABLE
} from 'Services/Strings.js'
import { getString } from 'Services/Strings/StringService'
import ItemGrid from '../../../Components/thirdParty/CreativeTim/components/Grid/ItemGrid'
import TransparentButton from '../../../Components/TransparentButton'
import CustomizeEditInput from './CustomizeCO2Input/CustomizeEditInput'
import EditApplyButton from './CustomizeCO2EditApply/EditApplyButton'
import { checkIsDefaultValue } from './CustomizeCO2Service'
import { CustomizeCO2Props } from './CustomizeCO2Interface'

import './CusomizeCO2.scss'

const ItemGridTSX: any = ItemGrid

const CustomizeCO2CostTbl: FC<CustomizeCO2Props> = ({
 settingsData,
 co2Names,
 co2Data,
 isEditAll,
 isResetAll,
 isError,
 isLoading,
 onEditToggle,
 onEditAllToggle,
 onEditCO2Data,
 onUpdateCO2Data,
 disableApplyAll,
 errorMessage
}) => {
  const renderValue = (
    keyLabel: string,
    co2Key: string,
    data: number,
    isEdit: boolean,
    isError: boolean,
    isDefault: boolean,
    hoverText: string
  ) => {
    const co2Label = co2Names && co2Names[keyLabel] || {}
    const isEditOn = isEdit || isEditAll

    return (
      <CustomizeEditInput
        hoverText={hoverText}
        keyLabel={keyLabel}
        onEditCO2Data={onEditCO2Data}
        co2Key={co2Key}
        label={co2Label?.label}
        isDefault={isDefault}
        key={keyLabel}
        error={isError}
        value={data}
        edit={isEditOn}
        minValue={co2Label?.validation?.min}
        maxValue={co2Label?.validation?.max}
      />
    )
  }

  const renderTblData = () => {
    let tableData: any = []

    for (const co2Key in co2Data) {
      const rowData: any = []
      const defaultDataRow: any = []
      const data = co2Data[co2Key].data

      const isError = co2Data[co2Key].error
      const isEdit = co2Data[co2Key].edit
      const hoverText = co2Data[co2Key].tooltip
      const rowName = co2Data[co2Key].name

      for (const key in data) {
        const isDefault = checkIsDefaultValue(data, key, settingsData.defaultSettings)
        defaultDataRow.push(isDefault)

        const value = renderValue(
          key,
          co2Key,
          data[key],
          isEdit,
          isError,
          isDefault,
          hoverText
        )
        rowData.push(value)
      }

      let shouldReset = defaultDataRow.some((data: boolean) => !data)

      const action = (
        <EditApplyButton
          errorMessage={errorMessage}
          onUpdateCO2Data={onUpdateCO2Data}
          isEditAll={isEditAll}
          shouldReset={shouldReset}
          onEditToggle={onEditToggle}
          key={co2Key}
          co2Key={co2Key}
          isEdit={isEdit}
          isError={isError}
        />
      )

      tableData.push([rowName, ...rowData, action])
    }

    return tableData
  }

  const isEditAllBtn = (<div>
    <TransparentButton
      onClick={() => onEditAllToggle(!isEditAll)}
      className="custom-input--action"
    >
      {isEditAll ?
        getString('CANCEL') :
        getString('COST_AND_LEAD_EDIT_ALL')}
    </TransparentButton>
    {
      isEditAll ?
        <TransparentButton
          disabled={disableApplyAll}
          onClick={() => onUpdateCO2Data(false)}
          className="custom-input--action"
        >
          {getString('APPLY_ALL')}
        </TransparentButton> : <></>
    }
    {
      !isEditAll && isResetAll ?
        <TransparentButton
          onClick={() => onUpdateCO2Data(true)}
          className="custom-input--action"
        >
          {getString('RESET_ALL')}
        </TransparentButton> : <></>
    }
  </div>)

  return (
    <div className="customize__table">
      <ItemGridTSX>
        <Table
          editTableStyle={'customizeCO2TableCell'}
          tableHead={[
            EMISSION_COMPARISON_TABLE.PROPERTY_NAME,
            EMISSION_COMPARISON_TABLE.SHIPPING_DISTANCE,
            EMISSION_COMPARISON_TABLE.SHIP,
            EMISSION_COMPARISON_TABLE.AIRPLANE,
            EMISSION_COMPARISON_TABLE.TRAIN,
            EMISSION_COMPARISON_TABLE.TRUCK,
            isEditAllBtn
          ]}
          tableData={renderTblData()}
        />
      </ItemGridTSX>
      {
        isError && <p className="customize__input-error left">
          {getString('EMISSION_COMPARISON_SEND_ERROR')}
        </p>
      }
      {
        isLoading && <p className="customize__input-loading left">
          {getString('EMISSION_COMPARISON_SEND_LOADING')}
        </p>
      }
    </div>
  )
}

export default memo(CustomizeCO2CostTbl)

