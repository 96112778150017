import React, { memo, FC, ChangeEvent, useState } from 'react'
import { Select, InputLabel, FormControl } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import MenuItem from '@material-ui/core/MenuItem'
import { find } from 'lodash'

import CastorCheckbox from '../CastorCheckbox'
import { ADMIN_PROJECTS_FILTER_NAME }from '../../../Services/Strings'

import './CastorServerFilterBar.scss'

const SelectTsx: any = Select
const MenuItemTsx: any = MenuItem
const InputLabelTsx: any = InputLabel
const FormControlTsx: any = FormControl
const KeyboardArrowDownIconTsx: any = KeyboardArrowDownIcon

interface item {
  key: string
  value: any
}

interface IProps {
  value?: String[]
  onChange: Function
  items: Array<item>
	disableFilter: boolean
}

const renderMenuItem = (menuItem: any, value?: String[]) => {
  const checked = value?.some((el)=> el === menuItem.value)

  return (
    <MenuItemTsx
      key={menuItem.key}
      style={{ textTransform: 'capitalize' }}
      value={menuItem.value}
    >
      <CastorCheckbox
        checked={!!checked}
        classes={{
          checked: 'checkbox--checked-icon',
          root: 'check-boxs--icon',
        }}
      />
      {menuItem.key}
    </MenuItemTsx>
  )
}


const CastorServerFilterBar: FC<IProps> = ({
  value,
  onChange,
  items,
	disableFilter
}) => {
  const [isSelectorOpen, setSelectorOpen] = useState(false)
  const onCloseList = () => setSelectorOpen(false)
  const onOpenList = () => !isSelectorOpen && setSelectorOpen(true)

	if (disableFilter) return <></>

  return (
    <FormControlTsx
      className="form-control custom filter"
      sx={{ m: 1, minWidth: 120 }}
      size="small"
    >
      <InputLabelTsx id="select-label">{ADMIN_PROJECTS_FILTER_NAME}</InputLabelTsx>
        <SelectTsx
          labelId="select-label"
          multiple
          open={isSelectorOpen}
          onWheel={onCloseList}
          onClick={onOpenList}
          onClose={onCloseList}
          MenuProps={{
            variant: "menu",
            getContentAnchorEl: null,
          }}
          IconComponent={() => (
            <KeyboardArrowDownIconTsx />
            )
          }
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value)
          }}
          renderValue={(selected: String[]) => {
            const getFirst = find(items, { value: selected[0] });
            if (selected.length === 1) {
              return <div>{getFirst?.key}</div>
            }
            if (selected.length > 1) {
              return <div>{getFirst?.key}, <span>+{selected.length - 1}</span></div>
            }
          }}
        >
          {items && items.map((item: any) => renderMenuItem(item, value))}
        </SelectTsx>
    </FormControlTsx>
  )
}

export default memo(CastorServerFilterBar)
