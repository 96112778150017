import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as AdminPrinterNamesActions from './AdminPrinterNamesActions'
import { ADMIN_PRINTER_NAMES_EDIT } from '../../../../Services/Strings'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import AdminPrinterNameForm from './AdminPrinterNameForm'

class EditAdminPrinterName extends Component {
	componentDidMount() {
		const {
			printerNames,
			selectPrinterNameToEdit,
			match,
			setupAdminPrinterNamesPage,
			pageNumber,
			limitPrinterNames,
			searchPhrase
		} = this.props
		const { id } = match.params
		if (!printerNames.length) {
			setupAdminPrinterNamesPage(searchPhrase, pageNumber, limitPrinterNames)
		} else {
			selectPrinterNameToEdit(id, printerNames)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			printerNames,
			selectPrinterNameToEdit,
			match,
			selectedEditPrinterName
		} = this.props
		const { id } = match.params
		if (
			printerNames.length &&
			(!selectedEditPrinterName || prevProps.match.params.id !== id)
		) {
			selectPrinterNameToEdit(id, printerNames)
		}
	}

	onSubmit = data => {
		this.props.updatePrinterName(data)
	}

	renderFormContent = () => {
		const { selectedEditPrinterName, printingTechnologies } = this.props
		return (
			<AdminPrinterNameForm
				printingTechnologies={printingTechnologies}
				onSubmit={this.onSubmit}
				initialValues={selectedEditPrinterName}
			/>
		)
	}

	render() {
		return (
			<NavBarAndMaterial title={ADMIN_PRINTER_NAMES_EDIT}>
				<CastorForm
					formTitle={ADMIN_PRINTER_NAMES_EDIT}
					content={this.renderFormContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const mapStateToProps = ({ AdminPrinterNamesReducer, user }) => {
	const {
		printerNames,
		selectedEditPrinterName,
		pageNumber,
		limitPrinterNames,
		searchPhrase
	} = AdminPrinterNamesReducer
	const { printingTechnologies } = user
	return {
		printerNames,
		selectedEditPrinterName,
		pageNumber,
		limitPrinterNames,
		printingTechnologies,
		searchPhrase
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminPrinterNamesActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditAdminPrinterName)
