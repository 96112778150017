import {
  MPIO_SHOW_ALERT,
  HIDE_ALERT,
  MPIO_REQUEST,
  MPIO_REQUEST_SUCCESS,
  MPIO_REQUEST_FAIL
} from '../../../../global actions/types'

const INITIAL_STATE = {
  error: null,
  loading: false,
  popup: null
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MPIO_SHOW_ALERT:
      return { ...state }
    case MPIO_REQUEST:
      return {
        ...state,
        popup: null,
        error: null,
        loading: true
      }

    case MPIO_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false
      }
    case MPIO_REQUEST_FAIL:
      return { ...state, loading: false }
    case HIDE_ALERT:
      return { ...state, popup: null, error: null }
    default:
      return state
  }
}
