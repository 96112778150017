import React, { FC, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { AM_FORM_IDS, STEP_ID } from '../../constants'
import { useStep } from '../../OnboardingWizardHooks'
import OnboardingStepFrame from '../OnboardingStepFrame'
import AMMaterialForm from './AMMaterialForm'
import { setupPrinterMaterialForm } from 'Scenes/Home/Customize/CustomizeUserMaterials/CustomizeUserMaterialsActions'

const AMFormComponents = {
	[AM_FORM_IDS.AM_FORM]: <AMMaterialForm />
}

const AMForm: FC = () => {
	const { steps } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { printerMaterialAdded } = useSelector(
		(state: RootStateOrAny) => state.CustomizeUserMaterialsReducer
	)
	const { step, stepContent, childSteps } = useStep(steps, STEP_ID.AM_FORM)
	const dispatch = useDispatch()

	useEffect(() => {
		if (printerMaterialAdded) {
			dispatch(setupPrinterMaterialForm())
		}
	}, [dispatch, printerMaterialAdded])

	return (
		<OnboardingStepFrame
			step={step}
			bodyClassName="cost-function-editor-frame"
			childSteps={childSteps}
		>
			<div>
				{stepContent?.map(component => (
					<React.Fragment key={component.id}>
						{AMFormComponents[component.id as keyof typeof AMFormComponents]}
					</React.Fragment>
				))}
			</div>
		</OnboardingStepFrame>
	)
}

export default AMForm
