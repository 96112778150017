import React, { FC, memo, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { AnyAction, bindActionCreators } from 'redux'

import * as AdminProjectsActions from '../AdminProjectsActions'
import CastorForm from '../../../../Components/CastorForm/CastorForm'
import DataTable from '../../../../Components/DataTable'
import NavBarAndMaterial from '../../../../Components/NavBarAndMaterial'
import Loader from '../../../../Loader/Loader'

import '../../adminHome.scss'

const NavBarAndMaterialTSX: any = NavBarAndMaterial

interface IProps extends RouteComponentProps<MatchParams> {
	projectSelected: any
	loading: boolean
	projectInfoData: any[][]
	fetchProject: (id: string) => any
}

interface IReduxStore {
	AdminProjectsReducer: any
}

interface MatchParams {
	id: string
}

const AdminProjectInfo: FC<IProps> = ({
	projectSelected,
	loading,
	fetchProject,
	projectInfoData,
	match: { params }
}) => {
	useEffect(() => {
		if (!projectSelected) {
			const { id } = params
			fetchProject(id)
		}
	}, [projectSelected])

	const renderInfoTable = () => {
		if (!projectSelected) {
			return <div></div>
		}
		return (
			<DataTable
				tableClassName="admin--projects--project-info"
				tableDataRows={projectInfoData}
				showBreakLines={true}
			/>
		)
	}

	if (loading) {
		return <Loader load={loading} message="" />
	}

	return (
		<NavBarAndMaterialTSX title={'Projects'}>
			<CastorForm
				formTitle="Project Information"
				formSubTitle=""
				content={renderInfoTable()}
				style={{ maxWidth: 'unset' }}
			/>
		</NavBarAndMaterialTSX>
	)
}

const mapStateToProps = ({ AdminProjectsReducer }: IReduxStore) => {
	return {
		...AdminProjectsReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...AdminProjectsActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(AdminProjectInfo)
)
