import { Action } from '../../../../global actions/ActionModels'
import {
  GET_ADMIN_VERSION_PAGE_PARAMETERS_GOT_ERROR,
  GET_ADMIN_VERSION_PAGE_PARAMETERS_SUCCESS
} from '../../../../global actions/types'

export class AdminVersionPageInitialState {
  readonly showNoParametersAlert: boolean = false
  readonly pageNumber: number = 1
  readonly isLastPage: boolean = false
  readonly showPagination: boolean = false
  readonly showAdminVersionPageAlert: boolean = false
  readonly loading: boolean = false
  readonly versionPageData: any = null
}

const initialState: AdminVersionPageInitialState =
  new AdminVersionPageInitialState()

const adminVersionPageReducer = (
  state = initialState,
  { type, payload }: Action<any>
) => {
  switch (type) {
    case GET_ADMIN_VERSION_PAGE_PARAMETERS_SUCCESS:
      const data = payload.data
      return {
        ...state,
        versionPageData: data,
        showNoParametersAlert: !data.length
      }
    case GET_ADMIN_VERSION_PAGE_PARAMETERS_GOT_ERROR:
      return { ...state, showAdminVersionPageAlert: true, loading: false }

    default:
      return state
  }
}

export default adminVersionPageReducer
