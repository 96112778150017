import { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import YouTube from 'react-youtube'

import { Muted } from '../../Components/thirdParty/CreativeTim/components'
import { REGISTER_EXPLINATION } from 'Services/Strings'
import { getTheme } from 'themes/getTheme'

const YouTubeTSX: any = YouTube

const { hideRegisterExplanation } = getTheme()

interface IProps {}

const youtubeOpt = {
	height: '214.5',
	width: '100%',
	playerVars: {
		autoplay: 0
	}
}

const RegisterExplanation: FC<IProps> = ({}) => {
	const isOnPrem = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer.isOnPrem
	)

	if (hideRegisterExplanation) {
		return <div />
	}

	try {
		return (
			<>
				<Muted>{REGISTER_EXPLINATION}</Muted>
				<br />
				{!isOnPrem && <YouTubeTSX videoId="OP2cPCnerZk" opts={youtubeOpt} />}
			</>
		)
	} catch (error: any) {
		return <Muted>{REGISTER_EXPLINATION}</Muted>
	}
}

export default memo(RegisterExplanation)
