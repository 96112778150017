import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { TextField } from '@material-ui/core'

import {
	onCarbonCreditCostChange,
	onSetUserCustomizationSettings
} from '../CustomizeCO2CalculationAction'
import { ParameterTypes } from '../ParameterTypes'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'
import { isDisabledKeyPressed } from 'Services/getKeyCodesService'
import { getString } from 'Services/Strings/StringService'

const TextFieldTSX: any = TextField

interface IProps {}

const CustomizeCarbonCreditCost: FC<IProps> = () => {
	const [isCarbonCreditError, setCarbonCreditError] = useState(false)

	const {
		carbonCreditCost,
		carbonCreditCostLoading,
		carbonCreditCostResetLoading,
		userSettingsDefaultValues
	} = useSelector((state: RootStateOrAny) => state.user)
	const { carbonCreditCostValue } = useSelector(
		(state: RootStateOrAny) => state.CustomizeCO2CalculationReducer
	)
	const dispatch = useDispatch()

	useEffect(() => {
		setCarbonCreditError(Number(carbonCreditCostValue) < 0)
	}, [carbonCreditCostValue])

	useEffect(() => {
		if (
			!carbonCreditCostLoading &&
			carbonCreditCost !== carbonCreditCostValue
		) {
			dispatch(onCarbonCreditCostChange(''))
		}
	}, [carbonCreditCost, carbonCreditCostLoading])

	const onSaveCarbonCreditCost = (reset: boolean = false) => {
		if (carbonCreditCostValue !== '' || reset) {
			dispatch(
				onSetUserCustomizationSettings(
					reset ? null : carbonCreditCostValue,
					ParameterTypes.carbonCreditCost,
					reset
				)
			)
		}
	}

	const carbonCreditCheck = (value: string) => {
		setCarbonCreditError(Number(value) < 0)

		dispatch(onCarbonCreditCostChange(value))
	}

	const handleKeyDown = (event: KeyboardEvent) => {
		if (isDisabledKeyPressed(event)) {
			event.preventDefault()
		}
	}

	return (
		<div
			className="material-production--wrapper product-use"
			data-qa="data-qa-carbon-credit-section"
		>
			<div className="custom-calculation-co2--title">
				{getString('CO2_PARAMETERS_CARBON_CREDIT_HEADER_TEXT')}
			</div>
			<div className="custom-calculation-co2--explanation">
				{getString('CO2_PARAMETERS_CARBON_CREDIT_DESCRIPTION_TEXT')}
			</div>
			<Flexbox flexDirection="row" className="custom-calculation-co2--wrapper">
				<Flexbox
					flexDirection="row"
					alignItems="center"
					width="100%"
					gap="13px"
				>
					<div className="custom-calculation-co2--explanation-text">
						{getString('CO2_PARAMETERS_CARBON_CREDIT_LABEL')}
					</div>
					<TextFieldTSX
						className="custom-calculation-co2--carbon-picker"
						data-qa="data-qa-carbon-credit-input"
						type="number"
						placeholder={carbonCreditCost}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							carbonCreditCheck(e.target.value)
						}}
						onWheel={(event: ChangeEvent<HTMLInputElement>) =>
							event.target.blur()
						}
						onKeyDown={handleKeyDown}
						value={carbonCreditCostValue}
					/>
					<div className="custom-calculation-co2--explanation-text">
						{getString('CO2_PARAMETERS_CARBON_CREDIT_LABEL_CURRENCY')}
					</div>
				</Flexbox>
				{carbonCreditCost !== userSettingsDefaultValues.carbonCreditCost && (
					<ButtonWithLoader
						className="button-to-default"
						color="transparent"
						loading={carbonCreditCostResetLoading}
						onClick={() => onSaveCarbonCreditCost(true)}
					>
						{getString('RETURN_TO_DEFAULT')}
					</ButtonWithLoader>
				)}
				<ButtonWithLoader
					qaDataElementName="data-qa-carbon-credit-set-btn"
					loading={carbonCreditCostLoading}
					onClick={() => onSaveCarbonCreditCost(false)}
					disabled={!carbonCreditCostValue || isCarbonCreditError}
				>
					{getString('SET')}
				</ButtonWithLoader>
			</Flexbox>
			{isCarbonCreditError && (
				<div className="custom-calculation-co2--error">
					{getString('CO2_PER_KG_DATA_VALIDATION')}
				</div>
			)}
		</div>
	)
}

export default memo(CustomizeCarbonCreditCost)
