import { FC, memo } from 'react'
import { useDispatch } from 'react-redux'

import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import {
	CONFIGURATION_INHOUSE_ALERT_TEXT,
	GO_TO_SETTINGS,
	NO_INHOUSE_PRINTER_WAS_SET
} from 'Services/Strings'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import {
	hideInhouseAlert
} from '../../MainPartAnalysis/MainPartAnalysisActions'

interface ConfigurationInHouseAlertProps {
	showInhouseAlert: boolean,
	onInhouseAlertConfirm: Function
}

const ConfigurationInHouseAlert: FC<ConfigurationInHouseAlertProps> = ({
	showInhouseAlert,
	onInhouseAlertConfirm
}) => {
	const dispatch = useDispatch()

	return (
		<CastorAlert
			onCancel={() => dispatch(hideInhouseAlert())}
			show={showInhouseAlert}
			onConfirm={onInhouseAlertConfirm}
			confirmOptionalText={GO_TO_SETTINGS}
			alertType={AlertType.WARNING}
			headerTitle={NO_INHOUSE_PRINTER_WAS_SET}
		>
			{CONFIGURATION_INHOUSE_ALERT_TEXT}
		</CastorAlert>
	)
}

export default memo(ConfigurationInHouseAlert)
