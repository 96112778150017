import { FC, memo, useRef } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'

import { bomFileTypes } from '../../fileTypes'
import { BOMFileSelected } from '../../UploadProjectActions'
import BomTemplate from 'Scenes/Components/BomTemplate'
import CastorSelectBox from 'Scenes/Components/CastorSelectBox'
import Flexbox from 'Scenes/Components/FlexBox'
import TransparentButton from 'Scenes/Components/TransparentButton'
import {
	FILE_EXPLANATION_ARRAY,
	GENERATIVE_FILE_EXPLANATION_ARRAY,
	LEARN_HOW
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as CircleClose } from 'assets/img/svg/icons/circle_close.svg'
import { ReactComponent as FileExcel } from 'assets/img/svg/icons/file_excel.svg'

interface UploadBOMProps {
	disabled: boolean
	isGenerativeFileOption?: boolean
}

interface FileExplanationProps {
	isOnPrem: boolean
	disabled: boolean
	isGenerativeFileOption?: boolean
}

const FileExplanationContent: FC<FileExplanationProps> = ({
	isOnPrem,
	disabled,
	isGenerativeFileOption
}) => {
	const text = isGenerativeFileOption
		? GENERATIVE_FILE_EXPLANATION_ARRAY
		: FILE_EXPLANATION_ARRAY

	let components = []
	for (let i = 0; i < text.length - 1; i++) {
		components.push(
			<p
				key={i}
				className={cx({
					disabledText: disabled,
					bomUploadInput: !disabled
				})}
			>
				{text[i]}
			</p>
		)
	}

	const lastStr = text[text.length - 1]

	const lastComponent = (
		<p
			key="key"
			className={cx({
				disabledText: disabled,
				bomUploadInput: !disabled
			})}
		>
			{lastStr}
			{!isGenerativeFileOption && !isOnPrem && (
				<TransparentButton
					isAnchor={true}
					href={disabled ? '/' : 'https://youtu.be/ICxxFlwZreU'}
					disabled={disabled}
					target="_blank"
					className="upload-form-learn-how-button"
				>
					{LEARN_HOW}
				</TransparentButton>
			)}
		</p>
	)
	components.push(lastComponent)
	return <div className="files-explanation">{components}</div>
}

const UploadBOM: FC<UploadBOMProps> = ({
	disabled,
	isGenerativeFileOption
}) => {
	const { isOnPrem } = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer
	)
	const { fileInputKey, BOMFile } = useSelector(
		(state: RootStateOrAny) => state.uploadProject
	)
	const inputRef: any = useRef(null)

	const dispatch = useDispatch()

	const onUploadBom = () => inputRef?.current?.click()

	return (
		<Flexbox className="upload-form-radio-bom">
			<FileExplanationContent
				isOnPrem={false}
				disabled={disabled}
				isGenerativeFileOption={isGenerativeFileOption}
			/>
			{!disabled && (
				<Flexbox
					className="bom-file-upload"
					alignItems="center"
					justifyContent="space-between"
				>
					<Flexbox alignItems="center">
						<input
							name="bomFileUpload"
							id="bomFileUpload"
							type="file"
							ref={inputRef}
							value={BOMFile ? '' : undefined}
							key={fileInputKey}
							onChange={(e: any) =>
								dispatch(BOMFileSelected(e.target?.files[0]))
							}
							className={cx('hide-input', {
								disabledText: disabled,
								bomUploadInput: !disabled
							})}
							accept={bomFileTypes.join(',')}
						/>
						{BOMFile ? (
							<CastorSelectBox
								key={BOMFile?.name}
								selected={true}
								withIcon={false}
								boxClassName="box-around"
							>
								<div>
									<FileExcel className="icon-file" />
									<div className="text">{BOMFile?.name}</div>
									<CircleClose
										onClick={() => dispatch(BOMFileSelected(null))}
										className="icon-close"
									/>
								</div>
							</CastorSelectBox>
						) : (
							<>
								<div
									onClick={onUploadBom}
									className="bom-file-upload__button"
									data-qa="data-qa-bom-choose-file"
								>
									{getString('UPLOAD_FILE_CHOSE_FILE')}
								</div>
								<div className="bom-file-upload__text">
									{BOMFile?.name || getString('UPLOAD_FILE_NO_CHOSEN_FILE')}
								</div>
							</>
						)}
					</Flexbox>
					<BomTemplate
						showImage={true}
						isOnPrem={isOnPrem}
						isGenerativeFileOption={isGenerativeFileOption}
					/>
				</Flexbox>
			)}
		</Flexbox>
	)
}

export default memo(UploadBOM)
