import { FC, memo } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import CardHeaderBox from 'Scenes/Components/CardHeaderBox'
import FieldWithLabel from 'Scenes/Components/FieldWithLabel'
import { getString } from 'Services/Strings/StringService'
import {
	updateProjectStandardCost
} from 'Scenes/Home/NewUploadProject/UploadProjectActions'

const UploadEstimatedPart: FC = () => {
	const {
		projectStandardCost
	} = useSelector(
		(state: RootStateOrAny) => state.uploadProject)
	const dispatch = useDispatch()

	return (
		<CardHeaderBox
			contentClass="new-upload-project__card"
			title={getString('NEW_UPLOAD_SECTION_COST')}
			content={
				<Flexbox
					flexDirection="column"
					className="new-upload-project__content"
				>
					<FieldWithLabel
						isNumberField
						fieldId="upload-form-field--yearly-prod"
						labelName={getString('WHAT_ESTIMATED_PART')}
						fieldValue={projectStandardCost}
						fieldClassName="short"
						fieldOnChange={value => dispatch(updateProjectStandardCost(value))}
						qaDataElementName="data-qa-upload-page-estimated-quantity"
						currency={'$'}
					/>
				</Flexbox>
			}
		/>
	)
}
export default memo(UploadEstimatedPart)
