import React, { FC, memo } from 'react'
import { InjectedFormProps, reduxForm, Field } from 'redux-form'
import { SUBMIT } from '../../../../Services/Strings'
import TextField from '../../../Components/TextField/TextField'
import Flexbox from 'Scenes/Components/FlexBox'
import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import TransparentButton from '../../../Components/TransparentButton'
import CastorSwitch from '../../../Components/CastorSwitch'
import { generateKey } from '../../../../Services/Utils/uniqKeyGenerator'

import './ParametersManager.scss'
import { getString } from 'Services/Strings/StringService'

const ReduxFormField: any = Field

interface IProps {
  onSubmitClick: any
  parametersUpdateLoading: boolean
  parametersEditableNames: Array<string>
  parametersInfoObject: any
}

interface IFormData {}

const renderToggleField = ({ input, label, change, disabled }: any) => (
  <div>
    <div>{label}</div>
    <CastorSwitch
      disabled={disabled}
      checked={input.value}
      onChange={input.onChange}
    />
  </div>
)
const renderTextField = ({
  input,
  label,
  type,
  placeholder,
  meta: { touched, error },
  inputProps,
  parametersInfo,
  maxValue
}: any) => (
  <TextField
    wrapperClassName="admin-project-form--field"
    labelInfoWrapperClassName="admin-project-form--field--text-field-wrapper"
    labelClassName="admin-project-form--field--text-field-label"
    disableMinus
    disablePlus
    disableZero
    maxValue={maxValue}
    label={label}
    error={error}
    type={type}
    input={input}
    touched={touched}
    placeholder={placeholder}
    inputProps={inputProps}
    infoData={parametersInfo?.info}
    infoDataBoxDirection="right-start"
  />
)
const renderFieldComponent = (fieldType: string) => {
  switch (fieldType) {
    case 'toggle':
      return renderToggleField

    default:
      return renderTextField
  }
}
const generateParametersFields = (
  parametersEditableNames: Array<string>,
  parametersInfoObject: any
) => {
  return (
    parametersEditableNames &&
    parametersEditableNames.map((name, index) => {
      return (
        <div key={generateKey(index)}>
          <ReduxFormField
            name={name}
            component={renderFieldComponent(
              parametersInfoObject[name].fieldType
            )}
            label={parametersInfoObject[name]?.label}
            type="number"
            maxValue={parametersInfoObject[name].maxValue}
            inputProps={{ min: 1, max: parametersInfoObject[name].maxValue }}
            parametersInfo={parametersInfoObject[name]}
          />
        </div>
      )
    })
  )
}
const AdminCostingCNCForm: FC<
  InjectedFormProps<IFormData, IProps> & IProps
> = ({
  handleSubmit,
  onSubmitClick,
  reset,
  pristine,
  submitting,
  valid,
  parametersUpdateLoading,
  parametersEditableNames,
  parametersInfoObject
}) => {
  if (!parametersEditableNames || !parametersEditableNames.length) {
    return <div />
  }
  return (
    <Flexbox className="admin-project-form" flexDirection="column">
      {generateParametersFields(parametersEditableNames, parametersInfoObject)}
      <Flexbox
        alignItems="center"
        alignSelf="flex-end"
        justifyContent="space-between"
        width="200px"
      >
        <TransparentButton onClick={() => reset()}>{getString('RESET')}</TransparentButton>
        <ButtonWithLoader
          onClick={handleSubmit(onSubmitClick)}
          loading={parametersUpdateLoading}
          disabled={pristine || submitting || !valid}
        >
          {SUBMIT}
        </ButtonWithLoader>
      </Flexbox>
    </Flexbox>
  )
}

export default memo(
  reduxForm<IFormData, IProps>({
    form: 'CostingCNC',
    enableReinitialize: true
  })(AdminCostingCNCForm)
)
