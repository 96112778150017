import fileSaver from 'file-saver'
import { isBoolean, isNumber, uniqBy } from 'lodash'

import { prepareUserFiltersToSend } from '../Scenes/Components/AdvancedSettings/AdvancedSettingsService'
import { LOGIN_ROUTE } from '../Services/Constants/RoutesConstants'
import { getTheme } from '../themes/getTheme'
import {
	GET_PROJECTS_SUCCESS,
	GOT_LOGIN_DATA,
	GOT_MATERIAL_TECHNOLOGIES_DATA,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	LOGIN_PENDING,
	LOGIN_STATE_CHANGED,
	POPPER_MENU_CLOSED,
	PROJECTS_REANALYSIS_RECALCULATE_POLLER_FAILED,
	PROJECTS_REANALYSIS_RECALCULATE_POLLER_FINISHED,
	PROJECTS_REANALYSIS_RECALCULATE_POLLER_STARTED,
	RESET_AUTH_STATE,
	USER_CUSTOM_SETTINGS_UPDATED,
	USER_FILTERS_CHANGE,
	USER_LOGOUT
} from './types'
import { ALERT_POPPED, ALERT_POPUP_CANCELED } from './types/CastorAlertTypes'
import {
	CASTOR_BANNER_CANCEL,
	SHOW_CASTOR_BANNER
} from './types/CastorBannerTypes'
import { store } from 'index'
import { loginUserSuccess } from 'Scenes/Authentication/AuthActions'
import { CastorBannerType } from 'Scenes/Components/CastorBanner/CastorBannerType.enum'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import {
	COMMUNICATION_TOOL_LS_KEY,
	COMMUNICATION_TOOL_PARAMS_LS_KEY,
	TRUE_STRING
} from 'Services/Constants'
import history from 'Services/history'
import {
	getJsonItemFromLocalStorage,
	getStringItemFromLocalStorage,
	removeAllLocalStorage
} from 'Services/LocalStorageService'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import {
	createHealthStatisticsLog,
	getPartsReanalysisEntriesByUser,
	getProjects,
	getUserProfileData,
	logout,
	logoutWithProvider,
	refreshJWT,
	updateUserCustomizeFilters,
	updateUserCustomizeFullTrayAssumption,
	updateUserCustomizeSupplChainCosts
} from 'Services/Network'
import Poller from 'Services/PollingService/Poller'
import { customizeUser } from 'Services/routeFuncs'
import {
	CONFIGURATION_CALCULATION_ERROR,
	HEALTH_STATISTICS_ERROR,
	HEALTH_STATISTICS_FAILED,
	HEALTH_STATISTICS_SUCCESS,
	POLLER_REACHED_TIMEOUT,
	SHOW_NOTIFICATION
} from 'Services/Strings'
import {
	getString,
	handleUserCurrencyChange,
	handleUserLanguageChange
} from 'Services/Strings/StringService'
import { UserFilterService } from 'Services/UserFilterService'

const version = require('../../package.json').version

const { skipLoginPage } = getTheme()

const userFilterService = new UserFilterService()
let poller = new Poller()

export const clearReanalysisPoller = () => {
	if (poller.pollerInterval) {
		poller.stop()
	}
	return {
		type: null
	}
}

export const loginStateChanged = loggedIn => {
	return { type: LOGIN_STATE_CHANGED, payload: loggedIn }
}

export const isLoggedInSuccess = (dispatch, response) => {
	if (response) {
		const { generalData } = response?.data
		const printerTechnologiesIds = generalData?.printerMaterials?.map(item => [
			item.printerTechnology,
			item.name,
			item.id
		])
		const printingTechnologies = generalData?.printingTechnology
		const userEmail = generalData?.userDetails.email
		const roles = generalData?.roles
		const printers = generalData?.printers
		const userMaterials = generalData?.userMaterials
		const features = generalData?.features
		const userSubscriptionDetails = generalData?.userSubscriptionDetails
		const printerTechnologies = getTechnologies(generalData?.printerMaterials)
		const printerMaterialUniqueNames = uniqBy(
			generalData?.printerMaterials,
			'name'
		)
		const { partsCreditExpired, trial, subscriptionExpired } =
			userSubscriptionDetails
		const userOnTrial = trial !== null && trial !== undefined && trial
		const { userFilters } = userFilterService.getUserFilters(
			generalData?.filters,
			generalData?.userFilters
		)

		const isAdmin =
			roles.length > 0 ? roles.includes(UserRole.SUPER_ADMIN) : undefined

		const isLightUser = roles.includes(UserRole.LIGHT)
		const userPrinters = generalData?.userPrinters
		const userPrinterMaterials = generalData?.userPrinterMaterials

		const isCommunicationTool = Boolean(
			getStringItemFromLocalStorage(COMMUNICATION_TOOL_LS_KEY)
		)
		const comsToolParams = getJsonItemFromLocalStorage(
			COMMUNICATION_TOOL_PARAMS_LS_KEY
		)
		// rewrite show property of uploadProjectComponents with values from urlSearchParams
		const uploadProjectComponents = generalData?.uploadProjectComponents.map(
			component => {
				if (comsToolParams[component.name]) {
					component.show = comsToolParams[component.name] === TRUE_STRING
				}
				return component
			}
		)
		const isOrganizationOwnerExist = generalData.isOrganizationOwnerExist

		dispatch(
			checkUserSubscriptionAlert(
				isAdmin,
				isLightUser,
				subscriptionExpired,
				partsCreditExpired,
				userOnTrial,
				userEmail,
				isOrganizationOwnerExist,
				features
			)
		)

		dispatch({
			type: GOT_MATERIAL_TECHNOLOGIES_DATA,
			payload: {
				printerTechnologies,
				printerTechnologiesIds,
				printingTechnologies,
				printers,
				userMaterials,
				printerMaterialUniqueNames,
				userPrinters,
				userPrinterMaterials
			}
		})
		dispatch({
			type: GOT_LOGIN_DATA,
			payload: {
				isLightUser,
				isCommunicationTool,
				userFilters,
				generalData: { ...generalData, uploadProjectComponents }
			}
		})

		dispatch({
			type: LOGIN_STATE_CHANGED,
			payload: true
		})
		dispatch({
			type: LOGIN_PENDING,
			payload: false
		})
	}
}

export const isLoggedInFail = (dispatch, error) => {
	dispatch({ type: LOGIN_STATE_CHANGED, payload: false })
	dispatch({
		type: LOGIN_PENDING,
		payload: false
	})
}

export const resetAuthStateUser = () => {
	return {
		type: RESET_AUTH_STATE
	}
}

export const onLanguageChange = selectedLanguage => {
	return async dispatch => {
		try {
			dispatch({
				type: HANDLE_LOADER,
				payload: 1
			})
			await handleUserLanguageChange(selectedLanguage)
		} catch (error) {
			console.error(error.message)
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}
export const checkReanalysisProjectsPoller = ownerId => {
	let numOfProjectReanalysis = 0
	return dispatch => {
		dispatch({
			type: PROJECTS_REANALYSIS_RECALCULATE_POLLER_STARTED
		})
		let timeOutDateTime = new Date()
		timeOutDateTime.setHours(timeOutDateTime.getHours() + 1)
		poller.stop()

		poller = new Poller(
			2000,
			timeOutDateTime,
			() => getPartsReanalysisEntriesByUser(ownerId),
			res => !res?.data?.allPartsReanalysisList,
			res => {
				if (res?.data?.allPartsReanalysisList !== numOfProjectReanalysis) {
					numOfProjectReanalysis = res?.data?.allPartsReanalysisList
					getProjects().then(projects => {
						const { features, maxAllowedUploadProjects, roles } =
							store.getState().user
						dispatch({
							type: GET_PROJECTS_SUCCESS,
							payload: {
								projects: projects.data.projects,
								userProjectFolders: projects.data.userProjectFolders,
								features,
								maxAllowedUploadProjects,
								roles
							}
						})
					})
				}
			}
		)

		poller
			.start()
			.then(res => {
				dispatch({
					type: PROJECTS_REANALYSIS_RECALCULATE_POLLER_FINISHED
				})
			})
			.catch(error => {
				console.error(error)
				if (error === POLLER_REACHED_TIMEOUT) {
					window.location.reload()
					return
				}
				dispatch({
					type: PROJECTS_REANALYSIS_RECALCULATE_POLLER_FAILED
				})
			})
	}
}

export const onCurrencyChange = selectedCurrency => {
	return async dispatch => {
		try {
			dispatch({
				type: HANDLE_LOADER,
				payload: 1
			})
			await handleUserCurrencyChange(selectedCurrency)
		} catch (error) {
			console.error(error.message)
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onDownloadHealthStatisticsClick = () => {
	return async dispatch => {
		try {
			dispatch({
				type: HANDLE_LOADER,
				payload: 1
			})
			const result = await createHealthStatisticsLog(version)
			if (result.data && result.data.jsonLog) {
				const blob = new Blob([JSON.stringify(result.data.jsonLog)], {
					type: 'text/plain;charset=utf-8'
				})
				fileSaver.saveAs(blob, 'healthStatistics.txt')
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: HEALTH_STATISTICS_SUCCESS
					}
				})
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: HEALTH_STATISTICS_ERROR
					}
				})
			}
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		} catch (error) {
			console.log(HEALTH_STATISTICS_FAILED, error.message)
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: HEALTH_STATISTICS_FAILED
				}
			})
		}
	}
}

export const logoutPressed = (email, forceToLoginPage) => {
	return async dispatch => {
		dispatch(clearReanalysisPoller())
		dispatch({
			type: POPPER_MENU_CLOSED
		})

		dispatch({ type: CASTOR_BANNER_CANCEL })

		logout(email)
			.then(async () => {
				const { data } = await logoutWithProvider(email)
				dispatch({
					type: RESET_AUTH_STATE
				})
				dispatch({
					type: LOGIN_STATE_CHANGED,
					payload: false
				})

				// show login page after logout
				dispatch({ type: USER_LOGOUT })

				if (skipLoginPage && !forceToLoginPage) {
					history.push('/home/castorLight')
					resetAllApplicationData()
					window.location.reload()
				} else {
					window.location.href = data?.signOutLink || LOGIN_ROUTE
				}

				// when logout we need to
				// reset the cache and localStorage data
				resetAllApplicationData()
			})
			.catch(error => {
				console.log(error)
			})
	}
}

export const getTechnologies = printerMaterials => {
	let materialNamePerTech = {}
	if (!printerMaterials) {
		return materialNamePerTech
	}
	const materialTechAndName = [
		...new Set(
			printerMaterials?.map(item => [item.printerTechnology, item.name.trim()])
		)
	]
	for (let i in materialTechAndName) {
		materialNamePerTech[materialTechAndName[i][0]] = []
	}
	for (let i in materialTechAndName) {
		materialNamePerTech[materialTechAndName[i][0]].push(
			materialTechAndName[i][1]
		)
	}
	for (let i in materialTechAndName) {
		materialNamePerTech[materialTechAndName[i][0]] = [
			...new Set(materialNamePerTech[materialTechAndName[i][0]])
		]
	}
	return materialNamePerTech
}

export const onFullTrayAssumptionChanged = fullTrayAssumptionOn => {
	return async dispatch => {
		try {
			const { data } = await updateUserCustomizeFullTrayAssumption(
				fullTrayAssumptionOn
			)

			if (data) {
				dispatch({
					type: USER_CUSTOM_SETTINGS_UPDATED,
					payload: { userCustomizationSettings: data.userSettings }
				})
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: 'fullTrayAssumption updated failed'
					}
				})
			}
		} catch (error) {
			console.log('fullTrayAssumption failed', error.message)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.validationMessage || 'fullTrayAssumption updated failed'
				}
			})
		}
	}
}

export const onSupplyChainCostChanged = supplyChainOn => {
	return async dispatch => {
		try {
			const { data } = await updateUserCustomizeSupplChainCosts(supplyChainOn)

			if (data) {
				dispatch({
					type: USER_CUSTOM_SETTINGS_UPDATED,
					payload: { userCustomizationSettings: data.userSettings }
				})
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: 'includeSupplyChainCost updated failed'
					}
				})
			}
		} catch (error) {
			console.log('Set includeSupplyChainCost failed', error.message)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.validationMessage || 'includeSupplyChainCost updated failed'
				}
			})
		}
	}
}

export const onFiltersChanged = (filters, userFilters, toDefault) => {
	return async dispatch => {
		try {
			const filtersToSend = prepareUserFiltersToSend(
				filters,
				userFilters,
				toDefault
			)
			const newUserFilters = (await updateUserCustomizeFilters(filtersToSend))
				.data._userFilters

			dispatch({
				type: USER_FILTERS_CHANGE,
				payload: { newUserFilters }
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('USER_FILTER_UPDATE_SUCCESS')
				}
			})
		} catch (error) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.validationMessage || getString('USER_FILTER_UPDATE_FAILED')
				}
			})
		}
	}
}

export const onInhouseTogleClick = history => {
	return dispatch => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: getString('CONFIGURATION_INHOUSE_ALERT_TEXT'),
				onConfirm: () => {
					dispatch({ type: ALERT_POPUP_CANCELED })
					history.push(customizeUser())
				},
				confirmText: getString('GO_TO_SETTINGS'),
				alertClass: 'upload-project-filters-inhouse-alert'
			}
		})
	}
}

export const onSettingsCloseButtonClick = callbackURL => {
	return () => {
		window.location.replace(callbackURL)
	}
}

export const onRefreshJWT = (token = '', provider) => {
	return async dispatch => {
		const tokenData = await refreshJWT(token, provider)
		if (tokenData) {
			const userId = tokenData.userId
			const userProfileData = await getUserProfileData(userId)
			const email = userProfileData?.data?.generalData?.userDetails?.email
			loginUserSuccess(dispatch, userProfileData, email)
		}
	}
}

export const checkUserSubscriptionAlert = (
	isAdmin,
	isLightUser,
	subscriptionExpired,
	partsCreditExpired,
	userOnTrial,
	userEmail,
	isOrganizationOwnerExist,
	features
) => {
	return async dispatch => {
		let { isLicense, initialRun } = store.getState().LicenseReducer
		let { userDetails } = store.getState().user
		const isNotSpecifiedUser = isLightUser && !userDetails.name
		const expiredParts = isNumber(partsCreditExpired)
			? partsCreditExpired === 0
			: partsCreditExpired
		const isLicenseManagerOn = Feature.isFeatureOn(
			FeatureComponentId.LICENSE_MANAGER,
			features
		)
		const isExpiredOrTrial =
			subscriptionExpired || (expiredParts && userOnTrial)
		const isUserExpired = isAdmin === false && isExpiredOrTrial
		const shouldShowPopup = isBoolean(isExpiredOrTrial)

		dispatch({
			type: CASTOR_BANNER_CANCEL
		})

		if (
			isNotSpecifiedUser ||
			(!isOrganizationOwnerExist && isOrganizationOwnerExist !== 0)
		) {
			isOrganizationOwnerExist = 1
		}

		if (
			!initialRun &&
			shouldShowPopup &&
			(isUserExpired || isLightUser || !isOrganizationOwnerExist)
		) {
			let bannerButtonText = getString('UPDATE_NOW') + '!'
			let bannerText =
				isLicenseManagerOn && isLicense
					? getString('BANNER_LICENSE_PARTS_END_MSG')
					: getString('BANNER_PARTS_END_MSG')
			let bannerType = CastorBannerType.UPGRADE_NOW

			if (!isOrganizationOwnerExist) {
				bannerText = getString('BANNER_ORGANIZATION_OWNER_NOT_EXIST_MSG')
			}

			if (isOrganizationOwnerExist) {
				if (isLightUser && !isUserExpired) {
					bannerButtonText = getString('CONNECT_CASTOR')
					bannerText = getString('UPLOAD_PAGE_MSG_LIGHT_USER')
					bannerType = CastorBannerType.CONTACT_US
				}

				if (subscriptionExpired && !isLightUser) {
					bannerText = getString('SUBSCRUPTION_LOGIN_MSG')
				}
			}
			if (!!bannerText && !!bannerButtonText) {
				dispatch({
					type: SHOW_CASTOR_BANNER,
					payload: {
						userEmail,
						buttonText: bannerButtonText,
						text: bannerText,
						type: bannerType
					}
				})
			}
		}
	}
}

export const resetAllApplicationData = () => {
	if (caches) {
		caches.keys().then(names => {
			names &&
				names.forEach(name => {
					caches.delete(name)
				})
		})
	}

	removeAllLocalStorage()
}
