import {
	MaterialTypeEnum,
	SurfaceFinishMethod
} from 'Services/models/IMaterial'

export const materialTypes = [MaterialTypeEnum.plastic, MaterialTypeEnum.metal]
export const surfaceFinishMethods = [
	SurfaceFinishMethod.rz,
	SurfaceFinishMethod.ra
]
export const density = { min: 0, max: 10 }
export const yieldStrengthMPa = { min: 0, max: 5000 }
export const ultimateTensileStrength = { min: 0, max: 100000 }
export const maximumServiceTemperature = { min: 0, max: 4000 }
export const thermalConductivity = { min: 0, max: 4000 }
export const surfaceFinish = { min: 0 }
export const youngsModulus = { min: 0, max: 30000 }
export const co2perKgMaterial = { min: 0, max: 20 }
export const percentElongationAtBreak = { min: 0, max: 1000 }
export const Cast = { min: 0, max: 15 }
export const CNC = { min: 0, max: 15 }
export const Mold = { min: 0, max: 15 }
