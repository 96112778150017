import { Folder, People } from '@material-ui/icons'
import CheckBox from '@material-ui/icons/CheckBox'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import PrintIcon from '@material-ui/icons/Print'
import SettingsIcon from '@material-ui/icons/Settings'
import TextFieldsIcon from '@material-ui/icons/TextFields'
import TimerIcon from '@material-ui/icons/Timer'
import TuneIcon from '@material-ui/icons/Tune'
import MaterialIcon from '@material-ui/icons/Whatshot'

import {
	EDIT_PART_ADMIN_ROUTE,
	PROJECT_PARTS_ADMIN_ROUTE
} from '../../../../Services/Constants/RoutesConstants'
import { FeatureComponentId } from '../../../../Services/models/Features'
import {
	adminClusterPartsRoute,
	adminFeatureToggleRoute,
	adminLicenseCreatorRoute,
	adminLicenseScreenRoute,
	adminMaterialPrinterPairsNewFormRoute,
	adminMaterialPrinterPairsRoute,
	adminMaterialsEditFormRoute,
	adminMaterialsNewFormRoute,
	adminMaterialsRoute,
	adminParametersManagerRoute,
	adminPrinterMaterialsEditFormRoute,
	adminPrinterMaterialsNewFormRoute,
	adminPrinterMaterialsRoute,
	adminPrinterNamesEditFormRoute,
	adminPrinterNamesRoute,
	adminPrintersEditFormRoute,
	adminPrintersNewFormRoute,
	adminPrintersRoute,
	adminPrintersTechnologyEditFormRoute,
	adminPrintersTechnologyRoute,
	adminProjectInfoRoute,
	adminProjectsRoute,
	adminSettingsRoute,
	adminStringsRoute,
	adminUserInfoRoute,
	adminUsersRoute,
	adminUserSubscriptionEditInfoRoute,
	adminVersionPageRoute
} from '../../../../Services/routeFuncs'
import AdminParts from '../../../admin/AdminHome/AdminParts/AdminParts'
import AdminProjects from '../../../admin/AdminHome/AdminProjects/AdminProjects'
import { Icon3dParametersAdmin } from '../../../Components/CastorIcons/icon3DParamters'
import { IconVersionPageAdmin } from '../../../Components/CastorIcons/iconVersionPageAdmin'
import AdminEditPartForm from '../AdminEditPartForm/AdminEditPartForm'
import AdminFeatureToggle from '../AdminFeatureToggle/AdminFeatureToggle'
import AdminLicenseCreator from '../AdminLicenseCreator'
import AdminLicenseScreen from '../AdminLicenseScreen'
import AdminMaterialPrinterPairs from '../AdminMaterialPrinterPairs/AdminMaterialPrinterPairs'
import NewAdminMaterialPrinterPair from '../AdminMaterialPrinterPairs/NewAdminMaterialPrinterPair'
import AdminMaterials from '../AdminMaterials/AdminMaterials'
import EditAdminMaterial from '../AdminMaterials/EditAdminMaterial'
import NewAdminMaterial from '../AdminMaterials/NewAdminMaterial'
import AdminPrinterMaterials from '../AdminPrinterMaterials/AdminPrinterMaterials'
import EditAdminPrinterMaterial from '../AdminPrinterMaterials/EditAdminPrinterMaterial'
import NewAdminPrinterMaterial from '../AdminPrinterMaterials/NewAdminPrinterMaterial'
import AdminPrinterNames from '../AdminPrinterNames/AdminPrinterNames'
import EditAdminPrinterName from '../AdminPrinterNames/EditAdminPrinterName'
import AdminPrinters from '../AdminPrinters/AdminPrinters'
import EditAdminPrinter from '../AdminPrinters/EditAdminPrinter'
import NewAdminPrinter from '../AdminPrinters/NewAdminPrinter'
import AdminPrintingTechnology from '../AdminPrintingTechnology'
import EditTechParameters from '../AdminPrintingTechnology/EditTechParameters'
import ClusterParts from '../AdminProjects/AdminClusters/ClusterParts'
import AdminProjectInfo from '../AdminProjects/AdminProjectInfo'
import AdminSettings from '../AdminSettings'
import AdminStrings from '../AdminStrings'
import AdminUserInfo from '../AdminUsers/AdminUserInfo'
import AdminUserSubscriptionEdit from '../AdminUsers/AdminUserInfo/userSubscriptionEdit'
import AdminUsers from '../AdminUsers/AdminUsers'
import AdminVersionPage from '../AdminVersionPage'
import ParametersManager from '../ParametersManager'
import { UserRole } from 'Scenes/Home/UserRole.enum'

const AdminRoutes = [
	{
		path: adminProjectsRoute(null, null) + '/:owner/:page',
		pathname: adminProjectsRoute(null, null) + '/:owner/:page',
		name: 'Projects',
		icon: Folder,
		component: AdminProjects,
		isInHome: true,
		sidebar: false,
		exact: true
	},
	{
		path: adminProjectsRoute(null, null),
		pathname: adminProjectsRoute(null, null),
		name: 'Projects',
		icon: Folder,
		component: AdminProjects,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_PROJECTS],
		permissions: [
			UserRole.ADMIN,
			UserRole.SUPER_ADMIN,
			UserRole.USER_MANAGER,
			UserRole.DB_MANAGER,
			UserRole.ORGANIZATIONS_ADMIN
		]
	},
	{
		path: adminProjectsRoute(null, null) + '/:page',
		pathname: adminProjectsRoute(null, null) + '/:page',
		name: 'Projects',
		icon: Folder,
		component: AdminProjects,
		isInHome: true,
		sidebar: false,
		exact: true,
		childRoute: true,
		features: [FeatureComponentId.ADMIN_MENU_PROJECTS],
		permissions: [
			UserRole.ADMIN,
			UserRole.SUPER_ADMIN,
			UserRole.USER_MANAGER,
			UserRole.DB_MANAGER,
			UserRole.ORGANIZATIONS_ADMIN
		]
	},
	{
		path: adminProjectInfoRoute() + '/:id',
		pathname: adminProjectInfoRoute() + '/:id',
		name: 'Project Info',
		component: AdminProjectInfo,
		isInHome: true,
		sidebar: false,
		exact: true,
		childRoute: true,
		features: [FeatureComponentId.ADMIN_MENU_PROJECTS],
		permissions: [
			UserRole.ADMIN,
			UserRole.SUPER_ADMIN,
			UserRole.USER_MANAGER,
			UserRole.DB_MANAGER,
			UserRole.ORGANIZATIONS_ADMIN
		]
	},
	{
		path: adminUsersRoute() + '/:page',
		pathname: adminUsersRoute() + '/:page',
		name: 'Users',
		icon: People,
		component: AdminUsers,
		isInHome: true,
		sidebar: false,
		exact: true,
		childRoute: true,
		features: [FeatureComponentId.ADMIN_MENU_USERS],
		permissions: [UserRole.ADMIN, UserRole.SUPER_ADMIN, UserRole.USER_MANAGER]
	},
	{
		path: adminUsersRoute(),
		pathname: adminUsersRoute(),
		name: 'Users',
		icon: People,
		component: AdminUsers,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_USERS],
		permissions: [UserRole.ADMIN, UserRole.SUPER_ADMIN, UserRole.USER_MANAGER]
	},
	{
		path: adminUserInfoRoute() + '/:userId',
		pathname: adminUserInfoRoute() + '/:userId',
		name: 'Users',
		component: AdminUserInfo,
		isInHome: true,
		sidebar: false,
		exact: true,
		childRoute: true,
		features: [FeatureComponentId.ADMIN_MENU_USERS],
		permissions: [UserRole.ADMIN, UserRole.SUPER_ADMIN, UserRole.USER_MANAGER]
	},
	{
		path: adminUserSubscriptionEditInfoRoute() + '/:user',
		pathname: adminUserSubscriptionEditInfoRoute() + '/:user',
		name: 'Users',
		component: AdminUserSubscriptionEdit,
		isInHome: true,
		sidebar: false,
		exact: true,
		childRoute: true,
		features: [FeatureComponentId.ADMIN_MENU_USERS],
		permissions: [UserRole.ADMIN, UserRole.SUPER_ADMIN, UserRole.USER_MANAGER]
	},
	{
		path: adminPrintersNewFormRoute(),
		pathname: adminPrintersNewFormRoute(),
		name: 'New Printer',
		component: NewAdminPrinter,
		isInHome: true,
		exact: true,
		childRoute: true,
		features: [FeatureComponentId.ADMIN_MENU_PRINTERS],
		permissions: [
			UserRole.SUPER_ADMIN,
			UserRole.ADMIN,
			UserRole.DB_MANAGER,
			UserRole.ORGANIZATIONS_ADMIN,
			UserRole.USER_MANAGER
		]
	},
	{
		path: adminPrintersRoute() + '/:page',
		pathname: adminPrintersRoute() + '/:page',
		name: 'Printers',
		icon: PrintIcon,
		component: AdminPrinters,
		isInHome: true,
		sidebar: false,
		exact: true,
		childRoute: true,
		features: [FeatureComponentId.ADMIN_MENU_PRINTERS],
		permissions: [
			UserRole.SUPER_ADMIN,
			UserRole.ADMIN,
			UserRole.DB_MANAGER,
			UserRole.ORGANIZATIONS_ADMIN,
			UserRole.USER_MANAGER
		]
	},
	{
		path: adminPrintersRoute(),
		pathname: adminPrintersRoute(),
		name: 'Printers',
		icon: PrintIcon,
		component: AdminPrinters,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_PRINTERS],
		permissions: [
			UserRole.SUPER_ADMIN,
			UserRole.ADMIN,
			UserRole.DB_MANAGER,
			UserRole.ORGANIZATIONS_ADMIN,
			UserRole.USER_MANAGER
		]
	},
	{
		path: adminPrintersEditFormRoute() + '/:id',
		pathname: adminPrintersEditFormRoute() + '/:id',
		name: 'Edit Printer',
		icon: PrintIcon,
		component: EditAdminPrinter,
		isInHome: true,
		exact: true
	},
	{
		path: adminMaterialsNewFormRoute(),
		pathname: adminMaterialsNewFormRoute(),
		name: 'New Material',
		component: NewAdminMaterial,
		isInHome: true,
		exact: true
	},
	{
		path: adminMaterialsRoute() + '/:page',
		pathname: adminMaterialsRoute() + '/:page',
		name: 'Materials',
		icon: MaterialIcon,
		component: AdminMaterials,
		isInHome: true,
		sidebar: false,
		exact: true
	},
	{
		path: adminMaterialsRoute(),
		pathname: adminMaterialsRoute(),
		name: 'Materials',
		icon: MaterialIcon,
		component: AdminMaterials,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_MATERIALS],
		permissions: [UserRole.SUPER_ADMIN, UserRole.USER_MANAGER]
	},
	{
		path: adminMaterialsEditFormRoute() + '/:id',
		pathname: adminMaterialsEditFormRoute() + '/:id',
		name: 'Edit Material',
		icon: MaterialIcon,
		component: EditAdminMaterial,
		isInHome: true,
		exact: true
	},

	{
		path: adminPrinterMaterialsNewFormRoute(),
		pathname: adminPrinterMaterialsNewFormRoute(),
		name: 'New Printer Material',
		component: NewAdminPrinterMaterial,
		isInHome: true,
		exact: true
	},
	{
		path: adminPrinterMaterialsRoute() + '/:page',
		pathname: adminPrinterMaterialsRoute() + '/:page',
		name: 'Printer Materials',
		icon: PrintIcon,
		component: AdminPrinterMaterials,
		isInHome: true,
		sidebar: false,
		exact: true
	},
	{
		path: adminPrinterMaterialsRoute(),
		pathname: adminPrinterMaterialsRoute(),
		name: 'Printer Materials',
		icon: PrintIcon,
		component: AdminPrinterMaterials,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_PRINTER_MATERIALS],
		permissions: [
			UserRole.SUPER_ADMIN,
			UserRole.ADMIN,
			UserRole.USER_MANAGER,
			UserRole.ORGANIZATIONS_ADMIN
		]
	},
	{
		path: adminPrinterMaterialsEditFormRoute() + '/:id',
		pathname: adminPrinterMaterialsEditFormRoute() + '/:id',
		name: 'Edit Printer Material',
		icon: PrintIcon,
		component: EditAdminPrinterMaterial,
		isInHome: true,
		exact: true,
		childRoute: true,
		features: [FeatureComponentId.ADMIN_MENU_PRINTER_MATERIALS],
		permissions: [
			UserRole.SUPER_ADMIN,
			UserRole.ADMIN,
			UserRole.USER_MANAGER,
			UserRole.ORGANIZATIONS_ADMIN
		]
	},

	{
		path: adminPrinterNamesRoute() + '/:page',
		pathname: adminPrinterNamesRoute() + '/:page',
		name: 'Printer Names',
		icon: PrintIcon,
		component: AdminPrinterNames,
		isInHome: true,
		sidebar: false,
		exact: true,
		childRoute: true,
		features: [FeatureComponentId.ADMIN_MENU_PRINTERS_NAMES],
		permissions: [UserRole.SUPER_ADMIN, UserRole.USER_MANAGER]
	},
	{
		path: adminPrinterNamesRoute(),
		pathname: adminPrinterNamesRoute(),
		name: 'Printer Names',
		icon: PrintIcon,
		component: AdminPrinterNames,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_PRINTERS_NAMES],
		permissions: [UserRole.SUPER_ADMIN, UserRole.USER_MANAGER]
	},
	{
		path: adminPrinterNamesEditFormRoute() + '/:id',
		pathname: adminPrinterNamesEditFormRoute() + '/:id',
		name: 'Edit PrinterName',
		icon: PrintIcon,
		component: EditAdminPrinterName,
		isInHome: true,
		exact: true,
		childRoute: true,
		features: [FeatureComponentId.ADMIN_MENU_PRINTERS_NAMES],
		permissions: [UserRole.SUPER_ADMIN, UserRole.USER_MANAGER]
	},

	{
		path: adminMaterialPrinterPairsNewFormRoute(),
		pathname: adminMaterialPrinterPairsNewFormRoute(),
		name: 'New Material Printer Pair',
		component: NewAdminMaterialPrinterPair,
		isInHome: true,
		exact: true
	},
	{
		path: adminMaterialPrinterPairsRoute() + '/:page',
		pathname: adminMaterialPrinterPairsRoute() + '/:page',
		name: 'Build Rates',
		icon: TimerIcon,
		component: AdminMaterialPrinterPairs,
		isInHome: true,
		sidebar: false,
		exact: true
	},
	{
		path: adminMaterialPrinterPairsRoute(),
		pathname: adminMaterialPrinterPairsRoute(),
		name: 'Build Rates',
		icon: TimerIcon,
		component: AdminMaterialPrinterPairs,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_BUILD_RATE],
		permissions: [UserRole.SUPER_ADMIN, UserRole.USER_MANAGER]
	},
	{
		path: adminSettingsRoute(),
		pathname: adminSettingsRoute(),
		name: 'Settings',
		icon: SettingsIcon,
		component: AdminSettings,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_SETTINGS],
		permissions: [
			UserRole.SUPER_ADMIN,
			UserRole.ADMIN_SETTINGS,
			UserRole.USER_MANAGER
		]
	},
	{
		path: PROJECT_PARTS_ADMIN_ROUTE + '/:projectId',
		pathname: PROJECT_PARTS_ADMIN_ROUTE + '/:projectId',
		name: 'Parts',
		icon: Folder,
		component: AdminParts,
		isInHome: true,
		sidebar: false,
		childRoute: true,
		permissions: [
			UserRole.ADMIN,
			UserRole.SUPER_ADMIN,
			UserRole.USER_MANAGER,
			UserRole.DB_MANAGER,
			UserRole.ORGANIZATIONS_ADMIN
		]
	},
	{
		path: `${adminClusterPartsRoute()}/:projectId/:clusterId`,
		pathname: `${adminClusterPartsRoute()}/:projectId/:clusterId`,
		name: 'ClusterParts',
		icon: Folder,
		component: ClusterParts,
		isInHome: true,
		sidebar: false,
		exact: true
	},
	{
		path: EDIT_PART_ADMIN_ROUTE + '/:partId',
		pathname: EDIT_PART_ADMIN_ROUTE + '/:partId',
		name: 'Edit part',
		icon: Folder,
		component: AdminEditPartForm,
		isInHome: true,
		sidebar: false
	},
	{
		path: adminStringsRoute(),
		pathname: adminStringsRoute(),
		name: 'Strings',
		icon: TextFieldsIcon,
		component: AdminStrings,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_STRINGS],
		permissions: [UserRole.SUPER_ADMIN, UserRole.ADMIN_SETTINGS]
	},
	{
		path: adminParametersManagerRoute(),
		pathname: adminParametersManagerRoute(),
		name: 'Costing Parameters',
		icon: TuneIcon,
		component: ParametersManager,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_COST_PARAMETERS],
		permissions: [
			UserRole.SUPER_ADMIN,
			UserRole.DB_MANAGER,
			UserRole.ORGANIZATIONS_ADMIN
		]
	},
	{
		path: adminPrintersTechnologyRoute(),
		pathname: adminPrintersTechnologyRoute(),
		name: '3D Tech Parameters',
		icon: Icon3dParametersAdmin,
		component: AdminPrintingTechnology,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_3D_TECH_PARAMETERS],
		permissions: [
			UserRole.SUPER_ADMIN,
			UserRole.DB_MANAGER,
			UserRole.ORGANIZATIONS_ADMIN
		]
	},
	{
		path: adminFeatureToggleRoute(),
		pathname: adminFeatureToggleRoute(),
		name: 'Feature Toggle',
		icon: CheckBox,
		component: AdminFeatureToggle,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_FEATURE_TOGGLE],
		permissions: [UserRole.SUPER_ADMIN]
	},
	{
		path: adminLicenseCreatorRoute(),
		pathname: adminLicenseCreatorRoute(),
		name: 'License Creator',
		icon: LockOpenIcon,
		component: AdminLicenseCreator,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_LICENSE_CREATOR],
		permissions: [UserRole.SUPER_ADMIN]
	},
	{
		path: adminLicenseScreenRoute(),
		pathname: adminLicenseScreenRoute(),
		name: 'License Screen',
		icon: LockIcon,
		component: AdminLicenseScreen,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_LICENSE_SCREEN],
		permissions: [
			UserRole.SUPER_ADMIN,
			UserRole.DB_MANAGER,
			UserRole.ORGANIZATIONS_ADMIN
		]
	},
	{
		path: adminPrintersTechnologyEditFormRoute() + '/:printerTechName',
		pathname: adminPrintersTechnologyEditFormRoute() + '/:printerTechName',
		name: 'Edit Printer Technology Parameters',
		icon: PrintIcon,
		component: EditTechParameters,
		isInHome: true,
		exact: true
	},
	{
		path: adminVersionPageRoute(),
		pathname: adminVersionPageRoute(),
		name: 'Service Health',
		icon: IconVersionPageAdmin,
		component: AdminVersionPage,
		isInHome: true,
		sidebar: true,
		exact: true,
		features: [FeatureComponentId.ADMIN_MENU_SERVICE_HEALTH],
		permissions: [UserRole.SUPER_ADMIN]
	}
]

export default AdminRoutes
