import React, { FC, memo } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'

import './FeatureSection.scss'

interface IProps {
  sectionHeader: string | JSX.Element
  sectionSubHeader: string | JSX.Element
  sectionContent: string | JSX.Element
  sectionAction: JSX.Element
}

const FeatureSection: FC<IProps> = ({
  sectionHeader,
  sectionSubHeader,
  sectionContent,
  sectionAction
}) => {
  return (
    <Flexbox alignItems="flex-start" className="feature-section">
      <Flexbox flexDirection="column" alignItems="flex-start">
        <div className="feature-section--header">{sectionHeader}</div>
        <div className="feature-section--subheader feature-section--opac-text">
          {sectionSubHeader}
        </div>
        {sectionAction}
      </Flexbox>
      <div className="feature-section--opac-text feature-section--content">
        {sectionContent}
      </div>
    </Flexbox>
  )
}

export default memo(FeatureSection)
