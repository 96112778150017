import { AxiosPromise } from 'axios'

import { get, post, put, writeExcelFile } from '../Network'

export const getStrings = (
	stringHash: string | null,
	locale: string = '',
	userEmail: string = ''
) =>
	put(
		`/locale`,
		{ userEmail, stringHash, locale },
		{
			throttle: { time: 2000, name: 'locale' }
		}
	)

export const changeUserLocale = (locale: string) => put(`/locale/${locale}`)
export const changeUserCurrency = (currency: string) =>
	put(`/locale/currency/${currency}`)

export const exportStrings = (): Promise<Blob> => {
	let params = {
		responseType: 'text',
		headers: { 'Content-Type': 'application/octet-stream' }
	}

	return new Promise((resolve, reject) => {
		get(`/strings/export`, params)
			?.then((res: any) => {
				resolve(writeExcelFile(res))
			})
			?.catch((e: any) => {
				console.log(e)
				reject(e)
			})
	})
}

export const importStrings = (
	fileSelected: File
): AxiosPromise<any> | undefined => {
	const formData = new FormData()
	formData.append('file', fileSelected)
	return post('/strings/import', formData)
}
