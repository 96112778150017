import React, { FC, useState } from 'react'

import TextField from '@material-ui/core/TextField'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import cx from 'classnames'

import { ENTER_KEY_CODE } from 'Services/Constants'
import { SEARCH } from 'Services/Strings'

import './SearchBar.scss'

type IProps = {
	onChange: (e: string) => void
	value: string
	className?: string
	searchOnClick?: boolean
	autoComplete?: boolean
}

const SearchBar: FC<IProps> = ({
	onChange,
	value,
	className,
	searchOnClick,
	autoComplete
}) => {
	const [searchInput, setSearchInput] = useState('')

	const onSearchIconClick = () => {
		if (!searchOnClick) return
		onChange(searchInput)
	}

	const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (!searchOnClick) return

		if (e.keyCode === ENTER_KEY_CODE) {
			onChange(searchInput)
		}
		return
	}

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!searchOnClick) {
			return onChange(e.target.value)
		}
		setSearchInput(e.target.value)
	}

	const onClearInput = (e: any) => {
		setSearchInput('')
		onChange('')
	}

	return (
		<div className={cx('search-bar', className)}>
			<TextField
				InputProps={{
					startAdornment: (
						<SearchIcon
							onClick={onSearchIconClick}
							className={cx('search-icon', { clickable: searchOnClick })}
						/>
					),
					endAdornment: (
						<ClearIcon
							onClick={onClearInput}
							className={cx('clear-input', {
								hidden: searchOnClick ? !searchInput : !value
							})}
						/>
					)
				}}
				id="standard-search"
				data-qa="data-qa-sidebar-search-filed"
				type="search"
				value={searchOnClick ? searchInput : value}
				placeholder={SEARCH}
				margin="none"
				onKeyUp={onKeyUp}
				onChange={onInputChange}
				autoComplete={autoComplete ? 'on' : 'off'}
			/>
		</div>
	)
}

export default SearchBar
