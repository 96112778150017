import React, { memo, FC } from 'react'
import { forEach, toNumber, map, isObject } from 'lodash'

import './DataTable.scss'

const X = 'X'
const Z = 'Z'
const Y = 'Y'
const XY = 'XY'
const YZ = 'YZ'
const emptyName = ''

interface Props {
  data: any
  className?: string
}

interface singleDeviation {
  val?: number | null
  stdev?: number | null
}

interface Deviation {
  X: singleDeviation
  Y: singleDeviation
  Z: singleDeviation
}

interface ModifiedDeviation {
  name: string
  value: singleDeviation
}

const checkSingleDeviationEquals = (
  el: singleDeviation,
  compareEl: singleDeviation
) => {
  const isValEqual = toNumber(el?.val) === toNumber(compareEl?.val)
  const isSTDEVEqual = toNumber(el?.stdev) === toNumber(compareEl?.stdev)

  return isValEqual && isSTDEVEqual
}

export const createSingleDeviation = (text: string, data: singleDeviation) => {
  const createStd = data?.stdev ? (
    <span key={text + data?.val} className={'data-table-opacity-field'}> ± {data?.stdev}</span>
  ) : (
    <div />
  )

  return (
    <div key={text + data?.val} >
      {text} {data?.val}
      {createStd}
    </div>
  )
}

export const createDeviationText = (dataOrDeviation: Deviation) => {
  let deviationText: ModifiedDeviation[] = []

  // show nothing if the value is not object (for old values)
  if (!isObject(dataOrDeviation)) return deviationText

  const xyIsEqual = checkSingleDeviationEquals(
    dataOrDeviation.X,
    dataOrDeviation.Y
  )
  const yZIsEqual = checkSingleDeviationEquals(
    dataOrDeviation.Y,
    dataOrDeviation.Z
  )
  const xyzIsEqual = xyIsEqual
    ? checkSingleDeviationEquals(dataOrDeviation.X, dataOrDeviation.Z)
    : false
  const nonIsEqual = !xyIsEqual && !xyzIsEqual && !yZIsEqual

  forEach(dataOrDeviation, (value: singleDeviation, key: string) => {
    // if X === Y but XY !== Z show XY
    if (!xyzIsEqual && xyIsEqual && key === X) {
      deviationText.push({ name: XY + ':', value: value })
    }

    // if Y === Z but YZ !== X, show only YZ
    if (!xyzIsEqual && yZIsEqual && key === Y) {
      deviationText.push({ name: YZ + ':', value: value })
    }

    // if X === Y but Y !== Z, show only Z
    if (!xyzIsEqual && xyIsEqual && !yZIsEqual && key === Z) {
      deviationText.push({ name: key + ':', value: value })
    }

    // if X !== Y but Y === Z, show only X
    if (!xyzIsEqual && !xyIsEqual && yZIsEqual && key === X) {
      deviationText.push({ name: key + ':', value: value })
    }

    // if X === Y === Z, show all
    if (xyzIsEqual && key === X) {
      deviationText.push({ name: emptyName, value: value })
    }

    // if X !== Y !== Z, show each
    if (nonIsEqual && ( key === Y || key === X || key === Z)) {
      deviationText.push({ name: key + ':', value: value })
    }
  })

  return deviationText
}

const DataTableTextWithXYZ: FC<Props> = ({ data: { dataOrDeviation } }) => {
  const deviationText = createDeviationText(dataOrDeviation)

  return (
    <div className="deviation-block">
      {map(deviationText, (deviation: ModifiedDeviation) =>
        createSingleDeviation(deviation.name, deviation.value)
      )}
    </div>
  )
}

export default memo(DataTableTextWithXYZ)
