import React, { FC, memo } from 'react'
import _ from 'lodash'
import WithFeatureToggleHOC from '../../../Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from '../../../Services/models/Features'
import { Currency, CurrencyName } from '../../../Services/models/IUser'
import { getCurrencyFromLocalStorage } from '../../../Services/LocalStorageService'
import './CurrencyPicker.scss'

import SelectWithIcons, {
  SelectWithIconsMenuItem
} from '../SelectWithIcons/SelectWithIcons'

// export enum countries {
//   US = 'US',
//   JP = 'JP'
// }

// const countriesCurrencies: Record<countries, string> = {
//   [countries.US]: Currency.en,
//   [countries.JP]: Currency.ja
// }

interface IProps {
  onCurrencyChange: Function
}

const CurrencyMenuItems: SelectWithIconsMenuItem[] = [
  {
    iconName: 'USDCurrency',
    text: CurrencyName.en,
    value: Currency.en
  },
  {
    iconName: 'JPYCurrency',
    text: CurrencyName.ja,
    value: Currency.ja
  }
]

const CurrencyPicker: FC<IProps> = ({ onCurrencyChange }) => {
  const onCurrencySelect = (selectedValue: string) => {
    onCurrencyChange(selectedValue)
  }

  return (
    <SelectWithIcons
      menuItems={CurrencyMenuItems}
      initialValue={getCurrencyFromLocalStorage()}
      onChange={onCurrencySelect}
      showTextOnSelect={false}
      selectRootClassName="currency-picker--select--root"
    />
  )
}

export default WithFeatureToggleHOC(
  memo(CurrencyPicker),
  FeatureComponentId.CURRENCY
)
