import { FC, memo } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import cx from 'classnames'

import * as CastorBannerAction from './CastorBannerAction'
import LockedPartsButtons from '../CastorLockedParts/LockedPartsButtons'
import { CastorBannerInitialState } from './CastorBannerReducer'
import { CastorBannerType } from './CastorBannerType.enum'
import { UPLOAD_ROUTE } from 'Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { homePathRoute } from 'Services/routeFuncs'

import logoImage from '../../../assets/img/castorLogo.png'

import './CastorBanner.scss'

const LockedPartsButtonsTsx: any = LockedPartsButtons

interface IReduxStore {
	CastorBannerReducer: CastorBannerInitialState
	user: any
	GlobalReducer: any
}

interface IReduxProps {
	text: string
	buttonText?: string
	buttonOnClick: Function
	showButton?: boolean
	disabledButton?: boolean
	buttonClass: string
	isOnPrem?: boolean
	type?: CastorBannerType
	contactUsEmail?: string
	userName?: string
}

const CastorBanner: FC<IReduxProps & CastorBannerInitialState> = ({
	text,
	buttonOnClick,
	show,
	showButton = true,
	buttonText,
	buttonClass,
	loadingCalculation,
	disabledButton,
	userEmail,
	contactUsEmail,
	isOnPrem,
	type,
	userName
}) => {
	const showContactUs = Feature.isFeatureOn(
		FeatureComponentId.SHOW_CONTACT_US_BANNER
	)
	const isContactUs = type === CastorBannerType.CONTACT_US
	const isUpgrade = type === CastorBannerType.UPGRADE_NOW
	const isQueuedToRecalculate = type === CastorBannerType.QUEUED_TO_RECALCULATE

	if (show && isContactUs && !showContactUs) {
		return <></>
	}

	if (show) {
		const typeBannerClass =
			isUpgrade || isQueuedToRecalculate
				? '--upgrade-now'
				: isContactUs
				? '--contact-us'
				: ''
		return (
			<div
				className={cx(
					'banner',
					typeBannerClass ? `banner${typeBannerClass}` : typeBannerClass
				)}
			>
				<Link to={homePathRoute(UPLOAD_ROUTE)}>
					<img className="banner--logo" src={logoImage} alt="logo" />
				</Link>
				<div
					className={cx(
						'text-container',
						typeBannerClass
							? `text-container${typeBannerClass}`
							: typeBannerClass
					)}
				>
					{text}
				</div>
				{!isOnPrem && !isQueuedToRecalculate ? (
					<LockedPartsButtonsTsx
						componentId={3}
						loading={loadingCalculation}
						disabled={disabledButton}
						className={cx(
							'button' || buttonClass,
							typeBannerClass ? `button${typeBannerClass}` : typeBannerClass
						)}
						color="secondary"
						buttonText={buttonText}
						onClick={() =>
							buttonOnClick(userEmail, contactUsEmail, type, userName)
						}
						horizontal
						userEmail={userEmail}
					/>
				) : (
					<div />
				)}
			</div>
		)
	} else {
		return <div />
	}
}

const mapStateToProps = ({
	CastorBannerReducer,
	GlobalReducer: { isOnPrem },
	user: {
		userDetails: { email, name },
		contactUsEmail
	}
}: IReduxStore) => {
	return {
		...CastorBannerReducer,
		isOnPrem,
		userEmail: CastorBannerReducer.userEmail || email,
		contactUsEmail,
		userName: name
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CastorBannerAction }, dispatch)

export default memo(connect(mapStateToProps, mapDispatchToProps)(CastorBanner))
