import { FC, useMemo } from 'react'

import cx from 'classnames'

import PartRow from './PartRow'
import PartsListHeaders from './PartsListHeaders'
import CastorLockedPartsListView from 'Scenes/Components/CastorLockedParts/CastorLockedPartsListView'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IBestMatchData } from 'Services/models/IBestMatch'
import { Part } from 'Services/models/IPart'

import './PartsListView.scss'

type IProps = {
	parts: Part[]
	leadingConfigurationData: IBestMatchData[]
	lockedParts: boolean
	amountOfLockedParts: number
	roles: number[]
	contactUsEmail: string
	isBundle: boolean
}

const PartsListView: FC<IProps> = ({
	parts,
	leadingConfigurationData,
	lockedParts,
	amountOfLockedParts,
	roles,
	contactUsEmail,
	isBundle
}) => {
	const isLightUser = useMemo(() => roles.includes(UserRole.LIGHT), [roles])
	const isGenerativeDesignOn = Feature.isFeatureOn(
		FeatureComponentId.GENERATIVE_DESIGN
	)
	const isQuickCADUpload = Feature.isFeatureOn(
		FeatureComponentId.QUICK_CAD_UPLOAD
	)

	if (parts?.length === 0 && !lockedParts) {
		return <div className="parts-list--wrapper empty" />
	}

	return (
		<div className="parts-list--wrapper">
			<PartsListHeaders
				isQuickCADUpload={isQuickCADUpload}
				isGenerativeDesignOn={isGenerativeDesignOn}
			/>
			<div className="parts-list--body">
				{lockedParts && (
					<CastorLockedPartsListView
						amountOfLockedParts={amountOfLockedParts}
						isLightUser={isLightUser}
						contactUsEmail={contactUsEmail}
					/>
				)}
				{parts?.map(part => (
					<PartRow
						key={part.id}
						part={part}
						leadingConfigurationData={leadingConfigurationData}
						isBundle={isBundle}
						isQuickCADUpload={isQuickCADUpload}
						isGenerativeDesignOn={isGenerativeDesignOn}
					/>
				))}
			</div>
		</div>
	)
}

export default PartsListView
