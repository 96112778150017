import { FC, memo } from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { MenuItem } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { forEach } from 'lodash'

import { REQUIRED } from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import CastorSwitch from '../../../Components/CastorSwitch'
import TransparentButton from '../../../Components/TransparentButton'
import { renderSelectFieldTouched } from '../AdminFields/AdminSelectField'
import {
	astmIsoName,
	astmIsoNames,
	checkErrorValues,
	formFields,
	OTHER
} from './constants'
import Flexbox from 'Scenes/Components/FlexBox'

import '../adminHome.scss'

const ReduxFormField: any = Field
const TextFieldTSX: any = TextField

interface IProps {
	onSubmitClick: any
	printersTechParametersAstmIsoNameList: string[]
	selectedAstmIsoName: string
	newAstmIsoSelected: Function
	isNewAstmIsoNameDisabled: boolean
}
interface IFormData {
	change: (name: string, value: string | null) => void
}

const validate = (values: any) => {
	const errors: any = {}
	forEach(checkErrorValues, item => {
		const selectedValue = values[item.name]

		if (item?.checkIfNull && values[item.name] == null) {
			errors[item.name] = REQUIRED
		}
		if (!item?.checkIfNull && !selectedValue) {
			values[item.name] = 0
		}

		if (selectedValue && item.checkIfMinMax) {
			const checkOnlyMin = item?.min && selectedValue < item?.min
			const checkAndEqualMin = !item?.min && selectedValue < item?.min

			if (selectedValue > item?.max || checkAndEqualMin || checkOnlyMin) {
				const label = item?.checkIfNull
					? getString('NUMBER_VALIDATION_REQUIRED')
					: getString('NUMBER_VALIDATION_NOT_REQUIRED')

				errors[item.name] = label.format(item?.min, item?.max)
			}
		}
	})
	return errors
}

const renderTextField = ({
	input,
	label,
	meta: { touched, error },
	initialized,
	...custom
}: {
	input: any
	label: string
	meta: { touched: any; error: any }
	initialized: boolean
}) => (
	<TextFieldTSX
		label={label}
		hintText={label}
		floatingLabelText={label}
		error={initialized && error}
		helperText={error}
		className="form-field-text-custom"
		{...input}
		{...custom}
	/>
)

const renderToggleField = ({ input, label, change, disabled }: any) => (
	<div className="form-field-toggle">
		<div>{label}</div>
		<CastorSwitch
			disabled={disabled}
			checked={input.value}
			onChange={input.onChange}
		/>
	</div>
)

const renderReduxFormField = (
	name: string,
	label: string,
	type: string,
	isToggle: boolean,
	initialized: boolean,
	printersTechParametersAstmIsoNameList: string[],
	selectedAstmIsoName: string,
	newAstmIsoSelected: Function,
	change: (name: string, value: string | null) => void,
	isSelect?: boolean
) => {
	let componentType: any = isToggle ? renderToggleField : renderTextField
	if (isSelect) {
		componentType = renderSelectedAstmIsoNames
	}

	return (
		<ReduxFormField
			name={name}
			component={componentType}
			label={label}
			type={type}
			initialized={initialized}
			printersTechParametersAstmIsoNameList={
				printersTechParametersAstmIsoNameList
			}
			selectedAstmIsoName={selectedAstmIsoName}
			newAstmIsoSelected={newAstmIsoSelected}
			change={change}
		/>
	)
}

const renderSelectedAstmIsoNames = ({
	name,
	label,
	change,
	initialized,
	printersTechParametersAstmIsoNameList,
	selectedAstmIsoName,
	newAstmIsoSelected
}: any) => {
	return (
		<Field
			name={name}
			component={renderSelectFieldTouched}
			label={label}
			custom={{
				value: selectedAstmIsoName || '',
				onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
					change(astmIsoNames, e.target.value)
					if (e.target.value === OTHER) {
						change(astmIsoName, '')
					} else {
						change(astmIsoName, e.target.value)
					}
					// TODO: call action
					newAstmIsoSelected(e.target.value)
				}
			}}
			initialized={initialized}
		>
			<MenuItem disabled>{label}</MenuItem>
			{printersTechParametersAstmIsoNameList?.map((astmIso: string) => {
				return (
					<MenuItem key={astmIso} value={astmIso}>
						{astmIso}
					</MenuItem>
				)
			})}
		</Field>
	)
}

const AdminPrinterTechFrom: FC<
	InjectedFormProps<IFormData, IProps> & IProps
> = ({
	handleSubmit,
	onSubmitClick,
	reset,
	pristine,
	submitting,
	valid,
	change,
	initialized,
	printersTechParametersAstmIsoNameList,
	selectedAstmIsoName,
	newAstmIsoSelected,
	isNewAstmIsoNameDisabled
}) => {
	return (
		<Flexbox className="admin-project-form" flexDirection="column">
			{formFields.map(({ name, label, type, isToggle, isSelect }) => {
				if (name === astmIsoName && isNewAstmIsoNameDisabled) {
					return <></>
				}

				return renderReduxFormField(
					name,
					label,
					type,
					isToggle,
					initialized,
					printersTechParametersAstmIsoNameList,
					selectedAstmIsoName,
					newAstmIsoSelected,
					change,
					isSelect
				)
			})}
			<Flexbox
				alignItems="center"
				alignSelf="flex-end"
				justifyContent="space-between"
				width="200px"
			>
				<TransparentButton onClick={() => reset()}>
					{getString('RESET')}
				</TransparentButton>
				<ButtonWithLoader
					onClick={handleSubmit(onSubmitClick)}
					loading={false}
					disabled={pristine || submitting || !valid}
				>
					{getString('SUBMIT')}
				</ButtonWithLoader>
			</Flexbox>
		</Flexbox>
	)
}

export default memo(
	reduxForm<IFormData, IProps>({
		form: 'AdminPrinterTechParameters',
		validate,
		enableReinitialize: true
	})(AdminPrinterTechFrom)
)
