export const styles = {
	submitButtonStyle: {
		margin: 15,
		minWidth: 115,
		height: 45
	},
	errorTextStyle: {
		fontSize: 15,
		alignSelf: 'center',
		color: 'red'
	},
	paperStyle: {
		margin: 250,
		textAlign: 'center',
		display: 'inline-block'
	},
	formWrapper: {
		margin: 15
	},
	logoImage: {
		margin: 40,
		width: 200,
		alighSelf: 'center'
	},
	lineStyle: {
		borderWidth: 0.5,
		borderColor: 'black',
		margin: 10
	},
	subtitle: {
		fontSize: 22,
		alignSelf: 'center',
		color: 'gray',
		margin: 6
	},
	linkStyle: {
		fontSize: 22,
		alignSelf: 'center',
		color: '#9c27b0'
	},
	textField: {
		width: 250
	},
	checkbox: {
		width: '30'
	},
	checkboxLabel: {
		fontSize: 14,
		color: 'gray',
		cursor: 'default'
	},
	checkboxLabelLink: {
		fontSize: 14,
		color: '#9c27b0',
		cursor: 'pointer'
	},
	horizontalFlex: {
		flex: 1,
		alignItems: 'center',
		align: 'center',
		justifyContent: 'center'
	},
	infoBut: { width: 40, marginLeft: 3, marginRight: -80, marginTop: 6 },
	infoButHidden: {
		width: 40,
		marginLeft: 3,
		marginRight: -80,
		visibility: 'hidden'
	}
}
