import React, { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { IN_HOUSE_COMPONENTS_IDS, STEP_ID } from '../../constants'
import { useStep } from '../../OnboardingWizardHooks'
import { isContentPresent } from '../../OnboardingWizardService'
import OnboardingStepFrame from '../OnboardingStepFrame'
import { setupInHousePrinters } from 'Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrintersActions'
import CustomizeInHousePrintersTable from 'Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrintersTable'
import InHouseMaterialOtherSelector from 'Scenes/Home/Customize/CustomizeInHousePrinters/InHouseMaterialOtherSelector'
import InHousePrintersOnlyCheckbox from 'Scenes/Home/Customize/CustomizeInHousePrinters/InHousePrintersOnlyCheckbox'
import InHousePrintersOtherSelector from 'Scenes/Home/Customize/CustomizeInHousePrinters/InHousePrintersOtherSelector'
import InHousePrintersSelector from 'Scenes/Home/Customize/CustomizeInHousePrinters/InHousePrintersSelector'

import './InHousePrinters.scss'

const InHousePrintersComponents = {
	[IN_HOUSE_COMPONENTS_IDS.PRINTERS_SELECTOR]: <InHousePrintersSelector />,
	[IN_HOUSE_COMPONENTS_IDS.PRINTERS_OTHER_SELECTOR]: (
		<InHousePrintersOtherSelector />
	),
	[IN_HOUSE_COMPONENTS_IDS.MATERIAL_OTHER_SELECTOR]: (
		<InHouseMaterialOtherSelector />
	),
	[IN_HOUSE_COMPONENTS_IDS.PRINTERS_ONLY_CHECKBOX]: (
		<InHousePrintersOnlyCheckbox className="in-house-printers-checkbox" />
	),
	[IN_HOUSE_COMPONENTS_IDS.PRINTERS_TABLE]: <CustomizeInHousePrintersTable />
}

const InHousePrinters: FC = () => {
	const { printersCompanies, userPrinters, userId, inHousePrintersOnly } =
		useSelector(
			(state: RootStateOrAny) => state.CustomizeInHousePrintersReducer
		)
	const { printers } = useSelector((state: RootStateOrAny) => state.user)
	const { steps } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { step, stepContent } = useStep(steps, STEP_ID.IN_HOUSE_PRINTERS)
	const match = useRouteMatch()
	const dispatch = useDispatch()

	const changesInCurrentStep =
		(isContentPresent(stepContent, IN_HOUSE_COMPONENTS_IDS.PRINTERS_TABLE) &&
			(userId ? userPrinters.length : printers.length)) ||
		(isContentPresent(
			stepContent,
			IN_HOUSE_COMPONENTS_IDS.PRINTERS_ONLY_CHECKBOX
		) &&
			inHousePrintersOnly)

	useEffect(() => {
		dispatch(setupInHousePrinters(printersCompanies, match))
	}, [])

	return (
		<OnboardingStepFrame
			step={step}
			bodyClassName="in-house-printers-frame"
			changesInCurrentStep={changesInCurrentStep}
		>
			<div>
				{stepContent?.map(component => (
					<React.Fragment key={component.id}>
						{
							InHousePrintersComponents[
								component.id as keyof typeof InHousePrintersComponents
							]
						}
					</React.Fragment>
				))}
			</div>
		</OnboardingStepFrame>
	)
}

export default memo(InHousePrinters)
