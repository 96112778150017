import React, { FC, memo } from 'react'

import { getString } from 'Services/Strings/StringService'
import { LineBreak } from 'Scenes/Components/PdfComponents/LineBreak'
import MaterialComparisonTab from '../../../SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/MaterialComparisonTab'
import { SolutionAnalysisReducerService } from '../../../SolutionAnalysisReducerService'

interface IProps {
	showAllMaterialCompars: boolean
	show: boolean
	configurationId: number
	solution: any
	material: any
	solutionPriorities: any
}

export const MaterialAnalysisPdf: FC<IProps> = ({
	show,
	solution,
	material,
	solutionPriorities
}) => {
	if (!show) return <div />
	const solutionAnalysisReducerService = new SolutionAnalysisReducerService()
	const materialComparisonRows =
		solutionAnalysisReducerService.getMaterialComparisonRows(
			solution,
			material,
			solutionPriorities,
			true
		)

	// need to calculate height for pdf
	const easyNumber = 100
	const rowHeight = 40
	const height = materialComparisonRows.length * rowHeight + easyNumber

	return (
		<div className="inline">
			<div className="export-pdf__material">
				<div style={{ height: `${height}pt` }}>
					<h2>{getString('MATERIAL_ANALYSIS')}</h2>
					<br />
					<MaterialComparisonTab
						materialComparisonRows={materialComparisonRows}
						showAll={true}
						onShowAllClick={() => {}}
					/>
				</div>
				<LineBreak />
			</div>
		</div>
	)
}

export default memo(MaterialAnalysisPdf)
