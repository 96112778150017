import React, { FC, memo } from 'react'

import { getString } from 'Services/Strings/StringService'
import ResultDetail
	from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisTopDetails/ResultDetail'

interface TopNewConfigurationBodyProps {
	disablePart: boolean,
	result: any,
	resultTitle: any,
	resultBody: any,
	disableConfigurationHeader: any,
}

const TopNewConfigurationBody: FC<TopNewConfigurationBodyProps> = ({
	disablePart,
	result,
	resultTitle,
	resultBody,
	disableConfigurationHeader
}) => {
	return (
		<>
			{disablePart && (
				<ResultDetail
					result={result}
					title={resultTitle}
					body={resultBody}
				/>
			)}
			{
				!disableConfigurationHeader &&
				<div className="solution-header-new-configuration">
					{getString('PART_ANALYSIS_TOP_DETAILS_NEW_CONFIGURATION')}
				</div>
			}
		</>
	)
}

export default memo(TopNewConfigurationBody)