// core components
import partImageAndDescriptionStyle from 'assets/jss/material-dashboard-pro-react/components/partImageAndDescriptionStyle'
import Flexbox from 'Scenes/Components/FlexBox'
import React from 'react'
import { connect } from 'react-redux'

import cx from 'classnames'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import { withStyles } from '@material-ui/core'

import { getString } from '../../../../Services/Strings/StringService'
import { CastorPartBenefits } from 'Scenes/Components/CastorPartBenefits/CastorPartBenefits'
import PartImageWithFallback from 'Scenes/Components/PartImageWithFallback/PartImageWithFallback'
import StarIcon from 'Scenes/Components/StarIcon/StarIcon'
import { starPartClicked } from 'Scenes/Components/StarIcon/StarIconActions'
import { ADD_TO_FAVORITES, REMOVE_FROM_FAVORITES } from 'Services/Strings'

import { starDiv, starIcon } from './ImageAndDescriptionCard.css'
import './ImageAndDescriptionCardCss.scss'

class ImageAndDescriptionCard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hover: false
		}
	}

	onStarClicked = () => {
		this.props.starPartClicked(this.props.part)
	}

	renderStarIcon(showStarIcon, part) {
		if (showStarIcon) {
			const { starred } = part
			return (
				<div style={starDiv}>
					<StarIcon
						starred={starred}
						styles={starIcon}
						title={starred ? REMOVE_FROM_FAVORITES : ADD_TO_FAVORITES}
						onStarClicked={this.onStarClicked}
					/>
					{starred ? REMOVE_FROM_FAVORITES : ADD_TO_FAVORITES}
				</div>
			)
		}
		return <div />
	}

	renderBenefits = () => {
		if (!this.props.part) {
			return <div />
		}
		const { benefits } = this.props.part
		return <CastorPartBenefits benefits={benefits} />
	}

	render() {
		const {
			classes,
			image,
			title,
			hover,
			style,
			imageInsideCard,
			part,
			imageComponent,
			inResultBlock
		} = this.props
		let showStarIcon = false
		if (part) {
			showStarIcon = true
		}

		const cardHeaderClasses =
			classes.cardHeader +
			cx({
				[' ' + classes.moveImageUp]: this.state.hover && hover
			})
		var addHoverEvent = {}
		if (hover) {
			if (navigator.userAgent.match(/iPad/i) != null) {
				addHoverEvent.onClick = () =>
					this.setState({ hover: !this.state.hover })
			} else {
				addHoverEvent.onMouseEnter = () => this.setState({ hover: true })
				addHoverEvent.onMouseLeave = () => this.setState({ hover: false })
			}
		}
		const contentHeight = this.props.contentHeight
			? `calc(100% - ${this.props.contentHeight}px)`
			: 'auto'

		let CardWithImageStyle = null

		if (imageInsideCard) {
			CardWithImageStyle = {
				marginTop: 15,
				height: contentHeight,
				maxHeight: '21.5em'
			}
		}

		if (inResultBlock) {
			CardWithImageStyle = {
				height: contentHeight,
				margin: 0,
				boxShadow: 'none',
				maxHeight: '564px'
			}
		}

		const cardContent = () => {
			return (
				<div>
					<div className="section-header">
						<div title={`${getString('ASSEMBLY')}/${title}`}>
							{getString('ASSEMBLY')}/{title}
						</div>
					</div>
					<CardHeader
						style={CardWithImageStyle}
						className={cx(cardHeaderClasses, 'section-body')}
						subheader={
							<div className="image-and-description-images">
								{imageComponent}
								<PartImageWithFallback
									src={image}
									alt="..."
									className={classes.cardImage}
									style={{
										visibility: imageComponent ? 'hidden' : 'visible',
										...CardWithImageStyle,
										marginTop: 0
									}}
								/>
							</div>
						}
					/>
					<Flexbox
						className="star-benefit-block"
						justifyContent="space-between"
						alignItems="center"
					>
						{this.renderStarIcon(showStarIcon, part)}
						{this.renderBenefits(showStarIcon, part)}
					</Flexbox>
				</div>
			)
		}

		if (inResultBlock) {
			return cardContent()
		}

		return (
			<Card className={classes.card} {...addHoverEvent} style={style}>
				{cardContent()}
			</Card>
		)
	}
}

ImageAndDescriptionCard.defaultProps = {
	hover: false
}

export default connect(null, { starPartClicked })(
	withStyles(partImageAndDescriptionStyle)(ImageAndDescriptionCard)
)
