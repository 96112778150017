import { Dispatch } from 'react'
import { AnyAction } from 'redux'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../../global actions/types/CastorAlertTypes'
import { SHOW_NOTIFICATION } from '../../../Services/Strings'
import {
	USER_SUBSCRIPTION_REQUEST_LOADING,
	USER_SUBSCRIPTION_REQUEST_SUCCEED,
	HANDLE_NOTIFICATION
} from '../../../global actions/types'
import { SUBSCRIPTION_FULL_VERSION_RADIO_VALUE } from '../../../Services/Constants'
import { sendReNewSubscriptionRequest } from '../network'
import { UploadProjectActionService } from '../../../Scenes/Home/NewUploadProject/UploadProjectActionService'
import { getString } from 'Services/Strings/StringService'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'

export class UploadProjectService extends UploadProjectActionService {
	onSubscriptionPopup = (dispatch: any, user: any) => {
		const {
			userOnTrial,
			userDetails: { email }
		} = user
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: userOnTrial
					? getString('PARTS_BUNDLE_END_TRIAL')
					: getString('PARTS_BUNDLE_END_NOT_TRIAL'),
				headerTitle: getString('PARTS_CREDIT_END_POPUP_HEADER'),
				onConfirm: () => {
					this.openConnectCastorPopup(dispatch, email)
				},
				confirmText: getString('CONNECT_CASTOR'),
				extraButton: {
					text: getString('CONNECT_EVONIK'),
					onConfirm: () => {
						this.openConnectEvonikPopup(dispatch, email)
					}
				}
			}
		})
	}

	openConnectEvonikPopup = (
		dispatch: Dispatch<AnyAction>,
		userEmail: string
	) => {
		dispatch({ type: ALERT_POPUP_CANCELED })
		this.sendConnectEvonikUserChoise(dispatch, userEmail)
	}

	sendConnectEvonikUserChoise = (
		dispatch: Dispatch<AnyAction>,
		userEmail: string
	) => {
		dispatch({ type: USER_SUBSCRIPTION_REQUEST_LOADING })
		sendReNewSubscriptionRequest(
			SUBSCRIPTION_FULL_VERSION_RADIO_VALUE,
			userEmail
		)
			?.then((response: any) => {
				this.sendSubscriptionRequestEvonikSucceed(dispatch)
			})
			?.catch((error: any) => {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: 'error'
					}
				})
				console.log(error)
			})
	}

	sendSubscriptionRequestEvonikSucceed = (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: USER_SUBSCRIPTION_REQUEST_SUCCEED })
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: getString('SUBSCRIPTION_REQUEST_SECCEDD'),
				headerTitle: getString('SUBSCRIPTION_REQUEST_SECCEDD_HEADER'),
				alertType: AlertType.SUCCESS,
				showCancel: false,
				onConfirm: () => {
					dispatch({ type: ALERT_POPUP_CANCELED })
				},
				confirmText: getString('OK')
			}
		})
	}
}
