/*!

 =========================================================
 * Material Dashboard PRO React - v1.0.0 based on Material Dashboard PRO - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

import { getTheme } from '../../themes/getTheme'

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const { colors, fontStyle, buttons } = getTheme()

const drawerWidth = 260

const drawerMiniWidth = 80

const transition = {
  transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
}

const containerFluid = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  '&:before,&:after': {
    display: 'table',
    content: '" "'
  },
  '&:after': {
    clear: 'both'
  }
}

const container = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  '@media (min-width: 768px)': {
    width: '750px'
  },
  '@media (min-width: 992px)': {
    width: '970px'
  },
  '@media (min-width: 1200px)': {
    width: '1170px'
  },
  '&:before,&:after': {
    display: 'table',
    content: '" "'
  },
  '&:after': {
    clear: 'both'
  }
}

const boxShadow = {
  boxShadow:
    '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
}

const card = {
  display: 'inline-block',
  position: 'relative',
  width: '100%',
  margin: '25px 0',
  boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
  borderRadius: '6px',
  color: 'rgba(0, 0, 0, 0.87)',
  background: '#fff',
  overflow: 'inherit',
}

const defaultFont = fontStyle || {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: '300',
  lineHeight: '1.5em'
}

const primaryColor = colors.successColor || '#20c0e6'
const primaryButtonColor = colors.primaryButtonColor || primaryColor
const primaryButtonHoverBorderColor = colors.hoverButtonBorderPrimary || primaryColor
const primaryButtonBackgroundColor = colors.hoverButtonBackgroundColor || primaryColor
const disabledColor =  colors.disabledButtonColor || primaryColor;
const secondaryColor = colors.secondaryColor || 'transparent'
const warningColor = '#ff9800'
const dangerColor = '#f44336'
const successColor = '#64c882'
const infoColor = buttons.infoColor || '#20c0e6'
const roseColor = '#1E4B64'
const grayRedColor = '#4B4B4B'
const grayColor = '#999999'
const simpleColor = colors.simpleColor || grayColor
const paginationButton = colors.paginationButtonColor || 'rgba(32, 192, 230, 0.2)'

const primaryFontColor = colors.passedFontColor || '#ffffff'
const primaryButtonTextColor = colors.hoverButtonTextColor || primaryFontColor
const infoFontColor = buttons.infoFontColor || '#ffffff'
const buttonBorderRadius = buttons?.borderRadius || '8px'

const buttonfontSize = buttons?.fontSize || '17px'
const primaryButtonHoverDecoration = buttons?.primaryButtonHoverDecoration || 'none'

const primaryBoxShadow = {
  boxShadow: buttons.primaryBoxShadow || '0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)'
}
const primaryBoxShadowHover = {
  boxShadow: buttons.primaryBoxShadowHover || '0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)'
}

const secondaryBoxShadow = buttons.secondaryBoxShadow || 'none'
const secondaryBoxShadowHover = buttons.secondaryBoxShadowHover || 'none'
const infoBoxShadow = {
  boxShadow: buttons.infoBoxShadow || '0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12)'
}
const infoBoxShadowHover = {
  boxShadow: buttons.infoBoxShadowHover || '0 14px 26px -12px rgba(0, 188, 212, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2)'
}
const successBoxShadow = {
  boxShadow:
    '0 6px 10px -10px rgba(0, 0, 0, 0.28), 0 4px 10px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)'
}
const warningBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)'
}
const dangerBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)'
}
const roseBoxShadow = {
  boxShadow:
    '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(21, 55, 76, 0.4)'
}

const orangeCardHeader = {
  background: 'linear-gradient(60deg, #ffa726, #fb8c00)',
  ...warningBoxShadow
}
const greenCardHeader = {
  background: `linear-gradient(180deg, ${colors.greenCardHeaderBackground}, ${colors.darkGreenCardHeaderBackground})`,
  ...successBoxShadow
}
const redCardHeader = {
  background: 'linear-gradient(60deg, #ef5350, #e53935)',
  ...dangerBoxShadow
}
const blueCardHeader = {
  background: `linear-gradient(60deg, ${colors.blueCardHeaderBackground}, ${colors.blueCardHeaderBackground})`,
  ...infoBoxShadow
}
const purpleCardHeader = {
  background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
  ...primaryBoxShadow
}
const roseCardHeader = {
  background: 'linear-gradient(60deg, #1E4B64, #102938)',
  ...roseBoxShadow
}
const grayRedCardHeader = {
  background: 'linear-gradient(180deg, #556679, #3D4A57)',
  ...roseBoxShadow
}

const cardActions = {
  margin: '0 20px 10px',
  paddingTop: '10px',
  borderTop: '1px solid #eeeeee',
  height: 'auto',
  ...defaultFont
}

const cardHeader = {
  margin: '-20px 15px 0',
  borderRadius: '8px',
  padding: '15px'
}

const defaultBoxShadow = {
  border: '0',
  borderRadius: '8px',
  boxShadow:
    '0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  padding: '10px 0',
  transition: 'all 150ms ease 0s'
}

const tooltip = {
  padding: '10px 15px',
  minWidth: '130px',
  color: '#FFFFFF',
  lineHeight: '1.7em',
  background: 'rgba(85,85,85,0.9)',
  border: 'none',
  borderRadius: '3px',
  opacity: '1!important',
  boxShadow:
    '0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)',
  maxWidth: '200px',
  textAlign: 'center',
  fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  textShadow: 'none',
  textTransform: 'none',
  letterSpacing: 'normal',
  wordBreak: 'normal',
  wordSpacing: 'normal',
  wordWrap: 'normal',
  whiteSpace: 'normal',
  lineBreak: 'auto'
}

export {
  //variables
  drawerWidth,
  drawerMiniWidth,
  transition,
  container,
  containerFluid,
  boxShadow,
  card,
  defaultFont,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  buttonfontSize,
  primaryBoxShadow,
  primaryBoxShadowHover,
  secondaryBoxShadow,
  secondaryBoxShadowHover,
  infoBoxShadow,
  infoBoxShadowHover,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  roseCardHeader,
  cardActions,
  cardHeader,
  defaultBoxShadow,
  tooltip,
  primaryFontColor,
  buttonBorderRadius,
  secondaryColor,
  infoFontColor,
  simpleColor,
  grayRedColor,
  grayRedCardHeader,
  primaryButtonColor,
  disabledColor,
  primaryButtonHoverBorderColor,
  primaryButtonBackgroundColor,
  primaryButtonTextColor,
  primaryButtonHoverDecoration,
  paginationButton
}
