import React, { FC, memo, ReactNode } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import SeeMore from 'react-responsive-see-more'
import { Link } from 'react-router-dom'

import cx from 'classnames'

import PartImageWithFallback from '../PartImageWithFallback/PartImageWithFallback'
import TransparentButton from '../TransparentButton'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import Flexbox from 'Scenes/Components/FlexBox'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import './ItemsWithPicturesList.scss'

const LinkTSX: any = Link

export interface IItemWithPicture {
	id: number
	image: string
	title: string
	linkTo?: string
	subTitle?: string
	subTitleLink?: subTitleLink
	info?: string
	projectName?: string
}

export interface IMaterialCountInfo {
	anyMaterialCount: number
	sameMaterialCount: number
	totalMaterialCount: number
}

export interface IItemClusterWithPicture extends IItemWithPicture {
	materialInfo?: string
	isSameMaterial?: boolean
}

export interface subTitleLink {
	url: string
	text: string
	onClick?: Function
}

interface IProps {
	items: IItemWithPicture[]
	seeMoreURL?: string
	isCluster?: boolean
	disabled?: boolean
	disabledWeight?: boolean
	disablePartLink?: boolean
	isWeightReduction?: boolean
	// buttonClickFunction: Function
}

const renderItemSubLink = (
	// buttonClickFunction: Function,
	// id: number,
	// stepURL?: boolean,
	subTitleLink?: subTitleLink,
	// hasBrepData?: boolean
	disabled?: boolean
) => {
	// TODO: This is a bug - I removed it. the onclick action should be from weightReductionItemsWithPictures array, more TODOs there
	// if (!Feature.isFeatureOn(FeatureComponentId.WEIGHT_REDUCTION_OLD)) {
	//   return (
	//     <TransparentButton
	//       onClick={() => buttonClickFunction(id, stepURL, hasBrepData)}
	//     >
	//       {REDUCE_WEIGHT_BUTTON_TEXT}
	//     </TransparentButton>
	//   )
	// }
	if (!subTitleLink) {
		return <div></div>
	}
	return (
		<TransparentButton
			disabled={disabled}
			isAnchor={!!subTitleLink.url}
			href={subTitleLink.url}
			onClick={() => subTitleLink.onClick && subTitleLink.onClick()}
		>
			{subTitleLink.text}
		</TransparentButton>
	)
}

const renderImageTitle = (
	disabled: boolean | undefined,
	linkTo?: string,
	image?: string,
	title?: string,
	imageClass?: string,
	disablePartLink?: boolean
) => {
	if (disabled || disablePartLink) {
		return (
			<>
				<div className="items-with-pictures--item">
					<div
						className={cx('items-with-pictures--item--content', {
							'not-interactive': disablePartLink
						})}
					>
						<PartImageWithFallback
							src={image}
							alt={title}
							className="items-with-pictures--item--content--image"
						/>
					</div>
				</div>
				<div title={title} className="items-with-pictures--item--title">
					{title}
				</div>
			</>
		)
	}
	return (
		<>
			<div className="items-with-pictures--item">
				<LinkTSX to={linkTo} className={imageClass}>
					<PartImageWithFallback
						src={image}
						alt={title}
						className="items-with-pictures--item--content--image"
					/>
				</LinkTSX>
			</div>
			<div title={title} className="items-with-pictures--item--title">
				{title}
			</div>
		</>
	)
}

const ItemsWithPicturesList: FC<IProps> = ({
	items,
	seeMoreURL,
	isCluster,
	disabledWeight,
	disabled,
	disablePartLink,
	isWeightReduction
	// buttonClickFunction,
}) => {
	const { roles } = useSelector((state: RootStateOrAny) => state.user)
	const allowInfoIcon = roles.includes(UserRole.QA_SPECIFIC)
	const isWeightReductionInGrams = Feature.isFeatureOn(
		FeatureComponentId.WEIGHT_REDUCTION_IN_GRAMS
	)

	const renderItem = ({
		id,
		image,
		title,
		subTitle,
		subTitleLink,
		linkTo,
		info
	}: // stepURL,
	// hasBrepData
	IItemWithPicture) => (
		<Flexbox
			className="single-item"
			flexDirection="column"
			alignItems="center"
			key={id}
		>
			{renderImageTitle(
				disabledWeight,
				linkTo,
				image,
				title,
				'items-with-pictures--item--content',
				disablePartLink
			)}

			{subTitle ? (
				<div className="items-with-pictures--item--subtitle">
					<div>{subTitle}</div>
					{isWeightReductionInGrams && allowInfoIcon ? (
						<DetailsPopup
							style={{ width: '250px' }}
							isHover={true}
							data={info}
							popperDirection="top-end"
							popperClassName="info-box-wrapper"
							popperContactClassName="info-box-data"
							targetClassName="weight-reduction-info-popup"
							inPortal
						>
							<IconFactory iconName="info" />
						</DetailsPopup>
					) : (
						<></>
					)}
				</div>
			) : (
				<div />
			)}

			{!isWeightReduction &&
				renderItemSubLink(
					// buttonClickFunction,
					// id,
					// stepURL,
					subTitleLink,
					// hasBrepData,
					disabledWeight
				)}
		</Flexbox>
	)

	const renderClusterItem = ({
		id,
		image,
		title,
		linkTo,
		materialInfo,
		isSameMaterial,
		projectName
	}: IItemClusterWithPicture) => (
		<Flexbox
			className="single-item"
			flexDirection="column"
			alignItems="center"
			key={id}
			data-qa={`cluster-${projectName}`}
		>
			{renderImageTitle(
				disabled,
				linkTo,
				image,
				title,
				`items-with-pictures--item--content ${
					materialInfo && isSameMaterial ? 'dark-border' : 'light-border'
				}`
			)}
			<div>{materialInfo}</div>
		</Flexbox>
	)

	const renderItems = (): ReactNode[] => items.map(item => renderItem(item))
	const renderClusterItems = (): ReactNode[] =>
		items.map(item => renderClusterItem(item))

	return (
		<SeeMore
			items={isCluster ? renderClusterItems() : renderItems()}
			itemWidth={190}
			seeMoreButtonClassName="items-with-pictures--item--content"
			seeMoreURL={seeMoreURL}
			seeMoreButtonnText={getString('SEE_ALL_RESULTS')}
		/>
	)
}

export default memo(ItemsWithPicturesList)
