import { FC, memo, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { isEmpty } from 'lodash'

import * as ParametersManagerAction from './ParametersManagerAction'
import {
	Feature,
	FeatureComponentId
} from '../../../../Services/models/Features'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import Loader from '../../../Loader/Loader'
import AdminCosting2DDrawingParameters from './Costing2DDrawing'
import AdminCosting3DPrinting from './Costing3DPrinting'
import AdminCostingCastingForm from './CostingCasting'
import AdminCostingCNCForm from './CostingCNC'
import AdminCostingCo2TmForm from './CostingCo2Tm'
import AdminCostingGeneralForm from './CostingGeneral'
import AdminCostingInjectionMoldingForm from './CostingInjectionMolding'
import AdminCostingInvestmentCastingForm from './CostingInvestmentCasting'
import AdminCostingSupplyChainForm from './CostingSupplyChain'
import { ParametersManagerInitialState } from './ParametersManagerReducer'
import {
	PARAMETERS_MANAGER_2D_DRAWING,
	PARAMETERS_MANAGER_3D_PRINTING,
	PARAMETERS_MANAGER_CASTING,
	PARAMETERS_MANAGER_CNC_COSTING,
	PARAMETERS_MANAGER_CO2_MENUFATURING,
	PARAMETERS_MANAGER_GENERAL_COSTING,
	PARAMETERS_MANAGER_INJECTION,
	PARAMETERS_MANAGER_INVESTMENT_CASTING,
	PARAMETERS_MANAGER_SUPPLY_CHAIN
} from 'Services/Strings'

interface IProps extends ParametersManagerInitialState {
	handleCostingInjectionSubmit: Function
	handleCosting3DPSubmit: Function
	handleCostingCNCSubmit: Function
	handleCostingGeneralSubmit: Function
	handleSupplyChainSubmit: Function
	handleCastingSubmit: Function
	handleInvestmentCastingSubmit: Function
	handle2dDrawingParameters: Function
	handleCo2TmSubmit: Function
	setupCostingParametersPage: Function
}

interface IStoreProps {
	ParametersManagerReducer: ParametersManagerInitialState
}

const ParametersManager: FC<IProps> = ({
	handleCostingInjectionSubmit,
	handleCosting3DPSubmit,
	handleCastingSubmit,
	handleInvestmentCastingSubmit,
	parametersObject,
	parametersInfoObject,
	handleCostingCNCSubmit,
	handleCostingGeneralSubmit,
	handle2dDrawingParameters,
	handleSupplyChainSubmit,
	handleCo2TmSubmit,
	setupCostingParametersPage,
	costing3DParametersName,
	costingCastingParametersName,
	costingInvestmentCastingParametersName,
	costingCNCParametersName,
	costingInjectionMoldingParametersName,
	costingGeneralParametersName,
	costingSupplyChainParametersName,
	costing2dDrawingParametersName,
	costingCo2TmParametersName,
	parametersCNCObject,
	parameters3DObject,
	parametersInjectionObject,
	parametersCastingObject,
	parametersInvestmentCastingObject,
	parametersGeneralObject,
	parametersSupplyChainObject,
	parametersCo2TmObject,
	parameters2dDrawingObject,
	updatingLoading,
	loading
}) => {
	useEffect(() => {
		setupCostingParametersPage()
	}, [])
	if (loading) {
		return <Loader load={loading} message="" />
	}

	const isDrawingFeatureOn = Feature.isFeatureOn(
		FeatureComponentId.DRAWING_ANALYSIS
	)
	return (
		<NavBarAndMaterial title={'Parameters Manager'}>
			<div>
				{costingGeneralParametersName?.length > 0 && (
					<CastorForm
						formTitle={PARAMETERS_MANAGER_GENERAL_COSTING.HEADER}
						formSubTitle={PARAMETERS_MANAGER_GENERAL_COSTING.DESCRIPTION}
						content={
							<AdminCostingGeneralForm
								onSubmitClick={(values: any) =>
									handleCostingGeneralSubmit(values)
								}
								parametersUpdateLoading={updatingLoading}
								initialValues={parametersGeneralObject}
								parametersInfoObject={parametersInfoObject}
								parametersEditableNames={costingGeneralParametersName}
							/>
						}
						style={{ maxWidth: 'unset' }}
					/>
				)}
				{costingCNCParametersName?.length > 0 && (
					<CastorForm
						formTitle={PARAMETERS_MANAGER_CNC_COSTING.HEADER}
						formSubTitle={PARAMETERS_MANAGER_CNC_COSTING.DESCRIPTION}
						content={
							<AdminCostingCNCForm
								onSubmitClick={(values: any) => handleCostingCNCSubmit(values)}
								parametersUpdateLoading={updatingLoading}
								initialValues={parametersCNCObject}
								parametersInfoObject={parametersInfoObject}
								parametersEditableNames={costingCNCParametersName}
							/>
						}
						style={{ maxWidth: 'unset' }}
					/>
				)}
				{costing3DParametersName?.length > 0 && (
					<CastorForm
						formTitle={PARAMETERS_MANAGER_3D_PRINTING.HEADER}
						formSubTitle={PARAMETERS_MANAGER_3D_PRINTING.DESCRIPTION}
						content={
							<AdminCosting3DPrinting
								onSubmitClick={(values: any) => handleCosting3DPSubmit(values)}
								parametersUpdateLoading={updatingLoading}
								initialValues={parameters3DObject}
								parametersInfoObject={parametersInfoObject}
								parametersEditableNames={costing3DParametersName}
							/>
						}
						style={{ maxWidth: 'unset' }}
					/>
				)}
				{costingInjectionMoldingParametersName?.length > 0 && (
					<CastorForm
						formTitle={PARAMETERS_MANAGER_INJECTION.HEADER}
						formSubTitle={PARAMETERS_MANAGER_INJECTION.DESCRIPTION}
						content={
							<AdminCostingInjectionMoldingForm
								onSubmitClick={(values: any) =>
									handleCostingInjectionSubmit(values)
								}
								parametersUpdateLoading={updatingLoading}
								initialValues={parametersInjectionObject}
								parametersInfoObject={parametersInfoObject}
								parametersEditableNames={costingInjectionMoldingParametersName}
							/>
						}
						style={{ maxWidth: 'unset' }}
					/>
				)}
				{costingCastingParametersName?.length > 0 && (
					<CastorForm
						formTitle={PARAMETERS_MANAGER_CASTING.HEADER}
						formSubTitle={PARAMETERS_MANAGER_CASTING.DESCRIPTION}
						content={
							<AdminCostingCastingForm
								onSubmitClick={(values: any) => handleCastingSubmit(values)}
								parametersUpdateLoading={updatingLoading}
								initialValues={parametersCastingObject}
								parametersInfoObject={parametersInfoObject}
								parametersEditableNames={costingCastingParametersName}
							/>
						}
						style={{ maxWidth: 'unset' }}
					/>
				)}
				{costingInvestmentCastingParametersName?.length > 0 && (
					<CastorForm
						formTitle={PARAMETERS_MANAGER_INVESTMENT_CASTING.HEADER}
						formSubTitle={PARAMETERS_MANAGER_INVESTMENT_CASTING.DESCRIPTION}
						content={
							<AdminCostingInvestmentCastingForm
								onSubmitClick={(values: any) =>
									handleInvestmentCastingSubmit(values)
								}
								parametersUpdateLoading={updatingLoading}
								initialValues={parametersInvestmentCastingObject}
								parametersInfoObject={parametersInfoObject}
								parametersEditableNames={costingInvestmentCastingParametersName}
							/>
						}
						style={{ maxWidth: 'unset' }}
					/>
				)}
				{costingSupplyChainParametersName?.length > 0 && (
					<CastorForm
						formTitle={PARAMETERS_MANAGER_SUPPLY_CHAIN.HEADER}
						formSubTitle={PARAMETERS_MANAGER_SUPPLY_CHAIN.DESCRIPTION}
						content={
							<AdminCostingSupplyChainForm
								onSubmitClick={(values: any) => handleSupplyChainSubmit(values)}
								parametersUpdateLoading={updatingLoading}
								initialValues={parametersSupplyChainObject}
								parametersInfoObject={parametersInfoObject}
								parametersEditableNames={costingSupplyChainParametersName}
							/>
						}
						style={{ maxWidth: 'unset' }}
					/>
				)}

				{costingCo2TmParametersName?.length > 0 && (
					<CastorForm
						formTitle={PARAMETERS_MANAGER_CO2_MENUFATURING.HEADER}
						formSubTitle={PARAMETERS_MANAGER_CO2_MENUFATURING.DESCRIPTION}
						content={
							<AdminCostingCo2TmForm
								onSubmitClick={(values: any) => handleCo2TmSubmit(values)}
								parametersUpdateLoading={updatingLoading}
								initialValues={parametersCo2TmObject}
								parametersInfoObject={parametersInfoObject}
								parametersEditableNames={costingCo2TmParametersName}
							/>
						}
						style={{ maxWidth: 'unset' }}
					/>
				)}

				{!isEmpty(costing2dDrawingParametersName) && isDrawingFeatureOn && (
					<CastorForm
						formTitle={PARAMETERS_MANAGER_2D_DRAWING.HEADER}
						formSubTitle={PARAMETERS_MANAGER_2D_DRAWING.DESCRIPTION}
						content={
							<AdminCosting2DDrawingParameters
								onSubmitClick={(values: any) =>
									handle2dDrawingParameters(values)
								}
								parametersUpdateLoading={updatingLoading}
								initialValues={parameters2dDrawingObject}
								parametersInfoObject={parametersInfoObject}
								parametersEditableNames={costing2dDrawingParametersName}
							/>
						}
						style={{ maxWidth: 'unset' }}
					/>
				)}
			</div>
		</NavBarAndMaterial>
	)
}

const mapStateToProps = ({ ParametersManagerReducer }: IStoreProps) => {
	return {
		...ParametersManagerReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...ParametersManagerAction }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(memo(ParametersManager))
