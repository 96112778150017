import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as CostAndLeadActions from './CostAndLeadActions'
import TransparentButton from '../../../Components/TransparentButton'

class CostAndLeadActionButton extends Component {
  componentDidMount = () => {
    const { setupActionButtonText, isDefault, edit, inputKey } = this.props
    setupActionButtonText(isDefault, edit, inputKey)
  }

  onClick = clickType => {
    const {
      onActionButtonClicked,
      inputKey,
      editInputs,
      settingsData,
      originalUserSettingsValues,
      costAndLeadNames
    } = this.props

    onActionButtonClicked(
      clickType,
      settingsData,
      inputKey,
      editInputs,
      originalUserSettingsValues,
      costAndLeadNames
    )
  }

  renderActions = () => {
    const { inputKey, actionButtonTexts } = this.props
    if (Object.keys(actionButtonTexts).length) {
      return actionButtonTexts[inputKey].map(actionButtonText => {
        return (
          <TransparentButton
            key={actionButtonText}
            onClick={this.onClick.bind(this, actionButtonText)}
            className="custom-input--action"
          >
            {actionButtonText}
          </TransparentButton>
        )
      })
    }
  }

  render() {
    return <div>{this.renderActions()}</div>
  }
}

const mapStateToProps = ({ CostAndLeadReducer }) => {
  const {
    editInputs,
    settingsData,
    originalUserSettingsValues,
    actionButtonTexts,
    costAndLeadNames
  } = CostAndLeadReducer
  return {
    editInputs,
    settingsData,
    originalUserSettingsValues,
    actionButtonTexts,
    costAndLeadNames
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...CostAndLeadActions }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CostAndLeadActionButton)
