import React, { FC, memo } from 'react'

import classnames from 'classnames'

import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { partResults } from 'Services/Constants'
import {
	ARE,
	IS,
	MAIN_RESULT_TITLE,
	PROJECT_ANALYSIS_OUT_OF
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface ResultsLabelProps {
	partsCount: number
	printablePartsCount: number
	isAssembly: boolean
}

const ResultsLabel: FC<ResultsLabelProps> = ({
	partsCount,
	printablePartsCount,
	isAssembly
}) => {
	return (
		<div className="project-results--label--title">
			<div className="project-results--label--title--info">
				<IconFactory
					iconName={partResults.printable}
					className="project-results--label--title--icon"
				/>
				<div className="project-results--label--text">{MAIN_RESULT_TITLE}</div>
			</div>

			<div
				className={classnames('project-results--label--info', {
					high: isAssembly
				})}
			>
				<span className="project-results--label--title--out-of">
					{PROJECT_ANALYSIS_OUT_OF.format(partsCount)}
				</span>
				<span className="project-results--label--title--printable">
					{getString('PROJECT_ANALYSIS_OUT_OF_PRINTABLE').format(
						printablePartsCount,
						printablePartsCount === 1 ? IS : ARE
					)}
				</span>
			</div>
		</div>
	)
}

export default memo(ResultsLabel)
