import { FC, memo } from 'react'

import cx from 'classnames'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import CastorCheckbox from '../../../../../../Components/CastorCheckbox'
import { IPriority } from 'Services/models/IPriority'
import { PART_ANALYSIS_CONFIGURE_PRIORITIES_FEATURES } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './SolutionConfigureFeatures.scss'

const LabelFormControl: any = FormControlLabel

interface Props {
	priorities: IPriority[]
	showSolutionPriorities: any
	configurationId: number
	isNewDesign?: boolean
	onPriorityToggleChange: (id: number, priority: IPriority) => void
}

const SolutionConfigureFeaturesPriorities: FC<Props> = ({
	priorities,
	showSolutionPriorities,
	configurationId,
	onPriorityToggleChange,
	isNewDesign
}) => {
	return (
		<div
			className={cx('solution-feature-modal one-column', {
				'solution-dropdown': isNewDesign
			})}
		>
			{!isNewDesign && (
				<div className="solution-feature-modal-title">
					{PART_ANALYSIS_CONFIGURE_PRIORITIES_FEATURES}
				</div>
			)}
			{priorities &&
				priorities.map(priority => (
					<LabelFormControl
						control={
							<CastorCheckbox
								checked={showSolutionPriorities[priority.name]}
								disabled={priority.mandatory}
							/>
						}
						label={getString(priority.stringKey)}
						onChange={() => onPriorityToggleChange(configurationId, priority)}
						key={priority.id}
						classes={{
							label: 'solution-feature-modal-label'
						}}
					/>
				))}
		</div>
	)
}

export default memo(SolutionConfigureFeaturesPriorities)
