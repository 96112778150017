export const bomFileTypes = [
  '.xls',
  '.xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]
export const zipFileTypes = [
  'application/zip',
  'application/x-zip-compressed',
  'multipart/x-zip'
]
export const pdfFileTypes = [
  'application/pdf',
  'pdf'
]
