import React, { FC, memo } from 'react'

import cx from 'classnames'

import DetailsPopup from '../DetailsPopup'
import IconFactory from '../StarIcon/IconFactory'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { EXPORT, EXPORT_AS_CSV } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './CastorExcelExport.scss'

interface IProps {
	onClick: any
	isAssembly?: boolean
	isLoading?: boolean
	disabled?: boolean
}

const CastorExcelExport: FC<IProps> = ({
	onClick,
	isAssembly,
	isLoading,
	disabled
}) => {
	const disableExportToPdf = !Feature.isFeatureActive(
		FeatureComponentId.PARTS_EXPORT
	)
	return (
		<DetailsPopup
			managerClassName={cx({ 'order-3': isAssembly })}
			isHover={disableExportToPdf}
			data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
			popperDirection="bottom"
			popperClassName="info-box-wrapper details-popup--contact-us"
			popperContactClassName="info-box-data"
			targetClassName={cx({ 'details-popup-target--small': isAssembly })}
		>
			<ButtonWithLoader
				qaDataElementName="data-qa-export-csv-btn"
				disabled={disableExportToPdf || disabled}
				loading={isLoading}
				color="primary"
				onClick={onClick}
				className={cx('export-button', {
					transparent: isAssembly,
					loading: isLoading
				})}
				muiClasses={{
					label: '',
					root: ''
				}}
			>
				<IconFactory
					iconName={isAssembly ? 'exportCSV' : 'exportExcel'}
					className={cx({
						'export-icon': !isAssembly,
						'export-csv': isAssembly
					})}
				/>
				<div className={cx({ 'export-text': !isAssembly })}>
					{isAssembly ? EXPORT_AS_CSV : EXPORT}
				</div>
			</ButtonWithLoader>
		</DetailsPopup>
	)
}

export default WithFeatureToggleHOC(
	memo(CastorExcelExport),
	FeatureComponentId.PARTS_EXPORT
)
