import { FC, memo } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'

import { Danger } from '../thirdParty/CreativeTim/components'
import { getString } from 'Services/Strings/StringService'
import NumberField from '../NumberField'

import './DataTable.scss'

interface IProps {
  text: string | number
  className: string
  data: any
}

const DataTableInputNumber: FC<IProps> = ({ text, className, data = {} }) => (
  <Flexbox width="100%" flexDirection="column" justifyContent="space-between">
    <NumberField
      inputClass={`data-table-input-number ${className} ${data.className} ${
        data.error ? 'text_field__input_error' : ''
      }`}
      value={typeof text === 'string' ? parseInt(text) : text}
      onChangeValue={(value: number) => data.onChange(value)}
      minValue={data.minValue}
      maxValue={data.maxValue}
      step={data.step || 1}
      isDotDisabled={!data.allowDot}
      showArrows={false}
      placeholder={data.placeholder}
    />
    {data.error && (
      <Danger className={`text_field__error`}>
        {getString('DATA_TABLE_ERROR_FIELD')}
      </Danger>
    )}
  </Flexbox>
)

export default memo(DataTableInputNumber)
