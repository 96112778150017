import React, { FC, memo } from 'react'
import { MenuItem } from '@material-ui/core'

import { IResultsSelectionOption } from '../../../Services/models/ResultsSelectionOptions'
import { getString } from '../../../Services/Strings/StringService'
import Loader from '../../Loader/Loader'
import CalculationAccordingSelect from '../CalculationAccordingSelect'
import SelectMenu from '../SimpleConfigurationSelector/SelectMenu'
import { ISimpleConfigurationPrinter } from '../SimpleConfigurationSelector/SimpleConfigurationSelectorService'

import './UserPanelROI.scss'
import RoiData from './RoiData'

const MenuItemTsx: any = MenuItem

interface IProps {
  selectTypeValue: IResultsSelectionOption
  load: boolean
  hideRoiData: boolean
  companiesList: Array<string>
  printerCompanyValue: string
  printersList: Array<ISimpleConfigurationPrinter>
  printerValue: ISimpleConfigurationPrinter | string
  onTypeSelectChange: Function
  onCompanyChange: Function
  onPrinterChange: Function
  userCurrencySign: string
  userROIDataText: string
  userROIMachineCost: number
  userROITraditionalSavingYears: number
}

const UserPanelROI: FC<IProps> = ({
  onTypeSelectChange,
  selectTypeValue,
  load,
  companiesList,
  printerCompanyValue,
  onCompanyChange,
  printerValue,
  printersList,
  onPrinterChange,
  userROIMachineCost,
  userROITraditionalSavingYears,
  userCurrencySign,
  hideRoiData,
  userROIDataText
}) => {
  return (
    <div className="user-roi">
      <Loader load={load} message={''} />
      <CalculationAccordingSelect
        explanationText={getString('RESULTS_SELECTION_ROI_EXPLANATION')}
        onSelectChange={onTypeSelectChange}
        selectValue={selectTypeValue.type}
      />
      <div className="user-roi--printer-selection">
        <div className="user-roi--printer-selection--explanation">
          {getString('USER_PANEL_ROI_PRINTER_SELECTION_EXPLANATION')}
        </div>
        <div className="user-roi--printer-selection--selection">
          <SelectMenu
            value={companiesList?.length ? printerCompanyValue : ''}
            disabled={!companiesList?.length}
            onChange={onCompanyChange}
            disabledMenuItem={getString(
              'IN_HOUSE_PRINTERS_COMPANY_SELECTOR_NAME'
            )}
          >
            {companiesList.map((menuItem: string) => (
              <MenuItemTsx
                key={menuItem}
                style={{ textTransform: 'capitalize' }}
                value={menuItem}
              >
                {menuItem}
              </MenuItemTsx>
            ))}
          </SelectMenu>
          <SelectMenu
            value={printerValue}
            title={
              typeof printerValue === 'object'
                ? printerValue?.name
                : printerValue
            }
            disabled={!printersList?.length}
            onChange={onPrinterChange}
            disabledMenuItem={getString('IN_HOUSE_PRINTERS_PRINTER_NAME')}
          >
            {printersList.map((menuItem: ISimpleConfigurationPrinter) => (
              <MenuItemTsx
                key={menuItem.printerId}
                style={{ textTransform: 'capitalize' }}
                value={menuItem}
              >
                {menuItem.name}
              </MenuItemTsx>
            ))}
          </SelectMenu>
        </div>
      </div>
      <RoiData
        hideRoiData={hideRoiData}
        userCurrencySign={userCurrencySign}
        userROIMachineCost={userROIMachineCost}
        userROITraditionalSavingYears={userROITraditionalSavingYears}
        userROIDataText={userROIDataText}
      />
    </div>
  )
}

export default memo(UserPanelROI)
