import {
  POPPER_MENU_CLICKED,
  POPPER_MENU_CLOSED
} from '../../../global actions/types'

export const popperMenuClicked = (menuName, menuStatus) => {
  return {
    type: POPPER_MENU_CLICKED,
    payload: { menuName, menuStatus }
  }
}

export const popperMenuClosed = () => {
  return {
    type: POPPER_MENU_CLOSED
  }
}
