import React, { memo, FC } from 'react'
import { Select, MenuItem, withStyles } from '@material-ui/core'

const SelectTsx: any = Select
const MenuItemTsx: any = MenuItem

interface IProps {
  value: String
  disabled: boolean
  onChange: Function
  children: any
  classes: any
  disabledMenuItem: any
  rootClassName?: string
  hideTitle?: boolean
  title?: string
  qaDataElementName?: string
}

const styles = {
  select: {
    width: '207px',
    height: '37px',
    fontSize: ' 14px',
    borderRadius: '2px',
    border: '1px solid #dedede',
    padding: '0 15px',
    lineHeight: '2.8em',
    margin: '4px 0'
  },
  icon: {
    right: '14px'
  }
}

const SelectMenu: FC<IProps & any> = ({
  value,
  disabled,
  onChange,
  children,
  classes,
  disabledMenuItem,
  rootClassName,
  hideTitle,
  title = '',
  qaDataElementName = 'data-qa-select'
}: IProps) => {
  return (
    <SelectTsx
      title={hideTitle ? '' : title || value}
      disableUnderline={true}
      displayEmpty={true}
      disabled={disabled}
      className={`material-selector-configure ${rootClassName || ''}`}
      value={value}
      onChange={onChange}
      classes={classes}
      variant={'filled'}
      inputProps={{
        name: 'simpleSelect',
        id: 'simple-select'
      }}
      MenuProps={{
        PaperProps: {
          style: {
            transform: 'translate3d(0, 0, 0)'
          }
        }
      }}
      data-qa={qaDataElementName}
    >
      {disabledMenuItem ? (
        <MenuItemTsx value="" disabled>
          {disabledMenuItem}
        </MenuItemTsx>
      ) : (
        <div />
      )}

      {children}
    </SelectTsx>
  )
}

export default memo(withStyles(styles)(SelectMenu))
