import React, { ChangeEvent, FC, useMemo, useState } from 'react'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import cx from 'classnames'

import { quantityOptions } from '../constants'
import { IFileWithMaterialAndQuantity } from 'Services/models/IUploadProject'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	state: IFileWithMaterialAndQuantity
	onQuantityBlur: (inputValue: string) => void
	onQuantityChange: (
		event: React.ChangeEvent<{}>,
		newValue: string | null
	) => void
}

const ProductionQuantity: FC<IProps> = ({
	state,
	onQuantityBlur,
	onQuantityChange
}) => {
	const [inputValue, setInputValue] = useState('')

	const isSelectedFromOptions = useMemo(
		() => !!quantityOptions.find(item => item === state.productionQuantity),
		[state.productionQuantity]
	)

	const onQuantityInputChange = (
		event: React.ChangeEvent<{}>,
		newInputValue: string
	) => {
		const regex = new RegExp(/^[1-9]+[0-9]*$/)
		if (newInputValue && !regex.test(newInputValue)) {
			return
		}
		setInputValue(newInputValue)
	}

	return (
		<Autocomplete
			value={state.productionQuantity}
			onChange={onQuantityChange}
			inputValue={inputValue}
			onInputChange={onQuantityInputChange}
			classes={{
				root: cx({ 'file-row--root': isSelectedFromOptions }),
				inputRoot: cx('file-row--select-field file-row--input-field', {
					'with-tilde': isSelectedFromOptions
				}),
				input: 'file-row--input-placeholder',
				inputFocused: 'focused',
				clearIndicator: 'file-row--clear-indicator',
				option: 'file-row--option',
				noOptions: 'file-row--no-options'
			}}
			onBlur={() => onQuantityBlur(inputValue)}
			options={quantityOptions}
			renderInput={(params: any) => (
				<TextField
					{...params}
					placeholder={getString(
						'QUICK_UPLOAD_FILES_QUANTITY_NO_QUANTITY_OPTION'
					)}
					onWheel={(event: ChangeEvent<HTMLInputElement>) =>
						event.target.blur()
					}
				/>
			)}
			data-qa="data-qa-file-quantity-selector"
		/>
	)
}

export default ProductionQuantity
