import React, { FC } from 'react'

import { withStyles } from '@material-ui/core'

import * as icons from './starIconEnum'
import IconFactory from './IconFactory'

type IProps = {
	displayIcon?: 'stars' | 'discs'
	starred: boolean
	className?: string
	styles?: string
	title?: string
	customColor?: boolean
	classes: any
	onStarClicked?: (e: React.MouseEvent<SVGSVGElement>) => void
}

const StarIcon: FC<IProps> = ({
	displayIcon,
	starred,
	onStarClicked,
	styles,
	title,
	customColor,
	classes,
	className = ''
}) => {
	const displayIcons = icons[displayIcon || 'discs']
	const starStatus = starred ? displayIcons.FILLED : displayIcons.OUTLINED

	const handleClick = (
		e: React.MouseEvent<SVGSVGElement>,
		onStarClick?: (e: React.MouseEvent<SVGSVGElement>) => void
	) => {
		e.preventDefault()
		e.stopPropagation()
		if (onStarClick) {
			onStarClick(e)
		}
	}

	return (
		<span title={title} style={{ display: 'flex' }}>
			<IconFactory
				iconName={starStatus}
				className={`${className} ${customColor ? '' : classes.icon}`}
				data-qa="data-qa-save-part-btn"
				style={styles}
				onClick={(e: React.MouseEvent<SVGSVGElement>) =>
					handleClick(e, onStarClicked)
				}
			/>
		</span>
	)
}

const styles = () => ({
	icon: {
		cursor: 'pointer',
		color: 'rgba(0, 0, 0, 0.87)'
	}
})

export default withStyles(styles)(StarIcon)
