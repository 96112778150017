import React, { ChangeEvent, FC, memo } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'
import { FormControlLabel } from '@material-ui/core'

import { getString } from 'Services/Strings/StringService'
import { FeatureComponentId } from 'Services/models/Features'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import CastorSwitch from '../../../Components/CastorSwitch'
import InfoBox from '../../../Components/InfoBox'

import './CustomizeFullTrayAssumption.scss'

const LabelFormControl: any = FormControlLabel

interface IProps {
  fullTrayAssumptionOn: false
  onFullTrayAssumptionToggleChange: (fullTrayAssumptionOn: boolean) => any
}

const CustomizeFullTrayAssumption: FC<IProps> = ({
 fullTrayAssumptionOn,
 onFullTrayAssumptionToggleChange,
}) => {
  return (
    <div className="solution-feature-full-tray">
      <CastorFormHeader
        explanationHeader={getString('CUSTOMIZE_FULL_TRAY_ASSUMPTION')}
        explanationArray={getString('CUSTOMIZE_FULL_TRAY_ASSUMPTION_ARRAY')}
        isInCard={true}
      />
      <Flexbox className="solution-feature-full-tray--tog">
        <LabelFormControl
          control={
            <CastorSwitch
              onChange={(e: ChangeEvent<HTMLInputElement>) => onFullTrayAssumptionToggleChange(e.target.checked)}
              checked={fullTrayAssumptionOn}
            />
          }
          label={getString('CUSTOMIZE_FULL_TRAY_LABEL')}
        />
        <InfoBox
          boxContact={
            fullTrayAssumptionOn ?
              getString('CUSTOMIZE_FULL_TRAY_INFO_ON') :
              getString('CUSTOMIZE_FULL_TRAY_INFO_OFF')
          }
          boxContactClassName="solution-feature-full-tray--info"
          boxDirection="right-end"
        />
      </Flexbox>
    </div>
  )
}

export default WithFeatureToggleHOC(
  memo(CustomizeFullTrayAssumption),
  FeatureComponentId.FULL_TRAY_ASSUMPTION
)
