import { FC, memo } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'

import { Danger } from '../thirdParty/CreativeTim/components'
import { getString } from 'Services/Strings/StringService'

import './DataTable.scss'

interface IProps {
  text: string | number
  className: string
  data: any
}

const DataTableInputText: FC<IProps> = ({ text, className, data = {} }) => (
  <Flexbox width="100%" flexDirection="column" justifyContent="space-between">
    <input
      className={`data-table-input-text ${className} ${data.className}`}
      value={text || ''}
      onChange={data.onChange}
    />
    {data.error && (
      <Danger className={`text_field__error`}>
        {data.error || getString('DATA_TABLE_ERROR_FIELD')}
      </Danger>
    )}
  </Flexbox>
)

export default memo(DataTableInputText)
