import Flexbox from 'Scenes/Components/FlexBox'
import React, { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { lowerCase, map } from 'lodash'
import Checkbox from '@material-ui/core/Checkbox'

import { updateAMValue } from '../../UploadProjectActions'
import { checkIfValueExist } from '../UploadBlocksService'
import CardHeaderBox from 'Scenes/Components/CardHeaderBox'
import { getString } from 'Services/Strings/StringService'

const CheckBoxTSX: any = Checkbox

const UploadAmValue: FC = () => {
	const dispatch = useDispatch()
	const { amValue } = useSelector(
		(state: RootStateOrAny) => state.uploadProject
	)

	const changeAmMethod = (methodName: string) => {
		let updatedValue = checkIfValueExist(methodName, amValue)
		dispatch(updateAMValue(updatedValue))
	}

	const defaultAmType = getString('AM_TYPE_IMPROVEMENTS')

	return (
		<CardHeaderBox
			contentClass="new-upload-project__card"
			title={getString('NEW_UPLOAD_SECTION_AM_VALUE')}
			content={
				<Flexbox className="new-upload-project__content" flexDirection="column">
					<div className="label">{getString('WHAT_TYPE_OF_AM')}</div>
					<div
						className="upload-form__scenario-block am-type"
						data-qa={`data-qa-manufacturing-block`}
					>
						{map(defaultAmType, (title: string, method: string) => {
							const methodKey = lowerCase(method)
							return (
								<div
									className="am-type-checkbox"
									key={methodKey}
									data-qa={`data-qa-am-type-${methodKey}`}
								>
									<CheckBoxTSX
										color="primary"
										checked={amValue.includes(method)}
										onChange={() => changeAmMethod(method)}
									/>
									<p>{title}</p>
								</div>
							)
						})}
					</div>
				</Flexbox>
			}
		/>
	)
}
export default memo(UploadAmValue)
