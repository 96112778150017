import { Action } from '../../../global actions/ActionModels'
import {
	CASTOR_BANNER_CANCEL,
	CASTOR_BANNER_USER_REQUEST_SENT,
	SHOW_CASTOR_BANNER,
	SHOW_PROJECT_QUEUED_TO_RECALCULATE_BANNER,
	SHOW_PROJECT_QUEUED_TO_RECALCULATE_BANNER_CANCEL
} from '../../../global actions/types/CastorBannerTypes'
import { CastorBannerType } from './CastorBannerType.enum'
import { getString } from 'Services/Strings/StringService'

export interface CastorBannerInitialState {
	readonly show: boolean
	readonly showButton: boolean
	readonly disabledButton: boolean
	readonly loadingCalculation: boolean
	readonly userEmail: string
	readonly buttonText: string
	readonly type: CastorBannerType
}

const initialState: CastorBannerInitialState = {
	loadingCalculation: false,
	show: false,
	showButton: true,
	disabledButton: false,
	userEmail: '',
	buttonText: getString('CONFIRM'),
	type: CastorBannerType.UPGRADE_NOW
}

export default (state = initialState, { type, payload }: Action<any>) => {
	switch (type) {
		case SHOW_CASTOR_BANNER: {
			return {
				...state,
				...payload,
				loadingCalculation: false,
				show: true
			}
		}
		case CASTOR_BANNER_USER_REQUEST_SENT:
			return {
				...state,
				...payload
			}
		case CASTOR_BANNER_CANCEL: {
			return {
				...state,
				show: false
			}
		}
		case SHOW_PROJECT_QUEUED_TO_RECALCULATE_BANNER_CANCEL: {
			return {
				...state,
				show: false
			}
		}
		case SHOW_PROJECT_QUEUED_TO_RECALCULATE_BANNER: {
			return {
				...state,
				...payload,
				show: true
			}
		}
		default:
			return state
	}
}
