import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'

import WithFeatureToggleHOC from '../../../Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from '../../../Services/models/Features'
import { getString } from '../../../Services/Strings/StringService'
import DetailsPopup from '../DetailsPopup'
import IconFactory from '../StarIcon/IconFactory'
import { Button } from '../thirdParty/CreativeTim/components'

import './ContactUs.scss'

interface IProps {
  mailto: string
}

const ContactUs: FC<IProps> = ({ mailto }) => {
  return (
    <DetailsPopup
      isHover={true}
      data={getString('CONTACT_US_EXPLANATION')}
      popperDirection={'bottom'}
      popperClassName={'contact-us--wrapper'}
      popperContactClassName={'contact-us--wrapper--popper'}
    >
      <Link
        to="#"
        onClick={e => {
          window.location.href = `mailto: ${mailto}`
          e.preventDefault()
        }}
        data-qa="data-qa-send-us-an-email-btn"
      >
        <Button justIcon round color="white" className="contact-us--button">
          <IconFactory iconName="contact-us" />
        </Button>
      </Link>
    </DetailsPopup>
  )
}

export default WithFeatureToggleHOC(
  memo(ContactUs),
  FeatureComponentId.CONTACT_US
)
