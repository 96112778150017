import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as UserActions from '../../../global actions/UserActions'
import CastorPopperMenu from '../CastorPopperMenu/CastorPopperMenu'
import ContactUs from '../ContactUs'
import CurrencyPicker from '../CurrencyPicker/CurrencyPicker'
import LanguagePicker from '../LanguagePicker.tsx'
import VersionViewer from '../VersionViewer'
import UserMenuItems from './UserMenuItems'
import UserMenuTarget from './UserMenuTarget'
import Flexbox from 'Scenes/Components/FlexBox'
import PushNotification from 'Scenes/Components/PushNotification'

import './UserMenu.scss'

class UserMenu extends Component {
	render() {
		const {
			name,
			email,
			addMargin,
			logoutPressed,
			onLanguageChange,
			onCurrencyChange,
			contactUsEmail
		} = this.props
		return (
			<Flexbox
				alignItems="center"
				className="header-links"
				padding="1.5em 10px 10px"
			>
				<VersionViewer />
				<ContactUs mailto={contactUsEmail} />
				<CurrencyPicker onCurrencyChange={onCurrencyChange} />
				<LanguagePicker onLanguageChange={onLanguageChange} />
				<PushNotification />
				<CastorPopperMenu
					menuName="userMenu"
					targetItem={<UserMenuTarget name={name} addMargin={addMargin} />}
					menuItems={
						<UserMenuItems onLogoutPressed={() => logoutPressed(email)} />
					}
					className="user-menu--popper"
				/>
			</Flexbox>
		)
	}
}

const mapStateToProps = ({
	user: {
		userDetails: { name, email },
		contactUsEmail
	},
	CastorBannerReducer: { show }
}) => {
	return {
		name,
		email,
		showTrialBanner: show,
		contactUsEmail
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...UserActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
