import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import * as AdminMaterialsActions from './AdminMaterialsActions'
import {
	adminMaterialsEditFormRoute,
	adminMaterialsNewFormRoute,
	adminMaterialsRoute
} from '../../../../Services/routeFuncs'
import {
	ADMIN_MATERIAL_PARAMS,
	ADMIN_MATERIAL_TABLE_TEXTS,
	ADMIN_MATERIALS_PAGE_HEADER,
	ADMIN_MATERIALS_SUB_TITLE
} from '../../../../Services/Strings'
import AdminDataTable from '../AdminDataTable/AdminDataTable'
import { MATERIALS_ADMIN_ROUTE } from 'Services/Constants/RoutesConstants'

import '../adminHome.scss'

const materialParams = ADMIN_MATERIAL_PARAMS

class AdminMaterials extends Component {
	componentDidMount() {
		const {
				match,
				setupAdminMaterialsPage,
				onAdminMaterialsUnmounted,
				limitMaterials,
				searchPhrase
			} = this.props,
			urlPage = parseInt(match.params['page']) || 1

		setupAdminMaterialsPage(searchPhrase, urlPage, limitMaterials)

		this.unListen = this.props.history.listen((location, action) => {
			if (
				action === 'PUSH' &&
				!location.pathname.includes(MATERIALS_ADMIN_ROUTE)
			) {
				onAdminMaterialsUnmounted()
			}
		})
	}

	componentDidUpdate = prevProps => {
		const {
			match,
			pageNumber,
			setupAdminMaterialsPage,
			limitMaterials,
			searchPhrase
		} = this.props
		let urlPage = parseInt(match.params['page']) || 1
		const isSearchChange = searchPhrase !== prevProps.searchPhrase

		if (isSearchChange && urlPage !== 1) {
			this.props.history.push(MATERIALS_ADMIN_ROUTE + `/1`)
		} else if (urlPage === 1 && isSearchChange) {
			setupAdminMaterialsPage(searchPhrase, pageNumber, limitMaterials)
		}
	}

	componentWillUnmount() {
		this.unListen()
	}

	renderPropertiesField = material => {
		return (
			<div className="admin-multiple-line-field">
				<div>
					{ADMIN_MATERIAL_TABLE_TEXTS.WALL_THICKNESS}: {material.wallThickness}
				</div>
				<div>
					{ADMIN_MATERIAL_TABLE_TEXTS.DENCITY}: {material.density}
				</div>
			</div>
		)
	}

	renderStrengthField = material => {
		return (
			<div className="admin-multiple-line-field">
				<div>
					{ADMIN_MATERIAL_TABLE_TEXTS.YIELD_STRENGTH}:{' '}
					{material.yieldStrengthMPa}
				</div>
				<div>
					{ADMIN_MATERIAL_TABLE_TEXTS.ELONGATION}:{' '}
					{material.percentElongationAtBreak}
				</div>
				<div>
					{ADMIN_MATERIAL_TABLE_TEXTS.YOUNGS_MODULUS}: {material.youngsModulus}
				</div>
			</div>
		)
	}

	renderMaterialsTableData = () => {
		const { materials } = this.props
		return materials.map(material => {
			return [
				material.name,
				material.type,
				this.renderPropertiesField(material),
				this.renderStrengthField(material),
				material.organizationId || '',
				material.organizationOwnerEmail || '',
				material.id
			]
		})
	}

	deleteRow = id => {
		this.props.deleteMaterial(id)
	}

	setSearchPhrase = searchPhrase => {
		this.props.setSearchPhrase(searchPhrase)
	}

	render() {
		const {
			showNoMaterialsAlert,
			showPagination,
			pageNumber,
			isLastPage,
			showAdminMaterialsAlert,
			totalMaterials,
			limitMaterials,
			searchPhrase,
			loading
		} = this.props
		return (
			<AdminDataTable
				loading={loading}
				formTitle={ADMIN_MATERIALS_PAGE_HEADER}
				formSubTitle={ADMIN_MATERIALS_SUB_TITLE}
				tableHeadParams={materialParams}
				tableData={this.renderMaterialsTableData()}
				linkToRouteFunction={adminMaterialsRoute}
				showNoDataAlert={showNoMaterialsAlert}
				showPagination={showPagination}
				pageNumber={pageNumber}
				isLastPage={isLastPage}
				showAdminErrorAlert={showAdminMaterialsAlert}
				totalResults={totalMaterials}
				limitResults={limitMaterials}
				linkToEdit={adminMaterialsEditFormRoute}
				linkToNew={adminMaterialsNewFormRoute}
				deleteRow={this.deleteRow}
				setSearchPhrase={this.setSearchPhrase}
				searchPhrase={searchPhrase}
			/>
		)
	}
}

const mapStateToProps = ({ AdminMaterialsReducer }) => {
	const {
		materials,
		totalMaterials,
		materialsTableHead,
		materialsTableData,
		showNoMaterialsAlert,
		pageNumber,
		isLastPage,
		showPagination,
		limitMaterials,
		showAdminMaterialsAlert,
		searchPhrase,
		searchPhraseChanged,
		loading
	} = AdminMaterialsReducer
	return {
		materials,
		totalMaterials,
		materialsTableHead,
		materialsTableData,
		showNoMaterialsAlert,
		pageNumber,
		isLastPage,
		showPagination,
		limitMaterials,
		showAdminMaterialsAlert,
		searchPhrase,
		searchPhraseChanged,
		loading
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminMaterialsActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminMaterials)
