import Flexbox from 'Scenes/Components/FlexBox'
import { FC, memo } from 'react'

import { FormControlLabel } from '@material-ui/core'

import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import { Feature } from 'Services/models/Features'

import './AdminFeatureToggle.scss'

const LabelFormControl: any = FormControlLabel

interface IProps {
	tempFeatureToggle: any
	setTempFeatureToggle: Function
}
const AdminPanelFeaturesFilters: FC<IProps> = ({
	tempFeatureToggle,
	setTempFeatureToggle
}) => {
	const onCheckedBoxChange = (id: number) => {
		const tempChange = tempFeatureToggle.map(
			(feature: { id: number; on: any }) => {
				if (feature.id === id) {
					if (feature.on === null) {
						feature.on = false
					}
					return { ...feature, on: !feature.on }
				}
				return feature
			}
		)
		setTempFeatureToggle(tempChange)
	}

	const renderLabelWithHover = (name: String, description: string) => {
		return (
			<DetailsPopup
				data={description}
				popperDirection="bottom"
				isHover={true}
				popperClassName="admin-feature-toggle--popper"
				popperContactClassName="admin-feature-toggle--popper--data"
				inPortal
			>
				<div className="admin-feature-toggle-title">{name}</div>
			</DetailsPopup>
		)
	}

	const renderCheckBox = (feature: any) => {
		return (
			<LabelFormControl
				control={
					<CastorCheckbox
						indeterminate={feature.on === null}
						className="admin-feature-toggle-checkbox"
						checked={feature.on}
					/>
				}
				label={renderLabelWithHover(feature.actualName, feature.description)}
				onChange={() => {
					onCheckedBoxChange(feature.id)
				}}
				key={feature.id}
				classes={{
					label: 'admin-feature-toggle-label'
				}}
			/>
		)
	}
	return (
		<Flexbox display="flex" flexDirection="column">
			<div className="admin-feature-toggle">
				{tempFeatureToggle
					.sort((featureA: any, featureB: any) =>
						featureA.actualName?.localeCompare(featureB.actualName)
					)
					.map((feature: Feature) => renderCheckBox(feature))}
			</div>
		</Flexbox>
	)
}

export default memo(AdminPanelFeaturesFilters)
