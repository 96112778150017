import { getString } from 'Services/Strings/StringService'

export const materialInfo = {
	cost: getString('COST'),
	ultimateTensileStrength: getString('STRENGTH'),
	density: getString('WEIGHT'),
	percentElongationAtBreak: getString('ELONGATION'),
	maximumServiceTemperature: getString('TEMPERATURE')
}

export const panelConfiguration = 'panelConfiguration'
export const panelMap = 'panelMap'

export const addNewConfigurationId = 0
export const addPrinterConfigurationId = -1

export const solutionMapConstant = {
	cost: 'cost',
	score: 'score',
	strength: 'strength',
	weight: 'weight',
	stiffness: 'stiffness',
	elongation: 'elongation',
	heatResistance: 'heatResistance',
	conductivity: 'conductivity'
}

export const solutionMapScoreConstants = {
	low: 32.99,
	medium: 65.99,
	high: 100
}

export const yAxisDropDownValues = [
	{
		name: getString('COST'),
		iIcon: '',
		value: 'cost',
		units: ''
	}
]

export const xAxisDropDownValues = [
	{
		name: getString('PROPERTIES_MATCH'),
		iIcon: getString('SCORE_TOOLTIP'),
		value: 'score',
		units: ''
	},
	{
		name: getString('STRENGTH'),
		iIcon: getString('STRENGTH_TOOLTIP'),
		value: 'strength',
		units: '[Mpa]'
	},
	{
		name: getString('WEIGHT'),
		iIcon: getString('WEIGHT_TOOLTIP'),
		value: 'weight',
		units: '[Kg]'
	},
	{
		name: getString('STIFFNESS'),
		iIcon: getString('STIFFNESS_TOOLTIP'),
		value: 'stiffness',
		units: '[Gpa]'
	},
	{
		name: getString('ELONGATION'),
		iIcon: getString('ELONGATION_TOOLTIP'),
		value: 'elongation',
		units: '[%]'
	},
	{
		name: getString('HEAT_RESISTANCE'),
		iIcon: getString('HEAT_RESISTANCE_TOOLTIP'),
		value: 'heatResistance',
		units: '[℃]'
	},
	{
		name: getString('THERMAL_CONDUCTIVITY'),
		iIcon: getString('THERMAL_CONDUCTIVITY_TOOLTIP'),
		value: 'conductivity',
		units: '[W/(m•°K)]'
	}
]

export const configurationOrder = [2, 1, 3, 4, null, 0]

export const defaultTabOpen = 0

export const exponentialLineColor = '#00e296'
export const dotsColor = '#008ffb'
export const fillAreaColor = 'rgba(255,255,107,0.5)'

export const seriesNames = {
	bestChoices: 2,
	alternativeSolutions: 1,
	exponentialLine: 0,
	highlightedArea: 3
}
export const fadeAnimation = { enter: 600, exit: 1000, appear: 1000 }
