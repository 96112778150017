import parseHTML from 'html-react-parser'
import { FC, memo, useMemo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import cx from 'classnames'

import { WarningMessageType } from './AdminLicenseScreenService'
import InfoBox from 'Scenes/Components/InfoBox'
import { getString } from 'Services/Strings/StringService'

interface LicenseInfoProps {
	info: string
	leftValue: number
	allValue: number
	type?: string
	unlimited?: boolean
}
const LicenseInfo: FC<LicenseInfoProps> = ({
	info,
	leftValue = 0,
	allValue = 0,
	type = '',
	unlimited = false
}) => {
	const { dataLoading } = useSelector(
		(state: RootStateOrAny) => state.AdminLicenseScreenReducer
	)
	const isWarning = useMemo(() => leftValue >= allValue, [leftValue, allValue])

	const warningMessage = useMemo(() => {
		if (!isWarning) return ''

		switch (type) {
			case WarningMessageType.user:
				return getString('ADMIN_PANEL_LICENSE_SCREEN_USER_ERROR')
			case WarningMessageType.part:
				return getString('ADMIN_PANEL_LICENSE_SCREEN_PART_ERROR')
			default:
				return ''
		}
	}, [type, isWarning])

	const all = useMemo(() => {
		if (dataLoading) return dataLoading
		if (unlimited) return getString('UNLIMITED')
		return allValue
	}, [dataLoading, allValue, unlimited])

	return (
		<p>
			<b>{info}:</b>
			<span className={cx('license-info-text', { red: isWarning })}>
				{parseHTML(
					getString('ADMIN_PANEL_LICENSE_SCREEN_OUT_OF').format(leftValue, all)
				)}
			</span>
			{warningMessage && (
				<InfoBox
					boxContact={warningMessage}
					iconClassName="cost-details-icon"
				/>
			)}
			<span className={cx('dot-loading', { animated: dataLoading })}>
				<span className="dot"></span>
				<span className="dot"></span>
				<span className="dot"></span>
			</span>
		</p>
	)
}

export default memo(LicenseInfo)
