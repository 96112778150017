import React, { FC, memo } from 'react'
import { MenuItem, Button } from '@material-ui/core'

import { CUSTOMIZE_IN_HOUSE_PRINTERS_TITLE } from '../../../../../Services/Strings'
import OverrideComponentHOC from '../../../../../themes/OverrideComponentHOC'

const MenuItemTSX: any = MenuItem
const ButtonTSX: any = Button

interface IProps {
  onClick: Function
  component: any
  to: string
  menuItemClassName: string
  buttonClassName: string
}

const CustomizeInhouseMenuItem: FC<IProps> = ({
  onClick,
  component,
  to,
  menuItemClassName,
  buttonClassName
}) => (
  <MenuItemTSX
    onClick={() => onClick}
    component={component}
    to={to}
    className={menuItemClassName}
  >
    <ButtonTSX className={buttonClassName} variant="text" size="small">
      {CUSTOMIZE_IN_HOUSE_PRINTERS_TITLE}
    </ButtonTSX>
  </MenuItemTSX>
)

export default memo(OverrideComponentHOC(CustomizeInhouseMenuItem))
