import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'

export function useFeatureToggledEnvironment(DEAFULT_ENV: any, features: any) {
  const [selectedEnvironment, setSelectedEnvironment] = useState(DEAFULT_ENV)
  const [expression, setExpression] = useState('')
  const [showNewConfigurationAlert, setShowNewConfigurationAlert] =
    useState(false)
  const [showWarningAlert, setShowWarningWindow] = useState(false)
  const [isLiveEnvironmentAlert, setIsLiveEnvironmentAlert] = useState(false)
  const [currentFeatureToggle, setCurrentFeatureToggle] = useState(
    cloneDeep(features)
  )
  const [isIndeteminate, setIsIndeteminate] = useState(false)

  useEffect(() => {
    setIsIndeteminate(false)
    currentFeatureToggle.map(
      (feature: { id: number; on: any; name: string }) => {
        if (feature.on === null) {
          setIsIndeteminate(true)
        }
      }
    )
  }, [currentFeatureToggle])

  useEffect(() => {
    setCurrentFeatureToggle(cloneDeep(features))
  }, [features])

  return {
    selectedEnvironment,
    expression,
    showNewConfigurationAlert,
    showWarningAlert,
    isLiveEnvironmentAlert,
    currentFeatureToggle,
    isIndeteminate,
    setSelectedEnvironment,
    setExpression,
    setShowNewConfigurationAlert,
    setShowWarningWindow,
    setIsLiveEnvironmentAlert,
    setCurrentFeatureToggle
  }
}
