import { FC, memo, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Divider } from '@material-ui/core'
import Filter from '@material-ui/icons/FilterList'

import { usePartReducer } from '../../PartAnalysisSelector'
import { filters } from 'Scenes/Components/FilterPartsGrid/filterPartsEnum'
import { filterSolutionsProperty } from 'Scenes/Components/FilterPartsGrid/FilterPartsService'
import { FilteredCheckbox } from 'Scenes/Components/FilterPartsGrid/FiltersForParts'
import FilterTags from 'Scenes/Components/FilterPartsGrid/FilterTags'
import Button from 'Scenes/Components/thirdParty/CreativeTim/components/CustomButtons/Button'
import { onChangeSolutionMapFilter } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/MainPartAnalysisActions'
import { getString } from 'Services/Strings/StringService'

const ButtonTSX: any = Button
const FilterIconTSX: any = Filter
const DividerTSX: any = Divider

interface IProps {}

const ConfigurationSolutionFilter: FC<IProps> = () => {
	const dispatch = useDispatch()
	const [open, setOpen] = useState(false)
	const {
		alternativeSolutionsFilter,
		initialAlternativeSolutions,
		alternativeSolutions
	} = usePartReducer()

	const toggleFilterMenu = useCallback((open: boolean) => {
		setOpen(open)
	}, [])

	const handleChange = (value: string) => {
		dispatch(onChangeSolutionMapFilter(value))
	}

	const solutionsLength = useMemo(() => {
		if (alternativeSolutions.length !== initialAlternativeSolutions.length) {
			return `${alternativeSolutions.length}/ ${initialAlternativeSolutions.length}`
		}
		return `${initialAlternativeSolutions.length}`
	}, [alternativeSolutions, initialAlternativeSolutions])

	return (
		<div className="filters-for-part solution">
			<div
				className="filters-for-part__menu-box solution"
				data-qa="data-qa-filter-btn"
			>
				<ButtonTSX
					color="primary"
					className="border-button"
					onClick={() => toggleFilterMenu(!open)}
				>
					<FilterIconTSX />
					{getString('FILTER_SOLUTIONS')} ({solutionsLength})
				</ButtonTSX>
				<div
					className="filters-for-part__menu solution"
					style={{ display: open ? '' : 'none' }}
					onMouseLeave={() => {
						setOpen(false)
					}}
				>
					<div className="filters-for-part__menu-body solution">
						<div data-qa="parts-filters-property-section solution">
							<h4>{getString('PROPERTY')}</h4>
							<div>
								{filterSolutionsProperty.map((value: string, idx: number) => (
									<FilteredCheckbox
										key={value + idx}
										value={value}
										title={value}
										idx={idx}
										handleChange={handleChange}
										selectedFilterValues={alternativeSolutionsFilter}
									/>
								))}
							</div>
						</div>
					</div>
					<DividerTSX />
					<div
						className="filters-for-part__option clear-all"
						onClick={() => handleChange(filters.ALL)}
					>
						<div>{getString('CLEAR_SELECTION')}</div>
					</div>
				</div>
			</div>
			<FilterTags
				handleChange={(value: string) => handleChange(value)}
				selectedFilterValues={alternativeSolutionsFilter}
				assemblyProjects={undefined}
			/>
		</div>
	)
}

export default memo(ConfigurationSolutionFilter)
