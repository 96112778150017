import { isNull, isUndefined } from 'lodash'

import { Action } from '../../../global actions/ActionModels'
import {
	UPLOAD_LICENSE_ADD_FILE,
	UPLOAD_LICENSE_ERROR,
	UPLOAD_LICENSE_FILE_END,
	UPLOAD_LICENSE_FILE_FINISH,
	UPLOAD_LICENSE_FILE_START,
	UPLOAD_LICENSE_SCRIPT
} from '../../../global actions/types'
import { setJsonItemToLocalStorage } from 'Services/LocalStorageService'

export interface CastorAlertInitialState {
	readonly loading: boolean
	readonly initialRun: boolean
	readonly file: any
	readonly preparedToSend: any
	readonly error: string
	readonly isLicense: boolean
}

const initialState: CastorAlertInitialState = {
	loading: false,
	initialRun: true,
	file: {},
	preparedToSend: {},
	error: '',
	isLicense: false
}

const LicenseReducer = (
	state = initialState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case UPLOAD_LICENSE_FILE_START:
			return {
				...state,
				loading: true
			}

		case UPLOAD_LICENSE_SCRIPT:
			if (payload != null) {
				setJsonItemToLocalStorage('license', payload)
			}
			return {
				...state,
				initialRun: false,
				isLicense: payload
			}

		case UPLOAD_LICENSE_FILE_END:
			return {
				...initialState,
				isLicense: state.isLicense
			}

		case UPLOAD_LICENSE_FILE_FINISH:
			return {
				...state,
				initialRun: false,
				loading: false
			}

		case UPLOAD_LICENSE_ADD_FILE:
			return {
				...state,
				file: payload.file,
				preparedToSend: payload.preparedToSend,
				error: ''
			}

		case UPLOAD_LICENSE_ERROR:
			return {
				...state,
				initialRun: false,
				error: payload.error
			}

		default:
			return state
	}
}
export default LicenseReducer
