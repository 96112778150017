import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as MaterialCostActions from './MaterialCostActions'
import {
	MATERIAL_COST_FIELDS_ALERT,
	MATERIAL_COST_INPUTS_LBS_EXPLENATION,
	MATERIAL_COST_INPUTS_WEIGHT_UNIT
} from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import { Danger } from '../../../Components/thirdParty/CreativeTim/components'
import MaterialPricePicker from '../MaterialPricePicker'
import CustomizeMaterialSelector from './CustomizeMaterialSelector'
import Flexbox from 'Scenes/Components/FlexBox'

import customizeMaterialStyle from './CustomizeMaterialCost.css'

class MaterialCostInputs extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedWeightUnit: MATERIAL_COST_INPUTS_WEIGHT_UNIT[0],
			showMaterialExplenation: false
		}
	}

	renderAlert = () => {
		if (this.props.showMaterialCostAlert) {
			return <Danger>{MATERIAL_COST_FIELDS_ALERT}</Danger>
		}
		return <div />
	}

	showMaterialExplenation = () => {
		if (this.state.showMaterialExplenation) {
			return (
				<span
					style={customizeMaterialStyle.weightSelectorSpan}
					className="weight-selector-info"
				>
					{MATERIAL_COST_INPUTS_LBS_EXPLENATION}
				</span>
			)
		}
		return <div />
	}

	onWeightSelectorChange = e => {
		this.setState({
			selectedWeightUnit: e.target.value,
			showMaterialExplenation:
				e.target.value === MATERIAL_COST_INPUTS_WEIGHT_UNIT[1]
		})
	}

	onMaterialPriceChange = e => {
		this.props.onMaterialPriceChange(e.target.value)
	}

	onMaterialCostAdd = () => {
		let {
			onMaterialCostAdd,
			materialPrice,
			selectedMaterialName,
			selectedMaterial
		} = this.props
		if (this.state.selectedWeightUnit === MATERIAL_COST_INPUTS_WEIGHT_UNIT[1]) {
			// lbs selected
			materialPrice = (materialPrice * 0.4535923).toFixed(2)
		}
		onMaterialCostAdd(materialPrice, selectedMaterialName, selectedMaterial)
	}

	isButtonDisabled = () => {
		const { materialPrice, selectedMaterialName } = this.props
		if (parseFloat(materialPrice) > 0 && selectedMaterialName) {
			return false
		}
		return true
	}

	render() {
		const { materialCostLoading, materialPrice, materialPriceError } =
			this.props
		return (
			<div style={customizeMaterialStyle.materialCost}>
				<div style={{ opacity: 0.6 }} className="material-costs-input-hint">
					{getString('MATERIAL_COST_INPUTS_TITLE')}
				</div>
				<div style={customizeMaterialStyle.inputsDiv}>
					<Flexbox
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
						className="customize-material"
					>
						<CustomizeMaterialSelector />
						<MaterialPricePicker
							materialPrice={materialPrice}
							onMaterialPriceChange={this.onMaterialPriceChange}
							onWeightSelectorChange={this.onWeightSelectorChange}
							selectedWeightUnit={this.state.selectedWeightUnit}
							materialPriceError={materialPriceError}
						/>

						<ButtonWithLoader
							disabled={this.isButtonDisabled()}
							size={30}
							top={20}
							style={{ padding: '5px 20px', width: 'unset' }}
							loading={materialCostLoading}
							onClick={this.onMaterialCostAdd}
						>
							{getString('ADD')}
						</ButtonWithLoader>
					</Flexbox>
				</div>
				{this.showMaterialExplenation()}
				{this.renderAlert()}
			</div>
		)
	}
}

const mapStateToProps = ({ user, MaterialCostReducer }) => {
	const { printerTechnologiesIds } = user
	const {
		materialPrice,
		materialCostLoading,
		selectedPrinterTechnology,
		selectedMaterialName,
		materialCosts,
		showMaterialCostAlert,
		selectedMaterial,
		materialPriceError
	} = MaterialCostReducer
	return {
		printerTechnologiesIds,
		materialPrice,
		materialCostLoading,
		selectedPrinterTechnology,
		selectedMaterialName,
		materialCosts,
		selectedMaterial,
		showMaterialCostAlert,
		materialPriceError
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(Object.assign({}, MaterialCostActions), dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialCostInputs)
