import { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { MenuItem, Select } from '@material-ui/core'

import * as AdminFeatureToggleAction from './AdminFeatureToggleAction'
import { Danger } from '../../../Components/thirdParty/CreativeTim/components'
import AdminFeatureToggleAlert from './AdminFeatureToggleAlert'
import { AdminFeatureToggleInitialState } from './AdminFeatureToggleReducer'
import { useFeatureToggledEnvironment } from './AdminFeatureToggleService'
import AdminPanelFeaturesFilters from './AdminPanelFeaturesFilters'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import './AdminFeatureToggle.scss'

const NavBarAndMaterialTSX: any = NavBarAndMaterial
const SelectTsx: any = Select
const MenuItemTsx: any = MenuItem
const DEAFULT_ENV = getString('ADMIN_PANEL_FEATURE_TOGGLE_CURRENT_ENVIRONMENT')
const BACKUP_ENV = getString('ADMIN_PANEL_FEATURE_TOGGLE_BACKUP_ENVIRONMENT')

interface IProps {
	features: any
	featureConfigurations: any
	environmentName: any
	onUpdateLiveEnvironment: Function
	onUpdateFeatureConfiguration: Function
	onCreateNewConfiguration: Function
	setFeatureToggle: Function
}
interface IReduxStore {
	AdminFeatureToggleReducer: AdminFeatureToggleInitialState
}

const AdminFeatureToggle: FC<IProps> = ({
	features,
	featureConfigurations,
	environmentName,
	onUpdateLiveEnvironment,
	onUpdateFeatureConfiguration,
	onCreateNewConfiguration,
	setFeatureToggle
}) => {
	const featureToggleOn = Feature.isFeatureOn(FeatureComponentId.FEATURE_TOGGLE)
	const {
		selectedEnvironment,
		expression,
		showNewConfigurationAlert,
		showWarningAlert,
		isLiveEnvironmentAlert,
		currentFeatureToggle,
		isIndeteminate,
		setSelectedEnvironment,
		setExpression,
		setShowNewConfigurationAlert,
		setShowWarningWindow,
		setIsLiveEnvironmentAlert,
		setCurrentFeatureToggle
	} = useFeatureToggledEnvironment(DEAFULT_ENV, features)

	useEffect(() => {
		setFeatureToggle()
	}, [])

	const onUpdateLiveConfigurationButton = () => {
		setIsLiveEnvironmentAlert(true)
		setShowWarningWindow(true)
	}
	const onUpdateConfigurationButton = () => {
		setShowWarningWindow(true)
	}
	const onCreateNewConfigurationButton = () => {
		setShowNewConfigurationAlert(true)
	}

	const onCreateConfigurationClick = () => {
		onCreateNewConfiguration({
			configuration_name: expression,
			features: currentFeatureToggle
		})
		setShowNewConfigurationAlert(false)
		setExpression('')
		setSelectedEnvironment(expression)
		setCurrentFeatureToggle(currentFeatureToggle)
	}

	const onUpdateConfigurationClick = () => {
		const featureId: any = featureConfigurations.filter(
			(res: any) => res.configuration_name === selectedEnvironment
		)[0].id
		onUpdateFeatureConfiguration({
			configuration_name: selectedEnvironment,
			features: currentFeatureToggle,
			id: featureId
		})

		setShowWarningWindow(false)
	}

	const onUpdateEnvironmentClick = () => {
		onUpdateLiveEnvironment(currentFeatureToggle)
		if (selectedEnvironment !== DEAFULT_ENV) {
			onUpdateConfigurationClick()
		}
		setShowWarningWindow(false)
		setIsLiveEnvironmentAlert(false)
	}

	const onChangeEnvironment = (value: any) => {
		setSelectedEnvironment(value)
		setCurrentFeatureToggle(features)
		if (value !== DEAFULT_ENV) {
			const newEnv = featureConfigurations.filter(
				(res: any) => res.configuration_name === value
			)[0].features
			setCurrentFeatureToggle(newEnv)
		}
	}

	const renderEnvironmentName = () => {
		return environmentName.map((data: any) => {
			return (
				<MenuItemTsx key={data.id} value={data.name}>
					{data.name}
				</MenuItemTsx>
			)
		})
	}

	const renderButtons = () => {
		return (
			<>
				<ButtonWithLoader
					className="admin-feature-toggle-back-button"
					color="primary"
					onClick={() => onUpdateLiveConfigurationButton()}
					disabled={selectedEnvironment === BACKUP_ENV || isIndeteminate}
				>
					{getString('ADMIN_PANEL_FEATURE_TOGGLE_UPDATE_ENVIRONMENT')}
				</ButtonWithLoader>
				<ButtonWithLoader
					className="admin-feature-toggle-back-button"
					color="primary"
					onClick={() => onUpdateConfigurationButton()}
					disabled={
						selectedEnvironment === DEAFULT_ENV ||
						selectedEnvironment === BACKUP_ENV ||
						isIndeteminate
					}
				>
					{getString('ADMIN_PANEL_FEATURE_TOGGLE_UPDATE_CONFIGURATION')}
				</ButtonWithLoader>
				<ButtonWithLoader
					className="admin-feature-toggle-back-button"
					color="primary"
					onClick={() => onCreateNewConfigurationButton()}
				>
					{getString('ADMIN_PANEL_FEATURE_TOGGLE_CREATE_CONFIGURATION')}
				</ButtonWithLoader>
			</>
		)
	}

	const renderFeatureToggleContent = () => {
		return (
			<>
				<SelectTsx
					value={selectedEnvironment}
					renderValue={() => selectedEnvironment}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						onChangeEnvironment(e.target.value)
					}
				>
					<MenuItemTsx value="0" disabled>
						{getString('CHOOSE_ENVIRONMENT')}
					</MenuItemTsx>
					{renderEnvironmentName()}
				</SelectTsx>
				<AdminPanelFeaturesFilters
					tempFeatureToggle={currentFeatureToggle}
					setTempFeatureToggle={setCurrentFeatureToggle}
				/>
				{renderButtons()}
				{isIndeteminate && (
					<Danger className="admin-feature-toggle-helper-text">
						{'Update is not available if some features are not defined'}
					</Danger>
				)}
				{selectedEnvironment === BACKUP_ENV && (
					<Danger className="admin-feature-toggle-helper-text">
						{'Can`t update on back up environment'}
					</Danger>
				)}
				<AdminFeatureToggleAlert
					expression={expression}
					isLiveEnvironment={isLiveEnvironmentAlert}
					showNewConfigurationAlert={showNewConfigurationAlert}
					showWarningAlert={showWarningAlert}
					setShowNewConfigurationAlert={setShowNewConfigurationAlert}
					setShowWarningWindow={setShowWarningWindow}
					setIsLiveEnvironment={setIsLiveEnvironmentAlert}
					setExpression={setExpression}
					onCreateConfigurationClick={onCreateConfigurationClick}
					onUpdateConfigurationClick={onUpdateConfigurationClick}
					onUpdateEnvironmentClick={onUpdateEnvironmentClick}
				></AdminFeatureToggleAlert>
			</>
		)
	}

	return (
		<>
			{featureToggleOn && (
				<NavBarAndMaterialTSX
					title={getString('ADMIN_PANEL_FEATURE_TOGGLE_TITLE')}
				>
					<div>
						<CastorForm
							formTitle={getString('ADMIN_PANEL_FEATURE_TOGGLE_TITLE')}
							content={renderFeatureToggleContent()}
							style={{ maxWidth: 'unset' }}
						/>
					</div>
				</NavBarAndMaterialTSX>
			)}
		</>
	)
}

function mapStateToProps(state: IReduxStore) {
	const { AdminFeatureToggleReducer } = state
	return {
		...AdminFeatureToggleReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...AdminFeatureToggleAction }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(memo(AdminFeatureToggle))
