import { Printer } from 'Services/models/IPrinter'

export interface IPrinterCost {
	printer: Printer
	price: string
	printerHourlyCost: string
	defaultLayerThickness: string
	edit?: boolean
	valueLabel?: string
	validationStatus?: Record<PrinterCostKey, ValidationStatus>
	validationMessage?: Record<PrinterCostKey, string>
	disabled?: Record<PrinterCostKey, boolean>
}

export enum PrinterCostKey {
	printerCost = 'price',
	printerHourlyCost = 'printerHourlyCost'
}

export enum ValidationStatus {
	success = 'success',
	error = 'error'
}
