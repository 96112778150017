import React, { FC, memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { ConfigurationResultTypes } from '../../MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'
import {
	onChangeMaterial,
	onFilterFeatureClick,
	onPrioritiesFeatureClick,
	onPrioritiesModalCancel,
	onPrioritiesModalConfirm,
	onPrioritizeFieldChange,
	onPriorityRemove,
	onPriorityToggleChange
} from '../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import SolutionConfigureFilters from '../../MainPartAnalysis/SolutionAnalysis/SolutionConfigure/SolutionConfigureFeatures/SolutionConfigureFilters'
import SolutionConfigurePrioritizeForm from '../../MainPartAnalysis/SolutionAnalysis/SolutionConfigure/SolutionConfigurePrioritizeForm'
import SolutionPrioritizeFeaturesModal from '../../MainPartAnalysis/SolutionAnalysis/SolutionPrioritizeFeaturesModal'
import { useSolutionReducer, useUserReducer } from '../PartAnalysisSelector'
import { getPrinterTechnologies } from '../PartAnalysisService'
import {
	changeAdvancedFilters,
	setupAdvancedFilters
} from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import FieldWithLabel from 'Scenes/Components/FieldWithLabel'
import MaterialSelector from 'Scenes/Components/MaterialSelector/MaterialSelector'
import { defaultMetal } from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IConfiguration } from 'Services/models/IConfiguration'
import { Material } from 'Services/models/IMaterial'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import './index.scss'

const { defaultMaterial } = getTheme()

interface ConfigurationCreateNewProps {
	configuration: IConfiguration
	setQuantity: Function
	quantity: number
	configurationName: string
	setConfigurationName: Function
	defaultSelectedMaterial: Material
	isPart2d: boolean
	isSpecifiedQuantity: any
	filters: Array<Record<string, any>>
}

let ConfigurationCreateNew: FC<ConfigurationCreateNewProps> = ({
	configuration,
	setQuantity,
	quantity,
	configurationName,
	setConfigurationName,
	defaultSelectedMaterial,
	isPart2d,
	isSpecifiedQuantity,
	filters
}) => {
	const dispatch = useDispatch()
	const showDefaultMaterialFilters = Feature.isFeatureOn(
		FeatureComponentId.DEFAULT_MATERIAL_FILTERS
	)
	const configurationId = configuration.id
	const { printers, priorities } = useUserReducer()
	const {
		solutionPriorities,
		showSolutionPriorities,
		showPrioritiesFeaturesModal,
		tempSolutionPrioritiesToggles
	} = useSolutionReducer(configurationId)

	useEffect(() => {
		dispatch(setupAdvancedFilters(configuration.id))
	}, [])

	useEffect(() => {
		if (showDefaultMaterialFilters) {
			dispatch(
				changeAdvancedFilters(
					configuration.id,
					defaultSelectedMaterial?.defaultFilters,
					true
				)
			)
		}
	}, [defaultSelectedMaterial])

	const withPrinterTechnology = getPrinterTechnologies(filters)

	return (
		<div className="add-new-configuration--form">
			<div className="configuration--name">
				<p>{getString('CREATE_NEW_CONFIGURATION_INFORMATION')}</p>
				<FieldWithLabel
					labelName={getString('CREATE_NEW_CONFIGURATION_NAME')}
					fieldPlaceholder={getString('CREATE_NEW_MY_CONFIGURATION')}
					fieldValue={configurationName}
					fieldOnChange={value => setConfigurationName(value)}
				/>
			</div>
			<div className="configuration--options">
				<div className="grey-block basic">
					{isSpecifiedQuantity && <p>{getString('GENERAL_PROPERTIES')}</p>}
					{!isPart2d && isSpecifiedQuantity && (
						<div className="quantity">
							<FieldWithLabel
								qaDataElementName="data-qa-quantity-input-filed"
								isNumberField
								labelName={getString('UPLOAD_PROJECT_YEARLY_PROD')}
								fieldValue={quantity}
								fieldClassName="short"
								fieldOnChange={value => setQuantity(value)}
							/>
						</div>
					)}
					<div className="material">
						<MaterialSelector
							hideMaterialNameSelector={Feature.isFeatureOn(
								FeatureComponentId.AUTO_NAME_MATERIAL_SELECTOR
							)}
							hiddenMaterialNameSelector={true}
							isButtonSelector={true}
							onChange={(material: Material) => {
								dispatch(onChangeMaterial(material?.name, configurationId))
							}}
							disabled={false}
							style={{ maxWidth: 50 }}
							defaultMaterialForTypeArr={[defaultMetal, defaultMaterial]}
							defaultMaterial={defaultSelectedMaterial}
							selectClassName="upload-project-material-selector-field"
							rootSelectClassName="upload-project-material-selector-root"
						/>
					</div>
				</div>
				<div className="grey-block property">
					<SolutionConfigurePrioritizeForm
						isNewDesign
						className="solution-analysis-object-content-configure-widget"
						configurationId={configurationId}
						onPriorityRemove={(id, priority, solutionPriorities) =>
							dispatch(onPriorityRemove(id, priority, solutionPriorities))
						}
						priorities={priorities}
						solutionPriorities={solutionPriorities}
						showSolutionPriorities={showSolutionPriorities}
						onPrioritizeFieldChange={(
							id,
							priorityName,
							priorityValue,
							solutionPriorities
						) =>
							dispatch(
								onPrioritizeFieldChange(
									id,
									priorityName,
									priorityValue,
									solutionPriorities
								)
							)
						}
						onPrioritiesFeatureClick={() =>
							dispatch(onPrioritiesFeatureClick(configurationId))
						}
					/>
				</div>
				<div className="grey-block filter">
					<SolutionConfigureFilters
						printerTechnology={withPrinterTechnology}
						userHavePrinters={!!printers.length}
						configurationId={configurationId}
						originalMaterial={configuration.material}
						className="solution-analysis-object-content-configure-widget solution-analysis-object-content-configure-widget-filters"
						onFilterFeatureClick={() =>
							dispatch(onFilterFeatureClick(configurationId))
						}
						isDesktopPrinterConfiguration={
							configuration.resultType === ConfigurationResultTypes.Desktop
						}
					/>
				</div>
			</div>
			<SolutionPrioritizeFeaturesModal
				showPrioritiesFeaturesModal={showPrioritiesFeaturesModal}
				onPrioritiesModalCancel={(id: number) =>
					dispatch(onPrioritiesModalCancel(id))
				}
				onPrioritiesModalConfirm={(
					id,
					priorities,
					tempSolutionPrioritiesToggles,
					solutionPriorities
				) =>
					dispatch(
						onPrioritiesModalConfirm(
							id,
							priorities,
							tempSolutionPrioritiesToggles,
							solutionPriorities
						)
					)
				}
				priorities={priorities}
				tempSolutionPrioritiesToggles={tempSolutionPrioritiesToggles}
				configuration={configuration}
				onPriorityToggleChange={(configurationId, priority) =>
					dispatch(onPriorityToggleChange(configurationId, priority))
				}
				solutionPriorities={solutionPriorities}
			/>
		</div>
	)
}

export default memo(ConfigurationCreateNew)
