import { benefitType, ISolutionBenefit } from '../../../Services/models/ISolutionBenefit'

export const BENEFIT_ORDER = [benefitType.costSaving, benefitType.timeSaving, benefitType.complexityScore, benefitType.weightReduction, benefitType.buyToFly]

export const sortBenefits = (benefits: any[]) => {
  return benefits.sort((a: any, b: any) => {
    return BENEFIT_ORDER.findIndex(element =>
      a.type.includes(element)) - BENEFIT_ORDER.findIndex(element =>
      b.type.includes(element))
  })
}
