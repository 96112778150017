import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import * as AdminPrinterNamesActions from './AdminPrinterNamesActions'
import {
	adminPrinterNamesEditFormRoute,
	adminPrinterNamesRoute,
	adminPrintersNewFormRoute
} from '../../../../Services/routeFuncs'
import {
	ADMIN_PRINTER_NAMES_MOVE_TO_PRINTERS,
	ADMIN_PRINTER_NAMES_PAGE_HEADER,
	ADMIN_PRINTER_NAMES_PARAMS,
	ADMIN_PRINTER_NAMES_SUB_TITLE
} from '../../../../Services/Strings'
import { Button } from '../../../Components/thirdParty/CreativeTim/components'
import AdminDataTable from '../AdminDataTable/AdminDataTable'
import NewAdminPrinterName from './NewAdminPrinterName'
import { PRINTER_NAMES_ADMIN_ROUTE } from 'Services/Constants/RoutesConstants'

import '../adminHome.scss'

const printerNameParams = ADMIN_PRINTER_NAMES_PARAMS

class AdminPrinterNames extends Component {
	componentDidMount() {
		this.props.clearSelectedPrinterName()
		const {
				match,
				setupAdminPrinterNamesPage,
				onAdminPrinterNamesUnmounted,
				limitPrinterNames,
				searchPhrase
			} = this.props,
			urlPage = parseInt(match.params['page']) || 1

		setupAdminPrinterNamesPage(searchPhrase, urlPage, limitPrinterNames)

		this.unListen = this.props.history.listen((location, action) => {
			if (
				action === 'PUSH' &&
				!location.pathname.includes(PRINTER_NAMES_ADMIN_ROUTE)
			) {
				onAdminPrinterNamesUnmounted()
			}
		})
	}

	componentDidUpdate = prevProps => {
		const {
			match,
			pageNumber,
			setupAdminPrinterNamesPage,
			limitPrinterNames,
			searchPhrase
		} = this.props
		let urlPage = parseInt(match.params['page']) || 1
		const isSearchChange = searchPhrase !== prevProps.searchPhrase
		if (isSearchChange && urlPage !== 1) {
			this.props.history.push(PRINTER_NAMES_ADMIN_ROUTE + `/1`)
		} else if (urlPage === 1 && isSearchChange) {
			setupAdminPrinterNamesPage(searchPhrase, pageNumber, limitPrinterNames)
		}
	}

	componentWillUnmount() {
		this.unListen()
	}

	onMoveToPrinterClick = printerName => {
		this.props.setPrinterNameInPrinterReducer(printerName)
	}

	renderMoveToPrinters = printerName => {
		return (
			<Link to={adminPrintersNewFormRoute()}>
				<Button
					size="sm"
					color="primary"
					onClick={this.onMoveToPrinterClick.bind(this, printerName)}
				>
					{ADMIN_PRINTER_NAMES_MOVE_TO_PRINTERS}
				</Button>
			</Link>
		)
	}

	renderPrinterNamesTableData = () => {
		const { printerNames } = this.props
		return printerNames.map(printerName => {
			return [
				printerName.name,
				printerName.company,
				this.renderMoveToPrinters(printerName),
				printerName.id
			]
		})
	}

	deleteRow = id => {
		this.props.deletePrinterName(id)
	}

	setSearchPhrase = searchPhrase => {
		this.props.setSearchPhrase(searchPhrase)
	}

	render() {
		const {
			showNoPrinterNamesAlert,
			showPagination,
			pageNumber,
			isLastPage,
			showAdminPrinterNamesAlert,
			totalPrinterNames,
			limitPrinterNames,
			searchPhrase,
			loading
		} = this.props
		return (
			<AdminDataTable
				loading={loading}
				formTitle={ADMIN_PRINTER_NAMES_PAGE_HEADER}
				formSubTitle={ADMIN_PRINTER_NAMES_SUB_TITLE}
				tableHeadParams={printerNameParams}
				tableData={this.renderPrinterNamesTableData()}
				linkToRouteFunction={adminPrinterNamesRoute}
				showNoDataAlert={showNoPrinterNamesAlert}
				showPagination={showPagination}
				pageNumber={pageNumber}
				isLastPage={isLastPage}
				showAdminErrorAlert={showAdminPrinterNamesAlert}
				totalResults={totalPrinterNames}
				limitResults={limitPrinterNames}
				linkToEdit={adminPrinterNamesEditFormRoute}
				deleteRow={this.deleteRow}
				setSearchPhrase={this.setSearchPhrase}
				NewComponent={NewAdminPrinterName}
				searchPhrase={searchPhrase}
			/>
		)
	}
}

const mapStateToProps = ({ AdminPrinterNamesReducer }) => {
	const {
		printerNames,
		totalPrinterNames,
		printerNamesTableHead,
		printerNamesTableData,
		showNoPrinterNamesAlert,
		pageNumber,
		isLastPage,
		showPagination,
		limitPrinterNames,
		showAdminPrinterNamesAlert,
		searchPhrase,
		loading
	} = AdminPrinterNamesReducer
	return {
		printerNames,
		totalPrinterNames,
		printerNamesTableHead,
		printerNamesTableData,
		showNoPrinterNamesAlert,
		pageNumber,
		isLastPage,
		showPagination,
		limitPrinterNames,
		showAdminPrinterNamesAlert,
		searchPhrase,
		loading
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminPrinterNamesActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPrinterNames)
