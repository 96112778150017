import React, { memo, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'
import { gerUrlParams } from 'Services/Utils/urlTools'
import { LOGIN_ROUTE } from '../../Services/Constants/RoutesConstants'
import { ConfirmationRequests } from '../../Services/models/ConfirmationRequests'
import { confirmOrganization, confirmUser } from '../../Services/Network'
import * as AuthActions from './AuthActions'

interface IStoreProps {
  AuthReducer: any
}
interface IProps {
  confirmUserAccount: Function
}
const UserConfirmation: React.FC<IProps> = ({ confirmUserAccount }) => {
  useEffect(() => {
    function setup(): any {
      const { token, confirmation_type} = gerUrlParams(['token', 'confirmation_type'])
      return { token, confirmation_type }
    }

    async function confirm(token: string, confirmation_type: string) {
      switch (confirmation_type) {
        case ConfirmationRequests.USER_ACCOUNT_CONFIRMATION:
          await confirmUser(token)
          window.location.href = LOGIN_ROUTE
          break

        case ConfirmationRequests.ORGANIZATION_CONFIRMATION:
          confirmOrganization(token)
          window.location.href = LOGIN_ROUTE
          break
        default:
          break
      }
    }
    const { token, confirmation_type } = setup()
    confirm(token, confirmation_type)
  }, [])
  return <div>User Account Confirmed</div>
}

function mapStateToProps({ AuthReducer }: IStoreProps) {
  return {
    ...AuthReducer
  }
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
  bindActionCreators({ ...AuthActions }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(UserConfirmation))
