import React, { FC, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowForward from '@material-ui/icons/ArrowForward'
import FileDownload from '@material-ui/icons/GetApp'
import ZoomIn from '@material-ui/icons/ZoomIn'
import ZoomOut from '@material-ui/icons/ZoomOut'
import RotateRightIcon from '@material-ui/icons/RotateRight'

import { getString } from 'Services/Strings/StringService'
import { Button } from '../../../../../../../Components/thirdParty/CreativeTim/components'

import {
  defaultRotate,
  defaultNumber,
  pageStep,
  zoomStep,
  rotateStep,
  maxRotate
} from './constants'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import './PdfViewer.scss'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const ArrowBackTSX: any = ArrowBack
const ArrowForwardTSX: any = ArrowForward
const FileDownloadTSX: any = FileDownload
const ZoomInTSX: any = ZoomIn
const ZoomOutTSX: any = ZoomOut
const RotateRightIconTSX: any = RotateRightIcon
const DocumentTSX: any = Document
const PageTSX: any = Page

interface IProps {
  partImageSrc: string
  fileUrl?: string
  showModal: boolean
  onCloseModal: Function
}

const PdfViewer: FC<IProps> = ({
  partImageSrc,
  fileUrl,
  showModal,
  onCloseModal
}) => {
  const [rotate, setRotate] = useState(defaultRotate)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(defaultNumber)
  const [scalePage, setScalePage] = useState(defaultNumber)

  const onDocumentLoadSuccess = ({ numPages, ...rest }: any) => {
    setNumPages(numPages)
  }

  const changePage = (offset: number) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  const onScalePage = (scale: number) => {
    setScalePage(prevScale => prevScale + scale)
  }

  const previousPage = () => {
    changePage(-pageStep)
  }

  const nextPage = () => {
    changePage(pageStep)
  }

  const onZoomOut = () => {
    onScalePage(-zoomStep)
  }

  const onZoomIn = () => {
    onScalePage(zoomStep)
  }

  const onRotate = () => {
    setRotate(prevRotate =>
      prevRotate < maxRotate ? prevRotate + rotateStep : defaultRotate
    )
  }

  return (
    <CastorAlert
      headerTitle={getString('PREVIEW_PDF')}
      onCancel={() => onCloseModal()}
      show={showModal}
      showConfirm={false}
      showCancel={false}
      showFooter={false}
      alertClass="pdf-preview--alert"
      alertBodyClass="pdf-preview--alert-body"
    >
      <DocumentTSX
        className="pdf-preview--body"
        imageResourcesPath={partImageSrc}
        file={{ url: fileUrl }}
        onLoadSuccess={onDocumentLoadSuccess}
        rotate={rotate}
      >
        <PageTSX
          className="pdf-preview--body__pdf"
          height="1190"
          width="800"
          scale={scalePage}
          pageNumber={pageNumber}
          renderTextLayer={false}
        />
        <div className="pdf-preview--body__zoom">
          <Button size="sm" color="primary" onClick={onZoomIn}>
            <ZoomInTSX />
          </Button>
          <Button size="sm" color="primary" onClick={onZoomOut}>
            <ZoomOutTSX />
          </Button>
          <Button size="sm" color="primary" onClick={onRotate}>
            <RotateRightIconTSX />
          </Button>
          <Button
            size="sm"
            color="primary"
            type="link"
            href={fileUrl}
            target="_blank"
          >
            <FileDownloadTSX />
          </Button>
        </div>
        <div className="pdf-preview--body__buttons">
          <Button
            size="sm"
            color="primary"
            type="button"
            disabled={pageNumber === 1}
            onClick={previousPage}
          >
            <ArrowBackTSX />
          </Button>
          <p data-qa='data-qa-pdf-preview-pagination-text'>{getString('PREVIEW_PDF_PAGE').format(pageNumber, numPages)}</p>
          <Button
          data-qa='data-qa-pdf-preview-next-page-btn'
            size="sm"
            color="primary"
            type="button"
            disabled={pageNumber === numPages}
            onClick={nextPage}
          >
            <ArrowForwardTSX />
          </Button>
        </div>
      </DocumentTSX>
    </CastorAlert>
  )
}

export default PdfViewer
