import React, { memo, FC } from 'react'
import './DataTable.scss'

interface Props {
  text: string | number
  headerClassName: string
  data: any
}

const DataTableTextHeaderWithDescription: FC<Props> = ({
  text,
  headerClassName,
  data: { className = '', description, descriptionClassName, exponent }
}) => (
  <div>
    <p className={`data-table-header ${headerClassName} ${className}`}>
      {text}
    </p>
    <div
      className={`data-table-row-text-description ${descriptionClassName} ${className}`}
    >
      {description}
    </div>
  </div>
)

export default memo(DataTableTextHeaderWithDescription)
