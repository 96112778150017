import { isInteger } from 'lodash'
import Numeral from 'numeral'

/**
 * Convert a long amount to a short amount with a currency mark.
 * @param {string | undefined} num Optional. Amount
 * @param {string} format Format template for converting the amount..
 * @returns Short amount with a currency mark or 0.
 */
export const currencyFormatter = (
  num: number,
  format: string
): number | string => {
  return num ? Numeral(num).format(format).toUpperCase() : 0
}

export const getFixedSize = (size: number, fixed: number): string => {
  return isInteger(size) ? size.toFixed(0) : size.toFixed(fixed)
}
