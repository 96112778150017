import React, { FC } from 'react'
import TransparentButton from '../../../../Components/TransparentButton'

import '../CusomizeCO2.scss'
import { getString } from '../../../../../Services/Strings/StringService';

interface IEditApply {
  co2Key: string
  isError: boolean
  isEdit: boolean
  isEditAll: boolean
  shouldReset: boolean
  errorMessage: any
  onEditToggle: Function
  onUpdateCO2Data: Function
}

const EditApplyButton: FC<IEditApply> = ({
 co2Key,
 isEdit,
 isEditAll,
 isError,
 onEditToggle,
 shouldReset,
 onUpdateCO2Data,
 errorMessage
}) => {
  const isEditOn = isEdit || isEditAll
  const resetData = true

  return (
    <>
      <div>
        <TransparentButton
          onClick={() => onEditToggle(co2Key, !isEditOn)}
          className="custom-input--action"
        >
          {isEditOn ? getString('CANCEL') : getString('EDIT')}
        </TransparentButton>
        {
          !isEditOn && shouldReset &&
          <TransparentButton
            onClick={() => onUpdateCO2Data(resetData, co2Key)}
            className="custom-input--action"
          >
            {getString('RESET')}
          </TransparentButton>
        }
        {
          isEditOn ?
            <TransparentButton
              disabled={isError}
              onClick={() => onUpdateCO2Data(false, co2Key)}
              className="custom-input--action"
            >
              {getString('APPLY')}
            </TransparentButton> : <></>
        }
      </div>
      {isError ? <p className="customize__input-error center">{errorMessage[co2Key]}</p>: ''}
    </>
  )
}

export default EditApplyButton
