import { ChangeEvent, FC, memo, useState } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import * as AdminStringsActions from './AdminStringsActions'
import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import CastorExcelExport from '../../../Components/CastorExcelExport/CastorExcelExport'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import { AdminStringsInitialState } from './AdminStringsReducer'
import Flexbox from 'Scenes/Components/FlexBox'

interface IProps extends AdminStringsInitialState {
	exportStringsToExcel: () => any
	onImportStrings: (fileSelected: File | null | undefined) => any
}

interface IStoreProps {
	AdminStringsReducer: AdminStringsInitialState
}

const AdminStrings: FC<IProps> = ({
	exportStringsToExcel,
	importLoading,
	onImportStrings
}) => {
	const [fileSelected, setFileSelected] = useState<File | null | undefined>(
		null
	)
	const renderSettingsContent = () => (
		<div>
			<CastorExcelExport onClick={exportStringsToExcel} />
			<Flexbox alignItems="center">
				<input
					type="file"
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						setFileSelected(e?.target?.files && e.target.files[0])
					}
					accept=".csv"
				/>
				<ButtonWithLoader
					style={{ width: 'unset' }}
					color="primary"
					onClick={() => onImportStrings(fileSelected)}
					buttonSize="sm"
					loading={importLoading}
				>
					Import (CSV only)
				</ButtonWithLoader>
			</Flexbox>
		</div>
	)

	return (
		<NavBarAndMaterial title={'Strings'}>
			<div>
				<CastorForm
					formTitle="Strings"
					content={renderSettingsContent()}
					style={{ maxWidth: 'unset' }}
				/>
			</div>
		</NavBarAndMaterial>
	)
}

const mapStateToProps = ({ AdminStringsReducer }: IStoreProps) => {
	return {
		...AdminStringsReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...AdminStringsActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(memo(AdminStrings))
