import { cloneDeep } from 'lodash'

import {
	ERROR_FETCHING_VARIABLES,
	NEW_POST_PROCESS_CHANGED,
	POST_PROCESS_CHANGED,
	START_FETCHING_VARIABLES,
	TECHNOLOGY_VARIABLES_FETCHED,
	TOGGLE_FUNCTION_EDITOR_MODAL,
	USER_POST_PROCESSES_SET_UP
} from 'global actions/types'

const INITIAL_STATE = {
	postProcesses: [],
	postProcessesInitialValues: [],
	postProcessTemplate: {},
	functionEditorModalIsOpen: false,
	functionEditorAlertType: '',
	postProcessInFunctionEditor: null,
	modalIsLoading: false,
	allFunctionStringParams: []
}

const CustomizePostProcessesReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case USER_POST_PROCESSES_SET_UP: {
			const { postProcesses, postProcessTemplate, allFunctionStringParams } =
				action.payload
			return {
				...state,
				postProcesses,
				postProcessesInitialValues: cloneDeep(postProcesses),
				postProcessTemplate,
				allFunctionStringParams
			}
		}
		case POST_PROCESS_CHANGED: {
			const { postProcesses } = action.payload
			return {
				...state,
				postProcesses
			}
		}
		case NEW_POST_PROCESS_CHANGED: {
			const { updatedPostProcess } = action.payload
			return {
				...state,
				postProcessTemplate: updatedPostProcess
			}
		}
		case TOGGLE_FUNCTION_EDITOR_MODAL: {
			const { open, postProcessInFunctionEditor, functionEditorAlertType } =
				action.payload
			return {
				...state,
				functionEditorModalIsOpen: open,
				postProcessInFunctionEditor,
				functionEditorAlertType
			}
		}
		case START_FETCHING_VARIABLES: {
			return {
				...state,
				modalIsLoading: true
			}
		}
		case TECHNOLOGY_VARIABLES_FETCHED:
		case ERROR_FETCHING_VARIABLES: {
			return {
				...state,
				modalIsLoading: false
			}
		}
		default:
			return state
	}
}

export default CustomizePostProcessesReducer
