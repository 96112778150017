import * as strings from '../Services/Strings'
import { getTheme } from './getTheme'

const theme = getTheme()
const s: any = strings

export const getThemeString = (stringType: string) => {
  const themeString = theme.strings[stringType]
  return themeString != null ? themeString : s[stringType]
}
