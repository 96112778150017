import React, { FC, memo, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { deleteCustomFunctionAlertOpen } from './CostingFunctionEditorActions'
import { getUserReadablePrintingTechnologiesNames } from './CostingFunctionEditorService'
import { ICustomFunction } from './CustomizeCostingFunctionTypes'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	customFunction: ICustomFunction
	printingTechnologies: IPrintingTechnology[]
}

const CustomFunctionRow: FC<IProps> = ({
	customFunction,
	printingTechnologies
}) => {
	const dispatch = useDispatch()

	const functionString = customFunction?.functionString
	const printingTechnologiesUserReadableNames = useMemo(
		() =>
			getUserReadablePrintingTechnologiesNames(
				printingTechnologies,
				customFunction
			),
		[customFunction, printingTechnologies]
	)

	const handleDeleteFunction = () =>
		dispatch(deleteCustomFunctionAlertOpen(customFunction.id))

	return (
		<div
			key={customFunction?.id}
			className="costing-function-editor--functions-list--row"
			data-qa="data-qa-custom-function-row"
		>
			<div
				className="costing-function-editor--functions-list--cell"
				title={customFunction?.userReadableName}
			>
				{customFunction?.userReadableName}
			</div>
			<div
				className="costing-function-editor--functions-list--cell"
				title={printingTechnologiesUserReadableNames}
			>
				{printingTechnologiesUserReadableNames}
			</div>
			<div
				className="costing-function-editor--functions-list--cell"
				title={functionString}
			>
				{functionString}
			</div>
			<div className="costing-function-editor--functions-list--cell action-buttons">
				<ButtonWithLoader
					color="transparent"
					className="costing-function-editor--create-function--transparent-button"
					title={getString('DELETE')}
					onClick={handleDeleteFunction}
					qaDataElementName="data-qa-delete-custom-function"
				>
					<IconFactory iconName="remove" />
					{getString('DELETE')}
				</ButtonWithLoader>
			</div>
		</div>
	)
}

export default memo(CustomFunctionRow)
