import {
	GET_PROJECT_CLUSTERS,
	GET_PROJECT_CLUSTERS_FAILED,
	GET_PROJECT_CLUSTERS_SUCCESS,
	HANDLE_LOADER
} from '../../../global actions/types'
import {
	getProjectPrintableClusters,
	getProjectsInfo
} from '../../../Services/Network'

export const setupClustersOverview = projectId => {
	return async dispatch => {
		dispatch({ type: HANDLE_LOADER, payload: 1 })
		try {
			const { data } = await getProjectsInfo(projectId)
			const isBundle = data?.data?.isBundle
			let bundleId = isBundle ? projectId : ''

			dispatch({ type: GET_PROJECT_CLUSTERS, payload: projectId })
			const response = await getProjectPrintableClusters(projectId, bundleId)
			dispatch({ type: GET_PROJECT_CLUSTERS_SUCCESS, payload: response.data })
		} catch (error) {
			console.log(error)
			dispatch({ type: GET_PROJECT_CLUSTERS_FAILED, payload: error.message })
		}
		dispatch({ type: HANDLE_LOADER, payload: -1 })
	}
}
