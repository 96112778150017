import React, { FC } from 'react'

import cx from 'classnames'

import IconFactory from '../StarIcon/IconFactory'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { pageBackground } from 'Services/colors'

import './CastorInfoScreen.scss'

type IconType = 'success' | 'warning' | 'thumbnail'
type IProps = {
	buttonText: string
	iconType: IconType
	onButtonClick: () => void
	headerText?: string
	descriptionText?: string
	wrapperClassName?: string
}

const CastorInfoScreen: FC<IProps> = ({
	buttonText,
	headerText,
	descriptionText,
	iconType,
	onButtonClick,
	wrapperClassName
}) => {
	return (
		<div
			style={{ backgroundColor: pageBackground }}
			className={cx('castor-info-screen', wrapperClassName)}
		>
			<div className="castor-info-screen--block">
				<IconFactory iconName={iconType} />
				<h3 className="castor-info-screen--block--heading">{headerText}</h3>
				<p className="castor-info-screen--block--description">
					{descriptionText}
				</p>
				<Button
					color="primary"
					onClick={onButtonClick}
					className="castor-info-screen--block--button"
					type="button"
				>
					{buttonText}
				</Button>
			</div>
		</div>
	)
}

export default CastorInfoScreen
