import React, { FC, memo, useState } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import * as SolutionAnalysisActions from '../../SolutionAnalysisActions'
import { ActionWithPayload } from '../../../../../../../global actions/ActionModels'
import { IStateExtractorState } from '../../../../../../../Services/HOR/InstanceStateExtractor'
import { OrientationData } from '../../../../../../../Services/models/IOrinetationData'
import { Part } from '../../../../../../../Services/models/IPart'
import { PartPrintIssue } from '../../../../../../../Services/models/PartPrintIssue'
import { MainPartAnalysisInitialState } from '../../../MainPartAnalysisReducer'
import { SolutionAnalysisInitialState } from '../../SolutionAnalysisReducer'
import SolutionPartImagesTabs from './SolutionPartImagesTabs'
import Flexbox from 'Scenes/Components/FlexBox'
import { IClusterPart } from 'Services/models/ICluster'

interface IProps {
	configurationId: number
	configuration: any
}

interface IReduxStore {
	MainPartAnalysisReducer: MainPartAnalysisInitialState
	CastorWallThicknessDemandReducer: any
	SolutionAnalysisReducer: IStateExtractorState
}

interface IReduxProps {
	part: Part | null
	cluster: IClusterPart | null
	// showHeatMap: any
	showHeatMapButton: boolean
	imageUrl: string
	show3dButton: boolean
	pollerStarted: boolean
	configurationImageUrl: string
	chosenOrientation: OrientationData | null
	solution: any
	batchSize: number
	tourStepIndex: number
	configurationChanged: boolean
	tempSolutionPostProcessToggles: any
	solutionPartImageIndex: number
	freezeConfiguration: boolean
	customConfiguration: boolean
	disableConfiguration: boolean
	loadingOrientation: boolean
	configurationPrintIssues: PartPrintIssue[]
	// onShowHeatToggle: (id: number) => Action<any>
	show3dModalIframe: (id: number, isCluster: boolean) => any
	onGeometryAnalysisReviewAndFixesClick: (
		...onGeometryAnalysisReviewAndFixesClickParams: Parameters<
			typeof SolutionAnalysisActions.onGeometryAnalysisReviewAndFixesClick
		>
	) => ActionWithPayload<any>
	hide3dModalIframe: (id: number) => any
	onChangeOrientationClick: (
		id: number,
		partSolution: any,
		batchSize: number,
		configurationChanged: boolean,
		updatedPostProcess: any,
		part: Part | null,
		configuration: any,
		configurationPrintIssues: PartPrintIssue[],
		isMechanicalAnalysisCall?: boolean
	) => any
	onPartImageTabClicked: (id: number, chosenTabIndex: number) => any
	onChangeViewer: Function
}

const SolutionPartDetails: FC<IProps & IReduxProps> = ({
	part,
	configurationId,
	cluster,
	show3dModalIframe,
	hide3dModalIframe,
	showHeatMapButton,
	imageUrl,
	show3dButton,
	pollerStarted,
	configurationImageUrl,
	chosenOrientation,
	solution,
	batchSize,
	configurationChanged,
	tempSolutionPostProcessToggles,
	solutionPartImageIndex,
	configuration,
	tourStepIndex,
	freezeConfiguration,
	configurationPrintIssues,
	customConfiguration,
	disableConfiguration,
	onChangeOrientationClick,
	onPartImageTabClicked,
	onGeometryAnalysisReviewAndFixesClick,
	onChangeViewer,
	loadingOrientation
}) => {
	const [showHeatMap, setShowHeatMap] = useState(true)

	return (
		<Flexbox
			flexDirection="column"
			alignItems="flex-start"
			width="500px"
			marginRight="25px"
		>
			<SolutionPartImagesTabs
				configurationResult={configuration?.result}
				onChangeViewer={onChangeViewer}
				part={part}
				cluster={cluster}
				freezeConfiguration={freezeConfiguration}
				disableConfiguration={disableConfiguration}
				tourStepIndex={tourStepIndex}
				configurationId={configurationId}
				onPartImageTabClicked={onPartImageTabClicked}
				solutionPartImageIndex={solutionPartImageIndex}
				isCluster={!!cluster}
				chosenOrientation={chosenOrientation}
				rotatedOrientation={solution?.rotatedOrientation || false}
				customConfiguration={customConfiguration}
				onChooseOrientationClick={() =>
					onChangeOrientationClick(
						configurationId,
						solution,
						batchSize,
						configurationChanged,
						tempSolutionPostProcessToggles,
						part,
						configuration,
						configurationPrintIssues,
						true
					)
				}
				showImageLoader={pollerStarted}
				showHeatMap={showHeatMap}
				partImageSrc={
					configurationImageUrl
						? showHeatMap
							? configurationImageUrl
							: imageUrl
						: imageUrl
				}
				onShowHeatToggle={() => setShowHeatMap(!showHeatMap)}
				hide3dModalIframe={() => hide3dModalIframe(configurationId)}
				show3dModalIframe={() =>
					onGeometryAnalysisReviewAndFixesClick(configurationId)
				}
				show3dButton={show3dButton}
				showHeatMapButton={showHeatMapButton}
				configurationVolume={configuration.volume}
				loadingOrientation={loadingOrientation}
			/>
		</Flexbox>
	)
}

const mapStateToProps = (
	{
		MainPartAnalysisReducer: {
			part,
			cluster,
			imageUrl,
			partPrintIssues,
			solutions
		},
		CastorWallThicknessDemandReducer: { pollerStarted },
		SolutionAnalysisReducer
	}: IReduxStore,
	ownProps: IProps
) => {
	const {
		showHeatMapButton,
		show3dButton,
		configurationImageUrl,
		chosenOrientation,
		batchSize,
		configurationChanged,
		tempSolutionPostProcessToggles,
		solutionPartImageIndex,
		tourStepIndex,
		customConfiguration,
		disableConfiguration,
		loadingOrientation
	}: SolutionAnalysisInitialState =
		SolutionAnalysisReducer.states[ownProps.configurationId] ||
		new SolutionAnalysisInitialState()

	return {
		part,
		cluster,
		configurationImageUrl,
		showHeatMapButton,
		imageUrl,
		show3dButton,
		pollerStarted,
		chosenOrientation,
		solution: solutions.find(
			s =>
				s.id ===
				(ownProps.configuration.solution && ownProps.configuration.solution.id)
		),
		batchSize,
		configurationChanged,
		tempSolutionPostProcessToggles,
		solutionPartImageIndex,
		configurationId: ownProps.configurationId,
		configuration: ownProps.configuration,
		customConfiguration,
		configurationPrintIssues: partPrintIssues.filter(
			partPrintIssue =>
				partPrintIssue.configurationId === ownProps.configurationId ||
				!partPrintIssue.configurationId
		),
		tourStepIndex,
		disableConfiguration,
		loadingOrientation
	}
}
const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...SolutionAnalysisActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(SolutionPartDetails)
)
