import React, { FC } from 'react'

import CustomizeThreadContent from 'Scenes/Home/Customize/CustomizeSolutionFilters/CustomizeThreadContent'
import { getString } from 'Services/Strings/StringService'

const GeometryAnalysisPreferences: FC = () => {
	return (
		<div className="solution-filtering--ga-preferences">
			<div className="solution-filtering--ga-preferences--title">
				{getString('ONBOARDING_CUSTOMIZE_GEOMETRY_ANALYSIS_PREFERENCES_TITLE')}
			</div>
			<CustomizeThreadContent />
		</div>
	)
}

export default GeometryAnalysisPreferences
