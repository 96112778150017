export enum WeightReductionGen2Type {
  FAST = 'FAST',
  COMPLEX = 'COMPLEX'
}

export enum WeightReductionMode {
  off = 'off',
  full = 'full',
  surfaceSelection = 'surfaceSelection'
}
