import {
	COST_AND_LEAD_BUTTON_ACTIONS,
	COST_AND_LEAD_LABELS
} from '../../../../Services/Strings'
import { PrinterCostKey } from './CustomizePrinterProps/CustomizePrinterCostsTypes'
import {
	CUSTOMIZE_MAXIMUM_PRINTER_HOURLY_PRICE,
	CUSTOMIZE_MAXIMUM_PRINTER_PRICE,
	CUSTOMIZE_MINIMUM_PRINTER_HOURLY_PRICE,
	CUSTOMIZE_MINIMUM_PRINTER_PRICE
} from 'Services/Constants'
import { getString } from 'Services/Strings/StringService'

export const setupValueLabels = (settingsData, costAndLeadNames) => {
	let valueLabels = {}
	const defaultSettings = settingsData.defaultSettings
	const userSettings = settingsData.userSettings
	for (const key in settingsData.defaultSettings) {
		if (costAndLeadNames.hasOwnProperty(key)) {
			let isDefault = !userSettings[key]
			valueLabels[`valueLabel_${key}`] = renderValueWithLabel(
				userSettings[key] || defaultSettings[key] || '',
				costAndLeadNames[key].label,
				isDefault
			)
		}
	}
	return valueLabels
}

export const renderValueWithLabel = (value, label, isDefault) => {
	const defaultString = isDefault ? ' (Default)' : ''
	const currencySign = value ? '$' : ''
	switch (label) {
		case COST_AND_LEAD_LABELS.RATE:
			return `${currencySign}${value}${defaultString}`
		case COST_AND_LEAD_LABELS.YEARS:
		case COST_AND_LEAD_LABELS.HOURS:
		case COST_AND_LEAD_LABELS.DAYS:
		case COST_AND_LEAD_LABELS.MINUTES:
			return `${value} ${label}${defaultString}`
		case COST_AND_LEAD_LABELS.PERCENTAGE:
			return `${value}% ${defaultString}`
		case COST_AND_LEAD_LABELS.KM:
			return `${value} ${defaultString}`
		default:
			return ''
	}
}

export const getNewActionButtonTexts = (
	settingsData,
	actionButtonTexts,
	inputKey
) => {
	const userSettings = settingsData.userSettings
	let newActionButtonTexts = { ...actionButtonTexts }
	if (inputKey === 'all') {
		for (const key in userSettings) {
			newActionButtonTexts = {
				...newActionButtonTexts,
				[key]: userSettings[key]
					? [COST_AND_LEAD_BUTTON_ACTIONS.DEFAULT]
					: [COST_AND_LEAD_BUTTON_ACTIONS.EDIT]
			}
		}
	} else {
		newActionButtonTexts = {
			...newActionButtonTexts,
			[inputKey]: userSettings[inputKey]
				? [COST_AND_LEAD_BUTTON_ACTIONS.DEFAULT]
				: [COST_AND_LEAD_BUTTON_ACTIONS.EDIT]
		}
	}

	return newActionButtonTexts
}

export const shouldPrinterButtonDisable = (
	printerId,
	selectedPrinterCost,
	selectedPrinterHourlyCost,
	incorrectEntryForLayerThickness,
	incorrectEntryForPrice,
	incorrectEntryForHourlyPrice,
	zeroForEntry
) =>
	!printerId ||
	!selectedPrinterCost ||
	selectedPrinterHourlyCost ||
	incorrectEntryForLayerThickness ||
	incorrectEntryForPrice ||
	incorrectEntryForHourlyPrice ||
	zeroForEntry

export const getPrinterCostInputErrorMessage = (
	printerCostKey,
	value,
	isIncorrectNumberValue
) => {
	const valueIsNotEmpty = value !== ''
	let errorMessage = ''
	switch (printerCostKey) {
		case PrinterCostKey.printerCost: {
			if (
				valueIsNotEmpty &&
				(value < CUSTOMIZE_MINIMUM_PRINTER_PRICE ||
					value > CUSTOMIZE_MAXIMUM_PRINTER_PRICE)
			) {
				errorMessage = getString('VALUE_BETWEEN').format(
					CUSTOMIZE_MINIMUM_PRINTER_PRICE,
					CUSTOMIZE_MAXIMUM_PRINTER_PRICE
				)
			}
			if (isIncorrectNumberValue) {
				errorMessage = getString('INCORRECT_NUMBER_VALIDATION')
			}
			break
		}
		case PrinterCostKey.printerHourlyCost: {
			if (
				valueIsNotEmpty &&
				(value < CUSTOMIZE_MINIMUM_PRINTER_HOURLY_PRICE ||
					value > CUSTOMIZE_MAXIMUM_PRINTER_HOURLY_PRICE)
			) {
				errorMessage = getString('VALUE_BETWEEN').format(
					CUSTOMIZE_MINIMUM_PRINTER_HOURLY_PRICE,
					CUSTOMIZE_MAXIMUM_PRINTER_HOURLY_PRICE
				)
			}
			if (isIncorrectNumberValue) {
				errorMessage = getString('INCORRECT_NUMBER_VALIDATION')
			}
			break
		}
		default: {
			break
		}
	}
	return errorMessage
}

export const getMaterialCostInputErrorMessage = value => {
	let errorMessage = ''
	if (Number(value) <= 0) {
		errorMessage = getString('MATERIAL_COST_PRICE_ZERO_VALIDATION')
	}
	if (!value) {
		errorMessage = getString('INCORRECT_NUMBER_VALIDATION')
	}
	return errorMessage
}

export const getEditableRowActionButtons = (editMode, value) => {
	if (editMode) {
		return [
			COST_AND_LEAD_BUTTON_ACTIONS.CANCEL,
			COST_AND_LEAD_BUTTON_ACTIONS.APPLY
		]
	} else if (!editMode && !value) {
		return [COST_AND_LEAD_BUTTON_ACTIONS.EDIT]
	} else {
		return [COST_AND_LEAD_BUTTON_ACTIONS.DEFAULT]
	}
}
