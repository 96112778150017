import React, { FC, memo } from 'react'
import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel'

import { getString } from 'Services/Strings/StringService'

interface ProjectLifeCycleAlertProps {
	changeToleranceAlert: () => void
	showToleranceAlert: boolean
	imgSrc: string
}

const ProjectLifeCycleAlert: FC<ProjectLifeCycleAlertProps> = ({
	changeToleranceAlert,
	showToleranceAlert,
	imgSrc
}) => {
	return (
		<AutoRotatingCarousel
			label={getString('UPLOAD_PROJECT_SCENARIO_ALERT_TITLE')}
			open={showToleranceAlert}
			autoplay={false}
			onClose={changeToleranceAlert}
			classes={{
				root: 'upload-form-tolerance-info-alert-root',
				footer: 'upload-form-tolerance-info-alert-footer',
				dots: 'upload-form-tolerance-info-alert-dots',
				slide: 'upload-form-tolerance-info-alert-slide'
			}}
			ButtonProps={{
				fullWidth: true,
				size: 'small',
				disableRipple: true,
				disableFocusRipple: true,
				disabled: true,
				classes: {
					root: 'upload-form-tolerance-info-alert-title',
					label: 'upload-form-tolerance-info-alert-title-label'
				}
			}}
		>
			<Slide
				media={
					<img
						src={imgSrc}
						width="90%"
						alt={imgSrc}
						style={{ marginTop: '120px' }}
					/>
				}
				style={{ backgroundColor: 'white' }}
				mediaBackgroundStyle={{
					backgroundColor: 'white',
					height: '95%',
					width: '95%'
				}}
				title=""
				subtitle=""
			/>
		</AutoRotatingCarousel>
	)
}

export default memo(ProjectLifeCycleAlert)
