import { memo, FC } from 'react'
import { IChainBenefits } from '../../../../../../../../../../Services/models/IChainBenefits'
import CastorAlert from '../../../../../../../../../Components/alerts/CastorAlert'
import ChainBenefitsFormContent from './ChainBenefitsFormContent'
import { SUBMIT } from '../../../../../../../../../../Services/Strings'

interface IProps {
  onCancel: Function
  chainBenefits: IChainBenefits
  onBenefitValueChange: Function
  chainBenefitEditType: string
  show: boolean
  configuration: any
  chainBenefitsFormLoading: boolean
  onConfirm: () => any
}

const ChainBenefitsForm: FC<IProps> = ({
  show,
  onConfirm,
  onCancel,
  chainBenefits,
  configuration,
  onBenefitValueChange,
  chainBenefitEditType,
  chainBenefitsFormLoading
}) => {
  return (
    <CastorAlert
      headerTitle={chainBenefitEditType}
      confirmOptionalText={SUBMIT}
      onCancel={onCancel}
      show={show}
      loadingCalculation={chainBenefitsFormLoading}
      onConfirm={() => onConfirm()}
    >
      <ChainBenefitsFormContent
        chainBenefits={chainBenefits}
        chainBenefitEditType={chainBenefitEditType}
        configuration={configuration}
        onBenefitValueChange={onBenefitValueChange}
      />
    </CastorAlert>
  )
}

export default memo(ChainBenefitsForm)
