import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { STEP_ID, TM_MATERIAL_IDS } from '../../constants'
import { goToNextStep } from '../../OnboardingWizardActions'
import { useStep } from '../../OnboardingWizardHooks'
import OnboardingStepFrame from '../OnboardingStepFrame'
import AddTMMaterialButton from './AddTMMaterialButton'
import CustomizeUserMaterialsTable from 'Scenes/Home/Customize/CustomizeUserMaterials/NewTMMaterial/CustomizeUserMaterialsTable'

import './TMMaterial.scss'

const TMMaterialsComponents = {
	[TM_MATERIAL_IDS.ADD_TM_MATERIAL_BUTTON]: <AddTMMaterialButton />,
	[TM_MATERIAL_IDS.TM_MATERIALS_TABLE]: <CustomizeUserMaterialsTable />
}

const TMMaterial: FC = () => {
	const { steps, parentSteps, currentStep } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { userId, userMaterials: customizeUserMaterials } = useSelector(
		(state: RootStateOrAny) => state.CustomizeUserMaterialsReducer
	)
	const { userMaterials } = useSelector((state: RootStateOrAny) => state.user)

	const { step, stepContent, childSteps } = useStep(
		steps,
		STEP_ID.TM_MATERIAL,
		true
	)
	const dispatch = useDispatch()

	const changesInCurrentStep = userId
		? customizeUserMaterials.length > 0
		: userMaterials.length > 0

	const onContinueClick = () => {
		const stepIndexInChildSteps =
			childSteps && childSteps.findIndex(childStep => childStep.id === step.id)
		const stepID =
			stepIndexInChildSteps !== childSteps.length - 1
				? childSteps[stepIndexInChildSteps + 1].id
				: undefined
		dispatch(goToNextStep(parentSteps, currentStep, stepID))
	}

	return (
		<OnboardingStepFrame
			step={step}
			childSteps={childSteps}
			onNextClickAnotherAction={onContinueClick}
			changesInCurrentStep={changesInCurrentStep}
		>
			<div className="tm-material--wrapper">
				{stepContent.map((component, index) => {
					return (
						<React.Fragment key={component.id}>
							{
								TMMaterialsComponents[
									component.id as keyof typeof TMMaterialsComponents
								]
							}
						</React.Fragment>
					)
				})}
			</div>
		</OnboardingStepFrame>
	)
}

export default TMMaterial
