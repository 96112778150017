import React, { FC } from 'react'

import cx from 'classnames'
import moment from 'moment'

import { getPDFLogo } from '../../Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisHeader/SolutionExportPdf/SolutionPdfService'

interface IProps {
	userCompany: string
	headerText: string
	headerDetailsText: string
	isSolutionPage?: boolean
}

export const HeaderPdf: FC<IProps> = ({
	userCompany,
	headerText,
	headerDetailsText,
	isSolutionPage = false
}) => {
	const currentDateTime = moment().format('LLL')
	const imageSrc = getPDFLogo(userCompany)

	return (
		<div className="page-header">
			<div className="line-pdf top" />
			<div
				className={cx('text-section', {
					solution: isSolutionPage
				})}
			>
				<div>
					<h1>{headerText}</h1>
					<h1>{headerDetailsText}</h1>
					<p>{currentDateTime}</p>
				</div>
				<div>
					<img src={imageSrc} alt="logo" />
				</div>
			</div>
			<div className="line-pdf" />
		</div>
	)
}

export default HeaderPdf
