import partImageAndDescriptionStyle from '../../../assets/jss/material-dashboard-pro-react/components/partImageAndDescriptionStyle'
import { FC, memo, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { withStyles } from '@material-ui/core'

import * as CastorLockedPartsAction from './CastorLockedPartsAction'
import { LOCKED_PARTS_BANNER } from '../../../Services/Strings'
import { getThemeString } from '../../../themes/getThemeString'
import Card from '../Card/Card'
import { CastorBannerType } from '../CastorBanner/CastorBannerType.enum'
import { IconLockCreditCard } from '../CastorIcons/iconCreditCard'
import { CastorLockedPartsInitialState } from './CastorLockedPartsReducer'
import LockedPartsButtons from './LockedPartsButtons'
import { getString } from 'Services/Strings/StringService'

import './CastorLockedParts.scss'

const LockedPartsButtonsTsx: any = LockedPartsButtons
const partImageAndDescriptionStyleTSX: any = partImageAndDescriptionStyle

interface IReduxStore {
	CastorLockedPartsReducer: CastorLockedPartsInitialState
	GlobalReducer: any
	user: any
	userEmail?: string
}

interface IReduxProps {
	amountOfLockedParts?: number | any
	classes?: any
	userEmail: string
  userName: string
	buttonText?: string
	isOnPrem?: boolean
	contactUsEmail?: string
	lightUser?: boolean
	setText: Function
	buttonOnClick: Function
}

const cardBody = (
	amountOfLockedParts: number | string,
	loadingCalculation: boolean,
	disabledButton: boolean,
	buttonText: string,
	buttonOnClick: Function,
	cardImage: any = '',
	userEmail: string,
	isOnPrem?: boolean,
	contactUsEmail?: string,
	lightUser?: boolean,
  userName?: string
) => {
	const type = lightUser
		? CastorBannerType.CONTACT_US
		: CastorBannerType.UPGRADE_NOW
	return (
		<div className={`lock-part-body ${cardImage}`}>
			<div className="lock-part-body-contact">
				<IconLockCreditCard />
				<div className="lock-part-body-contact-text lock-part-body-contact-text-1">
					{LOCKED_PARTS_BANNER[0]}
				</div>
				<div className="lock-part-body-contact-text lock-part-body-contact-text-2">
					<b>{amountOfLockedParts}</b>{' '}
					{amountOfLockedParts == 1
						? LOCKED_PARTS_BANNER[5]
						: LOCKED_PARTS_BANNER[1]}
				</div>
				<div className="lock-part-body-contact-text lock-part-body-contact-text-3">
					{lightUser ? (
						getString('LOCKED_PARTS_BANNER_LIGHT')
					) : (
						<>
							<div>{LOCKED_PARTS_BANNER[2]}</div>
							<div>
								{amountOfLockedParts}{' '}
								{amountOfLockedParts == 1
									? LOCKED_PARTS_BANNER[4]
									: LOCKED_PARTS_BANNER[3]}
							</div>
							<div>{getThemeString('LOCKED_PARTS_BANNER_6')}</div>
							<div>{getThemeString('LOCKED_PARTS_BANNER_7')}</div>
						</>
					)}
				</div>
				{!isOnPrem && (
					<LockedPartsButtonsTsx
						componentId={3}
						disabled={disabledButton}
						className={
							disabledButton
								? 'lock-part-body-button lock-part-body-button-disabled'
								: 'lock-part-body-button'
						}
						color="primary"
						buttonText={buttonText}
						onClick={(e: Event) => {
							e.preventDefault()
							buttonOnClick(userEmail, contactUsEmail, type, userName)
						}}
						userEmail={userEmail}
					/>
				)}
			</div>
		</div>
	)
}

const CastorLockedParts: FC<IReduxProps & CastorLockedPartsInitialState> = ({
	amountOfLockedParts,
	loadingCalculation,
	disabledButton,
	buttonText,
	userEmail,
  userName,
	classes: { cardImage },
	isOnPrem,
	contactUsEmail,
	lightUser,
	buttonOnClick,
	setText
}) => {
	useEffect(() => {
		const type = lightUser
			? CastorBannerType.CONTACT_US
			: CastorBannerType.UPGRADE_NOW
		setText(type)
	}, [lightUser])

	return (
		<Card
			customCardContent={cardBody(
				amountOfLockedParts,
				loadingCalculation,
				disabledButton,
				buttonText,
				buttonOnClick,
				cardImage,
				userEmail,
				isOnPrem,
				contactUsEmail,
				lightUser,
        userName
			)}
		/>
	)
}

const mapStateToProps = ({
	CastorLockedPartsReducer,
	user: {
		userDetails: { email, name }
	},
	GlobalReducer: { isOnPrem }
}: IReduxStore) => {
	return {
		...CastorLockedPartsReducer,
		userEmail: email,
		userName: name,
		isOnPrem
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CastorLockedPartsAction }, dispatch)

export default memo(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(partImageAndDescriptionStyleTSX)(CastorLockedParts))
)
