import orderBy from 'lodash/orderBy'

import { PushNotificationContent } from './PushNotificationService'
import {
	FAILED_PUSH_NOTIFICATION,
	FAILED_SILENT_PUSH_NOTIFICATION,
	GET_PUSH_NOTIFICATIONS,
	HANDLE_NOTIFICATION_CONNECTION,
	HANDLE_NOTIFICATION_CONNECTION_SILENT,
	LAST_MESSAGE_TIMESTAMP_UPDATE,
	PUSH_NOTIFICATIONS_POPUP
} from './PushNotificationTypes'
import { Action } from 'global actions/ActionModels'
import { SETUP_SSE_CONNECTIONS } from 'global actions/types'

export class PushNotificationInitialState {
	readonly pushNotifications: Array<PushNotificationContent> = []
	readonly isOpen: boolean = false
	readonly isReconnect: boolean = false
	readonly isSilentReconnect: boolean = false
	readonly newMessagesAmount: number = 0
	readonly failedConnection: boolean = false
	readonly failedSilentConnection: boolean = false
	readonly lastMessageTimeStamp: Record<string, number> = {}
}

const initialPushNotificationState: PushNotificationInitialState =
	new PushNotificationInitialState()

const PushNotificationReducer = (
	state = initialPushNotificationState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case GET_PUSH_NOTIFICATIONS: {
			const { pushNotifications } = payload

			return {
				...state,
				pushNotifications: orderBy(
					pushNotifications,
					[
						obj => !obj.isViewed, // Sort by viewed property (true first)
						obj => obj.createdAt // Sort by name in descending order
					],
					['desc', 'desc']
				),
				newMessagesAmount: pushNotifications?.filter(
					(notification: any) => !notification.isViewed
				).length
			}
		}

		case PUSH_NOTIFICATIONS_POPUP: {
			return {
				...state,
				isOpen: payload.isOpen
			}
		}

		case HANDLE_NOTIFICATION_CONNECTION: {
			return {
				...state,
				isReconnect: payload
			}
		}

		case HANDLE_NOTIFICATION_CONNECTION_SILENT: {
			return {
				...state,
				isSilentReconnect: payload
			}
		}

		case FAILED_PUSH_NOTIFICATION: {
			return {
				...state,
				failedConnection: true
			}
		}

		case SETUP_SSE_CONNECTIONS: {
			return {
				...state,
				failedConnection: false,
				failedSilentConnection: false
			}
		}

		case FAILED_SILENT_PUSH_NOTIFICATION: {
			return {
				...state,
				failedSilentConnection: true
			}
		}

		case LAST_MESSAGE_TIMESTAMP_UPDATE: {
			return {
				...state,
				lastMessageTimeStamp: {
					...state.lastMessageTimeStamp,
					[payload.url]: payload.time
				}
			}
		}

		default:
			return state
	}
}

export default PushNotificationReducer
