import { mapValues, orderBy } from 'lodash'

export const uploadBlocksOrdering = (uploadProjectComponents: Object) => {
	return orderBy(mapValues(uploadProjectComponents, (order, name) => {
		return {
			order,
		  name
		}
	}), ['order'], ['asc']).filter(el => !!el.order)
}

export const checkIfValueExist = (value: string, values: Array<any>) => {
	let updatedValue = values

	if (updatedValue.includes(value)) {
		updatedValue = updatedValue.filter(
			(method: string) => method !== value)
	} else {
		updatedValue.push(value)
	}

	return updatedValue
}