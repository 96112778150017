import { useEffect, useRef, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'

import { isNil } from 'lodash'
import moment from 'moment'

import { Route } from '../SideBarTypes'
import { onUserRoutesOrderChange } from 'global actions'
import { FOLDER } from 'Services/Constants'
import { getString } from 'Services/Strings/StringService'

const createOrderDate = (sourceDate: string, movingUp: boolean) => {
	const date = moment(sourceDate, 'YYYY-MM-DD HH:mm:ss')

	if (movingUp) {
		date.add(1, 'second')
	} else {
		date.subtract(1, 'second')
	}

	return date.format('YYYY-MM-DD HH:mm:ss')
}

export const useProjectsDrag = (
	routes: Route[],
	setIsDropDisabled: (disabled: boolean) => void
) => {
	const dispatch = useDispatch()

	const onDragEnd = (result: DropResult) => {
		setIsDropDisabled(false)
		const { destination, source, draggableId: originalDraggableId } = result
		const MY_PROJECTS = getString('NAV_TITLE_MY_PROJECTS')

		// do nothing if out of dropzone or dropped on the same place
		if (
			!destination ||
			(destination.droppableId === source.droppableId &&
				destination.index === source.index)
		) {
			return
		}

		let newOrderDate: string = ''
		let indexOfDestinationInRoutes
		let indexOfSourceInRoutes

		// find My projects route
		const routeListIndex = routes.findIndex(
			(route: Route) => route.name === MY_PROJECTS
		)
		const routeList = routes[routeListIndex]

		// copy My projects views
		const newRouteViews = Array.from(routeList.views as Route[])
		const isChangingFolder = destination.droppableId !== source.droppableId

		// if moving to folder - find index of this folder in all routes
		if (destination.droppableId !== MY_PROJECTS) {
			indexOfDestinationInRoutes = newRouteViews.findIndex(
				route => route.id === destination.droppableId
			)
		}
		// if moving out of folder - find index of this folder in all routes
		if (source.droppableId !== MY_PROJECTS) {
			indexOfSourceInRoutes = newRouteViews.findIndex(
				route => route.id === source.droppableId
			)
		}

		// take views from corresponding source folder
		const sourceFolderRoutes = !isNil(indexOfSourceInRoutes)
			? newRouteViews[indexOfSourceInRoutes].views
			: newRouteViews

		// prepare correct id of item we are moving
		const isFolder = originalDraggableId.startsWith(FOLDER)
		const draggableId = isFolder
			? originalDraggableId.replace(`${FOLDER}-`, '')
			: originalDraggableId

		const viewToMove = sourceFolderRoutes?.find(view => view.id === draggableId)

		// take views from corresponding destination folder
		const projectOnDestinationIndex = !isNil(indexOfDestinationInRoutes)
			? newRouteViews[indexOfDestinationInRoutes].views?.[destination.index]
			: newRouteViews[destination.index]
		const destinationFolderRoutes = !isNil(indexOfDestinationInRoutes)
			? newRouteViews[indexOfDestinationInRoutes].views
			: newRouteViews

		// moving to place of bundle
		if (projectOnDestinationIndex?.folderId) {
			const movingUp = isChangingFolder
				? true
				: destination.index < source.index
			const projectFromBundle = destinationFolderRoutes?.find(
				route =>
					route.folderId === projectOnDestinationIndex?.folderId &&
					route.project
			)
			newOrderDate = createOrderDate(
				projectFromBundle?.project?.orderDate ||
					(projectOnDestinationIndex.orderDate as string),
				movingUp
			)
			// moving to place of plain project
		} else if (projectOnDestinationIndex?.project) {
			const movingUp = isChangingFolder
				? true
				: destination.index < source.index
			newOrderDate = createOrderDate(
				projectOnDestinationIndex?.project?.orderDate as string,
				movingUp
			)
			// moving to place of user folder
		} else if (projectOnDestinationIndex?.userFolder) {
			const movingUp = isChangingFolder
				? true
				: destination.index < source.index
			newOrderDate = createOrderDate(
				projectOnDestinationIndex?.orderDate as string,
				movingUp
			)
			// moving to the end of the my projects list
		} else if (
			!projectOnDestinationIndex &&
			destination.droppableId === MY_PROJECTS
		) {
			newOrderDate = createOrderDate(
				newRouteViews[destination.index - 1].orderDate as string,
				destination.index < source.index
			)
		} else {
			// moving to empty folder
			if (viewToMove?.folderId) {
				const bundleProject = sourceFolderRoutes?.find(
					view => view.folderId === draggableId && view.project
				)
				newOrderDate = bundleProject?.project?.orderDate as string
			} else {
				newOrderDate = viewToMove?.project?.orderDate as string
			}
		}

		if (viewToMove) {
			viewToMove.orderDate = newOrderDate
			if (viewToMove.project) {
				viewToMove.project.orderDate = newOrderDate
			}
		}

		// create new routes

		// delete item from its source place
		if (!isNil(indexOfSourceInRoutes)) {
			newRouteViews[indexOfSourceInRoutes].views?.splice(source.index, 1)
		} else {
			newRouteViews.splice(source.index, 1)
		}
		// insert item to new place
		if (!isNil(indexOfDestinationInRoutes)) {
			// if dragging from upper place to folder
			const newIndexOfDestinationInRoutes =
				isNil(indexOfSourceInRoutes) &&
				source.index < indexOfDestinationInRoutes
					? indexOfDestinationInRoutes - 1
					: indexOfDestinationInRoutes
			newRouteViews[newIndexOfDestinationInRoutes]?.views?.splice(
				destination.index,
				0,
				viewToMove as Route
			)
		} else {
			newRouteViews.splice(destination.index, 0, viewToMove as Route)
		}

		const newRouteList = {
			...routeList,
			views: newRouteViews
		}
		const newRoutes = Array.from(routes)

		newRoutes[routeListIndex] = newRouteList
		dispatch(
			onUserRoutesOrderChange(
				newRoutes,
				newOrderDate,
				viewToMove?.id,
				viewToMove?.folderId,
				destination.droppableId === MY_PROJECTS
					? null
					: destination.droppableId,
				isFolder ? draggableId : undefined
			)
		)
	}
	return { onDragEnd }
}

export const useDraggableInPortal = () => {
	const self = useRef({}).current as Record<string, any>

	const div = document.createElement('div')
	div.style.position = 'absolute'
	div.style.pointerEvents = 'none'
	div.style.top = '0'
	div.style.width = '100%'
	div.style.height = '100%'
	self.elt = div
	document.body.appendChild(div)

	return (render: any) =>
		(provided: any, ...args: any) => {
			const element = render(provided, ...args)
			if (provided.draggableProps.style.position === 'fixed') {
				return createPortal(element, self.elt)
			}
			return element
		}
}

export const useMouseBoundary = ({
	id,
	onEnter,
	onLeave,
	isDropDisabled
}: {
	id?: string
	onEnter: () => void
	onLeave: () => void
	isDropDisabled?: boolean
}) => {
	useEffect(() => {
		let isMouseDown = false
		let isInBoundaries = false
		let enterTimer: NodeJS.Timeout | null = null

		const handleMouseMove = (event: MouseEvent) => {
			if (!isMouseDown || isDropDisabled || !id) {
				return
			}
			const divRef = document.getElementById(id)
			if (divRef) {
				const rect = divRef.getBoundingClientRect()
				const newIsInBoundaries =
					event.clientX >= rect.left &&
					event.clientX <= rect.right &&
					event.clientY >= rect.top + 5 &&
					event.clientY <= rect.bottom
				if (newIsInBoundaries !== isInBoundaries) {
					isInBoundaries = newIsInBoundaries
					if (isInBoundaries) {
						enterTimer = setTimeout(() => {
							onEnter()
						}, 200)
					} else {
						if (enterTimer) {
							clearTimeout(enterTimer)
							enterTimer = null
						}
						onLeave()
					}
				}
			}
		}
		const mousedownHandler = () => {
			isMouseDown = true
		}
		const mouseupHandler = () => {
			isMouseDown = false
		}

		document.addEventListener('mousedown', mousedownHandler)
		document.addEventListener('mouseup', mouseupHandler)
		document.addEventListener('mousemove', handleMouseMove)

		return () => {
			document.removeEventListener('mousemove', handleMouseMove)
			document.removeEventListener('mousedown', mousedownHandler)
			document.removeEventListener('mouseup', mouseupHandler)
			if (enterTimer) {
				clearTimeout(enterTimer)
			}
		}
	}, [isDropDisabled])
}
