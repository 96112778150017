import React, { memo } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'
import { getString } from '../../../../Services/Strings/StringService'
import { DRAWING_COSTS_TABLE } from '../../../../Services/Strings'
import {
  ItemGrid,
  Table
} from 'Scenes/Components/thirdParty/CreativeTim/components'
import TransparentButton from 'Scenes/Components/TransparentButton'
import * as CostAndLeadActions from '../../Customize/CustomizeCostAndLeadSettings/CostAndLeadActions'
import Customize2DDrawingCostButton from './Customize2DDrawingCostButton'
import Customize2DDrawingCostInput from './Customize2DDrawingCostInput'
import * as styles from '../customizeStyles.css'
import './Customize2DDrawingCost.scss'
import {
  onApplyAllDrawingData,
  onEditAllToggle,
  onEditToggleChange
} from './Customize2DDrawingCostService'

const ItemGridTSX: any = ItemGrid

interface IProps {
  drawingCost2dNames: any
  settingsData: any
  isEditAllDrawingCost: boolean
  drawingEditToggle: Function
  drawingEditAllToggle: Function
  drawingApplyToggle: Function
  updateDrawingData: Function
  editDrawingData: Function
  updateAllDrawingData: Function
}
interface IReduxStore {
  CostAndLeadReducer?: any
}

const Customize2DDrawingCostTbl: React.FC<IProps> = ({
  drawingCost2dNames,
  settingsData,
  isEditAllDrawingCost,
  drawingEditToggle,
  drawingEditAllToggle,
  drawingApplyToggle,
  updateDrawingData,
  editDrawingData,
  updateAllDrawingData
}) => {
  const userSettings = settingsData.userSettings
  const defaultSettings = settingsData.defaultSettings

  const renderValue = (drawingKey: any, isDefault: boolean) => {
    let inputStyle = styles.editedValue
    if (isDefault) {
      inputStyle = styles.defaultValue
    }
    const apply = drawingCost2dNames[drawingKey].apply
    const isEdit = drawingCost2dNames[drawingKey].edit
    const minValue = drawingCost2dNames[drawingKey]?.validation?.minValue
    const maxValue = drawingCost2dNames[drawingKey]?.validation?.maxValue
    const errorMsg = drawingCost2dNames[drawingKey].alert

    return (
      <Customize2DDrawingCostInput
        drawingKey={drawingKey}
        errorMessage={errorMsg}
        valueInput={
          userSettings[drawingKey]
            ? userSettings[drawingKey]
            : defaultSettings[drawingKey]
        }
        isEdit={isEdit}
        isApply={apply}
        minValue={minValue}
        maxValue={maxValue}
        isDefault={isDefault}
        styles={inputStyle}
        editDrawingData={editDrawingData}
        updateDrawingData={updateDrawingData}
      />
    )
  }

  const renderAction = (drawingKey: any) => {
    const isEdit = drawingCost2dNames[drawingKey].edit

    return (
      <Customize2DDrawingCostButton
        drawingKey={drawingKey}
        isEdit={isEdit}
        drawingApplyToggle={drawingApplyToggle}
        drawingEditToggle={drawingEditToggle}
        onEditToggle={onEditToggleChange}
      />
    )
  }

  const tableData = () => {
    let tableData: any = []
    for (const drawingKey in drawingCost2dNames) {
      const name = (
        <span title={drawingCost2dNames[drawingKey].title}>
          {drawingCost2dNames[drawingKey].text}
        </span>
      )
      const isDefault =
        !userSettings[drawingKey] ||
        userSettings[drawingKey] === defaultSettings[drawingKey]
      const value = renderValue(drawingKey, isDefault)
      const action = renderAction(drawingKey)

      tableData.push([name, value, action])
    }
    return tableData
  }

  const editAll = (
    <div>
      <TransparentButton
        className="custom-input--action"
        onClick={() =>
          onEditAllToggle(
            drawingEditToggle,
            drawingEditAllToggle,
            drawingCost2dNames,
            isEditAllDrawingCost
          )
        }
      >
        {isEditAllDrawingCost
          ? getString('CANCEL')
          : getString('DRAWING_COSTS_EDIT_ALL')}
      </TransparentButton>
      {isEditAllDrawingCost ? (
        <TransparentButton
          onClick={() =>
            onApplyAllDrawingData(
              drawingCost2dNames,
              userSettings,
              updateAllDrawingData
            )
          }
          className="custom-input--action"
        >
          {getString('APPLY_ALL')}
        </TransparentButton>
      ) : (
        <></>
      )}
    </div>
  )

  return (
    <div>
      <ItemGridTSX>
        <Table
          editTableStyle={'drawingCostTableCell'}
          tableHead={[
            DRAWING_COSTS_TABLE.PROPERTY_NAME,
            DRAWING_COSTS_TABLE.VALUE,
            editAll
          ]}
          tableData={tableData()}
        />
      </ItemGridTSX>
    </div>
  )
}

const mapStateToProps = (state: IReduxStore) => {
  const {
    CostAndLeadReducer: {
      drawingCost2dNames,
      settingsData,
      isEditAllDrawingCost
    }
  } = state
  return {
    drawingCost2dNames,
    settingsData,
    isEditAllDrawingCost
  }
}
const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
  bindActionCreators({ ...CostAndLeadActions }, dispatch)

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(Customize2DDrawingCostTbl)
)
