import {
	categoriesWithTechnologies,
	CLOSING_BRACKET,
	DOT,
	INVALID_FUNCTION,
	INVALID_FUNCTION_ERROR_CODE,
	OPENING_BRACKET
} from './constants'
import {
	FunctionCategories,
	ICustomFunction,
	IFunctionCategory,
	IFunctionVariable,
	VariableType
} from './CustomizeCostingFunctionTypes'
import { IFunctionStringParam } from 'Services/models/IFunctionStringParam'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'
import { getString } from 'Services/Strings/StringService'

export const findTechnologyName = (
	printingTechnologies: IPrintingTechnology[],
	selectedTechnology: string
) => {
	const technology = printingTechnologies.find(
		printingTechnology => printingTechnology.name === selectedTechnology
	)

	return technology?.userReadableName
}

export const functionForTechnologyExists = (
	customFunctionsList: ICustomFunction[],
	selectedFunctionCategory: string,
	technologyName: string
) => {
	return !!customFunctionsList.find(
		(customFunction: ICustomFunction) =>
			customFunction?.category === selectedFunctionCategory &&
			customFunction?.technologies &&
			customFunction?.technologies.includes(technologyName)
	)
}

export const functionForCategoryWithoutTechnologiesExists = (
	customFunctionsList: ICustomFunction[],
	selectedFunctionCategory: FunctionCategories
) => {
	return (
		!categoriesWithTechnologies.includes(selectedFunctionCategory) &&
		!!customFunctionsList.find(
			(customFunction: ICustomFunction) =>
				customFunction?.category === selectedFunctionCategory
		)
	)
}

export const getUserReadablePrintingTechnologiesNames = (
	printingTechnologies: IPrintingTechnology[],
	customFunction: ICustomFunction
) => {
	switch (customFunction.category) {
		case FunctionCategories.productionCost: {
			return printingTechnologies
				.reduce((acc: string[], technology) => {
					if (
						customFunction?.technologies &&
						customFunction?.technologies.includes(technology.name)
					) {
						acc.push(technology.userReadableName)
					}
					return acc
				}, [])
				.join(' , ')
		}
		default: {
			return '-'
		}
	}
}

export const customVariableIsInUse = (
	customVariable: IFunctionVariable,
	functionsList: ICustomFunction[]
) => {
	return functionsList.some(customFunction => {
		const functionArray = customFunction.functionString.split(' ')
		return functionArray.find(
			variable => variable.toLowerCase() === customVariable.name.toLowerCase()
		)
	})
}

const joinNumbersValues = (
	sumObject: IFunctionVariable,
	objectToJoin: IFunctionVariable
) => {
	return {
		...sumObject,
		type: VariableType.number,
		name: sumObject.name + objectToJoin.name,
		userReadableName:
			sumObject.userReadableName + objectToJoin.userReadableName,
		value:
			sumObject.value &&
			objectToJoin.value &&
			sumObject.value + objectToJoin.value
	}
}

export const cleanCustomFunctionVariables = (
	customFunctionVariables: IFunctionVariable[]
) => {
	const cleanedFunction = customFunctionVariables.reduce((acc: any, variable) => {
		// if we have 2 numbers 1 by 1 we have to join them into 1 number
		// example: 1 2 should become 12, 1 . 5 should become 1.5
		if (
			(variable.type === VariableType.number || variable.value === DOT) &&
			acc.length > 0 &&
			(acc.at(-1)?.type === VariableType.number || acc.at(-1)?.value === DOT)
		) {
			acc[acc.length - 1] = joinNumbersValues(acc[acc.length - 1], variable)
		} else {
			acc.push(variable)
		}
		return acc
	}, [] as IFunctionVariable[])
	return cleanedFunction
}

export const validateFunctionStringArray = (variables: IFunctionVariable[]) => {
	return variables.forEach((variable, index) => {
		let error = false
		switch (variable.type) {
			case VariableType.number: {
				if (variable.value?.endsWith(DOT) || variable.value?.startsWith(DOT)) {
					error = true
				}
				break
			}
			case VariableType.operator: {
				const countOfOpeningBracket = variables.filter(
					o => o.value === OPENING_BRACKET
				).length
				const countOfClosingBracket = variables.filter(
					c => c.value === CLOSING_BRACKET
				).length

				const isEqualBracketsCount =
					countOfOpeningBracket === countOfClosingBracket

				const operatorAfterOpeningBracket =
					variable.value === OPENING_BRACKET &&
					variables[index + 1] &&
					variables[index + 1].type === VariableType.operator

				const operatorNotBracketAfterOpeningBracket =
					operatorAfterOpeningBracket &&
					variables[index + 1].value !== OPENING_BRACKET

				const operatorBracketAfterOpeningBracket =
					operatorAfterOpeningBracket &&
					variables[index + 1].value === OPENING_BRACKET &&
					isEqualBracketsCount

				const operatorByOperator =
					variable.value !== OPENING_BRACKET &&
					variable.value !== CLOSING_BRACKET &&
					variables[index + 1] &&
					variables[index + 1].type === VariableType.operator &&
					variables[index + 1].value !== OPENING_BRACKET

				const startsWithOperator =
					variable.value !== OPENING_BRACKET && index === 0

				if (
					operatorNotBracketAfterOpeningBracket ||
					(operatorBracketAfterOpeningBracket && !isEqualBracketsCount) ||
					operatorByOperator ||
					startsWithOperator
				) {
					error = true
				}
				break
			}
			default: {
				break
			}
		}
		if (error) {
			throw Object.assign(new Error(INVALID_FUNCTION), {
				code: INVALID_FUNCTION_ERROR_CODE
			})
		}
	})
}

export const getFunctionStringFromArray = (
	customFunctionVariables: IFunctionVariable[]
) => {
	const cleanArray = cleanCustomFunctionVariables(customFunctionVariables)
	validateFunctionStringArray(cleanArray)
	return cleanArray.map((param: any) => param.name).join(' ')
}

export const getUserReadableFunctionCategoryName = (category: string) => {
	return getString(`${category}_USER_READABLE_TITLE`)
}

export const getUserReadableFunctionString = (
	functionString: string,
	params: IFunctionStringParam[]
): string => {
	const functionStringArray = functionString.split(' ')
	return functionStringArray
		.map(functionParam => {
			const defaultReadableNames = getString(
				'FUNCTION_STRING_PARAMS_USER_READABLE_NAMES'
			)
			const readableNameFromDB = params.find(
				param => param.name === functionParam
			)?.userReadableName
			return (
				defaultReadableNames[functionParam] ||
				readableNameFromDB ||
				functionParam
			)
		})
		.join(' ')
}

export const getDefaultSelectedFunctionCategory = (
	functionCategoriesList: IFunctionCategory[]
) => {
	const defaultCategory = functionCategoriesList.find(
		functionCategory =>
			functionCategory.name === FunctionCategories.productionCost
	)

	return defaultCategory?.name || functionCategoriesList[0]?.name || ''
}
