import {
	cloneDeep,
	filter,
	find,
	isEmpty,
	map,
	orderBy,
	take,
	uniqBy
} from 'lodash'

import { ConfigurationResultTypes } from './SolutionAnalysis/ConfigurationResultTypes'
import { checkUserSubscriptionAlert } from 'global actions'
import {
	SHOW_BOUNDARY_ERROR,
	USER_HOME_CRUMB_NAMES_CHANGED
} from 'global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import {
	PROJECT_PDF_OPTIONS_SUCCESS,
	SOLUTIONS_FETCHED_FINISHED
} from 'global actions/types/partAnalysisTypes'
import { TOUR_STEPS_UPDATED } from 'global actions/types/takeATourTypes'
import { store } from 'index'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import {
	benefitsFiltersValues,
	filters
} from 'Scenes/Components/FilterPartsGrid/filterPartsEnum'
import { findEffectivePoints } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisService'
import { solutionMapConstant } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisConstants'
import { filterConfigurations } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisService'
import {
	defaultNamingPrinterConfiguration,
	POLLER_COUNTER_CHANGE_UI_TIMEOUT,
	POLLER_DELAY
} from 'Services/Constants'
import { timeout } from 'Services/global/timeout'
import history from 'Services/history'
import {
	ChainBenefitsNames,
	IChainBenefits
} from 'Services/models/IChainBenefits'
import { IConfiguration } from 'Services/models/IConfiguration'
import { Material } from 'Services/models/IMaterial'
import { Part, PartStatus, WeightReductionType } from 'Services/models/IPart'
import { IDataSolutionMap, ISolution } from 'Services/models/ISolution'
import {
	getCustomizationSettings,
	getProjectPDFOptions
} from 'Services/Network'
import {
	getPart,
	getPartClusterSolutions,
	getPartSolutions
} from 'Services/Network/PartAnalysisNetwork'
import { projectRoute } from 'Services/routeFuncs'
import {
	OK,
	WEIGHT_REDUCTION_PROGRESS_CUSTOM_TAKE_A_TOUR_TEXT
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { defaultMaterial } = getTheme()

export class MainPartAnalysisService {
	public partStatus: PartStatus = PartStatus.complete

	convertMaterialId = (materials: Material[], partMaterialId: number) => {
		if (materials && materials.length) {
			const partMaterialById = materials.find(
				(material: Material) => material.id === partMaterialId
			)
			if (!partMaterialById) {
				return defaultMaterial
			}
			return partMaterialById
		}
	}

	sortConfiguration = (configurations: any[]): any => {
		return orderBy(configurations, ['order', 'createdAt'], ['asc', 'desc'])
	}

	updateCurrentSteps = (
		tourSteps: any[],
		dispatch: any,
		weightReductionProgressFromProject: boolean,
		tourConfigurationId?: number
	) => {
		const preparedSteps = cloneDeep(tourSteps)
		if (tourSteps.length) {
			tourSteps.forEach((step: any, idx: number) => {
				if (!step.target.includes(`_${tourConfigurationId}`)) {
					preparedSteps[idx].target = `${step.target}_${tourConfigurationId}`
				}
			})
			tourSteps = preparedSteps
		}
		let payload
		if (weightReductionProgressFromProject) {
			payload = {
				customSteps: [
					{
						content: WEIGHT_REDUCTION_PROGRESS_CUSTOM_TAKE_A_TOUR_TEXT,
						target: `#weight-reduction-solution-configuration-button-${tourConfigurationId}`,
						placement: 'top',
						noGradient: true,
						hideNextButton: true,
						spotlightPadding: 20,
						disableOverlayClose: true,
						spotlightClicks: true,
						styles: {
							options: {
								arrowColor: '#b9e5c7'
							}
						}
					}
				]
			}
		} else {
			payload = {
				steps: tourSteps,
				disableScrolling: true,
				run: false,
				stepIndex: 0
			}
		}
		dispatch({
			type: TOUR_STEPS_UPDATED,
			payload
		})
		return tourSteps
	}

	fetchPartConfigurations = async (
		partId: number,
		projectId: string,
		materials: Array<any>,
		dispatch: any,
		tourSteps: any[],
		weightReductionProgressFromProject: boolean,
		feaId?: string,
		disableRibbonInfo?: boolean,
		userCustomizationSettings?: any,
		isAdmin?: boolean
	) => {
		try {
			const response = await getPartSolutions(partId)
			const settingsResponse = await getCustomizationSettings()
			const projectPdfResponse: any = await getProjectPDFOptions()
			let {
				isLightUser,
				userDetails: { email = '' }
			} = store.getState().user

			let {
				project: { name },
				part: {
					materialId,
					partNumber,
					weightReductionType,
					fileURL,
					status,
					quantity,
					recalculateSolutions
				},
				configurations,
				solutions,
				partPrintIssues,
				generalData = {}
			} = response?.data

			if (recalculateSolutions && status === PartStatus.awaitingWallThickness) {
				const mainPartAnalysis = new MainPartAnalysisService()
				//start part poller
				await mainPartAnalysis.startPartPoller(partId, dispatch)
			}

			const { defaultSettings = {} } = settingsResponse?.data

			let exportPdfOptions = ''

			try {
				exportPdfOptions = projectPdfResponse?.data?.exportPdfOptions
			} catch (e) {
				console.error(e)
			}

			const { drawingCostPercentage } = defaultSettings

			const {
				userSubscriptionDetails: {
					subscriptionExpired,
					partsCreditExpired,
					trial
				}
			} = generalData

			this.partStatus = status
			solutions = solutions || []

			let tourConfigurationId = this.sortConfiguration(configurations)[0]?.id

			let tourConfigurationObject = configurations.find(
				(c: { resultType: number }) =>
					c.resultType === ConfigurationResultTypes.BestMatch
			)
			if (tourConfigurationObject) {
				tourConfigurationId = tourConfigurationObject.id
			}

			tourSteps = this.updateCurrentSteps(
				tourSteps,
				dispatch,
				weightReductionProgressFromProject,
				tourConfigurationId
			)
			const partMaterial = this.convertMaterialId(materials, materialId)
			const initialBatchSize = quantity

			dispatch(
				checkUserSubscriptionAlert(
					isAdmin,
					isLightUser,
					subscriptionExpired,
					partsCreditExpired,
					trial,
					email
				)
			)

			dispatch({
				type: SOLUTIONS_FETCHED_FINISHED,
				payload: {
					...(response?.data || {}),
					configurations,
					solutions,
					partPrintIssues,
					partId,
					projectId,
					initialBatchSize,
					partMaterial,
					clusterId: null,
					feaId,
					tourConfigurationId,
					tourSteps,
					currentStepTargetId: tourSteps[0]?.target,
					isWeightReductionPart:
						(weightReductionType ===
							WeightReductionType.COMPLEX_WEIGHT_REDUCED ||
							weightReductionType ===
								WeightReductionType.FAST_WEIGHT_REDUCED) &&
						!!fileURL,
					initialToleranceValue: response?.data?.part.customToleranceValue,
					disableRibbonInfo,
					userCustomizationSettings,
					drawingCostPercentage
				}
			})

			dispatch({
				type: USER_HOME_CRUMB_NAMES_CHANGED,
				payload: { updateCrumbs: { partName: partNumber, projectName: name } }
			})

			if (exportPdfOptions) {
				dispatch({
					type: PROJECT_PDF_OPTIONS_SUCCESS,
					payload: {
						projectPdfOptions: JSON.parse(exportPdfOptions)
					}
				})
			}
		} catch (err: any) {
			dispatch({
				type: SHOW_BOUNDARY_ERROR,
				payload: {
					errorMessage: err?.message || err || '',
					errorClass: 'full-page'
				}
			})
			console.error(err)
		}
	}

	startPartPoller = async (
		partId: number,
		dispatch: any,
		useCounter?: boolean
	) => {
		let counter = 1
		let response: any = null
		if (useCounter) {
			response = await getPart(partId)
			this.partStatus = response.data.part?.status
		}
		while (this.partStatus === PartStatus.awaitingWallThickness) {
			const pathArray = window.location.pathname.split('/')
			const partIdIndex = pathArray.indexOf('part') + 1
			const pathPartId = Number(pathArray[partIdIndex])
			if (pathPartId != partId) {
				response = null
				break
			}
			if (useCounter && counter === POLLER_COUNTER_CHANGE_UI_TIMEOUT + 3) {
				response = null
				break
			}
			await timeout(counter * POLLER_DELAY)
			response = await getPart(partId)
			this.partStatus = response.data.part?.status
			counter++
		}
		!useCounter && this.handlePartPollerResponse(response, dispatch)
	}

	handlePartPollerResponse = (res: any, dispatch: any) => {
		if (res?.data) {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: getString('ANALYSIS_FINISHED_TEXT'),
					headerTitle: getString('ANALYSIS_FINISHED'),
					alertType: AlertType.SUCCESS,
					onConfirm: () => {
						window.location.reload()
					},
					onCancel: () => {
						window.location.reload()
					},
					confirmText: OK,
					showCancel: false
				}
			})
		}
	}

	fetchClusterConfiguration = async (
		clusterId: number,
		projectId: string,
		materials: Array<any>,
		dispatch: any,
		tourSteps: any[],
		weightReductionProgressFromProject: boolean,
		userCustomizationSettings: any,
		disableRibbonInfo?: boolean,
		isAdmin?: boolean
	) => {
		const response = await getPartClusterSolutions(clusterId)
		const settingsResponse = await getCustomizationSettings()
		let {
			userDetails: { email = '' },
			isLightUser
		} = store.getState().user

		const {
			project: { quantity, name },
			cluster: { material, materialId, partNumber, status },
			configurations,
			generalData = {}
		} = response?.data

		const { defaultSettings = {} } = settingsResponse?.data

		const { drawingCostPercentage } = defaultSettings

		const { userSubscriptionDetails = {} } = generalData

		const { trial, partsCreditExpired, subscriptionExpired } =
			userSubscriptionDetails

		this.partStatus = status
		const partMaterial = this.convertMaterialId(
			materials,
			material.id || materialId
		)
		const initialBatchSize = quantity || 1

		let tourConfigurationId = this.sortConfiguration(configurations)[0]?.id

		let tourConfigurationObject = configurations.find(
			(c: { resultType: number }) =>
				c.resultType === ConfigurationResultTypes.BestMatch
		)
		if (tourConfigurationObject) {
			tourConfigurationId = tourConfigurationObject.id
		}

		tourSteps = this.updateCurrentSteps(
			tourSteps,
			dispatch,
			weightReductionProgressFromProject,
			tourConfigurationId
		)

		dispatch(
			checkUserSubscriptionAlert(
				isAdmin,
				isLightUser,
				subscriptionExpired,
				partsCreditExpired,
				trial,
				email
			)
		)

		dispatch({
			type: SOLUTIONS_FETCHED_FINISHED,
			payload: {
				...(response?.data || {}),
				clusterId,
				projectId,
				initialBatchSize,
				partMaterial,
				tourConfigurationId,
				partId: null,
				tourSteps,
				currentStepTargetId: tourSteps[0]?.target,
				initialToleranceValue: response?.data?.cluster.customToleranceValue,
				disableRibbonInfo,
				userCustomizationSettings,
				drawingCostPercentage
			}
		})
		dispatch({
			type: USER_HOME_CRUMB_NAMES_CHANGED,
			payload: { updateCrumbs: { partName: partNumber, projectName: name } }
		})
	}
	getChainBenefits = (
		part: Part,
		userCustomDefaultSupplyChainCost?: boolean
	): IChainBenefits => {
		const { chainBenefits } = part
		if (chainBenefits) {
			return chainBenefits
		}

		const defaultChainBenefits: IChainBenefits = {
			[ChainBenefitsNames.Global]: {
				on: true
			},
			[ChainBenefitsNames.Ordering]: {
				on: userCustomDefaultSupplyChainCost || false
			},
			[ChainBenefitsNames.Obsolescence]: {
				on: userCustomDefaultSupplyChainCost || false
			},
			[ChainBenefitsNames.Maintenance]: {
				on: userCustomDefaultSupplyChainCost || false
			}
		}
		return defaultChainBenefits
	}
}

export const makeAlternativeSolutionsData = (
	solutions: any = [],
	configurations: any,
	userState: any,
	solutionNumber: number = 100 //
) => {
	// remove add configuration and add printer material configuration
	const filteredConfigurations = filterConfigurations(configurations)

	const solutionsIds = solutions.map((s: ISolution) => s.id)
	let currentConfiguration =
		filteredConfigurations.find((config: IConfiguration) =>
			solutionsIds.includes(config?.solution?.id)
		) || {}

	let sortedSolutions = solutions.filter(
		(solution: ISolution) =>
			solution.isPartOfGraphExponentialLine &&
			solution.id !== currentConfiguration?.solution?.id
	)

	const orderAlternativeSolutions = take(
		orderBy(
			filter(solutions, solution => !!solution.score),
			['score'],
			['desc']
		),
		solutionNumber
	)

	sortedSolutions = orderBy(sortedSolutions, ['score'], ['desc'])

	// add alternative solutions to the best solutions
	sortedSolutions = sortedSolutions
		.concat([
			currentConfiguration?.solution || {},
			...orderAlternativeSolutions.filter(
				(solution: ISolution) => !solution.isPartOfGraphExponentialLine
			)
		])
		.filter((el: ISolution) => !isEmpty(el))

	// prepare solutions for the table
	let preparedSolutions: Array<IDataSolutionMap | any> = makeSolutionsData(
		sortedSolutions,
		filteredConfigurations,
		userState
	)

	// find the best match and remove it from all solutions
	const bestMatch = find(
		preparedSolutions,
		solution => solution.name === defaultNamingPrinterConfiguration.bestMatch
	)
	const solutionsWithoutBestMatch = filter(
		preparedSolutions,
		(solution: IDataSolutionMap) => solution !== bestMatch
	)

	// if best match is exist put it on top of ordering by score solutions
	preparedSolutions = bestMatch
		? [bestMatch].concat(solutionsWithoutBestMatch)
		: preparedSolutions

	return preparedSolutions.map((solution: IDataSolutionMap, id: number) => {
		return {
			...solution,
			position: id + 1
		}
	})
}

export const makeSolutionsData = (
	sortedSolutions: ISolution[],
	filteredConfigurations: any,
	userState: any
) => {
	const solutions = uniqBy(sortedSolutions, 'id')
	return map(solutions, (solution: ISolution) => {
		const {
			name = '',
			density = 0,
			youngsModulusExt = null,
			percentElongationAtBreakExt = null,
			maximumServiceTemperature = 0,
			ultimateTensileStrengthExt = null,
			thermalConductivity = 0
		} = solution.printerMaterial || {}

		const currConfigIndex = filteredConfigurations?.findIndex(
			(conf: IConfiguration) => conf?.solution?.id === solution?.id
		)

		return {
			...solution,
			url: currConfigIndex || null,
			printerName: solution.printer?.name,
			materialName: name,
			cost: solution.cost ? Math.round(solution.cost * 100) / 100 : 0,
			name: filteredConfigurations[currConfigIndex]?.order
				? filteredConfigurations[currConfigIndex]?.name
				: getString('OPEN_SOLUTION'),
			strength: ultimateTensileStrengthExt?.Z?.val,
			weight: density,
			stiffness: youngsModulusExt?.Z?.val,
			elongation: percentElongationAtBreakExt?.Z?.val,
			conductivity: thermalConductivity || 0,
			score: solution.score,
			heatResistance: maximumServiceTemperature,
			isLoading: false,
			disabled: false,
			effectiveQuantity: findEffectivePoints(
				solution,
				'configurationCostResults',
				userState
			)
		}
	})
}

export const getMaterialProperties = (xName: string, material: any) => {
	const {
		density,
		youngsModulus,
		youngsModulusExt,
		percentElongationAtBreakExt,
		percentElongationAtBreak,
		maximumServiceTemperature,
		ultimateTensileStrengthExt,
		ultimateTensileStrength,
		thermalConductivity
	} = material

	switch (xName) {
		case solutionMapConstant.strength:
			return ultimateTensileStrengthExt?.Z?.val || ultimateTensileStrength
		case solutionMapConstant.weight:
			return density
		case solutionMapConstant.stiffness:
			return youngsModulusExt?.Z?.val || youngsModulus
		case solutionMapConstant.elongation:
			return percentElongationAtBreakExt?.Z?.val || percentElongationAtBreak
		case solutionMapConstant.conductivity:
			return thermalConductivity || 0
		case solutionMapConstant.heatResistance:
			return maximumServiceTemperature
		default:
			return 0
	}
}

export const filterAlternativeSolutions = (
	filtersArray: Array<string>,
	solutions: Array<Record<string, any>>
) => {
	return filtersArray.includes(filters.ALL)
		? solutions
		: solutions.filter((solution: Record<string, any>) => {
				return filtersArray.every((filter: any) => {
					return solution.benefits.some(
						(benefit: Record<string, any>) =>
							benefit.type === benefitsFiltersValues[filter]
					)
				})
		  })
}
