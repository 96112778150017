import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { cloneDeep, map } from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import Flexbox from 'Scenes/Components/FlexBox';

import { IConfigureProperty } from '../NewPartConfigurationTypes';
import SelectMenu from '../../../../Components/MaterialSolutionSelector/SelectMenu';
import CastorSlider from '../../../../Components/CastorSlider';
import CastorCheckbox from '../../../../Components/CastorCheckbox'
import { getString } from '../../../../../Services/Strings/StringService';
import { defaultPropertiesOptions } from '../../../../../Services/Constants'
import CastorAlert from '../../../../Components/alerts/CastorAlert';

import './ConfigurePropertiesMethod.scss';

const MenuItemTsx: any = MenuItem
const defaultPropertiesOptionsTSX: any = defaultPropertiesOptions

interface IProps {
  open: boolean,
  handleClose: Function,
  onSaveNewPriorities: Function,
  onChangeProperty: Function,
  configurationProperties: Array<IConfigureProperty>,
  propertyName: string
}

const ConfigurePropertiesMethod: FC<IProps> = ({
   configurationProperties,
   open,
   handleClose,
   onSaveNewPriorities,
   onChangeProperty,
   propertyName
  }) => {
  const [rangeComponents, setRange]: any = useState(configurationProperties);
  const [property, setProperty]: any = useState(propertyName);

  useEffect(() => {
    setProperty(propertyName)
  }, [propertyName])

  const handleChangeRange = (value: number, id: string) => {
    const oldRangeComponents = cloneDeep(rangeComponents)
    const changedValueIdx = oldRangeComponents.findIndex((obj: IConfigureProperty) => obj.id == id);
    oldRangeComponents[changedValueIdx].value = value

    setRange(oldRangeComponents)
  };

  const handleChangeCheck = (active: boolean, id: string) => {
    const oldRangeComponents = cloneDeep(rangeComponents)
    const changedValueIdx = oldRangeComponents.findIndex((obj: IConfigureProperty) => obj.id == id);
    oldRangeComponents[changedValueIdx].active = active

    setRange(oldRangeComponents)
  };

  return (
    <CastorAlert
      headerTitle={getString('NEW_CONFIGURATION_PROPERTIES')}
      headerWithoutActions={true}
      alertClass="custom-theme"
      onCancel={() => {
        handleClose()
      }}
      show={open}
      onConfirm={() => {
        handleClose()
        onSaveNewPriorities(rangeComponents)
        onChangeProperty(property)
      }}
    >
      <br/>
      <Flexbox flexDirection="row">
        <div onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
          <SelectMenu
            value={property}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setProperty(e.target.value)}
          >
            {map(defaultPropertiesOptionsTSX, (property, key) =>
              <MenuItemTsx
               key={property + key}
               value={defaultPropertiesOptionsTSX[key]}
              >
                {defaultPropertiesOptionsTSX[key]}
              </MenuItemTsx>)}
          </SelectMenu>
        </div>
        <div className="custom-range-block">
          {map(rangeComponents, ({ name, id, withCheckbox, active, value, display }) => {

            if (!display && property === defaultPropertiesOptionsTSX.basic) return <div key={value + name}/>

            return (
              <div
                key={value + name}
                className="single-range"
              >
                <p>
                  {
                    withCheckbox &&
                    <CastorCheckbox
                      checked={active}
                      onChange={() => handleChangeCheck(!active, id)}
                      className="check-boxs--icon"
                      inputProps={{
                        'aria-label': 'secondary checkbox'
                      }}
                    />
                  }
                  {name}
                </p>
                <div>
                  <CastorSlider
                    onChange={(newValue: number) => {
                      handleChangeRange(newValue, id)
                    }}
                    marks={{ 1: 1, 3: 3, 5: 5 }}
                    step={1}
                    min={1}
                    max={5}
                    range={{ min: 1, max: 5 }}
                    value={value}
                    dots={true}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </Flexbox>
      <br/>
    </CastorAlert>
  );
};

export default memo(ConfigurePropertiesMethod)
