import { FC, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import cx from 'classnames'

import { TAB_3d_INDEX } from '../../constants'
import { materialRadioChanged, removeFiles } from '../../UploadProjectActions'
import UploadBOM from './UploadBOM'
import UploadMaterial from './UploadMaterial'
import CastorRadio from 'Scenes/Components/CastorRadio'
import Flexbox from 'Scenes/Components/FlexBox'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import {
	BOMRadioValue,
	chosenMaterialRadioValue,
	drawingFiles
} from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { FormatType } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

const RadioGroupTSX: any = RadioGroup
const FormControlLabelTSX: any = FormControlLabel

interface UploadMaterialsProps {
	is2dUpload: boolean | null
	uploadTabIndex: any
	selectedType?: string | null
	isDrawingFeatureOn?: boolean
}

const UploadMaterialsParameters: FC<UploadMaterialsProps> = ({
	is2dUpload,
	isDrawingFeatureOn,
	uploadTabIndex,
	selectedType
}) => {
	const { radioButtonSelected } = useSelector(
		(state: RootStateOrAny) => state.uploadProject
	)
	const user = useSelector((state: RootStateOrAny) => state.user)
	const dispatch = useDispatch()
	const isCombine3D2D = Feature.isFeatureOn(FeatureComponentId.COMBINE_3D_2D)
	const isUploadBomFileOn = Feature.isFeatureOn(
		FeatureComponentId.UPLOAD_BOM_FILE
	)
	const isUploadGenerativeFileOn = Feature.isFeatureOn(
		FeatureComponentId.GENERATIVE_DESIGN
	)

	const isGenerativeFileOption =
		!is2dUpload &&
		!isUploadBomFileOn &&
		isUploadGenerativeFileOn &&
		user.roles?.includes(UserRole.GENERATIVE_DESIGN)

	const showBomFileOption =
		isUploadBomFileOn && !is2dUpload && !isGenerativeFileOption

	const removeFilesByFormatType = (radioButtonId: any) => {
		if (uploadTabIndex === TAB_3d_INDEX) {
			switch (radioButtonId) {
				case BOMRadioValue:
					dispatch(removeFiles(FormatType.threeD))
					break
				case chosenMaterialRadioValue:
					dispatch(removeFiles(FormatType.threeD))
					break
				case drawingFiles:
					dispatch(removeFiles(FormatType.threeDByPDF))
					break
			}
		}
	}

	const materialRadioButtonChanged = (event: any) => {
		const isMachining = user?.userCustomizationSettings?.machining
		dispatch(materialRadioChanged(event.target.value, isMachining))
		removeFilesByFormatType(event.target.value)
	}

	const isSingleMaterial = Feature.isFeatureOn(
		FeatureComponentId.MATERIAL_FROM_FILES
	)
	const isSingleMaterialDisabled = !Feature.isFeatureActive(
		FeatureComponentId.MATERIAL_FROM_FILES
	)

	const showDrawingMaterialNot2d =
		isDrawingFeatureOn && !is2dUpload && isCombine3D2D && isSingleMaterial
	const showDrawingMaterialIs2d = !!is2dUpload && !showBomFileOption

	const drawingMaterialBlock = useMemo(() => {
		return (
			<FormControlLabelTSX
				disabled={!showDrawingMaterialIs2d && isSingleMaterialDisabled}
				value={drawingFiles}
				control={
					<CastorRadio
						className="upload-form-radio"
						inputProps={{ 'data-qa': `data-qa-radio-3d-drawing` }}
					/>
				}
				label={
					<div
						data-qa="data-qa-material-from-drawing-radio-btn"
						className="upload-form-radio-bom"
					>
						<p
							className={cx({
								disabledText: radioButtonSelected !== drawingFiles,
								bomUploadInput: radioButtonSelected === drawingFiles
							})}
						>
							{getString('USE_MATERIALS_FROM_DRAWING')}
						</p>
					</div>
				}
				className="upload-form-radioGroup"
			/>
		)
	}, [isSingleMaterialDisabled, radioButtonSelected, showDrawingMaterialIs2d])

	return (
		<Flexbox
			className="upload-form"
			alignContent="flex-start"
			flexDirection="row"
		>
			<div className="">
				<RadioGroupTSX
					id="upload-project-materials"
					className={cx('new-upload-project__materials', {
						disabled: !selectedType
					})}
					row={true}
					aria-label="select materials"
					name="select materials"
					value={radioButtonSelected}
					onChange={(e: any) => materialRadioButtonChanged(e)}
					color="rose"
				>
					{!!showDrawingMaterialIs2d && <div>{drawingMaterialBlock}</div>}
					{(isGenerativeFileOption || showBomFileOption) && (
						<FormControlLabelTSX
							value={BOMRadioValue}
							control={
								<CastorRadio
									className="upload-form-radio"
									inputProps={{ 'data-qa': `data-qa-radio-3d-bom` }}
								/>
							}
							label={
								<UploadBOM
									disabled={radioButtonSelected !== BOMRadioValue}
									isGenerativeFileOption={isGenerativeFileOption}
								/>
							}
							className={'upload-form-radioGroup'}
						/>
					)}
					<FormControlLabelTSX
						value={chosenMaterialRadioValue}
						control={
							<CastorRadio
								className="upload-form-radio"
								inputProps={{ 'data-qa': `data-qa-radio-material` }}
							/>
						}
						label={
							<UploadMaterial
								disabled={radioButtonSelected !== chosenMaterialRadioValue}
							/>
						}
						className="upload-form-radioGroup"
					/>
					{showDrawingMaterialNot2d && <div>{drawingMaterialBlock}</div>}
				</RadioGroupTSX>
			</div>
		</Flexbox>
	)
}

export default UploadMaterialsParameters
