export const projectPageScrollParams = {
	duration: 300,
	delay: 100,
	smooth: true,
	offset: 200,
	containerId: `main-content-scroller`
}

export const ProjectUrlParams = {
	scrollToBottom: 'scrollToBottom',
	search: 'search'
}
