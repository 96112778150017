import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { STEP_ID } from '../../constants'
import { goToNextStep } from '../../OnboardingWizardActions'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as AddIcon } from 'assets/img/svg/icons/circle_close.svg'

const AddPrinterButton: FC = () => {
	const { parentSteps, currentStep } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const dispatch = useDispatch()

	const onClick = () => {
		dispatch(goToNextStep(parentSteps, currentStep, STEP_ID.ADD_PRINTER_FORM))
	}

	return (
		<div className="tm-material--add-new">
			<Button
				disabled={false}
				id="step-card--footer--button"
				color="secondaryNewStyle"
				onClick={onClick}
				className="tm-material--add-new--button"
			>
				<AddIcon className="add-circle" />
				{getString('USER_PRINTER_ADD_NEW')}
			</Button>
		</div>
	)
}

export default AddPrinterButton
