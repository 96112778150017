import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { DONE_SCREEN_IDS, STEP_ID } from '../../constants'
import { useStep } from '../../OnboardingWizardHooks'
import OnboardingStepFrame from '../OnboardingStepFrame'
import DescriptionBlock from './DescriptionBlock'
import StartButton from './StartButton'

import './DoneScreen.scss'

const DoneScreenComponents = {
	[DONE_SCREEN_IDS.DESCRIPTION]: <DescriptionBlock />,
	[DONE_SCREEN_IDS.START_BUTTON]: <StartButton />
}

const DoneScreen: FC = () => {
	const { steps } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { step, stepContent } = useStep(steps, STEP_ID.DONE_SCREEN)

	return (
		<OnboardingStepFrame step={step}>
			<div className="done-screen--wrapper">
				{stepContent.map(component => (
					<React.Fragment key={component.id}>
						{
							DoneScreenComponents[
								component.id as keyof typeof DoneScreenComponents
							]
						}
					</React.Fragment>
				))}
			</div>
		</OnboardingStepFrame>
	)
}

export default memo(DoneScreen)
