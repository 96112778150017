import React, { FC, memo, useState, ChangeEvent } from 'react'
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import Flexbox from 'Scenes/Components/FlexBox'

import { generateKey } from '../../../Services/Utils/uniqKeyGenerator'
import { OTHER } from '../../../Services/Strings'
import TextField from '../TextField/TextField'

import './SelectorWithOther.scss'

const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem
const FormControlTSX: any = FormControl
const InputLabelTSX: any = InputLabel

interface IProps {
  items: string[]
  placeHolder?: string
  className?: string
  onChange: Function
}

const SelectorWithOther: FC<IProps> = ({ items, placeHolder,className, onChange }) => {
  const [selectedValue, setSelectedValue] = useState('')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSelectedValue(value)
    if (value.toLocaleLowerCase() === 'other') {
      onChange('')
    } else {
      onChange(value)
    }
  }

  const handleOtherChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  const renderOtherField = () => {
    if (selectedValue.toLocaleLowerCase() !== 'other') {
      return <div></div>
    }
    return (
      <TextField
        wrapperClassName="select-with-other--text-field"
        type="text"
        placeholder={`${OTHER}...`}
        onChange={handleOtherChange}
        inputProps={{ autofocus: 'autofocus' }}
        input={{
          onChange: handleOtherChange
        }}
      />
    )
  }

  return (
    <Flexbox alignItems="center" className={className}>
      <FormControlTSX fullWidth>
        <InputLabelTSX>{placeHolder}</InputLabelTSX>
        <SelectTSX value={selectedValue} onChange={handleChange}>
          <MenuItemTSX disabled>{placeHolder}</MenuItemTSX>
          {items.map((item, index) => {
            return (
              <MenuItemTSX key={generateKey(index)} value={item}>
                {item}
              </MenuItemTSX>
            )
          })}
        </SelectTSX>
      </FormControlTSX>
      {renderOtherField()}
    </Flexbox>
  )
}

export default memo(SelectorWithOther)
