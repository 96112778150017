import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form'

import * as AdminPrintersActions from './AdminPrintersActions'
import { ADMIN_PRINTERS_EDIT } from '../../../../Services/Strings'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import PrinterForm from '../../../Components/PrinterForm/PrinterForm'
import { companies, company, technology } from './constants'

class EditAdminPrinter extends Component {
	componentDidMount() {
		const {
			printers,
			selectPrinterToEdit,
			match,
			setupAdminPrintersPage,
			pageNumber,
			limitPrinters,
			searchPhrase
		} = this.props
		const { id } = match.params
		if (!printers.length) {
			setupAdminPrintersPage(searchPhrase, pageNumber, limitPrinters)
		} else {
			selectPrinterToEdit(id, printers)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { printers, selectPrinterToEdit, match, selectedEditPrinter } =
			this.props
		const { id } = match.params
		if (
			printers.length &&
			(!selectedEditPrinter || prevProps.match.params.id !== id)
		) {
			selectPrinterToEdit(id, printers)
		}
	}

	onSubmit = data => {
		this.props.updatePrinter(data)
	}

	renderFormContent = () => {
		const {
			selectedEditPrinter,
			printingTechnologies,
			onMaintenanceMachineCostFieldsSelectorChange,
			maintenanceMachineSelectorsValues,
			onMachineCostSelector,
			selectedPrinterTechnology,
			isNewCompanyDisabled,
			selectedPrinterCompany,
			printersFullData,
			newCompanySelected,
			onMachineCostValue
		} = this.props
		return (
			<PrinterForm
				selectedPrinterTechnology={selectedPrinterTechnology}
				isNewCompanyDisabled={isNewCompanyDisabled}
				selectedPrinterCompany={selectedPrinterCompany}
				onMaintenanceMachineCostFieldsSelectorChange={
					onMaintenanceMachineCostFieldsSelectorChange
				}
				onMachineCostSelector={onMachineCostSelector}
				maintenanceMachineCostFieldsSelectorValue={
					selectedEditPrinter &&
					maintenanceMachineSelectorsValues[selectedEditPrinter.id]?.value
				}
				onMachineCostValue={onMachineCostValue}
				printingTechnologies={printingTechnologies}
				onSubmit={this.onSubmit}
				initialValues={selectedEditPrinter}
				printerId={selectedEditPrinter && selectedEditPrinter.id}
				printersFullData={printersFullData}
				newCompanySelected={newCompanySelected}
				isAdminForm={true}
			/>
		)
	}

	render() {
		return (
			<NavBarAndMaterial title={ADMIN_PRINTERS_EDIT}>
				<CastorForm
					formTitle={ADMIN_PRINTERS_EDIT}
					content={this.renderFormContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const selector = formValueSelector('adminPrinterFormState')

const mapStateToProps = state => {
	const {
		printers,
		selectedEditPrinter,
		pageNumber,
		limitPrinters,
		searchPhrase,
		maintenanceMachineCostFieldsSelectorValue,
		maintenanceMachineSelectorsValues,
		isNewCompanyDisabled,
		onMachineCostSelector,
		onMachineCostValue
	} = state.AdminPrintersReducer
	const { printingTechnologies, printersFullData } = state.user
	return {
		printers,
		selectedEditPrinter,
		pageNumber,
		limitPrinters,
		printingTechnologies,
		searchPhrase,
		maintenanceMachineCostFieldsSelectorValue,
		maintenanceMachineSelectorsValues,
		selectedPrinterTechnology: selector(state, technology),
		selectedPrinterCompany:
			selector(state, companies) || selector(state, company),
		isNewCompanyDisabled,
		printersFullData,
		onMachineCostSelector,
		onMachineCostValue
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminPrintersActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAdminPrinter)
