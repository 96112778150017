import React, { FC, memo } from 'react'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from '../../../../../../../../../Services/Strings/StringService'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import ButtonWithLoader from '../../../../../../../../Components/ButtonWithLoader'
import TransparentButton from '../../../../../../../../Components/TransparentButton'
import { useUserReducer } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisSelector'

import './MechanicalAnalysisTab.scss'

interface IProps {
	disableButtons: boolean
	feaNewAnalysisLoad: boolean
	showFeaAnalysisOldAnalysis: boolean
	feaOldAnalysisLoad: boolean
	isWeightReductionPart: boolean
	onNewFeaClick: Function
	onViewAnalysisClick: Function
}

const renderViewOldAnalysisButton = (
	showFeaAnalysisOldAnalysis: boolean,
	feaOldAnalysisLoad: boolean,
	onViewAnalysisClick: Function
) => {
	if (!showFeaAnalysisOldAnalysis) {
		return <div></div>
	}
	return (
		<TransparentButton
			onClick={() => onViewAnalysisClick()}
			loading={feaOldAnalysisLoad}
		>
			{getString('VIEW_ANALYSIS')}
		</TransparentButton>
	)
}
const renderButton = (
	isWeightReductionPart: boolean,
	disableButtons: boolean,
	onNewFeaClick: Function,
	feaNewAnalysisLoad: boolean
) => {
	if (
		isWeightReductionPart ||
		disableButtons ||
		!Feature.isFeatureOn(FeatureComponentId.STRESS_ANALYSIS)
	) {
		return <div />
	}

	const disableNewAnalysis = !Feature.isFeatureActive(
		FeatureComponentId.STRESS_ANALYSIS
	)
	return (
		<DetailsPopup
			isHover={disableNewAnalysis}
			data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
			popperDirection="top"
			popperClassName="info-box-wrapper details-popup--contact-us"
			popperContactClassName="info-box-data"
		>
			<ButtonWithLoader
				disabled={disableNewAnalysis}
				onClick={onNewFeaClick}
				loading={feaNewAnalysisLoad}
				qaDataElementName="data-qa-start-new-analysis"
				className="mechanical-analysis--actions--buttons--new"
			>
				{getString('START_NEW_ANALYSIS')}
			</ButtonWithLoader>
		</DetailsPopup>
	)
}

const MechanicalAnalysisButtons: FC<IProps> = ({
	disableButtons,
	onNewFeaClick,
	feaNewAnalysisLoad,
	showFeaAnalysisOldAnalysis,
	onViewAnalysisClick,
	feaOldAnalysisLoad,
	isWeightReductionPart
}) => {
	return (
		<div className="mechanical-analysis--actions--buttons">
			{renderViewOldAnalysisButton(
				showFeaAnalysisOldAnalysis,
				feaOldAnalysisLoad,
				onViewAnalysisClick
			)}
			{renderButton(
				isWeightReductionPart,
				disableButtons,
				onNewFeaClick,
				feaNewAnalysisLoad
			)}
		</div>
	)
}

export default memo(MechanicalAnalysisButtons)
