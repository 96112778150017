import React, { memo, FC } from 'react'
import cx from 'classnames'
import ButtonWithLoader from '../ButtonWithLoader'
import DetailsPopup from '../DetailsPopup'

import './DataTable.scss'

interface Props {
	text: any
	data: any
	className: string
}

const DataTableButtonWithPopup: FC<Props> = ({
	text,
	data: {
		hoverText,
		isHover,
		onClick,
		disabled,
		buttonClassName,
		className: hoverClassName,
		buttonLoading = false
	},
	className
}) => (
	<DetailsPopup
		isHover={isHover}
		data={hoverText}
		popperDirection="bottom"
		popperClassName={cx('info-box-wrapper', hoverClassName)}
		popperContactClassName="info-box-data"
	>
		<div className="data-table-button-with-loader">
			<ButtonWithLoader
				className={`${buttonClassName} ${className}`}
				onClick={onClick}
				loading={buttonLoading}
				disabled={disabled}
			>
				{text}
			</ButtonWithLoader>
		</div>
	</DetailsPopup>
)

export default memo(DataTableButtonWithPopup)
