class CastorTagsInteractor {
  constructor(handleState) {
    this.handleState = newState => {
      this.state = { ...this.state, ...newState }
      handleState(newState)
    }
    this.state = {}
  }

  setInitialState = selectItems => {
    this.state = {
      showSelectField: Array.isArray(selectItems) && selectItems.length > 0,
      selectedItem: Array.isArray(selectItems) ? selectItems[0] : null,
      tag: { text: '', label: '', itemType: '' },
      itemAllreadyExist: false,
      savedValue: ''
    }
    return this.state
  }

  handleChangeInput = (tag, labels) => {
    this.handleState({
      tag: {
        text: tag,
        label: labels[this.state.selectedItem], //danger/ info
        itemType: this.state.selectedItem //manufactured/ off the shelf
      }
    })
  }

  onSelectFieldChange = (labels, value) => {
    const newState = {
      selectedItem: value,
      tag: {
        ...this.state.tag,
        label: labels[value],
        itemType: value
      }
    }
    this.handleState(newState)
  }

  handleTags = (newItemsArr, tag, index, tags, handleTags, reset = false) => {
    tag = this.state.tag

    if (!reset && tags.length < newItemsArr.length) {
      //adding an item
      const checkIfTagExist = tags.filter(t => t.text === tag.text)
      if (checkIfTagExist.length) {
        this.handleState({
          itemAllreadyExist: true,
          savedValue: tag.text
        })
        newItemsArr.splice(index, 1)
      } else {
        this.handleState({
          itemAllreadyExist: false,
          savedValue: ''
        })
        newItemsArr[index] = tag
        handleTags(newItemsArr, true)
      }
    } else {
      // remove or reset
      handleTags(newItemsArr, false, reset)
      this.handleState({
        savedValue: ''
      })
    }
  }

  onTextFieldChange = () => {
    this.handleState({ savedValue: '' })
  }
}

export default CastorTagsInteractor
