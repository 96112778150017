import React, { memo, FC } from 'react'

import DataTable from 'Scenes/Components/DataTable'
import {
  MATERIAL_COMPARISON_HEADER_COMPARISON,
  MATERIAL_COMPARISON_HEADER_PRINTED,
  MATERIAL_COMPARISON_HEADER_ORIGINAL,
  MATERIAL_COMPARISON_HEADER_DEVIATION,
  SHOW_ALL
} from 'Services/Strings'
import { DataTableFieldType } from 'Scenes/Components/DataTable/IDataTableField'
import { DataTableService } from 'Scenes/Components/DataTable/DataTableService'

import '../SolutionAnalysisTabs.scss'
import '../SolutionNewTabStyles.scss'

interface IProps {
  materialComparisonRows: any[][]
  showAll: boolean
  onShowAllClick: Function
}

const dataTableService = new DataTableService()

const MaterialComparisonTab: FC<IProps> = ({
  materialComparisonRows,
  onShowAllClick,
  showAll
}) => {
  const materialComparisonHeaders = [
    dataTableService.RenderFieldObject({
      type: DataTableFieldType.Header,
      text: MATERIAL_COMPARISON_HEADER_COMPARISON,
      className: 'cost-summary--table--text--fixed-height'
    }),
    dataTableService.RenderFieldObject({
      type: DataTableFieldType.Header,
      text: MATERIAL_COMPARISON_HEADER_PRINTED,
      className: 'cost-summary--table--text--fixed-height'
    }),
    dataTableService.RenderFieldObject({
      type: DataTableFieldType.Header,
      text: MATERIAL_COMPARISON_HEADER_ORIGINAL,
      className: 'cost-summary--table--text--fixed-height'
    }),
    dataTableService.RenderFieldObject({
      type: DataTableFieldType.Header,
      text: MATERIAL_COMPARISON_HEADER_DEVIATION,
      className: 'cost-summary--table--text--fixed-height'
    })
  ]

  const customElements = !showAll
    ? [
      dataTableService.RenderFieldObject({
        type: DataTableFieldType.Button,
        text: SHOW_ALL,
        onClick: () => onShowAllClick()
      })
    ] : undefined

  return (
    <div>
      <DataTable
        tableClassName="solution-tab--material-table solution-tab--new-table"
        tableHead={materialComparisonHeaders}
        tableDataRows={materialComparisonRows}
        customElements={customElements}
        showBreakLines={true}
      />
    </div>
  )
}

export default memo(MaterialComparisonTab)
