import React, { FC, memo, useRef } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import BomTemplate from 'Scenes/Components/BomTemplate'
import CastorSelectBox from 'Scenes/Components/CastorSelectBox'
import Flexbox from 'Scenes/Components/FlexBox'
import { bomFileTypes } from 'Scenes/Home/NewUploadProject/fileTypes'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as CircleClose } from 'assets/img/svg/icons/circle_close.svg'
import { ReactComponent as FileExcel } from 'assets/img/svg/icons/file_excel.svg'

import 'Scenes/Home/Customize/CustomizeMaterialNamesMapping/CustomizeMaterialNamesMapping.scss'

interface AddMultipleMaterialsPopupProps {
	isOpen: boolean
	onConfirmMultipleMaterials: Function
	onCancelMultipleMaterials: Function
	fileInputKey: number
	BOMMaterialsFile: Record<string, any>
	isMaterialsNameLoading: boolean
	isMaterialsNames: boolean
	isUserMaterialsNames: boolean
	uploadBom: Function
}

const AddMultipleMaterialsPopup: FC<AddMultipleMaterialsPopupProps> = ({
	isOpen,
	onConfirmMultipleMaterials,
	onCancelMultipleMaterials,
	fileInputKey,
	BOMMaterialsFile,
	isMaterialsNameLoading,
	isMaterialsNames,
	isUserMaterialsNames,
	uploadBom
}) => {
	const dispatch = useDispatch()
	const inputRef: any = useRef(null)
	const onUploadBom = () => inputRef?.current?.click()

	const { isOnPrem } = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer
	)

	return (
		<CastorAlert
			show={isOpen}
			alertClass="add-multiple-materials"
			headerTitle={getString('ADD_MULTIPLE_MATERIALS_POPUP_TITLE')}
			onConfirm={() => onConfirmMultipleMaterials()}
			loadingCalculation={isMaterialsNameLoading}
			onCancel={() => onCancelMultipleMaterials()}
			confirmOptionalText={getString('OK')}
			showCancel={true}
		>
			<div>{getString('ADD_MULTIPLE_MATERIALS_POPUP_TEXT')}</div>
			<div className="add-multiple-materials-popup new-upload-project">
				<Flexbox
					className="bom-file-upload"
					alignItems="center"
					justifyContent="space-between"
				>
					<Flexbox alignItems="center">
						<input
							name="bomFileUpload"
							id="bomFileUpload"
							type="file"
							className="hide-input"
							ref={inputRef}
							value={BOMMaterialsFile ? '' : undefined}
							key={fileInputKey}
							onChange={(e: any) => {
								uploadBom(e.target?.files[0])
							}}
							accept={bomFileTypes.join(',')}
						/>
						{!!BOMMaterialsFile ? (
							<CastorSelectBox
								key={BOMMaterialsFile?.name}
								selected={true}
								withIcon={false}
								boxClassName="box-around"
							>
								<div>
									<FileExcel className="icon-file" />
									<div className="text">{BOMMaterialsFile?.name}</div>
									<CircleClose
										onClick={() => dispatch(uploadBom(null))}
										className="icon-close"
									/>
								</div>
							</CastorSelectBox>
						) : (
							<Flexbox alignItems="center">
								<div
									onClick={onUploadBom}
									className="bom-file-upload__button"
									data-qa="data-qa-bom-choose-file"
								>
									{getString('UPLOAD_FILE_CHOSE_FILE')}
								</div>
								<div className="bom-file-upload__text">
									{getString('UPLOAD_FILE_NO_CHOSEN_FILE')}
								</div>
							</Flexbox>
						)}
						<BomTemplate
							showImage={true}
							isOnPrem={isOnPrem}
							isMaterialsNames={isMaterialsNames}
							isUserMaterialsNames={isUserMaterialsNames}
						/>
					</Flexbox>
				</Flexbox>
			</div>
		</CastorAlert>
	)
}

export default memo(AddMultipleMaterialsPopup)
