import {
	CHANGE_FILTERS_KEY,
	CHANGE_INITIAL_PROJECT_SETUP,
	CHANGE_SEARCH_PHRASE,
	GET_PROJECT_PARTS_STARTED,
	GET_PROJECT_PARTS_SUCCESS,
	GET_PROJECT_PARTS_TO_PRINT_STARTED,
	GET_PROJECT_PARTS_TO_PRINT_SUCCESS
} from '../../../global actions/types'
import {
	benefitsFiltersValues,
	filters,
	statusFiltersValues
} from './filterPartsEnum'
import { getProjectsParts } from 'Services/Network'
import { CONFIGURATION_RESULT_PRINTABLE } from 'Services/Strings'

export const changeFilterKey = (selectedFilter, isBundle) => {
	return {
		type: CHANGE_FILTERS_KEY,
		payload: { selectedFilter, isBundle }
	}
}

export const changeSearchPhrase = searchPhrase => {
	return {
		type: CHANGE_SEARCH_PHRASE,
		payload: searchPhrase
	}
}

export const filterParts = (
	id,
	searchPhrase,
	filterValues,
	isBundle,
	page,
	limit,
	toPrint = false,
	user,
	updatePagination = false
) => {
	return async dispatch => {
		let status = []
		let benefits = []
		let assembliesIds = []
		let starred
		let standaloneParts

		filterValues.forEach(filter => {
			switch (filter) {
				case filters.ALL:
					return
				case filters.PRINTABLE:
				case filters.NOT_PRINTABLE:
					status.push(statusFiltersValues[filter])
					return
				case filters.SAVED:
					starred = 1
					return
				case filters.BUY_TO_FLY:
				case filters.COST_SAVING:
				case filters.TIME_SAVING:
				case filters.CO2_SAVING:
				case filters.COMPLEX_GEOMETRY:
					benefits.push(benefitsFiltersValues[filter])
					const printableStatus = status.indexOf(
						statusFiltersValues[CONFIGURATION_RESULT_PRINTABLE]
					)
					if (printableStatus < 0) {
						status.push(statusFiltersValues[CONFIGURATION_RESULT_PRINTABLE])
					}
					return
				case filters.STANDALONE_PARTS:
					standaloneParts = 1
					return
				default:
					assembliesIds.push(filter)
					return
			}
		})

		try {
			dispatch({
				type: toPrint
					? GET_PROJECT_PARTS_TO_PRINT_STARTED
					: GET_PROJECT_PARTS_STARTED
			})

			const {
				data: {
					parts,
					totalPartsCount,
					leadingConfigurationData,
					generativeDesignParts
				}
			} = await getProjectsParts({
				projectId: isBundle ? '' : id,
				bundleId: isBundle ? id : '',
				searchPhrase,
				status,
				starred,
				benefits,
				standaloneParts,
				assembliesIds,
				page: toPrint ? 1 : page,
				limit: toPrint ? 0 : limit
			})

			dispatch({
				type: toPrint
					? GET_PROJECT_PARTS_TO_PRINT_SUCCESS
					: GET_PROJECT_PARTS_SUCCESS,
				payload: {
					parts,
					totalPartsCount,
					leadingConfigurationData,
					user,
					updatePagination,
					page,
					limit,
					generativeDesignParts
				}
			})

			dispatch({
				type: CHANGE_INITIAL_PROJECT_SETUP,
				payload: false
			})
		} catch (err) {
			console.log(err)
		}
	}
}
