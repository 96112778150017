import React, { FC } from 'react'

import CastorSelectBox from 'Scenes/Components/CastorSelectBox'

import { ReactComponent as CircleClose } from 'assets/img/svg/icons/circle_close.svg'
import { ReactComponent as FileIcon } from 'assets/img/svg/icons/file.svg'

type IProps = {
	uploadedFile: File
	fileChipDeleteClicked: (file: File) => void
}

const File: FC<IProps> = ({ uploadedFile, fileChipDeleteClicked }) => {
	return (
		<CastorSelectBox
			key={uploadedFile.name}
			selected={true}
			withIcon={false}
			boxClassName="file-row--box"
		>
			<div>
				<FileIcon />
				<div className="text">{uploadedFile.name}</div>
			</div>
			<CircleClose
				onClick={(e: any) => {
					e.stopPropagation()
					fileChipDeleteClicked(uploadedFile)
				}}
				className="icon-close"
			/>
		</CastorSelectBox>
	)
}

export default File
