import React, { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import {
	onUpdateSingletonsClick,
	setupAdminSettings
} from 'Scenes/admin/AdminHome/AdminSettings/AdminSettingsActions'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import HideForRoleHOC from 'Services/HOC/HideForRoleHOC'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'


const AdminSingletons: FC = () => {
	const dispatch = useDispatch()
	const { singletonButtonLoading } = useSelector((state: RootStateOrAny) => {
		return state.AdminSettingsReducer
	})
	const { priorities } = useSelector((state: RootStateOrAny) => {
		return state.user
	})

	useEffect(() => {
		dispatch(setupAdminSettings(priorities))
	}, [])

	return (
		<CastorForm
			formTitle={getString('ADMIN_SINGLETONS_TITLE')}
			content={
				<div>
					{/* <Flexbox alignItems="center">
           <CastorSwitch
           onChange={(e: any) => onPriorityToggleChange(e.target.checked)}
           checked={isRelativeToOriginalMaterial}
           />
           <div>priorities</div>
           </Flexbox> */}
					<div style={{ width: '200px' }}>
						<ButtonWithLoader
							onClick={() => dispatch(onUpdateSingletonsClick())}
							loading={singletonButtonLoading}
						>
							{getString('ADMIN_UPDATE_SINGLETONS')}
						</ButtonWithLoader>
					</div>
				</div>
			}
			style={{ maxWidth: 'unset' }}
		/>
	)
}

export default HideForRoleHOC(
	WithFeatureToggleHOC(
		memo(AdminSingletons),
		FeatureComponentId.SINGLETONS_SETTINGS
	),
	[UserRole.USER_MANAGER]
)