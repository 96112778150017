import {
	GET_PART_ANALYSIS,
	GET_PART_ANALYSIS_FAILED,
	GET_PART_ANALYSIS_SUCCESS,
	HEATMAP_SWITCH_VALUE_CHANGED,
	PART_ANALYSIS_3D_MODAL_CLOSED,
	PART_ANALYSIS_3D_MODAL_OPENED,
	PART_ANALYSIS_CONSTRUCTOR,
	PART_ANALYSIS_RETRIEVE_RESULTS_CLICKED,
	PART_ANALYSIS_WILL_UNMOUNT,
	PART_FINANCIAL_UPDATED,
	PART_UPDATED,
	PROJECT_UPDATE_SUCCESS,
	RESULT_RECALCULATED_SUCCESS,
	STAR_PART_SUCCESS,
	WALL_THICKNESS_POLLER_FINISHED,
	WALL_THICKNESS_UPDATED
} from '../../../global actions/types'
import '../../../Services/Prototypes'
import {
	GRAM_UNITS,
	KG_UNITS,
	PART_ANALYSIS_LED_WITH_TITLE_INDEXES,
	PART_ORIGINAL_WEIGHT,
	PART_ORIGINAL_WEIGHT_GRAMS,
	PART_RESULTS_NOT_PRINTABLE
} from '../../../Services/Strings'
import { noSolutionStub } from './noSolutionStub'

const INITIAL_STATE = {
	part: null,
	solution: null,
	project: null,
	error: null,
	loading: false,
	heatmapSwitchVisible: false,
	heatmapOn: true,
	imageURL: null,
	inHouse: false,
	metrics: null,
	show3dButton: true,
	show3dIframe: false,
	partOriginalWeight: null,
	partOriginalWeightText: '',
	partOriginalWeightUnits: '',
	showWidgets: true,
	showRetrieveResultsButton: false,
	ledWithTitleIndexes: PART_ANALYSIS_LED_WITH_TITLE_INDEXES,
	showLoader: false
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PART_ANALYSIS_CONSTRUCTOR:
			return INITIAL_STATE
		case GET_PART_ANALYSIS:
			return { ...state, loading: true, error: null }
		case GET_PART_ANALYSIS_SUCCESS: {
			const part = action.payload.part
			const show3dButton = part.wallThicknessFileURL ? true : false
			const heatmapSwitchVisible = part.wallThiknessImageURL
			const inHouse = action.payload.showInHouseSolutions
			const solution = action.payload.solution || noSolutionStub
			const partWeightData = getPartWeightData(solution)
			const imageURL =
				heatmapSwitchVisible && state.heatmapOn
					? part.wallThiknessImageURL
					: part.imageURL
			const ledWithTitleIndexes = formatLedWithTitleIndexesStrings(part)
			return {
				...state,
				loading: false,
				solution,
				part,
				project: action.payload.project,
				heatmapSwitchVisible,
				inHouse,
				imageURL,
				show3dButton,
				...partWeightData,
				showWidgets: part.result !== PART_RESULTS_NOT_PRINTABLE,
				ledWithTitleIndexes,
				showRetrieveResultsButton: part.result === PART_RESULTS_NOT_PRINTABLE,
				metrics: action.payload.metrics
			}
		}
		case GET_PART_ANALYSIS_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload
			}
		case PART_FINANCIAL_UPDATED:
			return { ...state, part: action.payload }

		case PROJECT_UPDATE_SUCCESS:
		case RESULT_RECALCULATED_SUCCESS:
			let { part, project } = action.payload
			if (!project) {
				project = state.project
			}

			const solution = action.payload.solution || noSolutionStub
			const partWeightData = getPartWeightData(solution)

			return {
				...state,
				solution,
				part,
				project,
				...partWeightData
			}
		case PART_UPDATED: {
			const part = action.payload.part
			const solution = action.payload.solution || noSolutionStub
			const partWeightData = getPartWeightData(solution)

			return {
				...state,
				part,
				solution,
				...partWeightData
			}
		}
		case HEATMAP_SWITCH_VALUE_CHANGED:
			const heatmapOn = action.payload
			const show3dButton = action.payload && state.part.wallThicknessFileURL
			const imageURL = heatmapOn
				? state.part.wallThiknessImageURL
				: state.part.imageURL
			return { ...state, heatmapOn, imageURL, show3dButton }
		case PART_ANALYSIS_WILL_UNMOUNT:
			return { ...INITIAL_STATE }
		case STAR_PART_SUCCESS:
			return { ...state, part: action.payload }
		case PART_ANALYSIS_3D_MODAL_OPENED:
			return {
				...state,
				show3dIframe: true
			}
		case PART_ANALYSIS_3D_MODAL_CLOSED:
			return { ...state, show3dIframe: false }
		case PART_ANALYSIS_RETRIEVE_RESULTS_CLICKED:
			return { ...state, showWidgets: true, showRetrieveResultsButton: false }
		case WALL_THICKNESS_UPDATED:
			return {
				...state,
				showLoader: true,
				show3dButton: false
			}
		case WALL_THICKNESS_POLLER_FINISHED: {
			const show3dButton =
				state.part && state.part.wallThicknessFileURL ? true : false
			return { ...state, showLoader: false, show3dButton }
		}
		default:
			return state
	}
}

const formatLedWithTitleIndexesStrings = part => {
	return PART_ANALYSIS_LED_WITH_TITLE_INDEXES.map(index => {
		const title = index.title.format(part.wallThicknessTestInMM)
		return {
			title,
			color: index.color,
			materialType: index?.materialType,
			featureId: index?.featureId
		}
	})
}

const getPartWeightData = solution => {
	let partOriginalWeightText = PART_ORIGINAL_WEIGHT,
		partOriginalWeightUnits = KG_UNITS,
		partOriginalWeight = solution.partOriginalWeight
	if (partOriginalWeight && partOriginalWeight < 0.5) {
		partOriginalWeight *= 1000
		partOriginalWeightText = PART_ORIGINAL_WEIGHT_GRAMS
		partOriginalWeightUnits = GRAM_UNITS
	}
	return {
		partOriginalWeight,
		partOriginalWeightText,
		partOriginalWeightUnits
	}
}
