import { SupplyChainResultObject } from './ISupplyChain'

export interface IChartData {
	componentChartData: any
	series: any[]
	effectivePointIndex: number
}

export interface IChartLeadData {
	componentChartData: any
	series: any[]
	firstParts: number
	allParts: number
}

export interface ISeries {
	name: string
	data: any
}

export interface IMetalFormParameters {
	camExistence: boolean
	accuracy: boolean
	complexity: number
	cncProgrammingPricePerHour: number
	operationCostPerHour: number
	customsTaxes: number
	domesticShipment: number
	profitMargin: number
}

export interface ICastFormParameters {
	castToolingCost: number
	investmentCastToolingCost: number
}

export interface ICostFormParameters {
	puAnnualKgCO2: number
	puYearsCO2: number
	disposeFactor: number
}

export interface IOptionalMetalFormParameters {
	camExistence?: any
	accuracy?: boolean
	complexity?: number
	cncProgrammingPricePerHour?: number
	operationCostPerHour?: number
	customsTaxes?: number
	domesticShipment?: number
	profitMargin?: number
	puAnnualKgCO2?: number
	puYearsCO2?: number
	disposeFactor?: number
}

export class CostData {
	actualResult: number = 0
	formParameters: any = {}
	chartData: IChartData | null = null
	printCostQuantity: number = 0
	isCostEffective: boolean = false
	supplyChainTraditionalDetails: SupplyChainResultObject | null = null
}

export class CostDataWithSolution extends CostData {
	constructor(
		public solution: any = null,
		public configuration: any = null,
		public actualResult: number = 0,
		public formParameters: any = {},
		public chartData: IChartData | null = null,
		public printCostQuantity: number = 0,
		public isCostEffective: boolean = false,
		public supplyChainTraditionalDetails: SupplyChainResultObject | null = null
	) {
		super()
	}
}

export class LeadData {
	actualResult: number = 0
	formParameters: any = {}
	chartData: IChartData | null = null
}

export class LeadDataWithSolution extends CostData {
	constructor(
		public solution: any = null,
		public configuration: any = null,
		public actualResult: number = 0,
		public formParameters: any = {},
		public chartData: IChartData | null = null,
		public printCostQuantity: number = 0,
		public isCostEffective: boolean = false,
		public supplyChainTraditionalDetails: SupplyChainResultObject | null = null,
		public leadTimeDetails: any = null
	) {
		super()
	}
}
