import React, { FC, memo, useState, useEffect, ChangeEvent } from 'react'
import { getString } from '../../../../../../Services/Strings/StringService'
import IconFactory from '../../../../../Components/StarIcon/IconFactory'
import { getFeaCompareResult } from './SolutionFeaContentService'
import DetailWithTitle from '../../../../../Components/DetailWithTitle/DetailWithTitle'

import './SolutionFea.scss'
import { toFixedOnlyIfNeeded } from '../../../../../../Services/global/toFixedOnlyIfNeeded'

interface IProps {
  userFeaResults: number[]
  feaResult: number
}

const SolutionFeaResults: FC<IProps> = ({ userFeaResults, feaResult }) => {
  const [feaCompareResult, setFeaCompareResult] = useState('')

  useEffect(() => {
    setFeaCompareResult(getFeaCompareResult(userFeaResults, feaResult))
  }, [userFeaResults])

  return (
    <div className="solution-fea--results">
      <div className="solution-fea--results--compare">
        <DetailWithTitle
          title={`${userFeaResults[0]}MPa - ${userFeaResults[1]}MPa`}
          body={getString('MECHANICAL_ANALYSIS_RESULTS_USER_INPUTS')}
          className="solution-fea--results--compare--detail"
          titleClassName="solution-fea--results--compare--detail--title"
          bodyClassName="solution-fea--results--compare--detail--body"
        />
        <IconFactory
          iconName="feaArrows"
          type={feaCompareResult}
          className="solution-fea--results--compare--detail--icon"
        />
        <DetailWithTitle
          title={`${toFixedOnlyIfNeeded(feaResult)}MPa`}
          body={getString('MECHANICAL_ANALYSIS_RESULTS_FEA')}
          className="solution-fea--results--compare--detail"
          titleClassName="solution-fea--results--compare--detail--title"
          bodyClassName="solution-fea--results--compare--detail--body"
        />
      </div>
      <div className="solution-fea--results--result">
        <IconFactory
          iconName="fea"
          type={feaCompareResult}
          className="solution-fea--results--result--icon"
        />
        <div className="solution-fea--results--result--explanation">
          {
            getString('MECHANICAL_ANALYSIS_RESULTS_FEA_EXPLANATION')[
              feaCompareResult
            ]
          }
        </div>
      </div>
    </div>
  )
}

export default memo(SolutionFeaResults)
