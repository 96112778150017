import { Link } from 'react-router-dom'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import cx from 'classnames'

import OverrideComponentHOC from '../../../themes/OverrideComponentHOC'
import CastorFormHeader from '../../Components/CastorForm/CastorFormHeader'
import CastorSwitch from '../../Components/CastorSwitch'
import Button from '../../Components/thirdParty/CreativeTim/components/CustomButtons/Button.jsx'
import { CUSTOMIZE } from 'Services/Strings'
import { generateKey } from 'Services/Utils/uniqKeyGenerator'

import { styles } from '../styles/formStyles.css'

const CustomizeFormSection = props => {
	const {
		explanationHeader,
		explanationArray,
		linkTo,
		switchChecked,
		onToggleChange,
		onSelectedInputUnitsType,
		wrapperClassName,
		toggleClassName,
		qaDataElementName,
		label = '',
		buttonName = '',
		select = false,
		selectTypes = [],
		selectPlaceHolder = '',
		selectedValue = 'disabled'
	} = props
	const renderLink = () => {
		if (linkTo) {
			return (
				<Link to={linkTo}>
					<Button color="primary" data-qa={`${qaDataElementName}-btn`}>
						{buttonName || CUSTOMIZE}
					</Button>
				</Link>
			)
		}
	}

	const renderSelect = () => {
		if (select) {
			return (
				<Select
					className={cx(qaDataElementName)}
					value={selectedValue || 'disabled'}
					onChange={onSelectedInputUnitsType}
					inputProps={{
						name: 'simpleSelect',
						id: 'simple-select'
					}}
					data-qa={qaDataElementName}
				>
					<MenuItem disabled value="disabled">
						{selectPlaceHolder}
					</MenuItem>
					{selectTypes?.map((json, index) => {
						return (
							<MenuItem
								key={generateKey(index)}
								value={json.key}
								data-qa={`data-qa-${json.key}`}
							>
								{json.name}
							</MenuItem>
						)
					})}
				</Select>
			)
		}
	}

	const renderToggle = () => {
		if (onToggleChange) {
			return (
				<FormControlLabel
					control={<CastorSwitch checked={switchChecked} />}
					onChange={onToggleChange}
					label={label}
					className={toggleClassName}
					data-qa={`${qaDataElementName}-toggle`}
				/>
			)
		}
	}
	return (
		<div
			style={styles.itemsDiv}
			className={wrapperClassName}
			data-qa={qaDataElementName}
		>
			<div style={styles.customizeFormElement}>
				<CastorFormHeader
					explanationHeader={explanationHeader}
					explanationArray={explanationArray}
					isInCard={true}
				/>
			</div>
			{renderToggle()}
			{renderLink()}
			{renderSelect()}
		</div>
	)
}

export default OverrideComponentHOC(CustomizeFormSection)
