import { setupAdminPage, updateItem } from '../adminActionsService'
import {
	updateParametersAdmin,
	getParametersAdmin
} from '../../../../Services/Network'
import {
	PARAMETERS_MANAGER_DATA_FETCHED,
	UPDATING_PARAMTER,
	PARAMTER_UPDATE_SUCCEED,
	PARAMETERS_MANAGER_DATA_CALLED
} from '../../../../global actions/types'
import { ParametersFormTypes } from '../../../../Services/models/ParametersManager'
import {
	PARAMETER_UPDATED_SUCCESS,
	PARAMETER_UPDATED_FAILED
} from '../../../../Services/Strings'

export const setupCostingParametersPage = () => {
	return async (dispatch: any) => {
		dispatch({ type: PARAMETERS_MANAGER_DATA_CALLED })
		setupAdminPage(
			dispatch,
			getParametersAdmin,
			[],
			PARAMETERS_MANAGER_DATA_FETCHED,
			null
		)
	}
}

export const updateParameters = (data: any) => {
	return async (dispatch: any) => {
		dispatch({ type: UPDATING_PARAMTER })
		updateItem(
			dispatch,
			updateParametersAdmin,
			data,
			PARAMTER_UPDATE_SUCCEED,
			'',
			PARAMETER_UPDATED_SUCCESS,
			PARAMETER_UPDATED_FAILED
		)
	}
}
export const handleCostingInjectionSubmit = (values: any) => {
	return async (dispatch: any) => {
		dispatch({ type: UPDATING_PARAMTER })
		await updateItem(
			dispatch,
			updateParametersAdmin,
			{ values, type: ParametersFormTypes.COSTING_INJECTION_MOLDING },
			PARAMTER_UPDATE_SUCCEED,
			'',
			PARAMETER_UPDATED_SUCCESS,
			PARAMETER_UPDATED_FAILED
		)
	}
}
export const handleCostingCNCSubmit = (values: any) => {
	return async (dispatch: any) => {
		dispatch({ type: UPDATING_PARAMTER })
		await updateItem(
			dispatch,
			updateParametersAdmin,
			{ values, type: ParametersFormTypes.COSTING_CNC },
			PARAMTER_UPDATE_SUCCEED,
			'',
			PARAMETER_UPDATED_SUCCESS,
			PARAMETER_UPDATED_FAILED
		)
	}
}
export const handleCostingGeneralSubmit = (values: any) => {
	return async (dispatch: any) => {
		dispatch({ type: UPDATING_PARAMTER })
		await updateItem(
			dispatch,
			updateParametersAdmin,
			{ values, type: ParametersFormTypes.GENERAL },
			PARAMTER_UPDATE_SUCCEED,
			'',
			PARAMETER_UPDATED_SUCCESS,
			PARAMETER_UPDATED_FAILED
		)
	}
}

export const handleCosting3DPSubmit = (values: any) => {
	return async (dispatch: any) => {
		dispatch({ type: UPDATING_PARAMTER })
		await updateItem(
			dispatch,
			updateParametersAdmin,
			{ values, type: ParametersFormTypes.COSTING_3DP },
			PARAMTER_UPDATE_SUCCEED,
			'',
			PARAMETER_UPDATED_SUCCESS,
			PARAMETER_UPDATED_FAILED
		)
	}
}

export const handleCastingSubmit = (values: any) => {
	return async (dispatch: any) => {
		dispatch({ type: UPDATING_PARAMTER })
		await updateItem(
			dispatch,
			updateParametersAdmin,
			{ values, type: ParametersFormTypes.COSTING_CASTING },
			PARAMTER_UPDATE_SUCCEED,
			'',
			PARAMETER_UPDATED_SUCCESS,
			PARAMETER_UPDATED_FAILED
		)
	}
}

export const handleInvestmentCastingSubmit = (values: any) => {
	return async (dispatch: any) => {
		dispatch({ type: UPDATING_PARAMTER })
		await updateItem(
			dispatch,
			updateParametersAdmin,
			{ values, type: ParametersFormTypes.COSTING_INVESTMENT_CASTING },
			PARAMTER_UPDATE_SUCCEED,
			'',
			PARAMETER_UPDATED_SUCCESS,
			PARAMETER_UPDATED_FAILED
		)
	}
}

export const handleSupplyChainSubmit = (values: any) => {
	return async (dispatch: any) => {
		dispatch({ type: UPDATING_PARAMTER })
		await updateItem(
			dispatch,
			updateParametersAdmin,
			{ values, type: ParametersFormTypes.COSTING_SUPPLY_CHAIN },
			PARAMTER_UPDATE_SUCCEED,
			'',
			PARAMETER_UPDATED_SUCCESS,
			PARAMETER_UPDATED_FAILED
		)
	}
}

export const handle2dDrawingParameters = (values: any) => {
	return async (dispatch: any) => {
		dispatch({ type: UPDATING_PARAMTER })
		await updateItem(
			dispatch,
			updateParametersAdmin,
			{ values, type: ParametersFormTypes.COSTING_2D_DRAWING },
			PARAMTER_UPDATE_SUCCEED,
			'',
			PARAMETER_UPDATED_SUCCESS,
			PARAMETER_UPDATED_FAILED
		)
	}
}

export const handleCo2TmSubmit = (values: any) => {
	return async (dispatch: any) => {
		dispatch({ type: UPDATING_PARAMTER })
		await updateItem(
			dispatch,
			updateParametersAdmin,
			{
				values,
				type: ParametersFormTypes.COSTING_CO2_TRADITIONAL_MENUFACTURING
			},
			PARAMTER_UPDATE_SUCCEED,
			'',
			PARAMETER_UPDATED_SUCCESS,
			PARAMETER_UPDATED_FAILED
		)
	}
}
