import registerPageStyle from '../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle'
import { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Checkbox, WithStyles, withStyles } from '@material-ui/core'
import { Check } from '@material-ui/icons'

import { styles } from './style'
import Flexbox from 'Scenes/Components/FlexBox'
import { CLOSE, PREV_PATH } from 'Scenes/Home/NewUploadProject/constants'
import { USAGE_AGREEMENT } from 'Services/Constants/RoutesConstants'
import { setStringItemToLocalStorage } from 'Services/LocalStorageService'
import { TURMS_CHECKBOX_LINK_TEXT, TURMS_CHECKBOX_TEXT } from 'Services/Strings'
import { getThemeString } from 'themes/getThemeString'

const registerPageStyleTS: any = registerPageStyle

interface UsageAgreementCheckboxProps
	extends WithStyles<typeof registerPageStyleTS> {
	openInTab?: boolean
	agreedToTerms: boolean
	checkboxClicked: (value: boolean) => void
	classes: any
}

const UsageAgreementCheckbox: FC<UsageAgreementCheckboxProps> = ({
	classes,
	agreedToTerms,
	checkboxClicked,
	openInTab
}) => {
	const history = useHistory()
	const { isOnPrem } = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer
	)

	const onUsageAgreementClick = () => {
		if (isOnPrem || !navigator.onLine) {
			setStringItemToLocalStorage(PREV_PATH, CLOSE)

			if (openInTab) {
				window.open(USAGE_AGREEMENT, '_blank')
			} else {
				history.push(USAGE_AGREEMENT)
			}
		} else {
			var win = window.open(getThemeString('USAGE_AGREEMENT_LINK'), '_blank')
			win?.focus()
		}
	}
	return (
		<Flexbox
			height="50px"
			width="100%"
			flexDirection="row"
			justifyContent="flex-start"
			cy-data="terms_of_use"
			className="terms-block"
			alignItems="center"
			classes={{
				root: classes.checkboxLabelControl,
				label: classes.checkboxLabel
			}}
			style={{ alignSelf: 'center' }}
		>
			<Checkbox
				cy-data="terms_of_use__checkbox"
				onClick={(e: any) => checkboxClicked(e.target.checked)}
				checked={agreedToTerms}
				checkedIcon={<Check className={classes.checkedIcon} />}
				icon={<Check className={classes.uncheckedIcon} />}
				classes={{
					checked: classes.checked
				}}
			/>
			<Flexbox flexDirection="row" justifyContent="flex-start">
				<span style={styles.checkboxLabel}>{TURMS_CHECKBOX_TEXT}&nbsp;</span>
				<div
					style={styles.checkboxLabelLink}
					onClick={() => onUsageAgreementClick()}
				>
					{TURMS_CHECKBOX_LINK_TEXT}
				</div>
			</Flexbox>
		</Flexbox>
	)
}
export default memo(withStyles(registerPageStyleTS)(UsageAgreementCheckbox))
