import React, { FC } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import { IFileWithMaterialAndQuantity } from 'Services/models/IUploadProject'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	state: IFileWithMaterialAndQuantity
	disabledMenuItem: string
	onToleranceChange: (tolerance: ToleranceClass) => void
}

const Tolerance: FC<IProps> = ({
	state,
	disabledMenuItem,
	onToleranceChange
}) => {
	return (
		<Select
			displayEmpty
			className={'file-row--select-field'}
			value={state.customToleranceValue}
			renderValue={selected => {
				if (selected) {
					const stringKey =
						Object.entries(ToleranceClass).find(
							tolerance => tolerance[1] === selected
						)?.[0] || ''
					return getString(stringKey)
				} else {
					return disabledMenuItem
				}
			}}
			onChange={(event: any) =>
				onToleranceChange(event.target.value as ToleranceClass)
			}
			classes={{
				select: 'file-row--select'
			}}
			inputProps={{
				name: 'simpleSelect',
				id: 'simple-select'
			}}
			MenuProps={{
				PaperProps: {
					style: {
						transform: 'translate3d(0, 0, 0)'
					}
				}
			}}
		>
			<MenuItem>{disabledMenuItem}</MenuItem>
			{Object.entries(ToleranceClass).map(tolerance => {
				if (tolerance[1] === ToleranceClass.TOLERANCE_CLASS_IRRELEVANT) {
					return null
				}
				return (
					<MenuItem
						key={tolerance[1]}
						style={{ textTransform: 'capitalize' }}
						value={tolerance[1]}
					>
						{getString(tolerance[0])}
					</MenuItem>
				)
			})}
		</Select>
	)
}

export default Tolerance
