import React, { memo, FC } from 'react'

import DataTableIcon from "./DataTableIcon";
import './DataTable.scss'

interface Props {
  headerClassName: string
  data: any
}

const DataTableIconHeader: FC<Props> = ({
  headerClassName,
  data: { className = '', iconName, iconClassName, hoverText }
}) => (
  <DataTableIcon
    hoverText={hoverText}
    iconClassName={iconClassName}
    iconName={iconName}
    classNames={`data-table-header ${headerClassName} ${className}`}
  />
)

export default memo(DataTableIconHeader)
