import { isEmpty } from 'lodash'

import {
	HANDLE_NOTIFICATION,
	UPLOAD_SCRIPT_ADD_FILE,
	UPLOAD_SCRIPT_ERROR,
	UPLOAD_SCRIPT_FILE_FINISH,
	UPLOAD_SCRIPT_FILE_START
} from '../../../global actions/types'
import { getDBQueryStatus, uploadScript } from '../../../Services/Network'
import {
	SHOW_NOTIFICATION,
	UPDATE_DATABASE_ERROR_FILE_FORMAT,
	UPDATE_DATABASE_ERROR_FILE_LENGTH,
	UPDATE_DATABASE_FAILED,
	UPDATE_DATABASE_SUCCESS
} from '../../../Services/Strings'
import { zipFileTypes } from '../NewUploadProject/fileTypes'
import { timeout } from 'Services/global/timeout'

export const onUploadFile = (sendFile: any): any => {
	return async (dispatch: any) => {
		dispatch({ type: UPLOAD_SCRIPT_FILE_START })

		try {
			if (sendFile) {
				await uploadScript(sendFile)

				//start polling for status
				let dbQueryFinished = false
				let DBExecutionSucceed = false
				while (!dbQueryFinished) {
					const dbQueryStatus = await getDBQueryStatus()
					const licenseServiceStatus = dbQueryStatus?.data?.licenseServiceStatus
					if (licenseServiceStatus?.DBExecutionRunning === false) {
						dbQueryFinished = true
						DBExecutionSucceed = licenseServiceStatus?.DBExecutionSucceed
						break
					}
					await timeout(5000)
				}

				if (DBExecutionSucceed) {
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.SUCCESS,
							notificationMessage: UPDATE_DATABASE_SUCCESS
						}
					})
					dispatch({ type: UPLOAD_SCRIPT_FILE_FINISH })
				} else {
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.ERROR,
							notificationMessage: UPDATE_DATABASE_FAILED
						}
					})
					dispatch({ type: UPLOAD_SCRIPT_FILE_FINISH })
				}
			}
		} catch (e) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: UPDATE_DATABASE_FAILED
				}
			})
			dispatch({ type: UPLOAD_SCRIPT_FILE_FINISH })
		}
	}
}

export const setFile = (newFile: any, readerResult: any): any => {
	return (dispatch: any) => {
		const file = newFile[0]
		const multipleFiles = newFile.length > 1
		const wrongType = !zipFileTypes.includes(file.type)
		const error = multipleFiles
			? UPDATE_DATABASE_ERROR_FILE_LENGTH
			: wrongType
			? UPDATE_DATABASE_ERROR_FILE_FORMAT
			: ''

		if (!isEmpty(error)) {
			dispatch({ type: UPLOAD_SCRIPT_ERROR, payload: { error } })
		} else {
			const preparedToSend = {
				data: readerResult?.split(',').pop(),
				fileName: file.name
			}
			dispatch({
				type: UPLOAD_SCRIPT_ADD_FILE,
				payload: { file, preparedToSend }
			})
		}
	}
}
