import {
  MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR,
  MATERIAL_CHANGED_IN_MATERIAL_SELECTOR,
  MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR,
  SETUP_MATERIAL_PICKER
} from '../../../global actions/types'

export const setup = (
    materials,
    onChange,
    initialMaterial,
    defaultMaterialForTypeArr,
    materialCategories
  ) => {
    return {
      type: SETUP_MATERIAL_PICKER,
      payload: {
        materials,
        onChange,
        initialMaterial,
        defaultMaterialForTypeArr,
        materialCategories
      }
    }
  },
  materialTypeChanged = value => {
    return { type: MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR, payload: value }
  },
  materialCategoryChanged = value => {
    return {
      type: MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR,
      payload: value
    }
  },
  materialChanged = value => {
    return { type: MATERIAL_CHANGED_IN_MATERIAL_SELECTOR, payload: value }
  }
