import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import {
	ADD_MATERIALS_BOM_FILE,
	ADD_MATERIALS_BOM_LOADING,
	ADD_MATERIALS_BOM_POPUP,
	CHANGE_USER_MATERIAL_NAME_MAPPING,
	COST_AND_LEAD_TBL_DATA_ARRANGED,
	CREATE_USER_MATERIAL_NAMES_MAPPING,
	FATCH_USER_MATERIAL_NAMES_MAPPING,
	FULL_TRAY_ASSUMPTION_DATA_ARRANGED,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	IN_HOUSE_PRINTERS_DATA_RECEIVED,
	PART_FILTERING_FORM_DATA_ARRANGED,
	REMOVE_USER_MATERIAL_NAMES_MAPPING,
	START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
	UPDATE_USER_MATERIAL_NAMES_MAPPING,
	UPLOAD_PROJECT_SCENARIO_DATA_ARRANGED,
	USER_DESKTOP_MATERIAL_TOGGLED,
	USER_UNITS_TYPE_SELECTED
} from '../../../global actions/types'
import {
	createUserMaterialNameMapping,
	getAllPrintersCompanies,
	getCustomizationMaterialPriceList,
	getCustomizationPrinterSettingsList,
	getCustomizationSettings,
	getProjectScenario,
	getProjectScenarioParameters,
	getUserMaterialNamesMapping,
	removeUserMaterialNameMapping,
	toggleUserDesktopPrinters,
	updateUnitsType,
	updateUserMaterialNamesMapping,
	uploadMaterialsFile
} from '../../../Services/Network'
import {
	checkSemiProfessionalPrintersToggle,
	getGlobalFilteringArray,
	getPartFilteringArray,
	setupValidationStateKeys
} from './CustomizeLogic'
import {
	ALERT_CALCULATION_STARTED,
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { defaultSettingScenario } from 'Services/Constants'
import { IUserMaterialNamesMapping } from 'Services/models/IMaterialNamesMapping'
import { UnitType } from 'Services/models/IProject'
import { SHOW_NOTIFICATION, YES } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export const fetchCustomizeData = (
	costAndLeadNames: Record<string, any>,
	userSettings?: Record<string, any>,
	drawingCost2dNames?: Record<string, any>,
	printersFullData?: Record<string, any>[]
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})
		Promise.allSettled([
			getCustomizationSettings(),
			getCustomizationMaterialPriceList(),
			getCustomizationPrinterSettingsList(),
			getAllPrintersCompanies(),
			getProjectScenario(),
			getProjectScenarioParameters()
		])
			.then(response => {
				handleCustomizeDataResponse(
					costAndLeadNames,
					response,
					dispatch,
					userSettings,
					drawingCost2dNames,
					printersFullData
				)
			})
			.catch(error => {
				dispatch({
					type: HANDLE_LOADER,
					payload: -1
				})
				console.error(error)
			})
	}
}

export const onToggleDesktopPrinter = (desktopPrinter: boolean) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			await toggleUserDesktopPrinters(!desktopPrinter)
			dispatch({
				type: USER_DESKTOP_MATERIAL_TOGGLED
			})
		} catch (error) {
			console.error(error)
		}
	}
}

export const onSelectedInputUnitsType = (selectedUnitsType: string) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const response = await updateUnitsType(selectedUnitsType)
			const unitsType = response?.data?.userSettings?.unitsType

			dispatch({
				type: USER_UNITS_TYPE_SELECTED,
				payload: { unitsType }
			})
		} catch (error) {
			console.error(error)
		}
	}
}

export const fetchUserMaterialNameMapping = () => {
	return async (dispatch: Dispatch<AnyAction>) => {
		const userMaterialNamesMapping = (await getUserMaterialNamesMapping())?.data
			?.userMaterialNamesMapping
		dispatch({
			type: FATCH_USER_MATERIAL_NAMES_MAPPING,
			payload: { userMaterialNamesMapping }
		})
	}
}

export const onCreateUserMaterialNameMapping = (
	userMaterialNameMapping: IUserMaterialNamesMapping,
	onChangeSelector: (property: string, value: any) => void
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
			payload: {
				isLoading: true
			}
		})
		try {
			const userMaterialNamesMapping = (
				await createUserMaterialNameMapping(userMaterialNameMapping)
			).data.userMaterialNamesMapping

			const newUserMaterialNameMapping = userMaterialNamesMapping.filter(
				(userMaterialNameMappingObject: IUserMaterialNamesMapping) =>
					userMaterialNameMappingObject.expression ===
					userMaterialNameMapping.expression
			)

			dispatch({
				type: CREATE_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					userMaterialNamesMapping,
					userMaterialNameMapping: newUserMaterialNameMapping[0]
				}
			})
			onChangeSelector('type', 'plastic')
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString(
						'USER_MATERIAL_NAME_MAPPING_UPDATED_SUCCESS'
					)
				}
			})
		} catch (error: any) {
			dispatch({
				type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					isLoading: false
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: error.internalMessage || error.validationMessage
				}
			})
		}
	}
}

export const onUpdateMaterialNameMapping = (
	_userMaterialNamesMapping: IUserMaterialNamesMapping[]
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
			payload: {
				isLoading: true
			}
		})
		try {
			const userMaterialNamesMapping = (
				await updateUserMaterialNamesMapping(_userMaterialNamesMapping)
			).data.userMaterialNamesMapping

			const userMaterialNameMappingUpdate = userMaterialNamesMapping.find(
				(userMaterialNameMappingObject: IUserMaterialNamesMapping) =>
					userMaterialNameMappingObject.expression ===
					_userMaterialNamesMapping[0].expression
			)

			dispatch({
				type: UPDATE_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					userMaterialNamesMapping,
					userMaterialNameMappingUpdate
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString(
						'USER_MATERIAL_NAME_MAPPING_UPDATED_SUCCESS'
					)
				}
			})
		} catch (error: any) {
			console.log(error)
			dispatch({
				type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					isLoading: false
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.validationMessage ||
						getString('USER_MATERIAL_NAME_MAPPING_UPDATED_FAILED')
				}
			})
		}
	}
}

export const onChangeSelectedMaterial = (
	userMaterialNameMapping: IUserMaterialNamesMapping[]
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: CHANGE_USER_MATERIAL_NAME_MAPPING,
				payload: {
					userMaterialNameMapping
				}
			})
		} catch (error) {
			console.log(error)
		}
	}
}

export const onRemoveUserMaterialNameMapping = (
	id?: string,
	fromAlert?: boolean,
	active?: boolean
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
			payload: {
				isLoading: true
			}
		})
		try {
			const userMaterialNamesMapping = (
				await removeUserMaterialNameMapping(id, active)
			).data.userMaterialNamesMapping

			dispatch({
				type: REMOVE_USER_MATERIAL_NAMES_MAPPING,
				payload: { userMaterialNamesMapping }
			})
			if (fromAlert) {
				dispatch({ type: ALERT_POPUP_CANCELED })
			}
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString(
						'USER_MATERIAL_NAME_MAPPING_REMOVED_SUCCESS'
					)
				}
			})
		} catch (error: any) {
			console.log(error)
			if (fromAlert) {
				dispatch({ type: ALERT_POPUP_CANCELED })
			}
			dispatch({
				type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					isLoading: false
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.validationMessage ||
						getString('USER_MATERIAL_NAME_MAPPING_REMOVED_FAILED')
				}
			})
		}
	}
}

export const onRemoveAllUserMaterialNameMapping = (active: boolean) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: getString(
					active
						? 'REMOVE_ALL_WARNING_DESCRIPTION'
						: 'REMOVE_ALL_INACTIVE_MATERIALS_WARNING_DESCRIPTION'
				),
				headerTitle: getString('CONFIGURATION_CHANGES_WARNING'),
				alertType: AlertType.WARNING,
				showCancel: true,
				onConfirm: () => {
					dispatch({
						type: ALERT_CALCULATION_STARTED
					})
					dispatch(onRemoveUserMaterialNameMapping(undefined, true, active))
				},
				confirmText: YES
			}
		})
	}
}

const handleCustomizeDataResponse = (
	costAndLeadNames: Record<string, any>,
	response: Record<string, any>[],
	dispatch: Dispatch<AnyAction>,
	userSettings?: Record<string, any>,
	drawingCost2dNames?: Record<string, any>,
	printersFullData?: Record<string, any>
) => {
	const settingsResponse = response[0].value
	const materialPriceResponse = response[1].value
	const printerSettingsResponse = response[2].value
	const printersCompanies = response[3].value?.data?.printerCompanies || []
	const printers = Object.values(printersCompanies).flat()
	const settingsData = settingsResponse?.data
	const originalUserSettingsValues = settingsData.userSettings
	const partFilteringArr = getPartFilteringArray(settingsData)
	const globalOffTheShelfSubstrings =
		settingsData.userSettings.globalOffTheShelfSubstrings
	const filteredGlobalOffTheShelf = getGlobalFilteringArray(
		globalOffTheShelfSubstrings
	)
	const materialPriceData = materialPriceResponse?.data
	const userPrinterSettingsData =
		printerSettingsResponse?.data?.userPrinterSettingsList || []
	const validationStateKeys = {}
	const defaultProjectScenario =
		response[4].value?.data?.defaultProjectScenario ||
		defaultSettingScenario.lowVolume
	const baseDefaultProjectScenarios =
		response[4].value?.data?.baseDefaultProjectScenarios
	const defaultProjectScenarioParameters =
		response[5].value?.data?.defaultProjectScenarioParams
	const isShowSemiProfessionalPrintersToggle =
		checkSemiProfessionalPrintersToggle(printersFullData)
	const selectedUnitsType = settingsData.userSettings.unitsType || UnitType.mm

	setupValidationStateKeys(
		settingsData,
		costAndLeadNames,
		validationStateKeys,
		drawingCost2dNames
	)

	dispatch({
		type: COST_AND_LEAD_TBL_DATA_ARRANGED,
		payload: {
			settingsData,
			materialCosts: materialPriceData?.priceList,
			originalUserSettingsValues,
			validationStateKeys,
			userPrinterSettingsData,
			userSettings,
			isShowSemiProfessionalPrintersToggle,
			selectedUnitsType
		}
	})
	dispatch({
		type: PART_FILTERING_FORM_DATA_ARRANGED,
		payload: {
			partFilteringArr,
			filteredGlobalOffTheShelf
		}
	})
	dispatch({
		type: IN_HOUSE_PRINTERS_DATA_RECEIVED,
		payload: { printersCompanies, printers }
	})
	dispatch({
		type: FULL_TRAY_ASSUMPTION_DATA_ARRANGED,
		payload: { settingsData }
	})
	dispatch({
		type: UPLOAD_PROJECT_SCENARIO_DATA_ARRANGED,
		payload: {
			defaultProjectScenario,
			defaultProjectScenarioParameters,
			baseDefaultProjectScenarios
		}
	})
	dispatch({
		type: HANDLE_LOADER,
		payload: -1
	})
}

export const uploadMaterialsNameBom = (file: File | null) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: ADD_MATERIALS_BOM_FILE,
			payload: file
		})
	}
}

export const openMaterialsNamePopup = (isOpen: boolean) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: ADD_MATERIALS_BOM_POPUP,
			payload: isOpen
		})
	}
}

export const sendMaterialsNameBom = (sendFile: File) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		if (!sendFile) {
			return
		}
		dispatch({
			type: ADD_MATERIALS_BOM_LOADING,
			payload: true
		})
		dispatch({
			type: ADD_MATERIALS_BOM_FILE,
			payload: null
		})

		let reader = new FileReader()
		reader.readAsDataURL(sendFile)
		reader.onload = async () => {
			try {
				const response = await uploadMaterialsFile(
					reader?.result,
					sendFile.name
				)
				if (response.data) {
					dispatch({
						type: FATCH_USER_MATERIAL_NAMES_MAPPING,
						payload: {
							userMaterialNamesMapping: response.data.userMaterialNamesMapping
						}
					})
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.SUCCESS,
							notificationMessage: getString('ADD_MULTIPLE_MATERIALS_SUCCESS')
						}
					})

					dispatch({
						type: ADD_MATERIALS_BOM_LOADING,
						payload: false
					})
				}
			} catch (err: any) {
				console.error(err)
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage:
							err.info ||
							err.message ||
							getString('ADD_MULTIPLE_MATERIALS_ERROR')
					}
				})
				dispatch({
					type: ADD_MATERIALS_BOM_LOADING,
					payload: false
				})
			}
		}
	}
}
