import { differenceWith, find, isEqual } from 'lodash'

import {
	EDIT_COST_ANALYSIS_DATA,
	GET_COST_ANALYSIS_DATA,
	UPDATING_COST_ANALYSIS_DATA
} from './CustomizeAnalysisCostTypes'
import { relatedCategories } from 'Scenes/Home/Customize/CustomizeAnalysisCost/CustomizeAnalysisCostConstants'
import {
	calculateParentSum,
	calculateTotalCost,
	updateCategoryValue
} from 'Scenes/Home/Customize/CustomizeAnalysisCost/CustomizeAnalysisCostService'
import { ICostAnalysisParam } from 'Services/models/ICostAnalysisParameter'

interface IState {
	initialCostAnalysis: ICostAnalysisParam[]
	costAnalysisData: ICostAnalysisParam[]
	isCostAnalysisLoading: boolean
	disableUpdateButton: boolean
	isError: boolean
	calculatedCost: number
}

const INITIAL_STATE: IState = {
	initialCostAnalysis: [],
	costAnalysisData: [],
	isCostAnalysisLoading: false,
	disableUpdateButton: true,
	isError: false,
	calculatedCost: 0
}

export default (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case GET_COST_ANALYSIS_DATA: {
			const { costAnalysisData } = action.payload

			const calculatedCost = calculateTotalCost(costAnalysisData)
			const analysisDataWithSum = calculateParentSum(costAnalysisData)

			return {
				...state,
				costAnalysisData: analysisDataWithSum,
				initialCostAnalysis: analysisDataWithSum,
				disableUpdateButton: true,
				calculatedCost
			}
		}

		case UPDATING_COST_ANALYSIS_DATA: {
			return {
				...state,
				isCostAnalysisLoading: action.payload
			}
		}

		case EDIT_COST_ANALYSIS_DATA: {
			const { category, on } = action.payload

			// need to turn off categories that related, but we don't show them
			const relatedCategoryKey = find(
				relatedCategories,
				(value, key) => key === category
			)

			const updatedIndex = state.costAnalysisData.findIndex(
				param => param.category == category
			)
			const updatedRelatedIndex = state.costAnalysisData.findIndex(
				param => param.category == relatedCategoryKey
			)

			let costAnalysisData = updateCategoryValue(
				state.costAnalysisData,
				updatedIndex,
				category,
				on
			)

			if (updatedRelatedIndex > -1 && relatedCategoryKey) {
				costAnalysisData = updateCategoryValue(
					costAnalysisData,
					updatedRelatedIndex,
					relatedCategoryKey,
					on
				)
			}

			const calculatedCost = calculateTotalCost(costAnalysisData)
			const analysisDataWithSum = calculateParentSum(costAnalysisData)

			return {
				...state,
				costAnalysisData: analysisDataWithSum,
				disableUpdateButton: !differenceWith(
					state.initialCostAnalysis,
					analysisDataWithSum,
					isEqual
				)?.length,
				isError: !analysisDataWithSum.find(el => el.isOn),
				calculatedCost
			}
		}
		default:
			return state
	}
}
