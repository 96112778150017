import { memo, FC } from 'react'

import './DataTable.scss'
import TransparentButton from '../TransparentButton'

interface Props {
  text: any
  data: any
  className: string
}

const DataTableButton: FC<Props> = ({
  text,
  data: { onClick, buttonClassName, disabled },
  className
}) => (
  <TransparentButton
    className={`${buttonClassName} ${className}`}
    disabled={disabled}
    onClick={onClick}
  >
    {text}
  </TransparentButton>
)

export default memo(DataTableButton)
