import registerPageStyle from '../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle'
import { ChangeEvent, FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { withStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InfoOutline from '@material-ui/icons/InfoOutlined'
import LockOutline from '@material-ui/icons/LockOutlined'

import { LOGIN_ROUTE } from '../../../Services/Constants/RoutesConstants'
import { getTheme } from '../../../themes/getTheme'
import { AlertType } from '../../Components/alerts/AlertTypes'
import CastorAlert from '../../Components/alerts/CastorAlert'
import MySimpleAlert from '../../Components/alerts/MySimpleAlert'
import ButtonWithLoader from '../../Components/ButtonWithLoader/index'
import CustomInput from '../../Components/thirdParty/CreativeTim/components/CustomInput/CustomInput'
import {
	forgotPasswordChanged,
	forgotReenterPasswordChanged,
	hideForgotPasswordStrengthAlert,
	hideResetPasswordErrorAlert,
	saveResetToken,
	sendResetPasswordToServer,
	showForgotPasswordStrengthAlert
} from '../AuthActions'
import { styles } from './styles'
import Flexbox from 'Scenes/Components/FlexBox'
import {
	OK,
	PASSWORD,
	RE_ENTER_PASSWORD,
	RESET_PASSWORD,
	RESET_PASSWORD_EMAIL_POPUP_HEADER,
	RESET_PASSWORD_ERROR_ALERT,
	RESET_PASSWORD_SUCCESS_POPUP_BODY,
	RESET_PASSWORD_SUCCESS_POPUP_HEADER
} from 'Services/Strings'

import './ResetPassword.css'

const registerPageStyleTS: any = registerPageStyle
const theme = getTheme()

const ResetPassword: FC<any> = ({ classes }) => {
	const dispatch = useDispatch()
	const params: any = useParams()
	const { token, userEmail } = params
	const {
		loading,
		forgotPassword,
		forgotReenterPassword,
		forgotReenterPasswordValid,
		forgotReenterPasswordInValid,
		showingSimpleAlertTitle,
		strengthScore,
		showingSimpleAlertText,
		showResetPasswordErrorAlert,
		resetPasswordSucceeded,
		resetToken,
		error,
		reenterForgotPassword
	} = useSelector((state: RootStateOrAny) => state.auth)

	useEffect(() => {
		dispatch(saveResetToken(token))
	}, [])

	const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(forgotPasswordChanged(event.target.value))
	}
	const onReenterPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(
			forgotReenterPasswordChanged(
				strengthScore,
				forgotPassword,
				event.target.value
			)
		)
	}
	const onSubmitClicked = () => {
		dispatch(
			sendResetPasswordToServer({
				forgotPassword,
				forgotReenterPassword,
				strengthScore,
				resetToken
			})
		)
	}
	const renderAlert = () => {
		if (resetPasswordSucceeded && !showResetPasswordErrorAlert) {
			return (
				<CastorAlert
					show={resetPasswordSucceeded && !showResetPasswordErrorAlert}
					alertType={AlertType.SUCCESS}
					headerTitle={RESET_PASSWORD_SUCCESS_POPUP_HEADER}
					onConfirm={() => {
						window.location.href = LOGIN_ROUTE
					}}
					onCancel={() => {
						window.location.href = LOGIN_ROUTE
					}}
					showCancel={false}
					confirmOptionalText={OK}
				>
					{RESET_PASSWORD_SUCCESS_POPUP_BODY}
				</CastorAlert>
			)
		}
		if (!showingSimpleAlertText && showResetPasswordErrorAlert) {
			return (
				<CastorAlert
					show={!showingSimpleAlertText && showResetPasswordErrorAlert}
					alertType={AlertType.WARNING}
					headerTitle={'reset failed'}
					onConfirm={() => dispatch(hideResetPasswordErrorAlert())}
					onCancel={() => dispatch(hideResetPasswordErrorAlert())}
					showCancel={false}
					confirmOptionalText={OK}
				>
					{RESET_PASSWORD_ERROR_ALERT}
				</CastorAlert>
			)
		}
		if (!showingSimpleAlertText) {
			return <div />
		}
		return (
			<MySimpleAlert
				style={{ display: 'block' }}
				title={showingSimpleAlertTitle}
				onConfirm={() => dispatch(hideForgotPasswordStrengthAlert())}
			>
				{showingSimpleAlertText}
			</MySimpleAlert>
		)
	}

	return (
		<div className="reset-password-form">
			{renderAlert()}
			<div className="reset-password-form-headers-div">
				<h3 className="reset-password-form-headers-div-title">
					{RESET_PASSWORD_EMAIL_POPUP_HEADER}
				</h3>
				<h5>{userEmail}</h5>
				<img
					alt="img"
					style={styles.logoImage}
					src={theme.whiteBackgroundLogo || theme.logo}
				/>
			</div>
			<div className="reset-password-form-inputs">
				<Flexbox flexDirection="row">
					<CustomInput
						success={strengthScore > 1}
						error={
							forgotPassword && forgotPassword.length > 0 && strengthScore <= 1
						}
						formControlProps={{
							fullWidth: true,
							className: classes.customFormControlClasses
						}}
						inputProps={{
							startAdornment: (
								<InputAdornment
									position="start"
									className={classes.inputAdornment}
								>
									<LockOutline className={classes.inputAdornmentIcon} />
								</InputAdornment>
							),
							placeholder: PASSWORD,
							value: forgotPassword,
							onChange: onPasswordChange,
							type: 'password',
							autoComplete: 'new-password'
						}}
					/>
					<IconButton
						onClick={() => dispatch(showForgotPasswordStrengthAlert())}
						//@ts-ignore
						style={
							forgotPassword && forgotPassword.length > 0 && strengthScore <= 1
								? styles.infoBut
								: styles.infoButHidden
						}
					>
						<InfoOutline />
					</IconButton>
				</Flexbox>
				<CustomInput
					success={forgotReenterPasswordValid}
					error={forgotReenterPasswordInValid}
					formControlProps={{
						fullWidth: true,
						className: classes.customFormControlClasses
					}}
					inputProps={{
						startAdornment: (
							<InputAdornment
								position="start"
								className={classes.inputAdornment}
							>
								<LockOutline className={classes.inputAdornmentIcon} />
							</InputAdornment>
						),
						placeholder: RE_ENTER_PASSWORD,
						value: reenterForgotPassword,
						onChange: onReenterPasswordChange,
						type: 'password',
						autoComplete: 'new-password'
					}}
				/>
				<ButtonWithLoader
					rounded
					loading={loading}
					primary={true}
					style={styles.submitButtonStyle}
					onClick={onSubmitClicked}
				>
					{RESET_PASSWORD}
				</ButtonWithLoader>
				<br />
				<p style={styles.errorTextStyle}>{error}</p>
			</div>
		</div>
	)
}

export default memo(withStyles(registerPageStyleTS)(ResetPassword))
