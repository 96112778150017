import React, { ChangeEvent, FC } from 'react'
import { useDispatch } from 'react-redux'

import { ListItemText, MenuItem, Select } from '@material-ui/core'
import { difference } from 'lodash'

import {
	changeAdvancedFilterCheckBox,
	changeAdvancedGroupFilter
} from '../../AdvancedSettingsActions'
import { IFilterData } from '../../AdvancedSettingsInterface'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import { Danger } from 'Scenes/Components/thirdParty/CreativeTim/components'
import Button from 'Scenes/Components/thirdParty/CreativeTim/components/CustomButtons/Button'
import { IFilter } from 'Services/models/IFilter'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	id: string | number
	filter: IFilterData
}

const GroupCheckboxFilter: FC<IProps> = ({ id, filter }) => {
	const dispatch = useDispatch()

	const selectOptions = filter.selectOptions as IFilter[]
	const selectedOptions = (filter.value as string[]) || []

	const renderValue = !selectedOptions?.length
		? getString('CHOOSE')
		: selectedOptions.length === selectOptions?.length
		? getString('ALL')
		: getString('SELECTED_CERTIFICATIONS').format(selectedOptions?.length)

	const onChangeFilterValue = (value: string[], all?: boolean) => {
		const newValue = all ? selectOptions.map(option => option.name) : value
		dispatch(changeAdvancedGroupFilter(id, filter.name, newValue))

		const checkedCheckboxes = difference(newValue, selectedOptions)
		const unCheckedCheckboxes = difference(selectedOptions, newValue)

		if (checkedCheckboxes.length > 0) {
			checkedCheckboxes.forEach(checkedCheckbox => {
				dispatch(changeAdvancedFilterCheckBox(id, true, checkedCheckbox))
			})
		} else if (unCheckedCheckboxes.length > 0) {
			unCheckedCheckboxes.forEach(unCheckedCheckbox => {
				dispatch(changeAdvancedFilterCheckBox(id, false, unCheckedCheckbox))
			})
		}
	}

	return (
		<div style={{ position: 'relative' }}>
			<Select
				className="select filter-fields--widget--text-field-box"
				MenuProps={{
					variant: 'menu',
					getContentAnchorEl: null
				}}
				multiple
				disableUnderline={true}
				value={selectedOptions}
				onChange={(
					e: ChangeEvent<{
						name?: string | undefined
						value: unknown
					}>
				) => {
					onChangeFilterValue(e.target.value as string[])
				}}
				displayEmpty
				title={renderValue}
				renderValue={() => renderValue}
			>
				<MenuItem value="0" className="select-toggle-all">
					<Button
						onClick={(e: MouseEvent) => {
							e.stopPropagation()
							onChangeFilterValue([], true)
						}}
						className="select-toggle-all--button"
					>
						{filter.selectHeader}
					</Button>
					<Button
						onClick={(e: MouseEvent) => {
							e.stopPropagation()
							onChangeFilterValue([])
						}}
						className="select-toggle-all--button"
					>
						{filter.removeHeader}
					</Button>
				</MenuItem>
				{selectOptions?.length ? (
					selectOptions.map(option => {
						const checked = selectedOptions?.indexOf(option.name) > -1

						return (
							<MenuItem
								className="select-toggle-custom"
								key={option.id}
								value={option.name}
								onClick={e => {
									e.stopPropagation()
								}}
							>
								<CastorCheckbox checked={checked} />
								<ListItemText
									className="select-toggle-custom-label"
									primary={getString(option.actualName)}
								/>
								<div
									className="select-toggle-only"
									onClick={(e: React.MouseEvent) => {
										e.stopPropagation()
										onChangeFilterValue([option.name])
									}}
								>
									{getString('SELECT_ONLY_TECHNOLOGY')}
								</div>
							</MenuItem>
						)
					})
				) : (
					<div />
				)}
			</Select>
			{filter.error && (
				<Danger className="text_field__error select-error">
					{filter.error}
				</Danger>
			)}
		</div>
	)
}

export default GroupCheckboxFilter
