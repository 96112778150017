import { FC, memo, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { getThemeString } from '../../../themes/getThemeString'
import { CastorBannerType } from '../CastorBanner/CastorBannerType.enum'
import { IconLockCreditCard } from '../CastorIcons/iconCreditCard'
import { buttonOnClick, setText } from './CastorLockedPartsAction'
import LockedPartsButtons from './LockedPartsButtons'
import { LOCKED_PARTS_BANNER } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	amountOfLockedParts: number
	isLightUser: boolean
	contactUsEmail: string
}

const CastorLockedPartsListView: FC<IProps> = ({
	amountOfLockedParts,
	isLightUser,
	contactUsEmail
}) => {
	const { isOnPrem } = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer
	)
	const { disabledButton, buttonText } = useSelector(
		(state: RootStateOrAny) => state.CastorLockedPartsReducer
	)
	const { userDetails } = useSelector((state: RootStateOrAny) => state.user)

	const dispatch = useDispatch()

	const type = useMemo(
		() =>
			isLightUser ? CastorBannerType.CONTACT_US : CastorBannerType.UPGRADE_NOW,
		[isLightUser]
	)

	const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		dispatch(
			buttonOnClick(
				userDetails.email || '',
				contactUsEmail,
				type,
				userDetails.name
			)
		)
	}

	useEffect(() => {
		dispatch(setText(type))
	}, [dispatch, isLightUser, type])

	return (
		<div className="lock-part--list-view">
			<div className="lock-part--list-view--cell-img">
				<IconLockCreditCard />
			</div>
			<div className="lock-part--list-view--cell-text">
				{LOCKED_PARTS_BANNER[0]} <b>{amountOfLockedParts}</b>{' '}
				{amountOfLockedParts === 1
					? LOCKED_PARTS_BANNER[5]
					: LOCKED_PARTS_BANNER[1]}
			</div>
			<div className="lock-part--list-view--cell-text">
				{isLightUser ? (
					getString('LOCKED_PARTS_BANNER_LIGHT')
				) : (
					<>
						{LOCKED_PARTS_BANNER[2]} {amountOfLockedParts}{' '}
						{amountOfLockedParts === 1
							? LOCKED_PARTS_BANNER[4]
							: LOCKED_PARTS_BANNER[3]}
						<div>
							{getThemeString('LOCKED_PARTS_BANNER_6')}{' '}
							{getThemeString('LOCKED_PARTS_BANNER_7')}
						</div>
					</>
				)}
			</div>
			{!isOnPrem && (
				<LockedPartsButtons
					componentId={3}
					disabled={disabledButton}
					className="lock-part--list-view--upgrade-btn"
					color="primary"
					buttonText={buttonText}
					onClick={onButtonClick}
					userEmail={userDetails.email}
				/>
			)}
		</div>
	)
}

export default memo(CastorLockedPartsListView)
