import React, { FC } from 'react'
import { ReactComponent as FEAPassed } from '../../../assets/img/svg/FEA Passed.svg'
import { ReactComponent as FEAFailed } from '../../../assets/img/svg/FEA FAILED.svg'
import { ReactComponent as FEABorderline } from '../../../assets/img/svg/FEA BORDLINE.svg'
import { IOtherProps } from '../../../Services/models/IOtherProps'
import { FeaResult } from '../../../Services/models/ISolutionFea'

interface Props extends IOtherProps {
  type: string
}

export const IconFea: FC<Props> = ({ type, ...props }) => {
  switch (type) {
    case FeaResult.passed:
      return <FEAPassed {...props} />
    case FeaResult.borderline:
      return <FEABorderline {...props} />
    case FeaResult.failed:
      return <FEAFailed {...props} />
    default:
      return <div></div>
  }
}
