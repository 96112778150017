export const OTHER = 'Other'
export const company = 'company'
export const companies = 'companies'
export const technology = 'printerTechnologyName'
export const typicalKwh = 'typicalKwh'

export const initialPrinterState = {
	name: '',
	company: '',
	newCompany: '',
	description: '',
	buildRate: '',
	trayX: '',
	trayY: '',
	trayZ: '',
	machineCost: '',
	hourlyCost: '',
	holeThresholdMM: '',
	trayWeightLimitation: '',
	infrastructureCostAsPercentOfMachineCost: null,
	maintenanceAsPercentOfMachineCostPerYear: null,
	maintenanceFixedMachineCostPerYear: null,
	reviewsLink: '',
	defaultLayerThickness: '',
	accuracy: null,
	minimalTolerance: '',
	printerTechnologyName: '',
	isMainstream: false,
	typicalKwh: ''
}
