import React, { FC, memo } from 'react'

import './NewFeatureTag.scss'
import cx from 'classnames'

interface IProps {
  isWhite?: boolean
}

const NewFeatureTag: FC<IProps> = ({ isWhite }) => {
  return <div className={cx('new-feature-tag', {
    white: isWhite
  })}>New</div>
}

export default memo(NewFeatureTag)
