import { post } from '../../Services/Network'

export const adopt3dPrint = (
  projectId: string,
  partId: number,
  configurationId: number,
  quantity: number
) => {
  const params = { projectId, partId, configurationId, quantity }
  return post('evonik/sendToPrint', params)
}

export const sendReNewSubscriptionRequest = (
  subscriptionRequestType: number,
  userEmail: string | null
) => {
  let params = {
    subscriptionRequestType,
    userEmail: userEmail || null
  }

  return post('evonik/reNewSubscriptionRequest', params)
}
