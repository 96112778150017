import { forEach, isEmpty, isNil, isObject, isString } from 'lodash'

import {
	REQUIRED,
	TABLE_DEVIATION_DATA_ERROR,
	TABLE_VALUE_DATA_ERROR
} from '../../../Services/Strings'
import { checkErrorValues, MAX_PERCENTAGE_STDEV } from './constants'
import { getString } from 'Services/Strings/StringService'

export const diffInPercentage = (val: number, stdev: number) => {
	return Number(((stdev * 100) / val).toFixed()) < MAX_PERCENTAGE_STDEV
}

export const checkOnEmpty = (
	stdev: number | string | Record<string, unknown>
) => {
	return (isString(stdev) && isEmpty(stdev)) || isNil(stdev)
}

export const validate = (values: Record<string, any>) => {
	const errors = {} as Record<string, any>
	if (isEmpty(values)) {
		return errors
	}

	forEach(checkErrorValues, item => {
		const selectedValue: any = values[item.name]
		const relatedField = item.relatedTo && values[item.relatedTo]
		const relatedFieldIsFilled = checkNestedElementsHaveValues(relatedField)

		if (item.checkIfEmpty && !selectedValue) {
			errors[item.name] = REQUIRED
		}

		// if we don't have val and stdev or values is not object (for old values)
		if (
			(!selectedValue || !isObject(selectedValue)) &&
			item.checkNestedElements &&
			item.checkIfEmpty
		) {
			if (relatedFieldIsFilled) {
				delete errors[item.name]
				return
			}
			errors[item.name] = TABLE_VALUE_DATA_ERROR
		}

		if (selectedValue && isObject(selectedValue) && item.checkNestedElements) {
			const nestedElementsHaveValues =
				checkNestedElementsHaveValues(selectedValue)

			if (
				(!item.checkIfEmpty && !nestedElementsHaveValues) ||
				(!nestedElementsHaveValues && relatedFieldIsFilled)
			)
				return

			const { X = {}, Y = {}, Z = {} } = selectedValue as any
			const { val: valX, stdev: stdevX } = X
			const { val: valY, stdev: stdevY } = Y
			const { val: valZ, stdev: stdevZ } = Z

			const stdevXAllow = diffInPercentage(+valX, +stdevX)
			const stdevYAllow = diffInPercentage(+valY, +stdevY)
			const stdevZAllow = diffInPercentage(+valZ, +stdevZ)

			const checkValuesArr = [
				+valX,
				+valY,
				+valZ,
				!checkOnEmpty(stdevX),
				!checkOnEmpty(stdevY),
				!checkOnEmpty(stdevZ)
			]

			// if some is empty or 0
			if (checkValuesArr.some(std => !std)) {
				errors[item.name] = TABLE_VALUE_DATA_ERROR
			} else if (
				(stdevX && !stdevXAllow) ||
				(stdevY && !stdevYAllow) ||
				(stdevZ && !stdevZAllow)
			) {
				errors[item.name] = TABLE_DEVIATION_DATA_ERROR
			}
			if (
				item.checkNestedForMinMax &&
				checkValuesArr.some(
					value =>
						typeof value === 'number' &&
						(value > item?.max || value <= item.min)
				)
			) {
				errors[item.name] = getString(
					'NUMBER_VALIDATION_NOT_INCLUDING_ZERO'
				).format(item?.min, item?.max)
			}
		}
		if (selectedValue && item.checkIfMinMax) {
			if (selectedValue > item?.max || selectedValue <= item?.min) {
				errors[item.name] = getString(
					item.checkIfEmpty
						? 'NUMBER_VALIDATION_REQUIRED_NOT_INCLUDING_ZERO'
						: 'NUMBER_VALIDATION_NOT_INCLUDING_ZERO'
				).format(item?.min, item?.max)
			}
		}

		if (selectedValue && item.checkIfOnlyMin) {
			if (selectedValue <= item?.min) {
				errors[item.name] = getString(
					item.checkIfEmpty
						? 'NUMBER_VALIDATION_FROM_REQUIRED'
						: 'NUMBER_VALIDATION_FROM_OPTIONAL'
				).format(item?.min)
			}
		}

		if (selectedValue && item.deviationFor) {
			const stdevAllow = diffInPercentage(
				values[item.deviationFor],
				selectedValue
			)
			if (!stdevAllow) {
				errors[item.name] = TABLE_DEVIATION_DATA_ERROR
			}
		}
	})

	return errors
}

export const checkNestedElementsHaveValues = (data?: Record<string, any>) => {
	if (!data || isEmpty(data)) return false
	return Object.keys(data).some((key: keyof typeof data) =>
		Object.values(data[key]).some(value => value)
	)
}

export const getExistingValue = (
	selectedId: number,
	checkBoxId: number,
	optionalPostProcessAvailability: Record<string, any>,
	selectedPrinterTechnology: string
) => {
	const getPostProcess = optionalPostProcessAvailability
		? optionalPostProcessAvailability[checkBoxId]
		: {}
	const getPostProcessByTechnology = getPostProcess
		? getPostProcess[selectedPrinterTechnology]
		: {}
	const isOn = getPostProcessByTechnology
		? getPostProcessByTechnology?.materials_ids?.find(
				(id: number) => id === selectedId
		  )
		: false
	return !!isOn
}

export const materialTypeChange = (
	e: React.ChangeEvent<HTMLInputElement>,
	change: (name: string, value: string | null) => void
) => {
	change('type', e.target.value)
	change('category', '')
	change('subCategory', '')
}
