import React, { FC, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import cx from 'classnames'

import { Route } from '../SideBarTypes'
import NewUserFolder from './NewUserFolder'
import {
	onNewUserFolderClick,
	onProjectsSearchPhraseChange,
	searchFoldersAndProjects
} from 'global actions'
import SearchBar from 'Scenes/Components/SearchBar/SearchBar'
import usePrevious from 'Services/CustomHooks/usePrevious'
import { Feature, FeatureComponentId } from 'Services/models/Features'

type IProps = {
	itemIcon: string
	updateState: (routes: Route[]) => void
}

const ProjectsToolBar: FC<IProps> = ({ itemIcon, updateState }) => {
	const { projectsSearchPhrase, showCreateNewFolder, routes } = useSelector(
		(state: RootStateOrAny) => state.userHome
	)
	const userFoldersInSidebar = Feature.isFeatureOn(
		FeatureComponentId.USER_FOLDERS_IN_SIDEBAR
	)
	const dispatch = useDispatch()

	const onNewFolderClick = () => {
		dispatch(onNewUserFolderClick())
	}

	const prevSearchPhrase = usePrevious(projectsSearchPhrase)

	useEffect(() => {
		if (!prevSearchPhrase && !projectsSearchPhrase) {
			return
		}
		let delayTime = 0
		if (prevSearchPhrase !== projectsSearchPhrase) {
			delayTime = 500
		}
		const delayDebounceFn = setTimeout(() => {
			dispatch(searchFoldersAndProjects(projectsSearchPhrase, routes))
		}, delayTime)

		return () => clearTimeout(delayDebounceFn)
	}, [dispatch, prevSearchPhrase, projectsSearchPhrase])

	return (
		<div className="sidebar--projects-toolbar--wrapper">
			<div className="sidebar--projects-toolbar">
				<SearchBar
					value={projectsSearchPhrase}
					onChange={(value: string) =>
						dispatch(onProjectsSearchPhraseChange(value))
					}
					className={cx('sidebar--projects-toolbar--search-input', {
						'full-width': !userFoldersInSidebar
					})}
				/>

				{userFoldersInSidebar && (
					<div
						data-qa="data-qa-sidebar-new-folder-btn"
						className={cx(
							itemIcon,
							'sidebar--projects-toolbar--new-folder-icon'
						)}
						onClick={onNewFolderClick}
					>
						<CreateNewFolderIcon />
					</div>
				)}
			</div>
			{showCreateNewFolder && (
				<NewUserFolder itemIcon={itemIcon} updateState={updateState} />
			)}
		</div>
	)
}

export default ProjectsToolBar
