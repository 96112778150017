import loginPageStyle from '../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle'
import { ChangeEvent, FC, memo, useEffect, useRef, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { TextField, withStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import InputAdornment from '@material-ui/core/InputAdornment'
import Email from '@material-ui/icons/Email'
import LockOutline from '@material-ui/icons/LockOutlined'
import cx from 'classnames'

import PackageJson from '../../../../package.json'
import {
	emailChanged,
	forgotPasswordClicked,
	forgotPasswordOnClose,
	getLoginProviders,
	loginPasswordChanged,
	loginUser,
	resetAuthState,
	resetPasswordRequestConfirm,
	sendResetEmailConfirmed,
	skipLoginProvider
} from '../../../global actions/index'
import { ENTER_KEY_CODE, LOGIN_LS_ERRORS } from '../../../Services/Constants'
import { REGISTER_ROUTE } from '../../../Services/Constants/RoutesConstants'
import { uploadProjectRoute } from '../../../Services/routeFuncs'
import {
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_ON_PREM_TEXT,
	FORGOT_PASSWORD_POPUP_BODY,
	FORGOT_PASSWORD_POPUP_TITLE,
	LOGIN_EMAIL_PLACEHOLDER,
	LOGIN_PASSWORD_PLACEHOLDER,
	LOGIN_REGISTER_BUT,
	LOGIN_TITLE,
	OK,
	RESET_PASSWORD_EMAIL_POPUP_HEADER,
	RESET_PASSWORD_REQUEST_ERROR,
	RESET_PASSWORD_REQUEST_SUCCESS,
	USER_PROFILE_EMAIL_ALERT
} from '../../../Services/Strings'
import { getTheme } from '../../../themes/getTheme'
import { getThemeString } from '../../../themes/getThemeString'
import CastorAlert from '../../Components/alerts/CastorAlert'
import ButtonWithLoader from '../../Components/ButtonWithLoader/index'
import PoweredBy from '../../Components/PoweredBy/index'
import LoginCard from '../../Components/thirdParty/CreativeTim/components/Cards/LoginCard'
import GridContainer from '../../Components/thirdParty/CreativeTim/components/Grid/GridContainer'
import ItemGrid from '../../Components/thirdParty/CreativeTim/components/Grid/ItemGrid'
import PageLoader from '../../Loader/PageLoader'
import LoginProviders from './LoginProviders'
import { styles } from './styles'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import FlexBox from 'Scenes/Components/FlexBox/index'
import { useSkipKeyDown } from 'Services/CustomHooks/useSkipKeyDown'
import {
	getStringItemFromLocalStorage,
	setStringItemToLocalStorage
} from 'Services/LocalStorageService'

import './Login.scss'

const loginPageStyleTS: any = loginPageStyle
const theme = getTheme()
export interface LocationPartsParams {
	disableAnimation?: boolean
	resetAuthState?: boolean
	pathname: string
	state: {
		disableAnimation?: boolean
		resetAuthState?: boolean
	}
}

const Login: FC<any> = ({ classes }) => {
	const errorRef = useRef<any>(null)
	const [isVisible, setIsVisible] = useState(false)
	const wrapperRef = useRef(null)
	const [showLogin, setShowLogin] = useState(false)
	const dispatch = useDispatch()
	const location = useLocation<LocationPartsParams>()
	const [cardAnimation, setCardAnimation] = useState(
		location?.state?.disableAnimation ? '' : 'cardHidden'
	)

	const history: any = useHistory()
	const {
		resetEmailRequestSuccess,
		resetRequestError,
		error,
		showForgotPasswordPopUp,
		loggedIn,
		email,
		password,
		language,
		loading,
		registerPage,
		loginProviders,
		loginFields,
		loadedPage
	} = useSelector((state: RootStateOrAny) => state.auth)
	const [loggedInState, setLoggedInState] = useState(loggedIn)
	const { loaderCounter, isOnPrem, isAllowRegister } = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer
	)

	const fetchLoginProviders = async () => {
		await dispatch(getLoginProviders())
	}

	useEffect(() => {
		if (!error || location?.state?.resetAuthState) {
			dispatch(resetAuthState(location?.state?.resetAuthState))
		}
		const setTimeCardAnimation = setTimeout(() => setCardAnimation(''), 700)
		fetchLoginProviders()

		if (
			!location.pathname.includes('login') &&
			!loggedIn &&
			theme.skipLoginPage
		) {
			history.push('/home/castorLight')
			window.location.reload()
		} else {
			setShowLogin(true)
		}
		return () => clearTimeout(setTimeCardAnimation)
	}, [])

	useEffect(() => {
		const current = errorRef.current || null
		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					setIsVisible(true)
				} else {
					setIsVisible(false)
				}
			})
		})

		if (current) observer.observe(current)

		return () => {
			if (current) observer.unobserve(current)
		}
	}, [error])

	useEffect(() => {
		if (isVisible) {
			setStringItemToLocalStorage(LOGIN_LS_ERRORS, '')
		}
	}, [isVisible])

	useEffect(() => {
		if (loggedInState !== loggedIn) {
			updateStateAndRoute()
			setLoggedInState(loggedIn)
		}
	}, [loggedIn, loggedInState])

	useSkipKeyDown(() => {
		dispatch(skipLoginProvider())
	})

	const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(emailChanged(event.target.value))
	}

	const onKeyUp = (event: KeyboardEvent | any) => {
		if (event.keyCode === ENTER_KEY_CODE) {
			onSubmitClicked()
		}
	}

	const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(loginPasswordChanged(event.target.value))
	}

	const onSubmitClicked = () => {
		dispatch(loginUser({ email, password }, language))
	}

	const onForgotPasswordClicked = () => {
		dispatch(forgotPasswordClicked(!showForgotPasswordPopUp))
	}

	const updateStateAndRoute = () => {
		history.push(uploadProjectRoute())
	}

	const renderResetPasswordFailedAlert = () => {
		return (
			<CastorAlert
				alertType={AlertType.WARNING}
				headerTitle={FORGOT_PASSWORD_POPUP_TITLE}
				show={!!resetRequestError}
				onConfirm={() => dispatch(resetPasswordRequestConfirm())}
				onCancel={() => dispatch(resetPasswordRequestConfirm())}
				showCancel={false}
				confirmOptionalText={OK}
			>
				{resetRequestError || RESET_PASSWORD_REQUEST_ERROR}
			</CastorAlert>
		)
	}

	const renderResetPasswordSuccessAlert = () => {
		return (
			<CastorAlert
				show={resetEmailRequestSuccess && !resetRequestError}
				headerTitle={RESET_PASSWORD_EMAIL_POPUP_HEADER}
				onConfirm={() => dispatch(resetPasswordRequestConfirm())}
				onCancel={() => dispatch(resetPasswordRequestConfirm())}
				showCancel={false}
				confirmOptionalText={OK}
				alertType={AlertType.SUCCESS}
			>
				{RESET_PASSWORD_REQUEST_SUCCESS}
			</CastorAlert>
		)
	}

	const renderResetPasswordAlert = () => {
		if (isOnPrem || !navigator.onLine) {
			return (
				<CastorAlert
					show={showForgotPasswordPopUp}
					inputLabel={FORGOT_PASSWORD_POPUP_BODY}
					alertClass="req-email"
					headerTitle={FORGOT_PASSWORD_POPUP_TITLE}
					validationMsg={USER_PROFILE_EMAIL_ALERT}
					onConfirm={() => dispatch(forgotPasswordOnClose())}
					onCancel={() => dispatch(onForgotPasswordClicked())}
					showCancel={false}
					confirmOptionalText={OK}
				>
					{FORGOT_PASSWORD_ON_PREM_TEXT}
				</CastorAlert>
			)
		}

		return (
			<CastorAlert
				show={showForgotPasswordPopUp && !resetRequestError}
				type="input"
				inputLabel={FORGOT_PASSWORD_POPUP_BODY}
				inputPlaceholder={LOGIN_EMAIL_PLACEHOLDER}
				inputDefaultValue={email}
				alertClass="req-email"
				inputType="email"
				headerTitle={FORGOT_PASSWORD_POPUP_TITLE}
				validationMsg={USER_PROFILE_EMAIL_ALERT}
				onConfirm={(data: any) => dispatch(sendResetEmailConfirmed(data.email))}
				onCancel={() => onForgotPasswordClicked()}
				confirmOptionalText={OK}
				resetFormOnCancel
			/>
		)
	}

	const showRegisterArea = () => {
		const blockRegisterLink = process.env.REACT_APP_REGISTER_LINK === 'false'
		if (isAllowRegister && !blockRegisterLink) {
			return (
				<>
					{!loginProviders.length && <Divider style={styles.divider} />}
					<div
						className={cx('register-text', {
							providers: loginProviders.length
						})}
					>
						<p style={styles.subtitle}>
							{getThemeString('LOGIN_REGISTER_HEADER')}
						</p>
						<Link to={REGISTER_ROUTE}>{LOGIN_REGISTER_BUT}</Link>
					</div>
				</>
			)
		}
		return <div />
	}

	if (!showLogin || !loadedPage) {
		return <></>
	}

	return (
		<div className={cx(classes.wrapper, 'login-screen')} ref={wrapperRef}>
			{loaderCounter ? (
				<PageLoader />
			) : (
				<>
					{renderResetPasswordAlert()}
					{renderResetPasswordSuccessAlert()}
					{renderResetPasswordFailedAlert()}
					<div className={classes.fullPage}>
						<div className={classes.content}>
							<div className={classes.container}>
								<GridContainer justifyContent="center">
									<ItemGrid xs={12} sm={6} md={4}>
										<form>
											<LoginCard
												customCardClass={classes[cardAnimation]}
												headerColor="rose"
												cardTitle={LOGIN_TITLE}
												cardSubtitle={
													<img
														alt={'logo-img'}
														style={styles.logoImage}
														src={theme.whiteBackgroundLogo || theme.logo}
													/>
												}
												footerAlign="center"
												content={
													<FlexBox flexDirection="column">
														{loginFields && (
															<>
																<TextField
																	label={LOGIN_EMAIL_PLACEHOLDER}
																	className="login-screen--input"
																	id="email"
																	fullWidth
																	InputProps={{
																		endAdornment: (
																			<InputAdornment
																				position="end"
																				className={classes.inputAdornment}
																			>
																				<Email
																					className={classes.inputAdornmentIcon}
																				/>
																			</InputAdornment>
																		)
																	}}
																	value={email}
																	onChange={onEmailChange}
																	onKeyUp={onKeyUp}
																	autoFocus
																/>
																<TextField
																	label={LOGIN_PASSWORD_PLACEHOLDER}
																	className="login-screen--input"
																	id="password"
																	fullWidth
																	InputProps={{
																		endAdornment: (
																			<InputAdornment
																				position="end"
																				className={classes.inputAdornment}
																			>
																				<LockOutline
																					className={classes.inputAdornmentIcon}
																				/>
																			</InputAdornment>
																		),
																		type: 'password'
																	}}
																	value={password}
																	onChange={onPasswordChange}
																	onKeyUp={onKeyUp}
																	autoFocus={false}
																/>
																<div className="register-text-link">
																	<div onClick={onForgotPasswordClicked}>
																		{FORGOT_PASSWORD}?
																	</div>
																</div>
																<ButtonWithLoader
																	qaDataElementName="data-qa-login-btn"
																	className="login-button"
																	loading={loading}
																	color={'primaryNewStyle'}
																	style={styles.submitButtonStyle}
																	onClick={onSubmitClicked}
																>
																	{LOGIN_TITLE}
																</ButtonWithLoader>
															</>
														)}
														{!!error && (
															<p ref={errorRef} style={styles.errorTextStyle}>
																{error}
															</p>
														)}
														<LoginProviders />
														{registerPage && showRegisterArea()}
														<PoweredBy className="login-powered-by" />
													</FlexBox>
												}
											/>
										</form>
									</ItemGrid>
								</GridContainer>
							</div>
						</div>
						<div
							className={classes.fullPageBackground}
							style={{ backgroundImage: 'url(' + theme.bgImage + ')' }}
						/>
					</div>
					<span className="login-version-text">V{PackageJson.version}</span>
				</>
			)}
		</div>
	)
}

export default memo(withStyles(loginPageStyleTS)(Login))
