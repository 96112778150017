import React, { FC, memo } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import forEach from 'lodash/forEach'
import map from 'lodash/map'

import * as CustomizeSupplyChainActions from './CustomizeSupplyChainActions'
import { getString } from '../../../../Services/Strings/StringService'
import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import { Table } from '../../../Components/thirdParty/CreativeTim/components'
import ItemGrid from '../../../Components/thirdParty/CreativeTim/components/Grid/ItemGrid'
import CustomizeBooleanInput from './CustomizeSupplyInput/CustomizeBooleanInput'
import CustomizeNumberInput from './CustomizeSupplyInput/CustomizeNumberInput'
import { CUSTOM } from 'Scenes/Home/NewUploadProject/constants'
import { UPLOAD_PROJECT_SCENARIO_METHODS_HEADER } from 'Services/Strings'

import './CustomizeSupplyChain.scss'

const ItemGridTSX: any = ItemGrid

interface IReduxStore {
	CustomizeSupplyChainReducer: any
}

interface ICustomizeProps {
	supplyChainTableData: any
	isEdit: boolean
	isLoading: boolean
	isError: boolean
	isApplyDisabled: boolean
	onEditToggle: Function
	onCancelToggle: Function
	onApplyData: Function
	updateDataValue: Function
	[name: string]: any
	projectScenarioSettings: any
}

const CustomizeSupplyChainTbl: FC<ICustomizeProps> = ({
	supplyChainTableData,
	isError,
	isEdit,
	isLoading,
	isApplyDisabled,
	onCancelToggle,
	updateDataValue,
	onEditToggle,
	onApplyData,
	projectScenarioSettings
}) => {
	const makeValue = (type: string, tableRows: any, key: string) => {
		if (type === 'boolean')
			return map(tableRows, (row, index) => (
				<CustomizeBooleanInput
					key={`${key}_${row.key}_${index}`}
					{...row}
					updateDataValue={updateDataValue}
					isEdit={isEdit}
				/>
			))
		else
			return map(tableRows, (row, index) => (
				<CustomizeNumberInput
					key={`${key}_${row.key}_${index}`}
					{...row}
					updateDataValue={updateDataValue}
					isEdit={isEdit}
				/>
			))
	}

	const makeName = (name: string, hoverText: string) => {
		return (
			<div className="with-tooltip">
				<span>{name}</span>
				<div className="tooltip info-box-wrapper">{hoverText}</div>
			</div>
		)
	}

	const renderTblData = () => {
		let tableData: any = []
		const supplyChainMethodNames = getString('PROJECT_SCENARIO_METHODS_NAMES')
		const supplyChainMethodInformation = getString(
			'PROJECT_SCENARIO_METHODS_INFORMATION'
		)

		forEach(supplyChainTableData, (dataValue: any) => {
			const values = makeValue(dataValue.type, dataValue.data, dataValue.key)
			const name = makeName(
				supplyChainMethodNames[dataValue.key],
				supplyChainMethodInformation[dataValue.key]
			)

			tableData.push([name, ...values])
		})

		return tableData
	}

	const renderButton = (
		buttonText: string,
		buttonFunction: Function,
		buttonClassName: string,
		allowLoading?: boolean,
		isApply?: boolean
	) => {
		const showLoading = allowLoading && isEdit && isLoading
		return (
			<ButtonWithLoader
				size={20}
				style={{ padding: '5px 20px', width: 'unset' }}
				top={20}
				disabled={isApply && isApplyDisabled}
				loading={showLoading}
				onClick={() => buttonFunction()}
				className={buttonClassName}
			>
				{buttonText}
			</ButtonWithLoader>
		)
	}

	const tableData = renderTblData()
	let fileNames = []
	fileNames.push(getString('PROPERTY'))
	projectScenarioSettings.forEach((file: any) => {
		fileNames.push(UPLOAD_PROJECT_SCENARIO_METHODS_HEADER[file.key])
	})

	const isCustom = projectScenarioSettings.find(function (scenario: {
		key: string
	}) {
		return scenario.key === CUSTOM
	})

	return (
		<div className="customize__table">
			<ItemGridTSX>
				<Table
					editTableStyle={'customizeCO2TableCell'}
					tableHead={fileNames}
					tableData={tableData}
				/>
			</ItemGridTSX>
			{isCustom && (
				<div className="customize__table-edit">
					{isEdit &&
						renderButton(getString('CANCEL'), onCancelToggle, 'cancel-button')}
					<div>
						{renderButton(
							isEdit ? getString('APPLY') : getString('EDIT'),
							isEdit ? onApplyData : onEditToggle,
							'add-button',
							true,
							isEdit
						)}
					</div>
					{isError && (
						<p className="customize__input-error">
							{getString('EMISSION_COMPARISON_SEND_ERROR')}
						</p>
					)}
				</div>
			)}
		</div>
	)
}

const mapStateToProps = ({ CustomizeSupplyChainReducer }: IReduxStore) => {
	return {
		...CustomizeSupplyChainReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeSupplyChainActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizeSupplyChainTbl)
)
