import { setupAdminPage } from '../adminActionsService'
import { getHealthStatusAdmin } from '../../../../Services/Network'
import {
	GET_ADMIN_VERSION_PAGE_PARAMETERS,
	GET_ADMIN_VERSION_PAGE_PARAMETERS_GOT_ERROR,
	GET_ADMIN_VERSION_PAGE_PARAMETERS_SUCCESS
} from '../../../../global actions/types'

export const setupAdminVersionPage = (searchPhrase: any, pageNumber: any) => {
	return (dispatch: any) => {
		setupAdminPage(
			dispatch,
			getHealthStatusAdmin,
			[searchPhrase, pageNumber],
			GET_ADMIN_VERSION_PAGE_PARAMETERS_SUCCESS,
			GET_ADMIN_VERSION_PAGE_PARAMETERS_GOT_ERROR,
			pageNumber
		)
		dispatch({ type: GET_ADMIN_VERSION_PAGE_PARAMETERS })
	}
}
