import React, { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { onCreateUserMaterialNameMapping } from 'Scenes/Home/Customize/CustomizeActions'
import AddMaterialNamesMapping from 'Scenes/Home/Customize/CustomizeMaterialNamesMapping/AddMaterialNamesMapping'
import { IUserMaterialNamesMapping } from 'Services/models/IMaterialNamesMapping'
import { getTheme } from 'themes/getTheme'

const { defaultMaterial } = getTheme()

const AddMaterialNames: FC = () => {
	const { materials, materialTypes, materialCategories } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const dispatch = useDispatch()

	const updateSelectedMaterial = (
		materialNameMapping: IUserMaterialNamesMapping,
		onChangeSelector: (property: string, value: any) => void
	) => {
		dispatch(
			onCreateUserMaterialNameMapping(materialNameMapping, onChangeSelector)
		)
	}

	return (
		<AddMaterialNamesMapping
			defaultMaterial={defaultMaterial}
			materialCategories={materialCategories}
			materialTypes={materialTypes}
			materials={materials}
			disabled={false}
			updateSelectedMaterial={updateSelectedMaterial}
		/>
	)
}

export default memo(AddMaterialNames)
