import { ComponentType, FC, memo } from 'react'
import { connect } from 'react-redux'

import { UserRole } from 'Scenes/Home/UserRole.enum'

interface IProps {
	roles: number[]
}

interface IReduxStore {
	user: any
}

export default <P extends object>(
	WrappedComponent: ComponentType<P> | any,
	avoidRoles: UserRole[],
	showForAvoid: boolean = false
): FC<P & IProps & any> => {
	const isAvoidRole: FC<IProps> = ({ roles, ...ownProps }: IProps) => {
		let avoidRole: boolean | undefined
		for (let index = 0; index < roles.length; index++) {
			if (avoidRoles.includes(roles[index])) {
				avoidRole = true
			}
		}
		if (showForAvoid) {
			return avoidRole ? <WrappedComponent {...(ownProps as P)} /> : <div />
		}
		return avoidRole ? <div /> : <WrappedComponent {...(ownProps as P)} />
	}

	const mapStateToProps = (
		{ user: { roles } }: IReduxStore,
		ownProps: P
	): IProps => ({
		roles,
		...ownProps
	})

	return connect(mapStateToProps)(memo(isAvoidRole))
}