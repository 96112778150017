import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ReanalyzingStatus } from 'Scenes/Components/thirdParty/CreativeTim/components/Sidebar/SideBarTypes'
import { ProjectStatusPartsType } from 'Scenes/Home/NewUploadProject/UploadingProject.enum'
import { UploadingProjectInitialState } from 'Scenes/Home/NewUploadProject/UploadingProjectReducer'
import { getUploadingStatus } from 'Scenes/Home/NewUploadProject/UploadProjectActionService'
import { getString } from 'Services/Strings/StringService'

const StatusMessage: FC = () => {
	const params: any = useParams()
	const { projectId } = useSelector((state: RootStateOrAny) => {
		return state?.uploadProject
	})

	const [
		{
			inProgressStatus,
			statusPartsType,
			numberOfPartsAnalyzed,
			numberOfTotalPartsAnalyzed,
			advancedFeaturesStatus,
			projectStatus,
			reanalyzingStatus
		}
	] = useSelector((state: RootStateOrAny) => {
		return [
			state.UploadingProjectReducer?.projectUploading[
				projectId || params.projectId || 0
			] || new UploadingProjectInitialState()
		]
	})
	const noPartsInAnalyze = !numberOfPartsAnalyzed && !numberOfTotalPartsAnalyzed

	const currentStatus = getUploadingStatus(
		inProgressStatus,
		advancedFeaturesStatus,
		projectStatus
	)

	if (
		statusPartsType === ProjectStatusPartsType.SINGLE_PART &&
		reanalyzingStatus !== ReanalyzingStatus.inProgress
	) {
		return <span className="loading-text">{currentStatus}</span>
	}

	if (
		!noPartsInAnalyze &&
		statusPartsType === ProjectStatusPartsType.MULTIPLE_PART &&
		reanalyzingStatus !== ReanalyzingStatus.inProgress
	) {
		return (
			<span className="loading-text">
				{getString('INITIAL_ANALYSIS_OUT_OF_PARTS').format(
					numberOfPartsAnalyzed,
					numberOfTotalPartsAnalyzed
				)}
			</span>
		)
	}

	return (
		<span className="loading-text">
			{getString('PROJECT_PENDING_SUBTITLE')}
		</span>
	)
}

export default memo(StatusMessage)
