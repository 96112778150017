import { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import WithFeatureToggleHOC from '../../../Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from '../../../Services/models/Features'
import { CASTOR_ENTERPRISE } from '../../../Services/Strings'
import IconFactory from '../StarIcon/IconFactory'
import { Button } from '../thirdParty/CreativeTim/components'

import './UserActivityData.scss'

interface IProps {
  showEnterprise: boolean
  onUpgradeClick: Function
  buttonCustomText: string
}

const UpgradeUser: FC<IProps> = ({
  showEnterprise,
  onUpgradeClick,
  buttonCustomText
}) => {
  const isOnPrem = useSelector(
    (state: RootStateOrAny) => state.GlobalReducer.isOnPrem
  )

  return (
    <div className="user-activity-data--upgrade">
      <div className="user-activity-data--upgrade--product">
        {showEnterprise && (
          <>
            <IconFactory iconName="crown" />
            <span className="user-activity-data--upgrade--product--text">
              {CASTOR_ENTERPRISE}
            </span>
          </>
        )}
      </div>
      <div>
        {!isOnPrem && (
          <Button
            size="xs"
            color="primary"
            onClick={onUpgradeClick}
            className="user-activity-data--upgrade--button"
            data-qa="data-qa-upgrade-btn"
          >
            {buttonCustomText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default WithFeatureToggleHOC(
  memo(UpgradeUser),
  FeatureComponentId.ENTERPRISE_UPGRADE
)
