import Numeral from 'numeral'

import { displayInRange } from 'Services/global/calculateRange'
import { IConfiguration } from 'Services/models/IConfiguration'
import { ISolution } from 'Services/models/ISolution'
import { getString } from 'Services/Strings/StringService'

export const generateProductionWithoutClusterParameters = (
	solution: ISolution,
	configuration: IConfiguration,
	isShowValuesInRanges: boolean = false,
	fullTrayAssumption: boolean = false,
	disabledParameters: Record<string, any>,
	costDetailsSolutionWithoutCluster?: Record<string, any>
) => {
	if (!costDetailsSolutionWithoutCluster) {
		return {
			//material cost
			materialCostWithoutCluster: null,
			totalPartMaterialWithoutCluster: null,
			totalSupportMaterialWithoutCluster: null,
			unusedMaterialWasteWithoutCluster: null,
			materialCostIsWithoutCluster: null,
			partWeightIsWithoutCluster: null,

			//machine cost
			machineCostWithoutCluster: null,
			buildPrepTimeWithoutCluster: null,
			printTimeWithoutCluster: null,
			machineCostPerHourWithoutCluster: null,
			partPrintTimeWithoutCluster: null,
			totalSharedMachineTimeWithoutCluster: null,

			//consumables cost
			consumablesCostWithoutCluster: null,
			operatingCostWithoutCluster: null,
			perBuildConsumablesWithoutCluster: null,
			mandatoryPostProcessCostWithoutCluster: null,
			optionalPostProcessCostWithoutCluster: null,

			//labor cost
			laborWithoutCluster: null,
			laborHoursBuildExchangeWithoutCluster: null,
			laborHoursDuringPureBuildWithoutCluster: null,
			laborTechnicianFTEWithoutCluster: null,
			assemblingCostWithoutCluster: null,

			// order fees cost
			totalOrderFeesCostWithoutCluster: null,

			//others
			totalProductionCostsPerUnitWithoutCluster: null,
			buildPrepWithoutCluster: null
		}
	}
	const { quantity } = configuration || {}

	const threeDPrintingCostsBreakDown =
		costDetailsSolutionWithoutCluster.costDetails.threeDPrintingCostsBreakDown

	const minCostDetails = costDetailsSolutionWithoutCluster.minCostDetails
	const maxCostDetails = costDetailsSolutionWithoutCluster.maxCostDetails

	const minThreeDPrintingCostsBreakDown =
		minCostDetails?.threeDPrintingCostsBreakDown
	const maxThreeDPrintingCostsBreakDown =
		maxCostDetails?.threeDPrintingCostsBreakDown
	const perPartCosts =
		costDetailsSolutionWithoutCluster.costDetails.threeDPrintingCostsBreakDown
			.supplyChainCostsBreakDown?.perPartCosts
	const minPartCosts =
		costDetailsSolutionWithoutCluster.minCostDetails
			.threeDPrintingCostsBreakDown.supplyChainCostsBreakDown?.perPartCosts
	const maxPartCosts =
		costDetailsSolutionWithoutCluster.maxCostDetails
			.threeDPrintingCostsBreakDown.supplyChainCostsBreakDown?.perPartCosts
	const partsQuantity = quantity

	const {
		unusedMaterialWaste,
		totalSupportMaterial,
		totalPartMaterial,
		materialCost,
		materialCostIs,
		partWeightIs
	} = getMaterialCost(
		threeDPrintingCostsBreakDown,
		minThreeDPrintingCostsBreakDown,
		maxThreeDPrintingCostsBreakDown,
		partsQuantity,
		isShowValuesInRanges,
		configuration,
		true
	)

	const {
		laborHoursBuildExchange,
		laborHoursDuringPureBuild,
		labor,
		mandatoryPostProcessCost,
		optionalPostProcessCost,
		laborTechnicianFTE,
		assemblingCost
	} = getLaborCost(
		threeDPrintingCostsBreakDown,
		minThreeDPrintingCostsBreakDown,
		maxThreeDPrintingCostsBreakDown,
		partsQuantity,
		fullTrayAssumption,
		isShowValuesInRanges,
		true
	)

	const {
		machineCost,
		printTime,
		totalSharedMachineTime,
		buildPrepTime,
		machineCostPerHour,
		partPrintTime
	} = getMachineCost(
		threeDPrintingCostsBreakDown,
		minThreeDPrintingCostsBreakDown,
		maxThreeDPrintingCostsBreakDown,
		partsQuantity,
		isShowValuesInRanges,
		disabledParameters,
		true
	)

	const { consumablesCost, operatingCost, perBuildConsumables } =
		getConsumableCost(
			threeDPrintingCostsBreakDown,
			minThreeDPrintingCostsBreakDown,
			maxThreeDPrintingCostsBreakDown,
			partsQuantity,
			isShowValuesInRanges,
			true
		)

	const { totalOrderFeesCost, totalProductionCostsPerUnit, buildPrep } =
		getFeesAndCost(
			threeDPrintingCostsBreakDown,
			minCostDetails.threeDPrintingCostsBreakDown,
			maxCostDetails.threeDPrintingCostsBreakDown,
			partsQuantity,
			isShowValuesInRanges,
			perPartCosts,
			minPartCosts,
			maxPartCosts,
			true
		)

	return {
		//material cost
		materialCostWithoutCluster: materialCost,
		totalPartMaterialWithoutCluster: totalPartMaterial,
		totalSupportMaterialWithoutCluster: totalSupportMaterial,
		unusedMaterialWasteWithoutCluster: unusedMaterialWaste,
		materialCostIsWithoutCluster: materialCostIs,
		partWeightIsWithoutCluster: partWeightIs,

		//machine cost
		machineCostWithoutCluster: machineCost,
		buildPrepTimeWithoutCluster: buildPrepTime,
		printTimeWithoutCluster: printTime,
		machineCostPerHourWithoutCluster: machineCostPerHour,
		partPrintTimeWithoutCluster: partPrintTime,
		totalSharedMachineTimeWithoutCluster: totalSharedMachineTime,

		//consumables cost
		consumablesCostWithoutCluster: consumablesCost,
		operatingCostWithoutCluster: operatingCost,
		perBuildConsumablesWithoutCluster: perBuildConsumables,
		mandatoryPostProcessCostWithoutCluster: mandatoryPostProcessCost,
		optionalPostProcessCostWithoutCluster: optionalPostProcessCost,

		//labor cost
		laborWithoutCluster: labor,
		laborHoursBuildExchangeWithoutCluster: laborHoursBuildExchange,
		laborHoursDuringPureBuildWithoutCluster: laborHoursDuringPureBuild,
		laborTechnicianFTEWithoutCluster: laborTechnicianFTE,
		assemblingCostWithoutCluster: assemblingCost,

		// order fees cost
		totalOrderFeesCostWithoutCluster: totalOrderFeesCost,

		//others
		totalProductionCostsPerUnitWithoutCluster: totalProductionCostsPerUnit,
		buildPrepWithoutCluster: buildPrep
	}
}
export const generateProductionParameters = (
	solution: ISolution,
	configuration: IConfiguration,
	isShowValuesInRanges: boolean = false,
	perPartCosts: Record<string, any>,
	minPartCosts: Record<string, any>,
	maxPartCosts: Record<string, any>,
	fullTrayAssumption: boolean = false,
	disabledParameters: Record<string, any>,
	originalDesignPerPartCosts?: Record<string, any>,
	originalDesignMinPartCosts?: Record<string, any>,
	originalDesignMaxPartCosts?: Record<string, any>,
	originalDesignSolution?: ISolution,
	isCluster?: boolean
) => {
	let originalDesignThreeDPrintingCostsBreakDown
	let originalDesignMinThreeDPrintingCostsBreakDown
	let originalDesignMaxThreeDPrintingCostsBreakDown

	const { costDetails } = solution || {}
	const { quantity } = configuration || {}
	const { minCostDetails, maxCostDetails } = costDetails
	const threeDPrintingCostsBreakDown = costDetails?.threeDPrintingCostsBreakDown
	const isFullTrayAssumptionOn =
		threeDPrintingCostsBreakDown?.fullTrayAssumption
	const minThreeDPrintingCostsBreakDown =
		minCostDetails?.threeDPrintingCostsBreakDown
	const maxThreeDPrintingCostsBreakDown =
		maxCostDetails?.threeDPrintingCostsBreakDown

	const partsQuantity = quantity

	if (originalDesignSolution) {
		const { costDetails: originalDesignCostDetails } =
			originalDesignSolution || {}
		const {
			minCostDetails: originalDesignMinCostDetails,
			maxCostDetails: originalDesignMaxCostDetails
		} = originalDesignCostDetails

		originalDesignThreeDPrintingCostsBreakDown =
			originalDesignCostDetails?.threeDPrintingCostsBreakDown
		originalDesignMinThreeDPrintingCostsBreakDown =
			originalDesignMinCostDetails?.threeDPrintingCostsBreakDown
		originalDesignMaxThreeDPrintingCostsBreakDown =
			originalDesignMaxCostDetails?.threeDPrintingCostsBreakDown
	}

	const {
		unusedMaterialWaste,
		totalSupportMaterial,
		totalPartMaterial,
		materialCost,
		materialCostIs,
		partWeightIs,
		originalDesignUnusedMaterialWaste,
		originalDesignTotalSupportMaterial,
		originalDesignTotalPartMaterial,
		originalDesignMaterialCost
	} = getMaterialCost(
		threeDPrintingCostsBreakDown,
		minThreeDPrintingCostsBreakDown,
		maxThreeDPrintingCostsBreakDown,
		partsQuantity,
		isShowValuesInRanges,
		configuration,
		false,
		originalDesignThreeDPrintingCostsBreakDown,
		originalDesignMinThreeDPrintingCostsBreakDown,
		originalDesignMaxThreeDPrintingCostsBreakDown
	)

	const {
		laborHoursBuildExchange,
		laborHoursDuringPureBuild,
		labor,
		mandatoryPostProcessCost,
		optionalPostProcessCost,
		laborTechnicianFTE,
		originalDesignLabor,
		originalDesignLaborHoursBuildExchange,
		originalDesignLaborHoursDuringPureBuild,
		originalDesignMandatoryPostProcessCost,
		originalDesignOptionalPostProcessCost,
		assemblingCost
	} = getLaborCost(
		threeDPrintingCostsBreakDown,
		minThreeDPrintingCostsBreakDown,
		maxThreeDPrintingCostsBreakDown,
		partsQuantity,
		fullTrayAssumption,
		isShowValuesInRanges,
		false,
		originalDesignThreeDPrintingCostsBreakDown,
		originalDesignMinThreeDPrintingCostsBreakDown,
		originalDesignMaxThreeDPrintingCostsBreakDown,
		isCluster
	)

	const {
		machineCost,
		printTime,
		totalSharedMachineTime,
		buildPrepTime,
		machineCostPerHour,
		partPrintTime,
		originalDesignMachineCost,
		originalDesignTotalSharedMachineTime,
		originalDesignPrintTime
	} = getMachineCost(
		threeDPrintingCostsBreakDown,
		minThreeDPrintingCostsBreakDown,
		maxThreeDPrintingCostsBreakDown,
		partsQuantity,
		isShowValuesInRanges,
		disabledParameters,
		false,
		originalDesignThreeDPrintingCostsBreakDown,
		originalDesignMinThreeDPrintingCostsBreakDown,
		originalDesignMaxThreeDPrintingCostsBreakDown
	)

	const {
		consumablesCost,
		operatingCost,
		perBuildConsumables,
		originalDesignConsumablesCost,
		originalDesignOperatingCost,
		originalDesignPerBuildConsumables
	} = getConsumableCost(
		threeDPrintingCostsBreakDown,
		minThreeDPrintingCostsBreakDown,
		maxThreeDPrintingCostsBreakDown,
		partsQuantity,
		isShowValuesInRanges,
		false,
		originalDesignThreeDPrintingCostsBreakDown,
		originalDesignMinThreeDPrintingCostsBreakDown,
		originalDesignMaxThreeDPrintingCostsBreakDown
	)

	const {
		totalOrderFeesCost,
		totalProductionCostsPerUnit,
		buildPrep,
		originalDesignTotalProductionCostsPerUnit,
		originalDesignTotalOrderFeesCost,
		originalDesignBuildPrep
	} = getFeesAndCost(
		threeDPrintingCostsBreakDown,
		minThreeDPrintingCostsBreakDown,
		maxThreeDPrintingCostsBreakDown,
		partsQuantity,
		isShowValuesInRanges,
		perPartCosts,
		minPartCosts,
		maxPartCosts,
		false,
		originalDesignThreeDPrintingCostsBreakDown,
		originalDesignMinThreeDPrintingCostsBreakDown,
		originalDesignMaxThreeDPrintingCostsBreakDown,
		originalDesignPerPartCosts,
		originalDesignMinPartCosts,
		originalDesignMaxPartCosts
	)

	return {
		//material cost
		materialCost,
		totalPartMaterial,
		totalSupportMaterial,
		unusedMaterialWaste,
		materialCostIs,
		partWeightIs,
		originalDesignUnusedMaterialWaste,
		originalDesignTotalSupportMaterial,
		originalDesignTotalPartMaterial,
		originalDesignMaterialCost,

		//machine cost
		machineCost,
		buildPrepTime,
		printTime,
		machineCostPerHour,
		partPrintTime,
		isFullTrayAssumptionOn,
		totalSharedMachineTime,
		originalDesignMachineCost,
		originalDesignTotalSharedMachineTime,
		originalDesignPrintTime,

		//consumables cost
		consumablesCost,
		operatingCost,
		perBuildConsumables,
		mandatoryPostProcessCost,
		optionalPostProcessCost,
		originalDesignConsumablesCost,
		originalDesignOperatingCost,
		originalDesignPerBuildConsumables,
		originalDesignMandatoryPostProcessCost,
		originalDesignOptionalPostProcessCost,

		//labor cost
		labor,
		laborHoursBuildExchange,
		laborHoursDuringPureBuild,
		laborTechnicianFTE,
		originalDesignLabor,
		originalDesignLaborHoursBuildExchange,
		originalDesignLaborHoursDuringPureBuild,
		assemblingCost,

		// order fees cost
		totalOrderFeesCost,
		originalDesignTotalOrderFeesCost,

		//others
		totalProductionCostsPerUnit,
		buildPrep,
		originalDesignTotalProductionCostsPerUnit,
		originalDesignBuildPrep
	}
}

const getMaterialCost = (
	threeDPrintingCostsBreakDown: Record<string, any>,
	minThreeDPrintingCostsBreakDown: Record<string, any>,
	maxThreeDPrintingCostsBreakDown: Record<string, any>,
	partQuantity: number,
	isShowValuesInRanges: boolean = false,
	configuration: IConfiguration,
	combinedClusterCosts: boolean,
	originalDesignThreeDPrintingCostsBreakDown?: Record<string, any>,
	originalDesignMinThreeDPrintingCostsBreakDown?: Record<string, any>,
	originalDesignMaxThreeDPrintingCostsBreakDown?: Record<string, any>
) => {
	let originalDesignUnusedMaterialWaste
	let originalDesignTotalSupportMaterial
	let originalDesignTotalPartMaterial
	let originalDesignMaterialCost

	const partsQuantity = combinedClusterCosts ? 1 : partQuantity

	const unusedMaterialCost =
		threeDPrintingCostsBreakDown.totalUnusedMaterialWasteCost ??
		threeDPrintingCostsBreakDown.unusedMaterialWaste
	const minUnusedMaterial =
		minThreeDPrintingCostsBreakDown.totalUnusedMaterialWasteCost ??
		minThreeDPrintingCostsBreakDown.unusedMaterialWaste
	const maxUnusedMaterial =
		maxThreeDPrintingCostsBreakDown.totalUnusedMaterialWasteCost ??
		maxThreeDPrintingCostsBreakDown.unusedMaterialWaste
	const totalSupportMaterialCost =
		threeDPrintingCostsBreakDown.totalSupportMaterialCost ??
		threeDPrintingCostsBreakDown.totalSupportMaterial
	const minTotalSupportMaterialCost =
		minThreeDPrintingCostsBreakDown.totalSupportMaterialCost ??
		minThreeDPrintingCostsBreakDown.totalSupportMaterial
	const maxTotalSupportMaterialCost =
		maxThreeDPrintingCostsBreakDown.totalSupportMaterialCost ??
		maxThreeDPrintingCostsBreakDown.totalSupportMaterial

	let materialCost: string = Numeral(
		threeDPrintingCostsBreakDown.totalMaterialCost / partsQuantity
	).format('0,0[.]00')
	let totalPartMaterial = Numeral(
		threeDPrintingCostsBreakDown.totalPartMaterialCost / partsQuantity
	).format('0,0[.]00')
	let totalSupportMaterial = Numeral(
		totalSupportMaterialCost / partsQuantity
	).format('0,0[.]00')
	let unusedMaterialWaste = Numeral(unusedMaterialCost / partsQuantity).format(
		'0,0[.]00'
	)

	const materialCostPerCubicCM =
		threeDPrintingCostsBreakDown?.materialCostPerCubicCM ||
		configuration?.solution?.printerMaterial?.costPerCubicCM

	const materialCostIs = Numeral(
		(materialCostPerCubicCM /
			configuration?.solution?.printerMaterial?.density) *
			1000
	).format('0,0[.]00')
	const configurationPart = configuration?.part || configuration?.cluster
	const partWeightNumber = Numeral(
		configuration?.solution?.printerMaterial?.density *
			configurationPart?.volume
	).format('0,0[.]')

	const partWeightIs = getPartWeight(partWeightNumber)

	if (
		isShowValuesInRanges &&
		minThreeDPrintingCostsBreakDown &&
		maxThreeDPrintingCostsBreakDown
	) {
		materialCost = displayInRange(
			minThreeDPrintingCostsBreakDown.totalMaterialCost / partsQuantity,
			maxThreeDPrintingCostsBreakDown.totalMaterialCost / partsQuantity,
			materialCost,
			'0,0[.]00'
		)

		totalPartMaterial = displayInRange(
			minThreeDPrintingCostsBreakDown.totalPartMaterialCost / partsQuantity,
			maxThreeDPrintingCostsBreakDown.totalPartMaterialCost / partsQuantity,
			totalPartMaterial,
			'0,0[.]00'
		)

		totalSupportMaterial = displayInRange(
			minTotalSupportMaterialCost / partsQuantity,
			maxTotalSupportMaterialCost / partsQuantity,
			totalSupportMaterial,
			'0,0[.]00'
		)
		unusedMaterialWaste = displayInRange(
			minUnusedMaterial / partsQuantity,
			maxUnusedMaterial / partsQuantity,
			unusedMaterialWaste,
			'0,0[.]00'
		)
	}

	if (originalDesignThreeDPrintingCostsBreakDown) {
		const originalDesignUnusedMaterialCost =
			originalDesignThreeDPrintingCostsBreakDown.totalUnusedMaterialWasteCost ??
			originalDesignThreeDPrintingCostsBreakDown.unusedMaterialWaste
		const originalDesignTotalSupportMaterialCost =
			originalDesignThreeDPrintingCostsBreakDown.totalSupportMaterialCost ??
			originalDesignThreeDPrintingCostsBreakDown.totalSupportMaterial

		originalDesignUnusedMaterialWaste = Numeral(
			originalDesignUnusedMaterialCost / partsQuantity
		).format('0,0[.]00')
		originalDesignTotalSupportMaterial = Numeral(
			originalDesignTotalSupportMaterialCost / partsQuantity
		).format('0,0[.]00')
		originalDesignTotalPartMaterial = Numeral(
			originalDesignThreeDPrintingCostsBreakDown.totalPartMaterialCost /
				partsQuantity
		).format('0,0[.]00')
		originalDesignMaterialCost = Numeral(
			originalDesignThreeDPrintingCostsBreakDown.totalMaterialCost /
				partsQuantity
		).format('0,0[.]00')

		if (
			isShowValuesInRanges &&
			originalDesignMinThreeDPrintingCostsBreakDown &&
			originalDesignMaxThreeDPrintingCostsBreakDown
		) {
			const originalDesignMinUnusedMaterial =
				originalDesignMinThreeDPrintingCostsBreakDown.totalUnusedMaterialWasteCost ??
				originalDesignMinThreeDPrintingCostsBreakDown.unusedMaterialWaste
			const originalDesignMaxUnusedMaterial =
				originalDesignMaxThreeDPrintingCostsBreakDown.totalUnusedMaterialWasteCost ??
				originalDesignMaxThreeDPrintingCostsBreakDown.unusedMaterialWaste
			const originalDesignMinTotalSupportMaterialCost =
				originalDesignMinThreeDPrintingCostsBreakDown.totalSupportMaterialCost ??
				originalDesignMinThreeDPrintingCostsBreakDown.totalSupportMaterial
			const originalDesignMaxTotalSupportMaterialCost =
				originalDesignMaxThreeDPrintingCostsBreakDown.totalSupportMaterialCost ??
				originalDesignMaxThreeDPrintingCostsBreakDown.totalSupportMaterial

			originalDesignUnusedMaterialWaste = displayInRange(
				originalDesignMinUnusedMaterial / partsQuantity,
				originalDesignMaxUnusedMaterial / partsQuantity,
				originalDesignUnusedMaterialWaste,
				'0,0[.]00'
			)
			originalDesignTotalSupportMaterial = displayInRange(
				originalDesignMinTotalSupportMaterialCost / partsQuantity,
				originalDesignMaxTotalSupportMaterialCost / partsQuantity,
				originalDesignTotalSupportMaterial,
				'0,0[.]00'
			)
			originalDesignTotalPartMaterial = displayInRange(
				originalDesignMinThreeDPrintingCostsBreakDown.totalPartMaterialCost /
					partsQuantity,
				originalDesignMaxThreeDPrintingCostsBreakDown.totalPartMaterialCost /
					partsQuantity,
				originalDesignTotalPartMaterial,
				'0,0[.]00'
			)
			originalDesignMaterialCost = displayInRange(
				originalDesignMinThreeDPrintingCostsBreakDown.totalMaterialCost /
					partsQuantity,
				originalDesignMaxThreeDPrintingCostsBreakDown.totalMaterialCost /
					partsQuantity,
				originalDesignMaterialCost,
				'0,0[.]00'
			)
		}
	}

	return {
		unusedMaterialWaste,
		totalSupportMaterial,
		totalPartMaterial,
		materialCost,
		materialCostIs,
		partWeightIs,
		originalDesignUnusedMaterialWaste,
		originalDesignTotalSupportMaterial,
		originalDesignTotalPartMaterial,
		originalDesignMaterialCost
	}
}

const getMachineCost = (
	threeDPrintingCostsBreakDown: Record<string, any>,
	minThreeDPrintingCostsBreakDown: Record<string, any>,
	maxThreeDPrintingCostsBreakDown: Record<string, any>,
	partQuantity: number,
	isShowValuesInRanges: boolean = false,
	disabledParameters: Record<string, any>,
	combinedClusterCosts: boolean,
	originalDesignThreeDPrintingCostsBreakDown?: Record<string, any>,
	originalDesignMinThreeDPrintingCostsBreakDown?: Record<string, any>,
	originalDesignMaxThreeDPrintingCostsBreakDown?: Record<string, any>
) => {
	let originalDesignMachineCost
	let originalDesignTotalSharedMachineTime
	let originalDesignPrintTime
	const partsQuantity = combinedClusterCosts ? 1 : partQuantity

	const printTimeValue = combinedClusterCosts
		? threeDPrintingCostsBreakDown.printTimeValue
		: (threeDPrintingCostsBreakDown.printTime *
				threeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
		  partsQuantity
	const minPrintTimeValue = combinedClusterCosts
		? minThreeDPrintingCostsBreakDown.printTimeValue
		: (minThreeDPrintingCostsBreakDown.printTime *
				minThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
		  partsQuantity
	const maxPrintTimeValue = combinedClusterCosts
		? maxThreeDPrintingCostsBreakDown.printTimeValue
		: (maxThreeDPrintingCostsBreakDown.printTime *
				maxThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
		  partsQuantity

	let totalMachineTimeValue: number = combinedClusterCosts
		? threeDPrintingCostsBreakDown.totalMachineTime
		: (threeDPrintingCostsBreakDown.totalMachineTimeRequired *
				threeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
		  partsQuantity

	let machineCost: string = Numeral(
		threeDPrintingCostsBreakDown.machineUsage / partsQuantity
	).format('0,0[.]00')
	let printTime: string = Numeral(printTimeValue).format('0,0[.]00')

	let totalSharedMachineTime = Numeral(totalMachineTimeValue).format('0,0[.]00')

	let buildPrepTimeValue = combinedClusterCosts
		? threeDPrintingCostsBreakDown.buildPrepTimeCost
		: (threeDPrintingCostsBreakDown.buildPrepTime *
				threeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
		  partsQuantity
	let buildPrepTime: string = Numeral(buildPrepTimeValue).format('0,0[.]00')

	let machineCostPerHour = Numeral(
		threeDPrintingCostsBreakDown.machineCostPerHourOccupied
	).format('0,0[.]00')
	let partPrintTime = Numeral(
		threeDPrintingCostsBreakDown.printTime / partsQuantity
	).format('0,0[.]00')

	if (
		isShowValuesInRanges &&
		minThreeDPrintingCostsBreakDown &&
		maxThreeDPrintingCostsBreakDown
	) {
		machineCost = displayInRange(
			minThreeDPrintingCostsBreakDown.machineUsage / partsQuantity,
			maxThreeDPrintingCostsBreakDown.machineUsage / partsQuantity,
			machineCost,
			'0,0[.]00'
		)

		buildPrepTime = displayInRange(
			combinedClusterCosts
				? minThreeDPrintingCostsBreakDown.buildPrepTimeCost
				: (minThreeDPrintingCostsBreakDown.buildPrepTime *
						minThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
						partsQuantity,
			combinedClusterCosts
				? maxThreeDPrintingCostsBreakDown.buildPrepTimeCost
				: (maxThreeDPrintingCostsBreakDown.buildPrepTime *
						maxThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
						partsQuantity,
			buildPrepTime,
			'0,0[.]00'
		)

		printTime = displayInRange(
			minPrintTimeValue,
			maxPrintTimeValue,
			printTime,
			'0,0[.]00'
		)

		machineCostPerHour = displayInRange(
			minThreeDPrintingCostsBreakDown.machineCostPerHourOccupied,
			maxThreeDPrintingCostsBreakDown.machineCostPerHourOccupied,
			machineCostPerHour,
			'0,0[.]00'
		)
		partPrintTime = displayInRange(
			minThreeDPrintingCostsBreakDown.printTime / partsQuantity,
			maxThreeDPrintingCostsBreakDown.printTime / partsQuantity,
			partPrintTime,
			'0,0[.]00'
		)

		let minTotalMachineTimeValue = combinedClusterCosts
			? minThreeDPrintingCostsBreakDown.totalMachineTime
			: (minThreeDPrintingCostsBreakDown.totalMachineTimeRequired *
					minThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
			  partsQuantity
		let maxTotalMachineTimeValue = combinedClusterCosts
			? maxThreeDPrintingCostsBreakDown.totalMachineTime
			: (maxThreeDPrintingCostsBreakDown.totalMachineTimeRequired *
					maxThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
			  partsQuantity

		totalSharedMachineTime = displayInRange(
			minTotalMachineTimeValue,
			maxTotalMachineTimeValue,
			totalSharedMachineTime,
			'0,0[.]00'
		)
	}

	if (originalDesignThreeDPrintingCostsBreakDown) {
		const originalDesignTotalMachineTimeValue: number =
			(originalDesignThreeDPrintingCostsBreakDown.totalMachineTimeRequired *
				originalDesignThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
			partsQuantity
		const originalDesignPrintTimeValue =
			originalDesignThreeDPrintingCostsBreakDown.printTime *
			originalDesignThreeDPrintingCostsBreakDown.machineCostPerHourOccupied

		originalDesignMachineCost = Numeral(
			originalDesignThreeDPrintingCostsBreakDown.machineUsage / partsQuantity
		).format('0,0[.]00')
		originalDesignTotalSharedMachineTime = Numeral(
			originalDesignTotalMachineTimeValue
		).format('0,0[.]00')
		originalDesignPrintTime = Numeral(
			originalDesignPrintTimeValue / partsQuantity
		).format('0,0[.]00')

		if (
			isShowValuesInRanges &&
			originalDesignMinThreeDPrintingCostsBreakDown &&
			originalDesignMaxThreeDPrintingCostsBreakDown
		) {
			const originalDesignMinTotalMachineTimeValue =
				(originalDesignMinThreeDPrintingCostsBreakDown.totalMachineTimeRequired *
					originalDesignMinThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
				partsQuantity
			const originalDesignMaxTotalMachineTimeValue =
				(originalDesignMaxThreeDPrintingCostsBreakDown.totalMachineTimeRequired *
					originalDesignMaxThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
				partsQuantity
			const originalDesignMinPrintTimeValue =
				originalDesignMinThreeDPrintingCostsBreakDown.printTime *
				originalDesignMinThreeDPrintingCostsBreakDown.machineCostPerHourOccupied
			const originalDesignMaxPrintTimeValue =
				originalDesignMaxThreeDPrintingCostsBreakDown.printTime *
				originalDesignMaxThreeDPrintingCostsBreakDown.machineCostPerHourOccupied

			originalDesignMachineCost = displayInRange(
				originalDesignMinThreeDPrintingCostsBreakDown.machineUsage /
					partsQuantity,
				originalDesignMaxThreeDPrintingCostsBreakDown.machineUsage /
					partsQuantity,
				originalDesignMachineCost,
				'0,0[.]00'
			)

			originalDesignTotalSharedMachineTime = displayInRange(
				originalDesignMinTotalMachineTimeValue,
				originalDesignMaxTotalMachineTimeValue,
				originalDesignTotalSharedMachineTime,
				'0,0[.]00'
			)

			originalDesignPrintTime = displayInRange(
				originalDesignMinPrintTimeValue / partsQuantity,
				originalDesignMaxPrintTimeValue / partsQuantity,
				originalDesignPrintTime,
				'0,0[.]00'
			)
		}
	}

	return {
		machineCost,
		printTime,
		totalSharedMachineTime,
		buildPrepTime,
		machineCostPerHour,
		partPrintTime,
		originalDesignMachineCost,
		originalDesignTotalSharedMachineTime,
		originalDesignPrintTime
	}
}

const getConsumableCost = (
	threeDPrintingCostsBreakDown: Record<string, any>,
	minThreeDPrintingCostsBreakDown: Record<string, any>,
	maxThreeDPrintingCostsBreakDown: Record<string, any>,
	partQuantity: number,
	isShowValuesInRanges: boolean = false,
	combinedClusterCosts: boolean,
	originalDesignThreeDPrintingCostsBreakDown?: Record<string, any>,
	originalDesignMinThreeDPrintingCostsBreakDown?: Record<string, any>,
	originalDesignMaxThreeDPrintingCostsBreakDown?: Record<string, any>
) => {
	let originalDesignConsumablesCost
	let originalDesignOperatingCost
	let originalDesignPerBuildConsumables
	const partsQuantity = combinedClusterCosts ? 1 : partQuantity

	let consumablesCost: string = Numeral(
		threeDPrintingCostsBreakDown.buildConsumablesCost / partsQuantity
	).format('0,0[.]00')
	let operatingCost: string = Numeral(
		threeDPrintingCostsBreakDown.operatingCost / partsQuantity
	).format('0,0[.]00')
	let perBuildConsumables: string = Numeral(
		threeDPrintingCostsBreakDown.perBuildConsumables / partsQuantity
	).format('0,0[.]00')

	if (
		isShowValuesInRanges &&
		minThreeDPrintingCostsBreakDown &&
		maxThreeDPrintingCostsBreakDown
	) {
		consumablesCost = displayInRange(
			minThreeDPrintingCostsBreakDown.buildConsumablesCost / partsQuantity,
			maxThreeDPrintingCostsBreakDown.buildConsumablesCost / partsQuantity,
			consumablesCost,
			'0,0[.]00'
		)
		operatingCost = displayInRange(
			minThreeDPrintingCostsBreakDown.operatingCost / partsQuantity,
			maxThreeDPrintingCostsBreakDown.operatingCost / partsQuantity,
			operatingCost,
			'0,0[.]00'
		)
		perBuildConsumables = displayInRange(
			minThreeDPrintingCostsBreakDown.perBuildConsumables / partsQuantity,
			maxThreeDPrintingCostsBreakDown.perBuildConsumables / partsQuantity,
			perBuildConsumables,
			'0,0[.]00'
		)
	}

	if (originalDesignThreeDPrintingCostsBreakDown) {
		originalDesignConsumablesCost = Numeral(
			originalDesignThreeDPrintingCostsBreakDown.buildConsumablesCost /
				partsQuantity
		).format('0,0[.]00')
		originalDesignOperatingCost = Numeral(
			originalDesignThreeDPrintingCostsBreakDown.operatingCost / partsQuantity
		).format('0,0[.]00')
		originalDesignPerBuildConsumables = Numeral(
			originalDesignThreeDPrintingCostsBreakDown.perBuildConsumables /
				partsQuantity
		).format('0,0[.]00')

		if (
			isShowValuesInRanges &&
			originalDesignMinThreeDPrintingCostsBreakDown &&
			originalDesignMaxThreeDPrintingCostsBreakDown
		) {
			originalDesignConsumablesCost = displayInRange(
				originalDesignMinThreeDPrintingCostsBreakDown.buildConsumablesCost /
					partsQuantity,
				originalDesignMaxThreeDPrintingCostsBreakDown.buildConsumablesCost /
					partsQuantity,
				originalDesignConsumablesCost,
				'0,0[.]00'
			)
			originalDesignOperatingCost = displayInRange(
				originalDesignMinThreeDPrintingCostsBreakDown.operatingCost /
					partsQuantity,
				originalDesignMaxThreeDPrintingCostsBreakDown.operatingCost /
					partsQuantity,
				originalDesignOperatingCost,
				'0,0[.]00'
			)
			originalDesignPerBuildConsumables = displayInRange(
				originalDesignMinThreeDPrintingCostsBreakDown.perBuildConsumables /
					partsQuantity,
				originalDesignMaxThreeDPrintingCostsBreakDown.perBuildConsumables /
					partsQuantity,
				originalDesignPerBuildConsumables,
				'0,0[.]00'
			)
		}
	}

	return {
		consumablesCost,
		operatingCost,
		perBuildConsumables,
		originalDesignConsumablesCost,
		originalDesignOperatingCost,
		originalDesignPerBuildConsumables
	}
}

const getFeesAndCost = (
	threeDPrintingCostsBreakDown: Record<string, any>,
	minThreeDPrintingCostsBreakDown: Record<string, any>,
	maxThreeDPrintingCostsBreakDown: Record<string, any>,
	partQuantity: number,
	isShowValuesInRanges: boolean = false,
	perPartCosts: Record<string, any>,
	minPartCosts: Record<string, any>,
	maxPartCosts: Record<string, any>,
	combinedClusterCosts: boolean,
	originalDesignThreeDPrintingCostsBreakDown?: Record<string, any>,
	originalDesignMinThreeDPrintingCostsBreakDown?: Record<string, any>,
	originalDesignMaxThreeDPrintingCostsBreakDown?: Record<string, any>,
	originalDesignPerPartCosts?: Record<string, any>,
	originalDesignMinPartCosts?: Record<string, any>,
	originalDesignMaxPartCosts?: Record<string, any>
) => {
	let originalDesignTotalProductionCostsPerUnit
	let originalDesignTotalOrderFeesCost
	let originalDesignBuildPrep
	const partsQuantity = combinedClusterCosts ? 1 : partQuantity

	let totalOrderFeesCost: string = Numeral(
		threeDPrintingCostsBreakDown.totalOrderFeesCost / partsQuantity
	).format('0,0[.]00')

	let buildPrep: string = Numeral(
		threeDPrintingCostsBreakDown.buildPrepCost / partsQuantity
	).format('0,0[.]00')

	if (
		isShowValuesInRanges &&
		minThreeDPrintingCostsBreakDown &&
		maxThreeDPrintingCostsBreakDown
	) {
		totalOrderFeesCost = displayInRange(
			minThreeDPrintingCostsBreakDown.totalOrderFeesCost / partsQuantity,
			maxThreeDPrintingCostsBreakDown.totalOrderFeesCost / partsQuantity,
			totalOrderFeesCost,
			'0,0[.]00'
		)

		buildPrep = displayInRange(
			minThreeDPrintingCostsBreakDown.buildPrep / partsQuantity,
			maxThreeDPrintingCostsBreakDown.buildPrep / partsQuantity,
			buildPrep,
			'0,0[.]00'
		)
	}

	let totalProductionCostsPerUnit = Numeral(
		perPartCosts.totalProductionCostsPerUnit
	).format('0,0[.]00')

	if (isShowValuesInRanges && minPartCosts && maxPartCosts) {
		totalProductionCostsPerUnit = displayInRange(
			minPartCosts?.totalProductionCostsPerUnit,
			maxPartCosts?.totalProductionCostsPerUnit,
			totalProductionCostsPerUnit,
			'0,0[.]00'
		)
	}

	if (
		originalDesignThreeDPrintingCostsBreakDown &&
		originalDesignPerPartCosts
	) {
		originalDesignTotalOrderFeesCost = Numeral(
			originalDesignThreeDPrintingCostsBreakDown.totalOrderFeesCost /
				partsQuantity
		).format('0,0[.]00')
		originalDesignBuildPrep = Numeral(
			originalDesignThreeDPrintingCostsBreakDown.buildPrepCost / partsQuantity
		).format('0,0[.]00')
		originalDesignTotalProductionCostsPerUnit = Numeral(
			originalDesignPerPartCosts.totalProductionCostsPerUnit
		).format('0,0[.]00')

		if (
			isShowValuesInRanges &&
			originalDesignMinThreeDPrintingCostsBreakDown &&
			originalDesignMaxThreeDPrintingCostsBreakDown
		) {
			originalDesignTotalOrderFeesCost = displayInRange(
				originalDesignMinThreeDPrintingCostsBreakDown.totalOrderFeesCost /
					partsQuantity,
				originalDesignMaxThreeDPrintingCostsBreakDown.totalOrderFeesCost /
					partsQuantity,
				originalDesignTotalOrderFeesCost,
				'0,0[.]00'
			)

			originalDesignBuildPrep = displayInRange(
				originalDesignMinThreeDPrintingCostsBreakDown.buildPrep / partsQuantity,
				originalDesignMaxThreeDPrintingCostsBreakDown.buildPrep / partsQuantity,
				originalDesignBuildPrep,
				'0,0[.]00'
			)
		}

		if (
			isShowValuesInRanges &&
			originalDesignMinPartCosts &&
			originalDesignMaxPartCosts
		) {
			originalDesignTotalProductionCostsPerUnit = displayInRange(
				originalDesignMinPartCosts?.totalProductionCostsPerUnit,
				originalDesignMaxPartCosts?.totalProductionCostsPerUnit,
				originalDesignTotalProductionCostsPerUnit,
				'0,0[.]00'
			)
		}
	}

	return {
		totalOrderFeesCost,
		totalProductionCostsPerUnit,
		buildPrep,
		originalDesignTotalProductionCostsPerUnit,
		originalDesignTotalOrderFeesCost,
		originalDesignBuildPrep
	}
}

const getLaborCost = (
	threeDPrintingCostsBreakDown: Record<string, any>,
	minThreeDPrintingCostsBreakDown: Record<string, any>,
	maxThreeDPrintingCostsBreakDown: Record<string, any>,
	partQuantity: number,
	fullTrayAssumption: boolean = false,
	isShowValuesInRanges: boolean = false,
	combinedClusterCosts: boolean,
	originalDesignThreeDPrintingCostsBreakDown?: Record<string, any>,
	originalDesignMinThreeDPrintingCostsBreakDown?: Record<string, any>,
	originalDesignMaxThreeDPrintingCostsBreakDown?: Record<string, any>,
	isCluster?: boolean
) => {
	let originalDesignLabor
	let originalDesignLaborHoursBuildExchange
	let originalDesignLaborHoursDuringPureBuild
	let originalDesignMandatoryPostProcessCost
	let originalDesignOptionalPostProcessCost

	const partsQuantity = combinedClusterCosts ? 1 : partQuantity

	const assemblingCostValue = threeDPrintingCostsBreakDown.assemblingCost || 0
	const assemblingCost = assemblingCostValue
		? Numeral(assemblingCostValue).format('0,0[.]00')
		: 0

	let mandatoryPostProcessCostValue =
		threeDPrintingCostsBreakDown.postProcessCost || 0

	let optionalPostProcessCostValue = isCluster
		? 0
		: threeDPrintingCostsBreakDown.optionalPostProcessesCost || 0

	const laborHoursBuildExchangeValue = fullTrayAssumption
		? threeDPrintingCostsBreakDown.laborHoursBuildExchangeCost +
		  threeDPrintingCostsBreakDown.totalSharedLaborTimeCost
		: threeDPrintingCostsBreakDown.laborHoursBuildExchangeCost

	const minLaborHoursBuildExchangeValue = fullTrayAssumption
		? minThreeDPrintingCostsBreakDown.laborHoursBuildExchangeCost +
		  minThreeDPrintingCostsBreakDown.totalSharedLaborTimeCost
		: minThreeDPrintingCostsBreakDown.laborHoursBuildExchangeCost

	const maxLaborHoursBuildExchangeValue = fullTrayAssumption
		? maxThreeDPrintingCostsBreakDown.laborHoursBuildExchangeCost +
		  maxThreeDPrintingCostsBreakDown.totalSharedLaborTimeCost
		: maxThreeDPrintingCostsBreakDown.laborHoursBuildExchangeCost

	const laborHoursDuringPureBuildValue = fullTrayAssumption
		? threeDPrintingCostsBreakDown.laborHoursDuringPureBuildCost
		: threeDPrintingCostsBreakDown.laborHoursDuringBuildCost

	const minLaborHoursDuringPureBuildValue = fullTrayAssumption
		? minThreeDPrintingCostsBreakDown.laborHoursDuringPureBuildCost
		: minThreeDPrintingCostsBreakDown.laborHoursDuringBuildCost

	const maxLaborHoursDuringPureBuildValue = fullTrayAssumption
		? maxThreeDPrintingCostsBreakDown.laborHoursDuringPureBuildCost
		: maxThreeDPrintingCostsBreakDown.laborHoursDuringBuildCost

	let mandatoryPostProcessCost: string = Numeral(
		mandatoryPostProcessCostValue / partsQuantity
	).format('0,0[.]00')
	let optionalPostProcessCost: string = Numeral(
		optionalPostProcessCostValue
	).format('0,0[.]00')

	let laborHoursBuildExchange: string = Numeral(
		laborHoursBuildExchangeValue / partsQuantity
	).format('0,0[.]00')

	let laborHoursDuringPureBuild: string = Numeral(
		laborHoursDuringPureBuildValue / partsQuantity
	).format('0,0[.]00')

	let labor: string = Numeral(
		(threeDPrintingCostsBreakDown.laborCost +
			optionalPostProcessCostValue +
			mandatoryPostProcessCostValue) /
			partsQuantity +
			assemblingCostValue
	).format('0,0[.]00')

	let laborTechnicianFTE = Numeral(
		threeDPrintingCostsBreakDown.engineerHourCost
	).format('0,0[.]00')

	if (
		isShowValuesInRanges &&
		minThreeDPrintingCostsBreakDown &&
		maxThreeDPrintingCostsBreakDown
	) {
		optionalPostProcessCost = displayInRange(
			minThreeDPrintingCostsBreakDown.optionalPostProcessesCost,
			maxThreeDPrintingCostsBreakDown.optionalPostProcessesCost,
			optionalPostProcessCost,
			'0,0[.]00'
		)
		mandatoryPostProcessCost = displayInRange(
			minThreeDPrintingCostsBreakDown.postProcessCost / partsQuantity,
			maxThreeDPrintingCostsBreakDown.postProcessCost / partsQuantity,
			mandatoryPostProcessCost,
			'0,0[.]00'
		)

		const minLaborData =
			minThreeDPrintingCostsBreakDown.optionalPostProcessesCost +
			(minLaborHoursDuringPureBuildValue +
				minLaborHoursBuildExchangeValue +
				minThreeDPrintingCostsBreakDown.postProcessCost) /
				partsQuantity +
			assemblingCostValue

		const maxLaborData =
			maxThreeDPrintingCostsBreakDown.optionalPostProcessesCost +
			(maxLaborHoursDuringPureBuildValue +
				maxLaborHoursBuildExchangeValue +
				maxThreeDPrintingCostsBreakDown.postProcessCost) /
				partsQuantity +
			assemblingCostValue

		laborHoursBuildExchange = displayInRange(
			minLaborHoursBuildExchangeValue / partsQuantity,
			maxLaborHoursBuildExchangeValue / partsQuantity,
			laborHoursBuildExchange,
			'0,0[.]00'
		)

		laborHoursDuringPureBuild = displayInRange(
			minLaborHoursDuringPureBuildValue / partsQuantity,
			maxLaborHoursDuringPureBuildValue / partsQuantity,
			laborHoursDuringPureBuild,
			'0,0[.]00'
		)

		labor = displayInRange(minLaborData, maxLaborData, labor, '0,0[.]00')
	}

	if (originalDesignThreeDPrintingCostsBreakDown) {
		const originalDesignMandatoryPostProcessCostValue =
			originalDesignThreeDPrintingCostsBreakDown.postProcessCost || 0

		const originalDesignOptionalPostProcessCostValue =
			originalDesignThreeDPrintingCostsBreakDown.optionalPostProcessesCost || 0

		const originalDesignLaborHoursBuildExchangeValue = fullTrayAssumption
			? originalDesignThreeDPrintingCostsBreakDown.laborHoursBuildExchangeCost +
			  originalDesignThreeDPrintingCostsBreakDown.totalSharedLaborTimeCost
			: originalDesignThreeDPrintingCostsBreakDown.laborHoursBuildExchangeCost

		const originalDesignLaborHoursDuringPureBuildValue = fullTrayAssumption
			? originalDesignThreeDPrintingCostsBreakDown.laborHoursDuringPureBuildCost
			: originalDesignThreeDPrintingCostsBreakDown.laborHoursDuringBuildCost

		originalDesignLabor = Numeral(
			(originalDesignThreeDPrintingCostsBreakDown.laborCost +
				originalDesignOptionalPostProcessCostValue +
				originalDesignMandatoryPostProcessCostValue) /
				partsQuantity
		).format('0,0[.]00')

		originalDesignLaborHoursBuildExchange = Numeral(
			originalDesignLaborHoursBuildExchangeValue / partsQuantity
		).format('0,0[.]00')

		originalDesignLaborHoursDuringPureBuild = Numeral(
			originalDesignLaborHoursDuringPureBuildValue / partsQuantity
		).format('0,0[.]00')

		originalDesignMandatoryPostProcessCost = Numeral(
			originalDesignMandatoryPostProcessCostValue / partsQuantity
		).format('0,0[.]00')

		originalDesignOptionalPostProcessCost = Numeral(
			originalDesignOptionalPostProcessCostValue
		).format('0,0[.]00')

		if (
			isShowValuesInRanges &&
			originalDesignMinThreeDPrintingCostsBreakDown &&
			originalDesignMaxThreeDPrintingCostsBreakDown
		) {
			const originalDesignMinLaborHoursDuringPureBuildValue = fullTrayAssumption
				? originalDesignMinThreeDPrintingCostsBreakDown.laborHoursDuringPureBuildCost
				: originalDesignMinThreeDPrintingCostsBreakDown.laborHoursDuringBuildCost
			const originalDesignMaxLaborHoursDuringPureBuildValue = fullTrayAssumption
				? originalDesignMaxThreeDPrintingCostsBreakDown.laborHoursDuringPureBuildCost
				: originalDesignMaxThreeDPrintingCostsBreakDown.laborHoursDuringBuildCost
			const originalDesignMinLaborHoursBuildExchangeValue = fullTrayAssumption
				? originalDesignMinThreeDPrintingCostsBreakDown.laborHoursBuildExchangeCost +
				  originalDesignMinThreeDPrintingCostsBreakDown.totalSharedLaborTimeCost
				: originalDesignMinThreeDPrintingCostsBreakDown.laborHoursBuildExchangeCost
			const originalDesignMaxLaborHoursBuildExchangeValue = fullTrayAssumption
				? originalDesignMaxThreeDPrintingCostsBreakDown.laborHoursBuildExchangeCost +
				  originalDesignMaxThreeDPrintingCostsBreakDown.totalSharedLaborTimeCost
				: originalDesignMaxThreeDPrintingCostsBreakDown.laborHoursBuildExchangeCost

			const originalDesignMinLaborData =
				originalDesignMinThreeDPrintingCostsBreakDown.optionalPostProcessesCost +
				(originalDesignMinLaborHoursDuringPureBuildValue +
					originalDesignMinLaborHoursBuildExchangeValue +
					originalDesignMinThreeDPrintingCostsBreakDown.postProcessCost) /
					partsQuantity
			const originalDesignMaxLaborData =
				originalDesignMaxThreeDPrintingCostsBreakDown.optionalPostProcessesCost +
				(originalDesignMaxLaborHoursDuringPureBuildValue +
					originalDesignMaxLaborHoursBuildExchangeValue +
					originalDesignMaxThreeDPrintingCostsBreakDown.postProcessCost) /
					partsQuantity

			originalDesignLabor = displayInRange(
				originalDesignMinLaborData,
				originalDesignMaxLaborData,
				originalDesignLabor,
				'0,0[.]00'
			)

			originalDesignLaborHoursBuildExchange = displayInRange(
				originalDesignMinLaborHoursBuildExchangeValue / partsQuantity,
				originalDesignMaxLaborHoursBuildExchangeValue / partsQuantity,
				originalDesignLaborHoursBuildExchange,
				'0,0[.]00'
			)

			originalDesignLaborHoursDuringPureBuild = displayInRange(
				originalDesignMinLaborHoursDuringPureBuildValue / partsQuantity,
				originalDesignMaxLaborHoursDuringPureBuildValue / partsQuantity,
				originalDesignLaborHoursDuringPureBuild,
				'0,0[.]00'
			)

			originalDesignMandatoryPostProcessCost = displayInRange(
				originalDesignMinThreeDPrintingCostsBreakDown.postProcessCost /
					partsQuantity,
				originalDesignMaxThreeDPrintingCostsBreakDown.postProcessCost /
					partsQuantity,
				originalDesignMandatoryPostProcessCost,
				'0,0[.]00'
			)

			originalDesignOptionalPostProcessCost = displayInRange(
				originalDesignMinThreeDPrintingCostsBreakDown.optionalPostProcessesCost,
				originalDesignMaxThreeDPrintingCostsBreakDown.optionalPostProcessesCost,
				originalDesignOptionalPostProcessCost,
				'0,0[.]00'
			)
		}
	}

	return {
		laborHoursBuildExchange,
		laborHoursDuringPureBuild,
		labor,
		mandatoryPostProcessCost,
		optionalPostProcessCost,
		laborTechnicianFTE,
		originalDesignLabor,
		originalDesignLaborHoursBuildExchange,
		originalDesignLaborHoursDuringPureBuild,
		originalDesignMandatoryPostProcessCost,
		originalDesignOptionalPostProcessCost,
		assemblingCost
	}
}

export const getPartWeight = (partWeight: string) => {
	const milligram = 1000000
	const gram = 1000

	const weightKilograms = `${Numeral(partWeight)
		.divide(milligram)
		.format('0,0.00')} ${getString('WEIGHT_KG')}`
	const weightGrams = `${Numeral(partWeight)
		.divide(gram)
		.format('0,0.00')} ${getString('WEIGHT_GRAM')}`
	const displayInKilograms = +partWeight.replaceAll(',', '') >= milligram

	return displayInKilograms ? weightKilograms : weightGrams
}
