import React, { FC, memo } from 'react'

import DetailsPopup from 'Scenes/Components/DetailsPopup'
import Flexbox from 'Scenes/Components/FlexBox'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { PART_ANALYSIS_TOP_DETAILS_NO_SOLUTION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const ButtonTSX: any = Button

interface TopNoConfigurationResultsProps {
	onConfigureClick: Function
	configurationId: number | string
	isCluster?: boolean
}

const TopNoConfigurationResults: FC<TopNoConfigurationResultsProps> = ({
	onConfigureClick,
	configurationId,
	isCluster
}) => {
	const disableConfigureButton = !Feature.isFeatureActive(
		FeatureComponentId.UPDATE_CONFIGURATION
	)

	if (isCluster) return <></>

	return (
		<Flexbox
			flexDirection="row"
			alignItems="center"
			className="castor-detail cost-details-row center"
		>
			<div className="solution-header-no-configuration">
				{PART_ANALYSIS_TOP_DETAILS_NO_SOLUTION[0]}&nbsp;
				{PART_ANALYSIS_TOP_DETAILS_NO_SOLUTION[1]}
			</div>
			<DetailsPopup
				isHover={disableConfigureButton}
				data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
				popperDirection="bottom"
				popperClassName="info-box-wrapper details-popup--contact-us"
				popperContactClassName="info-box-data"
			>
				<ButtonTSX
					data-qa="data-qa-project-configure"
					color="primary"
					onClick={(e: React.MouseEvent<HTMLDivElement>) =>
						onConfigureClick(e, configurationId)
					}
					className="solution-header-no-configuration-button"
					disabled={disableConfigureButton}
				>
					<IconFactory
						iconName="configure"
						className="solution-header-no-configuration-button-icon"
					/>
					{getString('PART_ANALYSIS_CONFIGURE')}
				</ButtonTSX>
			</DetailsPopup>
		</Flexbox>
	)
}

export default memo(TopNoConfigurationResults)
