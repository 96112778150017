import { OptionalPostProcessesIds } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisTopDetails/SolutionAnalysisTopConstants'
import { getString } from 'Services/Strings/StringService'

export const PP_CALCULATION_TYPE = {
	DEFAULT: null,
	FIXED: '1',
	PERCENT: '2',
	FUNCTION: '3'
}

export const FUNCTION_EDITOR_ALERT_TYPE = {
	COST: 'COST',
	LEAD_TIME: 'LEAD_TIME'
}

export const POST_PROCESS_ACTION_BUTTON_TYPE = {
	EDIT: getString('EDIT'),
	ADD: getString('CUSTOMIZE_POST_PROCESS_ADD'),
	REMOVE: getString('CUSTOMIZE_POST_PROCESS_REMOVE'),
	TO_DEFAULT: getString('CUSTOMIZE_POST_PROCESS_TO_DEFAULT'),
	CANCEL: getString('CANCEL'),
	APPLY: getString('APPLY')
}

export const getReducerStateId = (
	postProcessId: string,
	functionEditorAlertType: string
) => `${postProcessId || 'new'}-${functionEditorAlertType}`

export const chooseOptionValue = '0'
export const POST_PROCESSES_BLOCK_ID_HASH = '#customize-post-processes'
export const cncPostProcesses = [
	OptionalPostProcessesIds.SurfaceAreaMachining,
	OptionalPostProcessesIds.SupportRemovalMachining
]
