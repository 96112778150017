import React, { FC } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'
import { Field } from 'redux-form'
import { SUBMIT } from '../../../../Services/Strings'
import TextField from '../../../Components/TextField/TextField'
import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import TransparentButton from '../../../Components/TransparentButton'
import { getString } from 'Services/Strings/StringService'

const ReduxFormField: any = Field

interface IProps {
  onSubmitClick: any
  parametersUpdateLoading: boolean
  parametersEditableNames: Array<any>
  parametersInfoObject: any
  handleSubmit: Function
  valid: any
  reset: any
  pristine: any
  submitting: any
}

const renderTextField = ({
  input,
  label,
  type,
  placeholder,
  meta: { touched, error },
  inputProps,
  parametersInfo,
  maxValue
}: any) => (
  <TextField
    wrapperClassName="admin-project-form--field"
    labelInfoWrapperClassName="admin-project-form--field--text-field-wrapper"
    labelClassName="admin-project-form--field--text-field-label"
    disableZero
    disableMinus
    disablePlus
    maxValue={maxValue}
    label={label}
    error={error}
    type={type}
    input={input}
    touched={touched}
    placeholder={placeholder}
    inputProps={inputProps}
    infoData={parametersInfo?.info}
    infoDataBoxDirection="right-start"
  />
)
const generateParametersFields = (
  parametersEditableNames: Array<string>,
  parametersInfoObject: any
) => {
  return (
    parametersEditableNames &&
    parametersEditableNames.map((name, index) => {
      return (
        <div key={name}>
          <ReduxFormField
            name={name}
            component={renderTextField}
            label={parametersInfoObject[name]?.label}
            type="number"
            maxValue={parametersInfoObject[name].maxValue}
            inputProps={{ min: 1, max: parametersInfoObject[name].maxValue }}
            parametersInfo={parametersInfoObject[name]}
          />
        </div>
      )
    })
  )
}

const ParametersManagerFields: FC<IProps> = ({
  parametersUpdateLoading,
  parametersEditableNames,
  parametersInfoObject,
  onSubmitClick,
  handleSubmit,
  reset,
  pristine,
  submitting,
  valid
}) => {
  if (!parametersEditableNames || !parametersEditableNames.length) {
    return <div />
  }
  return (
    <Flexbox className="admin-project-form" flexDirection="column">
      {generateParametersFields(parametersEditableNames, parametersInfoObject)}
      <Flexbox
        alignItems="center"
        alignSelf="flex-end"
        justifyContent="space-between"
        width="200px"
      >
        <TransparentButton onClick={() => reset()}>
          {getString('RESET')}
        </TransparentButton>
        <ButtonWithLoader
          onClick={handleSubmit(onSubmitClick)}
          loading={parametersUpdateLoading}
          disabled={pristine || submitting || !valid}
        >
          {SUBMIT}
        </ButtonWithLoader>
      </Flexbox>
    </Flexbox>
  )
}

export default ParametersManagerFields
