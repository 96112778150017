import extendedTablesStyle from '../../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import { Component } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { withStyles } from '@material-ui/core'
import Close from '@material-ui/icons/Close'

import * as CustomizeUserMaterialsActions from '../CustomizeUserMaterialsActions.js'
import {
	IN_HOUSE_PRINTERS_ACTION,
	MATERIAL_COST_DELETE_ITEM,
	USER_MATERIAL_NAME,
	USER_MATERIAL_NO_MATERIALS_FOUND,
	USER_MATERIAL_TYPE
} from '../../../../../Services/Strings.js'
import ItemGrid from '../../../../Components/thirdParty/CreativeTim/components/Grid/ItemGrid.jsx'
import {
	Button,
	Muted,
	Table
} from '../../../../Components/thirdParty/CreativeTim/components/index.js'

const extendedTablesStyleTSX: any = extendedTablesStyle

interface IProps {
	onDeletePrinterMaterialClick: Function
	userId: number
	removeUserPrinterMaterial: any
	itemToDelete: any
	classes: any
	userPrinterMaterials: any[]
}

interface IReduxStore {
	user: any
	CustomizeUserMaterialsReducer: any
}

class CustomizeUserMaterialsTable extends Component<IProps> {
	onDeletePrinterMaterialClick = (itemToDelete: any) => {
		const { onDeletePrinterMaterialClick, userId } = this.props
		onDeletePrinterMaterialClick(itemToDelete, userId)
	}

	onDeleteConfirmed = () => {
		const { removeUserPrinterMaterial, userId, itemToDelete } = this.props
		removeUserPrinterMaterial(itemToDelete, userId)
	}

	renderDeleteButton = (itemToDelete: any) => {
		const { classes } = this.props
		return (
			<Button
				title={MATERIAL_COST_DELETE_ITEM}
				onClick={this.onDeletePrinterMaterialClick.bind(this, itemToDelete)}
				color="dangerNoBackground"
				style={{ margin: 'unset', padding: 'unset' }}
				customClass={classes.actionButton}
			>
				<Close className={classes.icon} />
			</Button>
		)
	}

	renderInHousePrinterTblData = () => {
		const { userPrinterMaterials } = this.props
		if (!userPrinterMaterials || !userPrinterMaterials?.length) {
			return [['', <Muted>{USER_MATERIAL_NO_MATERIALS_FOUND}</Muted>, '']]
		}

		return userPrinterMaterials.map((userPrinterMaterial: any) => [
			userPrinterMaterial.name,
			userPrinterMaterial.type,
			this.renderDeleteButton(userPrinterMaterial)
		])
	}

	render() {
		return (
			<ItemGrid
				data-qa={'data-qa-additive-manufacturing-materials-table'}
				classes={{ grid: 'customize-user-material--table-wrapper' }}
			>
				<Table
					editTableStyle={'materialCostTableCell'}
					tableHead={[
						USER_MATERIAL_NAME,
						USER_MATERIAL_TYPE,
						IN_HOUSE_PRINTERS_ACTION
					]}
					tableData={this.renderInHousePrinterTblData()}
				/>
			</ItemGrid>
		)
	}
}

const mapStateToProps = ({
	CustomizeUserMaterialsReducer,
	user
}: IReduxStore) => {
	const userPrinterMaterials = CustomizeUserMaterialsReducer.userId
			? CustomizeUserMaterialsReducer.userPrinterMaterials
			: user.userPrinterMaterials,
		{ userId, itemToDelete, showDeleteAlert } = CustomizeUserMaterialsReducer
	return { userPrinterMaterials, userId, itemToDelete, showDeleteAlert }
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) => {
	return bindActionCreators({ ...CustomizeUserMaterialsActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(extendedTablesStyleTSX)(CustomizeUserMaterialsTable))
