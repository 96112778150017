import React, { FC, memo } from 'react'

import { Radio } from '@material-ui/core'

import { IOtherProps } from 'Services/models/IOtherProps'

import './CastorRadio.scss'


const RadioComponent: any = Radio

interface IProps extends IOtherProps {
	disabled?: boolean
	onChange?: Function
	className?: string
}

const CastorRadio: FC<IProps> = ({
	disabled,
	onChange,
	className,
	...props
}) => {
	const changedProps = {
		...props,
		value: props?.value?.toString() || ''
	}

	return (
		<RadioComponent
			className={className}
			disabled={disabled}
			onChange={onChange}
			classes={{
				checked: 'radio--checked-icon',
				disabled: 'radio--disabled-icon'
			}}
			{...changedProps}
		/>
	)
}

export default memo(CastorRadio)