import React, { FC, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'
import { differenceWith, isEqual } from 'lodash'

import { toggleIncludeAllCostsCheckbox } from '../../OnboardingWizardActions'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import { ICostAnalysisParam } from 'Services/models/ICostAnalysisParameter'
import { getString } from 'Services/Strings/StringService'

const IncludeAllCostsCheckbox: FC = () => {
	const { includeAllCostsCheckbox } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { initialCostAnalysis, costAnalysisData } = useSelector(
		(state: RootStateOrAny) => state.CustomizeAnalysisCostReducer
	)

	const dispatch = useDispatch()

	const isDisabled = useMemo(
		() =>
			initialCostAnalysis.some((data: ICostAnalysisParam) => !data.isOn) ||
			differenceWith(costAnalysisData, initialCostAnalysis, isEqual).length > 0,
		[costAnalysisData, initialCostAnalysis]
	)

	const toggleCheckbox = () => {
		if (isDisabled) return
		dispatch(toggleIncludeAllCostsCheckbox(!includeAllCostsCheckbox))
	}

	useEffect(() => {
		if (
			differenceWith(costAnalysisData, initialCostAnalysis, isEqual).length > 0
		) {
			dispatch(toggleIncludeAllCostsCheckbox(false))
		}
	}, [costAnalysisData, dispatch, initialCostAnalysis])

	return (
		<div
			className={cx('include-all-costs-checkbox--wrapper', {
				disabled: isDisabled
			})}
			onClick={toggleCheckbox}
		>
			<CastorCheckbox checked={includeAllCostsCheckbox} disabled={isDisabled} />
			<div>{getString('ONBOARDING_INCLUDE_ALL_CHECKBOX_TEXT')}</div>
		</div>
	)
}

export default IncludeAllCostsCheckbox
