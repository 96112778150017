import React, { ChangeEvent, FC, memo } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'
import MenuItem from '@material-ui/core/MenuItem'
import cx from 'classnames'

import SelectMenu from './SelectMenu'
import { getString } from 'Services/Strings/StringService'
import {
	ISimpleConfigurationMaterial,
	ISimpleConfigurationPrinter
} from './SimpleConfigurationSelectorService'
import { IPrinter } from 'Services/models/IPrintersTypes'

import './SimpleConfiguratoionSelector.scss'

const MenuItemTsx: any = MenuItem

interface Placeholders {
	selectMaterialPlaceHolder: string
	selectMaterialTypePlaceholder: string
	selectMaterialCategoryPlaceholder: string
}

interface IProps extends Placeholders {
	printerCompanyValue: string
	printerValue: IPrinter
	materialValue: ISimpleConfigurationMaterial
	disableConfiguration: boolean
	companiesList: Array<string>
	printersList: Array<ISimpleConfigurationPrinter>
	materialsList: Array<ISimpleConfigurationMaterial>
	disabled?: boolean
	dropDownsHeaderClass?: string
	itemWrapperClassname?: string
	selectMenuClassName?: string
	displayAsRow?: boolean
	isNewDesign?: boolean
	onMaterialsChange: (event: string) => any
	onCompanyChange: (event: string) => any
	onPrinterChange: (event: string) => any
}

const SimpleConfigurationSelector: FC<IProps & any> = ({
	companiesList,
	printersList,
	materialsList,
	onMaterialsChange,
	onCompanyChange,
	onPrinterChange,
	printerCompanyValue,
	materialValue,
	printerValue,
	disabled,
	dropDownsHeaderClass,
	itemWrapperClassname = '',
	selectMenuClassName = '',
	displayAsRow = false,
	isNewDesign = false,
	disableConfiguration
}: IProps) => {
	const printerNameExist = printersList.find(
		elem => elem.name === printerValue?.name
	)
	const printerCompanyExist = companiesList.find(
		elem => elem === printerCompanyValue
	)
	const printerMaterialExist = materialsList.find(
		elem => elem.name === (materialValue?.name || materialValue)
	)

	const companyChanged = (event: ChangeEvent<HTMLSelectElement>) => {
		onCompanyChange(event.target.value)
	}

	const printerChanged = (event: ChangeEvent<HTMLSelectElement>) => {
		onPrinterChange(event.target.value)
	}

	const materialChanged = (event: ChangeEvent<HTMLSelectElement>) => {
		onMaterialsChange(event.target.value)
	}

	const createMenuItemTsxElement = (menuItem: any, isDisable?: boolean) => {
		return (
			<MenuItemTsx
				disabled={isDisable || disabled}
				key={menuItem.id || menuItem}
				style={{ textTransform: 'capitalize' }}
				value={menuItem}
			>
				{menuItem.name || menuItem}
			</MenuItemTsx>
		)
	}

	const renderMaterialMenuItems = () =>
		materialsList
			.sort(
				(
					materialOne: ISimpleConfigurationMaterial,
					materialTwo: ISimpleConfigurationMaterial
				) => {
					return materialOne.name.localeCompare(materialTwo.name)
				}
			)
			.map((material: ISimpleConfigurationMaterial) => (
				<MenuItemTsx
					disabled={disabled}
					key={material.id}
					value={material}
					defaultValue={'Materials'}
				>
					{material.name}
				</MenuItemTsx>
			))

	return (
		<Flexbox
			flexDirection={displayAsRow ? 'row' : 'column'}
			justifyContent="space-between"
			alignItems="flex-start"
			className={itemWrapperClassname}
		>
			<Flexbox
				flexDirection="column"
				justifyContent="space-between"
				alignItems="flex-start"
			>
				<div
					className={`printer-material-config--drop-down-headers ${
						dropDownsHeaderClass || ''
					}`}
				>
					{getString('PRINTER_COMPANY')}
				</div>
				<Flexbox
					flexDirection="row"
					justifyContent="space-between"
					alignItems="flex-start"
				>
					<SelectMenu
						hideTitle
						rootClassName={cx({
							disabled: !companiesList?.length || disableConfiguration
						})}
						value={companiesList?.length ? printerCompanyValue : ''}
						disabled={!companiesList?.length || disableConfiguration}
						onChange={companyChanged}
						disabledMenuItem={
							isNewDesign
								? getString('SELECT')
								: getString('IN_HOUSE_PRINTERS_COMPANY_SELECTOR_NAME')
						}
						classes={{ select: selectMenuClassName }}
						qaDataElementName="data-qa-printer-company-select"
					>
						{companiesList.map((menuItem: any) =>
							createMenuItemTsxElement(menuItem)
						)}
						{(!printerCompanyExist &&
							printerCompanyValue &&
							createMenuItemTsxElement(printerCompanyValue, true)) || <></>}
					</SelectMenu>
				</Flexbox>
			</Flexbox>
			<Flexbox
				flexDirection="column"
				justifyContent="space-between"
				alignItems="flex-start"
			>
				<div
					className={`printer-material-config--drop-down-headers ${
						dropDownsHeaderClass || ''
					}`}
				>
					{getString('PRINTER_NAME')}
				</div>
				<Flexbox
					flexDirection="row"
					justifyContent="space-between"
					alignItems="flex-start"
				>
					<SelectMenu
						rootClassName={cx({
							disabled: !printersList?.length || disableConfiguration
						})}
						displayEmpty={true}
						value={printerValue || ''}
						hideTitle
						disabled={!printersList?.length || disableConfiguration}
						onChange={printerChanged}
						disabledMenuItem={
							isNewDesign
								? getString('SELECT')
								: getString('IN_HOUSE_PRINTERS_PRINTER_NAME')
						}
						classes={{ select: selectMenuClassName }}
						qaDataElementName="data-qa-printer-name-select"
					>
						{!printerNameExist && printerValue
							? createMenuItemTsxElement(printerValue, true)
							: printersList
									.sort((materialOne: any, materialTwo: any) => {
										return materialOne.name.localeCompare(materialTwo.name)
									})
									.map((menuItem: ISimpleConfigurationPrinter) =>
										createMenuItemTsxElement(menuItem)
									)}
					</SelectMenu>
				</Flexbox>
			</Flexbox>

			<Flexbox
				flexDirection="column"
				justifyContent="space-between"
				alignItems="flex-start"
			>
				<div
					className={`printer-material-config--drop-down-headers ${
						dropDownsHeaderClass || ''
					}`}
				>
					{getString('MATERIAL')}
				</div>
				<SelectMenu
					rootClassName={cx({
						disabled: !printersList?.length || disableConfiguration
					})}
					value={materialValue || ''}
					hideTitle
					disabled={!materialsList?.length || disableConfiguration}
					onChange={materialChanged}
					disabledMenuItem={
						isNewDesign
							? getString('SELECT')
							: getString('MATERIAL_COST_MATERIAL_NAME')
					}
					classes={{ select: selectMenuClassName }}
					qaDataElementName="data-qa-printer-material-select"
				>
					{!printerMaterialExist && materialValue
						? createMenuItemTsxElement(materialValue, true)
						: renderMaterialMenuItems()}
				</SelectMenu>
			</Flexbox>
		</Flexbox>
	)
}
export default memo(SimpleConfigurationSelector)
