import { ChangeEvent, FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { onChangePermission } from '../AdminUsersActions'
import { togglePermission } from '../constants'
import CastorSwitch from 'Scenes/Components/CastorSwitch'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import FlexBox from 'Scenes/Components/FlexBox'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { getString } from 'Services/Strings/StringService'

import './AdminUserInfo.scss'

const AdminUserPermissions: FC = () => {
	const dispatch = useDispatch()
	const { organizationOwner, adminUserManager } = useSelector(
		(state: RootStateOrAny) => state.AdminUsersReducer
	)

	return (
		<div className="admin-user-permission">
			<FlexBox
				data-qa="data-qa-organization-owner-toggle"
				alignItems="center"
				justifyContent="flex-start"
			>
				<CastorSwitch
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						dispatch(
							onChangePermission(
								e.target.checked,
								togglePermission.organizationOwner
							)
						)
					}
					checked={organizationOwner}
				/>
				<span className="data-table-text-field">
					{getString('ORGANIZATION_OWNER')}
				</span>
				<DetailsPopup
					isHover={true}
					data={getString('ORGANIZATION_OWNER_POPUP_MESSAGE')}
					popperDirection="bottom"
					popperClassName="info-box-wrapper"
					popperContactClassName="info-box-data"
					inPortal={true}
				>
					<IconFactory
						iconName="info"
						className="solution-analysis-header-text-icon without-stroke"
					/>
				</DetailsPopup>
			</FlexBox>
			<FlexBox
				data-qa="data-qa-admin-toggle"
				alignItems="center"
				justifyContent="flex-start"
			>
				<CastorSwitch
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						dispatch(
							onChangePermission(
								e.target.checked,
								togglePermission.adminUserManager
							)
						)
					}
					checked={adminUserManager}
				/>
				<span className="data-table-text-field">{getString('ADMIN')}</span>
				<DetailsPopup
					isHover={true}
					data={getString('ADMIN_POPUP_MESSAGE')}
					popperDirection="bottom"
					popperClassName="info-box-wrapper"
					popperContactClassName="info-box-data"
					inPortal={true}
				>
					<IconFactory
						iconName="info"
						className="solution-analysis-header-text-icon without-stroke"
					/>
				</DetailsPopup>
			</FlexBox>
		</div>
	)
}

export default memo(AdminUserPermissions)
