import React, { FC, memo } from 'react'

import {
	ADD_TO_FAVORITES,
	PART_ANALYSIS_REMOVE,
	REMOVE_FROM_FAVORITES
} from '../../../../Services/Strings'
import IconFactory from '../../../Components/StarIcon/IconFactory'
import StarIcon from '../../../Components/StarIcon/StarIcon'

interface Props {
	starred: boolean
	onStarClicked: (e: React.MouseEvent<SVGSVGElement>) => void
	onRemoveClicked: Function
	showRemove?: boolean
}

const PartCardActionIcons: FC<Props> = ({
	starred,
	onStarClicked,
	onRemoveClicked,
	showRemove
}) => {
	return (
		<div className="part-actions">
			{showRemove && (
				<IconFactory
					iconName="remove"
					className="part-actions--remove"
					title={PART_ANALYSIS_REMOVE}
					onClick={onRemoveClicked}
				/>
			)}
			<StarIcon
				className={`part-actions--save ${
					starred ? 'part-actions--save--starred' : ''
				}`}
				starred={starred}
				title={starred ? REMOVE_FROM_FAVORITES : ADD_TO_FAVORITES}
				onStarClicked={onStarClicked}
				customColor
			/>
		</div>
	)
}

export default memo(PartCardActionIcons)
