import React, { FC } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { START_SCREEN_IDS, STEP_ID } from '../../constants'
import { useStep } from '../../OnboardingWizardHooks'
import OnboardingStepFrame from '../OnboardingStepFrame'
import DescriptionBlock from './DescriptionBlock'
import Greeting from './Greeting'
import StartButton from './StartButton'

import './StartScreen.scss'

const StartScreenComponents = {
	[START_SCREEN_IDS.GREETING]: <Greeting />,
	[START_SCREEN_IDS.DESCRIPTION]: <DescriptionBlock />,
	[START_SCREEN_IDS.START_BUTTON]: <StartButton />
}

const StartScreen: FC = () => {
	const { steps } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { step, stepContent } = useStep(steps, STEP_ID.START_SCREEN)

	return (
		<OnboardingStepFrame step={step}>
			<div className="start-screen--wrapper">
				{stepContent.map(component => (
					<React.Fragment key={component.id}>
						{
							StartScreenComponents[
								component.id as keyof typeof StartScreenComponents
							]
						}
					</React.Fragment>
				))}
			</div>
		</OnboardingStepFrame>
	)
}

export default StartScreen
