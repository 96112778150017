import { Component } from 'react'
import { connect } from 'react-redux'

import {
	CUSTOMIZE_OFF_THE_SHELF_TITLE,
	ERROR_UPDATING_TAGS,
	NAV_TITLE_CUSTOMIZE_USER,
	OFF_THE_SHELF_SELECTOR_ITEMS
} from '../../../../Services/Strings'
import { getThemeString } from '../../../../themes/getThemeString'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import CastorTags from '../../../Components/CastorTags/CastorTags'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import { Danger } from '../../../Components/thirdParty/CreativeTim/components'
import { OFF_THE_MANUFACTURE, OFF_THE_SHELF } from '../CustomizeLogic'
import {
	getPartFilteringData,
	partFilteringChanged
} from './CustomizePartFilteringActions'

class CustomizePartFiltering extends Component {
	componentDidMount = () => {
		if (!this.props.partFilteringArr.length) {
			this.props.getPartFilteringData()
		}
	}

	renderAlertError = () => {
		if (this.props.errorSavingFilterString) {
			return <Danger>{ERROR_UPDATING_TAGS}</Danger>
		}
		return <div />
	}

	setTagsContent = () => {
		const customizeLabel = {}
		customizeLabel[OFF_THE_SHELF_SELECTOR_ITEMS[OFF_THE_SHELF]] = 'info'
		customizeLabel[OFF_THE_SHELF_SELECTOR_ITEMS[OFF_THE_MANUFACTURE]] = 'danger'
		const { filteredGlobalOffTheShelf, partFilteringArr, loading } = this.props

		return (
			<div>
				<CastorFormHeader
					explanationHeader={CUSTOMIZE_OFF_THE_SHELF_TITLE}
					explanationArray={getThemeString(
						'CUSTOMIZE_OFF_THE_SHELF_EXPLANATION_ARRAY'
					)}
					isInCard={true}
				/>
				<CastorTags
					filteredGlobalOffTheShelf={filteredGlobalOffTheShelf}
					selectedItems={OFF_THE_SHELF_SELECTOR_ITEMS}
					handleTags={this.handleTags}
					tags={partFilteringArr}
					labels={customizeLabel}
					loading={loading}
				/>
				{this.renderAlertError()}
			</div>
		)
	}

	renderFormHeader = () => {
		return (
			<CastorFormHeader
				explanationHeader={getThemeString('CUSTOMIZE_EXPLINATION_1_HEADER')}
				explanationArray={getThemeString('CUSTOMIZE_EXPLANATION_ARRAY')}
			/>
		)
	}

	handleTags = (newItemsArr, add, reset) => {
		this.props.partFilteringChanged(newItemsArr, add, reset)
	}

	render() {
		return (
			<NavBarAndMaterial title={NAV_TITLE_CUSTOMIZE_USER}>
				<CastorForm
					formHeader={this.renderFormHeader()}
					formTitle={CUSTOMIZE_OFF_THE_SHELF_TITLE}
					content={this.setTagsContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const mapStateToProps = ({ CustomizePartFilteringReducer }) => {
	const {
		partFilteringArr,
		loading,
		errorSavingFilterString,
		filteredGlobalOffTheShelf
	} = CustomizePartFilteringReducer

	return {
		partFilteringArr,
		loading,
		errorSavingFilterString,
		filteredGlobalOffTheShelf
	}
}

export default connect(mapStateToProps, {
	partFilteringChanged,
	getPartFilteringData
})(CustomizePartFiltering)
