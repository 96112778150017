import React, { FC, memo } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'

import { PART_ANALYSIS_HEADER } from '../../../../../Services/Strings'
import SimpleConfiguration from './SimpleConfiguration'

import '../MainPartAnalysis.scss'

interface Props {
  solutionLength: number
  onAddSimpleConfigurationClick: Function
  showSimpleConfiguration: boolean
  disabled?: boolean
}

const PartAnalysisHeader: FC<Props> = ({
  solutionLength,
  onAddSimpleConfigurationClick,
  showSimpleConfiguration,
  disabled = false,
}) => (
  <Flexbox
    alignItems="center"
    justifyContent="space-between"
    width="100%"
    margin="47px 0 40px"
  >
    <div className="part-analysis-header-title">
      {PART_ANALYSIS_HEADER}{' '}
      <span className="part-analysis-header-title-number">
        ( {solutionLength - 2} )
      </span>
    </div>
    {showSimpleConfiguration && (
      <SimpleConfiguration
        onAddSimpleConfigurationClick={onAddSimpleConfigurationClick}
        disabled={disabled}
      />
    )}
  </Flexbox>
)

export default memo(PartAnalysisHeader)
