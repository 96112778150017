import { FC, memo } from 'react'

import { isNil } from 'lodash'

import {
	PRINTER_TECHNOLOGY,
	USE_RELEVANT_FOR_PRINTER_MATERIALS
} from '../../../../NewPartConfiguration/constants'
import { IFilter } from 'Services/models/IFilter'
import { Material } from 'Services/models/IMaterial'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as Chevron } from '../../../../../../../assets/img/svg/chevron.svg'

import '../SolutionConfigure.scss'

interface SolutionSingleFilterProps {
	showSolutionFilters: any
	filter: any
	originalMaterial: Material
	printerTechnology?: any
}

const renderUI = (filter: any) => {
	return (
		<p
			data-qa={`data-qa-solution-filter-${filter.value}`}
			className="single-filter-row"
		>
			<Chevron />
			{filter.value}
		</p>
	)
}

export const SolutionSingleFilter: FC<SolutionSingleFilterProps> = ({
	filter,
	printerTechnology,
	originalMaterial
}) => {
	if (
		filter.key === USE_RELEVANT_FOR_PRINTER_MATERIALS &&
		originalMaterial?.relevantForPrinterMaterials?.length
	) {
		return renderUI(filter)
	}

	if (!filter.viewOnly) {
		if (filter.key === PRINTER_TECHNOLOGY) {
			if (!isNil(printerTechnology)) {
				return renderUI(filter)
			} else {
				return <></>
			}
		}

		return renderUI(filter)
	}

	return <></>
}

export default memo(SolutionSingleFilter)
