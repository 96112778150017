import React, { FC, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { IPartsToPrintSummary } from '../../ProjectAnalysisInterfaces'
import PartRow from '../PartsListView/PartRow'
import PartsListHeaders from '../PartsListView/PartsListHeaders'
import PartsSummaryPDF from './PartsSummaryPDF'
import ProjectSummaryPDF from './ProjectSummaryPDF'
import { GET_PROJECT_PARTS_TO_PRINT_FROM_STATE } from 'global actions/types'
import { filterParts } from 'Scenes/Components/FilterPartsGrid/FilterPartsAction'
import FooterPdf from 'Scenes/Components/PdfComponents/FooterPdf'
import HeaderPdf from 'Scenes/Components/PdfComponents/HeaderPdf'
import RowComponent from 'Scenes/Components/PdfComponents/RowPdf'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import { useGetBundleProjectNamesFromParts } from 'Services/CustomHooks/usePdfHooks'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IBestMatchData } from 'Services/models/IBestMatch'
import { Part } from 'Services/models/IPart'
import { PartProperties } from 'Services/models/IPartPropertiest'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	isBundle: boolean
	projectId: string
	selectedFilterValues: string[]
	searchPhrase: string
	projectName: string
	printabilityData: Record<string, number>
	totalPartsCount: number
	partsToPrintSummary: IPartsToPrintSummary[]
	partsToPrint: Part[]
	leadingConfigurationData: IBestMatchData[]
	shouldRequestAllParts: boolean
}

const ProjectPDF: FC<IProps> = ({
	isBundle,
	projectId,
	selectedFilterValues,
	searchPhrase,
	projectName,
	printabilityData,
	totalPartsCount,
	partsToPrintSummary,
	partsToPrint,
	leadingConfigurationData,
	shouldRequestAllParts
}) => {
	const userDetails = useSelector((state: RootStateOrAny) => {
		return state?.user?.userDetails || ''
	})
	const bundleProjectsNames = useGetBundleProjectNamesFromParts(partsToPrint)

	const dispatch = useDispatch()

	const projectNames = isBundle ? bundleProjectsNames : [projectName]

	const isQuickCADUpload = Feature.isFeatureOn(
		FeatureComponentId.QUICK_CAD_UPLOAD
	)

	const isGenerativeDesignOn = Feature.isFeatureOn(
    FeatureComponentId.GENERATIVE_DESIGN
  )

	useEffect(() => {
		if (shouldRequestAllParts) {
			dispatch(
				filterParts(
					projectId,
					searchPhrase,
					selectedFilterValues,
					isBundle,
					1,
					0,
					true
				)
			)
		} else {
			dispatch({ type: GET_PROJECT_PARTS_TO_PRINT_FROM_STATE })
		}
	}, [])

	return (
		<div>
			<HeaderPdf
				userCompany={userDetails?.company}
				headerText={getString('PROJECT_NAME')}
				headerDetailsText={projectName}
			/>
			<table className="export-pdf">
				<thead>
					<RowComponent>
						<div className="header-space" />
					</RowComponent>
				</thead>
				<tbody>
					<RowComponent>
						<ProjectSummaryPDF
							userDetails={userDetails}
							printabilityData={printabilityData}
							projectName={projectName}
							totalPartsCount={totalPartsCount}
						/>
					</RowComponent>
					<RowComponent>
						<PartsSummaryPDF
							partsToPrintSummary={partsToPrintSummary}
							filteredPartsCount={partsToPrint.length}
							projectNames={projectNames}
						/>
					</RowComponent>
					<RowComponent>
						<table>
							<thead>
								<RowComponent>
									<PartsListHeaders
										isQuickCADUpload={isQuickCADUpload}
										toPrint
										isGenerativeDesignOn={isGenerativeDesignOn}
									/>
								</RowComponent>
							</thead>
							<tbody>
								{partsToPrint?.map(part => {
									return (
										<RowComponent key={part.id}>
											<PartRow
												part={part}
												leadingConfigurationData={leadingConfigurationData}
												isBundle={isBundle}
												isQuickCADUpload={isQuickCADUpload}
												toPrint
												isGenerativeDesignOn={isGenerativeDesignOn}
											/>
										</RowComponent>
									)
								})}
							</tbody>
							<tfoot>
								<RowComponent>
									<div className="footer-space" />
								</RowComponent>
							</tfoot>
						</table>
					</RowComponent>
				</tbody>
			</table>
			<FooterPdf userCompany={userDetails?.company} />
		</div>
	)
}

export default ProjectPDF
