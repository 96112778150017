import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import AddCircleIcon from '@material-ui/icons/AddCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import _ from 'lodash'

import {
	ADD_NEW,
	ADMIN_TABLE_ERROR,
	ADMIN_TABLE_NO_DATA_FOUND,
	ARE_YOU_SURE_DELETE,
	DELETE_ITEM,
	NO,
	YES
} from '../../../../Services/Strings'
import { AlertType } from '../../../Components/alerts/AlertTypes'
import CastorAlert from '../../../Components/alerts/CastorAlert'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import CastorPagination from '../../../Components/CastorPagination/CastorPagination'
import CastorServerSearchBar from '../../../Components/CastorServerSearchBar/CastorServerSearchBar.tsx'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import {
	Button,
	Danger,
	Table
} from '../../../Components/thirdParty/CreativeTim/components'
import { getString } from 'Services/Strings/StringService'

import '../adminHome.scss'
import './AdminDataTable.css'

class AdminDataTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedId: 0,
			showAlert: false
		}
	}

	deleteRow = () => {
		const { deleteRow } = this.props
		if (this.state.selectedId) {
			deleteRow(this.state.selectedId)
			this.setState({ showAlert: false })
		}
	}

	onDeleteClick = id => {
		this.setState({ selectedId: id, showAlert: true })
	}

	renderAlert() {
		const { extraMessageOnDelete } = this.props
		return (
			<CastorAlert
				alertType={AlertType.WARNING}
				show={this.state.showAlert}
				headerTitle={DELETE_ITEM}
				onConfirm={() => this.deleteRow()}
				onCancel={() => this.setState({ showAlert: false })}
				cancelOptionalText={NO}
				confirmOptionalText={YES}
			>
				{extraMessageOnDelete &&
					this.state.showAlert &&
					extraMessageOnDelete(this.state.selectedId)}
				{ARE_YOU_SURE_DELETE}
			</CastorAlert>
		)
	}

	renderEditButton = row => {
		const { linkToEdit } = this.props
		if (!linkToEdit) {
			return <div />
		}
		return (
			<Link
				title="Edit"
				data-qa={`data-table-edit-${row[0]}`}
				className="admin-data-table-link"
				to={linkToEdit(this.findEditRowIndex(row))}
			>
				<Button justIcon color="transparent">
					<EditIcon />
				</Button>
			</Link>
		)
	}

	findEditRowIndex = row => {
		const { editLinkCustomRowIndex } = this.props
		if (editLinkCustomRowIndex || editLinkCustomRowIndex === 0) {
			return row[editLinkCustomRowIndex]
		} else {
			return _.last(row)
		}
	}

	renderRow = (...rows) => {
		return rows.map(row => (
			<span key={`row-${rows}-${row}`} data-qa={`admin-row-${row}`}>
				{row}
			</span>
		))
	}

	renderActions = row => {
		const { hideDeleteButton } = this.props
		return (
			<div>
				{this.renderEditButton(row)}
				{!hideDeleteButton && (
					<Button
						data-qa={`data-table-delete-${row[0]}`}
						title="Delete"
						onClick={this.onDeleteClick.bind(this, _.last(row))}
						justIcon
						color="transparent"
					>
						<DeleteIcon />
					</Button>
				)}
			</div>
		)
	}

	renderTableData = () => {
		const { tableData } = this.props
		return tableData.map(row => [
			...this.renderRow(...row),
			this.renderActions(row)
		])
	}

	renderTableContent = () => {
		const {
			tableHeadParams,
			linkToRouteFunction,
			showNoDataAlert,
			showPagination,
			pageNumber,
			isLastPage,
			showAdminErrorAlert,
			totalResults,
			limitResults,
			loading,
			isNoAction
		} = this.props
		let contentBody = ''
		if (showNoDataAlert) {
			contentBody = (
				<div className="align-start">
					<Danger>{ADMIN_TABLE_NO_DATA_FOUND}</Danger>
				</div>
			)
		} else if (showAdminErrorAlert) {
			contentBody = (
				<div className="align-start">
					<Danger>{ADMIN_TABLE_ERROR}</Danger>
				</div>
			)
		} else {
			const adminTableHead = [
				...tableHeadParams.map(tableHeadParam => tableHeadParam)
			]
			if (!isNoAction) {
				adminTableHead.push(getString('ACTIONS').toLowerCase())
			}
			contentBody = (
				<>
					<Table
						tableHead={adminTableHead}
						tableData={this.renderTableData()}
						loading={loading}
						addMinHeight
					/>
					<CastorPagination
						showPagination={showPagination}
						pageNumber={pageNumber}
						isLastPage={isLastPage}
						linkTo={pageNumber => {
							return linkToRouteFunction(pageNumber)
						}}
						total={totalResults}
						limit={limitResults}
					/>
				</>
			)
		}
		return (
			<div className="admin-home-page">
				<div className="admin-data-table-add-item-button">
					{this.renderSearchBar()}
					{this.renderNewButton()}
				</div>
				{contentBody}
			</div>
		)
	}

	renderNewButton = () => {
		const { linkToNew, removeNewItem, NewComponent } = this.props
		if (removeNewItem) {
			return <div />
		}
		if (!linkToNew && NewComponent) {
			return <NewComponent />
		}
		return (
			<Link
				className="admin-data-table-link add-new"
				title="Add New"
				to={linkToNew()}
			>
				<Button justIcon color="transparent">
					<AddCircleIcon className="circle-icon" />
					<div className="new-text">{ADD_NEW}</div>
				</Button>
			</Link>
		)
	}

	renderSearchBar = () => {
		if (this.props.removeSearchBar) {
			return <div />
		}
		return (
			<div className="admin-data-table-search-field">
				<CastorServerSearchBar
					searchPhrase={this.props.searchPhrase}
					setSearchPhrase={this.setSearchPhrase}
				/>
			</div>
		)
	}

	setSearchPhrase = searchPhrase => {
		this.props.setSearchPhrase(searchPhrase)
	}

	render() {
		const { formTitle, formSubTitle } = this.props
		return (
			<NavBarAndMaterial title={formTitle}>
				{this.renderAlert()}
				<CastorForm
					formTitle={formTitle}
					formSubTitle={formSubTitle}
					content={this.renderTableContent()}
					style={{ maxWidth: 'unset' }}
				/>
			</NavBarAndMaterial>
		)
	}
}

export default AdminDataTable
