import React, { FC, memo, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import AdminDataTable from '../AdminDataTable/AdminDataTable'

import Loader from '../../../Loader/Loader'
import './AdminVersionPage.scss'
import '../adminHome.scss'
import { AdminVersionPageInitialState } from './AdminVersionPageReducer'
import * as AdminVersionPageActions from './AdminVersionPageActions'
import { getString } from '../../../../Services/Strings/StringService'
const moment = require('moment')
const version = require('../../../../../package.json').version


interface IProps extends AdminVersionPageInitialState {
	setupAdminVersionPage: Function
}

export enum ServicesStatusTypes {
	GREEN = 'GREEN',
	ORANGE = 'ORANGE',
	RED = 'RED'
}

interface IStoreProps {
	AdminVersionPageReducer: AdminVersionPageInitialState
}
const renderStatusField = (status: String) => {
	return (
		<div className="admin-version-status-field">
			<div>
				{status === ServicesStatusTypes.GREEN ? (
					<span className="circle-green"></span>
				) : status === ServicesStatusTypes.ORANGE ? (
					<span className="circle-orange"></span>
				) : (
					<span className="circle-red"></span>
				)}
			</div>
		</div>
	)
}

const AdminVersionPage: FC<IProps> = ({
	loading,
	versionPageData,
	showNoParametersAlert,
	showPagination,
	isLastPage,
	showAdminVersionPageAlert,
	setupAdminVersionPage
}) => {
	useEffect(() => {
		setupAdminVersionPage()
	}, [])
	if (loading) {
		return <Loader load={loading} message="" />
	}
	const renderVersionPageData = (serviceParametersData: Array<any>) => {
		if (!serviceParametersData) {
			return []
		}

		// Add Current 'React Client' version:
		const serviceName = 'React Client'
		const isReactServiceExist = serviceParametersData.find(service => service.serviceName===serviceName);

		if (!isReactServiceExist) {
			const reactClient = {
				serviceName: serviceName,
				version: version,
				repeatInterval: 1,
				lastUpdate: null,
				status: 'GREEN'
			}
			serviceParametersData.push(reactClient)
		}

		return (
			serviceParametersData &&
			serviceParametersData.map((versionPageData: any) => {
				return [
					versionPageData.serviceName,
					versionPageData.version,
					versionPageData.repeatInterval,
					versionPageData.lastUpdate
						? moment(versionPageData.lastUpdate).format('DD/MM/YYYY hh:mm')
						: '-',
					renderStatusField(versionPageData.status)
				]
			})
		)
	}
	return (
		<AdminDataTable
			loading={false}
			formTitle={getString('ADMIN_VERSION_PAGE_HEADER')}
			tableHeadParams={getString('ADMIN_VERSION_PAGE_TABLE_HEADERS')}
			tableData={renderVersionPageData(versionPageData)}
			showNoDataAlert={showNoParametersAlert}
			showPagination={showPagination}
			pageNumber={0}
			isLastPage={isLastPage}
			showAdminErrorAlert={showAdminVersionPageAlert}
			linkToEdit={null}
			removeSearchBar={true}
			removeNewItem={true}
			hideDeleteButton={true}
			editLinkCustomRowIndex={0}
			isNoAction={true}
		/>
	)
}

function mapStateToProps({ AdminVersionPageReducer }: IStoreProps) {
	return {
		...AdminVersionPageReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...AdminVersionPageActions }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(memo(AdminVersionPage))
