import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'

import TextField from '@material-ui/core/TextField'
import classNames from 'classnames'

import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import Flexbox from 'Scenes/Components/FlexBox'
import { isEnterKeyPressed } from 'Services/getKeyCodesService'
import {
	OK,
	WALL_THICKNESS_ALERT_HELPER_TEXT,
	WALL_THICKNESS_DEMAND_ALERT_DESCRIPTION,
	WALL_THICKNESS_DEMAND_ALERT_TITLE,
	WALL_THICKNESS_DEMAND_BUTTON_PLACEHOLDER
} from 'Services/Strings'

import './WallThicknessDemand.scss'

const InputField: any = TextField

interface IProps {
	loading: boolean
	show: boolean
	wallThickness: number
	onConfirm: Function
	onCancel: Function
}

const WallThicknessDemand: FC<IProps> = ({
	loading,
	show,
	onConfirm,
	wallThickness,
	onCancel
}) => {
	const [customWallThickness, setCustomWallThickness] = useState<null | number>(
		null
	)
	const [disableAlertButton, setDisableAlertButton] = useState(true)
	const [errorInput, setErrorInput] = useState(false)

	const onInputValueChange = (value: number) => {
		const wrongValue = value < 0.1 || value > 3.5
		const isNanValue = isNaN(Number(String(value).charAt(0)))
		const isMoreDecimals = String(value).length > 3

		setDisableAlertButton(wrongValue)
		setErrorInput(wrongValue || isNanValue || isMoreDecimals)
		setCustomWallThickness(value)
	}

	const onKeyUpChange = (e: KeyboardEvent | ChangeEvent<HTMLInputElement>) => {
		if (isEnterKeyPressed(e as KeyboardEvent) && !disableAlertButton) {
			onConfirm(customWallThickness)
		}
	}

	useEffect(() => {
		wallThickness && setCustomWallThickness(wallThickness)
	}, [wallThickness])

	const onAlertCancel = () => {
		// reset unsaved value
		wallThickness && setCustomWallThickness(wallThickness)
		setErrorInput(false)
		onCancel()
	}

	const renderAlertBody = () => {
		return (
			<Flexbox flexDirection="column" alignItems="center">
				{wallThickness
					? WALL_THICKNESS_DEMAND_ALERT_DESCRIPTION.format(
							wallThickness.toString()
					  )
					: ''}
				<InputField
					helperText={errorInput ? WALL_THICKNESS_ALERT_HELPER_TEXT : ''}
					placeholder={WALL_THICKNESS_DEMAND_BUTTON_PLACEHOLDER || ''}
					value={customWallThickness}
					error={errorInput}
					onKeyUp={onKeyUpChange}
					onChange={(e: any) => onInputValueChange(e.target.value)}
					onWheel={(event: ChangeEvent<HTMLInputElement>) =>
						event.target.blur()
					}
					className={classNames('wall-thickness--alert--input grey', {
						error: errorInput
					})}
					InputLabelProps={{
						shrink: false
					}}
					variant={'standard'}
					type="number"
					id={'wall-thickness'}
					autoFocus={false}
					inputProps={{
						step: 0.1
					}}
				/>
			</Flexbox>
		)
	}

	return (
		<CastorAlert
			loadingCalculation={loading}
			headerTitle={WALL_THICKNESS_DEMAND_ALERT_TITLE}
			show={show}
			onConfirm={() => onConfirm(customWallThickness)}
			onCancel={onAlertCancel}
			showCancel={false}
			confirmOptionalText={OK}
			disabled={disableAlertButton}
			alertClass="wall-thickness--alert"
			alertBodyClass="wall-thickness--alert--body"
		>
			{renderAlertBody()}
		</CastorAlert>
	)
}

export default memo(WallThicknessDemand)
