import Flexbox from 'Scenes/Components/FlexBox'
import { FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { forEach, map } from 'lodash'

import { updateManufacturing } from '../../UploadProjectActions'
import { useMaterial } from '../UploadBlocksHooks'
import CardHeaderBox from 'Scenes/Components/CardHeaderBox'
import CastorSelectBox from 'Scenes/Components/CastorSelectBox'
import { disableMaterialAndManufacturing } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/SolutionAnalysisTabsService'
import { getMethodName } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/LeadTimeTab/LeadTimeService'
import { manufacturingMethodTypes } from 'Services/Constants'
import { getString } from 'Services/Strings/StringService'


const UploadManufacturing: FC = () => {
	const [manufacturingTypes, setManufacturingTypes] = useState<string[]>([])
	const manufactureMethod = useSelector(
		(state: RootStateOrAny) => state.uploadProject?.manufactureMethod
	)
	const dispatch = useDispatch()
	const [material] = useMaterial()

	useEffect(() => {
		const allowedTypes: string[] = []
		forEach(manufacturingMethodTypes, (method: string) => {
			const disableMethod = disableMaterialAndManufacturing(material, method)
			if (!disableMethod) allowedTypes.push(method)
		})

		setManufacturingTypes(allowedTypes)

		// need to update manufacturing if 1 element is available
		if (allowedTypes.length === 1) {
			dispatch(updateManufacturing(allowedTypes[0]))
		}
	}, [material])

	return (
		<CardHeaderBox
			contentClass="new-upload-project__card"
			title={getString('NEW_UPLOAD_SECTION_MANUFACTURING')}
			content={
				<Flexbox className="new-upload-project__content" flexDirection="column">
					<div className="label">{getString('WHAT_TYPE_OF_MANUFACTURING')}</div>
					<Flexbox
						className="upload-form__scenario-block"
						data-qa={`data-qa-manufacturing-block`}
					>
						{map(manufacturingTypes, (method: string) => {
							return (
								<CastorSelectBox
									key={method}
									qaDataElementName={`data-qa-manufacturing-${method}`}
									setSelectedType={() => {
										dispatch(updateManufacturing(method))
									}}
									selected={manufactureMethod === method}
								>
									<>{getMethodName(method)}</>
								</CastorSelectBox>
							)
						})}
					</Flexbox>
				</Flexbox>
			}
		/>
	)
}
export default memo(UploadManufacturing)