import React, { FC, memo } from 'react'

import { Button } from '../../../../Components/thirdParty/CreativeTim/components'
import { getString } from '../../../../../Services/Strings/StringService'

import '../MainPartAnalysis.scss'
import WithFeatureToggleHOC from '../../../../../Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from '../../../../../Services/models/Features'

interface Props {
  disabled?: boolean
  onAddSimpleConfigurationClick: Function
}

const SimpleConfiguration: FC<Props> = ({
  onAddSimpleConfigurationClick,
  disabled = false
}) => (
  <Button
    color="primary"
    onClick={onAddSimpleConfigurationClick}
    className="part-analysis-header-button part-analysis-button-text-transform"
    muiClasses={{
      label: 'part-analysis-button-label',
      root: 'part-analysis-button-root'
    }}
    disabled={disabled}
  >
    + {getString('ADD_PRINTER_MATERIAL_CONFIGURATION_BUTTON')}
  </Button>
)

export default WithFeatureToggleHOC(
  memo(SimpleConfiguration),
  FeatureComponentId.SIMPLE_CONFIGURATION
)
