import React, { FC, memo } from 'react'

import { isNumber } from 'lodash'

import { filters } from './filterPartsEnum'
import { Project } from 'Services/models/IProject'

import { ReactComponent as CircleClose } from 'assets/img/svg/icons/circle_close.svg'

import './FilterParts.scss'

interface FilterTagsProps {
	selectedFilterValues: string[]
	assemblyProjects?: Project[]
	handleChange: Function
}

const FilterTags: FC<FilterTagsProps> = ({
	selectedFilterValues,
	assemblyProjects,
	handleChange
}) => {
	return (
		<div className="filters-for-part">
			<div className="filters-for-part__list">
				{selectedFilterValues.map((value: string) => {
					let title = value

					const projectIndex = assemblyProjects?.findIndex(
						(project: Project) => {
							return project.id === value
						}
					)

					if (isNumber(projectIndex) && assemblyProjects && projectIndex > -1) {
						title = assemblyProjects[projectIndex].name
					}

					return (
						<div
							key={value}
							className="filters-for-part__list-selected"
							data-qa={`data-qa-selected-filter-part-${value}`}
							onClick={() => handleChange(value)}
						>
							<span className="filters-for-part__list-text">{title}</span>
							{value !== filters.ALL && <CircleClose />}
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default memo(FilterTags)
