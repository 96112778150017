import React, { FC, memo } from 'react'
import { FormControlLabel } from '@material-ui/core'
import Flexbox from 'Scenes/Components/FlexBox'

import { CUSTOMIZE_USER_SEMI_MATERIAL_TITLE_FOR_ADMIN } from '../../../../../Services/Strings'
import OverrideComponentHOC from '../../../../../themes/OverrideComponentHOC'
import CastorSwitch from '../../../../Components/CastorSwitch'

const FormControlLabelTSX: any = FormControlLabel

interface IProps {
  onChange: Function
  isDesktopPrinter: boolean
}

const CustomizeDesktopPrinterMenuItem: FC<IProps> = ({
  onChange,
  isDesktopPrinter
}) => (
  <Flexbox
    alignItems="center"
    width="100%"
    justifyContent="space-between"
    paddingLeft="35px"
  >
    <span style={{ marginRight: '10px' }}>
      {CUSTOMIZE_USER_SEMI_MATERIAL_TITLE_FOR_ADMIN}
    </span>
    <FormControlLabelTSX
      control={<CastorSwitch onChange={onChange} checked={isDesktopPrinter} />}
    />
  </Flexbox>
)

export default memo(OverrideComponentHOC(CustomizeDesktopPrinterMenuItem))
