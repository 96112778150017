import { Action, ActionWithPayload } from 'global actions/ActionModels'
import { PART_COST } from 'Scenes/Home/Customize/CustomizeCostingFunctionEditor/constants'

interface IInstanceStateExtractorPayload {
	id: string
}

interface IStateExtractorState {
	[name: string]: { [sid: string]: any }
}

const initialState: IStateExtractorState = {
	functionEditorStates: {}
}

const StateFunctionEditorExtractor = (
	reducer: (
		state: any,
		{ type, payload }: Action<IInstanceStateExtractorPayload>
	) => any
) => {
	return (
		states: IStateExtractorState,
		payloadAndType: ActionWithPayload<IInstanceStateExtractorPayload>
	) => {
		if (payloadAndType && payloadAndType.payload) {
			const id = payloadAndType.payload.id || PART_COST

			const originalState = states.functionEditorStates[id]

			states.functionEditorStates[id] = reducer(originalState, payloadAndType)
			return { ...states }
		}
		return initialState
	}
}

export default StateFunctionEditorExtractor
