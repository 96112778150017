import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

export default class LocationService {
  address: string

  constructor(address: string) {
    this.address = address
  }

  getGeoLocationByAddress = async () => {
    const locationObj = await geocodeByAddress(this.address)
    return locationObj[0]
  }

  getLatLongFromLocation = async (location: google.maps.GeocoderResult) => {
    const latLong = await getLatLng(location)
    return latLong
  }

  parseAddress = (addressObj: any) => {
    const { addressArray, latLng, formatted_address } = addressObj
    let city = addressArray.find((address: any) =>
        address.types.includes('locality')
      ),
      country = addressArray.find((address: any) =>
        address.types.includes('country')
      ),
      state =
        country && country.long_name === 'United States'
          ? addressArray.find((address: any) =>
              address.types.includes('administrative_area_level_1')
            )
          : null,
      zip_code = addressArray.find((address: any) =>
        address.types.includes('postal_code')
      ),
      long = latLng ? latLng.lng : null,
      lat = latLng ? latLng.lat : null

    city = city ? city.long_name : null
    state = state ? state.long_name : null
    country = country ? country.long_name : null
    zip_code = zip_code ? zip_code.long_name : null

    return {
      formatted_address,
      city,
      state,
      country,
      zip_code,
      long,
      lat
    }
  }
}
