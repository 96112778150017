import React, { FC, memo } from 'react'

import cx from 'classnames'

import DetailsPopup from '../DetailsPopup'
import { getString } from 'Services/Strings/StringService'
import IconFactory from '../StarIcon/IconFactory'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { Feature, FeatureComponentId } from 'Services/models/Features'

import './StandardCost.scss'
import 'animate.css'


interface IProps {
	showStandardCostFinishIcon?: boolean
	onClick: Function
	isPrimary?: boolean
	isTransparent?: boolean
}

const StandardCost: FC<IProps> = ({
	showStandardCostFinishIcon,
	onClick,
	isPrimary,
	isTransparent
}) => {
	const renderApprovalIcon = () => {
		if (!showStandardCostFinishIcon) {
			return <div />
		}
		return (
			<IconFactory
				iconName="done"
				className={cx('animate__bounceIn standard-cost--approve-icon', {
					'inside-transparent': isTransparent
				})}
			/>
		)
	}

	const disableStandardCost = !Feature.isFeatureActive(
		FeatureComponentId.STANDARD_COST
	)

	return (
		<DetailsPopup
			isHover={disableStandardCost}
			data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
			popperDirection="bottom"
			popperClassName="info-box-wrapper details-popup--contact-us"
			popperContactClassName="info-box-data"
		>
			<div className="standard-cost" id={FeatureComponentId.STANDARD_COST}>
				{renderApprovalIcon()}
				<Button
					disabled={disableStandardCost}
					className={cx({
					'border-button': !isPrimary,
					transparent: isTransparent
				})}
					color={isPrimary ? 'primary' : 'transparent'}
					onClick={onClick}
					style={{ marginBottom: '10px', marginTop: 0 }}
				>
					{getString('STANDARD_COST')}
				</Button>
			</div>
		</DetailsPopup>
	)
}

export default WithFeatureToggleHOC(
	memo(StandardCost),
	FeatureComponentId.STANDARD_COST
)