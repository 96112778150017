import { Action } from '../../global actions/ActionModels'
import {
  BEFORE_SEND_PART_TO_CONSUMER,
  FINISH_SEND_PART_TO_CONSUMER,
  SEND_PART_TO_CONSUMER_REMOVE,
  START_SEND_PART_TO_CONSUMER
} from '../../global actions/types/partAnalysisTypes'
import { IUserProvider } from '../../Services/models/IUserProvider'

export class IntegrationProviderInitialState {
  readonly userProviders: IUserProvider[] = []
  readonly providerState: any = {}
  readonly sendToLoader: boolean = false
}

const initialState: IntegrationProviderInitialState =
  new IntegrationProviderInitialState()

const integrationProviderReducer = (
  state = initialState,
  { type, payload }: Action<any>
) => {
  switch (type) {
    case START_SEND_PART_TO_CONSUMER:
      return {
        ...state,
        sendToLoader: true
      }
    case FINISH_SEND_PART_TO_CONSUMER:
      return {
        ...state,
        sendToLoader: false
      }
    case BEFORE_SEND_PART_TO_CONSUMER:
      const { providerState } = payload
      return {
        ...state,
        providerState
      }
    case SEND_PART_TO_CONSUMER_REMOVE:
      return {
        ...state,
        providerState: {}
      }
    default:
      return state
  }
}
export default integrationProviderReducer
