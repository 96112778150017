import React, { FC, memo } from 'react'
import Slider, { Range } from 'rc-slider'

import { IOtherProps } from '../../../Services/models/IOtherProps'

import './CastorSlider.scss'

const { createSliderWithTooltip } = Slider
let RCSlider: any = Slider
let RCRange: any = Range

interface IProps extends IOtherProps {
  className?: string
  isRange?: boolean
  withTooltip?: boolean
}

const CastorSlider: FC<IProps> = ({
  className = '',
  isRange = false,
  withTooltip = false,
  ...otherProps
}) => {
  if (withTooltip) {
    RCSlider = createSliderWithTooltip(Slider)
    RCRange = createSliderWithTooltip(Range)
  }
  if (!isRange) {
    return <RCSlider className={`castor-slider ${className}`} {...otherProps} />
  }
  return <RCRange className={`castor-slider ${className}`} {...otherProps} />
}

export default memo(CastorSlider)
