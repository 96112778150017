import { MenuItem, Select } from '@material-ui/core'
import { ChangeEvent, FC, useEffect, useState } from 'react'

import { getString } from 'Services/Strings/StringService'
import CastorSelectBox from 'Scenes/Components/CastorSelectBox'

const SelectTsx: any = Select
const MenuItemTsx: any = MenuItem

export enum ToleranceClass {
	TOLERANCE_CLASS_IRRELEVANT = 'ir',
	TOLERANCE_CLASS_FINE = 'f',
	TOLERANCE_CLASS_MEDIUM = 'm',
	TOLERANCE_CLASS_COARSE = 'c',
	TOLERANCE_CLASS_VERY_COARSE = 'v'
}

interface IProps {
	showIrrelevant?: boolean
	toleranceIncluded?: boolean
	selectedToleranceClass?: string
	disableUnderline?: boolean
	onSelectToleranceClass: Function
	renderButtonComponents?: boolean
}

const ToleranceClassMenu: FC<IProps> = ({
	showIrrelevant,
	toleranceIncluded,
	selectedToleranceClass,
	disableUnderline,
	renderButtonComponents = false,
	onSelectToleranceClass
}) => {
	const [toleranceClass, setToleranceClass] = useState<string>(
		ToleranceClass.TOLERANCE_CLASS_IRRELEVANT
	)

	useEffect(() => {
		const _toleranceClass = selectedToleranceClass
			? selectedToleranceClass
			: showIrrelevant
			? ToleranceClass.TOLERANCE_CLASS_IRRELEVANT
			: ToleranceClass.TOLERANCE_CLASS_MEDIUM
		setToleranceClass(_toleranceClass)
		onSelectToleranceClass(_toleranceClass)
	}, [selectedToleranceClass])

	const renderToleranceClassMenu = () => {
		return Object.entries(ToleranceClass).map(obj => {
			if (obj[1] === ToleranceClass.TOLERANCE_CLASS_IRRELEVANT) {
				if (showIrrelevant) {
					return (
						<MenuItemTsx key={obj[0]} value={obj[1]}>
							{getString(obj[0])}
						</MenuItemTsx>
					)
				}
			} else {
				return (
					<MenuItemTsx key={obj[0]} value={obj[1]}>
						{getString(obj[0])}
					</MenuItemTsx>
				)
			}
		})
	}

	const renderToleranceButtonsClassMenu = () => {
		return Object.entries(ToleranceClass).map(obj => {

	// TODO: We need the code in case we want to return the option - 'IRRELEVANT'
		// 	if (obj[1] === ToleranceClass.TOLERANCE_CLASS_IRRELEVANT) {
		// 		return  <CastorSelectBox
        //   disabled={!toleranceIncluded}
        //   key={obj[0]}
        //   setSelectedType={() => {
        //     setToleranceClass(obj[1])
        //     onSelectToleranceClass(obj[1])
        //   }}
        //   selected={toleranceIncluded ? obj[1] === toleranceClass : false}
        //   boxClassName="material-selector--box"
        // >
        //   {getString(obj[0])}
        // </CastorSelectBox>
		// 	}

		if (obj[1] !== ToleranceClass.TOLERANCE_CLASS_IRRELEVANT) {
			return (
				<CastorSelectBox
					disabled={!toleranceIncluded}
					key={obj[0]}
					setSelectedType={() => {
						setToleranceClass(obj[1])
						onSelectToleranceClass(obj[1])
					}}
					selected={toleranceIncluded ? obj[1] === toleranceClass : false}
					boxClassName="material-selector--box"
				>
					{getString(obj[0])}
				</CastorSelectBox>
			)
				}
		})
	}

	if (renderButtonComponents) {
		return <>{renderToleranceButtonsClassMenu()}</>
	}

	return (
		<SelectTsx
			title={toleranceIncluded ? toleranceClass : ''}
			className="tolerance-class-menu"
			disableUnderline={disableUnderline}
			value={toleranceIncluded ? toleranceClass : ''}
			onChange={(e: ChangeEvent<HTMLInputElement>) => {
				setToleranceClass(e.target.value)
				onSelectToleranceClass(e.target.value)
			}}
			disabled={!toleranceIncluded}
		>
			{renderToleranceClassMenu()}
		</SelectTsx>
	)
}

export default ToleranceClassMenu
