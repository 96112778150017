import React, { memo, FC } from 'react'
import {
  COST_SUMMARY_TABLE_SUB_TEXT,
  PARTS
} from '../../../../../../../../../Services/Strings'
import DataTable from '../../../../../../../../Components/DataTable'

import '../../SolutionAnalysisTabs.scss'
import '../../SolutionNewTabStyles.scss'

import {
  useCostSummaryHeaders,
  useCostSummaryRows
} from './CostSummaryTabService'

interface IProps {
  manufacturingMethodName: string
  solution: any
  configuration: any
  configurationResultBody: string
}

const CostSummaryTable: FC<IProps> = ({
  manufacturingMethodName,
  solution,
  configuration
}) => {
  const costSummaryHeaders = useCostSummaryHeaders()
  const costSummaryTableRows = useCostSummaryRows(manufacturingMethodName, solution, configuration)

  return (
    <div>
      <DataTable
        tableClassName="solution-tab--material-table solution-tab--new-table"
        tableHead={costSummaryHeaders}
        tableDataRows={costSummaryTableRows}
        showBreakLines={true}
        showBreakLineAtEndOfTable
      />
      <div className="cost-summary--quantity">
        {COST_SUMMARY_TABLE_SUB_TEXT}
        <strong>{` ${configuration?.quantity}  ${PARTS}`}</strong>
      </div>
    </div>
  )
}

export default memo(CostSummaryTable)
