import { Folder } from '@material-ui/icons'
import { isEmpty, isNull, isString } from 'lodash'

import ProjectPage from './ProjectPage'
import { Empty } from 'Scenes/Components/Empty'
import { UNDEFINED_STRING } from 'Services/Constants'
import { UPLOAD_ROUTE } from 'Services/Constants/RoutesConstants'
import {
	getStringItemFromLocalStorage,
	setStringItemToLocalStorage
} from 'Services/LocalStorageService'
import { Project, ProjectClusterStatus } from 'Services/models/IProject'
import { IUserProjectFolder } from 'Services/models/IUserProjectFolder'
import { partRoute, projectRoute } from 'Services/routeFuncs'

interface PartRoute {
	id: number
	partNumber: string
	isChargedForSubscription?: boolean
}

interface Route {
	collapse?: boolean
	component?: any
	exact?: boolean
	folderId?: string
	icon?: any
	id?: string
	isInHome?: boolean
	name?: string
	navbarName?: string
	path?: string
	reanalyzingStatus?: string
	sidebar?: boolean
	state?: string
	views?: any

	// we can add any other field
	[name: string]: any
}

export const preparePartView = (
	project: Project,
	isLight: boolean = false,
	projectPartsInSideBar: boolean = false
) =>
	project.parts
		.filter((part: PartRoute) =>
			projectPartsInSideBar && isLight ? !!part.isChargedForSubscription : true
		)
		.map((part: PartRoute) => {
			return {
				path: partRoute(project.id, part.id),
				name: part.partNumber,
				navbarName: part.partNumber,
				icon: null,
				isInHome: true,
				exact: true,
				isChargedForSubscription: part.isChargedForSubscription,
				project,
				createdAt: project.createdAt,
				orderDate: project.orderDate
			}
		})

export const clustersToRoutes = (
	projects: Array<Project>,
	projectBundle: boolean
) => {
	const routes: Array<Route> = []
	projects.forEach((project: Project) => {
		if (project.clusterStatus === ProjectClusterStatus.complete) {
			let projectPath = projectRoute(project.id)
			let projectName = project.name
			let folderId = project.id

			if (project.bundle && projectBundle) {
				projectPath = projectRoute(project?.bundle?.id)
				projectName = project?.bundle?.name
				folderId = project?.bundle?.id
			}

			routes.push({
				id: project.id,
				folderId: folderId,
				folderPath: projectPath,
				folderName: projectName,
				path: `/clusterAnalysis/${project.id}`,
				name: project.name,
				clusterRoute: true,
				navbarName: project.name,
				icon: null,
				isInHome: true,
				project,
				hidden: true,
				sidebar: false
			})
		}
	})

	return routes
}

export const makeObject = (data: any, defaultData: any) =>
	data ? (typeof data === 'string' ? JSON.parse(data) : data) : defaultData

export const projectsToRoutes = (
	projects: Array<Project>,
	projectBundle: boolean,
	projectPartsInSideBar: boolean,
	isLight: boolean
) => {
	let routes: Array<Route> = []

	projects?.forEach((project: Project) => {
		project.parts = makeObject(project.parts, [])
		project.bundle = makeObject(project.bundle, null)

		if (project.bundle && project?.bundle?.id) {
			const routeIndex = routes.findIndex(
				(route: Route) => route.folderId === project?.bundle?.id
			)
			// if we have bundle, we need to show only bundle +
			// hide the projects that related to bundle to have route
			if (projectBundle) {
				routes = makeRouteForProjectBundle(
					routeIndex,
					routes,
					project,
					projectPartsInSideBar,
					isLight
				)
			} else {
				// old logic for showing bundle collapse
				routes = makeCollapseRouteForProjectBundle(
					routeIndex,
					routes,
					project,
					projectPartsInSideBar,
					isLight
				)
			}
		} else {
			// regular project
			routes = makeRouteForProject(
				routes,
				project,
				projectPartsInSideBar,
				isLight
			)
		}
	})

	return routes
}

export const makeRouteForProject = (
	routes: Array<Route>,
	project: Project,
	projectPartsInSideBar: boolean,
	isLight: boolean
) => {
	let changedRoutes = routes
	let projectRoutes = {
		id: project.id,
		path: projectRoute(project.id),
		name: project.name,
		navbarName: project.name,
		icon: null,
		isInHome: true,
		project,
		reanalyzingStatus: project.reanalyzingStatus,
		exact: true,
		createdAt: project.createdAt,
		orderDate: project.orderDate,
		userProjectFolderId: project.userProjectFolderId
	}

	//show parts in sidebar
	if (projectPartsInSideBar) {
		// check if we have more than 1 part then create partsView
		const partsViews =
			project.parts.length > 1 || project.totalParts > 1
				? preparePartView(project, isLight, projectPartsInSideBar)
				: undefined

		// create collapse menu for project parts
		changedRoutes.push({
			...projectRoutes,
			collapse: !!partsViews,
			state: `openProjectPart_${project.id}`,
			component: ProjectPage,
			icon: null,
			sidebar: true,
			views: partsViews
		})
	} else {
		//without parts in sidebar
		changedRoutes.push(projectRoutes)
	}

	return changedRoutes
}

export const makeRouteForProjectBundle = (
	routeIndex: number,
	routes: Array<Route>,
	project: Project,
	projectPartsInSideBar: boolean,
	isLight: boolean
) => {
	let changedRoutes = routes
	let bundleProject = {
		id: project?.bundle?.id,
		folderId: project?.bundle?.id,
		path: projectRoute(project?.bundle?.id),
		name: project?.bundle?.name,
		navbarName: project?.bundle?.name,
		isInHome: true,
		reanalyzingStatus: project.reanalyzingStatus,
		exact: true,
		createdAt: project.createdAt,
		orderDate: project.orderDate,
		userProjectFolderId: project.userProjectFolderId
	}

	//show parts in sidebar
	if (projectPartsInSideBar) {
		// create collapse menu for project parts
		const bundleViews =
			project.parts.length > 0
				? preparePartView(project, isLight, projectPartsInSideBar)
				: []

		if (routeIndex < 0) {
			// add first project route and view
			changedRoutes.push({
				...bundleProject,
				collapse: true,
				state: `openProjectPart_${project?.bundle?.id}`,
				component: ProjectPage,
				icon: null,
				sidebar: true,
				views: bundleViews
			})
		} else {
			// add to current project route views
			changedRoutes[routeIndex].views.push(...bundleViews)
		}
	} else {
		if (routeIndex < 0) {
			changedRoutes.push(bundleProject)
		}
	}

	// prepare hidden route
	// we need it to highlight area
	changedRoutes.push({
		id: project.id,
		folderId: project?.bundle?.id,
		folderPath: projectRoute(project?.bundle?.id),
		folderName: project?.bundle?.name,
		path: projectRoute(project.id),
		name: project.name,
		navbarName: project.name,
		icon: null,
		isInHome: true,
		project,
		hidden: true,
		sidebar: false,
		createdAt: project.createdAt,
		orderDate: project.orderDate,
		userProjectFolderId: project.userProjectFolderId
	})

	return changedRoutes
}

export const makeCollapseRouteForProjectBundle = (
	routeIndex: number,
	routes: Array<Route>,
	project: Project,
	projectPartsInSideBar: boolean,
	isLight: boolean
) => {
	let changedRoutes = routes
	let folderProject = {
		id: project?.bundle?.id,
		folderId: project?.bundle?.id,
		name: project?.bundle?.name,
		navbarName: project?.bundle?.name,
		isInHome: true,
		reanalyzingStatus: project.reanalyzingStatus,
		exact: true,
		createdAt: project.createdAt,
		orderDate: project.orderDate,
		userProjectFolderId: project.userProjectFolderId
	}

	let viewProject = {
		path: projectRoute(project.id),
		name: project.name,
		navbarName: project.name,
		reanalyzingStatus: project.reanalyzingStatus,
		icon: null,
		isInHome: true,
		exact: true,
		project,
		createdAt: project.createdAt,
		orderDate: project.orderDate,
		userProjectFolderId: project.userProjectFolderId
	}

	//show parts in sidebar
	if (projectPartsInSideBar) {
		// create collapse menu for project parts
		const partsViews =
			project.parts.length > 0
				? preparePartView(project, isLight, projectPartsInSideBar)
				: []

		if (routeIndex < 0) {
			changedRoutes.push({
				collapse: true,
				name: project?.bundle?.name,
				state: `openProjectBundle_${project?.bundle?.id}`,
				component: ProjectPage,
				icon: null,
				sidebar: true,
				isInHome: true,
				folderId: project?.bundle?.id,
				exact: true,
				views: partsViews,
				createdAt: project.createdAt,
				orderDate: project.orderDate,
				userProjectFolderId: project.userProjectFolderId
			})
		} else {
			// add to current project route views
			changedRoutes[routeIndex].views.push(...partsViews)
		}
	} else {
		// old logic without parts in sidebar
		if (routeIndex < 0) {
			changedRoutes.push({
				...folderProject,
				collapse: true,
				state: `openProjectBundle_${project?.bundle?.id}`,
				component: ProjectPage,
				icon: null,
				sidebar: true,
				views: [viewProject]
			})
		} else {
			changedRoutes[routeIndex].views.push(viewProject)
		}
	}

	return changedRoutes
}

// keep showSideBar in localstorage if
// it's not provided form Url
export const getShowSideBarValue = (sideBar: string | boolean | null) => {
	let showSideBar = sideBar

	//always show sideBar if we go to Upload page param wasn't sent
	if (isNull(sideBar) && window.location.href?.includes(UPLOAD_ROUTE)) {
		showSideBar = true
	}

	//verify if new param is empty
	const emptyParam =
		isNull(showSideBar) || (isString(showSideBar) && isEmpty(showSideBar))

	//take data from localstorage
	const showSideBarFromLocalStorage =
		getStringItemFromLocalStorage('showSideBar')

	//verify if param is empty
	//and param in localstorage is not empty
	if (
		emptyParam &&
		showSideBarFromLocalStorage !== UNDEFINED_STRING &&
		!isEmpty(showSideBarFromLocalStorage)
	) {
		//take param from LS
		showSideBar = showSideBarFromLocalStorage
	} else {
		//update param
		setStringItemToLocalStorage('showSideBar', showSideBar?.toString() || '')
	}

	return showSideBar
}

export const getUserHomePermittedRoutes = (
	routes: Route[],
	roles: Number[]
) => {
	let disableRoute: number | undefined
	return routes.map(route => {
		if (route.disablePermission) {
			disableRoute = route.disablePermission.find((permission: Number) => {
				return roles?.some(role => role === permission)
			})
			route = {
				...route,
				component: !!disableRoute ? Empty : route.component
			}
		}

		return route
	})
}

export const userFoldersToRoutes = (
	userProjectFolders: IUserProjectFolder[],
	parentFolderId: string | null = null
) => {
	let result = [] as Record<string, any>
	for (let folder of userProjectFolders) {
		if (folder.parentFolderId === parentFolderId) {
			let nestedFolder = {
				collapse: true,
				name: folder.name,
				state: `open_user_folder_${folder.id}`,
				component: ProjectPage,
				icon: Folder,
				isInHome: true,
				views: userFoldersToRoutes(userProjectFolders, folder.id),
				sidebar: true,
				exact: true,
				id: folder.id,
				userFolder: true,
				createdAt: folder.createdAt,
				orderDate: folder.orderDate
			}
			result.push(nestedFolder)
		}
	}
	return result
}

export const sortProjectRoutes = (a: Route, b: Route) => {
	const aHasOrderDate = a.orderDate !== null
	const bHasOrderDate = b.orderDate !== null

	if (!aHasOrderDate && bHasOrderDate) {
		return -1
	} else if (aHasOrderDate && !bHasOrderDate) {
		return 1
	} else {
		const sortBy = aHasOrderDate ? 'orderDate' : 'createdAt'
		return new Date(b[sortBy]).valueOf() - new Date(a[sortBy]).valueOf()
	}
}

export const projectsToFolders = (
	folderRoutes: Route[],
	projectRoutes: Route[]
) => {
	projectRoutes.forEach(projectRoute => {
		if (!projectRoute.userProjectFolderId) {
			return folderRoutes.push(projectRoute)
		} else {
			folderRoutes = findProjectFolder(folderRoutes, projectRoute)
		}
	})

	return folderRoutes
}

export const findProjectFolder = (folderRoutes: Route[], project: Route) => {
	const folders = Array.from(folderRoutes)
	const folderMap = new Map(
		folderRoutes.map(folderRoute => [folderRoute.id, folderRoute])
	) as Map<string, Route>

	if (folderMap.has(project.userProjectFolderId)) {
		const folder = folderMap.get(project.userProjectFolderId)
		if (folder) {
			if (!folder?.views.length) {
				folder.views = []
			}
			folder.views.push(project)
		}
	} else {
		folders.forEach(folderRoute => {
			if (!folderRoute.userFolder) {
				return
			}
			findProjectFolder(folderRoute.views, project)
		})
	}

	return folders
}

export const sortProjectRoutesNested = (routes: Route[]) => {
	routes.map(route => {
		if (route.views?.length) {
			return route.views.sort(sortProjectRoutes)
		}
		return route
	})
	return routes.sort(sortProjectRoutes)
}
