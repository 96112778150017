import React, { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { goToNextStep } from '../../OnboardingWizardActions'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import { getString } from 'Services/Strings/StringService'

const StartButton: FC = () => {
	const { parentSteps, currentStep, nextStepLoading } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const dispatch = useDispatch()

	return (
		<ButtonWithLoader
			disabled={false}
			color="primaryNewStyle"
			loading={nextStepLoading}
			onClick={() => dispatch(goToNextStep(parentSteps, currentStep))}
			className="start-screen--button"
			qaDataElementName="data-qa-start-onboarding-btn"
		>
			{getString('ONBOARDING_START_SCREEN_START_BUTTON_TEXT')}
		</ButtonWithLoader>
	)
}

export default memo(StartButton)
