import { getString } from '../../../../Services/Strings/StringService'

export const ADVANCED = 'advanced'
export const BASIC = 'basic'
export const MIN_BATCH = 50
export const MIN_DEFAULT_YEARS = 1
export const MIN_YEARS = 5

export const PRINTER_TECHNOLOGY = 'printerTechnology'
export const USE_RELEVANT_FOR_PRINTER_MATERIALS =
	'useRelevantForPrinterMaterials'

export const objectives = [
	{
		name: getString('NEW_PART_CONFIGURATION_OBJECTIVES_BASIC'),
		value: BASIC,
		yearsOfDemand: MIN_DEFAULT_YEARS,
		quantity: MIN_DEFAULT_YEARS,
		fullTrayAssumption: false,
		titleText: getString('SEND_TO_SERVICE_BUREAU_QUANTITY_LABEL')
	},
	{
		name: getString('NEW_PART_CONFIGURATION_OBJECTIVES_ADVANCED'),
		value: ADVANCED,
		yearsOfDemand: MIN_YEARS,
		quantity: MIN_BATCH,
		fullTrayAssumption: true,
		titleText: getString('MATERIAL_QUANTITY_RIBBON_QUANTITY')
	}
]
