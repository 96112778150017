export const userPrinterMaterialFormName = 'userPrinterMaterialFormState'
export const initialPrinterMaterialState = {
	name: '',
	subCategory: '',
	type: '',
	category: '',
	printers: '',
	printerCompany: '',
	youngsModulusExt: '',
	ultimateTensileStrengthExt: '',
	yieldStrengthMPaExt: '',
	printerTechnology: '',
	description: '',
	wallThickness: '',
	costPerCubicCM: '',
	co2perKgMaterial: '',
	poissonRatioExt: '',
	density: '',
	heatTreatment: false,
	euReach: true,
	rohs: true
}
