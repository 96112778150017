import React, { FC } from 'react'
import cx from 'classnames'

import { getString } from 'Services/Strings/StringService'

interface BetaProps {
  selected: boolean
}

const Beta: FC<BetaProps> = ({ selected }) => {
  return <div className={cx('beta-tag', { 'active': selected })}>
    {getString('NEW_UPLOAD_BETA')}
  </div>
}

export default Beta
