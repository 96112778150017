import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { cloneDeep } from 'lodash'

import { clearCustomFunction } from '../CustomizeCostingFunctionEditor/CostingFunctionEditorActions'
import {
	findTechnologyName,
	getUserReadableFunctionString
} from '../CustomizeCostingFunctionEditor/CostingFunctionEditorService'
import { IFunctionVariable } from '../CustomizeCostingFunctionEditor/CustomizeCostingFunctionTypes'
import {
	cncPostProcesses,
	FUNCTION_EDITOR_ALERT_TYPE,
	getReducerStateId,
	POST_PROCESS_ACTION_BUTTON_TYPE,
	PP_CALCULATION_TYPE
} from './Constants'
import {
	changePostProcess,
	closeFunctionEditorModal,
	openFunctionEditorModal,
	removeCustomizedPostProcess,
	saveCustomFunction,
	saveCustomizedPostProcess
} from './CustomizePostProcessesActions'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { IFunctionStringParam } from 'Services/models/IFunctionStringParam'
import { MaterialTypeEnum } from 'Services/models/IMaterial'
import { IPostProcess } from 'Services/models/IPostProcess'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'
import { YES } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export const useCustomizePostProcessFunctions = (
	printingTechnologies: IPrintingTechnology[],
	postProcess = {} as IPostProcess,
	newInput: boolean,
	postProcessesInitialValues: IPostProcess[],
	allFunctionStringParams: IFunctionStringParam[]
) => {
	const [name, setName] = useState(postProcess.name)
	const [costCalculationValue, setCostCalculationValue] = useState(
		postProcess.costCalculationValue
	)
	const [leadTimeCalculationValue, setLeadTimeCalculationValue] = useState(
		postProcess.leadTimeCalculationValue
	)
	const dispatch = useDispatch()

	const costReducerStateID = getReducerStateId(
		postProcess.postProcessId,
		FUNCTION_EDITOR_ALERT_TYPE.COST
	)

	const leadTimeReducerStateID = getReducerStateId(
		postProcess.postProcessId,
		FUNCTION_EDITOR_ALERT_TYPE.LEAD_TIME
	)

	useEffect(() => {
		// on component unmount
		return () => {
			dispatch(clearCustomFunction(costReducerStateID))
			dispatch(clearCustomFunction(leadTimeReducerStateID))
		}
	}, [
		costReducerStateID,
		dispatch,
		leadTimeReducerStateID,
		postProcess.postProcessId
	])

	useEffect(() => {
		if (name !== postProcess.name) {
			setName(postProcess.name)
		}

		if (costCalculationValue !== postProcess.costCalculationValue) {
			setCostCalculationValue(postProcess.costCalculationValue)
		}

		if (leadTimeCalculationValue !== postProcess.leadTimeCalculationValue) {
			setLeadTimeCalculationValue(postProcess.leadTimeCalculationValue)
		}
	}, [
		postProcess.costCalculationValue,
		postProcess.leadTimeCalculationValue,
		postProcess.name
	])

	useEffect(() => {
		const onNameChange = (value: string) => {
			postProcess.name = value
			dispatch(changePostProcess(postProcess))
		}
		let delayDebounceFn: NodeJS.Timeout
		if (name !== postProcess.name) {
			const delayTime = 500
			delayDebounceFn = setTimeout(() => {
				onNameChange(name)
			}, delayTime)
		}

		return () => clearTimeout(delayDebounceFn)
	}, [dispatch, name, postProcess, postProcess.name])

	useEffect(() => {
		const onCostCalculationValueChange = (value: string | null) => {
			const post = cloneDeep(postProcess)
			post.costCalculationValue = value
			dispatch(changePostProcess(post))
		}
		let delayDebounceFn: NodeJS.Timeout
		if (costCalculationValue !== postProcess.costCalculationValue) {
			const delayTime = 500
			delayDebounceFn = setTimeout(() => {
				onCostCalculationValueChange(costCalculationValue)
			}, delayTime)
		}
		return () => clearTimeout(delayDebounceFn)
	}, [costCalculationValue, dispatch, postProcess])

	useEffect(() => {
		const onLeadTimeValueChange = (value: string | null) => {
			const post = cloneDeep(postProcess)
			post.leadTimeCalculationValue = value
			dispatch(changePostProcess(post))
		}

		let delayDebounceFn: NodeJS.Timeout
		if (leadTimeCalculationValue !== postProcess.leadTimeCalculationValue) {
			const delayTime = 500
			delayDebounceFn = setTimeout(() => {
				onLeadTimeValueChange(leadTimeCalculationValue)
			}, delayTime)
		}

		return () => clearTimeout(delayDebounceFn)
	}, [dispatch, leadTimeCalculationValue, postProcess])

	const disabledMenuItem = getString('CHOOSE')

	const initialPostProcess = useMemo(
		() =>
			postProcessesInitialValues.find(
				pp => pp.id === postProcess.id
			) as IPostProcess,
		[postProcess.id, postProcessesInitialValues]
	)

	const allPrintingTechnologies = useMemo(
		() => printingTechnologies?.map(technology => technology.name),
		[printingTechnologies]
	)

	const renderPrintingTechnologyValue = useCallback(
		(selected?: unknown) =>
			postProcess?.printingTechnologies?.length === 0
				? getString('SELECT')
				: postProcess?.printingTechnologies?.length ===
				  allPrintingTechnologies.length
				? getString('ALL')
				: postProcess?.printingTechnologies?.length === 1
				? findTechnologyName(
						printingTechnologies,
						postProcess?.printingTechnologies?.[0]
				  )
				: getString('PRINTING_TECHNOLOGIES_SELECTED').format(
						postProcess?.printingTechnologies?.length
				  ),
		[
			allPrintingTechnologies.length,
			postProcess?.printingTechnologies,
			printingTechnologies
		]
	)

	const handlePrintingTechnologyChange = useCallback(
		(e: React.ChangeEvent<any>) => {
			if (
				postProcess?.printingTechnologies?.length === 1 &&
				e.target.value.length === 0
			)
				return
			postProcess.printingTechnologies = e.target.value as string[]
			dispatch(changePostProcess(postProcess))
		},
		[dispatch, postProcess]
	)

	const handleAllTechnologiesClick = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation()
			if (cncPostProcesses.includes(postProcess.id as number)) {
				const selectedPrintingTechnologies = printingTechnologies.reduce(
					(acc: string[], technology: IPrintingTechnology) => {
						if (technology.type !== MaterialTypeEnum.plastic) {
							acc.push(technology.name)
						}
						return acc
					},
					[]
				)
				postProcess.printingTechnologies = selectedPrintingTechnologies
			} else {
				postProcess.printingTechnologies = allPrintingTechnologies
			}
			dispatch(changePostProcess(postProcess))
		},
		[allPrintingTechnologies, dispatch, postProcess, printingTechnologies]
	)

	const handleOnlyTechnologyClick = useCallback(
		(e: React.MouseEvent<HTMLDivElement>, technologyName: string) => {
			e.stopPropagation()
			postProcess.printingTechnologies = [technologyName]
			dispatch(changePostProcess(postProcess))
		},
		[dispatch, postProcess]
	)

	const renderToggleValue = (selected?: unknown) => {
		const selectedOption = postProcess.onOptions?.find(
			option => option.value === selected
		)
		return getString(selectedOption?.stringKey as string) || disabledMenuItem
	}

	const handleToggleChange = (value: boolean) => {
		postProcess.on = value
		dispatch(changePostProcess(postProcess))
	}

	const renderCostCalculationTypeValue = (selected?: unknown) => {
		const selectedOption = postProcess.costCalculationOptions?.find(
			option => option.value === selected
		)
		return getString(selectedOption?.stringKey as string) || disabledMenuItem
	}

	const handleCostCalculationTypeChange = (value: string | null) => {
		postProcess.costCalculationType = value
		postProcess.costCalculationValue = ''
		dispatch(changePostProcess(postProcess))
		if (value === PP_CALCULATION_TYPE.FUNCTION) {
			dispatch(
				openFunctionEditorModal(postProcess, FUNCTION_EDITOR_ALERT_TYPE.COST)
			)
		}
	}

	const renderLeadTimeTypeValue = (selected?: unknown) => {
		const selectedOption = postProcess.leadTimeOptions?.find(
			option => option.value === selected
		)
		return getString(selectedOption?.stringKey as string) || disabledMenuItem
	}

	const handleLeadTimeTypeChange = (value: string | null) => {
		postProcess.leadTimeCalculationType = value
		postProcess.leadTimeCalculationValue = ''
		dispatch(changePostProcess(postProcess))
	}

	const onCostCalculationOptionClick = (
		value: string | null,
		alertType: string
	) => {
		if (value === PP_CALCULATION_TYPE.FUNCTION) {
			const costCalculationFunctionExists =
				initialPostProcess?.costCalculationValue &&
				initialPostProcess.costCalculationValue ===
					postProcess.costCalculationValue
			const leadTimeCalculationFunctionExists =
				initialPostProcess?.leadTimeCalculationValue &&
				initialPostProcess.leadTimeCalculationValue ===
					postProcess.leadTimeCalculationValue
			if (
				postProcess.organizationId &&
				alertType === FUNCTION_EDITOR_ALERT_TYPE.COST
					? costCalculationFunctionExists
					: leadTimeCalculationFunctionExists
			) {
				dispatch({
					type: ALERT_POPPED,
					payload: {
						text: getString('CHANGING_PP_FUNCTION_ALERT_DESCRIPTION'),
						headerTitle: getString('CONFIGURATION_CHANGES_WARNING'),
						alertType: AlertType.WARNING,
						onConfirm: () => {
							dispatch(openFunctionEditorModal(postProcess, alertType))
							dispatch({
								type: ALERT_POPUP_CANCELED
							})
						},
						confirmText: YES
					}
				})
			} else {
				dispatch(openFunctionEditorModal(postProcess, alertType))
			}
		}
	}

	const getActionButtonType = () => {
		return newInput
			? [POST_PROCESS_ACTION_BUTTON_TYPE.ADD]
			: postProcess.editMode
			? [
					POST_PROCESS_ACTION_BUTTON_TYPE.CANCEL,
					POST_PROCESS_ACTION_BUTTON_TYPE.APPLY
			  ]
			: postProcess.organizationId && postProcess.custom
			? [POST_PROCESS_ACTION_BUTTON_TYPE.REMOVE]
			: postProcess.organizationId && !postProcess.custom
			? [POST_PROCESS_ACTION_BUTTON_TYPE.TO_DEFAULT]
			: [POST_PROCESS_ACTION_BUTTON_TYPE.EDIT]
	}

	const getButtonDisabled = (
		actionType: keyof typeof POST_PROCESS_ACTION_BUTTON_TYPE
	) => {
		const disabled = newInput
			? !postProcess.name ||
			  (!postProcess.costCalculationValue &&
					!postProcess.leadTimeCalculationValue) ||
			  !postProcess.printingTechnologies?.length
			: actionType === POST_PROCESS_ACTION_BUTTON_TYPE.CANCEL
			? false
			: postProcess.editMode && !postProcess.changesPerformed
		return Boolean(disabled)
	}

	const handleButtonClick = (
		actionType: keyof typeof POST_PROCESS_ACTION_BUTTON_TYPE
	) => {
		switch (actionType) {
			case POST_PROCESS_ACTION_BUTTON_TYPE.ADD:
			case POST_PROCESS_ACTION_BUTTON_TYPE.APPLY: {
				return dispatch(saveCustomizedPostProcess(postProcess))
			}
			case POST_PROCESS_ACTION_BUTTON_TYPE.CANCEL: {
				const initial = cloneDeep(initialPostProcess)

				initial.costCalculationValue = ''
				initial.leadTimeCalculationValue = ''
				setCostCalculationValue('')
				setLeadTimeCalculationValue('')

				dispatch(changePostProcess(initial, false))
				dispatch(clearCustomFunction(costReducerStateID))
				dispatch(clearCustomFunction(leadTimeReducerStateID))
				break
			}
			case POST_PROCESS_ACTION_BUTTON_TYPE.EDIT: {
				postProcess.editMode = true
				return dispatch(changePostProcess(postProcess, false))
			}
			case POST_PROCESS_ACTION_BUTTON_TYPE.TO_DEFAULT:
			case POST_PROCESS_ACTION_BUTTON_TYPE.REMOVE: {
				return dispatch(removeCustomizedPostProcess(postProcess))
			}
		}
	}

	const getValuePlaceholder = (
		calculationType: string | null,
		calculationValue: string | null
	) => {
		return calculationType === PP_CALCULATION_TYPE.FUNCTION && calculationValue
			? getUserReadableFunctionString(calculationValue, allFunctionStringParams)
			: ''
	}

	const getValueTitle = (
		calculationType: string | null,
		calculationValue: string | null
	) => {
		return calculationType === PP_CALCULATION_TYPE.FUNCTION && calculationValue
			? getUserReadableFunctionString(calculationValue, allFunctionStringParams)
			: calculationValue || ''
	}

	const switchToEditMode = () => {
		if (!postProcess.editMode) {
			handleButtonClick(POST_PROCESS_ACTION_BUTTON_TYPE.EDIT)
		}
	}

	return {
		renderPrintingTechnologyValue,
		handlePrintingTechnologyChange,
		handleAllTechnologiesClick,
		handleOnlyTechnologyClick,
		renderToggleValue,
		handleToggleChange,
		renderCostCalculationTypeValue,
		handleCostCalculationTypeChange,
		renderLeadTimeTypeValue,
		handleLeadTimeTypeChange,
		onCostCalculationOptionClick,
		getActionButtonType,
		getButtonDisabled,
		handleButtonClick,
		name,
		setName,
		costCalculationValue,
		setCostCalculationValue,
		leadTimeCalculationValue,
		setLeadTimeCalculationValue,
		getValuePlaceholder,
		getValueTitle,
		switchToEditMode
	}
}

export const useFunctionEditorModal = (
	postProcess = {} as IPostProcess,
	functionEditorAlertType: string
) => {
	const dispatch = useDispatch()

	const getModalDetails = useCallback(
		(modalType: string) => {
			let modalHeader = ''
			let functionCategory = ''
			switch (modalType) {
				case FUNCTION_EDITOR_ALERT_TYPE.COST: {
					const costFunctionEditorOption =
						postProcess.costCalculationOptions?.find(
							option => option.value === PP_CALCULATION_TYPE.FUNCTION
						)

					modalHeader = getString(costFunctionEditorOption?.stringKey as string)
					functionCategory = postProcess.costFunctionStringCategory
					break
				}
				case FUNCTION_EDITOR_ALERT_TYPE.LEAD_TIME: {
					const leadTimeFunctionEditorOption =
						postProcess.leadTimeOptions?.find(
							option => option.value === PP_CALCULATION_TYPE.FUNCTION
						)

					modalHeader = getString(
						leadTimeFunctionEditorOption?.stringKey as string
					)
					functionCategory = postProcess.leadTimeFunctionStringCategory
					break
				}
			}
			return { modalHeader, functionCategory }
		},
		[
			postProcess.costCalculationOptions,
			postProcess.costFunctionStringCategory,
			postProcess.leadTimeFunctionStringCategory,
			postProcess.leadTimeOptions
		]
	)

	const { modalHeader, functionCategory } = useMemo(
		() => getModalDetails(functionEditorAlertType),
		[functionEditorAlertType, getModalDetails]
	)

	const cancelModal = () => {
		switch (functionEditorAlertType) {
			case FUNCTION_EDITOR_ALERT_TYPE.COST: {
				if (!postProcess.costCalculationValue) {
					postProcess.costCalculationType = null
				}
				break
			}
			case FUNCTION_EDITOR_ALERT_TYPE.LEAD_TIME: {
				if (!postProcess.leadTimeCalculationValue) {
					postProcess.leadTimeCalculationType = null
				}
				break
			}
			default:
				break
		}

		dispatch(changePostProcess(postProcess))
		dispatch(closeFunctionEditorModal())
	}

	const handleApply = (customFunction: IFunctionVariable[]) => {
		dispatch(
			saveCustomFunction(postProcess, customFunction, functionEditorAlertType)
		)
	}

	return { cancelModal, handleApply, modalHeader, functionCategory }
}
