import React, { FC, memo } from 'react'

import { MenuItem, TextField } from '@material-ui/core'
import cx from 'classnames'
import { isEmpty } from 'lodash'

import InfoBox from '../../../../Components/InfoBox'

const TextFieldTSX: any = TextField
const MenuItemTSX: any = MenuItem

interface DropDownAxisProps {
	value: string
	dropDownArray: Array<any>
	onChangeValue: Function
	dropDownClass?: string
	explanation?: string
	disableArrow?: boolean
}

const DropDownAxis: FC<DropDownAxisProps> = ({
	value,
	dropDownArray,
	onChangeValue,
	dropDownClass,
	explanation,
	disableArrow
}) => {
	return (
		<div>
			<TextFieldTSX
				disabled={dropDownArray.length === 1}
				InputLabelProps={{ shrink: false }}
				select
				SelectProps={{
					autoFocus: false,
					MenuProps: {
						autoFocus: false,
						PopoverClasses: {
							root: 'dropdown'
						}
					}
				}}
				className={cx('axis-input', dropDownClass, {
					'without-arrow': disableArrow
				})}
				value={value}
				onChange={(e: any) => onChangeValue(e.target.value)}
			>
				{dropDownArray.map(xItem => {
					return (
						<MenuItemTSX key={xItem.name} value={xItem.value}>
							{xItem.name} {xItem.units}
						</MenuItemTSX>
					)
				})}
			</TextFieldTSX>
			{!isEmpty(explanation) && (
				<InfoBox
					boxContactElement={<div>{explanation}</div>}
					iconClassName="cost-details-icon"
				/>
			)}
		</div>
	)
}

export default memo(DropDownAxis)
