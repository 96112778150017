import React, { FC, memo, useEffect } from 'react'

import ItemsWithPicturesList from '../../Components/ItemsWithPicturesList'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { allClustersView } from 'Services/routeFuncs'
import { getString } from 'Services/Strings/StringService'


interface PrintableClustersOverviewProps {
	clusterItemsWithPictures: any[]
	projectIdentifier: string
	setupClustersOverview: Function
	projectId: string
}

const PrintableClustersOverview: FC<PrintableClustersOverviewProps> = ({
	clusterItemsWithPictures,
	projectIdentifier,
	setupClustersOverview,
	projectId
}) => {
	const disabledUnification = !Feature.isFeatureActive(
		FeatureComponentId.UNIFICATION
	)

	const mountHelper = () => {
		if (projectIdentifier) {
			setupClustersOverview(projectIdentifier)
		} else if (projectId) {
			setupClustersOverview(projectId)
		}
	}

	useEffect(() => {
		mountHelper()
	}, [])

	useEffect(() => {
		setupClustersOverview(projectId)
	}, [projectId])

	return (
		<DetailsPopup
			isHover={disabledUnification}
			data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
			popperDirection="top-start"
			popperClassName="info-box-wrapper details-popup--contact-us"
			popperContactClassName="info-box-data"
			targetClassName="all-clusters--popup"
		>
			<ItemsWithPicturesList
				items={clusterItemsWithPictures}
				seeMoreURL={allClustersView(projectIdentifier)}
				isCluster={true}
				disabled={disabledUnification}
			/>
		</DetailsPopup>
	)
}

export default memo(PrintableClustersOverview)