import { REQUIRED } from '../../../Services/Strings'
import {
	Cast,
	CNC,
	co2perKgMaterial,
	density,
	maximumServiceTemperature,
	Mold,
	percentElongationAtBreak,
	surfaceFinish,
	thermalConductivity,
	ultimateTensileStrength,
	yieldStrengthMPa,
	youngsModulus
} from './constants'
import {
	IMaterialCategory,
	Material,
	MaterialTypeEnum
} from 'Services/models/IMaterial'
import { getString } from 'Services/Strings/StringService'

export const validate = (values: Record<string, any>) => {
	let errors = {} as Record<string, any>
	if (!values.name || !values.name.trim()) {
		errors.name = REQUIRED
	}
	if (!values.category || !values.category.trim()) {
		errors.category = REQUIRED
	}
	if (!values.type) {
		errors.type = REQUIRED
	}
	if (!values.yieldStrengthMPa) {
		errors.yieldStrengthMPa = REQUIRED
	}
	if (
		values.yieldStrengthMPa &&
		(+values.yieldStrengthMPa <= yieldStrengthMPa.min ||
			+values.yieldStrengthMPa > yieldStrengthMPa.max)
	) {
		errors.yieldStrengthMPa = getString(
			'NUMBER_VALIDATION_REQUIRED_NOT_INCLUDING_ZERO'
		).format(yieldStrengthMPa.min, yieldStrengthMPa.max)
	}
	if (!values.density) {
		errors.density = REQUIRED
	}
	if (
		values.density &&
		(+values.density <= density.min || +values.density > density.max)
	) {
		errors.density = getString(
			'NUMBER_VALIDATION_REQUIRED_NOT_INCLUDING_ZERO'
		).format(density.min, density.max)
	}
	if (!values.ultimateTensileStrength) {
		errors.ultimateTensileStrength = REQUIRED
	}
	if (
		values.ultimateTensileStrength &&
		(+values.ultimateTensileStrength <= ultimateTensileStrength.min ||
			+values.ultimateTensileStrength > ultimateTensileStrength.max)
	) {
		errors.ultimateTensileStrength = getString(
			'NUMBER_VALIDATION_REQUIRED_NOT_INCLUDING_ZERO'
		).format(ultimateTensileStrength.min, ultimateTensileStrength.max)
	}
	if (
		!values.maximumServiceTemperature &&
		values.type === MaterialTypeEnum.plastic
	) {
		errors.maximumServiceTemperature = REQUIRED
	}
	if (
		values.maximumServiceTemperature &&
		(+values.maximumServiceTemperature <= maximumServiceTemperature.min ||
			+values.maximumServiceTemperature > maximumServiceTemperature.max)
	) {
		errors.maximumServiceTemperature = getString(
			'NUMBER_VALIDATION_REQUIRED_NOT_INCLUDING_ZERO'
		).format(maximumServiceTemperature.min, maximumServiceTemperature.max)
	}

	if (!values.thermalConductivity && values.type === MaterialTypeEnum.metal) {
		errors.thermalConductivity = REQUIRED
	}
	if (
		values.thermalConductivity &&
		(+values.thermalConductivity <= thermalConductivity.min ||
			+values.thermalConductivity > thermalConductivity.max)
	) {
		errors.thermalConductivity = getString(
			'NUMBER_VALIDATION_REQUIRED_NOT_INCLUDING_ZERO'
		).format(thermalConductivity.min, thermalConductivity.max)
	}
	if (!values.surfaceFinish) {
		errors.surfaceFinish = REQUIRED
	}
	if (values.surfaceFinish && +values.surfaceFinish <= surfaceFinish.min) {
		errors.surfaceFinish = getString('NUMBER_VALIDATION_FROM_REQUIRED').format(
			surfaceFinish.min
		)
	}
	if (!values.surfaceFinishMethod) {
		errors.surfaceFinishMethod = REQUIRED
	}
	if (!values.percentElongationAtBreak) {
		errors.percentElongationAtBreak = REQUIRED
	}
	if (
		values.percentElongationAtBreak &&
		(+values.percentElongationAtBreak <= percentElongationAtBreak.min ||
			+values.percentElongationAtBreak > percentElongationAtBreak.max)
	) {
		errors.percentElongationAtBreak = getString(
			'NUMBER_VALIDATION_REQUIRED_NOT_INCLUDING_ZERO'
		).format(percentElongationAtBreak.min, percentElongationAtBreak.max)
	}
	if (!values.youngsModulus) {
		errors.youngsModulus = REQUIRED
	}
	if (
		values.youngsModulus &&
		(+values.youngsModulus <= youngsModulus.min ||
			+values.youngsModulus > youngsModulus.max)
	) {
		errors.youngsModulus = getString(
			'NUMBER_VALIDATION_REQUIRED_NOT_INCLUDING_ZERO'
		).format(youngsModulus.min, youngsModulus.max)
	}
	if (!values.co2perKgMaterial) {
		errors.co2perKgMaterial = REQUIRED
	}
	if (
		values.co2perKgMaterial &&
		(+values.co2perKgMaterial <= co2perKgMaterial.min ||
			+values.co2perKgMaterial > co2perKgMaterial.max)
	) {
		errors.co2perKgMaterial = getString(
			'NUMBER_VALIDATION_REQUIRED_NOT_INCLUDING_ZERO'
		).format(co2perKgMaterial.min, co2perKgMaterial.max)
	}

	if (
		values.traditionalCost?.CNC &&
		(+values.traditionalCost.CNC <= CNC.min ||
			+values.traditionalCost.CNC > CNC.max)
	) {
		errors = {
			...errors,
			traditionalCost: {
				...errors?.traditionalCost,
				CNC: getString('NUMBER_VALIDATION_NOT_INCLUDING_ZERO').format(
					CNC.min,
					CNC.max
				)
			}
		}
	}
	if (
		values.traditionalCost?.Cast &&
		(+values.traditionalCost.Cast <= Cast.min ||
			+values.traditionalCost.Cast > Cast.max)
	) {
		errors = {
			...errors,
			traditionalCost: {
				...errors?.traditionalCost,
				Cast: getString('NUMBER_VALIDATION_NOT_INCLUDING_ZERO').format(
					Cast.min,
					Cast.max
				)
			}
		}
	}
	if (
		values.traditionalCost?.Mold &&
		(+values.traditionalCost.Mold <= Mold.min ||
			+values.traditionalCost.Mold > Mold.max)
	) {
		errors = {
			...errors,
			traditionalCost: {
				...errors?.traditionalCost,
				Mold: getString('NUMBER_VALIDATION_NOT_INCLUDING_ZERO').format(
					Mold.min,
					Mold.max
				)
			}
		}
	}
	return errors
}

export const resetForm = (
	reset: () => void,
	allMaterialCategories: IMaterialCategory[],
	onMaterialTypeChange: (
		value: string,
		allMaterialCategories: IMaterialCategory[]
	) => void,
	initialValues: Record<string, any>
) => {
	reset()
	onMaterialTypeChange(
		initialValues ? initialValues.type : '',
		allMaterialCategories
	)
}

export const onChangeCertificatedValue = (
	change: (name: string, value: any) => void,
	filters: Record<string, any>,
	name: string,
	value: boolean
) => {
	const updatedFilters = {
		...filters,
		[name]: value
	}
	change('defaultFilters', updatedFilters)
}

export const getAverageByCategory = (materials: Material[]) => {
	let averageByCategories = {} as Record<string, any>
	for (const material of materials) {
		const categoryForAverage = averageByCategories[material.category] || {
			count: 0,
			sum: 0
		}
		categoryForAverage.count++
		categoryForAverage.sum = material.co2perKgMaterial + categoryForAverage.sum
		averageByCategories[material.category] = categoryForAverage
	}
	for (const categoryForAverage in averageByCategories) {
		averageByCategories[categoryForAverage].average = parseFloat(
			(averageByCategories[categoryForAverage].sum /
				averageByCategories[categoryForAverage].count) as any
		).toFixed(2)
	}
	return averageByCategories
}

export const materialTypeChange = (
	e: React.ChangeEvent<HTMLInputElement>,
	change: (name: string, value: string | null) => void,
	onMaterialTypeChange: (
		value: string,
		allMaterialCategories: IMaterialCategory[]
	) => void,
	allMaterialCategories: IMaterialCategory[]
) => {
	change('type', e.target.value)
	onMaterialTypeChange(e.target.value, allMaterialCategories)
	change('category', '')
}
