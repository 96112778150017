import React, { FC } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { IFileWithMaterialAndQuantity } from 'Services/models/IUploadProject'

type IProps = {
	state: IFileWithMaterialAndQuantity
	onMaterialCategoryChange: (value: string) => void
	categoriesList: string[]
	disabledMenuItem: string
}

const MaterialCategory: FC<IProps> = ({
	state,
	onMaterialCategoryChange,
	categoriesList,
	disabledMenuItem
}) => {
	return (
		<Select
			displayEmpty
			disabled={!state.materialType}
			className={'file-row--select-field'}
			value={state.materialCategory}
			renderValue={selected =>
				(selected as React.ReactNode) || disabledMenuItem
			}
			onChange={(event: any) => onMaterialCategoryChange(event.target.value)}
			classes={{
				select: 'file-row--select'
			}}
			inputProps={{
				name: 'simpleSelect',
				id: 'simple-select'
			}}
			MenuProps={{
				PaperProps: {
					style: {
						transform: 'translate3d(0, 0, 0)'
					}
				}
			}}
		>
			<MenuItem disabled>{disabledMenuItem}</MenuItem>
			{categoriesList.map((category: string) => {
				return (
					<MenuItem
						key={category}
						style={{ textTransform: 'capitalize' }}
						value={category}
					>
						{category}
					</MenuItem>
				)
			})}
		</Select>
	)
}

export default MaterialCategory
