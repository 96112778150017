export const styles = {
	textField: {
		width: 250
	},
	submitButtonStyle: {
		margin: 15,
		alignSelf: 'center'
	},
	errorTextStyle: {
		fontSize: 15,
		alignSelf: 'center',
		color: 'red',
		marginTop: 10
	},
	paperStyle: {
		margin: 200,
		textAlign: 'center',
		display: 'inline-block'
	},
	formWrapper: {
		margin: 15
	},
	logoImage: {
		marginTop: 10,
		width: 180
	},
	loginImage: {
		width: 24,
		height: 24
	},
	lineStyle: {
		borderWidth: 0.5,
		borderColor: 'black',
		margin: 10
	},
	subtitle: {
		alignSelf: 'center',
		color: 'gray'
	},
	divider: {
		width: '90%',
		margin: '0 auto',
		color: 'black',
		marginTop: 19,
		marginBottom: 18
	},
	successButton: {
		fontSize: 16,
		color: '#ffffff',
		width: 120,
		height: 40,
		backgroundColor: '#5ab867',
		boxShadow:
			'0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)',
		'&:hover': {
			backgroundColor: '#5ab867',
			boxShadow:
				'0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)'
		}
	},
	failedButton: {
		fontSize: 16,
		color: '#ffffff',
		width: 120,
		height: 40,
		backgroundColor: '#f4413f',
		boxShadow:
			'0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)',
		'&:hover': {
			backgroundColor: '#f4413f',
			boxShadow:
				'0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)'
		}
	}
}

export const resetStyle = {
	submitButtonStyle: {
		margin: 15,
		width: 400
	},
	errorTextStyle: {
		fontSize: 15,
		alignSelf: 'center',
		color: 'red'
	},
	logoImage: {
		margin: 10,
		width: 150,
		alighSelf: 'center'
	},
	infoBut: { width: 40, marginLeft: 3, marginRight: -80, marginTop: 6 },
	infoButHidden: {
		width: 40,
		marginLeft: 3,
		marginRight: -80,
		visibility: 'hidden'
	}
}
