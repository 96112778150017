import React, { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { LOGIN_ROUTE } from '../../../Services/Constants/RoutesConstants'
import { setCallbackURLInLocalStorage } from '../../../Services/LocalStorageService'
import { externalLogin } from '../../../Services/Network'
import Loader from '../../Loader/Loader'
import { REFRESH_TOKEN_UPDATED } from 'global actions/types'
import { gerUrlParams } from 'Services/Utils/urlTools'

interface IProps {}
const ExternalUserLogin: React.FC<IProps> = ({}) => {
	const dispatch = useDispatch()
	useEffect(() => {
		function setup() {
			const pathname = window.location.pathname
			const origin = window.location.origin
			const {
				userEmail,
				token,
				timestamp,
				back: callbackURL
			} = gerUrlParams(['userEmail', 'token', 'timestamp', 'back'])
			if (callbackURL) {
				setCallbackURLInLocalStorage(callbackURL)
			}
			const querySearch = { userEmail, token, timestamp }

			const externalUrl = `${origin}${pathname}`
			return {
				querySearch,
				externalUrl
			}
		}

		async function externalProjectLogin(querySearch: any, externalUrl: string) {
			try {
				const res = await externalLogin({ ui_url: externalUrl, ...querySearch })

				dispatch({
					type: REFRESH_TOKEN_UPDATED,
					payload: {
						refreshToken: res?.data?.refreshToken,
						token_type: res?.data?.token_type,
						refreshTokenExpireIn: res?.data?.refreshTokenExpireIn
					}
				})

				window.location.href = externalUrl.replace('external', 'home')
			} catch (err) {
				const Error = err as any
				console.log(Error)
				setTimeout(() => {
					//TODO : change msg to user , something went wrong
					window.location.href = LOGIN_ROUTE
				}, 4000)
			}
		}
		const { querySearch, externalUrl } = setup()
		externalProjectLogin(querySearch, externalUrl)
	}, [])
	return (
		<div>
			<Loader load={true} message={''} />
		</div>
	)
}

export default memo(ExternalUserLogin)
