import { getString } from 'Services/Strings/StringService'
import { Action } from '../../../global actions/ActionModels'
import {
  CASTOR_LOCKED_PARTS_REQUEST_SENT,
  CASTOR_LOCKED_PARTS_SENDING_REQUEST,
  CASTOR_LOCKED_PARTS_SET_TEXT
} from '../../../global actions/types'

export interface CastorLockedPartsInitialState {
  readonly show: boolean
  readonly showButton: boolean
  readonly disabledButton: boolean
  readonly buttonText: string
  readonly text: string
  readonly loadingCalculation: boolean
}

const initialState: CastorLockedPartsInitialState = {
  show: false,
  showButton: true,
  disabledButton: false,
  buttonText: getString('UPDATE_NOW') + '!',
  text: '',
  loadingCalculation: false
}

export default (state = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case CASTOR_LOCKED_PARTS_SET_TEXT: {
			const { buttonText, text } = payload
      return {
        ...state,
        buttonText,
        text
      }
    }
    case CASTOR_LOCKED_PARTS_SENDING_REQUEST: {
      return {
        ...state,
        loadingCalculation: true
      }
    }
    case CASTOR_LOCKED_PARTS_REQUEST_SENT: {
      return {
        ...state,
        loadingCalculation: false,
        buttonText: getString('LOCKED_PARTS_CARD_SENT_REQ_BUTTON_TEXT'),
        disabledButton: true
      }
    }
    default:
      return state
  }
}
