import { cloneDeep } from 'lodash'

import {
  EMISSION_EDIT_SELECTED_METHOD,
  EMISSION_EDIT_ALL_METHODS,
  EMISSION_TBL_DATA_ARRANGED,
  EMISSION_DATA_ERROR_METHODS,
  EMISSION_CHANGE_DATA_METHODS,
  EMISSION_UPDATED_FAILED_DATA_METHODS,
  EMISSION_UPDATED_SUCCESS_DATA_METHODS,
  EMISSION_UPDATED_LOADING_DATA_METHODS
} from '../../../../global actions/types'
import { CO2_NAMES, CO2_MANUFACTURING } from './constants'
import {
  checkOnDisableApply,
  checkOnResetAll,
  makeLabelsToMethod,
  setupValueLabels
} from './CustomizeCO2Service'

interface IState {
  settingsData: any
  co2Names: any
  co2Data: any
  co2InitialData: any
  isEditAll: boolean
  isResetAll: boolean
  isError: boolean
  isLoading: boolean
  errorMessage: any
  disableApplyAll: boolean
}

const INITIAL_STATE: IState = {
  settingsData: {},
  co2Names: CO2_NAMES,
  co2Data: CO2_MANUFACTURING,
  co2InitialData: CO2_MANUFACTURING,
  isEditAll: false,
  isResetAll: false,
  isLoading: false,
  isError: false,
  disableApplyAll: false,
  errorMessage: {}
}

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case EMISSION_TBL_DATA_ARRANGED: {
      const {
        settingsData = {},
        userSettings = {}
      } = action.payload

      const { defaultSettings = {} } = settingsData

      const valueLabels = setupValueLabels(userSettings, defaultSettings, CO2_NAMES, true)
      const co2Data = makeLabelsToMethod(valueLabels, CO2_MANUFACTURING)
      const isResetAll = checkOnResetAll(userSettings, defaultSettings)

      return {
        ...state,
        co2Data,
        isResetAll,
        settingsData,
        co2InitialData: co2Data
      }
    }

    case EMISSION_EDIT_SELECTED_METHOD: {
      const { co2Key, isEdit } = action.payload
      const changedCO2Data: any = cloneDeep(state.co2Data)

      changedCO2Data[co2Key].edit = isEdit

      if (isEdit === false) {
        changedCO2Data[co2Key].error = false
        changedCO2Data[co2Key].data = state.co2InitialData[co2Key].data
      }

      return {
        ...state,
        isEditAll: false,
        isError: false,
        co2Data: changedCO2Data
      }
    }

    case EMISSION_EDIT_ALL_METHODS: {
      const isEditAll = action.payload
      let changedCO2Data = cloneDeep(state.co2Data)

      if (!isEditAll) {
        changedCO2Data = state.co2InitialData
      }

      return {
        ...state,
        isError: false,
        co2Data: changedCO2Data,
        isEditAll
      }
    }

    case EMISSION_DATA_ERROR_METHODS: {
      const { co2Key, isError, errorMessage: error} = action.payload
      const { errorMessage } = state
      const changedCO2Data: any = cloneDeep(state.co2Data)

      changedCO2Data[co2Key].error = isError
      errorMessage[co2Key] = error

      const disableApplyAll: any = checkOnDisableApply(changedCO2Data)

      return {
        ...state,
        disableApplyAll,
        errorMessage: errorMessage,
        co2Data: changedCO2Data
      }
    }

    case EMISSION_CHANGE_DATA_METHODS: {
      const { co2Key, keyLabel, value } = action.payload
      const changedCO2Data: any = cloneDeep(state.co2Data)

      changedCO2Data[co2Key].data[keyLabel] = value
      changedCO2Data[co2Key].error = false

      const disableApplyAll: any = checkOnDisableApply(changedCO2Data)

      return {
        ...state,
        isError: false,
        disableApplyAll,
        co2Data: changedCO2Data
      }
    }

    case EMISSION_UPDATED_SUCCESS_DATA_METHODS: {
      const updatedData = action.payload?.params || {}
      const co2Key = action.payload?.co2Key || null
      const { defaultSettings = {} } = state.settingsData

      const valueLabels = setupValueLabels(updatedData, defaultSettings, CO2_NAMES, true)
      const changedCO2Data = makeLabelsToMethod(valueLabels, CO2_MANUFACTURING)
      const isResetAll = checkOnResetAll(updatedData, defaultSettings)

      let co2DataUpdate = changedCO2Data

      if (co2Key) {
        co2DataUpdate = {
          ...state.co2Data,
          [co2Key]: changedCO2Data[co2Key]
        }
      }

      // edit should stay for all no applied data
      if (state.isEditAll && co2Key) {
        for (const key in co2DataUpdate) {
          if (co2Key !== key) {
            co2DataUpdate[key] = {
              ...co2DataUpdate[key],
              edit: true
            }
          }
        }
      }

      return {
        ...state,
        isResetAll,
        isError: false,
        isEditAll: false,
        co2Data: co2DataUpdate
      }
    }

    case EMISSION_UPDATED_FAILED_DATA_METHODS: {
      return {
        ...state,
        isError: true
      }
    }

    case EMISSION_UPDATED_LOADING_DATA_METHODS: {
      return {
        ...state,
        isLoading: action.payload.isLoading
      }
    }

    default:
      return state
  }
}
