import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import typographyStyle from '../../variables/styles/typographyStyle.jsx'

function Danger({ ...props }) {
  const { classes, children, style, className } = props
  return (
    <div
      style={style}
      className={
        (className || '') +
        ' ' +
        classes.defaultFontStyle +
        ' ' +
        classes.dangerText
      }
    >
      {children}
    </div>
  )
}

Danger.propTypes = {
  classes: PropTypes.object.isRequired
}

export default memo(withStyles(typographyStyle)(Danger))
