import { getString } from 'Services/Strings/StringService'
import { Action } from '../../../../global actions/ActionModels'
import {
  FEATURE_TOGGLE_DATA_FETCHED,
  FEATURE_CONFIGURATION_CREATED,
  FEATURE_LIVE_ENVIRONMENT_UPDATED,
  FEATURE_CONFIGURATION_UPDATED
} from '../../../../global actions/types'

export class AdminFeatureToggleInitialState {
  readonly features: any = []
  readonly featureConfigurations: any = []
  environmentName: any = []
}

const initialState: AdminFeatureToggleInitialState =
  new AdminFeatureToggleInitialState()

const DEAFULT_ENV = getString('ADMIN_PANEL_FEATURE_TOGGLE_CURRENT_ENVIRONMENT')

export default (state = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case FEATURE_TOGGLE_DATA_FETCHED:
      const featuresData = payload.features
      const featuresConfigurationData = payload.featureConfigurations
      state.environmentName = []
      state.environmentName.push({ name: DEAFULT_ENV, id: 0 })
      featuresConfigurationData.map((data: any) => {
        state.environmentName.push({
          name: data.configuration_name,
          id: data.id
        })
      })
      return {
        ...state,
        features: featuresData,
        featureConfigurations: featuresConfigurationData,
        environmentName: state.environmentName
      }

    case FEATURE_LIVE_ENVIRONMENT_UPDATED: {
      const { featuresList } = payload
      return {
        ...state,
        features: featuresList
      }
    }

    case FEATURE_CONFIGURATION_UPDATED: {
      const { configuration } = payload
      const newFeatures = state.featureConfigurations.map(
        (configurationData: { id: any; features: any }) => {
          if (configurationData.id === configuration.id) {
            configurationData.features = configuration.features
          }
          return configurationData
        }
      )

      return {
        ...state,
        featureConfigurations: newFeatures
      }
    }

    case FEATURE_CONFIGURATION_CREATED: {
      const newConfiguration = payload.featureConfiguration
      const changedUserConfigurationFeatures = state.featureConfigurations
      changedUserConfigurationFeatures.push(newConfiguration)

      state.environmentName.push({
        name: newConfiguration.configuration_name,
        id: newConfiguration.id
      })

      return {
        ...state,
        featureConfigurations: changedUserConfigurationFeatures,
        environmentName: state.environmentName
      }
    }

    default:
      return state
  }
}
