import React, { memo, FC } from 'react'
import ButtonWithLoader from '../ButtonWithLoader'

import './DataTable.scss'

interface Props {
  text: any
  data: any
  className: string
}

const DataTableButtonWithLoader: FC<Props> = ({
  text,
  data: { onClick, disabled, buttonClassName, buttonLoading = false },
  className
}) => (
  <div className='data-table-button-with-loader'>
    <ButtonWithLoader
      className={`${buttonClassName} ${className}`}
      onClick={onClick}
      loading={buttonLoading}
      disabled={disabled}
    >
      {text}
    </ButtonWithLoader>
  </div>
)

export default memo(DataTableButtonWithLoader)
