export const ADLinks = [
	{
		teaserName: 'Additive Minds Academy Training',
		teaserHeadline: 'Identifying <br/> AM Applications',
		teaserButton: 'Sign Up Now',
		link: 'https://us.store.eos.info/collections/online-courses/products/identifying-am-applications',
		text:
			'<ul><li>Understand why choosing the RIGHT applications is' +
			' key.</li>' +
			' <li>Reduce' +
			' time to bring your additive manufacturing applications to' +
			' market.</li>' +
			' <li>Reduce cost per part for the identified applications.</li></ul>',
		image:
			'https://cdn.shopify.com/s/files/1/0550/9064/3121/products/Bild_product_Partscreening_selection_1000x.jpg?v=1632832288'
	},
	{
		teaserName: 'Additive Minds Academy Training',
		teaserHeadline: 'Design for Additive Manufacturing Metal ',
		teaserButton: 'Sign Up Now',
		link: 'https://us.store.eos.info/collections/online-courses/products/design-for-additive-manufacturing',
		text:
			'Design is one of the most important parts of the additive' +
			' manufacturing process chain. Without good design, every other stage' +
			' is unpredictable. <br/> <br/>This additive manufacturing module will teach' +
			' you the ins and outs of proper design procedure, enabling you to design high quality and efficient projects that minimise build time and maximise profit.',
		image:
			'https://cdn.shopify.com/s/files/1/0550/9064/3121/products/Innovation_07-Kopie_1000x.jpg?v=1632831843'
	},
	{
		teaserName: 'Additive Minds Academy Training',
		teaserHeadline: 'Design for Additive Manufacturing Polymer',
		teaserButton: 'Sign Up Now',
		link: 'https://us.store.eos.info/collections/online-courses/products/design-for-additive-manufacturing-polymer',
		text: 'Design is one of the most important parts of the additive manufacturing process chain. Without good design, every other stage is unpredictable. <br/> <br/>This additive manufacturing module will teach you the ins and outs of proper design procedure for polymer materials, enabling you to design high quality and efficient projects that minimise part weight, increasing part performance and foster functional integration as well as part consolidation.',
		image:
			'https://cdn.shopify.com/s/files/1/0550/9064/3121/products/EOS_App_LifeStyle_Bikeseat_EOSTPU1301_EOSP396_02_169_700x.jpg?v=1632831855'
	}
]
