import { getString } from 'Services/Strings/StringService'

export const assemblingCalculationType = {
	DEFAULT: 'default',
	TOTAL_TIME: 'totalTime',
	TOTAL_COST: 'totalCost'
}

export const assemblingCostOptions = [
	{
		type: assemblingCalculationType.DEFAULT,
		value: null,
		showInput: false,
		text: getString('ASSEMBLY_TYPE_PARAM_DEFAULT'),
		info: getString('ASSEMBLY_TYPE_PARAM_DEFAULT_INFO')
	},
	{
		type: assemblingCalculationType.TOTAL_COST,
		value: '',
		showInput: true,
		text: getString('ASSEMBLY_TYPE_PARAM_TOTAL_COST'),
		info: getString('ASSEMBLY_TYPE_PARAM_TOTAL_COST_INFO')
	},
	{
		type: assemblingCalculationType.TOTAL_TIME,
		value: '',
		showInput: true,
		text: getString('ASSEMBLY_TYPE_PARAM_TOTAL_TIME'),
		info: getString('ASSEMBLY_TYPE_PARAM_TOTAL_TIME_INFO')
	}
]
