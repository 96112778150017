export enum ProjectStatusPartsType {
	WAITING = 'WAITING',
	SINGLE_PART = 'SINGLE_PART',
	MULTIPLE_PART = 'MULTIPLE_PART'
}

export enum AdvancedFeatureStatus {
	DONE = 'DONE',
	WEIGHT_REDUCTION = 'WEIGHT_REDUCTION',
	WEIGHT_REDUCTION_AND_PART_CONSOLIDATION = 'WEIGHT_REDUCTION_AND_PART_CONSOLIDATION',
	PART_CONSOLIDATION = 'PART_CONSOLIDATION'
}
