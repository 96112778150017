import { CO2_NAMES, CO2_ORDER, ZERO_AMOUNT } from './constants'

export const makePercentage = (value: number) => Math.round(value * 100)
export const makeNumbers = (value: number) => (value / 100).toFixed(2)
export const isShipping = (key: string) => key.includes('Shipping')

export const checkIsDefaultValue = (
  data: any,
  key: string,
  defaultSettings: any
) => {
  const defaultData = !isShipping(key) ?
    makePercentage(defaultSettings[key]) : defaultSettings[key]

  return !data[key] && data[key] !== 0 || data[key] === defaultData
}


const sortObjectByKeys = (o: any) => {
  return Object.keys(o).sort((a: string, b: string) => {
    return CO2_ORDER.findIndex(element =>
      a.includes(element)) - CO2_ORDER.findIndex(element =>
      b.includes(element))
  }).reduce((r: any, k: any) => (r[k] = o[k], r), {})
}

export const makeLabelsToMethod = (labels: any, method: any) => {
  const methodData = method
  for (const labelKey in labels) {
    for (const methodKey in method) {
      if (labelKey.includes(methodKey)) {
        methodData[methodKey].error = false
        methodData[methodKey].edit = false
        methodData[methodKey].data[labelKey] = labels[labelKey] || ZERO_AMOUNT
      }
    }
  }

  for (const methodKey in methodData) {
    methodData[methodKey].data = sortObjectByKeys(methodData[methodKey].data)
  }

  return methodData
}

export const setupValueLabels = (
  userSettings: any = {},
  defaultSettings: any = {},
  co2Names: any,
  percentage: boolean
) => {
  let valueLabels: any = {}
  for (const key in defaultSettings) {
    if (co2Names.hasOwnProperty(key)) {
      const value = userSettings[key] || userSettings[key] === 0 ? userSettings[key]: defaultSettings[key]
      if (!isShipping(key) && percentage) {
        valueLabels[key] = makePercentage(value)
      } else {
        valueLabels[key] = value
      }
    }
  }
  return valueLabels
}

export const setupResetLabels = (
  defaultSettings: any = {},
  co2Names: any,
  co2Key?: string
) => {
  const keyMethod = co2Key || ''
  let valueLabels: any = setupValueLabels({}, defaultSettings, co2Names, false)
  return Object.fromEntries(Object.entries(valueLabels).filter(([key]) => key.includes(keyMethod)))
}

/**
 * Prepare to send key: value data
 * @param co2Data all changed data
 * @param co2Key should be passed if single row update
 * @returns prepared data
 */
export const prepareToSendCO2 = (co2Data: any, co2Key?: string) => {
  let prepareToSend: any = {}

  const updateCo2Data = (key: string) => {
    for (const keyLabel in co2Data[key].data) {
      if (!isShipping(keyLabel)) {
        prepareToSend[keyLabel] =
          makeNumbers(co2Data[key].data[keyLabel]) || ZERO_AMOUNT
      } else {
        prepareToSend[keyLabel] =
          co2Data[key].data[keyLabel] || ZERO_AMOUNT
      }
    }
  }

  if (co2Key) {
    updateCo2Data(co2Key)
  } else {
    for (const key in co2Data) {
      updateCo2Data(key)
    }
  }

  return prepareToSend
}

export const checkOnDisableApply = (co2Data: any) => {
  let disableApplyAll: any = false

  for (const key in co2Data) {
    if (co2Data[key].error) {
      disableApplyAll = true
    }
  }

  return disableApplyAll
}

export const checkOnResetAll = (userSettings: any, defaultSettings: any) => {
  let diffArray  = []
  const setupValues = setupValueLabels(userSettings, defaultSettings, CO2_NAMES, true)

  for (const key in setupValues) {
    const isDefault = checkIsDefaultValue(setupValues, key, defaultSettings)
    diffArray.push(isDefault)
  }

  return diffArray.some((data: boolean) => !data)
}
