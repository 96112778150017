import React, { memo } from 'react'

import cx from 'classnames'

import GeometryAnalysisReviewAndFixesContent from './GeometryAnalysisReviewAndFixesContent'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'
import { PartPrintIssue, PrintIssue } from 'Services/models/PartPrintIssue'
import { getString } from 'Services/Strings/StringService'

import './GeometryAnalysisReviewAndFixes.scss'

interface Props {
	show: boolean
	partImageUrl: string
	configurationPrintIssues: PartPrintIssue[]
	orientationVector: number[]
	configuration: any
	solution: any
	printIssues: PrintIssue[]
	printIssueLoaders: Record<string, boolean>
	onViewerModelError: Function
	onCancel: Function
	onWallThicknessClick: Function
	onTolerancesClick: Function
	onMeshHealingClick: Function
	onPartPrintIssueChanged: Function
	partHealedStlURLExist?: boolean
	part: Part
}

const GeometryAnalysisReviewAndFixes: React.FC<Props> = ({
	show,
	onCancel,
	partImageUrl,
	onViewerModelError,
	configurationPrintIssues,
	orientationVector,
	configuration,
	solution,
	printIssues,
	onWallThicknessClick,
	onTolerancesClick,
	onMeshHealingClick,
	partHealedStlURLExist,
	onPartPrintIssueChanged,
	printIssueLoaders,
	part
}) => {
	const actionColumnIsOn = Feature.isFeatureOn(FeatureComponentId.ACTION_COLUMN)
	return (
		<CastorAlert
			headerTitle={
				part?.isDrawing
					? getString('PREVIEW_3D')
					: actionColumnIsOn
					? getString('GEOMETRY_ANALYSIS_ALERT_TITLE')
					: getString('GEOMETRY_ANALYSIS_REVIEW_TITLE')
			}
			onCancel={onCancel}
			show={show}
			showConfirm={false}
			showCancel={false}
			showFooter={false}
			fullScreen={!part?.isDrawing}
			alertClass={cx('geometry-analysis--alert', {
				'drawing-alert': part?.isDrawing
			})}
		>
			<GeometryAnalysisReviewAndFixesContent
				partImageUrl={partImageUrl}
				onViewerModelError={onViewerModelError}
				configurationPrintIssues={configurationPrintIssues}
				orientationVector={orientationVector}
				configuration={configuration}
				solution={solution}
				printIssues={printIssues}
				onWallThicknessClick={onWallThicknessClick}
				onTolerancesClick={onTolerancesClick}
				onMeshHealingClick={onMeshHealingClick}
				partHealedStlURLExist={partHealedStlURLExist}
				onPartPrintIssueChanged={onPartPrintIssueChanged}
				printIssueLoaders={printIssueLoaders}
				part={part}
			/>
		</CastorAlert>
	)
}

export default memo(GeometryAnalysisReviewAndFixes)
