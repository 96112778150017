import { sendReNewSubscriptionRequest } from '../../../Services/Network'
import {
  CASTOR_LOCKED_PARTS_REQUEST_SENT,
  CASTOR_LOCKED_PARTS_SENDING_REQUEST,
  CASTOR_LOCKED_PARTS_SET_TEXT,
  HANDLE_NOTIFICATION
} from '../../../global actions/types'
import { SHOW_NOTIFICATION, ERROR_UPDATING_TAGS } from '../../../Services/Strings'
import { CastorBannerType } from '../CastorBanner/CastorBannerType.enum'
import { getString } from 'Services/Strings/StringService'
import { SUBSCRIPTION_FULL_VERSION_RADIO_VALUE } from 'Services/Constants'

export const buttonOnClick = (userEmail: string, contactUsEmail: string, type: CastorBannerType, userName: string): any => {
  return (dispatch: any) => {
    dispatch({ type: CASTOR_LOCKED_PARTS_SENDING_REQUEST })
    // @ts-ignore
    switch (type) {
      case CastorBannerType.UPGRADE_NOW:
        sendReNewSubscriptionRequest(SUBSCRIPTION_FULL_VERSION_RADIO_VALUE, userEmail)
          ?.then((response: any) => {
            dispatch({ type: CASTOR_LOCKED_PARTS_REQUEST_SENT })
          })
          .catch((error: any) => {
            console.log(error)
            dispatch({
              type: HANDLE_NOTIFICATION,
              payload: {
                notificationType: SHOW_NOTIFICATION.ERROR,
                notificationMessage: error.validationMessage || ERROR_UPDATING_TAGS
              }
            })
          })
        break;
      
      case CastorBannerType.CONTACT_US:
        const emailSubject = getString(
					'LIGHT_USER_CONTACT_US_EMAIL_SUBJECT'
				).format(userName ? `- ${userName}` : '')
				// we check here for user name in order not to pass light.user default email
				// until we don't know the real user's email
				const emailFrom = userName && userEmail ? userEmail : ''
				const emailBody = encodeURIComponent(
					getString('LIGHT_USER_CONTACT_US_EMAIL_BODY').format(userName || '')
				)
				window.location.href = `mailto:${contactUsEmail}?subject=${emailSubject}&cc=${emailFrom}&body=${emailBody}`
				break
    }
  }
}

export const setText = (type: CastorBannerType) => {
  return (dispatch: any) => {
    const buttonText = type === CastorBannerType.CONTACT_US ? getString('CONNECT_CASTOR') : getString('UPDATE_NOW') + '!'
    const text = type === CastorBannerType.CONTACT_US ? getString('CONNECT_CASTOR') : getString('UPDATE_NOW') + '!'

    dispatch({ 
      type: CASTOR_LOCKED_PARTS_SET_TEXT,
      payload: {buttonText, text}
    })
  }
}
