import React, { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { Accordion, ListItemText, MenuItem } from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Select from '@material-ui/core/Select'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import cx from 'classnames'

import { categoriesWithTechnologies, PART_COST } from './constants'
import { useSelectTechnologyFunctions } from './CostFunctionEditorHooks'
import {
	functionTypeSelected,
	moveToCreateFunction,
	setupCostingFunctionEditor,
	toggleCostingFunctionAccordion
} from './CostingFunctionEditorActions'
import { FunctionEditorInitialState } from './CostingFunctionEditorReducer'
import {
	functionForCategoryWithoutTechnologiesExists,
	functionForTechnologyExists
} from './CostingFunctionEditorService'
import {
	CostingFunctionSteps,
	FunctionCategories,
	IFunctionCategory
} from './CustomizeCostingFunctionTypes'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import Button from 'Scenes/Components/thirdParty/CreativeTim/components/CustomButtons/Button'
import { IUser } from 'Services/models/IUser'
import { getString } from 'Services/Strings/StringService'

const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem
const ListItemTextTSX: any = ListItemText

const Step1: FC = () => {
	const dispatch = useDispatch()
	const {
		stepIsOpen,
		functionCategoriesList,
		selectedFunctionCategory,
		selectedPrintingTechnologies,
		isLoadingStep1,
		customFunctionsList,
		isStep2ToggleAllowed
	} = useSelector(
		(state: RootStateOrAny) =>
			state.CostingFunctionEditorReducer?.functionEditorStates[PART_COST] ||
			new FunctionEditorInitialState()
	)
	const { printingTechnologies, editableFunctionStrings }: IUser = useSelector(
		(state: RootStateOrAny) => state.user
	)

	const {
		handleOnlyClick,
		handleAllClick,
		handleSelectChange,
		renderSelectValue
	} = useSelectTechnologyFunctions(
		customFunctionsList,
		selectedFunctionCategory,
		printingTechnologies,
		selectedPrintingTechnologies,
		isStep2ToggleAllowed
	)

	const step1 = CostingFunctionSteps.SelectFunctionAndTechnology
	const shouldShowTechnologiesSelect = categoriesWithTechnologies.includes(
		selectedFunctionCategory
	)
	const disableContinueButton =
		!selectedFunctionCategory ||
		(shouldShowTechnologiesSelect && !selectedPrintingTechnologies.length)

	useEffect(() => {
		dispatch(
			setupCostingFunctionEditor(
				editableFunctionStrings,
				printingTechnologies,
				PART_COST
			)
		)
	}, [dispatch, editableFunctionStrings, printingTechnologies])

	return (
		<Accordion
			disabled={functionCategoriesList.length === 0}
			className="costing-function-editor--accordion"
			expanded={stepIsOpen[step1]}
			onChange={() => {
				dispatch(toggleCostingFunctionAccordion(step1))
			}}
		>
			<AccordionSummary
				aria-controls="panel1bh-content"
				id="panel1bh-header"
				className="costing-function-editor--step-heading--wrapper"
				classes={{ content: 'costing-function-editor--step-heading' }}
			>
				<div className="costing-function-editor--step-heading--text">
					<span className="costing-function-editor--step-heading--text--main">
						{getString('COSTING_FUNCTION_EDITOR_STEP_1_HEADER')}
					</span>
					<span>{getString('COSTING_FUNCTION_EDITOR_STEP_1_DESCRIPTION')}</span>
				</div>
				<ExpandMoreIcon
					className={cx('expand', {
						open: stepIsOpen[step1]
					})}
				/>
			</AccordionSummary>
			<AccordionDetails className="costing-function-editor--step-body">
				{stepIsOpen[step1] && (
					<div className="costing-function-editor--step-body--content">
						<div className="costing-function-editor--select-wrapper">
							<label htmlFor="functionType">
								{getString('FUNCTION_SELECT_LABEL')}
							</label>
							<SelectTSX
								disabled={functionCategoriesList.length <= 1}
								id="functionType"
								className="costing-function-editor--select-field"
								value={selectedFunctionCategory}
								data-qa="data-qa-function-type-select"
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									dispatch(
										functionTypeSelected(
											event.target.value as FunctionCategories,
											isStep2ToggleAllowed,
											functionForCategoryWithoutTechnologiesExists(
												customFunctionsList,
												event.target.value as FunctionCategories
											)
										)
									)
								}
								classes={{
									select: 'costing-function-editor--select'
								}}
								inputProps={{
									name: 'simpleSelect',
									id: 'simple-select'
								}}
								MenuProps={{
									PaperProps: {
										style: {
											transform: 'translate3d(0, 0, 0)'
										}
									},
									PopoverClasses: {
										root: 'costing-function-editor--popover-root'
									}
								}}
							>
								{functionCategoriesList.map(
									(functionType: IFunctionCategory) => {
										const functionForCategoryExists =
											functionForCategoryWithoutTechnologiesExists(
												customFunctionsList,
												functionType.name as FunctionCategories
											)
										return (
											<MenuItemTSX
												key={functionType}
												style={{ textTransform: 'capitalize' }}
												value={functionType.name}
												className={cx('select-toggle-custom', {
													disabled: functionForCategoryExists
												})}
												disabled={functionForCategoryExists}
											>
												{functionType.userReadableName}
											</MenuItemTSX>
										)
									}
								)}
							</SelectTSX>
						</div>
						{shouldShowTechnologiesSelect && (
							<div className="costing-function-editor--select-wrapper">
								<label htmlFor="functionType">
									{getString('PRINTING_TECHNOLOGY_SELECT_LABEL')}
								</label>
								<SelectTSX
									className="costing-function-editor--select-field"
									MenuProps={{
										variant: 'menu',
										getContentAnchorEl: null,
										PopoverClasses: {
											root: 'costing-function-editor--popover-root'
										}
									}}
									classes={{
										select: 'costing-function-editor--select'
									}}
									multiple
									disableUnderline={true}
									displayEmpty={true}
									value={selectedPrintingTechnologies}
									onChange={handleSelectChange}
									renderValue={renderSelectValue}
									data-qa="data-qa-printing-technology"
								>
									<MenuItemTSX value="0" className="select-toggle-all">
										<Button
											onClick={handleAllClick}
											title={getString('SELECT_ALL_TECHNOLOGIES_HEADER')}
											style={{
												margin: '0 auto',
												padding: 'unset',
												width: 'fit-content'
											}}
											data-qa="data-qa-select-all-technologies"
										>
											{getString('SELECT_ALL_TECHNOLOGIES_HEADER')}
										</Button>
									</MenuItemTSX>
									{printingTechnologies?.length ? (
										printingTechnologies.map(technology => {
											const checked =
												selectedPrintingTechnologies?.indexOf(technology.name) >
												-1
											const technologyFunctionAlreadyExists =
												functionForTechnologyExists(
													customFunctionsList,
													selectedFunctionCategory,
													technology.name
												)
											const checkboxDisabled =
												(checked &&
													selectedPrintingTechnologies.length === 1) ||
												technologyFunctionAlreadyExists

											return (
												<MenuItemTSX
													className={cx('select-toggle-custom', {
														disabled: technologyFunctionAlreadyExists
													})}
													key={technology.name}
													value={technology.name}
												>
													<CastorCheckbox
														checked={checked}
														disabled={checkboxDisabled}
													/>
													<ListItemTextTSX
														className="select-toggle-custom-label"
														primary={technology.userReadableName}
													/>
													<div
														className="select-toggle-only"
														onClick={(e: React.MouseEvent<HTMLDivElement>) =>
															handleOnlyClick(
																e,
																technologyFunctionAlreadyExists,
																technology.name
															)
														}
													>
														{getString('SELECT_ONLY_TECHNOLOGY')}
													</div>
												</MenuItemTSX>
											)
										})
									) : (
										<div />
									)}
								</SelectTSX>
							</div>
						)}
						<ButtonWithLoader
							disabled={disableContinueButton}
							id="costing-function-editor--button"
							className="costing-function-editor--button in-select-row"
							loading={isLoadingStep1}
							primary={true}
							onClick={() =>
								dispatch(
									moveToCreateFunction(
										selectedFunctionCategory,
										selectedPrintingTechnologies,
										printingTechnologies,
										PART_COST
									)
								)
							}
						>
							{getString('CONTINUE')}
						</ButtonWithLoader>
					</div>
				)}
			</AccordionDetails>
		</Accordion>
	)
}

export default memo(Step1)
