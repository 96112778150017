import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form'

import _ from 'lodash'

import * as AdminPrintersActions from './AdminPrintersActions'
import { adminPrintersRoute } from '../../../../Services/routeFuncs'
import { ADMIN_PRINTERS_NEW } from '../../../../Services/Strings'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import PrinterForm from '../../../Components/PrinterForm/PrinterForm'
import { companies, technology } from './constants'

class NewAdminPrinter extends Component {
	componentDidMount() {
		this.props.resetData()
	}

	onSubmit = data => {
		const {
			createNewPrinter,
			selectedNewPrinter,
			createNewPrinterAndRemovePrinterName
		} = this.props
		if (_.isEmpty(selectedNewPrinter)) {
			createNewPrinter(data)
		} else {
			createNewPrinterAndRemovePrinterName(data, selectedNewPrinter.id)
		}
	}

	renderFormContent = () => {
		const {
			printingTechnologies,
			selectedNewPrinter,
			addingNewItem,
			onMaintenanceMachineCostFieldsSelectorNewPrinterChange,
			maintenanceMachineSelectorsValueNewPrinter,
			onMachineCostValue,
			onMachineCostSelector,
			selectedPrinterTechnology,
			isNewCompanyDisabled,
			selectedPrinterCompany,
			printersFullData,
			newCompanySelected
		} = this.props
		return (
			<PrinterForm
				selectedPrinterTechnology={selectedPrinterTechnology}
				isNewCompanyDisabled={isNewCompanyDisabled}
				onSubmit={this.onSubmit}
				newCompanySelected={newCompanySelected}
				selectedPrinterCompany={selectedPrinterCompany}
				onMaintenanceMachineCostFieldsSelectorChange={
					onMaintenanceMachineCostFieldsSelectorNewPrinterChange
				}
				onMachineCostSelector={onMachineCostSelector}
				maintenanceMachineCostFieldsSelectorValue={
					maintenanceMachineSelectorsValueNewPrinter
				}
				onMachineCostValue={onMachineCostValue}
				initialValues={selectedNewPrinter}
				printingTechnologies={printingTechnologies}
				addingNewItem={addingNewItem}
				printersFullData={printersFullData}
				isAdminForm={true}
			/>
		)
	}

	render() {
		const { printerAdded, pageNumber } = this.props
		if (printerAdded) {
			return <Redirect to={adminPrintersRoute(pageNumber)} />
		}
		return (
			<NavBarAndMaterial title={ADMIN_PRINTERS_NEW}>
				<CastorForm
					formTitle={ADMIN_PRINTERS_NEW}
					content={this.renderFormContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const selector = formValueSelector('adminPrinterFormState')

const mapStateToProps = state => {
	const {
		printerAdded,
		pageNumber,
		selectedNewPrinter,
		addingNewItem,
		maintenanceMachineSelectorsValueNewPrinter,
		onMachineCostValue,
		isNewCompanyDisabled,
		onMachineCostSelector
	} = state.AdminPrintersReducer
	const { printingTechnologies, printersFullData } = state.user
	return {
		printerAdded,
		pageNumber,
		printingTechnologies,
		selectedNewPrinter,
		addingNewItem,
		maintenanceMachineSelectorsValueNewPrinter,
		onMachineCostValue,
		selectedPrinterTechnology: selector(state, technology),
		selectedPrinterCompany: selector(state, companies),
		isNewCompanyDisabled,
		onMachineCostSelector,
		printersFullData
	}
}
const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminPrintersActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAdminPrinter)
