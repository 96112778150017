import React, { FC, memo, useEffect, useState } from 'react'

import AnalysisResultsTab from '../../../SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/AnalysisResultsTab'
import { LineBreak } from 'Scenes/Components/PdfComponents/LineBreak'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	part: Part
	analysisResultsRows: any
	show: boolean
}

export const GeometryAnalysisPdf: FC<IProps> = ({
	part,
	analysisResultsRows,
	show
}) => {
	const divider = 1.33 // px to pt
	const easyNumber = 100
	const rowHeight = 35
	const height = analysisResultsRows.length * rowHeight + easyNumber
	// need to find the block height
	const reviewAndFix = document
		.querySelectorAll('.export-pdf__geometry')[0]
		?.getBoundingClientRect()?.height
	const [blockHeight, setBlockHeight] = useState(height)

	useEffect(() => {
		if (reviewAndFix) {
			const convertValue = reviewAndFix / divider + easyNumber
			setBlockHeight(convertValue)
		}
	}, [part, analysisResultsRows])

	if (!show) return <div />

	return (
		<div className="inline">
			<div className="export-pdf__geometry">
				<div style={{ height: `${blockHeight}pt` }}>
					<h2>{getString('GEOMETRY_ANALYSIS')}</h2>
					<br />
					<AnalysisResultsTab
						meshUploadURL={undefined}
						configurationResult={undefined}
						part={part}
						analysisResultsRows={analysisResultsRows}
						shouldShowButton={false}
						onGeometryAnalysisReviewAndFixesClick={() => {}}
					/>
				</div>
				<LineBreak />
			</div>
		</div>
	)
}

export default memo(GeometryAnalysisPdf)
