import { FC, memo, ComponentType } from 'react'
import { connect } from 'react-redux'
import { Feature, FeatureComponentId } from '../models/Features'

interface IProps {
  features: Feature[]
}

interface IReduxStore {
  user: any
}

export default <P extends object>(
  WrappedComponent: ComponentType<P> | any,
  featureComponentId: FeatureComponentId | FeatureComponentId[]
): FC<P & IProps & any> => {
  const isFeatureOpen: FC<IProps> = ({ features, ...ownProps }: IProps) => {
    let featureIsOn: boolean | undefined
    if (typeof featureComponentId === 'object') {
      featureIsOn = Feature.isOneOfTheFeaturesOn(featureComponentId, features)
    } else {
      featureIsOn = Feature.isFeatureOn(featureComponentId, features)
    }

    return !featureIsOn ? <div /> : <WrappedComponent {...(ownProps as P)} />
  }

  const mapStateToProps = (
    { user: { features } }: IReduxStore,
    ownProps: P
  ): IProps => ({
    features,
    ...ownProps
  })

  return connect(mapStateToProps)(memo(isFeatureOpen))
}
