import React, { FC, memo } from 'react'
import { NavLink, useHistory } from 'react-router-dom'

import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import cx from 'classnames'

import NavLinkItem from './NavLinkItem'
import { Route } from 'Scenes/Components/thirdParty/CreativeTim/components/Sidebar/SideBarTypes'
import { Feature, FeatureComponentId } from 'Services/models/Features'

const ListItemTSX: any = ListItem
const ListTSX: any = List
const ListItemIconTSX: any = ListItemIcon
const ListItemTextTSX: any = ListItemText
const CollapseTSX: any = Collapse

interface CollapseViewItemProps {
	prop: Route
	keyProp: string
	collapseItemText: string
	itemIcon: string
	itemText: string
	caret: string
	classes: {
		[key: string]: string
	}
	color: string
	rtlActive?: boolean
	activeRoute: Function
	openCollapse: Function
	state: {
		[key: string]: boolean
	}
	isDragging?: boolean
}

const CollapseViewItem: FC<CollapseViewItemProps> = ({
	prop,
	keyProp,
	collapseItemText,
	itemIcon,
	itemText,
	caret,
	classes,
	color,
	rtlActive,
	activeRoute,
	openCollapse,
	state,
	isDragging
}) => {
	const isActive = !prop.views && activeRoute(prop?.path, prop?.folderId)

	const navLinkClasses = cx(classes.collapseItemLink, {
		[classes[color]]: isActive,
		[classes.draggingItem]: isDragging
	})
	const collapseItemMini = cx(classes.collapseItemMini, {
		[classes.collapseItemMiniRTL]: rtlActive
	})

	if (prop.hidden) {
		return null
	}

	if (!prop.collapse) {
		return (
			<NavLinkItem
				keyProp={keyProp + prop.name}
				collapseItem={classes.collapseItem}
				path={prop.path}
				navLinkClasses={navLinkClasses}
				collapseItemMini={collapseItemMini}
				mini={prop.mini}
				name={prop.name}
				reanalyzingStatus={prop?.reanalyzingStatus}
				collapseItemText={collapseItemText}
				icon={prop.icon as string}
			/>
		)
	}

	const Icon: JSX.Element | any = prop.icon
	const projectPartsInSideBar = Feature.isFeatureOn(
		FeatureComponentId.PROJECT_PARTS_IN_SIDEBAR
	)
	const showProjectPage =
		!Feature.isFeatureOn(FeatureComponentId.SKIP_PROJECT_PAGE) &&
		!Feature.isFeatureOn(FeatureComponentId.GLOBAL_SKIP_PROJECT_PAGE)
	const linkToProject = showProjectPage && prop.path ? prop.path : '#'

	return (
		<ListItemTSX
			key={keyProp + prop.name}
			className={cx(classes.item, 'sidebar-with-collapse', {
				'parts-sidebar': projectPartsInSideBar
			})}
			id="sidebarWrapper-2"
		>
			<NavLink
				to={linkToProject}
				className={navLinkClasses}
				onClick={() => (!showProjectPage ? openCollapse(prop.state) : {})}
			>
				{prop.icon ? (
					<ListItemIconTSX className={itemIcon}>
						{prop.icon ? <Icon /> : <></>}
					</ListItemIconTSX>
				) : (
					<></>
				)}
				<ListItemTextTSX
					title={prop.name}
					primary={prop.name}
					secondary={
						<span
							className="caret-block"
							onClick={e => {
								e.stopPropagation()
								e.preventDefault()
								openCollapse(prop.state)
							}}
						>
							<b
								className={cx(caret, {
									[classes.caretActive]: state[prop.state as string]
								})}
							/>
						</span>
					}
					disableTypography={true}
					className={`${itemText} sidebar--text`}
				/>
			</NavLink>
			<CollapseTSX
				className="collapse-block"
				in={state[prop.state as string]}
				unmountOnExit
			>
				<ListTSX
					className={cx(
						classes.list,
						classes.collapseList,
						'collapse-block__list',
						{
							'parts-list': projectPartsInSideBar
						}
					)}
				>
					{prop?.views?.map((prop: Route, key: number) => {
						if (prop.redirect) {
							return null
						}

						const isActive = activeRoute(prop?.path, prop?.folderId)

						const navLinkClasses = cx(classes.collapseItemLink, {
							[classes[color]]: isActive
						})
						const collapseItemMini = cx(classes.collapseItemMini, {
							[classes.collapseItemMiniRTL]: rtlActive
						})
						return (
							<NavLinkItem
								key={key + keyProp + prop.name}
								keyProp={key + keyProp + prop.name}
								collapseItem={classes.collapseItem}
								path={prop.path}
								navLinkClasses={navLinkClasses}
								collapseItemMini={collapseItemMini}
								mini={prop.mini}
								name={prop.name}
								reanalyzingStatus={prop?.reanalyzingStatus}
								popperDirection={key === 0 ? 'bottom-end' : 'top-end'}
								collapseItemText={collapseItemText}
								icon={prop.icon}
							/>
						)
					})}
				</ListTSX>
			</CollapseTSX>
		</ListItemTSX>
	)
}

export default memo(CollapseViewItem)
