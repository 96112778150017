import { ChangeEvent, FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { getString } from 'Services/Strings/StringService'
import { IPrinterTechnology } from 'Services/models/IPrintingTechnology'
import {
	onMaterialNameSelectorChange,
	onPrinterMaterialCompanyChange,
	onPrinterMaterialNameChange,
	setupPrinterMaterialPropsData
} from 'Scenes/Home/Customize/CustomizeMaterialCost/MaterialCostActions'
import {
	ISimpleConfigurationCompany,
	ISimpleConfigurationPrinter
} from 'Scenes/Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { IPrinter } from 'Services/models/IPrintersTypes'

import './CustomizeMaterialSelector.scss'

const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem

interface CustomizeMaterialSelectorProps {
	selectedPrinterTechnology: number
	selectedMaterialName: string
	selectedPrinterName: string
	printerNames: Array<ISimpleConfigurationPrinter>
	printerCompanies: Array<string>
	selectedPrinterCompany: string
	printersFullData: Array<ISimpleConfigurationCompany>
	selectedPrinter: IPrinter
	printerTechnologies: {
		[key: string]: Array<string>
	}
	printingTechnologies?: Array<IPrinterTechnology>
	onPrinterTechSelectorChange: Function
	onMaterialNameSelectorChange: Function
	onPrinterMaterialCompanyChange: Function
	onPrinterMaterialNameChange: Function
	setupPrinterMaterialPropsData: Function
}

const CustomizeMaterialSelector: FC<CustomizeMaterialSelectorProps> = () => {
	const {
		printersFullData
	} = useSelector((state: RootStateOrAny) => state.user)

	const {
		selectedMaterialName,
		printerCompanies,
		selectedPrinterCompany,
		selectedPrinterName,
		printerNames,
		selectedPrinter
	} = useSelector((state: RootStateOrAny) => state.MaterialCostReducer)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setupPrinterMaterialPropsData(printersFullData))
	}, [])

	const onMaterialNameChange = (e: ChangeEvent<{ value: string }>) => {
		dispatch(onMaterialNameSelectorChange(e.target.value))
	}

	return (
		<>
			<SelectTSX
				displayEmpty
				title={selectedPrinterCompany}
				className="customize-printer-props--content--select--long"
				value={selectedPrinterCompany}
				renderValue={() =>
					selectedPrinterCompany ||
					<div className="custom-placeholder">{getString(
						'PRINTER_COMPANY')}</div>
				}
				onChange={(e: ChangeEvent<HTMLSelectElement>) => {
					dispatch(onPrinterMaterialCompanyChange(e.target.value))
				}}
			>
				<MenuItemTSX value="" disabled>
					{getString('PRINTER_COMPANY')}
				</MenuItemTSX>
				{printerCompanies ? printerCompanies?.map((item: string) => {
					return (
						<MenuItemTSX key={item} value={item}>
							{item}
						</MenuItemTSX>
					)
				}) : <div />}
			</SelectTSX>
			<SelectTSX
				displayEmpty
				title={selectedPrinterName}
				className="customize-printer-props--content--select"
				disabled={!printerNames?.length}
				value={selectedPrinterName || 'printer name'}
				renderValue={() => selectedPrinterName ||
					<div className="custom-placeholder">{getString(
						'PRINTER_NAME')}</div>}
				onChange={(e: ChangeEvent<any>) => {
					dispatch(onPrinterMaterialNameChange(e.target.value))
				}}
			>
				<MenuItemTSX value="" disabled>
					{getString('PRINTER_NAME')}
				</MenuItemTSX>
				{printerNames ? printerNames?.map((printerName: ISimpleConfigurationPrinter) => {
					return (
						<MenuItemTSX key={printerName.printerId} value={printerName}>
							{printerName.name}
						</MenuItemTSX>
					)
				}) : <div />}
			</SelectTSX>
			<SelectTSX
				displayEmpty={true}
				className="customize-material-selector--material-name"
				value={selectedMaterialName || ''}
				renderValue={(value?: string) =>
					value || (
						<div className="custom-placeholder">
							{getString('MATERIAL_COST_MATERIAL_NAME')}
						</div>
					)
				}
				onChange={onMaterialNameChange}
			>
				<MenuItemTSX disabled>
					{getString('MATERIAL_COST_MATERIAL_NAME')}
				</MenuItemTSX>
				{
					selectedPrinter ? selectedPrinter?.materials?.map((item: any) => {
						return (
							<MenuItemTSX key={item.id} value={item.name}>
								{item.name}
							</MenuItemTSX>
						)
					}) : <div />
				}
			</SelectTSX>
		</>
	)
}

export default memo(CustomizeMaterialSelector)
