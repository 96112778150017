export const sortArrayOfObjectsInAlphabeticalOrder = <T extends Array<any>>(
	objectsArray: T,
	property: string
): T => {
	return objectsArray.sort((a, b) => {
		const aProperty = a[property].toLowerCase()
		const bProperty = b[property].toLowerCase()
		return aProperty < bProperty ? -1 : aProperty > bProperty ? 1 : 0
	})
}
