export const GET_PUSH_NOTIFICATIONS = 'GET_PUSH_NOTIFICATIONS'
export const GET_PUSH_NOTIFICATIONS_ERROR = 'GET_PUSH_NOTIFICATIONS_ERROR'
export const SET_PUSH_NOTIFICATIONS_VIEWED = 'SET_PUSH_NOTIFICATIONS_VIEWED'
export const SET_ALL_PUSH_NOTIFICATIONS_VIEWED =
	'SET_ALL_PUSH_NOTIFICATIONS_VIEWED'
export const FAILED_SILENT_PUSH_NOTIFICATION = 'FAILED_SILENT_PUSH_NOTIFICATION'
export const FAILED_PUSH_NOTIFICATION = 'FAILED_PUSH_NOTIFICATION'
export const PUSH_NOTIFICATIONS_POPUP = 'PUSH_NOTIFICATIONS_POPUP'
export const LAST_MESSAGE_TIMESTAMP_UPDATE = 'LAST_MESSAGE_TIMESTAMP_UPDATE'
export const HANDLE_NOTIFICATION_CONNECTION = 'HANDLE_NOTIFICATION_CONNECTION'
export const HANDLE_NOTIFICATION_CONNECTION_SILENT =
	'HANDLE_NOTIFICATION_CONNECTION_SILENT'