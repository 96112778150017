import React, { FC } from 'react'
import cx from 'classnames'
import IconFactory from '../StarIcon/IconFactory'

import './index.scss'

interface SelectBoxProps {
	setSelectedType?: () => void
	selected: boolean
	withIcon?: boolean
	disabled?: boolean
	children?: JSX.Element[] | JSX.Element | string
	boxClassName?: string
	qaDataElementName?: any
}

const CastorSelectBox: FC<SelectBoxProps> = ({
	setSelectedType,
	selected,
	withIcon = true,
	children,
	boxClassName,
	disabled,
	qaDataElementName
}) => {
	return (
		<div
			onClick={() => {
				if (!disabled) {
					setSelectedType && setSelectedType()
				}
			}}
			className={cx('select-box', boxClassName, {
				selected: selected,
				disabled: disabled
			})}
			data-qa={qaDataElementName}
		>
			{children}
			{withIcon && selected && <IconFactory iconName="checkmark" />}
		</div>
	)
}

export default CastorSelectBox
