import React, { FC, memo } from 'react'
import { VictoryPie } from 'victory'
import ProjectResultDistributionCustomLabel from '../../Home/ProjectAnalysis/ProjectResultDistributionChart/ProjectResultDistributionCustomLabel'

const VictoryPieTSX: any = VictoryPie

export interface IChartSeriesData {
  x: string
  y: number
  label: string
  name: string
  stroke: string
  colorScale: string
}

interface IProps {
  chartData: IChartSeriesData[]
  maxWidth?: number
  active?: boolean
  padAngle?: number
  yPosition?: number
  labelStyle?: Record<string, string>
}

const PieChart: FC<IProps> = ({
  chartData,
  maxWidth,
  active,
  padAngle,
  labelStyle,
  yPosition
}) => {
  if (!chartData?.length) {
    return <div></div>
  }

  const numberOfColors: number = chartData.filter(d => d.y).length
  return (
    <VictoryPieTSX
      labelComponent={
        <ProjectResultDistributionCustomLabel yPosition={yPosition} style={{ fontSize: '30px' }} />
      }
      data={chartData}
      style={{
        labels: { fill: 'dark gray', fontSize: 19 },
        parent: { maxWidth: maxWidth || 380 },
        data: {
          stroke: (datum: IChartSeriesData) => datum.y && datum.stroke,
          strokeWidth: 3
        }
      }}
      padAngle={
        padAngle != null ? padAngle : numberOfColors > 1 ? numberOfColors : 0
      }
      innerRadius={80}
      radius={125}
      colorScale={chartData.map(d => d.colorScale)}
    />
  )
}

export default memo(PieChart)
