import React, { ChangeEvent, FC, memo } from 'react'
import { useDispatch } from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox'

import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import {
	COMBINE_AROUND_THIS_PART,
	COMBINE_AROUND_THIS_PART_INFO,
	NO,
	SEARCH_WITHIN_SAME_CATEGORY,
	YES
} from 'Services/Strings'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import {
	onCombineClick
} from '../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'

const CheckBoxTS: any = Checkbox

interface ConfigurationCombineAlertProps {
	sameMaterial: boolean,
	setSameMaterial: Function,
	showCombineAlert: boolean,
	setShowCombineAlert: Function,
	partId: string,
	projectId: string
}

const ConfigurationCombineAlert: FC<ConfigurationCombineAlertProps> = ({
	sameMaterial,
	setSameMaterial,
	showCombineAlert,
	setShowCombineAlert,
	partId,
	projectId
}) => {
	const dispatch = useDispatch()

	return (
		<CastorAlert
			headerTitle={COMBINE_AROUND_THIS_PART}
			onCancel={() => setShowCombineAlert(false)}
			show={showCombineAlert}
			onConfirm={() => {
				partId && dispatch(onCombineClick(partId, projectId, !sameMaterial))
				setShowCombineAlert(false)
			}}
			cancelOptionalText={NO}
			confirmOptionalText={YES}
			alertType={AlertType.WARNING}
		>
			{COMBINE_AROUND_THIS_PART_INFO}
			<div>
				<CheckBoxTS
					color="primary"
					checked={sameMaterial}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						setSameMaterial(e.target.checked)
					}
				/>
				<span>{SEARCH_WITHIN_SAME_CATEGORY}</span>
			</div>
		</CastorAlert>
	)
}

export default memo(ConfigurationCombineAlert)
