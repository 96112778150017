import React from 'react'
import { Field, reduxForm, reset } from 'redux-form'

import '../adminHome.scss'
import {
  Button,
  CustomInput
} from '../../../Components/thirdParty/CreativeTim/components'

import { REQUIRED, SUBMIT } from '../../../../Services/Strings'

const validate = values => {
  const errors = {}
  if (!values.name || !values.name.trim()) {
    errors.name = REQUIRED
  }
  if (!values.company || !values.company.trim()) {
    errors.company = REQUIRED
  }
  return errors
}

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <CustomInput
    id={label}
    error={touched && error}
    formControlProps={{
      ...custom
    }}
    inputProps={{
      placeholder: label,
      ...input
    }}
  />
)

const afterSubmit = (result, dispatch) =>
  dispatch(reset('adminPrinterNameFormState'))

const AdminPrinterNameForm = props => {
  const { handleSubmit, onSubmit, valid } = props
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="admin-form inline">
      <div>
        <Field
          className="admin-form-field inline"
          name="name"
          component={renderTextField}
          label="name*"
        />
      </div>
      <div>
        <Field
          className="admin-form-field inline"
          name="company"
          component={renderTextField}
          label="company*"
        />
      </div>

      <div className="submit-inline-button">
        <Button size="sm" color="primary" type="submit" disabled={!valid}>
          {SUBMIT}
        </Button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'adminPrinterNameFormState',
  validate,
  enableReinitialize: true,
  onSubmitSuccess: afterSubmit
})(AdminPrinterNameForm)
