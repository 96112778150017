import {
  POPPER_MENU_CLICKED,
  POPPER_MENU_CLOSED
} from '../../../global actions/types'

const INITIAL_STATE = {
  openMenu: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POPPER_MENU_CLICKED:
      const { menuName, menuStatus } = action.payload
      return {
        ...state,
        openMenu: { ...state.openMenu, [menuName]: menuStatus }
      }
    case POPPER_MENU_CLOSED:
      return {
        ...state,
        openMenu: {}
      }
    default:
      return state
  }
}
