import {
	FEATURE_CONFIGURATION_CREATED,
	FEATURE_TOGGLE_DATA_FETCHED,
	HANDLE_NOTIFICATION,
	FEATURE_CONFIGURATION_UPDATED,
	FEATURE_LIVE_ENVIRONMENT_UPDATED
} from 'global actions/types'
import {
	CONFIGURATION_CALCULATION_ERROR,
	SHOW_NOTIFICATION
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import {
	createNewConfigurationFeatures,
	getConfigurationFeatures,
	getAllFeatures,
	updateConfigurationFeatures,
	updateAllFeatures
} from '../../../../Services/Network'

export const setFeatureToggle = () => {
	return async (dispatch: any) => {
		try {
			const newFeatures = await getAllFeatures()
			const newFeatureConfiguration = await getConfigurationFeatures()
			dispatch({
				type: FEATURE_TOGGLE_DATA_FETCHED,
				payload: {
					features: newFeatures?.data.features,
					featureConfigurations:
						newFeatureConfiguration?.data.featuresConfiguraion
				}
			})
		} catch (error: any) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onUpdateLiveEnvironment = (featuresList: any) => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: FEATURE_LIVE_ENVIRONMENT_UPDATED,
				payload: {
					featuresList
				}
			})
			await updateAllFeatures(featuresList)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('ADMIN_FEATURE_UPDATE_SUCCESS')
				}
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onUpdateFeatureConfiguration = (configuration: any) => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: FEATURE_CONFIGURATION_UPDATED,
				payload: {
					configuration
				}
			})
			await updateConfigurationFeatures(configuration)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('ADMIN_FEATURE_UPDATE_SUCCESS')
				}
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onCreateNewConfiguration = (configuration: any) => {
	return async (dispatch: any) => {
		try {
			const newFeatureConfiguraion = await createNewConfigurationFeatures(
				configuration
			)
			dispatch({
				type: FEATURE_CONFIGURATION_CREATED,
				payload: {
					featureConfiguration:
						newFeatureConfiguraion?.data.getFeatureConfiguration
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('ADMIN_FEATURE_CREATE_SUCCESS')
				}
			})
		} catch (error: any) {
			console.error(error)

			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: error.internalMessage || error.validationMessage
				}
			})
		}
	}
}
