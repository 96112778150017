import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import Close from '@material-ui/icons/Close'

import ItemGrid from '../../../Components/thirdParty/CreativeTim/components/Grid/ItemGrid.jsx'
import extendedTablesStyle from '../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import {
  Table,
  Button
} from '../../../Components/thirdParty/CreativeTim/components/index.js'
import { removeUserPrinter } from './CustomizeInHousePrintersActions'
import {
  MATERIAL_COST_DELETE_ITEM,
  IN_HOUSE_PRINTERS_COMPANY,
  IN_HOUSE_PRINTERS_PRINTER_NAME,
  IN_HOUSE_PRINTERS_ACTION,
  IN_HOUSE_PRINTERS_AMOUNT
} from '../../../../Services/Strings.js'
import { getString } from '../../../../Services/Strings/StringService'

class CustomizeInHousePrintersTable extends Component {
  onDeleteClick = itemToDelete => {
    const { removeUserPrinter, userId } = this.props
    removeUserPrinter(itemToDelete, userId)
  }

  renderDeleteButton = itemToDelete => {
    const { classes } = this.props
    return (
      <Button
        title={MATERIAL_COST_DELETE_ITEM}
        onClick={this.onDeleteClick.bind(this, itemToDelete)}
        color="dangerNoBackground"
        style={{ margin: 'unset', padding: 'unset' }}
        customClass={classes.actionButton}
      >
        <Close className={classes.icon} />
      </Button>
    )
  }

  renderMaterials = materials => {
    return materials?.map(material => (
      <div key={material.id}>{material.name}</div>
    ))
  }

  renderInHousePrinterTblData = () => {
    return this.props.userPrinters?.map(userPrinter => [
      userPrinter.company,
      userPrinter.name,
      this.renderMaterials(userPrinter.materials),
      userPrinter.amount,
      this.renderDeleteButton(userPrinter)
    ])
  }

  render() {
    if (this.props.userPrinters?.length) {
      return (
        <ItemGrid>
          <Table
            editTableStyle={'materialCostTableCell'}
            tableHead={[
              IN_HOUSE_PRINTERS_COMPANY,
              IN_HOUSE_PRINTERS_PRINTER_NAME,
              getString('ADMIN_MATERIALS_PAGE_HEADER'),
              IN_HOUSE_PRINTERS_AMOUNT,
              IN_HOUSE_PRINTERS_ACTION
            ]}
            tableData={this.renderInHousePrinterTblData()}
            qaDataElementName="data-qa-user-printers-table"
          />
        </ItemGrid>
      )
    }
    return <div />
  }
}

const mapStateToProps = ({ CustomizeInHousePrintersReducer, user }) => {
  const userPrinters = CustomizeInHousePrintersReducer.userId
      ? CustomizeInHousePrintersReducer.userPrinters
      : user.printers,
    { userId } = CustomizeInHousePrintersReducer
  return { userPrinters, userId }
}

export default connect(mapStateToProps, { removeUserPrinter })(
  withStyles(extendedTablesStyle)(CustomizeInHousePrintersTable)
)
