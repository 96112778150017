import { useEffect } from 'react'

import { SKIP_KEY_CODE } from 'Scenes/Home/OnboardingWizard/constants'

export const useSkipKeyDown = (callBackFunction: Function) => {
	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => {
			if (
				(e.ctrlKey || e.metaKey) &&
				e.shiftKey &&
				e.keyCode === SKIP_KEY_CODE
			) {
				callBackFunction()
			}
		}
		document.addEventListener('keydown', onKeyDown)
		return () => {
			document.removeEventListener('keydown', onKeyDown)
		}
	})
}
