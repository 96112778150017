import React, { FC, memo, ReactNode } from 'react'

import cx from 'classnames'

interface Props {
	title: string
	extraTitles?: Array<string>
	contentClassName?: string
	contentText?: string
	titleClassName?: string
	contentCustomComponent?: ReactNode
	isContentTextInclined?: boolean
}

const CardContent: FC<Props> = ({
	title = '',
	extraTitles = [],
	contentCustomComponent = <div />,
	contentText = '',
	contentClassName = '',
	titleClassName = '',
	isContentTextInclined = false
}) => {
	const extraTitlesElements = !extraTitles?.length ? (
		<div />
	) : (
		extraTitles.map(extraTitle => (
			<h4 title={extraTitle} className="card--title">
				{extraTitle}
			</h4>
		))
	)

	if (
		!title &&
		!extraTitles?.length &&
		!contentText &&
		!contentCustomComponent
	) {
		return <></>
	}

	return (
		<div className={`card--content ${contentClassName}`}>
			<h4 title={title} className={`card--title ${titleClassName}`}>
				{title}
			</h4>
			{extraTitlesElements}
			{contentText ? (
				<p className={cx('', { defaultText: isContentTextInclined })}>
					{contentText}
				</p>
			) : (
				<div
					className={cx({
						'card--content--empty':
							!extraTitles?.length && !contentCustomComponent
					})}
				/>
			)}
			{contentCustomComponent}
		</div>
	)
}

export default memo(CardContent)
