import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Route, withRouter } from 'react-router-dom'

import { getLoginState } from '../../global actions/index'

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest)
  return React.createElement(component, finalProps)
}

class SelectiveRoute extends Component {
  renderRoute(routeProps) {
    return renderMergedProps(
      this.props.parentProps.component,
      this.props,
      routeProps,
      this.props.parentProps
    )
  }

  refreshIndicatorLayout() {
    return <div />
  }

  render() {
    const {
      stateUnknown,
      isRouteLegalFunc,
      parentProps,
      alternativeComponent
    } = this.props
    if (stateUnknown) {
      return this.refreshIndicatorLayout()
    }
    if (!isRouteLegalFunc()) {
      return (
        <Route
          path={parentProps.path}
          replace
          component={alternativeComponent}
        />
      )
    }
    return (
      <Route
        path={parentProps.path}
        replace
        render={routeProps => {
          return renderMergedProps(
            parentProps.component,
            routeProps,
            this.props
          )
        }}
      />
    )
  }
}

const mapStateToProps = ({ user }) => {
  const { stateUnknown, loggedIn } = user

  return { loggedIn, stateUnknown }
}
export default withRouter(
  connect(mapStateToProps, { getLoginState })(SelectiveRoute)
)
