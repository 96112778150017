import React, { memo, FC } from 'react'

import MySimpleAlert from '../../../../Components/alerts/MySimpleAlert'
import ModalHeader from '../../../../Components/Modal/ModalHeader'
import { PART_ANALYSIS_ADD_FEATURES } from '../../../../../Services/Strings'
import SolutionConfigureFeatures from './SolutionConfigure/SolutionConfigureFeatures/SolutionConfigureFeatures'
import SolutionConfigureFeaturesPriorities from './SolutionConfigure/SolutionConfigureFeatures/SolutionConfigureFeaturesPriorities'
import { ActionWithPayload } from '../../../../../global actions/ActionModels'
import { IPriority } from '../../../../../Services/models/IPriority'
import CastorAlert from '../../../../Components/alerts/CastorAlert'

interface IProps {
  showPrioritiesFeaturesModal: boolean
  priorities: IPriority[]
  tempSolutionPrioritiesToggles: any
  configuration: any
  solutionPriorities: Map<string, number>
  onPriorityToggleChange: (
    id: number,
    priority: IPriority
  ) => ActionWithPayload<any>
  onPrioritiesModalCancel: (id: number) => ActionWithPayload<any>
  onPrioritiesModalConfirm: (
    id: number,
    priorities: IPriority[],
    tempSolutionPrioritiesToggles: any,
    solutionPriorities: Map<string, number>
  ) => ActionWithPayload<any>
}

const SolutionPrioritizeFeaturesModal: FC<IProps> = ({
  showPrioritiesFeaturesModal,
  onPrioritiesModalCancel,
  onPrioritiesModalConfirm,
  priorities,
  tempSolutionPrioritiesToggles,
  configuration,
  onPriorityToggleChange,
  solutionPriorities
}) => {
  return (
    <CastorAlert
      headerTitle={PART_ANALYSIS_ADD_FEATURES}
      onCancel={() => onPrioritiesModalCancel(configuration.id)}
      show={showPrioritiesFeaturesModal}
      onConfirm={() =>
        onPrioritiesModalConfirm(
          configuration.id,
          priorities,
          tempSolutionPrioritiesToggles,
          solutionPriorities
        )
      }
      inPortal
    >
      <SolutionConfigureFeaturesPriorities
        priorities={priorities}
        showSolutionPriorities={tempSolutionPrioritiesToggles}
        configurationId={configuration.id}
        onPriorityToggleChange={onPriorityToggleChange}
      />
    </CastorAlert>
  )
}

export default memo(SolutionPrioritizeFeaturesModal)
