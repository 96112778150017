import React, { FC, memo, useEffect } from 'react'
import { connect, DispatchProp, RootStateOrAny, useSelector } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import Close from '@material-ui/icons/Close'

import * as CostAndLeadActions from '../CostAndLeadActions'
import {
	COST_AND_LEAD_BUTTON_ACTIONS,
	CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM
} from '../../../../../Services/Strings'
import { getString } from '../../../../../Services/Strings/StringService'
import {
	Button,
	Table
} from '../../../../Components/thirdParty/CreativeTim/components'
import { getEditableRowActionButtons } from '../CostAndLeadService'
import {
	IPrinterCost,
	PrinterCostKey,
	ValidationStatus
} from './CustomizePrinterCostsTypes'
import EditableValue from './EditableValue'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { Printer } from 'Services/models/IPrinter'

import './CustomizePrinterProps.scss'

const CloseTSX: any = Close

interface IReduxProps {
	onPrinterSettingsDelete: (itemToDelete: number) => any
	setupPrinterCostsTable: (
		userPrinterSettingsData: Record<string, any>[],
		inHousePrinters: Printer[]
	) => void
	onPrinterCostChange: (
		printerId: string,
		printerCostKey: PrinterCostKey,
		value: string,
		editablePrinterCostsList: IPrinterCost[],
		isIncorrectNumberValue: boolean,
		relatedPrinterCostKey: PrinterCostKey
	) => void
	onPrinterCostActionClicked: (
		clickType: string,
		item: IPrinterCost,
		editablePrinterCostsList: IPrinterCost[]
	) => void
}

const renderEditButton = (
	printerCost: IPrinterCost,
	editablePrinterCostsList: IPrinterCost[],
	onPrinterCostActionClicked: (
		clickType: string,
		item: IPrinterCost,
		editablePrinterCostsList: IPrinterCost[]
	) => void
) => {
	const actionButtons = getEditableRowActionButtons(
		printerCost.edit,
		printerCost.price || printerCost.printerHourlyCost
	)

	return actionButtons.map((actionButton: string) => {
		const disabled =
			actionButton === COST_AND_LEAD_BUTTON_ACTIONS.APPLY &&
			!(printerCost.validationStatus?.[PrinterCostKey.printerCost] ===
				ValidationStatus.success ||
				printerCost.validationStatus?.[PrinterCostKey.printerHourlyCost] ===
					ValidationStatus.success)

		return (
			<TransparentButton
				key={actionButton}
				className="custom-input--action"
				disabled={disabled}
				onClick={() =>
					onPrinterCostActionClicked(
						actionButton,
						printerCost,
						editablePrinterCostsList
					)
				}
			>
				{actionButton}
			</TransparentButton>
		)
	})
}

const renderDeleteButton = (
	itemToDelete: Record<string, any>,
	onPrinterSettingsDelete: Function
) => {
	return (
		<Button
			title={CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM}
			onClick={() => onPrinterSettingsDelete(itemToDelete)}
			color="dangerNoBackground"
			style={{ margin: 'unset', padding: 'unset' }}
		>
			<CloseTSX className="customize-printer-props--content--table--icon" />
		</Button>
	)
}

export const CustomizePrinterPropsTable: FC<IReduxProps> = ({
	onPrinterSettingsDelete,
	setupPrinterCostsTable,
	onPrinterCostChange,
	onPrinterCostActionClicked
}) => {
	const { printers: inHousePrinters } = useSelector(
		(state: RootStateOrAny) => state.user
	) as { printers: Printer[] }
	const {
		userPrinterSettingsData,
		editablePrinterCostsList,
		deletablePrinterCostsList
	} = useSelector(
		(state: RootStateOrAny) =>
			state.CostAndLeadReducer as {
				userPrinterSettingsData: Record<string, any>[]
				editablePrinterCostsList: IPrinterCost[]
				deletablePrinterCostsList: IPrinterCost[]
			}
	)

	const renderPrinterPropsTblData = () => {
		const editablePrinterCostsData = editablePrinterCostsList?.map(item => [
			item.printer.name,
			<EditableValue
				printerCost={item}
				printerCostKey={PrinterCostKey.printerCost}
				relatedPrinterCostKey={PrinterCostKey.printerHourlyCost}
				editablePrinterCostsList={editablePrinterCostsList}
				onPrinterCostChange={onPrinterCostChange}
			/>,
			<EditableValue
				printerCost={item}
				printerCostKey={PrinterCostKey.printerHourlyCost}
				relatedPrinterCostKey={PrinterCostKey.printerCost}
				editablePrinterCostsList={editablePrinterCostsList}
				onPrinterCostChange={onPrinterCostChange}
			/>,
			renderEditButton(
				item,
				editablePrinterCostsList,
				onPrinterCostActionClicked
			)
		])
		const deletablePrinterCostsData = deletablePrinterCostsList.map(item => [
			item.printer.name,
			item.price,
			item.printerHourlyCost,
			renderDeleteButton(
				{
					printer: item.printer.id,
					printerSettings: { price: null, printerHourlyCost: null }
				},
				onPrinterSettingsDelete
			)
		])
		return [...editablePrinterCostsData, ...deletablePrinterCostsData]
	}

	useEffect(() => {
		setupPrinterCostsTable(userPrinterSettingsData, inHousePrinters)
	}, [inHousePrinters, setupPrinterCostsTable, userPrinterSettingsData])

	if (!editablePrinterCostsList.length && !deletablePrinterCostsList.length) {
		return <div />
	}
	return (
		<Table
			editTableStyle={'materialCostTableCell'}
			tableHead={[
				getString('PRINTER_NAME'),
				getString('PRINTER_PRICE'),
				getString('PRITNER_SETTINGS_PRINTER_HOURLY_COST'),
				getString('PRINTER_SETTINGS_ACTION')
			]}
			tableData={renderPrinterPropsTblData()}
			className="printer-costs-table"
		/>
	)
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CostAndLeadActions }, dispatch)

export default memo(
	connect(null, mapDispatchToProps)(CustomizePrinterPropsTable)
)
