import { FC, memo } from 'react'

import { IPriority } from '../../../../../../Services/models/IPriority'
import { getString } from '../../../../../../Services/Strings/StringService'
import Devider from '../../../../../Components/Devider/Devider'
import SimpleConfigurationSelector from '../../../../../Components/SimpleConfigurationSelector'
import SimpleConfigurationInHouse from '../../../../../Components/SimpleConfigurationSelector/SimpleConfigurationInHouse'
import { ConfigurationResultTypes } from '../ConfigurationResultTypes'
import SolutionConfigureFilters from './SolutionConfigureFeatures/SolutionConfigureFilters'
import SolutionConfigurePrioritizeForm from './SolutionConfigurePrioritizeForm'
import Flexbox from 'Scenes/Components/FlexBox'
import { getPrinterTechnologies } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisService'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'

import '../../../../../../assets/css/slider.scss'

import '../SolutionAnalysis.scss'

const DeviderTSX: any = Devider

interface IProps {
	configuration: any
	priorities: IPriority[]
	simpleInhouseConfiguration: boolean
	solutionPriorities: Map<string, number>
	showSolutionPriorities: any
	simpleConfigurationSelectorCompanyValue: string
	simpleConfigurationSelectorPrinterValue: any
	simpleConfigurationSelectorMaterialValue: any
	userPrinters: any
	simpleConfiguration: boolean
	customConfiguration: boolean
	printersCompaniesList: Array<string>
	simpleConfigurationMaterialsList: Array<any>
	simpleConfigurationPrinters: Array<any>
	printingTechnologies: IPrintingTechnology[]
	onPrioritizeFieldChange: Function
	onFilterFeatureClick: Function
	onInhouseTogleClick: Function
	onPrioritiesFeatureClick: Function
	onPriorityRemove: Function
	onCompanyChangeSimpleConfiguration: Function
	onPrinterChangeSimpleConfiguration: Function
	onMaterialChangeSimpleConfiguration: Function
	onSimpleInhouseConfigurationChange: Function
}

const renderPrioritizeForm = (
	configuration: any,
	onPriorityRemove: any,
	priorities: any,
	solutionPriorities: any,
	showSolutionPriorities: any,
	onPrioritizeFieldChange: any,
	onPrioritiesFeatureClick: Function
) => {
	return (
		<SolutionConfigurePrioritizeForm
			className="solution-analysis-object-content-configure-widget"
			configurationId={configuration.id}
			onPriorityRemove={onPriorityRemove}
			priorities={priorities}
			solutionPriorities={solutionPriorities}
			showSolutionPriorities={showSolutionPriorities}
			onPrioritizeFieldChange={onPrioritizeFieldChange}
			onPrioritiesFeatureClick={() =>
				onPrioritiesFeatureClick(configuration.id)
			}
		/>
	)
}

const renderFilterForm = (
	userPrinters: any,
	configuration: any,
	printingTechnologies: IPrintingTechnology[],
	onInhouseTogleClick: Function,
	onFilterFeatureClick: Function
) => {
	const withPrinterTechnology =
		getPrinterTechnologies(configuration?.filters) ||
		!!configuration?.printerTechnology

	return (
		<SolutionConfigureFilters
			printerTechnology={withPrinterTechnology}
			userHavePrinters={!!userPrinters?.length}
			configurationId={configuration.id}
			className="solution-analysis-object-content-configure-widget solution-analysis-object-content-configure-widget-filters"
			onFilterFeatureClick={() => onFilterFeatureClick(configuration.id)}
			isDesktopPrinterConfiguration={
				configuration.resultType === ConfigurationResultTypes.Desktop
			}
			originalMaterial={configuration.material}
			configurationFilters={configuration.filters}
		/>
	)
}

const renderSimpleConfigurationPrinterSelector = (
	printersCompaniesList: Array<string>,
	simpleConfigurationPrinters: Array<any>,
	simpleConfigurationMaterialsList: Array<any>,
	onCompanyChangeSimpleConfiguration: Function,
	onPrinterChangeSimpleConfiguration: Function,
	onMaterialChangeSimpleConfiguration: Function,
	simpleConfigurationSelectorCompanyValue: string,
	simpleConfigurationSelectorPrinterValue: any,
	simpleConfigurationSelectorMaterialValue: any,
	onInhouseTogleClick: Function,
	onSimpleInhouseConfigurationChange: Function,
	userHavePrinters: boolean,
	simpleInhouseConfiguration: boolean,
	customConfiguration: boolean
) => {
	return (
		<Flexbox width="55%">
			<DeviderTSX size="292px" vertical />
			<Flexbox
				flexDirection="column"
				alignItems="flex-start"
				marginLeft="30px"
				className="simpleConfiguration-content"
			>
				<div className="simpleConfiguration-printers-headers">
					{getString('ADD_PRINTER_MATERIAL_CONFIGURATION_TITLE')}
				</div>
				<SimpleConfigurationInHouse
					userHavePrinters={userHavePrinters}
					simpleInhouseConfiguration={simpleInhouseConfiguration}
					disableConfiguration={customConfiguration}
					onInhouseTogleClick={onInhouseTogleClick}
					onSimpleInhouseConfigurationChange={
						onSimpleInhouseConfigurationChange
					}
					componentId={2}
				/>
				<SimpleConfigurationSelector
					printerCompanyValue={simpleConfigurationSelectorCompanyValue}
					printerValue={simpleConfigurationSelectorPrinterValue}
					materialValue={simpleConfigurationSelectorMaterialValue}
					companiesList={printersCompaniesList}
					printersList={simpleConfigurationPrinters}
					materialsList={simpleConfigurationMaterialsList}
					onCompanyChange={onCompanyChangeSimpleConfiguration}
					onPrinterChange={onPrinterChangeSimpleConfiguration}
					onMaterialsChange={onMaterialChangeSimpleConfiguration}
					disableConfiguration={customConfiguration}
					selectMenuClassName="printer-material-config--select-menu"
					itemWrapperClassname="printer-material-config--selectors"
				/>
			</Flexbox>
		</Flexbox>
	)
}

const SolutionConfigureContentSelection: FC<IProps> = ({
	configuration,
	priorities,
	onPrioritizeFieldChange,
	onFilterFeatureClick,
	solutionPriorities,
	showSolutionPriorities,
	onPrioritiesFeatureClick,
	onPriorityRemove,
	userPrinters,
	printingTechnologies,
	onInhouseTogleClick,
	simpleConfiguration,
	printersCompaniesList,
	simpleConfigurationMaterialsList,
	simpleConfigurationPrinters,
	onCompanyChangeSimpleConfiguration,
	onPrinterChangeSimpleConfiguration,
	onMaterialChangeSimpleConfiguration,
	simpleConfigurationSelectorCompanyValue,
	simpleConfigurationSelectorPrinterValue,
	simpleConfigurationSelectorMaterialValue,
	simpleInhouseConfiguration,
	onSimpleInhouseConfigurationChange,
	customConfiguration
}) => {
	if (simpleConfiguration) {
		return (
			<>
				{renderSimpleConfigurationPrinterSelector(
					printersCompaniesList,
					simpleConfigurationPrinters,
					simpleConfigurationMaterialsList,
					onCompanyChangeSimpleConfiguration,
					onPrinterChangeSimpleConfiguration,
					onMaterialChangeSimpleConfiguration,
					simpleConfigurationSelectorCompanyValue,
					simpleConfigurationSelectorPrinterValue,
					simpleConfigurationSelectorMaterialValue,
					onInhouseTogleClick,
					onSimpleInhouseConfigurationChange,
					!!userPrinters?.length,
					simpleInhouseConfiguration,
					customConfiguration
				)}
				<div></div>
			</>
		)
	}

	return (
		<>
			<DeviderTSX size="292px" vertical />
			{renderPrioritizeForm(
				configuration,
				onPriorityRemove,
				priorities,
				solutionPriorities,
				showSolutionPriorities,
				onPrioritizeFieldChange,
				onPrioritiesFeatureClick
			)}

			<DeviderTSX size="292px" vertical />
			{renderFilterForm(
				userPrinters,
				configuration,
				printingTechnologies,
				onInhouseTogleClick,
				onFilterFeatureClick
			)}
		</>
	)
}

export default memo(SolutionConfigureContentSelection)
