import { FC } from 'react'

import { useError } from './ErrorInterceptorProvider'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const ErrorInterceptor: FC = () => {
	const { error } = useError()

	if (!error) return null

	return (
		<div className="error-interceptor-block">
			<div>
				<h4>{error}</h4>
				<Button
					color="primary"
					onClick={() => {
						window.location.reload()
					}}
				>
					{getString('RELOAD_THE_PAGE')}
				</Button>
			</div>
		</div>
	)
}

export default ErrorInterceptor
