export enum AdditiveMindsTypes {
	button = 'button',
	status = 'status',
	analysis = 'analysis'
}

export interface AdditiveMindsProps {
	className?: string
	type: AdditiveMindsTypes.button | AdditiveMindsTypes.status | AdditiveMindsTypes.analysis
	configurationId: number
	hoverText?: string
}

export interface AdditiveMindsPopupProps {
	configurationId: number
}

export interface AdditiveMindsButtonProps {
	type: AdditiveMindsTypes.button | AdditiveMindsTypes.status | AdditiveMindsTypes.analysis
	hoverText?: string
}