import React from 'react'
import { Field, reduxForm } from 'redux-form'

import TextField from '@material-ui/core/TextField'

import { REQUIRED, SUBMIT, UNDO_CHANGES } from '../../../../Services/Strings'
import { Button } from '../../../Components/thirdParty/CreativeTim/components'

import '../adminHome.scss'

const validate = values => {
	const errors = {}
	if (!values.printer) {
		errors.printer = REQUIRED
	}
	if (!values.printerMaterial) {
		errors.printerMaterial = REQUIRED
	}
	if (!values.buildRate) {
		errors.buildRate = REQUIRED
	}
	return errors
}

const renderTextField = ({
	input,
	label,
	meta: { touched, error },
	...custom
}) => (
	<TextField
		label={label}
		hintText={label}
		floatingLabelText={label}
		error={touched && error}
		helperText={error}
		onWheel={event => event.target.blur()}
		{...input}
		{...custom}
	/>
)

const AdminMaterialPrinterPairForm = props => {
	const {
		handleSubmit,
		pristine,
		reset,
		submitting,
		onSubmit,
		valid,
		addingNewItem
	} = props
	return (
		<form onSubmit={handleSubmit(onSubmit)} className="admin-form">
			<div>
				<Field
					className="admin-form-field"
					name="printerId"
					component={renderTextField}
					label="Printer Id"
					type="text"
				/>
			</div>
			<div>
				<Field
					className="admin-form-field"
					name="printerMaterialId"
					component={renderTextField}
					label="Printer Material Id"
					type="text"
				/>
			</div>
			<div>
				<Field
					className="admin-form-field"
					name="buildRate"
					component={renderTextField}
					label="Build Rate"
					type="number"
				/>
			</div>

			<div>
				<Button
					size="sm"
					color="primary"
					type="submit"
					disabled={!valid || addingNewItem}
				>
					{SUBMIT}
				</Button>
				<Button
					size="sm"
					color="primary"
					type="button"
					disabled={pristine || submitting}
					onClick={reset}
				>
					{UNDO_CHANGES}
				</Button>
			</div>
		</form>
	)
}

export default reduxForm({
	form: 'adminMaterialPrinterPairFormState',
	validate,
	enableReinitialize: true
})(AdminMaterialPrinterPairForm)
