import { useMemo } from 'react'

import { IOnboardingWizardStep } from 'Services/models/IOnboardingWizardStep'

export const useStep = (
	steps: IOnboardingWizardStep[],
	id: number,
	isChildAndParentStep = false
) => {
	const step = useMemo(
		() => steps.find((step: IOnboardingWizardStep) => step.id === id),
		[id, steps]
	) as IOnboardingWizardStep
	const stepContent = step?.content.sort((a, b) => a.order - b.order) || []
	const childSteps = steps
		.filter(
			(anyStep: IOnboardingWizardStep) =>
				anyStep.parentStepId &&
				(anyStep.parentStepId === step.parentStepId ||
					(!isChildAndParentStep && anyStep.parentStepId === step.id))
		)
		.sort((a, b) => a.order - b.order)

	return { step, stepContent, childSteps }
}
