import { FC, memo, useEffect, useState } from 'react'

import * as SolutionAnalysisActions from '../SolutionAnalysisActions'
import { ActionWithPayload } from '../../../../../../global actions/ActionModels'
import Flexbox from 'Scenes/Components/FlexBox'
import InfoBox from 'Scenes/Components/InfoBox'
import MaterialsSolutionSelector from 'Scenes/Components/MaterialSolutionSelector/MaterialsSolutionSelector'
import NumberField from 'Scenes/Components/NumberField'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { manufacturingMethodTypes } from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'
import { PartPrintIssue } from 'Services/models/PartPrintIssue'
import { getString } from 'Services/Strings/StringService'

import './SolutionConfigure.scss'

interface Props {
	configurationId: number
	batchSize: number
	initialMaterial: any
	materialTypesList: Array<string>
	categoriesList: Array<string>
	materialsList: Array<any>
	chosenMaterialType: string
	chosenMaterialCategory: string
	chosenMaterial: any
	showMaterialsFilters: boolean
	numberOfPostProcessesOn: number
	partSolution: any
	configurationChanged: boolean
	trayOrientationCustom: boolean
	trayOrientationLoader: boolean
	disableAdvancedButtons: boolean
	disableConfiguration: boolean
	printable: boolean
	updatedPostProcess: any
	simpleConfiguration: boolean
	cluster: any
	part: Part
	configuration: any
	configurationPrintIssues: PartPrintIssue[]
	initialToleranceValue: string
	lowestToleranceValue?: number
	manufacturingMethod: string
	onTolerancesClick: Function
	isSpecifiedQuantity: boolean
	onPostProcessesClick: (id: number) => ActionWithPayload<any>
	onChangeOrientationClick: (
		...onChangeOrientationClickParams: Parameters<
			typeof SolutionAnalysisActions.onChangeOrientationClick
		>
	) => any
	onBatchSizeChange: (id: number, value: number) => ActionWithPayload<any>
	onChangeMaterial: (value: any, id: number) => ActionWithPayload<any>
	onChangeMaterialCategory: (value: any, id: number) => ActionWithPayload<any>
	onChangeMaterialType: (value: any, id: number) => ActionWithPayload<any>
	originalFilterButtonClicked: (
		value: boolean,
		id: number
	) => ActionWithPayload<any>
}

const renderTrayOrientationButton = (
	configurationId: number | string,
	partSolution: any,
	batchSize: number | string,
	configurationChanged: boolean,
	updatedPostProcess: any,
	onChangeOrientationClick: Function,
	trayOrientationLoader: boolean,
	trayOrientationCustom: boolean,
	printable: boolean,
	disableAdvancedButtons: boolean,
	cluster: any,
	part: Part,
	configuration: any,
	configurationPrintIssues: PartPrintIssue[]
) => {
	if (
		part?.isDrawing ||
		part?.formatType === METADATA ||
		!Feature.isFeatureOn(FeatureComponentId.TRAY_ORIENTATION) ||
		cluster
	) {
		return <div />
	}

	return (
		<Flexbox alignItems="center">
			<TransparentButton
				className="solution-analysis-object-content-configure--transparent-button"
				disabled={disableAdvancedButtons}
				loading={trayOrientationLoader}
				onClick={() =>
					onChangeOrientationClick(
						configurationId,
						partSolution,
						batchSize,
						configurationChanged,
						updatedPostProcess,
						part,
						configuration,
						configurationPrintIssues,
						false
					)
				}
			>
				{`+ ${getString('PRINTING_ORIENTATION_BUTTON_TEXT')} - ${
					trayOrientationCustom
						? getString('CUSTOM')
						: getString('PRINTING_ORIENTATION_BUTTON_DEFAULT_TEXT')
				}`}
			</TransparentButton>
			{trayOrientationLoader ? (
				<div />
			) : (
				<InfoBox
					boxDirection="bottom-start"
					boxContact={getString('PRINTING_ORIENTATION_GENERAL_TITLE')}
					iconClassName="icon-info"
				/>
			)}
		</Flexbox>
	)
}

const renderToleranceButton = (
	disableAdvancedButtons: boolean,
	onTolerancesClick: Function,
	toleranceValue: string | number
) => {
	if (!Feature.isFeatureOn(FeatureComponentId.TOLERANCES)) {
		return <div />
	}

	return (
		<Flexbox alignItems="center">
			<TransparentButton
				className="solution-analysis-object-content-configure--tolerance-button"
				disabled={disableAdvancedButtons}
				onClick={() => onTolerancesClick()}
			>
				{`+ ${getString('TOLERANCE')}${` (
          ${
						typeof toleranceValue === 'number'
							? `${toleranceValue} [mm]`
							: getString(toleranceValue || '')
					}
        )`}`}
			</TransparentButton>
		</Flexbox>
	)
}

const renderMaterialsSolutionSelector = (
	chosenMaterial: any,
	chosenMaterialType: any,
	chosenMaterialCategory: any,
	materialTypesList: any,
	categoriesList: any,
	materialsList: any,
	configurationId: any,
	showMaterialsFilters: any,
	simpleConfiguration: boolean,
	onChangeMaterial: Function,
	onChangeMaterialCategory: Function,
	onChangeMaterialType: Function
) => {
	if (simpleConfiguration || !showMaterialsFilters || !chosenMaterial) {
		return <div />
	}
	return (
		<MaterialsSolutionSelector
			hideMaterialNameSelector={Feature.isFeatureOn(
				FeatureComponentId.AUTO_NAME_MATERIAL_SELECTOR
			)}
			materialValue={chosenMaterial}
			materialTypeValue={chosenMaterialType}
			materialCategoryValue={chosenMaterialCategory}
			typesList={materialTypesList}
			categoriesList={categoriesList}
			materialsList={materialsList}
			onMaterialsChange={(value: any) => {
				onChangeMaterial(value, configurationId)
			}}
			onTypeChange={(value: any) => {
				onChangeMaterialType(value, configurationId)
			}}
			onCategoryChange={(value: any) => {
				onChangeMaterialCategory(value, configurationId)
			}}
		/>
	)
}

const SolutionConfigureMaterials: FC<Props> = ({
	onBatchSizeChange,
	configurationId,
	batchSize,
	initialMaterial,
	onChangeMaterial,
	onChangeMaterialCategory,
	onChangeMaterialType,
	materialTypesList,
	categoriesList,
	materialsList,
	numberOfPostProcessesOn,
	chosenMaterialType,
	chosenMaterialCategory,
	chosenMaterial,
	showMaterialsFilters,
	originalFilterButtonClicked,
	onPostProcessesClick,
	onChangeOrientationClick,
	partSolution,
	configurationChanged,
	trayOrientationCustom,
	trayOrientationLoader,
	updatedPostProcess,
	printable,
	simpleConfiguration,
	disableAdvancedButtons,
	cluster,
	part,
	configuration,
	configurationPrintIssues,
	initialToleranceValue,
	lowestToleranceValue,
	onTolerancesClick,
	disableConfiguration,
	manufacturingMethod,
	isSpecifiedQuantity
}) => {
	const [toleranceValue, setToleranceValue] = useState<string | number>(
		initialToleranceValue
	)
	const partEntity = part || cluster
	useEffect(() => {
		if (
			typeof lowestToleranceValue === 'number' &&
			partEntity?.customToleranceValue ===
				ToleranceClass.TOLERANCE_CLASS_IRRELEVANT
		) {
			setToleranceValue(lowestToleranceValue)
		} else {
			const _class =
				Object.entries(ToleranceClass).find(
					([key, val]) => val === initialToleranceValue
				)?.[0] || ''
			setToleranceValue(_class)
		}
	}, [
		initialToleranceValue,
		lowestToleranceValue,
		partEntity.customToleranceValue
	])
	const blockManufacturingMethodOperation =
		partEntity?.blockManufacturingMethodOperation || false

	const standardCost = configuration?.standardCost || partEntity?.standardCost
	const isStandardCost =
		manufacturingMethod === manufacturingMethodTypes.standardCost ||
		(blockManufacturingMethodOperation && standardCost)
	return (
		<Flexbox
			className="material-configuration with-scroll"
			data-qa="data-qa-general-properties-section"
			flexDirection="column"
			alignItems="flex-start"
			width="35%"
			padding="0 12px"
			maxHeight="300px"
		>
			<Flexbox flexDirection="column" alignItems="flex-start">
				<div className="materials-title">{getString('GENERAL_PROPERTIES')}</div>
				{!partEntity?.isDrawing && isSpecifiedQuantity ? (
					<div className="material-selector-configure-wrapper">
						<div
							className={`text_field__label material-selector-configure-batch-size-text`}
						>
							{getString('BATCH_SIZE')}
						</div>
						<NumberField
							disabled={disableConfiguration || isStandardCost}
							value={batchSize}
							onChangeValue={(value: number) =>
								onBatchSizeChange(configuration.id, value)
							}
							changeOnScroll={true}
							inputClass="material-selector-configure-batch-size-input"
							variant="standard"
						/>
					</div>
				) : (
					<></>
				)}
				{!simpleConfiguration && !partEntity?.isDrawing ? (
					<div className="materials-selector-wrapper">
						<div
							onClick={() =>
								originalFilterButtonClicked(
									showMaterialsFilters,
									configurationId
								)
							}
							className="material-selector-configure-materials-button"
							data-qa="data-qa-original-material-menu-btn"
						>
							{getString('ORIGINAL_MATERIALS_BUTTON')}
							<IconFactory
								iconName="chevronRight"
								className={`arrow-down ${
									showMaterialsFilters ? 'arrow-down-rotate' : ''
								}`}
							/>
						</div>
						<p className="material-selector-configure-chosen-material-text">
							{chosenMaterial && !showMaterialsFilters
								? chosenMaterial.name
								: ''}
						</p>
					</div>
				) : (
					<div />
				)}
				{renderMaterialsSolutionSelector(
					chosenMaterial,
					chosenMaterialType,
					chosenMaterialCategory,
					materialTypesList,
					categoriesList,
					materialsList,
					configurationId,
					showMaterialsFilters,
					simpleConfiguration,
					onChangeMaterial,
					onChangeMaterialCategory,
					onChangeMaterialType
				)}
				{!disableConfiguration && partSolution && (
					<>
						<Flexbox alignItems="center" margin="5px 0 0">
							<TransparentButton
								disabled={disableAdvancedButtons}
								className="solution-analysis-object-content-configure--transparent-button"
								onClick={() => onPostProcessesClick(configurationId)}
							>
								{`+ ${getString(
									'POST_PROCESSES'
								)} (${numberOfPostProcessesOn})`}
							</TransparentButton>
							<InfoBox
								boxDirection="bottom-start"
								boxContact={getString('POST_PROCESSES_GENERAL_TITLE')}
								iconClassName="icon-info"
							/>
						</Flexbox>

						{renderTrayOrientationButton(
							configurationId,
							partSolution,
							batchSize,
							configurationChanged,
							updatedPostProcess,
							onChangeOrientationClick,
							trayOrientationLoader,
							trayOrientationCustom,
							printable,
							disableAdvancedButtons,
							cluster,
							part,
							configuration,
							configurationPrintIssues
						)}
						{!partEntity?.isDrawing &&
							renderToleranceButton(
								disableAdvancedButtons,
								onTolerancesClick,
								toleranceValue
							)}
					</>
				)}
			</Flexbox>
		</Flexbox>
	)
}

export default memo(SolutionConfigureMaterials)
