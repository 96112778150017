import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AdminPrinterNameForm from './AdminPrinterNameForm'
import * as AdminPrinterNamesActions from './AdminPrinterNamesActions'

class NewAdminPrinterName extends Component {
  onSubmit = data => {
    this.props.createNewPrinterName(data)
  }

  render() {
    return <AdminPrinterNameForm onSubmit={this.onSubmit} />
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...AdminPrinterNamesActions }, dispatch)
}

export default connect(
  null,
  mapDispatchToProps
)(NewAdminPrinterName)
