import React, { memo } from 'react'

import MaterialQuantityRibbon from './MaterialQuantityRibbon/MaterialQuantityRibbon'
import SolutionAnalysisTabs from './SolutionAnalysisTabs/SolutionAnalysisTabs'
import SolutionPartDetails from './SolutionPartDetails/SolutionPartDetails'
import Flexbox from 'Scenes/Components/FlexBox'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'

interface IProps {
	part: Part
	configuration: any
	cluster: any
	freezeConfiguration: boolean
	chosenMaterialType: string
	chosenMaterialCategory: string
	categoriesList: Array<string>
	materialTypesList: Array<string>
	batchSize: number
	showAnalysis: boolean
	onChangeMaterialType: (event: any) => any
	onChangeMaterialCategory: (event: any) => any
	onCostQuantityChanged: Function
	onPostProcesses: (id: number, showCheckbox: true) => any
	onChangeViewer: Function
}

const SolutionAnalysisContent: React.FC<IProps> = ({
	part,
	configuration,
	cluster,
	freezeConfiguration,
	chosenMaterialType,
	chosenMaterialCategory,
	categoriesList,
	materialTypesList,
	batchSize,
	showAnalysis,
	onChangeMaterialType,
	onChangeMaterialCategory,
	onCostQuantityChanged,
	onPostProcesses,
	onChangeViewer
}: IProps) => {
	return (
		<>
			<MaterialQuantityRibbon
				configuration={configuration}
				chosenMaterialType={chosenMaterialType}
				chosenMaterialCategory={chosenMaterialCategory}
				categoriesList={categoriesList}
				materialTypesList={materialTypesList}
				batchSize={batchSize}
				showAnalysis={showAnalysis}
				onChangeMaterialType={onChangeMaterialType}
				onChangeMaterialCategory={onChangeMaterialCategory}
				onCostQuantityChanged={onCostQuantityChanged}
				onPostProcesses={onPostProcesses}
			/>
			{Feature.isFeatureOn(FeatureComponentId.MATERIAL_QUANTITY_RIBBON) && (
				<div className="part-file-name">{part?.partFileName}</div>
			)}
			<Flexbox
				alignItems="flex-start"
				justifyContent="space-between"
				className="solution-analysis-object-content-details"
			>
				<SolutionPartDetails
					onChangeViewer={onChangeViewer}
					configurationId={configuration.id}
					configuration={configuration}
					freezeConfiguration={freezeConfiguration}
				/>
				<div className="solution-analysis-object-content-tabs">
					<SolutionAnalysisTabs
						cluster={cluster}
						part={part}
						configuration={configuration}
					/>
				</div>
			</Flexbox>
		</>
	)
}

export default memo(SolutionAnalysisContent)
