if (!String.prototype.format) {
  String.prototype.format = function() {
    // usage: "{0} is dead, but {1} is alive! {0} {2}".format("ASP", "ASP.NET")
    let args = arguments
    const regex = /{(\d+)}/g //finds a digit [0-9] (inside {}) from 0 and goes up by 1
    return this.replace(regex, function(match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match
    })
  }
}
