import { PRINTING_SAVING_TYPES } from '../models/ResultsSelectionOptions'
import {
	get,
	getLicense,
	getUserRemainingPartsCreditNonAdminUsers,
	post,
	put
} from '../Network'

export const getUserActivityPanelData = () => get(`/myPanel/userActivity`)
export const getLicenseData = () => getLicense()
export const getUserRemainingPartsCredit = () =>
	getUserRemainingPartsCreditNonAdminUsers()
export const getUserPrintingSaving = () => get(`/myPanel/userPrintingSaving`)
export const updateUserPrintingSaving = (
	type: PRINTING_SAVING_TYPES,
	threshold: number
) => {
	const params = { type, threshold }
	return put(`/myPanel/userPrintingSaving`, params)
}
export const getUserROI = () => get(`/myPanel/roi`)
export const updateUserPanelROI = (
	type: PRINTING_SAVING_TYPES,
	threshold: number,
	printerId: string
) => {
	const params = { type, threshold, printerId }
	return post(`/myPanel/roi`, params)
}

export const sendProjectFailedToAdmins = (projectId: number) => {
	const params = { projectId }
	return post(`/sendProjectFailedToAdmins`, params)
}
