import { POLLER_REACHED_TIMEOUT } from '../Strings'

class Poller {
  constructor(
    intervalTimeSec,
    timeoutDateTime,
    networkCallFunc,
    handleResolveFunc,
    checkStatus = (res) => {},
  ) {
    this.intervalTimeSec = intervalTimeSec
    this.timeoutDateTime = timeoutDateTime
    this.networkCallFunc = networkCallFunc
    this.handleResolveFunc = handleResolveFunc
    this.checkStatus = checkStatus
  }

  settimeoutDateTime = timeoutDateTime => {
    this.timeoutDateTime = timeoutDateTime
  }

  start = () => {
    const that = this
    return new Promise((resolve, reject) => {
      this.pollerInterval = setInterval(() => {
        const nowDateTime = new Date()
        if (nowDateTime >= that.timeoutDateTime) {
          this.stop()
          reject(POLLER_REACHED_TIMEOUT)
        }

        this.networkCallFunc()
          .then(res => {
            this.checkStatus(res)
            if (this.handleResolveFunc(res)) {
              this.stop()
              resolve(res)
            }
          })
          .catch(error => {
            this.stop()
            reject(error)
          })
      }, this.intervalTimeSec)
    })
  }

  stop = () => {
    clearInterval(this.pollerInterval)
    this.pollerInterval = 0
  }
}

export default Poller
