import fileSaver from 'file-saver'

import {
	ADMIN_PROJECT_DATEPICKER_TOGGLED,
	ADMIN_PROJECT_DOWNLOAD_CLICKED,
	ADMIN_PROJECT_FETCHED,
	ADMIN_PROJECT_FILTER_TYPE_CHANGED,
	ADMIN_PROJECT_GENERATE_REPORT_LOADING,
	ADMIN_PROJECT_SEARCH_PHRASE_CHANGED,
	ADMIN_PROJECT_SELECTED,
	ADMIN_PROJECT_UPDATE_TOGGLED,
	ADMIN_PROJECT_UPDATED,
	ALERT_CANCEL_CLICKED_ADMIN_PROJECTS,
	EXPLODE_PROJECT_SUCCESS,
	GET_ADMIN_PROJECTS,
	GET_ADMIN_PROJECTS_SUCCESS,
	GET_ADMIN_PROJECTS_UNMOUNTED,
	HANDLE_NOTIFICATION,
	PUBLISH_PROJECT_ADMIN_PAGE,
	PUBLISH_PROJECT_ADMIN_PAGE_SUCCESS,
	PUBLISH_PROJECT_ADMIN_PAGE_SURE_CLICKED,
	SERVER_CALL_FAILED_ADMIN
} from '../../../../global actions/types'
import {
	explodeProject,
	exportDataToXLSX,
	getProjectDownloadLink
} from '../../../../Services/Network'
import { getProjectAdmin } from '../../../../Services/Network'
import {
	getProjectsAdmin,
	publishProjectAdmin
} from '../../../../Services/Network'
import { updateProject } from '../../../../Services/Network/Admin/ProjectNetwork'
import {
	ACTION_UNAUTHORIZED_MASSAGE,
	ADMIN_TABLE_NO_DATA_FOUND,
	ERROR_UPDATING_TAGS,
	EXPORT_FILE_NAME,
	PROJECT_ANALYSYS_ERROR_DOWNLOAD,
	PROJECT_UPDATED_SUCCESS,
	SHOW_NOTIFICATION
} from '../../../../Services/Strings'

export const setupAdminProjectsPage = (
	searchPhrase,
	pageNumber,
	userId,
	limitProjects,
	filterType
) => {
	return dispatch => {
		getProjectsAdmin(
			searchPhrase,
			pageNumber,
			userId,
			limitProjects,
			filterType
		)
			.then(response => {
				dispatch({
					type: GET_ADMIN_PROJECTS_SUCCESS,
					payload: { data: response.data, userId, pageNumber, searchPhrase }
				})
			})
			.catch(error => {
				console.log(error)
				dispatch({ type: SERVER_CALL_FAILED_ADMIN, payload: error.message })
			})
		dispatch({ type: GET_ADMIN_PROJECTS })
	}
}

export const onAdminProjectsUnmounted = () => {
	return {
		type: GET_ADMIN_PROJECTS_UNMOUNTED
	}
}

export const publishProjectClicked = project => {
	return { type: PUBLISH_PROJECT_ADMIN_PAGE, payload: project }
}
export const cancelAlert = () => {
	return { type: ALERT_CANCEL_CLICKED_ADMIN_PROJECTS }
}

export const setSearchPhrase = searchPhrase => {
	return {
		type: ADMIN_PROJECT_SEARCH_PHRASE_CHANGED,
		payload: searchPhrase
	}
}

export const datePickersToggled = show => {
	return {
		type: ADMIN_PROJECT_DATEPICKER_TOGGLED,
		payload: show
	}
}

export const setFilterType = filterType => {
	return {
		type: ADMIN_PROJECT_FILTER_TYPE_CHANGED,
		payload: filterType
	}
}

export const onReportLoading = isLoading => {
	return dispatch => {
		dispatch({
			type: ADMIN_PROJECT_GENERATE_REPORT_LOADING,
			payload: isLoading
		})
	}
}

export const downloadReport = (startDate, endDate) => {
	return dispatch => {
		dispatch(onReportLoading(true))
		dispatch(exportProjectsDataToExcel(startDate, endDate))

		dispatch({ type: ADMIN_PROJECT_DATEPICKER_TOGGLED, payload: false })
	}
}

export const exportProjectsDataToExcel = (startDate, endDate) => {
	return async dispatch => {
		try {
			const blob = await exportDataToXLSX(
				'exportProjectsDataToExcel',
				startDate,
				endDate,
				new Date().getTimezoneOffset()
			)
			if (blob) {
				fileSaver.saveAs(blob, EXPORT_FILE_NAME)
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.WARN,
						notificationMessage: ADMIN_TABLE_NO_DATA_FOUND
					}
				})
			}
			dispatch(onReportLoading(false))
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: PROJECT_ANALYSYS_ERROR_DOWNLOAD
				}
			})
		}
	}
}

export const explodeProjectClicked = project => {
	return dispatch => {
		explodeProject(project)
			.then(updateProject => {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: PROJECT_UPDATED_SUCCESS
					}
				})
				dispatch({ type: EXPLODE_PROJECT_SUCCESS, payload: { updateProject } })
			})
			.catch(error => {
				console.log(error)
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: error.message || ERROR_UPDATING_TAGS
					}
				})
			})
	}
}
export const publishConfirmed = project => {
	return dispatch => {
		publishProjectAdmin(project.id)
			.then(() => {
				dispatch({ type: PUBLISH_PROJECT_ADMIN_PAGE_SUCCESS })
			})
			.catch(error => {
				console.log(error)
				dispatch({ type: SERVER_CALL_FAILED_ADMIN, payload: error.message })
			})
		dispatch({ type: PUBLISH_PROJECT_ADMIN_PAGE_SURE_CLICKED })
	}
}

export const onProjectUpdateSubmitClick = (projectId, project) => {
	return async dispatch => {
		try {
			dispatch({
				type: ADMIN_PROJECT_UPDATE_TOGGLED
			})
			const response = await updateProject(projectId, project)
			dispatch({
				type: ADMIN_PROJECT_UPDATED,
				payload: { projectUpdated: response.data.projectUpdated }
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: PROJECT_UPDATED_SUCCESS
				}
			})
		} catch (error) {
			console.error(error)
			const unAuthorized =
				error.code === 401 ? ACTION_UNAUTHORIZED_MASSAGE : null
			dispatch({
				type: ADMIN_PROJECT_UPDATE_TOGGLED
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						unAuthorized || error.validationMessage || ERROR_UPDATING_TAGS
				}
			})
		}
	}
}

export const onProjectDownloadClick = projectId => {
	return async dispatch => {
		try {
			dispatch({
				type: ADMIN_PROJECT_DOWNLOAD_CLICKED,
				payload: { projectId }
			})
			const response = await getProjectDownloadLink(projectId)
			dispatch({
				type: ADMIN_PROJECT_DOWNLOAD_CLICKED,
				payload: { projectId }
			})
			window.open(response.data.projectDownloadURL)
		} catch (error) {
			console.error(error)
			dispatch({
				type: ADMIN_PROJECT_DOWNLOAD_CLICKED,
				payload: { projectId }
			})
		}
	}
}
export const onProjectClick = project => {
	return { type: ADMIN_PROJECT_SELECTED, payload: { project } }
}

export const fetchProject = id => {
	return async dispatch => {
		dispatch({ type: GET_ADMIN_PROJECTS })
		try {
			const response = await getProjectAdmin(id)
			dispatch({
				type: ADMIN_PROJECT_FETCHED,
				payload: { project: response.data.project }
			})
			return
		} catch (error) {
			console.error(error)
			dispatch({ type: SERVER_CALL_FAILED_ADMIN })
		}
	}
}
