import {
  USER_PROFILE_NAME_CHANGED,
  SETUP_PROFILE_DETAILS,
  USER_PROFILE_EMAIL_CHANGED,
  USER_PROFILE_COMPANY_CHANGED,
  USER_PROFILE_LOADER,
  USER_PROFILE_UPDATED,
  USER_DETAIL_CHANGED,
  HANDLE_NOTIFICATION,
  USER_PROFILE_ADDRESS_CHANGED
} from '../../../global actions/types'
import { updateUserProfileNetwork } from '../../../Services/Network'
import {
  SHOW_NOTIFICATION,
  PROFILE_NOTIFICATION_SUCCESS,
  PROFILE_NOTIFICATION_ERROR,
  ERROR,
  SUCCESS
} from '../../../Services/Strings'

export const setupProfileDetails = userDetails => {
  return {
    type: SETUP_PROFILE_DETAILS,
    payload: userDetails
  }
}

export const onUserNameChange = value => {
  let validUserName = SUCCESS
  if (!value || !value.trim()) {
    validUserName = ERROR
  }
  return {
    type: USER_PROFILE_NAME_CHANGED,
    payload: { userName: value, validUserName }
  }
}

export const onUserEmailChange = value => {
  let validUserEmail = SUCCESS
  if (!verifyEmail(value)) {
    validUserEmail = ERROR
  }
  return {
    type: USER_PROFILE_EMAIL_CHANGED,
    payload: { userEmail: value, validUserEmail }
  }
}

export const onUserCompanyChange = value => {
  let validUserCompany = SUCCESS
  if (!value || !value.trim()) {
    validUserCompany = ERROR
  }
  return {
    type: USER_PROFILE_COMPANY_CHANGED,
    payload: { userCompany: value, validUserCompany }
  }
}

export const onValidAddressChange = validAddress => {
  return {
    type: USER_PROFILE_ADDRESS_CHANGED,
    payload: validAddress
  }
}

export const updateUserProfile = userDetail => {
  return async dispatch => {
    dispatch({
      type: USER_PROFILE_LOADER,
      payload: true
    })
    try {
      const response = await updateUserProfileNetwork(userDetail)
      dispatch({
        type: USER_PROFILE_LOADER,
        payload: false
      })
      const { userDetails } = response.data
      dispatch({
        type: USER_PROFILE_UPDATED,
        payload: { ...userDetails }
      })
      dispatch({
        type: USER_DETAIL_CHANGED,
        payload: userDetails
      })
      dispatch({
        type: HANDLE_NOTIFICATION,
        payload: {
          notificationType: SHOW_NOTIFICATION.SUCCESS,
          notificationMessage: PROFILE_NOTIFICATION_SUCCESS
        }
      })
    } catch (error) {
      dispatch({
        type: USER_PROFILE_LOADER,
        payload: false
      })
      dispatch({
        type: HANDLE_NOTIFICATION,
        payload: {
          notificationType: SHOW_NOTIFICATION.ERROR,
          notificationMessage: error.message || PROFILE_NOTIFICATION_ERROR
        }
      })
      console.log(error)
    }
  }
}

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (emailRex.test(value)) {
    return true
  }
  return false
}
