import { FC, memo } from 'react'

import Danger from 'Scenes/Components/thirdParty/CreativeTim/components/Typography/Danger'
import { PUBLISHED } from 'Services/Constants'
import { Project } from 'Services/models/IProject'
import { PROJECT_ANALYSIS_WARNING } from 'Services/Strings'

interface ProjectAdminWarningProps {
	project: Project
}

const ProjectAdminWarning: FC<ProjectAdminWarningProps> = ({ project }) => {
	if (!project || project.status === PUBLISHED) {
		return <div />
	}
	return <Danger>{PROJECT_ANALYSIS_WARNING}</Danger>
}

export default memo(ProjectAdminWarning)