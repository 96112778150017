import Flexbox from 'Scenes/Components/FlexBox'
import React, { ChangeEvent, FC, memo, useState } from 'react'

import {
	FormControl,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select
} from '@material-ui/core'

import IconFactory from '../StarIcon/IconFactory'

import './SelectWithIcons.scss'

const SelectTSX: any = Select
const ListItemIconTSX: any = ListItemIcon
const ListItemTextTSX: any = ListItemText

export interface SelectWithIconsMenuItem {
  iconName: string
  value: string
  text: string
}

interface IProps {
  menuItems: SelectWithIconsMenuItem[]
  initialValue?: string
  selectRootClassName?: string
  showTextOnSelect?: boolean
  onChange: Function,
  disableContentAnchorEl?: boolean
}

const findSelectedMenuItem = (
  menuItems: SelectWithIconsMenuItem[],
  value: string
): SelectWithIconsMenuItem => {
  const selectedMenuItem = menuItems.find(c => c.value === value)
  return selectedMenuItem || menuItems[0]
}

const SelectWithIcons: FC<IProps> = ({
  menuItems,
  onChange,
  showTextOnSelect = true,
  initialValue = '',
  selectRootClassName = '',
  disableContentAnchorEl
}) => {
  const [selectedItem, setSelectedItem] = useState<SelectWithIconsMenuItem>(
    findSelectedMenuItem(menuItems, initialValue)
  )

  const handleChange = (e: ChangeEvent<{ value: string }>) => {
    const selectedValue = e.target.value
    if (selectedValue !== selectedItem.value) {

      setSelectedItem(findSelectedMenuItem(menuItems, selectedValue))
      onChange(selectedValue)
    }
  }

  const renderValue = () => (
    <Flexbox alignItems="center">
      <ListItemIconTSX className="select-with-icons--icon">
        <IconFactory
          iconName={selectedItem.iconName}
          className="select-with-icons--icon--svg"
        />
      </ListItemIconTSX>
      {showTextOnSelect && <ListItemTextTSX primary={selectedItem.text} />}
    </Flexbox>
  )

  return (
    <FormControl className="select-with-icons--formControl">
      <SelectTSX
        disableUnderline={true}
        IconComponent={() => (
          <IconFactory
            iconName="selectArrow"
            className="select-with-icons--select--icon"
          />
        )}
        displayEmpty
        id="demo-simple-select-outlined"
        className="test2"
        MenuProps={{
          PopoverClasses: {
            root: 'dropdown'
          },
	        ...(disableContentAnchorEl ? { getContentAnchorEl: null } : {})
        }}
        classes={{
          root: `select-with-icons--select--root ${selectRootClassName}`
        }}
        value={selectedItem.value}
        renderValue={renderValue}
        onChange={handleChange}
      >
        {menuItems.map(({ iconName, value, text }: SelectWithIconsMenuItem) => (
          <MenuItem className="test4" value={value} key={value}>
            <ListItemIconTSX className="select-with-icons--icon">
              <IconFactory
                iconName={iconName}
                className="select-with-icons--icon--svg"
              />
            </ListItemIconTSX>
            <ListItemTextTSX
              primary={text}
              classes={{ primary: 'select-with-icons--item--text' }}
              className="test5"
            />
          </MenuItem>
        ))}
      </SelectTSX>
    </FormControl>
  )
}

export default memo(SelectWithIcons)