import React, { FC, memo } from 'react'

import IconFactory from '../StarIcon/IconFactory'

import './StatsSimpleDataCard.scss'

interface IProps {
  mainData: string | number
  explenation: string
  iconName?: string
  className?: string
  contentClassName?: string
  iconClassName?: string
  contentMainDataClassName?: string
  explenationClassName?: string,
  qaDataElementName?: string
}

const renderDataIcon = (iconName?: string, iconClassName?: string) => {
  if (!iconName) {
    return <div></div>
  }

  return (
    <IconFactory
      iconName={iconName}
      className={`stats-simple-card--content--icon ${iconClassName}`}
      small
    />
  )
}
const checkValue = (value: number | string) => {
  if (value === 0) {
    return value
  }
  if (value == null) {
    return 'X'
  }
  return value
}

const StatsSimpleDataCard: FC<IProps> = ({
  mainData,
  iconName,
  explenation,
  className = '',
  contentClassName = '',
  contentMainDataClassName = '',
  explenationClassName = '',
  iconClassName = '',
  qaDataElementName
}) => {
  return (
    <div className={`stats-simple-card ${className}`}>
      <div className={`stats-simple-card--content ${contentClassName}`}>
        <div
          className={`stats-simple-card--content--main ${contentMainDataClassName}`}
          data-qa={qaDataElementName || `data-qa-${explenation}-value`}
        >
          {checkValue(mainData)}
        </div>
        {renderDataIcon(iconName, iconClassName)}
      </div>
      <div className={`stats-simple-card--explenation ${explenationClassName}`}>
        {explenation}
      </div>
    </div>
  )
}

export default memo(StatsSimpleDataCard)
