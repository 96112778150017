import React, { ChangeEvent, FC, memo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { togglePrintingAlert } from '../../MainPartAnalysis/MainPartAnalysisActions'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import FlexBox from 'Scenes/Components/FlexBox'
import { onAcceptPrintingStandards } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisActions'
import {
	PrintingStandardsEnum,
	PrintingStandardsList
} from 'Scenes/Home/NewUploadProject/UploadBlocks/QuickUploadFiles/FileRowColumns/PrintingStandards'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'
import { OK } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface ConfigurationRemoveAlertProps {
	part: Part
}

const PrintingStandardsAlert: FC<ConfigurationRemoveAlertProps> = ({
	part
}) => {
	const [checked, setChecked] = useState(false)
	const dispatch = useDispatch()

	const user = useSelector((state: RootStateOrAny) => state.user)
	const { showPrintingStandardAlert } = useSelector(
		(state: RootStateOrAny) => state.MainPartAnalysisReducer
	)
	const printingStandardsList = user.printingStandardsList
	const printStandard = printingStandardsList.find(
		(standard: PrintingStandardsList) =>
			standard.key === part?.printingStandards
	)

	const isPrintingStandards = Feature.isFeatureOn(
		FeatureComponentId.PRINTING_STANDARDS
	)

	if (
		part?.printingStandardsAlertAccepted ||
		!isPrintingStandards ||
		!part?.printingStandards ||
		part?.printingStandards === PrintingStandardsEnum.UNKNOWN
	)
		return <></>

	return (
		<CastorAlert
			showCancel={false}
			headerTitle={getString('PRINTING_STANDARD_POPUP_TITLE')}
			show={showPrintingStandardAlert}
			onCancel={() => dispatch(togglePrintingAlert(false))}
			onConfirm={() => {
				if (checked && !part.printingStandardsAlertAccepted) {
					dispatch(onAcceptPrintingStandards(part.id, checked))
				}
				dispatch(togglePrintingAlert(false))
			}}
			confirmOptionalText={OK}
		>
			<div className="printing-standard">
				<div>
					{getString('PRINTING_STANDARD_POPUP_TITLE_IS').format(
						getString(printStandard.title)
					)}
				</div>
				<br />
				<div>{getString(printStandard.description)}</div>
				<br />
				<FlexBox flexDirection="row" alignItems="center">
					<CastorCheckbox
						checked={checked}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							setChecked(e.target.checked)
						}}
					/>
					<div>{getString('DO_NOT_SHOW_AGAIN')}</div>
				</FlexBox>
			</div>
		</CastorAlert>
	)
}

export default WithFeatureToggleHOC(
	memo(PrintingStandardsAlert),
	FeatureComponentId.PRINTING_STANDARDS
)
