import React, {
	ChangeEvent,
	FC,
	memo,
	useEffect,
	useMemo,
	useState
} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'

import { MenuItem } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import { capitalize, toLower } from 'lodash'

import {
	MATERIAL_TABLE_TEXTS,
	SUBMIT,
	UNDO_CHANGES
} from '../../../Services/Strings'
import { renderSelectFieldTouched } from '../../admin/AdminHome/AdminFields/AdminSelectField'
import InfoBox from '../InfoBox'
import { Button } from '../thirdParty/CreativeTim/components'
import { materialTypes, surfaceFinishMethods } from './constants'
import {
	getAverageByCategory,
	materialTypeChange,
	onChangeCertificatedValue,
	resetForm,
	validate
} from './MaterialFormService'
import Flexbox from 'Scenes/Components/FlexBox'
import { specialMaterial } from 'Scenes/Components/PrinterMaterialForm/constants'
import {
	isCommaKeyPressed,
	isDisabledKeyPressed,
	isDotKeyPressed
} from 'Services/getKeyCodesService'
import {
	IMaterialCategory,
	Material,
	MaterialType,
	MaterialTypeEnum,
	SurfaceFinishMethod
} from 'Services/models/IMaterial'
import { getString } from 'Services/Strings/StringService'

import './index.scss'
import 'Scenes/admin/AdminHome/adminHome.scss'

const NUMBER_TYPE = 'number'

type IProps = {
	handleSubmit: (onSubmit: Function) => React.FormEventHandler<HTMLFormElement>
	pristine: boolean
	reset: () => void
	submitting: boolean
	onSubmit: (data: Record<string, any>) => void
	valid: boolean
	materialCategories: IMaterialCategory[]
	change: (name: string, value: any) => void
	onMaterialTypeChange: () => void
	allMaterialCategories: IMaterialCategory[]
	initialValues: Record<string, any>
	defaultFilters: Record<string, boolean>
	addingNewItem: boolean
	userCurrencySign: string
	initialized: boolean
	selectedMaterialType: MaterialType
	selectedMaterialCategory: string
	selectedSurfaceFinishMethod: SurfaceFinishMethod
	materials: Material[]
}

const renderTypeSelectedItems = (
	change: (name: string, value: any) => void,
	onMaterialTypeChange: () => void,
	allMaterialCategories: IMaterialCategory[],
	initialized: boolean,
	selectedMaterialType: MaterialType
) => {
	return (
		<Field
			name={'type'}
			component={renderSelectFieldTouched}
			label={MATERIAL_TABLE_TEXTS.TYPE}
			custom={{
				value: selectedMaterialType || '',
				'data-qa': 'admin-user-material-type',
				onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
					materialTypeChange(
						e,
						change,
						onMaterialTypeChange,
						allMaterialCategories
					)
			}}
			initialized={initialized}
		>
			<MenuItem disabled>{MATERIAL_TABLE_TEXTS.TYPE}</MenuItem>
			{materialTypes.map(materialType => {
				return (
					<MenuItem
						data-qa={`admin-user-material-type-${toLower(materialType)}`}
						key={materialType}
						value={materialType}
					>
						{capitalize(materialType)}
					</MenuItem>
				)
			})}
		</Field>
	)
}

const renderCategorySelectedItems = (
	change: (name: string, value: any) => void,
	materialCategories: IMaterialCategory[],
	initialized: boolean,
	selectedMaterialCategory: string
) => {
	if (!materialCategories || !materialCategories.length) {
		return <div />
	}
	return (
		<Field
			name={'category'}
			component={renderSelectFieldTouched}
			label={MATERIAL_TABLE_TEXTS.CATEGORY}
			custom={{
				value: capitalize(selectedMaterialCategory) || '',
				'data-qa': 'admin-user-material-category',
				onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
					change('category', e.target.value)
			}}
			initialized={initialized}
		>
			<MenuItem disabled>{MATERIAL_TABLE_TEXTS.CATEGORY}</MenuItem>
			{materialCategories.map(materialCategory => {
				return (
					<MenuItem
						data-qa={`admin-user-material-category-${toLower(
							materialCategory.name
						)}`}
						key={materialCategory.id}
						value={capitalize(materialCategory.name)}
					>
						{materialCategory.name}
					</MenuItem>
				)
			})}
		</Field>
	)
}

const renderSurfaceFinishMethodSelectedItems = (
	selectedSurfaceFinishMethod: SurfaceFinishMethod,
	change: (name: string, value: any) => void,
	initialized: boolean
) => {
	return (
		<Field
			name="surfaceFinishMethod"
			data-qa="admin-user-material-surfaceFinishMethod"
			component={renderSelectFieldTouched}
			label={MATERIAL_TABLE_TEXTS.METHOD}
			custom={{
				value: selectedSurfaceFinishMethod || '',
				onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
					change('surfaceFinishMethod', e.target.value)
			}}
			initialized={initialized}
		>
			<MenuItem disabled>{MATERIAL_TABLE_TEXTS.METHOD}</MenuItem>
			{surfaceFinishMethods.map(surfaceFinishMethod => {
				return (
					<MenuItem key={surfaceFinishMethod} value={surfaceFinishMethod}>
						{surfaceFinishMethod}
					</MenuItem>
				)
			})}
		</Field>
	)
}

const renderTextField = ({
	input,
	label,
	InputProps,
	meta: { touched, error },
	...custom
}: any) => (
	<TextField
		label={label}
		hintText={label}
		floatingLabelText={label}
		error={touched && error}
		helperText={error}
		{...input}
		{...custom}
		onKeyDown={(e: ChangeEvent<HTMLInputElement> | KeyboardEvent) => {
			const disableSymbolsIfNoValue =
				(isCommaKeyPressed(e as KeyboardEvent) ||
					isDotKeyPressed(e as KeyboardEvent)) &&
				!input.value

			if (custom.type === NUMBER_TYPE) {
				if (
					isDisabledKeyPressed(e as KeyboardEvent) ||
					disableSymbolsIfNoValue
				) {
					e.preventDefault()
				}
			}
		}}
		InputProps={InputProps}
		onWheel={(event: ChangeEvent<HTMLInputElement>) => event.target.blur()}
	/>
)

const renderSpecialMaterial = (
	defaultFilters: Record<string, any>,
	change: (name: string, value: any) => void
) => {
	const filters = defaultFilters ? defaultFilters : {}
	return (
		<div className="checkboxes-block">
			<p>{specialMaterial.label}</p>
			<div className="special-materials">
				{specialMaterial?.checkBoxes.map(checkBox => {
					return (
						<FormControlLabel
							control={
								<Checkbox
									data-qa={`admin-user-material-checkbox-${checkBox.name}`}
									checked={filters[checkBox.name] || false}
									onChange={e =>
										onChangeCertificatedValue(
											change,
											filters,
											checkBox.name,
											e.target.checked
										)
									}
								/>
							}
							label={checkBox?.label}
						/>
					)
				})}
			</div>
		</div>
	)
}

const MaterialForm: FC<IProps> = props => {
	const {
		handleSubmit,
		pristine,
		reset,
		submitting,
		onSubmit,
		valid,
		materialCategories,
		change,
		onMaterialTypeChange,
		allMaterialCategories,
		initialValues,
		defaultFilters,
		addingNewItem,
		userCurrencySign,
		initialized,
		selectedMaterialType,
		selectedMaterialCategory,
		selectedSurfaceFinishMethod,
		materials
	} = props
	const [averageByCategory, setAverageByCategory] = useState(0)

	const averageByCategories = useMemo(
		() => getAverageByCategory(materials),
		[materials]
	)

	const isMaterialTypeMetal =
		selectedMaterialType && selectedMaterialType === MaterialTypeEnum.metal

	const isMaterialTypePlastic =
		selectedMaterialType && selectedMaterialType === MaterialTypeEnum.plastic

	useEffect(() => {
		if (selectedMaterialCategory) {
			setAverageByCategory(
				averageByCategories[toLower(selectedMaterialCategory)]?.average
			)
		}
	}, [averageByCategories, selectedMaterialCategory])

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="admin-form">
			<div>
				<Field
					data-qa="admin-user-material-name"
					className="admin-form-field"
					name="name"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.NAME}
				/>
			</div>

			<div>
				{renderTypeSelectedItems(
					change,
					onMaterialTypeChange,
					allMaterialCategories,
					initialized,
					selectedMaterialType
				)}
			</div>

			<div>
				{renderCategorySelectedItems(
					change,
					materialCategories,
					initialized,
					selectedMaterialCategory
				)}
			</div>
			<div>
				<Field
					data-qa="admin-user-material-yieldStrengthMPa"
					className="admin-form-field"
					name="yieldStrengthMPa"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.YIELD_STRENGTH}
					type={NUMBER_TYPE}
				/>
			</div>
			<div>
				<Field
					data-qa="admin-user-material-percentElongationAtBreak"
					className="admin-form-field"
					name="percentElongationAtBreak"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.ELONGATION}
					type={NUMBER_TYPE}
				/>
			</div>

			<div>
				<Field
					data-qa="admin-user-material-youngsModulus"
					className="admin-form-field"
					name="youngsModulus"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.YOUNGS_MODULUS}
					type={NUMBER_TYPE}
				/>
			</div>

			<div>
				<Field
					className="admin-form-field"
					data-qa="admin-user-material-density"
					name="density"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.DENCITY}
					type={NUMBER_TYPE}
					InputProps={{ inputProps: { min: 0, step: 0.01 } }}
				/>
			</div>

			<div>
				<Field
					className="admin-form-field"
					data-qa="admin-user-material-ultimateTensileStrength"
					name="ultimateTensileStrength"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.TENSILE_STRENGTH}
					type={NUMBER_TYPE}
				/>
			</div>

			{isMaterialTypePlastic ? (
				<div>
					<Field
						className="admin-form-field"
						data-qa="admin-user-material-maximumServiceTemperature"
						name="maximumServiceTemperature"
						component={renderTextField}
						label={MATERIAL_TABLE_TEXTS.SERVICE_TEMPERATURE}
						type={NUMBER_TYPE}
						InputProps={{ inputProps: { min: 0, step: 0.01 } }}
					/>
				</div>
			) : (
				<div />
			)}

			{isMaterialTypeMetal ? (
				<div>
					<Field
						className="admin-form-field"
						data-qa="admin-user-material-thermalConductivity"
						name="thermalConductivity"
						component={renderTextField}
						label={MATERIAL_TABLE_TEXTS.THERMAL_CONDUCTIVITY}
						type={NUMBER_TYPE}
					/>
				</div>
			) : (
				<div />
			)}
			<div className="form-multiple-fields">
				<div className="form-field-in-multiple first">
					<Field
						className="admin-form-field"
						name="surfaceFinish"
						data-qa="admin-user-material-surfaceFinish"
						component={renderTextField}
						label={MATERIAL_TABLE_TEXTS.SURFACE_FINISH}
						type={NUMBER_TYPE}
					/>
				</div>
				<div className="form-field-in-multiple last">
					{renderSurfaceFinishMethodSelectedItems(
						selectedSurfaceFinishMethod,
						change,
						initialized
					)}
				</div>
			</div>
			{selectedMaterialCategory ? (
				<div className="admin-form-with-info">
					<Flexbox>
						<Field
							className="admin-form-field"
							name="co2perKgMaterial"
							data-qa="admin-user-material-co2perKgMaterial"
							component={renderTextField}
							label={MATERIAL_TABLE_TEXTS.CO2_PER_KG}
							type={NUMBER_TYPE}
						/>
						<InfoBox
							boxContact={`${getString('CO2_PER_KG_MATERIAL_INFO_STRING')}${
								selectedMaterialCategory && averageByCategory
									? getString(
											'CO2_PER_KG_MATERIAL_AVERAGE_PER_CATEGORY'
									  ).format(selectedMaterialCategory, averageByCategory)
									: ''
							}`}
							iconClassName="admin-form-field__info_icon"
						/>
					</Flexbox>
				</div>
			) : (
				<div />
			)}

			{isMaterialTypeMetal ? (
				<>
					<div>
						<Field
							className="customize-form-field"
							name="traditionalCost.CNC"
							data-qa="admin-user-material-traditionalCostCNC"
							component={renderTextField}
							label={MATERIAL_TABLE_TEXTS.COST_CNC.format(userCurrencySign)}
							type={NUMBER_TYPE}
							InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
						/>
					</div>
					<div>
						<Field
							className="customize-form-field"
							name="traditionalCost.Cast"
							data-qa="admin-user-material-traditionalCostCast"
							component={renderTextField}
							label={MATERIAL_TABLE_TEXTS.COST_CAST.format(userCurrencySign)}
							type={NUMBER_TYPE}
							InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
						/>
					</div>
				</>
			) : (
				<div />
			)}

			{isMaterialTypePlastic ? (
				<>
					<div>
						<Field
							className="customize-form-field"
							name="traditionalCost.Mold"
							data-qa="admin-user-material-traditionalCostMold"
							component={renderTextField}
							label={MATERIAL_TABLE_TEXTS.COST_MOLD.format(userCurrencySign)}
							type={NUMBER_TYPE}
							InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
						/>
					</div>
					<div>
						<Field
							className="customize-form-field"
							name="traditionalCost.CNC"
							data-qa="admin-user-material-traditionalCostCNC"
							component={renderTextField}
							label={MATERIAL_TABLE_TEXTS.COST_CNC.format(userCurrencySign)}
							type={NUMBER_TYPE}
							InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
						/>
					</div>
				</>
			) : (
				<div />
			)}

			{renderSpecialMaterial(defaultFilters, change)}

			<div>
				<Button
					data-qa="admin-user-material-submit"
					size="sm"
					color="primary"
					type="submit"
					disabled={!valid || addingNewItem}
				>
					{SUBMIT}
				</Button>
				<Button
					size="sm"
					color="primary"
					type="button"
					disabled={pristine || submitting}
					onClick={resetForm.bind(
						this,
						reset,
						allMaterialCategories,
						onMaterialTypeChange,
						initialValues
					)}
				>
					{UNDO_CHANGES}
				</Button>
			</div>
		</form>
	)
}

export default memo(
	compose(
		connect((state: any, props: any) => {
			return {
				form: props.formName
			}
		}),
		reduxForm<any, any>({
			validate,
			enableReinitialize: true
		})
	)(MaterialForm)
)
