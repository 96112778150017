import { FC, memo } from 'react'
import { useDispatch } from 'react-redux'

import { stopAllRecalculatingAlert } from 'Scenes/admin/AdminHome/AdminSettings/AdminSettingsActions'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import HideForRoleHOC from 'Services/HOC/HideForRoleHOC'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

const AdminStopRecalculating: FC = () => {
	const dispatch = useDispatch()

	return (
		<CastorForm
			formTitle={getString('ADMIN_STOP_RECALCULATE_TITLE')}
			content={
				<div>
					<div style={{ width: '200px' }}>
						<Button
							style={{ backgroundColor: 'red', borderColor: 'red' }}
							onClick={() => dispatch(stopAllRecalculatingAlert())}
							qaDataElementName="recalculate-start-btn"
						>
							{getString('ADMIN_STOP_RECALCULATE')}
						</Button>
					</div>
				</div>
			}
			style={{ maxWidth: 'unset' }}
		/>
	)
}

export default memo(
	HideForRoleHOC(
		WithFeatureToggleHOC(
			AdminStopRecalculating,
			FeatureComponentId.RECALCULATE_PROJECTS
		),
		[UserRole.SUPER_ADMIN],
		true
	)
)
