import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { AM_MATERIAL_IDS, STEP_ID } from '../../constants'
import { goToNextStep } from '../../OnboardingWizardActions'
import { useStep } from '../../OnboardingWizardHooks'
import OnboardingStepFrame from '../OnboardingStepFrame'
import AddAMMaterialButton from './AddAMMaterialButton'
import CustomizeUserPrinterMaterialsTable from 'Scenes/Home/Customize/CustomizeUserMaterials/NewAMMaterial/CustomizeUserPrinterMaterialsTable'

const AMMaterialsComponents = {
	[AM_MATERIAL_IDS.ADD_AM_MATERIAL_BUTTON]: <AddAMMaterialButton />,
	[AM_MATERIAL_IDS.AM_MATERIALS_TABLE]: <CustomizeUserPrinterMaterialsTable />
}

const AMMaterial: FC = () => {
	const { steps, parentSteps, currentStep } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)

	const { userPrinterMaterials: customUserPrinterMaterials, userId } =
		useSelector((state: RootStateOrAny) => state.CustomizeUserMaterialsReducer)
	const { userPrinterMaterials } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { step, stepContent, childSteps } = useStep(
		steps,
		STEP_ID.AM_MATERIAL,
		true
	)
	const dispatch = useDispatch()

	const changesInCurrentStep = userId
		? customUserPrinterMaterials.length > 0
		: userPrinterMaterials.length > 0

	const onContinueClick = () => {
		const stepIndexInChildSteps =
			childSteps && childSteps.findIndex(childStep => childStep.id === step.id)
		const stepID =
			stepIndexInChildSteps !== childSteps.length - 1
				? childSteps[stepIndexInChildSteps + 1].id
				: undefined
		dispatch(goToNextStep(parentSteps, currentStep, stepID))
	}

	return (
		<OnboardingStepFrame
			step={step}
			childSteps={childSteps}
			onNextClickAnotherAction={onContinueClick}
			changesInCurrentStep={changesInCurrentStep}
		>
			<div className="tm-material--wrapper">
				{stepContent.map((component, index) => {
					return (
						<React.Fragment key={component.id}>
							{
								AMMaterialsComponents[
									component.id as keyof typeof AMMaterialsComponents
								]
							}
						</React.Fragment>
					)
				})}
			</div>
		</OnboardingStepFrame>
	)
}

export default AMMaterial
