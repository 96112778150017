import { SAVE_BEFORE_LOGOUT_LS_KEYS_LIST } from './Constants'

export const setJsonItemToLocalStorage = (key: string, value: any): void =>
	localStorage.setItem(key, JSON.stringify(value))

export const setStringItemToLocalStorage = (key: string, value: string): void =>
	localStorage.setItem(key, value)

export const getJsonItemFromLocalStorage = (key: string): any =>
	JSON.parse(
		(localStorage.getItem(key) === 'undefined'
			? false
			: localStorage.getItem(key)) || '{}'
	)

export const getStringItemFromLocalStorage = (key: string): string =>
	localStorage.getItem(key) || ''

export const removeItemFromLocalStorage = (key: string): void =>
	localStorage.removeItem(key)

export const getLocaleFromLocalStorage = (): string =>
	getStringItemFromLocalStorage('locale')

export const getReloadFromLocalStorage = (): string =>
	getStringItemFromLocalStorage('reload')

export const getCurrencyFromLocalStorage = (): string =>
	getStringItemFromLocalStorage('currency')

export const getUserStringsFromLocalStorage = (): any =>
	getJsonItemFromLocalStorage('userStrings')

export const isUserStringsExistInLocalStorage = (): boolean =>
	localStorage.getItem('userStrings') != null

export const setLocaleInLocalStorage = (selectedLanguage: string): void =>
	setStringItemToLocalStorage('locale', selectedLanguage)

export const setReloadInLocalStorage = (): void =>
	setStringItemToLocalStorage('reload', 'true')

export const setCurrencyInLocalStorage = (selectedCurrency: string): void =>
	setStringItemToLocalStorage('currency', selectedCurrency)

export const removeAllLocalStorage = (
	lsKeysToSave = SAVE_BEFORE_LOGOUT_LS_KEYS_LIST
): void => {
	if (lsKeysToSave && lsKeysToSave.length) {
		const lsObject = {} as Record<string, string>
		lsKeysToSave.forEach(key => {
			lsObject[key] = getStringItemFromLocalStorage(key)
		})
		localStorage.clear()
		lsKeysToSave.forEach(key => {
			setStringItemToLocalStorage(key, lsObject[key])
		})
	} else {
		localStorage.clear()
	}
}
export const saveConfigurationsStateToLocalStorage = (state: any): void =>
	setJsonItemToLocalStorage('userConfigurationsState', state)

export const getConfigurationsStateFromLocalStorage = (): any =>
	getJsonItemFromLocalStorage('userConfigurationsState')

export const removeConfigurationsStateFromLocalStorage = (): void =>
	removeItemFromLocalStorage('userConfigurationsState')

export const getCallbackURLFromLocalStorage = (): string =>
	getStringItemFromLocalStorage('callbackURL')

export const setCallbackURLInLocalStorage = (callbackURL: string): void =>
	setStringItemToLocalStorage('callbackURL', callbackURL)
