import { History } from 'history'
import { isEmpty } from 'lodash'
import { ISolutionConfiguration } from 'Services/models/ISolutionConfiguration'

import {
  BEFORE_SEND_PART_TO_CONSUMER,
  FINISH_SEND_PART_TO_CONSUMER,
  SEND_PART_TO_CONSUMER_REMOVE,
  START_SEND_PART_TO_CONSUMER
} from '../../global actions/types/partAnalysisTypes'
import { USER_HOME_ROUTE } from '../../Services/Constants/RoutesConstants'
import { IUserProvider } from '../../Services/models/IUserProvider'
import { IntegrationProviderService } from './IntegrationProviderService'
import { Part } from 'Services/models/IPart'

export const onBeforeSendPartToProvider = (
  userEmail: string,
  queryParams: any,
  redirect_uri: string,
  history: History
) => {
  return async (dispatch: any) => {
    dispatch({
      type: START_SEND_PART_TO_CONSUMER
    })
    const integrationProviderService = new IntegrationProviderService()
    await integrationProviderService.updateToken({
      provider_guid: queryParams.state.provider_printer.auth.guid,
      user_email: userEmail,
      code: queryParams.code,
      redirect_uri
    })
    dispatch({
      type: BEFORE_SEND_PART_TO_CONSUMER,
      payload: {
        providerState: queryParams.state
      }
    })
    history.push(
      `${USER_HOME_ROUTE}${
        queryParams.state.location.split('?')[0].split('/home')[1]
      }`
    )
  }
}

export const onSendPartToProvider = (
  partName: string,
  partStlUrl: string,
  partCastorPath: string,
  userProvider: IUserProvider,
  providerState: any,
  userEmail: string,
  configuration: any,
  partActualName?: string
) => {
  return async (dispatch: any) => {
    dispatch({
      type: SEND_PART_TO_CONSUMER_REMOVE
    })

    if (!isEmpty(providerState)) {
      const integrationProviderService = new IntegrationProviderService()
      await integrationProviderService.sendPartToProvider(
        partName,
        partStlUrl,
        partCastorPath,
        userProvider,
        userEmail,
        configuration,
        partActualName
      )
    }
  }
}

export const onSendPartToProviderClick = (
  part: Part,
  configuration: ISolutionConfiguration,
  partCastorPath: string,
  userProvider: IUserProvider,
  providerPrinterId: number,
  userEmail: string
) => {
  return async (dispatch: any) => {
    dispatch({ type: START_SEND_PART_TO_CONSUMER })
    const integrationProviderService = new IntegrationProviderService()
    if (!userProvider) return
    await integrationProviderService.sendPartToProvider(
      part.partNumber + '.stl',
      part.stlURL,
      partCastorPath,
      userProvider,
      userEmail,
      configuration,
      part.partNumber
    )

    dispatch({ type: FINISH_SEND_PART_TO_CONSUMER })

    dispatch({
      type: SEND_PART_TO_CONSUMER_REMOVE
    })
  }
}
