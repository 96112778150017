import React, { FC, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { SKIP_KEY_CODE, STEP_ID } from './constants'
import OnboardingProgressBar from './OnboardingProgressBar/OnboardingProgressBar'
import AddPrinter from './OnboardingSteps/AddPrinter'
import AddPrinterForm from './OnboardingSteps/AddPrinterForm'
import Advanced from './OnboardingSteps/Advanced/Advanced'
import AMForm from './OnboardingSteps/AMForm'
import AMMaterial from './OnboardingSteps/AMMaterial'
import CostAndSupplyChain from './OnboardingSteps/CostAndSupplyChain/CostAndSupplyChain'
import CostFunctionEditor from './OnboardingSteps/CostFunctionEditor/CostFunctionEditor'
import CostModel from './OnboardingSteps/CostModel/CostModel'
import CustomizeCostParams from './OnboardingSteps/CustomizeCostParams/CustomizeCostParams'
import DoneScreen from './OnboardingSteps/DoneScreen/DoneScreen'
import InHousePrinters from './OnboardingSteps/InHousePrinters/InHousePrinters'
import MaterialNames from './OnboardingSteps/MaterialNames/MaterialNames'
import PrintersAndMaterialsCosts from './OnboardingSteps/PrintersAndMaterialsCosts/PrintersAndMaterialsCosts'
import SolutionFiltering from './OnboardingSteps/SolutionFiltering/SolutionFiltering'
import StartScreen from './OnboardingSteps/StartScreen/StartScreen'
import Sustainability from './OnboardingSteps/Sustainability/Sustainability'
import TMForm from './OnboardingSteps/TMForm'
import TMMaterial from './OnboardingSteps/TMMaterial/TMMaterial'
import { skipOrCompleteOnboarding } from './OnboardingWizardActions'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import { useSkipKeyDown } from 'Services/CustomHooks/useSkipKeyDown'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { ONBOARDING_WIZARD_PAGE_NAME } from 'Services/Strings'

import './index.scss'

const ONBOARDING_STEPS = {
	[STEP_ID.START_SCREEN]: <StartScreen />,
	[STEP_ID.DONE_SCREEN]: <DoneScreen />,
	[STEP_ID.COST_MODEL]: <CostModel />,
	[STEP_ID.COST_FUNCTION_EDITOR]: <CostFunctionEditor />,
	[STEP_ID.IN_HOUSE_PRINTERS]: <InHousePrinters />,
	[STEP_ID.PRINTERS_AND_MATERIALS_COSTS]: <PrintersAndMaterialsCosts />,
	[STEP_ID.COST_AND_SUPPLY_CHAIN]: <CostAndSupplyChain />,
	[STEP_ID.MATERIAL_NAMES]: <MaterialNames />,
	[STEP_ID.SOLUTION_FILTERING]: <SolutionFiltering />,
	[STEP_ID.ADVANCED]: <Advanced />,
	[STEP_ID.SUSTAINABILITY]: <Sustainability />,
	[STEP_ID.TM_MATERIAL]: <TMMaterial />,
	[STEP_ID.TM_FORM]: <TMForm />,
	[STEP_ID.AM_MATERIAL]: <AMMaterial />,
	[STEP_ID.AM_FORM]: <AMForm />,
	[STEP_ID.ADD_PRINTER]: <AddPrinter />,
	[STEP_ID.ADD_PRINTER_FORM]: <AddPrinterForm />,
	[STEP_ID.CUSTOMIZE_COST_PARAMS]: <CustomizeCostParams />
}

const OnboardingWizard: FC = () => {
	const { progressBarSteps, currentStepId, currentStep } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { userDetails } = useSelector((state: RootStateOrAny) => state.user)
	const dispatch = useDispatch()
	const history = useHistory()

	useSkipKeyDown(() => {
		dispatch(skipOrCompleteOnboarding(history))
	})

	useEffect(() => {
		if (userDetails?.organization_owner === false) {
			dispatch(skipOrCompleteOnboarding(history))
		}
	}, [dispatch, history, userDetails?.organization_owner])

	return (
		<NavBarAndMaterial title={ONBOARDING_WIZARD_PAGE_NAME}>
			<div className="wizard--wrapper">
				<OnboardingProgressBar
					steps={progressBarSteps}
					currentStep={currentStep}
				/>
				<>{ONBOARDING_STEPS[currentStepId as keyof typeof ONBOARDING_STEPS]}</>
			</div>
		</NavBarAndMaterial>
	)
}

export default WithFeatureToggleHOC(
	OnboardingWizard,
	FeatureComponentId.ONBOARDING_WIZARD
)
