import { ChangeEvent, FC, memo } from 'react'
import { useDispatch } from 'react-redux'

import { Divider, ListItemText, MenuItem, Select } from '@material-ui/core'
import isArray from 'lodash/isArray'
import orderBy from 'lodash/orderBy'

import {
	changeAdvancedFilterTechnology,
	changeAdvancedMultipleFilterTechnology
} from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { IFilterData } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsInterface'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import Button from 'Scenes/Components/thirdParty/CreativeTim/components/CustomButtons/Button'
import {
	IPrintingCategories,
	IPrintingTechnology
} from 'Services/models/IPrintingTechnology'
import { CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface MultiCheckboxFilterProps {
	id: string | number
	filter: IFilterData
}

const renderMaterialNamesMenuItems = (
	dataOptions: any = [],
	selectedOptions: any = [],
	onChangeFilterValue: any,
	onChangeMultipleFilterValue: any
) => {
	const lastChecked = selectedOptions.length === 1
	let lastSection: string
	return dataOptions.map((data: any) => {
		let isNewSection = false
		let toDivide = false
		if (data.section && (!lastSection || lastSection !== data.section)) {
			isNewSection = true
			toDivide = !lastSection ? false : true
			lastSection = data.section
		}
		const checked = isArray(selectedOptions)
			? selectedOptions?.indexOf(data.key) > -1
			: selectedOptions

		return (
			<>
				{toDivide ? <Divider className={'select-section-divider'} /> : <></>}
				{isNewSection ? (
					<label className={'select-section-title'}>{lastSection}:</label>
				) : (
					<></>
				)}
				<MenuItem
					className="select-toggle-custom"
					key={data.key}
					value={data.key}
					onClick={(e: any) => {
						e.stopPropagation()
						onChangeMultipleFilterValue([data.key])
					}}
				>
					<CastorCheckbox checked={checked} disabled={checked && lastChecked} />
					<ListItemText
						className="select-toggle-custom-label"
						primary={data.value}
					/>
					<div
						className="select-toggle-only"
						onClick={(e: React.MouseEvent) => {
							e.stopPropagation()
							onChangeFilterValue([data.key])
						}}
					>
						{getString('SELECT_ONLY_TECHNOLOGY')}
					</div>
				</MenuItem>
			</>
		)
	})
}

const MultiCheckboxFilter: FC<MultiCheckboxFilterProps> = ({ id, filter }) => {
	const dispatch = useDispatch()
	const value = filter?.value as Array<any> | null
	const onChangeFilterValue = (value: string[] | null) => {
		dispatch(changeAdvancedFilterTechnology(id, value, filter.name))
	}
	const onChangeMultipleFilterValue = (value: string[] | null) => {
		dispatch(changeAdvancedMultipleFilterTechnology(id, value, filter.name))
	}
	let selectedOptions =
		value && value.length > 0
			? filter.value
			: filter?.selectOptions?.map(option => option.name)

	let dataOptions: any[] = []
	filter?.selectOptions?.forEach(
		(printingTechnology: IPrintingTechnology | IPrintingCategories | any) =>
			dataOptions.push({
				key: printingTechnology.name,
				value: printingTechnology.userReadableName,
				section: printingTechnology?.astmIsoName || null
			})
	)

	if (dataOptions[0].section) {
		dataOptions = orderBy(dataOptions, ['section'], ['asc'])
	}

	return (
		<Select
			className="select filter-fields--widget--text-field-box"
			MenuProps={{
				variant: 'menu',
				getContentAnchorEl: null
			}}
			multiple
			disableUnderline={true}
			value={selectedOptions}
			onChange={(e: ChangeEvent<any>) => {
				const valueLength = e.target.value?.length
				if (valueLength > 0) {
					onChangeFilterValue(e.target.value)
					if (valueLength === dataOptions?.length) {
						onChangeFilterValue(null)
					}
				}
			}}
			renderValue={(selected: any) =>
				selected.length === dataOptions?.length
					? getString('ALL')
					: getString('CUSTOM')
			}
		>
			<MenuItem value="0" className="select-toggle-all">
				<Button
					onClick={(e: any) => {
						e.stopPropagation()
						onChangeFilterValue(null)
					}}
					title={CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM}
					style={{ margin: '0 auto', padding: 'unset', width: 'fit-content' }}
				>
					{filter.selectHeader}
				</Button>
			</MenuItem>
			{dataOptions?.length ? (
				renderMaterialNamesMenuItems(
					dataOptions,
					selectedOptions,
					onChangeFilterValue,
					onChangeMultipleFilterValue
				)
			) : (
				<div />
			)}
		</Select>
	)
}

export default memo(MultiCheckboxFilter)
