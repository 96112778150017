import { FC, memo } from 'react'

import Subheader from '@material-ui/core/ListSubheader'

import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'


const SubheaderTSX: any = Subheader

interface WeightReducedPartsSubHeaderProps {
	weightReducedParts: Part[]
}

const WeightReducedPartsSubHeader: FC<WeightReducedPartsSubHeaderProps> = ({ weightReducedParts }) => {
	return (
		<SubheaderTSX component="div">
			<h4 className="parts-header--title parts-header--title--advanced">
				{getString('WEIGHT_REDUCTION_RESULTS')} ({weightReducedParts.length})
			</h4>
		</SubheaderTSX>
	)
}

export default memo(WeightReducedPartsSubHeader)