import React, { FC, memo } from 'react'
import {
  PART_ANALYSIS_CONFIGURE,
  PART_ANALYSIS_NON_PRINTABLE_BUTTON,
  PART_ANALYSIS_NON_PRINTABLE_DESCRIPTION,
  PART_ANALYSIS_TOP_DETAILS_NO_SOLUTION
} from '../../../../../../../../../Services/Strings'
import ButtonWithLoader
  from '../../../../../../../../Components/ButtonWithLoader'
import IconFactory
  from '../../../../../../../../Components/StarIcon/IconFactory'

import ResultDetail from '../../../../SolutionAnalysisTopDetails/ResultDetail'
import '../../SolutionAnalysisTabs.scss'
import {
  Feature,
  FeatureComponentId
} from '../../../../../../../../../Services/models/Features'

interface IProps {
  result: string
  resultTitle: string
  configurationResultBody: string
  solution: any
  showAnalysis: boolean
  onConfigureClick: Function
  onRetrieveResultClick: Function
}

const renderDetails = (
  solution: any,
  showAnalysis: boolean,
  onConfigureClick: Function,
  onRetrieveResultClick: Function
) => {
  const showHeaderButtons =
    Feature.isFeatureOn(FeatureComponentId.CONFIGURATION_HEADER) || false
  const retrieveResultMode =
    Feature.isFeatureOn(FeatureComponentId.RETRIEVE_RESULTE_UNPRINTABLE)

  if ((retrieveResultMode && !showAnalysis) && solution) {
    return (
      <div className="cost-summary-tab--unprintable--explanation">
        <div className="solution-header-no-configuration cost-summary-tab--unprintable--explanation--text">
          {PART_ANALYSIS_NON_PRINTABLE_DESCRIPTION}
        </div>
        <ButtonWithLoader
          color="primary"
          onClick={onRetrieveResultClick}
          className="solution-header-no-configuration-button"
        >
          {PART_ANALYSIS_NON_PRINTABLE_BUTTON}
        </ButtonWithLoader>
      </div>
    )
  }
  if (!solution && showHeaderButtons) {
    return (
      <div className="cost-summary-tab--unprintable--explanation">
        <div className="solution-header-no-configuration cost-summary-tab--unprintable--explanation--text">
          {PART_ANALYSIS_TOP_DETAILS_NO_SOLUTION[0]}
          <IconFactory
            iconName="configure"
            className="solution-header-no-configuration-text-icon"
          />
          {PART_ANALYSIS_TOP_DETAILS_NO_SOLUTION[1]}
        </div>
        <ButtonWithLoader
          data-qa="data-qa-project-configure"
          color="primary"
          onClick={onConfigureClick}
          className="solution-header-no-configuration-button"
        >
          <IconFactory
            iconName="configure"
            className="solution-header-no-configuration-button-icon"
          />
          {PART_ANALYSIS_CONFIGURE}
        </ButtonWithLoader>
      </div>
    )
  }
}

const CostSummaryUnprintable: FC<IProps> = ({
  result,
  resultTitle,
  configurationResultBody,
  solution,
  showAnalysis,
  onConfigureClick,
  onRetrieveResultClick
}) => {
  return (
    <div>
      <ResultDetail
        result={result}
        title={resultTitle}
        body={configurationResultBody}
      />
      {renderDetails(
        solution,
        showAnalysis,
        onConfigureClick,
        onRetrieveResultClick
      )}
    </div>
  )
}

export default memo(CostSummaryUnprintable)
