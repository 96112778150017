import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { FormControlLabel } from '@material-ui/core'

import {
	Feature,
	FeatureComponentId
} from '../../../../Services/models/Features'
import { getString } from '../../../../Services/Strings/StringService'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import CastorSwitch from '../../../Components/CastorSwitch'
import InfoBox from '../../../Components/InfoBox'
import CustomizeSupplyChainTbl from './CustomizeSupplyChainTbl'
import { onSupplyChainCostChanged } from 'global actions'
import Flexbox from 'Scenes/Components/FlexBox'

import './CustomizeSupplyChain.scss'

interface IProps {
	disabled?: boolean
	showOnlyToggle?: boolean
}

const LabelFormControl: any = FormControlLabel

const CustomizeSupplyChain: FC<IProps> = ({
	disabled = false,
	showOnlyToggle = false
}) => {
	const { userCustomizationSettings } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const dispatch = useDispatch()
	const supplyChainOn = userCustomizationSettings?.includeSupplyChainCosts
	const projectSettingsScenariosOn =
		Feature.isFeatureOn(FeatureComponentId.PROJECT_SETTINGS_SCENARIOS) &&
		!showOnlyToggle

	const supplyChainTable =
		Feature.isFeatureOn(FeatureComponentId.DEFAULT_SCENARIO) && !showOnlyToggle

	const onSupplyChainToggleChange = (supplyChainOn: boolean) => {
		dispatch(onSupplyChainCostChanged(supplyChainOn))
	}

	return (
		<div
			className="solution-feature-supply-chain"
			data-qa="data-qa-supply-chain-block"
		>
			<CastorFormHeader
				explanationHeader={
					projectSettingsScenariosOn
						? getString('CUSTOMIZE_SUPPLY_CHAIN_TITLE_CUSTOM')
						: getString('CUSTOMIZE_SUPPLY_CHAIN_TITLE')
				}
				explanationArray={
					projectSettingsScenariosOn
						? getString('CUSTOMIZE_SUPPLY_CHAIN_EXPLANATION_ARRAY_CUSTOM')
						: getString('CUSTOMIZE_SUPPLY_CHAIN_EXPLANATION_ARRAY')
				}
				isInCard={true}
			/>
			{supplyChainTable ? (
				<CustomizeSupplyChainTbl />
			) : (
				<Flexbox className="solution-feature-supply-chain--tog">
					<LabelFormControl
						control={
							<CastorSwitch
								disabled={disabled}
								onChange={(e: any) =>
									onSupplyChainToggleChange(e.target.checked)
								}
								checked={supplyChainOn}
							/>
						}
						label={getString('CUSTOMIZE_SUPPLY_CHAIN_LABEL')}
					/>
					<InfoBox
						boxContact={getString('CUSTOMIZE_SUPPLY_CHAIN_INFO')}
						boxContactClassName="solution-feature-supply-chain--info"
						boxDirection="right-end"
					/>
				</Flexbox>
			)}
		</div>
	)
}

export default CustomizeSupplyChain
