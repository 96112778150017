import parseHTML from 'html-react-parser'
import React, { FC, memo, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Fade } from '@material-ui/core'
import cx from 'classnames'

import { parseErrorMessage } from './ErrorPageService'
import Button from 'Scenes/Components/thirdParty/CreativeTim/components/CustomButtons/Button'
import { disableErrorRoutes, redirectMessage } from 'Services/Constants'
import { LOGIN_ROUTE } from 'Services/Constants/RoutesConstants'
import { uploadProjectRoute } from 'Services/routeFuncs'
import { LOGIN_TITLE } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as ErrorImage } from 'assets/img/svg/error-image.svg'

import './index.scss'

interface ErrorPageProps {
	errorMessage?: string
	errorDescription?: string
	mainPage?: boolean
	showMessageOnly?: boolean
}

const ErrorPage: FC<ErrorPageProps> = ({
	errorMessage = '',
	errorDescription = '',
	mainPage = false,
	showMessageOnly = false
}) => {
	const [showErrorPage, setShowErrorPage] = useState(false)
	const location = useLocation()
	const history = useHistory()
	const isDisableErrorPage = disableErrorRoutes.includes(location.pathname)

	// need to wait a bit to show an animation
	useEffect(() => {
		const showErrorPage = setTimeout(() => {
			setShowErrorPage(true)
		}, 600)

		return () => {
			clearTimeout(showErrorPage)
			setShowErrorPage(false)
		}
	}, [])

	useEffect(() => {
		if (errorDescription?.includes(redirectMessage)) {
			history.push(LOGIN_ROUTE)
		}
	}, [errorDescription])

	if (showMessageOnly)
		return (
			<div className={cx('page-not-exist only-message')}>
				<p>{errorMessage}</p>
			</div>
		)
	if (isDisableErrorPage) return <></>

	if (errorDescription || errorMessage) {
		return (
			<Fade timeout={600} in={showErrorPage}>
				<div className={cx('page-not-exist', { show: showErrorPage })}>
					<div className="text">{parseErrorMessage(errorMessage)}</div>
					<br />
					<div className="text small">
						{parseHTML(errorDescription?.split('.').join('<br/><br/>'))}
					</div>
					<br />
					<Button
						color="primary"
						onClick={() => {
							history.push(LOGIN_ROUTE)
						}}
						className="page-not-exist__button"
						type="button"
					>
						{LOGIN_TITLE}
					</Button>
				</div>
			</Fade>
		)
	}

	if (mainPage) {
		return <></>
	}

	return (
		<Fade timeout={600} in={showErrorPage}>
			<div className={cx('page-not-exist', { show: showErrorPage })}>
				<ErrorImage />
				<div className="text">{getString('PAGE_IS_NOT_EXIST_OR_ACCESS')}</div>
				<br />
				<Button
					color="primary"
					onClick={() => history.push(uploadProjectRoute())}
					className="page-not-exist__button"
					type="button"
				>
					{getString('LIGHT_USER_WARNING_BUTTON_TEXT')}
				</Button>
			</div>
		</Fade>
	)
}

export default memo(ErrorPage)
