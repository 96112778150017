import { AlertType } from './AlertTypes'
import { Action } from 'global actions/ActionModels'
import {
	ALERT_CALCULATION_FINISHED,
	ALERT_CALCULATION_STARTED,
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { getString } from 'Services/Strings/StringService'

export interface CastorAlertInitialState {
	readonly headerTitle: string
	readonly alertType: AlertType
	readonly show: boolean
	readonly alertClass: string
	readonly alertBodyClass: string
	readonly confirmText: string
	readonly cancelText: string
	readonly loadingCalculation: boolean
	readonly text: string
	readonly disabled: boolean
	readonly showConfirm: boolean
	readonly showCancel: boolean
	readonly extraButton: any
	readonly onConfirm: Function
	readonly onCancel: null | Function
	readonly onButtonHoverText?: string
	readonly closeOnClickOutside?: boolean
}

const initialState: CastorAlertInitialState = {
	headerTitle: '',
	alertType: '' as AlertType,
	show: false,
	alertClass: '',
	alertBodyClass: '',
	confirmText: getString('CONFIRM'),
	cancelText: getString('CANCEL'),
	loadingCalculation: false,
	text: '',
	disabled: false,
	showConfirm: true,
	showCancel: true,
	extraButton: null,
	onConfirm: () => {},
	onCancel: null,
	onButtonHoverText: '',
	closeOnClickOutside: true
}

export default (state = initialState, { type, payload }: Action<any>) => {
	switch (type) {
		case ALERT_POPPED:
			return {
				...state,
				...payload,
				show: true
			}

		case ALERT_CALCULATION_STARTED:
			return {
				...state,
				loadingCalculation: true
			}

		case ALERT_CALCULATION_FINISHED:
			return {
				...state,
				loadingCalculation: false
			}

		case ALERT_POPUP_CANCELED:
			return {
				...state,
				...initialState
			}

		default:
			return state
	}
}
