import { createContext, FC, ReactNode, useContext, useState } from 'react'

import { getString } from 'Services/Strings/StringService'

interface ErrorContextType {
	error: string | null
	setError: (error: string | null) => void
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined)

export const useError = (): ErrorContextType => {
	const context = useContext(ErrorContext)
	if (!context) {
		throw new Error(getString('CONTEXT_ERROR'))
	}
	return context
}

interface ErrorProviderProps {
	children: ReactNode
}

export const ErrorProvider: FC<ErrorProviderProps> = ({ children }) => {
	const [error, setError] = useState<string | null>(null)

	return (
		<ErrorContext.Provider value={{ error, setError }}>
			{children}
		</ErrorContext.Provider>
	)
}
