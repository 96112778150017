import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import cloneDeep from 'lodash/cloneDeep'

import { getString } from 'Services/Strings/StringService'
import {
	MAX_NUMBER_FIELD_VALUE,
	ZERO_NUMBER_FIELD_VALUE
} from 'Services/Constants'

export const CUSTOM = 'CUSTOM'
export const QUANTITY = 'quantity'
export const YEARS_OF_DEMAND = 'expectedYearsOfDemand'
export const minQuantity = 1
export const minYearsOfDemand = 1
export const maxYearsOfDemand = 20

const getMinMaxValues = (keyData: string) => {
	let min = undefined
	let max = undefined

	if (keyData === QUANTITY) {
		min = minQuantity
		max = MAX_NUMBER_FIELD_VALUE
	}

	if (keyData === YEARS_OF_DEMAND) {
		min = minYearsOfDemand
		max = maxYearsOfDemand
	}

	return { min, max }
}

export const getErrorMessage = (keyData: string, value: number) => {
	let errorMessage = null
	if (keyData === QUANTITY) {
		if (value < minQuantity || !value) {
			errorMessage = getString('NUMBER_VALIDATION_FROM').format(
				ZERO_NUMBER_FIELD_VALUE
			)
		}

		if (value > MAX_NUMBER_FIELD_VALUE) {
			errorMessage = getString('NUMBER_VALIDATION_FROM_TO').format(
				ZERO_NUMBER_FIELD_VALUE,
				MAX_NUMBER_FIELD_VALUE
			)
		}
	}

	if (
		keyData === YEARS_OF_DEMAND &&
		(value > maxYearsOfDemand || value < minYearsOfDemand || !value)
	) {
		errorMessage = getString('NUMBER_VALIDATION').format(
			minYearsOfDemand,
			maxYearsOfDemand
		)
	}

	return errorMessage
}

export const setupTableData = (defaultProjectScenarioParameters: any[]) => {
	const data = defaultProjectScenarioParameters.map(object => {
		return {
			key: object.key,
			name: object.name,
			info: object.information,
			type: object.type,
			data: []
		}
	})
	return data
}

export const setupValueLabels = (
	defaultProjectScenario: any[],
	defaultProjectScenarioParameters: any[]
) => {
	let preparedData: any = setupTableData(defaultProjectScenarioParameters)

	forEach(defaultProjectScenario, (data: any) => {
		forEach(preparedData, (table: any) => {
			const nameData = table.key
			table.data.push({
				name: nameData,
				value: data[nameData],
				labelKey: data.key,
				editable: data.key === CUSTOM,
				error: '',
				...getMinMaxValues(nameData)
			})
		})
	})
	return preparedData
}

export const setupValueToSend = (setupValueLabels: any) => {
	const data: any = {}

	forEach(setupValueLabels, (valueLabel: any) => {
		forEach(valueLabel.data, label => {
			if (label.labelKey === CUSTOM) {
				data[label.name] = label.value
			}
		})
	})

	return data
}

export const setupCustomValuesToSend = (
	preparedDataToUpdate: any,
	projectScenarioSettings: any
) => {
	const scenarioIndex = findIndex(
		projectScenarioSettings,
		(scenario: any) => scenario.key === CUSTOM
	)
	const scenarioData: any = projectScenarioSettings[scenarioIndex]
	for (const parameter in preparedDataToUpdate) {
		for (const scenarioParameter in scenarioData) {
			if (parameter === scenarioParameter) {
				scenarioData[parameter] = preparedDataToUpdate[scenarioParameter]
			}
		}
	}

	return scenarioData
}
