import { FC, memo } from 'react'

import cx from 'classnames'
import isNil from 'lodash/isNil'

import IconFactory from '../StarIcon/IconFactory'

import './DataTable.scss'

interface Props {
	text: string | number
	data: any
	className: string
}

const emptyFunction = () => {}

const renderTitle = (text: string | number) => {
	return (
		<div
			data-qa={`data-qa-part-filed-${text}`}
			className="data-table-row-text"
			title={isNil(text) ? '' : text.toString()}
		>
			{text}
		</div>
	)
}

const renderContent = (
	iconClassName: string,
	iconName: string,
	text: string | number
) => {
	return (
		<>
			{renderTitle(text)}
			<IconFactory
				iconName={iconName}
				className={cx(`data-table-row-${iconClassName}`, 'cursor-pointer')}
			/>
		</>
	)
}

const DataTableTextWithIconLink: FC<Props> = ({
	text,
	data: {
		onClick,
		iconName,
		iconClassName,
		iconOnTheRight,
		isLink = false,
		...restData
	},
	className
}) => (
	<div
		className={cx('data-table-row data-table-opacity-field', {
			'extra-field': restData.extra
		})}
	>
		<div
			onClick={isLink ? emptyFunction : onClick}
			className={cx(
				'data-table-row data-table-opacity-field',
				className,
				restData.className,
				iconOnTheRight ? 'icon-right' : 'icon-left'
			)}
			data-qa={`data-qa-parts-properties-pdf-icon-${text}`}
		>
			{isLink ? (
				<a
					href={restData?.property?.link}
					target="_blank"
					rel="noopener noreferrer"
				>
					{renderContent(iconClassName, iconName, text)}
				</a>
			) : (
				renderContent(iconClassName, iconName, text)
			)}
		</div>
		{restData.extra && (
			<IconFactory
				onClick={restData.extra.onClick}
				iconName={restData.extra.iconName}
				className={cx(
					`data-table-row-${restData.extra.iconClassName}`,
					'cursor-pointer'
				)}
				data-qa={`data-qa-parts-properties-cad-icon-${text}`}
			/>
		)}
	</div>
)

export default memo(DataTableTextWithIconLink)
