import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Slide, toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { bindActionCreators } from 'redux'

import * as CastorNotificationActions from './CastorNotificationActions'
import { SHOW_NOTIFICATION } from '../../../Services/Strings'

import './CastorNotification.css'

const notificationParams = {
	position: toast.POSITION.TOP_CENTER,
	autoClose: 3000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true
}

class CastorNotification extends Component {
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.renderNotification()
	}

	renderNotification = () => {
		const {
			clearNotification,
			notificationType,
			notificationMessage,
			notificationTime,
			removeNotification
		} = this.props
		if (removeNotification) {
			toast.dismiss()
			clearNotification()
			return
		}
		if (notificationMessage && notificationType) {
			switch (notificationType) {
				case SHOW_NOTIFICATION.SUCCESS:
					this.successNotification(notificationMessage, notificationTime)
					break
				case SHOW_NOTIFICATION.ERROR:
					this.errorNotification(notificationMessage, notificationTime)
					break
				case SHOW_NOTIFICATION.INFO:
					this.infoNotification(notificationMessage, notificationTime)
					break
				case SHOW_NOTIFICATION.WARN:
					this.warningsNotification(notificationMessage, notificationTime)
					break
				default:
					break
			}
			clearNotification()
		}
	}

	successNotification = (message, time) => {
		toast.success(message, {
			className: 'success_notification',
			...notificationParams,
			autoClose: time || notificationParams.autoClose
		})
	}

	errorNotification = (message, time) => {
		toast.error(message, {
			className: 'error_notification',
			...notificationParams,
			autoClose: time || 6000 || notificationParams.autoClose
		})
	}

	warningsNotification = (message, time) => {
		toast.warn(message, {
			className: 'warn_notification',
			...notificationParams,
			autoClose: time || notificationParams.autoClose
		})
	}

	infoNotification = (message, time) => {
		toast.info(message, {
			className: 'info_notification',
			...notificationParams,
			autoClose: time || notificationParams.autoClose
		})
	}

	render() {
		return (
			<div>
				<ToastContainer className="notification" transition={Slide} />
			</div>
		)
	}
}

const mapStateToProps = ({ CastorNotificationReducer }) => {
	return { ...CastorNotificationReducer }
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...CastorNotificationActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CastorNotification)
