import React from 'react'

const RowComponent = (props: any) => {
  return (
    <tr>
      <td>
        {props.children}
      </td>
    </tr>
  )
}

export default RowComponent
