import { Action } from '../../../global actions/ActionModels'
import {
  FETCH_QR,
  TWO_FACTOR_CODE_CHANGED,
  TWO_FACTOR_LOGIN_SUCCESSED,
  USER_SAVED_QR,
  TWO_FACTOR_AUTH_SETUP_FINISH,
  RESET_TOW_FACTOR_STATE,
  TOGGLE_ADMIN_LOGIN_HELP_POPUP,
  TOW_FACTOR_WRONG_CODE
} from '../../../global actions/types'

export interface TwoFactorAuthInitialState {
  readonly qr: string
  readonly qrSaved: boolean
  readonly initialSetup: boolean
  readonly codeInput: number | null
  readonly adminAuthenticated: boolean
  readonly setupEnd: boolean
  readonly showLoginHelpPopup: boolean
  readonly showCodeError: boolean
}

const initialState: TwoFactorAuthInitialState = {
  qr: '',
  codeInput: null,
  qrSaved: false,
  adminAuthenticated: false,
  initialSetup: false,
  setupEnd: false,
  showLoginHelpPopup: false,
  showCodeError: false
}

export default (state = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case FETCH_QR: {
      const { qr } = payload
      return {
        ...state,
        qr
      }
    }
    case USER_SAVED_QR: {
      return {
        ...state,
        qrSaved: true
      }
    }
    case TWO_FACTOR_CODE_CHANGED: {
      const { value } = payload
      return {
        ...state,
        codeInput: value,
        showCodeError: false
      }
    }
    case TWO_FACTOR_LOGIN_SUCCESSED: {
      return {
        ...state,
        adminAuthenticated: true
      }
    }
    case TWO_FACTOR_AUTH_SETUP_FINISH: {
      const { adminAuthenticated, setupExist } = payload
      return {
        ...state,
        adminAuthenticated,
        setupEnd: true,
        initialSetup: !setupExist
      }
    }
    case RESET_TOW_FACTOR_STATE: {
      return {
        ...state,
        ...initialState
      }
    }
    case TOGGLE_ADMIN_LOGIN_HELP_POPUP: {
      const { showLoginHelpPopup } = payload
      return {
        ...state,
        showLoginHelpPopup
      }
    }
    case TOW_FACTOR_WRONG_CODE: {
      return {
        ...state,
        showCodeError: true,
        codeInput: ''
      }
    }
    default:
      return state
  }
}
