import { FC, useCallback, useEffect, useRef, useState } from 'react'

import cx from 'classnames'

import './DropDownListMenu.scss'

interface Option {
	name: string
	element?: any
	onClick?: (event: any) => any
}

interface IProps {
	options: Option[]
	show: boolean
	parentId: string
	onClose: Function
	parentPosition?: any
	dropdownClass?: string
}

const DropDownListMenu: FC<IProps> = ({
	options,
	show,
	parentId,
	onClose,
	parentPosition,
	dropdownClass
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [parentHeight, setParentHeight] = useState(0)
	const ref = useRef<HTMLDivElement>(null)

	const handleClickOutside = (event: any) => {
		if (ref.current && !ref.current.contains(event.target)) {
			onClose()
		}
	}

	useEffect(() => {
		setIsOpen(show)
	}, [show])

	useEffect(() => {
		if (parentId) {
			const object = document.getElementById(parentId)
			setParentHeight(object?.offsetHeight || 0)
		}
	}, [parentId])

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true)
		return () => {
			document.removeEventListener('click', handleClickOutside, true)
		}
	})

	const onOptionClick = (e: any, option: any) => {
		onClose()
		option.onClick(e)
	}

	const styles = {
		...(parentPosition
			? {
					left: `${parentPosition?.left}px`,
					top: `${parentPosition?.top}px`
			  }
			: {
					top: parentHeight
			  })
	}

	return (
		<div
			className={cx('drop-down-list-menu', dropdownClass, {
				show: isOpen
			})}
			style={styles}
			ref={ref}
		>
			{options.map((option: Option, idx: number) => (
				<div
					key={option.name || idx}
					className="drop-down-list-menu--item"
					onClick={e => onOptionClick(e, option)}
				>
					{option.name || option.element}
				</div>
			))}
		</div>
	)
}

export default DropDownListMenu
