import {
  WALL_THICKNESS_CLICKED,
  WALL_THICKNESS_ALERT_CANCELED,
  WALL_THICKNESS_INPUT_CHANGED,
  WALL_THICKNESS_UPDATED,
  WALL_THICKNESS_POLLER_FINISHED,
  WALL_THICKNESS_CONFIRMED
} from '../../../global actions/types'

const INITIAL_STATE = {
  showAlert: false,
  customWallThickness: '',
  pollerStarted: false,
  disableAlertButton: null,
  loading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WALL_THICKNESS_CLICKED:
      return {
        ...state,
        showAlert: true
      }
    case WALL_THICKNESS_ALERT_CANCELED:
      return {
        ...state,
        showAlert: false,
        loading: false
      }
    case WALL_THICKNESS_INPUT_CHANGED:
      return {
        ...state,
        customWallThickness: action.payload,
        disableAlertButton: action.payload <= 0 || action.payload > 2.5
      }
    case WALL_THICKNESS_CONFIRMED:
      return {
        ...state,
        loading: true
      }
    case WALL_THICKNESS_UPDATED:
      return {
        ...state,
        pollerStarted: true,
        showAlert: false,
        loading: false
      }
    case WALL_THICKNESS_POLLER_FINISHED:
      return {
        ...state,
        pollerStarted: false
      }
    default:
      return state
  }
}
