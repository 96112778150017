import parseHTML from 'html-react-parser'
import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button } from '../../Components/thirdParty/CreativeTim/components'
import { CLOSE, PREV_PATH } from 'Scenes/Home/NewUploadProject/constants'
import {
	getStringItemFromLocalStorage,
	removeItemFromLocalStorage
} from 'Services/LocalStorageService'
import { getTheme } from 'themes/getTheme'

import logo from '../../../assets/img/castorLogo.png'
import agreementText from '../../../assets/json/AgreementText.json'
import agreementTextOnPrem from '../../../assets/json/onprem/AgreementText.json'

import './UsageAgreement.scss'

const { agreementLogo, showBackButton = true } = getTheme()

const UsageAgreement: FC = () => {
	const history = useHistory()
	const { isOnPrem } = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer
	)

	const agreement = isOnPrem ? agreementTextOnPrem : agreementText

	const renderAgreementText = () => {
		return agreement?.text?.map((text: any) => {
			return (
				<>
					<p className="text"> {parseHTML(text)}</p>
					<p>&nbsp;</p>
				</>
			)
		})
	}

	const onBackClick = () => {
		const prevPath = getStringItemFromLocalStorage(PREV_PATH)
		if (prevPath) {
			removeItemFromLocalStorage(PREV_PATH)
			if (prevPath === CLOSE) {
				window.close()
			} else {
				window.open(prevPath, '_self')
			}
		} else {
			history.goBack()
		}
	}

	const renderGoBackButton = () => {
		if (!showBackButton) return
		return <Button onClick={onBackClick}>{agreement?.backButton}</Button>
	}

	return (
		<div className="agreement-wrapper">
			<img className="logo" src={agreementLogo || logo} alt="logo" />
			{renderGoBackButton()}
			<div className="text-wrapper">
				<p className="version">{parseHTML(agreement?.version)}</p>
				<p className="header">{parseHTML(agreement?.header)}</p>
				<p>&nbsp;</p>
				{renderAgreementText()}
			</div>
			{renderGoBackButton()}
		</div>
	)
}

export default memo(UsageAgreement)
