import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { isEmpty, some } from 'lodash'
import Numeral from 'numeral'

import {
	OptionalPostProcessesIds,
	POPUP_ROWS
} from '../SolutionAnalysisTopConstants'
import { TopPostProcessesDetails } from './TopPostProcessesDetails'
import Devider from 'Scenes/Components/Devider/Devider'
import InfoBox from 'Scenes/Components/InfoBox'
import { CostAnalysisEnum } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostParametersEnum'
import { postProcessesConstants } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostParametersFormConstant'
import {
	PrintingStandardsEnum,
	PrintingStandardsList
} from 'Scenes/Home/NewUploadProject/UploadBlocks/QuickUploadFiles/FileRowColumns/PrintingStandards'
import {
	calculate2DRange,
	displayInRange
} from 'Services/global/calculateRange'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { CustomFunctionStringCategory } from 'Services/models/IFunctionString'
import { COST_ESTIMATE_WARNING, IMPORTANT } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const DeviderTSX: any = Devider

interface TopCostIconProps {
	costDetails: any
	costObjectValid: boolean
	userCurrencySign: string
	drawingCostPercentage?: number
	isSpecifiedQuantity: boolean
	costCalculatedWithCustomFunction: Record<
		CustomFunctionStringCategory,
		boolean
	> | null
	costCalculatedWithOverrideFunction: Record<CostAnalysisEnum, boolean> | null
	showCostInRange?: boolean
	printingStandards?: string | null
	configurationId: number,
	isWRConfig: boolean
}

const TopCostDetailsIcon: FC<TopCostIconProps> = ({
	costDetails,
	costObjectValid,
	userCurrencySign,
	drawingCostPercentage,
	showCostInRange = false,
	isSpecifiedQuantity,
	costCalculatedWithCustomFunction,
	costCalculatedWithOverrideFunction,
	printingStandards,
	configurationId,
	isWRConfig
}) => {
	const user = useSelector((state: RootStateOrAny) => state.user)
	const printingStandardsList = user.printingStandardsList
	const printStandard = printingStandardsList.find(
		(standard: PrintingStandardsList) => standard.key === printingStandards
	)
	const isShowValuesInRanges = Feature.isFeatureOn(
		FeatureComponentId.SHOW_VALUES_IN_RANGES
	)

	const isPrintingStandards = Feature.isFeatureOn(
		FeatureComponentId.PRINTING_STANDARDS
	)

	const printingCost = Math.ceil(costDetails.printingCost)
	const { maxCostDetails, minCostDetails } = costDetails

	let totalCost: string | number = Numeral(costDetails.totalCost).format('0,0')
	let supplyChainCost: string | number = Numeral(
		costDetails?.onlySupplyChainCost
	).format('0,0')
	let totalPrinting: string | number = Numeral(printingCost).format('0,0')
	const productionCostCalculatedWithCustomFunction =
		costCalculatedWithCustomFunction
			? costCalculatedWithCustomFunction[
					CustomFunctionStringCategory.productionCost
			  ]
			: false

	if (isShowValuesInRanges && minCostDetails && maxCostDetails) {
		totalCost = displayInRange(
			minCostDetails?.totalCost,
			maxCostDetails?.totalCost,
			totalCost,
			null,
			showCostInRange ? drawingCostPercentage : undefined
		)

		supplyChainCost = displayInRange(
			minCostDetails?.onlySupplyChainCost,
			maxCostDetails?.onlySupplyChainCost,
			supplyChainCost,
			null,
			showCostInRange ? drawingCostPercentage : undefined
		)

		totalPrinting = displayInRange(
			minCostDetails?.printingCost,
			maxCostDetails?.printingCost,
			totalPrinting,
			null,
			showCostInRange ? drawingCostPercentage : undefined
		)
	}

	if (
		(!isShowValuesInRanges || (!minCostDetails && !maxCostDetails)) &&
		showCostInRange
	) {
		totalCost = calculate2DRange(costDetails.totalCost, drawingCostPercentage)

		supplyChainCost = calculate2DRange(
			costDetails?.onlySupplyChainCost,
			drawingCostPercentage
		)

		totalPrinting = calculate2DRange(printingCost, drawingCostPercentage)
	}

	const printingStandardsExplanation = () => {
		if (
			!isPrintingStandards ||
			!printingStandards ||
			printingStandards === PrintingStandardsEnum.UNKNOWN
		) {
			return <div />
		}

		return (
			<div className="printing-standard">
				<div>
					{getString('PRINTING_STANDARD_POPUP_TITLE_IS').format(
						getString(printStandard.title)
					)}
				</div>
				<div>{getString(printStandard.description)}</div>
			</div>
		)
	}

	const costDetailsPopUpExplenation = () => {
		if (!costObjectValid) {
			return <div />
		}

		return (
			<>
				<div className="cost-details-row-header">
					{POPUP_ROWS.COST_ESTIMATION}
				</div>
				{!productionCostCalculatedWithCustomFunction && (
					<>
						<div className="cost-details-row">
							<div>{POPUP_ROWS.PRINTING}</div>
							<div>{`${userCurrencySign}${totalPrinting}`}</div>
						</div>
						<TopPostProcessesDetails
							costCalculatedWithOverrideFunction={
								costCalculatedWithOverrideFunction
							}
							costDetails={costDetails}
							userCurrencySign={userCurrencySign}
							drawingCostPercentage={drawingCostPercentage}
							showCostInRange={showCostInRange}
							configurationId={configurationId}
							isWRConfig={isWRConfig}
						/>
						{costDetails?.onlySupplyChainCost > 0 && (
							<>
								<DeviderTSX size="228px" className="cost-details-row-divider" />
								<div className="cost-details-row">
									<div>
										{getString('SUPPLY_CHAIN_LABEL_COST_DETAILS_EXPLANATION')}
									</div>
									<div>{`${userCurrencySign}${supplyChainCost}`}</div>
								</div>
							</>
						)}
						<DeviderTSX size="228px" className="cost-details-row-divider" />
					</>
				)}
				<div className="cost-details-row">
					<div className="cost-details-row-total">
						{productionCostCalculatedWithCustomFunction
							? getString('TOTAL_PART_COST').format('$')
							: POPUP_ROWS.TOTAL}
					</div>
					<div className="cost-details-row-total">{`${userCurrencySign}${totalCost}`}</div>
				</div>
				<DeviderTSX
					size="228px"
					className="cost-details-row-divider cost-details-row-divider--last"
				/>
			</>
		)
	}
	const { optionalPostProcessBreakdownCost = {} } = costDetails

	const surfaceAreaPrice =
		optionalPostProcessBreakdownCost[
			OptionalPostProcessesIds.SurfaceAreaMachining
		] &&
		(optionalPostProcessBreakdownCost[
			OptionalPostProcessesIds.SurfaceAreaMachining
		]?.[postProcessesConstants.surfaceAreaRemoval] ||
			optionalPostProcessBreakdownCost[
				OptionalPostProcessesIds.SurfaceAreaMachining
			]?.[OptionalPostProcessesIds.SurfaceAreaMachining])

	const minSurfaceAreaPrice =
		minCostDetails?.optionalPostProcessBreakdownCost[
			OptionalPostProcessesIds.SurfaceAreaMachining
		]?.[postProcessesConstants.surfaceAreaRemoval] ||
		optionalPostProcessBreakdownCost[
			OptionalPostProcessesIds.SurfaceAreaMachining
		]?.[OptionalPostProcessesIds.SurfaceAreaMachining]

	const maxSurfaceAreaPrice =
		maxCostDetails.optionalPostProcessBreakdownCost[
			OptionalPostProcessesIds.SurfaceAreaMachining
		]?.[postProcessesConstants.surfaceAreaRemoval] ||
		optionalPostProcessBreakdownCost[
			OptionalPostProcessesIds.SurfaceAreaMachining
		]?.[OptionalPostProcessesIds.SurfaceAreaMachining]

	const isSurfaceAreaNa =
		surfaceAreaPrice === -1 &&
		minSurfaceAreaPrice === -1 &&
		maxSurfaceAreaPrice === -1

	const supportRemovalPrice =
		optionalPostProcessBreakdownCost[
			OptionalPostProcessesIds.SurfaceAreaMachining
		] &&
		(optionalPostProcessBreakdownCost[
			OptionalPostProcessesIds.SurfaceAreaMachining
		]?.[postProcessesConstants.supportRemoval] ||
			optionalPostProcessBreakdownCost[
				OptionalPostProcessesIds.SurfaceAreaMachining
			]?.[OptionalPostProcessesIds.SupportRemovalMachining])

	const supportRemovalMinPrice =
		minCostDetails?.optionalPostProcessBreakdownCost[
			OptionalPostProcessesIds.SurfaceAreaMachining
		]?.[postProcessesConstants.supportRemoval] ||
		optionalPostProcessBreakdownCost[
			OptionalPostProcessesIds.SurfaceAreaMachining
		]?.[OptionalPostProcessesIds.SupportRemovalMachining]

	const supportRemovalMaxPrice =
		maxCostDetails.optionalPostProcessBreakdownCost[
			OptionalPostProcessesIds.SurfaceAreaMachining
		]?.[postProcessesConstants.supportRemoval] ||
		maxCostDetails.optionalPostProcessBreakdownCost[
			OptionalPostProcessesIds.SurfaceAreaMachining
		]?.[OptionalPostProcessesIds.SupportRemovalMachining]

	const isSupportRemovalNa =
		supportRemovalPrice === -1 &&
		supportRemovalMinPrice === -1 &&
		supportRemovalMaxPrice === -1

	let naMsg = ''
	if (Feature.isFeatureOn(FeatureComponentId.INCLUDE_MACHINING_POST_PROCESS)) {
		if (isSurfaceAreaNa) {
			naMsg = getString('SURFACE_AREA_HIGH')
		}
		if (isSupportRemovalNa) {
			naMsg = getString('SUPPORT_REMOVAL_AREA_HIGH')
		}
		if (isSurfaceAreaNa && isSupportRemovalNa) {
			naMsg = getString('SURFACE_AREA_AND_SUPPORT_REMOVAL_HIGH')
		}
	}

	const isForceQuantity = Feature.isFeatureOn(
		FeatureComponentId.FORCE_SPECIFY_QUANTITY
	)

	const isCostCalculatedWithCustomFunction =
		!isEmpty(costCalculatedWithCustomFunction) &&
		some(costCalculatedWithCustomFunction, value => value)

	const costDetailsPopUp = () => {
		return (
			<div className="cost-details-wrapper">
				{costDetailsPopUpExplenation()}
				<div className="cost-details-wrapper--warning">
					<div>{IMPORTANT}</div>
					{printingStandardsExplanation()}
					{productionCostCalculatedWithCustomFunction
						? getString('CUSTOM_COST_ESTIMATION_HOVER_TEXT')
						: COST_ESTIMATE_WARNING}
					{!isSpecifiedQuantity && !isForceQuantity && (
						<div>{getString('QUANTITY_NOT_DETECTED')}</div>
					)}
					{!isCostCalculatedWithCustomFunction && naMsg ? (
						<div>
							<b>{naMsg}</b>
						</div>
					) : (
						<div />
					)}
				</div>
			</div>
		)
	}

	return (
		<InfoBox
			boxContactElement={costDetailsPopUp()}
			iconClassName="cost-details-icon"
			shouldPreventOverflow
		/>
	)
}

export default memo(TopCostDetailsIcon)
