import Flexbox from 'Scenes/Components/FlexBox'
import { FC, HTMLAttributes, memo } from 'react'

import cx from 'classnames'

import { IOtherProps } from '../../../Services/models/IOtherProps'
import Loader from '../../Loader/Loader'

import './TranparentButton.scss'
import { submitButtonWithLoaderStyle } from 'Scenes/Loader/loaderStyles.css'

interface IProps extends IOtherProps {
	children: JSX.Element[] | JSX.Element | string
	className?: string
	isAnchor?: boolean
	disabled?: boolean
	loading?: boolean
	loaderClassName?: string
}

const TransparentButton: FC<
	HTMLAttributes<HTMLAnchorElement & HTMLButtonElement> & IProps
> = ({
	children,
	className,
	isAnchor = false,
	disabled,
	loading,
	loaderClassName,
	loaderWrapperClassName,
	...otherProps
}) => {
	if (loading) {
		return (
			<Flexbox
				flexDirection="row"
				style={{ ...submitButtonWithLoaderStyle }}
				className={loaderWrapperClassName}
			>
				<Loader
					message=""
					load={loading}
					size={20}
					left={10}
					top={0}
					showFlex={false}
					wrapperClassName={`transparent-button--loader ${loaderClassName}`}
				/>
			</Flexbox>
		)
	}
	if (isAnchor) {
		return (
			<a
				className={cx('transparent-button', className, {
					disabled: disabled
				})}
				{...otherProps}
			>
				{children}
			</a>
		)
	}
	return (
		<button
			disabled={disabled}
			className={cx('transparent-button', className, {
				disabled: disabled
			})}
			{...otherProps}
		>
			{children}
		</button>
	)
}

export default memo(TransparentButton)
