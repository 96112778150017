import React, { useEffect } from 'react'

import cx from 'classnames'

import NumberField from 'Scenes/Components/NumberField'
import { Danger } from 'Scenes/Components/thirdParty/CreativeTim/components'
import {
	onUpdateMetadataData,
	useRenderValues
} from 'Scenes/Home/Customize/CustomizeMetadataCost/CustomizeMetadataCostService'

import '../Customize2DDrawingCost/Customize2DDrawingCost.scss'

interface IProps {
	styles: any
	metadataKey: string
	errorMessage: string
	valueInput: number
	minValue: number
	maxValue: number
	isEdit: boolean
	isApply: boolean
	isDefault: boolean
	editMetadataData: Function
	updateMetadataData: Function
}

const CustomizeMetadataCostInput: React.FC<IProps> = ({
	styles,
	metadataKey,
	errorMessage,
	valueInput,
	maxValue,
	minValue,
	isEdit,
	isApply,
	isDefault,
	editMetadataData,
	updateMetadataData
}) => {
	const { numberValue, placeholder, isError, setNumberValue, setIsError } =
		useRenderValues(valueInput, isDefault)

	useEffect(() => {
		if (isApply === true) {
			onUpdateMetadataData(numberValue / 100, metadataKey, updateMetadataData)
		}
	}, [isApply])

	const onEditInputUser = (value: number) => {
		if (value < minValue || value > maxValue) {
			setIsError(true)
		} else {
			setIsError(false)
		}
		setNumberValue(value)
		editMetadataData(value / 100, metadataKey)
	}

	const renderAlertMessage = (alert: string) => {
		return <Danger style={{ textAlign: 'left' }}>{alert}</Danger>
	}

	if (!isEdit) {
		return (
			<div style={styles} className={cx('drawing__field')}>
				<span>{placeholder}</span>
			</div>
		)
	}
	return (
		<>
			<NumberField
				inputClass="drawing__input-field"
				value={numberValue}
				onChangeValue={(value: number) => {
					onEditInputUser(value)
				}}
				step={1}
				allowEmpty={true}
				allowZero={false}
				isDotDisabled={false}
				isCommaDisabled={false}
				showArrows={true}
			/>
			{isError && renderAlertMessage(errorMessage)}
		</>
	)
}

export default CustomizeMetadataCostInput
