// ##############################
// // // RegularCard styles
// #############################

import { getTheme } from '../../../../themes/getTheme'
import {
  blueCardHeader,
  card,
  cardHeader,
  defaultFont,
  grayRedCardHeader,
  greenCardHeader,
  orangeCardHeader,
  purpleCardHeader,
  redCardHeader,
  roseCardHeader
} from '../../material-dashboard-pro-react.jsx'

const { colors } = getTheme()

const headerCardStyle = {
  card,
  cardPlain: {
    background: 'transparent',
    boxShadow: 'none'
  },
  cardHeader: {
    ...cardHeader,
    ...defaultFont,
    display: 'inline-block'
  },
  cardPlainHeader: {
    marginLeft: 0,
    marginRight: 0
  },
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  roseCardHeader,
  grayRedCardHeader,
  cardTitle: {
    // color: '#FFFFFF',
    color: colors.blueCardHeaderTextColor,
    marginTop: '0',
    marginBottom: '0',
    ...defaultFont,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px'
  },
  cardSubtitle: {
    marginBottom: '0',
    // color: 'rgba(255, 255, 255, 0.62)',
    color: colors.blueCardHeaderSubTextColor,
    fontSize: '14px'
  },
  cardActions: {
    padding: '14px',
    display: 'block',
    height: 'auto'
  },
  cardContent: {
    padding: '15px 20px',
    position: 'relative'
  },
  left: {
    textAlign: 'left'
  },
  right: {
    textAlign: 'right'
  },
  center: {
    textAlign: 'center'
  }
}

export default headerCardStyle
