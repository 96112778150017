import React, { FC } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import OnboardingStepFrame from '../OnboardingStepFrame'
import CostFunctionEditorSteps from './CostFunctionEditorSteps'
import { PART_COST } from 'Scenes/Home/Customize/CustomizeCostingFunctionEditor/constants'
import { FunctionEditorInitialState } from 'Scenes/Home/Customize/CustomizeCostingFunctionEditor/CostingFunctionEditorReducer'
import {
	COST_FUNCTION_IDS,
	STEP_ID
} from 'Scenes/Home/OnboardingWizard/constants'
import { useStep } from 'Scenes/Home/OnboardingWizard/OnboardingWizardHooks'

import './CostFunctionEditor.scss'

const CostFunctionEditorComponents = {
	[COST_FUNCTION_IDS.STEPS]: <CostFunctionEditorSteps />
}

const CostFunctionEditor: FC = () => {
	const { steps } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { customFunctionsList } = useSelector(
		(state: RootStateOrAny) =>
			state.CostingFunctionEditorReducer?.functionEditorStates[PART_COST] ||
			new FunctionEditorInitialState()
	)
	const { step, stepContent, childSteps } = useStep(
		steps,
		STEP_ID.COST_FUNCTION_EDITOR
	)

	return (
		<OnboardingStepFrame
			step={step}
			bodyClassName="cost-function-editor-frame"
			childSteps={childSteps}
			changesInCurrentStep={!!customFunctionsList.length}
		>
			<div>
				{stepContent?.map(component => (
					<React.Fragment key={component.id}>
						{
							CostFunctionEditorComponents[
								component.id as keyof typeof CostFunctionEditorComponents
							]
						}
					</React.Fragment>
				))}
			</div>
		</OnboardingStepFrame>
	)
}

export default CostFunctionEditor
