import { IConfiguration } from './IConfiguration'
import { Part } from './IPart'

export interface PrintIssue {
	id?: number
	name: string
	manageable: boolean
	issuePresented?: boolean
	order: number
}

export enum PrintIssueId {
	Holes = 1,
	WallThickness = 2,
	Overhanging = 3,
	Tolerance = 4,
	CAD = 5,
	Size = 6,
	Structural = 7,
	Material = 8,
	CNC = 9,
	OrientationStability = 10,
	OrientedCNC = 11,
	HeatDeformation = 12,
	Threads = 13,
	InternalCavities = 14,
	all = 100
}

export interface PartPrintIssue {
	id?: number
	printIssueId: number
	printIssue: PrintIssue
	partId: number
	part?: Part
	configurationId?: number
	configuration?: IConfiguration
	orientationVector?: number[]
	score: number
	message?: string
	active: boolean
	orientationRotated: boolean
}

export interface PartThreadsCollection {
	confidenceThreshold: number
	majorRadiusThreshold: number
	meshImageURL: any
	meshModelURL: any
	minorRadiusThreshold: number
	status: string
	partThreads: PartThread[]
}

export interface PartThread {
	axis: Array<number>
	center: Array<number>
	confidence: number
	height: number
	majorRadius: number
	minorRadius: number
}
