import {
	OFF_THE_SHELF_SUBSTRING_CHANGE,
	HANDLE_LOADER,
	CUSTOMIZE_BUTTON_LOADER_UPDATED,
	PART_FILTERING_FORM_DATA_ARRANGED,
	SAVE_PART_FILTERING_ERROR
} from '../../../../global actions/types'
import {
	getCustomizationSettings,
	uploadUserSettings
} from '../../../../Services/Network'
import {
	getPartFilteringArray,
	getGlobalFilteringArray,
	validateUserSettingsResponseData
} from '../CustomizeLogic'
import {
	CONFIRM,
	OFF_THE_SHELF_SELECTOR_ITEMS
} from '../../../../Services/Strings'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../../../global actions/types/CastorAlertTypes'
import { AlertType } from '../../../Components/alerts/AlertTypes'

import { getString } from '../../../../Services/Strings/StringService'

export const partFilteringChanged = (partFilteringArr, add, reset = false) => {
	const offTheShelfSubstrings = partFilteringArr
		.filter(tag => tag.itemType === OFF_THE_SHELF_SELECTOR_ITEMS[0])
		.map(tag => tag.text)
	const manufacturedSubstrings = partFilteringArr
		.filter(tag => tag.itemType === OFF_THE_SHELF_SELECTOR_ITEMS[1])
		.map(tag => tag.text)

	if (reset) {
		return dispatch => {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: getString('RESET_ALL_MESSAGE'),
					headerTitle: getString('RESET_ALL'),
					alertType: AlertType.WARNING,
					onConfirm: () => {
						updateUserTagSettings(
							dispatch,
							partFilteringArr,
							offTheShelfSubstrings,
							manufacturedSubstrings
						)
						dispatch({ type: ALERT_POPUP_CANCELED })
					},
					confirmText: CONFIRM
				}
			})
		}
	}

	return dispatch => {
		dispatch({
			type: CUSTOMIZE_BUTTON_LOADER_UPDATED,
			payload: add ? add : false
		})

		updateUserTagSettings(
			dispatch,
			partFilteringArr,
			offTheShelfSubstrings,
			manufacturedSubstrings
		)
	}
}

const updateUserTagSettings = (
	dispatch,
	partFilteringArr,
	offTheShelfSubstrings,
	manufacturedSubstrings
) => {
	uploadUserSettings({ offTheShelfSubstrings, manufacturedSubstrings })
		.then(() => {
			dispatch({
				type: OFF_THE_SHELF_SUBSTRING_CHANGE,
				payload: {
					partFilteringArr,
					offTheShelfSubstrings,
					manufacturedSubstrings
				}
			})
			dispatch({
				type: CUSTOMIZE_BUTTON_LOADER_UPDATED,
				payload: false
			})
		})
		.catch(error => {
			dispatch({
				type: CUSTOMIZE_BUTTON_LOADER_UPDATED,
				payload: false
			})
			dispatch({
				type: SAVE_PART_FILTERING_ERROR
			})
			console.error(error)
		})
}

export const getPartFilteringData = () => {
	return dispatch => {
		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})
		getCustomizationSettings()
			.then(response => {
				const settingsData = validateUserSettingsResponseData(response.data)
				const globalOffTheShelfSubstrings =
					settingsData.userSettings.globalOffTheShelfSubstrings
				const partFilteringArr = getPartFilteringArray(settingsData)
				const filteredGlobalOffTheShelf = getGlobalFilteringArray(
					globalOffTheShelfSubstrings
				)

				dispatch({
					type: PART_FILTERING_FORM_DATA_ARRANGED,
					payload: {
						partFilteringArr,
						filteredGlobalOffTheShelf
					}
				})
				dispatch({
					type: HANDLE_LOADER,
					payload: -1
				})
			})
			.catch(error => {
				dispatch({
					type: HANDLE_LOADER,
					payload: -1
				})
				console.error(error)
			})
	}
}
