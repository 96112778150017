import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import cx from 'classnames'

import { KEY_2d, metaData, threeD, twoD } from '../../constants'
import { resetUnitTypeError } from '../../UploadProjectActions'
import Beta from './Beta'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import CastorSelectBox from 'Scenes/Components/CastorSelectBox'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import Flexbox from 'Scenes/Components/FlexBox'
import { disabledFilesTypeFeatures } from 'Scenes/Home/NewUploadProject/UploadBlocks/UploadType/UploadTypeService'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { CANCEL, YES } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface UploadProjectProps {
	selectedType: string | null
	setSelectedType: (value: string) => void
	filesLength?: number
}

interface WarningChangeTypeProps {
	elemKey: string
	showWarning: boolean
	setSelectedType: (value: string) => void
	setShowWarning: (value: boolean) => void
}

interface IType {
	img: string
	text: any
	key: string
}

const WarningChangeType: FC<WarningChangeTypeProps> = ({
	showWarning,
	setShowWarning,
	setSelectedType,
	elemKey
}) => {
	return (
		<CastorAlert
			show={showWarning}
			alertType={AlertType.WARNING}
			headerTitle={'Warning'}
			onConfirm={() => {
				setShowWarning(false)
				setSelectedType(elemKey)
			}}
			onCancel={() => setShowWarning(false)}
			confirmOptionalText={YES}
			cancelOptionalText={CANCEL}
		>
			{getString('SWITCH_TYPE_WARNING')}
		</CastorAlert>
	)
}

const UploadProjectType: FC<UploadProjectProps> = ({
	selectedType,
	setSelectedType,
	filesLength = 0
}) => {
	const dispatch = useDispatch()
	const [showWarning, setShowWarning] = useState(false)
	const [type, setType] = useState<string>('')

	const is2dProjectOn = Feature.isFeatureOn(
		FeatureComponentId.UPLOAD_2D_PROJECT
	)
	const isMetaDataOn = Feature.isFeatureOn(
		FeatureComponentId.META_DATA_PROJECT
	)

	const typesItems: Array<IType> = [
		...threeD,
		...(is2dProjectOn ? twoD : []),
		...(isMetaDataOn ? metaData : [])
	]

	return (
		<>
			<Flexbox
				className="upload-form__types"
				alignContent="space-between"
				flexDirection="row"
			>
				{typesItems?.map((elem: IType) => {
					const selected = elem.key === selectedType
					const getFTByKey = disabledFilesTypeFeatures.find(
						ft => ft.key === elem.key
					)
					const isFeatureActive = getFTByKey
						? Feature.isFeatureActive(getFTByKey.feature)
						: true
					const is2dKey = elem.key === KEY_2d
					const img = require(`assets/img/${elem.img}`)

					return (
						<div key={elem.key} className="upload-form__scenario-type">
							<DetailsPopup
								isHover={!isFeatureActive}
								data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
								popperDirection="bottom"
								popperClassName="info-box-wrapper details-popup--contact-us"
								popperContactClassName="info-box-data"
							>
								<CastorSelectBox
									key={elem.key}
									setSelectedType={() => {
										setType(elem.key)

										if (filesLength > 0) {
											setShowWarning(true)
										} else {
											setSelectedType(elem.key)
											dispatch(resetUnitTypeError())
										}
									}}
									selected={selected}
									boxClassName="select-box__text"
									qaDataElementName={`data-qa-file-types-${elem.key}`}
									disabled={!isFeatureActive}
								>
									<>
										{is2dKey && <Beta selected={selected} />}
										<img className="image-type" src={img} alt={'img'} />
									</>
								</CastorSelectBox>
							</DetailsPopup>
							<p className={cx({ selected: selected })}>
								{getString(elem.text)}
							</p>
						</div>
					)
				})}
			</Flexbox>
			<WarningChangeType
				elemKey={type}
				showWarning={showWarning}
				setSelectedType={(type: string) => {
					setSelectedType(type)
					dispatch(resetUnitTypeError())
				}}
				setShowWarning={setShowWarning}
			/>
		</>
	)
}

export default UploadProjectType
