import React, { ChangeEvent, FC, memo } from 'react'
import { useHistory } from 'react-router-dom'

import Checkbox from '@material-ui/core/Checkbox'

import { USAGE_AGREEMENT } from 'Services/Constants/RoutesConstants'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { TURMS_CHECKBOX_LINK_TEXT, TURMS_CHECKBOX_TEXT } from 'Services/Strings'

import './index.scss'

const CheckBoxTSX: any = Checkbox

const UploadAgreement: FC<any> = ({ setAgreement, agreement }) => {
	const history = useHistory()

	return (
		<div className="upload-terms-of-agreement" data-qa={`data-qa-agreement`}>
			<CheckBoxTSX
				color="primary"
				checked={agreement}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					setAgreement(e.target.checked)
				}
			/>
			<div>
				{TURMS_CHECKBOX_TEXT}{' '}
				<div
					className="agreement-button"
					onClick={() => history.push(USAGE_AGREEMENT)}
				>
					{TURMS_CHECKBOX_LINK_TEXT}
				</div>
			</div>
		</div>
	)
}

export default WithFeatureToggleHOC(
	memo(UploadAgreement),
	FeatureComponentId.SHOW_AGREEMENT
)
