import {
	SUBSCRIPTION_ALERT_POPUP_CANCELED,
	SUBSCRIPTION_CONNECT_ALERT_POPUP,
	ALERT_POPUP_CANCELED,
	ALERT_POPPED
} from '../../../global actions/types/CastorAlertTypes'
import { Action, ActionWithPayload } from '../../../global actions/ActionModels'
import {
	CONNECT_CASTOR_RADIO_BUTTON_VALUE,
	USER_SUBSCRIPTION_REQUEST_LOADING,
	USER_SUBSCRIPTION_REQUEST_SUCCEED,
	HANDLE_NOTIFICATION
} from '../../../global actions/types'
import { sendReNewSubscriptionRequest } from '../../../Services/Network'
import {
	SUBSCRIPTION_REQUEST_SECCEDD,
	SUBSCRIPTION_REQUEST_SECCEDD_HEADER,
	OK,
	SHOW_NOTIFICATION,
	ERROR_UPDATING_TAGS
} from '../../../Services/Strings'
import { AlertType } from '../alerts/AlertTypes'

export const onSubscriptionConnectAlertCanceled = (): Action<any> => {
	return {
		type: SUBSCRIPTION_ALERT_POPUP_CANCELED
	}
}

export const openSubscriptionConnectPopup = (): Action<any> => {
	return {
		type: SUBSCRIPTION_CONNECT_ALERT_POPUP
	}
}
export const connectCastorRadioChanged = (
	value: any
): ActionWithPayload<any> => {
	return {
		type: CONNECT_CASTOR_RADIO_BUTTON_VALUE,
		payload: typeof value === 'string' ? parseInt(value) : value
	}
}

export const sendConnectCastorUserChoice = (
	subscriptionRequestType: any,
	userEmail: string
): any => {
	return (dispatch: any) => {
		dispatch({ type: USER_SUBSCRIPTION_REQUEST_LOADING })
		sendReNewSubscriptionRequest(subscriptionRequestType, userEmail)
			?.then((response: any) => {
				sendSubscriptionRequestSucceed(dispatch)
			})
			?.catch((error: any) => {
				console.log(error)
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: error.validationMessage || ERROR_UPDATING_TAGS
					}
				})
			})
	}
}
export const sendSubscriptionRequestSucceed = (dispatch: any) => {
	dispatch({ type: USER_SUBSCRIPTION_REQUEST_SUCCEED })
	dispatch({ type: SUBSCRIPTION_ALERT_POPUP_CANCELED })
	dispatch({
		type: ALERT_POPPED,
		payload: {
			text: SUBSCRIPTION_REQUEST_SECCEDD,
			headerTitle: SUBSCRIPTION_REQUEST_SECCEDD_HEADER,
			alertType: AlertType.SUCCESS,
			showCancel: false,
			onConfirm: () => {
				dispatch({ type: ALERT_POPUP_CANCELED })
			},
			confirmOptionalText: OK
		}
	})
}
