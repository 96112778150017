import cx from 'classnames'

import { benefitType } from '../../../Services/models/ISolutionBenefit'
import { generateKey } from '../../../Services/Utils/uniqKeyGenerator'
import DetailsPopup from '../DetailsPopup'
import IconFactory from '../StarIcon/IconFactory'
import { sortBenefits } from './CastorPartBenefitService'
import { ComparisonInfo } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/Co2Comparison'

import './CastorPartBenefits.scss'

const FEA = 'fea'

export const CastorPartBenefits = props => {
	const {
		benefits = [],
		small,
		style,
		className,
		expandIconOnHover,
		iconClassName = '',
		showWeightBenefit = true,
		showHoverData = true,
		showFullIcon = false,
		customProps = {},
		feaResult
	} = props

	return (
		<div style={style} className={cx('benefit-section', className)}>
			{feaResult ? (
				<span className={cx('benefit-icon-wrapper')}>
					<DetailsPopup
						isHover={true}
						data={
							<div>
								<span className="benefit-name">{FEA}</span> {feaResult}
							</div>
						}
						popperDirection="top-end"
						popperClassName="details-popup-data-benefits-message"
						popperContactClassName="details-popup-data-benefits"
					>
						<IconFactory
							iconName={FEA}
							type={feaResult}
							className="grid-list-benefits--icon"
						/>
					</DetailsPopup>
				</span>
			) : (
				<></>
			)}
			{sortBenefits(benefits).map((benefit, index) => {
				const shouldShowFull =
					showFullIcon && benefit.type === benefitType.buyToFly

				if (
					!showWeightBenefit &&
					benefit.type === benefitType.weightReduction
				) {
					return <div></div>
				}

				if (benefit.type === benefitType.co2Saving) {
					return (
						<span
							key={generateKey(index)}
							className={cx('benefit-icon-wrapper', benefit.type)}
						>
							<DetailsPopup
								isHover={true}
								inPortal={true}
								data={
									customProps.convertedCoData ? (
										<ComparisonInfo
											convertedCoData={customProps.convertedCoData}
											gainCO2={customProps.coData}
											CO2HasPotential={customProps.CO2HasPotential}
											alwaysShowCO2Details={customProps.alwaysShowCO2Details}
										/>
									) : (
										benefit.name
									)
								}
								popperClassName={
									customProps.convertedCoData
										? 'co2-details-wrapper'
										: 'details-popup-data-benefits-message'
								}
								popperDirection={'top'}
								popperContactClassName={
									customProps.convertedCoData
										? 'info-box-data'
										: 'details-popup-data-benefits'
								}
							>
								<IconFactory
									expandIconOnHover={expandIconOnHover}
									iconName={benefit.type}
									small={small}
									smallClassName={`castor-icon-small ${iconClassName}`}
									className={`castor-icon-small ${iconClassName}`}
								/>
							</DetailsPopup>
						</span>
					)
				}

				if (!showHoverData) {
					return (
						<span
							key={generateKey(index)}
							className={cx('benefit-icon-wrapper', benefit.type)}
						>
							<IconFactory
								expandIconOnHover={expandIconOnHover}
								iconName={benefit.type}
								small={small && !shouldShowFull}
								smallClassName={`castor-icon-small ${iconClassName}`}
								className={`castor-icon ${iconClassName}`}
								score={benefit.traditionalCost}
							/>
						</span>
					)
				}
				return (
					<span
						key={generateKey(index)}
						className={cx('benefit-icon-wrapper', benefit.type)}
					>
						<DetailsPopup
							isHover={true}
							data={benefit.name}
							popperDirection="top-end"
							popperClassName="details-popup-data-benefits-message"
							popperContactClassName="details-popup-data-benefits"
						>
							<IconFactory
								expandIconOnHover={expandIconOnHover}
								iconName={benefit.type}
								small={small}
								smallClassName={`castor-icon-small ${iconClassName}`}
								className={`castor-icon ${iconClassName}`}
							/>
						</DetailsPopup>
					</span>
				)
			})}
		</div>
	)
}
