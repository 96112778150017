import { RootStateOrAny, useSelector } from 'react-redux'

import { defaultMetal, materialTypes } from 'Services/Constants'
import { getTheme } from 'themes/getTheme'

const { defaultMaterial } = getTheme()

export const useMaterial = () => {
	const user = useSelector((state: RootStateOrAny) => state.user)

	const materialChosen = useSelector(
		(state: RootStateOrAny) => state.uploadProject?.materialChosen
	)

	const defaultChosenMaterial =
		user.defaultUploadProjectMaterialType === materialTypes.metal
			? defaultMetal
			: defaultMaterial

	const material = materialChosen || defaultChosenMaterial

	return [material, defaultChosenMaterial]
}
