import React, { memo, FC, ReactElement, ReactNode } from 'react'
import './DetailsPopup.scss'

interface IProps {
  data: ReactNode | ReactElement | string | number
  className?: string
}

const PopupMessage: FC<IProps> = ({ data, className }) => {
  return <div className={`details-popup-data ${className || ''}`}>{data}</div>
}

export default memo(PopupMessage)
