import { ChangeEvent } from 'react'
import { Link } from 'react-router-dom'

import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { SEND_TO_SERVICE_BUREAU_ROUTE } from 'Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

const LinkTSX: any = Link

export const getExportMenu = (
	printingOptionIsOn: any,
	integrationProviderOptions: any,
	onGetAQClick: Function,
	on3dExportClick: Function,
	btnGetAQElementId: string,
	solution: any,
	isOnPrem: boolean,
	enableSolutionButtons: boolean,
	quantity: number,
	showGetQAEnabled: number,
	printable: boolean,
	cluster: any,
	setShowModal: Function,
	sendToLoader: boolean,
	exportPdfButton: JSX.Element,
	configurationId: number,
	isNewDesign?: boolean,
	disabledExportToPdfOn?: boolean,
	btn3DExportElementId?: string
) => {
	const menu = []

	const getAQuoteButton = () => {
		return (
			<LinkTSX
				className={
					!enableSolutionButtons || !solution || disabledExportToPdfOn
						? 'solution-analysis-header-disable-link'
						: ''
				}
				to={
					solution
						? `${SEND_TO_SERVICE_BUREAU_ROUTE}/${solution.projectId}/${solution.partId}/${quantity}/${solution.printer?.name}/${solution.printerMaterial?.name}/${configurationId}`
						: ''
				}
			>
				<Button
					id={btnGetAQElementId}
					color="transparent"
					onClick={(e: any) => onGetAQClick(e, btnGetAQElementId)}
					className={`part-analysis-button-text-transform solution-analysis-header-text inside-link ${
						showGetQAEnabled ? 'enabled' : ''
					}`}
					disabled={
						!enableSolutionButtons || !solution || disabledExportToPdfOn
					}
					componentId={1}
				>
					<IconFactory
						iconName="getAQ"
						className="solution-analysis-header-text-icon"
					/>
					<div className="solution-analysis-header-text-text">
						{getString('PART_ANALYSIS_GETAQ')}
					</div>
				</Button>
			</LinkTSX>
		)
	}

	if (printingOptionIsOn && !cluster) {
		if (integrationProviderOptions?.length > 0) {
			integrationProviderOptions?.forEach((option: any) => {
				menu.push(option)
			})
		}
	}

	if (!isOnPrem) {
		menu.push({
			onClick: () => {},
			name: '',
			element: getAQuoteButton()
		})
	}

	if (printable && !cluster && !isNewDesign) {
		menu.push({
			name: '',
			element: exportPdfButton,
			onClick: (e: ChangeEvent<HTMLInputElement>) => {
				e.stopPropagation()
			}
		})
	}

	return menu
}

export default getExportMenu
