import React, { FC, useState, useEffect } from 'react'
import cx from 'classnames'

import NumberField from '../../../../Components/NumberField'
import { renderValueWithLabel } from '../../CustomizeCostAndLeadSettings/CostAndLeadService'
import { isShipping } from '../CustomizeCO2Service'

import '../CusomizeCO2.scss'

interface IProps {
  value: number | null
  edit: boolean
  minValue: number
  maxValue: number
  hoverText: string
  error: boolean
  isDefault: boolean
  label: string
  keyLabel: string
  co2Key: string
  onEditCO2Data: Function
}

const CustomizeEditInput: FC<IProps> = ({
 value,
 edit,
 minValue,
 maxValue,
 hoverText,
 error,
 isDefault,
 label,
 co2Key,
 keyLabel,
 onEditCO2Data
}) => {
  const [numberValue, setNumberValue] = useState(value);
  const placeholder = renderValueWithLabel(value, label, isDefault)
  const showTooltip = isShipping(keyLabel)

  useEffect(() => {
    setNumberValue(value)
  }, [value])


  // prevent updates each time when user is typing (debounce on minimums)
  useEffect(() => {
    let delayDebounceFn: any

    if (value !== numberValue) {
      delayDebounceFn = setTimeout(() => {
        onEditCO2Data(co2Key, keyLabel, numberValue)
      }, 300)
    }

    return () => clearTimeout(delayDebounceFn)
  }, [numberValue])


  if (!edit) {
    return <div
      className={cx('customize__field', { 'customize__default-field': isDefault, 'with-tooltip': showTooltip})}>
      <span>{placeholder}</span>
      {
        showTooltip ?
          <div className="tooltip info-box-wrapper">
            {hoverText}
          </div> : <></>
      }
    </div>
  }

  return <NumberField
    showArrows={false}
    inputClass="customize__input-field"
    value={numberValue}
    error={error}
    onChangeValue={(value: number) => {
      setNumberValue(value)
    }}
    minValue={minValue}
    allowEmpty={true}
    allowZero={true}
    disableCheckMaxValue={true}
  />
}

export default CustomizeEditInput
