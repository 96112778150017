import React, { FC, MouseEvent } from 'react'

import DetailsPopup from 'Scenes/Components/DetailsPopup'
import { Button as ButtonComponents } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { useUserReducer } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisSelector'
import { cncColor } from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as Print } from 'assets/img/svg/icons/print.svg'
import { ReactComponent as Screw } from 'assets/img/svg/icons/screw.svg'

const PrintIcon: any = Print
const ScrewIcon: any = Screw

interface NewConfigurationButtonProps {
	disablePart: boolean
	onAddNewConfigurationClick: Function
}

export const NewConfigurationButton: FC<NewConfigurationButtonProps> = ({
	onAddNewConfigurationClick,
	disablePart
}) => {
	const { isLightUser } = useUserReducer()
	const showHover = !Feature.isFeatureActive(
		FeatureComponentId.SHOW_HOVER_DETAILS
	)
	return (
		<div
			onClick={(e: MouseEvent<HTMLDivElement>) =>
				showHover && isLightUser && e.stopPropagation()
			}
		>
			<div className="part-analysis-header-button_text">
				{getString('WANT_TO_TRY_CONFIGURATION')}
			</div>
			<DetailsPopup
				isHover={showHover && isLightUser}
				data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
				popperDirection="bottom"
				popperClassName="info-box-wrapper details-popup--contact-us"
				popperContactClassName="info-box-data"
			>
				<ButtonComponents
					color="primary"
					onClick={onAddNewConfigurationClick}
					className="part-analysis-header-button part-analysis-button-text-transform"
					muiClasses={{
						label: 'part-analysis-button-label',
						root: 'part-analysis-button-root'
					}}
					disabled={disablePart || isLightUser}
				>
					<ScrewIcon /> {getString('PART_ANALYSIS_ADD_CONFIGURATION_BUTTON')}
				</ButtonComponents>
			</DetailsPopup>
		</div>
	)
}

interface NewPrinterMaterialButtonProps {
	disablePart: boolean
	onAddSimpleConfigurationClick: Function
	isEmpty?: boolean
	isFilterEmpty?: boolean
}

export const NewPrinterMaterialButton: FC<NewPrinterMaterialButtonProps> = ({
	onAddSimpleConfigurationClick,
	disablePart,
	isEmpty,
	isFilterEmpty
}) => {
	const { isLightUser } = useUserReducer()
	const showHover = !Feature.isFeatureActive(
		FeatureComponentId.SHOW_HOVER_DETAILS
	)
	return (
		<div
			onClick={(e: MouseEvent<HTMLDivElement>) =>
				showHover && isLightUser && e.stopPropagation()
			}
		>
			<div className="part-analysis-header-button_text">
				{isFilterEmpty
					? getString('SORRY_NO_SOLUTION_FOUND')
					: isEmpty
					? getString('SORRY_WE_DIDNT_FIND')
					: getString('DIDNT_FIND_PRINTER_HERE')}
			</div>
			<DetailsPopup
				isHover={showHover && isLightUser}
				data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
				popperDirection="bottom"
				popperClassName="info-box-wrapper details-popup--contact-us"
				popperContactClassName="info-box-data"
			>
				<ButtonComponents
					color="primary"
					onClick={onAddSimpleConfigurationClick}
					className="part-analysis-header-button part-analysis-button-text-transform"
					muiClasses={{
						label: 'part-analysis-button-label',
						root: 'part-analysis-button-root'
					}}
					disabled={disablePart || isLightUser}
					data-qa="data-qa-add-printer-material-config-btn"
				>
					<PrintIcon /> {getString('ADD_PRINTER_MATERIAL_CONFIGURATION_BUTTON')}
				</ButtonComponents>
			</DetailsPopup>
		</div>
	)
}
