import { FC, memo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { GridList, GridListTile } from '@material-ui/core'

import ProjectPartItem from '../../../../../../ProjectAnalysis/ProjectPartItem'
import Loader from 'Scenes/Loader/Loader'
import { Part } from 'Services/models/IPart'

import '../SolutionAnalysisTabs.scss'

const GridListTSX: any = GridList
const GridListTileTSX: any = GridListTile

interface IProps {
	parts: Part[] | null
	onPartClick: Function
	fetchMoreData: any
	hasMoreData: boolean
}

const ClusterPartsTab: FC<IProps> = ({
	parts,
	onPartClick,
	fetchMoreData,
	hasMoreData
}) => {
	return (
		<InfiniteScroll
			dataLength={parts?.length || 0}
			next={fetchMoreData}
			hasMore={hasMoreData}
			loader={
				<Loader
					load={true}
					size={50}
					showFlex={false}
					wrapperClassName="solution-tab-cluster-part-tile--loader"
				/>
			}
			height={330}
		>
			<GridListTSX className="solution-tab-cluster" spacing={15} cols={3}>
				{parts?.map(part => (
					<GridListTileTSX
						className="solution-tab-cluster-part-tile"
						classes={{ tile: 'solution-tab-cluster-part-tile--element' }}
						key={part.id}
						sm={4}
					>
						<ProjectPartItem
							part={part}
							onPartClick={() => onPartClick(part)}
							cardClassName="solution-tab-cluster-part-tile--card"
							cardSizeWrapperClassName="solution-tab-cluster-part-tile--card--image-wrapper"
							cardContentClassName="solution-tab-cluster-part-tile--card--content"
						/>
					</GridListTileTSX>
				))}
			</GridListTSX>
		</InfiniteScroll>
	)
}

export default memo(ClusterPartsTab)
