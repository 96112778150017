import {
	ADMIN_3D_TECH_PARAMETERS_UPDATED,
	ADMIN_PRINTER_TECH_NEW_ASTM_ISO_NAME_SELECTED,
	GET_ADMIN_3D_TECH_PARAMETERS,
	GET_ADMIN_3D_TECH_PARAMETERS_EDIT_SELECTED,
	GET_ADMIN_3D_TECH_PARAMETERS_GOT_ERROR,
	GET_ADMIN_3D_TECH_PARAMETERS_SUCCESS
} from '../../../../global actions/types'
import {
	getTechParametersAdmin,
	updatePrinterTechParametersAdmin
} from '../../../../Services/Network'
import { getString } from '../../../../Services/Strings/StringService'
import {
	selectItemToEdit,
	setupAdminPage,
	updateItem
} from '../adminActionsService'

export const setupAdminPrintingTechnologyPage = (
	searchPhrase: any,
	pageNumber: any,
	limitPrinters: any
) => {
	return (dispatch: any) => {
		setupAdminPage(
			dispatch,
			getTechParametersAdmin,
			[searchPhrase, pageNumber, limitPrinters],
			GET_ADMIN_3D_TECH_PARAMETERS_SUCCESS,
			GET_ADMIN_3D_TECH_PARAMETERS_GOT_ERROR,
			pageNumber
		)
		dispatch({ type: GET_ADMIN_3D_TECH_PARAMETERS })
	}
}

export const newAstmIsoSelected = (selectedAstmIsoName: string) => {
	return {
		type: ADMIN_PRINTER_TECH_NEW_ASTM_ISO_NAME_SELECTED,
		payload: selectedAstmIsoName
	}
}

export const selectPrinterTechnologyToEdit = (
	printerTechName: number,
	printersTechnologies: Array<any>
) => {
	return (dispatch: any) => {
		selectItemToEdit(
			dispatch,
			printerTechName,
			printersTechnologies,
			getTechParametersAdmin,
			[printerTechName],
			GET_ADMIN_3D_TECH_PARAMETERS_EDIT_SELECTED,
			GET_ADMIN_3D_TECH_PARAMETERS_GOT_ERROR,
			'printer',
			'name'
		)
	}
}

export const updatePrinterTechnologyParameters = (data: any) => {
	return (dispatch: any) => {
		updateItem(
			dispatch,
			updatePrinterTechParametersAdmin,
			data,
			ADMIN_3D_TECH_PARAMETERS_UPDATED,
			'printerTechParametersData',
			getString('ADMIN_PRINTER_TECHNOLOGY_UPDATE_SUCCESS'),
			getString('ADMIN_PRINTER_TECHNOLOGY_UPDATE_ERROR')
		)
	}
}
