import React, { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import InHousePrintersOtherSelector from 'Scenes/Home/Customize/CustomizeInHousePrinters/InHousePrintersOtherSelector'
import InHousePrintersSelector from 'Scenes/Home/Customize/CustomizeInHousePrinters/InHousePrintersSelector'
import CustomizeInHousePrintersTable from 'Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrintersTable'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import InHouseMaterialOtherSelector from 'Scenes/Home/Customize/CustomizeInHousePrinters/InHouseMaterialOtherSelector'
import { onInHousePrintersAlertClose } from 'Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrintersActions'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { removeUserPrinters } from './CustomizeInHousePrintersActions'
import {
	SET,
	NO_INHOUSE_PRINTER_WAS_SET,
	CUSTOMIZE_IN_HOUSE_PRINTERS_EXPLANATION_ARRAY
} from 'Services/Strings'

import './CustomizeInHousePrinters.scss'

const CustomizeInHousePrintersAlert: FC = () => {
	const {
		user: { printers },
		CustomizeInHousePrintersReducer: { userId, showInHousePrintersAlert }
	} = useSelector((state: RootStateOrAny) => state)
	const dispatch = useDispatch()

	const InHousePrintersSelectorProps = { zIndex: 99999 }

	const handleCloseInHousePrintersAlert = () => {
		onInHousePrintersAlertClose(dispatch)
	}

	const onInHousePrintersAlertCancel = () => {
		if (printers?.length) {
			dispatch(removeUserPrinters(userId))
		}
		handleCloseInHousePrintersAlert()
	}

	return (
			<CastorAlert
				headerTitle={NO_INHOUSE_PRINTER_WAS_SET}
				onCancel={onInHousePrintersAlertCancel}
				show={showInHousePrintersAlert}
				onConfirm={handleCloseInHousePrintersAlert}
				confirmOptionalText={SET}
				disabled={!printers?.length}
				alertType={AlertType.WARNING}
				alertBodyClass="in-house-printers--alert--body"
				mediumSize
			>
				<CastorFormHeader
					explanationArray={CUSTOMIZE_IN_HOUSE_PRINTERS_EXPLANATION_ARRAY}
				/>
				<InHousePrintersSelector {...InHousePrintersSelectorProps} />
				<InHousePrintersOtherSelector />
				<InHouseMaterialOtherSelector {...InHousePrintersSelectorProps} />
				<CustomizeInHousePrintersTable />
			</CastorAlert>
	)
}

export default memo(CustomizeInHousePrintersAlert)
