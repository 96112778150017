import React from 'react'
import { getString } from '../../../../Services/Strings/StringService'
import TransparentButton from 'Scenes/Components/TransparentButton'
import './Customize2DDrawingCost.scss'
import {
  onApplyToggle,
  onEditToggleChange
} from './Customize2DDrawingCostService'

interface IProps {
  drawingKey: any
  isEdit: boolean
  drawingApplyToggle: Function
  drawingEditToggle: Function
  onEditToggle: Function
}

const Customize2DDrawingCostButton: React.FC<IProps> = ({
  drawingKey,
  isEdit,
  drawingApplyToggle,
  onEditToggle,
  drawingEditToggle
}) => {
  return (
    <div>
      <TransparentButton
        onClick={() =>
          onEditToggleChange(
            !isEdit,
            drawingKey,
            drawingEditToggle,
            drawingApplyToggle
          )
        }
        className="custom-input--action"
      >
        {isEdit ? getString('CANCEL') : getString('EDIT')}
      </TransparentButton>
      {isEdit ? (
        <TransparentButton
          onClick={() =>
            onApplyToggle(
              !isEdit,
              drawingKey,
              drawingApplyToggle,
              drawingEditToggle,
              onEditToggle
            )
          }
          className="custom-input--action"
        >
          {getString('APPLY')}
        </TransparentButton>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default Customize2DDrawingCostButton
