import { cloneDeep, toNumber } from 'lodash'

import {
	CHANGE_PROJECTS_SEARCH_PHRASE,
	END_ADDING_NEW_FOLDER,
	GET_PROJECTS,
	GET_PROJECTS_FAILED,
	GET_PROJECTS_SUCCESS,
	PROJECTS_REANALYSIS_RECALCULATE_POLLER_FAILED,
	PROJECTS_REANALYSIS_RECALCULATE_POLLER_FINISHED,
	PROJECTS_REANALYSIS_RECALCULATE_POLLER_STARTED,
	RESET_AUTH_STATE,
	START_ADDING_NEW_FOLDER,
	TOGGLE_CREATE_PROJECTS_FOLDER,
	USER_HOME_CRUMB_NAME_CHANGED,
	USER_HOME_CRUMB_NAMES_CHANGED,
	USER_HOME_CRUMB_RESET,
	USER_HOME_DRAW_TOGGLE,
	USER_HOME_PROJECT_REMOVED,
	USER_HOME_ROUTE_CHANGED,
	USER_HOME_ROUTES_CHANGED
} from '../../global actions/types'
import userHomeRoutes from '../../routes/app'
import { PROJECTS_ROUTE } from '../../Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from '../../Services/models/Features'
import { getString } from '../../Services/Strings/StringService'
import {
	clustersToRoutes,
	getUserHomePermittedRoutes,
	projectsToFolders,
	projectsToRoutes,
	sortProjectRoutesNested,
	userFoldersToRoutes
} from './UserHomeService'
import { UserRole } from './UserRole.enum'

const INITIAL_STATE = {
	loading: false,
	projects: [],
	filteredRoutes: cloneDeep(userHomeRoutes),
	routes: userHomeRoutes,
	error: null,
	mobileOpen: false,
	dateTime: Date.now(),
	setupCalled: false,
	crumbs: [],
	flatRoutes: [],
	projectsStatusPollerStarted: false,
	showSideBar: true,
	projectsSearchPhrase: '',
	showCreateNewFolder: false,
	newFolderLoading: false
}

const userHomeReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_PROJECTS:
			return { ...state, loading: true, setupCalled: true }

		case USER_HOME_ROUTE_CHANGED:
			return { ...state, crumbs: action.payload.crumbs }

		case USER_HOME_CRUMB_RESET: {
			return {
				...state,
				crumbs: INITIAL_STATE.crumbs
			}
		}

		case USER_HOME_CRUMB_NAME_CHANGED: {
			const { crumbNewName, changeNameWith } = action.payload
			const flatRoutes = state.flatRoutes.map(route => {
				if (route.changeNameWith === changeNameWith) {
					route.name = crumbNewName
					return route
				}
				return route
			})
			return { ...state, flatRoutes }
		}

		case USER_HOME_CRUMB_NAMES_CHANGED: {
			const { updateCrumbs } = action.payload
			const flatRoutes = state.flatRoutes.map(route => {
				if (route.changeNameWith) {
					route.name = updateCrumbs[route.changeNameWith]
					return route
				}
				return route
			})
			return { ...state, flatRoutes }
		}

		case GET_PROJECTS_SUCCESS:
			const {
				projects,
				features,
				maxAllowedUploadProjects,
				showSideBar,
				roles,
				userProjectFolders
			} = action.payload
			const disableUploadNavbar =
				projects &&
				projects.length >= toNumber(maxAllowedUploadProjects) &&
				toNumber(maxAllowedUploadProjects) === 1
			const routes = createRoutes(
				projects,
				features,
				disableUploadNavbar,
				roles,
				userProjectFolders
			)
			const flatRoutes = []
			routes.map(route => {
				flatRoutes.push({ ...route })
				if (route.views?.length) {
					route.views.map(routeView => {
						flatRoutes.push({ ...routeView })
						if (routeView.views?.length) {
							routeView.views.map(r => flatRoutes.push({ ...r }))
						}
					})
				}
			})

			//check if sidebar was provided,
			//if not use from state
			const sideBarIsOn = !!showSideBar
				? showSideBar !== 'false'
				: state.showSideBar

			return {
				...state,
				projects: action.payload,
				routes,
				filteredRoutes: cloneDeep(routes),
				projectsSearchPhrase: '',
				flatRoutes,
				showSideBar: sideBarIsOn,
				loading: false
			}
		case PROJECTS_REANALYSIS_RECALCULATE_POLLER_STARTED:
			return { ...state, projectsStatusPollerStarted: true }
		case PROJECTS_REANALYSIS_RECALCULATE_POLLER_FINISHED:
			return { ...state, projectsStatusPollerStarted: false }
		case PROJECTS_REANALYSIS_RECALCULATE_POLLER_FAILED:
			return { ...state, projectsStatusPollerStarted: false }

		case GET_PROJECTS_FAILED:
			return { ...state, loading: false, error: action.payload }
		case USER_HOME_DRAW_TOGGLE:
			return { ...state, mobileOpen: !state.mobileOpen }
		case USER_HOME_PROJECT_REMOVED:
			const { projectId } = action.payload
			const newRoutes = removeProjectRoute(projectId, state.routes)
			return {
				...state,
				routes: newRoutes,
				filteredRoutes: cloneDeep(newRoutes),
				projectsSearchPhrase: '',
				dateTime: Date.now()
			}
		case RESET_AUTH_STATE:
			return { ...INITIAL_STATE }
		case USER_HOME_ROUTES_CHANGED: {
			const { routes, filteredRoutes } = action.payload
			return {
				...state,
				routes,
				filteredRoutes: filteredRoutes || cloneDeep(routes),
				projectsSearchPhrase: filteredRoutes ? state.projectsSearchPhrase : ''
			}
		}
		case CHANGE_PROJECTS_SEARCH_PHRASE: {
			const { projectsSearchPhrase } = action.payload
			return { ...state, projectsSearchPhrase }
		}
		case TOGGLE_CREATE_PROJECTS_FOLDER: {
			const { showCreateNewFolder } = action.payload
			return { ...state, showCreateNewFolder }
		}
		case START_ADDING_NEW_FOLDER: {
			return { ...state, newFolderLoading: true }
		}
		case END_ADDING_NEW_FOLDER: {
			return { ...state, newFolderLoading: false, showCreateNewFolder: false }
		}

		default:
			return state
	}
}

const removeProjectRoute = (projectId, routes) => {
	var projectsRouteIndex = routes.findIndex(route =>
		route.path.endsWith(PROJECTS_ROUTE + '/:projectId')
	)
	routes[projectsRouteIndex].views = routes[projectsRouteIndex].views.filter(
		view => view.project?.id !== projectId && view.folderId !== projectId
	)

	routes[projectsRouteIndex].views = routes[projectsRouteIndex].views.map(
		view => {
			if (view.views) {
				view.views = view.views.filter(v => v.project?.id !== projectId)
			}
			return view
		}
	)

	return routes
}

const createRoutes = (
	projects,
	features,
	disableUploadNavbar,
	roles,
	userProjectFolders
) => {
	const isLightUser = roles.includes(UserRole.LIGHT)
	let routes = userHomeRoutes
	const userPanel = Feature.isFeatureOn(FeatureComponentId.USER_PANEL, features)

	const projectBundle = Feature.isFeatureOn(
		FeatureComponentId.PROJECT_BUNDLE_PAGE,
		features
	)
	const projectPartsInSideBar = Feature.isFeatureOn(
		FeatureComponentId.PROJECT_PARTS_IN_SIDEBAR,
		features
	)
	const searchProjectsInSidebar = Feature.isFeatureOn(
		FeatureComponentId.SEARCH_PROJECTS_IN_SIDEBAR,
		features
	)
	const userFoldersInSidebar = Feature.isFeatureOn(
		FeatureComponentId.USER_FOLDERS_IN_SIDEBAR,
		features
	)
	const projectsToolBar = searchProjectsInSidebar || userFoldersInSidebar

	const userPanelIndex = routes.findIndex(
		route => route.name === getString('MY_PANEL')
	)
	const myProjectsIndex = routes.findIndex(
		x => x.name === getString('NAV_TITLE_MY_PROJECTS')
	)
	const foundClustersIndex = routes.findIndex(
		x => x.name === getString('NAV_TITLE_ANALYSIS_CLUSTER')
	)
	const foundUploadIndex = routes.findIndex(
		x => x.name === getString('NAV_TITLE_PROJECT_UPLOAD')
	)

	if (routes[myProjectsIndex]) {
		let projectViews = []
		let userProjectFolderRoutes
		if (userFoldersInSidebar) {
			routes[myProjectsIndex].draggableViews = true
			if (userProjectFolders?.length) {
				userProjectFolderRoutes = userFoldersToRoutes(userProjectFolders)
			}
		}

		const projectRoutes = projectsToRoutes(
			projects,
			projectBundle,
			projectPartsInSideBar,
			isLightUser
		)

		if (userProjectFolderRoutes?.length) {
			projectViews = projectsToFolders(userProjectFolderRoutes, projectRoutes)
		} else {
			projectViews = projectRoutes
		}
		routes[myProjectsIndex].views = sortProjectRoutesNested(projectViews)

		if (projectsToolBar) {
			routes[myProjectsIndex].projectsToolBar = true
		}
	}
	if (routes[foundClustersIndex]) {
		routes[foundClustersIndex].views = clustersToRoutes(projects, projectBundle)
	}
	if (userPanelIndex >= 0) {
		routes[userPanelIndex].sidebar = userPanel
	}

	if (disableUploadNavbar) {
		routes[foundUploadIndex].sidebar = false
	}

	return getUserHomePermittedRoutes(routes, roles)
}

export default userHomeReducer
