import {
  INITIAL_TECHNOLOGY_SETUP,
  REMOVAL_RATE_CNC,
  MOQ,
  REMOVAL_RATE_CNC_INFO,
  MOQ_INFO,
  INITIAL_TECHNOLOGY_SETUP_INFO
} from '../../../../../../../../../../Services/Strings'

export const checkErrorValues = [
  { name: 'tmInitialTechnologySetupTime', checkIfNull: true, checkIfEmpty: true, checkIfMinMax: true, min: 0, max: 56, notInclude: true },
  { name: 'removalRateCNC', checkIfNull: true, checkIfEmpty: true, checkIfMinMax: true, min: 0, max: 9000, notInclude: true },
  { name: 'moq', checkIfNull: true, checkIfEmpty: true },
]

export const initialValues = {
  tmInitialTechnologySetupTime: 56,
  removalRateCNC: 9000,
  moq: 0
}

export const leadTimeFormData = [
  { name: 'tmInitialTechnologySetupTime', label: INITIAL_TECHNOLOGY_SETUP, iIcon: INITIAL_TECHNOLOGY_SETUP_INFO },
  { name: 'removalRateCNC', label: REMOVAL_RATE_CNC, isCnc: true, iIcon: REMOVAL_RATE_CNC_INFO },
  { name: 'moq', label: MOQ, iIcon: MOQ_INFO},
]
