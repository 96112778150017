import React, { memo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import PartDetails from './PartDetails/PartDetails'
import PdfViewer from './PdfViewer/PdfViewer'
import SolutionPartImage from './SolutionPartImage'
import SolutionPartImageButton from './SolutionPartImageButton'
import SolutionPartOrientationImage from './SolutionPartOrientationImage'
import Flexbox from 'Scenes/Components/FlexBox'
import InfoBox from 'Scenes/Components/InfoBox'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { partResults } from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { OrientationData } from 'Services/models/IOrinetationData'
import { FormatType, LocationPartsParams, Part } from 'Services/models/IPart'

import './SolutionPartDetails.scss'

interface Props {
	part: Part | null
	cluster: any
	partImageSrc: string
	showHeatMap: boolean
	show3dButton: Boolean
	isCluster: boolean
	showHeatMapButton: Boolean
	showImageLoader: boolean
	solutionPartImageIndex: number
	tourStepIndex: number
	configurationId: number
	chosenOrientation: OrientationData | null
	rotatedOrientation: boolean
	freezeConfiguration: boolean
	customConfiguration: boolean
	disableConfiguration: boolean
	onShowHeatToggle: Function
	show3dModalIframe: any | Function
	hide3dModalIframe: Function
	onPartImageTabClicked: Function
	onChooseOrientationClick: any | Function
	onChangeViewer: any | Function
	configurationResult?: string
	configurationVolume: number
	loadingOrientation?: boolean
}

const partSizeInfoPopUp = (
	part: Part | null,
	cluster: any,
	configurationVolume: number,
	isWeightReductionConfiguration: boolean
) => {
	return (
		<div className="part-size-details-wrapper">
			<PartDetails
				width={(part && part.width) || (cluster && cluster.width)}
				height={(part && part.height) || (cluster && cluster.height)}
				depth={(part && part.depth) || (cluster && cluster.depth)}
				volume={
					isWeightReductionConfiguration
						? part?.weightReducedVolume
						: configurationVolume ||
						  (part && part.volume) ||
						  (cluster && cluster.volume)
				}
				countInAssembly={(part && part.countInAssembly) || 0}
			/>
		</div>
	)
}

const renderInfoButton = (
	part: Part | null,
	cluster: any,
	configurationVolume: number,
	isWeightReductionConfiguration: boolean
) => {
	return (
		<InfoBox
			boxContactElement={partSizeInfoPopUp(
				part,
				cluster,
				configurationVolume,
				isWeightReductionConfiguration
			)}
			iconClassName="part-size-details__info_icon with-color"
			boxDirection="right-start"
		/>
	)
}

const SolutionPartImagesTabs: React.FC<Props> = ({
	part,
	cluster,
	partImageSrc,
	onShowHeatToggle,
	showHeatMap,
	show3dModalIframe,
	show3dButton,
	showHeatMapButton,
	showImageLoader,
	solutionPartImageIndex,
	configurationId,
	chosenOrientation,
	rotatedOrientation,
	onChooseOrientationClick,
	tourStepIndex,
	freezeConfiguration,
	customConfiguration,
	onChangeViewer,
	configurationResult,
	isCluster,
	configurationVolume,
	loadingOrientation
}: Props) => {
	const [showModal, setShowModal] = useState(false)
	const isDrawing = part?.isDrawing
	const isMetadata = part?.formatType === METADATA
	const show3DForDrawing =
		(configurationResult === partResults.printable ||
			configurationResult === partResults.borderline) &&
		part?.meshUploadURL &&
		isDrawing &&
		Feature.isFeatureOn(FeatureComponentId.DRAWING_3D)

	const onCloseModal = () => {
		onChangeViewer(false)
		setShowModal(false)
	}
	const changeModalView = (isOpen: boolean) => {
		onChangeViewer(isOpen)
		setShowModal(isOpen)
	}

	const location = useLocation<LocationPartsParams>()
	const isWeightReductionConfiguration = location?.state?.isWeightReduction

	const renderImage = (isDrawing?: boolean, fileUrl?: string) => {
		if (isDrawing) {
			return (
				<>
					<div className="pdf-file" onClick={() => changeModalView(true)}>
						<SolutionPartImage
							formatType={FormatType.pdf}
							partImageSrc={partImageSrc}
							showImageLoader={showImageLoader}
							solutionPartImageIndex={solutionPartImageIndex}
						/>
					</div>
					<PdfViewer
						onCloseModal={onCloseModal}
						showModal={showModal}
						partImageSrc={partImageSrc}
						fileUrl={fileUrl}
					/>
				</>
			)
		}

		return (
			<>
				<SolutionPartImage
					formatType={part?.formatType}
					partImageSrc={partImageSrc}
					showImageLoader={showImageLoader}
					solutionPartImageIndex={solutionPartImageIndex}
				/>

				<SolutionPartOrientationImage
					formatType={part?.formatType}
					chosenOrientation={chosenOrientation}
					rotatedOrientation={rotatedOrientation}
					showImageLoader={showImageLoader}
					solutionPartImageIndex={solutionPartImageIndex}
				/>
			</>
		)
	}

	return (
		<>
			<Flexbox
				flexDirection="column"
				alignItems="center"
				justifyContent="space-around"
				className="part-image-container part-image-container--image-content"
				data-qa="data-qa-part-image-container"
			>
				{renderImage(isDrawing, part?.fileURL || '')}
				{!isDrawing || show3DForDrawing ? (
					<>
						{!isDrawing && (
							<div className={`part-image-container--part-size`}>
								{renderInfoButton(
									part,
									cluster,
									configurationVolume,
									isWeightReductionConfiguration
								)}
							</div>
						)}
						<SolutionPartImageButton
							isCluster={isCluster}
							showOrientationButton={!show3DForDrawing && !isMetadata}
							customConfiguration={customConfiguration}
							show3dButton={show3dButton || show3DForDrawing}
							tourStepIndex={tourStepIndex}
							show3dModalIframe={show3dModalIframe}
							showHeatMapButton={showHeatMapButton}
							onShowHeatToggle={onShowHeatToggle}
							showHeatMap={showHeatMap}
							solutionPartImageIndex={solutionPartImageIndex}
							onChoseOrientationClick={onChooseOrientationClick}
							configurationId={configurationId}
							freezeConfiguration={freezeConfiguration}
							configurationResult={configurationResult}
							loadingOrientation={loadingOrientation}
						/>
					</>
				) : (
					<></>
				)}
			</Flexbox>
		</>
	)
}

export default memo(SolutionPartImagesTabs)
