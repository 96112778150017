import React, { FC, memo } from 'react'
import Numeral from 'numeral'

import { getString } from '../../../Services/Strings/StringService'
import IconFactory from '../StarIcon/IconFactory'
import StatsSimpleDataCard from '../StatsSimpleDataCard'

import './UserPanelROI.scss'

interface IProps {
  hideRoiData: boolean
  userCurrencySign: string
  userROIDataText: string
  userROIMachineCost: number
  userROITraditionalSavingYears: number
}

const RoiData: FC<IProps> = ({
  userROIMachineCost,
  userROITraditionalSavingYears,
  userCurrencySign,
  hideRoiData,
  userROIDataText
}) => {
  if (hideRoiData) {
    return <div></div>
  }
  return (
    <div className="user-roi--content">
      <div className="user-roi--content--table">
        <div className="user-roi--content--table--item">
          <div className="user-roi--content--table--item--left">
            <IconFactory
              iconName="3DPrinter"
              className="user-roi--content--table--item--icon"
            />
            <div className="user-roi--content--table--item--data">
              {getString('MACHINE_PURCHASE_COST')}
            </div>
          </div>
        </div>
        <div className="user-roi--content--table--item">{`${userCurrencySign} ${
          userROIMachineCost ? Numeral(userROIMachineCost).format('0,0') : 'X'
        }`}</div>
        <div className="user-roi--content--table--item user-roi--content--table--item--take-two user-roi--content--table--item--bottom">
          <StatsSimpleDataCard
            mainData={userROIDataText}
            explenation={
              userROITraditionalSavingYears > 0
                ? getString('USER_PANEL_ROI_TRADITIONAL_YEARS_EXPLANATION')
                : getString('USER_PANEL_ROI_NO_PARTS_FOUND')
            }
            contentMainDataClassName="user-roi--content--table--item--main-data"
            explenationClassName="user-roi--content--table--item--explanation"
            contentClassName="user-roi--content--table--item--content"
          />
        </div>
      </div>
    </div>
  )
}

export default memo(RoiData)
