import { memo, FC } from 'react'
import TransparentButton from '../TransparentButton'
import './DataTable.scss'

interface Props {
  text: any
  data: any
  className: string
}

const DataTableTransparentButtonWithLoader: FC<Props> = ({
  text,
  data: { onClick, buttonClassName, disabled, buttonLoading },
  className
}) => (
  <TransparentButton
    className={`${buttonClassName} ${className}`}
    disabled={disabled}
    onClick={onClick}
    loading={buttonLoading}
  >
    {text}
  </TransparentButton>
)

export default memo(DataTableTransparentButtonWithLoader)
