import { FC, memo, useState, useEffect } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'

import ToleranceClassMenu from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import { getString } from 'Services/Strings/StringService'
import CastorAlert from '../../../../../Components/alerts/CastorAlert'
import { Button } from '../../../../../Components/thirdParty/CreativeTim/components'
import IconFactory from '../../../../../Components/StarIcon/IconFactory'

import './TolerancesChanger.scss'

interface IProps {
	loading: boolean
	show: boolean
	initialToleranceValue: string
	onConfirm: Function
	onCancel: Function
	onRemove: Function
	toggleTolerancePopup: Function
}

const TolerancesChanger: FC<IProps> = ({
	loading,
	show,
	initialToleranceValue,
	onConfirm,
	onCancel,
	onRemove,
	toggleTolerancePopup
}) => {
	const [customToleranceValue, setCustomTolerance] = useState<string>(
		initialToleranceValue
	)
	const [disableAlertButton, setDisableAlertButton] = useState(true)
	useEffect(() => {
		setCustomTolerance(initialToleranceValue)
	}, [initialToleranceValue])

	const onSelectToleranceClass = (toleranceClass: string) => {
		setCustomTolerance(toleranceClass)
		setDisableAlertButton(initialToleranceValue == toleranceClass)
	}

	const onAlertCancel = () => {
		setCustomTolerance(initialToleranceValue)
		onCancel()
	}

	const onAlertRemove = () => {
		onRemove()
		onCancel()
	}

	const renderAlertBody = () => {
		return (
			<Flexbox
				className="tolerances-changer"
				flexDirection="column"
				alignItems="baseline"
			>
				<Flexbox
					className="alert--message"
					flexDirection="row"
					alignItems="center"
				>
					<div>{getString('UPDATE_TOLERANCE_POPUP_MESSAGE')}</div>
					<Button
						className="tolerance-check-box-icon"
						justIcon
						onClick={() => toggleTolerancePopup(true)}
						color="transparent"
					>
						<IconFactory
							iconName="info"
							className="upload-form-tolerance-info-icon"
						/>
					</Button>
				</Flexbox>
				<ToleranceClassMenu
					showIrrelevant={true}
					toleranceIncluded={true}
					disableUnderline={true}
					selectedToleranceClass={customToleranceValue}
					onSelectToleranceClass={onSelectToleranceClass}
				></ToleranceClassMenu>
			</Flexbox>
		)
	}

	return (
		<CastorAlert
			loadingCalculation={loading}
			headerTitle={getString('UPDATE_TOLERANCE_POPUP_HEADER')}
			show={show}
			onConfirm={() => onConfirm(customToleranceValue)}
			showCancel={true}
			onCancel={onAlertCancel}
			cancelOptionalText={getString('REMOVE_TOLERANCE')}
			confirmOptionalText={getString('UPDATE_TOLERANCE')}
			onFooterCancel={() => onAlertRemove()}
			disabled={disableAlertButton !== null ? disableAlertButton : true}
		>
			{renderAlertBody()}
		</CastorAlert>
	)
}

export default memo(TolerancesChanger)
