import { RootStateOrAny, useSelector } from 'react-redux'

import { SolutionAnalysisInitialState } from '../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisReducer'

export const usePartReducer = () => {
	const mainPartData = useSelector((state: RootStateOrAny) => {
		return state?.MainPartAnalysisReducer
	})

	return {
		...mainPartData
	}
}

export const useSolutionReducer = (configurationId: number) => {
	const solutionData = useSelector((state: RootStateOrAny) => {
		return (
			state.SolutionAnalysisReducer?.states[configurationId] ||
			new SolutionAnalysisInitialState()
		)
	})

	return {
		...solutionData
	}
}

export const useProjectReducer = () => {
	const {
		weightReductionProgressFromProject,
		doRefreshConfigurations,
		onlyPart
	} = useSelector(
		(state: RootStateOrAny) => {
			return (
				state?.ProjectAnalysisReducer
			)
		})

	return {
		weightReductionProgressFromProject,
		doRefreshConfigurations,
		onlyPart
	}
}

export const useUserReducer = () => {
	const {
		userDetails,
		materials,
		pagesVisited,
		pages,
		userCustomizationSettings,
		priorities,
		...props
	} = useSelector((state: RootStateOrAny) => {
		return (
			state?.user
		)
	})
	return {
		userEmail: userDetails.email,
		pagesVisitedUserDetails: userDetails.pagesVisited,
		userDetails,
		materials,
		pagesVisited,
		tourPages: pages,
		userCustomizationSettings,
		priorities,
		pages,
		...props
	}
}

export const useGlobalReducer = () => {
	const [
		loaderCounter,
		crumbs,
		providerState
	] = useSelector((state: RootStateOrAny) => {
		return [
			state?.GlobalReducer.loaderCounter,
			state?.userHome.crumbs,
			state?.IntegrationProviderReducer.providerState
		]
	})

	return {
		loaderCounter,
		crumbs,
		providerState
	}
}

/*
 *
 *  Selector that returns all props
 *
 * */
export const useAllData = () => {
	return {
		...usePartReducer(),
		...useProjectReducer(),
		...useUserReducer(),
		...useGlobalReducer()
	}
}