import React, { memo, FC } from 'react'

import './DataTable.scss'

interface Props {
  text: string | number
  data: any
  className: string
}

const DataTableTextWithSD: FC<Props> = ({
  text,
  data: { dataOrDeviation },
  className
}) => (
  <div>
    {text}
    {dataOrDeviation ? <span className={`data-table-opacity-field ${className}`}>
      {' '}
    ± {dataOrDeviation}
    </span>: <div/>}
  </div>
)

export default memo(DataTableTextWithSD)
