import React, { memo } from 'react'

import cx from 'classnames'

import DataTable from 'Scenes/Components/DataTable'
import Flexbox from 'Scenes/Components/FlexBox'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { partResults } from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import './AnalysisResultsTab.scss'

interface Props {
	meshUploadURL?: string
	configurationResult?: string
	analysisResultsRows: any[][]
	part: Part
	shouldShowButton: boolean
	onGeometryAnalysisReviewAndFixesClick: Function
}

const renderButton = (
	shouldShowButton: boolean,
	onGeometryAnalysisReviewAndFixesClick: Function,
	isDrawing?: boolean,
	meshUploadURL?: string,
	configurationResult?: string,
	isMetadata?: boolean
) => {
	const { buttons } = getTheme()
	const show3dButton =
		(configurationResult === partResults.printable ||
			configurationResult === partResults.borderline) &&
		isDrawing &&
		meshUploadURL &&
		Feature.isFeatureOn(FeatureComponentId.DRAWING_3D)

	if (((!shouldShowButton || isDrawing) && !show3dButton) || isMetadata) {
		return <div></div>
	}

	return (
		<Button
			color={buttons.tableButtonColor}
			onClick={onGeometryAnalysisReviewAndFixesClick}
		>
			{show3dButton
				? getString('PREVIEW_3D')
				: getString('PART_ANALYSIS_CONFIGURE_REVIEW_AND_FIX')}
		</Button>
	)
}

const AnalysisResultsTab: React.FC<Props> = ({
	analysisResultsRows,
	shouldShowButton,
	onGeometryAnalysisReviewAndFixesClick,
	part,
	meshUploadURL,
	configurationResult
}) => {
	const isSmallTable = analysisResultsRows.length === 1
	const isDrawing = part?.isDrawing
	const isMetadata = part?.formatType === METADATA

	return (
		<Flexbox
			className={cx('data-table-results review-and-fix', {
				'small-table': isSmallTable
			})}
			flexDirection="column"
		>
			<DataTable
				tableClassName="data-table-results--grid"
				tableDataRows={analysisResultsRows}
				tableStyle={{ gridTemplateColumns: '0.5fr 1.5fr' }}
				showBreakLines
				showBreakLineAtEndOfTable
			/>
			{renderButton(
				shouldShowButton,
				onGeometryAnalysisReviewAndFixesClick,
				isDrawing,
				meshUploadURL,
				configurationResult,
				isMetadata
			)}
		</Flexbox>
	)
}

export default memo(AnalysisResultsTab)
