import React, { FC, memo, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import * as CastorAlertActions from './CastorAlertActions'
import ModalFooter from '../Modal/ModalFooter'
import ModalHeader from '../Modal/ModalHeader'
import { CastorAlertInitialState } from './CastorAlertReducer'
import MySimpleAlert from './MySimpleAlert'
import { Action } from 'global actions/ActionModels'
import Flexbox from 'Scenes/Components/FlexBox'

import './CastorAlert.scss'

interface IReduxStore {
	CastorAlertReducer: CastorAlertInitialState
}

interface IReduxProps {
	onAlertCancel: () => Action<any>
}

const CastorAlert: FC<IReduxProps & CastorAlertInitialState> = ({
	headerTitle,
	onAlertCancel,
	show,
	onConfirm,
	onCancel,
	alertClass,
	alertBodyClass,
	text,
	confirmText,
	loadingCalculation,
	cancelText,
	disabled,
	showCancel,
	showConfirm,
	extraButton,
	alertType,
	onButtonHoverText,
	closeOnClickOutside
}) => {
	useEffect(() => {
		if (show) {
			document.body.style.overflow = 'hidden'
		}
		return () => {
			document.body.style.overflow = 'unset'
		}
	}, [show])

	return (
		<MySimpleAlert
			title={
				headerTitle && (
					<ModalHeader
						alertType={alertType}
						title={headerTitle}
						onCancel={onCancel || onAlertCancel}
					/>
				)
			}
			show={show}
			onCancel={onCancel || onAlertCancel}
			showConfirm={false}
			onConfirm={onConfirm}
			customClass={`castor-alert ${alertClass}`}
			closeOnClickOutside={closeOnClickOutside}
		>
			<Flexbox flexDirection="column">
				{text && (
					<div className={`castor-alert--body ${alertBodyClass}`}>{text}</div>
				)}
				<ModalFooter
					disabled={disabled}
					onCancel={onCancel || onAlertCancel}
					onConfirm={onConfirm}
					cancelOptionalText={cancelText}
					confirmOptionalText={confirmText}
					loadingCalculation={loadingCalculation}
					showConfirm={showConfirm}
					showCancel={showCancel}
					extraButton={extraButton}
					onButtonHoverText={onButtonHoverText}
				/>
			</Flexbox>
		</MySimpleAlert>
	)
}

const mapStateToProps = ({ CastorAlertReducer }: IReduxStore) => {
	return {
		...CastorAlertReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CastorAlertActions }, dispatch)

export default memo(connect(mapStateToProps, mapDispatchToProps)(CastorAlert))
