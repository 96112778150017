import React, { cloneElement, Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as PushNotificationActions from '../PushNotification/PushNotificationActions'
import * as CastorPopperMenuActions from './CastorPopperMenuActions'
import PopperMenu from '../Popper/PopperMenu'

class CastorPopperMenu extends Component {
	onPopperMenuClicked = () => {
		const { menuName, openMenu, popperMenuClicked } = this.props
		popperMenuClicked(menuName, !openMenu[menuName])
	}

	onHandleCloseClicked = () => {
		const { openMenu, popperMenuClosed } = this.props
		if (Object.keys(openMenu).length) {
			popperMenuClosed()
		}
	}

	toggleNotificationView = () => {
		const { toggleNotificationView } = this.props

		toggleNotificationView(false)
	}

	render() {
		const {
			menuName,
			openMenu,
			menuItems,
			targetItem,
			className,
			qaDataElementName
		} = this.props
		return (
			<PopperMenu
				open={openMenu[menuName]}
				targetItem={cloneElement(targetItem, {
					onClick: e => {
						e.stopPropagation()
						this.onPopperMenuClicked()
						this.toggleNotificationView()
					}
				})}
				menuItems={cloneElement(menuItems, {
					onClick: this.onHandleCloseClicked
				})}
				handleClose={this.onHandleCloseClicked}
				className={className}
				qaDataElementName={qaDataElementName}
			/>
		)
	}
}

const mapStateToProps = ({ CastorPopperMenuReducer }) => {
	const { openMenu } = CastorPopperMenuReducer
	return { openMenu }
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{ ...CastorPopperMenuActions, ...PushNotificationActions },
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(CastorPopperMenu)
