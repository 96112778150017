import React, { FC, memo, useState } from 'react'
import { Menu, MenuItem } from '@material-ui/core'
import NestedMenuItem from 'material-ui-nested-menu-item'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import CheckIcon from '@material-ui/icons/Check'
import { map } from 'lodash'

import { defaultNamingPrinterConfiguration } from '../../../../../Services/Constants'
import {
  IPrintersTypes,
  ICompaniesPrinters,
  IPrinter,
  IBestMatchConfiguration,
  IDefaultConfiguration
} from '../../../../../Services/models/IPrintersTypes'
import { IConfigureProperty } from '../NewPartConfigurationTypes'
import IconFactory from '../../../../Components/StarIcon/IconFactory'
import ConfigurePropertiesMethod from '../NewConfigureProperties/ConfigurePropertiesMethod'

import './ConfigurePrintersMethod.scss'

const ArrowDropDownTS: any = ArrowDropDown
const NamingPrinterConfiguration: any = defaultNamingPrinterConfiguration

interface IProps {
  printersConfiguration: any
  configurationProperties: Array<IConfigureProperty>
  chosenPrinterConfigurationType: string
  chosenPrinterConfiguration: any
  onChangeSelectedPrinter: Function
  onSaveNewPriorities: Function
  onChangeProperty: Function
  propertyName: string
  semiProfessionalOn: boolean
}

const confLabel = 'Configuration'
// need to pass as JS, can't add class to menu
const popperStyle = {
  style: {
    width: '300px',
    maxWidth: '300px'
  }
}

const ConfigurePrintersMethod: FC<IProps> = ({
  onChangeSelectedPrinter,
  chosenPrinterConfigurationType,
  chosenPrinterConfiguration,
  printersConfiguration,
  onSaveNewPriorities,
  configurationProperties,
  onChangeProperty,
  propertyName,
  semiProfessionalOn
}) => {
  const [menuPosition, setMenuPosition] = useState<any>(null)
  const [open, setOpen] = useState<boolean>(false)

  const handleMenuClick = (event: React.MouseEvent) => {
    const { left, top } = event.currentTarget.getBoundingClientRect()

    if (menuPosition) {
      return
    }
    event.preventDefault()

    setMenuPosition({
      top: top + 40,
      left: left
    })
  }

  const preventEvent = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleItemClick = (
    printer: IPrinter | IDefaultConfiguration,
    key: string
  ) => {
    setMenuPosition(null)
    onChangeSelectedPrinter(printer, key)
  }

  const mapBestMatchConfigurations = (
    printerType: IBestMatchConfiguration,
    key: string
  ) => {
    return (
      <NestedMenuItem
        key={key}
        className="nested-menu"
        label={
          <span className="dropdown-config__title">
            <IconFactory
              iconName={key}
              className="solution-analysis-header-text-icon"
            />
            {NamingPrinterConfiguration[key]}
          </span>
        }
        parentMenuOpen={!!menuPosition}
      >
        {map(printerType.printers, printer => {
          if (printer.desktopPrinter && !semiProfessionalOn) {
            return <div key={printer.name} />
          }
          return (
            <MenuItem
              className="nested-menu"
              key={printer.name}
              onClick={() => handleItemClick(printer, key)}
            >
              <span className="dropdown-config__label">
                {printer.name}
                {chosenPrinterConfiguration?.name === printer.name && (
                  <CheckIcon />
                )}
              </span>
            </MenuItem>
          )
        })}
        <MenuItem
          className="nested-menu"
          onClick={() => {
            setOpen(true)
            setMenuPosition(null)
          }}
        >
          <span className="dropdown-config__label">
            Custom
            {chosenPrinterConfiguration?.name === confLabel && <CheckIcon />}
          </span>
        </MenuItem>
      </NestedMenuItem>
    )
  }

  const mapPrintersConfigurations = (
    printerType: ICompaniesPrinters,
    key: string
  ) => {
    if (printerType?.printers.length === 0) return
    return (
      <NestedMenuItem
        key={key}
        className="nested-menu"
        label={
          <span className="dropdown-config__title">
            <IconFactory
              iconName={key}
              className="solution-analysis-header-text-icon"
            />
            {NamingPrinterConfiguration[key]}
          </span>
        }
        parentMenuOpen={!!menuPosition}
      >
        <div className="dropdown-fixed-height-menu">
          <div className="dropdown-fixed__menu">
            {map(printerType.printers, singleCompany => {
              return (
                <NestedMenuItem
                  className="nested-menu"
                  key={singleCompany.company}
                  label={
                    <span className="dropdown-config__title">
                      {singleCompany.company}
                    </span>
                  }
                  parentMenuOpen={!!menuPosition}
                >
                  <div className="dropdown-fixed-height-menu">
                    <div className="dropdown-fixed__menu">
                      {map(singleCompany.printers, singlePrinter => {
                        return (
                          <MenuItem
                            className="nested-menu"
                            key={singlePrinter.name}
                            onClick={() => handleItemClick(singlePrinter, key)}
                          >
                            <span className="dropdown-config__label">
                              {singlePrinter.name}
                              {chosenPrinterConfiguration?.name ===
                                singlePrinter.name && <CheckIcon />}
                            </span>
                          </MenuItem>
                        )
                      })}
                    </div>
                  </div>
                </NestedMenuItem>
              )
            })}
          </div>
        </div>
      </NestedMenuItem>
    )
  }

  return (
    <div className="configure-manufacturing-method">
      <div onClick={handleMenuClick} className="label">
        {NamingPrinterConfiguration[chosenPrinterConfigurationType]}:{' '}
        {chosenPrinterConfiguration?.name}
        <ArrowDropDownTS />
      </div>
      <Menu
        className="configure-manufacturing-method--ddl"
        onMouseEnter={preventEvent}
        onMouseLeave={preventEvent}
        PaperProps={popperStyle}
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
      >
        <div className="dropdown-config__menu">
          {map(printersConfiguration, (confPrinter, key) => {
            if (key === IPrintersTypes.BEST_MATCH) {
              return mapBestMatchConfigurations(confPrinter, key)
            } else {
              return mapPrintersConfigurations(confPrinter, key)
            }
          })}
        </div>
      </Menu>
      <ConfigurePropertiesMethod
        propertyName={propertyName}
        onChangeProperty={onChangeProperty}
        onSaveNewPriorities={onSaveNewPriorities}
        configurationProperties={configurationProperties}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </div>
  )
}

export default memo(ConfigurePrintersMethod)
