import React, { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'

import { toNumber } from 'lodash'

import CastorForm from '../../../../Components/CastorForm/CastorForm'
import DataTable from '../../../../Components/DataTable'
import NavBarAndMaterial from '../../../../Components/NavBarAndMaterial'
import { Button } from '../../../../Components/thirdParty/CreativeTim/components'
import { fetchUserInfo, onUserInfoEditClick } from '../AdminUsersActions'
import AdminUserPermissions from './AdminUserPermissions'
import ErrorPage from 'Scenes/Components/ErrorPage'
import PageLoader from 'Scenes/Loader/PageLoader'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { adminUserSubscriptionEditInfoRoute } from 'Services/routeFuncs'
import {
	NO_USER_MATERIALS_MSG,
	NO_USER_PRINTERS_MSG,
	USER_INFORMATION_HEADERS
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './AdminUserInfo.scss'

const NavBarAndMaterialTSX: any = NavBarAndMaterial
const LinkTSX: any = Link
const ButtonTSX: any = Button
const style = { maxWidth: 'unset' }

const AdminUserInfo: FC = () => {
	const { params } = useRouteMatch() as { params: Record<string, string> }
	const { userId } = params
	const dispatch = useDispatch()
	const {
		userSelected,
		loading,
		userSubscriptionInfoData,
		userPersonalInfoData,
		userProjectAndPartInfoData,
		userPrintersData,
		userMaterialsData,
		userPrintersHeaders,
		userMaterialsHeaders,
		error,
		userIdError
	} = useSelector((state: RootStateOrAny) => state.AdminUsersReducer)
	const { loaderCounter } = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer
	)
	const editUser = Feature.isFeatureOn(FeatureComponentId.EDIT_USER)
	const editUserPermission = Feature.isFeatureOn(
		FeatureComponentId.EDIT_USER_PERMISSION
	)

	useEffect(() => {
		if (
			(error && !!userIdError && toNumber(userIdError) !== toNumber(userId)) ||
			(!error &&
				((!userSelected && !loading) ||
					(!loading && toNumber(userSelected?.id) !== toNumber(userId))))
		) {
			dispatch(fetchUserInfo(userId))
		}
	}, [userSelected, loading, userId, error, userIdError])

	const renderSubscriptionInfoTable = (userSelected: any) => {
		return (
			<div>
				{editUser && (
					<LinkTSX
						to={adminUserSubscriptionEditInfoRoute(userSelected.id)}
						onClick={() => dispatch(onUserInfoEditClick(userSelected))}
					>
						<ButtonTSX size="sm" color="primary" data-qa="data-qa-edit-button">
							{getString('EDIT')}
						</ButtonTSX>
					</LinkTSX>
				)}
				<DataTable
					tableClassName="admin--users--user-info"
					tableDataRows={userSubscriptionInfoData}
					showBreakLines={true}
				/>
			</div>
		)
	}

	const renderPersonalInfo = () => {
		return (
			<DataTable
				tableClassName="admin--users--user-info"
				tableDataRows={userPersonalInfoData}
				showBreakLines={true}
			/>
		)
	}
	const renderPrintersInfo = () => {
		return userPrintersData && userPrintersData.length ? (
			<DataTable
				tableClassName="admin--users--user-info"
				tableDataRows={userPrintersData}
				tableHead={userPrintersHeaders}
				showBreakLines={true}
			/>
		) : (
			<div>{NO_USER_PRINTERS_MSG}</div>
		)
	}
	const renderMaterialsInfo = () => {
		return userMaterialsData && userMaterialsData.length ? (
			<DataTable
				tableClassName="admin--users--user-info"
				tableDataRows={userMaterialsData}
				showBreakLines={true}
				tableHead={userMaterialsHeaders}
			/>
		) : (
			<div>{NO_USER_MATERIALS_MSG}</div>
		)
	}
	const renderProjectAndPartsInfoTable = () => {
		return (
			<DataTable
				tableClassName="admin--users--user-info"
				tableDataRows={userProjectAndPartInfoData}
				showBreakLines={true}
			/>
		)
	}

	if (loaderCounter) {
		return <PageLoader withDrawer />
	}

	return (
		<NavBarAndMaterialTSX title={getString('ADMIN_USERS_PAGE_HEADER')}>
			{!userSelected ? (
				<ErrorPage
					showMessageOnly
					errorMessage={getString('ADMIN_USERS_NO_USERS_FOUND')}
				/>
			) : (
				<>
					{!!editUserPermission && (
						<CastorForm
							formTitle={USER_INFORMATION_HEADERS.PERMISSIONS}
							formSubTitle=""
							content={<AdminUserPermissions />}
							style={style}
						/>
					)}
					<CastorForm
						formTitle={
							USER_INFORMATION_HEADERS.SUBSCRIPTION_INFORMATION_INFO_HEADER
						}
						formSubTitle=""
						content={renderSubscriptionInfoTable(userSelected)}
						style={style}
					/>
					<CastorForm
						formTitle={USER_INFORMATION_HEADERS.PROJECT_AND_PARTS_INFO_HEADER}
						formSubTitle=""
						content={renderProjectAndPartsInfoTable()}
						style={style}
					/>
					<CastorForm
						formTitle={USER_INFORMATION_HEADERS.PERSONAL_INFO_HEADER}
						formSubTitle=""
						content={renderPersonalInfo()}
						style={style}
					/>
					<CastorForm
						formTitle={USER_INFORMATION_HEADERS.PRINTERS_INFO_HEADER}
						formSubTitle=""
						content={renderPrintersInfo()}
						style={style}
					/>
					<CastorForm
						formTitle={USER_INFORMATION_HEADERS.MATERIALS_INFO_HEADER}
						formSubTitle=""
						content={renderMaterialsInfo()}
						style={style}
					/>
				</>
			)}
		</NavBarAndMaterialTSX>
	)
}

export default memo(AdminUserInfo)
