import React, { FC, memo } from 'react'

import { Switch } from '@material-ui/core'

import './CastorSwitch.scss'


const SwitchComponent: any = Switch

interface IProps {
	checked: boolean
	disabled?: boolean
	onChange: Function
	classes?: Record<string, string>

	[name: string]: any
}

const CastorSwitch: FC<IProps> = ({
	checked,
	disabled,
	onChange,
	classes = {},
	...props
}) => {
	const changedProps = {
		...props,
		value: props?.value?.toString() || ''
	}
	return (
		<SwitchComponent
			onClick={(e: Event) => {
				e.stopPropagation()
			}}
			checked={!!checked}
			disabled={disabled}
			onChange={onChange}
			classes={{
				checked: 'switch--checked-icon',
				disabled: 'switch--disabled-icon',
				...classes
			}}
			{...changedProps}
		/>
	)
}

export default memo(CastorSwitch)