import React, { FC } from 'react'
import Chart from 'react-apexcharts'

import cx from 'classnames'
import { isEmpty } from 'lodash'

import LeadTimeChartExplanation from '../../../SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/LeadTimeTab/LeadTimeChartExplanation'
import {
	checkOnStandard,
	getErrorMessage,
	getMethodName
} from '../../../SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/LeadTimeTab/LeadTimeService'
import LockLeadTimeGraph from '../../../SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/LeadTimeTab/LockLeadTimeGraph'
import { LineBreak } from 'Scenes/Components/PdfComponents/LineBreak'
import {
	usePDFReduxState,
	useUpdateChart
} from 'Services/CustomHooks/usePdfHooks'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

const ChartGraph: any = Chart

interface IProps {
	part: Part
	manufacturingMethod: string
	configuration: any
	solution: any
	configurationId: any
	fullConfiguration: any
	show: boolean
	isStandardCostManufacturing: boolean
}

export const LeadTimePdf: FC<IProps> = ({
	configurationId,
	manufacturingMethod,
	fullConfiguration,
	configuration,
	show,
	isStandardCostManufacturing
}) => {
	const { chartLeadData, leadTimeLoading, unlockLeadTimeGraph, timeBenefit } =
		usePDFReduxState(configurationId)

	const chart = useUpdateChart(chartLeadData)

	if (!show) return <div />

	const isStandard =
		checkOnStandard(manufacturingMethod) ||
		(!checkOnStandard(manufacturingMethod) &&
			!configuration.standardCost &&
			configuration.part.standardCost)
	const methodText = getMethodName(manufacturingMethod)
	const leadTimeResults = configuration?.leadTimeResults

	const errorMessage = getErrorMessage(
		unlockLeadTimeGraph,
		leadTimeResults?.tmResults,
		leadTimeResults?.amResults,
		configuration?.solution.quantity,
		isStandard
	)

	const isNotSpecifiedQuantity =
		!configuration.isSpecifiedQuantity ||
		!configuration.part.isSpecifiedQuantity

	if (isNotSpecifiedQuantity) {
		return <></>
	}

	return (
		<div className="inline">
			<div className="export-pdf__lead-time last">
				<h2>
					{isStandardCostManufacturing
						? getString(
								'PART_FINANCIAL_ANALYSIS_CARD_SUB_TITLE_LEAD_NO_COMPARE'
						  )
						: getString(
								'PART_FINANCIAL_ANALYSIS_CARD_SUB_TITLE_LEAD_METAL'
						  ).format(methodText)}
				</h2>
				<br />
				<div className="lead-time-chart">
					<div
						className={cx('financial-form-chart lead', {
							blur: errorMessage
						})}
					>
						{!isEmpty(chart) && (
							<ChartGraph
								type="line"
								options={chart && chart?.componentChartData}
								series={chart && chart?.series}
								height="200px"
								width="1200px" //a4 width
							/>
						)}
					</div>
					{!leadTimeLoading && (
						<LockLeadTimeGraph errorMessage={errorMessage} />
					)}
				</div>
				{!errorMessage && (
					<LeadTimeChartExplanation
						configuration={configuration}
						solution={fullConfiguration.solution}
						timeBenefit={timeBenefit}
						isStandard={isStandard}
						methodText={methodText}
					/>
				)}
				<LineBreak />
			</div>
		</div>
	)
}

export default LeadTimePdf
