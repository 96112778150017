import React, { FC, memo } from 'react'
import { bindActionCreators, AnyAction } from 'redux'
import { DispatchProp, connect } from 'react-redux'

import * as CostAndLeadActions from '../CostAndLeadActions'
import ButtonWithLoader from '../../../../Components/ButtonWithLoader'
import { ADD } from '../../../../../Services/Strings'
import CustomizePrinterSelector from './CustomizePrinterSelector'
import { ISimpleConfigurationPrinter } from '../../../../Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import CustomizePrinterFields from './CustomizePrinterFields'
import CustomizePrinterCostOptions from './CustomizePrinterCostOptions'
import ConfigurationSolutionMap from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/ConfigurationContent/ConfigurationTabDetails/ConfigurationSolutionMap'
import { getString } from 'Services/Strings/StringService'
import './CustomizePrinterProps.scss'

// interface IProps {
// }

interface IReduxProps {
	printerCompanies: any[]
	printerNames: ISimpleConfigurationPrinter[]
	selectedPrinterCost: number
	selectedPrinterHourlyCost: number
	selectedPrinterLayerThickness: number
	selectedPrinterCostParameter: String
	defaultPrinterCost: number
	disablePrinterPropsButton: boolean
	defaultLayerThickness: any
	onPrinterCompanyChange: (printerCompany: string) => any
	onPrinterCostParameterChange: (printerCostParameters: string) => any
	onPrinterNameChange: any
	onPrinterPropsPriceChange: (
		value: number,
		selectedPrinterCostParameter: string
	) => any
	onPropsLayerThicknessChange: (value: number) => any
	onPrinterSettingsAdd: any
	selectedPrinter: string
	price: number
	layerThickness: number
	userPrinterSettingsLoading: any
	incorrectEntryForLayerThickness: boolean
	incorrectEntryForPrice: boolean
	incorrectEntryForHourlyPrice: boolean
}

interface ICostAndLeadReducerState {
	printerCompanies: any[]
	printerNames: ISimpleConfigurationPrinter[]
	selectedPrinterCost: number
	selectedPrinterHourlyCost: number
	selectedPrinterLayerThickness: number
	selectedPrinterCostParameter: String
	defaultPrinterCost: number
	disablePrinterPropsButton: boolean
	defaultLayerThickness: any
	selectedPrinter: string
	price: number
	layerThickness: number
	userPrinterSettingsLoading: boolean
	incorrectEntryForLayerThickness: boolean
	incorrectEntryForPrice: boolean
	incorrectEntryForHourlyPrice: boolean
}

interface IReduxStore {
	CostAndLeadReducer: ICostAndLeadReducerState
}

export const CustomizePrinterPropsInputs: FC<IReduxProps> = ({
	selectedPrinterLayerThickness,
	selectedPrinterCost,
	selectedPrinterHourlyCost,
	disablePrinterPropsButton,
	onPrinterPropsPriceChange,
	defaultPrinterCost,
	defaultLayerThickness,
	onPropsLayerThicknessChange,
	onPrinterSettingsAdd,
	userPrinterSettingsLoading,
	selectedPrinter,
	incorrectEntryForLayerThickness,
	incorrectEntryForPrice,
	selectedPrinterCostParameter,
	incorrectEntryForHourlyPrice
}) => {
	return (
		<div className="customize-printer-props--content--inputs">
			<div className="customize-printer-props--content--inputs--title"></div>
			<div className="customize-printer-props--content--inputs--wrapper">
				<CustomizePrinterSelector />
				<CustomizePrinterCostOptions></CustomizePrinterCostOptions>
				<CustomizePrinterFields
					selectedPrinterCost={selectedPrinterCost}
					selectedPrinterHourlyCost={selectedPrinterHourlyCost}
					onPrinterPropsPriceChange={onPrinterPropsPriceChange}
					defaultPrinterCost={defaultPrinterCost}
					incorrectEntryForPrice={incorrectEntryForPrice}
					incorrectEntryForLayerThickness={incorrectEntryForLayerThickness}
					selectedPrinterCostParameter={selectedPrinterCostParameter}
					incorrectEntryForHourlyPrice={incorrectEntryForHourlyPrice}
				/>
				<ButtonWithLoader
					disabled={disablePrinterPropsButton}
					className="customize-printer-props--content--inputs--button"
					size={30}
					top={20}
					loading={userPrinterSettingsLoading}
					onClick={() =>
						onPrinterSettingsAdd(
							selectedPrinter,
							selectedPrinterCost,
							selectedPrinterHourlyCost,
							selectedPrinterLayerThickness || defaultLayerThickness
						)
					}
				>
					{ADD}
				</ButtonWithLoader>
			</div>
		</div>
	)
}

const mapStateToProps = ({ CostAndLeadReducer }: IReduxStore) => {
	return {
		...CostAndLeadReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CostAndLeadActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizePrinterPropsInputs)
)
