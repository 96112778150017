import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import TextField from '@material-ui/core/TextField'

import * as CustomizeInHousePrintersActions from './CustomizeInHousePrintersActions'
import { getString } from '../../../../Services/Strings/StringService'
import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import MaterialPricePicker from '../MaterialPricePicker'

import './CustomizeInHousePrinters.scss'

class InHouseMaterialOtherSelector extends Component {
	componentWillUnmount() {
		this.props.onInHousePrintersUnmount({
			otherMaterialsSelected: []
		})
	}

	onOtherMaterialNameSelectorChange = (e, id) => {
		const { onOtherMaterialNameSelectorChange } = this.props
		onOtherMaterialNameSelectorChange(e.target.value, id)
	}

	onPlusButtonClick = (name, id) => {
		const { onOtherPlusButtonChange } = this.props
		onOtherPlusButtonChange(name, id)
	}

	render() {
		const {
			disablePlusButton,
			otherMaterialsSelected,
			onOtherMaterialUnitSelectorChange,
			onOtherMaterialPriceSelectorChange,
			zIndex
		} = this.props
		if (!otherMaterialsSelected.length) {
			return <div />
		}
		return otherMaterialsSelected.map(material => (
			<div className="in-house-printers-other-selector" key={material.id}>
				<TextField
					className="text-field"
					placeholder={getString('MATERIAL_COST_MATERIAL_NAME')}
					onChange={e => this.onOtherMaterialNameSelectorChange(e, material.id)}
					value={material.name}
				/>
				<MaterialPricePicker
					materialPrice={material.price}
					onMaterialPriceChange={e =>
						onOtherMaterialPriceSelectorChange(e.target.value, material.id)
					}
					onWeightSelectorChange={e =>
						onOtherMaterialUnitSelectorChange(e.target.value, material.id)
					}
					selectedWeightUnit={material.unit}
					zIndex={zIndex}
				/>
				<ButtonWithLoader
					disabled={disablePlusButton}
					size={20}
					style={{ padding: '5px 28px', width: 'unset' }}
					onClick={e => this.onPlusButtonClick(material.name, material.id)}
					className={
						!disablePlusButton ? 'plus-button--button' : 'plus-button--disabled'
					}
				>
					{'+'}
				</ButtonWithLoader>
			</div>
		))
	}
}

const mapStateToProps = ({ CustomizeInHousePrintersReducer }) => {
	const { otherMaterialsSelected, disablePlusButton } =
		CustomizeInHousePrintersReducer

	return {
		otherMaterialsSelected,
		disablePlusButton
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...CustomizeInHousePrintersActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InHouseMaterialOtherSelector)
