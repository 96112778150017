export const LOGIN_ROUTE = '/login'
export const REGISTER_ROUTE = '/register'
export const RESET_PASSWORD = '/resetPassword'
export const EXTERNAL_ROUTE_PREFIX = '/external'
export const LOGIN_PROVIDER = '/login-provider'
export const AZURE = '/azure'
export const UPLOAD_ROUTE = '/uploadProject'
export const UPLOAD_SCRIPT_ROUTE = '/uploadScript'
export const USER_PANEL_ROUTE = '/myPanel'
export const UPLOADING_ROUTE = '/uploadingProject'
export const PRINTABLE_ANALYSIS_ROUTE = '/printableAnalysis'
export const UNPRINTABLE_ANALYSIS_ROUTE = '/unprintableAnalysis'
export const USER_HOME_ROUTE = '/home'
export const PROJECTS_ROUTE = '/projects'
export const CASTOR_LIGHT_ROUTE = '/castorLight'
export const COMMUNICATION_TOOL_ROUTE = '/comsTool'
export const FEA_ROUTE = '/fea'
export const PART_ROUTE = '/part'
export const CONFIGURATION_ROUTE = '/configurations'
export const CLUSTER_OVERVIEW_ROUTE = '/clustersOverview'
export const CLUSTER_ANALYSIS_ROUTE = '/clusterAnalysis'
export const ALL_CLUSTER_VIEW = '/allClusters'
export const ALL_WEIGHT_REDUCTION_VIEW = '/allWeightReduction'
export const ADMIN_ROUTE = '/admin'
export const SEND_TO_SERVICE_BUREAU_ROUTE = `${USER_HOME_ROUTE}/getPrintQuote`
export const TWO_FACTOR_AUTH = '/2fa'
export const USER_ACCOUNT_CONFIRMATION = '/confirmation'
export const PROJECT_ADMIN_ROUTE = ADMIN_ROUTE + '/projects'
export const USERS_ADMIN_ROUTE = ADMIN_ROUTE + '/users'

export const PRINTERS_ADMIN_ROUTE = ADMIN_ROUTE + '/printers'
export const PRINTERS_ADMIN_NEW_ROUTE = ADMIN_ROUTE + '/new-printer'
export const PRINTERS_ADMIN_EDIT_ROUTE = ADMIN_ROUTE + '/printers/edit'

export const MATERIALS_ADMIN_ROUTE = ADMIN_ROUTE + '/materials'
export const MATERIALS_ADMIN_NEW_ROUTE = ADMIN_ROUTE + '/new-material'
export const MATERIALS_ADMIN_EDIT_ROUTE = ADMIN_ROUTE + '/materials/edit'

export const PRINTER_MATERIALS_ADMIN_ROUTE = ADMIN_ROUTE + '/printerMaterials'
export const PRINTER_MATERIALS_ADMIN_NEW_ROUTE =
	ADMIN_ROUTE + '/new-printerMaterial'
export const PRINTER_MATERIALS_ADMIN_EDIT_ROUTE =
	ADMIN_ROUTE + '/printerMaterials/edit'

export const PRINTER_NAMES_ADMIN_ROUTE = ADMIN_ROUTE + '/printerNames'
export const PRINTER_NAMES_ADMIN_EDIT_ROUTE = ADMIN_ROUTE + '/printerNames/edit'

export const MATERIAL_PRINTER_PAIRS_ADMIN_ROUTE =
	ADMIN_ROUTE + '/materialPrinterPairs'
export const MATERIAL_PRINTER_PAIRS_ADMIN_NEW_ROUTE =
	ADMIN_ROUTE + '/new-materialPrinterPair'

export const SETTINGS_ADMIN_ROUTE = ADMIN_ROUTE + '/settings'
export const STRINGS_ADMIN_ROUTE = ADMIN_ROUTE + '/strings'
export const PARAMETERS_MANAGER_ADMIN_ROUTE =
	ADMIN_ROUTE + '/parameters-manager'

export const CLUSTER_PARTS_ADMIN_ROUTE = ADMIN_ROUTE + '/cluster/parts'

export const ADMIN_PROJECT_INFO_ROUTE = '/project/info'
export const ADMIN_USER_INFO_ROUTE = '/user/info'
export const ADMIN_USER_INFO_SUBSCRIPTION_EDIT_ROUTE =
	'/user/info/subscription/edit'

export const PROJECT_PARTS_ADMIN_ROUTE = ADMIN_ROUTE + '/project/parts'
export const EDIT_PART_ADMIN_ROUTE = ADMIN_ROUTE + '/project/part/edit'

export const CASTOR_HOME = '/castorHome'
export const CUSTOMIZE_ROUTE = '/customize'
export const CUSTOMIZE_TAGS_ROUTE = `${CUSTOMIZE_ROUTE}/part_filtering`
export const CUSTOMIZE_COST_AND_LEAD_ROUTE = `${CUSTOMIZE_ROUTE}/cost_and_lead`
export const CUSTOMIZE_CO2_CALCULATIONS_ROUTE = `${CUSTOMIZE_ROUTE}/co2_calculations`
export const CUSTOMIZE_SOLUTION_FILTER_ROUTE = `${CUSTOMIZE_ROUTE}/solution_filter`
export const CUSTOMIZE_MATERIAL_NAMES_MAPPING_ROUTE = `${CUSTOMIZE_ROUTE}/material-names-mapping`
export const CUSTOMIZE_IN_HOUSE_PRINTERS_ROUTE = `${CUSTOMIZE_ROUTE}/in_house_printers`
export const CUSTOMIZE_MATERIAL_ROUTE = `${CUSTOMIZE_ROUTE}/materials`
export const CUSTOMIZE_MATERIAL_ROUTE_NEW_PRINTER_MATERIAL = `${CUSTOMIZE_ROUTE}/new-am-material`
export const CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE = `${CUSTOMIZE_ROUTE}/recalculate_projects`
export const CUSTOMIZE_NEW_MATERIAL_ROUTE = `${CUSTOMIZE_ROUTE}/new-material`
export const CUSTOMIZE_NEW_PRINTER_ROUTE = `${CUSTOMIZE_ROUTE}/new-printer`
export const CUSTOMIZE_COSTING_FUNCTION_EDITOR_ROUTE = `${CUSTOMIZE_ROUTE}/costing-function-editor`

export const USER_PROFILE_ROUTE = '/profile'
export const USAGE_AGREEMENT = '/usage-agreement'

export const USER_SETTINGS = '/settings'
export const OAUTH2 = '/oauth2'
export const EXTERNAL = OAUTH2 + '/external'

export const RESET_PASSWORD_ROUTE = '/resetPassword'
export const PRINTERS_TECHNOLOGY_ADMIN_ROUTE =
	ADMIN_ROUTE + '/printer-technology'

export const VERSION_PAGE_ADMIN_ROUTE = ADMIN_ROUTE + '/service-health'

export const PRINTERS_TECHNOLOGY_EDIT_ADMIN_ROUTE =
	ADMIN_ROUTE + '/printer-technology/edit'

export const FEATURE_TOGGLE_ADMIN_ROUTE = ADMIN_ROUTE + '/feature-toggle'
export const LICENSE_CREATOR_ADMIN_ROUTE = ADMIN_ROUTE + '/license-creator'
export const LICENSE_SCREEN_ADMIN_ROUTE = ADMIN_ROUTE + '/license-screen'

export const PROJECT_NOT_UPLOADED = '/not-uploaded'
export const ONBOARDING_WIZARD_ROUTE = '/onboarding-wizard'
