import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form'

import * as AdminMaterialsActions from './AdminMaterialsActions'
import { adminMaterialsRoute } from '../../../../Services/routeFuncs'
import { ADMIN_MATERIALS_NEW_USER_MATERIAL } from '../../../../Services/Strings'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import MaterialForm from '../../../Components/MaterialForm/MaterialForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import { materialFormName } from './constants'

class NewAdminMaterial extends Component {
	componentDidMount() {
		this.props.setupNewMaterialForm()
	}

	onSubmit = data => {
		this.props.createNewMaterial(data)
	}

	renderFormContent = () => {
		const {
			materialCategoriesToShow,
			materialCategories,
			onMaterialTypeChange,
			addingNewItem,
			selectedMaterialType,
			selectedMaterialCategory,
			selectedSurfaceFinishMethod,
			defaultFilters,
			materials
		} = this.props
		return (
			<MaterialForm
				onSubmit={this.onSubmit}
				materialCategories={materialCategoriesToShow}
				allMaterialCategories={materialCategories}
				materials={materials}
				onMaterialTypeChange={onMaterialTypeChange}
				addingNewItem={addingNewItem}
				userCurrencySign={'$'}
				selectedMaterialType={selectedMaterialType}
				selectedMaterialCategory={selectedMaterialCategory}
				selectedSurfaceFinishMethod={selectedSurfaceFinishMethod}
				defaultFilters={defaultFilters}
				formName={materialFormName}
			/>
		)
	}

	render() {
		const { materialAdded, pageNumber } = this.props
		if (materialAdded) {
			return <Redirect to={adminMaterialsRoute(pageNumber)} />
		}
		return (
			<NavBarAndMaterial title={ADMIN_MATERIALS_NEW_USER_MATERIAL}>
				<CastorForm
					formTitle={ADMIN_MATERIALS_NEW_USER_MATERIAL}
					content={this.renderFormContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const selector = formValueSelector('adminMaterialFormState')

const mapStateToProps = state => {
	const {
		AdminMaterialsReducer: {
			materialAdded,
			pageNumber,
			materialCategoriesToShow,
			addingNewItem
		},
		user: { materialCategories, materials }
	} = state
	return {
		materialAdded,
		pageNumber,
		materialCategories,
		materialCategoriesToShow,
		addingNewItem,
		defaultFilters: selector(state, 'defaultFilters'),
		selectedMaterialType: selector(state, 'type'),
		selectedMaterialCategory: selector(state, 'category'),
		selectedSurfaceFinishMethod: selector(state, 'surfaceFinishMethod'),
		materials
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminMaterialsActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAdminMaterial)
