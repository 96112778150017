import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { MenuItem, Select } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import cx from 'classnames'
import { map } from 'lodash'

import {
	getAdminStepEnums,
	onAdminStopServer,
	onAdminStopServerInstantly
} from 'Scenes/admin/AdminHome/AdminSettings/AdminSettingsActions'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import Flexbox from 'Scenes/Components/FlexBox'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import HideForRoleHOC from 'Services/HOC/HideForRoleHOC'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { SUBMIT } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const PLACEHOLDER = 'placeholder'
const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem

const transformText = (text: string) => {
	const item = text
		.replace(/_/g, ' ')
		.replace(/([a-z])([A-Z])/g, '$1 $2')
		.replace(/([a-zA-Z])+/g, match => match.toLowerCase())

	return item.charAt(0).toUpperCase() + item.slice(1)
}

const AdminResetServer: FC = () => {
	const [selectedStep, setSelectedStep] = useState<string>(PLACEHOLDER)
	const [partName, setPartName] = useState<string>('')
	const [partNameAfterSaving, setPartNameAfterSaving] = useState<string>('')
	const dispatch = useDispatch()
	const {
		stopServerSteps,
		stopServerStepsLoading,
		stopServerLoading,
		partNameToFailSaving,
		partNameToFail,
		stopServerStep
	} = useSelector((state: RootStateOrAny) => {
		return state.AdminSettingsReducer
	})

	useEffect(() => {
		dispatch(getAdminStepEnums())
	}, [])

	useEffect(() => {
		partNameToFailSaving && setPartNameAfterSaving(partNameToFailSaving)
		partNameToFail && setPartName(partNameToFail)
		stopServerStep && setSelectedStep(stopServerStep)
	}, [partNameToFailSaving, partNameToFail, stopServerStep])

	return (
		<CastorForm
			formTitle={getString('ADMIN_RESET_SERVER_SETTINGS')}
			content={
				<div className="reset-server-form">
					<div className="reset-server--fields">
						<ButtonWithLoader
							loading={stopServerLoading}
							onClick={() => dispatch(onAdminStopServerInstantly())}
						>
							{getString('RESET_MONO_INSTANTLY')}
						</ButtonWithLoader>
						<br />
						<br />
						<Flexbox flexDirection="column" width="50%">
							<label className="label" htmlFor="select">
								{getString('RESET_SUPPORT')}
							</label>
							<SelectTSX
								className={cx('select', {
									disabled: PLACEHOLDER === selectedStep
								})}
								MenuProps={{
									variant: 'menu',
									getContentAnchorEl: null,
									className: 'my-dropdown-menu',
									PopoverClasses: {
										root: 'dropdown'
									}
								}}
								value={selectedStep}
								onChange={(event: ChangeEvent<HTMLInputElement>) => {
									setSelectedStep(event.target.value)
								}}
							>
								<MenuItem value={PLACEHOLDER} disabled>
									{getString('RESET_SUPPORT_PLACEHOLDER')}
								</MenuItem>
								{map(stopServerSteps, (item: string, key: string) => {
									return (
										<MenuItemTSX
											className={`select-drop-down--menu`}
											value={item}
											key={key}
										>
											{transformText(item)}
										</MenuItemTSX>
									)
								})}
							</SelectTSX>
							<br />

							<TextField
								InputLabelProps={{
									shrink: true
								}}
								type="text"
								label={getString('PART_NAME_TO_FAIL')}
								value={partName}
								placeholder={getString('PART_NAME_TO_FAIL_PLACEHOLDER')}
								onChange={(e: ChangeEvent<HTMLInputElement>) =>
									setPartName(e.target.value)
								}
							/>
							<br />
							<TextField
								InputLabelProps={{
									shrink: true
								}}
								type="text"
								label={getString('PART_NAME_TO_FAIL_AFTER_SAVING')}
								value={partNameAfterSaving}
								placeholder={getString(
									'PART_NAME_TO_FAIL_AFTER_SAVING_PLACEHOLDER'
								)}
								onChange={(e: ChangeEvent<HTMLInputElement>) =>
									setPartNameAfterSaving(e.target.value)
								}
							/>
						</Flexbox>
					</div>

					<Flexbox
						alignItems="center"
						alignSelf="flex-end"
						justifyContent="flex-end"
						className="button-block"
					>
						<ButtonWithLoader
							loading={stopServerStepsLoading}
							onClick={() =>
								dispatch(
									onAdminStopServer(
										selectedStep !== PLACEHOLDER ? selectedStep : '',
										partName,
										partNameAfterSaving
									)
								)
							}
						>
							{SUBMIT}
						</ButtonWithLoader>
					</Flexbox>
				</div>
			}
			style={{ maxWidth: 'unset' }}
		/>
	)
}

export default memo(
	HideForRoleHOC(
		WithFeatureToggleHOC(AdminResetServer, FeatureComponentId.RESET_SERVER),
		[UserRole.SUPER_ADMIN],
		true
	)
)
