import React, { FC, memo } from 'react'
import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel'

import {
  UPLOAD_PROJECT_MATERIAL_TOLERANCE_ALERT_TITLE
} from 'Services/Strings.js'

import Capture1 from 'assets/img/captures/Capture1.png'
import Capture2 from 'assets/img/captures/Capture2.png'
import Capture3 from 'assets/img/captures/Capture3.png'
import Capture4 from 'assets/img/captures/Capture4.png'
import Capture5 from 'assets/img/captures/Capture5.png'
import Capture6 from 'assets/img/captures/Capture6.png'
import Capture7 from 'assets/img/captures/Capture7.png'

interface ToleranceSliderProps {
  changeToleranceAlert: () => void
  showToleranceAlert: boolean
}

const ToleranceSlider: FC<ToleranceSliderProps> = ({
  changeToleranceAlert,
  showToleranceAlert
}) => {
  const images = [
    Capture1,
    Capture2,
    Capture3,
    Capture4,
    Capture5,
    Capture6,
    Capture7
  ]

  return (
    <AutoRotatingCarousel
      label={UPLOAD_PROJECT_MATERIAL_TOLERANCE_ALERT_TITLE}
      open={showToleranceAlert}
      autoplay={false}
      onClose={changeToleranceAlert}
      classes={{
        root: 'upload-form-tolerance-info-alert-root',
        footer: 'upload-form-tolerance-info-alert-footer',
        dots: 'upload-form-tolerance-info-alert-dots',
        slide: 'upload-form-tolerance-info-alert-slide'
      }}
      ButtonProps={{
        fullWidth: true,
        size: 'small',
        disableRipple: true,
        disableFocusRipple: true,
        disabled: true,
        classes: {
          root: 'upload-form-tolerance-info-alert-title',
          label: 'upload-form-tolerance-info-alert-title-label'
        }
      }}
    >
      {images.map((image, i) => (
        <Slide
          key={i}
          media={
            <img 
              src={image} 
              width="100%" 
              alt={image} 
              data-qa={`data-qa-img-${i+1}`} 
              data-test={`${images.length}`}/>
          }
          style={{ backgroundColor: '#c5c5c5' }}
          mediaBackgroundStyle={{
            backgroundColor: 'white',
            height: '106%'
          }}
          title=""
          subtitle=""
        />
      ))}
    </AutoRotatingCarousel>
  )
}

export default memo(ToleranceSlider)
