import { FC, memo } from 'react'

import { invert } from 'lodash'

import SelectWithIcons, {
	SelectWithIconsMenuItem
} from './SelectWithIcons/SelectWithIcons'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { Language, Locale } from 'Services/models/IUser'
import { getUserLanguage } from 'Services/Utils/startupTools'

export enum countries {
	US = 'US',
	JP = 'JP'
}

const countriesLocales: Record<countries, Locale> = {
	[countries.US]: Locale.en,
	[countries.JP]: Locale.ja
}

interface IProps {
	onLanguageChange: Function
}

const countriesMenuItems: SelectWithIconsMenuItem[] = [
	{
		iconName: 'USFlag',
		text: Language.en,
		value: countries.US
	},
	{
		iconName: 'JPFlag',
		text: Language.ja,
		value: countries.JP
	}
]

const LanguagePicker: FC<IProps> = ({ onLanguageChange }) => {
	const onLanguageSelect = (selectedValue: countries) => {
		onLanguageChange(countriesLocales[selectedValue])
	}

	return (
		<SelectWithIcons
			disableContentAnchorEl
			menuItems={countriesMenuItems}
			initialValue={invert(countriesLocales)[getUserLanguage()]}
			onChange={onLanguageSelect}
			showTextOnSelect={false}
		/>
	)
}

export default WithFeatureToggleHOC(
	memo(LanguagePicker),
	FeatureComponentId.LANGUAGE
)