import { memo, FC } from 'react'
import isNil from 'lodash/isNil'

import './DataTable.scss'

interface Props {
  text: string | number
  data: any
  className: string
}

const renderContent = (text: string | number, description: string) => {
  return (
    <div>
      <p
        className="data-table-row-text"
        title={isNil(text) ? '' : text.toString()}
      >
        {text}
      </p>

      <div
        className="data-table-row-text-description"
        title={isNil(text) ? '' : text.toString()}
      >
        {description}
      </div>
    </div>
  )
}

const DataTableTextWithDescription: FC<Props> = ({
  text,
  data: { description, ...restData },
  className
}) => (
  <div
    className={`data-table-row data-table-opacity-field ${className} ${restData.className}`}
  >
    {renderContent(text, description)}
  </div>
)

export default memo(DataTableTextWithDescription)
