export const BACKSPACE_KEY_CODES = [8]
export const ENTER_KEY_CODES = [13]
export const ESC_KEY_CODES = [27]
export const MINUS_KEY_CODES = [189, 109]
export const DOT_KEY_CODES = [190, 110]
export const COMMA_KEY_CODES = [188]
export const DOWN_KEY_CODES = [40]
export const UP_KEY_CODES = [38]
export const PLUS_KEY_CODES = [187, 107]
export const E_KEY_CODES = [69]
export const D_KEY_CODES = [68]
export const SPACE_KEY_CODES = [32]
export const COPY_PASTE_CODE = [17, 91, 86, 67, 46, 65] // ctrl, left window key, v, c, delete, a

export const charCodes: Record<string, number[]> = {
  Enter: ENTER_KEY_CODES,
  Backspace: BACKSPACE_KEY_CODES,
  Escape: ESC_KEY_CODES,
  '-': MINUS_KEY_CODES,
  '.': DOT_KEY_CODES,
  ',': COMMA_KEY_CODES,
  '+': PLUS_KEY_CODES,
  e: E_KEY_CODES,
  d: D_KEY_CODES
}

export const getCharCode = (e: KeyboardEvent): number => {
  let characterCode: number
  if (e.keyCode != null) {
    characterCode = e.keyCode
  } else if (e.key) {
    characterCode = charCodes[e.key]?.[0]
  } else if (e.code) {
    characterCode = charCodes[e.code]?.[0]
  } else {
    /* As @Leonid suggested */
    characterCode = e.which || e.charCode || e.keyCode || 0
  }
  return characterCode
}

export const isEnterKeyPressed = (e: KeyboardEvent) =>
  ENTER_KEY_CODES.includes(getCharCode(e))

// React.KeyboardEvent in some reason was needed
export const isBackSpaceKeyPressed = (e: KeyboardEvent | React.KeyboardEvent) =>
  BACKSPACE_KEY_CODES.includes(getCharCode(e as KeyboardEvent))

export const isCopyPasteKeyPressed = (
  e: KeyboardEvent | React.KeyboardEvent
) => {
  return COPY_PASTE_CODE.includes(getCharCode(e as KeyboardEvent))
}

export const isEscapeKeyPressed = (e: KeyboardEvent) =>
  ESC_KEY_CODES.includes(getCharCode(e))

export const isPlusKeyPressed = (e: KeyboardEvent) =>
  PLUS_KEY_CODES.includes(getCharCode(e))

export const isMinusKeyPressed = (e: KeyboardEvent) =>
  MINUS_KEY_CODES.includes(getCharCode(e))

export const isCommaKeyPressed = (e: KeyboardEvent) =>
  COMMA_KEY_CODES.includes(getCharCode(e))

export const isDownKeyPressed = (e: KeyboardEvent) =>
  DOWN_KEY_CODES.includes(getCharCode(e))

export const isUpKeyPressed = (e: KeyboardEvent) =>
  UP_KEY_CODES.includes(getCharCode(e))

// React.KeyboardEvent in some reason was needed
export const isDotKeyPressed = (e: KeyboardEvent | React.KeyboardEvent) =>
  DOT_KEY_CODES.includes(getCharCode(e as KeyboardEvent))

const isDisabledLetters = (e: KeyboardEvent) => {
  return e.code.startsWith('Key')
}

export const isDisabledKeyPressed = (e: KeyboardEvent) => {
  if (isDisabledLetters(e)) {
    return true
  }
  const chartCode = getCharCode(e)
  return [...PLUS_KEY_CODES, ...MINUS_KEY_CODES, ...E_KEY_CODES].includes(
    chartCode
  )
}
