import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { isEmpty } from 'lodash'

import {
	ADDITIVE_PDF_ADD,
	ADDITIVE_PDF_ERROR,
	ADDITIVE_PDF_RESET,
	ADDITIVE_TOGGLE_POPUP
} from './AdditiveMindsTypes'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { pdfFileTypes } from 'Scenes/Home/NewUploadProject/fileTypes'
import { sendToPrint } from 'Services/Network'
import { getString } from 'Services/Strings/StringService'

export const resetPdfFile = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: ADDITIVE_PDF_RESET })
	}
}

export const toggleAdditivePopup = (showPopup: boolean) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: ADDITIVE_TOGGLE_POPUP, payload: { showPopup } })
	}
}

export const sendAdditiveMind = (
	file: any,
	notes: string,
	quantity: number,
	printerName: string,
	printerMaterial: string,
	configurationId: number,
	projectId: string,
	partId: string
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const response = await sendToPrint(
				projectId,
				partId,
				configurationId,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				quantity,
				notes
			)

			if (response) {
				dispatch({
					type: ALERT_POPPED,
					payload: {
						text:
							getString('ADDITIVE_MINDS_SEND_SUCCESS') || getString('SUCCESS'),
						headerTitle: getString('ADDITIVE_MINDS'),
						showCancel: false,
						alertType: AlertType.SUCCESS,
						onConfirm: () => {
							dispatch({ type: ALERT_POPUP_CANCELED })
						}
					}
				})
			}
		} catch (err) {
			console.error(err)
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: getString('ADDITIVE_MINDS_SEND_ERROR') || getString('ERROR'),
					showCancel: false,
					headerTitle: getString('ADDITIVE_MINDS'),
					alertType: AlertType.WARNING,
					onConfirm: () => {
						dispatch({ type: ALERT_POPUP_CANCELED })
					}
				}
			})
		}
	}
}

export const setPDFFile = (newFile: File[], readerResult: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		const additiveFile = newFile[0]
		const multipleFiles = newFile.length > 1
		const wrongType = !pdfFileTypes.includes(additiveFile.type)
		const error = multipleFiles
			? getString('UPDATE_DATABASE_ERROR_FILE_LENGTH')
			: wrongType
			? getString('UPDATE_DATABASE_ERROR_PDF_FORMAT')
			: ''

		if (!isEmpty(error)) {
			dispatch({ type: ADDITIVE_PDF_ERROR, payload: { error } })
		} else {
			const preparedToSend = {
				data: readerResult?.split(',').pop(),
				fileName: additiveFile.name
			}
			dispatch({
				type: ADDITIVE_PDF_ADD,
				payload: { additiveFile, preparedToSend }
			})
		}
	}
}
