export const styles = {
  pageStyle: {
    backgroundColor: '#eeeeee'
  },
  superview: { margin: 20 },
  cardDiv: { maxWidth: 720, alignSelf: 'center', textAlign: 'left' },
  explanationText: {
    alignSelf: 'left',
    textAlign: 'left',
    margin: 12
  },
  explanationTextHeader: {
    alignSelf: 'left',
    textAlign: 'left',
    marginTop: 20,
    marginLeft: 12
  },
  extraExplanationText: {
    fontSize: 10,
    alignSelf: 'left',
    textAlign: 'left',
    margin: 12
  },
  topText: {
    width: 720,
    alignSelf: 'center',
    alignItems: 'left'
  },
  formSuperview: {
    maxWidth: 1000,
    justifyContent: 'center',
    marginLeft: 30,
    marginRight: 30
  },
  itemsDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  customizeFormElement: {
    width: '77%'
  }
}
