import parse from 'html-react-parser'
import { FC, memo, useMemo } from 'react'

import cx from 'classnames'

import {
	convertCoDataWithUnits,
	getCoDataValue,
	showCO2Banner
} from './CostComparisonService'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import Devider from 'Scenes/Components/Devider/Devider'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'
import { EMISSIONS_COMPARISON_CO2, POTENTIAL_SAVING } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	configuration: any
	part: Part
	shouldShowStandardCost: boolean
}

interface ComparisonInfoProps {
	convertedCoData: Record<string, any>
	gainCO2: Record<string, any>
	CO2HasPotential: boolean
	alwaysShowCO2Details: boolean
}
export const ComparisonInfo: FC<ComparisonInfoProps> = ({
	convertedCoData,
	gainCO2,
	CO2HasPotential,
	alwaysShowCO2Details
}) => {
	if (!gainCO2) {
		return <></>
	}
	const tooltipExplanation = getString('EMISSIONS_COMPARISON_TEXT').format(
		`<b>${convertedCoData.total?.weight}</b> ${
			convertedCoData.total?.unit || ''
		}`
	)
	const tooltipExplanationMaterial = getString('CO2_RAW_TEXT').format(
		`<b>${convertedCoData.materialRaw?.weight || 0}</b> ${
			convertedCoData.materialRaw?.unit || ''
		}`
	)
	const tooltipExplanationElectricity = getString('CO2_RAW_TEXT').format(
		`<b>${convertedCoData.electricity?.weight || 0}</b> ${
			convertedCoData.electricity?.unit || ''
		}`
	)
	const tooltipExplanationPu = getString('CO2_RAW_TEXT').format(
		`<b>${convertedCoData.pu?.weight || 0}</b> ${
			convertedCoData.pu?.unit || ''
		}`
	)
	const tooltipExplanationTransportation = getString('CO2_RAW_TEXT').format(
		`<b>${convertedCoData.transportation?.weight || 0}</b> ${
			convertedCoData.transportation?.unit || ''
		}`
	)
	const tooltipExplanationEndOfLife = getString('CO2_RAW_TEXT').format(
		`<b>${convertedCoData.eol?.weight || 0}</b> ${
			convertedCoData.eol?.unit || ''
		}`
	)

	const popUpHeader = getString(
		alwaysShowCO2Details && !CO2HasPotential
			? 'NO_POTENTIAL_SAVINGS'
			: 'EMISSIONS_COMPARISON_TEXT_HEADER'
	)

	const popUpSubHeader =
		alwaysShowCO2Details && !CO2HasPotential
			? getString('NO_POTENTIAL_SAVINGS_SUBHEADER')
			: parse(tooltipExplanation)

	return (
		<div>
			<div className="cost-details-column">
				<div>{popUpHeader}</div>
				<div>{popUpSubHeader}</div>
			</div>
			<Devider size={'200px'} className="cost-details-row-divider" />
			<br />
			<div className="cost-details-row">
				<div>{getString('CUSTOMIZE_CO2_MATERIAL_PRODUCTION')}</div>
				<div>
					{gainCO2 && !Number(gainCO2)
						? parse(`${tooltipExplanationMaterial}<br />`)
						: ''}
				</div>
			</div>
			<div className="cost-details-row">
				<div>{getString('PRODUCT_MANUFACTURING')}</div>
				<div>
					{gainCO2 && !Number(gainCO2)
						? parse(`${tooltipExplanationElectricity}<br />`)
						: ''}
				</div>
			</div>
			<div className="cost-details-row">
				<div>{getString('PRODUCT_USE')}</div>
				<div>
					{gainCO2 && !Number(gainCO2)
						? parse(`${tooltipExplanationPu}<br />`)
						: ''}
				</div>
			</div>
			<div className="cost-details-row">
				<div>{getString('TRANSPORTATION_AND_HOLDING')}</div>
				<div>
					{gainCO2 && !Number(gainCO2)
						? parse(`${tooltipExplanationTransportation}<br />`)
						: ''}
				</div>
			</div>
			<div className="cost-details-row">
				<div>{getString('CO2_PARAMETERS_END_OF_LIFE_HEADER_TEXT')}</div>
				<div>
					{gainCO2 && !Number(gainCO2)
						? parse(`${tooltipExplanationEndOfLife}<br />`)
						: ''}
				</div>
			</div>
			<Devider size={'200px'} className="cost-details-row-divider" />
			{getString('EMISSIONS_COMPARISON_BOTTOM_TEXT')}
		</div>
	)
}

const Co2Comparison: FC<IProps> = ({
	configuration,
	part,
	shouldShowStandardCost
}) => {
	const alwaysShowCO2Details = !!Feature.isFeatureOn(
		FeatureComponentId.ALWAYS_SHOW_CO2_DETAILS
	)
	const gainCO2 = configuration?.costResults?.gainCO2

	const shouldShowCO2Banner = useMemo(
		() => showCO2Banner(shouldShowStandardCost, part?.isDrawing),
		[shouldShowStandardCost, part?.isDrawing]
	)
	const { coData, CO2HasPotential } = useMemo(
		() => getCoDataValue(gainCO2, alwaysShowCO2Details),
		[gainCO2, alwaysShowCO2Details]
	)

	const convertedCoData = useMemo(
		() => convertCoDataWithUnits(coData),
		[coData]
	)

	if (!shouldShowCO2Banner) {
		return <div></div>
	}

	const comparisonText = CO2HasPotential
		? `${convertedCoData.total.weight} ${convertedCoData.total.unit}${EMISSIONS_COMPARISON_CO2} ${POTENTIAL_SAVING}`
		: getString('NO_POTENTIAL_SAVINGS')

	return (
		<div className="co-tooltip co-tooltip-information">
			<div
				className={cx('co-tooltip-information__text', {
					grayed: !CO2HasPotential
				})}
				data-qa={
					CO2HasPotential ? 'data-qa-co2-saving' : 'data-qa-no-co2-saving'
				}
			>
				<DetailsPopup
					popperClassName={'co2-details-wrapper'}
					isHover={alwaysShowCO2Details || CO2HasPotential}
					data={
						<ComparisonInfo
							convertedCoData={convertedCoData}
							gainCO2={gainCO2}
							CO2HasPotential={CO2HasPotential}
							alwaysShowCO2Details={alwaysShowCO2Details}
						/>
					}
					popperDirection="top-start"
					popperContactClassName="info-box-data"
				>
					<IconFactory iconName="co2" className="admin-form-field__info_icon" />
					<div className="text">{comparisonText}</div>
				</DetailsPopup>
			</div>
		</div>
	)
}

export default memo(Co2Comparison)
