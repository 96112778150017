import { Action } from '../../../global actions/ActionModels'
import {
  SUBSCRIPTION_CONNECT_ALERT_POPUP,
  SUBSCRIPTION_ALERT_POPUP_CANCELED
} from '../../../global actions/types/CastorAlertTypes'
import {
  CONNECT_CASTOR_RADIO_BUTTON_VALUE,
  USER_SUBSCRIPTION_REQUEST_LOADING,
  USER_SUBSCRIPTION_REQUEST_SUCCEED
} from '../../../global actions/types'

export interface CastorSubscriptionAlertInitialState {
  readonly show: boolean
  readonly email: string
  readonly loadingCalculation: boolean
  readonly selectedRadioButtonValue: number
}

const initialState: CastorSubscriptionAlertInitialState = {
  show: false,
  loadingCalculation: false,
  selectedRadioButtonValue: 3,
  email: ''
}

export default (state = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case SUBSCRIPTION_CONNECT_ALERT_POPUP:
      return {
        ...state,
        ...payload,
        show: true
      }

    case SUBSCRIPTION_ALERT_POPUP_CANCELED:
      return {
        ...state,
        ...initialState
      }
    case CONNECT_CASTOR_RADIO_BUTTON_VALUE:
      return {
        ...state,
        selectedRadioButtonValue: payload
      }

    case USER_SUBSCRIPTION_REQUEST_LOADING:
      return { ...state, loadingCalculation: true }
    case USER_SUBSCRIPTION_REQUEST_SUCCEED:
      return {
        ...state,
        loadingCalculation: false
      }
    default:
      return state
  }
}
