import { FC, memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Divider from '@material-ui/core/Divider'
import moment from 'moment'

import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import HeaderCard from '../../Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import LicenseDropZone from './LicenseDropZone'
import { UPLOAD_LICENSE_SCRIPT } from 'global actions/types'
import { SKIP_LICENSE_LS_KEY } from 'Services/Constants'
import { useSkipKeyDown } from 'Services/CustomHooks/useSkipKeyDown'
import {
	getStringItemFromLocalStorage,
	setStringItemToLocalStorage
} from 'Services/LocalStorageService'
import {
	EXPIRED_DATE_LICENSE_MSG,
	FUTURE_START_DATE_LICENSE_MSG,
	LICENSE_UPDATE,
	UPDATE_DATABASE_LICENSE
} from 'Services/Strings'
import { getTheme } from 'themes/getTheme'

import './index.scss'

const { cardBoxColor } = getTheme()

const DividerTSX: any = Divider

interface IProps {
	licenseData: any
}

const License: FC<IProps> = ({ licenseData }) => {
	const dispatch = useDispatch()

	let massageLicense = UPDATE_DATABASE_LICENSE

	if (licenseData?.data) {
		if (
			licenseData.data?.expire_date &&
			moment(new Date(licenseData.data.expire_date))
				.endOf('day')
				.isBefore(new Date())
		) {
			massageLicense = EXPIRED_DATE_LICENSE_MSG.format(
				licenseData.data.expire_date
			)
		} else if (
			licenseData.data?.start_date &&
			moment(new Date(licenseData.data.start_date))
				.startOf('day')
				.isAfter(new Date())
		) {
			massageLicense = FUTURE_START_DATE_LICENSE_MSG.format(
				licenseData.data.start_date
			)
		}
	}

	useSkipKeyDown(() => {
		dispatch({
			type: UPLOAD_LICENSE_SCRIPT,
			payload: true
		})
		setStringItemToLocalStorage(SKIP_LICENSE_LS_KEY, 'true')
	})

	useEffect(() => {
		const skipLicense = getStringItemFromLocalStorage(SKIP_LICENSE_LS_KEY)
		if (skipLicense) {
			dispatch({
				type: UPLOAD_LICENSE_SCRIPT,
				payload: true
			})
		}
	}, [dispatch])

	return (
		<div className="upload-script">
			<NavBarAndMaterial title={LICENSE_UPDATE}>
				<div className="upload-script-body">
					<HeaderCard
						cardTitle={LICENSE_UPDATE}
						cardSubtitle=""
						headerColor={cardBoxColor || 'green'}
						content={
							<div>
								<br />
								<p className="upload-script-error">{massageLicense}</p>
								<br />
								<DividerTSX />
								<br />
								<LicenseDropZone />
							</div>
						}
					/>
				</div>
			</NavBarAndMaterial>
		</div>
	)
}

export default memo(License)
