import React, { FC, memo, useCallback } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import {
	materialSelected,
	updateManufacturing
} from '../../UploadProjectActions'
import { useMaterial } from '../UploadBlocksHooks'
import CardHeaderBox from 'Scenes/Components/CardHeaderBox'
import MaterialSelector from 'Scenes/Components/MaterialSelector/MaterialSelector'
import { defaultMetal } from 'Services/Constants'
import { IMaterial, Material } from 'Services/models/IMaterial'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { defaultMaterial } = getTheme()

const UploadCurrentMaterial: FC = () => {
	const user = useSelector((state: RootStateOrAny) => state.user)
	const dispatch = useDispatch()
	const [defaultChosenMaterial] = useMaterial()

	const materialChangedUpload = (material: Material) => {
		const isMachining = user?.userCustomizationSettings?.machining
		dispatch(materialSelected(material, isMachining))
	}

	const materialChanged = useCallback((material: Material) => {
		materialChangedUpload(material)
		dispatch(updateManufacturing(''))
	}, [])

	return (
		<CardHeaderBox
			contentClass="new-upload-project__card"
			title={getString('NEW_UPLOAD_SECTION_MATERIAL')}
			content={
				<div className="new-upload-project__content">
					<div className="label">{getString('WHAT_CURRENT_MATERIAL')}</div>
					<MaterialSelector
						hideMaterialNameSelector={false}
						hiddenMaterialNameSelector={true}
						isButtonSelector={true}
						onChange={(material: Material) => materialChanged(material)}
						disabled={false}
						style={{ maxWidth: 50 }}
						defaultMaterialForTypeArr={[defaultMetal, defaultMaterial]}
						defaultMaterial={defaultChosenMaterial}
						selectClassName="upload-project-material-selector-field"
						qaDataElementNameType={'data-qa-original-material-type-'}
						qaDataElementNameCategory={'data-qa-original-category-'}
						qaDataElementNameMaterial={'data-qa-original-material-'}
					/>
				</div>
			}
		/>
	)
}
export default memo(UploadCurrentMaterial)
