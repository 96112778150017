import { match } from 'react-router'

import * as NewPartConfigurationActions from './NewPartConfigurationActions'
import { MainPartAnalysisInitialState } from '../MainPartAnalysis/MainPartAnalysisReducer'
import { ActionWithPayload } from 'global actions/ActionModels'
import { History } from 'history'
import { Material } from 'Services/models/IMaterial'
import { IDefaultConfiguration } from 'Services/models/IPrintersTypes'
import { Printer } from 'Services/models/IPrinter'

export interface NewPartConfigurationState {
	readonly newConfigurationPage: boolean
	readonly initialMaterial: any
	readonly materialTypesList: Array<string>
	readonly sortedPrinters: Array<any>
	readonly categoriesList: Array<string>
	readonly materialsList: Array<{ name: string } & any>
	readonly printerList: Array<object>
	readonly configurationMethod: string
	readonly chosenMaterialType: string
	readonly chosenMaterialCategory: string
	readonly chosenMaterial: any
	readonly chosenDefaultMaterial: any
	readonly chosenDefaultMaterialType: string
	readonly chosenDefaultMaterialCategory: string
	readonly chosenPrinterConfiguration: string
	readonly allowedManufacturingMethods: any
	readonly chosenPrinterConfigurationType: string
	readonly manufacturingMethod: string
	readonly printersConfiguration: any
	readonly partMaterial: any
	readonly allPrinterList: Array<object>
	readonly inHouseList: Array<object>
	readonly printersFullData: Array<any>
	readonly loading: boolean
	readonly configurationProperties: Array<IConfigureProperty>
	readonly priorities: any
	readonly propertyName: string
	readonly defaultConfig: string
	readonly objectivesValue: string
}

export interface NewPartConfigurationProps {
	history: History
	loading: boolean
	disableSubmit: boolean
	match: match<any>
	partMaterial: any
	priorities: any
	configurationProperties: Array<IConfigureProperty>
	materials: Array<any>
	materialCategories: any[]
	defaultConfigurations: any[]
	materialTypesList: Array<string>
	allowedManufacturingMethods: Array<string>
	categoriesList: Array<string>
	materialsList: Array<any>
	printerList: Array<object>
	printersConfiguration: Array<object>
	printersFullData: Array<any>
	initialBatchSize: number
	manufacturingMethod: string
	chosenMaterialCategory: string
	chosenMaterialType: string
	chosenMaterial?: Material
	chosenDefaultMaterial?: Material
	chosenDefaultMaterialCategory: string
	chosenDefaultMaterialType: string
	chosenPrinterConfigurationType: string
	chosenPrinterConfiguration: string
	desktopPrinter: boolean
	isSpecifiedQuantity: boolean
	onChangeNewMaterialCategory: (value: any) => ActionWithPayload<any>
	onChangeObjectiveMethod: (value: any) => ActionWithPayload<any>
	onSendNewConfiguration: (
		batchSize: number,
		yearsOfDemand: number,
		history: History,
		partId: number,
		projectId: string,
		manufactureMethod: string,
		priorities: any,
		chosenPrinterConfiguration: any,
		partMaterial: Material,
		chosenMaterial: any,
		chosenPrinterConfigurationType: string,
		propertyName: string,
		isAdvanced: boolean,
		settingScenario: string,
		fullTrayAssumption: boolean,
		isSpecifiedQuantity: boolean
	) => ActionWithPayload<any>
	onChangeManufacturingMethod: (value: any) => ActionWithPayload<any>
	onChangeNewMaterialType: (value: any) => ActionWithPayload<any>
	onChangeNewMaterial: (value: any) => ActionWithPayload<any>
	onChangeSelectedPrinter: (printer: Printer, key: string) => ActionWithPayload<any>
	onSaveNewPriorities: (
		configurationProperties: Array<IConfigureProperty>
	) => ActionWithPayload<any>
	onChangeProperty: (propertyName: string) => ActionWithPayload<any>
	setupProjectAnalysis: (projectId: string) => any
	setupNewConfiguration: (
		...setupConfigurationParams: Parameters<
			typeof NewPartConfigurationActions.setupNewConfiguration
		>
	) => ActionWithPayload<any>
	propertyName: string
	objectivesValue: string
}

export interface NewPartConfigurationInitialState {
	initialMaterial: any
	materialTypesList: Array<string>
	allowedManufacturingMethods: Array<string>
	categoriesList: Array<string>
	materialsList: Array<any>
	chosenMaterial: any
	printerList: Array<object>
	printersConfiguration: Array<object>
	chosenMaterialType: string
	chosenMaterialCategory: string
	chosenDefaultMaterialType: string
	chosenDefaultMaterialCategory: string
	chosenPrinterConfigurationType: string
	chosenPrinterConfiguration: string
	manufacturingMethod: string
	configurationMethodPrinter: {
		type: string
		value: string
	}
}

export interface IReduxState {
	user: any
	MainPartAnalysisReducer: MainPartAnalysisInitialState
	NewPartConfigurationReducer: NewPartConfigurationInitialState
}

export interface State {
	batchSize: number
	yearsOfDemand: number
	fullTrayAssumption: boolean
	quantityTitleText: string
	callbackURL: string
}

export type StateType = 'batchSize' | 'yearsOfDemand' | 'quantityTitleText'

export enum StateValues {
	BATCH_SIZE = 'batchSize',
	YEARS_OF_DEMAND = 'yearsOfDemand',
	QUANTITY_TITLE_TEXT = 'quantityTitleText'
}

export interface IConfigureProperty {
	name: string
	id: string
	value: number
	display: boolean
}

export interface IConfiguration {
	dontShow: boolean
	filters: any
	id: number
	material: any
	name: string
	priorities: {}
	solution: any
	printer: IDefaultConfiguration
	printerMaterial: any
	quantity?: number
	manufactureMethod?: any
}
