import { connect } from 'react-redux'
import { Component } from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'

import { UPLOAD_ROUTE } from '../../Services/Constants/RoutesConstants'
import { getLoginState } from '../../global actions/index'
import SelectiveRoute from './SelectiveRoute'
import { homePathRoute } from '../../Services/routeFuncs'
import UploadProjectForm from '../Home/NewUploadProject'

class LoggedOutRoute extends Component {

	isRouteLegal = () => {
		return !this.props.loggedIn
	}

	render() {
		const { loggedIn, path, isAllowRegister } = this.props

		if (!isAllowRegister) {
			return <div />
		}

		if (loggedIn) {
			return (
				<Route
					exact
					path={path}
					render={() => <Redirect to={homePathRoute(UPLOAD_ROUTE)} />}
				/>
			)
		}
		return (
			<SelectiveRoute
				alternativeComponent={UploadProjectForm}
				isRouteLegalFunc={this.isRouteLegal.bind(this)}
				parentProps={this.props}
			/>
		)
	}
}

const mapStateToProps = ({ user, GlobalReducer: { isAllowRegister } }) => {
	const { stateUnknown, loggedIn, loginPending } = user

	return { loggedIn, stateUnknown, loginPending, isAllowRegister }
}
export default withRouter(
	connect(mapStateToProps, { getLoginState })(LoggedOutRoute)
)
