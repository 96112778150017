import {
	HANDLE_NOTIFICATION,
	NEW_ITEM_ADD_CALLED,
	NEW_ITEM_ADD_FAILED
} from '../../../global actions/types'
import { PRINTER_MATERIAL_NAME_ALREADY_EXIST_ERROR } from '../../../Services/Constants'
import { SHOW_NOTIFICATION } from '../../../Services/Strings'
import { getString } from 'Services/Strings/StringService'

const userMaterialNameErrorCode = 235433

export const setupAdminPage = (
	dispatch,
	networkCall,
	callArgs,
	successType,
	errorType,
	pageNumber
) => {
	networkCall(...callArgs)
		.then(response => {
			dispatch({
				type: successType,
				payload: { data: response.data, pageNumber }
			})
		})
		.catch(error => {
			console.log(error)
			dispatch({ type: errorType })
		})
}

export const selectItemToEdit = (
	dispatch,
	itemId,
	items,
	networkCall,
	callArgs,
	successType,
	errorType,
	itemType,
	findItemByIndex
) => {
	let selectedItem
	if (findItemByIndex) {
		selectedItem = items.find(item => item[findItemByIndex] == itemId)
	} else {
		selectedItem = items.find(item => item.id == itemId)
	}
	if (!selectedItem) {
		// happens when reaching edit page not from the table page (refreshing or via url)
		networkCall(...callArgs)
			.then(response => {
				dispatch({
					type: successType,
					payload: response.data[itemType]
				})
			})
			.catch(error => {
				console.log(error)
				dispatch({ type: errorType })
			})
	} else {
		dispatch({
			type: successType,
			payload: selectedItem
		})
	}
}

export const updateItem = (
	dispatch,
	updateItemNetworkCall,
	data,
	successType,
	itemType,
	successNotificationMessage,
	errorNotificationMessage
) => {
	updateItemNetworkCall(data)
		.then(response => {
			dispatch({
				type: successType,
				payload: itemType ? response.data[itemType] : response.data
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: successNotificationMessage
				}
			})
		})
		.catch(error => {
			console.log(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.validationMessage || errorNotificationMessage
				}
			})
		})
}

export const createNewItem = (
	dispatch,
	createNewItemNetworkCall,
	item,
	successType,
	itemType,
	successNotificationMessage,
	errorNotificationMessage
) => {
	dispatch({
		type: NEW_ITEM_ADD_CALLED
	})
	createNewItemNetworkCall(item)
		.then(response => {
			dispatch({
				type: successType,
				payload: itemType ? response.data[itemType] : response.data
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: successNotificationMessage
				}
			})
		})
		.catch(error => {
			console.log(error)
			dispatch({
				type: NEW_ITEM_ADD_FAILED
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.code === PRINTER_MATERIAL_NAME_ALREADY_EXIST_ERROR
							? getString('PRINTER_MATERIAL_NAME_EXISTS_ERROR')
							: error.code === userMaterialNameErrorCode
							? getString('USER_MATERIAL_NAME_EXISTS_ERROR')
							: error.validationMessage || errorNotificationMessage
				}
			})
		})
}

export const deleteItem = (
	dispatch,
	deleteItemNetworkCall,
	id,
	successType,
	errorType,
	successNotificationMessage,
	errorNotificationMessage
) => {
	deleteItemNetworkCall(id)
		.then(response => {
			dispatch({
				type: successType,
				payload: { id, response }
			})
			if (successNotificationMessage) {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: successNotificationMessage
					}
				})
			}
		})
		.catch(error => {
			console.log(error)
			dispatch({ type: errorType })
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: errorNotificationMessage
				}
			})
		})
}
