import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import findKey from 'lodash/findKey'
import isEmpty from 'lodash/isEmpty'

import { UserRole } from '../UserRole.enum'
import {
	KEY_2d,
	lightUserEmailExistsError,
	METADATA,
	PDF_FORMAT,
	regularUserEmailExistsError
} from './constants'
import { bomFileTypes } from './fileTypes'
import { simpleLoginUser } from 'global actions'
import {
	ADDED_FILES,
	ADDITIONAL_NOTES_CHANGED,
	AUTHORIZATION_TOKEN_UPDATED,
	BOM_REMOVED_UPLOAD_PROJ,
	BOM_SELECTED_UPLOAD_PROJ,
	CAD_NAME_CHANGED,
	CREATE_PROJECT,
	CREATE_PROJECT_FAILED,
	CREATE_PROJECT_SUCCESS,
	DRAGGING_OVER_DROPZONE,
	ERP_NAME_CHANGED,
	FILE_MATERIAL_OR_QUANTITY_CHANGED,
	GET_USER_REMAINING_PARTS,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	HIDE_TRIAL_USER_UPLOAD_PROJECT_POPUP,
	MATERIAL_RADIO_BUTTON_VALUE_CHANGED_UPLOAD_PROJ,
	MATERIAL_VALUE_CHANGED_UPLOAD_PROJ,
	NEW_PAGE_VISITED,
	PROJECT_APPLICATION_CHANGED,
	PROJECT_NAME_CHANGED,
	QUANTITY_CHANGED,
	QUICK_PROJECT_CONNECTION_ERROR_FAILED,
	QUICK_PROJECT_FAILED,
	QUICK_PROJECT_POLLER_STARTED,
	QUICK_PROJECT_PUBLISHED,
	REFRESH_TOKEN_UPDATED,
	REMOVE_ERROR_FROM_DROP_ZONE,
	REMOVE_FILE_FROM_UPLOAD_DROPZONE,
	REMOVED_FILES,
	UNIT_TYPE_CREATE_PROJECT_FAILED,
	UNIT_TYPE_CREATE_PROJECT_RESET,
	UPDATE_TRIAL_USER_UPLOAD_PROJECT_POPUP,
	UPLOAD_CHECK_STATUS,
	UPLOAD_CLEAR_STATUS,
	UPLOAD_COMPLETE,
	UPLOAD_FAILED_FILES,
	UPLOAD_FILE_COMPLETE,
	UPLOAD_PROJECT_AM_VALUE_CHANGE,
	UPLOAD_PROJECT_EARLY_PRODUCTION_CHANGE,
	UPLOAD_PROJECT_FILTER_ALERT_CLOSED,
	UPLOAD_PROJECT_FILTER_ALERT_CONFIRMED,
	UPLOAD_PROJECT_FILTER_RESET,
	UPLOAD_PROJECT_MANUFACTURING_CHANGE,
	UPLOAD_PROJECT_SCENARIO_CHANGE,
	UPLOAD_PROJECT_SETUPED,
	UPLOAD_PROJECT_STANDART_COST_CHECKBOX,
	UPLOAD_PROJECT_TOLERANCE_CHANGED,
	UPLOAD_PROJECT_TOLERANCE_TOGGLED,
	UPLOAD_START_LOADING,
	UPLOAD_UNIT_TYPE_CHANGED,
	USER_ID_UPDATE
} from 'global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { TOUR_STARTED } from 'global actions/types/takeATourTypes'
import { checkUserSubscriptionAlert } from 'global actions/UserActions'
import { History } from 'history'
import { store } from 'index'
import { prepareFiltersToSend } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsService'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import {
	getCorrectFormatType,
	hasUserDateExpired
} from 'Scenes/Home/NewUploadProject/UploadProjectActionService'
import {
	DEFAULT_QUANTITY,
	defaultSettingScenario,
	FAILED,
	manufacturingMethodTypes,
	PUBLISHED,
	USER_COUNTRY_NOT_ALLOWED
} from 'Services/Constants'
import { UPLOADING_ROUTE } from 'Services/Constants/RoutesConstants'
import {
	MISSING_BOM_FILE_OR_MATERIAL_ERROR,
	MISSING_CAD_FILE_ERROR,
	MISSING_DRAWING_MATERIAL_OR_MATERIAL_ERROR,
	MISSING_PROJECT_FILES_ERROR,
	MISSING_PROJECT_NAME_ERROR,
	MISSING_PROJECT_WRONG_QUANTITY_ERROR
} from 'Services/LocalError'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Material } from 'Services/models/IMaterial'
import { ProjectCADType } from 'Services/models/IProject'
import { IFileWithMaterialAndQuantity } from 'Services/models/IUploadProject'
import {
	createProject,
	getCustomizationSettings,
	getProjectAnalysisStatus,
	getProjectScenario,
	informAdminUploadFilesFailed,
	register,
	uploadProjectFiles
} from 'Services/Network'
import {
	getUserActivityPanelData,
	getUserRemainingPartsCredit
} from 'Services/Network/UserNetwork'
import Poller from 'Services/PollingService/Poller'
import { customizeUser } from 'Services/routeFuncs'
import {
	CONFIGURATION_INHOUSE_ALERT_TEXT,
	GO_TO_SETTINGS,
	NO_CAD_FILE_ERROR,
	NO_INHOUSE_PRINTER_WAS_SET,
	NO_PDF_FILE_ERROR,
	OK,
	POLLER_CANNOT_CONNECT_TO_THE_SERVER,
	POLLER_REACHED_TIMEOUT,
	SHOW_NOTIFICATION
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

let poller = new Poller()

//TODO: FIX THE TYPES
export const setupUploadPage = (
	filters: any,
	uploadProjectSelectedRadioButton: any,
	defaultUploadProjectSelectedRadioButton: any,
	pagesVisited: any,
	pageName: any
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})
		dispatch({
			type: NEW_PAGE_VISITED,
			payload: pageName
		})
		dispatch({
			type: UPLOAD_START_LOADING,
			payload: true
		})

		const chainBenefitOn = Feature.isFeatureOn(
			FeatureComponentId.CHAIN_BENEFITS
		)
		const projectSettingsScenariosOn = Feature.isFeatureOn(
			FeatureComponentId.PROJECT_SETTINGS_SCENARIOS
		)
		const isCurrentStandardCostOn = Feature.isFeatureOn(
			FeatureComponentId.CURRENT_STANDARD_COST
		)
		try {
			let { data: projectSettingsScenario = {} }: any =
				await getProjectScenario()
			let { data: userSettings = {} }: any = await getCustomizationSettings()
			let defaultIndex =
				userSettings?.userSettings?.defaultProjectScenario ||
				defaultSettingScenario.lowVolume
			await getUserUploadActivity()

			const uploadUnitType =
				store.getState().user?.userCustomizationSettings?.unitsType ||
				store.getState().user?.uploadUnitType

			dispatch({
				type: UPLOAD_PROJECT_SETUPED,
				payload: {
					unitType: uploadUnitType || '',
					defaultUploadProjectSelectedRadioButton,
					uploadProjectSelectedRadioButton: defaultIndex,
					chainBenefitOn,
					projectSettingsScenariosOn,
					defaultProjectScenario:
						projectSettingsScenario?.defaultProjectScenario,
					isStandardCostBoxChecked: !isCurrentStandardCostOn
				}
			})

			updateShowTourUpload(dispatch, pagesVisited, pageName)

			dispatch({
				type: UPLOAD_START_LOADING,
				payload: false
			})

			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		} catch (e) {
			console.error('UPLOAD_PROJECT', e)
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		}
	}
}

export const getUserUploadActivity = () => {
	return async (dispatch: Dispatch<AnyAction>) => {
		const user = store.getState().user

		// prevent banner blinking before user is updated in store
		const isSuperAdmin =
			user.roles.length > 0
				? user.roles.includes(UserRole.SUPER_ADMIN)
				: undefined
		const isLight =
			user.roles.length > 0 ? user.roles.includes(UserRole.LIGHT) : undefined

		const isUserRecognized = isLight ? user.name || user.userDetails.name : true

		try {
			let { data: userActivity = {} }: any = await getUserActivityPanelData()
			const userSubscription =
				userActivity?.userSubscriptionData || userActivity?.userActivity

			// const licenseData = await getLicenseData()
			const getUserRemainingPartsCreditResponse =
				await getUserRemainingPartsCredit()

			const remainingPartsCredit =
				getUserRemainingPartsCreditResponse?.data?.userRemainingPartsCredit

			let userRemainingPart =
				userSubscription?.amount_of_remain_parts ||
				userSubscription?.userSubscriptionData?.amount_of_remain_parts

			if (remainingPartsCredit != null) {
				userRemainingPart = remainingPartsCredit
			}

			let userExpirationDate =
				userSubscription?.subscription_expire_date ||
				userSubscription?.userSubscriptionData?.subscription_expire_date

			let userOnTrial =
				userActivity?.userActivity?.userSubscriptionData.trial ||
				userActivity?.userSubscriptionData?.trial

			if (!isUserRecognized) {
				userOnTrial = true

				if (!Feature.isFeatureOn(FeatureComponentId.LICENSE_MANAGER)) {
					userRemainingPart = userSubscription.globalRemainingParts
				}
			}

			dispatch({
				type: GET_USER_REMAINING_PARTS,
				payload: {
					userRemainingPart,
					userExpirationDate,
					isAdmin: isSuperAdmin
				}
			})

			store.dispatch(
				checkUserSubscriptionAlert(
					isSuperAdmin,
					user?.isLightUser,
					hasUserDateExpired(userExpirationDate, isSuperAdmin),
					userRemainingPart,
					userOnTrial,
					user?.userDetails?.email,
					user.isOrganizationOwnerExist
				)
			)
		} catch (e) {
			console.error('GET_USER_REMAINING_PARTS', e)
		}
	}
}

export const onChangeProjectSettingsScenarios = (
	name: any,
	selectedProjectScenario: any
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: UPLOAD_PROJECT_SCENARIO_CHANGE,
			payload: {
				name,
				selectedProjectScenario
			}
		})
	}
}

export const updateShowTourUpload = (
	dispatch: any,
	pagesVisited: any,
	pageName: any
) => {
	if (pagesVisited && !pagesVisited.includes(pageName)) {
		setTimeout(() => {
			dispatch({
				type: TOUR_STARTED,
				payload: {}
			})
		}, 300)
	}
}

export const onStandardCostCheckboxChange = (
	isStandardCostBoxChecked: boolean
) => {
	return {
		type: UPLOAD_PROJECT_STANDART_COST_CHECKBOX,
		payload: { isStandardCostBoxChecked }
	}
}

export const onFilterToggleReset = () => {
	return {
		type: UPLOAD_PROJECT_FILTER_RESET
	}
}

export const onInhouseToggleClickUpload = (history: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: CONFIGURATION_INHOUSE_ALERT_TEXT,
				onConfirm: () => {
					dispatch({ type: ALERT_POPUP_CANCELED })
					history.push(customizeUser())
				},
				confirmText: GO_TO_SETTINGS,
				headerTitle: NO_INHOUSE_PRINTER_WAS_SET,
				alertType: AlertType.WARNING,
				alertClass: 'upload-project-filters-inhouse-alert'
			}
		})
	}
}

export const onFilterAlertClosed = (filterValues: any) => {
	return {
		type: UPLOAD_PROJECT_FILTER_ALERT_CLOSED,
		payload: { filterValues }
	}
}

export const onFilterAlertConfirm = (filters: any, filterValues: any) => {
	return {
		type: UPLOAD_PROJECT_FILTER_ALERT_CONFIRMED,
		payload: { filters, filterValues }
	}
}

export const uploadComplete = (success: any) => {
	return { type: UPLOAD_COMPLETE }
}
export const projectNameChanged = (newValue: any) => {
	return { type: PROJECT_NAME_CHANGED, payload: newValue }
}

export const draggingToDropzoneChangedUpload = (newValue: any) => {
	return { type: DRAGGING_OVER_DROPZONE, payload: newValue }
}
export const onCADChange = (newValue: any) => {
	return { type: CAD_NAME_CHANGED, payload: newValue }
}
export const onERPChange = (newValue: any) => {
	return { type: ERP_NAME_CHANGED, payload: newValue }
}
export const onQuantityChange = (newValue: any) => {
	return { type: QUANTITY_CHANGED, payload: newValue }
}
export const onApplicationChange = (newValue: any) => {
	return { type: PROJECT_APPLICATION_CHANGED, payload: newValue }
}

export const onDropDefaultFile = (
	acceptedFileTypes: any,
	notSupportedUnitTypeFormats: any,
	selectedType: boolean,
	defaultFileName: string
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const file = new File([], defaultFileName, {
				type: 'binary/octet-stream'
			})
			const allowNumberExtensionFeatureOn = Feature.isFeatureOn(
				FeatureComponentId.ALLOW_NUMBER_EXTENSION
			)
			dispatch({
				type: ADDED_FILES,
				payload: {
					files: [file],
					acceptedFileTypes,
					notSupportedUnitTypeFormats,
					selectedType,
					allowNumberExtensionFeatureOn
				}
			})
		} catch (err) {
			console.log(err)
		}
	}
}

export const addedFiles = (
	files: any,
	acceptedFileTypes: any,
	notSupportedUnitTypeFormats: any,
	selectedType: string,
	partsFileSizeLimit: number,
	rejectedFiles: any
) => {
	const allowNumberExtensionFeatureOn = Feature.isFeatureOn(
		FeatureComponentId.ALLOW_NUMBER_EXTENSION
	)
	return {
		type: ADDED_FILES,
		payload: {
			files,
			acceptedFileTypes,
			notSupportedUnitTypeFormats,
			selectedType,
			partsFileSizeLimit,
			rejectedFiles,
			allowNumberExtensionFeatureOn
		}
	}
}

export const removeError = () => {
	return {
		type: REMOVE_ERROR_FROM_DROP_ZONE
	}
}
export const removeFiles = (formatType: any) => {
	return {
		type: REMOVED_FILES,
		payload: {
			formatType
		}
	}
}

export const onToleranceToggle = () => {
	return {
		type: UPLOAD_PROJECT_TOLERANCE_TOGGLED
	}
}

export const onSelectToleranceClass = (customToleranceValue: any) => {
	return {
		type: UPLOAD_PROJECT_TOLERANCE_CHANGED,
		payload: { customToleranceValue }
	}
}

const getErrorWhileUpload = (
	dispatch: any,
	projectName: any,
	quantity: any,
	showUnitTypeSelector: any,
	unitType: any,
	selectedType: string,
	bomFile: any,
	material: any,
	drawingMaterial: any,
	files: any
) => {
	let error = false
	const is2dUpload = selectedType === KEY_2d
	const isMetaData = selectedType === METADATA

	if (!projectName || projectName.length === 0) {
		createProjectFailed(dispatch, MISSING_PROJECT_NAME_ERROR.message)
		error = true
	}

	if (
		!quantity &&
		Feature.isFeatureOn(FeatureComponentId.FORCE_SPECIFY_QUANTITY) &&
		!Feature.isFeatureOn(FeatureComponentId.QUICK_CAD_UPLOAD)
	) {
		createProjectFailed(dispatch, MISSING_PROJECT_WRONG_QUANTITY_ERROR.message)
		error = true
	}

	if (showUnitTypeSelector && (!unitType || unitType.length === 0)) {
		unitTypeProjectFailed(dispatch)
		error = true
	}

	if (!isMetaData && !is2dUpload && !bomFile && !material && !drawingMaterial) {
		createProjectFailed(dispatch, MISSING_BOM_FILE_OR_MATERIAL_ERROR.message)
		error = true
	}

	if (!is2dUpload && drawingMaterial) {
		const cadFiles = files?.filter(
			(file: any) => file.name.split('.').pop().toLowerCase() !== PDF_FORMAT
		)
		if (cadFiles?.length === 0) {
			createProjectFailed(dispatch, MISSING_CAD_FILE_ERROR.message)
			error = true
		}
	}

	if (is2dUpload && !drawingMaterial && !material) {
		createProjectFailed(
			dispatch,
			MISSING_DRAWING_MATERIAL_OR_MATERIAL_ERROR.message
		)
		error = true
	}

	if (!files || files.length === 0) {
		createProjectFailed(dispatch, MISSING_PROJECT_FILES_ERROR.message)
		error = true
	}

	const fileValidationErr = getFileValidationError(files, is2dUpload)

	if (fileValidationErr) {
		createProjectFailed(dispatch, fileValidationErr)
		error = true
	}

	return error
}

export const uploadProjectClicked = (
	projectName: any,
	quantity: any,
	ERP: any,
	CAD: any,
	files: any,
	unitType: any,
	bomFile: any,
	material: any,
	toleranceIncluded: any,
	customToleranceValue: any,
	user: any,
	application: any,
	filters: any,
	showUnitTypeSelector: any,
	drawingMaterial: any,
	selectedType: string,
	selectedScenario: any,
	history: History,
	skipFileUpload: boolean,
	lightUser: {
		email: string
		name: string
		company: string
		zipCode: string
		communicationTool?: boolean
	},
	projectStandardCost?: number | null,
	manufactureMethod?: string,
	amValue?: Array<string>,
	filesWithMaterialAndQuantity?: IFileWithMaterialAndQuantity[],
	additionalNotes?: string,
	personalizedLightUser?: boolean,
	isStandardCostBoxChecked?: boolean
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		const is2dUpload = selectedType === KEY_2d

		const quickCADUploadFeatureOn = Feature.isFeatureOn(
			FeatureComponentId.QUICK_CAD_UPLOAD
		)
		const isAddressFieldOn = Feature.isFeatureOn(
			FeatureComponentId.UPLOAD_USER_COUNTRY_FIELD
		)
		const allowSimpleLogin = Feature.isFeatureOn(
			FeatureComponentId.ALLOW_SIMPLE_LOGIN
		)

		if (user.roles.includes(UserRole.LIGHT) && !personalizedLightUser) {
			dispatch({
				type: UPDATE_TRIAL_USER_UPLOAD_PROJECT_POPUP,
				payload: { trialUserPopupErrorMessage: '' }
			})

			const address = store.getState().CastorLocationSearchReducer

			if (!lightUser.email || !lightUser.name) {
				return
			}

			try {
				const newTrialUser = await register({
					email: lightUser.email,
					company: lightUser.company,
					name: lightUser.name,
					zip_code: lightUser.zipCode || '',
					communicationTool: lightUser.communicationTool || false,
					light: true,
					...(isAddressFieldOn && !isEmpty(address?.country)
						? {
								country: address?.country,
								state: address?.state
						  }
						: {})
				})

				if (!newTrialUser) {
					return
				}
				const tokenData = newTrialUser?.data?.tokenData

				dispatch({
					type: AUTHORIZATION_TOKEN_UPDATED,
					payload: {
						token: tokenData?.access_token,
						token_type: tokenData?.token_type
					}
				})
				dispatch({
					type: REFRESH_TOKEN_UPDATED,
					payload: {
						refreshToken: tokenData?.refreshToken,
						token_type: tokenData?.token_type,
						refreshTokenExpireIn: tokenData?.refreshTokenExpireIn
					}
				})
				dispatch({
					type: USER_ID_UPDATE,
					payload: {
						id: newTrialUser.data.user?.id
					}
				})
			} catch (error: any) {
				if (
					error?.message?.internalCode === USER_COUNTRY_NOT_ALLOWED ||
					error.code === USER_COUNTRY_NOT_ALLOWED
				) {
					dispatch({
						type: ALERT_POPPED,
						payload: {
							alertClass: 'not-available-modal',
							text: getString('COUNTRY_NOT_AVAILABLE'),
							headerTitle: '',
							alertType: AlertType.WARNING,
							showCancel: false,
							onConfirm: () => {
								dispatch({ type: ALERT_POPUP_CANCELED })
								window.location.reload()
							},
							confirmText: OK
						}
					})
					return
				}

				if (!allowSimpleLogin) {
					if (
						error?.message?.internalCode === lightUserEmailExistsError ||
						error?.message?.internalCode === regularUserEmailExistsError
					) {
						dispatch({
							type: UPDATE_TRIAL_USER_UPLOAD_PROJECT_POPUP,
							payload: {
								trialUserPopupErrorMessage: getString(
									'USER_NOT_EXIST_OR_NO_CREDIT'
								)
							}
						})

						return
					}

					if (
						error?.message?.internalCode !== lightUserEmailExistsError ||
						error?.message?.internalCode !== regularUserEmailExistsError
					) {
						dispatch({
							type: UPDATE_TRIAL_USER_UPLOAD_PROJECT_POPUP,
							payload: {
								trialUserPopupErrorMessage:
									error?.message?.message || error?.message
							}
						})

						return
					}
				}
				if (error?.message?.internalCode === lightUserEmailExistsError) {
					try {
						await simpleLoginUser(dispatch, lightUser.email)
					} catch (error: any) {
						dispatch({
							type: UPDATE_TRIAL_USER_UPLOAD_PROJECT_POPUP,
							payload: {
								trialUserPopupErrorMessage:
									error?.message?.message || error?.message
							}
						})

						return
					}
				} else {
					dispatch({
						type: UPDATE_TRIAL_USER_UPLOAD_PROJECT_POPUP,
						payload: {
							trialUserPopupErrorMessage: getString(
								'USER_NOT_EXIST_OR_NO_CREDIT'
							)
						}
					})

					return
				}
			}
			dispatch({ type: HIDE_TRIAL_USER_UPLOAD_PROJECT_POPUP })
		}

		dispatch({ type: CREATE_PROJECT })

		const uploadError = getErrorWhileUpload(
			dispatch,
			projectName,
			quantity,
			showUnitTypeSelector,
			unitType,
			selectedType,
			bomFile,
			material,
			drawingMaterial,
			files
		)

		await store.dispatch(getUserUploadActivity())
		const { userDateExpired } = store.getState().uploadProject

		if ((!quickCADUploadFeatureOn && uploadError) || userDateExpired) {
			return
		}

		let materialId = material && material.id ? material.id : null
		const filterValues = prepareFiltersToSend(filters) || null
		const projectCadType = is2dUpload
			? ProjectCADType.DRAWING
			: drawingMaterial
			? ProjectCADType.MIXED
			: ProjectCADType.MODEL

		const selectedProjectScenario = {
			...(!isEmpty(selectedScenario) && {
				...selectedScenario.data,
				name: selectedScenario.name,
				Global: true
			})
		}

		let formatType = getCorrectFormatType(
			is2dUpload,
			drawingMaterial,
			selectedType
		)

		const manufacture =
			findKey(
				manufacturingMethodTypes,
				method => method === manufactureMethod
			) || null

		const isSpecifiedQuantity =
			quantity >= 1 ||
			filesWithMaterialAndQuantity?.every(file => file.productionQuantity)

		if (quantity < 1) {
			quantity = selectedProjectScenario.quantity || DEFAULT_QUANTITY
		}

		const filesWithParams = filesWithMaterialAndQuantity?.map(file => ({
			name: file.name,
			material: file.materialId || materialId,
			isSpecifiedQuantity: !!file.productionQuantity || isSpecifiedQuantity,
			printingStandards: file.printingStandards || null,
			quantity: file.productionQuantity || quantity,
			standardCost:
				file.standardCost ||
				(isStandardCostBoxChecked ? null : projectStandardCost),
			customToleranceValue: file.customToleranceValue || customToleranceValue
		}))

		if (!materialId) {
			const materialFromFile = filesWithParams?.[0].material
			const allFilesHaveSameMaterial = filesWithParams?.every(
				file => file.material === materialFromFile
			)
			materialId = allFilesHaveSameMaterial ? materialFromFile : null
		}

		try {
			const response = await createProject(
				projectName,
				quantity,
				CAD,
				ERP,
				skipFileUpload,
				filesWithParams,
				formatType,
				bomFile,
				unitType,
				materialId,
				toleranceIncluded,
				application,
				filterValues,
				drawingMaterial,
				projectCadType,
				selectedProjectScenario,
				lightUser,
				isStandardCostBoxChecked ? null : projectStandardCost,
				manufacture,
				amValue,
				isSpecifiedQuantity,
				additionalNotes
			)
			history.push(UPLOADING_ROUTE)

			const {
				quickProject,
				uploadURLs,
				id,
				antivirusMessages,
				antivirusMessagingEndpoint
			} = response?.data
			const fileUrls = !isEmpty(uploadURLs.metadataUploadURLs)
				? uploadURLs.metadataUploadURLs
				: uploadURLs
			const filesToUpload = files.filter((file: any) => fileUrls[file.name])

			createProjectSuccess(
				dispatch,
				files,
				quickProject,
				uploadURLs,
				id,
				bomFile,
				antivirusMessages,
				antivirusMessagingEndpoint,
				skipFileUpload,
				user?.defaultFileName,
				fileUrls,
				filesToUpload
			)
		} catch (error: any) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('SOMETHING_WENT_WRONG')
				}
			})
			createProjectFailed(dispatch, error.message)
		}
	}
}

const createProjectSuccess = (
	dispatch: any,
	files: any,
	quickProject: any,
	uploadURLs: any,
	projectId: any,
	bomFile: any,
	antivirusMessages: any,
	antivirusMessagingEndpoint: any,
	skipFileUpload: boolean,
	defaultFileName: string,
	fileUrls: any,
	filesToUpload: any
) => {
	uploadFilesToProject(
		dispatch,
		uploadURLs,
		bomFile,
		antivirusMessages,
		antivirusMessagingEndpoint,
		skipFileUpload,
		defaultFileName,
		fileUrls,
		filesToUpload
	)

	dispatch({
		type: CREATE_PROJECT_SUCCESS,
		payload: { projectId, quickProject, files: filesToUpload }
	})
}

const uploadFilesToProject = (
	dispatch: any,
	uploadURLs: any,
	bomFile: any,
	antivirusMessages: any,
	antivirusMessagingEndpoint: any,
	skipFileUpload: boolean,
	defaultFileName: string,
	fileUrls: any,
	filesToUpload: any
) => {
	uploadProjectFiles(
		uploadURLs,
		bomFile,
		antivirusMessages,
		antivirusMessagingEndpoint,
		(file: any, success: any, filePrecentage: any) =>
			fileUploadCallback(
				dispatch,
				file,
				success,
				uploadURLs,
				antivirusMessages,
				antivirusMessagingEndpoint,
				filePrecentage
			),
		skipFileUpload,
		defaultFileName,
		fileUrls,
		filesToUpload
	)
}

export const uploadFailedFiles = (
	uploadURLs: any,
	bomFile: any,
	antivirusMessages: any,
	antivirusMessagingEndpoint: any,
	skipFileUpload: boolean,
	defaultFileName: string,
	fileUrls: any,
	filesToUpload: any
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		uploadFilesToProject(
			dispatch,
			uploadURLs,
			bomFile,
			antivirusMessages,
			antivirusMessagingEndpoint,
			skipFileUpload,
			defaultFileName,
			fileUrls,
			filesToUpload
		)
		dispatch({ type: UPLOAD_FAILED_FILES })
	}
}
const createProjectFailed = (dispatch: any, message?: any) => {
	dispatch({ type: CREATE_PROJECT_FAILED, payload: message })
}

const unitTypeProjectFailed = (dispatch: any) => {
	dispatch({ type: UNIT_TYPE_CREATE_PROJECT_FAILED })
}

export const resetUnitTypeError = () => {
	return {
		type: UNIT_TYPE_CREATE_PROJECT_RESET
	}
}

const fileUploadCallback = (
	dispatch: any,
	file: any,
	success: any,
	uploadURLs: any,
	antivirusMessages: any,
	antivirusMessagingEndpoint: any,
	filePrecentage: any
) => {
	dispatch({
		type: UPLOAD_FILE_COMPLETE,
		payload: {
			file,
			success,
			uploadURLs,
			antivirusMessages,
			antivirusMessagingEndpoint,
			filePrecentage
		}
	})
}

export const uploadUnitTypeSelected = (value: any) => {
	return { type: UPLOAD_UNIT_TYPE_CHANGED, payload: value }
}

export const BOMFileSelected = (file: any) => {
	const isAllowedExt = file && bomFileTypes.includes(file.type)

	if (!file || !isAllowedExt) {
		return { type: BOM_REMOVED_UPLOAD_PROJ }
	}

	return { type: BOM_SELECTED_UPLOAD_PROJ, payload: file }
}

export const materialRadioChanged = (value: any, isMachining: any) => {
	return {
		type: MATERIAL_RADIO_BUTTON_VALUE_CHANGED_UPLOAD_PROJ,
		payload: { value, isMachining }
	}
}

export const materialSelected = (material: Material, isMachining?: any) => {
	return {
		type: MATERIAL_VALUE_CHANGED_UPLOAD_PROJ,
		payload: { material, isMachining }
	}
}

export const removeFile = (
	fileToDelete: any,
	notSupportedUnitTypeFormats: any
) => {
	const allowNumberExtensionFeatureOn = Feature.isFeatureOn(
		FeatureComponentId.ALLOW_NUMBER_EXTENSION
	)
	return {
		type: REMOVE_FILE_FROM_UPLOAD_DROPZONE,
		payload: {
			fileToDelete,
			notSupportedUnitTypeFormats,
			allowNumberExtensionFeatureOn
		}
	}
}

export const clearUploadMessages = (projectId: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: UPLOAD_CLEAR_STATUS,
			payload: { id: projectId }
		})
	}
}

export const sendAdminUploadFilesInfo = (projectId: string) => {
	return async () => {
		try {
			await informAdminUploadFilesFailed(projectId)
		} catch (err) {
			console.error(err)
		}
	}
}

export const getFastProjectPoller = (projectId: string, bundleId?: string) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: QUICK_PROJECT_POLLER_STARTED
		})

		const silentUploadingNotification = Feature.isFeatureOn(
			FeatureComponentId.SILENT_UPLOADING_NOTIFICATION
		)

		if (silentUploadingNotification) {
			try {
				await getProjectAnalysisStatus(projectId, bundleId)
			} catch (err) {
				console.error(err)
			}
		} else {
			let timeOutDateTime = new Date()
			timeOutDateTime.setHours(timeOutDateTime.getHours() + 1)
			poller = new Poller(
				2000,
				timeOutDateTime,
				() => getProjectAnalysisStatus(projectId, bundleId),
				(res: any) =>
					res.data.projectStatus === PUBLISHED ||
					res.data.projectStatus === FAILED,
				res => {
					if (res) {
						dispatch({
							type: UPLOAD_CHECK_STATUS,
							payload: {
								id: projectId || bundleId,
								...res?.data,
								analyzingStatus:
									typeof res?.data?.analyzingStatus !== 'object'
										? JSON.parse(res?.data?.analyzingStatus)
										: res?.data?.analyzingStatus || {}
							}
						})
					} else {
						throw new Error(POLLER_CANNOT_CONNECT_TO_THE_SERVER)
					}
				}
			)

			poller
				.start()
				.then(res => {
					// need to show full progress for a while
					setTimeout(() => {
						dispatch({
							type: QUICK_PROJECT_PUBLISHED
						})
					}, 2000)
				})
				.catch(error => {
					console.error(error)
					if (error === POLLER_REACHED_TIMEOUT) {
						window.location.reload()
						return
					}
					if (error.toString().includes(POLLER_CANNOT_CONNECT_TO_THE_SERVER)) {
						dispatch({
							type: QUICK_PROJECT_CONNECTION_ERROR_FAILED
						})
						return
					}

					dispatch({
						type: QUICK_PROJECT_FAILED
					})
				})
		}
	}
}

export const clearPoller = () => {
	if (poller.pollerInterval) {
		poller.stop()
	}
	return {
		type: null
	}
}

const getFileValidationError = (files: any, is2dUpload: any) => {
	if (files.length <= 0) {
		if (is2dUpload) {
			return NO_PDF_FILE_ERROR
		}
		return NO_CAD_FILE_ERROR
	}
	return null
}

export const updateProjectStandardCost = (value: number | string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: UPLOAD_PROJECT_EARLY_PRODUCTION_CHANGE,
			payload: value
		})
	}
}

export const updateManufacturing = (value: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: UPLOAD_PROJECT_MANUFACTURING_CHANGE,
			payload: value
		})
	}
}

export const updateAMValue = (value: Array<''>) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: UPLOAD_PROJECT_AM_VALUE_CHANGE,
			payload: value
		})
	}
}

export const onFileMaterialAndQuantityChange = (
	changedFile: IFileWithMaterialAndQuantity
) => {
	const { filesWithMaterialAndQuantity: oldFiles } =
		store.getState().uploadProject

	const filesWithMaterialAndQuantity = oldFiles?.map(
		(file: IFileWithMaterialAndQuantity) => {
			if (file.name === changedFile.name) {
				return changedFile
			}
			return file
		}
	)

	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: FILE_MATERIAL_OR_QUANTITY_CHANGED,
			payload: filesWithMaterialAndQuantity
		})
	}
}

export const additionalNotesChanged = (value: any) => {
	return { type: ADDITIONAL_NOTES_CHANGED, payload: value }
}
