import React, { FC, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { FunctionEditorInitialState } from '../CustomizeCostingFunctionEditor/CostingFunctionEditorReducer'
import CreateFunctionContent from '../CustomizeCostingFunctionEditor/CreateFunctionContent'
import { FunctionCategories } from '../CustomizeCostingFunctionEditor/CustomizeCostingFunctionTypes'
import { getReducerStateId } from './Constants'
import { setupFunctionEditorModal } from './CustomizePostProcessesActions'
import { useFunctionEditorModal } from './CustomizePostProcessesHooks'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import Loader from 'Scenes/Loader/Loader'
import { getString } from 'Services/Strings/StringService'

const FunctionEditorAlert: FC = () => {
	const {
		functionEditorModalIsOpen,
		postProcessInFunctionEditor,
		functionEditorAlertType,
		modalIsLoading
	} = useSelector(
		(state: RootStateOrAny) => state.CustomizePostProcessesReducer
	)

	const { editableFunctionStrings, printingTechnologies } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const reducerStateId = getReducerStateId(
		postProcessInFunctionEditor.id,
		functionEditorAlertType
	)

	const { customFunction } = useSelector(
		(state: RootStateOrAny) =>
			state.CostingFunctionEditorReducer?.functionEditorStates[
				reducerStateId
			] || new FunctionEditorInitialState()
	)
	const dispatch = useDispatch()

	const { cancelModal, handleApply, modalHeader, functionCategory } =
		useFunctionEditorModal(postProcessInFunctionEditor, functionEditorAlertType)

	useEffect(() => {
		dispatch(
			setupFunctionEditorModal(
				functionCategory as FunctionCategories,
				reducerStateId,
				printingTechnologies,
				editableFunctionStrings
			)
		)
	}, [
		dispatch,
		editableFunctionStrings,
		functionCategory,
		printingTechnologies,
		reducerStateId
	])

	return (
		<CastorAlert
			alertBodyClass="costing-function-editor--alert"
			headerTitle={modalHeader}
			show={functionEditorModalIsOpen}
			onConfirm={() => handleApply(customFunction)}
			showCancel={true}
			onCancel={cancelModal}
			cancelOptionalText={getString('CANCEL')}
			confirmOptionalText={getString('APPLY')}
			onFooterCancel={cancelModal}
			disabled={!customFunction.length}
			fullScreen
		>
			<div className="customize-post-process--function-editor-alert">
				<Loader
					load={modalIsLoading}
					wrapperClassName="customize-post-process--function-editor-alert--loader"
				/>
				<div className="customize-post-process--function-editor-alert--description">
					{getString('COSTING_FUNCTION_EDITOR_STEP_2_DESCRIPTION')}
				</div>
				<CreateFunctionContent
					saveButton={false}
					reducerStateId={reducerStateId}
				/>
			</div>
		</CastorAlert>
	)
}

export default FunctionEditorAlert
