import { Component } from 'react'
import cx from 'classnames'

import Loader from '../../Loader/Loader'
import { Button } from '../thirdParty/CreativeTim/components'

import './ButtonWithLoader.scss'

class ButtonWithLoader extends Component {
	render() {
		const {
			loading,
			style,
			refreshStyle,
			rounded,
			label,
			onClick,
			children,
			disabled,
			buttonSize,
			showFlex = false,
			className,
			id,
			color,
			type = 'button',
			qaDataElementName
		} = this.props

		return (
			<Button
				data-qa={qaDataElementName}
				id={id}
				size={buttonSize}
				disabled={!!disabled || loading}
				color={loading ? 'transparent' : color || 'primary'}
				rounded={rounded?.toString()}
				label={label}
				style={style}
				onClick={onClick}
				className={cx(className, {
					'button-with-loader-on': loading
				})}
				type={type}
			>
				{loading ? (
					<Loader
						load={loading}
						size={40}
						showFlex={showFlex}
						styles={{
							...refreshStyle,
							...styles.refreshDefault
						}}
					/>
				) : (
					children
				)}
			</Button>
		)
	}
}

const styles = {
	refreshDefault: { display: 'inline-block', position: 'relative' }
}

export default ButtonWithLoader
