import loginCardStyle from '../../../../../../assets/jss/material-dashboard-pro-react/components/loginCardStyle.jsx'
import React from 'react'

// material-ui components
import { withStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import cx from 'classnames'
import classNames from 'classnames'
import PropTypes from 'prop-types'

function LoginCard({ ...props }) {
	const {
		classes,
		headerColor,
		plainCard,
		cardTitle,
		cardSubtitle,
		content,
		footer,
		socials,
		footerAlign,
		customCardClass,
		customCardTitleClasses,
		customCardHeaderClasses
	} = props
	const plainCardClasses = cx({
		[' ' + classes.cardPlain]: plainCard,
		[' ' + customCardClass]: customCardClass !== undefined
	})
	const cardPlainHeaderClasses = cx({
		[' ' + classes.cardPlainHeader]: plainCard
	})
	return (
		<Card className={classes.card + plainCardClasses}>
			<CardHeader
				classes={{
					root: classNames(
						classes.cardHeader,
						customCardHeaderClasses,
						classes[headerColor + 'CardHeader'],
						cardPlainHeaderClasses
					),
					title: classes.cardTitle + ' ' + customCardTitleClasses,
					subheader: classes.cardSubtitle
				}}
				title={cardTitle}
				subheader={socials}
			/>
			<p className={classes.cardSubtitle}>{cardSubtitle}</p>
			<CardContent className={classes.cardContent}>{content}</CardContent>
			{footer !== undefined ? (
				<CardActions
					className={classes.cardActions + ' ' + classes[footerAlign]}
				>
					{footer}
				</CardActions>
			) : null}
		</Card>
	)
}

LoginCard.defaultProps = {
	headerColor: 'purple'
}

LoginCard.propTypes = {
	plainCard: PropTypes.bool,
	classes: PropTypes.object.isRequired,
	headerColor: PropTypes.oneOf([
		'orange',
		'green',
		'red',
		'blue',
		'purple',
		'rose'
	]),
	cardTitle: PropTypes.node,
	cardSubtitle: PropTypes.node,
	content: PropTypes.node,
	footer: PropTypes.node,
	socials: PropTypes.node,
	footerAlign: PropTypes.oneOf(['left', 'right', 'center']),
	customCardTitleClasses: PropTypes.string,
	customCardHeaderClasses: PropTypes.string,
	customCardClass: PropTypes.string
}

export default withStyles(loginCardStyle)(LoginCard)
