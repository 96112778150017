import React, { ChangeEvent, FC, memo, useState } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { Close } from '@material-ui/icons'

import * as CustomizeCO2CalculationAction from '.././CustomizeCO2CalculationAction'
import {
	Button,
	ItemGrid,
	Table
} from 'Scenes/Components/thirdParty/CreativeTim/components'
import { CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import '../CustomizeCO2Calculation.scss'

const ItemGridTSX: any = ItemGrid
const CloseTSX: any = Close

interface IProps {
	printersList: any
	deleteMaterial: Function
}
interface IReduxStore {
	CustomizeCO2CalculationReducer: any
}

const EmissionsPrinterMaterialTable: FC<IProps> = ({
	printersList,
	deleteMaterial
}) => {
	const onDeleteMaterial = (itemToDelete: any) => {
		deleteMaterial(itemToDelete)
	}

	const renderDeleteButton = (itemToDelete: number) => {
		return (
			<Button
				title={CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM}
				onClick={() => onDeleteMaterial(itemToDelete)}
				color="dangerNoBackground"
				style={{ margin: 'unset', padding: 'unset' }}
			>
				<CloseTSX className="custom-calculation-co2--table--icon" />
			</Button>
		)
	}

	const renderCo2MaterialData = () => {
		return printersList.map(
			(material: {
				material: { type: any; printers: any; name: string; id: number }
				co2PerKg: number
				printerName: string
			}) => [
				material.printerName || material.material.printers,
				material.material.name,
				material.co2PerKg,
				renderDeleteButton(material.material.id)
			]
		)
	}

	if (printersList.length > 0) {
		return (
			<div className="customize__table">
				<ItemGridTSX>
					<Table
						editTableStyle={'co2CalculationTable'}
						tableHead={[
							getString('PRINTER_NAME'),
							getString('ADMIN_PRINTER_MATERIALS_PAGE_HEADER'),
							getString('CO2_PER_KG_PLACE_HOLDER'),
							getString('ACTIONS')
						]}
						tableData={renderCo2MaterialData()}
					/>
				</ItemGridTSX>
			</div>
		)
	} else {
		return <></>
	}
}

const mapStateToProps = (CustomizeCO2CalculationReducer: IReduxStore) => {
	return {
		...CustomizeCO2CalculationReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeCO2CalculationAction }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(EmissionsPrinterMaterialTable)
)
