import { FC, memo } from 'react'

import cx from 'classnames'

import DetailsPopup from '../DetailsPopup'
import IconFactory from '../StarIcon/IconFactory'

import './DataTable.scss'

interface Props {
	text: string | number
	data: any
	className: string
}

const renderContent = (
	iconClassName: string,
	iconName: string,
	text: string | number,
	iconHoverText?: string
) => {
	return (
		<>
			<div
				className="data-table-row-text"
				data-qa={`data-qa-orientation-test-${text}`}
				title={typeof text === 'string' ? text : ''}
			>
				{text}
			</div>
			<DetailsPopup
				isHover={!!iconHoverText}
				data={iconHoverText}
				popperDirection="bottom"
				popperClassName="info-box-wrapper"
				popperContactClassName="info-box-data"
			>
				<IconFactory
					iconName={iconName}
					className={`data-table-row-icon ${iconClassName}`}
				/>
			</DetailsPopup>
		</>
	)
}

const DataTableTextWithIcon: FC<Props> = ({
	text,
	data: { iconName, iconClassName, iconOnTheRight, iconHoverText, ...restData },
	className
}) => (
	<div
		className={cx(
			'data-table-row data-table-opacity-field',
			className,
			restData.className,
			iconOnTheRight ? 'icon-right' : 'icon-left'
		)}
		data-qa={restData?.dataQaAttribute}
	>
		{renderContent(iconClassName, iconName, text, iconHoverText)}
	</div>
)

export default memo(DataTableTextWithIcon)
