import React, { Component } from 'react'

import { VictoryLabel, VictoryTooltip } from 'victory'

class ProjectResultDistributionCustomLabel extends Component {
	static defaultEvents = VictoryTooltip.defaultEvents

	render() {
		const minValue = this.props.datum.y < 1
		const diff = this.props.datum.endAngle - this.props.datum.startAngle

		//calculation
		const smallAngle = diff < 20
		let dx = minValue ? -60 : 0
		let dy = minValue ? 10 : 0
		let angle = smallAngle ? -45 : 0
		let fontSize = smallAngle ? 14 : 18

		if (angle) {
			dy = 10
			dx = 10
		}

		const victoryLabelStyle = {
			fontSize: fontSize,
			fontWeight: 'bold'
		}

		return (
			<g>
				<VictoryLabel
					{...this.props}
					labelPlacement={'vertical'}
					style={victoryLabelStyle}
					text={this.props.datum.x}
					dx={dx}
					dy={dy}
					angle={angle}
					polar={true}
				/>
				<VictoryTooltip
					{...this.props}
					x={200}
					y={this.props.yPosition || 240}
					orientation="top"
					pointerLength={2}
					width={100}
					height={100}
					flyoutStyle={{ stroke: 'none', fill: 'transparent' }}
					active={this.props.active}
					style={this.props.style}
				/>
			</g>
		)
	}
}

export default ProjectResultDistributionCustomLabel
