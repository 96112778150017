import {
	COST_AND_LEAD_LABELS,
	COST_LEAD_PERCENTAGE_DRAWING_COST
} from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'

export const CO2_NAMES = {
	AMShippingDistance: {
		label: COST_AND_LEAD_LABELS.KM,
		type: 'number',
		validation: {
			min: 1,
			max: 20000
		}
	},
	co2airPlanePercentageForAM: {
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		type: 'number',
		validation: {
			min: 0,
			max: 100
		}
	},
	co2truckPercentageForAM: {
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		type: 'number',
		validation: {
			min: 0,
			max: 100
		}
	},
	co2trainPercentageForAM: {
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		type: 'number',
		validation: {
			min: 0,
			max: 100
		}
	},
	co2shipPercentageForAM: {
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		type: 'number',
		validation: {
			min: 0,
			max: 100
		}
	},
	CNCShippingDistance: {
		label: COST_AND_LEAD_LABELS.KM,
		type: 'number',
		validation: {
			min: 1,
			max: 20000
		},
		alert: COST_LEAD_PERCENTAGE_DRAWING_COST.ALERT
	},
	co2airPlanePercentageForCNC: {
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		type: 'number',
		validation: {
			min: 0,
			max: 100
		}
	},
	co2truckPercentageForCNC: {
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		type: 'number',
		validation: {
			min: 0,
			max: 100
		}
	},
	co2trainPercentageForCNC: {
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		type: 'number',
		validation: {
			min: 0,
			max: 100
		}
	},
	co2shipPercentageForCNC: {
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		type: 'number',
		validation: {
			min: 0,
			max: 100
		}
	},
	MoldingShippingDistance: {
		label: COST_AND_LEAD_LABELS.KM,
		type: 'number',
		validation: {
			min: 1,
			max: 20000
		}
	},
	co2airPlanePercentageForMold: {
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		type: 'number',
		validation: {
			min: 0,
			max: 100
		}
	},
	co2truckPercentageForMold: {
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		type: 'number',
		validation: {
			min: 0,
			max: 100
		}
	},
	co2trainPercentageForMold: {
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		type: 'number',
		validation: {
			min: 0,
			max: 100
		}
	},
	co2shipPercentageForMold: {
		label: COST_AND_LEAD_LABELS.PERCENTAGE,
		type: 'number',
		validation: {
			min: 0,
			max: 100
		}
	}
}

export const CO2_MANUFACTURING = {
	AM: {
		name: getString('AM'),
		tooltip: getString('EMISSION_COMPARISON_AM_INFO'),
		edit: false,
		error: false,
		data: {}
	},
	CNC: {
		name: getString('CNC'),
		tooltip: getString('EMISSION_COMPARISON_CNC_INFO'),
		edit: false,
		error: false,
		data: {}
	},
	Mold: {
		name: getString('MOLDINGS'),
		tooltip: getString('EMISSION_COMPARISON_MOLDING_INFO'),
		edit: false,
		error: false,
		data: {}
	}
}

export const SHIPMENT_MAX = 20000
export const SHIPMENT_MIN = 1
export const CO2_ORDER = [
	'ShippingDistance',
	'shipPercentage',
	'airPlane',
	'trainPercentage',
	'truckPercentage'
]
export const ALLOW_AMOUNT = 100
export const ZERO_AMOUNT = 0
export const FIRST_IDX = 0
export const LAST_IDX = 2
export const EMPTY = ''
