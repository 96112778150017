import { Action } from '../../../../global actions/ActionModels'
import {
	GENERATE_LICENSE_ADD_FILE,
	GENERATE_LICENSE_ERROR,
	GENERATE_LICENSE_FILE_END,
	GENERATE_LICENSE_FILE_START
} from '../../../../global actions/types'

export interface CastorAlertInitialState {
	readonly loading: boolean
	readonly file: any
	readonly preparedToSend: any
	readonly error: string
}

const initialState: CastorAlertInitialState = {
	loading: false,
	file: {},
	preparedToSend: {},
	error: ''
}
const adminLicenseCreatorReducer = (
	state = initialState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case GENERATE_LICENSE_FILE_START:
			return {
				...state,
				loading: true
			}
		case GENERATE_LICENSE_FILE_END:
			return {
				...initialState
			}

		case GENERATE_LICENSE_ADD_FILE:
			return {
				...state,
				file: payload.file,
				preparedToSend: payload.preparedToSend,
				error: ''
			}

		case GENERATE_LICENSE_ERROR:
			return {
				...state,
				error: payload.error
			}

		default:
			return state
	}
}
export default adminLicenseCreatorReducer
