import {
  UPDATE_SHOW_TOUR,
  UPDATE_TOUR_FAILED
} from '../../../global actions/types'
import {
  TOUR_NEXT_STEP_CLICKED,
  TOUR_STARTED,
  TOUR_FINISHED,
  TOUR_STEPS_UPDATED
} from '../../../global actions/types/takeATourTypes'
import { updateTour } from '../../../Services/Network'
import { IPage } from './types'

export interface UpdateTourActionAttributes {
  email: string
  pagesVisited: string[]
  pageName: string
  isShowTourClick?: boolean
  isTourFinished?: boolean
}

export const onNextStepClick = (back?: boolean) => {
  return { type: TOUR_NEXT_STEP_CLICKED, payload: { back } }
}

export const onStepIndexUpdated = (stepIndex: number) => {
  return { type: TOUR_STEPS_UPDATED, payload: { stepIndex } }
}

export const onUpdateTourSteps = (
  tourPages: IPage[],
  pageName: string,
  enableScrolling?: boolean
) => {
  const steps =
    tourPages.find(tourPage => tourPage.name === pageName)?.tourSteps || []
  return {
    type: TOUR_STEPS_UPDATED,
    payload: {
      steps,
      stepIndex: undefined,
      disableScrolling: !enableScrolling,
      run: false
    }
  }
}

export const onTourStart = (payload: Record<string, any> = {}) => {
  return { type: TOUR_STARTED, payload }
}

export const onTourStop = () => {
  return { type: TOUR_FINISHED }
}

export const updateTourAction = ({
  email,
  pagesVisited,
  pageName,
  isShowTourClick = false,
  isTourFinished = false
}: UpdateTourActionAttributes) => {
  if (isShowTourClick) {
    return { type: TOUR_STARTED, payload: { pauseTour: false } }
  }
  const payload = {
    email,
    pagesVisited: pagesVisited
      ? [...new Set([...pagesVisited, pageName])]
      : null
  }
  return async (dispatch: any) => {
    try {
      await updateTour(payload)
      dispatch({ type: UPDATE_SHOW_TOUR, payload })
      if (isTourFinished) {
        dispatch({ type: TOUR_FINISHED })
      }
    } catch (error: any) {
      dispatch({ type: UPDATE_TOUR_FAILED, error })
    }
  }
}
