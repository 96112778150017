import {
	IInstanceStateExtractorPayload,
	IStateExtractorState
} from './InstanceStateExtractor'
import { Action, ActionWithPayload } from 'global actions/ActionModels'

const initialState: IStateExtractorState = {
	projectUploading: {}
}

export default (
	reducer: (
		state: any,
		{ type, payload }: Action<IInstanceStateExtractorPayload>
	) => any
) => {
	return (
		states: IStateExtractorState,
		payloadAndType: ActionWithPayload<IInstanceStateExtractorPayload>
	) => {
		if (payloadAndType && payloadAndType.payload) {
			const id = payloadAndType.payload.id || 0

			const originalState = states.projectUploading[id]

			states.projectUploading[id] = reducer(originalState, payloadAndType)
			return { ...states }
		}
		return initialState
	}
}
