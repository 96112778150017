import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { capitalize, isEmpty, map } from 'lodash'

import { addConfigurationClick } from '../../../MainPartAnalysis/MainPartAnalysisActions'
import { SolutionSingleFilter } from '../../../MainPartAnalysis/SolutionAnalysis/SolutionConfigure/SolutionConfigureFeatures/SolutionSingleFilter'
import { NewConfigurationButton } from '../../common/Buttons'
import { materialInfo } from '../../PartAnalysisConstants'
import { useSolutionReducer } from '../../PartAnalysisSelector'
import { getPrinterTechnologies } from '../../PartAnalysisService'
import { setupAdvancedFilters } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IConfiguration } from 'Services/models/IConfiguration'
import { Part } from 'Services/models/IPart'
import { ISolution } from 'Services/models/ISolution'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as Check } from 'assets/img/svg/icons/small-check.svg'

const materialInformation: any = materialInfo
const CheckIcon: any = Check

interface RowTextProps {
	name: string
	value?: string | number
	withIcon?: boolean
}

const RowText: FC<RowTextProps> = ({ name, value, withIcon }) => {
	return (
		<p>
			<span className="title">
				{withIcon && <CheckIcon />}
				{name}
			</span>
			{value && <span>{value}</span>}
		</p>
	)
}

interface ConfigurationInformationProps {
	configuration: IConfiguration
	part: Part
	partSolution: ISolution
	initialToleranceValue: string
	disablePart: boolean
}

const ConfigurationInformation: FC<ConfigurationInformationProps> = ({
	configuration,
	part,
	partSolution,
	initialToleranceValue,
	disablePart
}) => {
	const [toleranceValue, setToleranceValue] = useState<string | number>(
		initialToleranceValue
	)
	const dispatch = useDispatch()

	const { PostProcessOnCounter } = useSolutionReducer(configuration.id)

	const { filters, filterItemsList } = useSelector(
		(state: RootStateOrAny) =>
			state.AdvancedSettingsReducer?.advancedStates[
				`${configuration.id}_content`
			] || new AdvancedSettingsInitialState()
	)
	useEffect(() => {
		dispatch(
			setupAdvancedFilters(
				`${configuration.id}_content`,
				false,
				configuration.filters
			)
		)
	}, [])

	const materialProperty = isEmpty(configuration?.priorities)
		? configuration?.initialPriorities
		: configuration?.priorities

	const materialProperties = useMemo(() => {
		return map(materialProperty, (el: any, key: any) => {
			if (!el) return
			return (
				<RowText
					key={materialInformation[key] || key}
					name={`${materialInformation[key] || key}:`}
					value={el}
				/>
			)
		})
	}, [materialProperty])

	useEffect(() => {
		if (typeof partSolution?.lowestToleranceValue === 'number') {
			setToleranceValue(partSolution?.lowestToleranceValue)
		} else {
			const _class =
				Object.entries(ToleranceClass).find(
					([key, val]) => val == initialToleranceValue
				)?.[0] || ''
			setToleranceValue(_class)
		}
	}, [initialToleranceValue, partSolution?.lowestToleranceValue])

	const tolerance =
		typeof toleranceValue === 'number'
			? `${toleranceValue} [mm]`
			: getString(toleranceValue || '')

	const withPrinterTechnology = getPrinterTechnologies(configuration?.filters)

	return (
		<div className="details__block">
			<div className="details__information">
				<div className="details__information-general">
					<b>{getString('GENERAL_PROPERTIES')}</b>
					<RowText
						name={`${getString('BATCH_SIZE')}:`}
						value={configuration?.quantity}
					/>
					<RowText
						name={`${getString('ORIGINAL_MATERIAL_TYPE')}:`}
						value={capitalize(configuration?.material?.type)}
					/>
					<RowText
						name={`${getString('ORIGINAL_MATERIAL_CATEGORY')}:`}
						value={capitalize(configuration?.material?.category)}
					/>
					<RowText
						name={`${getString('MATERIAL_COMPARISON_HEADER_ORIGINAL')}:`}
						value={capitalize(configuration?.material?.name)}
					/>
					<RowText
						name={`${getString('POST_PROCESSES')}:`}
						value={
							PostProcessOnCounter && PostProcessOnCounter > 0
								? PostProcessOnCounter
								: getString('NONE')
						}
					/>
					{Feature.isFeatureOn(FeatureComponentId.TOLERANCES) &&
						!part?.isDrawing && (
							<RowText name={`${getString('TOLERANCE')}:`} value={tolerance} />
						)}
				</div>

				<div className="details__information-material">
					<b>{getString('MATERIAL_PROPERTIES')}</b>
					<RowText name={getString('LOW_HIGH_PROPERTIES')} />
					{materialProperties}
				</div>

				<div className="details__information-filter">
					<b>
						{getString('MATERIAL_PROPERTIES_FILTER')}:{' '}
						{filterItemsList?.length === 0 && getString('NONE')}
					</b>
					{filterItemsList?.map((filter: any) => (
						<SolutionSingleFilter
							printerTechnology={withPrinterTechnology}
							key={filter.name}
							showSolutionFilters={filters}
							filter={filter}
							originalMaterial={configuration?.material}
						/>
					))}
				</div>
			</div>
			<div className="devider" />
			<div className="details__action">
				<div>
					<NewConfigurationButton
						onAddNewConfigurationClick={() => dispatch(addConfigurationClick())}
						disablePart={disablePart}
					/>
				</div>
			</div>
		</div>
	)
}

export default memo(ConfigurationInformation)
