import React, { FC, memo } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'
import CastorPartResultIndex from '../../../../Components/CastorPartResultIndex/CastorPartResultIndex'
import CastorModalIframe from '../../../../Components/CastorModalIframe/CastorModalIframe'
import TransparentButton from '../../../../Components/TransparentButton'
import { WALL_THICKNESS_DEMAND_BUTTON_TEXT } from '../../../../../Services/Strings'
import { Part } from '../../../../../Services/models/IPart'

interface IProps {
  wallThickessModelUrl: string
  show3dIframe: boolean
  wallThicknessTestInMM: number
  ledWithTitleIndexes: any
  threeDViewerURL: string
  showWTButoon: boolean
  solution: any
  materialType: any
  part: Part
  onWallThicknessDemandClick: Function
  hide3dModalIframe: Function
  onViewerModelError: Function
}

const renderIframeFooter = (
  ledWithTitleIndexes: any,
  showWTButoon: boolean,
  materialType: any,
  onWallThicknessDemandClick: Function
) => {
  return (
    <Flexbox
      justifyContent="space-between"
      alignItems="flex-start"
      className="solution-analysis-object--threeDViewer--footer"
    >
      <CastorPartResultIndex
        indexes={ledWithTitleIndexes}
        materialType={materialType}
        className="solution-analysis-object--threeDViewer--footer--index"
      />
      {showWTButoon ? (
        <TransparentButton onClick={() => onWallThicknessDemandClick()}>
          {WALL_THICKNESS_DEMAND_BUTTON_TEXT}
        </TransparentButton>
      ) : (
        <div />
      )}
    </Flexbox>
  )
}

const ThreeDViewer: FC<IProps> = ({
  wallThickessModelUrl,
  show3dIframe,
  hide3dModalIframe,
  wallThicknessTestInMM,
  ledWithTitleIndexes,
  onWallThicknessDemandClick,
  threeDViewerURL,
  showWTButoon,
  onViewerModelError,
  solution,
  materialType,
  part
}) => {
  if (!wallThickessModelUrl) {
    return <div></div>
  }
  return (
    <>
      <CastorModalIframe
        solution={solution}
        partImageUrl={wallThickessModelUrl}
        showModal={show3dIframe}
        onCancel={hide3dModalIframe}
        onViewerModelError={onViewerModelError}
        part={part}
      >
        {renderIframeFooter(
          ledWithTitleIndexes,
          showWTButoon,
          materialType,
          onWallThicknessDemandClick
        )}
      </CastorModalIframe>
    </>
  )
}

export default memo(ThreeDViewer)
