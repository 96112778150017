import { memo, FC, useState, useEffect } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'

import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import OverrideComponentHOC from 'themes/OverrideComponentHOC'
import ToleranceClassMenu, {
  ToleranceClass
} from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import { getString } from 'Services/Strings/StringService'

interface CheckBoxToleranceProps {
  currentToleranceIncluded?: boolean
  onChange: Function
  is2dUpload: boolean
}

interface InfoButtonIconProps {
  onClick: Function
}

interface IProps extends CheckBoxToleranceProps, InfoButtonIconProps {
  materialCategory: any
  onToleranceToggle: any
  toleranceIncluded?: boolean
  onSelectToleranceClass: Function
  renderButtonComponents?: boolean
}

const CheckBoxTolerance: FC<CheckBoxToleranceProps> = ({
  currentToleranceIncluded,
  onChange,
  is2dUpload
}) => {
  return <>
    <CastorCheckbox
      checked={!!currentToleranceIncluded}
      onChange={onChange}
      inputProps={{
        'aria-label': 'secondary checkbox'
      }}
    />
    <span className="tolerance-check-box__text">
      {is2dUpload
        ? getString('UPLOAD_PROJECT_MATERIAL_TOLERANCE_DEFAULT')
        : getString('UPLOAD_PROJECT_MATERIAL_TOLERANCE')}
      </span>
  </>
}

const InfoButtonIcon: FC<InfoButtonIconProps> = ({ onClick }) => {
  return <Button
    className="tolerance-check-box-icon"
    data-qa="data-qa-tolerance-info-btn"
    justIcon
    onClick={onClick}
    color="transparent"
  >
    <IconFactory
      iconName="info"
      className="upload-form-tolerance-info-icon"
    />
  </Button>
}

const ToleranceCheckBox: FC<IProps> = ({
  toleranceIncluded,
  onClick,
  onChange,
  onSelectToleranceClass,
  renderButtonComponents = false,
  is2dUpload
}) => {
  const [customToleranceValue, setCustomToleranceValue] = useState<string>('')
  const [currentToleranceIncluded, setCurrentToleranceIncluded] =
    useState<boolean>(false)
  useEffect(() => {
    setCurrentToleranceIncluded(!!toleranceIncluded)
    if (toleranceIncluded && customToleranceValue === '') {
      setCustomToleranceValue(ToleranceClass.TOLERANCE_CLASS_MEDIUM)
    }
  }, [toleranceIncluded])

  if (renderButtonComponents) {
    return <div>
      <Flexbox
        className="tolerance-check"
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <Flexbox className="tolerance-check-box" alignItems="center">
          <CheckBoxTolerance
            currentToleranceIncluded={currentToleranceIncluded}
            onChange={onChange}
            is2dUpload={is2dUpload}
          />
        </Flexbox>
        <InfoButtonIcon onClick={onClick} />
      </Flexbox>
      {
        toleranceIncluded && (
          <Flexbox
            className="tolerance-check-box_items"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            flexDirection="row"
          >
            <ToleranceClassMenu
              renderButtonComponents={renderButtonComponents}
              toleranceIncluded={toleranceIncluded}
              selectedToleranceClass={customToleranceValue}
              onSelectToleranceClass={onSelectToleranceClass}
            />
          </Flexbox>
        )
      }
    </div>
  }

  return (
    <Flexbox
      className="tolerance-check"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
    >
      <Flexbox className="tolerance-check-box" alignItems="center">
        <CheckBoxTolerance
          currentToleranceIncluded={currentToleranceIncluded}
          onChange={onChange}
          is2dUpload={is2dUpload}
        />
        <ToleranceClassMenu
          toleranceIncluded={toleranceIncluded}
          selectedToleranceClass={customToleranceValue}
          onSelectToleranceClass={onSelectToleranceClass}
        />
        {getString('UPLOAD_PROJECT_MATERIAL_TOLERANCE_ENDING')}
      </Flexbox>
      <InfoButtonIcon onClick={onClick} />
    </Flexbox>
  )
}

export default memo(OverrideComponentHOC(ToleranceCheckBox))
