import React, { Component } from 'react'

import IconFactory from '../StarIcon/IconFactory'
import Pagination from '../thirdParty/CreativeTim/components/Pagination/Pagination'
import { getString } from 'Services/Strings/StringService'

const NUMBER_OF_PAGES_TO_SEE = 3

class CastorPagination extends Component {
	onNextClick = () => {
		if (this.props.onNextClick) {
			this.props.onNextClick()
		}
	}

	onPrevClick = () => {
		if (this.props.onPrevClick) {
			this.props.onPrevClick()
		}
	}

	onPageClick = page => {
		if (this.props.onPageClick) {
			this.props.onPageClick(page)
		}
	}

	pageObject = (text, active, linkTo, pageNumber) => {
		return {
			text,
			active,
			onClick: () => this.onPageClick(pageNumber),
			linkTo: linkTo || undefined
		}
	}

	pushPagesHelper = (pages, starterIndex, endIndex) => {
		const { pageNumber, linkTo } = this.props
		for (let i = starterIndex; i <= endIndex; i++) {
			pages.push(this.pageObject(i, pageNumber === i, linkTo && linkTo(i), i))
		}
	}

	pushFirstPages = (pages, lastPageNumber) => {
		this.pushPagesHelper(pages, 1, NUMBER_OF_PAGES_TO_SEE)
		this.renderLastDots(pages, lastPageNumber)
	}

	pushLastPages = (pages, lastPageNumber) => {
		this.renderFirstDots(pages)
		this.pushPagesHelper(
			pages,
			lastPageNumber - NUMBER_OF_PAGES_TO_SEE + 1,
			lastPageNumber
		)
	}

	renderAllPages = (pages, lastPageNumber) => {
		this.pushPagesHelper(pages, 1, lastPageNumber)
	}

	pushMiddlePages = (pages, pageTolerance, lastPageNumber) => {
		const { pageNumber, linkTo } = this.props
		this.renderFirstDots(pages, pageTolerance)
		for (let i = pageTolerance; i >= -pageTolerance; i--) {
			pages.push(
				this.pageObject(
					pageNumber - i,
					i === 0,
					linkTo && linkTo(pageNumber - i),
					pageNumber - i
				)
			)
		}
		this.renderLastDots(pages, lastPageNumber)
	}

	renderFirstDots = (pages, pageTolerance) => {
		const { pageNumber, linkTo } = this.props
		if (!pageTolerance || pageNumber - pageTolerance > 1) {
			pages.push(
				this.pageObject(
					'...',
					false,
					linkTo && linkTo(Math.floor(pageNumber / 2)),
					Math.floor(pageNumber / 2)
				)
			)
		}
	}

	renderLastDots = (pages, lastPageNumber) => {
		const { pageNumber, linkTo } = this.props
		pages.push(
			this.pageObject(
				'...',
				false,
				linkTo && linkTo(Math.ceil((pageNumber + lastPageNumber) / 2)),
				Math.ceil((pageNumber + lastPageNumber) / 2)
			)
		)
	}

	renderPages = () => {
		const { pageNumber, total, limit } = this.props,
			lastPageNumber = Math.ceil(total / limit),
			pageTolerance = Math.floor(NUMBER_OF_PAGES_TO_SEE / 2)
		let pages = []
		if (NUMBER_OF_PAGES_TO_SEE >= lastPageNumber) {
			this.renderAllPages(pages, lastPageNumber)
		} else {
			if (pageNumber <= pageTolerance) {
				this.pushFirstPages(pages, lastPageNumber)
			} else if (pageNumber >= lastPageNumber - pageTolerance) {
				this.pushLastPages(pages, lastPageNumber)
			} else {
				this.pushMiddlePages(pages, pageTolerance, lastPageNumber)
			}
		}

		return pages
	}

	renderCurrentShowing = (showingFrom, showingTo) => {
		if (!showingFrom || !showingTo) {
			return []
		}
		return [
			{
				text: getString('SHOWING_PAGINATION').format(showingFrom, showingTo),
				qaDataElementName: 'data-qa-pagination-current-showing'
			}
		]
	}

	render() {
		const {
				showPagination,
				pageNumber,
				isLastPage,
				linkTo,
				className,
				showingFrom,
				showingTo
			} = this.props,
			nextPagNumber = pageNumber + 1,
			prevPageNumber = pageNumber - 1
		if (!showPagination) {
			if (!showingFrom || !showingTo) {
				return <div />
			}
			return (
				<Pagination
					classes={className}
					pages={[...this.renderCurrentShowing(showingFrom, showingTo)]}
				/>
			)
		}
		return (
			<Pagination
				classes={className}
				pages={[
					...this.renderCurrentShowing(showingFrom, showingTo),
					{
						text: <IconFactory iconName="prevArrow" />,
						qaDataElementName: 'data-qa-pagination-prev-arrow',
						disabled: pageNumber === 1,
						onClick: this.onPrevClick,
						linkTo: linkTo ? linkTo(prevPageNumber) : undefined
					},
					...this.renderPages(),
					{
						text: <IconFactory iconName="nextArrow" />,
						qaDataElementName: 'data-qa-pagination-next-arrow',
						disabled: isLastPage,
						onClick: this.onNextClick,
						linkTo: linkTo ? linkTo(nextPagNumber) : undefined
					}
				]}
			/>
		)
	}
}

export default CastorPagination
