import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { isArray } from 'lodash'

import {
	CO2_CARBON_CREDIT_COST_CHANGED,
	CO2_HOLD_FACTOR_CHANGED,
	CO2_MATERIAL_DELETED,
	CO2_NEW_MATERIAL_ADDED,
	CO2_NEW_PRINTER_MATERIAL_ADDED,
	CO2_NEW_PRINTER_TYPICAL_KWH_ADDED,
	CO2_NEW_TM_TYPICAL_KWH_ADDED,
	CO2_PER_KG_MATERIAL_CHANGED,
	CO2_PER_KG_PRINTER_MATERIAL_CHANGED,
	CO2_PER_KW_CHANGED,
	CO2_PRINTER_MATERIAL_DELETED,
	CO2_PRINTER_TYPICAL_KWH_DELETED,
	CO2_TM_TYPICAL_KWH_DELETED,
	CO2_USER_SETTINGS_CARBON_CHANGED,
	CO2_USER_SETTINGS_CARBON_CREDIT_COST,
	CO2_USER_SETTINGS_DATA_LOADING,
	CO2_USER_SETTINGS_DISPOSE,
	CO2_USER_SETTINGS_HOLD,
	CO2_USER_SETTINGS_PU_AMOUNT,
	CO2_USER_SETTINGS_PU_YEARS,
	EMISSION_TBL_DATA_ARRANGED,
	END_OF_LIFE_DISPOSE_FACTOR_CHANGED,
	GET_CO2_MATERIAL,
	HANDLE_NOTIFICATION,
	PART_LIFE_TIME_CHANGED,
	PRINTER_TYPICAL_KWH_CHANGED,
	TM_TYPICAL_KWH_CHANGED,
	YEARLY_SAVED_AMOUNT_CHANGED
} from '../../../../global actions/types'
import { ParameterTypes } from './ParameterTypes'
import {
	createNewMaterialCo2Calculation,
	createNewPrinterMaterialCo2Calculation,
	createNewPrinterTypicalKwh,
	deleteCycleTypicalKwh,
	deleteMaterialCo2Calculation,
	deletePrinterMaterialCo2Calculation,
	getCustomizationSettings,
	getCycleCo2Calculation,
	getMaterialCo2Calculation,
	getPrinterMaterialCo2Calculation,
	getPrinterTypicalKwh,
	getTypicalKwhList,
	updateCustomizationPrinterSetting,
	updateTmTypicalKwh,
	updateUserCarbonCreditCost,
	updateUserCo2HoldFactor,
	updateUserCo2PerKW,
	updateUserEndOfLifeDisposeFactor,
	updateUserProductUsePartLife,
	updateUserProductUseYearlyAmount
} from 'Services/Network'
import { REQUEST_FAILED, SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const loading = 'Loading'
const resetLoading = 'Reset'

export const fetchUserCo2Data = () => {
	return async (dispatch: any) => {
		try {
			const materialList = await getMaterialCo2Calculation()
			const printersList = await getPrinterMaterialCo2Calculation()
			const printersTypicalkwhList = await getPrinterTypicalKwh()
			const cycleList = await getCycleCo2Calculation()
			const typicalKwhList = await getTypicalKwhList()
			const customizationSettings = await getCustomizationSettings()
			const settingsData = customizationSettings?.data

			dispatch({
				type: GET_CO2_MATERIAL,
				payload: {
					materialList: materialList?.data.materialList,
					cycleList: cycleList?.data.cycleList,
					typicalKwhList: typicalKwhList?.data.typicalKwhList,
					printersList: printersList?.data.printersList,
					printersTypicalkwhList:
						printersTypicalkwhList?.data?.printersTypicalkwhList
				}
			})
			dispatch({
				type: EMISSION_TBL_DATA_ARRANGED,
				payload: {
					settingsData,
					userSettings: settingsData?.userSettings
				}
			})
		} catch (error) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: 'error in fetchUserCo2Data'
				}
			})
		}
	}
}

export const onCo2PerKgMaterialChange = (value: number) => {
	return {
		type: CO2_PER_KG_MATERIAL_CHANGED,
		payload: { value }
	}
}

export const onPrinterTypicalkwhChange = (value: number) => {
	return {
		type: PRINTER_TYPICAL_KWH_CHANGED,
		payload: { value }
	}
}

export const onTmTypicalkwhChange = (value: number) => {
	return {
		type: TM_TYPICAL_KWH_CHANGED,
		payload: { value }
	}
}

export const onCo2PerKgPrinterMaterialChange = (value: number) => {
	return {
		type: CO2_PER_KG_PRINTER_MATERIAL_CHANGED,
		payload: { value }
	}
}

export const onCo2PerKwChange = (value: number) => {
	return {
		type: CO2_PER_KW_CHANGED,
		payload: { value }
	}
}
export const onYearlyAmountChange = (value: number) => {
	return {
		type: YEARLY_SAVED_AMOUNT_CHANGED,
		payload: { value }
	}
}
export const onPartLifeTimeChange = (value: number) => {
	return {
		type: PART_LIFE_TIME_CHANGED,
		payload: { value }
	}
}
export const onDisposeFactorChange = (value: number) => {
	return {
		type: END_OF_LIFE_DISPOSE_FACTOR_CHANGED,
		payload: { value }
	}
}
export const onHoldFactorChange = (value: number | string) => {
	return {
		type: CO2_HOLD_FACTOR_CHANGED,
		payload: { value }
	}
}
export const onCarbonCreditCostChange = (value: number | string) => {
	return {
		type: CO2_CARBON_CREDIT_COST_CHANGED,
		payload: { value }
	}
}

export const onSetUserCustomizationSettings = (
	value: number,
	parameter: string,
	reset: boolean = false
) => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: CO2_USER_SETTINGS_DATA_LOADING,
				payload: {
					[`${parameter}${reset ? resetLoading : ''}${loading}`]: true
				}
			})

			switch (parameter) {
				case ParameterTypes.carbon: {
					const { data: { userSettings: { co2PerKW = value } = {} } = {} } =
						await updateUserCo2PerKW(value)
					dispatch({
						type: CO2_USER_SETTINGS_CARBON_CHANGED,
						payload: { value: co2PerKW }
					})
					break
				}
				case ParameterTypes.yearlyAmount: {
					await updateUserProductUseYearlyAmount(value)
					dispatch({
						type: CO2_USER_SETTINGS_PU_AMOUNT,
						payload: { value }
					})
					break
				}
				case ParameterTypes.partLife: {
					await updateUserProductUsePartLife(value)
					dispatch({
						type: CO2_USER_SETTINGS_PU_YEARS,
						payload: { value }
					})
					break
				}
				case ParameterTypes.disposeFactor: {
					const {
						data: { userSettings: { disposeFactor = value } = {} } = {}
					} = await updateUserEndOfLifeDisposeFactor(value)
					dispatch({
						type: CO2_USER_SETTINGS_DISPOSE,
						payload: { value: disposeFactor }
					})
					break
				}
				case ParameterTypes.holdFactor: {
					const { data: { userSettings: { holdFactor = value } = {} } = {} } =
						await updateUserCo2HoldFactor(value)

					dispatch({
						type: CO2_USER_SETTINGS_HOLD,
						payload: { value: holdFactor }
					})
					break
				}
				case ParameterTypes.carbonCreditCost: {
					const {
						data: { userSettings: { carbonCreditCost = value } = {} } = {}
					} = await updateUserCarbonCreditCost(value)

					dispatch({
						type: CO2_USER_SETTINGS_CARBON_CREDIT_COST,
						payload: { value: carbonCreditCost }
					})
					break
				}
			}

			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('DATA_WAS_UPDATED')
				}
			})
			dispatch({
				type: CO2_USER_SETTINGS_DATA_LOADING,
				payload: {
					[`${parameter}${reset ? resetLoading : ''}${loading}`]: false
				}
			})
		} catch (error) {
			dispatch({
				type: CO2_USER_SETTINGS_DATA_LOADING,
				payload: {
					[`${parameter}${reset ? resetLoading : ''}${loading}`]: false
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: REQUEST_FAILED
				}
			})
		}
	}
}

export const onSetNewMaterial = (newMaterial: any) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			await createNewMaterialCo2Calculation(newMaterial)
			dispatch({
				type: CO2_NEW_MATERIAL_ADDED,
				payload: { newMaterial }
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: error.internalMessage || error.validationMessage
				}
			})
		}
	}
}

export const onDeleteMaterial = (materialToDelete: any) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			await deleteMaterialCo2Calculation(materialToDelete)
			dispatch({
				type: CO2_MATERIAL_DELETED,
				payload: { materialToDelete }
			})
		} catch (error) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: 'error in onDeleteMaterial'
				}
			})
		}
	}
}

export const onDeletePrinterMaterial = (materialToDelete: any) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			await deletePrinterMaterialCo2Calculation(materialToDelete)
			dispatch({
				type: CO2_PRINTER_MATERIAL_DELETED,
				payload: { materialToDelete }
			})
		} catch (error) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: 'error in onDeletePrinterMaterial'
				}
			})
		}
	}
}

export const onDeletePrinterTypicalKwh = (printerToDelete: any) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const userPrinterResponse = await updateCustomizationPrinterSetting(
				printerToDelete
			)
			const printersTypicalkwhList =
				userPrinterResponse.data.userPrinterSettingsList
			dispatch({
				type: CO2_PRINTER_TYPICAL_KWH_DELETED,
				payload: { printersTypicalkwhList }
			})
		} catch (error) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: 'error in onDeletePrinterTypicalKwh'
				}
			})
		}
	}
}

export const onDeleteCycleKwh = (cycleToDelete: any) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			await deleteCycleTypicalKwh(cycleToDelete)
			dispatch({
				type: CO2_TM_TYPICAL_KWH_DELETED,
				payload: { cycleToDelete }
			})
		} catch (error) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: 'error in onDeleteCycleKwh'
				}
			})
		}
	}
}

export const onSetNewPrinterMaterial = (newPrinterMaterial: any) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			await createNewPrinterMaterialCo2Calculation(newPrinterMaterial)
			dispatch({
				type: CO2_NEW_PRINTER_MATERIAL_ADDED,
				payload: { newPrinterMaterial }
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: error.internalMessage || error.validationMessage
				}
			})
		}
	}
}

export const onSetNewPrinterTypicalkwh = (newPrinter: any) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const newPrinterTypicalKwhResponse = await createNewPrinterTypicalKwh(
				newPrinter
			)
			const printersTypicalkwhList =
				newPrinterTypicalKwhResponse.data.userPrinterSettingsList
			dispatch({
				type: CO2_NEW_PRINTER_TYPICAL_KWH_ADDED,
				payload: { printersTypicalkwhList }
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: error.internalMessage || error.validationMessage
				}
			})
		}
	}
}

export const onUpdateTypicalkwhTm = (cycle: any) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const response = await updateTmTypicalKwh(cycle)
			const { cycleResult } = response.data
			dispatch({
				type: CO2_NEW_TM_TYPICAL_KWH_ADDED,
				payload: { cycle: isArray(cycleResult) ? cycleResult[0] : cycleResult }
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: error.internalMessage || error.validationMessage
				}
			})
		}
	}
}
