export type requestMethodType = 'get' | 'post' | 'put' | 'delete'

const throttled: {
  get: Record<string, boolean>
  post: Record<string, boolean>
  put: Record<string, boolean>
  delete: Record<string, boolean>
} = { get: {}, post: {}, put: {}, delete: {} }

/**
 * Add the throttle name to the `throttled` object, and set timeout to change it's value to false
 * @param {string} throttleName Name for the throttle
 * @param {number} throttleTime Amount of time to throttle
 * @param {string} requestMethod  Request method name (get, post...)
 */
const setThrottle = (
  throttleName: string,
  throttleTime: number,
  requestMethod: requestMethodType
) => {
  throttled[requestMethod][throttleName] = true
  setTimeout(() => {
    throttled[requestMethod][throttleName] = false
  }, throttleTime)
}

/**
 * Checks if network call is being throttled at the moment
 * @param {{name: string; time: number}|undefined} throttle Optional. Name and time of the network request
 * @param {string} requestMethod Request method name (get, post...)
 * @returns True if it's being throttled. Else, false
 */
export const isRequestThrottled = (
  throttle: { name: string; time: number } | undefined,
  requestMethod: requestMethodType
) => {
  if (!throttle?.time || !throttle?.name) {
    return false
  }
  if (throttled[requestMethod][throttle.name]) {
    return true
  }
  // We have throttle object but it's value is false - the timeout has finished.
  setThrottle(throttle.name, throttle.time, requestMethod)
  return false
}
