import React, { FC, memo } from 'react'
import ButtonWithLoader from '../ButtonWithLoader'
import IconFactory from '../StarIcon/IconFactory'

import './DataTable.scss'

interface Props {
  text: any
  data: any
  className: string
}

const DataTableButtonWithIcon: FC<Props> = ({
  text,
  data: {
    onClick,
    buttonClassName,
    buttonLoading = false,
    iconName,
    iconClassName,
    textClassName,
    disabled
  },
  className
}) => (
  <ButtonWithLoader
    disabled={disabled}
    className={`${buttonClassName} ${className}`}
    onClick={onClick}
    loading={buttonLoading}
  >
    <IconFactory iconName={iconName} className={iconClassName} />
    <div className={textClassName}>{text}</div>
  </ButtonWithLoader>
)

export default memo(DataTableButtonWithIcon)
