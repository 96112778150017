import { getString } from "Services/Strings/StringService"

export const parseErrorMessage = (error: string) => {
	switch (error) {
		case 'access_denied':
			return getString('ACCESS_DENIED_ERROR')
		case 'invalid_request':
			return getString('INVALID_REQUEST_ERROR')
		default:
			return getString('AUTH_REQUEST_ERROR')
	}
}
