import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { PART_COST } from './constants'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import { ROLL_BACK_TO_FIRST_STEP } from 'global actions/types'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import { getString } from 'Services/Strings/StringService'

import './CustomizeCostingFunctionEditor.scss'

const CustomizeCostingFunctionEditor: FC = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		// on component unmount
		return () => {
			dispatch({
				type: ROLL_BACK_TO_FIRST_STEP,
				payload: { id: PART_COST }
			})
		}
	}, [dispatch])

	return (
		<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
			<h3 className="costing-function-editor--header">
				{getString('COSTING_FUNCTION_EDITOR_TITLE')}
			</h3>
			<div className="costing-function-editor--body">
				<Step1 />
				<Step2 />
				<Step3 />
			</div>
		</NavBarAndMaterial>
	)
}

export default CustomizeCostingFunctionEditor
