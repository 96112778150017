import React, { FC, memo } from 'react'
import { getString } from '../../../Services/Strings/StringService'
import CastorPartResultIndex from '../CastorPartResultIndex/CastorPartResultIndex'
import PieChart, { IChartSeriesData } from '../PieChart'
import NoUploadedParts from './NoUploadedParts'

import './StatsUploadedParts.scss'

interface IProps {
  totalUploadedParts: number
  uploadedPartsChartData: IChartSeriesData[]
  uploadedPartsChartIndexes: any[]
}

const StatsUploadedParts: FC<IProps> = ({
  totalUploadedParts,
  uploadedPartsChartData,
  uploadedPartsChartIndexes
}) => {
  if (!totalUploadedParts) {
    return <NoUploadedParts />
  }
  return (
    <div className="stats-uploaded-parts" data-qa="data-qa-uploaded-parts-stats">
      <PieChart chartData={uploadedPartsChartData} maxWidth={270} />
      <div className="stats-uploaded-parts--indexes">
        <div className="stats-uploaded-parts--indexes--title">
          {getString('USER_PANEL_PART_RESULTS_UPLOADED_INDEX_TITLE').format(
            totalUploadedParts
          )}
        </div>
        <CastorPartResultIndex indexes={uploadedPartsChartIndexes} />
      </div>
    </div>
  )
}

export default memo(StatsUploadedParts)
