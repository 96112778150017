import React, { FC } from 'react'

import { LineBreak } from 'Scenes/Components/PdfComponents/LineBreak'
import { PROJECT_SUMMARY_PDF_ROW_NAMES } from 'Services/Strings'

type IProps = {
	projectName: string
	printabilityData: Record<string, number>
	userDetails: Record<string, number>
	totalPartsCount: number
}

const ProjectSummaryPDF: FC<IProps> = ({
	projectName,
	printabilityData,
	userDetails,
	totalPartsCount
}) => {
	return (
		<>
			<div className="export-pdf--project-summary">
				<div className="export-pdf--project-summary--row">
					{PROJECT_SUMMARY_PDF_ROW_NAMES.userName}:{' '}
					<span>{userDetails.name}</span>
				</div>
				<div className="export-pdf--project-summary--row">
					{PROJECT_SUMMARY_PDF_ROW_NAMES.company}:{' '}
					<span>{userDetails.company}</span>
				</div>
				<div className="export-pdf--project-summary--row">
					{PROJECT_SUMMARY_PDF_ROW_NAMES.email}:{' '}
					<span>{userDetails.email}</span>
				</div>
				<div className="export-pdf--project-summary--row">
					{PROJECT_SUMMARY_PDF_ROW_NAMES.projectName}:{' '}
					<span>{projectName}</span>
				</div>
				<div className="export-pdf--project-summary--row">
					{PROJECT_SUMMARY_PDF_ROW_NAMES.numberOfParts}:{' '}
					<span>{totalPartsCount}</span>
				</div>
				<div className="export-pdf--project-summary--row">
					{PROJECT_SUMMARY_PDF_ROW_NAMES.printableParts}:{' '}
					<span>{printabilityData.printable}</span>
				</div>
				<div className="export-pdf--project-summary--row">
					{PROJECT_SUMMARY_PDF_ROW_NAMES.printableWithChanges}:{' '}
					<span>{printabilityData.borderline}</span>
				</div>
				<div className="export-pdf--project-summary--row">
					{PROJECT_SUMMARY_PDF_ROW_NAMES.unprintableParts}:{' '}
					<span>{printabilityData.notPrintable}</span>
				</div>
			</div>
			<LineBreak />
		</>
	)
}

export default ProjectSummaryPDF
