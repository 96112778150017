import React, { FC, memo } from 'react'

import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { logo } = getTheme()

const Greeting: FC = () => {
	return (
		<div className="start-screen--greeting">
			{getString('ONBOARDING_START_SCREEN_GREETING')}
			<img className="start-screen--greeting--logo" src={logo} alt="logo" />
		</div>
	)
}

export default memo(Greeting)
