import React, { memo } from 'react'

import { toFixedOnlyIfNeeded } from '../../../../../../../../Services/global/toFixedOnlyIfNeeded'
import {
	PART_ANALYSIS_DIMENSIONS,
	PART_COUNT_IN_ASSEMBLY,
	PART_VOLUME
} from '../../../../../../../../Services/Strings'
import DetailWithTitle from '../../../../../../../Components/DetailWithTitle/DetailWithTitle'
import Flexbox from 'Scenes/Components/FlexBox'

import '../SolutionPartDetails.scss'
import './PartDetails.scss'

interface IProps {
	width: number
	depth: number
	height: number
	volume: number
	countInAssembly: number
}

const PartDetails: React.FC<IProps> = ({
	width,
	depth,
	height,
	volume,
	countInAssembly
}: IProps) => {
	const renderCountInAssemblyDetail = () => {
		if (!countInAssembly || countInAssembly <= 1) {
			return <div></div>
		}
		return (
			<>
				<DetailWithTitle
					title={PART_COUNT_IN_ASSEMBLY}
					body={`${countInAssembly}`}
					className="part-detail"
					titleClassName="part-detail-title"
					bodyClassName="part-detail-body"
				/>
			</>
		)
	}

	return (
		<Flexbox width="100%" flexDirection="column">
			<DetailWithTitle
				title={PART_ANALYSIS_DIMENSIONS}
				body={`W ${toFixedOnlyIfNeeded(width)} x D ${toFixedOnlyIfNeeded(
					depth
				)} x H ${toFixedOnlyIfNeeded(height)}`}
				className="part-detail"
				titleClassName="part-detail-title"
				bodyClassName="part-detail-body"
			/>
			<DetailWithTitle
				title={PART_VOLUME}
				body={`${volume?.toFixed()}`}
				className="part-detail"
				titleClassName="part-detail-title"
				bodyClassName="part-detail-body"
			/>
			{renderCountInAssemblyDetail()}
		</Flexbox>
	)
}

export default memo(PartDetails)
