import React, { memo, FC } from 'react'
import { Button } from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {default as classNames} from 'classnames'
import { getString } from '../../../../../../../../../../../Services/Strings/StringService'

import IconFactory from "../../../../../../../../../../Components/StarIcon/IconFactory";
import "./CostAnalysisTableButtons.scss";

const ButtonTSX: any = Button

interface IProps {
  openSwitch: boolean
  show: boolean
  onChange: Function
}

interface IButton {
  onChange: Function,
  icon: string,
  name: string,
  check: boolean,
  active: boolean,
  iconClassName: string
}

const ButtonToggle: FC<IButton> = ({ onChange, icon, name, check, active, iconClassName }) => {
  return (
    <ButtonTSX
      className={classNames("cost-analysis-table-buttons--button", {
        'active': active
      })}
      onClick={() => onChange({target: { checked: check }})}
    >
      <div className="cost-analysis-table-buttons--button__content">
        <IconFactory
          className={classNames("cost-analysis-table-buttons--icon", iconClassName, {
            'active': active
          })}
          iconName={icon}
        />
        <span>
          {name}
        </span>
      </div>
    </ButtonTSX>
  )
}

const CostAnalysisTableButtons: FC<IProps> = ({
   openSwitch,
   onChange,
   show
 }) => {
  if (!show) {
    return <div />
  }

  const activeCheck = true

  return (
    <div className="cost-analysis-table-buttons">
      <ButtonGroup
        className="cost-analysis-table-buttons--group"
        color="primary"
        aria-label="outlined primary button group">
        <ButtonToggle
          iconClassName="graphic-icon"
          onChange={onChange}
          active={!openSwitch}
          name={getString('GRAPH_TOGGLE')}
          icon='graphic'
          check={!activeCheck}
        />
        <ButtonToggle
          iconClassName=''
          onChange={onChange}
          active={openSwitch}
          name={getString('GRID_TOGGLE')}
          icon='grid'
          check={activeCheck}
        />
      </ButtonGroup>
    </div>
  )
}

export default memo(CostAnalysisTableButtons)
