import { FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import cx from 'classnames'
import { capitalize, startCase, toNumber } from 'lodash'

import {
	KEY_2d,
	KEY_3d,
	METADATA,
	TAB_2d_INDEX,
	TAB_3d_INDEX,
	TAB_METADATA_INDEX
} from './constants'
import UploadBlocks from './UploadBlocks/index'
import QuickUploadFiles from './UploadBlocks/QuickUploadFiles'
import UploadFiles from './UploadBlocks/UploadFiles'
import {
	BOMFileSelected,
	getUserUploadActivity,
	materialRadioChanged,
	removeFiles,
	setupUploadPage
} from './UploadProjectActions'
import { checkUserSubscriptionAlert } from 'global actions'
import CastorInfoScreen from 'Scenes/Components/CastorInfoScreen/CastorInfoScreen'
import CastorSubscriptionAlert from 'Scenes/Components/CastorSubscriptionAlert'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import {
	onTourStop,
	onUpdateTourSteps
} from 'Scenes/Components/TakeATour/TakeATourActions'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import {
	BOMRadioValue,
	chosenMaterialRadioValue,
	drawingFiles
} from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { projectRoute, uploadProjectRoute } from 'Services/routeFuncs'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import './index.scss'
import './UploadProjectForm.scss'

const { preSelectedType } = getTheme()

const NewUploadProject: FC = () => {
	const { state } = useLocation() as { state?: Record<string, boolean> }
	const history = useHistory()
	const [selectedType, setSelectedType] = useState<string | null>(KEY_3d)
	const [uploadTabIndex, setUploadTabIndex] = useState<number>(
		selectedType === KEY_3d ? TAB_3d_INDEX : TAB_2d_INDEX
	)
	const is2dUpload = selectedType === KEY_2d
	const isDrawingFeatureOn = Feature.isFeatureOn(
		FeatureComponentId.DRAWING_ANALYSIS
	)
	const isQuickCADUploadFeatureOn = Feature.isFeatureOn(
		FeatureComponentId.QUICK_CAD_UPLOAD
	)
	const isLightUserLogoutOn = Feature.isFeatureOn(
		FeatureComponentId.LIGHT_USER_LOGOUT
	)

	const { radioButtonSelected, userRemainingPart, pageName, userDateExpired } =
		useSelector((state: RootStateOrAny) => state.uploadProject)
	const {
		filters,
		uploadProjectSelectedRadioButton,
		defaultUploadProjectSelectedRadioButton,
		pages,
		pagesVisited,
		userDetails,
		userOnTrial,
		maxAllowedUploadProjects,
		roles,
		isOrganizationOwnerExist
	} = useSelector((state: RootStateOrAny) => state.user)
	const isLightUser = roles.includes(UserRole.LIGHT)
	// prevent banner blinking before user is updated in store
	const isSuperAdmin =
		roles.length > 0 ? roles.includes(UserRole.SUPER_ADMIN) : undefined

	const { projects } = useSelector(
		(state: RootStateOrAny) => state.userHome.projects
	)
	const userName = startCase(capitalize(userDetails?.name || ''))
	const navBarTitle = isQuickCADUploadFeatureOn
		? getString('QUICK_CAD_UPLOAD_NAVBAR').format(
				userName ? ' ' + userName : ''
		  )
		: getString('NEW_UPLOAD_NAVBAR').format(userName ? ' ' + userName : '')

	const dispatch = useDispatch()

	useEffect(() => {
		setUploadTabIndex(
			selectedType === KEY_3d
				? TAB_3d_INDEX
				: selectedType === METADATA
				? TAB_METADATA_INDEX
				: TAB_2d_INDEX
		)
		dispatch(removeFiles(null))
	}, [selectedType])

	useEffect(() => {
		const disableUploadNavbar =
			projects?.length >= toNumber(maxAllowedUploadProjects) &&
			toNumber(maxAllowedUploadProjects) === 1

		//need to redirect to the project page if upload is disable
		if (disableUploadNavbar) {
			history.push(projectRoute(projects[0].id))
		}

		dispatch(getUserUploadActivity())
		dispatch(onUpdateTourSteps(pages, pageName, true))
		dispatch(
			setupUploadPage(
				filters,
				uploadProjectSelectedRadioButton,
				preSelectedType || defaultUploadProjectSelectedRadioButton,
				pagesVisited,
				pageName
			)
		)

		return () => {
			dispatch(onTourStop())
		}
	}, [])

	useEffect(() => {
		dispatch(
			checkUserSubscriptionAlert(
				isSuperAdmin,
				isLightUser,
				userDateExpired,
				userRemainingPart,
				userOnTrial,
				userDetails.email,
				isOrganizationOwnerExist
			)
		)
	}, [userRemainingPart])

	const updateSelectedType = (type: string) => {
		let newTabIndex

		switch (type) {
			case KEY_3d:
				newTabIndex = TAB_3d_INDEX
				break
			case METADATA:
				newTabIndex = TAB_METADATA_INDEX
				break
			default:
				newTabIndex = TAB_2d_INDEX
				break
		}

		// select radio button if Tab was changed
		if (uploadTabIndex !== newTabIndex) {
			if (
				radioButtonSelected === drawingFiles &&
				uploadTabIndex === TAB_2d_INDEX
			) {
				dispatch(materialRadioChanged(BOMRadioValue, null))
				dispatch(materialRadioChanged(drawingFiles, null))
			}

			if (radioButtonSelected === BOMRadioValue) {
				dispatch(materialRadioChanged(drawingFiles, null))
			}

			if (type === METADATA) {
				dispatch(BOMFileSelected(null))
				dispatch(materialRadioChanged(null, null))
			}

			if (radioButtonSelected === chosenMaterialRadioValue) {
				dispatch(materialRadioChanged(chosenMaterialRadioValue, null))
			}

			if (
				radioButtonSelected === BOMRadioValue &&
				newTabIndex === TAB_3d_INDEX
			) {
				dispatch(BOMFileSelected(null))
				dispatch(materialRadioChanged(BOMRadioValue, null))
			}
		}

		setSelectedType(type)
	}

	if (state?.showCastorLightWarning) {
		return (
			<CastorInfoScreen
				iconType="warning"
				buttonText={getString('LIGHT_USER_WARNING_BUTTON_TEXT')}
				descriptionText={getString('LIGHT_USER_WARNING_DESCRIPTION')}
				onButtonClick={() => history.push(uploadProjectRoute())}
				wrapperClassName="new-upload-project__info-screen"
			/>
		)
	}

	return (
		<div className="new-upload-project" data-qa={`data-qa-new-upload-project`}>
			<NavBarAndMaterial
				fullWidth={true}
				title={navBarTitle}
				showLogOutLink={isLightUserLogoutOn && !userName}
			>
				<CastorSubscriptionAlert />
				<div
					id="upload-project-form-title"
					className={cx('parent', {
						'quick-upload-parent': isQuickCADUploadFeatureOn
					})}
				>
					{isQuickCADUploadFeatureOn ? (
						<QuickUploadFiles />
					) : (
						<>
							<UploadBlocks
								isDrawingFeatureOn={isDrawingFeatureOn}
								selectedType={selectedType}
								is2dUpload={is2dUpload}
								uploadTabIndex={uploadTabIndex}
								setSelectedType={updateSelectedType}
							/>
							<UploadFiles
								isDrawingFeatureOn={isDrawingFeatureOn}
								selectedType={selectedType}
								is2dUpload={is2dUpload}
							/>
						</>
					)}
				</div>
			</NavBarAndMaterial>
		</div>
	)
}

export default memo(NewUploadProject)
