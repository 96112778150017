import React, { FC, memo } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'
import cx from 'classnames'

import { progressFlex, progressFull } from './loaderStyles.css.js'
import { getTheme } from 'themes/getTheme'

import './Loader.scss'

const { loaderImage, colors } = getTheme()
const loader = require('../../assets/img/loader.gif')

const renderMessage = (message: string | undefined) => {
	if (message) {
		return <h4>{message}</h4>
	}
	return <div />
}

const Loader: FC<any> = ({ load, message, ...rest }) => {
	if (!load) {
		return <></>
	}

	const {
		size = 80,
		showFlex = true,
		styles = {},
		wrapperClassName,
		fullPage = false
	} = rest

	let flexBoxStyle = {} as Record<string, unknown>

	if (fullPage) {
		flexBoxStyle = { ...progressFull, backgroundColor: colors.loaderBackground }
	} else if (showFlex) {
		flexBoxStyle = progressFlex
	}

	return (
		<Flexbox
			flexDirection="column"
			style={flexBoxStyle}
			className={cx(wrapperClassName)}
		>
			<div
				className={cx({
					'loader-with-text': !!message
				})}
			>
				<div
					className="circle-load"
					style={{
						width: size,
						...styles
					}}
				>
					<img src={loaderImage || loader} alt="loading..." />
				</div>
				{!!message && renderMessage(message)}
			</div>
		</Flexbox>
	)
}

export default memo(Loader)
