import { Action, ActionWithPayload } from '../../global actions/ActionModels'

export interface IInstanceStateExtractorPayload {
	id: number
}

export interface IStateExtractorState {
	[name: string]: { [sid: number]: any }
}

const initialState: IStateExtractorState = {
	states: {}
}
export default (
	reducer: (
		state: any,
		{ type, payload }: Action<IInstanceStateExtractorPayload>
	) => any
) => {
	return (
		states: IStateExtractorState,
		payloadAndType: ActionWithPayload<IInstanceStateExtractorPayload>
	) => {
		if (payloadAndType && payloadAndType.payload) {
			const id = payloadAndType.payload.id || 0

			const originalState = states.states[id]

			states.states[id] = reducer(originalState, payloadAndType)
			return { ...states }
		}
		return initialState
	}
}
