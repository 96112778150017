import { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as CostAndLeadActions from './CostAndLeadActions'
import {
  CustomInput,
  Danger
} from '../../../Components/thirdParty/CreativeTim/components'

import './CostAndLeadEditInput.scss'

class CostAndLeadEditInput extends Component {
  onInputClick = () => {
    const { changeFieldToEdit, inputKey, editInputs, settingsData } = this.props
    changeFieldToEdit(inputKey, editInputs, settingsData)
  }

  onKeyPress = event => {
    const {
      onTextInputKeyPressed,
      inputKey,
      settingsData,
      userSettingsWithError,
      editInputs,
      originalUserSettingsValues,
      costAndLeadNames
    } = this.props

    onTextInputKeyPressed(
      event.charCode,
      inputKey,
      settingsData,
      userSettingsWithError,
      editInputs,
      originalUserSettingsValues,
      costAndLeadNames
    )
  }

  onChange(event, validationType, stateNameEqualTo, maxValue, fieldType) {
    const { onTextInputChanged, inputKey, settingsData, actionButtonTexts } =
      this.props

    onTextInputChanged(
      event.target.value,
      validationType,
      stateNameEqualTo,
      maxValue,
      fieldType,
      inputKey,
      settingsData,
      actionButtonTexts
    )
  }

  renderAlertMessage = alert => {
    if (this.props.validationStateKey === 'error') {
      return <Danger style={{ textAlign: 'left' }}>{alert}</Danger>
    }
    return <div />
  }

  render() {
    const {
      edit,
      styles,
      valueLabel,
      label,
      inputKey,
      value,
      validationStateKey,
      costAndLeadNames
    } = this.props
    if (edit) {
      return (
        <div className="custom-input">
          <CustomInput
            id="outlined-dense"
            labelText={valueLabel}
            success={validationStateKey === 'success'}
            error={validationStateKey === 'error'}
            formControlProps={{
              fullWidth: true,
              style: { margin: 'unset' }
            }}
            inputProps={{
              label: label,
              autoFocus: true,
              value: value,
              onKeyPress: event => {
                this.onKeyPress(event)
              },
              onChange: event =>
                this.onChange(
                  event,
                  costAndLeadNames[inputKey].validation.type,
                  costAndLeadNames[inputKey].validation.value[0],
                  costAndLeadNames[inputKey].validation.value[1],
                  costAndLeadNames[inputKey].type
                ),
              type: costAndLeadNames[inputKey].type
            }}
          />
          {this.renderAlertMessage(costAndLeadNames[inputKey].alert)}
        </div>
      )
    }
    return (
      <span onClick={this.onInputClick} style={styles}>
        {valueLabel}
      </span>
    )
  }
}

const mapStateToProps = ({ CostAndLeadReducer }, ownProps) => {
  const {
    editInputs,
    settingsData,
    originalUserSettingsValues,
    rangeState,
    costAndLeadNames,
    actionButtonTexts,
    userSettingsWithError
  } = CostAndLeadReducer
  const validationType = costAndLeadNames[ownProps.inputKey].validation.type
  return {
    editInputs,
    settingsData,
    originalUserSettingsValues,
    rangeState,
    costAndLeadNames,
    actionButtonTexts,
    userSettingsWithError,
    validationStateKey:
      CostAndLeadReducer[`${validationType}State_${ownProps.inputKey}`],
    valueLabel: CostAndLeadReducer[`valueLabel_${ownProps.inputKey}`]
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(Object.assign({}, CostAndLeadActions), dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CostAndLeadEditInput)
