import React, { FC, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { PRINTERS_AND_MATERIALS_COSTS_IDS, STEP_ID } from '../../constants'
import { useStep } from '../../OnboardingWizardHooks'
import { isContentPresent } from '../../OnboardingWizardService'
import OnboardingStepFrame from '../OnboardingStepFrame'
import { fetchCustomizeData } from 'Scenes/Home/Customize/CustomizeActions'
import { setupPrinterPropsData } from 'Scenes/Home/Customize/CustomizeCostAndLeadSettings/CostAndLeadActions'
import CustomizePrinterProps from 'Scenes/Home/Customize/CustomizeCostAndLeadSettings/CustomizePrinterProps'
import CustomizeMaterialCost from 'Scenes/Home/Customize/CustomizeMaterialCost/CustomizeMaterialCost'

import './PrintersAndMaterialsCosts.scss'

const PrintersAndMaterialsCostsComponents = {
	[PRINTERS_AND_MATERIALS_COSTS_IDS.PRINTERS]: <CustomizePrinterProps />,
	[PRINTERS_AND_MATERIALS_COSTS_IDS.MATERIALS]: <CustomizeMaterialCost />
}

const PrintersAndMaterialsCosts: FC = () => {
	const { steps } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const {
		costAndLeadNames,
		drawingCost2dNames,
		settingsData,
		userPrinterSettingsData
	} = useSelector((state: RootStateOrAny) => state.CostAndLeadReducer)
	const { materialCosts } = useSelector(
		(state: RootStateOrAny) => state.MaterialCostReducer
	)
	const { printersFullData } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const dispatch = useDispatch()
	const { step, stepContent } = useStep(
		steps,
		STEP_ID.PRINTERS_AND_MATERIALS_COSTS
	)

	const changesInCurrentStep = useMemo(
		() =>
			(isContentPresent(
				stepContent,
				PRINTERS_AND_MATERIALS_COSTS_IDS.PRINTERS
			) &&
				userPrinterSettingsData.filter(
					(printer: Record<string, unknown>) =>
						printer.price || printer.printerHourlyCost
				).length > 0) ||
			(isContentPresent(
				stepContent,
				PRINTERS_AND_MATERIALS_COSTS_IDS.MATERIALS
			) &&
				materialCosts.length > 0),
		[materialCosts.length, stepContent, userPrinterSettingsData]
	)

	useEffect(() => {
		dispatch(
			fetchCustomizeData(costAndLeadNames, settingsData, drawingCost2dNames)
		)
		dispatch(setupPrinterPropsData(printersFullData))
	}, [])

	return (
		<OnboardingStepFrame
			step={step}
			changesInCurrentStep={changesInCurrentStep}
		>
			<div className="printers-materials-costs--wrapper">
				{stepContent?.map(component => {
					return (
						<React.Fragment key={component.id}>
							{
								PrintersAndMaterialsCostsComponents[
									component.id as keyof typeof PrintersAndMaterialsCostsComponents
								]
							}
						</React.Fragment>
					)
				})}
			</div>
		</OnboardingStepFrame>
	)
}

export default PrintersAndMaterialsCosts
