export enum ParametersFormTypes {
	GENERAL = 'GENERAL',
	COSTING_CASTING = 'COSTING_CASTING',
	COSTING_INVESTMENT_CASTING = 'COSTING_INVESTMENT_CASTING',
	COSTING_CNC = 'COSTING_CNC',
	COSTING_INJECTION_MOLDING = 'COSTING_INJECTION_MOLDING',
	COSTING_3DP = 'COSTING_3DP',
	COSTING_SUPPLY_CHAIN = 'COSTING_SUPPLY_CHAIN',
	COSTING_2D_DRAWING = 'COSTING_2D_DRAWING',
	COSTING_CO2_TRADITIONAL_MENUFACTURING = 'COSTING_CO2_TRADITIONAL_MENUFACTURING'
}
