import React, { FC } from 'react'
import { ReactComponent as Warning } from 'assets/img/svg/warning.svg'
import {
	ReactComponent as CircleWarning
} from 'assets/img/svg/icons/circle-warn.svg'
import { Feature, FeatureComponentId } from 'Services/models/Features'

interface Props {}

export const IconWarning: FC<Props> = (props: any) => {
	let isCircle = Feature.isFeatureOn(
		FeatureComponentId.SHOW_WARNING_IN_CIRCLE)

	if (isCircle) {
		return (
			<CircleWarning {...props} />
		)
	}
	return (
		<Warning {...props} />
	)
}
