import { isEmpty } from 'lodash'
import {
  USER_PROFILE_ADDRESS_SELECTED,
  LOCATION_ADDRESS_CHANGED,
  DISABLE_LOCATION_ADDRESS_AUTO_COMPLETE,
  ENABLE_LOCATION_ADDRESS_AUTO_COMPLETE,
  USER_PROFILE_ADDRESS_CHANGED
} from '../../../global actions/types'
import { SUCCESS, ERROR } from '../../../Services/Strings'

import LocationService from '../../../Services/LocationService/LocationService'

export const onAddressSelected = fullAddress => {
  return async dispatch => {
    try {
      const addressObj = {}
      const locationService = new LocationService(fullAddress)

      const locationObj = await locationService.getGeoLocationByAddress()
      addressObj.addressArray = locationObj.address_components
      addressObj.formatted_address = locationObj.formatted_address

      addressObj.latLng = await locationService.getLatLongFromLocation(
        locationObj
      )
      const parsedAddress = locationService.parseAddress(addressObj)

      dispatch({
        type: USER_PROFILE_ADDRESS_SELECTED,
        payload: {
          address: fullAddress,
          ...parsedAddress
        }
      })
    } catch (error) {
      console.error('Error', error)
    }
  }
}

export const onAddressChanged = (address, autoCompleteDisable) => {
  return {
    type: LOCATION_ADDRESS_CHANGED,
    payload: { address, autoCompleteDisable }
  }
}
export const autoCompeteError = () => {
  return {
    type: DISABLE_LOCATION_ADDRESS_AUTO_COMPLETE
  }
}
export const enableAutoCompete = () => {
  return {
    type: ENABLE_LOCATION_ADDRESS_AUTO_COMPLETE
  }
}
