import parseHTML from 'html-react-parser'
import React, { FC, memo, useCallback, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'

import { goToNextStep, goToPrevStep } from '../OnboardingWizardActions'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import {
	Button,
	RegularCard
} from 'Scenes/Components/thirdParty/CreativeTim/components'
import { IOnboardingWizardStep } from 'Services/models/IOnboardingWizardStep'
import { getString } from 'Services/Strings/StringService'

import './OnboardingStepFrame.scss'

const RegularCardTSX: any = RegularCard

type IProps = {
	children: React.ReactNode
	step: IOnboardingWizardStep
	onNextClickExtraAction?: () => void
	onNextClickAnotherAction?: () => void
	bodyClassName?: string
	childSteps?: IOnboardingWizardStep[]
	changesInCurrentStep?: boolean
}

const OnboardingStepFrame: FC<IProps> = ({
	children,
	step,
	onNextClickExtraAction,
	onNextClickAnotherAction,
	bodyClassName,
	childSteps,
	changesInCurrentStep
}) => {
	const { progressBarSteps, nextStepLoading, parentSteps, currentStep } =
		useSelector((state: RootStateOrAny) => state.OnboardingWizardReducer)
	const dispatch = useDispatch()

	const showBackButton = useMemo(
		() => progressBarSteps[0]?.id !== step.id,
		[progressBarSteps, step.id]
	)
	const isLastStep = childSteps
		? childSteps.at(-1)?.id === step.id &&
		progressBarSteps.at(-1)?.id === step.parentStepId
		: progressBarSteps.at(-1)?.id === step.id

	const onBackButtonClick = () => {
		dispatch(goToPrevStep(parentSteps, currentStep, childSteps))
	}

	const onNextClick = useCallback(
		(anotherAction?: boolean) => {
			if (anotherAction && onNextClickAnotherAction) {
				onNextClickAnotherAction()
			} else {
				if (onNextClickExtraAction) {
					onNextClickExtraAction()
				}
				dispatch(goToNextStep(parentSteps, currentStep))
			}
		},
		[
			currentStep,
			dispatch,
			onNextClickAnotherAction,
			onNextClickExtraAction,
			parentSteps
		]
	)

	return (
		<RegularCardTSX
			classes={{
				card: cx('step-card', {
					'body-full-height': !step.showStepHeader && !step.showStepFooter,
					'without-header': !step.showStepHeader,
					'with-background': step.backgroundImage
				})
			}}
			content={
				<>
					{step?.showStepHeader && (
						<div className="step-card--header">
							<h4>{getString(step?.headerStringKey)}</h4>
							<p>{parseHTML(getString(step?.descriptionStringKey))}</p>
						</div>
					)}
					<div className={cx('step-card--body', bodyClassName)}>{children}</div>
					{step?.showStepFooter && (
						<div className="step-card--footer">
							{showBackButton ? (
								<Button
									disabled={false}
									id="step-card--footer--button"
									color="secondaryNewStyle"
									onClick={onBackButtonClick}
									className="step-card--footer--button"
									data-qa='data-qa-back-onbording-btn'
								>
									{getString('ONBOARDING_BACK_BUTTON')}
								</Button>
							) : (
								<div />
							)}
							<div className="step-card--footer--continue-buttons">
								{step.continueButtons.map((button, index) => {
									const nextButtonText = changesInCurrentStep
										? getString(button.changedTextKey)
										: getString(button.initialTextKey)
									return (
										<ButtonWithLoader
											key={button.initialTextKey + index}
											disabled={!changesInCurrentStep && button.initialDisabled}
											id="step-card--footer--button"
											color="primaryNewStyle"
											loading={
												step.continueButtons.length === 1 && nextStepLoading
											}
											onClick={() => onNextClick(button.anotherAction)}
											className="step-card--footer--button"
											qaDataElementName="data-qa-main-continue-onbording-btn"
										>
											{isLastStep ? getString('DONE') : nextButtonText}
										</ButtonWithLoader>
									)
								})}
							</div>
						</div>
					)}
				</>
			}
		/>
	)
}

export default memo(OnboardingStepFrame)
