import React, { FC, memo } from 'react'
import { useDispatch } from 'react-redux'

import cx from 'classnames'

import { toggleAdditivePopup } from 'Scenes/Components/AdditiveMinds/AdditiveMindsActions'
import {
	AdditiveMindsButtonProps,
	AdditiveMindsTypes
} from 'Scenes/Components/AdditiveMinds/AdditiveMindsInterface'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as AdditiveMindsIcon } from 'assets/img/svg/additiveMinds.svg'

const AdditiveMindsButton: FC<AdditiveMindsButtonProps> = ({
	type,
	hoverText
}) => {
	const dispatch = useDispatch()
	const isButton = type === AdditiveMindsTypes.button

	return (
		<Button
			className={cx('solution-analysis-header-text filled', {
				'additive-minds__button': isButton,
				'additive-minds__status': !isButton
			})}
			color="transparent"
			onClick={() => {
				dispatch(toggleAdditivePopup(true))
			}}
		>
			{isButton && <AdditiveMindsIcon id="additive-minds__image" />}
			{getString('CONTACT_ADDITIVE_MINDS')}
			{isButton && !!hoverText && (
				<DetailsPopup
					isHover={isButton && !!hoverText}
					data={hoverText}
					popperDirection="bottom"
					popperClassName="info-box-wrapper"
					popperContactClassName="info-box-data"
					inPortal={true}
				>
					<IconFactory
						iconName="info"
						className="solution-analysis-header-text-icon without-stroke"
					/>
				</DetailsPopup>
			)}
		</Button>
	)
}

export default memo(AdditiveMindsButton)
