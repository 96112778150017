import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { flatMap, map, property } from 'lodash'

import * as AdminPrinterMaterialsActions from './AdminPrinterMaterialsActions'
import {
	adminPrinterMaterialsEditFormRoute,
	adminPrinterMaterialsNewFormRoute,
	adminPrinterMaterialsRoute
} from '../../../../Services/routeFuncs'
import {
	ADMIN_PRINTER_MATERIALS_PAGE_HEADER,
	ADMIN_PRINTER_MATERIALS_SUB_TITLE,
	MATERIAL_TABLE_TEXTS,
	PRINTER_MATERIAL_PARAMS
} from '../../../../Services/Strings'
import {
	createDeviationText,
	createSingleDeviation
} from '../../../Components/DataTable/DataTableTextWithXYZ'
import AdminDataTable from '../AdminDataTable/AdminDataTable'
import { getPrintersNames } from './AdminPrinterMaterialService'
import { PRINTER_MATERIALS_ADMIN_ROUTE } from 'Services/Constants/RoutesConstants'

import '../adminHome.scss'

const printerMaterialParams = PRINTER_MATERIAL_PARAMS

class AdminPrinterMaterials extends Component {
	componentDidMount() {
		const {
				match,
				setupAdminPrinterMaterialsPage,
				onAdminPrinterMaterialsUnmounted,
				limitPrinterMaterials,
				searchPhrase
			} = this.props,
			urlPage = parseInt(match.params['page']) || 1

		setupAdminPrinterMaterialsPage(searchPhrase, urlPage, limitPrinterMaterials)

		this.unListen = this.props.history.listen((location, action) => {
			if (
				action === 'PUSH' &&
				!location.pathname.includes(PRINTER_MATERIALS_ADMIN_ROUTE)
			) {
				onAdminPrinterMaterialsUnmounted()
			}
		})
	}

	componentDidUpdate = prevProps => {
		this.setupAdminPrinterMaterialsPage(prevProps)
	}

	componentWillUnmount() {
		this.unListen()
	}

	setupAdminPrinterMaterialsPage = prevProps => {
		const {
			match,
			pageNumber,
			setupAdminPrinterMaterialsPage,
			limitPrinterMaterials,
			searchPhrase
		} = this.props
		let urlPage = parseInt(match.params['page']) || 1
		const isSearchChange = searchPhrase !== prevProps.searchPhrase

		if (isSearchChange && urlPage !== 1) {
			this.props.history.push(PRINTER_MATERIALS_ADMIN_ROUTE + `/1`)
		} else if (urlPage === 1 && isSearchChange) {
			setupAdminPrinterMaterialsPage(
				searchPhrase,
				pageNumber,
				limitPrinterMaterials
			)
		}
	}

	renderPropertiesField = printerMaterial => {
		return (
			<div className="admin-multiple-line-field">
				<div>
					{MATERIAL_TABLE_TEXTS.TYPE}: {printerMaterial.type}
				</div>
				<div>
					{MATERIAL_TABLE_TEXTS.CATEGORY}: {printerMaterial.category}
				</div>
				<div>
					{MATERIAL_TABLE_TEXTS.DENCITY}: {printerMaterial.density}
				</div>
				<div>
					{MATERIAL_TABLE_TEXTS.TECHNOLOGY}:{' '}
					{printerMaterial.printerTechnologyName}
				</div>
			</div>
		)
	}

	getXYZ = data => {
		const deviationText = data ? createDeviationText(data) : {}
		return map(deviationText, deviation =>
			createSingleDeviation(deviation.name, deviation.value)
		)
	}

	renderStrengthField = printerMaterial => {
		return (
			<div className="admin-multiple-line-field">
				<div>
					{MATERIAL_TABLE_TEXTS.YIELD_STRENGTH}:{' '}
					{this.getXYZ(printerMaterial.yieldStrengthMPaExt)}
				</div>
				<div>
					{MATERIAL_TABLE_TEXTS.YOUNGS_MODULUS}:{' '}
					{this.getXYZ(printerMaterial.youngsModulusExt)}
				</div>
				<div>
					{MATERIAL_TABLE_TEXTS.TENSILE_STRENGTH}:{' '}
					{this.getXYZ(printerMaterial.ultimateTensileStrengthExt)}
				</div>
			</div>
		)
	}

	renderDescriptionField = printerMaterial => {
		return (
			<div
				className="admin-multiple-line-elypsis"
				title={printerMaterial.description}
			>
				{printerMaterial.description}
			</div>
		)
	}

	renderPrinterMaterialsTableData = () => {
		const { printerMaterials, printersFullData } = this.props
		const printers = flatMap(printersFullData, property('printers'))

		return printerMaterials.map(printerMaterial => {
			return [
				printerMaterial.name,
				getPrintersNames(printerMaterial, printers),
				this.renderPropertiesField(printerMaterial),
				this.renderStrengthField(printerMaterial),
				this.renderDescriptionField(printerMaterial),
				printerMaterial.costPerCubicCM,
				printerMaterial.organizationId || '',
				printerMaterial.organizationOwnerEmail || '',
				printerMaterial.id
			]
		})
	}

	deleteRow = id => {
		this.props.deletePrinterMaterial(id)
	}

	setSearchPhrase = searchPhrase => {
		this.props.setSearchPhrase(searchPhrase)
	}

	render() {
		const {
			showNoPrinterMaterialsAlert,
			showPagination,
			pageNumber,
			isLastPage,
			showAdminPrinterMaterialsAlert,
			totalPrinterMaterials,
			limitPrinterMaterials,
			searchPhrase,
			loading
		} = this.props
		return (
			<AdminDataTable
				loading={loading}
				formTitle={ADMIN_PRINTER_MATERIALS_PAGE_HEADER}
				formSubTitle={ADMIN_PRINTER_MATERIALS_SUB_TITLE}
				tableHeadParams={printerMaterialParams}
				tableData={this.renderPrinterMaterialsTableData()}
				linkToRouteFunction={adminPrinterMaterialsRoute}
				showNoDataAlert={showNoPrinterMaterialsAlert}
				showPagination={showPagination}
				pageNumber={pageNumber}
				isLastPage={isLastPage}
				showAdminErrorAlert={showAdminPrinterMaterialsAlert}
				totalResults={totalPrinterMaterials}
				limitResults={limitPrinterMaterials}
				linkToEdit={adminPrinterMaterialsEditFormRoute}
				linkToNew={adminPrinterMaterialsNewFormRoute}
				deleteRow={this.deleteRow}
				setSearchPhrase={this.setSearchPhrase}
				searchPhrase={searchPhrase}
			/>
		)
	}
}

const mapStateToProps = ({ AdminPrinterMaterialsReducer, user }) => {
	const {
		printerMaterials,
		totalPrinterMaterials,
		printerMaterialsTableHead,
		printerMaterialsTableData,
		showNoPrinterMaterialsAlert,
		pageNumber,
		isLastPage,
		showPagination,
		limitPrinterMaterials,
		showAdminPrinterMaterialsAlert,
		searchPhrase,
		loading
	} = AdminPrinterMaterialsReducer
	const { printersFullData } = user
	return {
		printerMaterials,
		totalPrinterMaterials,
		printerMaterialsTableHead,
		printerMaterialsTableData,
		showNoPrinterMaterialsAlert,
		pageNumber,
		isLastPage,
		showPagination,
		limitPrinterMaterials,
		showAdminPrinterMaterialsAlert,
		searchPhrase,
		loading,
		printersFullData
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminPrinterMaterialsActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AdminPrinterMaterials)
