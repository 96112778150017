export const NEXT_STEP_CLICKED = 'NEXT_STEP_CLICKED'
export const CANCEL_PROGRESS = 'CANCEL_PROGRESS'
export const DONE_PROGRESS_CLICKED = 'DONE_PROGRESS_CLICKED'
export const BACK_PROGRESS_CLICKED = 'BACK_PROGRESS_CLICKED'
export const COMPANY_SELECTOR_CHANGED = 'COMPANY_SELECTOR_CHANGED'
export const PRINTER_SELECTOR_CHANGED = 'PRINTER_SELECTOR_CHANGED'
export const MATERIAL_SELECTOR_CHANGED = 'MATERIAL_SELECTOR_CHANGED'
export const PROGRESS_ENDED = 'PROGRESS_ENDED'
export const RESET_PROGRESS = 'RESET_PROGRESSD'
export const WEIGHT_REDUCTION_PROGRESS_SETUP = 'WEIGHT_REDUCTION_PROGRESS_SETUP'
export const HIDE_WEIGHT_REDUCTION_MODEL = 'HIDE_WEIGHT_REDUCTION_MODEL'
export const SHOW_WEIGHT_REDUCTION_MODEL = 'SHOW_WEIGHT_REDUCTION_MODEL'
