import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { DRAGGING_CLASS_NAME } from './constants'
import {
	addCustomVariable,
	addVariableToFunction,
	closeParameterModal,
	toggleFunctionPrintingTechnology
} from './CostingFunctionEditorActions'
import { findTechnologyName } from './CostingFunctionEditorService'
import {
	ICustomFunction,
	IFunctionVariable,
	IFunctionVariableExtended
} from './CustomizeCostingFunctionTypes'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'
import { getString } from 'Services/Strings/StringService'

export const useSelectTechnologyFunctions = (
	customFunctionsList: ICustomFunction[],
	selectedFunctionCategory: string,
	printingTechnologies: IPrintingTechnology[],
	selectedPrintingTechnologies: string[],
	isStep2ToggleAllowed: boolean
) => {
	const dispatch = useDispatch()

	const allPrintingTechnologies = useMemo(
		() => printingTechnologies.map(technology => technology.name),
		[printingTechnologies]
	)

	const handleOnlyClick = (
		e: React.MouseEvent<HTMLDivElement>,
		technologyFunctionAlreadyExists: boolean,
		technologyName: string
	) => {
		if (technologyFunctionAlreadyExists) {
			e.nativeEvent.stopImmediatePropagation()
			return
		}
		e.stopPropagation()
		dispatch(
			toggleFunctionPrintingTechnology(
				[technologyName],
				customFunctionsList,
				selectedFunctionCategory,
				isStep2ToggleAllowed
			)
		)
	}

	const handleAllClick = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation()
			dispatch(
				toggleFunctionPrintingTechnology(
					allPrintingTechnologies,
					customFunctionsList,
					selectedFunctionCategory,
					isStep2ToggleAllowed
				)
			)
		},
		[
			allPrintingTechnologies,
			customFunctionsList,
			dispatch,
			selectedFunctionCategory,
			isStep2ToggleAllowed
		]
	)

	const handleSelectChange = useCallback(
		(e: any) => {
			if (
				selectedPrintingTechnologies.length === 1 &&
				e.target.value.length === 0
			)
				return
			dispatch(
				toggleFunctionPrintingTechnology(
					e.target.value as string[],
					customFunctionsList,
					selectedFunctionCategory,
					isStep2ToggleAllowed
				)
			)
		},
		[
			customFunctionsList,
			dispatch,
			selectedFunctionCategory,
			selectedPrintingTechnologies.length,
			isStep2ToggleAllowed
		]
	)

	const renderSelectValue = useCallback(
		(selected: string[]) =>
			selectedPrintingTechnologies.length === 0
				? getString('SELECT')
				: selectedPrintingTechnologies.length === 1
				? findTechnologyName(
						printingTechnologies,
						selectedPrintingTechnologies[0]
				  )
				: getString('PRINTING_TECHNOLOGIES_SELECTED').format(
						selectedPrintingTechnologies.length
				  ),
		[printingTechnologies, selectedPrintingTechnologies]
	)

	return {
		handleOnlyClick,
		handleAllClick,
		handleSelectChange,
		renderSelectValue
	}
}

export const useDragAndDrop = (stateId: string) => {
	const dispatch = useDispatch()
	const handleDragStart = (e: React.DragEvent, variable: IFunctionVariable) => {
		e.dataTransfer.dropEffect = 'copy'
		e.dataTransfer.setData('text', JSON.stringify(variable))
		e.currentTarget.classList.add(DRAGGING_CLASS_NAME)
	}

	const handleDrop = (e: React.DragEvent) => {
		const variable = JSON.parse(e.dataTransfer.getData('text'))
		dispatch(addVariableToFunction(variable, stateId))
	}

	const handleDragEnd = (e: any) => {
		e.target.classList.remove(DRAGGING_CLASS_NAME)
	}

	const handleDragOver = (e: React.DragEvent) => e.preventDefault()

	const handleClick = (variable: IFunctionVariable) => {
		dispatch(addVariableToFunction(variable, stateId))
	}

	return {
		handleDragStart,
		handleDrop,
		handleDragEnd,
		handleDragOver,
		handleClick
	}
}

export const useSetParameterModal = (
	variablesList: IFunctionVariableExtended[],
	stateId: string
) => {
	const [name, setName] = useState('')
	const [value, setValue] = useState<string | number>(0)
	const [nameError, setNameError] = useState<string>('')

	const dispatch = useDispatch()

	const alertIsDisabled = useMemo(
		() => !name || value === '' || +value === 0,
		[name, value]
	)

	const closeModal = useCallback(() => {
		setName('')
		setValue(0)
		setNameError('')
		dispatch(closeParameterModal(stateId))
	}, [dispatch, stateId])

	const handleNameChange = useCallback(
		(name: string | number) => {
			if (!!nameError) {
				setNameError('')
			}
			setName(name as string)
		},
		[nameError]
	)

	const handleValueChange = useCallback(
		(value: string | number) => setValue(value as number),
		[]
	)

	const handleConfirm = useCallback(() => {
		const nameStartsFromNumber = name.match(/^[a-zA-Z]+/) === null
		const variableAlreadyExists = variablesList.some(
			(variable: IFunctionVariable) =>
				variable.userReadableName.toLowerCase() === name.toLowerCase()
		)

		if (nameStartsFromNumber) {
			setNameError(
				getString('COSTING_FUNCTION_SET_NEW_PARAMETER_NAME_NUMBER_ERROR')
			)
			return
		}

		if (variableAlreadyExists) {
			setNameError(getString('COSTING_FUNCTION_SET_NEW_PARAMETER_NAME_ERROR'))
			return
		}

		dispatch(
			addCustomVariable(
				name,
				+value,
				errorText => setNameError(errorText),
				closeModal,
				stateId
			)
		)
	}, [closeModal, dispatch, name, stateId, value, variablesList])

	return {
		name,
		nameError,
		value,
		alertIsDisabled,
		closeModal,
		handleNameChange,
		handleValueChange,
		handleConfirm
	}
}
