import React, { memo } from 'react'

import IconLoaderGif from 'Scenes/Components/CastorIcons/IconLoaderGif'
import PartImageWithFallback from 'Scenes/Components/PartImageWithFallback/PartImageWithFallback'
import { Info } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { PART_IMAGES_TABS_INDEX } from 'Services/Constants'
import { FormatType } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'
import { doesImageDontHaveCoordinates } from 'Services/Utils/ImagesTools'

import coordinateImage from 'assets/img/axes_watermark.png'

import './SolutionPartDetails.scss'

const { STRUCTURAL_LIMITATION_TAB_INDEX } = PART_IMAGES_TABS_INDEX

interface Props {
	partImageSrc: string
	showImageLoader: boolean
	formatType?: string
	solutionPartImageIndex: number
}

const SolutionPartImage: React.FC<Props> = ({
	partImageSrc,
	showImageLoader,
	solutionPartImageIndex,
	formatType
}: Props) => {
	const isPdfFile = formatType === FormatType.pdf

	const renderImage = () => {
		if (showImageLoader) {
			return (
				<>
					<IconLoaderGif />
					<Info>{getString('PART_ANALYSIS_WALL_THICKNESS_ANALYZE')}</Info>
				</>
			)
		}
		return (
			<>
				{doesImageDontHaveCoordinates(partImageSrc) && (
					<img
						className="part-image-container-coordinate"
						src={coordinateImage}
						alt="..."
					/>
				)}
				<PartImageWithFallback
					formatType={formatType}
					className="part-image-container-img"
					src={partImageSrc}
					alt="..."
				/>
			</>
		)
	}

	return (
		<>
			<div
				className={`part-image-container-div part-image-container--structural ${
					solutionPartImageIndex === STRUCTURAL_LIMITATION_TAB_INDEX ||
					isPdfFile
						? 'show'
						: ''
				}`}
			>
				{renderImage()}
			</div>
		</>
	)
}

export default memo(SolutionPartImage)
