import Flexbox from 'Scenes/Components/FlexBox'
import React, { FC, memo } from 'react'

import { default as classNames } from 'classnames'

import Loader from '../../Loader/Loader'
import IconFactory from '../StarIcon/IconFactory'
import { Button } from '../thirdParty/CreativeTim/components'
import { LOADING } from 'Services/Constants'

import './Modal.scss'

interface IProps {
	title: string
	alertType?: string
	onCancel: Function
	showCancelIcon?: boolean
	disableCancel?: boolean
}

const renderHeaderIcon = (alertType?: string) => {
	if (!alertType) {
		return <div></div>
	}
	if (alertType === LOADING) {
		return <Loader load={true} size={40} message="" showFlex={false} />
	}
	return (
		<IconFactory iconName={alertType} className="modal-header--type-icon" />
	)
}

const ModalHeader: FC<IProps> = ({
	title,
	onCancel,
	alertType,
	showCancelIcon = true,
	disableCancel
}) => {
	return (
		<Flexbox
			className={classNames('modal-header', {
				loading: alertType === LOADING
			})}
			data-qa="data-qa-modal-header"
		>
			<Flexbox className="modal-header-block" alignItems="center">
				{renderHeaderIcon(alertType)}
				<div className="modal-header-title">{title}</div>
			</Flexbox>

			<Button
				justIcon
				color="transparent"
				onClick={onCancel}
				disabled={disableCancel}
				data-qa="data-qa-close-modal-btn"
			>
				{showCancelIcon && (
					<IconFactory iconName="clear" className="modal-header-icon" />
				)}
			</Button>
		</Flexbox>
	)
}

export default memo(ModalHeader)
