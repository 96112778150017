import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { isEmpty } from 'lodash'

import {
	EMISSION_CHANGE_DATA_METHODS,
	EMISSION_DATA_ERROR_METHODS,
	EMISSION_EDIT_ALL_METHODS,
	EMISSION_EDIT_SELECTED_METHOD,
	EMISSION_UPDATED_FAILED_DATA_METHODS,
	EMISSION_UPDATED_LOADING_DATA_METHODS,
	EMISSION_UPDATED_SUCCESS_DATA_METHODS
} from '../../../../global actions/types'
import { store } from '../../../../index'
import {
	ALLOW_AMOUNT,
	CO2_NAMES,
	SHIPMENT_MAX,
	SHIPMENT_MIN,
	ZERO_AMOUNT
} from './constants'
import {
	isShipping,
	prepareToSendCO2,
	setupResetLabels
} from './CustomizeCO2Service'
import { updateCO2Data } from 'Services/Network'
import { getString } from 'Services/Strings/StringService'

export const onEditToggle = (co2Key: string, isEdit: boolean) => {
	return {
		type: EMISSION_EDIT_SELECTED_METHOD,
		payload: { co2Key, isEdit }
	}
}

export const onEditAllToggle = (isEditAll: boolean) => {
	return {
		type: EMISSION_EDIT_ALL_METHODS,
		payload: isEditAll
	}
}

export const onEditCO2Data = (
	co2Key: string,
	keyLabel: string,
	value: any
): any => {
	return (dispatch: Dispatch<AnyAction>) => {
		const { co2Data } = store.getState().CustomizeCO2Reducer
		const selectedData = co2Data[co2Key].data

		let dataShippingError = false
		let dataError = false
		let totalOnEdit = ZERO_AMOUNT

		selectedData[keyLabel] = value

		// check on Errors
		for (const key in selectedData) {
			const numberValue = +selectedData[key]

			if (!isShipping(key)) {
				totalOnEdit += numberValue
				if (
					(typeof selectedData[key] === 'string' &&
						isEmpty(selectedData[key])) ||
					numberValue > ALLOW_AMOUNT
				) {
					dataError = true
				}
			} else {
				if (numberValue < SHIPMENT_MIN || numberValue > SHIPMENT_MAX) {
					dataShippingError = true
				}
			}
		}

		const errorMessage = dataShippingError
			? getString('EMISSION_COMPARISON_SHIPPING_ERROR')
			: dataError
			? getString('EMISSION_COMPARISON_PERCENTAGE_ERROR')
			: getString('EMISSION_COMPARISON_SUM_ERROR')

		if (totalOnEdit !== ALLOW_AMOUNT || dataError || dataShippingError) {
			dispatch({
				type: EMISSION_DATA_ERROR_METHODS,
				payload: { co2Key, isError: true, errorMessage: errorMessage }
			})
		} else {
			dispatch({
				type: EMISSION_CHANGE_DATA_METHODS,
				payload: { co2Key, keyLabel, value }
			})
		}
	}
}

export const onUpdateCO2Data = (reset: boolean, co2Key?: string) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		const { co2Data, settingsData } = store.getState().CustomizeCO2Reducer
		let params = prepareToSendCO2(co2Data, co2Key)

		if (reset) {
			params = setupResetLabels(settingsData.defaultSettings, CO2_NAMES, co2Key)
		}

		dispatch({
			type: EMISSION_UPDATED_LOADING_DATA_METHODS,
			payload: {
				isLoading: true
			}
		})
		try {
			const updatedData = await updateCO2Data(params, reset, co2Key)
			dispatch({
				type: EMISSION_UPDATED_SUCCESS_DATA_METHODS,
				payload: {
					params: updatedData?.data?.params,
					co2Key: co2Key
				}
			})
		} catch (e) {
			dispatch({
				type: EMISSION_UPDATED_FAILED_DATA_METHODS
			})
		}

		dispatch({
			type: EMISSION_UPDATED_LOADING_DATA_METHODS,
			payload: {
				isLoading: false
			}
		})
	}
}
