import Numeral from 'numeral'
import { useEffect, useState } from 'react'

import { NA, partResults } from 'Services/Constants'
import { toFixedOnlyIfNeeded } from 'Services/global/toFixedOnlyIfNeeded'
import {
	COST_AND_LEAD_LABELS,
	COST_SUMMARY_ADDITIVE_METHOD,
	COST_SUMMARY_METHOD,
	COST_SUMMARY_TRADITIONAL_METHOD,
	MANUFACTURABILITY_SHOULD_BE_VALIDATED,
	PART_RESULTS_OPTIONS,
	SYSTEM_RECOMMENDATION,
	TAB_HEADER_COST_PER_PART,
	TAB_HEADER_MANUFACTURABILITY,
	TAB_HEADER_PRODUCTION_TIME
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { DataTableService } from 'Scenes/Components/DataTable/DataTableService'
import { DataTableFieldType } from 'Scenes/Components/DataTable/IDataTableField'
import { generateLeadTimeInformation } from '../LeadTimeTab/LeadTimeInformation'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { displayInRange } from 'Services/global/calculateRange'

const dataTableService = new DataTableService()

const getCostSummaryHeaders = () => [
	dataTableService.RenderFieldObject({
		type: DataTableFieldType.Header,
		text: COST_SUMMARY_METHOD,
		className: 'cost-summary--table--text--fixed-height'
	}),
	dataTableService.RenderFieldObject({
		type: DataTableFieldType.HeaderIcon,
		iconName: 'build',
		className: 'cost-summary--table--text--fixed-height content-center',
		iconClassName: 'data-table-row-icon--small data-table-row-icon--with-opacity',
		hoverText: TAB_HEADER_MANUFACTURABILITY
	}),
	dataTableService.RenderFieldObject({
		type: DataTableFieldType.HeaderIcon,
		iconName: 'time-dark',
		className: 'cost-summary--table--text--fixed-height content-center',
		iconClassName: 'data-table-row-icon--small data-table-row-icon--with-opacity',
		hoverText: TAB_HEADER_PRODUCTION_TIME
	}),
	dataTableService.RenderFieldObject({
		type: DataTableFieldType.HeaderIcon,
		iconName: 'wallet',
		className: 'cost-summary--table--text--fixed-height content-center',
		iconClassName: 'data-table-row-icon--small data-table-row-icon--with-opacity',
		hoverText: TAB_HEADER_COST_PER_PART
	})
]

export function useCostSummaryHeaders() {
	const [costSummaryHeaders, setCostSummaryHeaders] = useState(
		getCostSummaryHeaders()
	)

	useEffect(() => {
		setCostSummaryHeaders(getCostSummaryHeaders())
	}, [])

	return costSummaryHeaders
}

const getCostSummaryRows = (
	traditionalMethod: string,
	solution: any,
	configuration: any,
	isShowValuesInRanges?: boolean
) => {
	if (configuration?.id <= 0) {
		return []
	}
	const actualResult = configuration?.costResults?.actualResult
	const totalLeadTime = solution?.leadTime
	const manufacturingLeadTime =
		configuration?.costResults?.supplyChainTraditionalDetails?.leadTime
	const material3d = solution?.printerMaterial?.name
	const printerName = solution?.printer?.name
	const printerCompany = solution?.printer?.company
	const originalMaterial = configuration?.material?.name
	const isCostEffective = solution?.costDetails?.totalCost < actualResult
	const costAffectivePoint = Numeral(toFixedOnlyIfNeeded(actualResult)).format(
		'0,0')
	let solutionCostEstimation = Numeral(
		toFixedOnlyIfNeeded(solution?.costDetails?.totalCost)
	).format('0,0')

	const minTotalCost = solution?.costDetails?.minCostDetails?.totalCost
	const maxTotalCost = solution?.costDetails?.maxCostDetails?.totalCost

	if (isShowValuesInRanges && minTotalCost && maxTotalCost) {
		solutionCostEstimation = displayInRange(minTotalCost, maxTotalCost,
			solution?.costDetails?.totalCost)
	}

	const configurationPrintable = [
		partResults.printable,
		partResults.borderline
	].includes(configuration?.result)

	const days = COST_AND_LEAD_LABELS.DAYS

	const configurationLeadTime = configuration?.leadTimeResults?.leadTimeDetails
	const solutionLeadTime = solution?.leadTimeDetails

	const rows = [
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Detailed,
				text: isCostEffective
					? `${SYSTEM_RECOMMENDATION} : ${getString('3D_PRINTING')}`
					: '',
				className: `cost-summary--table--text--title data-table--first-row`,
				title: COST_SUMMARY_ADDITIVE_METHOD,
				intro: `${printerCompany} ${printerName}, ${material3d}`
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.IconInfo,
				iconName: configuration?.result,
				className: 'cost-summary--table--text--title content-center flex',
				iconClassName: 'data-table-row-icon--small',
				hoverText: PART_RESULTS_OPTIONS.find(
					(partResultOption: any) =>
						partResultOption.value === configuration?.result
				).title
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIconButton,
				text: totalLeadTime && configurationPrintable
					? `${totalLeadTime} ${days}`
					: NA,
				iconName: 'info',
				className: `cost-summary--table--text--full-detail content-center with-i-icon`,
				iconOnTheRight: true,
				isHover: true,
				description: solutionLeadTime?.lastDay > solutionLeadTime?.firstLeadTime
					?
					generateLeadTimeInformation(solutionLeadTime)
					: ''
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIcon,
				text: solution && configurationPrintable
					? `$${solutionCostEstimation}`
					: NA,
				className: `cost-summary--table--text cost-summary--table--text--full-detail content-center data-table--last-row`,
				iconOnTheRight: true
			})
		],
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Detailed,
				text: traditionalMethod,
				className: `cost-summary--table--text--title data-table--first-row`,
				title: COST_SUMMARY_TRADITIONAL_METHOD,
				intro: originalMaterial
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.IconInfo,
				iconName: 'minus',
				className: 'cost-summary--table--text--title content-center flex',
				iconClassName: 'data-table-row-icon--small warning-color',
				hoverText: MANUFACTURABILITY_SHOULD_BE_VALIDATED
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIconButton,
				text: manufacturingLeadTime ? `${manufacturingLeadTime} ${days}` : NA,
				iconName: 'info',
				className: `cost-summary--table--text--full-detail content-center with-i-icon`,
				iconOnTheRight: true,
				isHover: true,
				description: configurationLeadTime?.lastDay >
				configurationLeadTime?.firstLeadTime
					? generateLeadTimeInformation(configurationLeadTime) : ''
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: solution ? `$${costAffectivePoint}` : NA,
				className: 'content-center cost-summary--table--text--full-detail data-table--last-row'
			})
		]
	]
	return rows
}

export function useCostSummaryRows(
	traditionalMethod: string,
	solution: any,
	configuration: any
) {
	const isShowValuesInRanges = Feature.isFeatureOn(
		FeatureComponentId.SHOW_VALUES_IN_RANGES)

	const [CostSummaryRows, setCostSummaryRows] = useState(
		getCostSummaryRows(traditionalMethod, solution, configuration,
			isShowValuesInRanges)
	)

	useEffect(() => {
		setCostSummaryRows(
			getCostSummaryRows(traditionalMethod, solution, configuration,
				isShowValuesInRanges)
		)
	}, [traditionalMethod, solution, configuration])

	return CostSummaryRows
}
