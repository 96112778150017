import { AuthService } from '../evonik/customServices/AuthService'
import { AuthActionsService } from '../../Scenes/Authentication/AuthActionsService'

export class AuthActionsServiceBuilder {
  constructor() {
    switch (process.env.REACT_APP_THEME_NAME) {
      case 'evonik':
        return new AuthService()

      default:
        return new AuthActionsService()
    }
  }
}
