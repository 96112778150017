export const SETUP_ADVANCED_FILTERS = 'SETUP_ADVANCED_FILTERS'
export const CHANGE_ADVANCED_FILTERS_VALUE = 'CHANGE_ADVANCED_FILTERS_VALUE'
export const CHANGE_ADVANCED_FILTERS_VALUES = 'CHANGE_ADVANCED_FILTERS_VALUES'
export const CHANGE_ADVANCED_FILTERS_CHECKBOX =
	'CHANGE_ADVANCED_FILTERS_CHECKBOX'
export const CHANGE_ADVANCED_FILTERS_TECHNOLOGY =
	'CHANGE_ADVANCED_FILTERS_TECHNOLOGY'
export const CHANGE_ADVANCED_MULTIPLE_FILTERS_TECHNOLOGY =
	'CHANGE_ADVANCED_MULTIPLE_FILTERS_TECHNOLOGY'
export const RESET_ADVANCED_FILTERS = 'RESET_ADVANCED_FILTERS'
export const SAVE_ADVANCED_FILTERS = 'SAVE_ADVANCED_FILTERS'
export const REMOVE_ADVANCED_FILTERS = 'REMOVE_ADVANCED_FILTERS'
export const CHANGE_ADVANCED_GROUP_FILTER = 'CHANGE_ADVANCED_GROUP_FILTER'
