import React, { memo } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import * as CustomizeMetadataActions from './CustomizeMetadataActions'
import {
	ItemGrid,
	Table
} from 'Scenes/Components/thirdParty/CreativeTim/components'
import TransparentButton from 'Scenes/Components/TransparentButton'
import CustomizeMetadataCostButton from 'Scenes/Home/Customize/CustomizeMetadataCost/CustomizeMetadataCostButton'
import CustomizeMetadataCostInput from 'Scenes/Home/Customize/CustomizeMetadataCost/CustomizeMetadataCostInput'
import {
	onApplyAllMetadataData,
	onEditAllToggle,
	onEditToggleChange
} from 'Scenes/Home/Customize/CustomizeMetadataCost/CustomizeMetadataCostService'
import { DRAWING_COSTS_TABLE } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import * as styles from '../customizeStyles.css'
import '../Customize2DDrawingCost/Customize2DDrawingCost.scss'
import './index.scss'

const ItemGridTSX: any = ItemGrid

interface IProps {
	metadataCostNames: any
	settingsData: any
	isEditAllMetadataCost: boolean
	metadataEditToggle: Function
	metadataEditAllToggle: Function
	metadataApplyToggle: Function
	updateMetadataData: Function
	editMetadataData: Function
	updateAllMetadataData: Function
}

interface IReduxStore {
	CostAndLeadReducer?: any
}

const CustomizeMetadataCostTbl: React.FC<IProps> = ({
	metadataCostNames,
	settingsData,
	isEditAllMetadataCost,
	metadataEditToggle,
	metadataEditAllToggle,
	metadataApplyToggle,
	updateMetadataData,
	editMetadataData,
	updateAllMetadataData
}) => {
	const userSettings = settingsData.userSettings
	const defaultSettings = settingsData.defaultSettings

	const renderValue = (metadataKey: any, isDefault: boolean) => {
		let inputStyle = styles.editedValue
		if (isDefault) {
			inputStyle = styles.defaultValue
		}
		const apply = metadataCostNames[metadataKey].apply
		const isEdit = metadataCostNames[metadataKey].edit
		const minValue = metadataCostNames[metadataKey]?.validation?.minValue
		const maxValue = metadataCostNames[metadataKey]?.validation?.maxValue
		const errorMsg = metadataCostNames[metadataKey].alert

		return (
			<CustomizeMetadataCostInput
				metadataKey={metadataKey}
				errorMessage={errorMsg}
				valueInput={
					userSettings[metadataKey]
						? userSettings[metadataKey]
						: defaultSettings[metadataKey]
				}
				isEdit={isEdit}
				isApply={apply}
				minValue={minValue}
				maxValue={maxValue}
				isDefault={isDefault}
				styles={inputStyle}
				editMetadataData={editMetadataData}
				updateMetadataData={updateMetadataData}
			/>
		)
	}

	const renderAction = (metadataKey: any) => {
		const isEdit = metadataCostNames[metadataKey].edit

		return (
			<CustomizeMetadataCostButton
				metadataKey={metadataKey}
				isEdit={isEdit}
				metadataApplyToggle={metadataApplyToggle}
				metadataEditToggle={metadataEditToggle}
				onEditToggle={onEditToggleChange}
			/>
		)
	}

	const tableData = () => {
		let tableData: any = []
		for (const metadataKey in metadataCostNames) {
			const name = (
				<span title={metadataCostNames[metadataKey].title}>
					{metadataCostNames[metadataKey].text}
				</span>
			)
			const isDefault =
				!userSettings[metadataKey] ||
				userSettings[metadataKey] === defaultSettings[metadataKey]
			const value = renderValue(metadataKey, isDefault)
			const action = renderAction(metadataKey)

			tableData.push([name, value, action])
		}
		return tableData
	}

	const editAll = (
		<div id="editAll">
			<TransparentButton
				className="custom-input--action"
				onClick={() => {
					onEditAllToggle(
						metadataEditToggle,
						metadataEditAllToggle,
						metadataCostNames,
						isEditAllMetadataCost
					)
				}}
			>
				{isEditAllMetadataCost
					? getString('CANCEL')
					: getString('DRAWING_COSTS_EDIT_ALL')}
			</TransparentButton>
			{isEditAllMetadataCost ? (
				<TransparentButton
					onClick={() =>
						onApplyAllMetadataData(
							metadataCostNames,
							userSettings,
							updateAllMetadataData
						)
					}
					className="custom-input--action"
				>
					{getString('APPLY_ALL')}
				</TransparentButton>
			) : (
				<></>
			)}
		</div>
	)

	return (
		<div>
			<ItemGridTSX>
				<Table
					editTableStyle={'drawingCostTableCell'}
					tableHead={[
						DRAWING_COSTS_TABLE.PROPERTY_NAME,
						DRAWING_COSTS_TABLE.VALUE,
						editAll
					]}
					tableData={tableData()}
				/>
			</ItemGridTSX>
		</div>
	)
}

const mapStateToProps = (state: IReduxStore) => {
	const {
		CostAndLeadReducer: {
			metadataCostNames,
			settingsData,
			isEditAllMetadataCost
		}
	} = state
	return {
		metadataCostNames,
		settingsData,
		isEditAllMetadataCost
	}
}
const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeMetadataActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizeMetadataCostTbl)
)
