import React, { FC } from 'react'
import { getTheme, getComponentFactoryTheme } from './getTheme'
const { customComponents } = getTheme()

interface IOverrideProps {
  componentId: number
}

export default <P extends object>(
  WrappedComponent: React.ComponentType<P> | any
): FC<IOverrideProps & any> => ({ componentId, ...props }: IOverrideProps) => {
  const CustomComponent: any = customComponents[componentId]
  if (!CustomComponent) {
    return <WrappedComponent {...(props as P)} />
  }
  const ComponentFactoryTheme = getComponentFactoryTheme()
  return <ComponentFactoryTheme name={CustomComponent} {...(props as P)} />
}
