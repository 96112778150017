import React, { memo, FC, ReactNode } from 'react'
import { BooleanSelector } from 'redux-form'
import Button from '../../Components/thirdParty/CreativeTim/components/CustomButtons/Button'

interface IProps {
  className: string
  buttonText: string
  hide: boolean
  onClick: Function
}

export const ButtonForSupport: FC<IProps> = ({
  className = '',
  buttonText = '',
  hide,
  onClick
}) => {
  if (hide) {
    return <div />
  }
  return (
    <Button color="primary" className={className} onClick={onClick}>
      {buttonText}
    </Button>
  )
}

export default memo(ButtonForSupport)
