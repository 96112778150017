import React, { FC, memo } from 'react'
import { Field, reduxForm } from 'redux-form'

import { FormControlLabel, MenuItem } from '@material-ui/core'
import { forEach, isString, map, toLower } from 'lodash'

import {
	MATERIAL_TABLE_TEXTS,
	REQUIRED,
	SUBMIT,
	UNDO_CHANGES
} from '../../../Services/Strings'
import { getString } from '../../../Services/Strings/StringService'
import { isLink } from '../../../Services/validationFuncs'
import { renderCheckbox } from '../../admin/AdminHome/AdminFields/AdminCheckbox'
import { renderNumberField } from '../../admin/AdminHome/AdminFields/AdminNumberField'
import { renderSelectFieldTouched } from '../../admin/AdminHome/AdminFields/AdminSelectField'
import { renderTextField } from '../../admin/AdminHome/AdminFields/AdminTextField'
import { NUMBER } from '../../admin/AdminHome/AdminPrinterMaterials/constants'
import {
	checkErrorValues,
	companies,
	company,
	machineCostAsHour,
	machineCostAsMachine,
	machineSelector,
	maintenanceAsFixed,
	maintenanceAsPercent,
	maintenanceMachineSelector,
	nameField,
	OTHER,
	printerCheckboxes,
	standardPrinterProperties,
	technology,
	typicalKwh
} from './constants'
import CastorSelector from 'Scenes/Components/CastorSelector'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { toFixedOnlyIfNeeded } from 'Services/global/toFixedOnlyIfNeeded'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'

import 'Scenes/admin/AdminHome/adminHome.scss'

const ReduxFormField: any = Field
type IProps = {
	handleSubmit: (onSubmit: Function) => React.FormEventHandler<HTMLFormElement>
	pristine: boolean
	reset: () => void
	submitting: boolean
	onSubmit: (data: Record<string, any>) => void
	printingTechnologies: IPrintingTechnology[]
	valid: boolean
	addingNewItem: boolean
	onMaintenanceMachineCostFieldsSelectorChange: Function
	maintenanceMachineCostFieldsSelectorValue: number
	onMachineCostSelector: Function
	onMachineCostValue: number
	change: (name: string, value: string | null) => void
	printerId: string
	initialized: boolean
	selectedPrinterTechnology: string
	selectedPrinterCompany: string
	isNewCompanyDisabled: boolean
	printersFullData: Record<string, any>[]
	newCompanySelected: Function
	isAdminForm: boolean
}

const validate = (values: Record<string, any>) => {
	const errors = {} as Record<string, any>

	forEach(checkErrorValues, item => {
		const selectedValue = values[item.name]
		if (!selectedValue && !values[nameField] && item.defaultValue) {
			values[item.name] = item.defaultValue
		}
		if (
			item.checkIfEmpty &&
			isString(selectedValue) &&
			!selectedValue?.trim()
		) {
			errors[item.name] = REQUIRED
		}

		if (item.checkIfNull && values[item.name] == null) {
			errors[item.name] = REQUIRED
		}

		if (selectedValue && item.checkIfMinMax) {
			const checkOnlyMin = item?.min && selectedValue < item?.min
			const checkAndEqualMin = !item?.min && selectedValue <= item?.min

			if (selectedValue > item?.max || checkAndEqualMin || checkOnlyMin) {
				const label = item?.isRequired
					? getString('NUMBER_VALIDATION_REQUIRED_NOT_INCLUDING_ZERO')
					: getString('NUMBER_VALIDATION_NOT_INCLUDING_ZERO')

				errors[item.name] = label.format(item?.min, item?.max)
			}
		}

		if (selectedValue && item?.checkIfLink && !isLink(selectedValue)) {
			errors[item.name] = getString('INVALID_LINK')
		}
	})

	return errors
}

const onMaintenanceMachineCostSelectorChanged = (
	event: Record<string, any>,
	changeFunc: Function,
	actionFunc: Function,
	printerId: string
) => {
	let fieldToRestName
	switch (event.target.value) {
		case 2:
			fieldToRestName = 'maintenanceAsPercentOfMachineCostPerYear'
			break
		case 1:
			fieldToRestName = 'maintenanceFixedMachineCostPerYear'
			break
		default:
			break
	}

	changeFunc(fieldToRestName, null)
	changeFunc('maintenanceMachineCostFieldsSelectorValue', event.target.value)

	if (printerId) {
		actionFunc(event, printerId)
	} else {
		actionFunc(event)
	}
}

const renderSelectedCompanies = (
	selectedPrinterCompany: string,
	companiesNames: string[],
	standard: Record<string, any>,
	change: (name: string, value: string | null) => void,
	initialized: boolean,
	newCompanySelected: Function
) => {
	return (
		<Field
			name={standard?.name}
			component={renderSelectFieldTouched}
			label={standard?.label}
			custom={{
				value: selectedPrinterCompany || '',
				onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
					change(company, e.target.value)
					change(companies, e.target.value)
					if (e.target.value === OTHER) {
						change(company, '')
					}
					// TODO: call action
					newCompanySelected(e.target.value)
				}
			}}
			initialized={initialized}
		>
			<MenuItem disabled>{MATERIAL_TABLE_TEXTS.COMPANY}</MenuItem>
			{companiesNames.map(companyName => {
				return (
					<MenuItem key={companyName} value={companyName}>
						{companyName}
					</MenuItem>
				)
			})}
		</Field>
	)
}
const renderSelectedTechnology = (
	selectedPrinterTechnology: string,
	printingTechnologies: IPrintingTechnology[],
	standard: Record<string, any>,
	change: (name: string, value: string | null) => void,
	initialized: boolean
) => {
	return (
		<ReduxFormField
			name={standard?.name}
			component={renderSelectFieldTouched}
			label={standard?.label}
			custom={{
				value: selectedPrinterTechnology || '',
				onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
					change(technology, e.target.value)
			}}
			initialized={initialized}
		>
			<MenuItem disabled>{MATERIAL_TABLE_TEXTS.TECHNOLOGY}</MenuItem>
			{printingTechnologies.map(printingTechnology => {
				return (
					<MenuItem
						key={printingTechnology.name}
						value={printingTechnology.name}
					>
						{printingTechnology.userReadableName}
					</MenuItem>
				)
			})}
		</ReduxFormField>
	)
}

const renderMaintenanceMachineCostFields = (
	key: number,
	initialized: boolean
) => {
	switch (key) {
		case 1:
			return (
				<ReduxFormField
					initialized={initialized}
					className="admin-form-field-maintenance-machine-cost"
					name={maintenanceAsPercent.name}
					component={renderTextField}
					label={maintenanceAsPercent.label}
					type={maintenanceAsPercent.type}
					iIcon={maintenanceAsPercent.iIcon}
				/>
			)
		case 2:
			return (
				<ReduxFormField
					initialized={initialized}
					className="admin-form-field-maintenance-machine-cost"
					name={maintenanceAsFixed.name}
					component={renderTextField}
					label={maintenanceAsFixed.label}
					type={maintenanceAsFixed.type}
					iIcon={maintenanceAsFixed.iIcon}
				/>
			)

		default:
			return <div />
	}
}

const onMachineCostSelectorChanged = (
	event: Record<string, any>,
	changeFunc: Function,
	actionFunc: Function
) => {
	let fieldToRestName
	switch (event.target.value) {
		case 2:
			fieldToRestName = 'hourlyCost'
			break
		case 1:
			fieldToRestName = 'machineCost'
			break
		default:
			break
	}

	changeFunc(fieldToRestName, '')
	changeFunc('onMachineCostValue', event.target.value)
	actionFunc(event)
}

const renderMachineCostFields = (key: number, initialized: boolean) => {
	switch (key) {
		case 1:
			return (
				<ReduxFormField
					initialized={initialized}
					className="admin-form-field-machine-cost"
					name={machineCostAsMachine.name}
					component={renderTextField}
					label={machineCostAsMachine.label}
					type={machineCostAsMachine.type}
					iIcon={machineCostAsMachine.iIcon}
				/>
			)
		case 2:
			return (
				<ReduxFormField
					initialized={initialized}
					className="admin-form-field-machine-cost"
					name={machineCostAsHour.name}
					component={renderTextField}
					label={machineCostAsHour.label}
					type={machineCostAsHour.type}
					iIcon={machineCostAsHour.iIcon}
				/>
			)

		default:
			return <div />
	}
}
const getAverageByTechnology = (
	printersFullData: Record<string, any>[],
	technology: string
) => {
	let averageByTechnologies: Record<string, any> = {
		count: 0,
		sum: 0,
		technology: technology
	}
	let allPrinters: Record<string, any>[] = []

	for (const printersByCompany of printersFullData) {
		const printersByTechnology = printersByCompany.printers.filter(
			(p: Record<string, any>) =>
				toLower(p.technology) === toLower(technology) && !p.organization
		)
		allPrinters = allPrinters.concat(printersByTechnology)
	}

	for (const printer of allPrinters) {
		averageByTechnologies.count++
		averageByTechnologies.sum = printer?.typicalKwh + averageByTechnologies.sum
	}
	if (!averageByTechnologies.count) {
		return null
	}

	averageByTechnologies.averageByTechnology = toFixedOnlyIfNeeded(
		Number(averageByTechnologies?.sum / averageByTechnologies?.count)
	)
	return averageByTechnologies
}
const PrinterForm: FC<IProps> = props => {
	const {
		handleSubmit,
		pristine,
		reset,
		submitting,
		onSubmit,
		printingTechnologies,
		valid,
		addingNewItem,
		onMaintenanceMachineCostFieldsSelectorChange,
		maintenanceMachineCostFieldsSelectorValue,
		onMachineCostSelector,
		onMachineCostValue,
		change,
		printerId,
		initialized,
		selectedPrinterTechnology,
		selectedPrinterCompany,
		isNewCompanyDisabled,
		printersFullData,
		newCompanySelected,
		isAdminForm
	} = props
	const companiesNames = printersFullData.map(data => data.companyName)
	companiesNames.push(OTHER)
	let averageByTechnology: Record<string, any> | null = null
	if (selectedPrinterTechnology) {
		averageByTechnology = getAverageByTechnology(
			printersFullData,
			selectedPrinterTechnology
		)
	}
	return (
		<form onSubmit={handleSubmit(onSubmit)} className="admin-form">
			{map(standardPrinterProperties, standard => {
				if (standard.name === company && isNewCompanyDisabled) {
					return <></>
				}

				if (standard?.isSelectedItems && standard.name === technology) {
					return (
						<div key={standard.name}>
							{renderSelectedTechnology(
								selectedPrinterTechnology,
								printingTechnologies,
								standard,
								change,
								initialized
							)}
						</div>
					)
				}
				if (standard?.isSelectedItems && standard.name === companies) {
					return (
						<div key={standard.name}>
							{renderSelectedCompanies(
								selectedPrinterCompany,
								companiesNames,
								standard,
								change,
								initialized,
								newCompanySelected
							)}
						</div>
					)
				}

				if (standard.name === typicalKwh) {
					standard.extraData = { iIconShow: '0' }
					if (selectedPrinterTechnology && averageByTechnology) {
						standard.extraData = {
							iIconShow: '1',
							firstValue: printingTechnologies.find(
								p => p.name === selectedPrinterTechnology
							)?.userReadableName,
							secondValue: averageByTechnology.averageByTechnology
						}
					}
					return (
						<div key={standard?.name}>
							<ReduxFormField
								{...standard}
								className="admin-form-field"
								name={standard?.name}
								component={renderNumberField}
								label={standard?.label}
								initialized={initialized}
								multiline={standard?.multiline}
							/>
						</div>
					)
				}
				return (
					<div key={standard.name}>
						<ReduxFormField
							{...standard}
							className="admin-form-field"
							name={standard.name}
							component={
								standard?.type === NUMBER ? renderNumberField : renderTextField
							}
							label={standard.label}
							initialized={initialized}
							multiline={standard?.multiline}
						/>
					</div>
				)
			})}

			<div className="admin-form-field--machineCost-div">
				<CastorSelector
					className="admin-form-field--machineCost-div-selector"
					classesProps={{
						root: 'admin-form-field--machineCost-div-selector--root'
					}}
					value={onMachineCostValue}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						onMachineCostSelectorChanged(e, change, onMachineCostSelector)
					}}
					items={machineSelector?.items}
				/>

				{renderMachineCostFields(onMachineCostValue, initialized)}
			</div>

			<div className="admin-form-field--maintenance-div">
				<CastorSelector
					className="admin-form-field--maintenance-div-selector"
					classesProps={{
						root: 'admin-form-field--maintenance-div-selector--root'
					}}
					value={maintenanceMachineCostFieldsSelectorValue}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						onMaintenanceMachineCostSelectorChanged(
							e,
							change,
							onMaintenanceMachineCostFieldsSelectorChange,
							printerId
						)
					}}
					items={maintenanceMachineSelector?.items}
				/>
				{renderMaintenanceMachineCostFields(
					maintenanceMachineCostFieldsSelectorValue,
					initialized
				)}
			</div>

			{isAdminForm ? (
				map(printerCheckboxes, checkbox => {
					return (
						<div key={checkbox?.name}>
							<FormControlLabel
								control={
									<ReduxFormField
										name={checkbox?.name}
										component={renderCheckbox}
									/>
								}
								label={checkbox?.label}
							/>
						</div>
					)
				})
			) : (
				<></>
			)}
			<div>
				<Button
					size="sm"
					color="primary"
					type="submit"
					disabled={!valid || addingNewItem}
					data-qa="data-qa-submit-setting-btn"
				>
					{SUBMIT}
				</Button>
				<Button
					size="sm"
					color="primary"
					type="button"
					disabled={pristine || submitting}
					onClick={reset}
					data-qa="data-qa-undo-setting-btn"
				>
					{UNDO_CHANGES}
				</Button>
			</div>
		</form>
	)
}

export default memo(
	reduxForm<any, any>({
		form: 'adminPrinterFormState',
		validate,
		enableReinitialize: true
	})(PrinterForm)
)
