import React, { forwardRef, HTMLProps } from 'react'
import { BeaconRenderProps } from 'react-joyride'

interface Props extends BeaconRenderProps {
  handleClick(arg0: any): void
}

type DivProps = HTMLProps<HTMLDivElement> & Props

const Beacon = forwardRef<HTMLDivElement, DivProps>((props, ref) => {

  const handleClick = (e: any) => { // TODO: remove it if not used
    props.onClick && props.onClick(e)
    props.handleClick && props.handleClick(e)
  }

  return (
    <div
      className="tour-beacon"
      ref={ref}
      {...props}
      onClick={handleClick}
    >
      <span style={{
        animation: '1.2s ease-in-out 0s infinite normal none running joyride-beacon-inner',
        backgroundColor: 'rgb(255, 0, 68)',
        borderRadius: '50%',
        display: 'block',
        height: '50%',
        left: '50%',
        opacity: '0.7',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
      }}></span>
      <span style={{
        animation: '1.2s ease-in-out 0s infinite normal none running joyride-beacon-outer',
        backgroundColor: 'rgba(255, 0, 68, 0.2)',
        border: '2px solid rgb(255, 0, 68)',
        borderRadius: '50%',
        boxSizing: 'border-box',
        display: 'block',
        height: '100%',
        left: '0px',
        opacity: '0.9',
        position: 'absolute',
        top: '0px',
        transformOrigin: 'center center',
        width: '100%',
      }}></span>
    </div>
  )
})

export default Beacon
