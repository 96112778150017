import { Action } from '../../../global actions/ActionModels'
import {
  NEXT_STEP_CLICKED,
  CANCEL_PROGRESS,
  DONE_PROGRESS_CLICKED,
  BACK_PROGRESS_CLICKED,
  COMPANY_SELECTOR_CHANGED,
  PRINTER_SELECTOR_CHANGED,
  MATERIAL_SELECTOR_CHANGED,
  PROGRESS_ENDED,
  RESET_PROGRESS,
  WEIGHT_REDUCTION_PROGRESS_SETUP,
  HIDE_WEIGHT_REDUCTION_MODEL,
  SHOW_WEIGHT_REDUCTION_MODEL
} from '../../../global actions/types/WeightReductionProgressTypes'

export interface WeightReductionProgressInitialState {
  readonly stepValue: number
  readonly customInitialStep: any
  readonly loading: boolean
  readonly innerShow: boolean
  readonly printersCompaniesList: Array<string>
  readonly materialsPickerPrinters: Array<any>
  readonly materialsPickerMaterialsList: Array<any>
  readonly materialsPickerCompanyValue: string
  readonly materialsPickerPrinterValue: string
  readonly materialsPickerMaterialValue: string
  readonly processFromProjectPage: boolean
  readonly configurationData: any
}

const INITIAL_STEP = 1
const initialState: WeightReductionProgressInitialState = {
  stepValue: INITIAL_STEP,
  customInitialStep: null,
  loading: false,
  printersCompaniesList: [],
  materialsPickerPrinters: [],
  materialsPickerMaterialsList: [],
  materialsPickerCompanyValue: '',
  materialsPickerPrinterValue: '',
  materialsPickerMaterialValue: '',
  processFromProjectPage: false,
  innerShow: true,
  configurationData: null
}

export default (state = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case WEIGHT_REDUCTION_PROGRESS_SETUP: {
      const { initialStep, configurationData } = payload
      return {
        ...state,
        stepValue: initialStep || INITIAL_STEP,
        customInitialStep: initialStep,
        processFromProjectPage: false,
        configurationData: configurationData || state.configurationData
      }
    }
    case NEXT_STEP_CLICKED:
      return {
        ...state,
        stepValue:
          state.stepValue + 1 > 2 ? state.stepValue : state.stepValue + 1
      }
    case CANCEL_PROGRESS:
      return {
        ...state,
        stepValue: state.customInitialStep || INITIAL_STEP
      }
    case DONE_PROGRESS_CLICKED:
      return {
        ...state,
        loading: true
      }
    case PROGRESS_ENDED: {
      return {
        ...state,
        loading: false
      }
    }
    case RESET_PROGRESS: {
      return {
        stepValue: state.customInitialStep || INITIAL_STEP,
        loading: false,
        printersCompaniesList: [],
        materialsPickerPrinters: [],
        materialsPickerMaterialsList: [],
        materialsPickerCompanyValue: '',
        materialsPickerPrinterValue: '',
        materialsPickerMaterialValue: '',
        innerShow: true
      }
    }
    case BACK_PROGRESS_CLICKED:
      return {
        ...state,
        stepValue: state.stepValue - 1
      }
    case COMPANY_SELECTOR_CHANGED: {
      const { value, printersList } = payload
      return {
        ...state,
        materialsPickerCompanyValue: value,
        materialsPickerPrinters: printersList,
        materialsPickerMaterialsList: [],
        materialsPickerPrinterValue: '',
        materialsPickerMaterialValue: ''
      }
    }
    case PRINTER_SELECTOR_CHANGED: {
      const { value, materialsList } = payload
      return {
        ...state,
        materialsPickerPrinterValue: value,
        materialsPickerMaterialsList: materialsList,
        materialsPickerMaterialValue: ''
      }
    }
    case MATERIAL_SELECTOR_CHANGED: {
      const { value } = payload
      return {
        ...state,
        materialsPickerMaterialValue: value
      }
    }
    case HIDE_WEIGHT_REDUCTION_MODEL: {
      return {
        ...state,
        innerShow: false
      }
    }
    case SHOW_WEIGHT_REDUCTION_MODEL: {
      return {
        ...state,
        innerShow: true
      }
    }
    default:
      return state
  }
}
