import parseHTML from 'html-react-parser'
import React, { FC, memo } from 'react'

import { getString } from 'Services/Strings/StringService'

const DescriptionBlock: FC = () => {
	const descriptionTexts =
		getString('ONBOARDING_START_SCREEN_DESCRIPTION_TEXTS') || []
	return (
		<div className="start-screen--description">
			{descriptionTexts?.map((text: string) => (
				<p key={text}>{parseHTML(text)}</p>
			))}
		</div>
	)
}

export default memo(DescriptionBlock)
