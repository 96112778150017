import {
	ADMIN_PRINTER_ADDED,
	ADMIN_PRINTER_DELETED,
	ADMIN_PRINTER_INITIAL_VALUES_HAD_SET,
	ADMIN_PRINTER_NEW_COMPANY_SELECTED,
	ADMIN_PRINTER_RESET_DATA,
	ADMIN_PRINTER_SEARCH_PHRASE_CHANGED,
	ADMIN_PRINTER_UPDATED,
	GET_ADMIN_PRINTERS,
	GET_ADMIN_PRINTERS_EDIT_PRINTER_SELECTED,
	GET_ADMIN_PRINTERS_GOT_ERROR,
	GET_ADMIN_PRINTERS_SUCCESS,
	MACHINE_COST_FIELDS_SELECTOR_CHANGED,
	MAINTENANCE_FIELDS_SELECTOR_CHANGED,
	MAINTENANCE_FIELDS_SELECTOR_NEW_PRINTER_CHANGED,
	NEW_ITEM_ADD_CALLED,
	NEW_ITEM_ADD_FAILED
} from '../../../../global actions/types'
import { initialPrinterState, OTHER } from './constants'

const INITIAL_STATE = {
	printers: [],
	totalPrinters: 0,
	printersTableData: [],
	showNoPrintersAlert: false,
	pageNumber: 1,
	isLastPage: false,
	NumOfPrintersSeen: 0,
	showPagination: false,
	limitPrinters: 50,
	showAdminPrintersAlert: false,
	selectedEditPrinter: null,
	printerAdded: false,
	searchPhrase: '',
	selectedNewPrinter: initialPrinterState,
	addingNewItem: false,
	maintenanceMachineSelectorsValues: {},
	maintenanceMachineSelectorsValueNewPrinter: 1,
	maintenanceMachineCostFieldsSelectorValue: null,
	loading: false,
	isNewCompanyDisabled: true,
	onMachineCostSelector: null,
	onMachineCostValue: 1
}
const adminPrintersReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ADMIN_PRINTER_RESET_DATA:
			return {
				...state,
				...INITIAL_STATE
			}
		case GET_ADMIN_PRINTERS:
			return { ...state, loading: true }
		case GET_ADMIN_PRINTERS_SUCCESS:
			const { data, pageNumber } = action.payload,
				{ printers, totalPrinters } = data,
				NumOfPrintersSeen =
					(pageNumber - 1) * state.limitPrinters + printers.length,
				isLastPage = NumOfPrintersSeen >= totalPrinters
			const temp = createSelectorValuesObject(printers)

			return {
				...state,
				printers,
				totalPrinters,
				printersTableData: printers.map(printer => Object.values(printer)),
				maintenanceMachineSelectorsValues: temp,
				showNoPrintersAlert: !printers.length,
				pageNumber,
				NumOfPrintersSeen,
				isLastPage,
				showPagination: !(isLastPage && pageNumber === 1),
				showAdminPrintersAlert: false,
				selectedNewPrinter: initialPrinterState,
				printerAdded: false,
				addingNewItem: false,
				loading: false
			}
		case ADMIN_PRINTER_UPDATED:
			const updatedPrinter = action.payload
			return {
				...state,
				printers: state.printers.map(printer =>
					printer.id === updatedPrinter.id ? updatedPrinter : printer
				)
			}
		case GET_ADMIN_PRINTERS_GOT_ERROR:
			return {
				...state,
				showAdminPrintersAlert: true,
				loading: false
			}
		case GET_ADMIN_PRINTERS_EDIT_PRINTER_SELECTED:
			const selectedEditPrinter = action.payload
			const onMachineCostValue = selectedEditPrinter.hourlyCost ? 2 : 1
			return {
				...state,
				selectedEditPrinter: selectedEditPrinter,
				printerAdded: false,
				onMachineCostValue: onMachineCostValue
			}
		case ADMIN_PRINTER_DELETED:
			const id = action.payload.id
			return {
				...state,
				printers: state.printers.filter(printer => printer.id !== id)
			}
		case ADMIN_PRINTER_ADDED:
			const newPrinter = action.payload
			return {
				...state,
				printers: [...state.printers, newPrinter],
				printerAdded: true,
				addingNewItem: false
			}
		case ADMIN_PRINTER_SEARCH_PHRASE_CHANGED: {
			const { searchPhrase } = action.payload
			return {
				...state,
				searchPhrase
			}
		}
		case ADMIN_PRINTER_NEW_COMPANY_SELECTED:
			let isNewCompanyDisabled = true
			if (action.payload === OTHER) {
				isNewCompanyDisabled = false
			}
			return {
				...state,
				isNewCompanyDisabled
			}

		case ADMIN_PRINTER_INITIAL_VALUES_HAD_SET:
			return {
				...state,
				selectedNewPrinter: action.payload
			}
		case NEW_ITEM_ADD_CALLED:
			return {
				...state,
				addingNewItem: true
			}
		case NEW_ITEM_ADD_FAILED:
			return {
				...state,
				addingNewItem: false
			}
		case MAINTENANCE_FIELDS_SELECTOR_CHANGED: {
			const { value, printerId } = action.payload
			return {
				...state,
				maintenanceMachineSelectorsValues: {
					...state.maintenanceMachineSelectorsValues,
					[printerId]: { value }
				}
			}
		}
		case MACHINE_COST_FIELDS_SELECTOR_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				onMachineCostValue: value
			}
		}
		case MAINTENANCE_FIELDS_SELECTOR_NEW_PRINTER_CHANGED:
			const { value } = action.payload
			return {
				...state,
				maintenanceMachineSelectorsValueNewPrinter: value
			}
		default:
			return state
	}
}

const createSelectorValuesObject = printers => {
	return printers.reduce((acc, printer) => {
		if (printer.maintenanceAsPercentOfMachineCostPerYear) {
			return { ...acc, ...{ [printer.id]: { value: 1 } } }
		} else {
			return { ...acc, ...{ [printer.id]: { value: 2 } } }
		}
	}, {})
}

export default adminPrintersReducer
