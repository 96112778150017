import React, { Component } from 'react'
import { Manager, Target, Popper } from 'react-popper'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Grow from '@material-ui/core/Grow'
import headerLinksStyle from '../thirdParty/CreativeTim/variables/styles/headerLinksStyle'

const renderPopper = (
  classes,
  open,
  menuItems,
  handleClose,
  hidePopper,
  className
) => {
  if (hidePopper) {
    return <div />
  }
  return (
    <Popper
      eventsEnabled={open}
      className={
        classNames({ [classes.popperClose]: !open }) +
        ' ' +
        classes.pooperResponsive +
        ' ' +
        className
      }
      style={{ zIndex: '9' }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Grow in={open} id="menu-list" style={{ transformOrigin: '0 0 0' }}>
          <Paper className={classes.dropdown}>{menuItems}</Paper>
        </Grow>
      </ClickAwayListener>
    </Popper>
  )
}

const PopperMenu = props => {
  const {
    classes,
    open,
    targetItem,
    menuItems,
    handleClose,
    managerStyle,
    hidePopper,
    className,
    qaDataElementName,
  } = props

  const managerClasses = classNames({
    [classes.managerClasses]: true
  })
  return (
    <Manager className={managerClasses} style={managerStyle} data-qa={qaDataElementName || 'data-qa-popper-menu'}>
      <Target>{targetItem}</Target>
      {renderPopper(
        classes,
        open,
        menuItems,
        handleClose,
        hidePopper,
        className
      )}
    </Manager>
  )
}

export default withStyles(headerLinksStyle)(PopperMenu)
