import React, { FC, memo } from 'react'
import {
	TIME_DETAILS_HOVER_DELIVERY_LABEL,
	TIME_DETAILS_HOVER_HEADER,
	TIME_DETAILS_HOVER_PRODUCING_LABEL
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import Devider from 'Scenes/Components/Devider/Devider'
import LeadCostDetailsIcon from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/common/LeadCostDetail'
import InfoBox from 'Scenes/Components/InfoBox'

const DeviderTSX: any = Devider

interface TopTimeDetailsIconProps {
	leadTimeDetails: any
	isSpecifiedQuantity: boolean
	firstParts?: number
	isLeadTime?: boolean
}

export const TopTimeDetailsIcon: FC<TopTimeDetailsIconProps> = ({
	leadTimeDetails,
	firstParts,
	isLeadTime,
	isSpecifiedQuantity
}) => {
	if (
		!leadTimeDetails ||
		((!leadTimeDetails?.deliveryTime || !leadTimeDetails?.productionTime) &&
			!leadTimeDetails?.lastDay) ||
		!isSpecifiedQuantity
	) {
		return <div />
	}

	const timeLeadBreakdown = () => {
		const { productionTime, deliveryTime, total } = leadTimeDetails

		return (
			<>
				<div className="cost-details-row-header">
					{TIME_DETAILS_HOVER_HEADER}
				</div>
				<div className="cost-details-row">
					<div>{TIME_DETAILS_HOVER_PRODUCING_LABEL}</div>
					<div>
						<span>{productionTime.value || ''}</span>
						<span>{productionTime.format}</span>
					</div>
				</div>
				<div className="cost-details-row">
					<div>{TIME_DETAILS_HOVER_DELIVERY_LABEL}</div>
					<div>
						<span>{deliveryTime.value || ''}</span>
						<span>{deliveryTime.format}</span>
					</div>
				</div>
				<DeviderTSX size="228px" className="cost-details-row-divider" />
				<div className="cost-details-row cost-details-row-total">
					<div>{getString('TOTAL')}</div>
					<div>
						<span>{total.value || ''}</span>
						<span>{total.format}</span>
					</div>
				</div>
				<DeviderTSX
					size="228px"
					className="cost-details-row-divider cost-details-row-divider--last"
				/>
			</>
		)
	}

	const costDetailsPopUp = () => {
		return <div className="cost-details-wrapper">{timeLeadBreakdown()}</div>
	}

	if (isLeadTime) {
		return (
			<LeadCostDetailsIcon
				isBig
				leadTimeDetails={leadTimeDetails}
				boxClassName="info-box-details"
			/>
		)
	}

	return (
		<InfoBox
			boxContactElement={costDetailsPopUp()}
			iconClassName="cost-details-icon"
		/>
	)
}

export default memo(TopTimeDetailsIcon)
