import React, { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { updateUserSettings } from './CostAndLeadActions'
import CostAndLeadTbl from './CostAndLeadTbl'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import { Danger } from 'Scenes/Components/thirdParty/CreativeTim/components'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { getString } from 'Services/Strings/StringService'

import { applyAllButtons } from '../customizeStyles.css'

const CostCalculationParams: FC = () => {
	const {
		userSettingsWithError,
		showUploadButton,
		settingsData,
		editInputs,
		costAndLeadNames
	} = useSelector((state: RootStateOrAny) => state.CostAndLeadReducer)
	const dispatch = useDispatch()
	const showAlert = Object.values(userSettingsWithError).indexOf(true) > -1

	const onUpdateClick = () => {
		dispatch(updateUserSettings(settingsData, editInputs, costAndLeadNames))
	}

	return (
		<div className="cost-calculation-params-wrapper" data-qa="data-qa-cost-calculation-parameters-block">
			<CastorFormHeader
				explanationHeader={getString('CUSTOMIZE_COST_AND_LEAD_TITLE')}
				explanationArray={getString(
					'CUSTOMIZE_COST_AND_LEAD_EXPLANATION_ARRAY'
				)}
				isInCard
			/>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<CostAndLeadTbl />
				<div style={applyAllButtons as any}>
					{showAlert ? (
						<Danger style={{ textAlign: 'left' }}>
							{getString('COST_AND_LEAD_APPLY_CHANGES_ALERT')}
						</Danger>
					) : (
						<div />
					)}
					{!showUploadButton || showAlert ? (
						<div />
					) : (
						<TransparentButton onClick={onUpdateClick}>
							{getString('COST_AND_LEAD_APPLY_CHANGES')}
						</TransparentButton>
					)}
				</div>
			</div>
		</div>
	)
}

export default memo(CostCalculationParams)
