import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { skipOrCompleteOnboarding } from '../../OnboardingWizardActions'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { getString } from 'Services/Strings/StringService'

const StartButton: FC = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	return (
		<Button
			disabled={false}
			color="primaryNewStyle"
			onClick={() => dispatch(skipOrCompleteOnboarding(history))}
			className="done-screen--button"
		>
			{getString('ONBOARDING_DONE_SCREEN_START_BUTTON_TEXT')}
		</Button>
	)
}

export default StartButton
