import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { WALL_THICKNESS_DEMAND_BUTTON_TEXT } from '../../../Services/Strings'
import * as CastorWallThicknessDemandActions from './CastorWallThicknessDemandActions'
import TransparentButton from '../TransparentButton'

import './CastorWallThicknessDemand.css'

class CastorWallThicknessDemand extends Component {
  render() {
    const {
      onWallThicknessDemandClick,
      pollerStarted,
      text,
      buttonClassName,
      wrapperClassName
    } = this.props
    return (
      <div className={wrapperClassName}>
        <TransparentButton
          onClick={() => onWallThicknessDemandClick()}
          className={buttonClassName}
          disabled={pollerStarted}
        >
          {text || WALL_THICKNESS_DEMAND_BUTTON_TEXT}
        </TransparentButton>
      </div>
    )
  }
}

const mapStateToProps = ({ CastorWallThicknessDemandReducer }) => {
  return {
    ...CastorWallThicknessDemandReducer
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...CastorWallThicknessDemandActions }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CastorWallThicknessDemand)
