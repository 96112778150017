import { get, post, put } from '../Network'

const path = '/api/integration-consumer'

export const isLoggedin = (query: string) => {
	return get(`${path}/provider-auth/isLoggedin${query}`)
}

export const updateToken = (body: {}) => {
	return post(`${path}/provider-auth`, body)
}

export const sendPartToProvider = (body: {}) => {
	return put(`${path}/upload`, body)
}

export const getFileStatus = (file_id: string, query: string) => {
	return get(`${path}/upload/files/${file_id}${query}`)
}

export const providerTokenLogin = (
	token: string,
	providerGuid: string,
	third_party_id: string
) => {
	return post(
		`/provider/login/${token}`,
		{ providerGuid, third_party_id },
		{ throttle: { time: 2000, name: 'providerLogin' } }
	)
}
