import React, { FC } from 'react'

import { shouldShowPoweredBy } from '../../Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisHeader/SolutionExportPdf/SolutionPdfService'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import CastorLogo from 'assets/img/castorLogo.png'

const { showPDFInformation = true } = getTheme()

interface IProps {
	userCompany: string
}

export const FooterPdf: FC<IProps> = ({ userCompany }) => {
	const showPoweredBy = shouldShowPoweredBy(userCompany)

	return (
		<div id="pageFooter" className="page-footer">
			{showPDFInformation && (
				<div className="small-text">{getString('INFORMATION_PDF_FOOTER')}</div>
			)}
			{showPoweredBy && (
				<div>
					<a href="https://www.3dcastor.com/">
						<div className="page-footer__powered">
							<p>{getString('POWERED_BY')}</p>
							<div>
								<img src={CastorLogo} alt="castor-logo" />
							</div>
						</div>
					</a>
				</div>
			)}
		</div>
	)
}

export default FooterPdf
