import React, { ChangeEvent, FC } from 'react'
import Checkbox from '@material-ui/core/Checkbox'

const CheckboxTSX: any = Checkbox

interface IProps {
  name: string,
  value: number | null
  isEdit: boolean
  editable: boolean
  labelKey: string
  updateDataValue: Function
}

const CustomizeBooleanInput:FC<IProps> = ({
 name,
 value,
 isEdit,
 editable,
 labelKey,
 updateDataValue
}) => {

  if (editable && isEdit) {
    return <CheckboxTSX
      color="primary"
      className="checkbox"
      checked={value}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        updateDataValue(e.target.checked, name, labelKey)
      }
    />
  }

  return <span className="with-opacity">
      <CheckboxTSX
        color="primary"
        className="checkbox"
        disabled={true}
        checked={value}
      />
    </span>
}

export default CustomizeBooleanInput
