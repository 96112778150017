import {
	ADMIN_PRINTER_MATERIAL_DELETED,
	ADMIN_PRINTER_MATERIAL_DELETED_GOT_ERROR,
	ADMIN_PRINTER_MATERIAL_SEARCH_PHRASE_CHANGED,
	ADMIN_PRINTER_MATERIAL_UPDATED,
	GET_ADMIN_PRINTER_MATERIALS,
	GET_ADMIN_PRINTER_MATERIALS_EDIT_PRINTER_MATERIAL_SELECTED,
	GET_ADMIN_PRINTER_MATERIALS_GOT_ERROR,
	GET_ADMIN_PRINTER_MATERIALS_SUCCESS,
	GET_ALL_PRINTERS_ERROR,
	GET_ALL_PRINTERS_LOADING,
	GET_ALL_PRINTERS_SUCCESS,
	NEW_PRINTER_MATERIAL_ADDED
} from '../../../../global actions/types'
import {
	createNewPrinterMaterialAdmin,
	deletePrinterMaterialAdmin,
	getAllPrintersMaterialsAdmin,
	getPrinterMaterialAdmin,
	getPrinterMaterialCategories,
	getPrinterMaterialsAdmin,
	updatePrinterMaterialAdmin
} from '../../../../Services/Network'
import {
	ADMIN_PRINTER_MATERIALS_ADDED_SUCCESS,
	ADMIN_PRINTER_MATERIALS_UPDATE_ERROR,
	ADMIN_PRINTER_MATERIALS_UPDATE_SUCCESS
} from '../../../../Services/Strings'
import {
	createNewItem,
	deleteItem,
	selectItemToEdit,
	setupAdminPage,
	updateItem
} from '../adminActionsService'
import {
	addMissingValues,
	checkMachining,
	prepareDeviationToProperty,
	printerToCompany
} from './AdminPrinterMaterialService'

export const setupAdminPrinterMaterialsPage = (
	searchPhrase,
	pageNumber,
	limitPrinterMaterials
) => {
	return dispatch => {
		setupAdminPage(
			dispatch,
			getPrinterMaterialsAdmin,
			[searchPhrase, pageNumber, limitPrinterMaterials],
			GET_ADMIN_PRINTER_MATERIALS_SUCCESS,
			GET_ADMIN_PRINTER_MATERIALS_GOT_ERROR,
			pageNumber
		)

		dispatch({ type: GET_ADMIN_PRINTER_MATERIALS })
	}
}

export const onAdminPrinterMaterialsUnmounted = () => {
	return {
		type: ADMIN_PRINTER_MATERIAL_SEARCH_PHRASE_CHANGED,
		payload: ''
	}
}

export const setupAdminPrinters = () => {
	return async dispatch => {
		dispatch({ type: GET_ALL_PRINTERS_LOADING })
		try {
			const printerResult = await getAllPrintersMaterialsAdmin()
			const categoriesResult = await getPrinterMaterialCategories()
			const allPrintersCompanies = printerToCompany(printerResult.data.printers)

			dispatch({
				type: GET_ALL_PRINTERS_SUCCESS,
				payload: {
					allPrintersCompanies,
					allCategories: categoriesResult.data.printerMaterialsCategories,
					allSubCategories: categoriesResult.data.printerMaterialsSubCategories,
					categoriesAverageCO2: categoriesResult.data.categoriesAverageCO2
				}
			})
		} catch (e) {
			dispatch({ type: GET_ALL_PRINTERS_ERROR })
		}
	}
}

export const setSearchPhrase = searchPhrase => {
	return {
		type: ADMIN_PRINTER_MATERIAL_SEARCH_PHRASE_CHANGED,
		payload: searchPhrase
	}
}

export const selectPrinterMaterialToEdit = (
	printerMaterialId,
	printerMaterials
) => {
	return dispatch => {
		selectItemToEdit(
			dispatch,
			printerMaterialId,
			printerMaterials,
			getPrinterMaterialAdmin,
			[printerMaterialId],
			GET_ADMIN_PRINTER_MATERIALS_EDIT_PRINTER_MATERIAL_SELECTED,
			GET_ADMIN_PRINTER_MATERIALS_GOT_ERROR,
			'printerMaterial'
		)
	}
}

export const updatePrinterMaterial = data => {
	const preparedDataMachining = checkMachining(data)
	const changedData = prepareDeviationToProperty(preparedDataMachining)

	return dispatch => {
		updateItem(
			dispatch,
			updatePrinterMaterialAdmin,
			changedData,
			ADMIN_PRINTER_MATERIAL_UPDATED,
			'',
			ADMIN_PRINTER_MATERIALS_UPDATE_SUCCESS,
			ADMIN_PRINTER_MATERIALS_UPDATE_ERROR
		)
	}
}

export const deletePrinterMaterial = id => {
	return dispatch => {
		deleteItem(
			dispatch,
			deletePrinterMaterialAdmin,
			id,
			ADMIN_PRINTER_MATERIAL_DELETED,
			ADMIN_PRINTER_MATERIAL_DELETED_GOT_ERROR
		)
	}
}

export const createNewPrinterMaterial = (
	printerMaterial,
	organizationSpecific = false
) => {
	const preparedDataMachining = checkMachining(printerMaterial)
	const dataWithMissingValues = addMissingValues(preparedDataMachining)
	const changedData = prepareDeviationToProperty(dataWithMissingValues)
	// remove redundant spaces from material name
	changedData.name = changedData.name?.trim()
	return dispatch => {
		createNewItem(
			dispatch,
			createNewPrinterMaterialAdmin,
			{ printerMaterial: changedData, organizationSpecific },
			NEW_PRINTER_MATERIAL_ADDED,
			'',
			ADMIN_PRINTER_MATERIALS_ADDED_SUCCESS,
			ADMIN_PRINTER_MATERIALS_UPDATE_ERROR
		)
	}
}
