import { getString } from '../../../../Services/Strings/StringService'

export const threadName = 'threadDetection'
export const holesName = 'holesFiltering'
export const wallThicknessName = 'wallThicknessAlert'
export const toleranceName = 'toleranceAlert'
export const internalCavitiesName = 'internalCavitiesAlert'
export const cadName = 'cadAlert'
export const millingMetalSupportName = 'millingMetalSupportAlert'
export const heatDeformationName = 'heatDeformationAlert'
export const orientationStabilityName = 'orientationStabilityAlert'
export const ADMIN = 'admin'

export interface FilterValue {
	accuracy: null | boolean
	bioCompatible: null | boolean
	cost: null | boolean
	elongation: null | boolean
	esd: null | boolean
	foodSafe: null | boolean
	inHousePrinters: null | boolean
	leadTime: null | boolean
	printerTechnology: null | boolean
	sameMaterial: null | boolean
	serviceTemperature: null | boolean
	strength: null | boolean
	surfaceFinish: null | boolean
	thermalConductivity: null | boolean
	youngsModulus: null | boolean
	[name: string]: null | boolean | undefined
}

export interface IOption {
	value: number
	label: string
	info: string
}

export const customizeGeometryAnalysisSelectOptions = [
	{
		value: 1,
		label: getString('NOTIFY')
	},
	{
		value: 0,
		label: getString('DO_NOT_NOTIFY')
	}
]
