export enum PartPropertyName {
	partNumber = 'partNumber',
	quantity = 'quantity',
	width = 'width',
	depth = 'depth',
	height = 'height',
	volume = 'volume',
	originalMaterialMatch = 'originalMaterialMatch',
	material = 'material',
	solidsCount = 'solidsCount',
	dropdown = 'dropdown',
	status = 'status',
	packingDensity = 'packingDensity'
}
export interface PartProperty {
	allowDot: boolean
	dependsError: string | null
	dependsField: string | null
	editable: boolean
	index: number
	labelName: string
	mandatory: boolean
	maxValue: string | null
	minValue: string | null
	propertyName: PartPropertyName
	showInUI: boolean
	step: number
	type: string
	units: string
	value: string
	link?: string
	showTooltip?: boolean
	isInapplicable?: boolean
}

export interface PartProperties {
	changed: boolean
	drawingStatusCode: number
	partId: number
	data: PartProperty[]
}
