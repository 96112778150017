import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import Loader from './Loader'


const LoaderTSX: any = Loader

const PageLoader: FC<any> = ({ withDrawer = false }) => {
	const styles = withDrawer ? { marginLeft: -260 } : {}
	const { loaderCounter } = useSelector(
		(state: RootStateOrAny) => state?.GlobalReducer
	)

	return (
		<div>
			<LoaderTSX styles={styles} fullPage load={loaderCounter} />
		</div>
	)
}

export default memo(PageLoader)