import React, { ChangeEvent, memo, PureComponent } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import MenuItem from '@material-ui/core/MenuItem'
import { map } from 'lodash'

import * as NewPartConfigurationActions from './NewPartConfigurationActions'
import { ADVANCED, MIN_BATCH, MIN_DEFAULT_YEARS, objectives } from './constants'
import ConfigurePrintersMethod from './NewConfigurePrinters/ConfigurePrintersMethod'
import {
	IReduxState,
	NewPartConfigurationProps,
	State,
	StateType,
	StateValues
} from './NewPartConfigurationTypes'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'
import MaterialsSolutionSelector from 'Scenes/Components/MaterialSolutionSelector/MaterialsSolutionSelector'
import SelectMenu from 'Scenes/Components/MaterialSolutionSelector/SelectMenu'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import NumberField from 'Scenes/Components/NumberField'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { MIN_NUMBER_FIELD_VALUE } from 'Services/Constants'
import { getCallbackURLFromLocalStorage } from 'Services/LocalStorageService'
import { partConfigurationsRoute } from 'Services/routeFuncs'
import { getString } from 'Services/Strings/StringService'

import './NewPartConfiguration.scss'

const MenuItemTsx: any = MenuItem
const NavBarAndMateriall: any = NavBarAndMaterial

class NewPartConfiguration extends PureComponent<
	NewPartConfigurationProps,
	State
> {
	state = {
		batchSize: objectives[0].quantity,
		yearsOfDemand: objectives[0].yearsOfDemand,
		quantityTitleText: objectives[0].titleText,
		fullTrayAssumption: objectives[0].fullTrayAssumption,
		callbackURL: getCallbackURLFromLocalStorage()
	}

	componentDidMount = () => {
		const {
			materials,
			materialCategories,
			initialBatchSize,
			setupNewConfiguration,
			match,
			printersFullData,
			defaultConfigurations,
			desktopPrinter,
			objectivesValue
		} = this.props

		const { partId } = match.params

		if (objectivesValue === ADVANCED) {
			this.setState({
				batchSize: objectives[1].quantity,
				yearsOfDemand: objectives[1].yearsOfDemand,
				quantityTitleText: objectives[1].titleText,
				fullTrayAssumption: objectives[1].fullTrayAssumption
			})
		}

		setupNewConfiguration(
			materials,
			materialCategories,
			initialBatchSize,
			partId,
			printersFullData,
			defaultConfigurations,
			desktopPrinter
		)
	}

	goBack = () => {
		const { partId, projectId } = this.props.match.params

		this.props.history.push(partConfigurationsRoute(projectId, partId))
	}

	updateState = (key: StateType, value: number) =>
		this.setState(state => ({ ...state, [key]: value }))

	onSendNewConfiguration = () => {
		const { batchSize, yearsOfDemand, fullTrayAssumption } = this.state
		const {
			history,
			manufacturingMethod,
			priorities,
			chosenPrinterConfiguration,
			partMaterial,
			chosenDefaultMaterial,
			chosenPrinterConfigurationType,
			propertyName,
			objectivesValue,
			isSpecifiedQuantity
		} = this.props

		const { partId, projectId } = this.props.match.params

		this.props.onSendNewConfiguration(
			batchSize,
			yearsOfDemand,
			history,
			partId,
			projectId,
			manufacturingMethod,
			priorities,
			chosenPrinterConfiguration,
			partMaterial,
			chosenDefaultMaterial,
			chosenPrinterConfigurationType,
			propertyName,
			objectivesValue === ADVANCED,
			objectivesValue,
			fullTrayAssumption,
			isSpecifiedQuantity
		)
	}

	onCloseClick = (e: any) => {
		e.stopPropagation()
		const { callbackURL } = this.state
		if (callbackURL) {
			window.location.replace(callbackURL)
		}
	}

	configurationObjectives = () => {
		return (
			<div className="configure-wrapper-objectives">
				<div className="flex start">
					<div className="configure-wrapper-manufacturing flex">
						<div className={`text_field__label`}>
							{getString('NEW_PART_CONFIGURATION_OBJECTIVES')}
						</div>
						<div>
							<SelectMenu
								value={this.props.objectivesValue}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									const objective = e.target.value
									this.updateState(
										StateValues.BATCH_SIZE,
										objectives.find(obj => obj.value === objective)?.quantity ||
											MIN_BATCH
									)
									this.updateState(
										StateValues.QUANTITY_TITLE_TEXT,
										objectives.find(obj => obj.value === objective)
											?.titleText ||
											getString('NEW_PART_CONFIGURATION_YEARS_OF_DEMAND')
									)

									this.setState({
										yearsOfDemand:
											objectives.find(obj => obj.value === objective)
												?.yearsOfDemand || MIN_DEFAULT_YEARS,
										fullTrayAssumption:
											objectives.find(obj => obj.value === objective)
												?.fullTrayAssumption || false
									})
									this.props.onChangeObjectiveMethod(objective)
								}}
							>
								{map(objectives, method => {
									return (
										<MenuItemTsx
											key={method?.value}
											style={{ textTransform: 'capitalize' }}
											value={method?.value}
										>
											{method?.name}
										</MenuItemTsx>
									)
								})}
							</SelectMenu>
						</div>
					</div>
				</div>
				{this.configurationQuantity()}
			</div>
		)
	}

	configurationMaterial = () => {
		const {
			materialTypesList,
			categoriesList,
			materialsList,
			chosenDefaultMaterialType,
			chosenDefaultMaterialCategory,
			chosenDefaultMaterial,
			onChangeNewMaterialType,
			onChangeNewMaterialCategory,
			onChangeNewMaterial
		} = this.props
		return (
			<div className="configure-wrapper-material flex">
				{chosenDefaultMaterialType && chosenDefaultMaterialCategory && (
					<MaterialsSolutionSelector
						hiddenMaterialNameSelector={false}
						showName={true}
						materialTypeValue={chosenDefaultMaterialType}
						materialCategoryValue={chosenDefaultMaterialCategory}
						materialValue={chosenDefaultMaterial}
						typesList={materialTypesList}
						categoriesList={categoriesList}
						materialsList={materialsList}
						onTypeChange={(value: string) => {
							onChangeNewMaterialType(value)
						}}
						onCategoryChange={(value: string) => {
							onChangeNewMaterialCategory(value)
						}}
						onMaterialsChange={(value: any) => {
							onChangeNewMaterial(value)
						}}
					/>
				)}
			</div>
		)
	}

	configurationQuantity = () => {
		const { batchSize, yearsOfDemand, quantityTitleText } = this.state
		const isAdvanced = this.props.objectivesValue === ADVANCED

		return (
			<div className="configure-wrapper flex">
				<div className="configure-wrapper__section flex">
					<div className={`text_field__label`}>{quantityTitleText}</div>
					<NumberField
						value={batchSize}
						onChangeValue={(value: number) =>
							this.updateState(StateValues.BATCH_SIZE, value)
						}
						inputClass="financial-form-quantity"
						idInput="plastic-form-quantity"
						minValue={MIN_NUMBER_FIELD_VALUE}
					/>
				</div>
				{isAdvanced && (
					<div className="configure-wrapper__section flex">
						<div className={`text_field__label`}>
							{getString('NEW_PART_CONFIGURATION_YEARS_OF_DEMAND')}
						</div>
						<NumberField
							value={yearsOfDemand}
							onChangeValue={(value: number) =>
								this.updateState(StateValues.YEARS_OF_DEMAND, value)
							}
							inputClass="financial-form-quantity"
							idInput="plastic-form-quantity"
							minValue={MIN_NUMBER_FIELD_VALUE}
						/>
					</div>
				)}
			</div>
		)
	}

	actionsButton = () => {
		const { loading, disableSubmit = false } = this.props
		const { callbackURL, batchSize, yearsOfDemand } = this.state
		const disableButton = disableSubmit || !batchSize || !yearsOfDemand

		return (
			<Flexbox
				justifyContent="flex-end"
				className="new-configuration-form--footer"
			>
				{callbackURL !== '' && (
					<Button
						className="new-configuration-form--footer-cancel-button"
						color="secondary"
						onClick={this.onCloseClick}
					>
						{getString('CANCEL')}
					</Button>
				)}
				<div className="new-configuration-form--footer-ok-button">
					<ButtonWithLoader
						loading={loading}
						disabled={disableButton}
						color="primary"
						onClick={() => this.onSendNewConfiguration()}
					>
						{getString('OK')}
					</ButtonWithLoader>
				</div>
			</Flexbox>
		)
	}

	configurationPrinters = () => {
		const {
			printersConfiguration,
			chosenPrinterConfigurationType,
			chosenPrinterConfiguration,
			onChangeSelectedPrinter,
			configurationProperties,
			onSaveNewPriorities,
			onChangeProperty,
			propertyName,
			desktopPrinter
		} = this.props

		return (
			<div className="flex start">
				<div className="configure-wrapper-manufacturing flex">
					<div className={`text_field__label`}>
						{getString('NEW_PART_CONFIGURATION_FIRST_METHOD')}
					</div>
					<div>
						<ConfigurePrintersMethod
							propertyName={propertyName}
							onChangeProperty={onChangeProperty}
							onSaveNewPriorities={onSaveNewPriorities}
							configurationProperties={configurationProperties}
							chosenPrinterConfigurationType={chosenPrinterConfigurationType}
							chosenPrinterConfiguration={chosenPrinterConfiguration}
							printersConfiguration={printersConfiguration}
							onChangeSelectedPrinter={onChangeSelectedPrinter}
							semiProfessionalOn={desktopPrinter}
						/>
					</div>
				</div>
			</div>
		)
	}

	configurationManufacturing = () => {
		const {
			manufacturingMethod,
			allowedManufacturingMethods,
			onChangeManufacturingMethod
		} = this.props

		return (
			<div className="flex start">
				<div className="configure-wrapper-manufacturing flex">
					<div className={`text_field__label`}>
						{getString('NEW_PART_CONFIGURATION_SECOND_METHOD')}
					</div>
					<div>
						<SelectMenu
							value={manufacturingMethod || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								onChangeManufacturingMethod(e.target.value)
							}
						>
							{map(allowedManufacturingMethods, method => {
								return (
									<MenuItemTsx
										key={method}
										style={{ textTransform: 'capitalize' }}
										value={method}
									>
										{method}
									</MenuItemTsx>
								)
							})}
						</SelectMenu>
					</div>
				</div>
			</div>
		)
	}

	render() {
		return (
			<NavBarAndMateriall title={''}>
				<Flexbox
					flexDirection="column"
					alignSelf="center"
					alignItems="center"
					maxWidth="98%"
					margin="0 auto 75px"
				>
					<div className="new-configuration-form">
						<div className="new-configuration-form--header flex center">
							<div className="new-configuration-form--header__title">
								{getString('NEW_PART_ANALYSIS_COMPARISON')}
							</div>
						</div>
						<div className="new-configuration-form--body">
							<div className="new-configuration-form--body__top flex">
								{this.configurationMaterial()}
							</div>
							<div className="new-configuration-form--body__center">
								{this.configurationObjectives()}
							</div>
							<div className="new-configuration-form--body__center">
								{this.configurationPrinters()}
								{this.configurationManufacturing()}
							</div>
						</div>
						<div className="flex end buttons-section">
							{this.actionsButton()}
						</div>
					</div>
				</Flexbox>
			</NavBarAndMateriall>
		)
	}
}

const mapStateToProps = (state: IReduxState) => {
	const {
		NewPartConfigurationReducer,
		MainPartAnalysisReducer: {
			partId,
			projectId,
			configurations,
			initialBatchSize
		},
		user: {
			materials,
			materialCategories,
			defaultConfigurations,
			userCustomizationSettings: { desktopPrinter }
		}
	}: IReduxState = state

	return {
		materials,
		partId,
		projectId,
		configurations,
		materialCategories,
		defaultConfigurations,
		initialBatchSize,
		desktopPrinter,
		...NewPartConfigurationReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators(
		{
			...NewPartConfigurationActions
		},
		dispatch
	)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(NewPartConfiguration)
)
