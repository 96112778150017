import React, { FC, memo } from 'react'
import CastorRadio from '../CastorRadio'

import './DataTable.scss'

interface IProps {
  wrapperClassName: string
  data: any
}

const DataTableRadioButtonWithColor: FC<IProps> = ({
  wrapperClassName,
  data: { color, value, selectedValue, handleChange, disabled }
}) => {
  return (
    <div
      className={`data-table--radio ${wrapperClassName} ${
        disabled ? 'disabled' : ''
      }`}
    >
      <div
        className={`data-table--radio--color`}
        style={{ backgroundColor: color }}
      />
      <CastorRadio
        checked={selectedValue === value}
        onChange={handleChange}
        value={value}
        disabled={disabled}
        name="data-table-radio"
        inputProps={{ 'aria-label': value }}
        className={`data-table--radio--input`}
      />
    </div>
  )
}

export default memo(DataTableRadioButtonWithColor)
