import React, { FC, useMemo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { gaAlertKeys, SOLUTION_FILTERS_IDS, STEP_ID } from '../../constants'
import { useStep } from '../../OnboardingWizardHooks'
import {
	isContentPresent,
	isSolutionFilteringChanged
} from '../../OnboardingWizardService'
import OnboardingStepFrame from '../OnboardingStepFrame'
import GeometryAnalysisPreferences from './GeometryAnalysisPreferences'
import SolutionPreferences from './SolutionPreferences'
import { IN_HOUSE_PRINTERS } from 'Services/Constants'
import { IUserFilter } from 'Services/models/IUserFilter'
import { UserFilterService } from 'Services/UserFilterService'

import './SolutionFiltering.scss'

const SolutionFilteringComponents = {
	[SOLUTION_FILTERS_IDS.SOLUTION_PREFERENCES]: <SolutionPreferences />,
	[SOLUTION_FILTERS_IDS.GA_PREFERENCES]: <GeometryAnalysisPreferences />
}

const userFilterService = new UserFilterService()

const SolutionFiltering: FC = () => {
	const { steps } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { userCustomizationSettings, filters, userFilters } = useSelector(
		(state: RootStateOrAny) => state?.user
	)
	const { step, stepContent } = useStep(steps, STEP_ID.SOLUTION_FILTERING)

	const defaultFilters = useMemo(() => {
		const userInHouseFilter = userFilters.find(
			(filter: IUserFilter) => filter.name === IN_HOUSE_PRINTERS
		)
		return userFilterService.initializeFilters(filters, userInHouseFilter)
	}, [filters, userFilters])

	const changesInCurrentStep =
		(isContentPresent(stepContent, SOLUTION_FILTERS_IDS.GA_PREFERENCES) &&
			gaAlertKeys.some(key => !userCustomizationSettings[key])) ||
		(isContentPresent(stepContent, SOLUTION_FILTERS_IDS.SOLUTION_PREFERENCES) &&
			isSolutionFilteringChanged(defaultFilters, userFilters))

	return (
		<OnboardingStepFrame
			step={step}
			changesInCurrentStep={changesInCurrentStep}
		>
			<div className="solution-filtering--wrapper">
				{stepContent?.map(component => {
					return (
						<React.Fragment key={component.id}>
							{
								SolutionFilteringComponents[
									component.id as keyof typeof SolutionFilteringComponents
								]
							}
						</React.Fragment>
					)
				})}
			</div>
		</OnboardingStepFrame>
	)
}

export default SolutionFiltering
