import React from 'react'
import { Button } from '../../../../Components/thirdParty/CreativeTim/components'
import Settings from '@material-ui/icons/Settings'

import '../AdminUsers.scss'
import '../../adminHome.scss'

const CustomizeMenuTarget = props => {
  const { name, onClick } = props

  return (
    <div>
      <Button
        className="link-button"
        size="sm"
        color="primary"
        onClick={onClick}
      >
        <Settings />
        {name}
      </Button>
    </div>
  )
}

export default CustomizeMenuTarget
