import { memo } from 'react'
import { Link } from 'react-router-dom'
import Flexbox from 'Scenes/Components/FlexBox'
import PublishIcon from '@material-ui/icons/Publish'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import ExtensionIcon from '@material-ui/icons/Extension'

import CastorForm from '../../../../Components/CastorForm/CastorForm'
import {
  Danger,
  Table,
  Button
} from '../../../../Components/thirdParty/CreativeTim/components'
import { ICluster } from '../../../../../Services/models/ICluster'
import {
  ADMIN_PARTS_PART_ID,
  ADMIN_PARTS_SIZE,
  ADMIN_PARTS_VOLUME,
  ADMIN_PARTS_AREA,
  ADMIN_PARTS_CLUSTER_PARTS_NO,
  ADMIN_PARTS_RESULT,
  ADMIN_PARTS_STATUS,
  ADMIN_PARTS_ORIGINAL_MATERIAL,
  REJECT,
  PARTS,
  PART_ANALYSIS_REMOVE
} from '../../../../../Services/Strings'

import ButtonWithLoader from '../../../../Components/ButtonWithLoader'
import IconFactory from '../../../../Components/StarIcon/IconFactory'
import { adminClusterPartsRoute } from '../../../../../Services/routeFuncs'

import './AdminClusters.scss'

const PublishIconTSX: any = PublishIcon
const NotInterestedIconTSX: any = NotInterestedIcon
const ExtensionIconTSX: any = ExtensionIcon
const LinkTSX: any = Link
const TableTSX: any = Table
const ButtonTSX: any = Button
const DangerTSX: any = Danger

const clustersTableHead = ['image', 'Information', 'Results', 'Actions']

interface IProps {
  clusters: ICluster[]
  publishAllClustersLoading: boolean
  publishClusterLoaders: any
  rejectClusterLoaders: any
  removeClusterLoaders: any
  projectClusterHeaderText: string
  publishAllClusters: Function
  onPublishClusterClick: Function
  onRejectClusterClick: Function
  onClusterStructureClick: Function
  onRemoveClusterClick: Function
}

export default memo(function AdminClusters({
  clusters,
  projectClusterHeaderText,
  publishAllClustersLoading,
  publishAllClusters,
  onRejectClusterClick,
  rejectClusterLoaders,
  onClusterStructureClick,
  onRemoveClusterClick,
  removeClusterLoaders
}: IProps) {
  const renderClusterButtons = (cluster: ICluster) => (
    <Flexbox flexDirection="column" key={cluster.id}>
      <ButtonWithLoader
        onClick={() => onRejectClusterClick(cluster.id)}
        className="admin-clusters--action-button"
        style={{ width: '60%' }}
        color="primary"
        loading={rejectClusterLoaders[cluster.id]}
      >
        <NotInterestedIconTSX />
        {REJECT}
      </ButtonWithLoader>
      <ButtonWithLoader
        onClick={() => onRemoveClusterClick(cluster.id)}
        className="admin-clusters--action-button"
        style={{ width: '60%' }}
        color="primary"
        loading={removeClusterLoaders[cluster.id]}
      >
        <IconFactory iconName="clear" />
        {PART_ANALYSIS_REMOVE}
      </ButtonWithLoader>
      <LinkTSX to={adminClusterPartsRoute(cluster.projectId, cluster.id)}>
        <ButtonTSX className="admin-clusters--action-button" color="primary">
          <ExtensionIconTSX />
          {PARTS}
        </ButtonTSX>
      </LinkTSX>
    </Flexbox>
  )

  const renderClusterResults = (cluster: ICluster) => (
    <div key={cluster.id}>
      <div>
        {ADMIN_PARTS_STATUS}:{' '}
        <span className="admin-clusters--result">{cluster.status}</span>
      </div>
      <div>
        {ADMIN_PARTS_RESULT}: {cluster.result}
      </div>
      <div>
        {ADMIN_PARTS_ORIGINAL_MATERIAL}: {`${cluster.material?.name || ''}`}
      </div>
    </div>
  )

  const renderClusterInfo = (cluster: ICluster) => (
    <div key={cluster.id}>
      <div>
        {ADMIN_PARTS_PART_ID}: {cluster.id}
      </div>
      <div>
        {ADMIN_PARTS_SIZE}:{' '}
        {`${cluster.width} * ${cluster.height} * ${cluster.depth} mm`}
      </div>
      <div>
        {ADMIN_PARTS_VOLUME}: {`${cluster.volume}`}
      </div>
      <div>
        {ADMIN_PARTS_AREA}: {`${cluster.area}`}
      </div>
      <div>
        {ADMIN_PARTS_CLUSTER_PARTS_NO}: {`${cluster.parts.length}`}
      </div>
    </div>
  )

  const renderClusterImage = (cluster: ICluster) => (
    <img
      src={cluster.imageURL}
      className="admin-clusters--image"
      key={cluster.id}
    />
  )

  const renderClustersTableData = () => {
    return clusters.map(cluster => {
      return [
        renderClusterImage(cluster),
        renderClusterInfo(cluster),
        renderClusterResults(cluster),
        renderClusterButtons(cluster)
      ]
    })
  }

  const renderClusterHeaderText = () => (
    <div className="align-start">
      <DangerTSX>{projectClusterHeaderText}</DangerTSX>
    </div>
  )

  const renderClustersTable = () => {
    if (!clusters.length) {
      return renderClusterHeaderText()
    }
    return (
      <div>
        {renderClusterHeaderText()}
        <TableTSX
          tableHead={clustersTableHead}
          tableData={renderClustersTableData()}
        />
      </div>
    )
  }

  return (
    <CastorForm
      formTitle={'Unifications'}
      formSubTitle="List of all project unification options"
      content={renderClustersTable()}
      style={{ maxWidth: 'unset' }}
    />
  )
})
